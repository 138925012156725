import React, { useContext, useEffect, useState } from 'react'
import { LoadingOverlay, LoadingPropsSizeEnum } from './loading'
import { GridTableHeader } from './grid-table-header'
import { GridContext } from './grid'
import { Checkbox } from './forms'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IRowGroup, IGridListItem, IGridColumn } from '../stores/grid-definitions'
import { buildTooltipProps, enableTooltip, destroyTooltip } from '../services/helpers'
import { usePrevious } from 'react-use'
import { ReactComponent as InfoCircleIcon } from '../assets/info-circle.svg'
import { GridRowAction } from '../models/grid-row-action'
import clsx from 'clsx'
import { ChevronDownIcon, ChevronRightIcon } from '../assets'
import { uniq } from 'lodash'
import { ContextMenu } from './context-menu'

export const GridTable = () => {
	const { state: gridState, actions: gridActions } = useContext(GridContext)!
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)

	const { loading, rows, columns } = gridState

	const [cellTooltips, setCellTooltips] = useState<{ [key: string]: string }>({})
	const previousCellTooltips = usePrevious(cellTooltips)

	const groupedRows: {
		rows: IGridListItem[]
		rowGroupDetails: IRowGroup
	}[] = []
	const unGroupedRows: IGridListItem[] = []

	rows.forEach(row => {
		if (row.rowGroup && (!row.hidden || row.rowGroup.showEmptyRowGroup)) {
			const existingRowGroup = groupedRows.find(rowGroup => rowGroup.rowGroupDetails.id === row.rowGroup?.id)
			if (existingRowGroup) {
				existingRowGroup.rows.push(row)
			} else {
				groupedRows.push({
					rowGroupDetails: row.rowGroup,
					rows: [row]
				})
			}
		} else {
			unGroupedRows.push(row)
		}
	})

	const getCellId = (row: IGridListItem, col: IGridColumn) => `${row.id}-${col.property}`
	const getCellTooltipId = (row: IGridListItem, col: IGridColumn) => `${getCellId(row, col)}-tooltip`

	const renderRow = (row: IGridListItem, removeTopBorder?: boolean) => {
		if (row.hidden) return null
		return (
			<tr
				onDoubleClick={() => {
					if (gridState.rowDoubleClicked) gridState.rowDoubleClicked(row)
				}}
				key={row.id}
				onClick={() => {
					let press = true
					if (typeof gridState.disableSelect === 'boolean' && gridState.disableSelect) press = false
					if (typeof gridState.disableSelect === 'function' && gridState.disableSelect(row)) press = false
					if (press) gridActions.rowPressed(row)
				}}
				id={row.id}
				className={`${gridState.highlightedRows?.includes(row.id) ? 'bg-warning-4' : ''} ${gridState.onRowPress ? 'cursor-pointer' : ''}`}
			>
				{gridState.rowSelectEnabled ?
					<td className='grid-checkbox'>
						<Checkbox
							id={row.id}
							checked={!!row.selected}
							onChange={(e) => { gridActions.toggleRowSelect(row) }}
							disabled={(() => {
								if (typeof gridState.disableSelect === 'boolean') return gridState.disableSelect
								if (typeof gridState.disableSelect === 'function') return gridState.disableSelect(row)
								return false
							})()}
						/>
					</td>
					:
					null
				}
				{gridState.enableRowColors ?
					<td>
						<div>
							<span style={{ height: '20px', width: '20px', backgroundColor: `#${row.color}`, display: 'block', marginTop: '2px' }} className="rounded-circle"></span>
						</div>
					</td>
					:
					null
				}
				{columns.filter(o => !o.hide).map(col => {
					let tooltipText = cellTooltips[getCellTooltipId(row, col)]
					const tooltipProps = tooltipText ? buildTooltipProps({ tooltipText, placement: 'bottom', html: true }) : {}

					let justifyContent = ''
					if (col.align) {
						if (col.align === 'left') justifyContent = 'justify-content-start'
						if (col.align === 'right') justifyContent = 'justify-content-end'
						if (col.align === 'center') justifyContent = 'justify-content-center'
					}
					
					let overflowHidden = ''
					if (col.type !== 'actions')
					{
						overflowHidden = 'overflow-hidden'
					}
					return (
						<td
							key={getCellId(row, col)}
							align={col.align || 'left'}
							className={`${removeTopBorder ? 'border-0' : ''} ${col.conditionalCellCSSFormatting ? col.conditionalCellCSSFormatting(row) : ''}`}
						>
							<div className={clsx('d-flex align-items-center', justifyContent, overflowHidden)}>
								{col.render(col, row, gridState, appActions, appState, gridActions)}
								{tooltipText &&
									<div
										id={getCellTooltipId(row, col)}
										className='ml-auto primary-color-hover cursor-pointer'
										{...tooltipProps}
										style={{ display: 'flex', 'whiteSpace': 'nowrap' }}
									>
										<InfoCircleIcon />
									</div>
								}
							</div>
						</td>
					)
				})}
			</tr>
		)
	}

	/* 
		Compile cell tooltips whenever rows change
	*/
	useEffect(() => {
		// Unlike normal state usage, we actually DO want to override the whole set of cell tooltip values, this will allow us to perform add/remove diff on the previous and new tooltip sets.
		const newCellTooltips: { [key: string]: string } = {}

		// See if any visible columns have tooltips
		columns.filter(o => !o.hide).forEach(col => {
			if (col.tooltip) {
				rows.forEach(row => {
					let tooltipText = null
					if (typeof col.tooltip === 'string') {
						tooltipText = col.tooltip
					} else if (typeof col.tooltip === 'function') {
						tooltipText = col.tooltip(row)
					}
					if (tooltipText) {
						newCellTooltips[getCellTooltipId(row, col)] = tooltipText
					}
				})
			}
		})

		setCellTooltips(newCellTooltips)

		//eslint-disable-next-line
	}, [rows])

	/* 
		Enable/disable cell tooltips whenever the tooltips change
	*/
	useEffect(() => {
		// Diff the cell IDs and destroy any tooltips that were removed and enable any tooltips that were added
		const previousCellTooltipIds: string[] = previousCellTooltips ? Object.keys(previousCellTooltips) : []
		const currentCellTooltipIds = Object.keys(cellTooltips)

		const removedTooltipIds = previousCellTooltipIds.filter(id => !currentCellTooltipIds.includes(id))
		const addedTooltipIds = currentCellTooltipIds.filter(id => !previousCellTooltipIds.includes(id))

		removedTooltipIds.forEach(destroyTooltip)
		addedTooltipIds.forEach(enableTooltip)

		//eslint-disable-next-line
	}, [cellTooltips])

	const renderSelectedRows = () => {
		const selectedRowCount = Object.keys(gridState.selectedRows).length
		if (selectedRowCount > 0)
			return <div><b>{selectedRowCount} of {gridState.totalCount}</b>&nbsp; records are selected.</div>
		else
			return <div>&nbsp;</div>
	}

	return (
		<div className={`grid-table ${loading ? 'no-scroll' : ''} ${rows.length === 0 ? 'd-flex flex-column' : ''}`}>
			{gridState.rowSelectEnabled && 
				<div className='d-flex p-2 justify-content-center' style={{backgroundColor: "#F5F3F2"}}>
				{renderSelectedRows()}
				</div> }
			<table
				className={`table table-hover`}

				// We manually set the width of the table so that we can respect individual column set widths (and make sure the table is large enough to hold them all)
				style={{ minWidth: columns.filter(o => !o.hide).reduce((total, col) => total += col.width, 0) }}
			>
				<GridTableHeader />

				<tbody>
					{groupedRows.map(rowGroup => (
						<React.Fragment key={rowGroup.rowGroupDetails.id}>
							{/* Title row for a group of rows */}
							<tr>
								<td
									className='border-0'
									colSpan={columns.filter(col => !col.hide).length + (gridState.rowSelectEnabled ? 1 : 0)}
								>
									<span className='px-1 pb-1 d-flex align-items-center'>
										<b
											style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
											onClick={() => {
												if (rowGroup.rows.filter(r => !r.hidden).length > 0) gridActions.toggleRowGroupExpandCollapse(rowGroup.rowGroupDetails.id)
											}}
										>
											{rowGroup.rowGroupDetails.collapsed ?
												<ChevronRightIcon className='mr-1' style={{ color: rowGroup.rows.filter(r => !r.hidden).length > 0 ? 'gray' : 'lightgray' }} />
												:
												<ChevronDownIcon className='mr-1' style={{ color: rowGroup.rows.filter(r => !r.hidden).length > 0 ? 'gray' : 'lightgray' }} />

											}
											{rowGroup.rowGroupDetails.title}</b>&nbsp;<span className='mr-2'>({rowGroup.rows.filter(r => !r.hidden).length} item{rowGroup.rows.filter(r => !r.hidden).length === 1 ? '' : 's'})
										</span>
										{gridState.rowGroupActions?.actions?.map(action => {
											if (typeof action.hidden === 'function' && action.hidden(rowGroup.rowGroupDetails.id)) {
												return null
											} else if (typeof action.hidden === 'boolean' && action.hidden) {
												return null
											}

											let tooltipText: string | null | undefined = null
											if (typeof action.tooltipText === 'string') {
												tooltipText = action.tooltipText
											} else if (typeof action.tooltipText === 'function') {
												tooltipText = action.tooltipText(rowGroup.rowGroupDetails.id)
											}

											let buttonClass = 'btn-light'
											let disabled = false
											if (typeof action.disabled === 'boolean') disabled = action.disabled
											if (typeof action.disabled === 'function') disabled = action.disabled(rowGroup.rowGroupDetails.id, gridState)

											return <button
												disabled={disabled}
												key={action.id}
												id={action.id}
												type="button"
												className={`btn btn-sm ml-2 ${buttonClass}`}
												onClick={(e) => {
													if (GridRowAction.getRowGroupDisabledState(action, rowGroup.rowGroupDetails.id, gridState)) return
													action.action({ rowGroupId: rowGroup.rowGroupDetails.id, gridState, e, appActions, appState })
												}}
											>
												{action.icon ? typeof action.icon === 'function' ? action.icon(rowGroup.rowGroupDetails.id, gridState) : action.icon : null}
												&nbsp;
												{action.title ? action.title : null}
											</button>
										})}
										{gridState.rowGroupActions?.nestedActions && gridState.rowGroupActions?.nestedActions.length > 0 &&
											<div className='ml-2'>
												<ContextMenu>
												{gridState.rowGroupActions.nestedActions.map(action => {
													if (typeof action.hidden === 'function' && action.hidden(rowGroup.rowGroupDetails.id)) {
														return null
													} else if (typeof action.hidden === 'boolean' && action.hidden) {
														return null
													}

													let tooltipText: string | null | undefined = null
													if (typeof action.tooltipText === 'string') {
														tooltipText = action.tooltipText
													} else if (typeof action.tooltipText === 'function') {
														tooltipText = action.tooltipText(rowGroup.rowGroupDetails.id)
													}

													let buttonClass = 'btn-light'
													let disabled = false
													if (typeof action.disabled === 'boolean') disabled = action.disabled
													if (typeof action.disabled === 'function') disabled = action.disabled(rowGroup.rowGroupDetails.id, gridState)

													return <a
														key={action.id}
														id={action.id}
														className={`dropdown-item ${disabled ? 'disabled' : ''} ${buttonClass}`}
														onClick={(e) => {
															if (GridRowAction.getRowGroupDisabledState(action, rowGroup.rowGroupDetails.id, gridState)) return
															action.action({ rowGroupId: rowGroup.rowGroupDetails.id, gridState, e, appActions, appState })
														}}
														href='#'
														>
														{action.icon ? typeof action.icon === 'function' ? action.icon(rowGroup.rowGroupDetails.id, gridState) : action.icon : null}
														&nbsp;
														{action.title ? action.title : null}
													</a>
												})}
											</ContextMenu>
											</div>
										}
									</span>
								</td>
							</tr>

							{/* Render the rows for this row group */}
							{!rowGroup.rowGroupDetails.collapsed && rowGroup.rows.map((row, index) => renderRow(row, index === 0))}

							{/* 
								Column totals 

								Iterate over each column and, if totalling is enabled, sum the values of all rows for that column.
							*/}
							{columns.filter(o => !o.hide).find(o => o.rowGroupTotal) &&
								<tr>
									{gridState.rowSelectEnabled  &&
										<td>&nbsp;</td>
									}	
									{columns.filter(o => !o.hide).map(col =>
										<td key={`${rowGroup.rowGroupDetails.id}-${col.property}-row-group-totals`}>
											{col.rowGroupTotal && !col.rowGroupTotalUnique &&
												rowGroup.rows
													.map(o => o.values[col.property])
													.filter(o => typeof o === 'number')
													.reduce((total, value) => (total as number) += (value as number), 0)
											}
											{col.rowGroupTotal && col.rowGroupTotalUnique &&
												uniq(rowGroup.rows
													.map(o => o.values[col.property]))
													.length
											}
											{col.rowGroupSuffix}
										</td>
									)}
								</tr>
							}

							{/* Render empty row group (no rows) label if applicable */}
							{rowGroup.rowGroupDetails.showEmptyRowGroup && rowGroup.rows.filter(r => !r.hidden).length === 0 ?
								<tr>
									<td
										className='border-0'
										colSpan={columns.filter(col => !col.hide).length + (gridState.rowSelectEnabled ? 1 : 0)}
									>
										<span className='px-1 pb-1 d-flex align-items-center justify-content-center'>
											<i>{rowGroup.rowGroupDetails.emptyRowGroupLabel}</i>
										</span>
									</td>
								</tr>
								:
								null
							}
						</React.Fragment>
					))}
					{unGroupedRows.map(row => renderRow(row))}
				</tbody>
			</table>

			{rows.length === 0 && !gridState.loading ?
				<div className='flex-fill d-flex flex-column justify-content-center align-items-center my-4'>
					{gridState.queryState.filters && gridState.queryState.filters.filter(f => f.enabled).length > 0 ?
						<h4 className='text-dark'>This combination of query filters has returned no results. Please try adjusting your filters.</h4>
						:
						<h4 className='text-dark'>No results.</h4>
					}
				</div>
				:
				null
			}

			{/* We apply a loading overlay when the table's data is loading so that we prevent user interactions while data is being fetched. */}
			{loading ? <LoadingOverlay size={LoadingPropsSizeEnum.medium} /> : null}
		</div>
	)
}