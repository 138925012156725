import { Form, Formik } from "formik"
import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IEventModelDocument, IMinistrySearchResultModelDocument, ISearchRequestFilterDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IFilter } from "../stores/api-actions"
import { FormikEffect } from "./formik-effect"
import { ISelectFieldOption, SelectField, SelectList, TextareaField } from "./forms"
import { Loading, LoadingOverlay, LoadingPropsSizeEnum } from "./loading"
import * as Yup from 'yup'
import { AccountLevel } from "../constants"
import { parse } from "papaparse"
import { isValidEmail } from "../services/helpers"
import { AddToCourseFormTab } from "./add-to-course-form"
import dayjs from "dayjs"

const filter: IFilter = {
    id: 'branchIdFilter',
    enabled: true,
    value: [],
    operator: 'in',
    property: 'branchAbbr'
}
	
interface Contact {
	contactId: number
	name?: string
	ministryName?: string
}

export interface IFormValues {
    ministryContactIds: number[]
	emails: string[]
	eventId: number
}

export interface IAddNonRegistrantsForm {
    afterSave?: () => void
	initialContacts?: Contact[]
    onSave: (args: IFormValues) => Promise<void>
	activeTab: AddToCourseFormTab,
	setActiveTab: (tab: AddToCourseFormTab) => void,
	eventToEdit?: IEventModelDocument
}

export enum AddNonRegistrantsModalTitle {
	'main' = 'Invite Contacts to Event Course Group',
	'add-contacts' = 'Add Contact',
	'add-outside-contacts' = 'Add Outside Contact',
	'remove-contacts' = 'Remove Contact'
}

export const AddNonRegistrantsForm = ({ afterSave, initialContacts, onSave, activeTab, setActiveTab, eventToEdit }: IAddNonRegistrantsForm) => {

    const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
    const { MinistriesApi, fetchMiUsers, MinistryContactsApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
	
	const [selectedContacts, setSelectedContacts] = useState<number[] | string[]>([])

    const [contactsLoading, setContactsLoading] = useState(false)
    const [contactOptions, setContactOptions] = useState<{ label: string, value: Contact }[]>([])

    const [ministriesLoading, setMinistriesLoading] = useState(false)
    const [ministries, setMinistries] = useState<IMinistrySearchResultModelDocument[] | null>()
    const [ministryOptions, setMinistryOptions] = useState<ISelectFieldOption[]>([])
	const [communityOptions, setCommunityOptions] = useState<ISelectFieldOption[] | null>()

    const [initialValues, setInitialValues] = useState({
        ministryContactIds: initialContacts || [],
		emails: [] as string[],
		eventId: eventToEdit?.eventId ?? 0,
    })

	const resetInitialValues = () => {
		setInitialValues({
			ministryContactIds: [],
			emails: [],
			eventId: eventToEdit?.eventId ?? 0,
		})
	}

	useEffect(() => {
		const fetchCommunities = async () => {
			const communities = await appActions.MiBranchesApi.apiMiBranchesGet()
			if ( ! communities ) return

			setCommunityOptions(communities.data.sort((a, b) => a.branchName?.localeCompare(b.branchName ?? '') ?? 0).map(o => ({ label: o.branchName, value: o.branchId.toString() }) as ISelectFieldOption))
		}

		appActions.refreshBranchesCache()
		fetchCommunities()
	}, [])

	const fetchMinistriesForBranchId = async (branchId: string) => {
		const branch = appState.activeBranches.find(o => o.branchId.toString() === branchId)
		if (!branch?.branchAbbr) return

		setMinistriesLoading(true)
		filter.value = [branch.branchAbbr]
		const branchFilter = JSON.stringify([filter]) as unknown as ISearchRequestFilterDocument[]
		const ministryQuery = await makeHttpRequestWithUi({
			request: MinistriesApi.apiMinistriesGet(0, 100000, undefined, branchFilter),
			toastErrorMessage: 'There was an error retrieving the ministries for this community.',
			disableSuccessToast: true,
			disableLoading: true,
		})
		setMinistries(ministryQuery.data.data)
		setMinistryOptions(ministryQuery.data.data?.map(o => ({ label: `${o.ministryName} ${o.levelId ? `(${AccountLevel[o.levelId]})` : ''}`, value: `${o.ministryId}` })) || [])
		setMinistriesLoading(false)
	}

	const handleAddRegistrants = useCallback((values) => {
		setInitialValues(initialValues => ({...initialValues, ministryContactIds: [...initialValues.ministryContactIds, ...values.ministryContactIds]}) )
		setActiveTab(AddToCourseFormTab["main"])
	}, [])

	const handleRemoveRegistrants = useCallback((values) => {
		setSelectedContacts(values)
		setActiveTab(AddToCourseFormTab["remove-contacts"])
	}, [])

	const contactsInitialValues = {
		branchId: '',
		ministryId: '',
		ministryContactIds: []
	}

	if ( activeTab === AddToCourseFormTab["add-contacts"] ) return (
		<Formik 
			enableReinitialize 
			onSubmit={handleAddRegistrants} 
			initialValues={contactsInitialValues} 
			children={formikProps => (
				<>
					<FormikEffect
						formikProps={formikProps}
						onChange={async (prevValues, nextValues) => {
							if (!nextValues.branchId) setMinistryOptions([])
							if (nextValues.branchId && nextValues.branchId !== prevValues.branchId) {
								fetchMinistriesForBranchId(nextValues.branchId)
							}

							if (nextValues.ministryId !== prevValues.ministryId || nextValues.branchId !== prevValues.branchId) setContactOptions([])
							if (nextValues.ministryId && nextValues.ministryId !== prevValues.ministryId) {
								setContactsLoading(true)
								const contactsQuery = await makeHttpRequestWithUi({
									request: MinistryContactsApi.apiMinistriesIdContactsGet(parseInt(nextValues.ministryId)),
									disableSuccessToast: true,
									toastErrorMessage: 'There was an error retrieving the contacts for this ministry.',
									disableLoading: true,
								})
								setContactOptions(contactsQuery.data.map(o => ({ 
									label: `${o.firstName} ${o.lastName} (${ministries?.find(m => m.ministryId.toString() === nextValues.ministryId)?.ministryName})`, 
									value: { 
										name: `${o.firstName} ${o.lastName}`, 
										ministryName: ministries?.find(m => m.ministryId.toString() === nextValues.ministryId)?.ministryName, 
										contactId: o.ministryContactId 
									} as Contact
								})))
								setContactsLoading(false)
							}
						}}
					/>
					<Form>
						<>
							<div className="d-flex">
								<div style={{ flex: 1, marginRight: 10 }}>
									<SelectField
										options={communityOptions as ISelectFieldOption[]}
										fieldProps={{ name: 'branchId', label: "AD's Community" }}
									/>
								</div>
								<div style={{ flex: 1 }}>
									{ministriesLoading ?
										<Loading size={LoadingPropsSizeEnum.medium} />
										:
										<SelectField fieldProps={{ name: 'ministryId', label: 'Ministry' }} options={ministryOptions} disabled={ministryOptions.length === 0} />
									}
								</div>
							</div>
							<div style={{ minHeight: 85 }}>
								{contactsLoading ?
									<Loading size={LoadingPropsSizeEnum.medium} />
									:
									<SelectField
										fieldProps={{ name: 'ministryContactIds', label: 'Contacts' }}
										options={contactOptions}
										disabled={contactOptions.length === 0}
										multiple
									/>
								}
							</div>
						</>

						<div style={{ display: 'flex' }}>
							<button type='submit' style={{ width: 100 }} className='btn btn-primary'>Add</button>
							<button style={{ width: 100, marginLeft: 10 }} type='button' className='btn btn-secondary' onClick={() => {
								setActiveTab(AddToCourseFormTab["main"])
							}}>Cancel</button>
						</div>
					</Form>
				</>
			)} 
		/>
	)

	if ( activeTab === AddToCourseFormTab["remove-contacts"] ) return (
		<>
			{ selectedContacts.length 
				? <>
					<p>Are you sure you want to remove these contacts?</p>
					<div style={{ display: 'flex' }}>
						<button style={{ width: 100, marginRight: 10 }} type='button' className='btn btn-secondary' onClick={() => setActiveTab(AddToCourseFormTab["main"])}>Cancel</button>
						<button type='submit' style={{ width: 100 }} className='btn btn-primary' onClick={() => {
							setInitialValues(values => {
								if ( typeof selectedContacts[0] === 'number' ) {
									const newContacts = values.ministryContactIds.filter(v => !(selectedContacts as (number[])).find(c => c === v.contactId))
			
									return {...values, ministryContacts: newContacts}
								} else {
									const newContacts = values.emails.filter(v => !(selectedContacts as (string[])).find(c => c === v))
			
									return {...values, outsideContactEmails: newContacts}
								}
							})
							setActiveTab(AddToCourseFormTab["main"])
						}}>Remove</button>
					</div>
				</>
				: <>
					<p>Please select at least one contact to remove.</p>
					<div style={{ display: 'flex' }}>
						<button style={{ width: 100 }} type='button' className='btn btn-secondary' onClick={() => {
							setActiveTab(AddToCourseFormTab["main"])
						}}>Close</button>
					</div>
				</>
			}
			
		</>
	)

	if ( activeTab === AddToCourseFormTab["add-outside-contacts"] ) return (
		<Formik
			initialValues={{
				csvFile: '',
				pastedEmails: ''
			}}
			validationSchema={Yup.object({
				pastedEmails: Yup.mixed().test(
					'pastedEmails',
					'Please upload or paste a list of emails.',
					function (value) {
						if (!value) return false
						if ((value as string).trim().replaceAll(' ', '').split(',').some(o => !isValidEmail(o)) && (value as string).trim().replaceAll(' ', '').split('\n').some(o => !isValidEmail(o))) return false
						return true
					}
				)
			})}
			onSubmit={async (values) => {
				if (values.pastedEmails) {
					const results = parse<string>(values.pastedEmails.trim().replaceAll(' ', ''))
					setInitialValues(initialValues => ({...initialValues, emails: [...initialValues.emails, ...results.data.flat().filter(o => !initialValues.emails.includes(o))]}))
					setActiveTab(AddToCourseFormTab["main"])
				}
			}}
			children={formikProps => (
				<Form style={{ height: '100%', }}>
					<TextareaField fieldProps={{ name: 'pastedEmails', label: 'Paste a delimitted list of emails' }} />

					<button type='submit' className='btn btn-primary mr-2'>Add Emails</button>
					<button type='button' className='btn btn-secondary' onClick={() => {
						setActiveTab(AddToCourseFormTab["main"])
					}}>Cancel</button>
				</Form>
			)}
		/>
	)

    return ( eventToEdit ?
		<Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
                ministryContactIds: Yup.array().of(Yup.object()).nullable()
                    .test(
                        'contacts',
                        'Required',
						function (value) {
							if ((!value || !value.length) && !this.parent.emails) return false
							return true
						}
                    ),
                emails: Yup.array().of(Yup.string()).nullable()
					.test(
						'outsideContacts',
						'Please add at least one Ministry Contact or Outside Contact.',
						function (value) {
							if ((!value || !value.length) && (!this.parent.ministryContactIds || !this.parent.ministryContactIds.length)) return false
							return true
						}
					),
				eventId: Yup.number().required('Required'),
            })}
            onSubmit={async (values, actions) => {
				const parsedValues = {
					...values,
					ministryContactIds: values.ministryContactIds.map((c: Contact) => c.contactId)
				}
                const results = await onSave(parsedValues)
                actions.resetForm()
				resetInitialValues()
                afterSave && afterSave()
            }}
            children={formikProps => {
                return (
					<>
						<p>You are adding <strong>non-registrants</strong> to this event's course group. This will grant the contacts access to the course and you have the option to notify them via email. <strong>This will not add the contacts to the event as registrants.</strong></p>

						<Form>
							<div className="row">
								<div className="col">
										<p>Event: </p>

										<p>{eventToEdit?.eventContent.title} - {eventToEdit?.startDateTime && dayjs(eventToEdit.startDateTime?.toString()).format('M/D/YYYY')} - {appState.activeBranches.find(b => b.branchId === eventToEdit?.branchId)?.branchAbbr}</p>
								</div>

								<div className="col">
									<SelectList
										initialValues={formikProps.values.ministryContactIds?.map(v => ({ value: v.contactId, label: `${v.name} (Ministry ${v.ministryName})` }))}
										onAddValue={() => {
											setInitialValues(formikProps.values)
											setActiveTab(AddToCourseFormTab["add-contacts"])
										}}
										onRemoveValue={(values) => {
											setInitialValues(formikProps.values)
											handleRemoveRegistrants(values)
										}}
										fieldProps={{ name: 'contacts', label: 'Selected Ministry Contacts'}} 
									/>

									<SelectList
										initialValues={formikProps.values.emails?.map(v => ({ value: v, label: v }))}
										onAddValue={() => {
											setInitialValues(formikProps.values)
											setActiveTab(AddToCourseFormTab["add-outside-contacts"])
										}}
										onRemoveValue={(values) => {
											setInitialValues(formikProps.values)
											handleRemoveRegistrants(values)
										}}
										fieldProps={{ name: 'outsideContacts', label: 'Selected Outside Contacts'}} 
									/>
								</div>
							</div>
							
							<div className="d-flex justify-content-center">
								<button type='submit' className='btn btn-primary mr-2'>Save</button>
								<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							</div>
						</Form>
					</>
                )
            }}
        /> : <LoadingOverlay size={LoadingPropsSizeEnum.medium} />
	)
}