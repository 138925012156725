import { IMinistryGrantsSummaryDocument } from "../open-api"
import { IGridListItem, IGridColumn } from "../stores/grid-definitions"
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from "../components/grid-cell-displays"

export class GrantModel {

	public static toGridListItem = (grantModel: IMinistryGrantsSummaryDocument): IGridListItem => {
		return {
			id: grantModel.grantId.toString(),
			values: {
				grantDesc: grantModel.grantDesc,
				grantType: grantModel.grantType,
				grantStatus: grantModel.grantStatus,
				grantReqDate: grantModel.grantReqDate ? new Date(grantModel.grantReqDate) : null,
				grantReportingYear: grantModel.grantReportingYear,
				grantAmount: grantModel.grantAmount,
				amountRaised: grantModel.amountRaised,
				amountRequested: grantModel.amountRequested,
				grcAmount: grantModel.grcAmount,
				goAmount: grantModel.goAmount
			}
		}
	}
}

export const grantModelDefaultColumns: IGridColumn[] = [
	{
		property: 'grantDesc',
		type: 'string',
		width: 200,
		allowFilters: true,
		title: 'Description',
		render: DefaultGridCellDisplay
	},
	{
		property: 'grantType',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'Type',
		render: DefaultGridCellDisplay
	},
	{
		property: 'grantStatus',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'Status',
		render: DefaultGridCellDisplay
	},
	{
		property: 'grid_actions',
		type: 'actions',
		width: 70,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	},
	{
		property: 'grantReqDate',
		type: 'date',
		width: 153,
		allowFilters: true,
		title: 'Req. Date',
		render: DefaultGridCellDisplay
	},
	{
		property: 'grantReportingYear',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'Reporting Year',
		render: DefaultGridCellDisplay
	},
	{
		property: 'grantAmount',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'Approved Amount',
		render: CurrencyDisplay,
	},
	{
		property: 'amountRequested',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'Requested Amount',
		render: CurrencyDisplay
	},
	{
		property: 'grcAmount',
		type: 'number',
		width: 153,
		allowFilters: true,
		hide: true,
		title: 'GRC Amount',
		render: DefaultGridCellDisplay
	},
	{
		property: 'goAmount',
		type: 'string',
		width: 153,
		allowFilters: true,
		hide: true,
		title: 'GTO Amount',
		render: DefaultGridCellDisplay
	},
]