import React, { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { FileField, TextareaField } from './forms'
import { IMinistryFileSummaryModelDocument, IMinistryFileModelDocument } from '../open-api'
import { AppActionContext } from '../app-store-provider'
import { Loading } from './loading'
import { IRS990_FILE_DESCRIPTION_ID, MAX_FILE_SIZE } from '../constants'
import moment from 'moment'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { FormikSelectField, ISelectFieldOption, ISelectFieldOptionGroup } from './forms/formik-select-field'

interface IDocumentFormProps {
	ministryFile?: IMinistryFileModelDocument
	ministryFiles: IMinistryFileSummaryModelDocument[]
	ministryId: number // The ministry the contact is for
	grantId?: number
	afterSave?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
}

export const DocumentForm = (props: IDocumentFormProps) => {
	const appActions = useContext(AppActionContext)!

	const { ministryFiles, ministryId, afterSave, ministryFile, grantId } = props

	const [fileTypes, setFileTypes] = useState<ISelectFieldOptionGroup[]>()
	const [fiscalYearsFor990, setFiscalYearsFor990] = useState<ISelectFieldOption[]>([])

	useEffect(() => {
		appActions.MinistryFilesApi.apiMinistryFilesFileDescriptionsGet()
			.then(results => {
				const optionGroups: ISelectFieldOptionGroup[] = []

				results.data.forEach(fileType => {
					const existingOptionGroup = optionGroups.find(optionGroup => optionGroup.id === `${fileType.fileTypeId}`)
					if (existingOptionGroup) {
						existingOptionGroup.options.push({ label: `${fileType.name}`, value: `${fileType.id}`, isDisabled: !fileType.isActive })
					} else {
						optionGroups.push({
							id: `${fileType.fileTypeId}`,
							label: fileType.fileTypeName || '',
							options: [{ label: `${fileType.name}`, value: `${fileType.id}`, isDisabled: !fileType.isActive }]
						})
					}
				})

				setFileTypes(optionGroups)
			})

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		/* 
				Allow user to upload an irs990 for fiscal year they have not already uploaded, going back to 1998
				(see MIF.Ministry.Web\Controllers\AccountController.cs line 928)
				var irs990sFiscalYears = ministry.MinistryFiles.Where(x => x.FileDescId == FileDescription.IRS990.Id).Select(x => x.FiscalYear);            
		*/
		const options: ISelectFieldOption[] = []
		const irs990sFiscalYears = ministryFiles.filter(file => file.fileDescId === IRS990_FILE_DESCRIPTION_ID).map(file => file.fiscalYear)
		let year = moment().year()
		for (let i = 1998; i < year; i++) {
			if (!irs990sFiscalYears.includes(i)) {
				let yearOption = i.toString()
				options.push({ value: yearOption, label: yearOption })
			}
		}
		setFiscalYearsFor990(options)

	}, [ministryFiles])

	let resetForm: () => void
	useEffect(() => {
		if (resetForm) resetForm()
		// eslint-disable-next-line
	}, [ministryFile])

	// Wait until we have the FileTypes before rendering the form.
	if (!fileTypes) return <Loading />

	return (
		<Formik
			initialValues={{
				file: '',
				fileDescId: ministryFile?.fileDescId?.toString() || '',
				fileDescription: ministryFile?.fileDesc || '',
				fiscalYear: ministryFile?.fiscalYear?.toString() || '',
			}}
			validationSchema={Yup.object({
				file: Yup.mixed()
					.test(
						'required',
						'Required',
						function (value) {
							if (ministryFile) return true
							return !!value
						}
					)
					.test(
						'allowedExtensions',
						'Please only upload files with the following extensions: .pdf, .docx, .doc, .xlsx, .xls, .txt, .pptx.',
						function (value) {
							if (ministryFile && !value) return true
							return value instanceof File && RegExp(/(\.pdf|\.docx|\.doc|\.xlsx|\.xls|\.txt|\.pptx)$/i).test(value.name)
						}
					)
					.test(
						'maxFileSize',
						`Your file is too large (max ${MAX_FILE_SIZE / 1024 / 1024} MB). Reduce the file size by scanning only a few key pages, or use a tool like https://smallpdf.com/compress-pdf`,
						function (value) {
							if (ministryFile && !value) return true
							return value instanceof File && value.size <= MAX_FILE_SIZE
						}
					),
				fileDescId: Yup.mixed()
					.test(
						'required',
						'Required',
						function (value) {
							if (ministryFile) return true
							return !!value
						}
					),
				fileDescription: Yup.string().notRequired(),
				fiscalYear: Yup.string()
					.test(
						'990FiscalYear',
						'Required',
						function (value) {
							if (this.parent.fileDescId === `${IRS990_FILE_DESCRIPTION_ID}`) return !!value
							return true
						}
					)
			})}
			onSubmit={async (values, formikActions) => {
				const taskId = registerLoadingTask()

				if (ministryFile) {
					await appActions.MinistryFilesApi.apiMinistryFilesIdPut(ministryFile.ministryFileGuid, { fileDesc: values.fileDescription })
				} else {
					await appActions.MinistryFilesApi.apiMinistryFilesPost(ministryId, parseInt(values.fileDescId), values.file, grantId, values.fileDescription, values.fileDescId === `${IRS990_FILE_DESCRIPTION_ID}` ? parseInt(values.fiscalYear) : undefined)
				}

				formikActions.resetForm()
				deregisterLoadingTask(taskId)
				if (afterSave) afterSave()
			}}
		>
			{formikProps => {
				resetForm = formikProps.resetForm
				return (
					<Form>
						<FileField disabled={!!ministryFile} fileName={ministryFile?.fileName} fieldProps={{ name: 'file', label: 'Choose a File' }} accept='.doc,.docx,.pdf,.xls,.xlsx, pptx,.txt,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.msword,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain' />

						<FormikSelectField field={{ name: 'fileDescId', label: 'File Type', placeholder: 'Select a file type', disabled: !!ministryFile }} optionGroups={fileTypes} />

						{formikProps.values.fileDescId === `${IRS990_FILE_DESCRIPTION_ID}` ?
							<FormikSelectField field={{ name: 'fiscalYear', label: 'Fiscal Year', placeholder: 'Select 990 fiscal year', disabled: !!ministryFile }} options={fiscalYearsFor990} />
							:
							null
						}

						<TextareaField fieldProps={{ name: 'fileDescription', label: 'File Description' }} />

						<div className='row px-3'>
							<button className={`btn btn-primary col`} type='submit'>Save</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}