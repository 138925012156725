import React, { useEffect } from 'react'
import { buildTooltipProps, PopperPlacementPosition, uuidv4, enableTooltip, destroyTooltip, showModal, hideModal } from '../services/helpers'
import { ReactComponent as XSquareIcon } from '../assets/x-square.svg'
import { ReactComponent as XSquareFillIcon } from '../assets/x-square-fill.svg'
import { IApprovalStatusDocument, IMinistrySummaryResponseModelDocument } from '../open-api'
import { UserModel } from '../models/user'
import { MinistryModel } from '../models/ministry'
import { Modal } from './modal'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { IAppState } from '../stores/app-definitions'
import { IMifActions } from '../stores/app-actions'

/* 20220617 TB - Karla requested we move the Approve/Deny Page buttons to the top of each profile page. Because of this, we are no longer using the
	getMinistryInfoProfileContactsAndDocumentsGridFooterActions partial.*/

interface ITooltipInfoProps {
	tooltipText: string
	placement?: PopperPlacementPosition
	className?: string
	style?: React.CSSProperties
}
export const TooltipInfo = (props: ITooltipInfoProps) => {
	const { tooltipText, placement, className, style } = props

	const id = uuidv4()

	useEffect(() => {
		enableTooltip(id)

		return function cleanup() {
			destroyTooltip(id)
		}

		//eslint-disable-next-line
	}, [])

	return (
		<svg style={style} id={id} {...buildTooltipProps({ tooltipText, placement, html: true })} className={`bi bi-info-circle cursor-pointer primary-color-hover ${className}`} width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd" />
			<path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
			<circle cx="8" cy="4.5" r="1" />
		</svg>
	)
}

interface ISquareDeleteIconProps {
	onClick?: (...any: any) => void | Promise<void>
}
export const SquareDeleteIcon = (props: ISquareDeleteIconProps) => {
	const { onClick } = props

	return (
		<div onClick={onClick} className='hover-toggle d-inline'>
			<XSquareFillIcon className='text-danger show-when-hovered' />
			<XSquareIcon className='hide-when-hovered' />
		</div>
	)
}

interface IGetMinistryInfoProfileContactsAndDocumentsGridFooterActions {
	pageCompletionStatusId: number | null
	pageApprovalStatus?: IApprovalStatusDocument
	ministry: IMinistrySummaryResponseModelDocument
	pageId: number
	appState: IAppState
	appActions: IMifActions
	pageApproved: () => Promise<void>
	pageDenied: () => Promise<void>
}
export const getMinistryInfoProfileContactsAndDocumentsGridFooterActions = (options: IGetMinistryInfoProfileContactsAndDocumentsGridFooterActions): JSX.Element[] => {
	const { pageCompletionStatusId, pageApprovalStatus, ministry, pageId, appState, appActions, pageApproved, pageDenied } = options
	/*
		Admin\modules\MinistryInfo\New\profile.js 2176
		toggle 'Approve Page' / 'Deny Page' disabled states
	*/
	let _enablePageApprove = false
	let _enablePageDeny = false

	switch (pageCompletionStatusId) {
		case -1:
			_enablePageApprove = true
			_enablePageDeny = false
			break
		case 1:
			_enablePageApprove = true
			_enablePageDeny = true
			break
		case 2:
			_enablePageApprove = false
			_enablePageDeny = true
			break
		default:
			_enablePageApprove = UserModel.userIsSuperUser(appState.currentUser)
			break
	}

	if (MinistryModel.isInternational(ministry)) _enablePageApprove = false

	const footerActions: JSX.Element[] = []
	if (_enablePageApprove) footerActions.push(
		<div key='_enablePageApprove' >
			<button type='button' className='btn btn-light ml-1' onClick={() => showModal('_enablePageApprove-confirm')}>Approve Page</button>
			<Modal
				modalId='_enablePageApprove-confirm'
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-primary" onClick={async () => {
							const taskId = registerLoadingTask()
							hideModal('_enablePageApprove-confirm')
							await appActions.MinistryProfileApi.apiMinistryProfileIdApproveProfilePagePost(ministry.ministryId, { pageId: pageId })
							await pageApproved()
							deregisterLoadingTask(taskId)
						}}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to approve this page?
			</Modal>
		</div>
	)
	if (_enablePageDeny) footerActions.push(
		<div key='_enablePageDeny'>
			<button type='button' className='btn btn-light ml-1' onClick={() => showModal('_enablePageDeny-confirm')}>Deny Page</button>
			<Modal
				modalId='_enablePageDeny-confirm'
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-primary" onClick={async () => {
							const taskId = registerLoadingTask()
							hideModal('_enablePageDeny-confirm')
							await appActions.MinistryProfileApi.apiMinistryProfileIdDenyProfilePagePost(ministry.ministryId, { pageId: pageId })
							await pageDenied()
							deregisterLoadingTask(taskId)
						}}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to unapprove this page?
			</Modal>
		</div>
	)

	if (pageApprovalStatus && _enablePageDeny) {
		footerActions.push(<i key='_pageApprovalStatus' className='ml-1 d-flex align-items-center'>{pageApprovalStatus.pageName} approved {pageApprovalStatus.completeInfo}</i>)
	}

	return footerActions
}