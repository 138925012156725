import React, { useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { IDefaultProps } from "./component-definitions"
import { BriefcaseIcon, InfoCircleIcon, PencilIcon } from '../assets/index'
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IConsultingApptDocument, IConsultingAreaDirectorDocument, IConsultingSearchResultsModelDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, GridUserInteractionStateKey, IGridAction, IGridListItem, IGridRowAction, IGridState } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { DefaultGridCellDisplay, GridActionCell, GridListItemObjectDisplay } from "./grid-cell-displays"
import { SquareDeleteIcon } from "./partials"
import { ConsultingManagementModel } from '../models/consulting-management-model'
import { UserModel } from "../models/user"
import { Grid } from "./grid"
import { IFilter } from "../stores/api-actions"
import { Modal } from "./modal"
import { ConsultingAppointmentForm } from './consulting-appointment-form'
import dayjs from "dayjs"
import { ISelectFieldOption, isSelectFieldOption } from "./forms"
import { locationHashToObject, uuidv4 } from "../services/helpers"
import { ConsultingMyCalendar } from './consulting-my-calendar'
import { useLocation } from "@reach/router"

export enum Mode {
    'recentHistory' = 'recentHistory',
    'olderHistory' = 'olderHistory',
    'upcoming' = 'upcoming'
}

export interface IConsultingManagementListDataType {
    id: number
    name: string
}

interface IConsultingManagementState {
    meetingTypes: Array<IConsultingManagementListDataType>
    appointmentTypes: Array<IConsultingManagementListDataType>
    consultingTopics: Array<IConsultingManagementListDataType>
    maConsultingTopics: Array<IConsultingManagementListDataType>
    trainingTopics: Array<IConsultingManagementListDataType>
    areaDirectors: IConsultingAreaDirectorDocument[]
    mode: Mode,
    appointments: IConsultingSearchResultsModelDocument[]
}

interface IConsultingManagementProps extends IDefaultProps { }
export const ConsultingManagement = ({ }: IConsultingManagementProps) => {

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IConsultingManagementState>({
        meetingTypes: [],
        appointmentTypes: [],
        consultingTopics: [],
        maConsultingTopics: [],
        trainingTopics: [],
        areaDirectors: [],
        mode: Mode.upcoming,
        appointments: []
    })

    const dataSource: GridDataFetch<IConsultingManagementState> = async (queryState, _consultingManagementState) => {

        try {
            if (!_consultingManagementState?.meetingTypes) return { count: 0, rows: [] }

            if (_consultingManagementState.mode === Mode.olderHistory && !appState.globalCommunityContext) return { count: 0, rows: [] }

            const _filters = queryState.filters || []
            const _historyFilter = _filters.find(o => o.id === historyFilter.id)
            const _historyFilter2 = _filters.find(o => o.id === historyFilter2.id)

            if (_historyFilter2) {
                _historyFilter2.enabled = false
            }

            if (_historyFilter) {
                if (_consultingManagementState.mode === Mode.upcoming) {
                    _historyFilter.operator = 'gt'
                    _historyFilter.value = dayjs().add(-1, 'day').format('MM/DD/YYYY')
                } else if (_consultingManagementState.mode === Mode.recentHistory) {
                    console.log('Recent History')
                    // Recent is everything in the last two calendar years
                    _historyFilter.operator = 'gt'
                    _historyFilter.value = dayjs('12/31/' + dayjs().subtract(2, 'years').get('y')).format('MM/DD/YYYY')

                    if (_historyFilter2) {
                        _historyFilter2.enabled = true
                        _historyFilter2.operator = 'lt'
                        _historyFilter2.value = dayjs().format('MM/DD/YYYY')
                    } else {
                        _filters.push(historyFilter2)
                    }
                    
                } else if (_consultingManagementState.mode === Mode.olderHistory) {
                    console.log('Older History')
                    console.log('olderHistory>_historyFilter', _historyFilter)
                    _historyFilter.operator = 'lt'
                    _historyFilter.value = dayjs('12/31/' + dayjs().subtract(2, 'years').get('y')).format('MM/DD/YYYY')
                    
                    console.log('olderHistory>_historyFilter2', _historyFilter2)
                }
            }

            // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
            const filters = (_filters ? JSON.stringify([..._filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as ISearchRequestFilterDocument[] | undefined
            console.log({filters})
            const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

            const itemsPerPage = _consultingManagementState.mode === Mode.olderHistory ? queryState.itemsPerPage : 10000;

            const queryResult = await makeHttpRequestWithUi({
                request: appActions.ConsultingApi.apiConsultingGet(
                    (queryState.page - 1) * itemsPerPage,
                    itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the unsubscribe list.'
            })

            const rows = (queryResult.data.data || []).map(item => ConsultingManagementModel.toGridListItem(item, _consultingManagementState?.meetingTypes || [], _consultingManagementState.mode))

            return {
                rows,
                count: queryResult.data.totalCount
            }
        } catch (e) {
            console.error('Error while trying to retrieve data', e)
            return {
                count: 0,
                rows: []
            }
        }
    }

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [appointmentFormModal, showHideSessionFormModal] = useModal()
    const [confirmDeleteModal, showHideConfirmDeleteModal] = useModal()
    const [appointmentToEdit, setAppointmentToEdit] = useState<IConsultingApptDocument>()
    const [selectedShowHistoryValue, setSelectedShowHistoryValue] = useState<string>(Mode.upcoming)
    const [helpModal, showHideHelpModal] = useModal()

    const rowActions: { [id: string]: IGridRowAction } = {
    }

    const editSession = async (consultingEventGroupId: string) => {
        const sessionToEditQuery = await makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdGet(parseInt(consultingEventGroupId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the session to edit.'
        })

        setAppointmentToEdit(sessionToEditQuery.data)

        showHideSessionFormModal(true)
    }

    if (UserModel.checkPermissionLevelForUser(6, 2, appState.currentUser)) {
        rowActions.editSession = {
            id: 'editSession',
            action: async ({ row }) => {
                setSelectedRow(row)
                editSession(row.id)
            },
            tooltipText: 'View Appointment',
            icon: <PencilIcon />,
        }
        rowActions.deleteSession = {
            id: 'deleteSession',
            action: async ({ row }) => {
                setSelectedRow(row)
                showHideConfirmDeleteModal(true)
            },
            tooltipText: 'Delete Appointment',
            icon: <SquareDeleteIcon />,
        }
    }

    const historyFilter: IFilter = {
        id: 'history-filter',
        isDateFilter: true,
        value: dayjs().add(-1, 'day').format('MM/DD/YYYY'),
        operator: 'gt',
        property: 'startDateTime',
        enabled: true,
    }

    const historyFilter2: IFilter = {
        id: 'history-filter2',
        isDateFilter: true,
        value: dayjs().format('MM/DD/YYYY'),
        operator: 'lt',
        property: 'startDateTime',
        enabled: false,
    }

    const timeRangeGridAction = {
        id: 'timeRangeGridAction',
        label: '',
        dropdown: {
            options: [
                { label: 'Show Upcoming', value: Mode.upcoming },
                { label: 'Show Recent History', value: Mode.recentHistory },
                { label: 'Show Older History', value: Mode.olderHistory },
            ],
            onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => isSelectFieldOption(option) && setState(_state => ({
                ..._state,
                // @ts-ignore
                mode: Mode[option.value],
            })),
            value: state.mode,
        },
        sortOrder: 2,
    }

    const [calendarModal, showHideCalendarModal] = useModal()
    const calendarModalAction: IGridAction = {
        id: 'calendarModal',
        label: 'My Weekly View',
        onClick: () => {
            if (appState.globalCommunityContext) {
                showHideCalendarModal(true)
            } else {
                appActions.addAlert({
                    id: uuidv4(),
                    title: 'No Community Selected',
                    body: 'Please select a community from the Global Community context to view the calendar for that community.',
                })
            }
        },
        sortOrder: 1,
    }
    useEffect(() => {
        if (appState.globalCommunityContext) {
            gridActions.updateGridAction({
                ...calendarModalAction,
                onClick: () => showHideCalendarModal(true)
            })
        } else {
            gridActions.updateGridAction({
                ...calendarModalAction,
                onClick: () => appActions.addAlert({
                    id: uuidv4(),
                    title: 'No Community Selected',
                    body: 'Please select a community from the Global Community context to view the calendar for that community.',
                })
            })
        }
    }, [appState.globalCommunityContext])

    // Support linking to a particular appt by appending "#view=EVENT_ID" to /consulting URL
    const location = useLocation()
    useEffect(() => {
        const parsedHash = locationHashToObject(location.hash)
        if (parsedHash.view) editSession(parsedHash.view)
    }, [location.hash])

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.ConsultingManagement,
        overrideInitialStateValues: {
            queryState: {
                ...defaultGridState.queryState,
                filters: [historyFilter, historyFilter2],
            },
        },
        gridActions: [
            {
                id: 'createSession',
                label: 'Create Appointment',
                onClick: () => showHideSessionFormModal(true),
                sortOrder: 0,
            },
            calendarModalAction,
            timeRangeGridAction,
        ],
        rowActions,
        columns: [
            // {
            //     property: 'cycle',
            //     type: 'string',
            //     width: 150,
            //     allowFilters: true,
            //     title: 'Cycle',
            //     render: DefaultGridCellDisplay,
            // },
            {
                property: 'consultingEventGroupId',
                type: 'number',
                width: 80,
                allowFilters: true,
                title: 'Appt ID',
                description: 'The Consulting Event Group ID.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true,
                rowGroupTotalUnique: true,
                rowGroupSuffix: ' Appts'
            },
            {
                property: 'startDateTime',
                type: 'date',
                width: 150,
                allowFilters: true,
                title: 'Date',
                description: 'The date of the event.',
                render: DefaultGridCellDisplay,
                dateFormatOverride: 'MM/DD/YYYY hh:mm a',
            },
            // {
            //     property: 'duration',
            //     type: 'number',
            //     width: 120,
            //     allowFilters: true,
            //     title: 'Duration Hrs',
            //     description: 'The length (in hours) of the event.',
            //     render: DefaultGridCellDisplay,
            // },
            //  {
            //     property: 'attendees',
            //     type: 'number',
            //     width: 120,
            //     allowFilters: true,
            //     title: 'Attendees',
            //     description: 'The number of people who attended the session.',
            //     render: DefaultGridCellDisplay,
            // },
            {
                property: 'ministryName',
                type: 'string',
                width: 270,
                allowFilters: true,
                title: 'Ministry Name',
                description: 'The number of people who attended the session.',
                render: (col, row) => <a href={`/ministry-info/${row.values.ministryID}`}>{row.values[col.property]}</a>,
                rowGroupTotal: true,
                rowGroupTotalUnique: true,
                rowGroupSuffix: ' Unique Ministries'
            },
            {
                property: 'presenterName',
                type: 'string',
                width: 180,
                allowFilters: false,
                disableSort: true,
                title: 'Presenter',
                description: 'The name of the person who presented. May be different than the Area Director.',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'registrantNames',
                type: 'string',
                width: 180,
                allowFilters: false,
                disableSort: true,
                title: 'Registrant Names',
                description: 'The names of those who have registered for this appointment.',
                render: GridListItemObjectDisplay,
            },
            {
                property: 'branchAbbr',
                type: 'string',
                width: 115,
                allowFilters: true,
                title: 'Community',
                description: 'The community of the Area Director who held the appointment.',
                render: DefaultGridCellDisplay,
                filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: `${b.branchAbbr}` })),
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 85,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },
            {
                property: 'meetingTypeId',
                type: 'string',
                width: 120,
                allowFilters: true,
                title: 'Meeting Type',
                description: 'Whether this appointment is by phone or in person.',
                render: DefaultGridCellDisplay,
            },
            // {
            //     property: 'groupMeeting',
            //     type: 'boolean',
            //     width: 120,
            //     allowFilters: true,
            //     title: 'Group Meeting',
            //     description: 'Whether or not this is a group appointment.',
            //     render: (col, row) => <span>{row.values[col.property] === true ? 'Yes' : 'No'}</span>,
            // },
            {
                property: 'appointmentType',
                type: 'string',
                width: 155,
                allowFilters: true,
                title: 'Appointment Type',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'durationHours',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Hours',
                description: 'Hours logged for this appointment.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true
            },
            {
                property: 'registrantCount',
                type: 'number',
                width: 100,
                allowFilters: false,
                title: 'Registered',
                description: 'The number of contacts registered.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true
            },
            {
                property: 'attendeeCount',
                type: 'number',
                width: 100,
                allowFilters: false,
                title: 'Attended',
                description: 'The number of contacts who attended.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true
            },
            {
                property: 'noteFromMinistry',
                type: 'string',
                width: 350,
                allowFilters: true,
                title: 'Note From Ministry',
                description: 'Note entered by the Ministry while registering via the Ministry Portal.',
                render: DefaultGridCellDisplay,
                tooltip: (row) => typeof row.values.noteFromMinistry === 'string' ? row.values.noteFromMinistry : undefined
            },
            {
                property: 'eventDetails',
                type: 'string',
                width: 350,
                allowFilters: true,
                title: 'Note to Ministry',
                description: 'Note entered by the Area Director. These notes will appear in the reminder email.',
                render: DefaultGridCellDisplay,
                tooltip: (row) => typeof row.values.eventDetails === 'string' ? row.values.eventDetails : undefined
            },
            {
                property: 'afterEventNote',
                type: 'string',
                width: 350,
                allowFilters: true,
                title: 'After Event Note',
                description: 'Notes the AD entered after the event.',
                render: DefaultGridCellDisplay,
                hide: true,
                tooltip: (row) => typeof row.values.afterEventNote === 'string' ? row.values.afterEventNote : undefined
            },
            // {
            //     property: 'presenter',
            //     type: 'string',
            //     width: 120,
            //     allowFilters: true,
            //     title: 'Presenter',
            //     description: 'The presenter(s) for the event.',
            //     render: DefaultGridCellDisplay,
            // },
        ],
        dataSource,
        rowSelectEnabled: true,
        respectGlobalCommunityFilter: true,
        disableExport: false,
        disabledPagination: state.mode !== Mode.olderHistory,
        infinitePaging: false,
        usingLocalData: true,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            editSession(row.id)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, state)

    useEffect(() => {
        makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingListDataGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the consulting management list data.'
        })
            .then(results => {
                // @ts-ignore
                setState(_state => ({ ..._state, ...results.data }))
            })

        makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingAreaDirectorsGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of area directors.'
        })
            .then(results => {
                setState(_state => ({ ..._state, areaDirectors: results.data }))
            })
    }, [])

    // const isMeetingTypeFirstCheck = useRef(true)
    // useEffect(() => {
    //     console.log('useEffect > state.meetingTypes 1')
    //     if (isMeetingTypeFirstCheck.current) {
    //         isMeetingTypeFirstCheck.current = false
    //         return
    //     }

    //     console.log('useEffect > state.meetingTypes 2')
    //     if (state.meetingTypes.length) gridActions.doFetch()
    // }, [state.meetingTypes])

    useEffect(() => {
        if (state) {
            const meetingTypeColumn = gridState.columns.find(c => c.property === 'meetingTypeId')
            if (meetingTypeColumn && state.meetingTypes.length && meetingTypeColumn.filterOptions?.length !== state.meetingTypes.length) {
                // Stupid hack to account for this column update seemingly getting overwritten by a deep race condition. Will be solved when we move to react-table
                gridActions.updateColumn({
                    ...meetingTypeColumn,
                    filterOptions: state?.meetingTypes?.map(o => ({ label: `${o.name}`, value: `${o.id}` }))
                })
            }
        }
    }, [state.meetingTypes, gridState.columns, gridActions.updateColumn])


    useEffect(() => {
        const appointmentTypeColumn = gridState.columns.find(c => c.property === 'appointmentType')
        if (appointmentTypeColumn && state?.appointmentTypes && state?.appointmentTypes.length > 0 && appointmentTypeColumn.filterOptions?.length !== state.appointmentTypes.length) {
            gridActions.updateColumn({
                ...appointmentTypeColumn,
                filterOptions: state.appointmentTypes.map(o => ({ label: `${o.name}`, value: `${o.name}` }))
            })
        }
    }, [state.appointmentTypes, gridState.columns, gridActions.updateColumn])

    const previousMode = useRef(state.mode)
    useEffect(() => {
        if (previousMode.current !== state.mode) {
            //fetchAppointments(state.mode)
            gridActions.updateGridAction({ ...timeRangeGridAction, dropdown: { ...timeRangeGridAction.dropdown, value: state.mode } })
            if (state.mode === Mode.olderHistory) {
                gridActions.toggleUsingLocalData(false)
            } else {
                gridActions.toggleUsingLocalData(true)
            }

            previousMode.current = state.mode

            gridActions.doFetch()
        }
        
    }, [state.mode])

    const getAppointmentTitle =  (appointment: IConsultingApptDocument) => {
        let title = `Edit Appointment`

        if (appointment.startDateTime) title += ` - ${dayjs(appointment.startDateTime?.toString()).format('M/D/YYYY')}`
        if (appointment.appointmentTypeId) title += ` - ${state.appointmentTypes.find(o => o.id.toString() === appointment.appointmentTypeId.toString())?.name}`
        if (appointment.meetingTypeId) title += ` - ${state.meetingTypes.find(o => o.id.toString() === appointment.meetingTypeId.toString())?.name}`
        title += ` (${appointment.consultingEventGroupId})`

        return title
    }

    return (
        <>
            <Helmet>
                <title>Appointment Management</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center justify-content-between'>
                    <div className='d-flex'><BriefcaseIcon style={{ width: '25px', height: '25px', marginTop: "5px" }} />
                    <h3 className='ml-2'>Coaching/Consulting Appointment Management</h3></div>
                    <div className='d-flex'><button type='button' className='btn btn-info btn-sm icon-right ml-1' onClick={() => showHideHelpModal(true)}>Help <InfoCircleIcon /></button></div>
                </div>

                {/* {(selectedShowHistoryValue === ShowHistoryValues.upcoming || 
                  (selectedShowHistoryValue === ShowHistoryValues.history && appState.globalCommunityContext)) ?  */}
                    <Grid state={gridState} actions={gridActions} />
                    {/* :
                    <div>
                        <p>Select a community.</p>
                    </div>
                } */}
            </div>

            <Modal
                {...appointmentFormModal}
                modalTitle={!!appointmentToEdit ? getAppointmentTitle(appointmentToEdit) : 'New Appointment'}
                size='xl'
                minHeightPercent={100}

                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setAppointmentToEdit(undefined)
                }}
            >
                {appointmentFormModal.show && state &&
                    <ConsultingAppointmentForm
                        appointmentToEdit={appointmentToEdit}
                        setAppointmentToEdit={setAppointmentToEdit}
                        meetingTypes={state.meetingTypes}
                        appointmentTypes={state.appointmentTypes}
                        consultingTopics={state.consultingTopics}
                        maConsultingTopics={state.maConsultingTopics}
                        trainingTopics={state.trainingTopics}
                        areaDirectors={state.areaDirectors}
                        onCancel={() => showHideSessionFormModal(false)}
                        afterSave={async () => {
                            gridActions.doFetch()
                            if (appointmentToEdit) editSession(appointmentToEdit.consultingEventGroupId.toString())
                        }}
                    />
                }
            </Modal>

            <Modal
                {...confirmDeleteModal}
                modalTitle='Confirm Delete'
                footer={
                    <React.Fragment>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmDeleteModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-danger'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdDelete(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully deleted consulting appointment.',
                                        toastErrorMessage: 'Encountered an error deleting consulting appointment.',
                                    })
                                    showHideConfirmDeleteModal(false)
                                    gridActions.doFetch()
                                }
                            }}
                        >
                            Delete
                        </button>
                    </React.Fragment>
                }
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
            >
                <p>Are you sure you want to delete this consulting appointment?</p>
                <p>This will remove the appointment from the ministry's attendance history and will completely remove the event from Admin.</p>
            </Modal>


            <Modal
                {...calendarModal}
                modalTitle='My Weekly View'
                size='fullscreen'
            >
                {calendarModal.show && <ConsultingMyCalendar />}
            </Modal>
            <Modal
				{...helpModal}
				modalTitle='Ministries Help'
				size={'lg'}
			>
				<h4>Who is eligible to register for an appointment via the Ministry Portal?</h4>
                <p>These are the business rules that govern who can and can't register for an appointment via the Ministry Portal:</p>
                <ul>
                    <li>If Teaching level account (regardless of status): Access approved but can only view appointments that are 30 minutes or less in length.</li>
                    <li>If Coaching or Consulting level account and status is SUB, GRC or OK: Access approved.</li>
                    <li>If Coaching or Consulting account and status is INC: Access denied.</li>
                </ul>
			</Modal>
        </>
    )
}
