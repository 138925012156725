import React, { useContext, useState } from 'react'
import { IDefaultProps } from './component-definitions'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { Checkbox } from './forms'
import { uuidv4 } from '../services/helpers'
import { Loading } from './loading'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { Modal } from './modal'
import { navigate } from '@reach/router'
import Helmet from 'react-helmet'
import { useAuth } from 'react-oidc-context';
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'

export const Settings = (props: IDefaultProps) => {
	const auth = useAuth()!
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const [settingsState, setSettingsState] = useState({
		slimTablesCheckboxId: uuidv4()
	})

	const [resetPreferencesConfirmModal, showHideResetPreferencesConfirmModal] = useModal()
	const [clearCacheConfirmModal, showHideClearCacheConfirmModal] = useModal()

	if (!appState.currentUser) return <Loading />

	const handleLogout = async () => {        
        
        auth.signoutRedirect({
			post_logout_redirect_uri:IDP_REDIRECT_URI
		});  
    }

	return (
		<>
			<Helmet>
				<title>User Settings</title>
			</Helmet>
			<div className='p-3'>
				<div className='d-flex justify-content-between pb-3 border-bottom'>
					<h3 className='text-dark'>{appState.currentUser?.fullName} ({appState.currentUser?.email})</h3>
					<button className='btn btn-secondary' onClick={() => handleLogout()}>Logout</button>
				</div>

				<div className='text-muted py-1'>
					<span>Settings for your GrowthTrack account.</span>
				</div>

				<div className='py-2'>
					<h3 className='text-dark col-6 p-0 pb-1'>Preferences</h3>
				</div>

				<Checkbox
					id={settingsState.slimTablesCheckboxId}
					label='Use compact tables'
					checked={appState.currentUserSessionState?.preferences?.slimTables}
					onChange={() => {
						makeHTTPRequestWithUi({
							request: appActions.UserSessionActions.toggleSlimTables(),
							toastSuccessMessage: 'Successfully changed compact tables preference setting.'
						})
					}}
				/>
				
				<p className='text-muted'><i>Reduces table font size and makes rows thinner, allowing more rows to be visible on the screen at once for a more "Excel"-like experience.</i></p>

				<hr className='mt-4' />

				<div className="card mt-4 mb-4">
					<div className="card-body">
						<h5 className="card-title">Reset Account Data</h5>
						<p className="card-text">This will reset all your saved favorites, settings, and saved table configurations (filters, columns, sorts).</p>
						<button className='btn btn-warning' onClick={() => showHideResetPreferencesConfirmModal(true)}>Reset Data</button>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<h5 className="card-title">Clear Browser Cache</h5>
						<p>This will clear GrowthTrack data that is cached in your browser's local storage. Includes the following:</p>
						<ul className='mb-3'>
							<li>Communities List</li>
							<li>Community Types</li>
							<li>Community Regions</li>
							<li>NTEE Codes</li>
							<li>Countries</li>
							<li>Involvement Multipliers</li>
							<li>Mail Types</li>
						</ul>
						<button className='btn btn-warning' onClick={() => showHideClearCacheConfirmModal(true)}>Clear Cache</button>
					</div>
				</div>

				<Modal
					{...resetPreferencesConfirmModal}
					modalTitle='Are you sure?'
					footer={
						<React.Fragment>
							<button className='btn btn-secondary' onClick={() => showHideResetPreferencesConfirmModal(false)}>Cancel</button>
							<button className='btn btn-danger' onClick={async () => {
								await appActions.UserSessionActions.clearUserSessionState()
								showHideResetPreferencesConfirmModal(false)
							}}>RESET</button>
						</React.Fragment>
					}
				>
					<p>Are you sure you want to reset your favorites, settings, and saved table configurations (filters, columns, sorts)?</p>
					<p className='bold text-danger'>This action cannot be reversed.</p>
				</Modal>

				<Modal
					{...clearCacheConfirmModal}
					modalTitle='Are you sure?'
					footer={
						<React.Fragment>
							<button className='btn btn-secondary' onClick={() => showHideClearCacheConfirmModal(false)}>Cancel</button>
							<button className='btn btn-danger' onClick={async () => {
								await appActions.clearAllCaches()
								showHideClearCacheConfirmModal(false)
							}}>RESET</button>
						</React.Fragment>
					}
				>
					<p>Are you sure you want to clear your browser's cache?</p>
				</Modal>
			</div>
		</>
	)
}