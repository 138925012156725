import { useContext, useEffect, useState } from "react"
import { Popup } from "./popup"
import clsx from 'clsx'
import { ChevronDownIcon } from "../assets"
import { IEventFilterFieldsDataModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { CheckboxField, ISelectFieldOption, SelectField, ToggleField } from "./forms"
import { TooltipInfo } from "./partials"
import { FormikProps } from "formik"
import { IFilter } from "../stores/api-actions"

interface IMapEventsPopperProps {
	formikProps: FormikProps<any>
}

export const MapEventsPopper = (props: IMapEventsPopperProps) => {
    const { formikProps } = props
    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)

    const [open, setIsOpen] = useState(false)
    const [filterByEventsOptions, setFilterByEventsOptions] = useState<IEventFilterFieldsDataModelDocument>()
    const [filterByEventsSelectOptions, setFilterByEventsSelectOptions] = useState<ISelectFieldOption[]>()
    const [filterByConsultingSelectOptions, setFilterByConsultingSelectOptions] = useState<ISelectFieldOption[]>()
    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()


    const fetchEvents = async () => {
      const request = await makeHTTPRequestWithUi({ request: appActions.MinistriesApi.apiMinistriesGetEventFilterFieldsDataGet(((appState.globalCommunityContext && appState.globalCommunityContext.branchId) || undefined)), disableSuccessToast: true, toastErrorMessage: `There was an error retrieving 'Filter by Events' data.` })
      setFilterByEventsOptions(request.data)
    }

    useEffect(() => {
      if (filterByEventsOptions) {
        setFilterByEventsSelectOptions(filterByEventsOptions.events?.map(event => ({ value: `${event.eventContentId}`, label: event.title || '' })) || [])
        setFilterByConsultingSelectOptions(filterByEventsOptions.consultingQuarters?.map(event => ({ value: `${event.dateRange}`, label: `${event.title}` })) || [])
      }
    }, [filterByEventsOptions])

    useEffect(() => {
      if (!!open && !filterByEventsOptions) {
        fetchEvents()
      }
    }, [open])

    useEffect(() => {
      if (!!filterByEventsOptions) {
        fetchEvents()
      }
    }, [appState.globalCommunityContext?.branchId])

    return (
      <Popup
        trigger={
          <button
            className={
              'btn btn-outline-secondary' +
              clsx(formikProps.values?.eventsRegisteredFor.length > 0 || formikProps.values?.consultingRegisteredFor.length > 0 ? ' active' : ' ')
            }
            type='button'
          >Events <ChevronDownIcon />
          </button>
        }
        onToggle={setIsOpen}
        popupOptions={{
          placement: 'bottom-start',
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 5]
                  }
              }
          ]
        }}
      >
        <div
            className="p-3"
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '.25rem',
            width: '400px',
            overflow: 'auto',
            //maxHeight: '300px',
            overflowX: 'hidden'
          }}
        >
          {filterByEventsOptions ?
          <>
          <div className='border p-2 rounded'>
								<h5>Event Attendance</h5>
								<hr className='mb-2' />
								<SelectField
									fieldProps={{ name: 'eventsRegisteredFor', label: 'Registered for' }}
									options={filterByEventsSelectOptions}
									multiple={true}
								/>

								<ToggleField
									fieldProps={{ name: 'registeredForEverySelectedEvent', label: 'Registered for' }}
									trueText={<span><b>Every</b> selected event</span>}
									falseText={<span><b>Any</b> selected event</span>}
								/>

								<CheckboxField
									fieldProps={{ name: 'eventsOnlyIncludeMinistriesThatAttended', label: <span>Only show attendees <TooltipInfo style={{ paddingBottom: '2px' }} placement='top' tooltipText='Only show ministries that actually attended the event(s).' /></span> }}
								/>
								<div className='mt-3'></div>

								<SelectField
									fieldProps={{ name: 'eventsNotRegisteredFor', label: 'Not registered for' }}
									options={filterByEventsSelectOptions}
									multiple={true}
								/>
							</div>

							<div className='border p-2 rounded mt-2'>
								<h5>Consulting Attendance</h5>
								<hr className='mb-2' />

								<div className='d-flex'>
									<div style={{ flex: 1 }} className='mr-2'>
										<SelectField
											fieldProps={{ name: 'consultingRegisteredFor', label: 'Registered for' }}
											options={filterByConsultingSelectOptions}
											multiple={true}
										/>
									</div>
									<div style={{ flex: 1 }} className='ml-2'>
										<SelectField
											fieldProps={{ name: 'consultingNotRegisteredFor', label: 'Not registered for' }}
											options={filterByConsultingSelectOptions}
											multiple={true}
										/>
									</div>
								</div>

                <ToggleField
											fieldProps={{ name: 'registeredForEverySelectedConsulting', label: 'Registered for' }}
											trueText={<span><b>Every</b> selected quarter</span>}
											falseText={<span><b>Any</b> selected quarter</span>}
										/>

								<CheckboxField
									fieldProps={{ name: 'consultingOnlyIncludeMinistriesThatAttended', label: <span>Only show attendees <TooltipInfo style={{ paddingBottom: '2px' }} placement='top' tooltipText='Only show ministries that actually attended appointments in the selected quarter(s).' /></span> }}
								/>
							</div>
          </>
          : null }
        </div>
      </Popup>
    )
}