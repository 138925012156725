import { useField } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IEventEmailTemplateDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { MAIL_TYPE } from '../constants'

interface ICustomEmailBlastFormSelectLayout { 
    mailTypeId: number
}
export const CustomEmailBlastFormSelectLayout = (props: ICustomEmailBlastFormSelectLayout) => {
    const { mailTypeId } = props

    const appActions = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [field, meta, helpers] = useField('layoutId')

    const EMPTY_LAYOUT_ID = 11;
    const [templates, setTemplates] = useState<IEventEmailTemplateDocument[]>()
    const [templatesToDisplay, setTemplatesToDisplay] = useState<IEventEmailTemplateDocument[]>([])

    useEffect(() => {
        makeHttpRequestWithUi({
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving potential custom blast template layouts.',
            request: appActions.EventEmailTemplatesApi.apiEventEmailTemplatesGet()
        }).then(results => setTemplates(results.data))
    }, [])

    useEffect(() => {
        if (!templates) return
        
        if (mailTypeId == MAIL_TYPE['All MA Email'] || mailTypeId == MAIL_TYPE['All CE Email']) {
            // MA/CE should only be using the empty template right now
            setTemplatesToDisplay(templates.filter(x => x.layoutId == EMPTY_LAYOUT_ID))
        } else {
            setTemplatesToDisplay(templates)
        }
    }, [mailTypeId, templates])

    if (!templates) return null

    return (
        <div>
            <label>Choose Layout</label>
            <div className='d-flex'>
                {templatesToDisplay.map(template => (
                    <div key={template.layoutId} onClick={() => helpers.setValue(template.layoutId)} className={`p-2 ${field.value == template.layoutId ? 'bg-primary text-white' : 'primary-color-bg-hover'}`} style={{ cursor: 'pointer' }}>
                        <div style={{width: 150, height: 100, backgroundColor: 'gray'}}>{template.screenshotUrl && <img style={{objectFit: 'cover', width: '100%', height: '100%', backgroundColor: 'gray'}} src={template.screenshotUrl || ''} />}</div>
                        <div>{template.layoutName}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
