import React, { useContext, useState, useEffect } from 'react'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { IGridListItem, GridDataFetch, IGridState } from '../stores/grid-definitions'
import { uuidv4, filterGridListItems, showModal, hideModal, sortListBySorts } from '../services/helpers'
import { INotesDocument, INotesSummaryDocument } from '../open-api'
import { UserModel, App, UserPermissionLevel } from '../models/user'
import { NoteModel } from '../models/ministry-note'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { SquareDeleteIcon } from './partials'
import { gridReducer } from '../stores/grid-reducer'
import { useHTTPRequestUiWrapper, useModal, useNoteDefaultColumns } from '../services/hooks'
import { Grid } from './grid'
import { Modal } from './modal'
import { NoteForm } from './note-form'
import { IAppState } from '../stores/app-definitions'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { NOTE_TYPES } from '../constants'


interface IProspectDetailNotesProps {
	prospectId: number
}
export const ProspectDetailNotes = (props: IProspectDetailNotesProps) => {
	const { prospectId } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [newNoteModal, showHideNewNoteModal] = useModal()
	const [deleteNoteModalId] = useState(uuidv4())
	const [selectedNote, setSelectedNote] = useState<INotesDocument>()

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const fetchProspectNotes: GridDataFetch<IAppState> = async (queryState, _state) => {

		const notesQuery = await makeHttpRequestWithUi({
			request: appActions.NotesApi.apiProspectsIdNotesGet(prospectId),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was a problem fetching the notes for this prospect.'
		})

		let cleanedNotes: INotesSummaryDocument[] = [...notesQuery.data]

		// Make sure the user has permissions to see admin note types. If not, filter admin notes out.
		if (!UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Administrator, appState.currentUser)) {
			const adminNoteTypeId = NOTE_TYPES.Admin
			cleanedNotes = cleanedNotes.filter(item => item.noteTypeId !== adminNoteTypeId)
		}

		if (queryState.sorts) sortListBySorts(cleanedNotes, queryState.sorts)

		let rows = cleanedNotes.map(note => NoteModel.toGridListItem(note))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length }
	}

	useEffect(() => {
		if (selectedNote) showHideNewNoteModal(true)
		// eslint-disable-next-line
	}, [selectedNote])

	const initialGridState: IGridState = {
		...defaultGridState,
		rowSelectEnabled: false,
		columns: useNoteDefaultColumns(),
		disabledPagination: true,
		dataSource: fetchProspectNotes,
		gridActions: [
			{
				id: 'noteActions',
				label: 'Create Note',
				disabled: !UserModel.checkPermissionLevelForUser(App.NoteManager, UserPermissionLevel.Modify, appState.currentUser),
				onClick: () => showHideNewNoteModal(true)
			}
		],
		rowActions: {
			editNote: {
				id: 'editNote',
				action: async (options) => {
					options.e.stopPropagation()

					const taskId = registerLoadingTask()
					const noteQuery = await appActions.NotesApi.apiNotesIdGet(parseInt(options.row.id))
					deregisterLoadingTask(taskId)
					setSelectedNote(noteQuery.data)

				},
				icon: <div><PencilIcon /></div>,
				tooltipText: 'Edit note',
			},
			deleteNote: {
				id: 'deleteNote',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(deleteNoteModalId)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete Note',
			},
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			<Modal
				{...newNoteModal}
				modalTitle='Note'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setSelectedNote(undefined)
				}}
			>
				{newNoteModal.show ?
					<NoteForm
						prospectId={prospectId}
						note={selectedNote}
						afterSave={async () => {
							showHideNewNoteModal(false)
							const taskId = registerLoadingTask()
							gridActions.doFetch()
							deregisterLoadingTask(taskId)
						}}
					/>
					:
					null
				}

			</Modal>

			<Modal
				modalId={deleteNoteModalId}
				modalTitle='Confirm'
				onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<React.Fragment>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-danger" onClick={async () => {
							if (selectedRow) {
								const taskId = registerLoadingTask()
								await appActions.NotesApi.apiNotesIdDelete(parseInt(selectedRow.id))
								gridActions.doFetch()
								deregisterLoadingTask(taskId)
								hideModal(deleteNoteModalId)
							} else {
								alert('There was an error deleting this note. No note ID specified.')
							}
						}}>Delete</button>
					</React.Fragment>
				}
			>
				Are you sure you want to delete this note?

				This cannot be reversed!
			</Modal>
		</React.Fragment>
	)
}