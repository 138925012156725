import { IGridListItem } from '../stores/grid-definitions'
import { IMinistrySearchResultModelDocument, IMinistrySummaryResponseModelDocument, IProfilePageMinistryModelDocument } from '../open-api'
import moment from 'moment'

export enum MinistryAccountLevel {
	Teaching = 1,
	Coaching = 2,
	Consulting = 3,
}

export enum MinistryProfileStatus {
	OK = 'OK',
	GRC = 'GRC',
	PRE = 'PRE',
	SUB = 'SUB',
	INC = 'INC',
}


export class MinistryModel {

	public static isInternational = (ministry: IMinistrySummaryResponseModelDocument): boolean => {
		return ministry.regionId === 12
	}

	public static profileReviewComplete = (ministry: IMinistrySummaryResponseModelDocument): boolean => {
		/* 
			Admin\handlers\Partner.cfc 993
			(
				select case when partnerLevels.accountLevelID <= 2 then bLiteReviewComplete else bReviewComplete end
				from ministryData
				where ministryID=ministries.ministryID
				and bActive=1
			) as profileReviewComplete,
		*/

		if (ministry.levelID && ministry.levelID <= 2) {
			return ministry.bLiteReviewComplete === 1
		} else {
			return ministry.bReviewComplete === 1
		}
	}

	public static profileComplete = (ministry: IMinistrySummaryResponseModelDocument): boolean => {
		if (ministry.levelID && ministry.levelID <= 2) {
			return !!ministry.bLiteProfileComplete
		} else {
			return !!ministry.bProfileComplete
		}
	}

	public static ministryServiceAreaCountries = (ministry: IProfilePageMinistryModelDocument): string[] => {
		return ministry.ministryServiceAreas?.filter(area => !area.bState).map(area => area.regionId.toString()) || []
	}

	public static ministryServiceAreaStates = (ministry: IProfilePageMinistryModelDocument) => {
		return ministry.ministryServiceAreas?.filter(area => area.bState).map(area => area.regionId.toString()) || []
	}

	public static hasValidTaxId = (ministry: IMinistrySummaryResponseModelDocument): boolean => ministry.federalTaxNo !== '11-1111111' && ministry.federalTaxNo !== '77-7777777'

	public static toListItem = (ministry: IMinistrySearchResultModelDocument): IGridListItem => {
		return {
			id: ministry.ministryId.toString(),
			values: {
				ministryId: ministry.ministryId,
				ratingText: ministry.ratingText,
				startYear: ministry.startYear,
				numGrants: ministry.numGrants,
				ministryName: ministry.ministryName,
				grantEligible: ministry.grantEligible,
				legalName: ministry.legalName,
				federalTaxNo: ministry.federalTaxNo,
				branchAbbr: ministry.branchAbbr,
				localBranchAbbr: ministry.localBranchAbbr,
				trainingAreaAbbr: ministry.trainingAreaAbbr,
				tags: ministry.tags,
				firstName: ministry.firstName,
				lastName: ministry.lastName,
				physicalAddress1: ministry.physicalAddress1,
				physicalAddress2: ministry.physicalAddress2,
				physicalCity: ministry.physicalCity,
				physicalState: ministry.physicalState,
				physicalCountry: ministry.physicalCountry,
				physicalPostalCode: ministry.physicalPostalCode,
				profileCompleted: ministry.profileCompleted,
				wkAtt: ministry.wkAtt,
				servedLy: ministry.servedLy,
				dRegistered: moment(ministry.dRegistered).toDate(),
				profileDeadline: ministry.profileDeadline,
				levelId: ministry.levelId !== null ? MinistryAccountLevel[ministry.levelId] : null,
				profileStatus: ministry.profileStatus,
				attendInLast12Mos: ministry.attendInLast12Mos,
				attendConsultInLast12Mos: ministry.attendConsultInLast12Mos,
				nteeCodes: ministry.nteeCodes,
				grantEligibleReason: ministry.grantEligibleReason,
				serviceArea: ministry.serviceArea,
				organizationServe: ministry.organizationServe,
				primaryContactEmail: ministry.primaryContactEmail,
				primaryContactSubjectId: ministry.primaryContactSubjectId,
				businessPhone: ministry.businessPhone,
				cellPhone: ministry.cellPhone,
				phone1: ministry.phone1,
				phone1Ext: ministry.phone1Ext,
				eventsAttended: ministry.eventsAttended,
				fax: ministry.fax,
				siteUrl: ministry.siteUrl,
				dec_For_Christ: ministry.dec_For_Christ,
				involvement: ministry.involvement,
				involvementRolling12Mo: ministry.involvementRolling12Mo,
				bNoReporting: ministry.bNoReporting,
				bSensitiveMinistry: ministry.bSensitiveMinistry,
				bHideLeverageSummary: ministry.bHideLeverageSummary,
				bArchived: ministry.bArchived,
				dArchived: new Date(ministry.dArchived),
				impersonationUrl: ministry.impersonationUrl,
				isCurrent: ministry.isCurrent
			}
		}
	}
}

export interface IMinistryUpdateDocument {
	bArchived?: boolean | null
	bCharity?: null | boolean
	bSensitiveMinistry?: null | boolean
	bDeniedForGrants?: null | boolean
	bAllowChurch?: null | boolean
	bHideLeverageSummary?: null | boolean
	trainingAreaId?: null | number
	branchId?: null | number
	localBranchId?: null | number
	bShowInDirectory?: null | boolean
	bNoReporting?: null | boolean
	startYear?: null | number
	financialYearEndID?: null | number
	gtoNotes?: string
	legalName?: string
	federalTaxNo?: string
}