import { IProfilePageModelDocument, IProfilePageListModelDocument } from '../open-api'

export class MinistryInfoProfilePageModel {
    public static getTitle = (profilePageDocument: IProfilePageListModelDocument | IProfilePageModelDocument): string | null => {
        let pageTitle = profilePageDocument.pageTitle

        // Page Title override preserved from original ExtJS code
        // Admin/modules/MinistryInfo/New/profile.js line 1581
        if (pageTitle && pageTitle.toLowerCase().includes('fundraising')) {
            pageTitle = 'Fundraising'
        } else if (pageTitle && pageTitle.toLowerCase().includes('governance')) {
            pageTitle = 'Governance'
        } else if (pageTitle && pageTitle.toLowerCase().includes('purpose')) {
            pageTitle = 'Purpose'
        }

        return pageTitle
    }
}
