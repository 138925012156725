import { useContext, useState, useEffect } from "react"
import { AppActionContext } from "../app-store-provider"
import { IDefaultProps } from "./component-definitions"
import { Loading } from "./loading"

interface IGrantRequesAgreementPrintProps extends IDefaultProps {
	grantId?: string
}

export const GrantRequestAgreementPrint = (props: IGrantRequesAgreementPrintProps) => {
    const { grantId } = props
    const { GrantRequestApi } = useContext(AppActionContext)!
    const [grantAgreement, setGrantAgreement] = useState<string>()

    const getGrantAgreement = async () => {
        if (grantId) {
            const results = await GrantRequestApi.apiGrantRequestGrantIdGrantAgreementGet(parseInt(grantId))
            setGrantAgreement(results.data)
        }
    }

	useEffect(() => {
        getGrantAgreement()
    }, [])

    return grantAgreement ?
        <div dangerouslySetInnerHTML={{ __html: grantAgreement}}>
        </div>
        :
        <Loading />
}