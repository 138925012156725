import { Form, Formik, FormikProps } from 'formik'
import React, { useContext, useEffect } from 'react'
import { IMiBranchGrantGoalsDocument } from '../open-api'
import * as Yup from 'yup'
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { CurrencyField, NumberField, SelectField } from './forms'
import moment from 'moment'

interface ICommunityGrantGoalFormProps {
	branchId: number
	grantGoalToEdit?: IMiBranchGrantGoalsDocument
	onSaveSuccess?: () => void
}
export const CommunityGrantGoalForm = (props: ICommunityGrantGoalFormProps) => {
	const { grantGoalToEdit, branchId, onSaveSuccess } = props

	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const initialValues = {
		branchId: grantGoalToEdit?.branchId?.toString() || branchId.toString(),
		goalYear: grantGoalToEdit?.goalYear?.toString() || '',
		grantTotalGoal: grantGoalToEdit?.grantTotalGoal?.toString() || '',
		grantNumGoal: grantGoalToEdit?.grantNumGoal || '',
	}

	const validationSchema = Yup.object({
		branchId: Yup.string().required('Required'),
		goalYear: Yup.string().required('Required'),
		grantTotalGoal: Yup.string().required('Required'),
		grantNumGoal: Yup.string().required('Required'),
	})

	let _formikProps: FormikProps<any>

	useEffect(() => {
		if (_formikProps) _formikProps.resetForm()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [grantGoalToEdit])

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {

				const grantGoal = {
					branchId: parseInt(values.branchId),
					goalYear: parseInt(values.goalYear),
					grantTotalGoal: parseInt(values.grantTotalGoal),
					grantNumGoal: values.grantNumGoal,
					goalMonth: null,
					goalQuarter: null
				}

				if (!!grantGoalToEdit) {
					await makeHttpRequestWithUi({
						request: appActions.MiBranchGrantGoalsApi.apiMiBranchGrantGoalsIdPut(grantGoalToEdit.branchGrantGoalId, { ...grantGoalToEdit, ...grantGoal }),
						toastErrorMessage: 'Error updating grant target.',
						toastSuccessMessage: 'Grant target updated created.'
					})
				} else {
					await makeHttpRequestWithUi({
						request: appActions.MiBranchGrantGoalsApi.apiMiBranchGrantGoalsPost(grantGoal),
						toastErrorMessage: 'Error creating grant target.',
						toastSuccessMessage: 'Grant target successfully created.'
					})
				}

				if (onSaveSuccess) onSaveSuccess()
			}}
		>
			{formikProps => {
				_formikProps = formikProps
				return (
					<Form>
						<SelectField
							disabled={!!grantGoalToEdit}
							fieldProps={{ name: 'goalYear', label: 'Grant Year', placeholder: 'Select a year...' }}
							options={[
								{
									label: moment().format('YYYY'),
									value: moment().format('YYYY')
								},
								{
									label: moment().add(1, 'year').format('YYYY'),
									value: moment().add(1, 'year').format('YYYY')
								}
								,
								{
									label: moment().add(2, 'year').format('YYYY'),
									value: moment().add(2, 'year').format('YYYY')
								},
								{
									label: moment().add(3, 'year').format('YYYY'),
									value: moment().add(3, 'year').format('YYYY')
								},
								{
									label: moment().add(4, 'year').format('YYYY'),
									value: moment().add(4, 'year').format('YYYY')
								}
							]}
						/>

						<NumberField fieldProps={{ name: 'grantNumGoal', label: 'Number of Grants' }} noFormat={true} />
						<CurrencyField fieldProps={{ name: 'grantTotalGoal', label: 'Grant Total ($)' }} />

						<button type='submit' className='btn btn-primary'>Save</button>
					</Form>
				)
			}}
		</Formik>
	)
}