import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { TrainingAreaModel } from '../models/training-area'
import { IMiBranchModelDocument, IMiTrainingAreasModelDocument, IMiTrainingAreaSummaryModelDocument } from '../open-api'
import { sortListByProperty, sortListBySorts } from '../services/helpers'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridListItem, IGridRowAction, IGridState } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, GridActionCell } from './grid-cell-displays'
import { Loading } from './loading'
import { SquareDeleteIcon } from './partials'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { Grid } from './grid'
import { gridReducer } from '../stores/grid-reducer'
import { TrainingAreaForm } from './training-area-form'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { Modal } from './modal'
import { UserModel, UserPermissionLevel } from '../models/user'

interface ICommunityTrainingAreasProps {
	community: IMiBranchModelDocument
}
export const CommunityTrainingAreas = (props: ICommunityTrainingAreasProps) => {
	const { community } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [trainingAreas, setTrainingAreas] = useState<IMiTrainingAreasModelDocument[]>()

	const fetchTrainingAreas = async () => {
		const trainingAreaQuery = await makeHttpRequestWithUi({
			request: appActions.MiTrainingAreasApi.apiMiBranchesBranchIdTrainingAreasGet(community.branchId),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving training areas for this branch.'
		})

		setTrainingAreas(trainingAreaQuery.data)
	}

	useEffect(() => {
		fetchTrainingAreas()

		// eslint-disable-next-line
	}, [community])

	const dataSource: GridDataFetch<IMiTrainingAreasModelDocument[]> = async (queryState, _state) => {

		if (!_state) return { count: 0, rows: [] }

		let _trainingAreas = [...sortListByProperty(_state.filter(o => o.branchId === community.branchId && !!o.bActive), 'branchName')]

		if (queryState.sorts) sortListBySorts(_trainingAreas, queryState.sorts)
		let rows = _trainingAreas.map(TrainingAreaModel.toGridListItem)

		return {
			count: _trainingAreas.length,
			rows,
		}
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [trainingAreaFormModal, showHideTrainingAreaFormModal] = useModal()
	const [trainingAreaToEdit, setTrainingAreaToEdit] = useState<IMiTrainingAreaSummaryModelDocument>()
	const editTrainingArea = async (trainingAreaRow: IGridListItem) => {
		setSelectedRow(trainingAreaRow)
		showHideTrainingAreaFormModal(true)
		const trainingAreaToEditQuery = await makeHttpRequestWithUi({
			request: appActions.MiTrainingAreasApi.apiMiTrainingAreasIdGet(parseInt(trainingAreaRow.id)),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the training area to edit.'
		})

		setTrainingAreaToEdit(trainingAreaToEditQuery.data)
	}

	const rowActions: { [id: string]: IGridRowAction } = {
		editTrainingArea: {
			id: 'editTrainingArea',
			action: async (options) => {
				options.e.stopPropagation()
				editTrainingArea(options.row)
			},
			icon: <PencilIcon />,
			tooltipText: 'Training Area Editor'
		}
	}

	/* 
		 Only users with permission level 3 or higher for App 1 can delete training areas
		 Admin\modules\BranchAdmin\branchAdmin.js line 144
	*/
	const [deleteTrainingAreaModal, showHideDeleteTrainingAreaModal] = useModal()
	const [trainingAreaToDelete, setTrainingAreaToDelete] = useState<{ trainingAreaId: string | null, firstConfirm: boolean, secondConfirm: boolean, thirdConfirm: boolean }>({
		trainingAreaId: null,
		firstConfirm: true,
		secondConfirm: false,
		thirdConfirm: false,
	})
	if (UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Administrator, appState.currentUser)) {
		rowActions.deleteTrainingArea = {
			id: 'deleteTrainingArea',
			action: async (options) => {
				options.e.stopPropagation()
				setTrainingAreaToDelete({
					trainingAreaId: options.row.id,
					firstConfirm: true,
					secondConfirm: false,
					thirdConfirm: false,
				})
				showHideDeleteTrainingAreaModal(true)
			},
			icon: <SquareDeleteIcon />,
			tooltipText: 'Archive Training Area'
		}
	}



	const initialGridState: IGridState = {
		...defaultGridState,
		columns: [
			{
				property: 'trainingArea',
				type: 'string',
				width: 200,
				allowFilters: false,
				title: 'Training Area Name',
				render: DefaultGridCellDisplay,
			},
			{
				property: 'trainingAreaAbbr',
				type: 'string',
				width: 200,
				allowFilters: false,
				title: 'Abbreviation',
				render: DefaultGridCellDisplay,
			},
			{
				property: 'grid_actions',
				type: 'actions',
				width: 130,
				disableSort: true,
				title: 'Actions',
				render: GridActionCell,
				align: 'center'
			},
		],
		rowActions,
		dataSource,
		usingLocalData: true,
		disabledPagination: true,
		rowSelectEnabled: false,
		hideGridFooter: true,
		hideGridHeader: true,
	}

	const [state, actions] = useGrid(gridReducer, initialGridState, trainingAreas)

	useEffect(() => {
		actions.doFetch()
		// eslint-disable-next-line
	}, [trainingAreas])

	if (!trainingAreas) return <Loading />

	return (
		<div style={{ height: '100%' }} className='d-flex flex-column m-2'>
			<div className='my-3'>
				<button className='btn btn-primary' onClick={() => showHideTrainingAreaFormModal(true)}>Add Training Area</button>
			</div>

			<Grid actions={actions} state={state} style={{ flex: 1 }} />

			<Modal
				{...trainingAreaFormModal}
				modalTitle={`Create New Training Area`}
				size='fullscreen'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setTrainingAreaToEdit(undefined)
				}}
			>
				{trainingAreaFormModal.show ?
					<TrainingAreaForm
						branchId={community.branchId}
						trainingAreaToEdit={trainingAreaToEdit}
						onSaveSucces={() => {
							setSelectedRow(undefined)
							showHideTrainingAreaFormModal(false)
							fetchTrainingAreas()
						}}
					/>
					:
					null
				}
			</Modal>

			<Modal
				{...deleteTrainingAreaModal}
				modalTitle='Archive Training Area Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteTrainingAreaModal(false)}>Cancel</button>
						<button
							className={`btn ${trainingAreaToDelete.firstConfirm ? 'btn-warning' : 'btn-danger'}`}
							onClick={async () => {
								if (!trainingAreaToDelete.trainingAreaId) return
								if (trainingAreaToDelete.firstConfirm) setTrainingAreaToDelete({ ...trainingAreaToDelete, firstConfirm: false, secondConfirm: true })
								if (trainingAreaToDelete.secondConfirm) setTrainingAreaToDelete({ ...trainingAreaToDelete, firstConfirm: false, secondConfirm: false, thirdConfirm: true })
								if (trainingAreaToDelete.thirdConfirm) {
									await makeHttpRequestWithUi({
										request: appActions.MiTrainingAreasApi.apiMiTrainingAreasIdArchiveDelete(parseInt(trainingAreaToDelete.trainingAreaId)),
										toastSuccessMessage: 'Successfully archived the Training Area.',
										toastErrorMessage: 'There was an error archiving the Training Area.'
									})
									fetchTrainingAreas()
									showHideDeleteTrainingAreaModal(false)
								}
							}}
						>
							{trainingAreaToDelete.thirdConfirm ? 'ARCHIVE' : 'Archive'}
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setTrainingAreaToDelete({ trainingAreaId: null, firstConfirm: true, secondConfirm: false, thirdConfirm: false })}
			>
				{trainingAreaToDelete.firstConfirm ? 'Are you sure you want to archive this training area?' : null}
				{trainingAreaToDelete.secondConfirm ? 'Chapters associated with this training area will need to be modified, Are you sure you want to continue?' : null}
				{trainingAreaToDelete.thirdConfirm ?
					<div className='text-danger'><b>Last chance, are you certain?</b></div>
					:
					null
				}
			</Modal>
		</div>
	)
}