import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { FieldArray, Form, Formik } from "formik"
import { AppActionContext } from "../app-store-provider"
import { IEventSearchResultDocument, IMailBlastTaskResponseDocument, IStatsDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import * as yup from 'yup'
import dayjs from "dayjs"
import { EVENT_TYPES, SERVER_TIME_FORMAT } from "../constants"
import { DatePickerField, ISelectFieldOption, SelectField } from "./forms"
import { XSquareIcon, FileSpreadsheetIcon } from "../assets"
import { Modal } from "./modal"
import { EventNewsStats } from "./event-news-stats"

interface IEventInviteBlastsFormProps {
    eventContentSummary?: IEventSearchResultDocument
    branchId: number
    branchAbbr: string
    onCancel?: () => void
    afterSave?: () => void
}

interface IInviteBlastRow {
    sendDate: string
    sendTime: string
    sent: boolean
    mailBlastId?: number
    previouslyCreated?: boolean
}

export const EventInviteBlastsForm = (props: IEventInviteBlastsFormProps) => {
    const { eventContentSummary, branchId, branchAbbr, afterSave, onCancel } = props

    const eventContentId = eventContentSummary ? eventContentSummary.eventContentId : 0

    const { MailBlastTaskApi, MailBlastApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [statsModal, showHideStatsModal] = useModal()
    const [emailStats, setEmailStats] = useState<IStatsDocument>()
    
    const [inviteBlasts, setInviteBlasts] = useState<IMailBlastTaskResponseDocument[]>()
    const fetchInviteBlasts = useCallback(async () => {
        if (eventContentSummary)
        {
            const { data } = await makeHttpRequestWithUi({
                request: MailBlastTaskApi.apiMailBlastTaskEventContentEventContentIdGetEventInviteBlastsByCommunityBranchIdGet(eventContentId, branchId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching sites.'
            })
            console.log('data', data)
            setInviteBlasts(data)
        }
    }, [])

    useEffect(() => {
        fetchInviteBlasts()
    }, [])

    const inviteBlastSchema = useMemo(() => {
        return yup.object({
            sendDate: yup.string().required('Required')
                .test(
                    'sendDate',
                    'Cannot schedule an invite blast in the past.',
                    function (value) {
                        if (value && this.parent.sendTime && !this.parent.previouslyCreated) return dayjs(`${value} ${this.parent.sendTime}`, 'MM/DD/YYYY h:mm A').isAfter(dayjs())
                        return true
                    }
                ),
            sendTime: yup.string().required('Required'),
        })
    }, [])

    const initialValues = useMemo(() => {
        console.log('inviteBlasts', inviteBlasts)
        return {
            inviteBlasts: inviteBlasts?.map<IInviteBlastRow>(o => {
                return {
                    sent: Boolean(o.dSend),
                    sendDate: o.scheduleDate ? dayjs(o.scheduleDate).format('MM/DD/YYYY') : '',
                    sendTime: o.scheduleDate ? dayjs(o.scheduleDate).format('h:mm A') : '',
                    mailBlastId: o.mailBlastId,
                    previouslyCreated: true,
                }
            }) || []
        }
    }, [inviteBlasts])

    const timeOptionsHourly = useRef<ISelectFieldOption[]>((() => {
        const options: ISelectFieldOption[] = []
        for (let i = 0; i <= 23; i++) {
            const hours = i
            const minutes = 0
            const amPM = hours < 12 ? 'AM' : 'PM'
            let formattedHours = ''
            if (hours === 0)
                formattedHours = '12' // 12 AM
            else
                formattedHours = `${hours - (hours > 12 ? 12 : 0)}`

            const time = `${formattedHours}:${minutes === 0 ? '00' : minutes} ${amPM}`
            options.push({
                label: time,
                value: time,
            })
        }
        return options
    })())

    return (
        <>
            <div>
                <p>
                    Schedule one or more email invitation blasts for all <strong>{branchAbbr}</strong> events related to <strong>{eventContentSummary?.eventContentMonth + '/' + eventContentSummary?.eventContentYear + ' ' + eventContentSummary?.eventContentTitle + ' - ' + (eventContentSummary?.eventType || '') || ''}</strong>.
                </p>
                <ul>
                    { eventContentSummary?.eventTypeId === EVENT_TYPES.Webinar &&
                        <li>Sends an invite email blast to all ministry contacts and prospects (regardless of community) who have not registered for an event by the time the scheduled blast goes out. Includes ministries who have been archived for less than 2 years.</li> }
                    { eventContentSummary?.eventTypeId !== EVENT_TYPES.Webinar &&
                        <li>Sends an invite email blast to ministry contacts and prospects in this community who have not registered for an event by the time the scheduled blast goes out. Includes: 
                            <ul>
                                <li>Active ministries.</li>
                                <li>Ministries who have been archived for less than 2 years.</li>
                                <li>Ministries that do not belong to this community, but have this community's <i>Event Invite Include</i> tag.</li>
                                <li>Prospects tagged with <i>Event Invite Include</i>.</li>
                            </ul>
                        </li> }
                    <li>The blast's email body includes a list of all upcoming events related to this event group.</li>
                    <li>We recommend that at least one invite blast be sent <strong>14 days prior</strong> to your first event in this group. You can schedule additional invite blasts if you choose.</li>
                </ul>
            </div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    inviteBlasts: yup.array().of(inviteBlastSchema),
                })}
                onSubmit={async (values, actions) => {
                    if (values.inviteBlasts.length && eventContentSummary) {
                        await makeHttpRequestWithUi({
                            request: MailBlastTaskApi.apiMailBlastTaskEventContentEventContentIdScheduleEventInviteBlastPost(eventContentId, {
                                branchId,
                                sendAt: values.inviteBlasts.filter(o => !o.mailBlastId).map(o => dayjs(`${o.sendDate} ${o.sendTime}`, 'MM/DD/YYYY h:mm A').format(SERVER_TIME_FORMAT))
                            }),
                            disableSuccessToast: true,
                            toastErrorMessage: `Encountered an error scheduling invite blast${values.inviteBlasts.length === 1 ? '' : 's'}.`
                        })
                        fetchInviteBlasts()
                    }

                    if (afterSave) afterSave()
                }}
            >
                {formikProps =>
                    <Form className="d-flex flex-column mt-4">
                        <h4>Scheduled Blasts</h4>
                        <div className="mt-2">
                            <FieldArray
                                name='inviteBlasts'
                                children={arrayHelpers =>
                                    <>
                                        {formikProps.values.inviteBlasts.map((_, index) =>
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ flex: 1, maxWidth: 200 }} className='mr-1'>
                                                    <DatePickerField disabled={Boolean(formikProps.values.inviteBlasts[index].mailBlastId)} fieldProps={{ name: `inviteBlasts.${index}.sendDate`, label: 'Date' }} disableDatepickerThrottle />
                                                </div>
                                                <div style={{ flex: 1, maxWidth: 200 }} className='mr-1 ml-1'>
                                                    <SelectField
                                                        fieldProps={{ name: `inviteBlasts.${index}.sendTime`, label: `Time (Pacific Standard Time)` }}
                                                        options={timeOptionsHourly.current}
                                                        disabled={Boolean(formikProps.values.inviteBlasts[index].mailBlastId)}
                                                    />
                                                </div>

                                                {!formikProps.values.inviteBlasts[index].sent &&
                                                    <a type='button' className={`btn btn-danger btn-sm icon-left`} href='#'
                                                    onClick={async () => {
                                                        if (formikProps.values.inviteBlasts[index].mailBlastId) {
                                                            await makeHttpRequestWithUi({
                                                                request: MailBlastTaskApi.apiMailBlastTaskEventContentEventContentIdDeleteEventInviteBlastDelete(eventContentId, formikProps.values.inviteBlasts[index].mailBlastId),
                                                                disableSuccessToast: true,
                                                                toastErrorMessage: 'Encountered an error deleting invite blast.'
                                                            })

                                                        }
                                                        arrayHelpers.remove(index)
                                                    }}><XSquareIcon />Delete blast</a>
                                                }
                                                {formikProps.values.inviteBlasts[index].sent &&
                                                    <a type='button' className={`btn btn-primary btn-sm icon-left`} href='#'
                                                    onClick={async () => {        
                                                        if (formikProps.values.inviteBlasts[index]?.mailBlastId) {                                           
                                                            const statsQuery = await makeHttpRequestWithUi({
                                                                request: MailBlastApi.apiMailBlastIdStatsSummaryGet(formikProps.values.inviteBlasts[index].mailBlastId || 0),
                                                                disableSuccessToast: true,
                                                                toastErrorMessage: 'Failed to fetch stats for this mail blast.'
                                                            })
                                                
                                                            if (statsQuery.status === 200) {
                                                                setEmailStats(statsQuery.data)
                                                                showHideStatsModal(true)
                                                            }
                                                        }
                                                    }}><FileSpreadsheetIcon />View stats</a>
                                                }
                                            </div>
                                        )}

                                        <div className='invalid-feedback' style={{ display: 'block' }}>{typeof formikProps.errors.inviteBlasts === 'string' && formikProps.errors.inviteBlasts}</div>
                                        <div className='d-flex' style={{paddingTop: '250px'}}>
                                            <button
                                                className='btn btn-primary mr-2'
                                                type='button'
                                                onClick={() => arrayHelpers.push({ sendDate: '', sendTime: '', sent: false } as IInviteBlastRow)}
                                            >
                                                Add Invite Blast
                                            </button>
                                            <button className='btn btn-primary mr-2' style={{ minWidth: 100 }} type='submit'>Save Changes</button>
                                            <button type='button' className='btn btn-secondary' onClick={() => onCancel ? onCancel() : null}>Cancel</button>
                                        </div>
                                        {/* {eventToEdit?.status !== EventStatus.activated && <i className='ml-2'>Invite blasts can only be added to active events.</i>} */}
                                    </>
                                }
                            />
                        </div>
                    </Form>
                }
            </Formik>
            <Modal
				{...statsModal}
				modalTitle={`Results`}
				_onModalHidden={() => {
					setEmailStats(undefined)
				}}
				size='xl'
			>
				{emailStats ?
					<EventNewsStats emailStats={[emailStats]} hideTypeColumn={true} />
					:
					<p>There was an error retrieving the results for this blast. Occasionally, it may take up to 12 hours before stats become available.</p>
				}
			</Modal>
        </>
    )
}