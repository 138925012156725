import { AxiosResponse } from 'axios'
import { ManNavExpandable as MainNavExpandable } from '../components/main-nav'
import { IMinistrySummaryResponseModelDocument, IProspectSearchResultModelDocument } from '../open-api'
import { useAsyncFnThrottleWrapper, useHTTPRequestUiWrapper } from '../services/hooks'
import { IMifApi } from './api-actions'
import { IAppState, IUserSessionState, IUserSessionStatePreferences } from './app-definitions'
import { AppAction, AppActionType } from './app-reducer'
import { IGridUserInteractionState } from './grid-definitions'

export interface IUserSessionStateActions {
	/* 
		Save user session state.
	*/
	saveUserSessionState: (userSessionState: IUserSessionState) => Promise<AxiosResponse<void>>

	/* 
		Clear user session state.
		COMPLETELY RESETS ALL USER PREFERENCES.
	*/
	clearUserSessionState: () => Promise<void>

	/* 
		Retrieve user session state
	*/
	refreshUserSessionState: () => Promise<void>

	/* 
		Check to see if a Ministry is in the user's current favorites. If not, add it.
		(favorites are stored in the user's session state)
	*/
	addMinistryToCurrentUserFavorites: (ministry: IMinistrySummaryResponseModelDocument) => Promise<void>

	/* 
		Check to see if a Ministry is stored in the user's current favorites. If it is, remove it.
	*/
	removeMinistryFromCurrentUserFavorites: (ministry: IMinistrySummaryResponseModelDocument) => Promise<void>

	// Toggle main nav expand/collapse status 
	toggleMainNavMenuExpandCollapse: (navItem: MainNavExpandable) => Promise<void>

	/* 
		Check to see if a Prospect is in the user's current favorites. If not, add it.
		(favorites are stored in the user's session state)
	*/
	addProspectToCurrentUserFavorites: (prospect: IProspectSearchResultModelDocument) => Promise<void>

	/* 
		Check to see if a Prospect is stored in the user's current favorites. If it is, remove it.
	*/
	removeProspectFromCurrentUserFavorites: (prospect: IProspectSearchResultModelDocument) => Promise<void>

	updateGridUserInteractionState: (
		gridUserInteractionState: IGridUserInteractionState, // The state of the user's interaction with the grid (filters, sorts, column visibility, etc.)
		gridSessionStateKey: string // The key that this grid's state is stored at within the user's session state
	) => Promise<void>

	/* 
		Clear the stored state for a Grid in the user's session data
	*/
	resetGridUserInteractionState: (
		gridSessionStateKey: string // The key that this grid's state is stored at within the user's session state
	) => Promise<void>

	/* 
		Toggle user's slim tables preference
	*/
	toggleSlimTables: () => Promise<AxiosResponse<void>>
}


export const useUserSessionStateActions = (dispatch: React.Dispatch<AppAction>, apiActions: IMifApi, state: IAppState): IUserSessionStateActions => {
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const saveUserSessionState = async (sessionState: IUserSessionState) => {
		console.log('state', state)
		if (!state.currentUser) throw new Error('Attempted to save user session state with non-existent user.')

		// Update our local store of the user's session state
		dispatch({ type: AppActionType.setUserSessionState, payload: sessionState })

		// Persist the user's session state
		return await apiActions.MiUsersApi.apiMiUsersIdAdminStatePut(state.currentUser.userId, { adminStateObj: JSON.stringify(sessionState) })
	}

	// We may want to throttle this since some user interactions could cause their session state to be updated repeatedly (e.g. resizing a grid column)
	const _throttledSaveUserSessionState = useAsyncFnThrottleWrapper(saveUserSessionState)

	return {
		saveUserSessionState,
		clearUserSessionState: async () => {
			await makeHTTPRequestWithUi({
				request: saveUserSessionState({}),
				toastSuccessMessage: 'User preferences cleared.',
				toastErrorMessage: 'Error clearing user preferences',
			})
		},
		refreshUserSessionState: async () => {
			const userAdminStateQuery = await makeHTTPRequestWithUi({ request: apiActions.MiUsersApi.apiMiUsersMeAppStateGet(), disableSuccessToast: true, disableLoading: true })

			let adminStateObj = undefined
			try {
				adminStateObj = userAdminStateQuery.data ? JSON.parse(userAdminStateQuery.data) : undefined
			}
			catch (e) {
				adminStateObj = undefined
			}
			dispatch({ type: AppActionType.setUserSessionState, payload: adminStateObj })
		},
		addMinistryToCurrentUserFavorites: async (ministry: IMinistrySummaryResponseModelDocument) => {
			if (!state.currentUser) return

			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState }

			const newFavoritedMinistry = { ministryId: ministry.ministryId, ministryName: ministry.ministryName || 'NO MINISTRY NAME PROVIDED' }

			// Check to see if this ministry is already in the user's favorites
			if (!newUserSessionState.favorites?.ministries?.find(m => m.ministryId === ministry.ministryId)) {
				if (!newUserSessionState.favorites) {
					// The favorites object hasn't even been created yet for this user's session state, we can go ahead and create it with this ministry as the first favorite
					newUserSessionState.favorites = { ministries: [newFavoritedMinistry] }
				}
				else if (!newUserSessionState.favorites.ministries) {
					// The favorites object has been created, but the list of ministries within the favorites has not. We can go ahead and create it with this ministry as the first favorite.
					newUserSessionState.favorites.ministries = [newFavoritedMinistry]
				}
				else {
					// Both the favorites object and the list of favorite ministries within it have already been created, we can just add this ministry to the list
					newUserSessionState.favorites.ministries.push(newFavoritedMinistry)
				}
			}

			saveUserSessionState(newUserSessionState)
		},

		removeMinistryFromCurrentUserFavorites: async (ministry: IMinistrySummaryResponseModelDocument) => {
			if (!state.currentUser) return

			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState }

			// We only need to remove the favorited ministry if it is in the user's favorites list.
			if (newUserSessionState.favorites?.ministries?.find(m => m.ministryId === ministry.ministryId)) {
				newUserSessionState.favorites.ministries = newUserSessionState.favorites.ministries.filter(m => m.ministryId !== ministry.ministryId)

				saveUserSessionState(newUserSessionState)
			}
		},

		addProspectToCurrentUserFavorites: async (prospect: IProspectSearchResultModelDocument) => {
			if (!state.currentUser) return

			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState }

			const newFavoritedProspect = { prospectId: prospect.prospectId, prospectName: `${prospect.firstName} ${prospect.lastName}` }

			// Check to see if this prospect is already in the user's favorites
			if (!newUserSessionState.favorites?.prospects?.find(m => m.prospectId === prospect.prospectId)) {
				if (!newUserSessionState.favorites) {
					// The favorites object hasn't even been created yet for this user's session state, we can go ahead and create it with this prospect as the first favorite
					newUserSessionState.favorites = { prospects: [newFavoritedProspect] }
				}
				else if (!newUserSessionState.favorites.prospects) {
					// The favorites object has been created, but the list of prospects within the favorites has not. We can go ahead and create it with this prospect as the first favorite.
					newUserSessionState.favorites.prospects = [newFavoritedProspect]
				}
				else {
					// Both the favorites object and the list of favorite prospects within it have already been created, we can just add this prospect to the list
					newUserSessionState.favorites.prospects.push(newFavoritedProspect)
				}
			}

			saveUserSessionState(newUserSessionState)
		},

		removeProspectFromCurrentUserFavorites: async (prospect: IProspectSearchResultModelDocument) => {
			if (!state.currentUser) return

			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState }

			// We only need to remove the favorited prospect if it is in the user's favorites list.
			if (newUserSessionState.favorites?.prospects?.find(m => m.prospectId === prospect.prospectId)) {
				newUserSessionState.favorites.prospects = newUserSessionState.favorites.prospects.filter(m => m.prospectId !== prospect.prospectId)

				saveUserSessionState(newUserSessionState)
			}
		},

		toggleMainNavMenuExpandCollapse: async (path: MainNavExpandable) => {
			if (!state.currentUser) return

			const expandedSubMenus: { [key: string]: boolean } = { ...state.currentUserSessionState?.expandedSubMenus }
			expandedSubMenus[path] = !expandedSubMenus[path]
			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState, expandedSubMenus }

			saveUserSessionState(newUserSessionState)
		},

		toggleSlimTables: async () => {
			const newUserSessionStatePreferences: IUserSessionStatePreferences = { ...state.currentUserSessionState?.preferences }
			newUserSessionStatePreferences.slimTables = !newUserSessionStatePreferences.slimTables
			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState, preferences: newUserSessionStatePreferences }
			return saveUserSessionState(newUserSessionState)
		},

		updateGridUserInteractionState: async (gridUserInteractionState: IGridUserInteractionState, gridSessionStateKey: string) => {
			const newGridInteractionStates =
				state.currentUserSessionState?.gridUserInteractionStates ?
					{
						...state.currentUserSessionState.gridUserInteractionStates,
						[gridSessionStateKey]: { ...gridUserInteractionState }
					}
					:
					{
						[gridSessionStateKey]: { ...gridUserInteractionState }
					}

			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState, gridUserInteractionStates: newGridInteractionStates }
			_throttledSaveUserSessionState(newUserSessionState)
		},

		resetGridUserInteractionState: async (gridSessionStateKey: string) => {
			const newGridInteractionStates =
				state.currentUserSessionState?.gridUserInteractionStates ?
					{
						...state.currentUserSessionState.gridUserInteractionStates,
						[gridSessionStateKey]: null
					}
					:
					{
						[gridSessionStateKey]: null
					}

			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState, gridUserInteractionStates: newGridInteractionStates }
			await saveUserSessionState(newUserSessionState)
		},
	}
}