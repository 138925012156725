import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { ArrowDownIcon, ArrowUpIcon, PencilIcon } from "../assets"
import { IGrantRequestCustomQuestionSummaryDocument } from "../open-api"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { IDefaultProps } from "./component-definitions"
import { Grid } from "./grid"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { Modal } from "./modal"
import { FormikTextField } from "./forms/formik-text-field"
import { Form, Formik } from "formik"
import * as Yup from 'yup'
import { SquareDeleteIcon } from "./partials"

interface IGrantRequestCustomQuestionsProps extends IDefaultProps {
    activeGrantId: number
    afterSave: () => Promise<void>
}

const dataSource: GridDataFetch<IGrantRequestCustomQuestionSummaryDocument[]> = async (_queryState, _items) => {
    try {
        if (!_items) return { rows: [], count: 0 }

        if (_queryState.sorts) sortListBySorts(_items, _queryState.sorts)
        let rows: IGridListItem[] = _items.map(o => ({
            id: o.ministryGrantCustomQuestionId!.toString(),
            values: {
                ...o,
                // regDate: o.regDate ? new Date(o.regDate) : null,
            }
        }))

        if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

        return {
            rows,
            count: rows.length
        }
    } catch (e) {
        console.log('GrantRequestCustomQuestions > Exception thrown:', e)
        return {
            rows: [],
            count: 0
        }
    }
}

export const GrantRequestCustomQuestions = (props: IGrantRequestCustomQuestionsProps) => {
    const { activeGrantId, afterSave } = props
    const { GrantRequestCustomQuestionsApi } = useContext(AppActionContext)!
    console.log('activeGrantId', activeGrantId)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [questions, setQuestions] = useState<IGrantRequestCustomQuestionSummaryDocument[]>()
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [addQuestionModal, showHideAddQuestionModal] = useModal()

    const fetchQuestions = async (grantId: number) => {
        console.log('fetchQuestions')
        const { data } = await makeHttpRequestWithUi({
            request: GrantRequestCustomQuestionsApi.apiGrantrequestGrantIdCustomquestionsGet(grantId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching questions.',
        })

        setQuestions(data)
    }

    const deleteQuestion = async (questionId: number) => {
        await makeHttpRequestWithUi({
            request: GrantRequestCustomQuestionsApi.apiGrantRequestCustomQuestionsQuestionIdDelete(questionId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching questions.',
        })

        if (activeGrantId) fetchQuestions(activeGrantId)
    }

    const moveQuestionUp = async (questionId: number, _activeGrantId: number) => {
        console.log('moveQuestionUp')
        await makeHttpRequestWithUi({
            request: GrantRequestCustomQuestionsApi.apiGrantRequestCustomQuestionsShiftUpQuestionIdPost(questionId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error moving question up.',
        })
        console.log('activeGrantId', activeGrantId)

        fetchQuestions(_activeGrantId)
    }

    const moveQuestionDown = async (questionId: number, _activeGrantId: number) => {
        await makeHttpRequestWithUi({
            request: GrantRequestCustomQuestionsApi.apiGrantRequestCustomQuestionsShiftDownQuestionIdPost(questionId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error moving question down.',
        })

        fetchQuestions(_activeGrantId)
    }

    // Want to lazy load this page's data, so don't load until parent sets the activeGrantId.
    useEffect(() => {
        console.log('activeGrantId changed', activeGrantId)
        if (activeGrantId) fetchQuestions(activeGrantId)
    }, [activeGrantId])

    const initialGridState: IGridState = {
        ...defaultGridState,
        gridActions: [
            {
                id: 'addQuestion',
                label: 'Add Question',
                onClick: () => showHideAddQuestionModal(true),
            }
        ],
        rowActions: {
            editQuestion: {
                id: 'editQuestion',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideAddQuestionModal(true)
                },
                tooltipText: 'Edit Question',
                icon: <PencilIcon />,
            },
            moveUp: {
                id: 'moveUp',
                action: async ({ row }) => {
                    moveQuestionUp(parseInt(row.id), activeGrantId)
                },
                tooltipText: 'Move Question Up',
                icon: <ArrowUpIcon />,
            },
            moveDown: {
                id: 'moveDown',
                action: async ({ row }) => {
                    moveQuestionDown(parseInt(row.id), activeGrantId)
                },
                tooltipText: 'Move Question Down',
                icon: <ArrowDownIcon />,
            },
            deleteQuestion: {
                id: 'deleteQuestion',
                action: async ({ row }) => {
                    deleteQuestion(parseInt(row.id))
                },
                tooltipText: 'Remove Question',
                icon: <SquareDeleteIcon />,
            }
        },
        columns: [
            {
                property: 'sortOrder',
                type: 'string',
                width: 270,
                allowFilters: true,
                title: 'Question Order',
                render: DefaultGridCellDisplay,
                description: 'The order in which the questions will be displayed on the Grant Report.'
            },
            {
                property: 'questionText',
                type: 'string',
                width: 270,
                allowFilters: true,
                title: 'Question',
                render: DefaultGridCellDisplay,
                description: 'The ministry name.'
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },
            {
                property: 'dCreated',
                type: 'date',
                width: 100,
                allowFilters: true,
                title: 'Date Created',
                render: DefaultGridCellDisplay
            },
            {
                property: 'createdByFullName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Created By',
                render: DefaultGridCellDisplay
            },
        ],
        dataSource,
        disableExport: true,
        rowSelectEnabled:false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            showHideAddQuestionModal(true)
        },
    }
    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, questions || [])

    useEffect(() => {
        console.log('GrantRequestCustomQuestions > doFetch')
        gridActions.doFetch()
    }, [questions])

    return (
        <>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} />
            </div>
            <Modal
				{...addQuestionModal}
				modalTitle={`${selectedRow ? `Edit` : `Add`} Custom Grant Report Questions`}
                size="md"
                style={{zIndex: 1051}} // Hack to prevent this modal from displaying behind the parent open modal.
			>
                <Formik
                    enableReinitialize
                    initialValues={{
                        questionText: selectedRow?.values.questionText?.toString() || ''
                    }}
                    validationSchema={Yup.object({
                        questionText: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, actions) => {
                        if (selectedRow) {
                            await makeHttpRequestWithUi({
                                request: GrantRequestCustomQuestionsApi.apiGrantRequestCustomQuestionsQuestionIdPut(parseInt(selectedRow.id), {
                                    ministryGrantCustomQuestionId: parseInt(selectedRow.id),
                                    questionText: values.questionText,
                                    sortOrder: parseInt(selectedRow.values.sortOrder!.toString())
                                }),
                                toastSuccessMessage: 'Successfully submitted request.',
                                toastErrorMessage: 'Error submitting request.',
                                disableLoading: false,
                            })
                        } else {
                            await makeHttpRequestWithUi({
                                request: GrantRequestCustomQuestionsApi.apiGrantRequestCustomQuestionsPost({
                                    grantId: activeGrantId!,
                                    questionText: values.questionText,
                                    sortOrder: questions ? questions.length + 1 : 0
                                }),
                                toastSuccessMessage: 'Successfully submitted request.',
                                toastErrorMessage: 'Error submitting request.',
                                disableLoading: false,
                            })
                        }
                        
                        actions.resetForm()
                        if (activeGrantId) fetchQuestions(activeGrantId)
                        setSelectedRow(undefined)
                        showHideAddQuestionModal(false)
                    }}
                >
                    {formikProps => {
                        return (
                            <Form className='d-flex flex-column mx-2'>
                                <div className="d-flex flex-column mt-2">
                                    <FormikTextField field={{ name: 'questionText', label: 'Question Text' }} />
                                </div>
                                <div className="mt-4 mb-4">
                                    <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2' disabled={!formikProps.dirty}>Save</button>
                                    <button type='button' style={{ width: 100 }} className='btn btn-secondary mr-2' disabled={!formikProps.dirty}>Cancel</button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal>
        </>
    )
}