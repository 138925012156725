import { IGridListItem } from '../stores/grid-definitions'
import { IMinistryProspectPossibleMatch } from '../components/prospect-possible-matches-modal'
import moment from 'moment'

export class ProspectPossibleMatch {
    public static toGridListItem = (possibleProspectMatch: IMinistryProspectPossibleMatch): IGridListItem => {
        return {
            id: possibleProspectMatch.ministryId.toString(),
            values: {
                ...possibleProspectMatch,
                dateRegistered: possibleProspectMatch.dateRegistered ? moment(possibleProspectMatch.dateRegistered).toDate() : null
            }
        }
    }
}