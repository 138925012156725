import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalFinancialProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalFinancial = (props: IMinistryInfoAreaDirectorEvalFinancialProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
        <Formik
            initialValues={{
                bConsistent: areaDirectorEval.ministryEvalState.bConsistent,
                bEqual: areaDirectorEval.ministryEvalState.bEqual,
                bCompared: areaDirectorEval.ministryEvalState.bCompared,
                bUnexplainable: areaDirectorEval.ministryEvalState.bUnexplainable,
                bRatio: areaDirectorEval.ministryEvalState.bRatio,
                bStaff: areaDirectorEval.ministryEvalState.bStaff,
                bAccounting: areaDirectorEval.ministryEvalState.bAccounting,
                bReview: areaDirectorEval.ministryEvalState.bReview,
                bReserve: areaDirectorEval.ministryEvalState.bReserve,
                bCompelling: areaDirectorEval.ministryEvalState.bCompelling,
                financial: areaDirectorEval.ministryEvalState.financial || '',
                tabDirection: null
            }}
            onSubmit={async (values) => {
                const evalRecord: IMinistryEvalsPutDocument = {
                    ...areaDirectorEval.ministryEvalState,
                    bConsistent: values.bConsistent,
                    bEqual: values.bEqual,
                    bCompared: values.bCompared,
                    bUnexplainable: values.bUnexplainable,
                    bRatio: values.bRatio,
                    bStaff: values.bStaff,
                    bAccounting: values.bAccounting,
                    bReview: values.bReview,
                    bReserve: values.bReserve,
                    bCompelling: values.bCompelling,
                    financial: values.financial,
                    dStep8Updated: moment().format(),
                    step8UpdatedBy: appState.currentUser?.userId || null
                }
                await makeHTTPRequestWithUi({
                    request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
                    toastSuccessMessage: 'Eval record successfully updated.',
                    toastErrorMessage: 'There was an error updating the eval record.',
                })
                
                if (values.tabDirection === 'previous' && afterSavePrevious) {
                    afterSavePrevious()
                } else if (afterSaveNext) {
                    afterSaveNext()
                }
            }}
        >
            {(formikProps) => {
                const form = <React.Fragment>
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bConsistent', 
                            label: 'The organization\'s goals are consistent with their financial capability' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bEqual', 
                            label: 'Over the past three years, the organization\'s income and expenses have been roughly equal' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bCompared', 
                            label: 'Financial performance of the organization is routinely compared against a projected fiscal budget' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bUnexplainable', 
                            label: 'There are no unusually large, unexplainable items that appear on the organization\'s financial statements' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bRatio', 
                            label: 'The expense ratio as expressed is within the acceptable range (10-39%). The lower the percentage the better' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bStaff', 
                            label: 'The organization has sufficient accounting staff or third party relationship in place to adequately handle the financial management of the organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bAccounting', 
                            label: 'A computer-based accounting software system is used to capture and analyze financial dealings of the organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bReview', 
                            label: 'An outside accounting firm conducts annual comprehensive reviews of this organization\'s financial activities' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bReserve', 
                            label: 'The organization has an adequate cash reserve to cover unexpected expenses' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bCompelling', 
                            label: 'The organization has a compelling use for grant funds from Mission Increase' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    { !readonly ?
                        <TextareaField
                            fieldProps={{
                                name: 'financial',
                                label: 'Additional Notes on Financial'
                            }} />
                            :
                            null
                    }
                    { !readonly ?
                        <div className='row px-3'>
                            <button className={`btn btn-primary`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'previous')
                                formikProps.submitForm()
                            }}>Save &amp; Previous</button>
                            <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'next')
                                formikProps.submitForm()
                            }}>Save &amp; Continue</button>
                        </div>
                        :
                        null
                    }
                </React.Fragment>
                // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                return (
                    readonly ? form :
                    <Form>
                        {form}
                    </Form>
                )
            }}
        </Formik>
    )
}