import React, { useContext, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import * as Yup from 'yup'
import { SelectField, TextField, EmailField } from './forms'
import { Loading } from './loading'
import { CANADA_COUNTRY_ID, MinistryContactType, UNITED_STATES_COUNTRY_ID } from '../constants'
import { ensureUrlIncludesHttp, urlRegex, objectToOperationDocument } from '../services/helpers'
import { IProspectSearchResultModelDocument } from '../open-api'
import { FormikStateSelectField } from './forms/formik-state-select-field'
import { FormikCountrySelectField } from './forms/formik-country-select-field'

/* 
	Original ExtJS requirements (Admin\modules\Prospects\showProspects.js)

	Community is set to the Global Community Context (line 788)

	There is a "card1" panel with a "previous" and "finish" navigation.
	It appears, however, that the "finish" button immediately submits the form (line 751, line 518-19) and the "previous" button does nothing.

	Required fields (530-37)
	- Branch ID
	- Contact First Name
	- Contact Last Name

	Changing the Community selection change the training area

	Training areas loaded from external data store (line 583)

	States load from external data store
*/

interface IProspectFormProps {
	onSaveCancel?: () => void
	onSaveFinish?: () => void

	setResetForm?: ((resetForm: () => void) => void)

	prospectToEdit?: IProspectSearchResultModelDocument
}
export const ProspectForm = (props: IProspectFormProps) => {
	const { onSaveCancel, onSaveFinish, setResetForm, prospectToEdit } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	let resetForm: () => void

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	useEffect(() => {
		if (resetForm) resetForm()
	}, [appState.globalCommunityContext, prospectToEdit])

	useEffect(() => {
		appActions.fetchTrainingAreas()
		if (!appState.states) appActions.fetchStates()
	}, [])

	if (!appState.states) return <Loading />

	return (
		<Formik
			initialValues={{
				branchId: prospectToEdit?.branchId?.toString() || appState.globalCommunityContext?.branchId.toString() || '',
				trainingAreaId: prospectToEdit?.trainingAreaId?.toString() || '',
				organizationName: prospectToEdit?.organizationName || '',
				firstName: prospectToEdit?.firstName || '',
				lastName: prospectToEdit?.lastName || '',
				contactTypeId: prospectToEdit?.contactTypeId?.toString() || '',
				title: prospectToEdit?.title || '',
				email: prospectToEdit?.email || '',
				siteUrl: prospectToEdit?.siteUrl || '',
				businessPhone: prospectToEdit?.businessPhone || '',
				mobilePhone: prospectToEdit?.mobilePhone || '',
				address1: prospectToEdit?.address1 || '',
				address2: prospectToEdit?.address2 || '',
				city: prospectToEdit?.city || '',
				stateId: prospectToEdit?.stateId?.toString() || '',
				stateOther: prospectToEdit?.stateOther || '',
				countryId: prospectToEdit?.countryId?.toString() || '',
				postalCode: prospectToEdit?.postalCode || '',
			}}
			validationSchema={Yup.object({
				branchId: Yup.string().required('Required'),
				firstName: Yup.string().required('Required'),
				lastName: Yup.string().required('Required'),
				siteUrl: Yup.string()
					.matches(urlRegex, 'Must be a valid URL.'),

			})}
			onSubmit={async (values, actions) => {
				const updates = {
					branchId: parseInt(values.branchId),
					trainingAreaId: values.trainingAreaId.length ? parseInt(values.trainingAreaId) : null,
					organizationName: values.organizationName.length ? values.organizationName : null,
					firstName: values.firstName.length ? values.firstName : null,
					lastName: values.lastName.length ? values.lastName : null,
					contactTypeId: values.contactTypeId.length ? parseInt(values.contactTypeId) : null,
					title: values.title.length ? values.title : null,
					email: values.email.length ? values.email : null,
					siteUrl: values.siteUrl.length ? ensureUrlIncludesHttp(values.siteUrl) : null,
					businessPhone: values.businessPhone.length ? values.businessPhone : null,
					mobilePhone: values.mobilePhone.length ? values.mobilePhone : null,
					address1: values.address1.length ? values.address1 : null,
					address2: values.address2.length ? values.address2 : null,
					city: values.city.length ? values.city : null,
					stateId: values.stateId.length ? parseInt(values.stateId) : null,
					stateOther: values.stateOther.length ? values.stateOther : null,
					countryId: values.countryId.length ? parseInt(values.countryId) : null,
					postalCode: values.postalCode.length ? values.postalCode : null,
				}

				if (prospectToEdit) {
					await makeHttpRequestWithUi({
						toastSuccessMessage: 'Successfully updated Prospect.',
						toastErrorMessage: 'Error updating Prospect.',
						request: appActions.ProspectsApi.apiProspectsIdPatch(prospectToEdit.prospectId, objectToOperationDocument(updates))
					})
				} else {
					await makeHttpRequestWithUi({
						toastSuccessMessage: 'Successfully created new Prospect.',
						toastErrorMessage: 'Error creating new Prospect.',
						request: appActions.ProspectsApi.apiProspectsPost(updates)
					})
				}

				if (onSaveFinish) onSaveFinish()
			}}
		>
			{formikProps => {
				if (setResetForm) setResetForm(formikProps.resetForm)
				return (
					<Form>

						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<SelectField
									fieldProps={{ name: 'branchId', label: 'Community', placeholder: 'Select a Community' }}
									options={appState.activeBranches.map(o => ({ label: `${o.branchAbbr} (${o.branchName})`, value: `${o.branchId}` }))}
								/>
							</div>

							<div className='ml-1' style={{ flex: 1 }}>
								<SelectField
									fieldProps={{ name: 'trainingAreaId', label: 'Training Area' }}
									options={appState.activeTrainingAreas?.filter(trainingArea => trainingArea.branchId.toString() === formikProps.values.branchId).map(o => ({ label: `${o.trainingAreaAbbr} (${o.trainingArea})`, value: `${o.trainingAreaId}` }))}
								/>
							</div>

							{/* {formikProps.values.branchId ?
								<div className='animated fadeInDown ml-1' style={{ animationDuration: '200ms', flex: 1 }}>
									<SelectField
										fieldProps={{ name: 'trainingAreaId', label: 'Training Area' }}
										options={appState.trainingAreas?.filter(trainingArea => trainingArea.branchId.toString() === formikProps.values.branchId).map(o => ({ label: `${o.trainingAreaAbbr} (${o.trainingArea})`, value: `${o.trainingAreaId}` }))}
									/>
								</div>
								:
								null
							} */}
						</div>

						<TextField fieldProps={{ name: 'organizationName', label: 'Ministry Name', maxlength: '250' }} />

						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<TextField fieldProps={{ name: 'firstName', label: 'First Name', maxlength: '50' }} />
							</div>

							<div style={{ flex: 1 }} className='ml-1'>
								<TextField fieldProps={{ name: 'lastName', label: 'Last Name', maxlength: '50' }} />
							</div>
						</div>

						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<SelectField
									fieldProps={{ name: 'contactTypeId', label: 'Contact Type', placeholder: 'Select contact type' }}
									// @ts-ignore
									options={Object.keys(MinistryContactType).filter(key => isNaN(key)).map(key => ({ value: MinistryContactType[key].toString(), label: key }))}
								/>
							</div>
							<div style={{ flex: 1 }} className='ml-1'>
								<TextField fieldProps={{ name: 'title', label: 'Title', maxlength: '100' }} />
							</div>
						</div>


						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<EmailField fieldProps={{ name: 'email', label: 'Email', maxlength: '100' }} />
							</div>
							<div style={{ flex: 1 }} className='ml-1'>
								<TextField fieldProps={{ name: 'siteUrl', label: 'Website', maxlength: '50' }} />
							</div>
						</div>

						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<TextField fieldProps={{ name: 'businessPhone', label: 'Business Phone', maxlength: '50' }} />
							</div>
							<div style={{ flex: 1 }} className='ml-1'>
								<TextField fieldProps={{ name: 'mobilePhone', label: 'Mobile Phone', maxlength: '50' }} />
							</div>
						</div>

						<div className='d-flex'>
							<div style={{ flex: 1 }} ><FormikCountrySelectField field={{ name: 'countryId', label: 'Country', placeholder: 'Select a country' }} /></div>
						</div>

						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<TextField fieldProps={{ name: 'address1', label: 'Address', maxlength: '100' }} />
							</div>
							<div style={{ flex: 1 }} className='ml-1'>
								<TextField fieldProps={{ name: 'address2', label: 'Address 2', maxlength: '100' }} />
							</div>
						</div>

						<div className='d-flex'>
							<div style={{ flex: 1 }} className='mr-1'>
								<TextField fieldProps={{ name: 'city', label: 'City', maxlength: '50' }} />
							</div>
							<div style={{ flex: 1 }} className='mr-1 ml-1'>
							{(formikProps.values.countryId == UNITED_STATES_COUNTRY_ID.toString() || formikProps.values.countryId == CANADA_COUNTRY_ID.toString()) ? 
								<FormikStateSelectField 
									field={{ name: 'stateId', label: 'State', placeholder: 'Select a state', disabled: !formikProps.values.countryId }}
									countryId={parseInt(formikProps.values.countryId)} />
								: 
								<TextField 
									fieldProps={{ name: 'stateOther', label: 'State/Province' }}  
									disabled={!formikProps.values.countryId} /> }
							</div>
							<div style={{ flex: 1 }} className='ml-1'>
								<TextField fieldProps={{ name: 'postalCode', label: 'Zip', maxlength: '25' }} />
							</div>
						</div>

						<div className='d-flex justify-content-end'>
							{onSaveCancel ? <button className='btn btn-secondary col mr-1' onClick={onSaveCancel}>Cancel</button> : null}
							<button className={`btn btn-primary col ${onSaveCancel ? 'ml-1' : ''}`} type='submit'>Save</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}