import moment from "moment"
import React, { useContext, useEffect, useRef, useState } from "react"
import { IAfterEventDataDocument, IConsultingApptDocument, IConsultingApptRegistrantDocument, IConsultingAreaDirectorDocument } from "../open-api"
import { ConulstingAppointmentFormDetails } from "./consulting-appointment-form-details"
import { ConsultingAppointmentFormRegistrants } from "./consulting-appointment-form-registrants"
import { IConsultingManagementListDataType } from "./consulting-management"
import { ConsultingAppointmentFormAfterEvent } from './consulting-appointment-form-after-event'
import { Tabs } from "./tabs"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { LoadingOverlay } from "./loading"
import dayjs from "dayjs"

export interface IConsultingSessionFormProps {
    appointmentToEdit?: IConsultingApptDocument
    setAppointmentToEdit: (appointment: IConsultingApptDocument) => void
    meetingTypes: Array<IConsultingManagementListDataType>
    appointmentTypes: Array<IConsultingManagementListDataType>
    consultingTopics: Array<IConsultingManagementListDataType>
    maConsultingTopics: Array<IConsultingManagementListDataType>
    trainingTopics: Array<IConsultingManagementListDataType>
    areaDirectors: IConsultingAreaDirectorDocument[]
    onCancel?: () => void
    afterSave: () => void
}

export const ConsultingAppointmentForm = (props: IConsultingSessionFormProps) => {

    const appActions = useContext(AppActionContext)!

    //const isAfterEvent = props.appointmentToEdit && moment(props.appointmentToEdit.endDateTime).isBefore()

    //console.log('isAfter', isAfterEvent)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [afterEventData, setAfterEventData] = useState<IAfterEventDataDocument>()

    const [loading, setLoading] = useState(false)
    const [registrants, setRegistrants] = useState<IConsultingApptRegistrantDocument[]>([])
    const fetchRegistrants = async () => {
        if (props.appointmentToEdit) {
            setLoading(true)
            const registrantsQuery = await makeHttpRequestWithUi({
                request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdRegistrantsGet(props.appointmentToEdit.consultingEventGroupId),
                toastErrorMessage: 'There was an error retrieving registrants.',
                disableSuccessToast: true,
                disableLoading: true,
            })
            setRegistrants(registrantsQuery.data)
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchRegistrants()
    }, [])

    const isFirstLoad = useRef(true)
    const fetchAfterEventData = async () => {
        
        if (!props.appointmentToEdit) return
        makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdAfterApptDataGet(props.appointmentToEdit.consultingEventGroupId),
            toastErrorMessage: 'Encountered an error retrieving after event data.',
            disableSuccessToast: true,
        })
            .then(results => {
                setAfterEventData(results.data)
                if (!results.data.actualHours && props.appointmentToEdit && dayjs().isAfter(props.appointmentToEdit.endDateTime) && isFirstLoad.current) setActiveTab('afterEvent')
                isFirstLoad.current = false
            })
    }
    useEffect(() => {
        fetchAfterEventData()
    }, [])

    const [activeTab, setActiveTab] = useState<string>('appointmentDetails')

    return (
        <>
            {loading && <LoadingOverlay position='absolute' />}

            <Tabs
                keepTabContentInDomWhenNotActive
                controls={{
                    activeTab,
                    setActiveTab
                }}
                tabs={[
                    {
                        id: 'appointmentDetails',
                        title: 'Appointment Summary',
                        content: <ConulstingAppointmentFormDetails {...props} setActiveTab={setActiveTab} registrants={registrants} />
                    },
                    {
                        id: 'registrants',
                        title: 'Registrants',
                        content:
                            <ConsultingAppointmentFormRegistrants
                                {...props}
                                registrants={registrants}
                                afterSave={async () => {
                                    await fetchRegistrants()
                                    props.afterSave()
                                }}
                            />
                    },
                    ...props.appointmentToEdit ?
                        [
                            {
                                id: 'afterEvent',
                                title: 'After Event',
                                content: <ConsultingAppointmentFormAfterEvent
                                    appointmentToEdit={props.appointmentToEdit}
                                    afterEventData={afterEventData}
                                    registrants={registrants}
                                    afterSave={async () => {
                                        await fetchRegistrants()
                                        props.afterSave()
                                    }}
                                />
                            },
                        ]
                        :
                        []
                    ,
                ]}
            />
        </>
    )
}