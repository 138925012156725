import { IMinistryInfoState, MinistryInfoTab } from "./ministry-info-definitions"
import { MinistryGeneralChartsDataModel } from '../models/ministry-general-charts-data'
import { IMinistrySummaryResponseModelDocument, IMinistryContactSummaryModelDocument, IMinistryFileSummaryModelDocument, IMinistryDataFiscalYearSummaryDocument, IMinistryEmailsSummaryDocument, IMinistryEventAttendanceSummaryDocument, IMinistryGrantsSummaryDocument, INotesSummaryDocument, ILeverageSummaryReportDocument, IMinistryProfileLoggingSummaryDocument, ISeminarEvalSummaryDocument, ILmsUserCourseModelDocument } from '../open-api'

export enum MinistryInfoActionType {
	loading = 'loading',
	doneLoading = 'doneLoading',
	setMinistryId = 'setMinistryId',
	refreshData = 'refreshData',
	setMinistrySummary = 'setMinistrySummary',
	setActiveTab = 'setActiveTab',
	contactsAction = 'contactsAction',
	setMinistryFiles = 'setMinistryFiles',
	setMinistryContacts = 'setMinistryContacts',
	setMinistryGeneralChartData = 'setMinistryGeneralChartData',
	setMinistryFyData = 'setMinistryFyData',
	setEmailLog = 'setEmailLog',
	setEventEvals = 'setEventEvals',
	setEventAttendance = 'setEventAttendance',
	setGrants = 'setGrants',
	setNotes = 'setNotes',
	setLeverageSummary = 'setLeverageSummary',
	setProfileLog = 'setProfileLog',
	//setGsReport = 'setGsReport',
	approveProfile = 'approveProfile',
	denyProfile = 'denyProfile',
	setMinistryCourses = 'setMinistryCourses'
}

interface IPayloadLessAction {
	type:
	MinistryInfoActionType.loading |
	MinistryInfoActionType.doneLoading |
	MinistryInfoActionType.approveProfile |
	MinistryInfoActionType.denyProfile
}

interface ISetMinistryIdAction {
	type: MinistryInfoActionType.setMinistryId
	payload: number
}

interface ISetMinistryAction {
	type: MinistryInfoActionType.refreshData
	payload: {
		ministry: IMinistrySummaryResponseModelDocument
		ministryContacts: IMinistryContactSummaryModelDocument[]
		ministryGeneralChartsData: MinistryGeneralChartsDataModel
	}
}

interface ISetActiveTabAction {
	type: MinistryInfoActionType.setActiveTab
	payload: {
		tabId: MinistryInfoTab
	}
}

interface ISetMinistrySummaryAction {
	type: MinistryInfoActionType.setMinistrySummary
	payload: IMinistrySummaryResponseModelDocument
}

interface ISetMinistryContactsAction {
	type: MinistryInfoActionType.setMinistryContacts
	payload: IMinistryContactSummaryModelDocument[]
}

interface ISetMinistryGeneralChartDataAction {
	type: MinistryInfoActionType.setMinistryGeneralChartData
	payload: MinistryGeneralChartsDataModel
}

interface ISetMinistryFilesAction {
	type: MinistryInfoActionType.setMinistryFiles
	payload: IMinistryFileSummaryModelDocument[]
}

interface ISetMinistryFyDataAction {
	type: MinistryInfoActionType.setMinistryFyData
	payload: IMinistryDataFiscalYearSummaryDocument[]
}

interface ISetEmailLogAction {
	type: MinistryInfoActionType.setEmailLog
	payload: IMinistryEmailsSummaryDocument[]
}

interface ISetEventEvalsAction {
	type: MinistryInfoActionType.setEventEvals
	payload: ISeminarEvalSummaryDocument[]
}

interface ISetEventAttendanceAction {
	type: MinistryInfoActionType.setEventAttendance
	payload: IMinistryEventAttendanceSummaryDocument[]
}

interface ISetGrantsAction {
	type: MinistryInfoActionType.setGrants
	payload: IMinistryGrantsSummaryDocument[]
}

interface ISetNotesAction {
	type: MinistryInfoActionType.setNotes
	payload: INotesSummaryDocument[]
}

interface ISetLeverageSummaryAction {
	type: MinistryInfoActionType.setLeverageSummary
	payload: ILeverageSummaryReportDocument[]
}

interface ISetProfileLogAction {
	type: MinistryInfoActionType.setProfileLog
	payload: IMinistryProfileLoggingSummaryDocument[]
}

interface ISetCoursesAction {
	type: MinistryInfoActionType.setMinistryCourses
	payload: ILmsUserCourseModelDocument[]
}

// interface ISetGsReportAction {
// 	type: MinistryInfoActionType.setGsReport
// 	payload?: {
// 		data?: string
// 		inline: boolean
// 		failed: boolean
// 	}
// }

export type MinistryInfoAction =
	IPayloadLessAction |
	ISetMinistryAction |
	ISetActiveTabAction |
	ISetMinistrySummaryAction |
	ISetMinistryContactsAction |
	ISetMinistryGeneralChartDataAction |
	ISetMinistryFilesAction |
	ISetMinistryFyDataAction |
	ISetEventEvalsAction |
	ISetEmailLogAction |
	ISetEventAttendanceAction |
	ISetGrantsAction |
	ISetNotesAction |
	ISetMinistryIdAction |
	ISetLeverageSummaryAction |
	ISetProfileLogAction |
	ISetCoursesAction 
	//ISetGsReportAction


export const ministryInfoReducer = (state: IMinistryInfoState, action: MinistryInfoAction): IMinistryInfoState => {
	let newState = state

	switch (action.type) {
		case MinistryInfoActionType.loading:
			newState = { ...newState, loading: true }
			break
		case MinistryInfoActionType.doneLoading:
			newState = { ...newState, loading: false }
			break
		case MinistryInfoActionType.setMinistryId:
			newState = { ...newState, ministryId: action.payload }
			break
		case MinistryInfoActionType.refreshData:
			newState = {
				...newState,
				loading: false,
				...action.payload,
				primaryContact: action.payload.ministryContacts.find(contact => contact.isPrimary)
			}
			break
		case MinistryInfoActionType.setActiveTab:
			newState = { ...newState, activeTab: action.payload.tabId }
			break
		case MinistryInfoActionType.setMinistrySummary:
			newState = { ...newState, ministry: action.payload }
			break
		case MinistryInfoActionType.setMinistryGeneralChartData:
			newState = { ...newState, ministryGeneralChartsData: action.payload }
			break
		case MinistryInfoActionType.setMinistryContacts:
			newState = { ...newState, ministryContacts: action.payload, primaryContact: action.payload.find(contact => contact.isPrimary) }
			break
		case MinistryInfoActionType.setMinistryFyData:
			newState = { ...newState, ministryFyData: action.payload }
			break
		case MinistryInfoActionType.setEmailLog:
			newState = { ...newState, ministryEmailLog: action.payload }
			break
		case MinistryInfoActionType.setMinistryCourses:
			newState = { ...newState, ministryCourses: action.payload }
			break
		case MinistryInfoActionType.setEventEvals:
			newState = { ...newState, ministryEventEvals: action.payload }
			break
		case MinistryInfoActionType.setEventAttendance:
			newState = { ...newState, ministryEventAttendance: action.payload }
			break
		case MinistryInfoActionType.setGrants:
			newState = { ...newState, ministryGrants: action.payload }
			break
		case MinistryInfoActionType.setMinistryFiles:
			newState = { ...newState, ministryFiles: action.payload }
			break
		case MinistryInfoActionType.setNotes:
			newState = { ...newState, ministryNotes: action.payload }
			break
		case MinistryInfoActionType.setLeverageSummary:
			newState = { ...newState, leverageSummary: action.payload }
			break
		case MinistryInfoActionType.setProfileLog:
			newState = { ...newState, ministryProfileLog: action.payload }
			break
		// case MinistryInfoActionType.setGsReport:
		// 	newState = { ...newState, gsReport: action.payload }
		// 	break
		case MinistryInfoActionType.approveProfile:
			newState = {
				...newState,
				ministry: newState.ministry ? { ...newState.ministry, bApproved: true, movedToGrantByFirstName: null } : newState.ministry
			}
			break
		case MinistryInfoActionType.denyProfile:
			newState = {
				...newState,
				ministry: newState.ministry ? { ...newState.ministry, bApproved: false, movedToGrantByFirstName: null } : newState.ministry
			}
			break
	}

	return newState
}