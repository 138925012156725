import { Form, Formik, FormikProps } from 'formik'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { UserModel, UserPermissionLevel } from '../models/user'
import { IMetricsReportBranchGoalsDocument, IMiBranchModelDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { NumberField } from './forms'
import * as Yup from 'yup'
import { CommunityGrantingTargets } from './community-granting-targets'

interface ICommunityFormGoalsProps {
	community: IMiBranchModelDocument
}
export const CommunityGoals = (props: ICommunityFormGoalsProps) => {
	const { community } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [branchGoals, setBranchGoals] = useState<IMetricsReportBranchGoalsDocument>()

	const fetchBranchGoals = async () => {
		const branchGoalsQuery = await makeHttpRequestWithUi({
			request: appActions.MetricsReportBranchGoalsApi.apiMiBranchesIdMetricsReportBranchGoalsGet(community.branchId),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the goals for this community.'
		})

		if (branchGoalsQuery.status === 204) {
			const defaultBranchGoalsQuery = await makeHttpRequestWithUi({
				request: appActions.MetricsReportBranchGoalsApi.apiMetricsReportBranchGoalsGet(),
				disableSuccessToast: true,
				toastErrorMessage: 'There was an error retrieving the default goals for this community.'
			})

			setBranchGoals(defaultBranchGoalsQuery.data)
		} else {
			setBranchGoals(branchGoalsQuery.data)
		}
	}

	useEffect(() => {
		fetchBranchGoals()
		// eslint-disable-next-line
	}, [community])

	let _formikProps: FormikProps<any>
	useEffect(() => {
		if (_formikProps) _formikProps.resetForm()
		// eslint-disable-next-line
	}, [branchGoals])

	if (!branchGoals) return null

	const initialValues = {
		level1Involvement: branchGoals.level1Involvement !== null ? branchGoals.level1Involvement.toString() : '',
		level2Involvement: branchGoals.level2Involvement !== null ? branchGoals.level2Involvement.toString() : '',
		level3Involvement: branchGoals.level3Involvement !== null ? branchGoals.level3Involvement.toString() : '',
		activeMinistries: branchGoals.activeMinistries !== null ? branchGoals.activeMinistries.toString() : '',
		coachingMinistries: branchGoals.coachingMinistries !== null ? branchGoals.coachingMinistries.toString() : '',
		consultingMinistries: branchGoals.consultingMinistries !== null ? branchGoals.consultingMinistries.toString() : '',
		trWorkshopAttendance: branchGoals.trWorkshopAttendance !== null ? branchGoals.trWorkshopAttendance.toString() : '',
		trCoaching: branchGoals.trCoaching !== null ? branchGoals.trCoaching.toString() : '',
		trConsulting: branchGoals.trConsulting !== null ? branchGoals.trConsulting.toString() : '',
		coachingHoursPerQuarterCap: branchGoals.coachingHoursPerQuarterCap !== null ? branchGoals.coachingHoursPerQuarterCap.toString() : '',
		consultingHoursPerQuarterCap: branchGoals.consultingHoursPerQuarterCap !== null ? branchGoals.consultingHoursPerQuarterCap.toString() : '',
	}

	const validationSchema = Yup.object({
		level1Involvement: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		level2Involvement: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		level3Involvement: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		activeMinistries: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		coachingMinistries: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		consultingMinistries: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		trWorkshopAttendance: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		trCoaching: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		trConsulting: Yup.number().required('Required').min(1, 'Must be 1 or greater.'),
		coachingHoursPerQuarterCap: Yup.number().required('Required').min(0, 'Must be 0 or greater.'),
		consultingHoursPerQuarterCap: Yup.number().required('Required').min(0, 'Must be 0 or greater.'),
	})

	return (
		<div className='d-flex flex-column' style={{ height: '100%' }}>
			{UserModel.checkPermissionLevelForUser(12, UserPermissionLevel.Administrator, appState.currentUser) ?
				<React.Fragment>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={async (values, actions) => {

							if (branchGoals.branchId === 0) {
								// Using default goals and creating new goals for this branch.
								await makeHttpRequestWithUi({
									request: appActions.MetricsReportBranchGoalsApi.apiMetricsReportBranchGoalsPost({
										branchId: community.branchId,
										level1Involvement: branchGoals.level1Involvement!,
										level2Involvement: branchGoals.level2Involvement!,
										level3Involvement: branchGoals.level3Involvement!,
										activeMinistries: branchGoals.activeMinistries!,
										coachingMinistries: branchGoals.coachingMinistries!,
										consultingMinistries: branchGoals.consultingMinistries!,
										trWorkshopAttendance: branchGoals.trWorkshopAttendance!,
										trSeminarAttendance: branchGoals.trSeminarAttendance!,
										trGroupCoaching: branchGoals.trGroupCoaching!,
										trCoaching: branchGoals.trCoaching!,
										trConsulting: branchGoals.trConsulting!,
										trTotal: branchGoals.trTotal!,
										coachingHoursPerQuarterCap: branchGoals.coachingHoursPerQuarterCap!,
										consultingHoursPerQuarterCap: branchGoals.consultingHoursPerQuarterCap!,
									}),
									toastSuccessMessage: 'Successfully created community goals.',
									toastErrorMessage: 'There was an error creating goals for this community.'
								})
							} else {
								// Updating existing goals

								await makeHttpRequestWithUi({
									request: appActions.MetricsReportBranchGoalsApi.apiMetricsReportBranchGoalsIdPut(branchGoals.metricsReportBranchGoalId, {
										...branchGoals,
										branchId: community.branchId,
										level1Involvement: parseInt(values.level1Involvement),
										level2Involvement: parseInt(values.level2Involvement),
										level3Involvement: parseInt(values.level3Involvement),
										activeMinistries: parseInt(values.activeMinistries),
										coachingMinistries: parseInt(values.coachingMinistries),
										consultingMinistries: parseInt(values.consultingMinistries),
										trWorkshopAttendance: parseInt(values.trWorkshopAttendance),
										trCoaching: parseInt(values.trCoaching),
										trConsulting: parseInt(values.trConsulting),
										coachingHoursPerQuarterCap: parseInt(values.coachingHoursPerQuarterCap),
										consultingHoursPerQuarterCap: parseInt(values.consultingHoursPerQuarterCap),
									}),
									toastSuccessMessage: 'Successfully updated community goals.',
									toastErrorMessage: 'There was an error updating community goals.',
								})
							}

							fetchBranchGoals()
						}}
					>
						{formikProps => {
							_formikProps = formikProps
							return (
								<Form className='d-flex flex-column m-2'>
									{branchGoals.branchId === 0 ? <p><i>Goals have not yet been set for this Community. Showing default Goal options below.</i></p> : null}
									<div className='d-flex my-2'>
										<div className='border p-3 mr-2' style={{ flex: 1 }}>
											<b className='text-primary'>Involvement Targets (These targets override the National Targets)</b>
											<NumberField fieldProps={{ name: 'level1Involvement', label: 'Level 1 Average Involvement Score Target' }} />
											<NumberField fieldProps={{ name: 'level2Involvement', label: 'Level 2 Average Involvement Score Target' }} />
											<NumberField fieldProps={{ name: 'level3Involvement', label: 'Level 3 Average Involvement Score Target' }} />
										</div>
										<div className='border p-3 ml-2' style={{ flex: 1 }}>
											<b className='text-primary'>Attendance Targets (These targets override the National Targets)</b>
											<NumberField fieldProps={{ name: 'activeMinistries', label: 'Active Ministries', labeltooltip: 'The total number of unique ministries attending any type of training event in the past 12 months.' }} />
											<NumberField fieldProps={{ name: 'coachingMinistries', label: 'Coaching Ministries', labeltooltip: 'The total number of unique ministries that have participated in any type of coaching/consulting in the past 12 months.' }} />
											<NumberField fieldProps={{ name: 'consultingMinistries', label: 'Consulting Ministries', labeltooltip: 'The total number of unique ministries that have participated in strategic or grant related consulting (including site visits) in the past 12 months.' }} />
										</div>
									</div>
									<div className='d-flex my-2'>
										<div className='border p-3 mr-2' style={{ flex: 1 }}>
											<b className='text-primary'>Training Report Quarterly Goals</b>
											<NumberField fieldProps={{ name: 'trWorkshopAttendance', label: 'Workshop/Webinar Attendance (Orgs)' }} />
											<NumberField fieldProps={{ name: 'trCoaching', label: '1-1 Coaching (Hours)' }} />
											<NumberField fieldProps={{ name: 'trConsulting', label: 'Consulting (Hours)' }} />
										</div>
										<div className='border p-3 ml-2' style={{ flex: 1 }}>
											<b className='text-primary'>Training Report Caps</b>
											<NumberField fieldProps={{ name: 'coachingHoursPerQuarterCap', label: 'Coaching Hours per Quarter Cap' }} />
											<NumberField fieldProps={{ name: 'consultingHoursPerQuarterCap', label: 'Consulting Hours per Quarter Cap' }} />
											<i>(enter a 0 to remove the caps)</i>
										</div>
									</div>
									<div className='my-4'>
										<button style={{ minWidth: 300 }} type='submit' className='btn btn-primary'>{branchGoals.branchId === 0 ? 'Create' : 'Update'} Goals</button>
									</div>
								</Form>
							)
						}}
					</Formik>

					<div className='m-2' style={{ flex: 1 }}>
						<hr />
						<h4 className='my-2'>Granting Targets</h4>

						<CommunityGrantingTargets community={community} />
					</div>
				</React.Fragment>
				:
				null
			}
		</div>
	)
}