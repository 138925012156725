import { useContext, useEffect, useState } from "react"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, openUrlInNewTab, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { Grid } from "./grid"
import { gridReducer } from "../stores/grid-reducer"
import { Loading } from "./loading"
import { DefaultGridCellDisplay, GridActionCell, ProgressGridCellDisplay } from "./grid-cell-displays"
import { EnvolpeIcon, IncognitoIcon, PersonIcon } from "../assets"
import { COURSE_STATUS_OPTIONS } from "../constants"
import { ContactInfoContext } from "./contact-info"
import { IContactInfoState } from "../stores/contact-info-definitions"
import { IQueryState } from "../stores/api-actions"
import { AppStateContext } from "../app-store-provider"
import { useModal } from "../services/hooks"
import { LmsSendEmailModal } from "./lms-send-email-modal"

export const ContactInfoCourses = () => {
	const { state: contactInfoState, actions: contactInfoActions } = useContext(ContactInfoContext)!
	const appState = useContext(AppStateContext)

    const [sendLmsEmailModal, showHideSendLmsEmailModal] = useModal()
	const [selectedRow, setSelectedRow] = useState<IGridListItem | null>(null)

	const dataSource: GridDataFetch<IContactInfoState> = async (queryState, _state) => {
		const { contactCourses } = _state

		if (!contactCourses) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(contactCourses, queryState.sorts)

		let rows: IGridListItem[] = contactCourses.map(o => ({
			id: o.userId.toString() + o.courseId.toString(),
			values: {
				courseId: o.courseId,
				userDisplayName: o.userDisplayName,
				userEmail: o.userEmail,
				userMinistryName: o.userMinistryName,
				userMinistryId: o.userMinistryId,
				userRegistered: o.userRegistered,
				courseStatus: o.courseStatus,
				courseProgressPercentage: o.courseProgressPercentage,
				courseTitle: o.courseTitle,
				groupManagementUrl: o.groupManagementUrl,
				userProfileUrl: o.userProfileUrl,
				groupCommunityAbbr: o.groupCommunityAbbr,
				impersonationUrl: o.impersonationUrl,
			}
		}))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	let queryState: IQueryState | undefined = appState.currentUserSessionState?.gridUserInteractionStates?.[GridUserInteractionStateKey.MinistryContactCourses]?.queryState
	if (!queryState) queryState = { ...defaultGridState.queryState }
	if (!queryState.filters) queryState.filters = []

	queryState.filters.push({
		id: 'courseStatus-in',
		enabled: true,
		value: ['Completed', 'In Progress'],
		operator: 'in',
		property: 'courseStatus',
	})

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		queryState,
		rowSelectEnabled: false,
		columns: [
			{
				property: 'groupCommunityAbbr',
				title: 'Community',
				width: 90,
				type: 'string',
				allowFilters: true,
				filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
				render: DefaultGridCellDisplay,
			},
			{
				property: 'courseTitle',
				title: 'Course',
				width: 100,
				type: 'string',
				render: (col, row) => row.values.groupManagementUrl ? <a href={`${row.values.groupManagementUrl}`} target="_blank">{row.values[col.property]}</a> : <>{row.values[col.property]}</>,
				allowFilters: true,
			},
			{
				property: 'courseStatus',
				title: 'Status',
				width: 100,
				type: 'string',
				render: (_, row) => {
					if (!row.values.courseStatus) return <></>
					const courseStatus = row.values.courseStatus
					let textColor = 'text-danger'
					if (courseStatus === "In Progress") textColor = 'text-warning'
					if (courseStatus === "Completed") textColor = 'text-success'

					return <span className={textColor}>{row.values.courseStatus}</span>
				},
				allowFilters: true,
				filterOptions: COURSE_STATUS_OPTIONS
			},
			{
				property: 'courseProgressPercentage',
				title: 'Progress',
				width: 100,
				type: 'number',
				allowFilters: true,
				render: ProgressGridCellDisplay
			},
			{
                property: 'grid_actions',
                type: 'actions',
                width: 110,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
		],
		disabledPagination: true,
		dataSource,
		rowActions: {
            viewProfile: {
                id: 'viewProfile',
                tooltipText: "View contact's LMS profile",
                icon: <PersonIcon />,
                action: async (options) => {
                    options.e.stopPropagation()
					if (options.row.values.userProfileUrl) window.open(options.row.values.userProfileUrl.toString())
                },
				disabled: (row) => !row.values.userProfileUrl,
            },
			impersonateUser: {
				id: 'impersonateUser',
				tooltipText: 'Impersonate User',
				icon: <IncognitoIcon />,
				action: async (options) => {
					options.e.stopPropagation()

					openUrlInNewTab(options.row.values.impersonationUrl?.toString() ?? '')
				},
				disabled: (row) => !row.values.impersonationUrl
			},
			sendCourseInvite: {
				id: 'sendCourseInvite',
				tooltipText: 'Send Course Invite Email',
				icon: <EnvolpeIcon />,
				action: async (options) => {
					options.e.stopPropagation()

					showHideSendLmsEmailModal(true)
					setSelectedRow(options.row)
				},
				disabled: (row) => !row.values.impersonationUrl
			}
        },
	}

	useEffect(() => {
		contactInfoActions.fetchContactLmsUserCourses()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [contactInfoState.contactCourses])


	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, contactInfoState)

	if (!contactInfoState.contactCourses || !contactInfoState.contact) return <Loading />

	return (
		<>
			<p className='m-2'>This is a list of courses that the contact is currently enrolled in. If a course is newly assigned to this contact, it may not show up here until after the next time they log into the LMS.<br />
			Courses are available at <a href="https://learn.missionincrease.org">https://learn.missionincrease.org</a></p>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			<LmsSendEmailModal modalProps={sendLmsEmailModal} courseId={selectedRow?.values.courseId as number || 0} ministryContactIds={[contactInfoState.contactId || 0]} />
		</>
	)
}
