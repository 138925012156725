import { Form, Formik, FormikProps } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IEventNewsDocument, IEventNewsPostModelDocument, INewsletterMonthDocument } from '../open-api'
import * as Yup from 'yup'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import moment from 'moment'
import { CheckboxField, CommunitySelectField, SelectField, TinyMceField } from './forms'
import { FormikEffect } from './formik-effect'
import { Loading, LoadingPropsSizeEnum } from './loading'
import { buildTooltipProps, uuidv4 } from '../services/helpers'

export enum EventNewsItemAfterSaveAction {
	preview,
	sendPreview,
	sendBlast,
	sendBlastWithOverride,
	save,
}

interface IEventNewsItemFormProps {
	eventNewsItemToEdit?: IEventNewsDocument
	onSaveSuccess?: (afterSaveAction: { savedEventNewsItemId: number, action: EventNewsItemAfterSaveAction }) => void
}

const monthYearDropdownDateFormat = 'MMM YYYY'

export const EventNewsItemForm = (props: IEventNewsItemFormProps) => {
	const { onSaveSuccess, eventNewsItemToEdit } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const hasBeenSent = !!eventNewsItemToEdit?.dateSent

	const initialValues = {
		branchId: eventNewsItemToEdit?.branchId.toString() || appState.globalCommunityContext?.branchId.toString() || '',
		monthYear: !!eventNewsItemToEdit ? moment(`${eventNewsItemToEdit.month} ${eventNewsItemToEdit.year}`, 'M YYYY').format(monthYearDropdownDateFormat) : '',
		includeCustomMessage: !!eventNewsItemToEdit?.message,
		message: eventNewsItemToEdit?.message || '',
		reviewed: eventNewsItemToEdit ? eventNewsItemToEdit.reviewed : false,
		readyToSend: eventNewsItemToEdit ? eventNewsItemToEdit.readyToSend : false,
		preview: false,
		sendPreview: false,
		sendPreviewEmail: '',
		sendBlast: false,
		ignoreFirstTuesdayRule: false,
	}

	const validationSchema = Yup.object({
		branchId: Yup.string().required('Required'),
		monthYear: Yup.string().required('Required'),
		message: Yup.string().test(
			'message',
			`Please enter a message.`,
			function (value) {
				if (this.parent.includeCustomMessage) {
					return !!value
				}
				return true
			}
		),
		sendPreviewEmail: Yup.string().test(
			'sendPreviewEmail',
			`Required`,
			function (value) {
				if (this.parent.sendPreview) {
					return !!value
				}
				return true
			}
		).email('Please enter a valid email address.')
	})

	const [availableNewsletterMonths, setAvailableNewsletterMonths] = useState<INewsletterMonthDocument[]>()
	const [loadingAvailableNewslettersMonths, setLoadingAvailableNewslettersMonths] = useState(false)
	const fetchAvailableNewsletterMonths = async (branchId: number) => {
		setAvailableNewsletterMonths(undefined)
		setLoadingAvailableNewslettersMonths(true)
		const availableNewsletterMonthsQuery = await makeHttpRequestWithUi({
			request: appActions.EventNewsApi.apiMiBranchesBranchIdAvailableNewsletterMonthsGet(branchId),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error getting available newsletter months for this community.',
		})

		setAvailableNewsletterMonths(availableNewsletterMonthsQuery.data)
		setLoadingAvailableNewslettersMonths(false)
	}

	let _formikProks: FormikProps<any>

	useEffect(() => {
		if (_formikProks) _formikProks.resetForm()
	}, [eventNewsItemToEdit])

	useEffect(() => {
		if (appState.globalCommunityContext) fetchAvailableNewsletterMonths(appState.globalCommunityContext.branchId)
	}, [appState.globalCommunityContext])

	const [unsavedChanges, setUnsavedChanges] = useState(false)

	return (
		<React.Fragment>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize={true}
				validateOnMount
				onSubmit={async (values, actions) => {
					let savedEventNewsItemId: number | undefined = eventNewsItemToEdit?.eventNewsId

					const eventNewsItem: IEventNewsPostModelDocument = {
						branchId: parseInt(values.branchId),
						month: moment(values.monthYear, monthYearDropdownDateFormat).month() + 1,
						year: moment(values.monthYear, monthYearDropdownDateFormat).year(),
						message: values.includeCustomMessage ? values.message : null,
						reviewed: values.reviewed,
						readyToSend: values.readyToSend,
					}

					if (!!eventNewsItemToEdit) {
						await makeHttpRequestWithUi({
							request: appActions.EventNewsApi.apiEventNewsIdPut(eventNewsItemToEdit.eventNewsId, { ...eventNewsItemToEdit, ...eventNewsItem }),
							toastErrorMessage: 'There was an error updating event news item.',
							toastSuccessMessage: 'Successfully updated event news item.',
						})
					} else {
						savedEventNewsItemId = (await makeHttpRequestWithUi({
							request: appActions.EventNewsApi.apiEventNewsPost(eventNewsItem),
							toastErrorMessage: 'There was an error creating event news item.',
							toastSuccessMessage: 'Successfully created event news item.',
						})).data.eventNewsId
					}

					if (values.preview && !!savedEventNewsItemId) {
						if (onSaveSuccess) onSaveSuccess({ savedEventNewsItemId, action: EventNewsItemAfterSaveAction.preview })
					} else if (values.sendPreview && !!savedEventNewsItemId) {
						if (onSaveSuccess) onSaveSuccess({ savedEventNewsItemId, action: EventNewsItemAfterSaveAction.sendPreview })
					} else if (values.sendBlast && !!savedEventNewsItemId && !values.ignoreFirstTuesdayRule) {
						if (onSaveSuccess) onSaveSuccess({ savedEventNewsItemId, action: EventNewsItemAfterSaveAction.sendBlast })
					} else if (values.sendBlast && !!savedEventNewsItemId && values.ignoreFirstTuesdayRule) {
						if (onSaveSuccess) onSaveSuccess({ savedEventNewsItemId, action: EventNewsItemAfterSaveAction.sendBlastWithOverride })
					}
					else if (onSaveSuccess && savedEventNewsItemId) onSaveSuccess({ savedEventNewsItemId, action: EventNewsItemAfterSaveAction.save })
				}}
			>
				{formikProps => {
					_formikProks = formikProps
					return (
						<Form>
							<FormikEffect formikProps={formikProps} onChange={(prev, next) => {
								if (prev.branchId !== next.branchId && !Number.isNaN(parseInt(next.branchId)) && !eventNewsItemToEdit) {
									formikProps.setFieldValue('monthYear', '')
									fetchAvailableNewsletterMonths(parseInt(next.branchId))
								}

								/* 
									Since this callback is called INSIDE the render, need to be careful it has checks
									that prevent it from being called in an infinite loop. Also, since this Effect
									fires on every keystroke, also just generally wise to only set some state when
									the exact conditions are fulfilled.
								*/
								if (!!eventNewsItemToEdit && eventNewsItemToEdit.message !== next.message && !unsavedChanges) setUnsavedChanges(true)
								if (!!eventNewsItemToEdit && eventNewsItemToEdit.message === next.message && unsavedChanges) setUnsavedChanges(false)

								if (!next.reviewed) formikProps.setFieldValue('readyToSend', false)

								// onChange fires on initial form load. Only call this if branchId is filled in.
								if (prev.branchId !== '' && (!prev.reviewed && next.reviewed)) appActions.addAlert({id: uuidv4(), body: 'Once marked (and saved) as AD Reviewed, this news item will not be able to be edited without contacting Karla Roe.'})
							}} />

							<div className='d-flex'>
								<div style={{ flex: 1 }} className='mr-2'>
									<CommunitySelectField disabled={!!eventNewsItemToEdit || !!appState.globalCommunityContext} fieldProps={{ name: 'branchId', label: 'Community' }} />
								</div>
								<div style={{ flex: 1 }}>
									{!loadingAvailableNewslettersMonths || !!eventNewsItemToEdit ?
										<SelectField
											disabled={!!eventNewsItemToEdit || !formikProps.values.branchId}
											fieldProps={{ name: 'monthYear', label: 'Month', placeholder: !!formikProps.values.branchId ? 'Select a month...' : 'Select a community to view available months...' }}
											options={eventNewsItemToEdit ?
												[{
													label: moment(`${eventNewsItemToEdit.month} ${eventNewsItemToEdit.year}`, 'M YYYY').format(monthYearDropdownDateFormat),
													value: moment(`${eventNewsItemToEdit.month} ${eventNewsItemToEdit.year}`, 'M YYYY').format(monthYearDropdownDateFormat)
												}]
												:
												availableNewsletterMonths?.map(o => {
													const value = moment(`${o.month} ${o.year}`, 'M YYYY').format(monthYearDropdownDateFormat)
													return { value, label: value }
												})
											}
										/>
										:
										<Loading size={LoadingPropsSizeEnum.small} />
									}
								</div>
							</div>

							<CheckboxField disabled={hasBeenSent} fieldProps={{ label: 'Add custom message', name: 'includeCustomMessage' }} />
							{formikProps.values.includeCustomMessage ?
								<div>
									<TinyMceField disabled={hasBeenSent || (formikProps.values.reviewed && !appState.currentUser?.bSuperUser)} fieldProps={{ name: 'message', label: 'Message' }} />
									{unsavedChanges ? <small className='text-danger'>You have unsaved changes.</small> : null}
								</div>
								:
								null
							}

							<div className='d-flex mt-2'>
								<div className='mr-4'>
									<CheckboxField disabled={hasBeenSent || (formikProps.values.reviewed && !appState.currentUser?.bSuperUser)} fieldProps={{ name: 'reviewed', label: 'AD Reviewed' }} />
								</div>
								{appState.currentUser?.bSuperUser && formikProps.values.reviewed ?
									<CheckboxField disabled={hasBeenSent} fieldProps={{ name: 'readyToSend', label: 'Ready to Send' }} />
									:
									null
								}
							</div>

							{!hasBeenSent ?
								<div className='d-flex mt-2'>
									<button
										disabled={!formikProps.isValid}
										type='submit'
										style={{ minWidth: 200 }}
										className='btn btn-primary mr-2'
									>
										Save
									</button>

									<button
										type='button'
										style={{ minWidth: 200 }}
										onClick={() => {
											formikProps.setFieldValue('preview', true)
											formikProps.submitForm()
										}}
										disabled={!formikProps.isValid}
										className='btn btn-primary mr-2'
									>
										Preview
								</button>

									<button
										type='button'
										style={{ minWidth: 200 }}
										onClick={() => {
											formikProps.setFieldValue('sendPreview', true)
											formikProps.submitForm()
										}}
										disabled={!formikProps.isValid}
										className='btn btn-primary mr-2'
									>
										Send Preview
								</button>

									{formikProps.values.readyToSend ?
										<React.Fragment>
											<button
												type='button'
												style={{ minWidth: 200 }}
												onClick={() => {
													formikProps.setFieldValue('sendBlast', true)
													formikProps.submitForm()
												}}
												disabled={!formikProps.isValid || formikProps.values.monthYear !== moment().format(monthYearDropdownDateFormat) && !formikProps.values.ignoreFirstTuesdayRule}
												className='btn btn-primary mr-2'
												{...buildTooltipProps({ tooltipText: `This button will be enabled when the current month and year matches the news item's month and year.` })}
											>
												Send Blast
											</button>
											<CheckboxField disabled={hasBeenSent} fieldProps={{ name: 'ignoreFirstTuesdayRule', label: 'Override First Tuesday Restriction' }} />
										</React.Fragment>
										:
										null
									}
								</div>
								:
								null
							}
						</Form>
					)
				}}
			</Formik>
		</React.Fragment>
	)
}
