import React from 'react'
import { IPartnerQuestionsModelDocument, IPartnerFieldsForExportModelDocument, IFieldDropdownValueModelDocument, IPastYearsFieldModelDocument, IMinistryDataModelDocument, IZCountrysDocument, IZStatesDocument, IMinistrySummaryResponseModelDocument } from '../open-api'
import { TextField, SelectField, TextareaField, YesNoField, CheckboxField } from './forms'
import { QuestionPastThreeYears } from './question-past-three-years'
import { FormikProps } from 'formik'
import { QuestionModel } from '../models/question'
import { CANADA_COUNTRY_ID, MINISTRY_INFO_PROFILE_QUESTIONS, UNITED_STATES_COUNTRY_ID } from '../constants'

interface IMininstryInfoProfilePageQuestionProps {
	ministry: IMinistrySummaryResponseModelDocument
	question: IPartnerQuestionsModelDocument
	questions: IPartnerQuestionsModelDocument[]
	dropdownOptions: IFieldDropdownValueModelDocument[] | null
	pastYearsFields: IPastYearsFieldModelDocument[] | null
	activeMinistryData: IMinistryDataModelDocument
	fiscalYearRange: number[] | null
	countries?: IZCountrysDocument[]
	states?: IZStatesDocument[]
	formikProps: FormikProps<{}>
}
export const MininstryInfoProfilePageQuestion = (props: IMininstryInfoProfilePageQuestionProps) => {
	const { question } = props

	if (question.bGetPast3Years) {
		return <QuestionPastThreeYears {...props} />
	} else {
		if (question.fields && question.fields.length > 1) {
			return (
				<div className='mb-4'>
					<div className='mb-2' dangerouslySetInnerHTML={{ __html: question.question || '' }}></div>
					<div className='ml-2'>
						{question.fields?.map(field => <QuestionField key={field.fieldId} field={field} {...props} />)}
					</div>
				</div>
			)

		} else {
			return <React.Fragment>
				{question.fields?.map(field => <QuestionField key={field.fieldId} field={field} {...props} />)}
			</React.Fragment>
		}
	}
}

interface IQuestionFieldProps extends IMininstryInfoProfilePageQuestionProps {
	field: IPartnerFieldsForExportModelDocument
}
const QuestionField = (props: IQuestionFieldProps) => {
	const { question, field, dropdownOptions, countries, states, formikProps } = props

	let questionField = <></>

	switch ((field.fieldType as any).id) {
		case 1: // LargeTextField
			if (field.fieldName) questionField = <TextareaField fieldProps={{ name: field.fieldName, label: field.fieldLabel || question.question || '' }} minHeight='200px' />
			break
		case 2: // TextField
			if (field.fieldName) {
				if (question.questionId === MINISTRY_INFO_PROFILE_QUESTIONS.PhysicalStateOther ) {
					const physicalCountryId = formikProps.getFieldProps('physicalCountryId').value;
					questionField = <>
						{physicalCountryId != UNITED_STATES_COUNTRY_ID && physicalCountryId != CANADA_COUNTRY_ID &&
							<TextField fieldProps={{ name: field.fieldName, label: field.fieldLabel || question.question || '' }} />
						}
					</>
				} else if (question.questionId === MINISTRY_INFO_PROFILE_QUESTIONS.MailingStateOther) {
					const mailingCountryId = formikProps.getFieldProps('MailingCountryId').value;
					questionField = <>
						{mailingCountryId != UNITED_STATES_COUNTRY_ID && mailingCountryId != CANADA_COUNTRY_ID &&
							<TextField fieldProps={{ name: field.fieldName, label: field.fieldLabel || question.question || '' }} />
						}
					</>
				} else {
					questionField = <TextField fieldProps={{ name: field.fieldName, label: field.fieldLabel || question.question || '' }} />
				}
			}
			break
		case 3: // YesNo
			if (field.fieldName) questionField = <YesNoField fieldProps={{ name: field.fieldName, label: field.fieldLabel || question.question || '' }} setFieldValue={formikProps.setFieldValue} />
			break
		case 4: // DropDownBox
			const dropDownValue = dropdownOptions?.find(v => v.fieldId === field.fieldId)
			if (dropDownValue && dropDownValue.dropdownOptions && field.fieldName) {
				if (states && question.questionId === MINISTRY_INFO_PROFILE_QUESTIONS.PhysicalStateId) {
					const physicalCountryId = formikProps.getFieldProps('physicalCountryId').value;
					questionField = <>
						{(physicalCountryId == UNITED_STATES_COUNTRY_ID || physicalCountryId == CANADA_COUNTRY_ID) &&
							<SelectField
							fieldProps={{ label: field.fieldLabel || question.question || '', name: field.fieldName, placeholder: 'Please select a value' }}
							options={states.filter(state => state.countryId == physicalCountryId).map(state => ({ label: `${state.state}`, value: `${state.stateId}` }))}
						/>
						}
					</>
				} else if (states && question.questionId === MINISTRY_INFO_PROFILE_QUESTIONS.MailingStateId) {
					const mailingCountryId = formikProps.getFieldProps('MailingCountryId').value;
					questionField = <>
						{(mailingCountryId == UNITED_STATES_COUNTRY_ID || mailingCountryId == CANADA_COUNTRY_ID) &&
							<SelectField
							fieldProps={{ label: field.fieldLabel || question.question || '', name: field.fieldName, placeholder: 'Please select a value' }}
							options={states.filter(state => state.countryId == mailingCountryId).map(state => ({ label: `${state.state}`, value: `${state.stateId}` }))}
						/>
						}
					</>
				} else {
					questionField =
						<SelectField
							fieldProps={{ label: field.fieldLabel || question.question || '', name: field.fieldName, placeholder: 'Please select a value' }}
							options={dropDownValue.dropdownOptions.map(opt => { return { label: opt.name || '', value: opt.id || '' } })}
						/>
				}
			}
			break
		case 7: // DropDownMulti
			/* 
				There are only two DropdownMulti questions:
					1. In what countries are your services offered (question id 44)
					2. In what states are your services offered (question id 45)
			*/
			if (question.questionId === 44 && countries) {
				questionField =
					<SelectField
						fieldProps={{ label: field.fieldLabel || question.question || '', name: field.fieldName || '' }}
						options={countries.map(country => ({ label: `${country.country}`, value: `${country.countryId}` }))}
						multiple={true}
					/>
			}
			if (question.questionId === 45 && states) {
				questionField =
					<>
						{formikProps.getFieldProps('CountryID').value?.includes(UNITED_STATES_COUNTRY_ID.toString()) && <SelectField
						fieldProps={{ label: field.fieldLabel || question.question || '', name: field.fieldName || '' }}
						options={states.filter(state => state.countryId === UNITED_STATES_COUNTRY_ID).map(state => ({ label: `${state.state}`, value: `${state.stateId}` }))}
						multiple={true}
					/>}
					</>
			}
			break
		case 8: // Checkbox
			// Currently there is only one active checkbox question
			if (QuestionModel.isDirectorActivities(question) && field.defaultValue) {
				questionField = <CheckboxField fieldProps={{ label: field.fieldLabel || question.question || '', name: QuestionModel.getFormFieldNameForDirectorActivitiesField(field.fieldName, field.defaultValue) }} />
			}
			break
		case 9: // FileUpload
			if (field.fieldName) questionField =
				<div className='mb-4'>
					<div className='mb-2' dangerouslySetInnerHTML={{ __html: question.question || '' }}></div>
					<div className='ml-2 text-muted'>
						{formikProps.getFieldProps(field.fieldName).value}
					</div>
				</div>
			break
	}

	return questionField
}