

// An artificial delay used for throttling automatic actions based on user keystrokes (e.g. typing in a search field that would trigger an API call)
export const AUTO_QUERY_DELAY_MS = 750

// This is a max on the total number of existing Ministries
export const MAX_MINISTRY_COUNT = 100000

// Ministry Info > Profile > General page IDs
export const MINISTRY_INFO_PROFILE_GENERAL_PAGE_IDS = [10, 19, 46]
// Ministry Info > Profile > Fundraising page IDs
export const MINISTRY_INFO_PROFILE_FUNDRAISING_PAGE_IDS = [2, 13, 40, 51, 56]

// Template Variables for Approval/Denial Emails
export const APPROVE_DENY_EMAIL_TEMPLATE_VARIABLES = [
	'legalName',
	'dba',
	'today',
	'firstName',
	'lastName',
	'areaDirectorFirstName',
	'areaDirectorLastName',
	'areaDirectorEmail',
	'areaDirectorPhone',
	'salutation',
	'physicalAddress1',
	'physicalCity',
	'physicalState',
	'physicalPostalCode',
]

export const UNITED_STATES_COUNTRY_ID = 211
export const CANADA_COUNTRY_ID = 36
export const UK_COUNTRY_ID = 76

export enum MINISTRY_INFO_PROFILE_QUESTIONS {
	"PhysicalStateId" = 70,
	"PhysicalStateOther" = 118,
	"MailingStateId" = 76,
	"MailingStateOther" = 120,
}

export const IRS990_FILE_DESCRIPTION_ID = 24
export const FY_BUDGET_FILE_DESCRIPTION_ID = 15

export const MAX_FILE_SIZE = 15728640 // 15MB -> 15 * 1024 * 1024

export const TOAST_ANIMATION_DURATION = 400

export const IS_PRODUCTION = !!process.env.REACT_APP_API_URL

export const MINISTRY_PORTAL_ROOT_URL = IS_PRODUCTION ? 'https://ministries.mif.org' : 'https://localhost:44323'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAfGQT3cD4D4DNDIJ_U6HPw3nxyBnfgvtc'

export const SERVER_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:00'

export const IDP_REDIRECT_URI = IS_PRODUCTION ? process.env.REACT_APP_IDP_REDIRECT_URI : 'http://localhost:3000' // 'https://staging-admin.mif.org'
//export const IDP_REDIRECT_URI = 'https://staging-admin.mif.org' //JLITTLE This needs to be reset 

export const IDP_AUTHORITY = IS_PRODUCTION ? process.env.REACT_APP_IDP_AUTHORITY : 'https://mi-idp-dev.azurewebsites.net' // 'https://mi-idp-staging.azurewebsites.net' // 
export const IDP_CLIENT_ID = 'growthtrack'

// These timezones used here are created the following way:
// 		value: Windows Time Zone (used by C#'s TimeZoneInfo.FindSystemTimeZoneById()). This is what is stored in MI_Branches table.
// 		label: tz database timezone (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones). We use the canonical version of each one. I believe all non-Windows systems use this.
export const BRANCH_TIMEZONE_OPTIONS = [
	{
		value: 'Hawaiian Standard Time',
		label: 'Pacific/Honolulu'
	},
	{
		value: 'Alaskan Standard Time',
		label: 'America/Anchorage'
	},
	{
		value: 'Pacific Standard Time',
		label: 'America/Los Angeles'
	},
	{
		value: 'US Mountain Standard Time',
		label: 'America/Phoenix'
	},
	{
		value: 'Mountain Standard Time',
		label: 'America/Denver'
	},
	{
		value: 'Central Standard Time',
		label: 'America/Chicago'
	},
	{
		value: 'Eastern Standard Time',
		label: 'America/New York'
	},
	{
		value: 'US Eastern Standard Time',
		label: 'America/Indiana/Indianapolis'
	},
	{
		value: 'GMT Standard Time',
		label: 'Europe/London'
	}
]

export const DATA_VISUALIZATION_COLORS = [
	'a21700', // TB 20221205 - Added higher contrast color specifically for Leverage Summary report. Colors were running together.
	'008BA2',
	'303B42',
	'3B61AD',
	'76818c',
	'43e8e0',
	'0377a8',
	'636687',
	'8ae6cf',
	'2477f2',
	'3c8db0',
	'282b33',
	'637794',
	'0e628a',
	'546a96',
	'1951e0',
	'323240',
	'8bf7e7',
	'1b57c4',
	'2b7873',
	'4b94cc',
	'030405',
	'3f7eb5',
	'4b706c',
	'151c1c',
	'48525e',
	'09e3a2',
	'9fa1a6',
	'144b54',
	'1A4F44',
	'11A1A8',
	'04756A',
]

export enum EVENT_TYPES {
	Seminar = 1,
	Workshop = 2,
	SmallGroupSession = 3,
	Webinar = 5,
	Consulting = 6,
	GroupLearning =7,
	None = -1
}

export enum EventContentType {
    'Workshop' = 2,
    'Webinar' = 5,
    'Small Group Session' = 3,
    'Group Learning' = 7
}

export const EVENT_CONTENT_TYPES = [
    {
		value: '7',
		label: 'Group Learning'
	},
	{
		value: '2',
		label: 'Workshop'
	},
	{
		value: '5',
		label: 'Webinar'
	},
	{
		value: '3',
		label: 'Small Group Session'
	}
]

export enum EventContentWebinarProvider {
    'Zoom' = 4,
}

export enum EventContentCategory {
    'Major Gifts' = 2,
    'Donor Acquisitions' = 26,
    'Communications' = 27,
    'Ministry Leadership' = 28
}

export const EVENT_TOPICS: { eventTopicId: number, topic: string, eventCategoryId: number }[] = [
    {
        eventTopicId: 48,
        topic: 'Other',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 47,
        topic: 'Other',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 46,
        topic: 'Other',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 45,
        topic: 'Other',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 44,
        topic: 'Church Partnership',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 43,
        topic: 'Your Annual Communications Plan',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 42,
        topic: 'Welcome package',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 41,
        topic: 'Grant seeking',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 40,
        topic: 'Board Governance',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 39,
        topic: 'Integrated Ministry Communications',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 38,
        topic: 'Marketing-SPP',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 37,
        topic: 'Coaching Champions',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 36,
        topic: 'Feasibility study',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 35,
        topic: 'Direct Mail',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 34,
        topic: 'Virtual gatherings/campaigns',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 33,
        topic: 'P-E-O',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 32,
        topic: 'Capital Campaigns',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 31,
        topic: 'Thanking and Receipting',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 30,
        topic: 'House Parties',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 29,
        topic: 'The TG Ask',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 28,
        topic: 'Case for Support',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 27,
        topic: 'E-communications/Infographics',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 26,
        topic: 'Events',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 25,
        topic: 'The Major Gifts Meeting',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 24,
        topic: 'Your Fundraising Plan',
        eventCategoryId: 28,
    },
    {
        eventTopicId: 21,
        topic: 'Inviting Major Gifts',
        eventCategoryId: 2,
    },
    {
        eventTopicId: 22,
        topic: 'Leveraging Fundraising Events',
        eventCategoryId: 26,
    },
    {
        eventTopicId: 23,
        topic: 'Newsletters',
        eventCategoryId: 27,
    },
    {
        eventTopicId: 49,
        topic: 'Digital Marketing',
        eventCategoryId: 29,
    },
]

export enum MinistryContactType {
	Staff = 1,
	"Volunteer/Champion" = 4,
	Board = 2,
}

export enum NOTE_TYPES {
	"Admin" = 16,
	"Admin Review" = 15,
	"Call List" = 4,
	"Consulting" = 7,
	"Email" = 9,
	"Prayer Request" = 8,
	"Private" = 2,
	"Public" = 1,
	"Profile" = 6,
	"Small Group Session" = 10,
}

export enum GRANT_REQUEST_STATUS {
	"Request In Progress" = 1,
	"Request Submitted" = 2,
	"AD Approved" = 3,
	"Ready For Review" = 4,
	"Submitted to Board" = 5,
	"Board Approved" = 12,
	"In Matching Period" = 6,
	"Queued For Funding" = 7,
	"Ready For Funding" = 8,
	"Grant Denied" = 9,
	"Request Tabled" = 10,
	"Grant Complete" = 11
}

export const EVENT_EVAL_RATINGS_WORKSHOP = [
	{
		value: 4,
		label: 'Excellent'
	},
	{
		value: 3,
		label: 'Good'
	},
	{
		value: 2,
		label: 'Fair'
	},
	{
		value: 1,
		label: 'Poor'
	},
	{
		value: 0,
		label: 'N/A'
	}
]

export const EVENT_EVAL_RATINGS_WEBINAR = [
	{
		value: 4,
		label: 'Most True'
	},
	{
		value: 3,
		label: 'More True'
	},
	{
		value: 2,
		label: 'Less True'
	},
	{
		value: 1,
		label: 'Least True'
	},
	{
		value: 0,
		label: 'N/A'
	}
]

export enum GRANT_TYPE {
	'Managed' = 20, // Used to be called "Charity"
	// 'Direct Mail' = 7,
	'Large Scale Event' = 1,
	// 'Grant Writing' = 4,
	'Major Gifts' = 2,
	// 'Micro-Events' = 8,
	'Other' = 6,
	// 'TGIA' = 25
}

// export const GRANT_TYPES = [
// 	{
// 		value: '20',
// 		label: 'Managed'
// 	},
// 	{
// 		value: '7',
// 		label: 'Direct Mail'
// 	},
// 	{
// 		value: '1',
// 		label: 'Large Scale Event'
// 	},
// 	{
// 		value: '4',
// 		label: 'Grant Writing'
// 	},
// 	{
// 		value: '2',
// 		label: 'Major Gifts'
// 	},
// 	{
// 		value: '8',
// 		label: 'Micro-Events'
// 	},
// 	{
// 		value: '6',
// 		label: 'Other'
// 	},
// 	{
// 		value: '25',
// 		label: 'TGIA'
// 	}
// ]

export enum GRANT_JOB_TITLE {
	'Other' = 5,
}

export enum MAIL_TYPE_GROUP {
	'Grants' = 5,
}

export enum MAIL_TYPE {
	'All MI Email' = 0,
	'General' = 1,
	'All MA Email' = 46,
	'All CE Email' = 48,
}


export enum GRANT_DENIAL_REASON {
// 	"Poor Fund Use Plan" = 1,
// 	"Poor Matching Plan" = 2,
// 	"Limited grant funds at this time" = 3,
// 	"Weak MIF Involvement" = 4,
// 	// "Weak TG implementation" = 5, 20230322 TB - Retired by Trent
// 	"Grant should target development" = 6,
// 	"Grant not warranted" = 7,
// 	"More training needed" = 8,
// 	"Concerns about ministry leadership, programs, or relevance" = 9,
// 	"Ministry will likely never get a grant based on funder's granting criteria" = 10,
 	"Other" = 11,
}

export const COURSE_STATUS_OPTIONS = [
	{label: 'Completed', value: 'Completed'},
	{label: 'In Progress', value: 'In Progress'},
	{label: 'Not Started', value: 'Not Started'}
]

export enum AccountLevel {
    Teaching = 1,
    Coaching = 2,
    Consulting = 3,
}

export enum AppointmentType {
    Coaching = 2,
	BookClub = 4,
    Consulting = 5,
    MaConsulting = 8,
}

export const NTEE_CODES_MINOR_Religion_Related = [
	{
		label: 'Alliances & Advocacy',
		value: 'X01'
	},
	{
		label: 'Management & Technical Assistance',
		value: 'X02'
	},
	{
		label: 'Professional Societies & Associations',
		value: 'X03'
	},
	{
		label: 'Research Institutes & Public Policy Analysis',
		value: 'X05'
	},
	{
		label: 'Single Organization Support',
		value: 'X11'
	},
	{
		label: 'Fund Raising & Fund Distribution',
		value: 'X12'
	},
	{
		label: 'Support N.E.C.',
		value: 'X19'
	},
	{
		label: 'Christianity',
		value: 'X20'
	},
	{
		label: 'Protestant',
		value: 'X21'
	},
	{
		label: 'Roman Catholic',
		value: 'X22'
	},
	{
		label: 'Religious Media & Communications',
		value: 'X80'
	},
	{
		label: 'Religious Film & Video',
		value: 'X81'
	},
	{
		label: 'Religious Television',
		value: 'X82'
	},
	{
		label: 'Religious Printing & Publishing',
		value: 'X83'
	},
	{
		label: 'Religious Radio',
		value: 'X84'
	},
	{
		label: 'Interfaith Coalitions',
		value: 'X90'
	},
	{
		label: 'Religion-Related N.E.C.',
		value: 'X99'
	},
]

export const NTEE_CODES_MINOR_Education = [
	{
		label: 'Elementary, Secondary Ed',
		value: 'B20'
	},
	{
		label: 'Primary/Elementary Schools',
		value: 'B24'
	},
	{
		label: 'Charter Schools',
		value: 'B29'
	},
	{
		label: 'Graduate & Professional Schools',
		value: 'B50'
	},
	{
		label: 'Educational Services and Schools - Other',
		value: 'B90'
	},
]

export const NTEE_CODES_MINOR_Health_Care = [
	{
		label: 'Reproductive Health Care Facilities and Allied Services',
		value: 'E40'
	},
]

export const NTEE_CODES_MINOR_Crime_Legal = [
	{
		label: 'Rehabilitation Services for Offenders',
		value: 'I40'
	},
	{
		label: 'Inmate Support',
		value: 'I43'
	},
	{
		label: 'Protection Against Abuse',
		value: 'I70'
	},
]

export const NTEE_CODES_MINOR_Employment = [
	{
		label: 'Employment Preparation & Procurement',
		value: 'J20'
	},
	{
		label: 'Vocational Rehabilitation',
		value: 'J30'
	},
]

export const NTEE_CODES_MINOR_Food_Agriculture_Nutrition = [
	{
		label: 'Food Programs',
		value: 'K30'
	},
	{
		label: 'Food Banks & Pantries',
		value: 'K31'
	},
	{
		label: 'Congregate Meals',
		value: 'K34'
	},
	{
		label: 'Soup Kitchens',
		value: 'K35'
	},
]

export const NTEE_CODES_MINOR_Mental_Health = [
	{
		label: 'Substance Abuse Dependency, Prevention & Treatment',
		value: 'F20'
	},
	{
		label: 'Alcohol, Drug Abuse (Prevention Only)',
		value: 'F21'
	},
	{
		label: 'Counseling Support Groups',
		value: 'F60'
	},
]

export const NTEE_CODES_MINOR_Housing_Shelter = [
	{
		label: 'Housing Development, Construction & Management',
		value: 'L20'
	},
	{
		label: 'Low-Income & Subsidized Rental Housing',
		value: 'L21'
	},
	{
		label: 'Senior Citizens Housing & Retirement Communities',
		value: 'L22'
	},
	{
		label: 'Independent Housing for People with Disabilities',
		value: 'L24'
	},
	{
		label: 'Housing Rehabilitation',
		value: 'L25'
	},
	{
		label: 'Temporary Housing',
		value: 'L40'
	},
	{
		label: 'Temporary Shelter For the Homeless',
		value: 'L41'
	},
]

export const NTEE_CODES_MINOR_Recreation_Sports = [
	{
		label: 'Recreational and Sporting Camps (Day, Overnight, etc.)',
		value: 'N20'
	},
]

export const NTEE_CODES_MINOR_Youth_Development = [
	{
		label: 'Youth Centers & Clubs',
		value: 'O20'
	},
	{
		label: 'Youth Development Programs',
		value: 'O50'
	},
	{
		label: 'Religious Leadership, Youth Development',
		value: 'O55'
	},
	{
		label: 'Other Youth Development N.E.C.',
		value: 'O99'
	},
]

export const NTEE_CODES_MINOR_Human_Services = [
	{
		label: 'Human Service Organizations',
		value: 'P20'
	},
	{
		label: 'Thrift Shops',
		value: 'P29'
	},
	{
		label: "Children's and Youth Services",
		value: 'P30'
	},
	{
		label: 'Adoption',
		value: 'P31'
	},
	{
		label: 'Foster Care',
		value: 'P32'
	},
	{
		label: "Family Services",
		value: 'P40'
	},
	{
		label: 'Family Violence Shelters',
		value: 'P43'
	},
	{
		label: 'Family Counseling',
		value: 'P46'
	},
	{
		label: 'Pregnancy Centers',
		value: 'P47'
	},
	{
		label: 'Financial Counseling',
		value: 'P51'
	},
	{
		label: 'Emergency Assistance (Food, Clothing, Cash)',
		value: 'P60'
	},
	{
		label: "Services to Promote the Independence of Specific Populations",
		value: 'P80'
	},
	{
		label: 'Homeless Services/Centers',
		value: 'P85'
	},
	{
		label: 'Human Services - Multipurpose and Other N.E.C.',
		value: 'P99'
	},
]

export const NTEE_CODES_MINOR_International = [
	{
		label: 'International Development, Relief Services',
		value: 'Q30'
	},
	{
		label: 'International Relief',
		value: 'Q33'
	},
]

export const NTEE_CODES_MINOR_Civil_Rights = [
	{
		label: 'Right to Life',
		value: 'R62'
	},
]

export const NTEE_CODES_MINOR_Community_Improvement = [
	{
		label: 'Community, Neighborhood Development, Improvement',
		value: 'S20'
	},
	{
		label: 'Urban & Community Economic Development',
		value: 'S31'
	},
	{
		label: 'Nonprofit Management',
		value: 'S50'
	},
]

export const NTEE_CODES_MINOR_Public_Benefit = [
	{
		label: 'Public, Society Benefit - Multipurpose and Other N.E.C.',
		value: 'W99'
	},
]

export const NTEE_CODES_MAJOR = [
	{
		label: 'Community Improvement & Capacity Building',
		value: 'S',
		options: NTEE_CODES_MINOR_Community_Improvement
	},
	{
		label: 'Education',
		value: 'B',
		options: NTEE_CODES_MINOR_Education
	},
	{
		label: 'Health Care',
		value: 'E',
		options: NTEE_CODES_MINOR_Health_Care
	},
	{
		label: 'Crime & Legal-Related',
		value: 'I',
		options: NTEE_CODES_MINOR_Crime_Legal
	},
	{
		label: 'Employment',
		value: 'J',
		options: NTEE_CODES_MINOR_Employment
	},
	{
		label: 'Food, Agriculture & Nutrition',
		value: 'K',
		options: NTEE_CODES_MINOR_Food_Agriculture_Nutrition
	},
	{
		label: 'Housing & Shelter',
		value: 'L',
		options: NTEE_CODES_MINOR_Housing_Shelter
	},
	{
		label: 'Human Services',
		value: 'P',
		options: NTEE_CODES_MINOR_Human_Services
	},
	{
		label: 'International, Foreign Affairs & National Security',
		value: 'Q',
		options: NTEE_CODES_MINOR_International
	},
	{
		label: 'Civil Rights, Social Action & Advocacy',
		value: 'R',
		options: NTEE_CODES_MINOR_Civil_Rights
	},
	{
		label: 'Mental Health & Crisis Intervention',
		value: 'F',
		options: NTEE_CODES_MINOR_Mental_Health
	},
	{
		label: 'Public & Societal Benefit',
		value: 'W',
		options: NTEE_CODES_MINOR_Public_Benefit
	},
	{
		label: 'Recreation & Sports',
		value: 'N',
		options: NTEE_CODES_MINOR_Recreation_Sports
	},
	{
		label: 'Religion-Related',
		value: 'X',
		options: NTEE_CODES_MINOR_Religion_Related
	},
	{
		label: 'Youth Development',
		value: 'O',
		options: NTEE_CODES_MINOR_Youth_Development
	},
]

export const NTEE_CODES_Map_Tool = [
	{
		label: 'Adoption',
		value: 'P31'
	},
	{
		label: 'Alliances & Advocacy',
		value: 'X01'
	},
	{
		label: 'Charter Schools',
		value: 'B29'
	},
	{
		label: "Children's and Youth Services",
		value: 'P30'
	},
	{
		label: 'Christianity',
		value: 'X20'
	},
	{
		label: 'Congregate Meals',
		value: 'K34'
	},
	{
		label: 'Elementary, Secondary Ed',
		value: 'B20'
	},
	{
		label: 'Employment Preparation & Procurement',
		value: 'J20'
	},
	{
		label: 'Family Counseling',
		value: 'P46'
	},
	{
		label: 'Family Violence Shelters',
		value: 'P43'
	},
	{
		label: 'Financial Counseling',
		value: 'P51'
	},
	{
		label: 'Food Banks & Pantries',
		value: 'K31'
	},
	{
		label: 'Food Programs',
		value: 'K30'
	},
	{
		label: 'Graduate & Professional Schools',
		value: 'B50'
	},
	{
		label: 'Homeless Services/Centers',
		value: 'P85'
	},
	{
		label: 'Housing Development, Construction & Management',
		value: 'L20'
	},
	{
		label: 'Housing Rehabilitation',
		value: 'L25'
	},
	{
		label: 'Human Service Organizations',
		value: 'P20'
	},
	{
		label: 'Independent Housing for People with Disabilities',
		value: 'L24'
	},
	{
		label: 'Inmate Support',
		value: 'I43'
	},
	{
		label: 'International Relief',
		value: 'Q33'
	},
	{
		label: 'Low-Income & Subsidized Rental Housing',
		value: 'L21'
	},
	{
		label: 'Nonprofit Management',
		value: 'S50'
	},
	{
		label: 'Pregnancy Centers',
		value: 'P47'
	},
	{
		label: 'Protection Against Abuse',
		value: 'I70'
	},
	{
		label: 'Rehabilitation Services for Offenders',
		value: 'I40'
	},
	{
		label: 'Religious Leadership, Youth Development',
		value: 'O55'
	},
	{
		label: 'Religious Media & Communications',
		value: 'X80'
	},
	{
		label: 'Right to Life',
		value: 'R62'
	},
	{
		label: 'Senior Citizens Housing & Retirement Communities',
		value: 'L22'
	},
	{
		label: 'Soup Kitchens',
		value: 'K35'
	},
	{
		label: 'Substance Abuse Dependency, Prevention & Treatment',
		value: 'F20'
	},
	{
		label: 'Temporary Housing',
		value: 'L40'
	},
	{
		label: 'Temporary Shelter For the Homeless',
		value: 'L41'
	},
	{
		label: 'Thrift Shops',
		value: 'P29'
	},
	{
		label: 'Urban & Community Economic Development',
		value: 'S31'
	},
	{
		label: 'Vocational Rehabilitation',
		value: 'J30'
	},
	{
		label: 'Youth Centers & Clubs',
		value: 'O20'
	},
	{
		label: 'Youth Development Programs',
		value: 'O50'
	},
]

// 20240312 TB - These are the only two mail types that matter for unsubscribes at this time.
export const MAIL_TYPE_OPTIONS = [
	{label: 'All MI Email', value: MAIL_TYPE["All MI Email"].toString()},
	{label: 'All MA Email', value: MAIL_TYPE["All MA Email"].toString()},
	{label: 'All CE Email', value: MAIL_TYPE["All CE Email"].toString()}
]