import React, { useContext } from "react"
import { AppActionContext } from '../app-store-provider'
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import { MinistryInfoAreaDirectorEvalPurpose } from "./ministry-info-ad-eval-purpose"
import { MinistryInfoAreaDirectorEvalGovernance } from "./ministry-info-ad-eval-governance"
import { MinistryInfoAreaDirectorEvalLeadership } from "./ministry-info-ad-eval-leadership"
import { MinistryInfoAreaDirectorEvalOperations } from "./ministry-info-ad-eval-operations"
import { MinistryInfoAreaDirectorEvalFundraising } from "./ministry-info-ad-eval-fundraising"
import { MinistryInfoAreaDirectorEvalCommunications } from "./ministry-info-ad-eval-communications"
import { MinistryInfoAreaDirectorEvalRiskMgmt } from "./ministry-info-ad-eval-risk-mgmt"
import { MinistryInfoAreaDirectorEvalFinancial } from "./ministry-info-ad-eval-financial"
import { Formik, Form } from "formik"
import { SelectField } from "./forms"
import { IMinsitryEvalsApproveOrDenyRequestDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { ReactComponent as PrinterIcon } from '../assets/printer.svg'

export enum GRANT_TYPES {
    "Fundraising Event" = 1,
    "Major Gifts" = 2,
    "Grant Writing" = 4,
    "Other" = 6,
    "Direct Mail" = 7,
    "Micro-Events" = 8,
    "Charity" = 20,
    "TGIA" = 25
}

export const MinistryInfoAreaDirectorEvalSummary = () => {
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!
    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    return (
        <React.Fragment>
            <div className="d-flex justify-content-end">
                <a href={`/ad-summary-print/${areaDirectorEval.ministryEvalState.ministryId}`} target="_blank"><PrinterIcon className='mr-1' style={{ minWidth: '16px' }} /> Print Summary</a>
            </div>
            <h5>Purpose</h5>
            <MinistryInfoAreaDirectorEvalPurpose
                readonly={true} />
            <h5>Governance</h5>
            <MinistryInfoAreaDirectorEvalGovernance
                readonly={true} />
            <h5>Leadership</h5>
            <MinistryInfoAreaDirectorEvalLeadership
                readonly={true} />
            <h5>Operations</h5>
            <MinistryInfoAreaDirectorEvalOperations
                readonly={true} />
            <h5>Fundraising</h5>
            <MinistryInfoAreaDirectorEvalFundraising
                readonly={true} />
            <h5>Communications</h5>
            <MinistryInfoAreaDirectorEvalCommunications
                readonly={true} />
            <h5>Risk Mngmt</h5>
            <MinistryInfoAreaDirectorEvalRiskMgmt
                readonly={true} />
            <h5>Financial</h5>
            <MinistryInfoAreaDirectorEvalFinancial
                readonly={true} />
            <Formik
                initialValues={{
                    isApproved: false,
                    allowedGrantTypes: areaDirectorEval.ministryEvalState.allowedGrantTypes?.split(",")
                }}
                onSubmit={async (values) => {
                    const evalRecord: IMinsitryEvalsApproveOrDenyRequestDocument = {
                        isApproved: values.isApproved,
                        allowedGrantTypes: values.allowedGrantTypes || null
                    }

                    if (areaDirectorEval.ministryEvalState.ministryId) {
                        await makeHTTPRequestWithUi({
                            request: appActions.MinistryEvalsApi.apiMinistriesIdMinistryEvalApproveOrDenyPost(areaDirectorEval.ministryEvalState.ministryId, evalRecord),
                            toastSuccessMessage: 'Eval record successfully updated.',
                            toastErrorMessage: 'There was an error updating the eval record.',
                        })
                    }
                }}
            >
                {(formikProps) => {
                    return (
                        <React.Fragment>
                            <Form>
                                <SelectField
                                    fieldProps={{ label: 'Allow grant types', name: 'allowedGrantTypes' }}
                                    multiple={true}
                                    // @ts-ignore
                                    options={Object.keys(GRANT_TYPES).filter(key => isNaN(key)).map(key => ({ value: GRANT_TYPES[key], label: key }))}
                                />
                                <div className='row px-3'>
                                    <button className={`btn btn-primary`} type='button' onClick={() => {
                                        formikProps.setFieldValue('isApproved', true)
                                        formikProps.submitForm()
                                    }}>Approved</button>
                                    <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                        formikProps.setFieldValue('isApproved', false)
                                        formikProps.submitForm()
                                    }}>Denied</button>
                                </div>
                            </Form>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}