import { IField } from "./types"
import clsx from 'clsx'
import { useField } from 'formik'
import React, { useRef } from "react"
import { useFormikScrollToError } from "../../hooks"
import { TooltipInfo } from "../partials"

interface IFormikTextFieldProps {
    field: IField
    labelTooltip?: string
}

export const FormikTextField = ({ field, labelTooltip }: IFormikTextFieldProps) => {

    const [formikField, { error, touched }] = useField(field)

    const showInvalid = error && touched

    const fieldRef = useRef<HTMLInputElement>(null)
    useFormikScrollToError({ field: fieldRef, name: formikField.name })

    return (
        <div className={clsx('form-group', showInvalid && 'is-invalid')}>
            <label htmlFor={formikField.name} >
                {field.label}
                {labelTooltip ? <TooltipInfo style={{ marginBottom: 4, marginLeft:4 }} tooltipText={labelTooltip} /> : ''}
            </label>

            <div style={{ position: 'relative' }}>
                <fieldset disabled={field.disabled} style={{ border: 'none', margin: 0, padding: 0 }}>
                    <input
                        className={clsx('form-control', showInvalid && 'is-invalid')}
                        {...field}
                        {...formikField}
                        ref={fieldRef}
                    />
                </fieldset>
            </div>

            <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{showInvalid && error}</div>
        </div>
    )
}