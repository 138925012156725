import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IEventModelDocument, IEventSiteModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { Form, Formik } from "formik"
import * as yup from 'yup'
import { CommunitySelectField, SelectField } from "./forms"
import { EventSiteSelectField } from "./event-site-select-field"
import { uuidv4 } from "../services/helpers"
import dayjs from "dayjs"

interface IEventDuplicateFormProps {
    eventToDuplicate: IEventModelDocument,
    onCancel?: () => void
    afterSave: () => void
}

export const EventDuplicateForm = (props: IEventDuplicateFormProps) => {
    const { eventToDuplicate, afterSave, onCancel } = props

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const { fetchTrainingAreas, EventSitesApi, EventMgmtApi, addAlert } = useContext(AppActionContext)!
    const { activeTrainingAreas } = useContext(AppStateContext)!
    useEffect(() => {
        if (!activeTrainingAreas) fetchTrainingAreas()
    }, [activeTrainingAreas])

    const [sites, setSites] = useState<IEventSiteModelDocument[]>()
    const fetchInitialData = useCallback(async () => {
        if (eventToDuplicate?.trainingAreaId && !sites) {
            const { data } = await makeHttpRequestWithUi({
                request: EventSitesApi.apiMiTrainingAreasTrainingAreaIdEventSitesGet(eventToDuplicate.trainingAreaId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching sites.'
            })
            setSites(data)
        }
    }, [])

    useEffect(() => {
        fetchInitialData()
    }, [])

    const initialValues = {
        eventId: eventToDuplicate.eventId,
        branchId: eventToDuplicate.branchId,
        trainingAreaId: eventToDuplicate.trainingAreaId,
        siteId: eventToDuplicate.siteId
    }

    return (
        <>
            <div>
                <p>
                This tool will duplicate the selected event. You have the option of changing the Community, Training Area and/or Site prior to 
                duplicating the event.
                </p>
                <p>If the original event has a future date, the duplicated event's date will be the same as the original. Otherwise it will be 14 days in the future.</p>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={yup.object({
                    branchId: yup.string().required('Required'),
                    trainingAreaId: yup.string().required('Required'),
                    siteId: yup.string().required('Required'),
                })}
                onSubmit={async (values, actions) => {
                    const {data} = await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventEventIdDuplicatePost(values.eventId, { 
                            eventId: values.eventId,
                            siteId: values.siteId || 0,
                            trainingAreaId: values.trainingAreaId || 0
                        }),
                        toastSuccessMessage: 'Successfully duplicated event.',
                        toastErrorMessage: 'Encountered an error duplicating event.',
                    })

                    if (data) {
                        addAlert({
                            id: uuidv4(),
                            title: 'Successfully duplicated!',
                            body: `This event was successfully duplicated. The newly created event's date is ${dayjs(data.startDateTime?.toString()).format('M/D/YYYY')}.`,
                        })
                    }

                    afterSave()
                }}
            >
                {formikProps =>
                    <Form className="d-flex flex-column">
                        <div className="d-flex flex-row">
                            <div className="col pl-0">
                                <CommunitySelectField 
                                fieldProps={{ name: 'branchId', label: 'Community' }}
                                disabled={!!eventToDuplicate.isSeries} />
                            </div>
                            <div className="col">
                                <SelectField fieldProps={{ name: 'trainingAreaId', label: 'Training Area' }} options={activeTrainingAreas?.filter(o => o.branchId === formikProps.values.branchId).map(o => ({ label: `${o.trainingArea}`, value: o.trainingAreaId.toString() }))} />
                            </div>
                            <div className="col">
                                <EventSiteSelectField
                                    field={{ name: 'siteId', label: 'Site' }}
                                    trainingAreaId={formikProps.values.trainingAreaId ? formikProps.values.trainingAreaId : undefined}
                                    setSites={(sites: IEventSiteModelDocument[]) => setSites(sites)}
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row'>
                            {onCancel && <button style={{ width: 100 }} type='button' onClick={onCancel} className='btn btn-secondary mr-2'>Cancel</button>}
                            <button type='submit' style={{ width: 100 }} className='btn btn-primary'>Continue</button>
                        </div>
                    </Form>
                }
            </Formik>
        </>
    )
}
