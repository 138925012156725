import React from 'react'
import { IDefaultProps } from './component-definitions'

const BaseLayout = (props: IDefaultProps) => {
	return (
		<div className={`${props.className} base`}>
			{props.children}
		</div>
	)
}

export default BaseLayout