import React, { useEffect, useContext, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { ministryMergeInfoDefaultColumns, MinistryMergeInfo } from '../models/ministry-merge-info'
import { Grid } from './grid'
import { useHTTPRequestUiWrapper } from '../services/hooks'

interface IMinistryManagementmergeMinistriesProps {
	ministriesIdsToMerge: number[]
	setWinningMinistryId: (ministryId: string) => void
}
export const MinistryManagementmergeMinistries = (props: IMinistryManagementmergeMinistriesProps) => {
	const { ministriesIdsToMerge, setWinningMinistryId } = props
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const fetchMergeInfo: GridDataFetch<number[]> = async (queryState, _ministriesIdsToMerge) => {
		const ministryMergeInfoRequest = await makeHttpRequestWithUi({ request: appActions.MinistriesApi.apiMinistriesGetMergeInfoGet(_ministriesIdsToMerge), disableLoading: true, disableSuccessToast: true, toastErrorMessage: 'Error retrieving ministry merge summary info.' })

		return {
			rows: ministryMergeInfoRequest.data.map(MinistryMergeInfo.toGridListItem),
			count: ministryMergeInfoRequest.data.length
		}
	}

	const [primaryMinistryId, setPrimaryMinistryId] = useState<string>()

	const initialGridState: IGridState = {
		...defaultGridState,
		columns: ministryMergeInfoDefaultColumns,
		disabledPagination: true,
		rowSelectEnabled: false,
		dataSource: fetchMergeInfo,
		hideGridFooter: true,
		hideGridHeader: true,
		onRowPress: async (row) => setPrimaryMinistryId(row.id)
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministriesIdsToMerge)

	useEffect(() => {
		if (primaryMinistryId) {
			gridActions.setHighlightedRows([primaryMinistryId])
			setWinningMinistryId(primaryMinistryId)
		}
	}, [primaryMinistryId])

	return (
		<div>
			<p>
				Please choose the primary ministry (choose one). All grants, notes, event history, contacts, etc, from the previously selected ministries will be merged into the primary ministry.
			</p>
			<Grid style={{ height: 'unset' }} state={gridState} actions={gridActions} />
		</div>
	)
}