import React, { useCallback, useContext, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Grid } from './grid'
import { GridDataFetch, IGridState, IGridListItem, GridUserInteractionStateKey, isGridListItemObjectValueTypeArray } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { IDefaultProps } from './component-definitions'
import { ReactComponent as Tag } from '../assets/tag.svg'
import { uuidv4, showModal, hideModal, openUrlInNewTab, onModalHidden } from '../services/helpers'
import { Modal } from './modal'
import { TagsList } from './tags-list'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { Loading } from './loading'
import { IQueryState } from '../stores/api-actions'
import { IAppState } from '../stores/app-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { useHTTPRequestUiWrapper, useModal, useMinistryContactManagementDefaultColumns } from '../services/hooks'
import { navigate } from '@reach/router'
import { isNullOrUndefined } from 'util'
import { Path } from './dashboard'
import { Helmet } from 'react-helmet'
import { IncognitoIcon, PersonIcon, EnvolpeIcon } from '../assets'
import { MinistryContactModel } from '../models/ministry-contact'
import { SquareDeleteIcon } from './partials'
import { UserModel, UserPermissionLevel, App } from '../models/user'
import { AddToCourseForm, AddToCourseFormTab, AddToCourseModalTitle, IAddToCourseForm, IFormValues } from './add-to-course-form'
import { Checkbox } from './forms'
import { LmsSendEmailModal } from './lms-send-email-modal'

interface IMinistryContactManagementProps extends IDefaultProps { }
export const MinistryContactManagement = (props: IMinistryContactManagementProps) => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()
	const showingArchived = props.path === Path['archived-contacts']
	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [selectedRows, setSelectedRows] = useState<IGridListItem[]>([])
	const [showAdvancedForm, setShowAdvancedForm] = useState(false)
	const [activeTab, setActiveTab] = useState<AddToCourseFormTab>(AddToCourseFormTab.main)

	const [tagsModalState, setTagsModalState] = useState<{
		modalId: string
		modalTitle: string
		startingTagIds: number[]
		endingTagIds: number[]
	}>({
		modalId: uuidv4(),
		modalTitle: 'Tags',
		startingTagIds: [],
		endingTagIds: [],
	})

	const setSelectedTags = (tags: number[]) => setTagsModalState({ ...tagsModalState, endingTagIds: tags })
	let resetTagList: () => void
	onModalHidden(tagsModalState.modalId, () => { if (resetTagList) resetTagList() })
	const fetchMinistryContacts: GridDataFetch<IAppState> = async (queryState, _appState) => {
		const _filters = [...queryState.filters || []]

		// We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing JSON into URL query params
		const filters = (_filters ? JSON.stringify([..._filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
		const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

		const ministriesQuery = await makeHTTPRequestWithUi({
			request: appActions.MinistryContactsApi.apiMinistryContactsGet(
				(queryState.page - 1) * queryState.itemsPerPage,
				queryState.itemsPerPage,
				sorts,
				filters
			),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving Ministry Contacts.'
		})

		return {
			rows: ministriesQuery.data.data?.map(MinistryContactModel.toListItem) || [],
			count: ministriesQuery.data.totalCount
		}
	}

	const [deleteContactModal] = useState(uuidv4())
	const [addToCourseModal] = useState(uuidv4())
	const [isAddToCourseModalShown, setIsAddToCourseModalShown] = useState(false)
    const [confirmSendLoginInstructionsModal, showHideConfirmSendLoginInstructionsModal] = useModal()
    const [sendLmsEmailModal, showHideSendLmsEmailModal] = useModal()

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	let queryState: IQueryState | undefined = appState.currentUserSessionState?.gridUserInteractionStates?.[showingArchived ? GridUserInteractionStateKey.ArchivedMinistries : GridUserInteractionStateKey.MinistryContacts]?.queryState

	/*
		Set the archived ministry filter depending on whether we're handling archived or active ministries
	*/
	if (!queryState) queryState = { ...defaultGridState.queryState }
	if (!queryState.filters) queryState.filters = []
	const archivedFilterIdx = queryState.filters?.findIndex(o => o.id === 'ministryIsArchived')
	if (archivedFilterIdx !== -1) {
		queryState.filters[archivedFilterIdx] = { ...queryState.filters[archivedFilterIdx], value: showingArchived ? true : false, enabled: true }
	} else {
		queryState.filters.push({
			id: 'ministryIsArchived',
			enabled: true,
			value: showingArchived,
			operator: '==',
			property: 'ministryIsArchived',
			hidden: true,
		})
	}

	const queryMinistryId = new URLSearchParams(window.location.search).get('ministryId')
	if ( queryMinistryId ) {
		const ministryFilterIdx = queryState.filters?.findIndex(o => o.id === 'ministryId')

		if (ministryFilterIdx !== -1) {
			queryState.filters[ministryFilterIdx] = { ...queryState.filters[ministryFilterIdx], value: parseInt(queryMinistryId), enabled: true }
		} else {
			queryState.filters.push({
				id: 'ministryId-eq',
				enabled: true,
				value: parseInt(queryMinistryId),
				operator: 'eq',
				property: 'ministryId',
			})
		}
	}

	const [disableTagsFooterBtns, setDisableTagsFooterBtns] = useState<boolean>(false)

	const initialGridState: IGridState = {
		...defaultGridState,
		loading: true,
		respectGlobalCommunityFilter: true,
		queryState,
		columns: useMinistryContactManagementDefaultColumns(showingArchived), // Per Brock on why he declares columns in a separate hooks.tsx file: "I started declaring them separately but then realized it was an over-separation of concerns. Those column declarations were unlikely to ever be re-used elsewhere and unnecessarily increased file "context switching". So I transitioned to almost entirely inline column definitions."
		dataSource: fetchMinistryContacts,
		tagType: 'MINISTRYCONTACTID',
		userSessionStateKey: showingArchived ? GridUserInteractionStateKey.ArchivedMinistryContacts : GridUserInteractionStateKey.MinistryContacts,
		overrideInitialStateValues: {
			queryState
		},
		gridActions:
			[
				{
					id: 'addToCourse',
					label: 'Add To Course',
					onClick: ({ rows }) => {
						if (rows.filter(row => row.selected).length >= 1) {
							setSelectedRows(rows.filter(row => row.selected))
							showModal(addToCourseModal)
							setIsAddToCourseModalShown(true)
						} else {
							appActions.addAlert({ id: uuidv4(), body: 'Please select at least 1 ministry contact.' })
						}
					},
				},
			],

		rowDoubleClicked: async (row) => {
			navigate(`/contact-info/${row.id}`)
		},
		rowActions: {
			contactInfo: {
				id: 'contactInfo',
				action: async (options) => options.e.stopPropagation(),
				icon: <PersonIcon />,
				tooltipText: 'View Contact',
				url: (row) => `/contact-info/${row.id}`,
			},
			impersonate: {
				id: 'impersonate',
				action: async (options) => {
					const { e, row, appActions } = options

					e.stopPropagation()
					openUrlInNewTab(row.values.impersonationUrl?.toString() ?? '')					
				},
				icon: <IncognitoIcon />,
				tooltipText: 'Impersonate User',
				disabled: (row) => !row.values.impersonationUrl
			},
			sendLoginInstructions: {
				id: 'sendLoginInstructions',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideConfirmSendLoginInstructionsModal(true)
				},
				icon: <EnvolpeIcon />,
				tooltipText: 'Send Login Instructions',
				disabled: (row) => !row.values.email,
            	hidden: (row) => !row.id
			},
			manageTags: {
				id: 'manageTags',
				action: async (options) => {
					const { e, row } = options

					e.stopPropagation()

					let existingTagIds: number[] = []
					const _rowTags = row.values.tags
					if (isGridListItemObjectValueTypeArray(_rowTags)) existingTagIds = _rowTags.map(tag => parseInt(tag.id.toString()))
					setTagsModalState({ ...tagsModalState, modalTitle: `Tags for ${row.values['fullName']}`, startingTagIds: existingTagIds, endingTagIds: existingTagIds })
					setSelectedRow(row)
					showModal(tagsModalState.modalId)
				},
				icon: <Tag />,
				tooltipText: 'Manage Tags'
			},
			deleteContact: {
				id: 'deleteContact',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(deleteContactModal)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete Contact',
				disabled: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser)
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	const addRemoveTagsFromSelectedMinistryContact = async () => {
		if (!selectedRow) return

		const contactId = parseInt(selectedRow.id)

		// Every tag that was in the final list but wasn't in the initial list
		const addedTags = tagsModalState.endingTagIds.filter(endingTagId => !tagsModalState.startingTagIds.find(startingTagId => startingTagId === endingTagId))
		// Every tag that was in the initial list, but isn't in the final list
		const removedTags = tagsModalState.startingTagIds.filter(startingTagId => !tagsModalState.endingTagIds.find(endingTagId => startingTagId === endingTagId))

		const promises: Promise<any>[] = []
		addedTags.forEach(tagId => promises.push(appActions.addMinistryContactToTag(tagId, contactId)))
		removedTags.forEach(tagId => promises.push(appActions.removeMinistryContactFromTag(tagId, contactId)))

		hideModal(tagsModalState.modalId)

		if (promises.length) {
			gridActions.setLoading(true)
			await Promise.all(promises)
			await appActions.fetchTagsSummary()
			gridActions.doFetch()
		}
	}

	const [ addToCourseFormValues, setAddToCourseFormValues ] = useState<IFormValues>()
	const [ courseEnrollmentIds, setCourseEnrollmentIds ] = useState<number[]>([])
	const [ showAdvancedFormCheckbox, setShowAdvancedFormCheckbox ] = useState(true)

	const handleAddToCourseSave: IAddToCourseForm['onSave'] = useCallback(async values => {
		const ids = await makeHTTPRequestWithUi({
			request: appActions.LmsApi.apiLmsAddCourseEnrollmentPost(
				{
					communityId: parseInt(values.branchId),
					courseId: parseInt(values.courseId),
					ministryContactIds: values.ministryContacts.map(c => c.contactId),
					emails: values.outsideContactEmails,
					groupCode: values.groupCode,
					groupId: values.groupId ?? 0,
					eventId: 0,
				}
			),
			disableLoading: true,
			disableSuccessToast: false,
			toastErrorMessage: 'There was an error adding contact to course.'
		})

		setAddToCourseFormValues(values)
		setCourseEnrollmentIds(ids.data)

		return []
    }, [])

	const handleAddToCourseAfterSave: IAddToCourseForm['afterSave'] = useCallback(() => {
		setIsAddToCourseModalShown(false)
		showHideSendLmsEmailModal(true)
    }, [])

	if (!gridState) {
		return <Loading />
	} else {
		return (
			<React.Fragment>
				<Helmet>
					<title>{showingArchived ? 'Archived' : 'Active'} Ministry Contacts</title>
				</Helmet>

				<div className='d-flex flex-column vh-100'>
					<div className='m-2 d-flex align-items-center justify-content-between'>
						<div>
							<h3>{showingArchived ? 'Archived' : 'Active'} Ministry Contacts</h3>

							<p>Below is a list of contacts belonging to {showingArchived ? 'archived' : 'active'} ministries.</p>
						</div>
					</div>
					<Grid state={gridState} actions={gridActions} style={{ flex: 1, height: 'unset' }} />
				</div>


				<Modal
					{...confirmSendLoginInstructionsModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' 
								className='btn btn-primary' 
								onClick={async () => {
									if (!selectedRow) return
									//const taskId = registerLoadingTask()
									await makeHttpRequestWithUi({
										request: appActions.MinistryContactsApi.apiMinistryContactsMinistryContactIdSendInviteOrLoginInstructionsPost(parseInt(selectedRow.id)),
										toastErrorMessage: 'There was an error while sending login instructions.',
										toastSuccessMessage: 'Successfully sent login instructions.',
									})
									//deregisterLoadingTask(taskId)
									setSelectedRow(undefined)
									showHideConfirmSendLoginInstructionsModal(false)
							}}>Send</button>
						</React.Fragment>
					}
					onModalHidden={() => setSelectedRow(undefined)}
				>
					Are you sure you want to send the login instructions email to this contact?
				</Modal>

				{/* Edit tags for the selected row */}
				<Modal
					modalId={tagsModalState.modalId}
					modalTitle={tagsModalState.modalTitle}
					allowOverflow={true}
					footer={
						<React.Fragment>
							<button type="button" className="btn btn-secondary" disabled={disableTagsFooterBtns} data-dismiss="modal">Cancel</button>
							<button type="button" className="btn btn-primary" disabled={disableTagsFooterBtns} onClick={addRemoveTagsFromSelectedMinistryContact}>Save changes</button>
						</React.Fragment>
					}
				>
					<TagsList
						tags={appState.tags?.filter(tag => tag.tableKey === 'MINISTRYCONTACTID' && !tag.isArchived) || []}
						selectedTagIds={tagsModalState.endingTagIds}
						setSelectedTagIds={setSelectedTags}
						setResetList={(reset) => resetTagList = reset}
						tagType={'MINISTRYCONTACTID'}
						disableFooterButtons={(disable) => setDisableTagsFooterBtns(disable)}
					/>
				</Modal>

				<Modal
					size="lg"
					modalId={addToCourseModal}
					modalTitle={
						<div className='d-flex col justify-content-between align-items-center'>
							<h4>{ AddToCourseModalTitle[activeTab] }</h4>

							<Checkbox id='useAdvanced' label='Use Advanced Tools' style={{display: showAdvancedFormCheckbox ? 'block' : 'none'}} onChange={() => setShowAdvancedForm(value => !value)}/>
						</div>
					}
					dismissible={false}
					closeModal={() => {
						if ( activeTab !== AddToCourseFormTab.main ) {
							setActiveTab(AddToCourseFormTab.main)
						}
						else {
							setIsAddToCourseModalShown(false)
						}
					}}
					show={isAddToCourseModalShown}
				>
					{isAddToCourseModalShown ? <AddToCourseForm 
						setShowAdvancedFormCheckbox={setShowAdvancedFormCheckbox}
						onSave={handleAddToCourseSave} 
						afterSave={handleAddToCourseAfterSave} 
						advanced={showAdvancedForm}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						initialContacts={selectedRows.map(row => ({ contactId: parseInt(row.id), name: `${row.values.firstName} ${row.values.lastName}`, ministryName: row.values.ministryName?.toString() }))}
						initialBranchId={appState.currentUser?.branchId?.toString() ?? ''}
				/> : null}
				</Modal>

				<LmsSendEmailModal modalProps={sendLmsEmailModal} courseEnrollmentIds={courseEnrollmentIds} />

				<Modal
					modalId={deleteContactModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' className='btn btn-danger' disabled={Boolean(selectedRow?.values.isPrimary).valueOf()} onClick={async () => {
								if (!selectedRow) return
								const taskId = registerLoadingTask()
								await makeHTTPRequestWithUi({
									request: appActions.MinistryContactsApi.apiMinistryContactsIdDelete(parseInt(selectedRow.id)),
									disableLoading: true,
									disableSuccessToast: false,
									toastErrorMessage: 'There was an error deleting contact.'
								})
								deregisterLoadingTask(taskId)
								gridActions.doFetch()
								hideModal(deleteContactModal)
							}}>Delete</button>
						</React.Fragment>
					}
				>
					{selectedRow?.values.isPrimary ?
						`We're sorry, but you cannot delete the primary contact.`
						:
						`Are you sure you want to delete this contact?`
					}
				</Modal>
			</React.Fragment>
		)
	}
}