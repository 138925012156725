import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { AppStateContext, AppActionContext } from "../app-store-provider"
import { BoxArrowUpRight, BuildingIcon, BullseyeIcon, IncognitoIcon, PersonIcon } from "../assets"
import { ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { openUrlInNewTab } from "../services/helpers"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { useGrid, defaultGridState } from "../stores/grid-actions"
import { GridDataFetch, IGridListItem } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { Grid } from "./grid"
import { GridActionCell } from "./grid-cell-displays"

export const GlobalSearchResultsContacts = ({ setMinistryContactsResultsCount }: { setMinistryContactsResultsCount: Dispatch<SetStateAction<number | undefined>> }) => {
    const { globalSearchTerm, activeBranches, currentUser } = useContext(AppStateContext)!
    const { GlobalSearchApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<string> = async (queryState, searchTerm) => {
        if (searchTerm && searchTerm.length) {
            // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
            const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as ISearchRequestFilterDocument[] | undefined
            const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

            const query = await makeHttpRequestWithUi({
                request: GlobalSearchApi.ministryContactGet(
                    searchTerm,
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters,
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was an error fetching ministry search results.',
            })

            if (!query.data.data)
                return {
                    rows: [],
                    count: 0,
                }

            const rows: IGridListItem[] = query.data.data?.map(o => {
                if (!o.contactKey) throw new Error('Encountered a contact search result without a contact key.')
                return {
                    id: o.contactKey,
                    values: {
                        ...o
                    }
                }
            })

            setMinistryContactsResultsCount(query.data.totalRows)

            return {
                rows,
                count: query.data.totalRows
            }
        }

        setMinistryContactsResultsCount(undefined)

        return {
            rows: [],
            count: 0
        }
    }

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            dataSource,
            rowSelectEnabled: false,
            columns: [
                {
                    property: 'organizationName',
                    title: 'Ministry',
                    width: 200,
                    type: 'string',
                    render: (col, row) => {
                    const url = row.values.isProspect ? `/prospects/${row.values.organizationId}` : `/ministry-info/${row.values.organizationId}`
                    return <><a href={url}>{row.values[col.property]}</a><a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>
                    },
                    allowFilters: true,
                },
                {
                    property: 'firstName',
                    title: 'First Name',
                    width: 200,
                    type: 'string',
                    render: (col, row) => row.values[col.property],
                    allowFilters: true,
                },
                {
                    property: 'lastName',
                    title: 'Last Name',
                    width: 200,
                    type: 'string',
                    render: (col, row) => row.values[col.property],
                    allowFilters: true,
                },
                {
                    property: 'email',
                    title: 'Email',
                    width: 200,
                    type: 'string',
                    render: (col, row) => row.values[col.property],
                    allowFilters: true,
                },
                {
                    property: 'branchId',
                    title: 'Community',
                    width: 200,
                    type: 'string',
                    render: (col, row) => row.values['branchAbbr'],
                    filterOptions: activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchId.toString() })),
                    allowFilters: true,
                },
                {
                    property: 'isProspect',
                    title: 'Type',
                    width: 75,
                    type: 'string',
                    render: (col, row) => row.values.isProspect ? <><BullseyeIcon className="mr-1" /> Prospect</> : <><BuildingIcon className="mr-1" /> Ministry</>,
                    allowFilters: false,
                },
                {
                    property: 'grid_actions',
                    type: 'actions',
                    width: 100,
                    disableSort: true,
                    title: 'Actions',
                    render: GridActionCell,
                    align: 'left'
                },
            ],
            rowActions: {
                ministryInfo: {
                    id: 'ministryInfo',
                    action: async (options) => options.e.stopPropagation(),
                    icon: (row) => <BuildingIcon />,
                    tooltipText: (row) => 'View Ministry',
                    disabled: (row) => !row.values.organizationId,
                    url: (row) => row.values.isProspect 
						? `/prospects/${row.values.organizationId}` 
						: `/ministry-info/${row.values.organizationId}`
                },
                entityInfo: {
                    id: 'entityInfo',
                    action: async (options) => options.e.stopPropagation(),
                    icon: (row) => 
                        row.values.isProspect ?
                            <BullseyeIcon />
                        :
                            <PersonIcon />,
                    tooltipText: (row) => row.values.isProspect ? 'View Prospect' : 'View Contact',
                    disabled: (row) => !row.values.ministryContactId && !row.values.organizationId,
                    url: (row) => 
                    row.values.isProspect ?
                        `/prospects/${row.values.organizationId}`
                    :
                        `/contact-info/${row.values.ministryContactId}`,
                },
                impersonate: {
                    id: 'impersonate',
                    action: async (options) => {
                        const { e, row } = options

                        e.stopPropagation()
                        if (row.values.impersonationUrl)
                            openUrlInNewTab(row.values.impersonationUrl.toString())
                    },
                    icon: <IncognitoIcon />,
                    tooltipText: 'Impersonate User',
                    disabled: (row) => !row.values.subjectId
                },
            }
        },
        globalSearchTerm
    )

    useEffect(() => {
        gridActions.doFetch()
    }, [globalSearchTerm])

    return (
        <Grid state={gridState} actions={gridActions} style={{ flex: 1, height: '100%' }} />
    )
}