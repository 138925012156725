import { EventEvals } from "./event-evals"
import { useContext, useEffect } from "react"
import { MinistryInfoContext } from "./ministry-info"
import { Tabs } from "./tabs"
import { EVENT_TYPES } from "../constants"
import { Loading } from "./loading"

export const MinistryInfoEventEvals = () => {
    const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

    useEffect(() => {
		ministryInfoActions.fetchEventEvals()
		// eslint-disable-next-line
	}, [])

    if (!ministryInfoState.ministryEventEvals || !ministryInfoState.ministry) return <Loading />

    return (
        <>
            <Tabs
                tablListClassName='my-2 ml-2'
                // controls={{
                //     activeTab,
                //     setActiveTab
                // }}
                tabs={[
                    {
                        id: 'nonWebinars',
                        title: 'Non-Webinars',
                        content: <EventEvals evals={ministryInfoState.ministryEventEvals?.filter(x => x.eventTypeId !== EVENT_TYPES.Webinar) || []} />
                    },
                    {
                        id: 'webinars',
                        title: 'Webinars',
                        content: <EventEvals evals={ministryInfoState.ministryEventEvals?.filter(x => x.eventTypeId === EVENT_TYPES.Webinar) || []} />
                    }
                ]}
            />
        </>
    )
}