import React, { useEffect, useRef } from 'react'
import { uuidv4, portalRoot, onModalHidden as bootstrapOnModalHidden, onModalShown as bootstrapOnModalShown, onModalHide as bootstrapOnModalHide, onModalShow as bootstrapOnModalShow, hideModal, showModal, destroyModal } from '../services/helpers'
import { createPortal } from 'react-dom'
import { isNullOrUndefined } from 'util'

export type ModalSize = 'fullscreen' | 'xxl' | 'xl' | 'lg' | 'sm' | 'md'

export interface IModalProps {
	modalId: string
	modalTitle: string | JSX.Element

	className?: string
	style?: React.CSSProperties

	size?: ModalSize
	noBodyPadding?: boolean
	children?: any
	footer?: any
	allowOverflow?: boolean

	// These events fire when a modal has finished being hidden or finished being shown
	onModalHidden?: () => void
	onModalShown?: () => void

	// These events fire when a modal begins to be hidden or shown
	onModalHide?: () => void
	onModalShow?: () => void

	/* 
		Need to migrate towards using this instead of Bootstraps built in modal events.
		onModalShow/Hide/Shown/Hidden all have potential to fire repeatedly if a render is happening while a modal is closing if we mutate state inside their callbacks.
		If we take control of when we fire show/close we can ensure we don't cause this.
	*/
	show?: boolean
	closeModal?: () => void
	_onModalHidden?: () => void
	_onModalShown?: () => void

	dismissible?: boolean

	minHeightPercent?: number // Minimum height percentage for the modal (useful if you want to make sure plenty of space if provided for the contents, regardless of how much contents there are)
}

export const Modal = (props: IModalProps) => {
	let {
		modalId,
		children,
		modalTitle,
		footer,
		onModalHidden,
		onModalShown,
		onModalHide,
		onModalShow,
		show,
		closeModal,
		_onModalShown,
		_onModalHidden,
		allowOverflow,
		size,
		noBodyPadding,
		dismissible,
		minHeightPercent: minHeight,
		className,
		style
	} = props

	if (show !== undefined && closeModal === undefined) throw new Error(`When using the new non-bootstrap method to show/hide modals, you must provide both the show boolean AND the close method (which sets the value of the 'show' boolean).`)

	const labeledById = uuidv4()

	if (size === 'fullscreen') minHeight = 100

	useEffect(() => {
		if (onModalHidden) bootstrapOnModalHidden(modalId, onModalHidden)
		if (onModalShown) bootstrapOnModalShown(modalId, onModalShown)
		if (onModalHide) bootstrapOnModalHide(modalId, onModalHide)
		if (onModalShow) bootstrapOnModalShow(modalId, onModalShow)

		//eslint-disable-next-line
	}, [onModalHidden, onModalHidden, onModalHide, onModalShow])

	const modalRef = useRef<HTMLDivElement>(null)
	const wasOpen = useRef(show)
	useEffect(() => {
		if (modalRef.current && show !== undefined) {
			if (show) {
				showModal(modalId)
				if (_onModalShown) _onModalShown()
			} else {
				hideModal(modalId)
				if (_onModalHidden && wasOpen.current) _onModalHidden()
			}
			wasOpen.current = show
		}
	}, [show])

	useEffect(() => {
		return function cleanup() {
			destroyModal(modalId)
		}
	}, [])

	const modal = (
		<div
			/* 
				This is part of taking over Bootstrap's show/hide functionality for modals.
				If the component presenting this modal passed a function for closeModal, 
				then we need to handle the onClick for the backdrop - if closeModal is passed AND dismissible isn't set to false,
				then we set the backdrop to 'static' (this stops Bootstraps from responding to clicks on the backdrop and closing it) 
				then we ++go ahead and use closeModal to hide this modal instead of letting Bootstrap do it.
			*/
			onClick={() => {
				if (!isNullOrUndefined(dismissible) && !dismissible) return
				if (closeModal) closeModal()
			}}
			data-backdrop={(!isNullOrUndefined(dismissible) && !dismissible) || closeModal ? 'static' : false}
			ref={modalRef}

			className={`modal ${className ? className : ''} fade ${allowOverflow ? 'allow-overflow' : ''}`}
			id={modalId}
			role='dialog'
			aria-labelledby={labeledById}
			aria-hidden='true'
			style={style}
		>
			<div onClick={(e) => e.stopPropagation()} className={`modal-dialog modal-dialog-scrollable modal-dialog-centered ${size ? `modal-${size}` : ''}`} role='document'>
				<div className='modal-content' style={{ ...minHeight ? { minHeight: `${minHeight}%` } : {} }}>
					<div className='modal-header'>
						{typeof modalTitle === 'string' ?
							<h5 className='modal-title' id={labeledById}>{modalTitle}</h5>
							:
							modalTitle
						}
						<button type='button' className='close' onClick={() => {
							console.log('clicked')
							console.log('closeModal', closeModal)
							if (closeModal) {
								closeModal()
							} else {
								hideModal(modalId)
							}
						}} aria-label='Close'>
							<span aria-hidden='true'>&times;</span>
						</button>
					</div>
					<div className='modal-body' style={noBodyPadding ? { padding: '0' } : {}}>
						{children}
					</div>
					{footer ?
						<div className='modal-footer'>
							{footer}
						</div>
						:
						null
					}
				</div>
			</div>
		</div>
	)
	
	return createPortal(modal, portalRoot)
}
