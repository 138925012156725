import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { IMailBlastRecipientDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { FileField, TextareaField } from './forms'
import { Grid } from './grid'
import { DefaultGridCellDisplay } from './grid-cell-displays'
import { parse } from 'papaparse'
import * as Yup from 'yup'
import { flatten } from 'lodash'
import { ExpandableSection } from './expandable-section'

interface ISelectRecipientsByCsv {
	recipientsSelected: (recipients: IMailBlastRecipientDocument[]) => void
}

export const SelectRecipientsByCsv = (props: ISelectRecipientsByCsv) => {
	const { recipientsSelected } = props

	const [emails, setEmails] = useState<string[]>()

	const dataSource: GridDataFetch<string[]> = async (queryState, _emails) => {

		if (!_emails) return { rows: [], count: 0 }

		const _recipients = _emails.map(email => ({ email }))

		if (queryState.sorts) sortListBySorts(_recipients, queryState.sorts)

		let rows: IGridListItem[] = _recipients.map(o => ({ id: o.email, values: { email: o.email } }))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: true,
		columns: [
			{
				property: 'email',
				type: 'string',
				width: 153,
				allowFilters: true,
				title: 'Email',
				render: DefaultGridCellDisplay
			},
		],
		disabledPagination: true,
		hideGridHeader: true,
		dataSource,
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, emails)

	useEffect(() => {
		recipientsSelected(gridState.rows.filter(r => r.selected).map(r => ({ name: ``, email: `${r.values.email}`, ministryContactId: r.values.ministryContactID, prospectId: r.values.prospectId })) as IMailBlastRecipientDocument[])
		//eslint-disable-next-line
	}, [gridState.rows])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [emails])

	return (
		<Formik
			initialValues={{
				csvFile: '',
				pastedEmails: ''
			}}
			validationSchema={Yup.object({
				csvFile: Yup.mixed().test(
					'csvFile',
					'Please upload or paste a list of emails.',
					function (value) {
						if (!value && !this.parent.pastedEmails) return false
						return true
					}
				),
				pastedEmails: Yup.mixed().test(
					'pastedEmails',
					'Please upload or paste a list of emails.',
					function (value) {
						if (!value && !this.parent.csvFile) return false
						return true
					}
				)
			})}
			onSubmit={async (values) => {
				if (values.csvFile) {
					parse<string>(values.csvFile, {
						complete: (results) => {
							setEmails(_emails => [..._emails || [], ...flatten(results.data).filter(o => !!o && o.toLowerCase() !== 'email')])
						}
					})
				}

				if (values.pastedEmails) {
					const results = parse<string>(values.pastedEmails)
					setEmails(_emails => [..._emails || [], ...flatten(results.data).filter(o => !!o && o.toLowerCase() !== 'email')])
				}
			}}
		>
			{formikProps => {
				return (
					<Form style={{ height: '100%', }}>

						<ExpandableSection>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ flex: 1 }}>
									<FileField accept={'accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'} fieldProps={{ name: 'csvFile', label: 'Upload CSV' }} />
								</div>
								<p style={{ margin: '0px 20px' }}>or</p>
								<div style={{ flex: 1 }}>
									<TextareaField fieldProps={{ name: 'pastedEmails', label: 'Paste a delimitted list of emails' }} />
								</div>
							</div>

							<p>
								Emails are validated by:
								<ul>
									<li>Removing empty values (e.g. a row with no email address provided)</li>
									<li>Removing duplicate email addresses</li>
									<li>Ensuring all email addresses are valid</li>
								</ul>
							</p>
							<p>
								If an email address has been <b>unsubscribed</b>, it will be excluded from the mail blast at the time it is sent.
							</p>
							<button type='button' className='btn btn-primary mb-4' onClick={formikProps.submitForm}>Parse Emails</button>
						</ExpandableSection>

						{emails ?
							<React.Fragment>
								<b>{emails.length} contact{emails.length === 1 ? '' : 's'}</b>
								<Grid state={gridState} actions={gridActions} style={{ height: 'auto', flex: '1' }} />
							</React.Fragment>
							:
							null
						}
					</Form>
				)
			}}
		</Formik>
	)
}