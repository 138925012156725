import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../../app-store-provider"
import { AccountLevel } from "../../constants"
import { ISearchRequestFilterDocument } from "../../open-api"
import { useHTTPRequestUiWrapper } from "../../services/hooks"
import { IFilter } from "../../stores/api-actions"
import { Loading, LoadingPropsSizeEnum } from "../loading"
import { FormikSelectField, IFormikSelectFieldProps, ISelectFieldOption } from './formik-select-field'

const filter: IFilter = {
    id: 'branchIdFilter',
    enabled: true,
    value: [],
    operator: 'in',
    property: 'branchAbbr'
}

interface IFormikMinistrySelectFieldProps extends IFormikSelectFieldProps {
    branchId?: number
    //setSites?: Dispatch<SetStateAction<IEventSiteModelDocument[]>> | ((sites: IEventSiteModelDocument[]) => void)
}
export const FormikMinistrySelectField = (props: IFormikMinistrySelectFieldProps) => {
    const appState = useContext(AppStateContext)!
    const { branchId } = props
    const { MinistriesApi } = useContext(AppActionContext)!
    const [ministriesLoading, setMinistriesLoading] = useState(false)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [ministryOptions, setMinistryOptions] = useState<ISelectFieldOption[]>([])
    const fetchMinistryOptionsForBranchId = useCallback(async () => {
        const branch = appState.activeBranches.find(o => o.branchId == branchId)
        if (!branch?.branchAbbr) return

        setMinistriesLoading(true)
        filter.value = [branch.branchAbbr]
        const branchFilter = JSON.stringify([filter]) as unknown as ISearchRequestFilterDocument[]
        const ministryQuery = await makeHttpRequestWithUi({
            request: MinistriesApi.apiMinistriesGet(0, 100000, undefined, branchFilter),
            toastErrorMessage: 'There was an error retrieving the ministries for this community.',
            disableSuccessToast: true,
            disableLoading: true,
        })

        setMinistryOptions(ministryQuery.data.data?.map(o => ({ label: `${o.ministryName} ${o.levelId ? `(${AccountLevel[o.levelId]})` : ''}`, value: `${o.ministryId}` })) || [])
        setMinistriesLoading(false)
    }, [branchId])

    useEffect(() => {
        console.log('branchId', branchId)
        if (branchId !== undefined && branchId > 0) {
            fetchMinistryOptionsForBranchId()
        } else {
            setMinistryOptions([])
        }
    }, [branchId])

    return (
        <>
            {ministriesLoading ?
            <Loading size={LoadingPropsSizeEnum.small} />
            :
            <FormikSelectField
                {...props}
                options={ministryOptions}
            />
            }
        </>
    )
}