import moment from 'moment'
import React from 'react'
import { IMailBlastSummaryDocument, IRecipientSearchResultDocument } from '../open-api'

interface ICustomMailBlastOverview {
	summary: IMailBlastSummaryDocument
	recipients: IRecipientSearchResultDocument[]
}

export const CustomMailBlastOverview = (props: ICustomMailBlastOverview) => {
	const { summary, recipients } = props

	return (
		<div>
			<div className='d-flex justify-content-between'>
				<div>
					<div><label>Recipients: </label> {recipients.length}</div>
					<div><label>From: </label> {summary.fromName} &lt;{summary.fromEmail}&gt;</div>
					{(summary.scheduleDate) ?
						<div><label>Scheduled: </label> {summary.dSend ? moment(summary.scheduleDate).format('M/D/YYYY h:mm A') + ' Pacific Time' : ''}</div>
						: 
						<div><label>Sent: </label> {summary.dSend ? moment(summary.dSend).format('M/D/YYYY h:mm A') + ' Pacific Time' : ''}</div>
					}
					<div><label>Subject: </label> {summary.subject}</div>
				</div>
				<div>
					<div><label>Created: </label> {summary.dCreated ? moment(summary.dCreated).format('M/D/YYYY') : ''}</div>
					<div><label>Layout: </label> {summary.layoutName}</div>
					<div><label>Event: </label> {summary.eventTitle}</div>
				</div>
			</div>

			<p className='mt-2'><i>This is a sample of the body for a single recipient, but each recipients body may look slightly different.</i></p>
			<div style={{ backgroundColor: '#6c757d20', border: 'solid 1px white', borderRadius: '5px', padding: 10 }} dangerouslySetInnerHTML={{ __html: summary.body ? summary?.body : '' }}></div>
		</div>
	)
}