import React, { useContext, useState, useEffect } from "react"
import { MinistryInfoContext } from './ministry-info'
import { IMinistryInfoState } from "../stores/ministry-info-definitions"
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, uuidv4, showModal, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { EmailLogModel, emailLogModelDefaultColumns } from '../models/email-log'
import { Grid } from "./grid"
import { gridReducer } from "../stores/grid-reducer"
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { Modal } from "./modal"
import { Loading } from "./loading"

export const MinistryInfoEmailLog = () => {
	console.log('MinistryInfoEmailLog')
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

	const fetchMinistryEmailLog: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryEmailLog } = _state

		if (!ministryEmailLog) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryEmailLog, queryState.sorts)

		let rows = ministryEmailLog.map(attendance => EmailLogModel.toGridListItem(attendance))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: emailLogModelDefaultColumns,
		disabledPagination: true,
		dataSource: fetchMinistryEmailLog,
		rowActions: {
			viewEmail: {
				id: 'viewEmail',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(viewEmailModal)
				},
				icon: <SearchIcon />,
				tooltipText: 'View Email',
			}
		}
	}
	const [viewEmailModal] = useState(uuidv4())

	useEffect(() => {
		ministryInfoActions.fetchEmailLog()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryEmailLog])


	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	/* 
		Whenever the ministry changes, refresh the data for the grid.
	*/
	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministry])

	if (!ministryInfoState.ministryEmailLog || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			< Modal
				modalId={viewEmailModal}
				modalTitle='Email Body'
				size='xl'
				onModalHidden={() => setSelectedRow(undefined)}>
				<div dangerouslySetInnerHTML={{ __html: typeof selectedRow?.values.body === "string" ? selectedRow?.values.body : '' }}></div>
			</Modal >
		</React.Fragment>
	)
}