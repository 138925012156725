import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IApptCalendarResponseDocument, IConsultingAreaDirectorDocument } from "../open-api"
import { uuidv4 } from "../services/helpers"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import FullCalendar, { EventSourceInput } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import dayListPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Modal } from "./modal"
import dayjs from "dayjs"

interface IConsultingMyCalendarProps {

}

export const ConsultingMyCalendar = () => {
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const { currentUser, globalCommunityContext, } = useContext(AppStateContext)!
    const { ConsultingApi, addAlert } = useContext(AppActionContext)!

    const [calendarEvents, setCalendarEvents] = useState<IApptCalendarResponseDocument[]>()
    const fullCalendarEvents: EventSourceInput = useMemo(() => {
        if (!calendarEvents) return []
        return calendarEvents?.map(o => ({
            start: o.startDateTime,
            end: o.endDateTime,
            title: `${o.eventId && o.apptCalendarContacts?.length ? o.apptCalendarContacts[0].ministryName : o.subject}`,
            backgroundColor: o.eventId ? '' : '#198754',
            color: o.eventId ? '' : '#198754',
            id: o.eventId?.toString() || o.consultingHourId?.toString(),
            eventId: o.eventId,
            classNames: o.eventId ? 'cursor-pointer' : ''
        }))
    }, [calendarEvents])

    const fetchEvents = useCallback(async (presenterIds: number[]) => {
        const { data } = await makeHttpRequestWithUi({
            request: ConsultingApi.apiConsultingGetApptCalendarPost(presenterIds),
            disableSuccessToast: true,
            toastErrorMessage: 'Error retrieving calendar events for this area director.'
        })
        setCalendarEvents(data)
    }, [])

    const [areaDirectors, setAreaDirectors] = useState<IConsultingAreaDirectorDocument[]>()
    useEffect(() => {
        makeHttpRequestWithUi({
            request: ConsultingApi.apiConsultingAreaDirectorsGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of area directors.'
        })
            .then(results => setAreaDirectors(results.data))
    }, [])

    const [eventDetailModal, showHideEventDetailModal] = useModal()
    const [selectedEvent, setSelectedEvent] = useState<IApptCalendarResponseDocument>()
    useEffect(() => {
        showHideEventDetailModal(!!selectedEvent)
    }, [selectedEvent])

    useEffect(() => {
        // Don't want to redo this once we have Area Directors...just wait until they're ready.
        if (!areaDirectors) return

        if (currentUser?.bSuperUser && globalCommunityContext) {
            const branchAreaDirectors = areaDirectors.filter(o => o.branchId === globalCommunityContext.branchId)
            console.log('branchAreaDirectors', branchAreaDirectors.map(o => o.presenterId))

            if (branchAreaDirectors) {
                fetchEvents(branchAreaDirectors.map(o => o.presenterId))
            } else {
                addAlert({
                    id: uuidv4(),
                    title: 'Error',
                    body: 'Could not locate the area director for the selected community. Please ensure this community has an area director or select another community.',
                })
            }
        } else if (currentUser?.presenterId && currentUser?.presenterId > 0) {
            fetchEvents([currentUser.presenterId])
        } else if (currentUser?.branchId && currentUser?.branchId > 0) {
            // In some cases (like Jackie Peterman), the logged in user may be an ADs assistant. Not a super user and don't have a presenterId.
            const areaDirector = areaDirectors.find(o => o.branchId === currentUser?.branchId)
            if (areaDirector) fetchEvents([areaDirector.presenterId])
        } else {
            addAlert({
                id: uuidv4(),
                title: 'No Community Selected',
                body: 'Please select a community from the Global Community context to view the calendar for that community.',
            })
        }
    }, [currentUser, globalCommunityContext, areaDirectors])

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ flex: 1 }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, dayListPlugin, bootstrapPlugin, timeGridPlugin]}
                        initialView='timeGridWeek'
                        headerToolbar={{
                            start: 'prev,next today',
                            center: 'title',
                            end: 'listWeek,dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        themeSystem='bootstrap'
                        events={fullCalendarEvents}
                        height='100%'
                        eventClick={info => {
                            console.log('info', info)
                            setSelectedEvent(calendarEvents?.find(o => o.eventId?.toString() === info.event.id))
                        }}
                        allDaySlot={false}
                    />
                </div>
            </div>

            <Modal
                {...eventDetailModal}
                modalTitle='Event Detail'
                size='lg'
                dismissible
                _onModalHidden={() => setSelectedEvent(undefined)}
            >
                <div>
                    <h5><a target='_blank' href={`/ministry-info/${selectedEvent?.apptCalendarContacts && selectedEvent?.apptCalendarContacts[0].ministryId}`}>{selectedEvent?.apptCalendarContacts && selectedEvent?.apptCalendarContacts[0].ministryName} {selectedEvent?.subject ? `(${selectedEvent?.subject})` : null}</a></h5>
                    <div>{dayjs(selectedEvent?.startDateTime).format('dddd, MMMM D')}</div>
                    <div>{dayjs(selectedEvent?.startDateTime).format('h:mma')} - {dayjs(selectedEvent?.endDateTime).format('h:mma')}</div>
                    <div className='mt-2'>
                        <b>Attendees:</b>
                        <ul>
                            {selectedEvent?.apptCalendarContacts?.map(o => (<li>{o.firstName} {o.lastName} {o.businessPhone ? `(${o.businessPhone})` : null}</li>))}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    )
}