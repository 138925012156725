import { Link } from "@reach/router"
import { useCallback, useEffect } from "react"
import { PersonIcon } from "../assets"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { useGrid, defaultGridState } from "../stores/grid-actions"
import { GridDataFetch, GridListItemValueType, IGridListItem } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { useEventFormContext } from "./event-form"
import { Grid } from "./grid"
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell } from "./grid-cell-displays"
import { COURSE_STATUS_OPTIONS } from "../constants"
//import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"

export const EventFormCourses = () => {
    const { eventToEdit, lmsUserCourses, fetchLmsUserCourses } = useEventFormContext()

    useEffect(() => {
        console.log('EventFormCourses > useEffect > fetchLmsUserCourses()')
        if (eventToEdit) fetchLmsUserCourses()
    }, [eventToEdit])
    
    const dataSource: GridDataFetch<typeof lmsUserCourses> = async (_queryState, userCourses) => {
        try {
            if (!userCourses) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(userCourses, _queryState.sorts)
            let rows: IGridListItem[] = userCourses.map(o => ({
                id: o.userId.toString(),
                values: {
                    courseId: o.courseId,
                    userDisplayName: o.userDisplayName,
                    userEmail: o.userEmail,
                    userMinistryName: o.userMinistryName,
                    userMinistryId: o.userMinistryId,
                    userRegistered: o.userRegistered,
                    courseStatus: o.courseStatus,
                    courseProgressPercentage: o.courseProgressPercentage,
                    courseTitle: o.courseTitle,
                    groupManagementUrl: o.groupManagementUrl,
                    userProfileUrl: o.userProfileUrl
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            return {
                rows: [],
                count: 0
            }
        }
    }

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            dataSource,
            columns: [
                {
                    property: 'courseTitle',
                    title: 'Course',
                    width: 100,
                    type: 'string',
                    render: (col, row) => row.values.groupManagementUrl ? <a href={`${row.values.groupManagementUrl}`} target="_blank">{row.values[col.property]}</a> : <>{row.values[col.property]}</>,
                    allowFilters: true,
                },
                {
                    property: 'userMinistryName',
                    title: 'Ministry',
                    width: 100,
                    type: 'string',
                    render: (col, row) => <Link to={`/ministry-info/${row.values.userMinistryId}`}>{row.values[col.property]}</Link>,
                    allowFilters: true,
                },
                {
                    property: 'userDisplayName',
                    title: 'Contact Name',
                    width: 100,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                },
                {
                    property: 'userEmail',
                    title: 'Contact Email',
                    width: 100,
                    type: 'string',
                    render: EmailCellDisplay,
                    allowFilters: true,
                },
                {
                    property: 'courseStatus',
                    title: 'Status',
                    width: 100,
                    type: 'string',
                    render: (_, row) => {
                        if (!row.values.courseStatus) return <></>
                        const courseStatus = row.values.courseStatus
                        let textColor = 'text-danger'
                        if (courseStatus === "In Progress") textColor = 'text-warning'
                        if (courseStatus === "Completed") textColor = 'text-success'

                        return <span className={textColor}>{row.values.courseStatus}</span>
                    },
					allowFilters: true,
                    filterOptions: COURSE_STATUS_OPTIONS
                },
                {
                    property: 'courseProgressPercentage',
                    title: 'Progress',
                    width: 100,
                    type: 'number',
					allowFilters: true,
                    render: (_, row) =>
                    <div style={{backgroundColor: "#eeeeee", width: "100%"}}>
                        <div style={getProgressStyling(row.values.courseProgressPercentage)}>
                            {row.values.courseProgressPercentage?.toString()}% Complete
                        </div>
                    </div>
                },
                {
                    property: 'grid_actions',
                    type: 'actions',
                    width: 110,
                    disableSort: true,
                    title: 'Actions',
                    render: GridActionCell,
                    align: 'center',
                }
            ],
            usingLocalData: true,
            hideGridFooter: true,
            rowSelectEnabled: false,
			// gridActions: [
			// 	{
			// 		id: 'addNonRegistrant',
			// 		label: 'Add Non-Registrant',
			// 		icon: <PersonPlusIcon />,
            //         onClick: async ({ rows }) => {
			// 			showHideAddNonRegistrantModal(true)
            //         },
			// 	}
			// ] as IGridAction[],
            rowActions: {
                viewProfile: {
                    id: 'viewProfile',
                    tooltipText: "View contact's LMS profile",
                    icon: <PersonIcon />,
                    action: async (options) => {
                        options.e.stopPropagation()
                        if (options.row.values.userProfileUrl) window.open(options.row.values.userProfileUrl.toString())
                    },
                    disabled: (row) => !row.values.userProfileUrl,
                },
                // viewGroup: {
                //     id: 'viewGroup',
                //     tooltipText: "View course group info",
                //     icon: <JournalsIcon />,
                //     action: async (options) => {
                //         options.e.stopPropagation()
                //         if (options.row.values.groupManagementUrl) window.open(options.row.values.groupManagementUrl.toString())
                //     },
                //     disabled: (row) => !row.values.groupManagementUrl,
                // }
            },
        },
        lmsUserCourses,
    )

    useEffect(() => {
        console.log('useEffect > doFetch')
        console.log('lmsUserCourses', lmsUserCourses)
        gridActions.doFetch()
    }, [lmsUserCourses])

    const getProgressStyling = useCallback((percentage: GridListItemValueType): React.CSSProperties => {
		if (typeof percentage !== 'number') return {}
	
		let backgroundColor = '#dc354520'
		if (percentage > 33) backgroundColor = '#ffc10720'
		if (percentage > 66) backgroundColor = '#28a74520'
	
		return {
			width: `${percentage}%`,
			backgroundColor,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			textAlign: 'center',
			...percentage === 0 ? { marginLeft: 5 } : { paddingLeft: 5, }
		}
	}, [])

    return (
        <>
            <div className="mt-2 mb-1">NOTE: Users must be granted access to the event's course and afterwards log into the LMS before they show up here.</div>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>
        </>
    )
}