import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { JournalsIcon, PencilIcon } from "../assets"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { IDefaultProps } from "./component-definitions"
import { Modal } from "./modal"
import { GridDataFetch, IGridListItem } from "../stores/grid-definitions"
import { GroupCoachingTopicForm } from "./group-coaching-topic-form"
import { AppActionContext } from "../app-store-provider"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { Grid } from "./grid"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { IGroupCoachingTopicResultDocument } from "../open-api"
import { sortListBySorts, filterGridListItems } from "../services/helpers"
import { AccountLevel } from "../constants"
import { SquareDeleteIcon } from "./partials"

interface IGroupCoachingTopicsProps extends IDefaultProps { }

export const GroupCoachingTopics = ({ }: IGroupCoachingTopicsProps) => {

    const { GroupCoachingTopicApi } = useContext(AppActionContext)!

    const [formModal, showHideFormModal] = useModal()
    const [confirmDeleteModal, showHideConfirmDeleteModal] = useModal()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [topicToEdit, setTopicToEdit] = useState<IGroupCoachingTopicResultDocument>()
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const [topicData, setTopicData] = useState<{ topics?: IGroupCoachingTopicResultDocument[], topicTypes?: { id: number, name: string }[] }>()
    const fetchTopics = async () => {
        const query = await makeHttpRequestWithUi({
            request: GroupCoachingTopicApi.apiGroupCoachingTopicGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving group learning topics.'
        })
        setTopicData(_topicData => ({ ..._topicData, topics: query.data }))
    }
    useEffect(() => {
        fetchTopics()

        makeHttpRequestWithUi({
            request: GroupCoachingTopicApi.groupCoachingTopicTypesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving group learning topic types.'
        })
            .then(results => {
                setTopicData(_topicData => ({ ..._topicData, topicTypes: results.data as { id: number, name: string }[] }))
            })
    }, [])

    const dataSource: GridDataFetch<typeof topicData> = async (queryState, _topicsData) => {
        if (!_topicsData?.topics || !_topicsData?.topicTypes) {
            return {
                rows: [],
                count: 0,
            }
        }
        const { topicTypes, topics } = _topicsData

        if (queryState.sorts) sortListBySorts(topics, queryState.sorts)
        let rows = topics.map<IGridListItem>(o => ({
            id: o.id.toString(),
            values: {
                ...o,
            }
        }))
        if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

        rows = rows.map(o => ({
            ...o,
            values: {
                ...o.values,
                groupCoachingTopicTypeId: topicTypes?.find(t => o.values.groupCoachingTopicTypeId === t.id)?.name || '',
                allowedAcctLevelsIds: (o.values.allowedAcctLevelsIds as number[]).map(o => AccountLevel[o]).join(', ')
            }
        }))

        return {
            rows: rows,
            count: rows.length,
        }
    }

    /* 
        Columns
        - title
        - isActive
        - groupCoachingTopicType
        - maxSeatCount
        - guestsAllowed
        - allowedAcctLevels
    */
    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            usingLocalData: true,
            hideGridFooter: true,
            dataSource,
            gridActions: [
                {
                    id: 'add',
                    label: 'Add Topic',
                    onClick: () => showHideFormModal(true)
                }
            ],
            rowActions: {
                edit: {
                    id: 'edit',
                    action: async ({ row, e }) => {
                        e.stopPropagation()
                        const query = await makeHttpRequestWithUi({
                            request: GroupCoachingTopicApi.apiGroupCoachingTopicIdGet(parseInt(row.id)),
                            disableSuccessToast: true,
                            toastErrorMessage: 'Encountered an error retrieving the topic to edit.'
                        })
                        setTopicToEdit(query.data)
                        setSelectedRow(row)
                        showHideFormModal(true)
                    },
                    icon: <PencilIcon />,
                    tooltipText: 'Edit Topic',
                },
                delete: {
                    id: 'delete',
                    action: async (options) => {
                        options.e.stopPropagation()
                        setSelectedRow(options.row)
                        showHideConfirmDeleteModal(true)
                    },
                    icon: <SquareDeleteIcon />,
                    tooltipText: 'Delete Topic',
                }
            },
            columns: [
                {
                    property: 'title',
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    title: 'Title',
                    width: 200,
                    allowFilters: true,
                },
                {
                    property: 'groupCoachingTopicTypeId',
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    title: 'Topic Type',
                    width: 100,
                    allowFilters: true,
                },
                {
                    property: 'maxSeatCount',
                    type: 'number',
                    render: (col, row) => typeof row.values[col.property] === 'number' ? row.values[col.property]?.toLocaleString() || '' : row.values[col.property],
                    title: 'Max Seat Count',
                    width: 130,
                    allowFilters: true,
                },
                {
                    property: 'guestsAllowed',
                    type: 'boolean',
                    render: DefaultGridCellDisplay,
                    title: 'Guests Allowed',
                    width: 130,
                    allowFilters: true,
                },
                {
                    property: 'allowedAcctLevelsIds',
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    title: 'Allowed Account Levels',
                    width: 200,
                    allowFilters: true,
                    filterOptions: Object.values(AccountLevel).filter(o => typeof o === 'number').map(o => ({ label: AccountLevel[(o as number)], value: o.toString() }))
                },
                {
                    property: 'grid_actions',
                    type: 'actions',
                    width: 85,
                    disableSort: true,
                    title: 'Actions',
                    render: GridActionCell,
                    align: 'center',
                },
                {
                    property: 'isActive',
                    type: 'boolean',
                    render: DefaultGridCellDisplay,
                    title: 'Active',
                    width: 100,
                    allowFilters: true,
                },
            ]
        },
        topicData
    )

    useEffect(() => {
        gridActions.doFetch()

        if (topicData?.topicTypes) {
            const groupCoachingTopicTypeColumn = gridState.columns.find(c => c.property === 'groupCoachingTopicTypeId')
            if (groupCoachingTopicTypeColumn) {
                gridActions.updateColumn({
                    ...groupCoachingTopicTypeColumn,
                    filterOptions: topicData.topicTypes?.map(o => ({ label: `${o.name}`, value: `${o.id}` }))
                })
            }
        }
    }, [topicData])


    return (
        <>
            <Helmet>
                <title>Group Learning Topics</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <JournalsIcon />
                    <h3 className='ml-2'>Group Learning Topics</h3>
                </div>

                <Grid state={gridState} actions={gridActions} style={{ height: 'unset' }} />

            </div>

            <Modal
                {...formModal}
                modalTitle='Group Learning Topic'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setTopicToEdit(undefined)
                }}
            >
                {topicData?.topicTypes &&
                    <GroupCoachingTopicForm
                        topicTypes={topicData.topicTypes}
                        topicToEdit={topicToEdit}
                        afterSave={async () => {
                            showHideFormModal(false)
                            fetchTopics()
                        }}
                    />
                }
            </Modal>

            <Modal
                {...confirmDeleteModal}
                modalTitle='Confirm'
                _onModalHidden={() => setSelectedRow(undefined)}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmDeleteModal(false)}>Cancel</button>
                        <button
                            className='btn btn-danger'
                            onClick={async () => {
                                if (!selectedRow) return
                                await makeHttpRequestWithUi({
                                    request: GroupCoachingTopicApi.apiGroupCoachingTopicIdDelete(parseInt(selectedRow.id)),
                                    toastSuccessMessage: 'Deleted topic.',
                                    toastErrorMessage: 'Encountered an error deleting topic.',
                                })
                                showHideConfirmDeleteModal(false)
                                fetchTopics()
                            }}
                        >
                            Delete
                        </button>
                    </>
                }
            >
                {selectedRow &&
                    <div>
                        <p>Are you sure you want to delete the <b>{selectedRow.values.title} topic?</b></p>
                        <p>This action cannot be undone.</p>
                    </div>
                }
            </Modal>
        </>
    )
}

