import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext } from "../app-store-provider"
import { IDefaultProps } from "./component-definitions"
import { PowerBIEmbed } from "powerbi-client-react"
import { models, Report, Embed, service } from 'powerbi-client';
import { useHTTPRequestUiWrapper } from "../services/hooks"

export const AnnualKpisReport = ({ }: IDefaultProps) => {

    //const { globalCommunityContext } = useContext(AppStateContext)!
    const { PbiApi } = useContext(AppActionContext)!

    //const [activeCommunity, setActiveCommunity] = useState<IMiBranchModelDocument>()
    const [loading, setLoading] = useState(false)
    const [reportKpi, setReportKpi] = useState<Report>()
    const [kpiError, setKpiError] = useState<string>()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    // Report config useState hook
	// Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
	const [kpiReportConfig, setKpiReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: { navContentPaneEnabled: false }
	})

    const fetchKpiReport = async () => {
        setLoading(true)
        const reportQuery = await makeHttpRequestWithUi({
            request: PbiApi.apiPbiReportIdIdGet('9304c34f-ec6f-4f85-87d3-45dddcba9c51'),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch Report.',
            disableLoading: true,
        })

        if (reportQuery.data) {
            if (reportQuery.data.embedUrl) {
                setKpiError('')
                // Set the fetched embedUrl and embedToken in the report config
                setKpiReportConfig({
                    ...kpiReportConfig,
                    embedUrl: reportQuery.data.embedUrl || '',			// embedUrl: reportConfig.EmbedUrl,
                    accessToken: reportQuery.data.token || ''	// accessToken: reportConfig.EmbedToken.Token
                })
            } else {
                setKpiError("Report unavailable. Reason: " + reportQuery.data.noReportReason)
            }
        }

        setLoading(false)
    }

    // useEffect(() => {
    //     if (globalCommunityContext) {
    //         setActiveCommunity(globalCommunityContext)
    //     } else {
    //         setActiveCommunity(undefined)
    //     }
    // }, [globalCommunityContext])

    useEffect(() => {
        fetchKpiReport()
    }, [])

    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded')
        }],
        ['rendered', function () {
            console.log('Report has rendered')
        }],
        ['error', function (event?: service.ICustomEvent<any>) { 
            if (event) {
                console.log('error occurred')
                console.error(event.detail)
            }
        }]
    ]);
    
    return(
        <>
            <Helmet>
                <title>Annual KPI Report [BETA]</title>
            </Helmet>

            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active">Reports</li>
                <li className="breadcrumb-item active" aria-current="page">Annual KPI Report</li>
            </ol>
            </nav>
            <div className='d-flex flex-column' style={{ minHeight: 0, overflow: 'hidden' }}>
                <div className='m-2 d-flex align-items-center'>
                    {/* <HouseIcon style={{ width: '25px', height: '25px' }} /> */}
                    <h3 className='ml-2'>Annual KPI Report [BETA]</h3>
                </div>
                <div className="row pl-3 pr-3" style={{ overflow: 'hidden' }}>
                    <div className="col-sm-12">
                        <PowerBIEmbed
                            embedConfig = { kpiReportConfig }
                            eventHandlers = { eventHandlersMap }
                            cssClassName = { "report-style-class" }
                            getEmbeddedComponent = { (embedObject:Embed) => {
                                console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                                setReportKpi(embedObject as Report);
                            } }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}