import { IGrantGiftModelDocument } from "../open-api"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { useContext } from "react"
import { CheckboxField, CommunitySelectField } from "./forms"
import { FormikTextAreaField } from "./forms/formik-text-area-field"

interface IGrantFormSummaryProps {
    grantToEdit?: IGrantGiftModelDocument
    afterSave: () => Promise<void>
}

export const GrantFormSummary = (props: IGrantFormSummaryProps) => {
    const { grantToEdit, afterSave } = props
    const { GrantGiftApi } = useContext(AppActionContext)!
    const {currentUser} = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const initialValues = {
        branchId: grantToEdit?.branchId || currentUser?.branchId || 0,
        grantTypeId: grantToEdit?.grantTypeId || 0,
        description: grantToEdit?.description || '',
        isActive: grantToEdit?.isActive || true
    }

    const validationSchema = Yup.object({
        branchId: Yup.number().required('Required'),
        grantTypeId: Yup.number().required('Required'),
        description: Yup.string().required('Required'),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                if (grantToEdit) {
                    await makeHttpRequestWithUi({
                        request: GrantGiftApi.apiGrantGiftGrantGiftIdPut( grantToEdit.grantGiftId, {
                                branchId: values.branchId,
                                amount: 0,
                                isActive: values.isActive,
                                description: values.description
                        }),
                        toastSuccessMessage: 'Successfully updated grant.',
                        toastErrorMessage: 'There was an error updating grant.'
                    })
                } else {
                    await makeHttpRequestWithUi({
                        request: GrantGiftApi.apiGrantGiftPost({
                            branchId: values.branchId,
                            amount: 0,
                            description: values.description
                        }),
                        toastSuccessMessage: 'Successfully created grant.',
                        toastErrorMessage: 'There was an error creating grant.'
                    })
                }

                if (afterSave) afterSave()
            }}
        >
            {formikProps => {
                return (
                    <Form style={{ padding: 10 }}>
                        {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                            You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                        <CommunitySelectField
                            fieldProps={{ name: 'branchId', label: 'Community' }}
                            disabled={!!grantToEdit}
                        />
                        <FormikTextAreaField field={{ name: 'description', label: 'Description (visible to minitry)', labelTooltip: 'Visible to ministry, so be sensitive to that. Description should be short.' }} />
                        { !!grantToEdit &&
                            <div className='mb-3'>
                                <CheckboxField fieldProps={{ name: 'isActive', label: 'Active', labeltooltip: "When a grant is inactive, ministries can no longer apply for it." }} />
                            </div>
                        }
                        
                        <button style={{ width: 200 }} className='btn btn-primary mt-4' disabled={!formikProps.dirty}>Save</button>
                    </Form>
                )
            }}
        </Formik>
    )
}