import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IEventContentModelDocument, IEventSiteModelDocument, IGcAfterEventDataDocument, IGroupCoachingModelDocument, IGroupCoachingTopicResultDocument, IPresentersDocument, ISeminarEvalSummaryDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { Tabs } from "./tabs"
import { GroupCoachingFormAfterEvent } from './group-coaching-form-after-event'
import { IGroupCoachingEventStatus } from "./group-coaching"
import { GroupCoachingFormSummary } from "./group-coaching-form-summary"
import { EventFormRegistrants } from "./event-form-registrants"
import { EventEvals } from "./event-evals"

interface IGroupCoachingFormEditProps {
    selectedEventContent?: IEventContentModelDocument
    eventToEdit?: IGroupCoachingModelDocument
    afterSave?: () => Promise<void>

    summaryProps: {
        selectedTopic: IGroupCoachingTopicResultDocument
        presenters: IPresentersDocument[]
        closeModal?: () => void
        setEventToEdit: Dispatch<SetStateAction<IGroupCoachingModelDocument | undefined>>
        sites: IEventSiteModelDocument[]
        setSites: Dispatch<SetStateAction<IEventSiteModelDocument[]>>
    }

    initialTab?: 'summary' | 'registrants'
}
export const GroupCoachingFormTabs = ({ eventToEdit, afterSave, summaryProps, initialTab, selectedEventContent }: IGroupCoachingFormEditProps) => {
    if (!eventToEdit && !summaryProps.selectedTopic && !selectedEventContent) throw new Error('Must provide either a group learning event to edit or a selected group learning topic or selected event content.')

    const { GroupCoachingApi, SeminarEvalApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [afterEventData, setAfterEventData] = useState<IGcAfterEventDataDocument>()
    const [evals, setEvals] = useState<ISeminarEvalSummaryDocument[]>([])

    const isFirstLoad = useRef(true)
    const fetchAfterEventData = async () => {
        if (!eventToEdit) return
        if (eventToEdit.status !== IGroupCoachingEventStatus.canceled) {
            const query = await makeHttpRequestWithUi({
                request: GroupCoachingApi.apiGroupCoachingEventIdAfterApptDataGet(eventToEdit.eventId),
                toastErrorMessage: 'Encountered an error retrieving after event data.',
                disableSuccessToast: true,
            })

            setAfterEventData(query.data)
            if (!query.data.actualHours && eventToEdit && isFirstLoad.current && !initialTab) setActiveTab('afterEvent')
        }
        isFirstLoad.current = false
    }

    const fetchEvals = useCallback(async () => {
        if (!eventToEdit) return
        const { data } = await makeHttpRequestWithUi({
            request: SeminarEvalApi.apiSeminarEvalEventsEventIdGet(eventToEdit.eventId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching event evals.',
        })

        setEvals(data)
    }, [eventToEdit])

    useEffect(() => {
        fetchAfterEventData()
        fetchEvals()
    }, [eventToEdit])

    const [activeTab, setActiveTab] = useState<string>(initialTab || 'summary')

    return (
        <>
            <Tabs
                keepTabContentInDomWhenNotActive
                controls={{
                    activeTab,
                    setActiveTab
                }}
                tabs={[
                    {
                        id: 'summary',
                        title: 'Summary',
                        content: <GroupCoachingFormSummary
                            selectedEventContent={selectedEventContent}
                            eventToEdit={eventToEdit}
                            afterSave={afterSave}
                            {...summaryProps}
                        />
                    },
                    ...eventToEdit && eventToEdit.status !== IGroupCoachingEventStatus.draft ?
                        [{
                            id: 'registrants',
                            title: 'Registrants',
                            content:
                                <EventFormRegistrants
                                    eventToEdit={eventToEdit}
                                    eventContentTitle={eventToEdit.title}
                                    isGroupLearning={true}
                                    hasLmsCourses={false}
                                />
                        },
                        {
                            id: 'evals',
                            title: 'Eval Results',
                            content: <EventEvals evals={evals} />
                        },
                        ] : [],
                    ...eventToEdit && eventToEdit.status !== IGroupCoachingEventStatus.draft ?
                        [
                            {
                                id: 'afterEvent',
                                title: 'After Event',
                                content: 
                                    <GroupCoachingFormAfterEvent
                                        eventToEdit={eventToEdit}
                                        afterEventData={afterEventData}
                                    />
                            },
                        ] : []
                    ,
                ]}
            />
        </>
    )
}