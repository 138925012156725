import { IRecipientSearchResultDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class MailBlastRecipient {
	public static toGridListItem = (mailBlastRecipient: IRecipientSearchResultDocument): IGridListItem => {
		return {
			id: mailBlastRecipient.mailBlastContactId.toString(),
			values: {
				...mailBlastRecipient,
				contactName: `${mailBlastRecipient.firstName} ${mailBlastRecipient.lastName}`
			}
		}
	}
}