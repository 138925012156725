import { useState, useEffect, useContext } from 'react'
import React from 'react'
import { GridContext } from './grid'

export const GridPageNavigation = () => {
	const { state, actions } = useContext(GridContext)!

	const setItemsPerPage = (itemsPerPage: number) => {
		if (!state.loading) actions.setItemsPerPage(itemsPerPage)
	}

	const setSelectedPage = (selectedPage: number) => {
		if (!state.loading) actions.setSelectedPage(selectedPage)
	}

	const { loading, pages, queryState, pageSizeOptions, infinitePaging } = state
	const { page: selectedPage, itemsPerPage } = queryState

	const [pageArray, setPageArray] = useState<(number | '...')[]>([])

	const buildPageArray = () => {
		if (infinitePaging) {
			return setPageArray([selectedPage])
		}
		else if (pages > 9) {
			/* 
				More than 9 pages...showing page selection UI gets...complicated
				We need to show '...' depending on where the user is in selecting pages
			
				[] indicates selected page state (which page the user is viewing/has selected)
			
				State 1: [1], [2], [3], [4], ..., n
				State 2 (the middle range): 1, ..., [selectedPage - 1], [selectedPage], [selectedPage + 1], ..., n
				
				This state covers this range of selections:
				1, ..., 4, [5], 6, ..., n THROUGH 1, ..., [n-4], [n-3], [n-2], ..., n
	
				State 3: 1, ..., [n-3], [n-2], [n-1], [n]
		 
				Inspiration for this set of states comes from here: https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/paging/#page-size-selection
				...with a slight improvement (imo) of not changing the number of page items just for (what I can only imagine to be) perceived "coolness" and unnecessary UI change
			*/

			switch (selectedPage) {
				case 1:
				case 2:
				case 3:
				case 4:
					// State 1: [1], [2], [3], [4], ..., n
					setPageArray([1, 2, 3, 4, 5, '...', pages])
					break
				case pages - 3:
				case pages - 2:
				case pages - 1:
				case pages:
					// State 3: 1, ..., [n-3], [n-2], [n-1], [n]
					setPageArray([1, '...', pages - 3, pages - 2, pages - 1, pages])
					break
				default:
					// State 2: 1, ..., [selectedPage - 1], [selectedPage], [selectedPage + 1], ..., n
					setPageArray([1, '...', selectedPage - 1, selectedPage, selectedPage + 1, '...', pages])
					break
			}

		} else {
			// 9 or fewer pages...page selection UI is easy...we just render every page as an option.
			const _pageArray = []
			for (let index = 0; index < pages; index++) {
				_pageArray.push(index + 1)
			}
			setPageArray(_pageArray)
		}
	}

	useEffect(() => {
		buildPageArray()

		//eslint-disable-next-line
	}, [pages, selectedPage])

	let pageItems = <></>

	const forward = () => {
		if (selectedPage < pages || infinitePaging) {
			setSelectedPage(selectedPage + 1)
		}
	}

	const back = () => {
		if (selectedPage > 1) {
			setSelectedPage(selectedPage - 1)
		}
	}

	pageItems = (
		<React.Fragment>
			{pageArray.map((page, index) => (
				<li
					className={`page-item ${selectedPage === page ? 'active' : ''} ${loading || page === '...' ? 'disabled' : ''}`}
					onClick={() => { if (page !== '...') { setSelectedPage(page) } }}
					key={`pageItem-${index}`}
				>
					<div className='page-link'>{page}</div>
				</li>
			))}
		</React.Fragment>
	)

	return (
		<React.Fragment>
			<nav aria-label='Page sizes'>
				<ul className='pagination'>
					{pageSizeOptions.map(pageSize => (
						<li
							key={pageSize}
							onClick={() => setItemsPerPage(pageSize)}
							className={`page-item ${itemsPerPage === pageSize ? 'active' : ''} ${loading ? 'disabled' : ''}`}
						>
							<div className='page-link'>{pageSize}</div>
						</li>
					))}
				</ul>
			</nav>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{(state.totalCount && !state.infinitePaging) ? <span className='mr-2 text-muted'>{state.totalCount?.toLocaleString('en-US', { maximumFractionDigits: 0 })} records</span> : null}
				<nav aria-label='Page navigation'>
					<ul className='pagination'>
						<li onClick={back} className={`page-item ${loading ? 'disabled' : ''}`}>
							<div className='page-link' aria-label='Previous'>
								<span aria-hidden='true'>&laquo;</span>
							</div>
						</li>

						{pageItems}

						<li onClick={forward} className={`page-item ${loading ? 'disabled' : ''}`}>
							<div className='page-link' aria-label='Next'>
								<span aria-hidden='true'>&raquo;</span>
							</div>
						</li>
					</ul>
				</nav>
			</div>

		</React.Fragment>
	)
}