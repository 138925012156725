import { IPresentersDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class PresenterModel {
    public static toGridListItem = (presenter: IPresentersDocument): IGridListItem => {
        return {
            id: presenter.presenterId.toString(),
            values: {
                ...presenter,
            },
        }
    }
} 