import dayjs from "dayjs"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext } from "../app-store-provider"
import { PencilIcon, FileMedicalIcon, IncognitoIcon } from "../assets"
import { GRANT_REQUEST_STATUS } from "../constants"
import { IGrantRequestSummaryDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { openUrlInNewTab } from "../services/helpers"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { IFilter, ISort } from "../stores/api-actions"
import { IAppState } from "../stores/app-definitions"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from '../stores/grid-reducer'
import { IDefaultProps } from "./component-definitions"
import { ISelectFieldOption, isSelectFieldOption } from "./forms"
import { GrantRequestForm } from "./grant-request-form"
import { Grid } from "./grid"
import { AccountStatusDisplay, CurrencyDisplay, DefaultGridCellDisplay, GrantEligibleDisplay, GridActionCell, MinistryNameDisplay } from "./grid-cell-displays"
import { Modal } from "./modal"

// const GrantRequestStatus = [
//     {
//         label: 'All Active',
//         value: 0
//     },
//     {
//         label: 'All Active',
//         value: -1
//     },
//     {
//         label: 'Request In Progress',
//         value: 1
//     }
//     ,
//     {
//         label: 'Request Submitted',
//         value: 2
//     }
// ]

enum Mode {
    'all' = 'all',
    'active' = 'active',
    'tabled' = 'tabled',
    'complete' = 'complete',
    'denied' = 'denied',
}

interface IGrantRequestsProps extends IDefaultProps { }

export const GrantRequests = (props: IGrantRequestsProps) => {
    const { GrantRequestApi } = useContext(AppActionContext)!
    
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [grantRequestToEdit, setGrantRequestToEdit] = useState<IGrantRequestSummaryDocument>()
    const [grantFormModal, showHideGrantFormModal] = useModal()
    const [filterMode, setFilterMode] = useState<string>(Mode.active)
    
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const minDateValue = dayjs('01/01/1950')

    const statusGridAction = {
        id: 'status',
        label: '',
        dropdown: {
            options: [
                { label: 'Active', value: Mode.active },
                { label: 'All', value: Mode.all },
                // { label: 'Tabled', value: Mode.tabled },
                // { label: 'Complete', value: Mode.complete },
                // { label: 'Denied', value: Mode.denied },
            ],
            onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => isSelectFieldOption(option) && setFilterMode(option.value),
            value: filterMode,
        }
    }

    const previousMode = useRef(filterMode)
    useEffect(() => {
        if (previousMode.current !== filterMode) {
            gridActions.updateGridAction({ ...statusGridAction, dropdown: { ...statusGridAction.dropdown, value: filterMode } })

            if (filterMode === Mode.active) {
                const _filters = [...gridState.queryState.filters || []]
                const _showActiveFilter = _filters.find(o => o.id === showActiveFilter.id)
                
                if (_showActiveFilter) {
                    const filterIdx = _filters.findIndex(o => o.id = _showActiveFilter.id)
                    _showActiveFilter.enabled = true
                    _filters.splice(filterIdx, 1, showActiveFilter)
                    gridActions.updateColumnFilters(_filters)
                }
                gridActions.setSort(defaultSort)
            }

            if (filterMode === Mode.all) {
                const _filters = [...gridState.queryState.filters || []]
                const _showActiveFilter = _filters.find(o => o.id === showActiveFilter.id)
                
                if (_showActiveFilter) {
                    const filterIdx = _filters.findIndex(o => o.id = _showActiveFilter.id)
                    _showActiveFilter.value = []
                    _showActiveFilter.enabled = false
                    _filters.splice(filterIdx, 1, _showActiveFilter)
                    console.log('_filters', _filters)
                    gridActions.updateColumnFilters(_filters)
                }
                gridActions.setSort(allSort)
            }

            previousMode.current = filterMode
        }
    }, [filterMode])

    // const fetchRecords = async () => {
    //     const callListsQuery = await makeHttpRequestWithUi({
    //         request: GrantGiftApi.apiGrantGiftGet(Boolean(gridState.queryState.filters?.find(o => o.id === showArchivedFilter.id)?.value)),
    //         disableSuccessToast: true,
    //         toastErrorMessage: 'Failed to fetch call lists.'
    //     })

    //     setCallLists(callListsQuery.data)
    // }

    const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        try {

            const query = await makeHttpRequestWithUi({
                request: GrantRequestApi.apiGrantRequestGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of grant requests.'
            })

            const rows = (query.data.data || []).map<IGridListItem>(item => ({
                id: item.grantId.toString(),
                values: {
                    ...item
                }
            }))

            return {
                rows,
                count: query.data.totalCount
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    const fetchGrantRequest = useCallback(async (grantId: string) => {
        const query = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestIdGet(parseInt(grantId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setGrantRequestToEdit(query.data)
    }, [])

    const showActiveFilter: IFilter = {
        id: 'showActiveFilter',
        value: [
            GRANT_REQUEST_STATUS["Request In Progress"].toString(),
            GRANT_REQUEST_STATUS["Request Submitted"].toString(),
            GRANT_REQUEST_STATUS["AD Approved"].toString(),
            GRANT_REQUEST_STATUS["Ready For Review"].toString(),
            GRANT_REQUEST_STATUS["Submitted to Board"].toString(),
            GRANT_REQUEST_STATUS["Board Approved"].toString(),
            GRANT_REQUEST_STATUS["In Matching Period"].toString(),
            GRANT_REQUEST_STATUS["Queued For Funding"].toString(),
            GRANT_REQUEST_STATUS["Ready For Funding"].toString()
        ],
        operator: 'in',
        property: 'grantRequestStatusId',
        enabled: true,
        hidden: true
    }

    const defaultSort: ISort = {
        property: 'grantRequestStatusId',
        direction: 'ASC'
    }

    const allSort: ISort = {
        property: 'dateMinistrySubmitted',
        direction: 'DESC'
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        queryState: {
            ...defaultGridState.queryState,
            filters: [showActiveFilter],
            sorts: [defaultSort]
        },
        userSessionStateKey: GridUserInteractionStateKey.GrantRequests,
        gridActions: [
            statusGridAction,
            // {
            //     id: 'submitToLab',
            //     label: 'Submit To Lab',
            //     onClick: () => showHideSubmitToLabModal(true),
            //     //sortOrder: 0,
            // }
        ],
        rowActions: {
            editGrantRequest: {
                id: 'editGrantRequest',
                tooltipText: 'View Grant Request',
                action: async (options) => options.e.stopPropagation(),
				icon: <PencilIcon />,
                url: (row) => `/grant-request/${row.id}`,
            },
            impersonate: {
                id: 'impersonate',
                action: async (options) => {
                    const { e, row } = options

                    e.stopPropagation()
                    if (row.values.impersonationUrl)
                        openUrlInNewTab(row.values.impersonationUrl.toString())
                },
                icon: <IncognitoIcon />,
                tooltipText: 'Impersonate User',
                disabled: (row) => !row.values.subjectId
            },
        },
        columns: [
            {
                property: 'grantRequestStatusId',
                type: 'string',
                render: (_, row) => `${row.values.grantRequestStatusOrder} - ${row.values.grantRequestStatus}`,
                title: 'Status',
                width: 165,
                allowFilters: true,
                filterOptions: [
                    { label: 'Request In Progress', value: '1' },
                    { label: 'Request Submitted ', value: '2' },
                    { label: 'AD Approved ', value: '3 ' },
                    { label: 'Ready For Review ', value: '4' },
                    { label: 'Submitted to Board', value: '5' },
                    { label: 'Board Approved', value: '12' },
                    { label: 'In Matching Period', value: '6' },
                    { label: 'Queued For Funding', value: '7' },
                    { label: 'Ready For Funding', value: '8' },
                    { label: 'Grant Denied', value: '9' },
                    { label: 'Request Tabled', value: '10' },
                    { label: 'Grant Complete', value: '11' },
                ],
            },
            {
                property: 'grantType',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Type',
                render: DefaultGridCellDisplay
            },
            {
                property: 'ministryName',
                type: 'string',
                width: 270,
                allowFilters: true,
                title: 'Ministry Name',
                render: MinistryNameDisplay,
                description: 'The ministry name.'
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },
            {
                property: 'dateMinistrySubmitted',
                type: 'date',
                width: 100,
                allowFilters: true,
                title: 'Submitted',
                render:  (_, row) => {
                    if (row.values.dateMinistrySubmitted) {
                        const myDate = dayjs(row.values.dateMinistrySubmitted?.toString())
                        return myDate > minDateValue ? myDate.format('MM/DD/YYYY') : "N/A"
                    }
                    return '';
                },
            },
            {
                property: 'profileStatus',
                type: 'string',
                width: 130,
                allowFilters: true,
                title: 'Account Status',
                render: AccountStatusDisplay,
                hide: true,
                description: 'Displays OK when the account is complete.<br>GRC is displayed when the ministry is within a 60 day grace period after a tax year roll.<br>SUB is displayed when the account has been submitted but not yet approved.<br>INC is displayed when the ministry has an incomplete account.<br>PRE is displayed when the ministry is within the 60 day window after being moved to the granting level.',
                filterOptions: [
                    { label: 'OK ', value: 'OK' },
                    { label: 'GRC ', value: 'GRC' },
                    { label: 'PRE ', value: 'PRE ' },
                    { label: 'SUB ', value: 'SUB' },
                    { label: 'INC ', value: 'INC' },
                ],
            },
            {
                property: 'grantEligible',
                type: 'string',
                width: 120,
                allowFilters: true,
                title: 'Grant Eligible',
                render: GrantEligibleDisplay,
                hide: true,
                description: 'Whether this ministry meets the basic eligibility requirements to be considered for a grant. To be eligible a ministry must not be archived, must have an OK status, must have income under $2M, and must be present in the IRS Pub78 Database (or be grouped with an organization that exists in the Pub78 Database).',
                filterOptions: [
                    { label: 'Yes ', value: 'Yes ' },
                    { label: 'No ', value: 'No ' },
                ],
            },
            {
                property: 'branchAbbr',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Community',
                render: DefaultGridCellDisplay
            },
            
            {
                property: 'grantReportingYear',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Reporting Year',
                hide: true,
                render: DefaultGridCellDisplay
            },
            {
                property: 'dateBoardApproved',
                type: 'date',
                width: 100,
                allowFilters: true,
                title: 'Board Approved',
                render: DefaultGridCellDisplay
            },
            {
                property: 'dateGrantPeriodEnding',
                type: 'date',
                width: 100,
                allowFilters: true,
                title: 'Match Period Ending',
                render: DefaultGridCellDisplay
            },
            {
                property: 'amountRequested',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Requested',
                render: CurrencyDisplay,
                hide:true
            },
            {
                property: 'amountApproved',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Approved',
                render: CurrencyDisplay
            },
            {
                property: 'amountRaised',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Raised',
                render: CurrencyDisplay
            },
            {
                property: 'grantPaymentsTotal',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Payments',
                render: CurrencyDisplay
            },
            {
                property: 'grantAgreementFileUrl',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'GA',
                hide: true,
                render: (_, row) => row.values.grantAgreementFileUrl ? 'Yes' : 'No'
            },
        ],
        dataSource,
        rowSelectEnabled: true,
        respectGlobalCommunityFilter: true,
        disableExport: false,
        infinitePaging: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            await fetchGrantRequest(row.id)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, [])

    return (
        <>
            <Helmet>
                <title>Grant Requests</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <FileMedicalIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Grant Requests</h3>
                </div>
                <p className='ml-2'>
                    <i>Grant requests</i> are submitted by a ministry to apply for grant funds. Grants are invitation only.
                    In the case of the <i>Managed</i> grant type, the AD may submit a grant request on the ministry's behalf.
                </p>
                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...grantFormModal}
                modalTitle={`${grantRequestToEdit?.ministryName} - ${grantRequestToEdit?.isLegacyGrant ? 'LEGACY' : ''} Grant Request (${grantRequestToEdit?.grantId})   `}
                size='fullscreen'
                minHeightPercent={100}
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setGrantRequestToEdit(undefined)
                    gridActions.doFetch()
                }}

                dismissible={false}
            >
                {grantFormModal.show && grantRequestToEdit &&
                    <GrantRequestForm
                        grantRequestToEdit={grantRequestToEdit} 
                        afterSave={async () => {
                            selectedRow && fetchGrantRequest(selectedRow?.id)
                        }} 
                    />
                }
            </Modal>
        </>
    )
}