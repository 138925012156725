import { IMiBranchSummaryDocument } from "../open-api"
import { IGridListItem } from "../stores/grid-definitions"

export interface ICommunityDocument {
	branchId: number
	branchGroupId: number
	branchTypeId: number
	regionId: number
	branchName: string
	branchAbbr: string
	branchContact: number
	publicBranchName: string
	address1: string
	address2: string
	city: string
	stateId: number
	countryId: number
	postalCode: string
	phone: string
	fax: string
	email: string
	latitude: number
	longitude: number
	bHostBranch: boolean
	mainCity: string | null
	bMetro: boolean
	timeZoneOffsetFromPdx: number | null
	bActive: boolean
	conferenceNumber: string
	participantPin: string
	presenterPin: string
	dOpen: string
	bHidden: boolean
	bUseAlternateAddress: boolean
	altAddress1: string
	altAddress2: string
	altCity: string
	altStateId: number
	altPostalCode: string
	bProcessOwnGrants: boolean
	bAffiliate: boolean
	bUseUserAddress: boolean
	subRegionId: number
	bNoGranting: boolean
	bClosed: boolean | null
	bShowImpactReport: boolean
	bAllowGridExport: boolean
	bNewsletterConsultingBlock: boolean
	gracePeriodOverride: string | null
	branchEventInviteTypeId: number | null
	iDonateId: string
	iDonateKey: string
	bConsultingSeesAll: boolean
	isCauseSpecific: boolean | null
	timezone: string | null
}

export interface IBranchType {
	bActive: boolean
	id: number
	name: string
	sortOrder: number
}

export interface IBranchRegion {
	bActive: boolean
	id: number
	name: string
	regionAbbr: string
}

export enum BranchTypeId {
	Public = 1,
	Affiliate = 2,
	Priavte = 4.
}

export class CommunitiesByTrainingAreaModel {
	public static toGridListItem = (community: IMiBranchSummaryDocument): IGridListItem => {
		return {
			id: community.trainingAreaId?.toString() || '',
			values: {
				...community,
				areaDirectorFullName: `${community.areaDirectorFirstName || ''} ${community.areaDirectorLastName || ''}`
			},
			hidden: !community.trainingAreaId,
			rowGroup: {
				id: community.branchId.toString(),
				title: community.branchName || '',
				showEmptyRowGroup: true,
				emptyRowGroupLabel: 'This Community does not have any Training Areas. Edit the Community to add one.'
			}
		}
	}
} 