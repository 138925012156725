import React, { useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../../app-store-provider"
import { FormikSelectField, IFormikSelectFieldProps } from './formik-select-field'
import { Loading, LoadingPropsSizeEnum } from "../loading"
import { IZStatesDocument } from "../../open-api"

interface IFormikStateSelectFieldProps extends IFormikSelectFieldProps {
    countryId:number
 }
export const FormikStateSelectField = (props: IFormikStateSelectFieldProps) => {
    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!

    const [states, setStates] = useState<IZStatesDocument[]>()

    useEffect(() => {
        console.log('hmmm')
		if (!appState.states) {
            console.log('fetching states')
            appActions.fetchStates()
        }
		// eslint-disable-next-line
	}, [])

    useEffect(() => {
        console.log('FormikStateSelectField > countryId', props.countryId)
        console.log('FormikStateSelectField > states', states)
        // Filter the state dropdown based on the selected countryId
        if (props.countryId) {
            console.log('here', appState.states)
            console.log('here2 > countryId', props.countryId)
            //console.log('here3 > filteredStates', filteredStates)
            setStates(appState.states?.filter(o => o.countryId == props.countryId))
        } else {
            console.log('there')
            setStates(appState.states)
        }
    }, [props.countryId, appState.states])

    useEffect(() => 
    {
        console.log('FormikStateSelectField > statesChanged', states)
    }, [states])

    useEffect(() => 
    {
        console.log('FormikStateSelectField > appState.states Changed', appState.states)
    }, [appState.states])

    if (!states) return <Loading size={LoadingPropsSizeEnum.small} />

    return <FormikSelectField
        {...props}
        options={states?.map(o => ({ label: `${o.state}`, value: `${o.stateId}` }))}
    />
}