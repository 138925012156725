export interface IModelDocumentBase {

}

export interface IModelBase extends IModelDocumentBase {
}

export class ModelBase implements IModelBase {
	constructor(doc?: IModelDocumentBase) {
		if (!doc) {
			throw new Error('Attempted to create a model with a non-existent document')
		}
	}
}