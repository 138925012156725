import { useCallback, useContext, useEffect, useState } from "react"
import { IDefaultProps } from "./component-definitions"
import { AppActionContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IGroupCoachingDetailState } from "./group-coaching-detail"
import { LoadingOverlay } from "./loading"
import Helmet from "react-helmet"
import { CalendarEventIcon } from "../assets"
import { GroupCoachingFormSummary } from "./group-coaching-form-summary"
import { IEventSiteModelDocument, IGroupCoachingModelDocument } from "../open-api"
import { uuidv4 } from "../services/helpers"
import { navigate } from "@reach/router"

interface IGroupCoachingDuplicateProps extends IDefaultProps {
    eventContentId?: string
}

export const GroupCoachingDuplicate = ({eventContentId } : IGroupCoachingDuplicateProps) => {
    const { PresentersApi, GroupCoachingApi, GroupCoachingTopicApi, addAlert } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IGroupCoachingDetailState>({
        presenters: [],
        topicTypes: [],
        topics: [],
    })
    const [eventToDuplicate, setEventToDuplicate] = useState<IGroupCoachingModelDocument>()
    const [sites, setSites] = useState<IEventSiteModelDocument[]>([])

    const getEventContent = useCallback(async (eventContentId: string) => {
        const {data: eventToEdit} = await makeHttpRequestWithUi({
            request: GroupCoachingApi.apiGroupCoachingEventContentIdGet(parseInt(eventContentId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setEventToDuplicate(eventToEdit)
    }, [])

    useEffect(() => {
        GroupCoachingTopicApi.apiGroupCoachingTopicGet()
            .then(results => {
                setState(_state => ({ ..._state, topics: results.data }))
            })

        // makeHttpRequestWithUi({
        //     request: GroupCoachingTopicApi.groupCoachingTopicTypesGet(),
        //     disableSuccessToast: true,
        //     toastErrorMessage: 'Encountered an error retrieving group learning topic types.'
        // })
        //     .then(results => {
        //         setState(_state => ({ ..._state, topicTypes: results.data as { id: number, name: string }[] }))
        //     })

        makeHttpRequestWithUi({
            request: PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of presenters.'
        })
            .then(results => {
                setState(_state => ({ ..._state, presenters: results.data }))
            })
    }, [])

    useEffect(() => {
        if (eventContentId) getEventContent(eventContentId)
    }, [])

    if (!eventToDuplicate) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>Duplicate Event Content</title>
            </Helmet>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/event-management">Event Management</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Duplicate Event Content</li>
                </ol>
            </nav>
            <div className='m-2 d-flex align-items-center'>
                <CalendarEventIcon style={{ width: '25px', height: '25px', }} />
                <h3 className='ml-2'>
                    Duplicate Event Content
                </h3>
            </div>
            <div className='m-2 d-flex align-items-center'>
                <div className='alert alert-warning'>
                    You are <b>duplicating</b> an existing record. Be sure to review each field carefully and make changes accordingly, before saving.
                    This is now your event and completely customizable.
                </div>
            </div>
            {state.presenters && state.topics && eventToDuplicate && eventToDuplicate.groupCoachingTopicId &&
                <div className='m-2' style={{ display: 'flex', flex: 1, minHeight: 0, overflow: 'hidden' }}>
                    <GroupCoachingFormSummary
                        eventToEdit={eventToDuplicate}
                        setEventToEdit={setEventToDuplicate}
                        presenters={state.presenters}
                        duplicateEventContent={true}
                        sites={sites}
                        setSites={setSites}
                        selectedTopic={state.topics.find(o => o.id.toString() === eventToDuplicate.groupCoachingTopicId.toString())!}
                        afterSave={async () => {
                            addAlert({
                                id: uuidv4(),
                                title: 'Success',
                                body: 'Duplicate event(s) created.',
                            })
                            navigate(`/group-learning/${eventToDuplicate.eventContentId}/${eventToDuplicate.eventId}`)
                        }}
                    />
                </div>
            }
        </>
    )
}