import { truncate } from 'lodash'
import { IGrantRequestSummaryReportProps } from './grant-request-summary-report'

export const GrantRequestSummaryReportPage4 = (props: IGrantRequestSummaryReportProps) => {
    const { grantRequestSummary } = props

    return (
        <>
            <table style={{width: '910px', border: 0}} cellPadding="0" cellSpacing="0">
                <tr>
                    <td align="left" className="hRule" width="500">
                        <h2 style={{fontSize: '16px'}}>{truncate(grantRequestSummary.ministryName || '', {length:70})}</h2>
                    </td>
                    <td align="right" className="hRule" width="200">
                        <h2 style={{fontSize: '16px'}}>{grantRequestSummary.branchName}</h2>
                    </td>
                </tr>
            </table>
            <br /><br />
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td className="hRule"><strong>Notes / Stipulations</strong></td>
                </tr>
            </table>
    
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td>
                        <div style={{width: '100%'}}>
                            <div style={{verticalAlign: 'bottom', textAlign: 'left'}}><strong><input type="checkbox" /> Approved&nbsp;&nbsp;&nbsp;&nbsp;<span className="fieldLabel" style={{paddingTop: '3px'}}>Amount:</span> $<span style={{borderBottom: '1px solid black', width: '100px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" /> Denied&nbsp;&nbsp;</strong></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="boxed" style={{height: '300px', verticalAlign: 'top'}}>
                        <table style={{width: '100%'}} cellSpacing={0} cellPadding={0}>
                            <tr>
                                <td style={{top: '-3px'}}><input type="checkbox" /> Poor Fund Use Plan</td>
                                <td><input type="checkbox" /> Poor Matching Plan</td>
                                <td><input type="checkbox" /> Limited grant funds at this time</td>
                            </tr>
                            <tr>
                                <td><input type="checkbox" /> Weak MI Involvement</td>
                                <td><input type="checkbox" /> Weak TG implementation</td>
                                <td><input type="checkbox" /> Grant should target development</td>
                            </tr>
                            <tr>
                                <td><input type="checkbox" /> Grant not warranted</td>
                                <td><input type="checkbox" /> More training needed</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><input type="checkbox" /> Concerns about ministry leadership, programs, or relevance</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><input type="checkbox" /> Ministry will likely never get a grant based on funder's granting criteria</td>
                            </tr>
                            <tr>
                                <td><input type="checkbox" /> Other: </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table> 
        </>
    )
}