import React, { useContext, useEffect, useState } from 'react'
import { truncate } from 'lodash'
import { formatCurrency } from '../services/helpers'
import dayjs from 'dayjs'
import { IGrantRequestSummaryReportProps } from './grant-request-summary-report'

export const GrantRequestSummaryReportPage2 = (props: IGrantRequestSummaryReportProps) => {
    const { grantRequestSummary } = props

    const getHistoryOthersGrantAmountTotal = () => {
        if (grantRequestSummary.grantHistoryOthers && grantRequestSummary.grantHistoryOthers.length > 0) {
            const total = grantRequestSummary.grantHistoryOthers.reduce((total, currentVal) => total + currentVal.grantAmount, 0)
            return total;
        }

        return 0;
    }

    const getHistoryOthersAmountRaisedTotal = () => {
        if (grantRequestSummary.grantHistoryOthers && grantRequestSummary.grantHistoryOthers.length > 0) {
            const total = grantRequestSummary.grantHistoryOthers.reduce((total, currentVal) => total + currentVal.amountRaised, 0)
            return total;
        }

        return 0;
    }

    return (
        <>
            <table style={{border: 0, width: 910}} cellPadding={0} cellSpacing={0}>
                <tr>
                    <td align="center" className="hRule" width="100%">
                        <h2 style={{fontSize: '16px'}}>Mission Increase Foundation - Grant Request Summary</h2>
                    </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>
                        <div style={{width: '100%'}}>
                            <div style={{float: 'left'}} className="headerDiv"><strong>{truncate(grantRequestSummary.ministryName || '', {length: 70})}</strong></div>
                            <div style={{float: 'right'}} className="headerDiv"><strong>{grantRequestSummary.branchName}</strong></div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td className="hRule"><strong>Mission/Evangelism Statement</strong></td>
                </tr>
                <tr>
                    <td className="boxed">
                        <table style={{border: 0, width: '100%'}}>
                            <tr>
                                <td className="fieldLabel">Mission Statement:</td>
                            </tr>
                            <tr>
                                <td>{truncate(grantRequestSummary.organizationMission || '', {length: 1500})}</td>
                            </tr>
                            <tr>
                                <td className="fieldLabel">Evangelism Incorporation:</td>
                            </tr>
                            <tr>
                                <td>{truncate(grantRequestSummary.evangelismIncorporation || '', {length: 1500})}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <br />
            {grantRequestSummary.notes && 
                <>
                    <table style={{border: 0, width: '910px'}}>
                        <tr>
                            <td className="hRule"><strong>Area Director Grant Notes</strong></td>
                        </tr>
                        <tr>
                            <td className="boxed">
                                <table style={{border: 0, width: '100%'}}>
                                    <tr>
                                        <td>{truncate(grantRequestSummary.notes, {length: 2000}).replace(/(?:\r\n|\r|\n)/g, "<br>")}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <br />
                </>
            }
            {grantRequestSummary.grantHistoryApprovals && grantRequestSummary.grantHistoryApprovals.length > 0 ?
                <>
                    <table style={{width: '910px', border: 0}}>
                        <tr>
                            <td className="hRule"><strong>Grant History</strong></td>
                        </tr>
                        <tr>
                            <td className="boxed">
                                <table style={{width: '100%', border: 0}}>
                                    <tr>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center'}}><strong>Type</strong></td>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center'}}><strong>Status</strong></td>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center'}}><strong>Amt. Granted</strong></td>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center'}}><strong>Amt. Matched</strong></td>
                                        <td style={{borderBottom: '1px solid black', textAlign: 'center'}}><strong>Date Requested</strong></td>
                                    </tr>
                                    {grantRequestSummary.grantHistoryApprovals.map((x, index) => 
                                        <tr key={index} >
                                            <td>{x.grantTypeName}</td>
                                            <td>{x.grantStatusName}</td>
                                            <td align="right">{formatCurrency(x.grantAmount)}</td>
                                            <td align="right">{formatCurrency(x.amountRaised)}</td>
                                            <td align="right">{x.grantReqDate && dayjs(x.grantReqDate).format('M/DD/YY')}</td>
                                        </tr>)

                                    }
                                    {grantRequestSummary.grantHistoryOthers && grantRequestSummary.grantHistoryOthers.length > 0 &&
                                        <tr>
                                            <td>Other Grants: </td>
                                            <td>&nbsp;</td>
                                            <td align="right">{formatCurrency(getHistoryOthersGrantAmountTotal())}</td>
                                            <td align="right">{formatCurrency(getHistoryOthersAmountRaisedTotal())}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    }
                                </table>
                            </td>
                        </tr>
                    </table>
                    <br />
                </>
                :
                <>
                    <table style={{width: '910px', border: 0}}>
                        <tr>
                            <td className="hRule"><strong>Grant History</strong></td>
                        </tr>
                        <tr>
                            <td className="boxed">
                                <table style={{width: '100%', border: 0}}>
                                    <tr>
                                        <td colSpan={5} align='center'>No Previous Grant  History</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <br />
                </>
            }
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td className="hRule"><strong>Income By Sector</strong></td>
                </tr>
                <tr>
                    <td className="boxed">
                        <table style={{width: '100%', border: 0}}>
                            <tr>
                                <td align="center">
                                    <table className="tblthin" style={{marginLeft: '0px', width: '100%', border: 0}}>
                                        <tr>
                                            <td className="tdthin">&nbsp;</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <th key={index} style={{borderBottom: '1px solid black', verticalAlign: 'bottom'}} colSpan={1} align="center">{x.fiscalYear}
                                                    {x.gotGrant > 0 && <img src="/images/icons/money.png" />}
                                                </th>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Special Events</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtSpecialEvent)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Major Donors</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtMajorDonor)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Communications</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtDirectMail)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Online Giving</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtOnlineGiving)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Grant Writing</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtGrantWrite)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Church Giving</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtChurchSponsor)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Fee for Service</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtFeeService)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Other</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtOther)}</td>)}
                                        </tr>
                                        <tr>
                                            <td className="tdthin" align="left" >Gifts in Kind</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right">{formatCurrency(x.amtGIK)}</td>)}
                                        </tr>
                                        <tr className="deltaHighlight">
                                            <td className="tdthin" align="left">Variance</td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right" style={x.variance == 0 ? {paddingTop: '3px'} : {paddingTop: '3px', color: '#981903'}}>{formatCurrency(x.variance)}</td>)}
                                        </tr>
                                        <tr style={{fontWeight: 'bold'}}>
                                            <td className="tdthin" align="left" style={{paddingTop: "3px"}}><strong>Total Income</strong></td>
                                            {grantRequestSummary.incomeData?.map((x, index) =>
                                                <td key={index}  className="tdthin" align="right" style={{paddingTop: '3px', borderTop: '1px solid'}}>{formatCurrency(x.income)}</td>)}
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </>
    )
}