import React, { useContext, useState, useEffect } from "react"
import { MinistryInfoContext } from './ministry-info'
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { IMinistryInfoState } from "../stores/ministry-info-definitions"
import { filterGridListItems, uuidv4, showModal, hideModal, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { NoteModel } from "../models/ministry-note"
import { gridReducer } from "../stores/grid-reducer"
import { Loading } from "./loading"
import { Grid } from "./grid"
import { Modal } from "./modal"
import { UserModel, App, UserPermissionLevel } from "../models/user"
import { AppStateContext, AppActionContext } from "../app-store-provider"
import { INotesSummaryDocument, INotesDocument, IMinistryContactSummaryModelDocument } from "../open-api"
import { NoteForm } from './note-form'
import { registerLoadingTask, deregisterLoadingTask } from "../services/loading-service"
import { QuickNoteForm } from "./quick-note-form"
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { SquareDeleteIcon } from "./partials"
import { useHTTPRequestUiWrapper, useNoteDefaultColumns } from "../services/hooks"
import { NOTE_TYPES } from "../constants"

export const MinistryInfoNotes = () => {
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [noteModalId] = useState(uuidv4())
	const [deleteNoteModalId] = useState(uuidv4())
	const [selectedNote, setSelectedNote] = useState<INotesDocument>()
	const [showNoteForm, setShowNoteForm] = useState(false)

	const fetchMinistryNotes: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryNotes } = _state

		if (!ministryNotes) return { count: 0, rows: [] }

		let cleanedMinistryNotes: INotesSummaryDocument[] = [...ministryNotes]

		// Make sure the user has permissions to see admin note types. If not, filter admin notes out.
		if (!UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Administrator, appState.currentUser)) {
			const adminNoteTypeId = NOTE_TYPES.Admin
			cleanedMinistryNotes = cleanedMinistryNotes.filter(item => item.noteTypeId !== adminNoteTypeId)
		}

		if (queryState.sorts) sortListBySorts(cleanedMinistryNotes, queryState.sorts)

		let rows = cleanedMinistryNotes.map(note => NoteModel.toGridListItem(note))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length }
	}

	useEffect(() => {
		if (selectedNote) showModal(noteModalId)
		// eslint-disable-next-line
	}, [selectedNote])

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: useNoteDefaultColumns(),
		disabledPagination: true,
		dataSource: fetchMinistryNotes,
		gridActions: [
			{
				id: 'ministryNoteActions',
				label: 'Create Note',
				disabled: !UserModel.checkPermissionLevelForUser(App.NoteManager, UserPermissionLevel.Modify, appState.currentUser),
				onClick: () => showModal(noteModalId)
			}
		],
		rowActions: {
			editNote: {
				id: 'editNote',
				action: async (options) => {
					options.e.stopPropagation()

					const taskId = registerLoadingTask()
					const noteQuery = await appActions.NotesApi.apiNotesIdGet(parseInt(options.row.id))
					deregisterLoadingTask(taskId)
					setSelectedNote(noteQuery.data)

				},
				icon: <PencilIcon />,
				tooltipText: 'Edit note',
			},
			deleteNote: {
				id: 'deleteNote',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(deleteNoteModalId)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete Note',
			},
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	useEffect(() => {
		ministryInfoActions.fetchNotes()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryNotes])

	const [ministryContacts, setMinistryContacts] = useState<IMinistryContactSummaryModelDocument[]>()
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()
	useEffect(() => {
		if (ministryInfoState.ministry && !ministryContacts && showNoteForm) {
			makeHTTPRequestWithUi({ request: appActions.MinistryContactsApi.apiMinistriesIdContactsGet(ministryInfoState.ministry.ministryId), disableSuccessToast: true })
				.then(results => {
					setMinistryContacts(results.data)
				})
		}

		// eslint-disable-next-line
	}, [ministryInfoState.ministry, showNoteForm])

	if (!ministryInfoState.ministryNotes || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<div>
				<h4 className="mb-2 ml-2 pt-2">Quick Note</h4>
				<QuickNoteForm
					ministryId={ministryInfoState.ministry.ministryId}
					noteText=''
					afterSave={async () => { await ministryInfoActions.fetchNotes() }} />
			</div>

			<h4 className="mb-2 ml-2">Notes</h4>

			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			<Modal
				modalTitle='Note'
				modalId={noteModalId}
				onModalHidden={() => {
					setSelectedRow(undefined)
					setSelectedNote(undefined)
				}}
				onModalShow={() => {
					setShowNoteForm(true)
				}}
				onModalHide={() => {
					setShowNoteForm(false)
				}}
			>
				{showNoteForm && !!ministryContacts ?
					<NoteForm
						ministryId={ministryInfoState.ministry.ministryId}
						note={selectedNote}
						afterSave={async () => {
							hideModal(noteModalId)
							const taskId = registerLoadingTask()
							await ministryInfoActions.fetchNotes()
							deregisterLoadingTask(taskId)
						}}
						ministryContacts={ministryContacts}
					/>
					:
					null
				}

			</Modal>

			<Modal
				modalId={deleteNoteModalId}
				modalTitle='Confirm'
				onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<React.Fragment>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-danger" onClick={async () => {
							if (selectedRow) {
								const taskId = registerLoadingTask()
								await appActions.NotesApi.apiNotesIdDelete(parseInt(selectedRow.id))
								await ministryInfoActions.fetchNotes()
								deregisterLoadingTask(taskId)
								hideModal(deleteNoteModalId)
							} else {
								alert('There was an error deleting this note. No note ID specified.')
							}
						}}>Delete</button>
					</React.Fragment>
				}
			>
				Are you sure you want to delete this note?

				This cannot be reversed!
			</Modal>
		</React.Fragment>
	)
}