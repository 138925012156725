import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IDefaultProps } from './component-definitions'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { IMiTrainingAreaSummaryModelDocument } from '../open-api'
import { TrainingAreaForm } from './training-area-form'
import { Helmet } from 'react-helmet'
import { PeopleIcon } from '../assets'
import { LoadingOverlay } from './loading'

interface ICommunitiesProps extends IDefaultProps {
    trainingAreaId?: string
}
export const TrainingAreaDetail = ({ trainingAreaId }: ICommunitiesProps) => {

    const appActions = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    useEffect(() => {
        // Go ahead and refresh the list of community summaries any time this component is loaded for the first time.
        appActions.fetchCommunitySummaries()

        // Go ahead and refresh the list of users any time this component is loaded for the first time.
        appActions.fetchMiUsers(true)

        // eslint-disable-next-line
    }, [])


    const [trainingAreaToEdit, setTrainingAreaToEdit] = useState<IMiTrainingAreaSummaryModelDocument>()

    const editTrainingArea = async (trainingAreaId: string) => {
        const trainingAreaToEditQuery = await makeHttpRequestWithUi({
            request: appActions.MiTrainingAreasApi.apiMiTrainingAreasIdGet(parseInt(trainingAreaId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the training area to edit.'
        })

        setTrainingAreaToEdit(trainingAreaToEditQuery.data)
    }

    useEffect(() => {
        if (trainingAreaId) editTrainingArea(trainingAreaId)
    }, [])

    if (!trainingAreaToEdit) return <LoadingOverlay />


    return (
        <React.Fragment>
            <Helmet>
                <title>Training Area Detail</title>
            </Helmet>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <PeopleIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Edit Training Area: {trainingAreaToEdit.trainingArea}</h3>
                </div>
                <TrainingAreaForm
                    branchId={trainingAreaToEdit.branchId}
                    trainingAreaToEdit={trainingAreaToEdit}
                    onSaveSucces={() => {
                        appActions.fetchCommunitySummaries()
                    }}
                />
            </div>
        </React.Fragment>
    )
}