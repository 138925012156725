import { IDashboardAlertDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class DashboardAlertModel {
    public static toGridListItem = (alert: IDashboardAlertDocument): IGridListItem => {
        return {
            id: alert.id?.toString() || '',
            values: {
                ...alert,
            },
        }
    }
} 