import moment from 'moment'
import { useLocalStorage } from 'react-use'

const LAST_CLEAR = '01/20/2021'

export const useClearLocalStorage = () => {
    const [lastCleared, setLastCleared] = useLocalStorage<string>('last_cleared', undefined, { raw: true })

    const clearLocalStorage = () => {
        if (!lastCleared || (lastCleared && moment(lastCleared).isBefore(moment(LAST_CLEAR)))) {
            localStorage.clear()
            setLastCleared(moment().format('MM/DD/YYYY'))
        }
    }

    return clearLocalStorage
}