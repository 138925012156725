import { useEffect } from 'react'
import { FormikProps } from 'formik'
import usePrevious from 'react-use/lib/usePrevious'

/* 
    Simple component we can put inside a <Formik /> form to add side effects whenever the form's values change.
*/

interface IFormikEffectProps<T> {
    formikProps: FormikProps<T>
	dependencies?: (keyof T)[]
    onChange: (prevValues: T, nextValues: T) => void
}
export const FormikEffect = <T,>(props: IFormikEffectProps<T>) => {
    const { formikProps, onChange, dependencies } = props

    const { values } = formikProps

    const prevValues = usePrevious(values)

    useEffect(() => {
		if ( dependencies && prevValues ) {
			if ( dependencies.every(dep => prevValues[dep] === values[dep]) ) {
				return
			}
		}
        // Wait for at least one value of prevValues (don't run on form init)
        if (prevValues) onChange(prevValues, values)
        //eslint-disable-next-line
    }, [values])

    return null
}