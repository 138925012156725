import dayjs from "dayjs"
import { useContext, useState, useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext } from "../app-store-provider"
import { CalendarEventIcon } from "../assets"
import { IPresentersDocument, IGroupCoachingModelDocument, IGroupCoachingTopicResultDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IDefaultProps } from "./component-definitions"
import { LoadingOverlay } from "./loading"
import { IGroupCoachingEventStatus } from "./group-coaching"
import { GroupCoachingForm } from "./group-coaching-form"

export interface IGroupCoachingDetailState {
    presenters: IPresentersDocument[]
    topicTypes: { id: number, name: string }[]
    topics: IGroupCoachingTopicResultDocument[]
}

interface IGroupCoachingDetailProps extends IDefaultProps {
    eventContentId?: string
    eventId?: string
}

export const GroupCoachingDetail = ({ eventContentId, eventId }: IGroupCoachingDetailProps) => {
    const { PresentersApi, GroupCoachingApi, GroupCoachingTopicApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IGroupCoachingDetailState>({
        presenters: [],
        topicTypes: [],
        topics: [],
    })
    const [eventToEdit, setEventToEdit] = useState<IGroupCoachingModelDocument>()
    //const [initialTab, setInitialTab] = useState<'summary' | 'registrants' | undefined>('summary')

    const editEvent = useCallback(async (eventContentId: string, eventId: string) => {
        const {data: eventToEdit} = await makeHttpRequestWithUi({
            request: GroupCoachingApi.apiGroupCoachingEventContentIdEventIdGet(parseInt(eventContentId), parseInt(eventId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setEventToEdit(eventToEdit)
    }, [])

    useEffect(() => {
        GroupCoachingTopicApi.apiGroupCoachingTopicGet()
            .then(results => {
                setState(_state => ({ ..._state, topics: results.data }))
            })

        makeHttpRequestWithUi({
            request: GroupCoachingTopicApi.groupCoachingTopicTypesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving group learning topic types.'
        })
            .then(results => {
                setState(_state => ({ ..._state, topicTypes: results.data as { id: number, name: string }[] }))
            })

        makeHttpRequestWithUi({
            request: PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of presenters.'
        })
            .then(results => {
                setState(_state => ({ ..._state, presenters: results.data }))
            })
    }, [])

    useEffect(() => {
        if (eventId && eventContentId) editEvent(eventContentId, eventId)
    }, [])

    const getPageTitle = () => {
        if (!eventToEdit) return 'Error: No event was found.'
        //@ts-ignore
        return  `${eventToEdit.startDateTime ? `${dayjs(eventToEdit.startDateTime?.toString()).format('M/D/YYYY')} - ` : ``} ${eventToEdit.isSeries ? 'Series' : 'Group Learning'} - ${eventToEdit.groupCoachingTopic.groupCoachingTopicType.name || ''}  (${eventToEdit.eventId})`
    }

    const getHeader = () => {
        if (!eventToEdit) return ''
        return <>
            {eventToEdit.startDateTime && <span>{dayjs(eventToEdit.startDateTime?.toString()).format('M/D/YYYY')}</span>} - {eventToEdit.isSeries ? 'Series' : 'Group Learning'} -&nbsp; 
            {/*@ts-ignore*/}
            {eventToEdit.groupCoachingTopic.groupCoachingTopicType.name || ''}
            &nbsp;({eventToEdit.eventId})&nbsp;
            {eventToEdit.status === IGroupCoachingEventStatus.canceled ? <span className='badge badge-warning'>Canceled</span> : null}
            {eventToEdit.status === IGroupCoachingEventStatus.activated ? <span className='badge badge-success'>Activated</span> : null}
            {eventToEdit.status === IGroupCoachingEventStatus.draft ? <span className='badge badge-info'>Draft</span> : null}
        </>
    }

    if (!eventToEdit) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>{getPageTitle()}</title>
            </Helmet>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/event-management">Event Management</a></li>
                    <li className="breadcrumb-item"><a href={`/event-content/${eventToEdit.eventContentId}`}>Event Content</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Event Detail</li>
                </ol>
            </nav>
            <div className='m-2 d-flex align-items-center'>
                <CalendarEventIcon style={{ width: '25px', height: '25px', }} />
                <h3 className='ml-2'>
                    {getHeader()}
                </h3>
            </div>

            {state.presenters &&
                <div className='m-2' style={{ display: 'flex', flex: 1, minHeight: 0, overflow: 'hidden' }}>
                    <GroupCoachingForm
                        topicTypes={state.topicTypes}
                        topics={state.topics}
                        eventToEdit={eventToEdit}
                        presenters={state.presenters}
                        setEventToEdit={setEventToEdit}
                        setSelectedTopicType={selectedTopicType => setState(_state => ({ ..._state, selectedTopicType }))}
                    />
                </div>
            }
        </>
    )
}