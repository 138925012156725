import React, { useCallback, useContext, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Grid } from './grid'
import { GridDataFetch, IGridState, IGridListItem, GridUserInteractionStateKey } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { IDefaultProps } from './component-definitions'
import { uuidv4, showModal, hideModal } from '../services/helpers'
import { Modal } from './modal'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { Loading } from './loading'
import { IQueryState } from '../stores/api-actions'
import { IAppState } from '../stores/app-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { isNullOrUndefined } from 'util'
import { Helmet } from 'react-helmet'
import { EnvolpeIcon, Mortorboard, XSquareIcon } from '../assets'
import { UserModel, UserPermissionLevel, App } from '../models/user'
import { Checkbox } from './forms'
import { LmsCourseEnrollmentModel, useLmsCourseEnrollmentDefaultColumns } from '../models/lms-course-enrollment'
import { AddToCourseForm, AddToCourseFormTab, AddToCourseModalTitle, IAddToCourseForm, IFormValues } from './add-to-course-form'
import { LmsSendEmailModal } from './lms-send-email-modal'

interface IManualCourseAssignmentProps extends IDefaultProps { }
export const ManualCourseAssignment = (props: IManualCourseAssignmentProps) => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()
	
	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [deleteAssignmentModal] = useState(uuidv4())

	const fetchCourses: GridDataFetch<IAppState> = async (_queryState, _appState) => {
		const _filters = [..._queryState.filters || []]
		const inviteSentFilter = _filters?.find(f => f.property === 'isInviteSent')

		if ( _filters?.length ) {
			const inviteSentFilter = _filters?.find(f => f.property === 'isInviteSent')
			if (inviteSentFilter) {
				const index = _filters?.indexOf(inviteSentFilter)
				_filters?.splice(index as number, 1)
			}
		}

		// We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing JSON into URL query params
		const filters = (_filters ? JSON.stringify([..._filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
		const sorts = (_queryState.sorts ? JSON.stringify(_queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

		const coursesQuery = await makeHTTPRequestWithUi({
			request: appActions.LmsApi.apiLmsGet(
				(_queryState.page - 1) * _queryState.itemsPerPage,
				_queryState.itemsPerPage,
				sorts,
				filters
			),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving LMS Courses.'
		})

		const courses = coursesQuery.data.data?.map(LmsCourseEnrollmentModel.toGridListItem) || []

		if (inviteSentFilter?.value) {
			const values = inviteSentFilter.value as string[]
			if ( values.length ) {
				courses.splice(0, courses.length, ...courses.filter(c => c.values.isInviteSent && values.includes(c.values.isInviteSent.toString().toLowerCase())))
			}
		}

		return {
			rows: courses,
			count: coursesQuery.data.totalCount < _queryState.itemsPerPage ? courses.length : coursesQuery.data.totalCount - (_queryState.itemsPerPage - courses.length)
		}
	}

	let queryState: IQueryState | undefined = appState.currentUserSessionState?.gridUserInteractionStates?.[GridUserInteractionStateKey.LmsCoursesEnrollment]?.queryState

	if (!queryState) queryState = { ...defaultGridState.queryState }
	if (!queryState.filters) queryState.filters = []
	if (!queryState.sorts) queryState.sorts = []

	// if (!queryState.sorts.length) {
	// 	queryState.sorts.push({
	// 		direction: 'DESC',
	// 		property: 'createdDate',
	// 	})
	// }

	// Allow this page to be filtered to a specific event using the query string.
	const queryEventId = new URLSearchParams(window.location.search).get('eventId')
	if (queryEventId) {
		console.log('queryEventId', queryEventId)
		const eventFilterIdx = queryState.filters?.findIndex(o => o.id === 'eventId')

		if (eventFilterIdx !== -1) {
			queryState.filters[eventFilterIdx] = { ...queryState.filters[eventFilterIdx], value: parseInt(queryEventId), enabled: true }
		} else {
			queryState.filters.push({
				id: 'eventId-eq',
				enabled: true,
				value: parseInt(queryEventId),
				operator: 'eq',
				property: 'eventId',
			})
		}
	}

	const addEnrollmentGridAction = 
	{
		id: 'addEnrollment',
		label: 'Add Enrollment',
		onClick: () => {
			showModal(addToCourseModal)
			setIsAddToCourseModalShown(true)
		},
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		loading: true,
		respectGlobalCommunityFilter: true,
		queryState,
		columns: useLmsCourseEnrollmentDefaultColumns(),
		dataSource: fetchCourses,
		userSessionStateKey: GridUserInteractionStateKey.LmsCoursesEnrollment,
		overrideInitialStateValues: {
			queryState
		},
		gridActions: [
			addEnrollmentGridAction
		],
		rowActions: {
			sendInvite: {
				id: 'sendInvite',
				action: async (options) => {
					const { e, row, appActions } = options

					e.stopPropagation()

					setCourseEnrollmentIds([row.values.lmsCourseEnrollmentId as number])
					showHideSendLmsEmailModal(true)

					// if ( row.values.ministryContactId ) {
					// 	makeHTTPRequestWithUi({
					// 		request: appActions.MinistryContactsApi.apiMinistryContactsMinistryContactIdSendInvitePost(row.values.ministryContactId as number),
					// 		toastSuccessMessage: 'Successfully sent invite.',
					// 		toastErrorMessage: 'Encountered an error sending invite.'
					// 	})
					// } else {
					// 	appActions.addToast({
					// 		id: uuidv4(),
					// 		timestamp: new Date(),
					// 		title: 'Error',
					// 		body: 'No contact ID was found.',
					// 		icon: <ExclamationCirclIcon className='text-danger' />,
					// 	})
					// }
				},
				//hidden: (row) => !!row.values.inviteSentDate, 
				icon: <EnvolpeIcon />,
				tooltipText: 'Send Invite',
			},
			deleteEnrollment: {
				id: 'deleteEnrollment',
				action: async (options) => {
					const { e, row, appActions } = options

					e.stopPropagation()
					setSelectedRow(row)
					showModal(deleteAssignmentModal)
				},
				icon: <XSquareIcon />,
				tooltipText: 'Delete Enrollment',
				disabled: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser)
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	const [addToCourseModal] = useState(uuidv4())
	const [isAddToCourseModalShown, setIsAddToCourseModalShown] = useState(false)
    const [sendLmsEmailModal, showHideSendLmsEmailModal] = useModal()
	const [showAdvancedForm, setShowAdvancedForm] = useState(false)

	const [ addToCourseFormValues, setAddToCourseFormValues ] = useState<IFormValues>()
	const [ courseEnrollmentIds, setCourseEnrollmentIds ] = useState<number[]>([])
	const [ showAdvancedFormCheckbox, setShowAdvancedFormCheckbox ] = useState(true)
	const [ activeTab, setActiveTab ] = useState<AddToCourseFormTab>(AddToCourseFormTab.main)

	const handleAddToCourseSave: IAddToCourseForm['onSave'] = useCallback(async values => {
		gridActions.setLoading(true)
		const ids = await makeHTTPRequestWithUi({
			request: appActions.LmsApi.apiLmsAddCourseEnrollmentPost(
				{
					communityId: parseInt(values.branchId),
					courseId: parseInt(values.courseId),
					ministryContactIds: values.ministryContacts.map(c => c.contactId),
					emails: values.outsideContactEmails,
					groupCode: values.groupCode,
					groupId: values.groupId ?? 0,
					eventId: 0,
				}
			),
			disableLoading: true,
			disableSuccessToast: false,
			toastSuccessMessage: 'Successfully added contacts to course.',
			toastErrorMessage: 'There was an error retrieving Ministry Contacts.'
		})

		setAddToCourseFormValues(values)
		setCourseEnrollmentIds(ids.data)

		return []
    }, [])

	const handleAddToCourseAfterSave: IAddToCourseForm['afterSave'] = useCallback(() => {
		gridActions.doFetch()
		gridActions.setLoading(false)
		setIsAddToCourseModalShown(false)
		showHideSendLmsEmailModal(true)
    }, [])

	if (!gridState) {
		return <Loading />
	} else {
		return (
			<React.Fragment>
				<Helmet>
					<title>Direct Enrollment</title>
				</Helmet>

				<div className='d-flex flex-column vh-100'>
					<div className='m-2 d-flex align-items-center'>
						<Mortorboard style={{ width: '25px', height: '25px' }} />
						<h3 className='ml-2'>Direct Course Enrollment</h3>
					</div>
					<p className='ml-2'>This is a list of all contacts who have been added to a course by a GrowthTrack user. This is NOT a list of all contacts who have access to courses.</p>
					<Grid state={gridState} actions={gridActions} style={{ flex: 1, height: 'unset' }} />
				</div>

				<Modal
					modalId={deleteAssignmentModal}
					modalTitle='Confirm'
					footer={
						
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' className='btn btn-danger' onClick={async () => {
								if (!selectedRow) return
								const taskId = registerLoadingTask()
								await makeHTTPRequestWithUi({
									request: appActions.LmsApi.apiLmsDeleteCourseEnrollmentDelete([parseInt(selectedRow.id)]),
									disableLoading: false,
									disableSuccessToast: false,
									toastSuccessMessage: 'Successfully deleted enrollment.',
									toastErrorMessage: 'There was an error deleting the enrollment.'
								})
								gridActions.setLoading(true)
								gridActions.doFetch()
								hideModal(deleteAssignmentModal)
								deregisterLoadingTask(taskId)
							}}>Delete</button>
						</React.Fragment>
					}
				>
					Are you sure you want to delete this assignment?
				</Modal>

				<Modal
					size="lg"
					modalId={addToCourseModal}
					modalTitle={
						<div className='d-flex col justify-content-between align-items-center'>
							<h4>{ AddToCourseModalTitle[activeTab] }</h4>

							<Checkbox id='useAdvanced' label='Use Advanced Tools' style={{display: showAdvancedFormCheckbox ? 'block' : 'none'}} onChange={() => setShowAdvancedForm(value => !value)} />
						</div>
					}
					dismissible={false}
					closeModal={() => {
						if ( activeTab !== AddToCourseFormTab.main ) {
							setActiveTab(AddToCourseFormTab.main)
						}
						else {
							setIsAddToCourseModalShown(false)
						}
					}}
					show={isAddToCourseModalShown}
				>
					{isAddToCourseModalShown ? <AddToCourseForm 
						setShowAdvancedFormCheckbox={setShowAdvancedFormCheckbox}
						onSave={handleAddToCourseSave} 
						afterSave={handleAddToCourseAfterSave} 
						advanced={showAdvancedForm}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						initialContacts={[]}
						initialBranchId={appState.currentUser?.branchId?.toString() ?? ''}
				/> : null}
				</Modal>

				<LmsSendEmailModal modalProps={sendLmsEmailModal} courseEnrollmentIds={courseEnrollmentIds} afterSend={() => {gridActions.doFetch()}} />
			</React.Fragment>
		)
	}
}