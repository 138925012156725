import { IModelDocumentBase, ModelBase } from './model-base'
import { IGridColumn, IGridListItem } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, GridActionCell } from '../components/grid-cell-displays'

export type TagType = 'MINISTRYID' | 'MINISTRYCONTACTID' | 'PROSPECTID'

export interface ITagDocument extends IModelDocumentBase {
	tagId: number
	tag: string | null
	tableKey: 'MINISTRYID' | 'MINISTRYCONTACTID' | 'PROSPECTID' | null
	taggedCount: number | null
	branchId: number | null
	branchName: string | null
	branchAbbr: string | null
	isArchived: boolean
}

export interface ITag extends ITagDocument {
	toGridListItem: () => IGridListItem
}

export class TagModel extends ModelBase implements ITag {
	tagId: number = 0

	// Nullable
	tag: string | null
	tableKey: TagType | null
	taggedCount: number | null
	branchId: number | null
	branchName: string | null
	branchAbbr: string | null
	isArchived: boolean

	constructor(doc: ITagDocument) {
		super(doc)

		this.tagId = doc.tagId

		this.tag = doc.tag
		this.taggedCount = doc.taggedCount
		this.tableKey = doc.tableKey
		this.branchId = doc.branchId
		this.branchAbbr = doc.branchAbbr
		this.branchName = doc.branchName
		this.isArchived = doc.isArchived
	}

	public toGridListItem = (): IGridListItem => {
		return {
			id: this.tagId.toString(),
			values: {
				tag: this.tag,
				tableKey: this.tableKey,
				taggedCount: this.taggedCount,
				branchId: this.branchId,
				branchName: this.branchName,
				branchAbbr: this.branchAbbr,
				isArchived: this.isArchived
			}
		}
	}
}

export const factory = (doc: ITagDocument): TagModel => {
	return new TagModel(doc)
}

export const tagDefaultColumns: IGridColumn[] = [
	{
		property: 'tag',
		type: 'string',
		width: 215,
		allowFilters: true,
		title: 'Tag',
		render: DefaultGridCellDisplay,
		description: 'The tag name.'
	},
	{
		property: 'tableKey',
		type: 'string',
		width: 120,
		allowFilters: true,
		title: 'Type',
		render: DefaultGridCellDisplay,
		description: 'Where this tag can be used.',
		filterOptions: [
			{ label: 'Ministry', value: 'MINISTRYID' },
			{ label: 'Ministry Contact', value: 'MINISTRYCONTACTID' },
			{ label: 'Prospect', value: 'PROSPECTID' },
		],
	},
	{
		property: 'taggedCount',
		type: 'number',
		width: 90,
		allowFilters: true,
		title: 'Tagged Count',
		render: DefaultGridCellDisplay,
		description: 'How many active (unarchived) items are tagged with this tag.'
	},
	{
		property: 'branchAbbr',
		type: 'string',
		width: 90,
		allowFilters: true,
		title: "Community",
		render: DefaultGridCellDisplay,
		description: 'Community that this tag belongs to.'
	},
	{
		property: 'isArchived',
		type: 'boolean',
		width: 90,
		allowFilters: true,
		title: "Archived",
		render: DefaultGridCellDisplay,
		description: 'Is this tag currently archived?'
	},
	{
		property: 'grid_actions',
		type: 'string',
		width: 120,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
		description: 'Available actions for this tag.'
	},
]