import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { ReactComponent as BellIcon } from '../assets/bell.svg'
import { ReactComponent as PersonPlusIcon } from '../assets/person-plus.svg'
import { ReactComponent as PersonDashIcon } from '../assets/person-dash.svg'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { IDefaultProps } from './component-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { IAppState } from '../stores/app-definitions'
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell } from './grid-cell-displays'
import { Modal } from './modal'
import { SelectField } from './forms'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { sortListByProperty } from '../services/helpers'

interface IMailNotificationsProps extends IDefaultProps { }


// eslint-disable-next-line no-empty-pattern
export const MailNotifications = ({ }: IMailNotificationsProps) => {
    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    useEffect(() => {
        // Go ahead and refresh the list of users any time this component is loaded for the first time.
        appActions.fetchMiUsers()
        // eslint-disable-next-line
    }, [])

    /* 
        This one is a bit unusual. We are limiting the data shown to one community at a time, but the backend does not need to support filtering by community for this endpoint.

        So instead we get all notification records for all users, then filter the rows to return based on the currently selected community context.

        The rows are then grouped based on mail type, with each row being a user. 
    */
    const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
        if (!_appState.mailTypes || !_appState.users) {
            return {
                count: 0,
                rows: []
            }
        }

        const mailNotificationsQuery = await makeHttpRequestWithUi({
            request: appActions.MeMailTypes2BranchesApi.apiMeMailTypes2BranchesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error fetching the email notification records.',
            disableLoading: true,
        })

        // console.log('_appState.globalCommunityContext', _appState.globalCommunityContext)

        let mailNotifications = [...mailNotificationsQuery.data].filter(o => _appState.globalCommunityContext ? o.branchId === _appState.globalCommunityContext.branchId : o.branchId === 0)

        let rows: IGridListItem[] = []

        mailNotifications.forEach(o => {
            const mailType = _appState.mailTypes?.find(m => o.mailTypeId === m.mailTypeId)
            const user = _appState.users?.find(u => o.userId === u.userId)

            if (mailType && user && !user.bDisabled) {
                rows.push({
                    id: o.mailType2BranchId.toString(),
                    values: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        id: user.userId,
                        bPrimary: o.bPrimary
                    },
                    rowGroup: {
                        id: mailType.mailTypeId.toString(),
                        title: mailType.name || `No Name (mailTypeId: ${mailType.mailTypeId})`,
                        showEmptyRowGroup: true,
                        emptyRowGroupLabel: 'No users subscribed to this mail type.'
                    }
                })
            }
        })

        /* 
            Check to make sure that we have at least one record for each mail type.
            We want to show the "empty" group if there are no users. That way admins can add a user to the mail type.
        */
        _appState.mailTypes.filter(o => o.bActive).forEach(mailType => {
            const exists = rows.find(o => o.rowGroup?.id === mailType.mailTypeId.toString())
            if (!exists) {
                rows.push({
                    id: mailType.mailTypeId.toString(),
                    values: {},
                    rowGroup: {
                        id: mailType.mailTypeId.toString(),
                        title: mailType.name || `No Name (mailTypeId: ${mailType.mailTypeId})`,
                        showEmptyRowGroup: true,
                        emptyRowGroupLabel: 'No users subscribed to this mail type.',
                    },
                    hidden: true,
                })
            }
        })

        // Sort the rows by the mail type.
        rows.sort((a, b) => {
            if (a.rowGroup?.title && b.rowGroup?.title) {
                if (a.rowGroup.title < b.rowGroup.title) return -1
                if (a.rowGroup.title > b.rowGroup.title) return 1
                return 0
            }
            return 0
        })

        return { count: rows.length, rows }
    }


    useEffect(() => {
        actions.doFetch()
    }, [appState.globalCommunityContext, appState.users])


    const [addUserToNotificationModal, showHideAddUserToNotificationModal] = useModal()
    const [mailTypeIdToAddUsersTo, setMailTypeIdToAddUsersTo] = useState<string>()

    const [confirmRemoveUserModal, showHideConfirmRemoveUserModal] = useModal()
    const [userNotificationToRemove, setUserNotificationToRemove] = useState<IGridListItem>()

    const initialGridState: IGridState = {
        ...defaultGridState,
        columns: [
            {
                property: 'firstName',
                type: 'string',
                width: 150,
                allowFilters: false,
                disableSort: true,
                title: 'First Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'lastName',
                type: 'string',
                width: 150,
                allowFilters: false,
                disableSort: true,
                title: 'Last Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'email',
                type: 'string',
                width: 150,
                allowFilters: false,
                disableSort: true,
                title: 'Email',
                render: EmailCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 700,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
            },
        ],
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        rowSelectEnabled: false,
        hideGridHeader: true,
        hideGridFooter: true,
        rowActions: {
            removeUser: {
                id: 'removeUser',
                action: async (options) => {
                    options.e.stopPropagation()
                    setUserNotificationToRemove(options.row)
                    showHideConfirmRemoveUserModal(true)
                },
                icon: <PersonDashIcon />,
                tooltipText: 'Remove User',
                hidden: (row) => row.values.bPrimary === true // Only the "All Email" record belonging to the communities primary contact should be primary. Don't allow that to be removed.
            }
        },
        rowGroupActions: { actions: [
            {
                id: 'addUser',
                action: async (options) => {
                    options.e.stopPropagation()
                    setMailTypeIdToAddUsersTo(options.rowGroupId)
                    showHideAddUserToNotificationModal(true)
                },
                icon: <PersonPlusIcon />,
                tooltipText: 'Add User'
            }
        ] },
    }

    const [state, actions] = useGrid(gridReducer, initialGridState, appState)

    return (
        <>
            <Helmet>
                <title>Email Notifications</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <BellIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>{appState.globalCommunityContext ? appState.globalCommunityContext.branchName : 'All Chapter'} Email Notifications</h3>
                </div>

                <p className='ml-2'>Select a community from the dropdown at the top left of this screen to view its email notification data.</p>

                <Grid state={state} actions={actions} />
            </div>

            <Formik
                initialValues={{ userIds: [] }}
                validationSchema={Yup.object({
                    userIds: Yup.array().nullable().required('Required')
                })}
                onSubmit={async (values) => {
                    if (!mailTypeIdToAddUsersTo) return

                    const promises: Promise<any>[] = values.userIds.map(userId => (
                        makeHttpRequestWithUi({
                            request: appActions.MeMailTypes2BranchesApi.apiMeMailTypes2BranchesPost({ userId, branchId: appState.globalCommunityContext?.branchId || 0, mailTypeId: parseInt(mailTypeIdToAddUsersTo) }),
                            toastErrorMessage: `Error adding user (${userId}) to mail type ${appState.mailTypes?.find(o => o.mailTypeId.toLocaleString() === mailTypeIdToAddUsersTo)?.name} for ${appState.globalCommunityContext ? appState.globalCommunityContext.branchName : 'All Chapters'}.`,
                            toastSuccessMessage: `Successfully added user (${userId}) to mail type ${appState.mailTypes?.find(o => o.mailTypeId.toLocaleString() === mailTypeIdToAddUsersTo)?.name} for ${appState.globalCommunityContext ? appState.globalCommunityContext.branchName : 'All Chapters'}.`
                        })
                    ))

                    await Promise.all(promises)

                    showHideAddUserToNotificationModal(false)

                    actions.doFetch()
                }}
            >
                {formikProps => (
                    <Form>
                        <Modal
                            {...addUserToNotificationModal}
                            modalTitle='Add User(s) to Notification'
                            _onModalHidden={() => {
                                setMailTypeIdToAddUsersTo(undefined)
                                formikProps.resetForm()
                            }}
                            dismissible
                            footer={
                                <>
                                    <button type='button' className='btn btn-secondary' onClick={() => showHideAddUserToNotificationModal(false)}>Cancel</button>
                                    <button type='submit' onClick={formikProps.submitForm} className='btn btn-primary'>Add</button>
                                </>
                            }
                        >
                            <p>Select user(s) to add to <b>{appState.mailTypes?.find(o => o.mailTypeId.toLocaleString() === mailTypeIdToAddUsersTo)?.name}</b></p>
                            <SelectField
                                multiple
                                fieldProps={{ label: 'User(s)', name: 'userIds' }}
                                options={appState.users ? sortListByProperty(appState.users, 'firstName').filter(o => !o.bDisabled && !state.rows.find(u => u.values.id === o.userId && u.rowGroup?.id === mailTypeIdToAddUsersTo)).map(o => ({ label: `${o.firstName} ${o.lastName}`, value: o.userId.toString() })) : []}
                            />
                        </Modal>
                    </Form>
                )}
            </Formik>

            <Modal
                {...confirmRemoveUserModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setUserNotificationToRemove(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmRemoveUserModal(false)}>Cancel</button>
                        <button
                            className='btn btn-danger'
                            onClick={async () => {
                                if (userNotificationToRemove) {
                                    await makeHttpRequestWithUi({
                                        request: appActions.MeMailTypes2BranchesApi.apiMeMailTypes2BranchesIdDelete(parseInt(userNotificationToRemove.id)),
                                        toastSuccessMessage: `Successfully removed ${userNotificationToRemove?.values.firstName} ${userNotificationToRemove?.values.lastName} from ${userNotificationToRemove?.rowGroup?.title} notifications for ${appState.globalCommunityContext ? appState.globalCommunityContext.branchName : 'All Chapters'}.`,
                                        toastErrorMessage: `Encountered an error removing ${userNotificationToRemove?.values.firstName} ${userNotificationToRemove?.values.lastName} from ${userNotificationToRemove?.rowGroup?.title} notifications for ${appState.globalCommunityContext ? appState.globalCommunityContext.branchName : 'All Chapters'}.`
                                    })
                                    showHideConfirmRemoveUserModal(false)
                                    actions.doFetch()
                                }
                            }}
                        >
                            Remove
                        </button>
                    </>
                }
            >
                <p>Are you sure you want to remove <b>{userNotificationToRemove?.values.firstName} {userNotificationToRemove?.values.lastName}</b> from <b>{userNotificationToRemove?.rowGroup?.title}</b> notifications for <b>{appState.globalCommunityContext ? appState.globalCommunityContext.branchName : 'All Chapters'}</b>?</p>
            </Modal>

        </>
    )
}