import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IMiBranchModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { models, Report, Embed, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { BoxArrowUpRight } from "../assets";
import { IDefaultProps } from "./component-definitions";

interface ICardReportDashboard extends IDefaultProps {
    activeCommunity: IMiBranchModelDocument | undefined,
    style: object | undefined
}

export type ReportTab = 'cq-tab' | 'lq-tab' | 'kpi-tab'

// Map of event handlers to be applied to the embedding report
const eventHandlersMap = new Map([
    ['loaded', function () {
        console.log('Report has loaded')
    }],
    ['rendered', function () {
        console.log('Report has rendered')
    }],
    ['error', function (event?: service.ICustomEvent<any>) { 
        if (event) {
            console.log('error occurred')
            console.error(event.detail)
        }
    }]
]);

export const CardReportDashboard = (props: ICardReportDashboard) => {
    const { activeCommunity, style } = props
    
    const { PbiApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    // PowerBI Report object (to be received via callback)
	//const [report, setReport] = useState<Report>();
    const [reportCurrentQuarter, setReportCurrentQuarter] = useState<Report>()
    const [reportLastQuarter, setReportLastQuarter] = useState<Report>()
    const [reportKpi, setReportKpi] = useState<Report>()
    const [loading, setLoading] = useState(false)
    const [kpiError, setKpiError] = useState<string>()
    const [cqError, setCqError] = useState<string>()
    const [lqError, setLqError] = useState<string>()
    const [activeReportTab, setActiveReportTab] = useState<ReportTab>('kpi-tab')

    // Report config useState hook
	// Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [kpiReportConfig, setKpiReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: { navContentPaneEnabled: false }
	})
    
	const [cqReportConfig, setCqReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: { navContentPaneEnabled: false }
	})

    const [lqReportConfig, setLqReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: { navContentPaneEnabled: false }
	})

    const fetchKpiReport = async () => {
        setLoading(true)
        const reportQuery = await makeHttpRequestWithUi({
            request: PbiApi.apiPbiReportIdIdGet('9304c34f-ec6f-4f85-87d3-45dddcba9c51'),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch Report.',
            disableLoading: true,
        })

        if (reportQuery.data) {
            if (reportQuery.data.embedUrl) {
                setKpiError('')
                // Set the fetched embedUrl and embedToken in the report config
                setKpiReportConfig({
                    ...kpiReportConfig,
                    embedUrl: reportQuery.data.embedUrl || '',			// embedUrl: reportConfig.EmbedUrl,
                    accessToken: reportQuery.data.token || ''	// accessToken: reportConfig.EmbedToken.Token
                })
            } else {
                setKpiError("Report unavailable. Reason: " + reportQuery.data.noReportReason)
            }
        }

        setLoading(false)
    }


    const fetchCqReport = async () => {
        console.log('fetchCqReport')
        setLoading(true)
        const reportQuery = await makeHttpRequestWithUi({
            request: PbiApi.apiPbiReportIdIdGet('9f75132c-eb03-46f4-9004-cc2008401dc5'),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch Dashboard Report.',
            disableLoading: true,
            disableFailureToast: true // Don't show error toast if report is not available. Don't want to distress the user.
        })

        if (reportQuery.data) {

            if (reportQuery.data.embedUrl) {
                setCqError('')
                // Set the fetched embedUrl and embedToken in the report config
                setCqReportConfig({
                    ...cqReportConfig,
                    embedUrl: reportQuery.data.embedUrl || '',			// embedUrl: reportConfig.EmbedUrl,
                    accessToken: reportQuery.data.token || ''	// accessToken: reportConfig.EmbedToken.Token
                })
            } else {
                setCqError("Report unavailable. Reason: " + reportQuery.data.noReportReason)
            }
        }

        setLoading(false)
    }

    const fetchLqReport = async () => {
        setLoading(true)
        const reportQuery = await makeHttpRequestWithUi({
            request: PbiApi.apiPbiReportIdIdGet('e7df71d6-c1ff-467a-8396-2d48aabcfd61'),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch Dashboard Report.',
            disableLoading: true,
            disableFailureToast: true // Don't show error toast if report is not available. Don't want to distress the user.
        })

        if (reportQuery.data.embedUrl) {
            setLqError('')
            // Set the fetched embedUrl and embedToken in the report config
            setLqReportConfig({
                ...lqReportConfig,
                embedUrl: reportQuery.data.embedUrl || '',			// embedUrl: reportConfig.EmbedUrl,
                accessToken: reportQuery.data.token || ''	// accessToken: reportConfig.EmbedToken.Token
            })
        } else {
            setLqError("Report unavailable. Reason: " + reportQuery.data.noReportReason)
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchKpiReport()
    }, [])

    useEffect(() => {
        console.log('activeReportTab', activeReportTab)
        if (activeReportTab === 'cq-tab' && !cqReportConfig.embedUrl) fetchCqReport()
    }, [activeReportTab])

    useEffect(() => {
        if (activeReportTab === 'lq-tab' && !lqReportConfig.embedUrl) fetchLqReport()
    }, [activeReportTab])

    const viewTab = (tabId: ReportTab) => {
        setActiveReportTab(tabId)
    }

    return (
        <>
            <div className="card" style={{ ...style, minHeight: 0 }}>
                <div className="card-header" role="tablist">
                    { window.location.pathname !== '/dashboard-report' ? 
                        <a href={`/dashboard-report`} style={{float: 'right'}} title="Open full-page report."><BoxArrowUpRight /></a>
                        :
                        null }
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <a className={`nav-link active`} id='kpi-tab' onClick={() => viewTab('kpi-tab')} href="#kpi" data-toggle="tab" aria-controls="kpi" aria-selected="true">Annual KPIs</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link`} id='cq-tab' onClick={() => viewTab('cq-tab')} href="#cq" data-toggle="tab" aria-controls="cq" aria-selected="true">Current Quarter</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link`} id='lq-tab' onClick={() => viewTab('lq-tab')} href="#lq" data-toggle="tab" aria-controls="lq" aria-selected="true">Last Quarter</a>
                        </li>
                    </ul>    
                </div>
                
                <div className="tab-content">
                    <div className="tab-pane fade show active" role="tabpanel" id="kpi" aria-labelledby="kpi-tab">
                        { kpiError ?
                            <div className="alert alert-warning m-5">{kpiError}</div>
                            :
                            <PowerBIEmbed
                                embedConfig = { kpiReportConfig }
                                eventHandlers = { eventHandlersMap }
                                cssClassName = { "report-style-class" }
                                getEmbeddedComponent = { (embedObject:Embed) => {
                                    //console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                                    setReportKpi(embedObject as Report);
                                } }
                            /> }
                    </div>
                    <div className="tab-pane fade show active" role="tabpanel" id="cq" aria-labelledby="cq-tab">
                        { cqReportConfig.embedUrl ?
                            <PowerBIEmbed
                                embedConfig = { cqReportConfig }
                                eventHandlers = { eventHandlersMap }
                                cssClassName = { "report-style-class" }
                                getEmbeddedComponent = { (embedObject:Embed) => {
                                    //console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                                    setReportCurrentQuarter(embedObject as Report);
                                } }
                            /> 
                        : null}
                    </div>
                    <div className="tab-pane fade" role="tabpanel" id="lq" aria-labelledby="lq-tab">
                        {lqReportConfig.embedUrl ? 
                            <PowerBIEmbed
                                embedConfig = { lqReportConfig }
                                eventHandlers = { eventHandlersMap }
                                cssClassName = { "report-style-class" }
                                getEmbeddedComponent = { (embedObject:Embed) => {
                                    //console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                                    setReportLastQuarter(embedObject as Report);
                                } }
                            />
                        :
                            null}
                    </div>
                </div>
            </div>
        </>
    )
}