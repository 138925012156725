import { IModelDocumentBase, ModelBase } from './model-base'
import { IGridListItem, IGridColumn, GridListItemValueType } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell, MinistryNameDisplay, MultilineGridCellDisplay, ProgressGridCellDisplay } from '../components/grid-cell-displays'
import { ILmsArchiveSearchResultModelDocument, IMiBranchModelDocument } from '../open-api'

export interface ILmsCourseDocument extends IModelDocumentBase {
	lmsUserId: number
	userId: number
	subjectId: string
	ministryId: number
	ministryName: string
	ministryLevelId: number
	userCommunityAbbr: string
	firstName: string
	lastName: string
	email: string
	isGroupLeader: true
	lmsRegistrationDate: Date | null
	createdDate: Date | null
	updatedDate: Date | null
	syncedDate: Date | null
	lastLoginDate: Date | null
	lmsUserCourseId: number
	courseId: number
	courseTitle: string
	courseSlug: string
	firstStepDate: Date | null
	lastStepDate: Date | null
	completedDate: Date | null
	archiveDate: Date | null
	lessonsTotal: number
	topicsTotal: number
	stepsComplete: number
	percentageComplete: number
	courseStatus: string
	groups: [
		{
			lmsUserCourseGroupId: number
			lmsUserCourseId: number
			groupTitle: string
			groupId: number
			groupType: string
			eventId: number
			eventTitle: string
			groupCommunityAbbr: string
		}
	]
	fullName: string
	impersonationUrl: string
	lmsLearnerProfileUrl: string
}

export interface ILmsCourse extends ILmsCourseDocument {

}

export enum ILmsCourseStatus {
	in_progress = 'In Progress',
	completed = 'Completed',
	not_started = 'Not Started'
}

export class LmsCourseModel extends ModelBase implements ILmsCourse {
	lmsUserId: number
	userId: number
	subjectId: string
	ministryId: number
	ministryName: string
	ministryLevelId: number
	userCommunityAbbr: string
	firstName: string
	lastName: string
	email: string
	isGroupLeader: true
	lmsRegistrationDate: Date | null
	createdDate: Date | null
	updatedDate: Date | null
	syncedDate: Date | null
	lastLoginDate: Date | null
	lmsUserCourseId: number
	courseId: number
	courseTitle: string
	courseSlug: string
	firstStepDate: Date | null
	lastStepDate: Date | null
	completedDate: Date | null
	archiveDate: Date | null
	lessonsTotal: number
	topicsTotal: number
	stepsComplete: number
	percentageComplete: number
	courseStatus: string
	groups: [
		{
			lmsUserCourseGroupId: number
			lmsUserCourseId: number
			groupTitle: string
			groupId: number
			groupType: string
			eventId: number
			eventTitle: string
			groupCommunityAbbr: string
		}
	]
	fullName: string
	impersonationUrl: string
	lmsLearnerProfileUrl: string

	constructor(doc: ILmsCourseDocument) {
		super(doc)

		this.lmsUserId = doc.lmsUserId
		this.userId = doc.userId
		this.subjectId = doc.subjectId
		this.ministryId = doc.ministryId

		this.ministryName = doc.ministryName
		this.ministryLevelId = doc.ministryLevelId
		this.userCommunityAbbr = doc.userCommunityAbbr
		this.firstName = doc.firstName
		this.lastName = doc.lastName
		this.fullName = doc.fullName
		this.email = doc.email
		this.isGroupLeader = doc.isGroupLeader
		
		this.lmsRegistrationDate = doc.lmsRegistrationDate ? new Date(doc.lmsRegistrationDate) : null
		this.createdDate = doc.createdDate ? new Date(doc.createdDate) : null
		this.updatedDate = doc.updatedDate ? new Date(doc.updatedDate) : null
		this.syncedDate = doc.syncedDate ? new Date(doc.syncedDate) : null
		this.lastLoginDate = doc.lastLoginDate ? new Date(doc.lastLoginDate) : null

		this.lmsUserCourseId = doc.lmsUserCourseId
		this.courseId = doc.courseId
		this.courseTitle = doc.courseTitle
		this.courseSlug = doc.courseSlug

		this.firstStepDate = doc.firstStepDate ? new Date(doc.firstStepDate) : null
		this.lastStepDate = doc.lastStepDate ? new Date(doc.lastStepDate) : null
		this.completedDate = doc.completedDate ? new Date(doc.completedDate) : null
		this.archiveDate = doc.archiveDate ? new Date(doc.archiveDate) : null

		this.lessonsTotal = doc.lessonsTotal
		this.topicsTotal = doc.topicsTotal
		this.stepsComplete = doc.stepsComplete
		this.percentageComplete = doc.percentageComplete
		this.courseStatus = doc.courseStatus

		this.groups = doc.groups
		this.impersonationUrl = doc.impersonationUrl
		this.lmsLearnerProfileUrl = doc.lmsLearnerProfileUrl
	}

	public static toGridListItem = (course: ILmsArchiveSearchResultModelDocument): IGridListItem => {
		return {
			id: course.lmsArchiveUserCourseId.toString(),
			values: {
				subjectId: course.subjectId,
				ministryId: course.ministryId,
				courseTitle: course.courseTitle,
				branchAbbr: course.userCommunityAbbr,
				ministryName: course.ministryName,
				firstName: course.firstName,
				lastName: course.lastName,
				email: course.email,
				courseStatus: ILmsCourseStatus[course.courseStatus as keyof typeof ILmsCourseStatus],
				percentageComplete: course.percentageComplete,
				lastStepDate: course.lastStepDate,
				impersonationUrl: course.impersonationUrl,
				lmsLearnerProfileUrl: course.lmsLearnerProfileUrl,
				groups: course.groups as GridListItemValueType,
				groupTitle: course.groups?.reduce((groupName, group) => groupName ? groupName + '<br/>' + `${group.groupTitle}` : `${group.groupTitle}`, '') as GridListItemValueType
			}
		}
	}
}

export const factory = (doc: ILmsCourseDocument): LmsCourseModel => {
	return new LmsCourseModel(doc)
}

export const lmsCourseDefaultColumns = (communities: IMiBranchModelDocument[]): IGridColumn[] => ([
	{
		property: 'subjectId',
		type: 'string',
		width: 147,
		hide: true,
		allowFilters: true,
		title: 'Subject ID',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'ministryId',
		type: 'number',
		width: 147,
		hide: true,
		allowFilters: true,
		title: 'Ministry ID',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'courseId',
		type: 'string',
		width: 147,
		hide: true,
		allowFilters: true,
		title: 'Course ID',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'courseTitle',
		type: 'string',
		width: 184,
		allowFilters: true,
		title: 'Course',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'branchAbbr',
		type: 'string',
		width: 90,
		allowFilters: true,
		title: 'Community',
		render: DefaultGridCellDisplay,
		filterOptions: communities.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
	},
	{
		property: 'ministryName',
		type: 'string',
		width: 184,
		allowFilters: true,
		title: 'Ministry',
		render: MinistryNameDisplay,
	},
	{
		property: 'firstName',
		type: 'string',
		width: 100,
		allowFilters: true,
		title: 'First Name',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'lastName',
		type: 'string',
		width: 100,
		allowFilters: true,
		title: 'Last Name',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'groupTitle',
		type: 'string',
		width: 164,
		title: 'Group Name',
		allowFilters: true,
		render: MultilineGridCellDisplay,
	},
	{
		property: 'email',
		type: 'string',
		width: 219,
		allowFilters: true,
		title: 'Email',
		render: EmailCellDisplay,
	},
	{
		property: 'courseStatus',
		type: 'string',
		width: 120,
		allowFilters: true,
		filterOptions: Object.entries(ILmsCourseStatus).map(([key, value]) => ({ value: key, label: value })),
		title: 'Status',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'percentageComplete',
		type: 'number',
		width: 175,
		allowFilters: true,
		title: 'Progress',
		render: ProgressGridCellDisplay,
	},
	{
		property: 'lastStepDate',
		type: 'date',
		width: 125,
		allowFilters: true,
		title: 'Last Activity Date',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'grid_actions',
		type: 'actions',
		width: 150,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	}
])
