import { ReactNode, useEffect, useRef, useState } from "react"
import { usePopper } from 'react-popper'
import { useClickAway } from "react-use"

export interface IPopupProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    trigger: ReactNode
    closeOnInnerClick?: boolean
    onToggle?: (isOpen: boolean) => void
    popupOptions?: Parameters<typeof usePopper>[2]
}

export const Popup = ({ trigger, closeOnInnerClick, onToggle, popupOptions, children, ...rest }: IPopupProps) => {
    const [open, setOpen] = useState(false)
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const popperRef = useRef<HTMLDivElement | null>(null)

    const { styles, attributes } = usePopper(referenceElement, popperElement, popupOptions)

    useClickAway(popperRef, () => setOpen(false))

    useEffect(() => {
        if (onToggle) onToggle(open)
    }, [onToggle, open])

    useEffect(() => {
        return () => {
            // Make sure it's closed if removed from DOM
            setOpen(false)
        }
    }, [])

    return (
        <>
            <div ref={setReferenceElement} {...rest} style={{ ...rest.style, cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                {trigger}
            </div>

            {open &&
                <div
                    ref={node => {
                        setPopperElement(node)
                        popperRef.current = node
                    }}
                    style={{ ...styles.popper, zIndex: 2000 }}
                    {...attributes.popper}
                    onClick={() => {
                        if (closeOnInnerClick) setOpen(false)
                    }}
                >
                    {children}
                </div>
            }
        </>
    )
}