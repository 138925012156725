import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Grid } from './grid'
import { GridDataFetch, IGridState, GridUserInteractionStateKey } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { IDefaultProps } from './component-definitions'
import { openUrlInNewTab } from '../services/helpers'
import { Loading } from './loading'
import { IQueryState, IFilter, FilterValues } from '../stores/api-actions'
import { IAppState } from '../stores/app-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { isNullOrUndefined } from 'util'
import { Helmet } from 'react-helmet'
import { IncognitoIcon, PersonIcon, Mortorboard } from '../assets'
import { LmsCourseModel, lmsCourseDefaultColumns } from '../models/lms-course'
import { ISelectFieldOption } from './forms'

interface IDropdownOptions {
	groups: { value: string, label: string }[]
	events: { value: string, label: string }[]
}

interface ICourseEnrollmentProps extends IDefaultProps { }
export const CourseEnrollment = (props: ICourseEnrollmentProps) => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const fetchCourses: GridDataFetch<IAppState> = async (queryState, _appState) => {
		const _filters = [...queryState.filters || []]

		// We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing JSON into URL query params
		const filters = (_filters ? JSON.stringify([..._filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
		const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

		const coursesQuery = await makeHTTPRequestWithUi({
			request: appActions.LmsArchiveApi.apiLmsArchiveGet(
				(queryState.page - 1) * queryState.itemsPerPage,
				queryState.itemsPerPage,
				sorts,
				filters
			),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving LMS Courses.'
		})

		const seen = new Set();
		const courses = coursesQuery.data.data?.filter(item => {
			const value = item.lmsArchiveUserCourseId;
			if (seen.has(value)) {
				return false;
			}
			seen.add(value);
			return true;
		});

		return {
			rows: courses?.map(LmsCourseModel.toGridListItem) || [],
			count: coursesQuery.data.totalCount
		}
	}

	const [ dropdownOptions, setDropdownOptions ] = useState<IDropdownOptions>({} as IDropdownOptions)

	const fetchDropdownOptions = async (_appState: IAppState) => {
		const groupsQuery = await makeHTTPRequestWithUi({
			request: appActions.LmsArchiveApi.apiLmsArchiveDropdownGet(
				_appState.globalCommunityContext?.branchAbbr ?? '',
				true
			),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving LMS Groups.'
		})

		setDropdownOptions(
			{
				groups: groupsQuery.data.groups?.map((group) => ({
					value: group.id.toString(),
					label: group.name
				})),
				events: []
			} as IDropdownOptions
		)
	}

	useEffect(() => {
		fetchDropdownOptions(appState)
	}, [])

	const groupFilter: IFilter = {
        id: 'groupFilter',
        value: [],
        operator: 'in',
        property: 'groupId',
        enabled: false,
        hidden: true
    }

	let queryState: IQueryState | undefined = appState.currentUserSessionState?.gridUserInteractionStates?.[GridUserInteractionStateKey.LmsCourses]?.queryState

	if (!queryState) queryState = { ...defaultGridState.queryState }
	if (!queryState.filters) queryState.filters = [
		groupFilter
	]

	const [ groupFilterMode, setGroupFilterMode ] = useState<string[]>([])

	const selectGroupGridAction = {
		id: 'selectGroup',
		label: 'Select Group',
		dropdown: {
			multiple: true,
			options: dropdownOptions.groups as ISelectFieldOption[],
			onChange: (option : ISelectFieldOption | ISelectFieldOption[] | null) => {
				if ( option ) {
					setGroupFilterMode(Array.isArray(option) ? option.map(o => o.value)  : [option.value])
				}
			},
			value: groupFilterMode
		},
		disabled: (state: IGridState) => !state.queryState.filters?.find(f => f.property === 'branchAbbr')
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		loading: true,
		respectGlobalCommunityFilter: true,
		queryState,
		columns: lmsCourseDefaultColumns(appState.activeBranches),
		dataSource: fetchCourses,
		userSessionStateKey: GridUserInteractionStateKey.LmsCourses,
		overrideInitialStateValues: {
			queryState
		},
		gridActions: [
			selectGroupGridAction
		],
		rowActions: {
			viewProfile: {
				id: 'viewLmsProfile',
				action: async (options) => {
					const { e, row, appActions } = options

					e.stopPropagation()
					openUrlInNewTab(row.values.lmsLearnerProfileUrl?.toString() ?? '')
				},
				icon: <PersonIcon />,
				tooltipText: 'View Contact\'s LMS Profile',
				disabled: (row) => !row.values.lmsLearnerProfileUrl
			},
			impersonateUser: {
				id: 'impersonateUser',
				action: async (options) => {
					const { e, row, appActions } = options

					e.stopPropagation()
					openUrlInNewTab(row.values.impersonationUrl?.toString() ?? '')
				},
				icon: <IncognitoIcon />,
				tooltipText: 'Impersonate User',
				disabled: (row) => !row.values.impersonationUrl
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	useEffect(() => {
		gridActions.updateGridAction({ ...selectGroupGridAction, dropdown: { ...selectGroupGridAction.dropdown, options: dropdownOptions.groups } })
	}, [dropdownOptions])

	const previousGroupFilter = useRef(groupFilterMode)
	useEffect(() => {
		if (previousGroupFilter.current !== groupFilterMode) {
            gridActions.updateGridAction({ ...selectGroupGridAction, dropdown: { ...selectGroupGridAction.dropdown, value: groupFilterMode ?? undefined } })

			groupFilter.value = groupFilterMode as FilterValues;
			groupFilter.enabled = groupFilterMode.length !== 0
			
			const _filters = [...gridState.queryState.filters || []]
			const filterIdx = _filters.findIndex(o => o.id = groupFilter.id)
			if ( filterIdx !== -1 ) {
				_filters.splice(filterIdx, 1, groupFilter)
				gridActions.updateColumnFilters(_filters)
			}
		}
		previousGroupFilter.current = groupFilterMode
	}, [groupFilterMode])

	if (!gridState) {
		return <Loading />
	} else {
		return (
			<React.Fragment>
				<Helmet>
					<title>Course Enrollment</title>
				</Helmet>

				<div className='d-flex flex-column vh-100'>
					<div className='m-2 d-flex align-items-center'>
						<Mortorboard style={{ width: '25px', height: '25px' }} />
						<h3 className='ml-2'>Course Enrollment</h3>
					</div>
					<p className='ml-2'>A list of contacts who have access to courses. Use the filters below to search for enrollees by Course Name, Event Name or LMS Group Name.</p>
					<Grid state={gridState} actions={gridActions} style={{ flex: 1, height: 'unset' }} />
				</div>
			</React.Fragment>
		)
	}
}