import React, { useContext, useState, useEffect } from 'react'
import { IProfilePageModelDocument, IMinistrySummaryResponseModelDocument, IZCountrysDocument, IZStatesDocument, IApprovalStatusDocument } from '../open-api'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { TextField, EinField } from './forms'
import { validateEin, uuidv4, showModal, hideModal, sortListByProperty } from '../services/helpers'
import { MinistryModel } from '../models/ministry'
import { MininstryInfoProfilePageQuestion } from './ministry-info-profile-page-question'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { isNullOrUndefined } from 'util'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Modal } from './modal'
import { MINISTRY_INFO_PROFILE_GENERAL_PAGE_IDS, UK_COUNTRY_ID } from '../constants'
import { QuestionModel } from '../models/question'
import logo from '../assets/mif-logo.gif'
import { UserModel } from '../models/user'

interface IMinistryInfoProfileGeneralProps {
	pageId: number
	pageCompletionStatusId: number | null
	pageApprovalStatus?: IApprovalStatusDocument
	ministry: IMinistrySummaryResponseModelDocument
	pageApproved: () => Promise<void>
	pageDenied: () => Promise<void>
}
export const MinistryInfoProfilePage = (props: IMinistryInfoProfileGeneralProps) => {
	const { pageId, pageCompletionStatusId, pageApprovalStatus, ministry, pageApproved, pageDenied } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	const [modalState, setModalState] = useState<{
		modalId: string
		modalTitle: string
		body: string | JSX.Element
		footer: JSX.Element | null,
		onModalHidden?: () => void,
	}>({
		modalId: uuidv4(),
		modalTitle: '',
		body: '',
		footer: null
	})

	const [page, setPage] = useState<IProfilePageModelDocument>()

	const [state, setState] = useState<{
		enablePageApprove: boolean
		enablePageDeny: boolean
	}>({
		enablePageApprove: false,
		enablePageDeny: false,
	})

	const { enablePageApprove, enablePageDeny } = state

	const initialValues: { [key: string]: string | number | null | number[] | string[] | boolean } = {}
	const validationSchema: { [key: string]: any } = {}

	const fetchPage = async () => {
		const taskId = registerLoadingTask()
		const pageQuery = await appActions.MinistryProfileApi.apiMinistryProfileIdProfilePagePageIdGet(ministry.ministryId, pageId)
		deregisterLoadingTask(taskId)

		if (pageQuery.status !== 200) {
			alert(`Error retrieving profile page: ${pageQuery.statusText}`)
			return
		}

		setPage(pageQuery.data)
	}

	/* 
		Refresh the page any time the ministry ID changes.
	*/
	useEffect(() => {
		// console.log('fetchPage', ministry.ministryId, pageId)
		fetchPage()
		//eslint-disable-next-line
	}, [ministry.ministryId, pageId])


	useEffect(() => {
		/*
			Admin\modules\MinistryInfo\New\profile.js 2176
			toggle 'Approve Page' / 'Deny Page' disabled states
		*/
		let _enablePageApprove = state.enablePageApprove
		let _enablePageDeny = state.enablePageDeny

		switch (pageCompletionStatusId) {
			case -1:
				_enablePageApprove = true
				_enablePageDeny = false
				break
			case 1:
				_enablePageApprove = true
				_enablePageDeny = true
				break
			case 2:
				_enablePageApprove = false
				_enablePageDeny = true
				break
			default:
				_enablePageApprove = UserModel.userIsSuperUser(appState.currentUser)
				break
		}

		setState({ ...state, enablePageApprove: _enablePageApprove, enablePageDeny: _enablePageDeny })

		//eslint-disable-next-line
	}, [pageCompletionStatusId])

	const approvePagePressed = () => {
		if (!page) return
		setModalState({
			...modalState,
			modalTitle: 'Confirm',
			body: 'Are you sure you want to approve this page?',
			footer: (
				<React.Fragment>
					<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
					<button type='button' className='btn btn-primary' onClick={async () => {
						const taskId = registerLoadingTask()
						hideModal(modalState.modalId)
						await appActions.MinistryProfileApi.apiMinistryProfileIdApproveProfilePagePost(ministry.ministryId, { pageId: page.pageId })
						await pageApproved()
						deregisterLoadingTask(taskId)
					}}>Confirm</button>
				</React.Fragment>
			)
		})
		showModal(modalState.modalId)
	}

	const denyPagePressed = () => {
		if (!page) return
		setModalState({
			...modalState,
			modalTitle: 'Confirm',
			body: 'Are you sure you want to deny this page?',
			footer: (
				<React.Fragment>
					<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
					<button type='button' className='btn btn-primary' onClick={async () => {
						const taskId = registerLoadingTask()
						hideModal(modalState.modalId)
						await appActions.MinistryProfileApi.apiMinistryProfileIdDenyProfilePagePost(ministry.ministryId, { pageId: page.pageId })
						await pageDenied()
						deregisterLoadingTask(taskId)
					}}>Confirm</button>
				</React.Fragment>
			)
		})
		showModal(modalState.modalId)
	}

	const [countries, setCountries] = useState<IZCountrysDocument[]>()
	const [states, setStates] = useState<IZStatesDocument[]>()

	if (page) {
		/* 
			Prepare form validation and set initial values for the form
		
			We'll need to loop through each question and each question's fields (almost always there will only be one field per question).
		*/

		/* 
		The "General" pages have two non-dynamic fields hard-coded.
	*/
	if (MINISTRY_INFO_PROFILE_GENERAL_PAGE_IDS.includes(pageId)) {
		console.log('setting init vals for legal')
		initialValues['legalName'] = page.ministry.legalName.legalName
		initialValues['federalTaxNo'] = page.ministry.legalName.federalTaxNo
		initialValues['ukCcewId'] = page.ministry.legalName.ukCcewId
		initialValues['ukCcniId'] = page.ministry.legalName.ukCcniId
		initialValues['ukOscrId'] = page.ministry.legalName.ukOscrId

		validationSchema['legalName'] = Yup.string().required('Required')
		validationSchema['federalTaxNo'] = Yup.lazy(value => {
			if (value) {
				return Yup.string().test('ein', 'Must be a valid EIN', value => validateEin(value))
			} else {
				return Yup.string().required('Required')
			}
		})
	}

		for (const question of page.questions || []) {
			if (question.bGetPast3Years) {
				question.fields?.forEach((field) => {
					/* 
						When a question is of type bGetPast3Years, we will need to loop through the range 
						of fiscal years for which to gather data. 
						
						(fwiw bGetPast3Years could probably be better named something like 'isFiscalYearRangeQuestion' or something like that)

						When we do this, each field will end up producing a form field for each fiscal year.
						We will add each form field to the validation schema AND set an initial value for each form field.
					*/

					page.fiscalYearRange?.forEach(fiscalYear => {
						const value = page.pastYearsFields?.find(value => value.fieldId === field.fieldId && value.fiscalYear === fiscalYear)
						initialValues[QuestionModel.getFormFieldNameForPastThreeYearField(field.fieldName, fiscalYear)] = (value && value.stringValue) ? parseInt(value.stringValue).toLocaleString('en-US') : 0
						validationSchema[QuestionModel.getFormFieldNameForPastThreeYearField(field.fieldName, fiscalYear)] = Yup.lazy(value => {
							const cleaned = `${value}`.replace(/\D/g, '')
							value = parseInt(cleaned)

							/* 
								If this is the "income" field of the ReportedIncome question AND this page has an IncomeAmount question, 
								then we need to include a validation for this field to check if each fiscal year matches the total of all but 
								the last field of the IncomeAmount question for each fiscal year.							
							*/
							const incomeAmountQuestion = page.questions?.find(_question => QuestionModel.isIncomeAmount(_question))
							if (
								QuestionModel.isReportedIncome(question) &&
								incomeAmountQuestion &&
								field.fieldName === 'income'
							) {
								// console.log('we have a total validation case')
								return Yup
									.mixed()
									.test(
										'incomeAmountsMatch',
										'The amount entered for total income must match the sum of amounts entered for all cash income subcategories.',
										function () {
											return value === incomeAmountQuestion.fields?.reduce((value, _field, index, array) => {
												/* 
													Nifty trick from Yup - we can dynamically access values from other fields here, 
													inside a validation test, at this.parent[field_name] (be careful to not use an arrow function for this
													part of the test, else Formik will lose the 'this' context for accessing other field values).
												*/
												if (index < array.length - 1) value += parseInt(`${this.parent[QuestionModel.getFormFieldNameForPastThreeYearField(_field.fieldName, fiscalYear)]}`.replace(/\D/g, ''))

												return value
											}, 0)
										}
									)
							}

							return Yup.mixed().notRequired()
						})
					})
				})
			} else {
				for (const field of question.fields || []) {
					if (field.fieldName) {
						/* 
							The fieldType property on a field is actually a mini-object with a couple properties, one of them an id field.
							These IDs correspond to the different field type, but aren't typed in the API schema, hence casting to any first.
						*/
						switch ((field.fieldType as any).id) {
							case 1: // LargeTextField
							case 2: // TextField
								const value = page.fieldStringValues?.find(v => v.fieldId === field.fieldId)
								initialValues[field.fieldName] = value ? value.stringValue : ''

								validationSchema[field.fieldName] = (() => {
									if (field.bRequired) {
										if (field.stringLength !== null) {
											const stringLength = field.stringLength
											// This when statement makes it so the field is only required when it is visible. https://github.com/jquense/yup/issues/1114
											return Yup.string().when('$exist', ([exist], schema) => 
												exist ? Yup.string().required('Required').max(stringLength, `Value must be ${stringLength} characters or less`) : Yup.string()
											)
										}

										return Yup.string().when('$exist', ([exist], schema) => 
											exist ? Yup.string().required() : Yup.string()
										)
									} else {
										if (field.stringLength !== null) {
											const stringLength = field.stringLength
											return Yup.string().when('$exist', ([exist], schema) => 
												exist ? Yup.string().required('Required').max(stringLength, `Value must be ${stringLength} characters or less`) : Yup.string()
											)
										}
									}

									return Yup.string()
								})()

								break
							case 3: // YesNo
								const boolValue = page.fieldBoolValues?.find(val => val.fieldId === field.fieldId)
								initialValues[field.fieldName] = boolValue ? boolValue.boolValue : false

								validationSchema[field.fieldName] = Yup.lazy(val => {
									if (field.bRequired) {
										return Yup.bool().required('Required')
									}

									return Yup.bool().required('Required')
								})
								break
							case 4: // DropDownBox
								const dropDownValue = page.fieldDropdownValues?.find(v => v.fieldId === field.fieldId)
								if (dropDownValue && dropDownValue.dropdownOptions) {
									initialValues[field.fieldName] = dropDownValue.stringValue

									validationSchema[field.fieldName] = (() => {
										if (field.bRequired) return Yup.string().when('$exist', ([exist], schema) => 
											exist ? Yup.string().required() : Yup.string()
										)
										return Yup.string()
									})()

									if (QuestionModel.isStatesServed(question)) {
										if (!states) {
											setStates([])
											appActions.StatesApi.apiStatesGet()
												.then(results => {
													setStates(sortListByProperty(results.data, 'state'))
												})
										}
									}
								}
								break
							case 7: // DropDownMulti
								/* 
									There are only two DropdownMulti questions:
										1. In what countries are your services offered (question id 44)
										2. In what states are your services offered (question id 45)
								*/
								if (field.bRequired) {
									validationSchema[field.fieldName] = Yup.mixed().required('Required')
								}

								if (QuestionModel.isCountriesServed(question)) {
									initialValues[field.fieldName] = MinistryModel.ministryServiceAreaCountries(page.ministry)

									if (!countries) {
										setCountries([])
										appActions.CountriesApi.apiCountriesGet()
											.then(results => {
												results.data.sort((a, b) => {
													if (a.country === null) return -1
													if (b.country === null) return 1
													if (a.country < b.country) return -1
													if (a.country > b.country) return 1
													return 0
												})
												const usa = results.data.find(c => c.countryId === 211)
												if (usa) {
													const usaIdx = results.data.indexOf(usa)
													const removed = results.data.splice(usaIdx, 1)
													results.data.unshift(...removed)
												}
												setCountries(results.data)
											})
									}
								}

								if (QuestionModel.isStatesServed(question)) {
									initialValues[field.fieldName] = MinistryModel.ministryServiceAreaStates(page.ministry)
									if (!states) {
										setStates([])
										appActions.StatesApi.apiStatesGet()
											.then(results => {
												setStates(sortListByProperty(results.data, 'state'))
											})
									}
								}
								break
							case 8: // Checkbox
								// Currently there is only one active checkbox question
								if (QuestionModel.isDirectorActivities(question) && field.defaultValue) {
									const fieldName = QuestionModel.getFormFieldNameForDirectorActivitiesField(field.fieldName, field.defaultValue)
									initialValues[fieldName] = !!page.ministry.directorActivities?.includes(field.defaultValue)
									validationSchema[fieldName] = field.bRequired ? Yup.boolean().required('Required') : Yup.boolean()
								}
								break
							case 9: // FileUpload
								// No validation since the original ExtJS did not support file uploads, it only displayed the name of previously uploaded files.
								// if (field.bRequired) {
								// 	validationSchema[field.fieldName] = Yup.mixed().required('Required')
								// } else {
								// 	validationSchema[field.fieldName] = Yup.mixed()
								// }

								const file = page.files?.find(file => file.fieldId === field.fieldId)
								initialValues[field.fieldName] = (file) ? file.ministryFile.clientFile : 'No File Uploaded'

								break
						}
					}
				}
			}
		}

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object(validationSchema)}
				validateOnMount
				onSubmit={async (values, actions) => {
					const taskId = registerLoadingTask()

					const ministryFieldValues: { [key: string]: any } = {}

					// The "General" pages have some non-dynamic fields hard-coded
					if (MINISTRY_INFO_PROFILE_GENERAL_PAGE_IDS.includes(pageId)) {
						ministryFieldValues['legalName'] = values.legalName
						ministryFieldValues['federalTaxNo'] = values.federalTaxNo
						ministryFieldValues['ukCcewId'] = values.ukCcewId
						ministryFieldValues['ukCcniId'] = values.ukCcniId
						ministryFieldValues['ukOscrId'] = values.ukOscrId
					}

					/*
						Gather field values into "single value" (ministryFieldNames) and "multi value" (oneToManyFieldNames) groupings in preparation to be persisted to API
						Every field name _should_ have a value inside `values`.
					*/
					page.questions?.forEach(question => {
						if (question.bGetPast3Years) {
							question.fields?.forEach(field => {
								page.fiscalYearRange?.forEach(fiscalYear => {
									const fieldName = QuestionModel.getFormFieldNameForPastThreeYearField(field.fieldName, fiscalYear)
									const value = values[fieldName]
									if (!isNullOrUndefined(value)) {
										const cleaned = `${value}`.replace(/\D/g, '')
										ministryFieldValues[fieldName] = cleaned
									}
								})
							})
						} else {
							/* 
								The value for this particular question is concatenated from all its fields.
							*/
							if (QuestionModel.isDirectorActivities(question)) {
								if (question.fields?.length && question.fields[0].fieldName) {
									/* 
										This question is comprised of 4 checkbox fields. Each field has a default value. If the field is checked, the default value is included in a comma delimitted string, otherwise it is ommited.
									*/
									const fieldValues: string[] = []
									question.fields.forEach(field => {
										if (values[QuestionModel.getFormFieldNameForDirectorActivitiesField(field.fieldName, field.defaultValue)] && field.defaultValue) fieldValues.push(field.defaultValue)
									})
									ministryFieldValues[question.fields[0].fieldName] = fieldValues.join(',')
								}
							} else {
								question.fields?.forEach(field => {
									const { fieldName } = field

									// We skip the file field dynamic field type for now. Original ExtJS did not support file uploads on the GrowthTrack.
									if (fieldName && values.hasOwnProperty(fieldName) && (field.fieldType as any).id !== 9) {
										/* 
											OneToMany value fields are concatenated into an array of string IDs
										*/
										if (question.oneToManyValueField) {
											const value = values[fieldName]
											if (value instanceof Array) {
												ministryFieldValues[fieldName] = [...value].map(val => `${val}`)
											} else {
												ministryFieldValues[fieldName] = values[fieldName]
											}
										} else {
											const value = values[fieldName]
											if (typeof value === 'boolean') {
												ministryFieldValues[fieldName] = value ? '1' : '0'
											} else {
												ministryFieldValues[fieldName] = isNullOrUndefined(values[fieldName]) ? null : `${values[fieldName]}`
											}
										}

									}
								})
							}
						}
					})

					const promises: Promise<any>[] = []

					if (MINISTRY_INFO_PROFILE_GENERAL_PAGE_IDS.includes(pageId)) {
						promises.push(
							appActions.MinistryLegalNamesApi.apiMinistriesIdMinistryLegalNamesPut(ministry.ministryId, {
								legalName: typeof values.legalName === 'string' ? values.legalName : null,
								federalTaxNo: typeof values.federalTaxNo === 'string' ? values.federalTaxNo : null,
								ukCcewId: typeof values.ukCcewId === 'string' ? values.ukCcewId : null,
								ukCcniId: typeof values.ukCcniId === 'string' ? values.ukCcniId : null,
								ukOscrId: typeof values.ukOscrId === 'string' ? values.ukOscrId : null,
								ministryid: ministry.ministryId,
							})
						)
					}

					promises.push(appActions.MinistryProfileApi.apiMinistryProfileIdProfilePagePageIdPost(ministry.ministryId, page.pageId, {
						ministryFieldNames: page.ministryFieldNames,
						oneToManyFieldNames: page.oneToManyFieldNames,
						ministryDataFieldNames: page.ministryDataFieldNames,
						previousYearsFieldNames: page.previous3FieldNames?.map(o => ({ ...o, valueField: QuestionModel.getFormFieldNameForPastThreeYearField(o.originalFieldName, o.fiscalYear) })) || null,
						ministryFieldValues
					}))

					await Promise.all(promises)

					deregisterLoadingTask(taskId)
				}}
			>
				{formikProps =>
					<React.Fragment>
						<Modal modalId={modalState.modalId} modalTitle={modalState.modalTitle} footer={modalState.footer} onModalHidden={modalState.onModalHidden}>
							{modalState.body}
						</Modal>

						<Modal
							modalId='inlineTaxYearHelper'
							modalTitle={<img src={logo} alt='Mission Increase Logo' />}
							// footer={<React.Fragment>
							// 	<button type='button' className='btn btn-secondary' data-dismiss='modal'>OK</button>
							// </React.Fragment>}
							size={'lg'}
						>
							<div><strong><em>Fiscal vs. Tax Years</em></strong></div>
							<br />
							<div className='text-danger'>Mission Increase requires your <b>Tax Year</b> information in order to better align with the financial information you submit to the Tax BureauIRS via your <b>Form 990</b>. It's important to understand the difference between <b>Fiscal Year</b> and <b>Tax Year</b>.</div>
							<br />
							<div>Most organizations think in terms of organization fiscal years. The Tax BureauIRS, in contrast, focuses on the Start/Tax Year in determining the <b>Tax Year</b> and which version of the Form 990 to use.</div>
							<br />
							<div>For a majority of non-profits whose fiscal year runs from January 1 to December 31 there is no difference; the <b>Fiscal Year</b> and the <b>Tax Year</b> represent the same 12 month period. <span className='text-danger'>(see Org "X" example below)</span></div>
							<br />
							<div><b>However</b>, some organizations, like Org "Y" <span className='text-danger'>(below)</span> have fiscal years which run from July 1 to June 30. When Org "Y" completed its 2010 fiscal year on June 30, 2010, it would use the Tax Bureau FormIRS Form 990 with "2009" in the upper right corner for <b><u>Tax Year 2009</u></b>, since this reflects its Start/Tax Year.</div>
							<br />
							<div><b><span className='text-danger'>Examples</span>:</b></div>
							<table className='table'>
								<thead className='borderless'>
									<tr>
										<th></th>
										<th><b>FY Start Date</b></th>
										<th><b>FY End Date</b></th>
										<th><b>Tax Year</b></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td><span className='text-danger'>Org X</span></td>
										<td>1/1/2009</td>
										<td>12/31/2009</td>
										<td>2009</td>
									</tr>
									<tr>
										<td><span className='text-danger'>Org Y</span></td>
										<td>7/1/2009</td>
										<td>6/30/2010</td>
										<td>2009</td>
									</tr>
									<tr>
										<td><span className='text-danger'>Org Z</span></td>
										<td>10/1/2008</td>
										<td>9/30/2009</td>
										<td>2008</td>
									</tr>
								</tbody>
							</table>
							<div className='mb-3'></div>
						</Modal>

						<Form>
							<div className='mb-3 d-flex'>
								<button className='btn btn-primary px-3' type='submit' disabled={!formikProps.isValid}>Save Changes</button>
								{enablePageApprove ?
									<button
										type='button'
										className='btn btn-light ml-1'
										onClick={async () => {
											if (formikProps.dirty) {
												formikProps.setFieldValue('saveBeforeApprove', true)
												await formikProps.submitForm()
											}
											approvePagePressed()
										}}
									>
										Approve Page
									</button>
									:
									null
								}
								{enablePageDeny ? <button type='button' className='btn btn-light ml-1' onClick={denyPagePressed}>Deny Page</button> : null}
								{pageApprovalStatus && enablePageDeny ? <i className='ml-1 d-flex align-items-center'>{pageApprovalStatus.pageName} approved {pageApprovalStatus.completeInfo}</i> : null}
							</div>
							{MINISTRY_INFO_PROFILE_GENERAL_PAGE_IDS.includes(page.pageId) ?
								<React.Fragment>
									<TextField fieldProps={{ name: 'legalName', label: 'Legal Name' }} />
									{ ministry.physicalCountryId === UK_COUNTRY_ID ? 
									<>
										<TextField fieldProps={{ name: 'ukCcewId', label: 'Charity Commission for England and Wales' }} />
										<TextField fieldProps={{ name: 'ukCcniId', label: 'Charity Commission for Northern Ireland' }} />
										<TextField fieldProps={{ name: 'ukOscrId', label: 'OSCR Scottish Charity Regulator' }} />
									</>
									: 
									<EinField fieldProps={{ name: 'federalTaxNo', label: 'Tax ID or EIN' }} /> }
								</React.Fragment>
								:
								null
							}
							{page.questions?.map(question => <MininstryInfoProfilePageQuestion
								key={question.questionId}
								ministry={ministry}
								questions={page.questions || []}
								question={question}
								dropdownOptions={page.fieldDropdownValues}
								pastYearsFields={page.pastYearsFields}
								activeMinistryData={page.activeMinistryData}
								fiscalYearRange={page.fiscalYearRange}
								countries={countries}
								states={states}
								formikProps={formikProps}
							/>)}
						</Form>
					</React.Fragment>
				}
			</Formik>
		)
	} else {
		return null
	}


}