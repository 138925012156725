import React, { useContext } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { uuidv4 } from '../services/helpers'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { Checkbox } from './forms'

export const EventNewsCoachingInvitationSetting = () => {

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    if (!appState.globalCommunityContext) {
        return <div>
            Please select a community under the “All Communities” drop-down to manage the coaching invitation.
        </div>
    } else {
        return <Checkbox
            label='Show Coaching Invitation'
            disabled={!appState.globalCommunityContext}
            checked={!!appState.globalCommunityContext?.bNewsletterConsultingBlock}
            id={uuidv4()}
            onChange={async (ev) => {
                if (!appState.globalCommunityContext) return
                await makeHttpRequestWithUi({
                    // @ts-ignore
                    request: appActions.MiBranchesApi.apiMiBranchesIdPatch(appState.globalCommunityContext.branchId, [{ value: !appState.globalCommunityContext.bNewsletterConsultingBlock, path: '/bNewsletterConsultingBlock', op: 'add' }]),
                    toastErrorMessage: `There was an error changing the coaching invitation setting for ${appState.globalCommunityContext.branchAbbr}.`,
                    toastSuccessMessage: `Coaching invitation setting successfully updated for ${appState.globalCommunityContext.branchAbbr}.`,
                })
                appActions.refreshBranchesCache()
            }}
        />
    }
}