import { IGridColumn, IGridState, GridDataFetch, IGridListItem, IGridRowAction, IGridUserInteractionState, gridColumnsToGridColumnUserInteractionState, IGridDataUpdate, isGridListItemObjectValueTypeArray, IGridAction } from "./grid-definitions"
import { IQueryState, ISort, IFilter, SortDirections } from "./api-actions"
import { GridActions, GridActionType } from "./grid-reducer"
import { useYieldThrottle, validateFilterState, sortListByProperty, uuidv4 } from "../services/helpers"
import { isNullOrUndefined } from "util"
import { DATA_VISUALIZATION_COLORS } from "../constants"
import { unparse } from 'papaparse'
import { IAppState } from "./app-definitions"
import { useReducer, useContext, useState, useEffect, useCallback } from "react"
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { usePrevious } from "react-use"
import dayjs from "dayjs"

/* 
	This is sort of a wrapper for all Grid actions - sometimes the actions will alter the state of the Grid, sometimes not.
		
	Regardless, all actions for the Grid live here. The root Grid component sets up the state and actions for itself 
	and provides access to the state and actions to inner components via Contexts (to avoid having to pass the state and actions down into nested components).

	Some of these functions will simply take what's passed, and "dispatch" the value on to the reducer.

	Some functions, though, will pause to run a query to refresh the data for the Grid.
*/

export interface IGridActions {
	// No side effects (synchronous)
	setLoading: (loading: boolean) => void
	setGridElement: (gridElement: HTMLDivElement) => void
	setGridDataSource: (dataSource: GridDataFetch<any>) => void
	setRowActions: (rowActions: { [id: string]: IGridRowAction }) => void
	setFooterActions: (footerActions: JSX.Element[]) => void
	updateColumn: (col: IGridColumn) => void

	updateColumns: (cols: IGridColumn[]) => void
	showAllColumns: () => void
	hideAllColumns: () => void
	setColumns: (cols: IGridColumn[]) => void
	setActiveFilterPopover: (popover: HTMLDivElement | null) => void
	toggleRowSelect: (row: IGridListItem, selected?: boolean) => void
	toggleAllRowsSelect: (allSelected?: boolean) => void
	rowPressed: (row: IGridListItem) => void
	setHighlightedRows: (rowIds: string[]) => void
	deselectAllRows: () => void
	updateGridAction: (gridAction: IGridAction) => void
	toggleUsingLocalData: (usingLocalData: boolean) => void
	toggleRowGroupExpandCollapse: (rowGroupId: string) => void

	// Side effects (asynchronous)
	doFetch: () => Promise<void>,
	setItemsPerPage: (itemsPerPage: number) => Promise<void>
	setSelectedPage: (selectedPage: number) => Promise<void>
	setSort: (sort: ISort) => Promise<void>
	addSort: (colProperty: string) => Promise<void>
	clearSort: (sort: ISort) => Promise<void>
	updateColumnFilters: (filters: IFilter[], throttleQuery?: boolean) => Promise<void>
	toggleColumnFilters: (col: IGridColumn) => Promise<void>
	setColumnFilters: (filters: IFilter[]) => Promise<void>
	exportData: () => Promise<string>
	setQueryState: (queryState: IQueryState) => Promise<void>
	resetFiltersSortsAndRowSelections: (filterIdsToPreserve: string[]) => Promise<void>

	saveViewToUserSessionState: () => Promise<void>
	resetViewInUserSessionStateToDefault: () => Promise<void>
}

const GLOBAL_COMMUNITY_CONTEXT_FILTER_ID = 'globalCommunityFilter'

/* 
	TODO: "otherState" could be improved. It's unclear. Some components will use the global app state in their data source when setting up a grid, others will use their own state. This is meant to be a way to pass that state into the Grid, but it's unclear that that "other state" could really be anything.
	TODO: we need to implement a grid action queue - since state updates are not synchronous, it's possible for multiple, rapid grid actions to end with an earlier action overriding state changes of a later action...
*/
export const useGrid = <StateType>(reducer: React.Reducer<IGridState, GridActions>, initialState: IGridState, otherState: StateType): [IGridState, IGridActions] => {
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	/* 
		Track whether the grid has been started up or not - this way we can avoid re-running first time code.
	*/
	const [bootstrapped, setBootstrapped] = useState(false)

	/* 
		When the grid is started, if a userSessionStateKey is provided, check to see if there is any user interaction state for this grid in the user's session state.
		If there is, use it as the beginning state of the grid.
	*/
	if (!bootstrapped) {
		if (initialState.id === 'default-grid-id') initialState.id = uuidv4()

		initialState.queryState = {
			...initialState.queryState,
			...initialState.overrideInitialStateValues?.queryState ? initialState.overrideInitialStateValues?.queryState : {}
		}

		if (initialState.userSessionStateKey && appState.currentUserSessionState && appState.currentUserSessionState.gridUserInteractionStates) {
			const storedGridInteractionState = appState.currentUserSessionState.gridUserInteractionStates[initialState.userSessionStateKey]
			if (storedGridInteractionState) {
				initialState.queryState = {
					...storedGridInteractionState.queryState,
					...initialState.overrideInitialStateValues?.queryState ? initialState.overrideInitialStateValues?.queryState : {}
				}
				const columns = [...initialState.columns]
				storedGridInteractionState.columns.forEach(c => {
					const col = columns.find(c2 => c2.property === c.property)
					if (col) {
						col.hide = c.hide
						col.width = c.width
						col.sortOrder = c.sortOrder
						col.disableFilters = c.disableFilters
					}
				})
				initialState.columns = sortListByProperty(columns, 'sortOrder')
			}
		}

		if (initialState.respectGlobalCommunityFilter) {
			const queryState = initialState.queryState
			const communityColumn = initialState.columns.find(col => col.property === 'branchAbbr' || col.property === 'branchId')
			const existingCommunityFilter = queryState.filters?.find(filter => filter.id === 'branchAbbr-in' || filter.id === 'branchId-in')
			const existingGlobalCommunityFilter = queryState.filters?.find(filter => filter.id === GLOBAL_COMMUNITY_CONTEXT_FILTER_ID)

			if (!communityColumn) throw new Error(`Cannot use the Grid's respectGlobalCommunityFilter feature without a filterable column with a property name of 'branchAbbr' or 'branchId'.`)

			if (appState.globalCommunityContext && appState.globalCommunityContext.branchAbbr) {
				if (existingCommunityFilter) {
					existingCommunityFilter.enabled = false
					existingCommunityFilter.hidden = true
				}

				const filterValue = communityColumn.property === 'branchAbbr' ?
					appState.globalCommunityContext.branchAbbr
					:
					appState.globalCommunityContext.branchId.toString()

				if (!existingGlobalCommunityFilter) {
					const communityFilter: IFilter = {
						id: GLOBAL_COMMUNITY_CONTEXT_FILTER_ID,
						enabled: true,
						value: [filterValue],
						operator: 'in',
						property: communityColumn.property,
						hidden: true,
					}

					if (queryState.filters) {
						queryState.filters.push(communityFilter)
					} else {
						queryState.filters = [communityFilter]
					}
				} else {
					existingGlobalCommunityFilter.value = [filterValue]
					existingGlobalCommunityFilter.enabled = true
				}

				if (communityColumn) {
					communityColumn.allowFilters = false
					communityColumn.filterUnallowedMessage = 'You cannot filter by Community when a Global Community context has been selected. Please select "All Communities" in the left hand menu to re-enable filtering by Community inside the Ministries table.'
				}
			}
		}

		setBootstrapped(true)
	}


	const getCurrentUserInteractionStateForGrid = (): IGridUserInteractionState => {
		if (state.userSessionStateKey && appState.currentUserSessionState?.gridUserInteractionStates) {
			const storedGridInteractionState = appState.currentUserSessionState.gridUserInteractionStates[state.userSessionStateKey]
			if (storedGridInteractionState) return storedGridInteractionState
		}

		return {
			queryState: state.queryState,
			columns: gridColumnsToGridColumnUserInteractionState(state.columns)
		}
	}

	const [state, dispatch] = useReducer(reducer, initialState)

	useEffect(() => {
		if (state.respectGlobalCommunityFilter) {
			if (state.loading) {
				// Disable the Global Community Context dropdown
				appActions.disableGlobalCommunityContextDropdown()
			} else {
				// Enable the Global Community Context dropdown
				appActions.enableGlobalCommunityContextDropdown()
			}
		}

		// eslint-disable-next-line
	}, [state.loading, state.respectGlobalCommunityFilter])

	/*
		Listen to the Global Community Filter change.
		When a user selects a Global Community, update the community filter in the grid.

		Only do this if the user is changing the Global Community Filter - don't apply if both the current and next selection are the same
		(this will prevent it from firing on component mount, which is unnecessary since we already respect the currently selected global community
		when setting up the ministry grid for the first time - we only need to listen to changes after mount).
	*/
	const previousGlobalCommunityFilter = usePrevious(appState.globalCommunityContext)
	useEffect(() => {
		if (state.respectGlobalCommunityFilter) {
			// If a user has already added their own community filter, store it so we can reapply it if they enable then disable a global community filter.
			const existingCommunityFilter = state.queryState.filters?.find(filter => filter.id === 'branchAbbr-in' || filter.id === 'branchId-in')
			const existingGlobalCommunityFilter = state.queryState.filters?.find(filter => filter.id === GLOBAL_COMMUNITY_CONTEXT_FILTER_ID)

			if (
				state &&
				previousGlobalCommunityFilter !== undefined && // When previousGlobalCommunityFilter is created it is undefined, once loaded it is null. So we can check !== undefined to make sure this isn't first load of the component.
				previousGlobalCommunityFilter?.branchAbbr !== appState.globalCommunityContext?.branchAbbr
			) {
				const filters: IFilter[] = []
				const communityColumn = state.columns.find(col => col.property === 'branchAbbr' || col.property === 'branchId')

				// If a global community is selected, override the grid's internal filters
				if (appState.globalCommunityContext && appState.globalCommunityContext.branchAbbr) {

					if (existingCommunityFilter) {
						filters.push({ ...existingCommunityFilter, enabled: false, hidden: true })
					}

					filters.push({
						id: GLOBAL_COMMUNITY_CONTEXT_FILTER_ID,
						enabled: true,
						value: communityColumn?.property === 'branchId' ? [appState.globalCommunityContext.branchId.toString()] : [appState.globalCommunityContext.branchAbbr],
						operator: 'in',
						property: communityColumn?.property || 'branchAbbr',
						hidden: true,
					})


					if (communityColumn) {
						const updateCommunityColumn = { ...communityColumn }
						updateCommunityColumn.allowFilters = false
						updateCommunityColumn.filterUnallowedMessage = 'You cannot filter by Community when a Global Community context has been selected. Please select "All Communities" in the left hand menu to re-enable filtering by Community inside the Ministries table.'
						actions.updateColumn(updateCommunityColumn)
					}
				} else {
					if (existingGlobalCommunityFilter) {
						filters.push({ ...existingGlobalCommunityFilter, enabled: false, value: undefined })
					}

					if (existingCommunityFilter) {
						filters.push({ ...existingCommunityFilter, enabled: false, hidden: false })
					}

					if (communityColumn) {
						const updateCommunityColumn = { ...communityColumn }
						updateCommunityColumn.allowFilters = true
						updateCommunityColumn.disableFilters = true
						updateCommunityColumn.filterUnallowedMessage = undefined
						actions.updateColumn(updateCommunityColumn)
					}
				}

				if (filters.length) actions.updateColumnFilters(filters)
				dispatch({ type: GridActionType.deselectAllRows })
			}
		}

		//eslint-disable-next-line
	}, [appState.globalCommunityContext, state.respectGlobalCommunityFilter])

	const [queueQuery] = useYieldThrottle()

	const doGridFetch = async (_queryState: IQueryState, _otherState: StateType, throttleQuery?: boolean) => {
		dispatch({ type: GridActionType.setQuery, payload: _queryState })

		const query = async () => {
			let _contine = true

			if (_queryState.filters) validateFilterState(_queryState.filters, state.columns)

			if (_contine) {
				dispatch({ type: GridActionType.loading })
				const promises: Promise<any>[] = [
					state.dataSource(_queryState, _otherState),
				]
				const queries = await Promise.all(promises)

				const data: IGridDataUpdate = queries[0]


				let colors = [...DATA_VISUALIZATION_COLORS]

				data.rows.forEach(row => {
					const _row = state.rows.find(r => r.id === row.id)

					if (state.enableRowColors) {
						// Refill the colors list if we've used them all
						if (colors.length === 0) colors = [...DATA_VISUALIZATION_COLORS]
						row.color = _row ? _row.color : colors.splice(0, 1)[0]
					}

					// Preserve row selection
					if (_row) {
						row.selected = _row.selected
					}
				})

				dispatch({ type: GridActionType.updateData, payload: data })
			}
		}

		if (throttleQuery && !state.usingLocalData) {
			queueQuery(query)
		} else {
			query()
		}
	}

	// Synchronous actions
	const setLoading = (loading: boolean) => dispatch({ type: loading ? GridActionType.loading : GridActionType.doneLoading })
	const setGridDataSource = (dataSource: GridDataFetch<IAppState>) => dispatch({ type: GridActionType.setGridDataSource, payload: dataSource })
	const setGridElement = (gridElement: HTMLDivElement) => dispatch({ type: GridActionType.setGridElement, payload: gridElement })
	const setRowActions = (rowActions: { [id: string]: IGridRowAction }) => dispatch({ type: GridActionType.setRowActions, payload: rowActions })
	const setFooterActions = (footerActions: JSX.Element[]) => dispatch({ type: GridActionType.setFooterActions, payload: footerActions })

	// Column controls
	const _setColumns = (cols: IGridColumn[]) => {
		// if (state.userSessionStateKey) {
		// 	appActions.UserSessionActions.updateGridUserInteractionState(
		// 		{ ...getCurrentUserInteractionStateForGrid(), columns: gridColumnsToGridColumnUserInteractionState(cols) },
		// 		state.userSessionStateKey
		// 	)
		// }
		dispatch({ type: GridActionType.setColumns, payload: cols })
	}

	const _findAndUpdateColumn = (_columns: IGridColumn[], _column: IGridColumn): IGridColumn[] => {
		const column = _columns.find(col => col.property === _column.property)
		if (!column) throw new Error('GridStateError: attempted to update a column that does not exist on this Grid.')
		let columnIdx = _columns.indexOf(column)
		_columns.splice(columnIdx, 1, _column)
		return _columns
	}
	const _findAndUpdateColumns = (_columns: IGridColumn[], _columnsToUpdate: IGridColumn[]): IGridColumn[] => {
		const tempColumns = [..._columns]
		_columnsToUpdate.forEach(c => {
			_findAndUpdateColumn(tempColumns, c)
		})
		return tempColumns
	}

	const updateColumn = useCallback((col: IGridColumn) => {
		console.log('updating with these columns', state.columns)
		_setColumns(_findAndUpdateColumn([...state.columns], col))
	}, [state.columns])
	const updateColumns = (cols: IGridColumn[]) => _setColumns(_findAndUpdateColumns(state.columns, cols))
	const setColumns = (cols: IGridColumn[]) => _setColumns(cols)
	const showAllColumns = () => {
		const _tempColumns = [...state.columns]
		_tempColumns.forEach(c => c.hide = false)
		_setColumns(_tempColumns)
	}
	const hideAllColumns = () => {
		const _tempColumns = [...state.columns]
		_tempColumns.forEach(c => c.hide = true)
		_setColumns(_tempColumns)
	}

	const setActiveFilterPopover = (popover: HTMLDivElement | null) => dispatch({ type: GridActionType.setActiveFilterPopover, payload: popover })

	const toggleRowSelect = (row: IGridListItem, selected?: boolean) => dispatch({ type: GridActionType.updateRow, payload: { ...row, selected: selected !== undefined ? selected : !row.selected } })
	const toggleAllRowsSelect = (allRowsSelected?: boolean) => dispatch({ type: GridActionType.toggleAllRowsSelected, payload: allRowsSelected })
	const rowPressed = (row: IGridListItem) => {
		dispatch({ type: GridActionType.rowPressed, payload: row })
		if (state.onRowPress) state.onRowPress(row)
	}
	const setHighlightedRows = (rowIds: string[]) => dispatch({ type: GridActionType.setHighlightedRows, payload: rowIds })
	const deselectAllRows = () => dispatch({ type: GridActionType.deselectAllRows })

	// Asynchronous actions
	const doFetch = async () => await doGridFetch({ ...state.queryState }, otherState)
	const setItemsPerPage = async (itemsPerPage: number) => await doGridFetch({ ...state.queryState, itemsPerPage }, otherState)
	const setSelectedPage = async (selectedPage: number) => await doGridFetch({ ...state.queryState, page: selectedPage }, otherState)
	const setSort = async (sort: ISort) => await doGridFetch({ ...state.queryState, sorts: [sort] }, otherState)
	const addSort = async (colProperty: string) => {
		const sorts = [...state.queryState.sorts || []]
		const existingSort = sorts.find(s => s.property === colProperty)

		let direction: SortDirections = 'ASC'

		if (!existingSort) {
			sorts.push({ property: colProperty, direction })
		} else {
			if (existingSort.direction === 'ASC') direction = 'DESC'
			existingSort.direction = direction
		}
		await doGridFetch({ ...state.queryState, sorts }, otherState)
	}
	const clearSort = async (sort: ISort) => {
		const sorts = state.queryState.sorts?.filter(s => s.property !== sort.property)
		await doGridFetch({ ...state.queryState, sorts }, otherState)
	}
	const updateColumnFilters = async (filters: IFilter[], throttleQuery?: boolean) => {
		/* 
			Check if this update takes us from at least one valid filter to none or visa versa
			This way we can avoid performing a fetch if the side effects of filter editing doesn't produce the need to do a refresh
	
			Note: This is a simple comparison and does not account for someone setting the value of a filter to the same value it currently has
		*/
		// const atLeastOneCurrentFilter = !!state.queryState.filters?.find(f => f.enabled && !isNullOrUndefined(f.value))
		// const atLeastOneNewFilter = !!filters.find(f => f.enabled && !isNullOrUndefined(f.value))

		const _filters = [...state.queryState.filters || []]

		/* 
			Check if each filter already exists, if it does, update it
			If a filter does not exist, add it to the list of filters on the query state
		*/
		filters.forEach(fil => {
			const existingFilter = state.queryState.filters?.find(f => f.id === fil.id)
			if (existingFilter) {
				const filterIdx = _filters.indexOf(existingFilter)

				// If one of the filters being updated is the managed Global Community Context Filter AND it's being disabled, go ahead and delete it entirely.
				if (fil.id === GLOBAL_COMMUNITY_CONTEXT_FILTER_ID && fil.enabled === false) {
					_filters.splice(filterIdx, 1)
				} else {
					_filters.splice(filterIdx, 1, fil)
				}
			} else {
				_filters.push(fil)
			}
		})

		/* 
			Check if any columns had filters disabled, for any column that had filters disabled if at least one new filter is enabled for that column, switch column to enabled
		*/
		const _columnsToUpdate: IGridColumn[] = []
		state.columns.filter(o => o.disableFilters).forEach(c => {
			const _atLeastOneColumnFilterEnabled = filters.filter(o => o.property === c.property && o.enabled).length
			if (_atLeastOneColumnFilterEnabled) {
				_columnsToUpdate.push({ ...c, disableFilters: false })
			}
		})
		if (_columnsToUpdate.length) {
			dispatch({ type: GridActionType.setColumns, payload: _findAndUpdateColumns(state.columns, _columnsToUpdate) })
		}

		// if (atLeastOneCurrentFilter || atLeastOneNewFilter) {
		// 	await doGridFetch({ ...state.queryState, page: 1, filters: _filters }, otherState, throttleQuery)
		// } else {
		// 	dispatch({ type: GridActionType.setQuery, payload: { ...state.queryState, filters: _filters } })
		// }

		/* 
			Turning off the above optimization since it was preventing fetches when filters were being switched from enabled to disabled.
		*/
		await doGridFetch({ ...state.queryState, page: 1, filters: _filters }, otherState, throttleQuery)
	}
	const toggleColumnFilters = async (col: IGridColumn) => {
		const _col = { ...col, disableFilters: !col.disableFilters }
		dispatch({ type: GridActionType.setColumns, payload: _findAndUpdateColumn(state.columns, _col) })

		const _queryState = { ...state.queryState }

		if (_queryState.filters) {
			const _columnFilters = _queryState.filters.filter(f => f.property === _col.property && !isNullOrUndefined(f.value))
			_columnFilters.forEach(f => {
				const fidx = _queryState.filters!.indexOf(f)

				_queryState.filters!.splice(fidx, 1, { ...f, enabled: !_col.disableFilters })
			})
		}

		await doGridFetch(_queryState, otherState)
	}
	const setColumnFilters = async (filters: IFilter[]) => await doGridFetch({ ...state.queryState, filters }, otherState)

	const setQueryState = async (queryState: IQueryState) => await doGridFetch({ ...queryState }, otherState)

	/* 
		Prepare an export of the data in the Grid according to the Grid's current state

		1) Query ALL rows using the current query state (sort, filters)
		2) Prep a CSV using the column state (show/hide, order)
	*/
	const exportData = async (): Promise<string> => {
		let totalCount = state.totalCount || 0

		// 1) get the data
		// Use the selected rows if the user has selected any rows
		let rows = Object.values(state.selectedRows)

		// Otherwise fetch 100% of the records that match the user's query state
		if (rows.length === 0) {
			const _queryState = {
				...state.queryState,
				page: 1,
				itemsPerPage: totalCount + 1 // We use the total count returned from the server to retrieve all the records
			}
			dispatch({ type: GridActionType.loading })
			const data = await state.dataSource(_queryState, otherState)
			rows = data.rows
			dispatch({ type: GridActionType.doneLoading })
		}

		// 2) reorganize the columns to match the current column state
		const columns = state.columns.filter(o => !o.hide && o.type !== 'actions')
		const header = unparse({ fields: columns.map(o => o.title), data: [] }, { header: true })
		const csv = header + unparse(rows.map(o => {
			const values: { [key: string]: any } = { ...o.values }
			console.log('ohhh', o)

			// Flatten any grid item values to a single value, currently we only need worry about arrays
			Object.keys(o.values).forEach(property => {
				const val = o.values[property]
				if (isGridListItemObjectValueTypeArray(val)) {
					values[property] = val.map(_val => _val.display).join(', ')
				} else {
					// The default date format is ISO and Excel doesn't know how to format it.
					// If this is a date value, convert to a format that is consumable by Excel.
					if (val instanceof Date) {
						const dateVal = dayjs(val)
						values[property] = dateVal.format('MM/DD/YYYY hh:mm a')
					}
				}

			})
			return values
		}), { columns: columns.map(o => o.property), header: false })

		return csv
	}

	const updateGridAction = (gridAction: IGridAction) => dispatch({ type: GridActionType.setGridAction, payload: gridAction })

	const toggleUsingLocalData = (usingLocalData: boolean) => dispatch({ type: GridActionType.toggleUsingLocalData, payload: usingLocalData })

	const resetFiltersSortsAndRowSelections = async (filterIdsToPreserve: string[]) => dispatch({ type: GridActionType.resetFiltersSortsAndRowSelections, payload: { filterIdsToPreserve } })

	const saveViewToUserSessionState = async () => {
		if (!state.userSessionStateKey) throw new Error('Cannot save view without a userSessionStateKey.')

		dispatch({ type: GridActionType.loading })
		await appActions.UserSessionActions.updateGridUserInteractionState(
			{
				...getCurrentUserInteractionStateForGrid(),
				queryState: { ...state.queryState },
				columns: gridColumnsToGridColumnUserInteractionState(state.columns)
			},
			state.userSessionStateKey
		)
		dispatch({ type: GridActionType.doneLoading })

		appActions.addToast({
			id: uuidv4(),
			timestamp: new Date(),
			title: 'Success',
			body: 'Saved grid view.',
			autoDismissTimeOut: 1500,
		})
	}

	const resetViewInUserSessionStateToDefault = async () => {
		if (!state.userSessionStateKey) throw new Error('Cannot reset view without a userSessionStateKey.')
		// dispatch({ type: GridActionType.loading })
		await appActions.UserSessionActions.resetGridUserInteractionState(state.userSessionStateKey)

		// Quick and dirty way to ensure view is 100% refreshed to default
		window.location.reload()
	}

	const toggleRowGroupExpandCollapse = (rowGroupId: string) => dispatch({ type: GridActionType.toggleRowGroupExpandCollapse, payload: { rowGroupId } })

	const actions = {
		setLoading,
		setGridDataSource,
		setGridElement,
		updateColumn,
		updateColumns,
		setColumns,
		setActiveFilterPopover,
		showAllColumns,
		hideAllColumns,
		toggleRowSelect,
		toggleAllRowsSelect,
		rowPressed,
		doFetch,
		setItemsPerPage,
		setSelectedPage,
		setSort,
		addSort,
		clearSort,
		updateColumnFilters,
		toggleColumnFilters,
		exportData,
		setRowActions,
		setFooterActions,
		setHighlightedRows,
		setColumnFilters,
		setQueryState,
		deselectAllRows,
		resetFiltersSortsAndRowSelections,
		updateGridAction,
		toggleUsingLocalData,
		saveViewToUserSessionState,
		resetViewInUserSessionStateToDefault,
		toggleRowGroupExpandCollapse,
	}

	return [state, actions]
}


export const defaultPageSizeOptions = [25, 50, 100]
export const defaultGridState: IGridState = {
	// Configuration
	pageSizeOptions: defaultPageSizeOptions,
	pages: 0,
	rowSelectEnabled: true,

	// State
	id: 'default-grid-id', // If this is unchanged in initial setup then grid-actions will override it with a uuid
	loading: false,
	columns: [],
	queryState: {
		page: 1,
		itemsPerPage: defaultPageSizeOptions[1],
	},

	// Data
	dataSource: () => {
		throw new Error('Cannot fetch Grid data - dataSource not set')
	},
	rows: [],
	selectedRows: {},
}