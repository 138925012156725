import { stat } from 'fs'
import React, { useState } from 'react'
import { IStatsDocument } from '../open-api'

interface IEventNewsStats {
	emailStats: IStatsDocument[]
	hideTypeColumn?: boolean
}

export const EventNewsStats = (props: IEventNewsStats) => {
	const { emailStats, hideTypeColumn } = props

	const [state, setState] = useState({
		showPercents: false
	})

	const getCellStyling = (value: number): React.CSSProperties => {
		let backgroundColor = '#dc354520'
		if (value > 30) backgroundColor = '#ffc10720'
		if (value > 60) backgroundColor = '#28a74520'

		return {
			width: `${value}%`,
			backgroundColor
		}
	}

	return (
		<div>
			<div className='d-flex  mb-2'>
				<div>
					These event counts are tracked for the first 30 days after the email is sent. Numbers like "Unique Opens and "Unique Clicks" will change as recipients take action on this email. Data available for 3 years.
				</div>
				<div className='unit-toggle'>
					<div onClick={() => setState({ showPercents: false })} className={state.showPercents ? '' : 'selected'}>#</div>
					<div onClick={() => setState({ showPercents: true })} className={state.showPercents ? 'selected' : ''}>%</div>
				</div>
			</div>
			<table className='table event-news-stats' >
				<thead className='thead-light'>
					<tr>
						{hideTypeColumn ? null : <th className='pl-2'>Type</th>}
						<th>Sent</th>
						<th>Delivered</th>
						<th>Unique Opens</th>
						<th>Unique Clicks</th>
						<th>Bounces</th>
						<th>Bounce Drops</th>
						<th>Invalid Emails</th>
					</tr>
				</thead>
				<tbody>
					{emailStats.map(stat => (
						<tr key={stat.type || ''} style={{ height: 30 }}>
							{hideTypeColumn ? null : <td className='pl-2'><div><div>{stat.type?.slice(0, 1).toUpperCase()}{stat.type?.slice(1)}</div></div></td>}
							{state.showPercents ?
								<React.Fragment>
									<td><div><div>{stat.metrics.requests}</div></div></td>
									<td><div style={getCellStyling(stat.metrics.delivered === 0 ? 0 : 100 * stat.metrics.delivered / stat.metrics.requests)}><div>{(stat.metrics.delivered === 0 ? 0 : stat.metrics.delivered / stat.metrics.requests * 100).toFixed(2)}%</div></div></td>
									<td><div style={getCellStyling(stat.metrics.uniqueOpens === 0 ? 0 : 100 * stat.metrics.uniqueOpens / stat.metrics.delivered)}><div>{(stat.metrics.uniqueOpens === 0 ? 0 : stat.metrics.uniqueOpens / stat.metrics.delivered * 100).toFixed(2)}%</div></div></td>
									<td><div style={getCellStyling(stat.metrics.uniqueClicks === 0 ? 0 : 100 * stat.metrics.uniqueClicks / stat.metrics.delivered)}><div>{(stat.metrics.uniqueClicks === 0 ? 0 : stat.metrics.uniqueClicks / stat.metrics.delivered * 100).toFixed(2)}%</div></div></td>
									<td><div style={getCellStyling(stat.metrics.bounces === 0 ? 0 : 100 * stat.metrics.bounces / stat.metrics.requests)}><div>{(stat.metrics.bounces === 0 ? 0 : stat.metrics.bounces / stat.metrics.requests * 100).toFixed(2)}%</div></div></td>
									<td><div style={getCellStyling(stat.metrics.bounceDrops === 0 ? 0 : 100 * stat.metrics.bounceDrops / stat.metrics.requests)}><div>{(stat.metrics.bounceDrops === 0 ? 0 : stat.metrics.bounceDrops / stat.metrics.requests * 100).toFixed(2)}%</div></div></td>
									<td><div style={getCellStyling(stat.metrics.invalidEmails === 0 ? 0 : 100 * stat.metrics.invalidEmails / stat.metrics.requests)}><div>{(stat.metrics.invalidEmails === 0 ? 0 : stat.metrics.invalidEmails / stat.metrics.requests * 100).toFixed(2)}%</div></div></td>
								</React.Fragment>
								:
								<React.Fragment>
									{/* Extra <div/>s to match CSS. Could change with classnames but eh */}
									<td><div><div>{stat.metrics.requests}</div></div></td>
									<td><div><div>{stat.metrics.delivered}</div></div></td>
									<td><div><div>{stat.metrics.uniqueOpens}</div></div></td>
									<td><div><div>{stat.metrics.uniqueClicks}</div></div></td>
									<td><div><div>{stat.metrics.bounces}</div></div></td>
									<td><div><div>{stat.metrics.bounceDrops}</div></div></td>
									<td><div><div>{stat.metrics.invalidEmails}</div></div></td>
								</React.Fragment>
							}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}