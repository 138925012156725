import { useState } from "react"
import { Popup } from "../popup"
import clsx from 'clsx'
import { FormikSelectField } from "./formik-select-field"
import { NTEE_CODES_MAJOR, NTEE_CODES_MINOR_Religion_Related } from "../../constants"
import { ChevronDownIcon } from "../../assets"
import { FormikProps } from "formik"


const selectAllNteeMinorReligionCodes = (_formikProps: FormikProps<any>) => {
    console.log('selectAllNteeMinorCodes clicked')
    console.log('_formikProps.values.nteeMinorCodes', _formikProps.values.nteeMinorCodes)
    const allCodes:string[] = []
    NTEE_CODES_MINOR_Religion_Related.forEach(x => allCodes.push(x.value));
    console.log('allCodes', allCodes)
    _formikProps.setFieldValue('nteeMinorCodes', allCodes)
}

interface IPopperSelectFieldProps {
    children?: React.ReactNode
    formikProps: FormikProps<any>
}

export const PopperSelectFieldNteeMinor = (props: IPopperSelectFieldProps) => {
    const { children, formikProps } = props
    const [open, setIsOpen] = useState(false)

    return (
      <Popup
        trigger={
          <button
            className={
              'btn btn-outline-secondary' +
              clsx(formikProps.values.nteeMinorCodes.length > 0 ? ' active' : ' ')
            }
            type='button'
            // style={{
            //   width: '100%',
            // }}
          >
            {formikProps.values.nteeMinorCodes.length === 0 ? 'NTEE Minor Codes' : `${formikProps.values.nteeMinorCodes.length} NTEE Minor Codes`} <ChevronDownIcon />
          </button>
        }
        onToggle={setIsOpen}
        popupOptions={{
          placement: 'bottom-start',
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 5]
                  }
              }
          ]
        }}
      >
        <div
            className="p-3"
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '.25rem',
            width: '400px',
            overflow: 'scroll',
            //maxHeight: '300px',
            overflowX: 'hidden'
          }}
        >
          <FormikSelectField
            multiple={true}
            field={{
              name: 'nteeMinorCodes',
              label: 'NTEE Minor Codes',
            }}
            optionGroups={NTEE_CODES_MAJOR.map((x) => ({
              id: x.value,
              label: x.label,
              options: x.options,
            }))}
          />
          <div>
            <a
                href='#'
                onClick={() =>
                selectAllNteeMinorReligionCodes(formikProps)
                }
                style={{
                position: 'relative',
                // top: '-20px',
                }}
            >
                Select Religion-Related NTEE Codes
            </a>
            </div>
        </div>
      </Popup>
    )
}