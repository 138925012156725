import { IEventNewsSettingDocument, INewsletterMonthDocument } from "../open-api"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useContext, useEffect, useState } from "react"
import { SelectField, TinyMceField } from "./forms"
import moment from "moment"

const monthYearDropdownDateFormat = 'MMM YYYY'

interface IEventNewsSettingFormProps {
    settingToEdit?: IEventNewsSettingDocument
    afterSave: () => Promise<void>
}

export const EventNewsSettingForm = (props: IEventNewsSettingFormProps) => {
    const { settingToEdit, afterSave } = props
    const { EventNewsSettingApi, EventNewsApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    console.log('settingToEdit', settingToEdit)

    const initialValues = {
        monthYear: !!settingToEdit ? moment(`${settingToEdit.month} ${settingToEdit.year}`, 'M YYYY').format(monthYearDropdownDateFormat) : '',
        globalMessage: settingToEdit?.globalMessage || '',
    }

    const validationSchema = Yup.object({
        monthYear: Yup.string().required('Required')
    })

    const [availableNewsletterMonths, setAvailableNewsletterMonths] = useState<INewsletterMonthDocument[]>()
	const [loadingAvailableNewslettersMonths, setLoadingAvailableNewslettersMonths] = useState(false)
    const fetchAvailableNewsletterMonths = async () => {
		setAvailableNewsletterMonths(undefined)
		setLoadingAvailableNewslettersMonths(true)
        const branchId = 0 // Will return all future month/year values
		const availableNewsletterMonthsQuery = await makeHttpRequestWithUi({
			request: EventNewsApi.apiMiBranchesBranchIdAvailableNewsletterMonthsGet(branchId),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error getting available newsletter months for this community.',
		})

		setAvailableNewsletterMonths(availableNewsletterMonthsQuery.data)
		setLoadingAvailableNewslettersMonths(false)
	}

    useEffect(() => {
		fetchAvailableNewsletterMonths()
	}, [])

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                console.log('submitted')
                if (settingToEdit) {
                    await makeHttpRequestWithUi({
                        request: EventNewsSettingApi.apiEventNewsSettingIdPut( settingToEdit.eventNewsSettingId, {
                            eventNewsSettingId: settingToEdit.eventNewsSettingId,
                            globalMessage: values.globalMessage || '',
                        }),
                        toastSuccessMessage: 'Successfully updated stipulation.',
                        toastErrorMessage: 'There was an error updating stipulation.'
                    })
                } else {
                    await makeHttpRequestWithUi({
                        request: EventNewsSettingApi.apiEventNewsSettingPost({
                            month: moment(values.monthYear, monthYearDropdownDateFormat).month() + 1,
                            year: moment(values.monthYear, monthYearDropdownDateFormat).year(),
                            globalMessage: values.globalMessage || '',
                        }),
                        toastSuccessMessage: 'Successfully created stipulation.',
                        toastErrorMessage: 'There was an error creating stipulation.'
                    })
                }

                afterSave && afterSave()
            }}
        >
            {formikProps => {
                return (
                    <Form style={{ padding: 10 }}>
                        <SelectField
                            disabled={!!settingToEdit}
                            fieldProps={{ name: 'monthYear', label: 'Month', placeholder: 'Select a month...' }}
                            options={settingToEdit ?
                                [{
                                    label: moment(`${settingToEdit.month} ${settingToEdit.year}`, 'M YYYY').format(monthYearDropdownDateFormat),
                                    value: moment(`${settingToEdit.month} ${settingToEdit.year}`, 'M YYYY').format(monthYearDropdownDateFormat)
                                }]
                                :
                                availableNewsletterMonths?.map(o => {
                                    const value = moment(`${o.month} ${o.year}`, 'M YYYY').format(monthYearDropdownDateFormat)
                                    return { value, label: value }
                                })
                            }
                        />
                         <TinyMceField fieldProps={{ name: 'globalMessage', label: 'Global Message' }} />
                        <button type="submit" style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button>
                    </Form>
                )
            }}
        </Formik>
    )
}