import React, { useState, useEffect } from "react"
import { registerLoadingListener } from '../services/loading-service'

export enum LoadingPropsSizeEnum {
	micro,
	small,
	medium,
	large
}
interface ILoadingProps {
	size?: LoadingPropsSizeEnum
	padding?: boolean
	position?: 'absolute' | 'fixed' | 'sticky'
}
export const Loading = (props: ILoadingProps) => {
	const [loadingTime, setLoadingTime] = useState(0)

	useEffect(() => {
		let totalTime = 0
		const interval = setInterval(() => {
			totalTime += 100
			setLoadingTime(totalTime)
		}, 100)

		return function cleanup() {
			clearInterval(interval)
		}

		// eslint-disable-next-line
	}, [])

	const getSizeClass = () => {
		switch (props.size) {
			case LoadingPropsSizeEnum.micro:
				return "micro"
			case LoadingPropsSizeEnum.small:
				return "small"
			case LoadingPropsSizeEnum.medium:
				return "medium"
			case LoadingPropsSizeEnum.large:
				return "large"
			default:
				return "medium"
		}
	}

	return (
		<div className={`loading full-height ${props.padding ? "padding" : ""}`.trim()}>
			{loadingTime > 200 ? (
				<img
					className={getSizeClass()}
					src={`${window.location.origin}/images/loading.svg`}
					alt="loading"
				/>
			) : (
					<></>
				)}
		</div>
	)
}


export const LoadingOverlay = (props: ILoadingProps) => {
	return (
		<div className={`loading-overlay ${props.position}`}>
			<Loading {...props} />
		</div>
	)
}


export const AppLoadingOverlay = () => {
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		registerLoadingListener(setLoading)
	}, [])
	return (
		<div className={`loading-overlay fixed ${loading ? "" : "hidden"}`}>
			<Loading />
		</div>
	)
}