import { IRecipientSearchResultDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class EventNewsRecipientModel {
    public static toGridListItem = (eventNewsRecipient: IRecipientSearchResultDocument): IGridListItem => {
        return {
            id: eventNewsRecipient.mailBlastContactId.toString(),
            values: {
                ...eventNewsRecipient,
            },
        }
    }
} 