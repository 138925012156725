import { Form, Formik } from "formik"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { AppActionContext } from "../app-store-provider"
import { IGrantRequestSummaryDocument, IMinistryGrantStipulationModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { CheckboxField } from "./forms"
import { FormikSelectField } from "./forms/formik-select-field"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { TooltipInfo } from "./partials"
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { Grid } from "./grid"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { DefaultGridCellDisplay } from "./grid-cell-displays"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { GRANT_REQUEST_STATUS, GRANT_TYPE } from "../constants"

interface IGrantRequestFormVerifyProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    stipulations: IMinistryGrantStipulationModelDocument[]
    setActiveParentTab: Dispatch<SetStateAction<string>>
    afterSave?: () => Promise<void>
}

export const GrantRequestFormVerify = (props: IGrantRequestFormVerifyProps) => {
    const { grantRequestToEdit, afterSave, stipulations, setActiveParentTab } = props
    const { GrantRequestApi } = useContext(AppActionContext)!

    //const [verificationData, setVerificationData] = useState<IVerificationDataModelDocument>()
    //const [verifiedStipulationIds, setVerifiedStipulationIds] = useState<number[]>([])

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    // const fetchVerificationData = async () => {
    //     const {data} = await makeHttpRequestWithUi({
    //         request: GrantRequestApi.apiGrantRequestGrantIdVerificationDataGet(grantRequestToEdit.grantId),
    //         disableSuccessToast: true,
    //         toastErrorMessage: 'There was an error retrieving the verification data.'
    //     })

    //     setVerificationData(data)
    // }

    const dataSource: GridDataFetch<IMinistryGrantStipulationModelDocument[]> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.grantStipulationId!.toString(),
                values: {
                    ...o,
                },
                selected: o.bGtoVerified || false
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('GrantRequestStipulations > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    // useEffect(() => {
    //     fetchVerificationData()
    // }, [])

    const getAccountStatusElement = (profileStatus:string) => {
        const className = profileStatus === 'OK' ? 'text-success' : 'text-danger'
         
        return <a href="" className={className}>{profileStatus} (View Profile)</a>
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        columns: [
            {
                property: 'stipulation',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Stipulation',
                render: DefaultGridCellDisplay,
            }

        ],
        dataSource,
        rowSelectEnabled: true,
        usingLocalData: true,
        disabledPagination: true,
        disableExport: true,
        respectGlobalCommunityFilter: false,
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, stipulations ?? [])

    const readonly = grantRequestToEdit.isLegacyGrant ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Complete"] || 
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Denied"] ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Request Tabled"]

    useEffect(() => {
        gridActions.doFetch()
    }, [stipulations])

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    approvedSignedLetters: grantRequestToEdit.approvedSignedLetters,
                    grantFeedbackReportVerified: grantRequestToEdit.feedbackApproved,
                    profileStatus: grantRequestToEdit.profileStatus,
                    metObjectiveID: grantRequestToEdit.metObjectiveID,
                    metObjectiveComments: grantRequestToEdit.metObjectiveComments || ''
                }}
                // validationSchema={Yup.object({
                //     adRecommendedAmount: Yup.number().min(1, 'Required').required('Required'),
                //     adFundUsePlan: Yup.string().required('Required'),
                //     adMatchingPlan: Yup.string().required('Required'),
                //     adNotes: Yup.string().required('Required'),
                //     adRatingCriteria: Yup.number().min(1, 'Select a rating value.').required('Required'),
                //     adRatingBenefit: Yup.number().min(1, 'Select a rating value.').required('Required'),
                //     adRatingImpact: Yup.number().min(1, 'Select a rating value.').required('Required'),
                //     adRatingLeadership: Yup.number().min(1, 'Select a rating value.').required('Required'),
                // })}
                onSubmit={async (values) => {
                    await makeHttpRequestWithUi({
                        request: GrantRequestApi.apiGrantRequestGrantIdVerificationDataPut(grantRequestToEdit.grantId, {
                            grantId: grantRequestToEdit.grantId,
                            approvedSignedLetters: values.approvedSignedLetters || false,
                            grantFeedbackReportVerified: values.grantFeedbackReportVerified || false,
                            metObjectiveID: values.metObjectiveID || null,
                            metObjectiveComments: values.metObjectiveComments,
                            verifiedStipulationIds: gridState.rows.filter(o => o.selected).map(o => parseInt(o.id))
                        }),
                        toastSuccessMessage: 'Successfully submitted request.',
                        toastErrorMessage: 'Error submitting request.',
                        disableLoading: false,
                    })

                    afterSave && afterSave()
                }}
            >
                {formikProps => {
                    return (
                        <Form id="adInfoForm" className='d-flex flex-column mx-2'>
                            {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                                You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                            <div className="mt-4 mb-4">
                                {!readonly && <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2'>Save</button>}
                            </div>
                            { grantRequestToEdit.isLegacyGrant && <p>This is a legacy grant, so the information you will see under this tab is limited.</p>}
                            { !grantRequestToEdit.isLegacyGrant && <CheckboxField 
                                fieldProps={{ name: 'approvedSignedLetters', 
                                    label: <>I have verified that the <a href='#' onClick={() => setActiveParentTab('documents')}>{grantRequestToEdit.grantTypeId != GRANT_TYPE.Managed ? 'Grant Agreement and' : ''} LOA documents</a> have been signed.</> }}
                                disabled={formikProps.values.approvedSignedLetters} /> }
                            { grantRequestToEdit.feedbackRequired && <CheckboxField 
                                fieldProps={{ name: 'grantFeedbackReportVerified', label: <>I have read the <a href={grantRequestToEdit.feedbackReportUrl || '#'} target="_blank">Grant Feedback Report</a> and approve it.</> }}
                                disabled={formikProps.values.grantFeedbackReportVerified} /> }
                            <div className="mt-4 mb-4">
                                Profile Status <TooltipInfo tooltipText={'The ministy\'s account status must be OK in order to complete the verification process.'} />: {getAccountStatusElement(formikProps.values.profileStatus || '')}
                            </div>
                            { ((grantRequestToEdit.isLegacyGrant && grantRequestToEdit.metObjectiveID) || grantRequestToEdit.feedbackRequired) && <FormikSelectField 
                                field={{ name: 'metObjectiveID', label: 'Did the ministry meet their Matching Plan targets?', disabled: readonly }}
                                // @ts-ignore
                                options={[
                                    {value: '4', label: 'All'},
                                    {value: '3', label: 'Most'},
                                    {value: '2', label: 'Some'},
                                    {value: '1', label: 'None'}
                                ]} /> }
                            <FormikTextAreaField 
                                field={{ name: 'metObjectiveComments', label: 'Area Director closing comments',
                                    //labelTooltip: "Do you have any comments related to the ministry meeting their matching plan targets?" 
                                    disabled: readonly}} />
                            {stipulations.length > 0 && 
                            <>
                                <div>
                                    I have verified the following stipulations have been met
                                </div>
                                <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                                    <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
                                </div>
                            </>}
                            {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                                You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                            <div className="mt-4 mb-4">
                                {!readonly && <>
                                    <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2'>Save</button>
                                    <button type='button' onClick={formikProps.handleReset} style={{ width: 100 }} className='btn btn-secondary mr-2' disabled={!formikProps.dirty}>Cancel</button>
                                </>}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>)
}