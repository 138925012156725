import { Link } from '@reach/router'
import {
  ChangeEvent,
  useContext,
  useState,
} from 'react'
import { IDefaultProps } from './component-definitions'
import { MapComponent } from './map-component'
import { FormikTextField } from './forms/formik-text-field'
import { Form, Formik, FormikProps } from 'formik'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { AppActionContext } from '../app-store-provider'
import {
  IMinistrySearchRequestFilterByEventsDocument,
  INonprofitMapGetResponseModelDocument,
  INonprofitResultDocument,
  ISearchFilterDocument,
} from '../open-api'
import Helmet from 'react-helmet'
import { MapIncomePopper } from './map-income-popper'
import { MapNteePopper } from './map-ntee-popper'
import { MapMorePopper } from './map-more-popper'
import '../styles/maps.scss'
import { MapCommunityPopper } from './map-community-popper'
import { IEventFilter, IFilter, ISort } from '../stores/api-actions'
import { MapEventsPopper } from './map-events-popper'
import { unparse } from 'papaparse'
import { IGridColumn, isGridListItemObjectValueTypeArray } from '../stores/grid-definitions'
import dayjs from 'dayjs'
import { download } from '../services/helpers'
import { DefaultGridCellDisplay } from './grid-cell-displays'
import { SelectField } from './forms'

export const NonprofitMap = ({}: IDefaultProps) => {
  const { NonprofitMapApi } = useContext(AppActionContext)!
  const center = { lat: 45.426782, lng: -122.7498582 }
  const zoom = 10
  
  let _formikProps: FormikProps<any> | undefined
  
  const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
  const [searchResponse, setSearchResponse] = useState<INonprofitMapGetResponseModelDocument>()
  const [formValues, setFormValues] = useState<any>()
  const [sortState, setSortState] = useState<ISort[]>([
      {
          property: 'orgName',
          direction: 'ASC'
      }
  ])
  const [eventsFilter, setEventsFilter] = useState<IFilter>({
    		id: 'ministry-management-filter-by-events',
    		operator: 'filter-by-event',
    		property: 'ministry-management-filter-by-events',
    		filterByEventOptions: {},
    		value: 'ministry-management-filter-by-events',
    	})

  const fetchRecords = async (
    searchPostalCode: string,
    radiusInMiles: number,
    includeMinistries: boolean, 
    includeOrphans: boolean,
    filters: ISearchFilterDocument[],
    sorts: ISort[],
    filterByEvents: IMinistrySearchRequestFilterByEventsDocument
  ) => {

    const query = await makeHttpRequestWithUi({
      request: NonprofitMapApi.apiNonprofitMapPost({
        postalCode: searchPostalCode,
        latitude: null,
        longitude: null,
        radiusInMiles,
        includeMinistries, 
        includeOrphans,
        filters,
        sorts,
        filterByEvents
      }),
      disableSuccessToast: true,
      toastErrorMessage: 'There was a problem fetching search results.',
    })

    setSearchResponse(query.data)
  }

  // function getZoom(radiusInMiles: any): number {
  //   if (radiusInMiles < 25) return 11
  //   if (radiusInMiles < 50) return 10
  //   if (radiusInMiles < 100) return 9
  //   if (radiusInMiles < 151) return 8

  //   return zoom
  // }

  // useEffect(() => {
  //   console.log('formValues', formValues)
  // }
  // , [formValues])

  const buildContent = (nonprofit: INonprofitResultDocument) => {
      return <div className='info-box' onClick={() => {
        console.log('ib-click')
        const elId = `.ib-${nonprofit.orgId}-${nonprofit.orgTypeId}`
        const el = document.querySelector(elId)
        if (el) {
          console.log('el found: ' + elId)
          el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          el.classList.add('item-highlight')
        } else {  
          console.log('el NOT found: ' + elId)
        }
      }}>
        <div style={{fontWeight: 'bold', fontSize: '14px'}}>{nonprofit.orgName}</div>
        <div>
          <div>Revenue <b>{nonprofit.incomeFormatted}</b></div>
        </div>
      </div>
  }

  const exportData = async (): Promise<string> => {

    if (searchResponse?.nonprofits) {
      const columns: IGridColumn[] = [
        {
          property: 'branchAbbr',
          title: 'Community',
          type: 'string',
          render: DefaultGridCellDisplay,
          width: 100
        },
        {
          property: 'orgId',
          type: 'number',
          width: 100,
          title: 'Org ID',
          render: DefaultGridCellDisplay,
          description: 'Our unique Database ID for this ministry record.'
        },
        {
          property: 'orgName',
          title: 'Nonprofit Name',
          type: 'string',
          render: DefaultGridCellDisplay,
          width: 100
        },
        {
          property: 'federalTaxNo',
          title: 'Tax ID',
          type: 'string',
          render: DefaultGridCellDisplay,
          width: 100
        },
        {
          property: 'bArchived',
          title: 'IsArchived',
          type: 'boolean',
          render: DefaultGridCellDisplay,
          width: 100
        },
        {
          property: 'income',
          title: 'Revenue',
          type: 'number',
          render: DefaultGridCellDisplay,
          width: 100
        },
        {
          property: 'involvement',
          type: 'number',
          width: 115,
          title: 'Involvement',
          render: DefaultGridCellDisplay,
        },
        {
          property: 'physicalAddress1',
          type: 'string',
          width: 225,
          title: 'Address',
          render: DefaultGridCellDisplay,
        },
        {
          property: 'physicalAddress2',
          type: 'string',
          width: 128,
          title: 'Address Line 2',
          render: DefaultGridCellDisplay,
        },
        {
          property: 'physicalCity',
          type: 'string',
          width: 100,
          title: 'City',
          render: DefaultGridCellDisplay,
        },
        {
          property: 'physicalState',
          type: 'string',
          width: 70,
          title: 'State',
          render: DefaultGridCellDisplay,
        },
        {
          property: 'physicalPostalCode',
          type: 'string',
          width: 77,
          title: 'Postal Code',
          render: DefaultGridCellDisplay,
        },
        {
          property: 'physicalCountry',
          type: 'string',
          width: 100,
          title: 'Country',
          render: DefaultGridCellDisplay,
        },
      ]
      // 2) reorganize the columns to match the current column state
      const header = unparse({ fields: columns.map(o => o.title), data: [] }, { header: true })
      const csv = header + unparse(searchResponse.nonprofits.map(o => {
        const values: { [key: string]: any } = { ...o }
        //const values: { [key: string]: any } = { ...o.values }
        console.log('ohhh', o)
  
        // Flatten any grid item values to a single value, currently we only need worry about arrays
        Object.keys(o).forEach(property => {
          const val: any = values[property]
          if (isGridListItemObjectValueTypeArray(val)) {
            values[property] = val.map(_val => _val.display).join(', ')
          } else {
            // The default date format is ISO and Excel doesn't know how to format it.
            // If this is a date value, convert to a format that is consumable by Excel.
            if (val instanceof Date) {
              const dateVal = dayjs(val)
              values[property] = dateVal.format('MM/DD/YYYY hh:mm a')
            }
          }
  
        })
        return values
      }), { columns: columns.map(o => o.property), header: false })

      return csv
    }

    return ''
	}

  const onDownloadClick = async () => {

    if (!searchResponse?.nonprofits) {
      alert('Nothing to export.')

      return
    }

    if (window.confirm("Would you like to export these results to a CSV file?")) {
      const csv = await exportData()

		  download(`MI Export ${dayjs().format('MM_DD_YYYY')}.csv`, csv)
    }
	}

  const handleSortChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    console.log('handleSortChange > event.target.value', event.target.value)
    setSortState([
      {
          property: event.target.value,
          direction: 'DESC'
      }
    ])
    
    if (_formikProps) _formikProps.submitForm()
  }

  return (
    <>
      <Helmet>
        <title>Nonprofit Map [BETA]</title>
      </Helmet>
      <div className='d-flex flex-column' style={{ height: '100vh', width: '100%' }}>
        <div className='d-flex flex-row'>
          <Formik
            initialValues={{
              searchPostalCode: '',
              radiusInMiles: '',
              nteeCodes: [],
              active: true,
              archived: false,
              orphaned: false,
              teaching: true,
              coaching: true,
              consulting: true,
              incomeMin: 0,
              incomeMax: 0,
              invMin: 0,
              invMax: 0,
              workshopAttendanceMos: '',
              branchIds: [],
              eventsRegisteredFor: [],
              registeredForEverySelectedEvent: true,
              eventsOnlyIncludeMinistriesThatAttended: true,
              eventsNotRegisteredFor: [],
              consultingRegisteredFor: [],
              registeredForEverySelectedConsulting: true,
              consultingNotRegisteredFor: [],
              consultingOnlyIncludeMinistriesThatAttended: true,
            }}
            onSubmit={async (values) => {
              let includeMinistries = true;
              let includeOrphans = values.orphaned;

              const filters: ISearchFilterDocument[] = []
              if (!values.searchPostalCode && !values.branchIds.length && !(values.eventsRegisteredFor.length || values.consultingRegisteredFor.length || values.eventsNotRegisteredFor.length || values.consultingNotRegisteredFor.length)) {
                alert('Please specify a Postal Code or select a Community or select an Event.')
                return
              }

              const radiusInMiles = values.radiusInMiles ? parseInt(values.radiusInMiles) : 10

              if (radiusInMiles > 150) {
                alert('Please reduce your search radius. Maximum radius to you can search is 150 miles.')
                return
              }

              if (values.nteeCodes.length > 0) {
                filters.push({
                  value: values.nteeCodes,
                  operator: 'like',
                  property: 'nteeCodes',
                })
              }

              if (values.incomeMin > 0) {
                filters.push({
                  value: [values.incomeMin.toString()],
                  operator: 'gt',
                  property: 'income',
                })
              }

              if (values.incomeMax > 0) {
                filters.push({
                  value: [values.incomeMax.toString()],
                  operator: 'lt',
                  property: 'income',
                })
              }

              if (values.invMin > 0) {
                filters.push({
                  value: [values.invMin.toString()],
                  operator: 'gt',
                  property: 'involvement',
                })
              }

              if (values.invMax > 0) {
                filters.push({
                  value: [values.invMax.toString()],
                  operator: 'lt',
                  property: 'involvement',
                })
              }

              if (values.active === true && values.archived === false) {
                filters.push({
                  value: ['false'],
                  operator: '==',
                  property: 'bArchived',
                })
              } else if (values.active === false && values.archived === true) { 
                filters.push({
                  value: ['true'],
                  operator: '==',
                  property: 'bArchived',
                })
              } else if (values.active === false && values.archived === false) {
                includeMinistries = false;
              }

              if (values.teaching === true && values.coaching === true && values.consulting === true) {
                // do nothing
              } else {
                const levelFilter: ISearchFilterDocument = {
                  value: [],
                  operator: 'in',
                  property: 'levelId',
                }
                if (values.teaching === true) {
                  levelFilter.value?.push('1')
                }
                if (values.coaching === true) {
                  levelFilter.value?.push('2')
                }
                if (values.consulting === true) {
                  levelFilter.value?.push('3')
                }

                filters.push(levelFilter)
              }

              if (values.workshopAttendanceMos) {
                filters.push({
                  value: [values.workshopAttendanceMos],
                  operator: 'lt',
                  property: 'workshopAttendanceMos',
                })
              }

              if (values.branchIds && values.branchIds.length > 0) {
                filters.push({
                  value: values.branchIds,
                  operator: 'in',
                  property: 'branchId',
                })
              }

              const filterByEventOptions: IEventFilter = {
                ...eventsFilter.filterByEventOptions
              }
    
              if (values.eventsRegisteredFor.length > 0) {
                filterByEventOptions.eventsRegisteredFor = {
                  eventIds: values.eventsRegisteredFor.map(o => parseInt(o)),
                  registeredForEverySelectedEvent: values.registeredForEverySelectedEvent,
                  onlyIncludeMinistriesThatAttended: values.eventsOnlyIncludeMinistriesThatAttended
                }
              }
    
              if (values.eventsNotRegisteredFor.length > 0) {
                filterByEventOptions.eventsNotRegisteredFor = {
                  eventIds: values.eventsNotRegisteredFor.map(o => parseInt(o))
                }
              }
    
              if (values.consultingRegisteredFor) {
                filterByEventOptions.consultingRegisteredFor = {
                  consultingQuarters: values.consultingRegisteredFor,
                  registeredForEverySelectedQuarter: values.registeredForEverySelectedConsulting,
                  onlyIncludeMinistriesThatAttended: values.consultingOnlyIncludeMinistriesThatAttended
                }
              }
    
              if (values.consultingNotRegisteredFor) {
                filterByEventOptions.consultingNotRegisteredFor = {
                  consultingQuarters: values.consultingNotRegisteredFor
                }
              }

              const filterByEvents: IMinistrySearchRequestFilterByEventsDocument = {
                registeredFor: filterByEventOptions?.eventsRegisteredFor?.eventIds ?? null,
                notRegisteredFor: filterByEventOptions?.eventsNotRegisteredFor?.eventIds ?? null,
                onlyIfAttended: filterByEventOptions?.eventsRegisteredFor?.onlyIncludeMinistriesThatAttended ?? null,
                onlyIfRegisteredForAll: filterByEventOptions?.eventsRegisteredFor?.registeredForEverySelectedEvent ?? null,
                registeredForConsulting: filterByEventOptions?.consultingRegisteredFor?.consultingQuarters ?? null,
                notRegisteredForConsulting: filterByEventOptions?.consultingNotRegisteredFor?.consultingQuarters ?? null,
                onlyIfAttendedConsulting: filterByEventOptions?.consultingRegisteredFor?.onlyIncludeMinistriesThatAttended ?? null,
                onlyIfRegisteredForAllConsulting: filterByEventOptions?.consultingRegisteredFor?.registeredForEverySelectedQuarter ?? null,
              }

              setFormValues(values)
              fetchRecords(values.searchPostalCode, radiusInMiles, includeMinistries, includeOrphans, filters, sortState, filterByEvents)
            }}
          >
            {(formikProps) => {
              _formikProps = formikProps
              return (
                <Form className='ml-2'>
                  <div className={'d-flex'}>
                    <div className='mr-2'>
                      <FormikTextField
                        field={{
                          name: 'searchPostalCode',
                          label: '',
                          placeholder: 'Postal Code',
                        }}
                      />
                    </div>
                    <div className='mr-2'>
                      <FormikTextField
                        field={{
                          name: 'radiusInMiles',
                          label: '',
                          placeholder: '10 mile radius',
                        }}
                      />
                    </div>
                    <div className='mr-2'>
                      <div style={{position: 'relative', top: '24px'}}><MapCommunityPopper formikProps={_formikProps} /></div>
                    </div>
                    <div className='mr-2'>
                      <div style={{position: 'relative', top: '24px'}}><MapNteePopper formikProps={_formikProps} /></div>
                    </div>
                    <div style={{position: 'relative', top: '24px'}} className='mr-2'>
                      <MapIncomePopper formikProps={_formikProps} />
                    </div>
                    <div style={{position: 'relative', top: '24px'}} className='mr-2'>
                      <MapEventsPopper 
                        formikProps={_formikProps}
                       />
                    </div>
                    <div style={{position: 'relative', top: '24px'}} className='mr-2'>
                      <MapMorePopper formikProps={_formikProps} />
                    </div>
                    <div style={{position: 'relative', top: '24px'}} className='mr-2'>
                      <button className='btn btn-primary mr-3' type='submit'>
                        Search
                      </button>
                    </div>
                    <div style={{position: 'relative', top: '24px'}} className='mr-2'>
                      <button className='btn btn-secondary' type='button' onClick={() => formikProps.resetForm()}>
                        Reset
                      </button>
                    </div>
                    <div style={{position: 'relative', top: '24px'}} className='mr-2'>
                      <button className='btn btn-secondary' type='button' onClick={onDownloadClick}>
                        Export
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
        <div className='d-flex flex-row' style={{height: '100vh'}}>
          <div style={{height: '100%', width: '650px', overflow: 'auto'}}>
            {searchResponse ? <>
              <h5 className='ml-3'>{formValues.searchPostalCode} Postal Code</h5>
              <div className='ml-3 d-flex flex-row'>
                <div style={{'fontSize': '0.85.rem'}}>{searchResponse.nonprofits?.length} Nonprofits</div>
                <div className='ml-4'>Sort by <select name="sortBy" id="sortBy" onChange={handleSortChange}>
                    <option value="orgName">Nonprofit Name</option>
                    <option value="involvement">Involvement score</option>
                  </select></div>
              </div>
              {searchResponse.nonprofits?.map(
                (x: INonprofitResultDocument) => (
                  <div className={`ib-${x.orgId}-${x.orgTypeId} card m-3`} key={`${x.orgTypeId}-${x.orgId}`}>
                    <div className="card-body">
                      <div><span className='badge rounded-pill badge-info'>{x.branchAbbr}</span>{x.bArchived === true ? <span className='badge rounded-pill badge-warning m-2'>Archived</span> : null}</div>
                      <h5 className="card-title"><a href={`/ministry-info/${x.orgId}`} target='_blank'>
                        {x.orgName}
                      </a></h5>
                      <div className='d-flex'>
                        <div style={{ flex: 1 }} className='mr-2'><b>Revenue</b><br />{x.incomeFormatted} ({x.incomeYear})</div>
                        <div style={{ flex: 1 }}><b>NTEE</b><br />{x.nteeCodes}</div>
                        <div style={{ flex: 1 }}><b>Inv. Score</b><br />{x.involvement}</div>
                      </div>
                      <div className='mt-2'>{x.physicalAddress1} {x.physicalCity}, {x.physicalStateAbbr} {x.physicalPostalCode}</div>
                    </div>
                  </div>
                )
              )}
              </>
              :
              <div className='ml-2'>Search by Postal Code or Community or Events.</div>}
          </div>
          <div style={{width: '100%'}}>
            <MapComponent
              center={
                searchResponse
                  ? {
                      lat: searchResponse.centerLat,
                      lng: searchResponse.centerLng,
                    }
                  : center
              }
              zoom={
                searchResponse ? searchResponse.zoom : zoom
              }
              locations={
                searchResponse?.nonprofits
                  ? searchResponse.nonprofits.map((x) => ({
                      key: `${x.orgTypeId}-${x.orgId}`,
                      location: { lat: x.latitude, lng: x.longitude },
                      content: buildContent(x),
                    }))
                  : []
              }
              circleRadius={
                searchResponse?.radiusInMiles
                  ? searchResponse.radiusInMiles * 1609.34 : undefined}
            />
          </div>
        </div>
      </div>
    </>
  )
}
