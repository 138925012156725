import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IEventNewsDocument, IRecipientSearchResultDocument, IStatsDocument } from '../open-api'
import { filterGridListItems, sortGridListItemsBySorts, openUrlInNewTab } from '../services/helpers'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { IAppState } from '../stores/app-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridRowAction, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { IDefaultProps } from './component-definitions'
import { Grid } from './grid'
import { Modal } from './modal'
import { SquareDeleteIcon } from './partials'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { ReactComponent as PeopleIcon } from '../assets/people.svg'
import { Helmet } from 'react-helmet'
import { EventNewsModel } from '../models/event-news'
import { EventNewsItemAfterSaveAction, EventNewsItemForm } from './event-news-item-form'
import { DefaultGridCellDisplay, GridActionCell } from './grid-cell-displays'
import { ReactComponent as EyeIcon } from '../assets/eye.svg'
import { ReactComponent as FileSpreadsheetIcon } from '../assets/file-spreadsheet.svg'
import { EmailField } from './forms'
import { EventNewsCoachingInvitationSetting } from './event-news-coaching-invitation-setting'
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { EventNewsRecipients } from './event-news-recipients'
import { EventNewsStats } from './event-news-stats'
import { IGridColumnFilterOption } from './grid-column-filter'
import dayjs from 'dayjs'
import moment from 'moment'
import { UserModel } from '../models/user'
import { useLocation } from "@reach/router"
import { InfoCircleIcon } from '../assets'

interface IEventNewsProps extends IDefaultProps {
	eventNewsId?: string
}
export const EventNews = (props: IEventNewsProps) => {
	const { eventNewsId } = props

	useEffect(() => {
		if (!!eventNewsId) editNewsItem(parseInt(eventNewsId))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventNewsId])

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
	
	const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
		let eventNewsQuery = await makeHttpRequestWithUi({
			request: appActions.EventNewsApi.apiEventNewsGet(),
			disableSuccessToast: true,
			disableLoading: true,
			toastErrorMessage: 'Failed to fetch event news.'
		})

		const _eventNews = [...eventNewsQuery.data.map(o => ({
			...o,
			branchAbbr: appState.activeBranches.find(b => b.branchId === o.branchId)?.branchAbbr,
			sent: !!o.dateSent // Add 'sent' prop here so we can sort on it.
		}))]

		let rows = _eventNews.map(EventNewsModel.toGridListItem)
		if (queryState.sorts) sortGridListItemsBySorts(rows, queryState.sorts)
		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return {
			count: rows.length,
			rows,
		}
	}

	const rowActions: { [id: string]: IGridRowAction } = {}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [helpModal, showHideHelpModal] = useModal()

	const [eventNewsItemToEdit, setEventNewsItemToEdit] = useState<IEventNewsDocument>()
	const [eventNewsItemFormModal, showHideEventNewsItemFormModal] = useModal()
	const editNewsItem = async (eventNewsItem: number) => {
		showHideEventNewsItemFormModal(true)

		const eventNewsItemToEditQuery = await makeHttpRequestWithUi({
			request: appActions.EventNewsApi.apiEventNewsIdGet(eventNewsItem),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the event news item to edit.'
		})

		setEventNewsItemToEdit(eventNewsItemToEditQuery.data)
	}

	rowActions.editNewsItem = {
		id: 'editNewsItem',
		action: async (options) => {
			options.e.stopPropagation()
			setSelectedRow(options.row)
			editNewsItem(parseInt(options.row.id))
		},
		icon: (row) => row.values.sent ? <SearchIcon /> : <PencilIcon />,
		tooltipText: (row) => row.values.sent ? 'View News Item' : 'Edit News Item',
	}

	const [previewModal, showHidePreviewModal] = useModal()
	const [emailPreview, setEmailPreview] = useState<string>()
	const previewNewsItem = async (newsItemId: number) => {
		const previewQuery = await makeHttpRequestWithUi({
			request: appActions.EventNewsApi.apiEventNewsIdPreviewGet(newsItemId),
			disableSuccessToast: true,
			toastErrorMessage: 'Failed to fetch preview for this event news item.'
		})

		setEmailPreview(previewQuery.data)
		showHidePreviewModal(true)
	}
	rowActions.previewNewsItem = {
		id: 'previewNewsItem',
		action: async (options) => {
			options.e.stopPropagation()

			previewNewsItem(parseInt(options.row.id))
		},
		icon: <EyeIcon />,
		tooltipText: 'Preview News Item',
		hidden: (row) => !!row.values.sent,
	}

	const [eventNewsIdJustSaved, setEventNewsIdJustSaved] = useState<number>()
	const [sendPreviewModal, showHideSendPreviewModal] = useModal()
	const [sendBlastModal, showHideSendBlastModal] = useModal()
	const [ignoreFirstTuesdayRule, setIgnoreFirstTuesdayRule] = useState(false)

	const [deleteEventNewsItemModal, showHideDeleteEventNewsItemModal] = useModal()
	rowActions.deleteNewsItem = {
		id: 'deleteNewsItem',
		action: async (options) => {
			options.e.stopPropagation()
			setSelectedRow(options.row)
			showHideDeleteEventNewsItemModal(true)
		},
		icon: <SquareDeleteIcon />,
		tooltipText: 'Delete News Item',
		hidden: (row) => !!row.values.sent,
	}

	const [statsModal, showHideStatsModal] = useModal()
	const [emailStats, setEmailStats] = useState<IStatsDocument[]>()
	rowActions.viewNewsItemStats = {
		id: 'viewNewsItemStats',
		action: async (options) => {
			options.e.stopPropagation()

			const statsQuery = await makeHttpRequestWithUi({
				request: appActions.EventNewsApi.apiEventNewsIdStatsSummaryGet(parseInt(options.row.id)),
				disableSuccessToast: true,
				toastErrorMessage: 'Failed to fetch stats for this event news item.'
			})

			setEmailStats(statsQuery.data)
			setSelectedRow(options.row)
			showHideStatsModal(true)
		},
		icon: <FileSpreadsheetIcon />,
		tooltipText: 'News Item Stats',
		hidden: (row) => !row.values.sent,
	}

	const [recipientsModal, showHideRecipientsModal] = useModal()
	const [emailRecipients, setEmailRecipients] = useState<IRecipientSearchResultDocument[]>()
	rowActions.viewNewsItemRecipients = {
		id: 'viewNewsItemRecipients',
		action: async (options) => {
			options.e.stopPropagation()

			const recipientsQuery = await makeHttpRequestWithUi({
				request: appActions.EventNewsApi.apiEventNewsIdRecipientsGet(parseInt(options.row.id)),
				disableSuccessToast: true,
				toastErrorMessage: 'Failed to fetch recipients for this event news item.'
			})

			setEmailRecipients(recipientsQuery.data)
			setSelectedRow(options.row)
			showHideRecipientsModal(true)
		},
		icon: <PeopleIcon />,
		tooltipText: 'News Item Recipients',
		hidden: (row) => !row.values.sent,
	}

	const [viewNewsItemModal, showHideViewNewsItemModal] = useModal()
	const [viewNewsItem, setViewNewsItem] = useState<string>()
	rowActions.viewNewsItem = {
		id: 'viewNewsItem',
		action: async (options) => {
			options.e.stopPropagation()

			const query = await makeHttpRequestWithUi({
				request: appActions.EventNewsApi.apiEventNewsIdEmailBodyGet(parseInt(options.row.id)),
				disableSuccessToast: true,
				toastErrorMessage: 'Encountered an error retrieving the email body for this News item.'
			})

			setViewNewsItem(query.data)
			showHideViewNewsItemModal(true)
		},
		icon: <EyeIcon />,
		tooltipText: 'Preview News Item',
		hidden: (row) => !row.values.sent,
	}

	// const toggleShowHistoryFilterId = 'sent-=='

	let gridActions = [
		{
			id: 'newNewsItem',
			label: 'Add Content',
			onClick: () => showHideEventNewsItemFormModal(true)
		},
		// {
		// 	id: 'toggleShowHistory',
		// 	label: (_gridState) => {
		// 		const communityTypeIdFilter = _gridState.queryState.filters?.find(f => f.id === toggleShowHistoryFilterId)
		// 		if (communityTypeIdFilter && communityTypeIdFilter.enabled) return 'Show History'
		// 		return 'Hide History'
		// 	},
		// 	onClick: (_gridState, _gridActions) => {
		// 		const communityTypeIdFilter = _gridState.queryState.filters?.find(f => f.id === toggleShowHistoryFilterId)
		// 		if (communityTypeIdFilter) {
		// 			_gridActions.updateColumnFilters([{ ...communityTypeIdFilter, enabled: !communityTypeIdFilter.enabled }])
		// 		} else {
		// 			_gridActions.updateColumnFilters([{
		// 				id: toggleShowHistoryFilterId,
		// 				enabled: true,
		// 				property: 'sent',
		// 				operator: '==',
		// 				value: false,
		// 			}])
		// 		}
		// 	},
		// },
		{
			id: 'showCoachingInvitation',
			label: 'Community Settings',
			ui: <EventNewsCoachingInvitationSetting />,
		},
	]

	const location = useLocation()

	if (UserModel.userIsSuperUser(appState.currentUser)) {
		gridActions.push({
			id: 'manageGlobalSettings',
			label: 'Global Settings',
			onClick: () => openUrlInNewTab(`${location.origin}/event-news-settings`),
		})
	}

	const monthYearFilterOptions = useMemo<IGridColumnFilterOption[]>(() => {
		const options: IGridColumnFilterOption[] = []
		for (let months = 0; months < 16; months++) {
			const month = dayjs().startOf('month').add(4, 'months').subtract(months, 'months')
			options.push({
				label: month.format('MMM YYYY'),
				range: {
					gtValue: month.toISOString(),
					ltValue: month.endOf('month').toISOString(),
				}
			})
		}

		return options
	}, [])

	const initialGridState: IGridState = {
		...defaultGridState,
		userSessionStateKey: GridUserInteractionStateKey.EventNews,
		// queryState: {
		// 	...defaultGridState.queryState,
		// 	filters: [
		// 		{
		// 			id: toggleShowHistoryFilterId,
		// 			enabled: true,
		// 			property: 'sent',
		// 			operator: '==',
		// 			value: false,
		// 		}
		// 	]
		// },
		columns: [
			{
				property: 'monthYear',
				type: 'date',
				width: 73,
				allowFilters: true,
				title: 'Month',
				render: DefaultGridCellDisplay,
				align: 'center',
				dateFormatOverride: 'MMM YYYY',
				filterOptions: monthYearFilterOptions,
			},
			{
				property: 'branchAbbr',
				type: 'string',
				width: 108,
				allowFilters: true,
				title: 'Community',
				render: DefaultGridCellDisplay,
				filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
			},
			{
				property: 'message',
				type: 'string',
				width: 250,
				allowFilters: true,
				title: 'Content',
				render: DefaultGridCellDisplay,
			},
			{
				property: 'reviewed',
				type: 'boolean',
				width: 100,
				allowFilters: true,
				title: 'AD Reviewed',
				render: DefaultGridCellDisplay,
				align: 'center',
				conditionalCellCSSFormatting: (row) => {
					if (row.values.reviewed) return 'bg-success-7'
					return ''
				},
			},
			{
				property: 'readyToSend',
				type: 'boolean',
				width: 120,
				allowFilters: true,
				title: 'Ready to Send',
				render: DefaultGridCellDisplay,
				align: 'center',
				conditionalCellCSSFormatting: (row) => {
					if (row.values.readyToSend) return 'bg-success-7'
					return ''
				},
			},
			{
				property: 'sent',
				type: 'boolean',
				width: 75,
				allowFilters: true,
				title: 'Sent',
				render: DefaultGridCellDisplay,
				align: 'center',
				conditionalCellCSSFormatting: (row) => {
					if (row.values.sent) return 'bg-success-7'
					return ''
				},
			},
			{
				property: 'grid_actions',
				type: 'actions',
				width: 85,
				disableSort: true,
				title: 'Actions',
				render: GridActionCell,
				align: 'center',
			},
		],
		dataSource,
		disabledPagination: true,
		disableExport: true,
		// userSessionStateKey: GridUserInteractionStateKey.EventNews,
		gridActions,
		rowActions,
		rowDoubleClicked: async (row) => {
			editNewsItem(parseInt(row.id))
		},
		respectGlobalCommunityFilter: true,
		rowSelectEnabled: false,
	}

	const [state, actions] = useGrid(gridReducer, initialGridState, appState)

	return (
		<React.Fragment>
			<Helmet>
				<title>Newsletter</title>
			</Helmet>

			<div className='d-flex flex-column' style={{ height: '100vh' }}>
				<div className='m-2 d-flex align-items-center justify-content-between'>
					<div className='m-2 d-flex align-items-center'>
						<CalendarIcon style={{ width: '25px', height: '25px' }} />
						<h3 className='ml-2'>Newsletter</h3>
					</div>
					<div className='d-flex'><button type='button' className='btn btn-info btn-sm icon-right ml-1 mr-2' onClick={() => showHideHelpModal(true)}>Help <InfoCircleIcon /></button></div>
				</div>
				<p className='ml-2 pl-2 pr-2'>
					Community Newsletters are email blasts that are managed by the AD and sent out by the NRC on the first Tuesday of every month. Aside from the custom note section and your choice to include/exclude the Coaching section, the details are automatically generated from activated events in GrowthTrack and serve as a way for ADs to inform their ministry leaders of upcoming events and other community information.
				</p>
				<Grid actions={actions} state={state} style={{ flex: 1, height: 'unset' }} />
			</div>

			<Modal
				{...deleteEventNewsItemModal}
				modalTitle='Delete News Item Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteEventNewsItemModal(false)}>Cancel</button>
						<button
							className={`btn btn-danger`}
							onClick={async () => {
								if (!selectedRow) return
								await makeHttpRequestWithUi({
									request: appActions.EventNewsApi.apiEventNewsIdDelete(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Successfully deleted the news item.',
									toastErrorMessage: 'There was an error deleting the news item.'
								})
								showHideDeleteEventNewsItemModal(false)
								actions.doFetch()
							}}
						>
							Delete
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setSelectedRow(undefined)}
			>
				Are you sure you want to delete this news item?
			</Modal>

			<Modal
				{...eventNewsItemFormModal}
				modalTitle={`${eventNewsItemToEdit ? 'Edit' : 'Add'} Content`}
				size='xl'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setEventNewsItemToEdit(undefined)
				}}
				dismissible={false}
			>
				{eventNewsItemFormModal.show ?
					<EventNewsItemForm
						eventNewsItemToEdit={eventNewsItemToEdit}
						onSaveSuccess={async (afterSaveAction) => {
							const { action, savedEventNewsItemId } = afterSaveAction

							await actions.doFetch()

							/* 
								Per https://missionincrease.atlassian.net/browse/MA20-1074, do not close the modal after a save.
								Instead, unlike other form modals, fetch the newly created or updated item and switch the form into edit mode.
							*/
							editNewsItem(savedEventNewsItemId)

							switch (action) {
								case EventNewsItemAfterSaveAction.preview:
									previewNewsItem(savedEventNewsItemId)
									break
								case EventNewsItemAfterSaveAction.sendPreview:
									setEventNewsIdJustSaved(savedEventNewsItemId)
									showHideSendPreviewModal(true)
									break
								case EventNewsItemAfterSaveAction.sendBlast:
									setEventNewsIdJustSaved(savedEventNewsItemId)
									showHideSendBlastModal(true)
									break
								case EventNewsItemAfterSaveAction.sendBlastWithOverride:
									setIgnoreFirstTuesdayRule(true)
									setEventNewsIdJustSaved(savedEventNewsItemId)
									showHideSendBlastModal(true)
									break
							}
						}}
					/>
					:
					null
				}
			</Modal>

			<Modal
				{...previewModal}
				modalTitle='Preview Email Blast'
				_onModalHidden={() => setEmailPreview(undefined)}
				size='xl'
			>
				<div dangerouslySetInnerHTML={{ __html: emailPreview || '' }}></div>
			</Modal>

			<Modal
				{...viewNewsItemModal}
				modalTitle='View Email Blast'
				_onModalHidden={() => setViewNewsItem(undefined)}
				size='xl'
			>
				<div dangerouslySetInnerHTML={{ __html: viewNewsItem || '' }}></div>
			</Modal>

			<Modal
				{...sendPreviewModal}
				modalTitle='Send Email Blast Preview'
				_onModalHidden={() => setEventNewsIdJustSaved(undefined)}
			>
				{eventNewsIdJustSaved && sendPreviewModal.show ?
					<Formik
						initialValues={{ previewRecipientEmail: '' }}
						validationSchema={Yup.object({
							previewRecipientEmail: Yup.string().required('Required').email('Please enter a valid email address.')
						})}
						onSubmit={async (values) => {
							await makeHttpRequestWithUi({
								request: appActions.EventNewsApi.apiEventNewsIdSendPreviewPost(eventNewsIdJustSaved, { toEmail: values.previewRecipientEmail }),
								toastSuccessMessage: `Successfully sent email blast preview to ${values.previewRecipientEmail}.`,
								toastErrorMessage: `There was an error sending email blast preview to ${values.previewRecipientEmail}.`
							})
							showHideSendPreviewModal(false)
						}}
					>
						{formikProps => {
							return <Form>
								<EmailField fieldProps={{ name: 'previewRecipientEmail', label: 'Email' }} />

								<div>
									<button style={{ minWidth: 150 }} type='button' onClick={() => showHideSendPreviewModal(false)} className='btn btn-secondary mr-2'>Cancel</button>
									<button
										style={{ minWidth: 150 }}
										disabled={!formikProps.isValid}
										type='submit'
										className='btn btn-secondary'
									>
										Send
								</button>
								</div>
							</Form>
						}}
					</Formik>
					:
					null
				}
			</Modal>

			<Modal
				{...sendBlastModal}
				modalTitle='Confirm'
				_onModalHidden={() => {
					setEventNewsIdJustSaved(undefined)
					setIgnoreFirstTuesdayRule(false)
				}}
				footer={
					<React.Fragment>
						<button style={{ minWidth: 150 }} type='button' onClick={() => showHideSendBlastModal(false)} className='btn btn-secondary mr-1'>Cancel</button>
						<button
							style={{ minWidth: 150 }}
							type='button'
							onClick={async () => {
								if (eventNewsIdJustSaved) {
									await makeHttpRequestWithUi({
										request: appActions.EventNewsApi.apiEventNewsIdSendBlastPost(eventNewsIdJustSaved, { ignoreFirstTuesdayRule }),
										toastSuccessMessage: `Successfully sent email blast.`,
										toastErrorMessage: `There was an error sending email blast.`
									})
									actions.doFetch()
								}
								showHideSendBlastModal(false)
								showHideEventNewsItemFormModal(false)
							}}
							className='btn btn-warning'
						>
							Send Now
						</button>
						{/* <button
							disabled={!formikProps.isValid}
							type='button'
							onClick={() => {
								formikProps.submitForm()
							}}
							className='btn btn-warning'
						>
							Send Later
						</button> */}
					</React.Fragment>
				}
			>
				Are you sure you want to send this email blast?
			</Modal>

			<Modal
				{...statsModal}
				modalTitle={`${selectedRow?.values.branchAbbr} ${moment(selectedRow?.values.monthYear?.toString()).format('MMM YYYY')} Results`}
				_onModalHidden={() => {
					setEmailStats(undefined)
					setSelectedRow(undefined)
				}}
				size='xl'
			>
				{emailStats ? <EventNewsStats emailStats={emailStats} /> : null}
			</Modal>

			<Modal
				{...recipientsModal}
				modalTitle={`${selectedRow?.values.branchAbbr} ${moment(selectedRow?.values.monthYear?.toString()).format('MMM YYYY')} Recipients`}
				_onModalHidden={() => {
					setEmailRecipients(undefined)
					setSelectedRow(undefined)
				}}
				size='xl'
				dismissible={false}
			>
				{emailRecipients ?
					<EventNewsRecipients recipients={emailRecipients} />
					:
					null
				}
			</Modal>
			<Modal
				{...helpModal}
				modalTitle='Newsletter Help'
				size={'lg'}
			>
				<h4>Newsletter FAQ</h4>
				<p><strong>Q: How many upcoming events will display in a newsletter?</strong> <br />
				A: A maximum of 4 upcoming events will display in your newsletter. 
				</p>
				<p><strong>Q: Will upcoming series events display in a newsletter?</strong> <br />
				A: A series will only display if the first event in the series has not already passed by the time the newsletter is scheduled to go out.
				</p>
			</Modal>
		</React.Fragment>
	)
}