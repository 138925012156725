import { createPortal } from 'react-dom'
import { IDefaultProps } from './component-definitions'
import { useRef, useState, useEffect } from 'react'
import * as Popper from '@popperjs/core'
import React from 'react'
import { Options } from '@popperjs/core'
import { useClickAway } from 'react-use'

/* 
	PopoverContainer

	A simple container for popover elements that renders those elements in a React Portal so it falls outside the normal DOM flow. 
*/

export interface IPopoverState {
	show?: boolean
	trigger?: Element
	popoverOptions?: Partial<Options>

}

interface IPopoverContainerProps extends IDefaultProps {
	parentElement: Element | null
	popoverState: IPopoverState
	onPopoverClosed: () => void
	setPopoverContainer?: (popover: HTMLDivElement | null) => void
}
export const PopoverContainer = (props: IPopoverContainerProps) => {
	const { children, className, parentElement, popoverState, onPopoverClosed, setPopoverContainer } = props
	const { show, trigger, popoverOptions } = popoverState

	const popoverContainer = useRef<HTMLDivElement>(null)
	const [popperInstance, setPopperInstance] = useState<Popper.Instance>()

	useClickAway(popoverContainer, () => {
		onPopoverClosed()
	})

	useEffect(() => {
		const popover = popoverContainer.current
		if (setPopoverContainer) setPopoverContainer(popover)

		if (!popover || !trigger) return

		if (show) {
			const popper = Popper.createPopper(trigger, popover, popoverOptions)
			setPopperInstance(popper)

			popover.classList.add('show')
		} else {
			if (popperInstance) popperInstance.destroy()
			popover.classList.remove('show')
		}

		//eslint-disable-next-line
	}, [popoverState, popoverContainer])

	if (!parentElement || !show) return null

	return createPortal(
		<div ref={popoverContainer} className={`${className}`}>
			{children}
		</div>,
		parentElement
	)
}