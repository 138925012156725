import { useRef } from "react"
import { Form, Formik } from "formik"
import { IEventContentFormState } from "./event-content-form"
import * as yup from 'yup'
import { EVENT_TOPICS, EventContentCategory, EventContentType, EventContentWebinarProvider } from "../constants"
import { ISelectFieldOption, SelectField } from "./forms"
import { FormikEffect } from "./formik-effect"

interface IEventContentStep1Props {
    parentState: IEventContentFormState
    setParentState: any
}

export const EventContentStep1 = ({ parentState, setParentState} : IEventContentStep1Props) => {

    // The second slice removes the 'Small Group Session' entry. That event type is currently not allowed to be used, but we need to keep the value around when displaying existing SGS events.
    const eventTypeOptions = useRef<ISelectFieldOption[]>(Object.entries(EventContentType).slice(-Object.entries(EventContentType).length / 2).slice(0, 2).map(o => ({ label: o[0], value: o[1].toString() })))
    const webinarProviderOptions = useRef<ISelectFieldOption[]>(Object.entries(EventContentWebinarProvider).slice(-Object.entries(EventContentWebinarProvider).length / 2).map(o => ({ label: o[0], value: o[1].toString() })))
    const eventContentCategoryOptions = useRef<ISelectFieldOption[]>(Object.entries(EventContentCategory).slice(-Object.entries(EventContentCategory).length / 2).map(o => ({ label: o[0], value: o[1].toString() })))

    return (
        <Formik
            initialValues={parentState.step1Values}
            validationSchema={yup.object({
                eventTypeId: yup.number().required('Required'),
                // webinarProviderId: yup.number()
                //     .test(
                //         'webinarProviderId',
                //         'Required',
                //         function (value) {
                //             if (this.parent.eveneTypeId?.toString() === EventContentType.Webinar.toString()) return !value || !isNaN(value)
                //             return true
                //         }
                //     ),
                eventCategoryId: yup.number().required('Required'),
                eventTopicId: yup.number().required('Required'),
            })}
            // @ts-ignore
            onSubmit={async (step1Values) => setParentState(_state => ({ ..._state, step: 'step2', step1Values, step2Values: { ..._state.step2Values, nationalEvent: step1Values.eventTypeId === EventContentType.Webinar.toString() } }))}
        >
            {formikProps =>
                <Form style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>

                    <h4>Step 1</h4>

                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, marginRight: 20 }}>
                            <SelectField fieldProps={{ name: 'eventTypeId', label: 'Event Type' }} options={eventTypeOptions.current} />
                        </div>
                        {/* <div style={{ flex: 1, marginLeft: 20 }}>
                            {formikProps.values.eventTypeId.toString() === EventContentType.Webinar.toString() &&
                                <>
                                    <SelectField fieldProps={{ name: 'webinarProviderId', label: 'Webinar Provider' }} options={webinarProviderOptions.current} />
                                </>
                            }
                        </div> */}
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, marginRight: 20 }}>
                            <SelectField fieldProps={{ name: 'eventCategoryId', label: 'Category' }} options={eventContentCategoryOptions.current} />
                        </div>
                        <div style={{ flex: 1, marginLeft: 20 }}>
                            <SelectField disabled={isNaN(parseInt(formikProps.values.eventCategoryId))} fieldProps={{ name: 'eventTopicId', label: 'Topic' }} options={EVENT_TOPICS.filter(o => o.eventCategoryId.toString() === formikProps.values.eventCategoryId.toString()).map(o => ({ label: o.topic, value: o.eventTopicId.toString() }))} />
                        </div>
                    </div>

                    {/* 
                    TB 20230203 - We have retired the Small Group Session event type. ADs should use Group Learning, instead of SGS, going forward.
                    {formikProps.values.eventTypeId.toString() === EventContentType.Workshop.toString() &&
                        <CheckboxField fieldProps={{ name: 'allowSmallGroupSession', label: 'Create Small Group Session Event Content', labeltooltip: 'Small group event connected to a workshop, available only to leaders who attended the connected workshop.' }} />
                    } */}

                    <FormikEffect formikProps={formikProps} onChange={(prev, next) => {
                        if (!isNaN(parseInt(prev.eventCategoryId)) && prev.eventCategoryId !== next.eventCategoryId) formikProps.setFieldValue('eventTopicId', '')
                        if (prev.eventTypeId.toString() !== EventContentType.Workshop.toString()) formikProps.setFieldValue('allowSmallGroupSession', false)
                    }} />

                    <div style={{ flex: 1 }} />

                    <div>
                        <button type='submit' className='btn btn-primary' style={{ minWidth: 100 }}>Continue</button>
                    </div>
                </Form>
            }
        </Formik>
    )
}