import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { AppStateContext } from "../app-store-provider"
import { IMiBranchModelDocument } from "../open-api"
import { CardReportDashboard } from "./card-report-dashboard"
import { IDefaultProps } from "./component-definitions"

export const DashboardReport = ({ }: IDefaultProps) => {

    const { globalCommunityContext } = useContext(AppStateContext)!
    const [activeCommunity, setActiveCommunity] = useState<IMiBranchModelDocument>()

    useEffect(() => {
        if (globalCommunityContext) {
            setActiveCommunity(globalCommunityContext)
        } else {
            setActiveCommunity(undefined)
        }
    }, [globalCommunityContext])
    
    return(
        <>
            <Helmet>
                <title>Dashboard Report</title>
            </Helmet>

            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active">Reports</li>
                <li className="breadcrumb-item active" aria-current="page">Dashboard Report</li>
            </ol>
            </nav>
            <div className='d-flex flex-column' style={{ minHeight: 0, overflow: 'hidden' }}>
                <div className='m-2 d-flex align-items-center'>
                    {/* <HouseIcon style={{ width: '25px', height: '25px' }} /> */}
                    <h3 className='ml-2'>Dashboard Report</h3>
                </div>
                <div className="row pl-3 pr-3" style={{ overflow: 'hidden' }}>
                    <div className="col-sm-12">
                        <CardReportDashboard activeCommunity={activeCommunity} style={{marginBottom: '15px'}} />
                    </div>
                </div>
            </div>
        </>
    )
}