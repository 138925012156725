import React, { useContext, useEffect } from 'react'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { IMinistrySummaryResponseModelDocument } from '../open-api'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { filterGridListItems, sortListBySorts } from '../services/helpers'
import { MinistryProfileLogModel, ministryProfileLogModelDefaultColumns } from '../models/ministry-profile-log'
import { Grid } from './grid'
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from '../stores/grid-reducer'
import { MinistryInfoContext } from './ministry-info'

interface IMinistryInfoProfileLogProps {
    ministry: IMinistrySummaryResponseModelDocument
}
export const MinistryInfoProfileLog = (props: IMinistryInfoProfileLogProps) => {
    const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

    const fetchMinistryProfileLog: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
        const { ministryProfileLog } = _state

        if (!ministryProfileLog) return { count: 0, rows: [] }

        if (queryState.sorts) sortListBySorts(ministryProfileLog, queryState.sorts)

        let rows = ministryProfileLog.map(x => MinistryProfileLogModel.toGridListItem(x))

        if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

        return { rows, count: rows.length }
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        usingLocalData: true,
        rowSelectEnabled: false,
        columns: ministryProfileLogModelDefaultColumns,
        disabledPagination: true,
        dataSource: fetchMinistryProfileLog
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

    useEffect(() => {
        ministryInfoActions.fetchProfileLog()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        gridActions.doFetch()
        //eslint-disable-next-line
    }, [ministryInfoState.ministryProfileLog])

    return (
        <React.Fragment>
            <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
        </React.Fragment>
    )
}

