import { useContext } from "react"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { Modal } from "./modal"
import { Form, Formik } from "formik"
import { TextareaField } from "./forms"
import { AppActionContext } from "../app-store-provider"
import { deregisterLoadingTask, registerLoadingTask } from "../services/loading-service"

// Implementation will either use just the courseEnrollmentIds property, or both courseId and ministryContactIds
interface ILmsSendEmailModalProps {
	afterSend?: () => void
    modalProps: ReturnType<typeof useModal>[0]
	courseEnrollmentIds?: number[]
	courseId?: number
	ministryContactIds?: number[]
}

export const LmsSendEmailModal = ({ afterSend, modalProps, courseEnrollmentIds, courseId, ministryContactIds } : ILmsSendEmailModalProps) => {
	//const appState = useContext(AppStateContext)
	const appActions = useContext(AppActionContext)!

	const usingMinistryContacts = !!ministryContactIds
	const enrollmentCount = usingMinistryContacts ? ministryContactIds?.length : courseEnrollmentIds?.length

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const handleSendLmsEmails = async (values: { personalNote: string }, actions: any) => {
		if (!courseEnrollmentIds?.length) return
		const taskId = registerLoadingTask()
		await makeHTTPRequestWithUi({
			request: appActions.LmsApi.apiLmsSendCourseEnrollmentEmailPost(values.personalNote, courseEnrollmentIds),
			disableLoading: true,
			disableSuccessToast: false,
			toastErrorMessage: 'There was an error sending LMS email.'
		})
		deregisterLoadingTask(taskId)
		modalProps.closeModal()
		actions.resetForm()
		afterSend && afterSend()
	}

	const handleSendCourseAccessEmails = async (values: { personalNote: string }, actions: any) => {
		if (!courseId || !enrollmentCount) return
		const taskId = registerLoadingTask()
		await makeHTTPRequestWithUi({
			request: appActions.LmsApi.apiLmsSendCourseAccessEmailPost(courseId, values.personalNote, ministryContactIds),
			disableLoading: true,
			disableSuccessToast: false,
			toastErrorMessage: 'There was an error sending LMS email.'
		})
		deregisterLoadingTask(taskId)
		modalProps.closeModal()
		actions.resetForm()
		afterSend && afterSend()
	}

    return (<Modal
					{...modalProps}
					size="md"
					modalTitle={
						<h4>Send LMS Email?</h4>
					}
					dismissible={false}
				>
					<>
						{enrollmentCount == 1 ? 
							<p>Would you like to send this contact an email notifying them they have access to this LMS content?</p>
							:
							<p>Would you like to send these { enrollmentCount } contacts an email notifying them they have access to this LMS content?</p>
						}

						<Formik initialValues={{ personalNote: '' }} onSubmit={usingMinistryContacts ? handleSendCourseAccessEmails : handleSendLmsEmails}>
							<Form>
								<TextareaField
									fieldProps={{ name: 'personalNote', id: 'personalNote', label: 'Include Personal Note in Email?', rows: 4 }}
								/>

								<div className='d-flex justify-content-end'>
									<button className="btn btn-secondary" onClick={(e) => {
										e.preventDefault()
										modalProps.closeModal()
									}}>
										No
									</button>
									
									<button className="btn btn-primary ml-2" type="submit">
										Send LMS Emails
									</button>
								</div>
							</Form>
						</Formik>
					</>
				</Modal>)
}