import React, { useContext, useState, useEffect } from 'react'
import { MinistryInfoContext } from './ministry-info'
import { showTab } from '../services/helpers'
import { IMinistryEvalsSummaryDocument } from '../open-api'
import { AppActionContext } from '../app-store-provider'
import { Loading } from './loading'
import { MinistryInfoAreaDirectorEvalPurpose } from './ministry-info-ad-eval-purpose'
import { MinistryInfoAreaDirectorEvalGovernance } from './ministry-info-ad-eval-governance'
import { MinistryInfoAreaDirectorEvalLeadership } from './ministry-info-ad-eval-leadership'
import { MinistryInfoAreaDirectorEvalOperations } from './ministry-info-ad-eval-operations'
import { MinistryInfoAreaDirectorEvalFundraising } from './ministry-info-ad-eval-fundraising'
import { MinistryInfoAreaDirectorEvalCommunications } from './ministry-info-ad-eval-communications'
import { MinistryInfoAreaDirectorEvalRiskMgmt } from './ministry-info-ad-eval-risk-mgmt'
import { MinistryInfoAreaDirectorEvalFinancial } from './ministry-info-ad-eval-financial'
import { MinistryInfoAreaDirectorEvalSummary } from './ministry-info-ad-eval-summary'
import moment from 'moment'

export type EvalTab = 'purpose-tab' | 'governance-tab' | 'leadership-tab' | 'operations-tab' | 'fundraising-tab' | 'communications-tab' | 'risk-mngmt-tab' | 'financial-tab' | 'summary-tab'

export const AreaDirectorEvalContext = React.createContext<{
	ministryEvalState: IMinistryEvalsSummaryDocument
	activeEvalTab: string
} | null>(null)

export const MinistryInfoAreaDirectorEval = () => {
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

	const appActions = useContext(AppActionContext)!

	const [ministryEvalState, setMinistryEvalState] = useState<IMinistryEvalsSummaryDocument>()
	const [activeEvalTab, setActiveEvalTab] = useState<string>('purpose-tab')
	const [lastUpdated, setLastUpdated] = useState<string>('')
	const [finalScore, setFinalScore] = useState<string>('')
	
	const viewTab = (tabId: EvalTab) => {
		showTab(tabId)
		setActiveEvalTab(tabId)
	}

	const getMinistryEval = async () => {
		const results = await appActions.MinistryEvalsApi.apiMinistriesIdMinistryEvalGet(ministryInfoState.ministry?.ministryId || 0)
		setMinistryEvalState(results.data)
	}

	useEffect(() => {
		getMinistryEval()
		// eslint-disable-next-line
	}, [])

	const setLastUpdatedVal = (updatedDate: string | null, updatedByName: string | null) => {
		let lastUpdatedTxt = ''
		if (updatedDate) {
			lastUpdatedTxt = (typeof updatedDate === 'string' ? moment(updatedDate).calendar() : '') +
				' by ' + updatedByName
		}
		setLastUpdated(lastUpdatedTxt)
	}

	// Determine the appropriate "Last Updated" date string to display
	useEffect(() => {
		if (ministryEvalState) {
			switch (activeEvalTab) {
				case 'purpose-tab':
					setLastUpdatedVal(ministryEvalState.dStep1Updated, ministryEvalState.step1UpdatedByName)
					break;
				case 'governance-tab':
					setLastUpdatedVal(ministryEvalState.dStep2Updated, ministryEvalState.step2UpdatedByName)
					break;
				case 'leadership-tab':
					setLastUpdatedVal(ministryEvalState.dStep3Updated, ministryEvalState.step3UpdatedByName)
					break;
				case 'operations-tab':
					setLastUpdatedVal(ministryEvalState.dStep4Updated, ministryEvalState.step4UpdatedByName)
					break;
				case 'fundraising-tab':
					setLastUpdatedVal(ministryEvalState.dStep5Updated, ministryEvalState.step5UpdatedByName)
					break;
				case 'communications-tab':
					setLastUpdatedVal(ministryEvalState.dStep6Updated, ministryEvalState.step6UpdatedByName)
					break;
				case 'risk-mngmt-tab':
					setLastUpdatedVal(ministryEvalState.dStep7Updated, ministryEvalState.step7UpdatedByName)
					break;
				case 'financial-tab':
					setLastUpdatedVal(ministryEvalState.dStep8Updated, ministryEvalState.step8UpdatedByName)
					break;
				default:
					setLastUpdated('')
					break;
			}

			setFinalScore(ministryEvalState.finalScore?.toString() || '0')
		}
		
		// eslint-disable-next-line
	}, [activeEvalTab, ministryEvalState])

	if (!ministryEvalState) return <Loading />

	return (
		<AreaDirectorEvalContext.Provider value={{ministryEvalState, activeEvalTab}}>
			<div className='d-flex flex-column'>
				<div>
					<ul className="nav nav-tabs my-2 ml-2" role="tablist">
						<li className="nav-item">
						<a className={`nav-link ${activeEvalTab === 'purpose-tab' ? 'active' : ''}`} id='purpose-tab' onClick={() => viewTab('purpose-tab')} href='#purpose' role='tab' aria-controls='purpose' aria-selected='true'>Purpose</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'governance-tab' ? 'active' : ''}`} id='governance-tab' onClick={() => viewTab('governance-tab')} href='#governance' role='tab' aria-controls='governance' aria-selected='true'>Governance</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'leadership-tab' ? 'active' : ''}`} id='leadership-tab' onClick={() => viewTab('leadership-tab')} href='#leadership' role='tab' aria-controls='leadership' aria-selected='true'>Leadership</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'operations-tab' ? 'active' : ''}`} id='operations-tab' onClick={() => viewTab('operations-tab')} href='#operations' role='tab' aria-controls='operations' aria-selected='true'>Operations</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'fundraising-tab' ? 'active' : ''}`} id='fundraising-tab' onClick={() => viewTab('fundraising-tab')} href='#fundraising' role='tab' aria-controls='fundraising' aria-selected='true'>Fundraising</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'communications-tab' ? 'active' : ''}`} id='communications-tab' onClick={() => viewTab('communications-tab')} href='#communications' role='tab' aria-controls='communications' aria-selected='true'>Communications</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'risk-mngmt-tab' ? 'active' : ''}`} id='risk-mngmt-tab' onClick={() => viewTab('risk-mngmt-tab')} href='#risk-mngmt' role='tab' aria-controls='risk-mngmt' aria-selected='true'>Risk Mngmt</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'financial-tab' ? 'active' : ''}`} id='financial-tab' onClick={() => viewTab('financial-tab')} href='#financial' role='tab' aria-controls='financial' aria-selected='true'>Financial</a>
						</li>
						<li className="nav-item">
							<a className={`nav-link ${activeEvalTab === 'summary-tab' ? 'active' : ''}`} id='summary-tab' onClick={() => viewTab('summary-tab')} href='#summary' role='tab' aria-controls='summary' aria-selected='true'>Summary</a>
						</li>
					</ul>
					<div className="tab-content" style={{ overflow: 'auto' }}>
						<div className='px-5'>
							<div className='d-flex justify-content-between'>
								{lastUpdated ? 
									<div><strong>Last Updated:</strong> {lastUpdated}</div>
								: 
								<div></div>
								}
								<div><strong>{activeEvalTab === 'summary-tab' ? `Final` : `Current`} Score:</strong> {ministryEvalState.finalScore} of 82</div>
							</div>
						</div>
						<div style={{ height: '100%' }} className="tab-pane fade px-5 py-3 show active" id="purpose" role="tabpanel" aria-labelledby="purpose-tab">
							<MinistryInfoAreaDirectorEvalPurpose
								afterSaveNext={async () => {
									viewTab('governance-tab')
									getMinistryEval()
								}} />
						</div>
						<div className="tab-pane fade px-5 py-3" id="governance" role="tabpanel" aria-labelledby="governance-tab">
							<MinistryInfoAreaDirectorEvalGovernance
									afterSaveNext={async () => {
										viewTab('leadership-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('purpose-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="leadership" role="tabpanel" aria-labelledby="leadership-tab">
							<MinistryInfoAreaDirectorEvalLeadership
									afterSaveNext={async () => {
										viewTab('operations-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('governance-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="operations" role="tabpanel" aria-labelledby="operations-tab">
							<MinistryInfoAreaDirectorEvalOperations
									afterSaveNext={async () => {
										viewTab('fundraising-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('leadership-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="fundraising" role="tabpanel" aria-labelledby="fundraising-tab">
							<MinistryInfoAreaDirectorEvalFundraising
									afterSaveNext={async () => {
										viewTab('communications-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('operations-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="communications" role="tabpanel" aria-labelledby="communications-tab">
							<MinistryInfoAreaDirectorEvalCommunications
									afterSaveNext={async () => {
										viewTab('risk-mngmt-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('fundraising-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="risk-mngmt" role="tabpanel" aria-labelledby="risk-mngmt-tab">
							<MinistryInfoAreaDirectorEvalRiskMgmt
									afterSaveNext={async () => {
										viewTab('financial-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('communications-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="financial" role="tabpanel" aria-labelledby="financial-tab">
							<MinistryInfoAreaDirectorEvalFinancial
									afterSaveNext={async () => {
										viewTab('summary-tab')
										getMinistryEval()
									}}
									afterSavePrevious={async () => {
										viewTab('risk-mngmt-tab')
										getMinistryEval()
									}}
								/>
						</div>
						<div className="tab-pane fade px-5 py-3" id="summary" role="tabpanel" aria-labelledby="summary-tab">
							<MinistryInfoAreaDirectorEvalSummary />
						</div>
					</div>
				</div>
		</div>
		</AreaDirectorEvalContext.Provider>
	)
}