import React, { useContext, useEffect, useState } from 'react'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { UserModel, UserPermissionLevel } from '../models/user'
import { IEventSiteModelDocument, IMiTrainingAreaSummaryModelDocument } from '../open-api'
import { sortListByProperty, sortListBySorts } from '../services/helpers'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridColumn, IGridListItem, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { DefaultGridCellDisplay, GridActionCell, GridListItemObjectDisplay } from './grid-cell-displays'
import { Loading } from './loading'
import { Modal } from './modal'
import { SquareDeleteIcon } from './partials'
import { EventSiteModel } from '../models/event-site'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { EventSiteForm } from './event-site-form'

interface IEventSitesProps {
	trainingArea: IMiTrainingAreaSummaryModelDocument
}

export const EventSites = (props: IEventSitesProps) => {
	const { trainingArea } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [eventSites, setEventSites] = useState<IEventSiteModelDocument[]>()

	const userCanModify = UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Modify, appState.currentUser);

	const fetchEventSites = async () => {
		const eventSitesQuery = await makeHttpRequestWithUi({
			request: appActions.EventSitesApi.apiMiTrainingAreasTrainingAreaIdEventSitesGet(trainingArea.trainingAreaId),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving event sites for this training area.'
		})

		setEventSites(eventSitesQuery.data)
	}

	useEffect(() => {
		fetchEventSites()

		// eslint-disable-next-line
	}, [trainingArea])

	const dataSource: GridDataFetch<IEventSiteModelDocument[]> = async (queryState, _state) => {

		if (!_state) return { count: 0, rows: [] }

		let eventSites = [...sortListByProperty(_state, 'locationName')]

		if (queryState.sorts) sortListBySorts(eventSites, queryState.sorts)
		let rows = eventSites.map(EventSiteModel.toGridListItem)

		return {
			count: eventSites.length,
			rows,
		}
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [eventSiteFormModal, showHideEventSiteFormModal] = useModal()
	const [eventSiteToEdit, setEventSiteToEdit] = useState<IEventSiteModelDocument>()
	const editEventSite = async (eventSiteRow: IGridListItem) => {
		setSelectedRow(eventSiteRow)
		const eventSiteToEditQuery = await makeHttpRequestWithUi({
			request: appActions.EventSitesApi.apiEventSitesIdGet(parseInt(eventSiteRow.id)),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the event site to edit.'
		})

		setEventSiteToEdit(eventSiteToEditQuery.data)

		showHideEventSiteFormModal(true)
	}

	const columns: IGridColumn[] = [
		{
			property: 'locationName',
			type: 'string',
			width: 200,
			disableSort: true,
			allowFilters: false,
			title: 'Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'locationContact',
			type: 'string',
			width: 200,
			disableSort: true,
			allowFilters: false,
			title: 'Contact',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'locationAddress',
			type: 'string',
			width: 200,
			disableSort: true,
			allowFilters: false,
			title: 'Address',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'locationCity',
			type: 'string',
			width: 200,
			disableSort: true,
			allowFilters: false,
			title: 'City',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'eventTypes',
			type: 'string',
			width: 200,
			disableSort: true,
			allowFilters: false,
			title: 'Hosts',
			render: GridListItemObjectDisplay,
		},
		{
			property: 'maxSeats',
			type: 'string',
			width: 200,
			disableSort: true,
			allowFilters: false,
			title: 'Max Seats',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'bDefaultSite',
			type: 'boolean',
			width: 120,
			allowFilters: true,
			title: 'Default',
			render: DefaultGridCellDisplay,
			align: 'center'
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 130,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			align: 'center',
		},
	]

	/* 
		 Only users with permission level 2 or higher for App 1 can edit or archive event sites, or view the "Default" column
		 Admin\modules\BranchAdmin\branchAdmin.js line 1841
	*/
	const [deleteEventSiteModal, showHideDeleteEventSiteModal] = useModal()

	const initialGridState: IGridState = {
		...defaultGridState,
		columns,
		rowActionsDisplayType: 'icons',
		rowActions: {
			editEventSite: {
				id: 'editEventSite',
				action: async (options) => {
					options.e.stopPropagation()
					editEventSite(options.row)
				},
				icon: <PencilIcon />,
				tooltipText: 'Edit Site',
				url: '#',
				disabled: !userCanModify
			},
			deleteSite: {
				id: 'deleteSite',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideDeleteEventSiteModal(true)
				},
				icon: <div style={{ paddingBottom: 5 }}><SquareDeleteIcon /></div>,
				tooltipText: 'Archive Event Site',
				url: '#',
				disabled: !userCanModify
			}
		},
		dataSource,
		usingLocalData: true,
		disabledPagination: true,
		rowSelectEnabled: false,
		hideGridFooter: true,
		hideGridHeader: true,
	}

	const [state, actions] = useGrid(gridReducer, initialGridState, eventSites)

	useEffect(() => {
		actions.doFetch()
		// eslint-disable-next-line
	}, [eventSites])

	if (!eventSites) return <Loading />

	return (
		<div className='d-flex flex-column'>
			{/* 
				Only users with permission level 2 or higher for App 1 can create event sites
				Admin\modules\BranchAdmin\branchAdmin.js line 1940
			*/}
			{UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Modify, appState.currentUser) ?
				<div className='my-3 d-flex'>
					<h4 className='mr-4'>Event Sites</h4>

					<button className='btn btn-secondary' onClick={() => showHideEventSiteFormModal(true)}>Add Event Site</button>
				</div>
				:
				null
			}
			<Grid actions={actions} state={state} style={{ flex: 1, height: '100%' }} />

			<Modal
				{...eventSiteFormModal}
				modalTitle={!!eventSiteToEdit ? 'Edit Event Site' : 'Create New Event Site'}
				size='fullscreen'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setEventSiteToEdit(undefined)
				}}
			>
				{eventSiteFormModal.show ?
					<EventSiteForm
						trainingArea={trainingArea}
						eventSiteToEdit={eventSiteToEdit}
						onSaveSucess={() => {
							setSelectedRow(undefined)
							setEventSiteToEdit(undefined)
							showHideEventSiteFormModal(false)
							fetchEventSites()
						}}
					/>
					:
					null
				}
			</Modal>

			<Modal
				{...deleteEventSiteModal}
				modalTitle='Archive Event Site Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteEventSiteModal(false)}>Cancel</button>
						<button
							className={`btn btn-danger`}
							onClick={async () => {
								if (selectedRow) {
									await makeHttpRequestWithUi({
										request: appActions.EventSitesApi.apiEventSitesIdDelete(parseInt(selectedRow.id)),
										toastSuccessMessage: 'Successfully archived event site.',
										toastErrorMessage: 'There was an error archiving the event site.'
									})
									fetchEventSites()
									showHideDeleteEventSiteModal(false)
								}
							}}
						>
							ARCHIVE
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setSelectedRow(undefined)}
			>
				{typeof selectedRow?.values.pastEvents === 'number' && selectedRow.values.pastEvents > 0 ?
					`This site has hosted ${selectedRow.values.pastEvents} previous event${selectedRow.values.pastEvents === 1 ? '' : 's'} and has . Are you sure you want to archive this site?`
					:
					'Are you sure you want to archive this site?'
				}
			</Modal>
		</div>
	)
}