import { ReactComponent as ArrowUpIcon } from '../assets/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down.svg'
import { ReactComponent as CarretUpIcon } from '../assets/carret-up-fill.svg'
import { ReactComponent as CarretDownIcon } from '../assets/carret-down-fill.svg'
import React, { useState, useContext } from 'react'
import { GridContext } from './grid'
import { GridColumnFilterSummary } from './grid-column-filter-summary'
import { sortListByProperty } from '../services/helpers'
import { ISort } from '../stores/api-actions'
import { uniqBy } from 'lodash'
import { SimpleSelectField } from './forms'
import { useModal } from '../services/hooks'
import { Modal } from './modal'
import { ContextMenu } from './context-menu'
import { ThreeDotsVerticalIcon } from '../assets'

/* 
	The GridHeader mainly handles extra controls above the Grid. 
	- Show/Hide columns
	- View and enable/disable/clear filters
*/

export const GridHeader = () => {
	const { state, actions } = useContext(GridContext)!

	const { loading, columns, queryState, gridActions, nestedGridActions, userSessionStateKey } = state

	const defaultHeaderViewsState: { [id: string]: boolean } = {
		viewShowHideColumns: false,
		viewFilters: false,
	}

	sortListByProperty(gridActions || [], 'sortOrder').forEach(gridAction => defaultHeaderViewsState[gridAction.id] = false)

	const [headerViewsState, setHeaderViewsState] = useState(defaultHeaderViewsState)
	const { viewFilters, viewShowHideColumns } = headerViewsState

	const [resetGridViewModal, showHideResetGridViewModal] = useModal()

	const clearSort = (sort: ISort) => {
		if (!loading) actions.clearSort(sort)
	}

	return (
		<>
			<div className='grid-header'>
				<div>
					<div className='_row vertical-center'>
						<button
							type="button"
							className={`btn btn-sm btn-light ${headerViewsState.viewShowHideColumns ? 'active' : ''}`}
							onClick={() => setHeaderViewsState({ ...defaultHeaderViewsState, viewShowHideColumns: !viewShowHideColumns })}
						>
							<span className="badge badge-light margin-right">{columns.filter(c => !c.hide).length}</span>
							Columns {headerViewsState.viewShowHideColumns ? <CarretUpIcon /> : <CarretDownIcon />}
						</button>

						{columns.find(col => col.allowFilters) || state.queryState.filters?.filter(f => !f.hidden).length ?
							<button
								type="button"
								className={`btn btn-sm ml-2 ${queryState.filters && queryState.filters.filter(f => f.enabled && !f.hidden).length ? 'btn-primary' : 'btn-light'} ${viewFilters ? 'active' : ''}`}
								onClick={() => setHeaderViewsState({ ...defaultHeaderViewsState, viewFilters: !viewFilters })}
							>
								<span className="badge badge-light margin-right">{queryState.filters ? uniqBy(queryState.filters.filter(f => f.enabled && !f.hidden), value => value.property).length : 0}</span>
								Filters {viewFilters ? <CarretUpIcon /> : <CarretDownIcon />}
							</button>
							:
							null
						}

						{/* Ony show the drop down for grid actions if a grid actions component was passed in */}
						{gridActions?.map(gridAction => {
							let buttonClass = 'btn-light'
							if (typeof gridAction.buttonClass === 'string') buttonClass = gridAction.buttonClass
							if (typeof gridAction.buttonClass === 'function') buttonClass = gridAction.buttonClass(state)

							let disabled = false
							if (typeof gridAction.disabled === 'boolean') disabled = gridAction.disabled
							if (typeof gridAction.disabled === 'function') disabled = gridAction.disabled(state)

							if (gridAction.dropdown) {
								let dropdown = typeof gridAction.dropdown === 'function' ? gridAction.dropdown(state, actions) : gridAction.dropdown
								return (
									<div key={gridAction.id} className='ml-2' style={{ minWidth: 200 }}>
										<SimpleSelectField
											value={dropdown.value}
											disabled={disabled}
											multiple={dropdown.multiple}
											fieldProps={{ name: gridAction.id, label: '', placeholder: typeof gridAction.label === 'function' ? gridAction.label(state) : gridAction.label }}
											onChange={dropdown.onChange}
											options={dropdown.options}
										/>
									</div>
								)
							} else {
								return <button
									disabled={disabled || state.loading}
									key={gridAction.id}
									id={gridAction.id}
									type="button"
									className={`btn btn-sm ml-2 ${headerViewsState[gridAction.id] ? 'active' : ''} ${buttonClass}`}
									onClick={() => {
										if (gridAction.ui) {
											setHeaderViewsState({ ...defaultHeaderViewsState, [gridAction.id]: !headerViewsState[gridAction.id] })
										}

										if (gridAction.onClick) gridAction.onClick(state, actions)
									}}
									style={{ display: 'flex', alignItems: 'center' }}
								>
									{typeof gridAction.icon === 'function' ? gridAction.icon(state) : gridAction.icon}
									{typeof gridAction.label === 'function' ? gridAction.label(state) : gridAction.label} {gridAction.ui ? headerViewsState[gridAction.id] ? <CarretUpIcon /> : <CarretDownIcon /> : null}
								</button>
							}


						})}

						{nestedGridActions && 
							<div className='ml-2'>
								<ContextMenu icon={<ThreeDotsVerticalIcon />}>
									{nestedGridActions?.map(gridAction => (
										<button 
											key={gridAction.id} 
											id={gridAction.id} 
											className={`dropdown-item ${gridAction.buttonClass ?? ''}`} 
											disabled={gridAction.disabled ? true : false} 
											onClick={() => gridAction.onClick ? gridAction.onClick(state, actions) : null}
										>
											{typeof gridAction.icon === 'function' ? gridAction.icon(state) : gridAction.icon}
											&nbsp;
											{typeof gridAction.label === 'function' ? gridAction.label(state) : gridAction.label}
										</button>
									))}
								</ContextMenu>
							</div>
						}


						{queryState.sorts?.map(sort => (
							<div className='active-column margin-left' key={`column-sort-indicator-${sort.property}`}>
								<div>
									<span className='column-name'>{columns.find(c => queryState.sorts && c.property === sort.property)?.title}</span>
									{sort.direction === 'ASC' ? <ArrowUpIcon /> : <ArrowDownIcon />}
								</div>
								<div onClick={() => clearSort(sort)} className={`clear-button ${loading ? 'disabled' : ''}`}>Clear sort</div>
							</div>
						))}

						{/* If a userSessionStateKey is provided, show the ... more menu in the Grid Header with "Reset to Default" and "Save View" options. */}
						{userSessionStateKey &&
							<>
								<div style={{ flex: 1 }} />
								<ContextMenu>
									<a className='dropdown-item' href='#' onClick={actions.saveViewToUserSessionState}>Save View</a>
									<a className='dropdown-item' href='#' onClick={() => showHideResetGridViewModal(true)} >Reset to Default</a>
								</ContextMenu>
							</>
						}
					</div>

					{viewShowHideColumns ?
						<div className='header-row animated fadeIn column'>
							<div style={{ marginBottom: '5px' }} className='_row'>
								<button style={{ marginRight: '10px' }} type="button" className="btn btn-light btn-extra-sm" onClick={actions.showAllColumns}>Check all</button>
								<button type="button" className="btn btn-light btn-extra-sm" onClick={actions.hideAllColumns}>Uncheck all</button>
							</div>

							<div className='_row show-hide-columns'>
								{sortListByProperty([...columns], 'title').map(col => (
									<div key={`${col.property}-showHide`}>
										<div className="custom-control custom-checkbox">
											<input checked={!col.hide} onChange={e => actions.updateColumn({ ...col, hide: !col.hide })} type="checkbox" className="custom-control-input" id={`${col.property}-toggleShowHide`} />
											<label className="custom-control-label" htmlFor={`${col.property}-toggleShowHide`}>{col.title}</label>
										</div>
									</div>
								))}
							</div>

						</div>
						:
						null
					}

					{viewFilters ?
						<div className='header-row animated fadeIn'>
							<GridColumnFilterSummary />
						</div>
						:
						null
					}

					{gridActions?.filter(gridAction => gridAction.ui).map(gridAction => {
						if (headerViewsState[gridAction.id]) {
							return (
								<div key={gridAction.id} className='header-row animated fadeIn'>
									{gridAction.ui}
								</div>
							)
						} else {
							return null
						}
					})}
				</div>
			</div>
			<Modal
				{...resetGridViewModal}
				modalTitle='Are you sure?'
				footer={
					<React.Fragment>
						<button className='btn btn-light' onClick={() => showHideResetGridViewModal(false)}>Cancel</button>
						<button className='btn btn-warning' onClick={actions.resetViewInUserSessionStateToDefault}>RESET</button>
					</React.Fragment>
				}
			>
				<p>Are you sure you want to reset this grid to its original state?</p>
				<p><b>All of your changes to this grid (filters, sorting, column order) will be lost.</b></p>
			</Modal>
		</>
	)
}
