import { IModelDocumentBase, ModelBase } from './model-base'

/* 
! This name (Tag2Data) comes from original schema naming...not by choice, but by necessity do we use it here...
*/
export interface ITag2DataDocument extends IModelDocumentBase {
    tag2DataId: number
    tagId: number | null
    data: number | null
    noteId: number | null
}

/* 
* Here we enjoy the freedom of our own domain...and name this thing something at least a little more sensible...
*/
export interface ITagJoinObject extends ITag2DataDocument {

}

export class TagJoinObjectModel extends ModelBase implements ITagJoinObject {
    tag2DataId: number = 0

    // Nullable
    tagId: number | null
    data: number | null
    noteId: number | null

    constructor(doc: ITag2DataDocument) {
        super(doc)

        this.tag2DataId = doc.tag2DataId

        this.tagId = doc.tagId
        this.data = doc.data
        this.noteId = doc.noteId

    }
}

export const factory = (doc: ITag2DataDocument): TagJoinObjectModel => {
    return new TagJoinObjectModel(doc)
}