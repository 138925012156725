import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { IPresentersDocument } from '../open-api'
import * as Yup from 'yup'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { AppActionContext } from '../app-store-provider'
import { CheckboxField, EmailField, FileField, TextField, TinyMceField } from './forms'
import { FormikEffect } from './formik-effect'

interface IPresenterFormProps {
    presenterToEdit?: IPresentersDocument
    onSaveSuccess?: () => void
}

export const PresenterForm = (props: IPresenterFormProps) => {
    const { presenterToEdit, onSaveSuccess } = props

    const appAction = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const initialValues = {
        firstName: presenterToEdit?.firstName || '',
        lastName: presenterToEdit?.lastName || '',
        description: presenterToEdit?.description || '',
        title: presenterToEdit?.title || '',
        organization: presenterToEdit?.organization || '',
        email: presenterToEdit?.email || '',
        bio: presenterToEdit?.bio || '',
        presenterImage: '',
        bHidden: presenterToEdit?.bHidden || false,
        bHostsEvents: presenterToEdit?.bHostsEvents || true,
        bArchived: presenterToEdit?.bArchived || false,
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        // TB 20210408 - Description is no longer required because we need the Mission Increase presenter record to have a blank description.
        // The reason for the blank description is that we look for that when sending out a Newsletter.
        //description: Yup.string().required('Required'),
        presenterImage: Yup.mixed().test(
            'presenterImage',
            'Images must have a width of exactly 100px.',
            async function (value) {
                return new Promise<boolean>((resolve) => {
                    if (!value) resolve(true)
                    if (value instanceof File) {
                        const reader = new FileReader()
                        const img = new Image()

                        img.onload = () => resolve(img.width === 100)

                        reader.onload = e => {
                            if (typeof e.target?.result === 'string') img.src = e.target.result
                        }

                        reader.readAsDataURL(value)
                    } else {
                        resolve(false)
                    }
                })
            }
        )
    })

    const [imagePreview, setImagePreview] = useState<unknown>()
    const processImageForPreview = (image?: string) => {
        if (image) {
            const reader = new FileReader()
            reader.onload = (e) => setImagePreview(e.target?.result)
            // @ts-ignore
            reader.readAsDataURL(image)
        } else {
            setImagePreview(undefined)
        }
    }

    useEffect(() => {
        if (presenterToEdit && presenterToEdit.image) setImagePreview(presenterToEdit.image)
    }, [presenterToEdit])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {

                if (presenterToEdit) {
                    await makeHttpRequestWithUi({
                        request: appAction.PresentersApi.apiPresentersIdUpdatePost(
                            presenterToEdit.presenterId,
                            values.firstName,
                            values.lastName,
                            presenterToEdit.presenterId,
                            values.title,
                            values.organization,
                            values.description,
                            values.email,
                            values.bHidden,
                            values.bio,
                            values.bHostsEvents,
                            values.bArchived,
                            values.presenterImage,
                        ),
                        toastSuccessMessage: 'Successfully updated presenter.',
                        toastErrorMessage: 'There was an error updating presenter.'
                    })
                } else {
                    await makeHttpRequestWithUi({
                        request: appAction.PresentersApi.apiPresentersPost(
                            values.firstName,
                            values.lastName,
                            values.title,
                            values.organization,
                            values.description,
                            values.email,
                            values.bHidden,
                            values.bio,
                            values.bHostsEvents,
                            values.bArchived,
                            values.presenterImage,
                        ),
                        toastSuccessMessage: 'Successfully created presenter.',
                        toastErrorMessage: 'There was an error creating presenter.'
                    })
                }

                if (onSaveSuccess) onSaveSuccess()
            }}
        >
            {formikProps => {
                return (
                    <Form>
                        <FormikEffect formikProps={formikProps} onChange={(prev, next) => {
                            if (prev.presenterImage !== next.presenterImage) {
                                processImageForPreview(next.presenterImage)
                            }
                        }} />

                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }}>
                                <TextField fieldProps={{ name: 'firstName', label: 'First Name' }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <TextField fieldProps={{ name: 'lastName', label: 'Last Name' }} />
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div className='mr-2' style={{ flex: 1 }}>
                                <TextField fieldProps={{ name: 'organization', label: 'Organization' }} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <TextField fieldProps={{ name: 'title', label: 'Title Name' }} />
                            </div>
                        </div>

                        <EmailField fieldProps={{ name: 'email', label: 'Email' }} />

                        <TinyMceField fieldProps={{ name: 'description', label: 'Description' }} />

                        <div className='d-flex mt-2'>
                            <div className='mr-4'>
                                <CheckboxField fieldProps={{ name: 'bHostsEvents', label: 'Event Presenter', labeltooltip: 'Uncheck this box if this person will not be hosting events or coaching appointments.' }} />
                            </div>
                            <div className='mr-4'>
                                <CheckboxField fieldProps={{ name: 'bHidden', label: 'Hidden' }} />
                            </div>
                            <div className='mr-4'>
                                <CheckboxField fieldProps={{ name: 'bArchived', label: 'Archived' }} />
                            </div>
                        </div>

                        <label className='mt-2'>Image (images must have a width of 100px and be either a JPG or JPEG file)</label>
                        <div className='d-flex flex-column' style={{ width: '50%' }}>
                            <FileField fieldProps={{ name: 'presenterImage', label: 'Image' }} />
                            {typeof imagePreview === 'string' ?
                                <img style={{ width: '100px', objectFit: 'contain' }} src={imagePreview} alt='Preview of uploaded image' />
                                :
                                null
                            }
                        </div>


                        <button style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button>
                    </Form>
                )
            }}
        </Formik>
    )
}