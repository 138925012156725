import React, { useContext } from "react"
import { AppStateContext } from "../../app-store-provider"
import { FormikSelectField, IFormikSelectFieldProps } from './formik-select-field'

interface IFormikCountrySelectFieldProps extends IFormikSelectFieldProps { }
export const FormikCountrySelectField = (props: IFormikCountrySelectFieldProps) => {
    const appState = useContext(AppStateContext)!
    return <FormikSelectField
        {...props}
        options={appState.countries.map(country => ({ label: `${country.country}`, value: `${country.countryId}` }))}
    />
}