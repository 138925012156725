import { useContext, useState, useEffect } from "react"
import { IDefaultProps } from "./component-definitions"
import { ITrainerEvalSummaryFullDocument } from '../open-api'
import { TrainerEval } from './trainer-eval'
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import {Helmet} from "react-helmet"

interface ITrainerEvalsPrintProps extends IDefaultProps {
	eventContentId?: string
}

export const TrainerEvalsPrint = (props: ITrainerEvalsPrintProps) => {
    const { eventContentId } = props
    const {SeminarTrainerEvalApi} = useContext(AppActionContext)!
    const [trainerEvals, setTrainerEvals] = useState<ITrainerEvalSummaryFullDocument[]>([])
    const [titleText1, setTitleText1] = useState<string>('')
    const [titleText2, setTitleText2] = useState<string>('')

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchData = async () => {
        const results = await SeminarTrainerEvalApi.apiSeminarTrainerEvalByEventContentEventContentIdFullGet(parseInt(eventContentId!))
        setTrainerEvals(results.data)
    }

    useEffect(() =>{
        fetchData()
        setTimeout(() => {
            window.print()
        }, 2000)
    }, [])

    useEffect(() => {
        if (trainerEvals && trainerEvals.length > 0) {
            setTitleText1(`${trainerEvals[0].eventContentMonth}/${trainerEvals[0].eventContentYear}`)
            setTitleText2(`${trainerEvals[0].eventContentTitle}`)
        }
    }, [trainerEvals])

    return (
        <>

            <Helmet>
                <title>{titleText1} {titleText2} Workshop Evals</title>
            </Helmet>

            <h3 className='ml-2 mb-4'>Area Director Evals for {titleText1}<br />{titleText2} Workshop</h3>
            {trainerEvals.map(x => <TrainerEval key={x.seminarTrainerEvalId} trainerEval={x}  />)}
        </>
    )
}
