import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { AppActionContext } from '../app-store-provider'
import { IDefaultProps } from './component-definitions'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { IEventNewsSettingDocument } from '../open-api'
import { filterGridListItems, sortListBySorts } from '../services/helpers'
import { Helmet } from 'react-helmet'
import { CalendarIcon, PencilIcon } from "../assets"
import { Grid } from './grid'
import { gridReducer } from '../stores/grid-reducer'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { SquareDeleteIcon } from './partials'
import { DefaultGridCellDisplay, GridActionCell } from './grid-cell-displays'
import { Modal } from './modal'
import { EventNewsSettingForm } from './event-news-setting-form'
import { Loading } from './loading'

interface IEventNewsSettings extends IDefaultProps { }

export const EventNewsSettings = (props: IEventNewsSettings) => {
    const { EventNewsSettingApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [settings, setSettings] = useState<IEventNewsSettingDocument[]>([])
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [settingToEdit, setSettingToEdit] = useState<IEventNewsSettingDocument>()
    const [settingFormModal, showHideSettingFormModal] = useModal()
    const [deleteSettingModal, showHideDeleteSettingModal] = useModal()
    const [loading, setLoading] = useState(false)

    const fetchSettings = async () => {
        setLoading(true)
        const {data} = await makeHttpRequestWithUi({
            request: EventNewsSettingApi.apiEventNewsSettingGet(),
            toastErrorMessage: 'There was an error retrieving the stipulation templates.',
            disableSuccessToast: true,
            disableLoading: true,
        })
        setLoading(false)

        setSettings(data)
    }

    useEffect(() => {
        fetchSettings()
    }, [])

    const dataSource: GridDataFetch<typeof settings> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.eventNewsSettingId!.toString(),
                values: {
                    ...o,
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('EventNews > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    const editSetting = useCallback(async (eventNewsSettingId: string) => {
        const editQuery = await makeHttpRequestWithUi({
            request: EventNewsSettingApi.apiEventNewsSettingIdGet(parseInt(eventNewsSettingId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the setting to edit.'
        })

        setSettingToEdit(editQuery.data)

        showHideSettingFormModal(true)
    }, [])

    const initialGridState: IGridState = {
        ...defaultGridState,
        gridActions: [
            {
                id: 'addSetting',
                label: 'Add Setting',
                onClick: () => showHideSettingFormModal(true),
                sortOrder: 0,
            }
        ],
        rowActions: {
            editSetting: {
                id: 'editSetting',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    editSetting(row.id)
                },
                tooltipText: 'Edit Setting',
                icon: <PencilIcon />
            },
            deleteSetting: {
                id: 'deleteSetting',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideDeleteSettingModal()
                },
                tooltipText: 'Delete Setting',
                icon: <SquareDeleteIcon />
            },
        },
        columns: [
            {
                property: 'month',
                type: 'number',
                width: 150,
                allowFilters: true,
                title: 'Month',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'year',
                type: 'number',
                width: 150,
                allowFilters: true,
                title: 'Year',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            }

        ],
        dataSource,
        rowSelectEnabled: false,
        usingLocalData: true,
        disabledPagination: true,
        disableExport: true,
        respectGlobalCommunityFilter: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            editSetting(row.id)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, settings ?? [])

    useEffect(() => {
        gridActions.doFetch()
    }, [settings])

    return (
		<React.Fragment>
			<Helmet>
				<title>Global Event News Settings</title>
			</Helmet>

			<div className='d-flex flex-column' style={{ height: '100vh' }}>
				<div className='m-2 d-flex align-items-center'>
					<CalendarIcon style={{ width: '25px', height: '25px' }} />
					<h3 className='ml-2'>Global Event News Settings</h3>
				</div>
                {loading ? 
                <Loading />
                :
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />}
			</div>

			<Modal
				{...deleteSettingModal}
				modalTitle='Delete News Setting Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteSettingModal(false)}>Cancel</button>
						<button
							className={`btn btn-danger`}
							onClick={async () => {
								if (!selectedRow) return
								await makeHttpRequestWithUi({
									request: EventNewsSettingApi.apiEventNewsSettingIdDelete(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Successfully deleted the news setting.',
									toastErrorMessage: 'There was an error deleting the news setting.'
								})
								showHideDeleteSettingModal(false)
								await fetchSettings()
							}}
						>
							Delete
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setSelectedRow(undefined)}
			>
				Are you sure you want to delete this news setting?
			</Modal>

			<Modal
				{...settingFormModal}
				modalTitle={`${settingToEdit ? 'Edit' : 'Add'} Setting`}
				size='xl'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setSettingToEdit(undefined)
				}}
				dismissible={false}
			>
				<EventNewsSettingForm 
                    settingToEdit={settingToEdit} 
                    afterSave={async () => {
                        showHideSettingFormModal(false)
                        await fetchSettings()
                    }}  />
			</Modal>
		</React.Fragment>
	)
}

function setSelectedRow(row: IGridListItem) {
    throw new Error('Function not implemented.')
}
