import React, { useContext, useState } from 'react'
import { IDefaultProps } from './component-definitions'
import { ReactComponent as EaselIcon } from '../assets/easel.svg'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IPresentersDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridRowAction, IGridListItem, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell } from './grid-cell-displays'
import { SquareDeleteIcon } from './partials'
import { useEffect } from 'react'
import { PresenterModel } from '../models/presenter'
import { Modal } from './modal'
import { App, UserModel, UserPermissionLevel } from '../models/user'
import { PresenterForm } from './presenter-form'

interface IPresentersProps extends IDefaultProps { }

export const Presenters = (props: IPresentersProps) => {
    const { } = props

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [presenters, setPresenters] = useState<IPresentersDocument[]>()

    const fetchPresenters = async () => {
        const presentersQuery = await makeHttpRequestWithUi({
            request: appActions.PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch presenters.'
        })

        setPresenters(presentersQuery.data)
    }

    useEffect(() => {
        fetchPresenters()
    }, [])

    const dataSource: GridDataFetch<IPresentersDocument[]> = async (queryState, _presenters) => {
        if (!_presenters) return { count: 0, rows: [] }

        const _presentersCopy = [..._presenters]

        if (queryState.sorts) sortListBySorts(_presentersCopy, queryState.sorts)
        let rows = _presentersCopy.map(PresenterModel.toGridListItem)
        if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

        return {
            count: _presentersCopy.length,
            rows,
        }
    }

    const rowActions: { [id: string]: IGridRowAction } = {}

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const [presenterToEdit, setPresenterToEdit] = useState<IPresentersDocument>()
    const [presenterFormModal, showHidePresenterFormModal] = useModal()
    const editPresenter = async (presenterId: number) => {
        const presenterToEditQuery = await makeHttpRequestWithUi({
            request: appActions.PresentersApi.apiPresentersIdGet(presenterId),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the presenter to edit.'
        })

        setPresenterToEdit(presenterToEditQuery.data)
        showHidePresenterFormModal(true)
    }

    rowActions.editPresenter = {
        id: 'editPresenter',
        action: async (options) => {
            options.e.stopPropagation()
            setSelectedRow(options.row)
            editPresenter(parseInt(options.row.id))
        },
        icon: <PencilIcon />,
        tooltipText: 'Edit Presenter',
        disabled: !UserModel.checkPermissionLevelForUser(App.EventManager, UserPermissionLevel.Modify, appState.currentUser)
    }

    const [deletePresenterModal, showHideDeletePresenterModal] = useModal()
    rowActions.deletePresenter = {
        id: 'deletePresenter',
        action: async (options) => {
            options.e.stopPropagation()
            setSelectedRow(options.row)
            showHideDeletePresenterModal(true)
        },
        icon: <SquareDeleteIcon />,
        tooltipText: 'Delete Presenter',
        disabled: !UserModel.checkPermissionLevelForUser(App.EventManager, UserPermissionLevel.Modify, appState.currentUser)
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        queryState: {
            ...defaultGridState.queryState,
            filters: [
                {
                    id: 'bActive-==',
                    enabled: true,
                    property: 'bActive',
                    operator: '==',
                    value: true,
                },
            ]
        },
        columns: [
            {
                property: 'firstName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'First Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'lastName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Last Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 85,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
            {
                property: 'title',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Title',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'organization',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Organization',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'email',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Email',
                render: EmailCellDisplay,
            },
            {
                property: 'bActive',
                type: 'boolean',
                width: 100,
                allowFilters: true,
                title: 'Active',
                render: DefaultGridCellDisplay,
                align: 'center',
                hide: true,
            },
        ],
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        disableExport: true,
        gridActions: [
            {
                id: 'newPresenter',
                label: 'Add Presenter',
                onClick: () => showHidePresenterFormModal(true),
                disabled: !UserModel.checkPermissionLevelForUser(App.EventManager, UserPermissionLevel.Modify, appState.currentUser)
            },
        ],
        rowActions,
        rowDoubleClicked: async (row) => {
            editPresenter(parseInt(row.id))
        },
        rowSelectEnabled: false,
    }

    const [state, actions] = useGrid(gridReducer, initialGridState, presenters)

    useEffect(() => {
        actions.doFetch()
    }, [presenters])

    return (
        <React.Fragment>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <EaselIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Event Presenters</h3>
                </div>

                <Grid actions={actions} state={state} style={{ flex: 1, height: 'unset' }} />
            </div>

            <Modal
                {...deletePresenterModal}
                modalTitle='Delete Presenter Confirmation'
                footer={
                    <React.Fragment>
                        <button className='btn btn-secondary' onClick={() => showHideDeletePresenterModal(false)}>Cancel</button>
                        <button
                            className={`btn btn-danger`}
                            onClick={async () => {
                                if (!selectedRow) return
                                await makeHttpRequestWithUi({
                                    request: appActions.PresentersApi.apiPresentersIdArchiveDelete(parseInt(selectedRow.id)),
                                    toastSuccessMessage: 'Successfully deleted the presenter.',
                                    toastErrorMessage: 'There was an error deleting the presenter.'
                                })
                                showHideDeletePresenterModal(false)
                                fetchPresenters()
                            }}
                        >
                            Delete
						</button>
                    </React.Fragment>
                }
                _onModalHidden={() => setSelectedRow(undefined)}
            >
                Are you sure you want to delete this presenter? This cannot be reversed!
			</Modal>

            <Modal
                {...presenterFormModal}
                modalTitle={!!presenterToEdit ? '' : 'New Presenter'}
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setPresenterToEdit(undefined)
                }}
                size='xl'
                dismissible={false}
            >
                {presenterFormModal.show ?
                    <PresenterForm presenterToEdit={presenterToEdit} onSaveSuccess={() => {
                        showHidePresenterFormModal(false)
                        fetchPresenters()
                    }} />
                    :
                    null
                }
            </Modal>

        </React.Fragment>
    )
}