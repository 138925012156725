import React, { useState, useEffect, useContext } from 'react'
import { IMinistrySummaryResponseModelDocument, IApprovalStatusDocument } from '../open-api'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { MinistryInfoContext } from './ministry-info'
import { Modal } from './modal'
import { Loading } from './loading'
import { UserModel } from '../models/user'
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { MinistryFiles } from './ministry-files'
import { UNITED_STATES_COUNTRY_ID } from '../constants'

//import { useDownloadAndViewFile } from '../services/hooks'

/* 
	// TODO: fetch the ministries files
	// TODO: set up basic Grid to show ministry files with appropriate columns
	// TODO: add grouping functionality to Grid
*/


interface IMinistryInfoProfileDocumentsPageProps {
	pageId: number
	pageCompletionStatusId: number | null
	pageApprovalStatus?: IApprovalStatusDocument
	ministry: IMinistrySummaryResponseModelDocument
	pageApproved: () => Promise<void>
	pageDenied: () => Promise<void>
}
export const MinistryInfoProfileDocumentsPage = (props: IMinistryInfoProfileDocumentsPageProps) => {
	const { ministry, pageApproved, pageDenied, pageCompletionStatusId, pageApprovalStatus, pageId } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [approvePageModal, showHideApprovePageModal] = useModal()
	const [denyPageModal, showHideDenyPageModal] = useModal()

	const [state, setState] = useState<{
		enablePageApprove: boolean
		enablePageDeny: boolean
	}>({
		enablePageApprove: false,
		enablePageDeny: false,
	})

	const { enablePageApprove, enablePageDeny } = state

	useEffect(() => {
		/*
			Admin\modules\MinistryInfo\New\profile.js 2176
			toggle 'Approve Page' / 'Deny Page' disabled states
		*/
		let _enablePageApprove = state.enablePageApprove
		let _enablePageDeny = state.enablePageDeny

		switch (pageCompletionStatusId) {
			case -1:
				_enablePageApprove = true
				_enablePageDeny = false
				break
			case 1:
				_enablePageApprove = true
				_enablePageDeny = true
				break
			case 2:
				_enablePageApprove = false
				_enablePageDeny = true
				break
			default:
				_enablePageApprove = UserModel.userIsSuperUser(appState.currentUser)
				break
		}

		setState({ ...state, enablePageApprove: _enablePageApprove, enablePageDeny: _enablePageDeny })

		//eslint-disable-next-line
	}, [pageCompletionStatusId])

	useEffect(() => {
		ministryInfoActions.fetchMinistryFiles()
		// eslint-disable-next-line
	}, [])

	if (!ministryInfoState.ministryFiles || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<div className='mb-3 d-flex'>
				{enablePageApprove ?
					<button
						type='button'
						className='btn btn-light ml-1'
						onClick={async () => {
							showHideApprovePageModal(true)
						}}
					>
						Approve Page
					</button>
					:
					null
				}
				{enablePageDeny ? <button type='button' className='btn btn-light ml-1' onClick={() => showHideDenyPageModal(true)}>Deny Page</button> : null}
				{pageApprovalStatus && enablePageDeny ? <i className='ml-1 d-flex align-items-center'>{pageApprovalStatus.pageName} approved {pageApprovalStatus.completeInfo}</i> : null}
			</div>
			<MinistryFiles 
				ministryId={ministry.ministryId} 
				ministryFiles={ministryInfoState.ministryFiles} 
				hideMissingDocumentsBtn={ministry.physicalCountryId !== UNITED_STATES_COUNTRY_ID}
				afterSave={async () => {
					await ministryInfoActions.fetchMinistryFiles()
				}} />

			<Modal
				{...approvePageModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideApprovePageModal(false)} >Cancel</button>
						<button type="button" className="btn btn-primary" onClick={async () => {
							await makeHttpRequestWithUi({
								request: appActions.MinistryProfileApi.apiMinistryProfileIdApproveProfilePagePost(ministry.ministryId, { pageId: pageId }),
								toastErrorMessage: 'Encountered an error approving page.',
								toastSuccessMessage: 'Successfully approved page.',
							})
							await pageApproved()

							showHideApprovePageModal(false)
						}}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to approve this page?
			</Modal>

			<Modal
				{...denyPageModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-primary" onClick={async () => {
							await makeHttpRequestWithUi({
								request: appActions.MinistryProfileApi.apiMinistryProfileIdDenyProfilePagePost(ministry.ministryId, { pageId: pageId }),
								toastErrorMessage: 'Encountered an error denying page.',
								toastSuccessMessage: 'Successfully denied page.',
							})
							await pageDenied()

							showHideDenyPageModal(false)
						}}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to deny this page?
			</Modal>
		</React.Fragment>
	)
}