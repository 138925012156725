import { IAppState, IUserSessionState, IToast, IAlert } from './app-definitions'
import { navigate } from '@reach/router'
import { TagModel } from '../models/tag'
import { TrainingAreaModel } from '../models/training-area'
import { sortListByProperty } from '../services/helpers'
import { IContactEditOptionsModelDocument, IInvolvementMultipliersModelDocument, IMailTypeDocument, IMiAppsDocument, IMiBranchModelDocument, IMiBranchSummaryDocument, IMiTrainingAreasModelDocument, IMiUserModelDocument, IMiUserSummaryDocument, IMeAuthModelDocument, IPresentersDocument, IZCountrysDocument, IZNteeCodesDocument, IZStatesDocument } from '../open-api'
import { IBranchRegion, IBranchType } from '../models/community'
import { Path } from '../components/dashboard'

export enum AppActionType {
	loading = 'loading',
	doneLoading = 'doneLoading',
	setTags = 'setTags',
	bootstrap = 'bootstrap',
	navigate = 'navigate',
	logout = 'logout',
	login = 'login',
	setTrainingAreas = 'setTrainingAreas',
	setStates = 'setStates',
	setsetInvolvementMultipliers = 'setInvolvementMultipliers',
	setGlobalCommunityContext = 'setGlobalCommunityContext',
	enableGlobalCommunityContextDropdown = 'enableGlobalCommunityContextDropdown',
	disableGlobalCommunityContextDropdown = 'disableGlobalCommunityContextDropdown',
	ministryManagementAction = 'ministryManagementAction',
	tagManagementAction = 'tagManagementAction',
	setSelectedMainNavItem = 'setSelectedMainNavItem',
	setUserSessionState = 'setUserSessionState',
	addToast = 'addToast',
	removeToast = 'removeToast',
	addAlert = 'addAlert',
	removeAlert = 'removeAlert',
	setUsers = 'setUsers',
	setUsersSummaries = 'setUsersSummaries',
	setBranches = 'setBranches',
	setCommunitySummaries = 'setCommunitySummaries',
	setNteeCodes = 'setNteeCodes',
	setBranchTypes = 'setBranchTypes',
	setBranchRegions = 'setBranchRegions',
	setAppPermissions = 'setAppPermissions',
	setCountries = 'setCountries',
	setInvolvementMultipliers = 'setInvolvementMultipliers',
	setPresenters = 'setPresenters',
	setMailTypes = 'setMailTypes',
	setGlobalSearchTerm = 'setGlobalSearchTerm',
	setContactEditOptions = 'setContactEditOptions'
}

interface IPayloadLessAction {
	type:
	AppActionType.loading |
	AppActionType.doneLoading |
	AppActionType.logout |
	AppActionType.enableGlobalCommunityContextDropdown |
	AppActionType.disableGlobalCommunityContextDropdown
}

interface IBootstrapAction {
	type: AppActionType.bootstrap
	payload: {
		tags?: TagModel[]
		user?: IMiUserModelDocument
		userSessionState?: IUserSessionState
		userAuth?: IMeAuthModelDocument
		selectedMainNavItem: Path
	}
}

interface ILoginAction {
	type: AppActionType.login
	payload: {
		tags?: TagModel[]
		user?: IMiUserModelDocument
		userSessionState?: IUserSessionState
		userAuth?: IMeAuthModelDocument
	}
}

interface ISetTagsAction {
	type: AppActionType.setTags
	payload: TagModel[]
}

interface IAddToastAction {
	type: AppActionType.addToast
	payload: IToast
}

interface IRemoveToastAction {
	type: AppActionType.removeToast
	payload: IToast
}

interface IAddAlertAction {
	type: AppActionType.addAlert
	payload: IAlert
}

interface IRemoveAlertAction {
	type: AppActionType.removeAlert
	payload: IAlert
}

interface ISetTrainingAreasAction {
	type: AppActionType.setTrainingAreas
	payload: TrainingAreaModel[]
}

interface ISetStatesAction {
	type: AppActionType.setStates
	payload: IZStatesDocument[]
}

interface INavigateAction {
	type: AppActionType.navigate
	payload: {
		url: string
	}
}

interface ISetGlobalCommunityContextAction {
	type: AppActionType.setGlobalCommunityContext
	payload: IMiBranchModelDocument | null
}

interface ISetSelectedMainNavItemAction {
	type: AppActionType.setSelectedMainNavItem
	payload: Path
}

interface ISetUserSessionStateAction {
	type: AppActionType.setUserSessionState
	payload: IUserSessionState
}

interface ISetUsersAction {
	type: AppActionType.setUsers
	payload: IMiUserModelDocument[]
}

interface ISetUsersSummariesAction {
	type: AppActionType.setUsersSummaries
	payload: IMiUserSummaryDocument[]
}


interface ISetBranchesAction {
	type: AppActionType.setBranches
	payload: IMiBranchModelDocument[]
}

interface ISetNTeeCodesAction {
	type: AppActionType.setNteeCodes
	payload: IZNteeCodesDocument[]
}

interface ISetAppPermissionsAction {
	type: AppActionType.setAppPermissions
	payload: IMiAppsDocument[]
}

interface ICommunitySummariesAction {
	type: AppActionType.setCommunitySummaries
	payload: IMiBranchSummaryDocument[]
}

interface ISetBranchTypesAction {
	type: AppActionType.setBranchTypes
	payload: IBranchType[]
}

interface ISetBranchRegionsAction {
	type: AppActionType.setBranchRegions
	payload: IBranchRegion[]
}

interface ISetCountriesAction {
	type: AppActionType.setCountries
	payload: IZCountrysDocument[]
}

interface ISetInvolvementMultipliersAction {
	type: AppActionType.setInvolvementMultipliers
	payload: IInvolvementMultipliersModelDocument
}

interface ISetPresentersAction {
	type: AppActionType.setPresenters
	payload: IPresentersDocument[]
}

interface ISetMailTypesAction {
	type: AppActionType.setMailTypes
	payload: IMailTypeDocument[]
}

interface ISetGlobalSearchTermAction {
	type: AppActionType.setGlobalSearchTerm
	payload: {
		globalSearchTerm?: string
	}
}

interface ISetContactEditOptionsAction {
	type: AppActionType.setContactEditOptions
	payload: IContactEditOptionsModelDocument
}

export type AppAction =
	IPayloadLessAction |
	IBootstrapAction |
	ISetTagsAction |
	ISetTrainingAreasAction |
	ILoginAction |
	INavigateAction |
	ISetGlobalCommunityContextAction |
	ISetSelectedMainNavItemAction |
	ISetUserSessionStateAction |
	IAddToastAction |
	IRemoveToastAction |
	IAddAlertAction |
	IRemoveAlertAction |
	ISetUsersAction |
	ISetUsersSummariesAction |
	ISetBranchesAction |
	ISetNTeeCodesAction |
	ISetStatesAction |
	ISetAppPermissionsAction |
	ICommunitySummariesAction |
	ISetBranchTypesAction |
	ISetBranchRegionsAction |
	ISetCountriesAction |
	ISetInvolvementMultipliersAction |
	ISetPresentersAction |
	ISetMailTypesAction |
	ISetGlobalSearchTermAction |
	ISetContactEditOptionsAction

export function appReducer(state: IAppState, action: AppAction): IAppState {
	let newState = state

	switch (action.type) {
		case AppActionType.bootstrap:
			newState = {
				...newState,
				bootstrapped: true,
				tags: action.payload.tags,
				mainNav: {
					...newState.mainNav,
					selectedItem: action.payload.selectedMainNavItem
				},
				currentUser: action.payload.user,
				currentAuth: action.payload.userAuth,
			}

			newState = restoreUserSessionState(newState, action.payload.userSessionState)

			break
		case AppActionType.navigate:
			const url = action.payload && action.payload.url
			navigate(url)
			break
		case AppActionType.login:
			newState = {
				...newState,
				tags: action.payload.tags,
				currentUser: action.payload.user,
				currentAuth: action.payload.userAuth,
			}

			newState = restoreUserSessionState(newState, action.payload.userSessionState)

			navigate('/')
			break
		case AppActionType.setTags:
			newState = { ...newState, tags: action.payload }
			break
		case AppActionType.addToast:
			newState = { ...newState, toasts: [...newState.toasts || [], action.payload] }
			break
		case AppActionType.removeToast:
			newState = { ...newState, toasts: newState.toasts ? [...newState.toasts].filter(toast => toast.id !== action.payload.id) : [] }
			break
		case AppActionType.addAlert:
			newState = { ...newState, alerts: [...newState.alerts || [], action.payload] }
			break
		case AppActionType.removeAlert:
			newState = { ...newState, alerts: newState.alerts ? [...newState.alerts].filter(alert => alert.id !== action.payload.id) : [] }
			break
		case AppActionType.setTrainingAreas:
			newState = { ...newState, trainingAreas: sortListByProperty(action.payload, 'trainingAreaAbbr'), activeTrainingAreas: sortListByProperty(action.payload.filter(x => x.bActive === true), 'trainingAreaAbbr') }
			break
		case AppActionType.setStates:
			newState = { ...newState, states: action.payload }
			break
		case AppActionType.setGlobalCommunityContext:
			newState = { ...newState, globalCommunityContext: action.payload }
			break
		case AppActionType.setSelectedMainNavItem:
			newState = { ...newState, mainNav: { ...newState.mainNav, selectedItem: action.payload } }
			break
		case AppActionType.enableGlobalCommunityContextDropdown:
			newState = { ...newState, globalCommunityContextDisabled: false }
			break
		case AppActionType.disableGlobalCommunityContextDropdown:
			newState = { ...newState, globalCommunityContextDisabled: true }
			break
		case AppActionType.setUserSessionState:
			newState = { ...newState, currentUserSessionState: action.payload }
			break
		case AppActionType.setUsers:
			newState = { ...newState, users: action.payload }
			break
		case AppActionType.setUsersSummaries:
			newState = { ...newState, userSummaries: action.payload }
			break
		case AppActionType.setBranches:
			newState = {
				...newState,
				activeBranches: sortListByProperty(action.payload.filter(b => b.bActive && !b.bHidden), 'branchAbbr'),
				globalCommunityContext: action.payload.find(b => b.branchId === newState.globalCommunityContext?.branchId)
			}
			break
		case AppActionType.setNteeCodes:
			newState = { ...newState, nteeCodes: action.payload }
			break
		case AppActionType.setAppPermissions:
			newState = { ...newState, appPermissions: action.payload }
			break
		case AppActionType.setCommunitySummaries:
			newState = { ...newState, communitySummaries: action.payload }
			break
		case AppActionType.setBranchTypes:
			newState = { ...newState, branchTypes: action.payload }
			break
		case AppActionType.setBranchRegions:
			newState = { ...newState, branchRegions: action.payload }
			break
		case AppActionType.setCountries:
			newState = { ...newState, countries: action.payload }
			break
		case AppActionType.setInvolvementMultipliers:
			newState = { ...newState, involvementMultipliers: action.payload }
			break
		case AppActionType.setPresenters:
			newState = { ...newState, presenters: action.payload }
			break
		case AppActionType.setMailTypes:
			newState = { ...newState, mailTypes: action.payload }
			break
		case AppActionType.setGlobalSearchTerm: {
			newState = { ...newState, ...action.payload }
			break
		}
		case AppActionType.setContactEditOptions:
			newState = { ...newState, contactEditOptions: action.payload }
			break
	}

	return newState
}

const restoreUserSessionState = (newState: IAppState, userSessionState?: IUserSessionState) => {
	if (userSessionState) {
		newState = {
			...newState,
			currentUserSessionState: userSessionState,
			globalCommunityContext: userSessionState.globalCommunityContextBranchId ? newState.activeBranches.find(branch => branch.branchId === userSessionState.globalCommunityContextBranchId) : null
		}
	}

	/* 
		Turn on slim tables by default.

		If slimTables setting has not been set for a user then set it to true.
	*/
	if (newState.currentUserSessionState?.preferences?.slimTables === undefined) {
		newState.currentUserSessionState = {
			...newState.currentUserSessionState ? newState.currentUserSessionState : {},
			preferences: {
				...newState.currentUserSessionState?.preferences ? newState.currentUserSessionState.preferences : {},
				slimTables: true
			}
		}
	}

	return newState
}