import { IGrantRequestSummaryDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { useContext } from "react"
import { formatCurrency } from "../services/helpers"

interface IGrantRequestFormProps extends IDefaultProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
}

export const GrantRequestFormSummaryMinistry = (props: IGrantRequestFormProps) => {
    const { grantRequestToEdit } = props
    const { MailEngineApi } = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    return (

        <div className="d-flex flex-column vh-100">
            <div className="row pl-2 mt-3" style={{ overflow: 'hidden' }}>
                <div className="col-sm-6">
                    <div className="mb-2">
                        <label>Area Director Eval Score:</label> {grantRequestToEdit.adEval}
                    </div>
                    <div className="mb-2">
                        <label>Workshop Att (lifetime):</label> {grantRequestToEdit.eventsAttended} of {grantRequestToEdit.possibleEvents}
                    </div>
                    <div className="mb-2">
                        <label>Workshop Att (12 mos.):</label> {grantRequestToEdit.eventsAttended12Mo} of {grantRequestToEdit.possibleEvents12Mo}
                    </div>
                    <div className="mb-2">
                        <label>Total Consulting Hours:</label> {grantRequestToEdit.consultingHours}
                    </div>
                    <div className="mb-2">
                        <label>Involvement Score:</label> {grantRequestToEdit.involvement}
                    </div>
                    <div className="mb-2">
                        <label>IRS Ruling Year:</label> {grantRequestToEdit.rulingYear}
                    </div>
                    <div className="mb-2">
                        <label>Joined MI:</label> {grantRequestToEdit.startYear}
                    </div>
                    <div className="mb-2">
                        <label>Board Giving:</label> {grantRequestToEdit.boardDonated ? `${grantRequestToEdit.boardDonated}%` : 'n/a'}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="mb-2">
                        <label>LY Total Income:</label> {formatCurrency(grantRequestToEdit.lastYearIncome)}
                    </div>
                    <div className="mb-2">
                        <label>LY Total Expenses:</label> {formatCurrency(grantRequestToEdit.lastYearExpenses)}
                    </div>
                    <div className="mb-2">
                        <label>FT Staff:</label> {grantRequestToEdit.fullTimeStaff}
                    </div>
                    <div className="mb-2">
                        <label>PT Staff:</label> {grantRequestToEdit.partTimeStaff}
                    </div>
                    <div className="mb-2">
                        <label>Prior MI Grants:</label> {formatCurrency(grantRequestToEdit.priorGrants)}
                    </div>
                    <div className="mb-2">
                        <label>Total Granted:</label> {formatCurrency(grantRequestToEdit.grantAmount)}
                    </div>
                    <div className="mb-2">
                        <label>Total Matched:</label> {formatCurrency(grantRequestToEdit.totalRaised)}
                    </div>
                </div>
            </div>
        </div>
    )
}