import moment from 'moment'
import { IEventNewsDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class EventNewsModel {
	public static toGridListItem = (eventNews: IEventNewsDocument): IGridListItem => {
		return {
			id: eventNews.eventNewsId.toString(),
			values: {
				...eventNews,
				monthYear: moment(`${eventNews.month} ${eventNews.year}`, 'M YYYY').toDate(),
				// sent: !!eventNews.dateSent,
				// sent: true,
			},
		}
	}
} 