import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IGrantRequestSummaryDocument, IMinistryGrantFundingSummaryDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { GRANT_REQUEST_STATUS } from "../constants"
import { GrantFormFunding } from "./grant-form-funding"
import { uuidv4 } from "../services/helpers"
import { GrantRequestPayments } from "./grant-request-payments"

interface IGrantRequestFormFundingProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    afterSave?: () => Promise<void>
}

export const GrantRequestFormFunding = (props: IGrantRequestFormFundingProps) => {
    const { grantRequestToEdit, afterSave } = props
    const { GrantRequestApi, MinistryGrantFundingApi, addAlert } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [fundingSources, setFundingSources] = useState<IMinistryGrantFundingSummaryDocument[]>([])

    const fetchFunding = async () => {
        if (grantRequestToEdit.grantGiftId && grantRequestToEdit.grantGiftId > 0) {
            // In 2.0 funding is attached to the Grant Gift record.
            const { data } = await makeHttpRequestWithUi({
                request: MinistryGrantFundingApi.apiGrantGiftGrantGiftIdFundingSourcesGet(grantRequestToEdit.grantGiftId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching funding sources.',
            })
            setFundingSources(data)
        } else if (grantRequestToEdit.grantId && grantRequestToEdit.grantId > 0) {
            // Keep things backwards compatible with 1.0.
            const { data } = await makeHttpRequestWithUi({
                request: MinistryGrantFundingApi.apiGrantGiftGrantGiftIdFundingSourcesGet(grantRequestToEdit.grantId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching funding sources.',
            })
            setFundingSources(data)
        }
        else {
            addAlert({
                id: uuidv4(),
                title: 'Error',
                body: 'No valid grantId or grantGiftId provided.',
            })
        }
    }

    useEffect(() => {
        fetchFunding()
    }, [])

    return (
        <>
            <div className='m-2'>
                <h5>Payments</h5>
                <GrantRequestPayments
                    fundingSources={fundingSources}
                    grantRequestToEdit={grantRequestToEdit}
                    afterSave={afterSave}
                />
            </div>
            {grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Grant Denied"] &&
            <div className='m-2'>
                <h5>Funding Sources</h5>
                <GrantFormFunding
                    fundingSources={fundingSources}
                    branchId={grantRequestToEdit.branchId}
                    grantGiftId={grantRequestToEdit.grantGiftId}
                    afterSave={() => {
                        fetchFunding()
                        afterSave && afterSave()
                    }}
                />
            </div>
            }
        </>
    )
}