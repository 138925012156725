import { useContext, useEffect, useState } from "react"
import { IEventContentModelDocument, IGroupCoachingTopicResultDocument, IPresentersDocument } from "../open-api"
import { ISelectFieldOption } from "./forms"
import { useRef } from "react"
import dayjs from "dayjs"
import { AppActionContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { EventContentCategory, EventContentType, EVENT_TYPES } from "../constants"
import { EventContentStep1 } from "./event-content-step1"
import { EventContentStep1GroupCoaching } from "./event-content-step1-group-coaching"
import { EventContentStep2 } from "./event-content-step2"

export interface IEventContentFormState {
    step: 'step1' | 'step2'
    step1Values: {
        eventTypeId: string,
        //webinarProviderId: EventContentWebinarProvider
        eventCategoryId: string,
        eventTopicId: string,
        allowSmallGroupSession: boolean,
        groupCoachingTopicId: string
    }
    step2Values: {
        title: string
        subtitle: string
        webinarUrl: string
        nationalEvent: boolean
        month: number
        year: number
        invitationOnly: 'any' | 'invitation'
        //mifApproved: boolean
        sendTrainerEvals: boolean
        bAllowPublicRegistration: boolean
        shortDescription: string
        fullDescription: string
        privateDetails: string
        notifyAreaDirectors: boolean
        notifyAreaDirectorIds: number[],
        courseIdList: string[], // For now, we are only allowing users to select a single course to attach to an EvenContent record.
        hasCourse: boolean,
        allowedAccountLevelIds: string[], // The EC records' allowedAccountLevelIds
        isSeries: boolean,
        seatCount: number | null,
        seatCost: number | null,
        maxSeatsPerOrg: number | null
    },
    groupCoachingTopicTypes: { id: number, name: string }[]
    groupCoachingTopics: IGroupCoachingTopicResultDocument[]
}

interface IEventContentFormProps {
    setEventContentToEdit: (eventContentToEdit: IEventContentModelDocument) => void
    eventContentToEdit?: IEventContentModelDocument
    presenters: IPresentersDocument[]
}

export const EventContentForm = (props: IEventContentFormProps) => {

    const { eventContentToEdit, setEventContentToEdit, presenters } = props

    console.log('EventContentForm HERE')

    const { GroupCoachingTopicApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    // Should we make this a read only form?
    const readonly = !!eventContentToEdit?.isLegacy

    const eventTypeId = eventContentToEdit?.eventTypeId.toString() || ''

    // The second slice removes the 'Small Group Session' entry. That event type is currently not allowed to be used, but we need to keep the value around when displaying existing SGS events.
    const eventTypeOptions = useRef<ISelectFieldOption[]>(Object.entries(EventContentType).slice(-Object.entries(EventContentType).length / 2).slice(0, 2).map(o => ({ label: o[0], value: o[1].toString() })))
    //const webinarProviderOptions = useRef<ISelectFieldOption[]>(Object.entries(EventContentWebinarProvider).slice(-Object.entries(EventContentWebinarProvider).length / 2).map(o => ({ label: o[0], value: o[1].toString() })))
    const eventContentCategoryOptions = useRef<ISelectFieldOption[]>(Object.entries(EventContentCategory).slice(-Object.entries(EventContentCategory).length / 2).map(o => ({ label: o[0], value: o[1].toString() })))

    const [state, setState] = useState<IEventContentFormState>({
        step: Boolean(eventContentToEdit) ? 'step2' : 'step1',
        step1Values: {
            eventTypeId: eventTypeId,
            //webinarProviderId: eventContentToEdit?.webinarProviderId || EventContentWebinarProvider.Zoom,
            eventCategoryId: eventContentToEdit?.eventCategoryId.toString() || '',
            eventTopicId: eventContentToEdit?.eventTopicId.toString() || '',
            allowSmallGroupSession: false,
            groupCoachingTopicId: eventContentToEdit?.groupCoachingTopicId?.toString() || ''
        },
        step2Values: {
            title: eventContentToEdit?.title || '',
            subtitle: eventContentToEdit?.subtitle || '',
            webinarUrl: eventContentToEdit?.webinarUrl || '',
            nationalEvent: eventContentToEdit ? Boolean(eventContentToEdit.nationalEvent) : false,
            month: eventContentToEdit?.month || dayjs().month() + 2,
            year: eventContentToEdit?.year || dayjs().year(),
            invitationOnly: eventContentToEdit?.invitationOnly ? 'invitation' : 'any',
            //mifApproved: eventContentToEdit ? Boolean(eventContentToEdit.mifApproved) : true,
            sendTrainerEvals: eventContentToEdit ? Boolean(eventContentToEdit.sendTrainerEvals) : false,
            bAllowPublicRegistration: eventContentToEdit ? Boolean(eventContentToEdit.bAllowPublicRegistration) : false,
            shortDescription: eventContentToEdit?.shortDescription || '',
            fullDescription: eventContentToEdit?.fullDescription || '',
            privateDetails: eventContentToEdit?.privateDetails || '',
            notifyAreaDirectors: false,
            notifyAreaDirectorIds: [],
            courseIdList: eventContentToEdit?.courseIdList?.map(cid => cid.toString()) || [],
            hasCourse: !!eventContentToEdit?.courseIdList && eventContentToEdit?.courseIdList?.length > 0,
            allowedAccountLevelIds: (eventContentToEdit?.allowedAccountLevelIds || []).map(o => o.toString()),
            isSeries: eventContentToEdit?.isSeries || false,
            seatCount: eventContentToEdit?.seatCount || null,
            seatCost: eventContentToEdit?.seatCost || null,
            maxSeatsPerOrg: eventContentToEdit?.maxSeatsPerOrg || 0
        },
        groupCoachingTopics: [],
        groupCoachingTopicTypes: [],
    })

    console.log('state', state)

    const getStep1Form = () => {
        if (eventTypeId == EVENT_TYPES.GroupLearning.toString()) 
            return <EventContentStep1GroupCoaching 
                parentState={state}
                setParentState={setState}
            />
            
        return <EventContentStep1 
            parentState={state}
            setParentState={setState}
        />
    }

    useEffect(() => {
        if (eventTypeId === EVENT_TYPES.GroupLearning.toString())
        {
            GroupCoachingTopicApi.apiGroupCoachingTopicGet()
                .then(results => {
                    setState(_state => ({ ..._state, groupCoachingTopics: results.data }))
                })

            // Only need topicTypes for step1. Step 1 only displays if we are creating a new EC record.
            state.step === 'step1' && makeHttpRequestWithUi({
                request: GroupCoachingTopicApi.groupCoachingTopicTypesGet(),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error retrieving group learning topic types.'
            })
                .then(results => {
                    setState(_state => ({ ..._state, groupCoachingTopicTypes: results.data as { id: number, name: string }[] }))
                })
        }
    }, [])

    return (
        <>
            {state.step === 'step1' && getStep1Form()}

            {state.step === 'step2' &&
                <EventContentStep2
                    eventContentToEdit={eventContentToEdit}
                    parentState={state}
                    setParentState={setState}
                    setEventContentToEdit={setEventContentToEdit}
                    readonly={readonly}
                    presenters={presenters}
                 />
            }
        </>
    )
}