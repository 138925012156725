import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { Editor } from '@tinymce/tinymce-react'
import dayjs from 'dayjs'
import { NOTE_TYPES } from '../constants'
import { INotePostModelDocument } from '../open-api'

interface IQuickNoteFormProps {
	ministryId?: number // The ministry the note is for
	grantId?: number
	noteText: string | null
	afterSave?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
	onSaveCancel?: () => void
}

export const QuickNoteForm = (props: IQuickNoteFormProps) => {
	const { ministryId, grantId, afterSave, noteText } = props

	const appActions = useContext(AppActionContext)!

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	return (
		<Formik
			initialValues={{
				noteText: noteText || ''
			}}
			onSubmit={async (values, actions) => {
				const noteRecord: INotePostModelDocument = {
					ministryId: ministryId || null,
					grantId: grantId || null,
					prospectId: null,
					dateEntered: dayjs().format('MM/DD/YYYY'),
					ministryContactId: null,
					noteText: values.noteText,
					noteTypeId: NOTE_TYPES.Private,
					alertUserId: null,
					callListId: null,
					deleteDate: null,
					deleteUserId: null,
					eventId: null,
					lengthHours: null,
					parentNoteId: null,
					reviewFiscalYear: null,
					reviewStatusId: null,
					seminarId: null
				}

				await makeHTTPRequestWithUi({
					request: appActions.NotesApi.apiNotesPost(noteRecord),
					toastSuccessMessage: 'Note successfully created.',
					toastErrorMessage: 'There was an error creating the note.',
				})

				actions.resetForm()

				if (afterSave) afterSave()
			}}
		>
			{formikProps => {

				return (
					<Form className='px-2 py-2'>
						<Editor
							init={{
								height: 250,
								plugins: [
									'advlist autolink lists link image charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
								],
								toolbar: 'fullscreen | undo redo | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | formatselect  | removeformat | help',
								block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6',
							}}
							onEditorChange={(content: string) => formikProps.setFieldValue('noteText', content)}
							value={formikProps.values.noteText}
						/>

						<div className='row px-3 py-2'>
							<button className={`btn btn-primary`} type='submit'>Save Quick Note</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}