import dayjs from 'dayjs'
import React from 'react'
import { ITrainerEvalSummaryFullDocument } from '../open-api'
import { IDefaultProps } from './component-definitions'

interface ITrainerEvalsProps extends IDefaultProps { 
    trainerEval: ITrainerEvalSummaryFullDocument
    nextEvalHandler?: () => Promise<void>
    previousEvalHandler?: () => Promise<void>
}

export const TrainerEval = (props: ITrainerEvalsProps) => {
    const { trainerEval, nextEvalHandler, previousEvalHandler } = props
    return (
        <>
            <div className='d-flex justify-content-between'>
            { previousEvalHandler ?
                <button className='btn btn-secondary' onClick={() => {previousEvalHandler()}}>&lt;&lt;Previous</button>
                :
                null 
            }
            { nextEvalHandler ?
                <button className='btn btn-secondary' onClick={() => {nextEvalHandler()}}>Next &gt;&gt;</button>
                :
                null 
            }
            </div>
            <table style={{width: '100%'}}>
            <tbody>
            <tr>
                <td>
                    <fieldset>
                        <legend style={{fontWeight: 'bold'}}>{trainerEval.presenterName}: {dayjs(trainerEval.evalDate).format('MM/DD/YYYY')}</legend>
                        <br />
                        <div>
                        <table>
                            <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontWeight: "bold"}} colSpan={2}>Satisfaction with your hosting and facilitation.</td>
                                                    </tr>
                                                    {trainerEval.eventEvals?.map((x, index) => 
                                                        <tr key={index}>
                                                            <td style={{width: '50'}}>{x.trainingAreaAbbr}: </td>
                                                            <td><i>{x.satisfactionText}</i></td>
                                                        </tr>)}
                                                </tbody>
                                                </table>
                                            </td>
                                            <td>&nbsp;&nbsp;&nbsp;</td>
                                            <td>
                                                <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontWeight: 'bold'}} colSpan={2}>Overall quality of your presentation.</td>
                                                    </tr>
                                                    {trainerEval.eventEvals?.map((x, index) => 
                                                        <tr key={index}>
                                                            <td style={{width: '50'}}>{x.trainingAreaAbbr}: </td>
                                                            <td><i>{x.qualityText}</i></td>
                                                        </tr>)}
                                                </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Rate the flow and teach-ability of this deck <i>(7 being excellent flow and teach-ability)</i>.</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.teachabilityOfDeck} of 7</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>The time required to prepare this material was:</td>
                            </tr>
                            <tr>
                                <td>
                                    <i>{trainerEval.prepTimeText}</i>
                                </td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Rate the degree of your audience's engagement with the material <i>(7 being highly engaged)</i>.</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.audienceEngagement} of 7</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Share a place where the audience connected to the material.</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.audienceConnectedExplain}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Was there a place in the material where any part of your audience got stuck?</td>
                            </tr>
                            <tr>
                                <td>
                                    <i>{
                                        trainerEval.audienceStuck ? 
                                        <span>Yes: {trainerEval.audienceStuckExplain}</span>
                                        :
                                        null
                                    }</i>
                                    </td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Please explain any significant adjustments you made after this quarter's first workshop.</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.significantAdjustments}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>What will you work on or change for your next events?</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.changeNextEvents}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Are there tools, ideas, or concepts you added that might be helpful to other Area Directors and/or to future versions of the material?</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.otherTools}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Did you try something new that was particularly effective?</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.somethingNew}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Additional Comments:</td>
                            </tr>
                            <tr>
                                <td><i>{trainerEval.comments}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            </tbody>
                        </table>
                        </div>
                    </fieldset>
                </td>
            </tr>
            </tbody>
        </table>
        </>
    )
}