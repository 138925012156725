import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { UserModel, UserPermissionLevel } from '../models/user'
import { filterGridListItems, sortListBySorts, openUrlInNewTab } from '../services/helpers'
import { useHTTPRequestUiWrapper, useModal, useUsersDefaultColumns } from '../services/hooks'
import { IAppState } from '../stores/app-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, GridUserInteractionStateKey, IGridAction, IGridListItem, IGridRowAction, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { IDefaultProps } from './component-definitions'
import { Grid } from './grid'
import { Modal } from './modal'
import { UserForm } from './user-form'
import { ReactComponent as ContactCard } from '../assets/contact-card.svg'
import { IMiUserModelDocument, IMiUserPutModelDocument } from '../open-api'
import { ReactComponent as BagXIcon } from '../assets/bag-x.svg'
import { ReactComponent as ToggleOnIcon } from '../assets/toggle-on.svg'
import { ReactComponent as ToggleOffIcon } from '../assets/toggle-off.svg'
import { SquareDeleteIcon } from './partials'
import { Helmet } from 'react-helmet'
import { PersonIcon, IncognitoIcon } from '../assets'
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'
import { navigate } from '@reach/router'


interface IUsersProps extends IDefaultProps { }
export const Users = (props: IUsersProps) => {
    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    useEffect(() => {
        // Go ahead and refresh the list of users any time this component is loaded for the first time.
        appActions.fetchMiUsersSummaries()

        // eslint-disable-next-line
    }, [])

    // DO NOT CHANGE THESE VALUES UNLESS ABSOLUTELY NECESSARY - if changed it will leave all filters for this Grid that used the previous ID values orphaned in user's session state until/unless their session state is cleared.
    const toggleCommunityFilterId = 'branch-type-id'
    const toggleActiveUsersFilterId = 'b-disabled-id'

    const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
        const { userSummaries } = _appState

        if (!userSummaries) return { count: 0, rows: [] }

        let _users = [...userSummaries]

        // Check if this user has permission to see anything besides themselves
        if (UserModel.checkPermissionLevelForUser(3, UserPermissionLevel.Read, _appState.currentUser, true)) _users = _users.filter(o => o.userId === _appState.currentUser?.userId)

        // Manually apply the Active/All Community filter (easier than trying to shoehorn the necessary behavior into Grid's native filtering)
        const communityTypeIdFilter = queryState.filters?.find(f => f.id === toggleCommunityFilterId)
        if (communityTypeIdFilter?.enabled) _users = _users.filter(o => o.branchTypeId === 1 || o.branchTypeId === 2 || o.branchTypeId === 4)

        if (queryState.sorts) sortListBySorts(_users, queryState.sorts)
        let rows = _users.map(UserModel.toGridListItem)
        if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

        return {
            count: _users.length,
            rows,
        }
    }

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const [userFormModal, showHideUserFormModal] = useModal()

    const gridActions: IGridAction[] = []

    /* 
        Only users with Administrator permissions for App 4 can add new users
        Admin\modules\userAdmin.js line 327
    */
    if (UserModel.checkPermissionLevelForUser(4, UserPermissionLevel.Administrator), appState.currentUser) {
        gridActions.push({
            id: 'newUser',
            label: 'New User',
            onClick: () => showHideUserFormModal(true)
        })
    }

    /*
        Only users with Modify permissions for App 1 can toggle active/all branches/users
        Admin\modules\userAdmin.js line 327
    */
    if (UserModel.checkPermissionLevelForUser(1, UserPermissionLevel.Modify), appState.currentUser) {
        const toggleActiveBranchesAction: IGridAction = {
            id: 'toggleActiveBranches',
            label: (_gridState) => {
                const communityTypeIdFilter = _gridState.queryState.filters?.find(f => f.id === toggleCommunityFilterId)
                if (communityTypeIdFilter && communityTypeIdFilter.enabled) return 'Show Inactive Communities'
                return 'Hide Inactive Communities'
            },
            onClick: (_gridState, _gridActions) => {
                const communityTypeIdFilter = _gridState.queryState.filters?.find(f => f.id === toggleCommunityFilterId)
                if (communityTypeIdFilter) {
                    _gridActions.updateColumnFilters([{ ...communityTypeIdFilter, enabled: !communityTypeIdFilter.enabled }])
                } else {
                    _gridActions.updateColumnFilters([{
                        id: toggleCommunityFilterId,
                        enabled: true,
                        property: 'branchTypeId',
                        operator: 'not-like',
                        value: 1,
                        hidden: true,
                    }])
                }
            },
        }

        gridActions.push(toggleActiveBranchesAction)

        const toggleActiveUsersAction: IGridAction = {
            id: 'toggleActiveUsers',
            label: (_gridState) => {
                const activeUserFilter = _gridState.queryState.filters?.find(f => f.id === toggleActiveUsersFilterId)
                if (activeUserFilter && activeUserFilter.enabled) return 'Show Inactive Users'
                return 'Hide Inactive Users'
            },
            onClick: (_gridState, _gridActions) => {
                const activeUserFilter = _gridState.queryState.filters?.find(f => f.id === toggleActiveUsersFilterId)
                if (activeUserFilter) {
                    _gridActions.updateColumnFilters([{ ...activeUserFilter, enabled: !activeUserFilter.enabled }])
                } else {
                    _gridActions.updateColumnFilters([{
                        id: toggleActiveUsersFilterId,
                        enabled: true,
                        property: 'bDisabled',
                        operator: '==',
                        value: false,
                        hidden: true,
                    }])
                }
            }
        }

        gridActions.push(toggleActiveUsersAction)
    }

    const [userToEdit, setUserToEdit] = useState<IMiUserModelDocument>()
    const editUser = async (userId: string) => {
        const userToEditQuery = await makeHttpRequestWithUi({
            request: appActions.MiUsersApi.apiMiUsersIdGet(parseInt(userId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the user to edit.',
        })

        setUserToEdit(userToEditQuery.data)

        showHideUserFormModal(true)
    }

    const rowActions: { [id: string]: IGridRowAction } = {
        userInfo: {
            id: 'userInfo',
            action: async (options) => {
                options.e.stopPropagation()
                editUser(options.row.id)
            },
            icon: <ContactCard />,
            tooltipText: 'User Info'
        }
    }

    const [clearStateManagementConfirmModal, showHideClearStateManagementConfirmModal] = useModal()
    const [confirmEnableDisableUserModal, showHideConfirmEnableDisableUserModal] = useModal()
    const [confirmDeleteUserModal, showHideConfirmDeleteUserModal] = useModal()
    const [secondDeleteConfirm, setSecondDeleteConfirm] = useState(false)

    if(appState.currentUser?.bCanImpersonateGrowthTrack) {
        rowActions.impersonateUser = {
            id: 'impersonate',  
            action: async (options) => {
                const { e, row, appActions } = options
                options.e.stopPropagation()
                
                sessionStorage.removeItem(`oidc.user:${IDP_AUTHORITY}:${IDP_CLIENT_ID}`);
                navigate(row.values.impersonateUrl?.toString() ?? '') 
            },
            icon: <IncognitoIcon />,
            tooltipText: 'Impersonate User',
            disabled: (row) => !row.values.impersonateUrl 
            || appState.currentUser?.userId === row.values.userId 
            || appState.currentAuth?.impersonatorId === row.values.subjectId
        }
    }

    if (appState.currentUser?.bSuperUser) {
        rowActions.clearStateManagement = {
            id: 'clearStateManagement',
            action: async (options) => {
                options.e.stopPropagation()
                setSelectedRow(options.row)
                showHideClearStateManagementConfirmModal(true)
            },
            icon: <BagXIcon />,
            tooltipText: 'Clear user session state'
        }

        rowActions.enableDisableUser = {
            id: 'enableDisableUser',
            action: async (options) => {
                options.e.stopPropagation()
                setSelectedRow(options.row)
                showHideConfirmEnableDisableUserModal(true)
            },
            icon: (row) => {
                if (row.values.bDisabled === true) return <ToggleOffIcon />
                return <ToggleOnIcon />
            },
            tooltipText: (row) => {
                if (row.values.bDisabled === true) return 'Enable user account'
                return 'Disable user account'
            }
        }

        rowActions.deleteUser = {
            id: 'deleteUser',
            action: async (options) => {
                options.e.stopPropagation()
                setSelectedRow(options.row)
                showHideConfirmDeleteUserModal(true)
            },
            icon: <SquareDeleteIcon />,
            tooltipText: 'Delete user'
        }
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        queryState: {
            ...defaultGridState.queryState,
            filters: [
                {
                    id: toggleActiveUsersFilterId,
                    enabled: true,
                    property: 'bDisabled',
                    operator: '==',
                    value: false,
                    hidden: true,
                },
                {
                    id: toggleCommunityFilterId,
                    enabled: true,
                    property: 'branchTypeId',
                    operator: 'not-like',
                    value: 1,
                    hidden: true,
                }
            ]
        },
        columns: useUsersDefaultColumns(),
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        userSessionStateKey: GridUserInteractionStateKey.Users,
        gridActions,
        rowActions,
        rowDoubleClicked: async (row) => {
            editUser(row.id)
        }
    }

    const [state, actions] = useGrid(gridReducer, initialGridState, appState)

    useEffect(() => {
        actions.doFetch()

        // eslint-disable-next-line
    }, [appState.userSummaries])

    return <React.Fragment>
        <Helmet>
            <title>Users</title>
        </Helmet>
        <div className='d-flex flex-column' style={{ height: '100vh' }}>
            <div className='m-2 d-flex align-items-center'>
                <PersonIcon style={{ width: '25px', height: '25px' }} />
                <h3 className='ml-2'>Users</h3>
            </div>
            <Grid actions={actions} state={state} />
        </div>

        <Modal
            {...userFormModal}
            modalTitle={userToEdit ? `Edit ${userToEdit.firstName} ${userToEdit.lastName} (${userToEdit.email})` : 'New User'}
            size='fullscreen'
            dismissible={false}
            _onModalHidden={() => setUserToEdit(undefined)}
            _onModalShown={() => {
                if (!appState.appPermissions) appActions.fetchAppPermissions()
            }}
        >
            {userFormModal.show ?
                appState.appPermissions ?
                    <UserForm
                        onSaveSuccess={async () => {
                            await appActions.fetchMiUsersSummaries()
                            showHideUserFormModal(false)
                            setUserToEdit(undefined)
                        }}
                        userToEdit={userToEdit}
                        appPermissions={appState.appPermissions}
                    />
                    :
                    null
                :
                null
            }
        </Modal>

        <Modal
            {...clearStateManagementConfirmModal}
            modalTitle='Confirm'
            footer={
                <React.Fragment>
                    <button onClick={() => showHideClearStateManagementConfirmModal(false)} className='btn btn-secondary'>Cancel</button>
                    <button
                        onClick={() => {
                            if (selectedRow) {
                                makeHttpRequestWithUi({
                                    request: appActions.MiUsersApi.apiMiUsersIdAdminStatePut(parseInt(selectedRow.id), { adminStateObj: JSON.stringify({}) })
                                })
                                showHideClearStateManagementConfirmModal(false)
                            }
                        }}
                        className='btn btn-danger'
                    >
                        CLEAR STATE
                    </button>
                </React.Fragment>
            }
            _onModalHidden={() => setSelectedRow(undefined)}
        >
            <p>Are you sure you want to reset session state for this user?</p>
            <p>This will clear all their saved session data: <b>favorites</b>, <b>settings</b>, and <b>saved table configurations (filters, columns, sorts)</b>.</p>
            <p className='bold text-danger'>This action cannot be reversed.</p>
        </Modal>

        <Modal
            {...confirmEnableDisableUserModal}
            modalTitle='Confirm'
            footer={
                <React.Fragment>
                    <button onClick={() => showHideConfirmEnableDisableUserModal(false)} className='btn btn-secondary'>Cancel</button>
                    <button
                        className={`btn ${selectedRow?.values.bDisabled === true ? 'btn-success' : 'btn-danger'}`}
                        onClick={async () => {
                            if (selectedRow) {

                                const userToEditQuery = await makeHttpRequestWithUi({
                                    request: appActions.MiUsersApi.apiMiUsersIdGet(parseInt(selectedRow.id)),
                                    disableSuccessToast: true,
                                    toastErrorMessage: 'There was an error retrieving the user to edit.',
                                })

                                const editedUser: IMiUserPutModelDocument = {
                                    ...userToEditQuery.data,
                                    reportPermissionId: userToEditQuery.data.reportPermissionId || 1,
                                    username: userToEditQuery.data.username || '',
                                    password: userToEditQuery.data.password || '',
                                    firstName: userToEditQuery.data.firstName || '',
                                    lastName: userToEditQuery.data.lastName || '',
                                    email: userToEditQuery.data.email || '',
                                    branchId: userToEditQuery.data.branchId || 0,
                                    bDisabled: !selectedRow.values.bDisabled
                                }

                                await makeHttpRequestWithUi({
                                    request: appActions.MiUsersApi.apiMiUsersIdPut(editedUser.userId, editedUser),
                                    toastSuccessMessage: `Successfully ${selectedRow.values.bDisabled === true ? 'enabled' : 'disabled'} user.`,
                                    toastErrorMessage: 'There was an error updating the user.',
                                })

                                await appActions.fetchMiUsersSummaries()
                                showHideConfirmEnableDisableUserModal(false)
                            }
                        }}
                    >
                        {selectedRow?.values.bDisabled === true ? 'Enable' : 'Disable'}
                    </button>
                </React.Fragment>
            }
            _onModalHidden={() => setSelectedRow(undefined)}
        >
            {selectedRow?.values.bDisabled === true ?
                'Are you sure you want to enable this user?'
                :
                'Are you sure you want to disable this user?'
            }
        </Modal>

        <Modal
            {...confirmDeleteUserModal}
            modalTitle='Confirm'
            footer={
                <React.Fragment>
                    <button onClick={() => showHideConfirmDeleteUserModal(false)} className='btn btn-secondary'>Cancel</button>
                    <button
                        className={`btn ${secondDeleteConfirm ? 'btn-danger' : 'btn-warning'}`}
                        onClick={async () => {
                            if (selectedRow) {
                                if (!secondDeleteConfirm) {
                                    setSecondDeleteConfirm(true)
                                } else {
                                    await makeHttpRequestWithUi({
                                        request: appActions.MiUsersApi.apiMiUsersIdDelete(parseInt(selectedRow.id)),
                                        toastSuccessMessage: `Successfully deleted user.`,
                                        toastErrorMessage: 'There was an error deleting the user.',
                                    })

                                    await appActions.fetchMiUsersSummaries()
                                    showHideConfirmDeleteUserModal(false)
                                }
                            }
                        }}
                    >
                        Delete User
                    </button>
                </React.Fragment>
            }
            _onModalHidden={() => {
                setSelectedRow(undefined)
                setSecondDeleteConfirm(false)
            }}
        >
            {secondDeleteConfirm ?
                <div>
                    Last chance, are you sure you want to delete this user?
                    <p className='bold text-danger'>All settings will be lost!</p>
                </div>
                :
                <div>
                    Are you sure you want to delete this user?
                    <p className='bold text-danger'>This cannot be reversed!</p>
                </div>
            }
        </Modal>
    </React.Fragment>
}