import { IMiBranchGrantGoalsDocument } from "../open-api"
import { IGridListItem } from "../stores/grid-definitions"

export class CommunitiesGrantTargetModel {
    public static toGridListItem = (grantGoal: IMiBranchGrantGoalsDocument): IGridListItem => {
        return {
            id: grantGoal.branchGrantGoalId?.toString() || '',
            values: {
                ...grantGoal,
            }
        }
    }
} 