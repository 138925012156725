import { IGrantGiftMinistryContactSummaryDocument, IGrantGiftModelDocument, IGrantTypeModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useContext, useEffect, useState } from "react"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, openUrlInNewTab, sortListBySorts } from "../services/helpers"
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { Modal } from "./modal"
import { GrantFormMinistryForm } from "./grant-form-ministry-form"
import { Grid } from "./grid"
import { SquareDeleteIcon } from "./partials"
import { IncognitoIcon, EnvolpeIcon, EyeIcon, PlusIcon } from "../assets"

interface IGrantFormMinistriesProps {
    ministries: IGrantGiftMinistryContactSummaryDocument[]
    grantToEdit: IGrantGiftModelDocument
    grantTypes: IGrantTypeModelDocument[]
    afterSave: () => Promise<void>
    ministryFormModal: any
    showHideMinistryFormModal: any
}

export const GrantFormMinistries = (props: IGrantFormMinistriesProps) => {
    const { ministries, grantToEdit, afterSave, grantTypes, ministryFormModal, showHideMinistryFormModal } = props
    const { GrantGiftMinistryContactApi } = useContext(AppActionContext)!

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [ministryToEdit, setMinistryToEdit] = useState<IGrantGiftMinistryContactSummaryDocument>()
    const [sendInviteModal, showHideSendInviteModal] = useModal()
    const [removeModal, showHideRemoveModal] = useModal()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<typeof ministries> = async (_queryState, _evals) => {
        try {
            console.log('_evals', _evals)
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.grantGiftMinistryContactId!.toString(),
                values: {
                    ...o,
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('GrantFormMinistries > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    // const editMinistry = useCallback(async (grantGiftMinistryContactId: string) => {
    //     const { data } = await makeHttpRequestWithUi({
    //         request: GrantGiftMinistryContactApi.apiGrantGiftMinistryContactGrantGiftMinistryContactIdGet(parseInt(grantGiftMinistryContactId)),
    //         disableSuccessToast: true,
    //         toastErrorMessage: 'There was an error retrieving the event to edit.'
    //     })

    //     setMinistryToEdit(data)

    //     showHideMinistryFormModal(true)
    // }, [])

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.GrantMinistries,
        rowActionsDisplayType: 'icons',
        gridActions: [
            {
                id: 'addMinistry',
                label: 'Add Ministry',
                icon: <PlusIcon className='mr-2' />,
                buttonClass: 'btn-primary',
                onClick: () => showHideMinistryFormModal(true),
                sortOrder: 0,
            }
        ],
        rowActions: {
            viewGrantRequest: {
                id: 'viewGrantRequest',
                action: async (options) => {
                    options.e.stopPropagation()
                    if (options.row.values.grantId) window.open(`/grant-request/${options.row.values.grantId}`)
                },
                icon: <EyeIcon />,
                tooltipText: 'View Grant Request', // Disabled if ministry has not submitted grant request.
                disabled: (row) => !row.values.grantId
            },
            impersonate: {
                id: 'impersonate',
                action: async (options) => {
                    const { e, row } = options

                    e.stopPropagation()
                    if (row.values.impersonationUrl)
                        openUrlInNewTab(row.values.impersonationUrl.toString())
                },
                icon: <IncognitoIcon />,
                tooltipText: 'Impersonate user',
                disabled: (row) => !row.values.subjectId
            },
            remove: {
                id: 'remove',
                action: async (options) => {
                    options.e.stopPropagation()
                    setSelectedRow(options.row)
                    showHideRemoveModal(true)
                },
                icon: <SquareDeleteIcon />,
                tooltipText: (row) => !!row.values.grantRequestGuid ? 'Cannot remove ministry who has already begun applying for this grant.' : 'Remove',
                disabled: (row) => !!row.values.grantRequestGuid
            },
            sendInvite: {
                id: 'sendInvite',
                action: async (options) => {
                    options.e.stopPropagation()
                    setSelectedRow(options.row)
                    showHideSendInviteModal(true)
                },
                icon: <EnvolpeIcon />,
                tooltipText: 'Send notification',
            }
        },
        columns: [
            {
                property: 'ministryName',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Ministry',
                render: (col, row) => <a target='_blank' href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
            },
            {
                property: 'ministryContactFirstName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Grant Contact',
                render: (_, row) => `${row.values.ministryContactFirstName} ${row.values.ministryContactLastName}`
            },
            {
                property: 'grantTypeName',
                type: 'string',
                width: 75,
                allowFilters: true,
                title: 'Grant Type',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'amount',
                type: 'number',
                width: 75,
                allowFilters: true,
                title: 'Amount',
                render: CurrencyDisplay
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: false,
        usingLocalData: true,
        disabledPagination: true,
        respectGlobalCommunityFilter: false,
        disableExport: true,
        // rowDoubleClicked: async (row) => {
        //     setSelectedRow(row)
        //     editMinistry(row.id)
        // },
    }

    //const [gridState, gridActions] = useGrid(gridReducer, initialGridState, [])
    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministries ?? [])

    useEffect(() => {
        console.log('GrantFormMinistries > doFetch')
        gridActions.doFetch()
    }, [ministries])

    return (
        <>
            <p className='mt-3'>
                A list of ministries that may apply for this grant via a grant request.
            </p>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>
            <Modal
                {...ministryFormModal}
                modalTitle={!!ministryToEdit ? `Edit Ministry (${ministryToEdit.grantGiftMinistryContactId})` : 'Add Ministry'}
                size='lg'

                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setMinistryToEdit(undefined)
                }}

                dismissible={false}
            >
                {ministryFormModal.show &&
                    <GrantFormMinistryForm
                        ministryToEdit={ministryToEdit}
                        grantToEdit={grantToEdit} 
                        grantTypes={grantTypes}
                        afterSave={async () => {
                            showHideMinistryFormModal(false)
                            afterSave && afterSave()
                        }} 
                    />
                }
            </Modal>
            <Modal
                {...removeModal}
                modalTitle='Remove Ministry'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideRemoveModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: GrantGiftMinistryContactApi.apiGrantGiftMinistryContactGrantGiftMinistryContactIdDelete(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully removed the ministry.',
                                        toastErrorMessage: 'Encountered an error removing the ministry.',
                                    })
                                    showHideRemoveModal(false)
                                    afterSave && afterSave()
                                }
                            }}
                        >
                            Remove
                        </button>
                    </>
                }
            >
                <p>Are you sure you want to remove this ministry from the grant? They will no longer be able to submit a grant request for this grant.</p>
            </Modal>
            <Modal
                {...sendInviteModal}
                modalTitle='Send Inviation Email'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideSendInviteModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: GrantGiftMinistryContactApi.apiGrantGiftMinistryContactGrantGiftMinistryContactIdSendinvitePost(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully removed the ministry.',
                                        toastErrorMessage: 'Encountered an error removing the ministry.',
                                    })
                                    showHideSendInviteModal(false)
                                    afterSave && afterSave()
                                }
                            }}
                        >
                            Send Invite
                        </button>
                    </>
                }
            >
                <p>Would you like to send an email to {selectedRow?.values.ministryContactFirstName} {selectedRow?.values.ministryContactFirstName} inviting them to apply for this grant?</p>
            </Modal>
        </>
    )
}