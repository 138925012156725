import React, { useEffect, useRef, useState } from 'react'
import { IPartnerQuestionsModelDocument, IPastYearsFieldModelDocument, IMinistryDataModelDocument, IPartnerFieldsForExportModelDocument, IMinistrySummaryResponseModelDocument } from '../open-api'
import { QuestionModel } from '../models/question'
import { NumberField } from './forms'
import { FormikProps } from 'formik'
import { isNullOrUndefined } from 'util'
import { uuidv4, enableTooltip, showModal, formatCurrency, buildTooltipProps, destroyTooltip } from '../services/helpers'
import { ReactComponent as InfoIcon } from '../assets/exclamation-circle.svg'
import { MINISTRY_PORTAL_ROOT_URL } from '../constants'

const ONLINE_GIVING_FIELDID = 67

interface IQuestionPastThreeYearsProps {
	ministry: IMinistrySummaryResponseModelDocument
	questions: IPartnerQuestionsModelDocument[]
	question: IPartnerQuestionsModelDocument
	pastYearsFields: IPastYearsFieldModelDocument[] | null
	activeMinistryData: IMinistryDataModelDocument
	fiscalYearRange: number[] | null
	formikProps: FormikProps<{}>
	
}
export const QuestionPastThreeYears = (props: IQuestionPastThreeYearsProps) => {
	const { question, fiscalYearRange, formikProps, questions } = props
	const { fields } = question

	const questionLabelRef = useRef<HTMLDivElement | null>(null)
	const [questionLabelId] = useState(uuidv4())

	/*
		After the page has loaded we need to init tooltips that are embedded in HTML in the data model.
	*/
	useEffect(() => {
		if (questionLabelRef.current) {
			Array.from(questionLabelRef.current.children).forEach(element => {
				if (element.classList.contains('qtipHelper') && element instanceof HTMLElement) {
					if (element.title && element.title.length > 0) {
						const id = uuidv4()
						element.id = id
						element.dataset.toggle = 'tooltip'
						element.dataset.placement = 'auto'
						element.dataset.html = 'true'
						enableTooltip(id)
					}
				}

				if (element instanceof HTMLAnchorElement && element.href.includes('inlineTaxYearHelper')) {
					element.href = '#'
					element.onclick = () => {
						showModal('inlineTaxYearHelper')
					}
				}

				if (element instanceof HTMLAnchorElement && element.href.includes('incomeStreamInstructions')) {
					element.href = MINISTRY_PORTAL_ROOT_URL + '/downloads/incomeStreamInstructions.pdf'
					element.target = '_blank'
				}
			})
		}
	}, [questionLabelRef, question.question])

	useEffect(() => {
		fiscalYearRange?.forEach(fiscalYear => {
			enableTooltip(`total-cash-income-${fiscalYear}-tooltip`)
		})

		return function cleanup() {
			fiscalYearRange?.forEach(fiscalYear => {
				destroyTooltip(`total-cash-income-${fiscalYear}-tooltip`)
			})
		}
		//eslint-disable-next-line
	}, [formikProps.values])

	if (fields) {
		return (
			<div className={`container background-hover ${fields.length > 1 ? 'mb-3' : ''}`}>
				<div className='row mb-2'>
					<div id={questionLabelId} ref={questionLabelRef} dangerouslySetInnerHTML={{ __html: question.question || '' }}></div>
				</div>
				{fields.map((field, index) => {
					let questionComponent = <QuestionPastThreeYearField
						key={field.fieldId}
						{...props}
						field={field}
						showCashIncomeLabel={index === 0 && QuestionModel.isIncomeAmount(question)} // MIF-Web\MIF.Ministry.Web\Views\Account\General.cshtml line 181
						hideFieldLabel={index > 0 && fields.length > 1 ? true : false} // When a "past three years" question has more than field, then we only want to show the years label on the first "field" (the first row of years).					
					/>

					if (QuestionModel.isIncomeAmount(question)) {
						/* 
							In the original ExtJS, the "Income Amount" question has a few hard coded modifications.

							1) A totals row is inserted before the last field row.
							2) Another totals row is added AFTER the last field row IF the last field row has any values greater than 0.
							3) A "note" is added at the bottom.
							4) The percentage of the overall reported income that each subcategory makes up is displayed to the side of each input field.
						*/
						if (index === fields.length - 1) {
							return (
								<div key={`total-cash-income-${field.fieldId}`}>
									<div className='row'>
										<div className='col pl-0 pr-1 pt-1'><b>Total Cash Income: </b></div>
										{fiscalYearRange?.map(fiscalYear => {
											const total = QuestionModel.getCashIncomeSubTotalForFiscalYearOfReportedIncomeQuestion(question, fiscalYear, formikProps)

											// We also need to retrieve the total of the ReportedIncome question's 'income' field to compare with this total.
											let valid = true
											const reportedIncomeQuestionField = questions.find(_question => QuestionModel.isReportedIncome(_question))?.fields?.find(f => f.fieldName === 'income')
											if (reportedIncomeQuestionField) {
												// @ts-ignore
												valid = total === parseInt(`${formikProps.values[QuestionModel.getFormFieldNameForPastThreeYearField(reportedIncomeQuestionField.fieldName, fiscalYear)]}`.replace(/\D/g, ''))
											}

											return (
												<div key={`total-cash-income-${fiscalYear}`} className='col pl-0 pr-1 profile-fundraising-total-column'>

													{valid ?
														formatCurrency(total)
														:
														<span id={`total-cash-income-${fiscalYear}-tooltip`} {...buildTooltipProps({ tooltipText: 'The total of all cash income streams should match the total income entered in question 2.' })} className='text-danger'><b>{formatCurrency(total)} <InfoIcon style={{ paddingBottom: '3px' }} /></b></span>
													}
												</div>
											)
										})}
									</div>
									<div className='row'>
										&nbsp;
									</div>
									<div className='row'>
										<div className='col pl-0 pr-1 pt-2'><b>Non-cash Income: </b></div>
									</div>
									{questionComponent}
									{/* MA20-3988 20240507 TB - Asked to remove the Total Income line by the Profile Updates committee.
									<div className='row mb-2'>
										<div className='col pl-0 pr-1 pt-1'><b>Total Income: </b></div>
										{fiscalYearRange?.map(fiscalYear => {
											const total = QuestionModel.getTotalIncomeSubTotalForFiscalYearOfReportedIncomeQuestion(question, fiscalYear, formikProps)

											return (
												<div key={`total-income-${fiscalYear}`} className='col pl-0 pr-1 profile-fundraising-total-column'>
													{formatCurrency(total)}
												</div>
											)
										})}
									</div> */}
									<div className='row mb-4'>
										<small><em><b>NOTE:</b> Gifts in Kind income is just for reference, and should not be included in the total cash income above.</em></small>
									</div>
								</div>
							)
						}

						return questionComponent
					}

					return questionComponent
				})}
			</div>
		)
	} else {
		return null
	}
}

interface IQuestionPastThreeYearFieldProps extends IQuestionPastThreeYearsProps {
	field: IPartnerFieldsForExportModelDocument
	showCashIncomeLabel?: boolean // MIF-Web\MIF.Ministry.Web\Views\Account\General.cshtml line 181
	hideFieldLabel?: boolean
}
const QuestionPastThreeYearField = (props: IQuestionPastThreeYearFieldProps) => {
	const { field, question, fiscalYearRange, formikProps, showCashIncomeLabel, hideFieldLabel } = props

	const fields: JSX.Element[] = []

	const fieldsWithLabels = question.fields?.filter(field => field.fieldLabel && field.fieldLabel.length > 0).length
	let displayFieldLabel = !isNullOrUndefined(fieldsWithLabels) && fieldsWithLabels > 0

	fiscalYearRange?.forEach(fiscalYear => {
		const name = QuestionModel.getFormFieldNameForPastThreeYearField(field.fieldName, fiscalYear)

		// Display subtotals for the Cash Income fields (all but the last field) of the IncomeAmount question
		// Admin\modules\MinistryInfo\New\profile.js 410
		let subTotal: string | undefined = undefined
		let value = 0
		if (QuestionModel.isIncomeAmount(question) && question.fields && question.fields?.indexOf(field) < question.fields?.length - 1) {
			const total = QuestionModel.getCashIncomeSubTotalForFiscalYearOfReportedIncomeQuestion(question, fiscalYear, formikProps)

			// @ts-ignore
			value = parseInt(`${formikProps.values[name]}`.replace(/\D/g, ''))
			subTotal = total && value > 0 ? `${(Math.ceil(value / total * 100)).toLocaleString('en-US', { maximumFractionDigits: 0 })}%` : '--'
		}

		fields.push(
			<div key={name} className='col pl-0 pr-1 d-flex'>
				<NumberField
					fieldProps={{ name, label: `${fiscalYear}` }}
					labelStyle={hideFieldLabel ? { display: 'none' } : { marginBottom: 0, fontWeight: 700 }}
					disabled={field.fieldId === ONLINE_GIVING_FIELDID && value < 1}
				/>
				{subTotal ?
					hideFieldLabel ?
						<span className='text-secondary pt-2 pl-1' style={{ minWidth: '45px' }}>{subTotal}</span>
						:
						<div className='text-secondary pl-1 d-flex align-items-center' style={{ minWidth: '45px' }}>{subTotal}</div>
					:
					null
				}
			</div>
		)
	})

	const fieldLabel = QuestionModel.isIncomeAmount(question) ?
		//eslint-disable-next-line
		<a id={`${field.fieldName}-row-label`} href='#' {...buildTooltipProps({ tooltipText: field.fieldHelpText, placement: 'top', html: true })}>{field.fieldLabel}</a> :
		field.fieldLabel

	useEffect(() => {
		enableTooltip(`${field.fieldName}-row-label`)

		return function cleanup() {
			destroyTooltip(`${field.fieldName}-row-label`)
		}
		//eslint-disable-next-line
	}, [])

	return (
		<div className='row'>
			{displayFieldLabel ?
				hideFieldLabel ?
					<div className='col pl-0 pr-1 pt-2'>{fieldLabel}</div>
					:
					showCashIncomeLabel ?
						<div className='col pl-0 pr-1 d-inline-flex flex-column'>
							<div><b>Cash Income:</b></div>
							<div className='pt-2'>{fieldLabel}</div>
							<div></div>
						</div>
						:
						<div className='col pl-0 pr-1 d-inline-flex flex-column justify-content-center'>
							<div></div>
							<div>{fieldLabel}</div>
							<div></div>
						</div>
				:
				<div className='col pl-0 pr-1'></div>
			}
			{fields}
		</div>
	)
}