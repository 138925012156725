import React, { useState, useEffect, useContext } from 'react'
import { IMeAuthModelDocument } from '../open-api'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { navigate } from '@reach/router'
import { useAuth} from "react-oidc-context";
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'



export const ImpersonationBar = () => {
    const appState = useContext(AppStateContext)!
	const auth = useAuth()!

    const handleStopImpersonation = async () => {        
        //I tried to use the "auth.removeUser()" method to do a local user signout but it triggers an imediate redirect to the IDP
        // to reauthenticate the user.  I need to remove the user from the local storage without triggering a redirect. So I removed 
        // the user from the local storage instead"
        sessionStorage.removeItem(`oidc.user:${IDP_AUTHORITY}:${IDP_CLIENT_ID}`);

        const impersonateStopUrl = `${IDP_AUTHORITY}/Identity/Account/ImpersonateStop?returnUrl=${IDP_REDIRECT_URI}`

        navigate(impersonateStopUrl)  
    }
    
    if (appState.currentAuth?.isImpersonating) {
        return (
            <div>
                <div className="impersonatebar">
                    
                    Impersonating {appState.currentAuth?.firstName} {appState.currentAuth?.lastName} 
                    &nbsp; &nbsp;
                    {/* <a href="~/Home/ImpersonateStop">Stop Impersonation</a> */}
                    <button className='btn btn-secondary' onClick={() => handleStopImpersonation()}>Stop Impersonation</button>
                </div>
                <div className="impersonatebarspace"></div>
            </div>
        );
    }

    return (null);
};
