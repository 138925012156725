import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalFundraisingProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalFundraising = (props: IMinistryInfoAreaDirectorEvalFundraisingProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
        <Formik
            initialValues={{
                bFundraising: areaDirectorEval.ministryEvalState.bFundraising,
                bProjected: areaDirectorEval.ministryEvalState.bProjected,
                bSoftware: areaDirectorEval.ministryEvalState.bSoftware,
                bStreams: areaDirectorEval.ministryEvalState.bStreams,
                bDonors: areaDirectorEval.ministryEvalState.bDonors,
                bActive: areaDirectorEval.ministryEvalState.bActive,
                bActiveFundraising: areaDirectorEval.ministryEvalState.bActiveFundraising,
                fundraising: areaDirectorEval.ministryEvalState.fundraising || '',
                tabDirection: null
            }}
            onSubmit={async (values) => {
                const evalRecord: IMinistryEvalsPutDocument = {
                    ...areaDirectorEval.ministryEvalState,
                    bFundraising: values.bFundraising,
                    bProjected: values.bProjected,
                    bSoftware: values.bSoftware,
                    bStreams: values.bStreams,
                    bDonors: values.bDonors,
                    bActive: values.bActive,
                    bActiveFundraising: values.bActiveFundraising,
                    fundraising: values.fundraising,
                    dStep5Updated: moment().format(),
                    step5UpdatedBy: appState.currentUser?.userId || null
                }
                await makeHTTPRequestWithUi({
                    request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
                    toastSuccessMessage: 'Eval record successfully updated.',
                    toastErrorMessage: 'There was an error updating the eval record.',
                })
                
                if (values.tabDirection === 'previous' && afterSavePrevious) {
                    afterSavePrevious()
                } else if (afterSaveNext) {
                    afterSaveNext()
                }
            }}
        >
            {(formikProps) => {
                const form = <React.Fragment>
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bFundraising', 
                            label: 'The organization has a formalized fundraising plan/strategy with specific goals and measurable outcomes' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bProjected', 
                            label: 'The funds projected to be raised are consistent with the organization\'s overall goals' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bSoftware', 
                            label: 'The organization has fundraising software that is being utilized for analysis,collection and reporting of donations.' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bStreams', 
                            label: 'The organization has multiple income streams and sufficient diversification of revenue sources' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bDonors', 
                            label: 'The organization is not solely dependent upon a few donors for it\'s livelihood' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bActive', 
                            label: 'Some of the board members are also active donors to the organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bActiveFundraising', 
                            label: 'Board members of this organization are actively fundraising on behalf of the Organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    { !readonly ?
                        <TextareaField
                            fieldProps={{
                                name: 'fundraising',
                                label: 'Additional Notes on Fundraising'
                            }} />
                        :
                        null
                    }
                    { !readonly ?
                        <div className='row px-3'>
                            <button className={`btn btn-primary`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'previous')
                                formikProps.submitForm()
                            }}>Save &amp; Previous</button>
                            <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'next')
                                formikProps.submitForm()
                            }}>Save &amp; Continue</button>
                        </div>
                        :
                        null
                    }
                </React.Fragment>
                // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                return (
                    readonly ? form :
                    <Form>
                        {form}
                    </Form>
                )
            }}
        </Formik>
    )
}