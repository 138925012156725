import { Form, Formik } from "formik"
import { useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IAfterEventDataDocument, IConsultingApptDocument, IConsultingApptRegistrantDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { NumberField, TinyMceField } from "./forms"
import { ConsultingAppointmentFormDisabledText } from './consulting-appointment-form-disabled-text'
import { GridDataFetch, IGridListItem } from "../stores/grid-definitions"
import { Link } from "@reach/router"
import { useGrid, defaultGridState } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { Grid } from "./grid"
import { LoadingOverlay } from "./loading"

const dataSource: GridDataFetch<IConsultingApptRegistrantDocument[]> = async (queryState, _registrants) => {
    try {
        const rows: IGridListItem[] = _registrants.filter(o => !!o.seminarAttendeeId).map(o => ({
            id: o.seminarAttendeeId!.toString(),
            values: {
                ...o
            },
            selected: o.bAttended
        }))

        return {
            rows,
            count: _registrants.length
        }
    } catch (e) {
        return {
            rows: [],
            count: 0
        }
    }
}

export const ConsultingAppointmentFormAfterEvent = ({ appointmentToEdit, afterEventData, registrants, afterSave }: {
    appointmentToEdit: IConsultingApptDocument
    afterEventData?: IAfterEventDataDocument
    registrants: IConsultingApptRegistrantDocument[]
    afterSave: () => void
}) => {

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const readOnly = appointmentToEdit && !appointmentToEdit.isEditable

    const [loading, setLoading] = useState(false)

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            columns: [
                {
                    property: 'firstName',
                    title: 'Name',
                    width: 100,
                    type: 'string',
                    render: (_, row) => `${row.values.firstName} ${row.values.lastName}`,
                },
                {
                    property: 'email',
                    title: 'Email',
                    width: 100,
                    type: 'string',
                    render: (_, row) => <a href={`mailto:${row.values.email}`}>{row.values.email}</a>
                },
                {
                    property: 'ministryName',
                    title: 'Ministry',
                    width: 120,
                    type: 'string',
                    render: (col, row) => <Link to={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</Link>
                },
            ],
            dataSource,
            usingLocalData: true,
            hideGridHeader: true,
            hideGridFooter: true,
            rowSelectEnabled: true,
        },
        registrants,
    )

    useEffect(() => {
        gridActions.doFetch()
    }, [registrants])

    return (
        <Formik
            enableReinitialize
            initialValues={{
                actualHours: afterEventData?.actualHours || '',
                note: afterEventData?.note || '',
            }}

            onSubmit={async (values) => {
                await makeHttpRequestWithUi({
                    request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdAfterApptDataPut(
                        appointmentToEdit.consultingEventGroupId,
                        {
                            ...afterEventData,
                            ...values,
                            actualHours: parseFloat(values.actualHours.toString()),
                            attendedSeminarAttendeesIds: gridState.rows.filter(o => o.selected).map(o => parseInt(o.id))
                        }
                    ),
                    toastErrorMessage: 'Enountered an error updating after event data.',
                    toastSuccessMessage: 'Successfully updated after event data.',
                })
                afterSave()
            }}

            children={formikProps => {
                return (
                    <>
                        <Form style={{ padding: 10, position: 'relative' }}>
                            {readOnly && <ConsultingAppointmentFormDisabledText />}
                            {loading && <LoadingOverlay position='absolute' />}

                            {!afterEventData?.actualHours &&
                                <>
                                    <p><b>The after event data needs added for this appointment.</b></p>
                                    <p><b>Please enter the hours, any notes, check the registrants for attendance, and then click &quot;Save&quot;.</b></p>
                                </>
                            }

                            <NumberField step={.1} fieldProps={{ name: 'actualHours', label: 'Actual Hours' }} noFormat disabled={readOnly} />
                            <TinyMceField fieldProps={{ name: 'note', label: 'Notes' }} disabled={readOnly} />

                            <p>Check all registrants that were in attendance.</p>
                            <Grid state={gridState} actions={gridActions} style={{ height: 'unset' }} />

                            <button className='btn btn-primary' disabled={readOnly} style={{ marginTop: 10 }}>Save</button>
                        </Form>
                    </>
                )
            }}
        />
    )
}