import { FilterValues, IQueryState } from './api-actions'
import { GridCellDisplayFunction } from '../components/grid-cell-displays'
import { IGridColumnFilterOption } from '../components/grid-column-filter'
import { MouseEvent, ReactNode } from 'react'
import { TagType } from '../models/tag'
import { IMifActions } from './app-actions'
import { IAppState } from './app-definitions'
import { IGridActions } from './grid-actions'
import { ISimpleSelectFieldProps } from '../components/forms'

/* 
	Need to allow for an object array with a display value (what to show the user) and an ID so that we can include 
	an array of objects (so far, just Tags) in local only grids AND still filter by Tag IDs (which is how the API handles tag filtering).
*/
export interface IGridListItemObject {
	display: string | null
	id: string | number
}
export type GridListItemValueType = string | number | boolean | null | Date | IGridListItemObject[] | number[]


export const isGridListItemObjectValueType = (object: any): object is IGridListItemObject => object && typeof object === 'object' && 'display' in object
export const isGridListItemObjectValueTypeArray = (object: any): object is IGridListItemObject[] => object && Array.isArray(object) && object.length > 0 && isGridListItemObjectValueType(object[0])

export interface IGridColumn {
	property: string
	title: string
	description?: string
	hide?: boolean
	width: number
	type: 'string' | 'number' | 'boolean' | 'date' | 'actions'
	render: GridCellDisplayFunction,
	allowFilters?: boolean


	/* 
		Right now filterRequired and filterDefaultValue only fully supports date filters. 
		Which are a bit complex since they have lt, gt, and eq filters rolled into one filter.

		Currently, since this (https://missionincrease.atlassian.net/browse/MA20-1138) is the only use calling for a required filter,
		it arbitrarily applies the requirement and the default value to the 'gt' filter inside a date filter.

		While the default value supports all filter value types, the type needs to match the value type for the filter.
		E.g. a boolean filter default value needs to be a boolean, a date filter default value actually needs to be a string (see IDateFilter, which should probably be changed to a Date instead of a string), etc.
	*/
	filterRequired?: boolean
	filterDefaultValue?: FilterValues

	/* 
		The idea here is to be able to expand into different possible validation criteria. 
		For now it only supports a "max range" validator for the date filter (to support https://missionincrease.atlassian.net/browse/MA20-1246)
	*/
	filterValidation?: {

		// When used on a date filter, the maxRange unit is days.
		maxRange?: number
	}

	filterUnallowedMessage?: string
	filterTypeOverride?: 'tags'
	disableFilters?: boolean
	filterOptions?: IGridColumnFilterOption[] // Provide a list of options by which to filter this column.
	disableSort?: boolean
	align?: 'right' | 'left' | 'center'
	conditionalCellCSSFormatting?: (row: IGridListItem) => string // Optional function to return optional CSS for the cell (useful for highlighting or other changes based on row values)
	tooltip?: string | ((row: IGridListItem) => string | undefined)
	sortOrder?: number
	dateFormatOverride?: string // Override the default date format if the value is a date
	rowGroupTotal?: boolean
	rowGroupTotalUnique?: boolean
	rowGroupSuffix?: string
}

export interface IGridListItem {
	id: string
	values: {
		[property: string]: GridListItemValueType
	}

	selected?: boolean

	/* 
		An item could be marked as hidden. It will still be included in the results, but it will not be displayed.
		
		Useful for when a placeholder item might be returned as a part of a list that includes Row Groups, 
		but a grouping doesn't include any results yet still needs to be displayed.
	*/
	hidden?: boolean

	// Group for the row grouping this row should be included with (if any)
	// TODO: row groups should probably be separated out off individual grid list items to make it easier to control row group state as a unit (e.g. expanded or collapsed)
	rowGroup?: IRowGroup

	// Set a color for a list item - useful when combining grid rows with dynamic charts
	color?: string
}
export interface IRowGroup {
	id: string
	title: ReactNode
	showEmptyRowGroup?: boolean
	emptyRowGroupLabel?: string
	collapsed?: boolean
}

export const isGridListItem = (object: any): object is IGridListItem => {
	return 'values' in object
}

interface IGridRowActionOptions {
	e: MouseEvent
	row: IGridListItem
	gridState: IGridState
	appActions: IMifActions
	appState: IAppState
}

export interface IGridRowAction {
	id: string
	title?: string
	tooltipText?: string | ((row: IGridListItem) => string | undefined)
	icon?: JSX.Element | ((row: IGridListItem) => JSX.Element) // 20231109 TB - Now that we added the 'url' property, not using this icon prop. Maybe we will find a use for it again someday.
	action: (options: IGridRowActionOptions) => Promise<void>
	disabled?: boolean | ((row: IGridListItem) => boolean)
	hidden?: boolean | ((row: IGridListItem) => boolean)
	url?: string | ((row: IGridListItem) => string )
	anchorTarget?: string
}

export interface IGridRowGroupActionOptions {
	e: MouseEvent
	rowGroupId: string
	gridState: IGridState
	appActions: IMifActions
	appState: IAppState
}

export interface IGridRowGroupAction {
	id: string
	title?: string
	tooltipText?: string | ((rowGroupId: string) => string | undefined)
	icon?: JSX.Element | ((rowGroupId: string, gridState: IGridState) => JSX.Element)
	action: (options: IGridRowGroupActionOptions) => Promise<void>
	disabled?: boolean | ((rowGroupId: string, gridState: IGridState) => boolean)
	hidden?: boolean | ((rowGroupId: string) => boolean)
}

export interface IGridRowGroupActions {
	actions?: IGridRowGroupAction[],
	nestedActions?: IGridRowGroupAction[],
}

export interface IGridStateManagement {
	loading: boolean
	gridState: IGridState
	setGridState: (queryState: IGridState, dataReloadRequired: boolean) => void
	helpers: {
		updateColumn: (_col: IGridColumn, dataReloadRequired: boolean) => void
		getVisibleColumns: () => IGridColumn[]
	}
}


export interface IGridDataUpdate {
	rows: IGridListItem[]
	count?: number
}

export type GridDataFetch<StateType> = (queryState: IQueryState, state: StateType) => Promise<IGridDataUpdate>

export interface IGridState {
	// Configuration
	usingLocalData?: boolean
	disabledPagination?: boolean
	pageSizeOptions: number[]
	rowSelectEnabled?: boolean
	disableSelect?: boolean | ((row: IGridListItem) => boolean) // Optional boolean or function to enable/disable select for a row
	tagType?: TagType
	hideGridHeader?: boolean
	hideGridFooter?: boolean
	enableRowColors?: boolean // Assigns a color for each row in the grid
	disableAllRowSelectOption?: boolean // Removes the checkbox from the header to select all rows. Useful for times when the Grid is used to provide a list from which only a single item should be selected.
	disableExport?: boolean // Turn off the export option

	/* 
		When enabled, the Grid will listen to the GlobalCommunity selection and manage the state of a 
		filter on the property 'branchAbbr' to filter results. 

		If this is enabled on a Grid that does not include a branchAbbr column, it will throw an error.
	*/
	respectGlobalCommunityFilter?: boolean

	/* 
		The key for storing the grid's user interaction state in the user's session state (filters, sorts, page, number of results per page).
		If this key is included, the grid will attempt to restore the state stored in the user's session.
		Then, as the user interacts with the grid, the grid will persist all user interactions to the grid's key value in the user's session state.
	*/
	userSessionStateKey?: string
	overrideInitialStateValues?: {
		queryState?: IQueryState
	}

	// State
	id: string
	version?: number
	lastFetch?: Date
	gridElement?: HTMLDivElement
	activeFilterPopover?: HTMLDivElement | null
	loading: boolean
	columns: IGridColumn[]
	queryState: IQueryState
	allRowsSelected?: boolean
	highlightedRows?: string[] // IDs of rows that are highlighted

	// Data
	dataSource: GridDataFetch<any>
	rows: IGridListItem[]
	selectedRows: {
		[rowId: string]: IGridListItem
	}
	pages: number
	totalCount?: number
	infinitePaging?: boolean // Allows the grid to page forward indefinitely

	// Actions
	rowActions?: { [id: string]: IGridRowAction }
	rowActionsDisplayType?: 'icons' | 'nested-buttons'
	rowGroupActions?: IGridRowGroupActions
	onRowPress?: (row: IGridListItem) => Promise<void> // TODO - this isn't needed yet, but could be used for custom row press actions
	gridActions?: IGridAction[]
	nestedGridActions?: IGridAction[],
	footerActions?: JSX.Element[]
	rowDoubleClicked?: (row: IGridListItem) => Promise<void>
	rowGroupDoubleClicked?: (rowGroupId: string) => Promise<void>
}

export interface IGridAction {
	id: string
	label: string | ((gridState: IGridState) => string)
	icon?: JSX.Element | ((gridState: IGridState) => JSX.Element)
	buttonClass?: string | ((gridState: IGridState) => string) // Allows for dynamically setting the button style based on grid state
	ui?: JSX.Element
	onClick?: (gridState: IGridState, gridActions: IGridActions) => void
	disabled?: boolean | ((gridState: IGridState) => boolean)
	dropdown?: Pick<ISimpleSelectFieldProps, 'options' | 'onChange' | 'value' | 'multiple'> | ((gridState: IGridState, gridActions: IGridActions) => Pick<ISimpleSelectFieldProps, 'options' | 'onChange' | 'value' | 'multiple'>)
	sortOrder?: number
}

export interface IGridUserInteractionState {
	queryState: IQueryState
	columns: IGridColumnUserInteractionState[]
}

interface IGridColumnUserInteractionState {
	property: string
	hide: boolean
	width: number
	sortOrder: number
	disableFilters: boolean
}

export const gridColumnsToGridColumnUserInteractionState = (gridColumns: IGridColumn[]): IGridColumnUserInteractionState[] =>
	gridColumns.map((c, i) => ({ property: c.property, hide: !!c.hide, width: c.width, sortOrder: i, disableFilters: c.disableFilters ? true : false }))

/* 
	Store these keys here to make it easier to check that we're not reusing keys to store Grid user interaction states in the user session state.
	Using the same key for two grids would result in those grids' user interaction states constantly overwriting each other in the user session state.
*/
export enum GridUserInteractionStateKey {
	MinistryManagement = 'ministry-management',
	ArchivedMinistries = 'archived-ministries',
	CurrentMinistries = 'current-ministries',
	Prospects = 'prospects',
	Users = 'user-management',
	Communities = 'community-management',
	EventNews = 'event-news',
	CustomBlast = 'custom-blast',
	ConsultingManagement = 'consulting-management',
	GroupCoaching = 'group-learning',
	Givers = 'givers',
	EventManagement = 'event-management',
	MailLog = 'mail-log',
	GroupLearningEditRegistrants = 'group-learning-edit-registrants',
	EventManagementEditRegistrants = 'event-mngmt-edit-registrants',
	TrainerEvals = 'trainer-evals',
	Grants = 'grants',
	GrantRequests = 'grant-requests',
	GrantFundingSources = 'grant-funding-sources',
	GrantMinistries = 'grant-ministries',
	GrantPayments = 'grant-payments',
	Orders = 'orders',
	ArchivedMinistryContacts = 'archived-ministry-contacts',
	MinistryContacts = 'ministry-contacts',
	MinistryContactCourses = 'ministry-contact-courses',
	LmsCourses = 'lms-courses',
	LmsCoursesEnrollment = 'lms-courses-enrollment',
	ministryInfoCourses = 'ministry-info-courses',
}