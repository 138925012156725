import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { IDefaultProps } from './component-definitions'
import { ReactComponent as PersonX } from '../assets/person-x.svg'
import { UnsubscribeListModel } from '../models/unsubscibe-list'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { MinistryNameMinistryIdDisplay, DefaultGridCellDisplay, GridActionCell } from './grid-cell-displays'
import { Grid } from './grid'
import * as Yup from 'yup'
import { SelectField, TextField } from './forms'
import { Formik, Form } from 'formik'
import { Modal } from './modal'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { SquareDeleteIcon } from './partials'
import { IAppState } from '../stores/app-definitions'
import { ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { MAIL_TYPE_OPTIONS } from '../constants'

// eslint-disable-next-line no-empty-pattern
export const UnsubscribeList = ({ }: IDefaultProps) => {

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {

        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

        try {
            const unsubscribeListQuery = await makeHttpRequestWithUi({
                request: appActions.MailUnsubscribeApi.apiMailUnsubscribeGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the unsubscribe list.'
            })

            const rows = (unsubscribeListQuery.data.data || []).map(UnsubscribeListModel.toGridListItem)

            return {
                count: unsubscribeListQuery.data.totalRows,
                rows,
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const initialGridState: IGridState = {
        ...defaultGridState,
        gridActions: [
            {
                id: 'addEmail',
                label: 'Add Email',
                onClick: () => showHideUpsertEmailModal(true)
            }
        ],
        rowActions: {
            editEmail: {
                id: 'editEmail',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideUpsertEmailModal(true)
                },
                tooltipText: 'Edit email record.',
                icon: <PencilIcon />,
            },
            deleteEmail: {
                id: 'deleteEmail',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideConfirmDeleteModal(true)
                },
                tooltipText: 'Delete email from unsubscribe list',
                icon: <SquareDeleteIcon />,
            }
        },
        columns: [
            {
                property: 'emailAddress',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Email Address',
                description: 'The email in the unsubscribe list. If multiple of the same email is in this list only one is returned.',
                render: MinistryNameMinistryIdDisplay,
            },
            {
                property: 'dateEntered',
                type: 'date',
                width: 100,
                allowFilters: false,
                title: 'Date Entered',
                description: 'This is the date that this email was first entered into this list.',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'branchId',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Community',
                description: 'If an active ministryContact is found (TOP 1) with this email it includes the Branch of this contact.',
                render: DefaultGridCellDisplay,
                filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchId.toString() })),
            },
            {
                property: 'mailTypeCsv',
                type: 'string',
                width: 120,
                allowFilters: true,
                title: 'Unsubscribed From',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 85,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
        ],
        dataSource,
        rowSelectEnabled: false,
        hideGridFooter: false,
        respectGlobalCommunityFilter: true,
        rowDoubleClicked: async (row) => {
			setSelectedRow(row)
            showHideUpsertEmailModal(true)
		},
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

    let submiteForm: () => void

    const [upsertEmailModal, showHideUpsertEmailModal] = useModal()
    const [confirmDeleteModal, showHideConfirmDeleteModal] = useModal()

    return (
        <>
            <Helmet>
                <title>Unsubscribe List</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <PersonX style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Unsubscribe List</h3>
                </div>

                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...upsertEmailModal}
                modalTitle={selectedRow ? 'Update Email' : 'Add Email to Unsubscribe List'}
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                dismissible
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideUpsertEmailModal(false)}>Cancel</button>
                        <button type='submit' onClick={() => submiteForm && submiteForm()} className='btn btn-primary'>{selectedRow ? 'Update' : 'Add'}</button>
                    </>
                }
            >
                {upsertEmailModal.show &&
                    <Formik
                        initialValues={{
                            emailAddress: selectedRow?.values.emailAddress || '',
                            mailTypeIds: typeof selectedRow?.values.mailTypeIds === 'string' ? selectedRow.values.mailTypeIds.split(',') : []
                        }}
                        validationSchema={Yup.object({
                            emailAddress: Yup.string().required('Required'),
                            mailTypeIds: Yup.array().nullable().required('Required').min(1, 'Select at least one mail type.')
                        })}
                        onSubmit={async (values) => {
                            await makeHttpRequestWithUi({
                                // @ts-ignore
                                request: appActions.MailUnsubscribeApi.apiMailUnsubscribePost([{ emailAddress: typeof values.emailAddress === 'string' ? values.emailAddress : null, mailTypeIds: values.mailTypeIds }]),
                                toastErrorMessage: 'There was a problem fetching the unsubscribe list.',
                                toastSuccessMessage: 'Successfully added email to unsubscribe list(s)!',
                            })
                            showHideUpsertEmailModal(false)
                            gridActions.doFetch()
                        }}
                    >
                        {formikProps => {
                            submiteForm = formikProps.submitForm
                            return (
                                <Form>
                                    <TextField fieldProps={{ name: 'emailAddress', label: 'Email Address' }} />
                                    <SelectField
                                        multiple
                                        fieldProps={{ label: 'Mail Types', name: 'mailTypeIds' }}
                                        //options={appState.mailTypes ? appState.mailTypes.filter(o => o.bActive && o.mailTypeId !== null).map(o => ({ label: `${o.name}`, value: o.mailTypeId.toString() })) : []}
                                        options={MAIL_TYPE_OPTIONS}
                                    />
                                </Form>
                            )
                        }}
                    </Formik>
                }
            </Modal>

            <Modal
                {...confirmDeleteModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmDeleteModal(false)}>Cancel</button>
                        <button
                            className='btn btn-danger'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: appActions.MailUnsubscribeApi.apiMailUnsubscribeDelete(typeof selectedRow?.values.emailAddress === 'string' ? selectedRow.values.emailAddress : undefined),
                                        toastSuccessMessage: `Successfully removed ${selectedRow?.values.emailAddress} from all unsubscribe lists.`,
                                        toastErrorMessage: `Encountered an error removing ${selectedRow?.values.emailAddress} from unsubscribe lists.`
                                    })
                                    showHideConfirmDeleteModal(false)
                                    gridActions.doFetch()
                                }
                            }}
                        >
                            Remove
                        </button>
                    </>
                }
            >
                <p>Are you sure you want to remove <b>{selectedRow?.values.emailAddress}</b> from <b>all</b> unsubscribe lists?</p>
            </Modal>
        </>
    )
}