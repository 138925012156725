import { Form, Formik } from "formik"
import { useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IConsultingApptRegistrantDocument, ISearchRequestFilterDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { IFilter } from "../stores/api-actions"
import { IConsultingAppointmentFormRegistrantsProps } from "./consulting-appointment-form-registrants"
import { FormikEffect } from "./formik-effect"
import { CommunitySelectField, ISelectFieldOption, SelectField } from "./forms"
import { Loading, LoadingOverlay, LoadingPropsSizeEnum } from "./loading"
import * as Yup from 'yup'
import { Modal } from "./modal"
import dayjs from "dayjs"

const filter: IFilter = {
    id: 'branchIdFilter',
    enabled: true,
    value: [],
    operator: 'in',
    property: 'branchAbbr'
}

interface IConulstingAppointmentFormRegistrantsForm extends IConsultingAppointmentFormRegistrantsProps {
    afterSave: () => void
}

export const ConulstingAppointmentFormRegistrantsForm = ({ appointmentToEdit, afterSave }: IConulstingAppointmentFormRegistrantsForm) => {

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [ministriesLoading, setMinistriesLoading] = useState(false)
    const [ministryOptions, setMinistryOptions] = useState<ISelectFieldOption[]>([])

    const [contactsLoading, setContactsLoading] = useState(false)
    const [contactOptions, setContactOptions] = useState<ISelectFieldOption[]>([])

    const readOnly = appointmentToEdit && !appointmentToEdit.isEditable

    const [sendNotificationModal, showHideSendNotificationModal] = useModal()
    const [justAddedRegistrants, setJustAddedRegistrants] = useState<IConsultingApptRegistrantDocument[]>()

    const branchId = appState.users?.find(o => o.presenterId?.toString() === appointmentToEdit?.presenterId.toString())?.branchId?.toString()

    const fetchMinistryOptionsForBranchId = async (branchId: string) => {
        const branch = appState.activeBranches.find(o => o.branchId.toString() === branchId)
        if (!branch?.branchAbbr) return

        setMinistriesLoading(true)
        filter.value = [branch.branchAbbr]
        const branchFilter = JSON.stringify([filter]) as unknown as ISearchRequestFilterDocument[]
        const ministryQuery = await makeHttpRequestWithUi({
            request: appActions.MinistriesApi.apiMinistriesGet(0, 100000, undefined, branchFilter),
            toastErrorMessage: 'There was an error retrieving the ministries for this community.',
            disableSuccessToast: true,
            disableLoading: true,
        })
        setMinistryOptions(ministryQuery.data.data?.map(o => ({ label: `${o.ministryName}`, value: `${o.ministryId}` })) || [])
        setMinistriesLoading(false)
    }

    useEffect(() => {
        appActions.fetchMiUsers(true)

        if (branchId) fetchMinistryOptionsForBranchId(branchId)
    }, [])

    return (
        <Formik
            enableReinitialize
            initialValues={{
                branchId: branchId || '',
                ministryId: '',
                ministryContactIds: [],
            }}
            validationSchema={Yup.object({
                branchId: Yup.string().required('Required'),
                ministryId: Yup.string().required('Required'),
                ministryContactIds: Yup.array().nullable().required('Required').min(1, 'Must select at least one ministry contact.')
            })}
            onSubmit={async (values, actions) => {
                if (!appointmentToEdit) return

                const addRegistrantsQuery = await makeHttpRequestWithUi({
                    request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdRegistrantsPost(appointmentToEdit.consultingEventGroupId, { ministryId: parseInt(values.ministryId), ministryContactIds: values.ministryContactIds.map(o => parseInt(o)) }),
                    toastErrorMessage: 'Encountered an error adding registrants.',
                    toastSuccessMessage: 'Successfully added registrants.'
                })

                setJustAddedRegistrants(addRegistrantsQuery.data)
                if (dayjs(appointmentToEdit.endDateTime).isAfter(dayjs())) showHideSendNotificationModal(true)

                actions.resetForm()
                afterSave()
            }}
            children={formikProps => {
                return (
                    <>
                        {!appointmentToEdit &&
                            <p>
                                <b className='text-danger'>Please finish saving the Appointment Details before adding any recipients.</b>
                            </p>
                        }

                        {!appState.users && <LoadingOverlay position='absolute' />}

                        <FormikEffect
                            formikProps={formikProps}
                            onChange={async (prevValues, nextValues) => {
                                if (!nextValues.branchId) setMinistryOptions([])
                                if (nextValues.branchId && nextValues.branchId !== prevValues.branchId) {
                                    fetchMinistryOptionsForBranchId(nextValues.branchId)
                                }

                                if (!nextValues.ministryId) setContactOptions([])
                                if (nextValues.ministryId && nextValues.ministryId !== prevValues.ministryId) {
                                    setContactsLoading(true)
                                    const contactsQuery = await makeHttpRequestWithUi({
                                        request: appActions.MinistryContactsApi.apiMinistriesIdContactsGet(parseInt(nextValues.ministryId)),
                                        disableSuccessToast: true,
                                        toastErrorMessage: 'There was an error retrieving the contacts for this ministry.',
                                        disableLoading: true,
                                    })
                                    setContactOptions(contactsQuery.data.map(o => ({ label: `${o.firstName} ${o.lastName}`, value: `${o.ministryContactId}` })))
                                    setContactsLoading(false)
                                }
                            }}
                        />
                        <Form style={{ padding: 10, border: '1px solid #dee2e6', borderRadius: 10, marginBottom: 10 }}>
                            <h5>Add Registrants</h5>
                            <div style={{ display: 'flex' }}>
                                <p>Manually register ministry contacts on their behalf. After you add one or more ministry contacts you will be asked if 
                                    you would like to send them a registration confirmation email.
                                </p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <CommunitySelectField
                                        fieldProps={{ name: 'branchId', label: 'Community' }}
                                        disabled={!appointmentToEdit || readOnly}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    {ministriesLoading ?
                                        <Loading size={LoadingPropsSizeEnum.small} />
                                        :
                                        <SelectField fieldProps={{ name: 'ministryId', label: 'Ministry' }} options={ministryOptions} disabled={ministryOptions.length === 0} />
                                    }
                                </div>
                            </div>
                            <div style={{ minHeight: 85 }}>
                                {contactsLoading ?
                                    <Loading size={LoadingPropsSizeEnum.small} />
                                    :
                                    <SelectField
                                        fieldProps={{ name: 'ministryContactIds', label: 'Contacts' }}
                                        options={contactOptions}
                                        disabled={contactOptions.length === 0}
                                        multiple
                                    />
                                }
                            </div>

                            <div style={{ display: 'flex' }}>
                                <button disabled={!appointmentToEdit || readOnly} style={{ width: 100, marginRight: 10 }} type='button' className='btn btn-secondary' onClick={() => formikProps.resetForm()}>Clear</button>
                                <button style={{ width: 100 }} className='btn btn-primary' disabled={!appointmentToEdit || readOnly}>Add</button>
                            </div>
                        </Form>

                        <Modal
                            {...sendNotificationModal}
                            modalTitle='Send Notification?'
                            _onModalHidden={() => {
                                setJustAddedRegistrants(undefined)
                            }}
                            footer={
                                <>
                                    <button className='btn btn-secondary' onClick={() => showHideSendNotificationModal(false)}>Don't Send</button>
                                    <button
                                        className='btn btn-primary'
                                        onClick={async () => {
                                            if (!appointmentToEdit || !justAddedRegistrants) return
                                            await makeHttpRequestWithUi({
                                                request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdSendRegistrantNotificationsPost(appointmentToEdit.consultingEventGroupId, justAddedRegistrants.map(o => ({ seminarAttendeeId: o.seminarAttendeeId, attendeeGuid: o.attendeeGuid, email: o.email }))),
                                                toastErrorMessage: 'Encountered an error sending notifications.',
                                                toastSuccessMessage: 'Successfully sent notifications.',
                                            })
                                            showHideSendNotificationModal(false)
                                            afterSave()
                                        }}
                                    >
                                        Send
                                    </button>
                                </>
                            }
                        >
                            <p>Would you like to send a registration confirmation email to these registrants?</p>
                            {justAddedRegistrants?.map(o => <p key={o.seminarAttendeeId}>{o.email}</p>)}

                            <p>(You can send individual notifications later.)</p>
                        </Modal>
                    </>
                )
            }}
        />
    )
}