import { useContext } from 'react'
import { GridPageNavigation } from './grid-page-navigation'
import { GridContext } from './grid'
import { download } from '../services/helpers'
import moment from 'moment'

export const GridFooter = () => {
	const context = useContext(GridContext)!

	const onDownloadClick = async () => {
		const csv = await context.actions.exportData()

		download(`MI Export ${moment().format('MM_DD_YYYY')}.csv`, csv)
	}

	const selectedRowCount = Object.keys(context.state.selectedRows).length

	return (
		<div className='grid-footer'>
			{!context.state.disabledPagination ?
				<div className='grid-footer-pagination'>
					<GridPageNavigation />
				</div>
				:
				(context.state.totalCount && !context.state.infinitePaging) ?
					<div className='grid-footer-pagination'>
						<span className='mr-2 text-muted'>{context.state.totalCount?.toLocaleString('en-US', { maximumFractionDigits: 0 })} records</span>
					</div>
					:
					null
			}

			<div className={!context.state.disabledPagination ? '_row vertical-center margin-right' : 'row m-1'}>
				{!context.state.disableExport && context.state.totalCount ?
					<button
						type="button"
						disabled={context.state.loading || context.state.totalCount > 3000}
						title={context.state.totalCount > 3000 ? 'Cannot export more than 3,000 records.' : ''}
						className="btn btn-sm btn-outline-secondary"
						onClick={onDownloadClick}
					>
						Export{selectedRowCount > 0 ? ` Selected (${selectedRowCount})` : ' all'}
					</button>
					:
					null
				}
				{context.state.footerActions}
			</div>
		</div>
	)
}