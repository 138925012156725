import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IMinistryDataFiscalYearSummaryDocument, IMinistryDataModelDocument } from '../open-api'
import { objectToOperationDocument } from '../services/helpers'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { CheckboxField, CurrencyField, NumberField, TextareaField } from './forms'
import { HistoryCard } from "./history-card"
import { FormikEffect } from './formik-effect'
import * as Yup from 'yup'
import { FormikTextAreaField } from './forms/formik-text-area-field'

interface IMinistryInfoFyDataGeneralDataForm {
    ministryInfoFyDataGeneral: IMinistryDataFiscalYearSummaryDocument | IMinistryDataModelDocument
    afterSave?: () => void
}

export const MinistryInfoFyDataGeneralDataForm = (props: IMinistryInfoFyDataGeneralDataForm) => {
    const { ministryInfoFyDataGeneral, afterSave } = props

    const { MinistryDataApi } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [changeReasonRequired, setChangeReasonRequired] = useState<boolean>(false)
    const [triggerRefresh, setTriggerRefresh] = useState<number>(0)

    return (
        <Formik
            initialValues={{...ministryInfoFyDataGeneral, changeReason: ''}}
            enableReinitialize={true}
            validationSchema={Yup.object({
                changeReason: Yup.string()
                    .test(
                        'changeReason',
                        'Required',
                        function (value) {
                            if (changeReasonRequired) return !!value
                            return true
                        }
                    ),
            })}
            onSubmit={async (values) => {

                // Not allowed to patch bReportMiincome, so remove it.
                // See: https://dev.to/darksmile92/js-use-spread-to-exclude-properties-1km9
                const patches = {
                    ministryDataId: values.ministryDataId,
                    bIgnoreDiscrepancy: values.bIgnoreDiscrepancy,
                    bLeverageSummaryReviewed: values.bLeverageSummaryReviewed,
                    gsDiscrepancyExplanation: values.gsDiscrepancyExplanation,
                    gs990MissingExplanation: values.gs990MissingExplanation,
                    income: values.income !== null ?  values.income.toString().match(/\d/g)?.join('') : null, // Remove non-numbers (e.g., commans) or endpoint won't patch data.
                    gsIncome: values.gsIncome !== null ?  values.gsIncome.toString().match(/\d/g)?.join('') : null,
                    expenses: values.expenses !== null ?  values.expenses.toString().match(/\d/g)?.join('') : null,
                    gsExpenses: values.gsExpenses !== null ?  values.gsExpenses.toString().match(/\d/g)?.join('') : null,
                    numEvents: values.numEvents !== null ?  values.numEvents.toString().match(/\d/g)?.join('') : null,
                    eventAttendance: values.eventAttendance !== null ?  values.eventAttendance.toString().match(/\d/g)?.join('') : null,
                    donorsAcquired: values.donorsAcquired !== null ?  values.donorsAcquired.toString().match(/\d/g)?.join('') : null,
                    majorDonorsAcquired: values.majorDonorsAcquired !== null ?  values.majorDonorsAcquired.toString().match(/\d/g)?.join('') : null,
                    totalDonors: values.totalDonors !== null ?  values.totalDonors.toString().match(/\d/g)?.join('') : null,
                    totalMajorDonors: values.totalMajorDonors !== null ?  values.totalMajorDonors.toString().match(/\d/g)?.join('') : null,
                    totalServed: values.totalServed !== null ?  values.totalServed.toString().match(/\d/g)?.join('') : null, 
                    fulltimeStaff: values.fulltimeStaff !== null ?  values.fulltimeStaff.toString().match(/\d/g)?.join('') : null, 
                    parttimeStaff: values.parttimeStaff !== null ?  values.parttimeStaff.toString().match(/\d/g)?.join('') : null, 
                }
                
                await makeHttpRequestWithUi({
                    request: MinistryDataApi.apiMinistryDataIdPatch(ministryInfoFyDataGeneral.ministryDataId, values.changeReason, objectToOperationDocument(patches)),
                    toastSuccessMessage: `Successfully updated FY data for ${ministryInfoFyDataGeneral.fiscalYear}.`,
                    toastErrorMessage: `Encountered an error updating FY data for ${ministryInfoFyDataGeneral.fiscalYear}.`,
                })

                setTriggerRefresh(triggerRefresh + 1)

                if (afterSave) afterSave()
            }}
        >   
            {formikProps => (
                <Form>
                    <FormikEffect
                        formikProps={formikProps}
                        onChange={(prevValues, nextValues) => {
                            if (nextValues.bLeverageSummaryReviewed !== prevValues.bLeverageSummaryReviewed) {
                                if (!!nextValues.bLeverageSummaryReviewed)
                                    setChangeReasonRequired(true)
                                else
                                    setChangeReasonRequired(false)
                            }
                        }}
                    />
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-8'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><CurrencyField fieldProps={{name: 'income', label: 'Income'}} /></div>
                                        {/* <div className='mr-2' style={{flex: 1}}><CurrencyField fieldProps={{name: 'gsIncome', label: 'GS Income'}} /></div> */}
                                        <div className='mr-2' style={{flex: 1}}><CurrencyField fieldProps={{name: 'expenses', label: 'Expenses'}} /></div>
                                        {/* <div className='mr-2' style={{flex: 1}}><CurrencyField fieldProps={{name: 'gsExpenses', label: 'GS Expenses'}} /></div> */}
                                    </div>
                                    <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><CheckboxField fieldProps={{name: 'bIgnoreDiscrepancy', label: 'Ignore GS Discrepancy?'}} /></div>
                                        {/* <div className='mr-2' style={{flex: 1}}><CheckboxField fieldProps={{name: 'downloadedFromGS', label: 'Downloaded from GS?'}} /></div> */}
                                        <div className='mr-2' style={{flex: 1}}><CheckboxField fieldProps={{
                                            name: 'bLeverageSummaryReviewed', 
                                            label: 'Leverage Summary Reviewed', 
                                            labeltooltip: 'Only used in relationship to the Leverage Summary Report. Checking this removes the record from the discrepancy report.'}} /></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><TextareaField disabled={!!formikProps.values.bIgnoreDiscrepancy} fieldProps={{name: 'gsDiscrepancyExplanation', label: 'GS Discrepancy Explanation'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><TextareaField fieldProps={{name: 'gs990MissingExplanation', label: 'GS 990 Missing Explanation'}} /></div>
                                    </div>
                                
                                    <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'numEvents', label: '# Events'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'eventAttendance', label: 'Attendance'}} /></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'donorsAcquired', label: 'New Donors'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'majorDonorsAcquired', label: 'New Major Donors'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'totalDonors', label: 'Total Donors'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'totalMajorDonors', label: 'Total Major Donors'}} /></div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'totalServed', label: '# Served'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'fulltimeStaff', label: 'FT Staff'}} /></div>
                                        <div className='mr-2' style={{flex: 1}}><NumberField fieldProps={{name: 'parttimeStaff', label: 'PT Staff'}} /></div>
                                    </div>
                                    {formikProps.dirty && <div className='d-flex'>
                                        <div className='mr-2' style={{flex: 1}}><FormikTextAreaField field={{name: 'changeReason', label: 'Why are you making this change?', labelTooltip: 'Give an explanation for these changes. Only required if "Leverage Summary Reviewed" was checked.'}}  /> </div>
                                    </div> }
                                    <div className='d-flex justify-content-center'>
                                        <button type='submit' className='btn btn-primary'>Submit</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <HistoryCard tableName='MinistryData' tableId={ministryInfoFyDataGeneral.ministryDataId} triggerRefresh={triggerRefresh} />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}


