import { IGridListItem } from '../stores/grid-definitions'
import { IMailBlastSummaryDocument } from '../open-api'

export enum MailBlastStatusId {
	'Draft' = 1,
	'Awaiting Approval' = 2,
	'Ready to Send' = 3,
	'In Progress' = 4,
	'Complete' = 5,
	'Archived' = 6,
	'Scheduled' = 7,
	'Canceled' = 8,
}

export const getMailBlastStatusFromStatusId = (statusId: number) => {
	switch (statusId) {
		case 1:
			return 'Draft'
		case 2:
			return 'Awaiting Approval'
		case 3:
			return 'Ready to Send'
		case 4:
			return 'In Progress'
		case 5:
			return 'Complete'
		case 6:
			return 'Archived'
		case 7:
			return 'Scheduled'
		case 8:
			return 'Canceled'
		default:
			return ''
	}
}

export class MailBlast {
	public static toGridListItem = (mailBlastSummary: IMailBlastSummaryDocument): IGridListItem => {
		return {
			id: mailBlastSummary.mailBlastId.toString(),
			values: {
				...mailBlastSummary
			}
		}
	}
}