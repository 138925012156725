import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IDefaultProps } from "./component-definitions"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from "powerbi-client-react"

// Map of event handlers to be applied to the embedding report
const eventHandlersMap = new Map([
    ['loaded', function () {
        console.log('Report has loaded')
    }],
    ['rendered', function () {
        console.log('Report has rendered')
    }],
    ['error', function (event?: service.ICustomEvent<any>) { 
        if (event) {
            console.log('error occurred')
            console.error(event.detail)
        }
    }]
]);

export const LeverageSummaryReport = ({ }: IDefaultProps) => {
    const { currentUser } = useContext(AppStateContext)!
    const { PbiApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    // PowerBI Report object (to be received via callback)
	//const [report, setReport] = useState<Report>();
    const [report, setReport] = useState<Report>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()

    // Report config useState hook
	// Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
	const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: {  
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                },
                pageNavigation: {
                    visible: true
                }
            } 
        }
	})

    const fetchReport = async () => {
        setLoading(true)
        const {data} = await makeHttpRequestWithUi({
            request: PbiApi.apiPbiReportIdIdGet('82d4540b-c6b1-4b5d-8eab-bb426edcf6b7'),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch report.',
            disableLoading: true,
        })

        if (data) {
            if (data.embedUrl) {
                setError('')
                // Set the fetched embedUrl and embedToken in the report config
                setReportConfig({
                    ...reportConfig,
                    embedUrl: data.embedUrl || '',			// embedUrl: reportConfig.EmbedUrl,
                    accessToken: data.token || ''	// accessToken: reportConfig.EmbedToken.Token
                })
            } else {
                setError("Report unavailable. Reason: " + data.noReportReason)
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchReport()
    }, [])
    
    return(
        <>
            <Helmet>
                <title>Leverage Summary Report</title>
            </Helmet>

            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active">Reports</li>
                <li className="breadcrumb-item active" aria-current="page">Leverage Summary Report</li>
            </ol>
            </nav>
            <div className='d-flex flex-column' style={{ minHeight: 0, overflow: 'hidden' }}>
                <div className='m-2 d-flex align-items-center'>
                    {/* <HouseIcon style={{ width: '25px', height: '25px' }} /> */}
                    <h3 className='ml-2'>Leverage Summary Report</h3>
                </div>
                <div className="row pl-3 pr-3" style={{ overflow: 'hidden' }}>
                    {!currentUser?.bSuperUser ? 
                        <div className="col-sm-12">
                            Only Super Users may view this report. To request access to this report, contact <a href="mailto:tbirdsall@mif.org">tbirdsall@mif.org</a>
                        </div> 
                        :
                        <div className="col-sm-12">
                        { error ?
                            <div className="alert alert-warning m-5">{error}</div>
                            :
                            <PowerBIEmbed
                                embedConfig = { reportConfig }
                                eventHandlers = { eventHandlersMap }
                                cssClassName = { "report-style-class" }
                                getEmbeddedComponent = { (embedObject:Embed) => {
                                    //console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
                                    setReport(embedObject as Report);
                                } }
                            /> }
                        </div>
                    }
                </div>
            </div>
        </>
    )
}