import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { IDefaultProps } from './component-definitions'
import { ReactComponent as EnvelopeIcon } from '../assets/envelope.svg'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IMailBlastSummaryDocument, IRecipientSearchResultDocument, ISearchRequestFilterDocument, ISearchSortDocument, IStatsDocument } from '../open-api'
import { useCustomMailBlastColumns, useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { IAppState } from '../stores/app-definitions'
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from '../stores/grid-definitions'
import { MailBlast, MailBlastStatusId } from '../models/custom-email-blast'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { ReactComponent as PeopleIcon } from '../assets/people.svg'
import { ReactComponent as FileSpreadsheetIcon } from '../assets/file-spreadsheet.svg'
import { Modal } from './modal'
import { CustomMailBlastOverview } from './custom-mail-blast-overview'
import { CustomMailBlastRecipients } from './custom-mail-blast-recipients'
import { EventNewsStats } from './event-news-stats'
import { SquareDeleteIcon } from './partials'
import { CustomEmailBlastForm, ICustomEmailBlastInitialValues } from './custom-email-blast-form'
import { uuidv4 } from '../services/helpers'
import { LayersIcon, XCircleIcon } from '../assets'
import { navigate, useLocation } from '@reach/router'
import { MAIL_TYPE } from '../constants'
import { IFilter } from '../stores/api-actions'

interface ICustomEmailBlast extends IDefaultProps { }

export const CustomEmailBlast = (props: ICustomEmailBlast) => {
	const { } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
		// We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
		const filters = JSON.stringify([{ operator: 'in', value: ['1', MAIL_TYPE['All MA Email'], MAIL_TYPE['All CE Email']], property: 'mailTypeId' }, ...queryState.filters?.filter(o => o.enabled && o.value) || []]) as unknown as ISearchRequestFilterDocument[]
		const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

		try {
			const mailBlastQuery = await makeHttpRequestWithUi({
				request: appActions.MailBlastApi.apiMailBlastGet((queryState.page - 1) * queryState.itemsPerPage, queryState.itemsPerPage, sorts, filters),
				disableLoading: true,
				disableSuccessToast: true,
				toastErrorMessage: 'There was a problem fetching the list of custom mail blasts.'
			})

			return {
				rows: mailBlastQuery.data ? mailBlastQuery.data.map(MailBlast.toGridListItem) : [],
				count: mailBlastQuery.data.length ? mailBlastQuery.data[0].totalCount : 0
			}
		} catch (e) {
			return {
				rows: [],
				count: 0
			}
		}
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [summary, setSummary] = useState<IMailBlastSummaryDocument>()
	const [recipients, setRecipients] = useState<IRecipientSearchResultDocument[]>()
	const [mailBlastOverviewModal, showHideMailBlastOverviewModal] = useModal()

	const [mailBlastRecipientsModal, showHideMailBlastRecipientsModal] = useModal()
	const [confirmMailBlastDraftDeleteModal, showHideConfirmMailBlastDraftDeleteModal] = useModal()
	const [confirmMailBlastCancelModal, showHideConfirmMailBlastCancelModal] = useModal()

	const [emailBlastFormModal, showHideEmailBlastFormModal] = useModal()
	const [confirmDuplicateMailBlastModal, showHideConfirmDuplicateMailBlastModal] = useModal()

	const [statsModal, showHideStatsModal] = useModal()
	const [emailStats, setEmailStats] = useState<IStatsDocument>()

	const editViewMailBlast = async (row: IGridListItem) => {
		const summaryQuery = await makeHttpRequestWithUi({
			request: appActions.MailBlastApi.apiMailBlastIdSummaryGet(parseInt(row.id)),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the summary for this mail blast.'
		})

		if (row.values.dSend) {
			setSummary(summaryQuery.data)

			const mailBlastIdFilter: IFilter = {
				id: 'mailBlastId',
				value: parseInt(row.id),
				operator: 'eq',
				property: 'mailBlastId',
				enabled: true,
				hidden: true
			}
			const filters = JSON.stringify([mailBlastIdFilter])  as unknown as ISearchRequestFilterDocument[]
			const sorts = undefined

			const recipientsQuery = await makeHttpRequestWithUi({
				request: appActions.MailBlastApi.apiMailBlastRecipientsGet(1, 999999, sorts, filters),
				disableSuccessToast: true,
				toastErrorMessage: 'There was an error retrieving the recipients for this mail blast.'
			})

			setRecipients(recipientsQuery.data.data || [])

			// View the mail blast overview
			showHideMailBlastOverviewModal(true)
		} else {
			if (summaryQuery.data.category === null) {
				appActions.addAlert({ id: uuidv4(), body: 'Cannot edit mail blasts created with MI Admin 1.0.' })
			} else {
				// Edit an unsent mail blast
				showHideEmailBlastFormModal(true)
			}
		}
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		loading: true,
		columns: useCustomMailBlastColumns(),
		dataSource,
		userSessionStateKey: GridUserInteractionStateKey.CustomBlast,
		gridActions: [
			{
				id: 'createEmailBlast',
				label: 'New Custom Blast',
				onClick: () => showHideEmailBlastFormModal(true),
			}
		],
		rowDoubleClicked: async (row) => {
			editViewMailBlast(row)
		},
		rowActions: {
			editMailBlast: {
				id: 'editMailBlast',
				action: async (options) => {
					options.e.stopPropagation()

					setSelectedRow(options.row)

					editViewMailBlast(options.row)
				},
				icon: (row) => row.values.dSend ? <SearchIcon /> : <PencilIcon />,
				tooltipText: (row) => row.values.dSend ? 'View Blast' : 'Edit Blast',
				hidden: (row) => row.values.mailStatusId === MailBlastStatusId.Canceled
			},
			viewRecipients: {
				id: 'viewRecipients',
				action: async (options) => {
					options.e.stopPropagation()

					// const summaryQuery = await makeHttpRequestWithUi({
					// 	request: appActions.MailBlastApi.apiMailBlastIdSummaryGet(parseInt(options.row.id)),
					// 	disableSuccessToast: true,
					// 	toastErrorMessage: 'There was an error retrieving the summary for this mail blast.'
					// })

					// if (summaryQuery.data.category === null) {
					// 	appActions.addAlert({ id: uuidv4(), body: 'Stats data is not available for mail blasts created with MI Admin 1.0.' })
					// } else {
					// 	showHideMailBlastRecipientsModal(true)
					// 	setSelectedRow(options.row)
					// }

					showHideMailBlastRecipientsModal(true)
					setSelectedRow(options.row)
				},
				icon: <PeopleIcon />,
				tooltipText: 'Recipients',
				hidden: (row) => !row.values.dSend || row.values.mailStatusId === MailBlastStatusId.Canceled,
			},
			viewEmailStats: {
				id: 'viewEmailStats',
				action: async (options) => {
					options.e.stopPropagation()

					const summaryQuery = await makeHttpRequestWithUi({
						request: appActions.MailBlastApi.apiMailBlastIdSummaryGet(parseInt(options.row.id)),
						disableSuccessToast: true,
						toastErrorMessage: 'There was an error retrieving the summary for this mail blast.'
					})

					if (summaryQuery.data.category === null) {
						appActions.addAlert({ id: uuidv4(), body: 'Stats data is not available for mail blasts created with MI Admin 1.0.' })
					} else {
						const statsQuery = await makeHttpRequestWithUi({
							request: appActions.MailBlastApi.apiMailBlastIdStatsSummaryGet(parseInt(options.row.id)),
							disableSuccessToast: true,
							toastErrorMessage: 'Failed to fetch stats for this mail blast.'
						})

						if (statsQuery.status === 200) setEmailStats(statsQuery.data)
						setSelectedRow(options.row)
						showHideStatsModal(true)
					}
				},
				icon: <FileSpreadsheetIcon />,
				tooltipText: 'Blast Stats',
				hidden: (row) => !row.values.dSend || row.values.mailStatusId === MailBlastStatusId.Canceled,
			},
			deleteEmailBlast: {
				id: 'deleteEmailBlast',
				action: async (options) => {
					if (Boolean(options.row.values.isDeletable)) {
						setSelectedRow(options.row)
						showHideConfirmMailBlastDraftDeleteModal(true)
					}
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete Draft',
				hidden: (row) => !Boolean(row.values.isDeletable)
			},
			cancelEmailBlast: {
				id: 'cancelEmailBlast',
				action: async (options) => {
					setSelectedRow(options.row)
					showHideConfirmMailBlastCancelModal(true)
				},
				icon: <XCircleIcon />,
				tooltipText: 'Cancel Blast',
				hidden: (row) => !row.values.isCancelable
			},
			duplicateEmailBlast: {
                id: 'duplicateEmailBlast',
                action: async ({ row }) => {
                    setSelectedRow(row)
					showHideConfirmDuplicateMailBlastModal(true)
                },
                tooltipText: 'Duplicate Blast',
                icon: <LayersIcon />
            }
		}
	}

	// Check to see if we were sent to this URL with some options for starting a Custom Blast
	const location = useLocation()
	const [initialValues, setInitialValues] = useState<ICustomEmailBlastInitialValues>()
	useEffect(() => {
		const initialValues = (new URLSearchParams(location.search)).get('initialValues')
		if (initialValues) {
			try {
				const parsedStatus = JSON.parse(decodeURIComponent(initialValues)) as ICustomEmailBlastInitialValues
				setInitialValues(parsedStatus)
				showHideEmailBlastFormModal(true)
			} catch (e) { console.log(e) }

			// Clear the initial values params from the URL (else if the user refreshed the page the form will open again)
			navigate('/custom-blast', { replace: true })
		}
	}, [])

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	return (
		<React.Fragment>
			<Helmet>
				<title>Custom Blast</title>
			</Helmet>

			<div className='d-flex flex-column' style={{ height: '100vh' }}>
				<div className='m-2 d-flex align-items-center'>
					<EnvelopeIcon style={{ width: '25px', height: '25px' }} />
					<h3 className='ml-2'>Custom Blast</h3>
				</div>

				<Grid state={gridState} actions={gridActions} />
			</div>

			<Modal
				{...mailBlastOverviewModal}
				modalTitle='Mail Blast Overview'
				size='xl'
				_onModalHidden={() => {
					setSelectedRow(undefined)
					setRecipients(undefined)
					setSummary(undefined)
				}}
			>
				{recipients && summary && mailBlastOverviewModal.show ? <CustomMailBlastOverview recipients={recipients} summary={summary} /> : null}
			</Modal>

			<Modal
				{...mailBlastRecipientsModal}
				modalTitle='Mail Blast Recipients'
				size='xl'
				_onModalHidden={() => setSelectedRow(undefined)}
			>
				{selectedRow && mailBlastRecipientsModal.show ? <CustomMailBlastRecipients mailBlastId={parseInt(selectedRow.id)} /> : null}
			</Modal>

			<Modal
				{...statsModal}
				modalTitle={`Results: ${selectedRow?.values.subject}`}
				_onModalHidden={() => {
					setEmailStats(undefined)
					setSelectedRow(undefined)
				}}
				size='xl'
			>
				{emailStats ?
					<EventNewsStats emailStats={[emailStats]} hideTypeColumn={true} />
					:
					<p>There was an error retrieving the results for this blast. Occasionally, it may take up to 12 hours before stats become available.</p>
				}
			</Modal>

			<Modal
				{...confirmMailBlastDraftDeleteModal}
				modalTitle='Confirm'
				_onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary'>Cancel</button>
						<button
							type='button'
							className='btn btn-danger'
							onClick={async () => {
								if (selectedRow) {
									await makeHttpRequestWithUi({
										request: appActions.MailBlastApi.apiMailBlastIdDelete(parseInt(selectedRow.id)),
										toastSuccessMessage: 'Successfully deleted mail blast draft.',
										toastErrorMessage: 'Encountered an error deleting mail blast draft',
									})
									showHideConfirmMailBlastDraftDeleteModal(false)
									gridActions.doFetch()
								}
							}}
						>
							Delete
						</button>
					</React.Fragment>
				}
			>
				Are you sure you want to delete this draft mail blast?
			</Modal>

			<Modal
				{...confirmMailBlastCancelModal}
				modalTitle='Confirm'
				_onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary'>No</button>
						<button
							type='button'
							className='btn btn-warning'
							onClick={async () => {
								if (selectedRow) {
									await makeHttpRequestWithUi({
										request: appActions.MailBlastApi.apiMailBlastIdCancelScheduledSendPost(parseInt(selectedRow.id)),
										toastSuccessMessage: 'Successfully canceled mail blast.',
										toastErrorMessage: 'Encountered an error canceling mail blast',
									})
									showHideConfirmMailBlastCancelModal(false)
									gridActions.doFetch()
								}
							}}
						>
							Yes
						</button>
					</React.Fragment>
				}
			>
				<p>Are you sure you want to cancel this mail blast?</p>

				<b>This action cannot be undone.</b>
			</Modal>

			<Modal
				{...emailBlastFormModal}
				modalTitle='Custom Blast Form'
				size='xl'
				_onModalHidden={() => setSelectedRow(undefined)}
			>
				{emailBlastFormModal.show ?
					<CustomEmailBlastForm
						afterSave={async (keepModalOpen) => {
							if (!keepModalOpen) showHideEmailBlastFormModal(false)
							gridActions.doFetch()
						}}
						mailBlast={selectedRow ?
							{
								mailBlastId: parseInt(selectedRow.id),
								...selectedRow.values,
							} as IMailBlastSummaryDocument
							:
							undefined
						}
						initialValues={initialValues}
					/>
					:
					null
				}
			</Modal>
			<Modal
                {...confirmDuplicateMailBlastModal}
                modalTitle='Confirm Duplication'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmDuplicateMailBlastModal(false)} >Cancel</button>
                        <button
                            className='btn btn-warning'
                            onClick={async () => {
                                if (!selectedRow) return
                                await makeHttpRequestWithUi({
									request: appActions.MailBlastApi.apiMailBlastMailBlastIdDuplicatePost(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Mail blast successfully duplicated.',
									toastErrorMessage: 'Encountered an error duplicating mail blast.',
								})
                                showHideConfirmDuplicateMailBlastModal(false)
								gridActions.doFetch()
                            }}
                        >
                            Duplicate
                        </button>
                    </>
                }
            >
                Are you sure you want to duplicate this mail blast?
            </Modal>
		</React.Fragment>
	)
}