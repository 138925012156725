import React, { useContext, useEffect, useState } from 'react'
import { MinistryInfoContext } from './ministry-info'
import { AppActionContext } from '../app-store-provider'

export const MinistryInfoGsReport = () => {
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

	const appActions = useContext(AppActionContext)!

	const [signedUrl, setSignedUrl] = useState<string>()

	useEffect(() => {
		const fetchData = async () => {
			if (ministryInfoState.ministry) {
				const response = await appActions.MinistryFilesApi.apiMinistryFilesMinistryIdGsReportGetSignedUrlGet(ministryInfoState.ministry?.ministryId);
				setSignedUrl(response.data)
			}
		}

		fetchData()
	}, [])

	if (!signedUrl) {
		return <div>No GuideStar data is available.</div>
	}
	
	return (
		<div className='d-flex' style={{ height: '100%' }}>
			<iframe title='Profile Log' src={signedUrl} style={{ width: '100%' }}></iframe>
		</div>
	)
}