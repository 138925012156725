import { navigate, useLocation } from "@reach/router"
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react"
import { useDebounce } from "react-use"
import { AppActionContext } from "../app-store-provider"

/** 
    GlobalSearch

    - Whenever a user enters an input into Search, navigate to /search if not already there.
    - Debounce search input changes to prevent hitting the search endpoint too much
*/
export const GlobalSearch = () => {

    const { setGlobalSearchTerm } = useContext(AppActionContext)!

    const location = useLocation()

    const [searchTerm, setSearchTerm] = useState('')
    const handleInputChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(ev.currentTarget.value)
    }, [])

    useDebounce(
        () => {
            setGlobalSearchTerm(searchTerm)
        },
        800,
        [searchTerm]
    )

    useEffect(() => {
        if (searchTerm.length && !location.pathname.split('/').includes('search')) navigate('/search')
    }, [searchTerm])

    return (
        <input
            className='form-control'
            placeholder='Global Search'

            value={searchTerm}
            onChange={handleInputChange}
        />
    )
}