import { Form, Formik, FormikProps } from 'formik'
import { useContext } from 'react'
import { IGetGiverResultDocument, IPostGiverRequestDocument, IPutGiverRequestDocument } from "../open-api"
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import * as Yup from 'yup'
import { TextField } from './forms'

interface IGiverFormProps {
	giverOrgId?: number
	giverToEdit?: IGetGiverResultDocument
	onSaveSucces?: () => void
}
export const GiverForm = (props: IGiverFormProps) => {
    const { giverOrgId, giverToEdit, onSaveSucces } = props

    const { GiverApi } = useContext(AppActionContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const initialValues = {
		giverOrgId: giverToEdit?.giverOrgId ?? giverOrgId ?? 0,
		email: giverToEdit?.email || '',
		firstName: giverToEdit?.firstName || '',
		lastName: giverToEdit?.lastName || ''
	}

	const validationSchema = Yup.object({
		giverOrgId: Yup.string().required('Required'),
		email: Yup.string().required('Required').email('Must be a valid email address.'),
		firstName: Yup.string().required('Required'),
		lastName: Yup.string().required('Required'),
	})

    let _formikProps: FormikProps<any>

    return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {
				const giver: IPostGiverRequestDocument = {
					...values,
					sendInviteEmail: true
				}

				if (!!giverToEdit && giverToEdit.giverId) {
					const giverEdit: IPutGiverRequestDocument = {
                        giverId: giverToEdit.giverId,
						...giver,
						firstName: giverToEdit.firstName,
                        lastName: giverToEdit.lastName,
                        email: giverToEdit.email,
						sendInviteEmail: false
					}

					await makeHttpRequestWithUi({
						request: GiverApi.apiGiverGiverIdPut(giverToEdit.giverId, giverEdit),
						toastSuccessMessage: 'Successfully edited Giver.',
						toastErrorMessage: 'Error creating Giver.'
					})
				} else {
					const giverResult = await makeHttpRequestWithUi({
						request: GiverApi.apiGiverPost(giver),
						toastSuccessMessage: 'Successfully created Giver.',
						toastErrorMessage: 'Error creating Giver.'
					})

					// setGiverToEdit(giverResult)
				}

				if (onSaveSucces) onSaveSucces()
			}}
		>
			{formikProps => {
				_formikProps = formikProps
				return (
					<div className='d-flex flex-column mx-2' style={{ height: '100%' }}>
						{(!giverToEdit) ?
						<p>
							An <strong>invitation email</strong> will automatically be sent to the provided email address with instructions on how to complete
							setting up their account in order to gain access to the Givers Portal.
						</p> 
						:
						null
						}
						<Form className='d-flex flex-column' style={{ flex: 1 }}>
							<div className='d-flex flex-column'>
								<div><TextField fieldProps={{ name: 'firstName', label: 'First Name' }} /></div>
								<div><TextField fieldProps={{ name: 'lastName', label: 'Last Name' }} /></div>
								{/* Email is read-only for givers with a subjectId. They must change their email using the IDP.
									A dev must also manually change the email address via the DB. Eventually, this should all be
									handled automagically.
								 */}
								<div><TextField disabled={(!!giverToEdit?.subjectId)} fieldProps={{ name: 'email', label: 'Email' }} /> {!!giverToEdit?.subjectId ?
											<a type='button' className='btn btn-primary w-50 mb-3' href={`${giverToEdit?.requestEmailChangeUrl}`} target='_blank'>Request change of email</a>
											:
											null
										}</div>
							</div>
							<div className='mb-4 mt-2'>
								<button type='submit' style={{ minWidth: 300 }} className='btn btn-primary'>{!!giverToEdit ? 'Update' : 'Create'}</button>
							</div>
						</Form>
					</div>
				)
			}}
		</Formik>
	)
}