import { ICallListRecordDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class CallListModel {
    public static toGridListItem = (callList: ICallListRecordDocument): IGridListItem => {
        return {
            id: callList.callListId?.toString() || '',
            values: {
                ...callList,
                dateCreated: callList.dateCreated ? new Date(callList.dateCreated) : null,
                lastCallDate: callList.lastCallDate ? new Date(callList.lastCallDate) : null
            },
        }
    }
} 