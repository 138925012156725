import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { IGrantDenialReasonModelDocument, IGrantRequestSummaryDocument, IGrantTypeModelDocument, IMinistryGrantStipulationModelDocument, INotesSummaryDocument } from "../open-api"
import { GrantRequestFormSummaryDetails } from "./grant-request-form-summary-details"
import { GrantRequestFormSummaryEmail } from "./grant-request-form-summary-email"
import { Tabs } from "./tabs"
import { Modal } from "./modal"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import statusFlowChart from '../assets/grant-request-status-flowchart.png'
import { GRANT_REQUEST_STATUS } from "../constants"
import { AppActionContext } from "../app-store-provider"
import { GrantRequestFormSummaryMinistry } from "./grant-request-form-summary-ministry"
import { GrantRequestFormSummaryNotes } from "./grant-request-form-summary-notes"

interface IGrantFormSummaryProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    grantTypes: IGrantTypeModelDocument[],
    stipulations: IMinistryGrantStipulationModelDocument[]
    grantDenialReasons: IGrantDenialReasonModelDocument[]
    afterSave?: () => Promise<void>
    initialTab?: 'summary',
    setActiveParentTab: Dispatch<SetStateAction<string>>
    fetchStipulations: () => Promise<void>
}

export const GrantRequestFormSummary = (props: IGrantFormSummaryProps) => {
    const { grantRequestToEdit, afterSave, initialTab, setActiveParentTab, grantTypes, stipulations, grantDenialReasons, fetchStipulations } = props
    const { NotesApi } = useContext(AppActionContext)!

    const [statusModal, showHideStatusModal] = useModal()
    const [grantNotes, setGrantNotes] = useState<INotesSummaryDocument[]>([])    

    const [activeTab, setActiveTab] = useState<string>(initialTab || 'details')

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchNotes = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: NotesApi.apiGrantsIdNotesGet(grantRequestToEdit.grantId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching notes.',
        })
        setGrantNotes(data)
    }

    const getStatusDescription = () => {
        switch (grantRequestToEdit.grantRequestStatusId) {
            case GRANT_REQUEST_STATUS["Request In Progress"]:
                return 'The grant request has been created by the ministry contact. Waiting for them to submit it.'
            case GRANT_REQUEST_STATUS["Request Submitted"]:
                return 'The grant request has been submitted by the ministry contact for review by the Area Director.'
            case GRANT_REQUEST_STATUS["Ready For Review"]:
                return 'The AD has reviewed the grant request and it is ready to be submitted to the Local Advisory Board for review.'
            case GRANT_REQUEST_STATUS["Submitted to Board"]:
                return 'This grant request has been submitted to the Local Advisory Board for review. Waiting for them to make a decision.'
            case GRANT_REQUEST_STATUS["Grant Denied"]:
                return 'The board has denied the grant request.'
            case GRANT_REQUEST_STATUS["Request Tabled"]:
                return 'The board has tabled the grant request. It will be reviewed for approval/denial at a later time.'
            case GRANT_REQUEST_STATUS["In Matching Period"]:
                return 'This grant has been approved for funding and is a matching period.'
            case GRANT_REQUEST_STATUS["Queued For Funding"]:
                return 'The matching period has ended and we are waiting for the AD to verify that all required information has been received.'
            case GRANT_REQUEST_STATUS["Ready For Funding"]:
                return 'The AD has verified that we have all required information on file. Waiting for the NRC accountant to submit the payment to the ministry.'
            case GRANT_REQUEST_STATUS["Grant Complete"]:
                return 'This grant request has been completed.'
            default:
                return 'This is a placeholder.'
        }
    }

    useEffect(() => {
        fetchNotes()
    }, [])
    
    return (
        <>
            <div className='d-flex'>
                <div style={{width: '100%'}}>
                    <div className='m-2 d-flex'>
                        <h5>Status: <a onClick={() => showHideStatusModal(true)} href="#"><span className="badge bg-primary" style={{color: 'white'}}>{grantRequestToEdit.grantRequestStatusName}</span></a></h5>
                    </div>
                    <div className='mt-3'>
                        <Tabs
                            keepTabContentInDomWhenNotActive
                            controls={{
                                activeTab,
                                setActiveTab
                            }}
                            tabs={[
                                {
                                    id: 'details',
                                    title: 'Details',
                                    content: <GrantRequestFormSummaryDetails
                                        grantRequestToEdit={grantRequestToEdit}
                                        grantTypes={grantTypes}
                                        afterSave={afterSave}
                                    />
                                },
                                ...grantRequestToEdit.isLegacyGrant ? [] :
                                [{
                                    // Legacy grants won't have associated emails
                                    id: 'email',
                                    title: 'Email',
                                    content: <GrantRequestFormSummaryEmail
                                        grantRequestToEdit={grantRequestToEdit}
                                    />
                                }],
                                {
                                    id: 'ministryInfo',
                                    title: 'Ministry Overview',
                                    content: <GrantRequestFormSummaryMinistry
                                        grantRequestToEdit={grantRequestToEdit}
                                    />
                                },
                                {
                                    id: 'grantNotes',
                                    title: `Notes ${grantNotes.length > 0 ? `(${grantNotes.length})` : ''}`,
                                    content: <GrantRequestFormSummaryNotes
                                        grantId={grantRequestToEdit.grantId}
                                        notes={grantNotes}
                                        afterSave={() => {
                                            fetchNotes()
                                        }}
                                    />
                                },
                            ]}
                        />
                    </div>
                    </div>
            </div>
            <Modal
				{...statusModal}
				modalTitle='Grant Request Status'
                size="lg"
				footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideStatusModal(false)}>Close</button>
					</>
				}
			>
                <div>
                    <label><strong>{grantRequestToEdit.grantRequestStatusName}</strong>:</label>&nbsp;
                    {getStatusDescription()}
                </div>
				<div className="d-flex justify-content-center">
                    <img src={statusFlowChart} alt='Grant Request Status Flowchart' />
                </div>
            </Modal>
        </>
    )
}