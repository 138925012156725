import React, { useCallback, useContext, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper, useMailBlastRecipientsDefaultColumns, useModal } from '../services/hooks'
import { IAppState } from '../stores/app-definitions'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { Modal } from './modal'
import { ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { IFilter } from '../stores/api-actions'
import { MailBlastRecipient } from '../models/custom-mail-blast-recipient'

interface ICustomMailBlastRecipients {
	mailBlastId: number
}

export const CustomMailBlastRecipients = (props: ICustomMailBlastRecipients) => {
	const { mailBlastId } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        try {

            const query = await makeHttpRequestWithUi({
                request: appActions.MailBlastApi.apiMailBlastRecipientsGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of grant requests.'
            })

            const rows = (query.data.data || []).map(MailBlastRecipient.toGridListItem)

            return {
                rows,
                count: query.data.totalCount
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

	// const dataSource: GridDataFetch<IAppState> = async (queryState, _state) => {
	// 	const mailBlastRecipientsQuery = await makeHttpRequestWithUi({
	// 		request: appActions.MailBlastApi.apiMailBlastIdRecipientsGet(mailBlastId),
	// 		disableSuccessToast: true,
	// 		toastErrorMessage: 'There was an error retrieving the recipients list for this mail blast.'
	// 	})

	// 	const emailLog = mailBlastRecipientsQuery.data

	// 	if (queryState.sorts) sortListBySorts(emailLog, queryState.sorts)

	// 	let rows = emailLog.map(MailBlastRecipient.toGridListItem)

	// 	if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

	// 	return { rows, count: rows.length, }
	// }

	const mailBlastIdFilter: IFilter = {
        id: 'mailBlastId',
        value: mailBlastId,
        operator: 'eq',
        property: 'mailBlastId',
        enabled: true,
        hidden: true
    }

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [viewEmailModal, showHideViewEmailModal] = useModal()
	const [confirmResendModal, showHideConfirmResendModal] = useModal()

	const initialGridState: IGridState = {
		...defaultGridState,
		queryState: {
			...defaultGridState.queryState,
			filters: [mailBlastIdFilter],
		},
		rowSelectEnabled: true,
		disableExport: false,
		columns: useMailBlastRecipientsDefaultColumns(),
		dataSource,
		rowActionsDisplayType: 'icons',
		gridActions: [
			{
				id: 'resendEmailBlast',
				label: 'Resend',
				disabled: (gridState) => gridState.rows.filter(o => o.values.canResend && o.selected).length <= 0,
				onClick: () => showHideConfirmResendModal(true),
			}
		],
		// rowActions: {
		// 	viewEmail: {
		// 		id: 'viewEmail',
		// 		action: async (options) => {
		// 			options.e.stopPropagation()
		// 			showHideViewEmailModal(true)
		// 			setSelectedRow(options.row)
		// 		},
		// 		icon: <EyeIcon />,
		// 		tooltipText: 'View Email',
		// 	},
		// 	resendBlast: {
		// 		id: 'resendBlast',
		// 		action: async (options) => {
		// 			options.e.stopPropagation()
		// 			showHideConfirmResendModal(true)
		// 			setSelectedRow(options.row)
		// 		},
		// 		icon: <ForwardIcon />,
		// 		tooltipText: 'Resend Blast',
		// 		hidden: (row) => !row.values.canResend
		// 	},
		// }
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			<Modal
				{...viewEmailModal}
				modalTitle='Email Preview'
				size='xl'
			>
				{selectedRow && viewEmailModal.show ? <div dangerouslySetInnerHTML={{ __html: typeof selectedRow.values.body === 'string' ? selectedRow.values.body : '' }}></div> : null}
			</Modal>

			<Modal
				{...confirmResendModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' onClick={() => showHideConfirmResendModal(false)}>Cancel</button>
						<button
							type='button'
							className='btn btn-primary'
							onClick={async () => {
								if (gridState.rows.filter(r => r.values.canResend && r.selected).length > 0) {
									await makeHttpRequestWithUi({
										request: appActions.MailBlastApi.apiMailBlastContactsResendPost(gridState.rows.filter(r => r.values.canResend && r.selected).map(r => parseInt(r.id))),
										toastSuccessMessage: 'Successfully resent mail blast.',
										toastErrorMessage: 'Encountered an error resending mail blast.',
									})
									showHideConfirmResendModal(false)
									gridActions.doFetch()
								}

							}}
						>
							Resend
						</button>
					</React.Fragment>
				}
			>
				Are you sure you want to resend this email blast to the selected recipients?
			</Modal>
		</React.Fragment>
	)
}

