import { FC, ReactNode, useState } from "react"
import { Popup } from "./popup"
import { ThreeDotsIcon } from '../assets'
import clsx from 'clsx'

interface IContextMenuProps {
	children: ReactNode,
	icon?: ReactNode
}

export const ContextMenu: FC<IContextMenuProps> = ({ children, icon }) => {

    const [open, setIsOpen] = useState(false)

    return (
        <Popup
            closeOnInnerClick
            trigger={
                <div
                    className={clsx(open ? 'bg-primary' : 'primary-color-hover')}
                    style={{
                        display: 'flex',
                        padding: 5,
                        fontSize: 24,
                        marginRight: 10,
                        color: open ? 'white' : 'gray',
                        borderRadius: '.25rem',
                    }}
                    title='More'
                >
                    {icon ? icon : <ThreeDotsIcon />}
                </div>
            }
            onToggle={setIsOpen}
            popupOptions={{
                placement: 'bottom-end',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [-10, 5]
                        }
                    }
                ]
            }}
        >
            <div style={{ backgroundColor: 'white', border: '1px solid gray', borderRadius: '.25rem', padding: '.5rem 0' }} >
                {children}
            </div>
        </Popup>
    )
}