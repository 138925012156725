import { ReactComponent as GreaterThan } from '../assets/chevron-right.svg'
import { ReactComponent as LessThan } from '../assets/chevron-left.svg'
import { ReactComponent as Equal } from '../assets/equal.svg'
import { ReactComponent as Search } from '../assets/search.svg'

import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { IGridColumn } from '../stores/grid-definitions'
import { INumericFilter, IFilter, FilterOperator, FilterType, IDateFilter, IStringFilter, IArrayFilter, IBooleanFilter } from '../stores/api-actions'
import { Checkbox, NumberInput, DayPickerInput, TextInput } from './forms'
import moment from 'moment'
import { isNullOrUndefined } from 'util'
import { GridContext } from './grid'
import { sortListByProperty, uuidv4 } from '../services/helpers'
import { AppActionContext, AppStateContext } from '../app-store-provider'

interface IInGridColumnFilterOption {
	label: string
	value: string
}

interface IRangeGridColumnFilterOption {
	label: string
	range: {
		gtValue: string
		ltValue: string
	}
}

export type IGridColumnFilterOption = IInGridColumnFilterOption | IRangeGridColumnFilterOption


interface IGridColumnFilterProps {
	column: IGridColumn
	columnFilters: IFilter[]
	updateColumnFilters: (filters: IFilter[], throttleQuery?: boolean) => Promise<void>
}
export const GridColumnFilter = (props: IGridColumnFilterProps) => {
	const { column } = props

	if (column.allowFilters) {
		if (column.filterTypeOverride) {
			switch (column.filterTypeOverride) {
				case 'tags':
					return <TagOptionsColumnFilter {...props} />
			}
		} else if (column.filterOptions) {
			return <OptionsColumnFilter {...props} />
		} else {
			switch (column.type) {
				case 'number':
					return <NumericColumnFilter {...props} />
				case 'date':
					return <DateColumnFilter {...props} />
				case 'string':
					return <TextColumnFilter {...props} />
				case 'boolean':
					return <BooleanColumnFilter {...props} />
				default:
					return <span>WIP</span>
			}
		}
	} else if (column.filterUnallowedMessage) {
		return <div>{column.filterUnallowedMessage}</div>
	} else {
		return <div>This column does not support any filters.</div>
	}
}

export function getOrCreateFilterForColumnPropertyAndOperator(filters: IFilter[], property: string, operator: FilterOperator, id?: string): FilterType {
	const existingFilter = filters.find(f => f.property === property && f.operator === operator)
	if (existingFilter) {
		return existingFilter
	} else {
		if (!id) id = `${property}-${operator}`
		return { property, operator, id }
	}
}

// function getOrCreateOptionFilterForColumnPropertyAndValue(filters: IFilter[], property: string, value: string, id?: string): FilterType {
// 	const existingFilter = filters.find(f => f.property === property && f.operator === 'in' && f.value === value)
// 	if (existingFilter) {
// 		return existingFilter
// 	} else {
// 		if (!id) id = `${property}-in-${value}`
// 		return { property, operator: 'in', value, id }
// 	}
// }


const NumericColumnFilter = (props: IGridColumnFilterProps) => {
	const { column, columnFilters, updateColumnFilters } = props

	const lt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'lt') as INumericFilter
	const gt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'gt') as INumericFilter
	const eq = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'eq') as INumericFilter

	interface IOnFilterChangeArgs {
		_lt?: INumericFilter
		_gt?: INumericFilter
		_eq?: INumericFilter
	}
	const onLtGtChange = (onFilterChangeArgs: IOnFilterChangeArgs, throttleQuery?: boolean) => {
		let { _lt, _gt, _eq } = onFilterChangeArgs

		if (!_lt) _lt = { ...lt }
		if (!_gt) _gt = { ...gt }
		if (!_eq) _eq = { ...eq }

		if (_lt.enabled || _gt.enabled) {
			_eq.enabled = false
		}

		updateColumnFilters([_lt, _gt, _eq], throttleQuery)
	}

	const onEqChange = (onFilterChangeArgs: IOnFilterChangeArgs, throttleQuery?: boolean) => {
		let { _lt, _gt, _eq } = onFilterChangeArgs

		if (!_lt) _lt = { ...lt }
		if (!_gt) _gt = { ...gt }
		if (!_eq) _eq = { ...eq }

		if (_eq.enabled) {
			_lt.enabled = false
			_gt.enabled = false
		}

		updateColumnFilters([_lt, _gt, _eq], throttleQuery)
	}

	return (
		<div className='column'>
			<div className='filter-option'>
				<Checkbox id={`${column.property}-gt`} checked={!!gt.enabled} onChange={(e) => onLtGtChange({ _gt: { ...gt, enabled: e.currentTarget.checked } })} />
				<GreaterThan />
				<NumberInput value={isNullOrUndefined(gt.value) ? '' : gt.value} onChange={(e) => onLtGtChange({ _gt: e.currentTarget.value.length ? { ...gt, enabled: true, value: parseInt(e.currentTarget.value) } : { ...gt, enabled: false, value: undefined } }, true)} />
			</div>
			<div className='filter-option'>
				<Checkbox id={`${column.property}-lt`} checked={!!lt.enabled} onChange={(e) => onLtGtChange({ _lt: { ...lt, enabled: e.currentTarget.checked } })} />
				<LessThan />
				<NumberInput value={isNullOrUndefined(lt.value) ? '' : lt.value} onChange={(e) => onLtGtChange({ _lt: e.currentTarget.value.length ? { ...lt, enabled: true, value: parseInt(e.currentTarget.value) } : { ...lt, enabled: false, value: undefined } }, true)} />
			</div>
			<hr style={{ margin: '5px 0' }}></hr>
			<div className='filter-option' style={{ paddingTop: '5px' }}>
				<Checkbox id={`${column.property}-eq`} checked={!!eq.enabled} onChange={(e) => onEqChange({ _eq: { ...eq, enabled: e.currentTarget.checked } })} />
				<Equal />
				<NumberInput value={isNullOrUndefined(eq.value) ? '' : eq.value} onChange={(e) => onEqChange({ _eq: e.currentTarget.value.length ? { ...eq, enabled: true, value: parseInt(e.currentTarget.value) } : { ...eq, enabled: false, value: undefined } }, true)} />
			</div>
		</div>
	)
}

interface IDateColumnFilterState {
	eq: IDateFilter
	lt: IDateFilter
	gt: IDateFilter
}

const DateColumnFilter = (props: IGridColumnFilterProps) => {
	const { state } = useContext(GridContext)!
	const appActions = useContext(AppActionContext)!

	const { activeFilterPopover } = state

	const { column, columnFilters, updateColumnFilters } = props

	const [filterState, setFilterState] = useState<IDateColumnFilterState>()

	useEffect(() => {
		const lt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'lt') as IDateFilter
		const gt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'gt') as IDateFilter
		const eq = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'eq') as IDateFilter

		// Manually setting the filter type to date filter, which is handled uniquely than other filter types (the presence of 'lt', 'gt', and 'eq' isn't enough to distinguish a filter as a date filter, since they can be used for a numeric filter as well)
		lt.isDateFilter = true
		gt.isDateFilter = true
		eq.isDateFilter = true

		setFilterState({ eq, gt, lt })
	}, [state])


	if (!filterState) return null

	const { eq, lt, gt } = filterState

	// Moving the actual filter updating out into an inner function since we need to apply some logic on every filter change.
	const updateFilter = (filter: IDateFilter, throttleQuery?: boolean) => {
		switch (filter.operator) {
			case 'eq':
				eq.value = filter.value
				eq.enabled = filter.enabled
				break
			case 'lt':
				lt.value = filter.value
				lt.enabled = filter.enabled
				break
			case 'gt':
				gt.value = filter.value
				gt.enabled = filter.enabled
				break
		}

		// If the filter being updated is a 'lt' or 'gt' filter AND it's enabled, we need to disable the 'eq' filter.
		if ((filter.operator === 'lt' || filter.operator === 'gt') && filter.enabled && eq.enabled) eq.enabled = false

		// If the filter being updated is a 'eq' filter AND it's enabled, we need to disable the 'lt' and 'gt' filters.
		if (filter.operator === 'eq' && filter.enabled) {
			lt.enabled = false
			gt.enabled = false
		}

		setFilterState({ eq, lt, gt })
	}

	const applyChanges = () => {
		/* 
			Check if the column has a filter required constraint.
			Currently implemented primarily for https://missionincrease.atlassian.net/browse/MA20-1138.
		*/
		let showAlert = false
		if (column.filterRequired && !eq.enabled && !lt.enabled && !gt.enabled) {
			gt.enabled = true
			showAlert = true
		}
		if (typeof column.filterDefaultValue === 'string' && !eq.value && !lt.value && !gt.value) {
			gt.value = column.filterDefaultValue
			showAlert = true
		}

		if (showAlert) {
			appActions.addAlert({ id: uuidv4(), body: 'This column requires an enabled filter with a value.' })
		} else {
			updateColumnFilters([eq, lt, gt])
		}
	}

	const valid = (() => {
		let valid = true

		// When there is a maxRange (days) validation on a date filter, there are a few cases we need to check.
		if (column.filterValidation?.maxRange) {
			// Case #1: gt date set, but no lt date set. In this case the filter is valid if the gt date is not too many days in the past.
			if (gt.enabled && gt.value && (!lt.enabled || !lt.value)) {
				valid = Math.abs(moment(gt.value).diff(moment(), 'days')) <= column.filterValidation.maxRange
			}

			// Case #2: lt date set, but no gt date set. We're just gonna assume in this case that gt needs to be set
			if (lt.enabled && lt.value && (!gt.enabled || !gt.value)) {
				valid = false
			}

			// Case #3: gt and lt dates set. In this case we just need to do a simple check that the range between the filters is valid.
			if (gt.enabled && gt.value && lt.enabled && lt.value) {
				valid = Math.abs(moment(gt.value).diff(moment(lt.value), 'days')) <= column.filterValidation.maxRange
			}

			// Case #4: no filters set. Immediately invalid since the range would be all of history...
			if ((!gt.enabled || !gt.value) && (!lt.enabled || !lt.value) && (!eq.enabled || !eq.value)) {
				valid = false
			}
		}

		return valid
	})()

	return (
		<div className='column'>
			<div className='filter-option'>
				<Checkbox id={`${column.property}-gt`} checked={!!gt.enabled} onChange={(e) => updateFilter({ ...gt, enabled: e.currentTarget.checked })} />
				<GreaterThan />
				<DayPickerInput
					parentElement={activeFilterPopover || null}
					selectedDay={gt.value ? moment(gt.value).toDate() : undefined}
					onDayChange={day => updateFilter(day ? { ...gt, enabled: true, value: moment(day).format('MM/DD/YYYY') } : { ...gt, enabled: false, value: undefined })}
					dateFilter={(date) => {
						if (lt.enabled && lt.value) {
							return moment(date).isBefore(moment(lt.value))
						}
						return true
					}}
				/>
			</div>
			<div className='filter-option'>
				<Checkbox id={`${column.property}-lt`} checked={!!lt.enabled} onChange={(e) => updateFilter({ ...lt, enabled: e.currentTarget.checked })} />
				<LessThan />
				<DayPickerInput
					parentElement={activeFilterPopover || null}
					selectedDay={lt.value ? moment(lt.value).toDate() : undefined}
					onDayChange={day => updateFilter(day ? { ...lt, enabled: true, value: moment(day).format('MM/DD/YYYY') } : { ...lt, enabled: false, value: undefined })}
					dateFilter={(date) => {
						if (gt.enabled && gt.value) {
							return moment(date).isAfter(moment(gt.value))
						}
						return true
					}}
				/>
			</div>
			{!valid ?
				<small className='text-danger'>This filter has a max range of {column.filterValidation?.maxRange} days. Please adjust the <b>greater than</b> and <b>less than</b> filters accordingly, or select an <b>equal to</b> date.</small>
				:
				null
			}
			<hr style={{ margin: '5px 0' }}></hr>
			<div className='filter-option' style={{ paddingTop: '5px' }}>
				<Checkbox id={`${column.property}-eq`} checked={!!eq.enabled} onChange={(e) => updateFilter({ ...eq, enabled: e.currentTarget.checked })} />
				<Equal />
				<DayPickerInput parentElement={activeFilterPopover || null} selectedDay={eq.value ? moment(eq.value).toDate() : undefined} onDayChange={day => updateFilter(day ? { ...eq, enabled: true, value: moment(day).format('MM/DD/YYYY') } : { ...eq, enabled: false, value: undefined })} />
			</div>

			<div className='mt-2 d-flex'><button disabled={!valid} className='btn btn-sm btn-primary' style={{ width: 100 }} onClick={applyChanges}>Apply</button></div>
		</div>
	)
}

export const TextColumnFilter = (props: IGridColumnFilterProps) => {
	const { column, columnFilters, updateColumnFilters } = props

	const like = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'like') as IStringFilter

	return (
		<div className='column'>
			<div className='filter-option text'>
				<Checkbox id={`${column.property}-like`} checked={!!like.enabled} onChange={(e) => updateColumnFilters([{ ...like, enabled: e.currentTarget.checked }])} />
				<Search />
				<TextInput autoFocus={true} value={isNullOrUndefined(like.value) ? '' : like.value} placeholder='Search for...' onChange={(e) => updateColumnFilters([e.currentTarget.value.length ? { ...like, enabled: true, value: e.currentTarget.value } : { ...like, enabled: false, value: undefined }], true)} />
			</div>
		</div>
	)
}

export const BooleanColumnFilter = (props: IGridColumnFilterProps) => {
	const { column, columnFilters, updateColumnFilters } = props

	const eq = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, '==') as IBooleanFilter

	const [yesNo, setYesNo] = useState({
		yes: eq.value === true,
		no: eq.value === false,
	})

	const setYes = (enabled: boolean) => {
		if (enabled) {
			setYesNo({ yes: true, no: false })
		} else {
			setYesNo({ ...yesNo, yes: false })
		}
	}

	const setNo = (enabled: boolean) => {
		if (enabled) {
			setYesNo({ yes: false, no: true })
		} else {
			setYesNo({ ...yesNo, no: false })
		}
	}

	useEffect(() => {
		let enabled = false
		let value: undefined | boolean

		if (yesNo.no === false && yesNo.yes === false) {
			if (eq.enabled) updateColumnFilters([{ ...eq, enabled: false, value: undefined }])
		} else {
			enabled = true
			if (yesNo.no) value = false
			if (yesNo.yes) value = true

			updateColumnFilters([{ ...eq, enabled, value }])
		}

		//eslint-disable-next-line
	}, [yesNo])

	return (
		<div className='column'>
			<Checkbox label='Yes' id={`${column.property}-yes`} checked={yesNo.yes} onChange={(e) => setYes(e.currentTarget.checked)} />
			<Checkbox label='No' id={`${column.property}-no`} checked={yesNo.no} onChange={(e) => setNo(e.currentTarget.checked)} />
		</div>
	)
}

export const OptionsColumnFilter = (props: IGridColumnFilterProps) => {
	const { column } = props
	const { filterOptions } = column

	if (filterOptions) return (
		<div className='column grid-filter-options'>
			<OptionsFilter {...props} filterOptions={filterOptions} />
		</div>
	)

	return null
}

export const TagOptionsColumnFilter = (props: IGridColumnFilterProps) => {
	const appState = useContext(AppStateContext)!
	const { state: gridState } = useContext(GridContext)!

	const tags = gridState.tagType ? appState.tags?.filter(tag => tag.tableKey === gridState.tagType) : appState.tags

	const eventInviteIncludeTags = tags?.filter(tag => tag.tag?.includes('Invite Include')) || []
	const nonEventInviteIncludeTags = tags?.filter(tag => !tag.tag?.includes('Invite Include')) || []

	const nonEventInviteIncludeTagFilterOptions: IGridColumnFilterOption[] = sortListByProperty(nonEventInviteIncludeTags, 'tag').map(tag => { return { label: `${tag.tag}`, value: tag.tagId.toString() } })
	const eventInviteIncludeTagFilterOptions: IGridColumnFilterOption[] = sortListByProperty(eventInviteIncludeTags, 'tag').map(tag => { return { label: `${tag.tag}`, value: tag.tagId.toString() } })

	return (
		<div className='column grid-filter-options'>
			<OptionsFilter {...props} filterOptions={nonEventInviteIncludeTagFilterOptions} />
			<h6 style={{ paddingTop: '10px' }}>Event invite Include</h6>
			<OptionsFilter {...props} filterOptions={eventInviteIncludeTagFilterOptions} />
		</div>
	)
}

interface IOptionsFilter extends IGridColumnFilterProps {
	filterOptions: IGridColumnFilterOption[]
}
const isRangeFilterOption = (option: IInGridColumnFilterOption | IRangeGridColumnFilterOption): option is IRangeGridColumnFilterOption => (option as IRangeGridColumnFilterOption).range !== undefined
const isRangeFilterOptionArray = (options: (IInGridColumnFilterOption | IRangeGridColumnFilterOption)[]): options is IRangeGridColumnFilterOption[] => options.length > 0 && isRangeFilterOption(options[0])
const isInFilterOption = (option: IInGridColumnFilterOption | IRangeGridColumnFilterOption): option is IRangeGridColumnFilterOption => (option as IInGridColumnFilterOption).value !== undefined
const isInFilterOptionArray = (options: (IInGridColumnFilterOption | IRangeGridColumnFilterOption)[]): options is IInGridColumnFilterOption[] => options.length > 0 && isInFilterOption(options[0])
const isEnabledDisabledRangeFilterOptionArray = (options: { enabled: boolean, option: IInGridColumnFilterOption | IRangeGridColumnFilterOption }[]): options is { enabled: boolean, option: IRangeGridColumnFilterOption }[] => options.length > 0 && isRangeFilterOption(options[0].option)
const isEnabledDisabledInFilterOptionArray = (options: { enabled: boolean, option: IInGridColumnFilterOption | IRangeGridColumnFilterOption }[]): options is { enabled: boolean, option: IInGridColumnFilterOption }[] => options.length > 0 && isInFilterOption(options[0].option)
/** 
	We support a few configurations of manually set filter options:

	- "in" filter: by providing an array of options with a "value" (multi-select)
	- "range" filter: by providing an array of options with a "range" (single-select)
*/
const OptionsFilter = (props: IOptionsFilter) => {
	const { column, columnFilters, updateColumnFilters, filterOptions } = props

	const updateFilterOption = useCallback((option: IGridColumnFilterOption, enabled: boolean) => {
		if (isRangeFilterOption(option)) {
			const lt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'lt') as IDateFilter
			const gt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'gt') as IDateFilter

			updateColumnFilters([{ ...lt, enabled, value: option.range.ltValue }, { ...gt, enabled, value: option.range.gtValue }])
		} else {
			const like = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'in') as IArrayFilter

			const value: string[] = like?.value || []
			if (enabled) {
				if (!value.includes(option.value)) {
					value.push(option.value)
				}
			} else {
				if (value.includes(option.value)) {
					const valueidx = value.indexOf(option.value)
					value.splice(valueidx, 1)
				}
			}

			updateColumnFilters([{ ...like, value, enabled: value.length > 0 }])
		}
	}, [columnFilters])

	const enabledDisabledFilterOptions = useMemo<{ enabled: boolean, option: IGridColumnFilterOption }[]>(() => {
		if (isRangeFilterOptionArray(filterOptions)) {
			const lt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'lt') as IDateFilter
			const gt = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'gt') as IDateFilter

			return filterOptions.map(option => ({
				option,
				enabled: Boolean(lt && lt.enabled && lt.value === option.range.ltValue && gt && gt.enabled && gt.value === option.range.gtValue)
			}))
		} else if (isInFilterOptionArray(filterOptions)) {
			return filterOptions.map(option => {
				const like = getOrCreateFilterForColumnPropertyAndOperator(columnFilters, column.property, 'in') as IArrayFilter

				const enabled = !!like.value?.includes(option.value)
				return { enabled, option }
			})
		}

		throw new Error('OptionsFilter: unsupported filter option type.')
	}, [filterOptions, columnFilters])

	if (isEnabledDisabledRangeFilterOptionArray(enabledDisabledFilterOptions)) {
		return <React.Fragment>
			{enabledDisabledFilterOptions.map(f => (
				<div key={`${column.property}-${f.option.range.ltValue}`} className='filter-option text'>
					<Checkbox id={`${column.property}-${f.option.range.ltValue}`} checked={!!f.enabled} label={f.option.label} onChange={(e) => updateFilterOption(f.option, e.currentTarget.checked)} />
				</div>
			))}
		</React.Fragment>

	} else if (isEnabledDisabledInFilterOptionArray(enabledDisabledFilterOptions)) {
		return <React.Fragment>
			{enabledDisabledFilterOptions.map(f => (
				<div key={`${column.property}-${f.option.value}`} className='filter-option text'>
					<Checkbox id={`${column.property}-${f.option.value}`} checked={!!f.enabled} label={f.option.label} onChange={(e) => updateFilterOption(f.option, e.currentTarget.checked)} />
				</div>
			))}
		</React.Fragment>
	}

	return null
}