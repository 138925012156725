import { Link } from "@reach/router"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { EnvolpeIcon } from "../assets"
import { IConsultingApptDocument, IConsultingApptRegistrantDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { useGrid, defaultGridState } from "../stores/grid-actions"
import { GridDataFetch, IGridListItem } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { ConsultingAppointmentFormDisabledText } from "./consulting-appointment-form-disabled-text"
import { ConulstingAppointmentFormRegistrantsForm } from './consulting-appointment-form-registrants-form'
import { Grid } from "./grid"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { Modal } from "./modal"
import { SquareDeleteIcon } from "./partials"

export interface IConsultingAppointmentFormRegistrantsProps {
    appointmentToEdit?: IConsultingApptDocument
    afterSave: () => void
    registrants: IConsultingApptRegistrantDocument[]
}

export const ConsultingAppointmentFormRegistrants = (props: IConsultingAppointmentFormRegistrantsProps) => {

    const appActions = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const readOnly = props.appointmentToEdit && !props.appointmentToEdit.isEditable

    const dataSource: GridDataFetch<IConsultingApptRegistrantDocument[]> = async (_queryState, _registrants) => {
        try {
            const rows: IGridListItem[] = _registrants.filter(o => !!o.ministryContactId).map(o => ({
                id: o.seminarAttendeeId!.toString(),
                values: {
                    ...o
                }
            }))

            return {
                rows,
                count: _registrants.length
            }
        } catch (e) {
            return {
                rows: [],
                count: 0
            }
        }
    }

    const [confirmRemoveRegistrantModal, showHideConfirmRemoveRegistrantModal] = useModal()
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [confirmSendNotificationModal, showHideConfirmSendNotificationEmail] = useModal()

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            columns: [
                {
                    property: 'firstName',
                    title: 'Name',
                    width: 100,
                    type: 'string',
                    render: (_, row) => `${row.values.firstName} ${row.values.lastName}`,
                },
                {
                    property: 'email',
                    title: 'Email',
                    width: 100,
                    type: 'string',
                    render: (_, row) => <a href={`mailto:${row.values.email}`}>{row.values.email}</a>
                },
                {
                    property: 'ministryName',
                    title: 'Ministry',
                    width: 120,
                    type: 'string',
                    render: (col, row) => <Link to={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</Link>
                },
                {
                    property: 'businessPhone',
                    title: 'Phone',
                    width: 100,
                    type: 'string',
                    render: DefaultGridCellDisplay
                },
                {
                    property: 'physicalCity',
                    title: 'City',
                    width: 100,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    hide: true
                },
                {
                    property: 'physicalStateAbbr',
                    title: 'State',
                    width: 50,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    hide: true
                },
                {
                    property: 'bSentConfirmation',
                    title: 'Confirmation Sent',
                    width: 100,
                    type: 'boolean',
                    render: DefaultGridCellDisplay,
                    conditionalCellCSSFormatting: (row) => {
                        if (row.values.bSentConfirmation) return 'bg-success-7'
                        return ''
                    },
                    description: 'Indicates that a registration confirmation email has been sent to this registrant.'
                },
                {
                    property: 'bAttended',
                    title: 'Attended',
                    width: 75,
                    type: 'boolean',
                    render: DefaultGridCellDisplay,
                    align: 'center',
                    conditionalCellCSSFormatting: (row) => {
                        if (row.values.bAttended) return 'bg-success-7'
                        return ''
                    },
                },
                {
                    property: 'grid_actions',
                    type: 'actions',
                    width: 85,
                    disableSort: true,
                    title: 'Actions',
                    render: GridActionCell,
                    align: 'center',
                },
            ],
            dataSource,
            rowActions: {
                sendNotification: {
                    id: 'sendNotification',
                    action: async ({ row }) => {
                        setSelectedRow(row)
                        showHideConfirmSendNotificationEmail(true)
                    },
                    tooltipText: 'Send Confirmation',
                    icon: <EnvolpeIcon />,
                    disabled: readOnly || (props.appointmentToEdit && dayjs(props.appointmentToEdit.endDateTime).isBefore(dayjs()))
                },
                removeRegistrant: {
                    id: 'removeRegistrant',
                    action: async ({ row }) => {
                        setSelectedRow(row)
                        showHideConfirmRemoveRegistrantModal(true)
                    },
                    tooltipText: 'Remove Registrant',
                    icon: <SquareDeleteIcon />,
                    disabled: readOnly
                },
            },
            usingLocalData: true,
            hideGridFooter: true,
            rowSelectEnabled: false,
            rowActionsDisplayType: "icons"
        },
        props.registrants
    )

    useEffect(() => {
        gridActions.doFetch()
    }, [props.registrants])

    return (
        <>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                {readOnly && <ConsultingAppointmentFormDisabledText />}

                <ConulstingAppointmentFormRegistrantsForm
                    {...props}
                    afterSave={props.afterSave}
                />

                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>

            <Modal
                {...confirmSendNotificationModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmSendNotificationEmail(false)} >Cancel</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                if (!selectedRow) return
                                if (!props.appointmentToEdit) return
                                await makeHttpRequestWithUi({
                                    request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdSendRegistrantNotificationsPost(props.appointmentToEdit.consultingEventGroupId, [{ seminarAttendeeId: parseInt(selectedRow.id), attendeeGuid: selectedRow.values.attendeeGuid?.toString() ?? null, email: typeof selectedRow.values.email === 'string' ? selectedRow.values.email : '' }]),
                                    toastErrorMessage: 'Encountered an error sending notification.',
                                    toastSuccessMessage: 'Successfully sent notification.',
                                })
                                props.afterSave()
                                showHideConfirmSendNotificationEmail(false)
                            }}
                        >
                            Send
                        </button>
                    </>
                }
            >
                Would you like to send a registration confirmation email to this registrant?
            </Modal>

            <Modal
                {...confirmRemoveRegistrantModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmRemoveRegistrantModal(false)} >Cancel</button>
                        <button
                            className='btn btn-warning'
                            onClick={async () => {
                                if (!selectedRow) return
                                await makeHttpRequestWithUi({
                                    request: appActions.ConsultingApi.apiConsultingRegistrantsSeminarAttendeeIdDelete(parseInt(selectedRow.id)),
                                    toastErrorMessage: 'There was an error removing this registrant.',
                                    toastSuccessMessage: 'Successfully remove registrant.',
                                })
                                props.afterSave()
                                showHideConfirmRemoveRegistrantModal(false)
                            }}
                        >
                            Remove
                        </button>
                    </>
                }
            >
                Are you sure you want to remove this registrant?
            </Modal>
        </>
    )
}