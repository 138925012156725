import moment from 'moment'
import { IConsultingManagementListDataType, Mode } from '../components/consulting-management'
import { ISearchResultDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class ConsultingManagementModel {
    public static toGridListItem = (
        consultingItem: ISearchResultDocument,
        meetingTypes: IConsultingManagementListDataType[],
        mode: Mode
    ): IGridListItem => {
        if (mode === Mode.olderHistory) {
            return {
                id: `${consultingItem.consultingEventGroupId}-${consultingItem.ministryID}`,
                values: {
                    ...consultingItem,
                    meetingTypeId: meetingTypes.find(o => o.id.toString() === consultingItem.meetingTypeId.toString())?.name || null,
                    startDateTime: moment(consultingItem.startDateTime).toDate()
                }
            }
        } else {
            return {
                id: `${consultingItem.consultingEventGroupId}-${consultingItem.ministryID}`,
                values: {
                    ...consultingItem,
                    meetingTypeId: meetingTypes.find(o => o.id.toString() === consultingItem.meetingTypeId.toString())?.name || null,
                    startDateTime: moment(consultingItem.startDateTime).toDate()
                },
                rowGroup: {
                    id: `${consultingItem.year}-${consultingItem.quarter}`,
                    title: `${consultingItem.year} Quarter ${consultingItem.quarter}`,
                    showEmptyRowGroup: true,
                    emptyRowGroupLabel: 'This Quarter does not have any consulting records.',
                    collapsed: true
                }
            }
        }
    }
}