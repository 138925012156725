// Uses the react-select.com library.
import { flatten } from "lodash"
import React, { useMemo } from "react"
import CreatableSelect from 'react-select/creatable'
import { MenuPlacement } from 'react-select'

interface IFieldProps {
	fieldProps: {
		label: string | null | JSX.Element
		name: string
		[additionalInputAttributes: string]: any
		placeholder?: string
		labeltooltip?: string
	}
	labelStyle?: React.CSSProperties
	icon?: JSX.Element
	disabled?: boolean
	hideInput?: boolean
}
interface ISelectFieldOption {
	value: string
	label: string
}

interface ISelectFieldOptionGroup {
	id: string
	label: string
	options: ISelectFieldOption[]
}

interface ISelectFieldProps extends IFieldProps {
	fieldProps: {
		label: string | null
		name: string
		[additionalInputAttributes: string]: any
		placeholder?: string
		labelTooltip?: string
	}
	options?: ISelectFieldOption[]
	optionGroups?: ISelectFieldOptionGroup[]
	multiple?: boolean
	clearable?: boolean
	menuPlacement?: MenuPlacement
	helperText?: string
}

interface ICreatableSelectFieldProps extends ISelectFieldProps {
	value?: string | string[]
	onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => void
}

interface ISimpleSelectFieldProps extends ISelectFieldProps {
	value?: string | string[]
	onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => void
	onCreate: (inputValue: string) => void
}

const isSelectFieldOption = (object: any | null | undefined): object is ISelectFieldOption => !!object && 'value' in object

export const CreatableSelectField = (props: ISimpleSelectFieldProps) => {
	const { options, multiple, clearable, disabled, fieldProps, onChange, onCreate, value, optionGroups } = props

	const _value = useMemo(() => {
		let _value: ISelectFieldOption[] = []

		if (value instanceof Array) {
			if (optionGroups) {
				_value = flatten(optionGroups.map(o => o.options)).filter(o => value.includes(o.value))
			} else if (options) {
				_value = options.filter(o => value.includes(o.value))
			}
		} else if (typeof value === 'string') {
			if (optionGroups) {
				_value = flatten(optionGroups.map(o => o.options)).filter(o => value === o.value)
			} else if (options) {
				_value = options.filter(o => value === o.value)
			}
		} else if (typeof value === 'number') {
			if (optionGroups) {
				_value = flatten(optionGroups.map(o => o.options)).filter(o => value == o.value)
			} else if (options) {
				_value = options.filter(o => value == o.value)
			}
		}

		return _value
	}, [value, optionGroups, options])

	// const handleChange = (
	// 	newValue: OnChangeValue<ColourOption, true>,
    // 	actionMeta: ActionMeta<ColourOption>
	// )

	return (
		<div className={`form-group`} style={{ marginBottom: 0 }}>
			{fieldProps.label && fieldProps.label.length && <label htmlFor={fieldProps.name} dangerouslySetInnerHTML={{ __html: fieldProps.label }}></label>}

			<CreatableSelect
				value={_value}
				options={optionGroups ? optionGroups : options}
				isDisabled={disabled}
				placeholder={fieldProps.placeholder}
				isMulti={multiple}
				isClearable={clearable}
				{...fieldProps}
				id={fieldProps.name}
				onChange={(selection, actionMeta) => {
					console.group('Value changed')
					console.log('selection', selection)
					console.log('actionMeta', actionMeta)
					console.groupEnd();
					if (selection instanceof Array) {
						onChange([...selection])
					} else if (isSelectFieldOption(selection)) {
						onChange(selection)
					} else {
						onChange(null)
					}
				}}
				onCreateOption={(inputValue: string) => {
					console.log('onCreate Occurred')
					onCreate(inputValue)
				}}
				menuPortalTarget={document.body}
				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
			/>
		</div>
	)
}