import { Link } from "@reach/router"
import React from "react"

export interface IMinistryRegistrantsSummaryProps {
    ministryName: string
    ministryId?: number
    prospectId?: number | null
    registrants: {
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        email?: string | null
        businessPhone?: string | null
    }[]
}

export const MinistryRegistrantsSummary = ({ ministryName, ministryId, registrants, prospectId }: IMinistryRegistrantsSummaryProps) => {
    return (
        <div style={{ marginLeft: 10 }}>
            <Link target='_blank' to={ministryId ? `/ministry-info/${ministryId}` : `/prospects/${prospectId}`}>{ministryName}</Link>
            {registrants.map(o => (
                <div key={o.email || o.id} style={{ marginLeft: 10 }}>
                    {o.fullName ? o.fullName : `${o.firstName} ${o.lastName}`}{o.email && <> - <a href={`mailto:${o.email}`}>{o.email}</a></>}{o.businessPhone && ` - ${o.businessPhone}`}
                </div>
            ))}
        </div>
    )
}