import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { SearchIcon } from "../assets"
import { IDefaultProps } from "./component-definitions"
import { Tabs } from "./tabs"
import { GlobalSearchResultsMinistries } from './global-search-results-ministries'
import { GlobalSearchResultsContacts } from './global-search-results-contacts'

interface IGlobalSearchResultsProps extends IDefaultProps {

}
export const GlobalSearchResults = ({ }: IGlobalSearchResultsProps) => {

    const [ministryResultsCount, setMinistryResultsCount] = useState<number>()
    const [ministryContactsResultsCount, setMinistryContactsResultsCount] = useState<number>()

    return (
        <>
            <Helmet>
                <title>Search</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <SearchIcon />
                    <h3 className='ml-2'>Search Results</h3>
                </div>
                <div className="ml-3 mb-3">
                    The system searched the following fields:
                    <ul>
                        <li>Ministry's name and legal name.</li>
                        <li>Ministry Contact's first name, last name and email</li>
                        <li>Prospect's organization name, first name, last name and email</li>
                    </ul>
                </div>
                {/* <div className='px-2' style={{ flex: 1, maxHeight: 'calc(100% - 100px)' }}> */}
                <div className='px-2' style={{ flex: 1 }}>
                    <Tabs
                        keepTabContentInDomWhenNotActive
                        tabs={[
                            {
                                id: 'search-results-ministries',
                                title: `Ministries ${ministryResultsCount ? `(${ministryResultsCount})` : ''}`,
                                content: <GlobalSearchResultsMinistries setMinistryResultsCount={setMinistryResultsCount} />
                            },
                            {
                                id: 'search-results-contacts',
                                title: `Ministry Contacts ${ministryContactsResultsCount ? `(${ministryContactsResultsCount})` : ''}`,
                                content: <GlobalSearchResultsContacts setMinistryContactsResultsCount={setMinistryContactsResultsCount} />
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    )
}