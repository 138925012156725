import { Form, Formik } from "formik"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IEventContentModelDocument, IEventSiteModelDocument, IGroupCoachingModelDocument, IGroupCoachingTopicResultDocument, IPresentersDocument } from "../open-api"
import { SelectField } from "./forms"
import * as Yup from 'yup'
import { AccountLevel } from "../constants"
import { sortListByProperty } from "../services/helpers"
import { GroupCoachingFormTabs } from "./group-coaching-form-tabs"

export enum EventFormTab {
    'summary' = 'summary',
    'registrants' = 'registrants',
}

interface IGroupCoachingFormProps {
    selectedEventContent?: IEventContentModelDocument
    eventToEdit?: IGroupCoachingModelDocument
    setEventToEdit: Dispatch<SetStateAction<IGroupCoachingModelDocument | undefined>>
    topicTypes: { id: number, name: string }[]
    topics: IGroupCoachingTopicResultDocument[]
    afterSave?: () => Promise<void>
    presenters: IPresentersDocument[]
    closeModal?: () => void
    setSelectedTopicType: (topicType: string) => void
}

export const GroupCoachingForm = ({ selectedEventContent, eventToEdit, setEventToEdit, topicTypes, topics, afterSave, presenters, closeModal, setSelectedTopicType }: IGroupCoachingFormProps) => {
    console.log('GroupCoachingFormV2 HERE')

    const [sites, setSites] = useState<IEventSiteModelDocument[]>([])
    const [initialValues, setInitialValues] = useState<{
        selectedTopic: IGroupCoachingTopicResultDocument
    } | undefined>()

    const [myInitialTab, setMyInitialTab] = useState<'summary' | 'registrants' | undefined>('summary')

    const getTopic = () => {
        if (eventToEdit) return {selectedTopic: topics.find(o => o.id.toString() === eventToEdit.groupCoachingTopicId.toString())!}
        /* If adding new event, will only have the EC record. */
        //@ts-ignore
        if (selectedEventContent && selectedEventContent.groupCoachingTopicId !== null) return {selectedTopic: topics.find(o => o.id.toString() === selectedEventContent.groupCoachingTopicId.toString())!}

        return undefined;
    }

    useEffect(() => {
        setInitialValues(getTopic())
    }, [eventToEdit, selectedEventContent])

    useEffect(() => {
        /* Check if we're loading a URL for a specific tab and show that tab */
        const hash = window.location.hash.replace('#', '')
        if (EventFormTab.hasOwnProperty(hash))
            setMyInitialTab(EventFormTab[hash as keyof typeof EventFormTab])
    }, [])

    if (!initialValues) {
        return (
            <div>
                <h5>Step 1 - Choose a Topic</h5>
                <div style={{paddingBottom: '15px'}}>
                    Start by choosing the topic for this event. Topics can vary in what accounts are allowed and all topics, except “custom”, include a pre-populated description, as seen below.
                </div>

                <Formik
                    initialValues={{
                        topicTypeId: '',
                        topicId: '',
                    }}
                    validationSchema={Yup.object({
                        topicTypeId: Yup.string().required('Required'),
                        topicId: Yup.string().required('Required'),
                    })}
                    onSubmit={(values) => {
                        const selectedTopic = topics.find(o => o.id.toString() === values.topicId)
                        if (selectedTopic) setInitialValues({ selectedTopic, ...values })
                        const selectedTopicType = topicTypes.find(o => o.id.toString() === values.topicTypeId)
                        if (selectedTopicType) setSelectedTopicType(selectedTopicType.name)
                    }}
                >
                    {({ values: { topicTypeId, topicId, } }) => {
                        const selectedTopicType = topicTypes.find(o => o.id.toString() === topicTypeId)
                        const selectedTopic = topics.find(o => o.id.toString() === topicId)

                        return (
                            <Form>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 1 }} className='mr-2'>
                                        <SelectField
                                            fieldProps={{ name: 'topicTypeId', label: 'Type' }}
                                            options={topicTypes.map(o => ({ value: o.id.toString(), label: o.name }))}
                                        />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <SelectField
                                            disabled={!Boolean(selectedTopicType)}
                                            fieldProps={{ name: 'topicId', label: 'Topic' }}
                                            options={sortListByProperty(topics.filter(o => o.isActive && o.groupCoachingTopicTypeId === selectedTopicType?.id), 'title').map(o => ({ value: o.id.toString(), label: o.title }))}
                                        />
                                    </div>
                                </div>

                                {selectedTopic &&
                                    <div>
                                        <div className='mt-2' style={{ display: 'flex' }}>
                                            <div className='mr-4'>
                                                <b>Allowed Account Level(s)</b>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {selectedTopic.allowedAcctLevelsIds?.map(o => <div key={o}>{AccountLevel[o]}</div>)}
                                                </div>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <b>Short Description</b>
                                                <div dangerouslySetInnerHTML={{ __html: selectedTopic.shortDescription || '' }} />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div style={{paddingTop: '15px'}}>
                                    Click continue to edit the description and add details for this event.
                                </div>
                                <button className='btn btn-primary mt-4' type='submit'>Continue</button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        )
    } else {
        return <GroupCoachingFormTabs
            {...initialValues}
            selectedEventContent={selectedEventContent}
            eventToEdit={eventToEdit}
            afterSave={afterSave}
            summaryProps={{
                ...initialValues,
                presenters,
                closeModal,
                setEventToEdit,
                sites,
                setSites,
            }}
            initialTab={myInitialTab}
        />
    }
}