import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalPurposeProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalPurpose = (props: IMinistryInfoAreaDirectorEvalPurposeProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
            <Formik
                initialValues={{
                    bMission: areaDirectorEval.ministryEvalState.bMission,
                    bMissionCurrent: areaDirectorEval.ministryEvalState.bMissionCurrent,
                    bPlan: areaDirectorEval.ministryEvalState.bPlan,
                    bGoals: areaDirectorEval.ministryEvalState.bGoals,
                    purposeStrategy: areaDirectorEval.ministryEvalState.purposeStrategy || '',
                    tabDirection: null
                }}
                onSubmit={async (values) => {
                    const evalRecord: IMinistryEvalsPutDocument = {
                        ...areaDirectorEval.ministryEvalState,
                        bMission: values.bMission,
                        bMissionCurrent: values.bMissionCurrent,
                        bPlan: values.bPlan,
                        bGoals: values.bGoals,
                        purposeStrategy: values.purposeStrategy,
                        dStep1Updated: moment().format(),
                        step1UpdatedBy: appState.currentUser?.userId || null
					}
                    await makeHTTPRequestWithUi({
						request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
						toastSuccessMessage: 'Eval record successfully updated.',
						toastErrorMessage: 'There was an error updating the eval record.',
                    })
                    
                    if (values.tabDirection === 'prev' && afterSavePrevious)
                        afterSavePrevious()
                    else if (afterSaveNext)
                        afterSaveNext()
                }}
            >
                {(formikProps) => {
                    const form = 
                    <React.Fragment>
                        <YesNoField 
                            fieldProps={{ 
                                name: 'bMission', 
                                label: 'This organization has a written mission/vision/purpose statement(s)'}}
                                setFieldValue={formikProps.setFieldValue}
                                disabled={readonly} />
                        <YesNoField 
                            fieldProps={{ 
                                name: 'bMissionCurrent', 
                                label: 'The mission/vision/purpose statement is current and represents the organization\'s long-term goals' }}
                                setFieldValue={formikProps.setFieldValue}
                                disabled={readonly} />
                        <YesNoField 
                            fieldProps={{ 
                                name: 'bPlan', 
                                label: 'An up-to-date, comprehensive, and formal strategic plan exists for this organization' }}
                                setFieldValue={formikProps.setFieldValue}
                                disabled={readonly} />
                        <YesNoField 
                            fieldProps={{ 
                                name: 'bGoals', 
                                label: 'Goals, objectives and timelines are created and prioritized based on the strategic plan' }}
                                setFieldValue={formikProps.setFieldValue}
                                disabled={readonly} />
                        { !readonly ?
                            <TextareaField
                                fieldProps={{
                                    name: 'purposeStrategy',
                                    label: 'Additional Notes on Purpose and Strategy'
                                }} />
                            :
                            null
                        }
                        
                        { !readonly ?
                            <div className='row px-3'>
                                <button className={`btn btn-primary`} type='button' onClick={() => {
                                    formikProps.setFieldValue('tabDirection', 'next')
                                    formikProps.submitForm()
                                }}>Save &amp; Continue</button>
                            </div>
                            :
                            null
                        }
                    </React.Fragment>
                    // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                    return (
                        readonly ? form :
                        <Form>
                            {form}
                        </Form>
                    )
                }}
            </Formik>
    )
}