import { Form, Formik } from "formik"
import { useContext } from "react"
import * as Yup from 'yup'
import { AppActionContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { FormikSelectField } from './forms/formik-select-field'
import { FormikNumberField } from './forms/formik-number-field'

interface IAlertSnoozeFormProps {
    onCancel?: () => void
    alertId: string
    afterSave: () => void
}

export const AlertSnoozeForm = (props: IAlertSnoozeFormProps) => {
    const { alertId, onCancel, afterSave } = props
    const { DashboardApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    return (
        <Formik
            initialValues={{
                alertId: alertId,
                lengthOfTime: '1',
                unitOfTime: 'days'
            }}

            validationSchema={Yup.object({
                lengthOfTime: Yup.number().required('Required'),
            })}

            onSubmit={async (values) => {
                makeHttpRequestWithUi({
                    request: DashboardApi.apiDashboardAlertsIdSnoozePost(alertId, {
                        ...values,
                        lengthOfTime: parseInt(values.lengthOfTime)
                    }),
                    toastErrorMessage: 'Enountered an error submitting snooze request.',
                    toastSuccessMessage: 'Successfully snoozed alert.',
                    onRequestSuccess: async () => {
                        afterSave()
                    }
                })
            }}
        >
            <Form>
                <div className='d-flex flex-row'>
                    <div className='mr-2'>
                        <FormikNumberField
                            field={{
                                name: 'lengthOfTime',
                                label: 'Snooze this alert for '
                            }}
                        />
                    </div>
                    <div className='flex-fill'>
                        <FormikSelectField
                            field={{ name: 'unitOfTime', label: '' }}
                            options={[{ label: 'days', value: 'days' }, { label: 'weeks', value: 'weeks'} , { label: 'months', value: 'months'} ]}
                        />
                    </div>
                </div>
                <div className='d-flex flex-row'>
                    {onCancel && <button style={{ width: 100 }} type='button' onClick={onCancel} className='btn btn-secondary mr-2'>Cancel</button>}
                    <button type='submit' style={{ width: 100 }} className='btn btn-primary'>Snooze</button>
                </div>
            </Form>
        </Formik>
    )
}