import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IMiBranchModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { Loading } from "./loading"

interface ICardCurrentMinistryCountProps {
    activeCommunity: IMiBranchModelDocument | undefined,
    style: object | undefined
}

export const CardCurrentMinistryCount = (props: ICardCurrentMinistryCountProps) => {
    const { activeCommunity, style } = props

    const { MinistriesApi } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [currentMinistryCount, setCurrentMinistryCount] = useState<number>()
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        if (activeCommunity) {
            setLoading(true)
            const countQuery = await makeHttpRequestWithUi({
                request: MinistriesApi.apiMinistriesMiBranchesBranchIdCurrentMinistryCountGet(activeCommunity.branchId),
                disableSuccessToast: true,
                toastErrorMessage: 'Failed to fetch ministry count.',
                disableLoading: true,
                disableFailureToast: true // Don't show error toast if not available. Don't want to distress the user.
            })

            setCurrentMinistryCount(countQuery.data)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [activeCommunity])

    return (
        <div className="card" style={style}>
            <div className="card-header">
                {activeCommunity?.branchAbbr} Count of Current Ministries
            </div>
            <div className="card-body">
                {activeCommunity ?
                    loading ? <Loading /> : <p className="card-text text-center" style={{ fontSize: '3.5rem' }}>{currentMinistryCount}</p>
                    :
                    <p className="card-text">Select a community.</p>
                }
            </div>
        </div>
    )
}