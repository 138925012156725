import { Formik, Form } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { PotentialRecipient } from '../models/potential-recipient'
import { IMailBlastRecipientDocument, IPotentialRecipientDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { useHTTPRequestUiWrapper, usePotentialRecipientsDefaultColumns } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { getContactTitleOptions } from './contact-form'
import { MinistryContactType } from '../constants'
import { CheckboxField, ISelectFieldOption, SelectField } from './forms'
import { Grid } from './grid'
import { ExpandableSection } from './expandable-section'

interface ISelectRecipientsByTags {
    recipientsSelected: (recipients: IMailBlastRecipientDocument[]) => void
    mailTypeIds?: number[]
}

export const SelectRecipientsByTags = (props: ISelectRecipientsByTags) => {
    const { recipientsSelected, mailTypeIds } = props

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [potentialRecipients, setPotentialRecipients] = useState<IPotentialRecipientDocument[]>()

    const dataSource: GridDataFetch<IPotentialRecipientDocument[]> = async (queryState, _potentialRecipients) => {

        if (!_potentialRecipients) return { rows: [], count: 0 }

        if (queryState.sorts) sortListBySorts(_potentialRecipients, queryState.sorts)

        let rows = _potentialRecipients.map(PotentialRecipient.toGridListItem)

        if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

        return { rows, count: rows.length, }
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        usingLocalData: true,
        rowSelectEnabled: true,
        columns: usePotentialRecipientsDefaultColumns(),
        disabledPagination: true,
        hideGridHeader: true,
        dataSource,
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, potentialRecipients)

    useEffect(() => {
        recipientsSelected(gridState.rows.filter(r => r.selected).map(r => ({ name: `${r.values.fullName}`, email: `${r.values.email}`, ministryContactId: r.values.ministryContactID, prospectId: r.values.prospectId })) as IMailBlastRecipientDocument[])
        //eslint-disable-next-line
    }, [gridState.rows])

    const [tagOptionsList, setTagOptionsList] = useState<ISelectFieldOption[]>([])

    useEffect(() => {
        gridActions.doFetch()
        //eslint-disable-next-line
    }, [potentialRecipients])

    useEffect(() => {
        if (appState.globalCommunityContext) {
            setTagOptionsList((appState.tags?.filter(tag => (tag.tableKey === 'MINISTRYID' || tag.tableKey === 'PROSPECTID') && tag.branchId === appState.globalCommunityContext?.branchId) || []).map(o => ({ label: `${o.tag} (${o.taggedCount})`, value: `${o.tagId}` })))
        } else {
            setTagOptionsList((appState.tags?.filter(tag => tag.tableKey === 'MINISTRYID' || tag.tableKey === 'PROSPECTID') || []).map(o => ({ label: `${o.tag} (${o.taggedCount})`, value: `${o.tagId}` })))
        }
        //eslint-disable-next-line
    }, [])

    const initialValues: {
        includeTagIds: number[],
        excludeTagIds: number[],
        contactTypeIds: number[],
        contactSubTypeIds: number[],
        onlyIncludeArchived: boolean,
        withoutTypeOrTitle: boolean,
        onlyPrimary: boolean,
    } = {
        includeTagIds: [],
        excludeTagIds: [],
        contactTypeIds: [],
        contactSubTypeIds: [],
        onlyIncludeArchived: false,
        withoutTypeOrTitle: false,
        onlyPrimary: false,
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                const contactQuery = await makeHttpRequestWithUi({
                    request: appActions.MailBlastApi.apiMailBlastPotentialRecipientsGetByTagsGet(values.includeTagIds, values.excludeTagIds, values.contactTypeIds, values.contactSubTypeIds, values.onlyIncludeArchived, values.withoutTypeOrTitle, values.onlyPrimary, mailTypeIds),
                    toastSuccessMessage: 'Successfully retrieved contacts.',
                    toastErrorMessage: 'Encountered an error retrieving contacts.'
                })

                setPotentialRecipients(contactQuery.data.filter(o => !!o.email))
            }}
        >

            {formikProps => {
                return (
                    <Form style={{ height: '100%' }}>

                        <ExpandableSection>
                            <div className='mb-2'>
                                <div className='mb-2'><b>Unsubscribed</b> emails are excluded from the results.</div>
                                <div className='d-flex'>
                                    <div style={{ width: '33%' }} className='mr-2'>
                                        <SelectField multiple={true} fieldProps={{ name: 'includeTagIds', label: 'Include Tags' }} options={tagOptionsList} />
                                        <SelectField multiple={true} fieldProps={{ name: 'excludeTagIds', label: 'Exclude Tags' }} options={tagOptionsList} />
                                    </div>
                                    <div style={{ width: '33%' }} className='mr-2'>
                                        <SelectField
                                            multiple={true}
                                            fieldProps={{ name: 'contactTypeIds', label: 'Contact Type(s)' }}
                                            // @ts-ignore
                                            options={Object.keys(MinistryContactType).filter(key => isNaN(key)).map(key => ({ value: MinistryContactType[key], label: key }))}
                                        />
                                        <SelectField
                                            multiple={true}
                                            disabled={formikProps.values.contactTypeIds?.length === 0}
                                            fieldProps={{ name: 'contactSubTypeIds', label: 'Title(s)' }}
                                            options={getContactTitleOptions(formikProps.values.contactTypeIds)}
                                        />
                                    </div>
                                    <div style={{ width: '33%' }} className='mt-4 ml-2'>
                                        <CheckboxField fieldProps={{ name: 'onlyIncludeArchived', label: 'Only include Archived Contacts' }} />
                                        <CheckboxField fieldProps={{ name: 'withoutTypeOrTitle', label: 'Include Contacts without Type/Title' }} />
                                        <CheckboxField fieldProps={{ name: 'onlyPrimary', label: 'Only Include Primary Contact' }} />
                                    </div>
                                </div>
                            </div>

                            <button type='button' className='btn btn-primary mb-4' onClick={formikProps.submitForm}>Load Contacts</button>
                        </ExpandableSection>

                        {potentialRecipients ?
                            <React.Fragment>
                                <b>{potentialRecipients.length} contact{potentialRecipients.length === 1 ? '' : 's'}</b>
                                <p><i>The results include non-archived and archived primary and secondary contacts.</i></p>
                                <Grid state={gridState} actions={gridActions} style={{ height: 'auto', flex: '1' }} />
                            </React.Fragment>
                            :
                            null
                        }
                    </Form>
                )
            }}
        </Formik>
    )
}