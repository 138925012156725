import { Form, Formik } from "formik"
import { useContext } from "react"
import { AppActionContext } from "../app-store-provider"
import { AccountLevel } from "../constants"
import { IGroupCoachingTopicResultDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { NumberField, SelectField, TextField, TinyMceField, YesNoField } from "./forms"
import * as Yup from 'yup'

export const GroupCoachingTopicForm = ({ topicToEdit, afterSave, topicTypes }: { topicToEdit?: IGroupCoachingTopicResultDocument, afterSave: () => Promise<void>, topicTypes: { id: number, name: string }[] }) => {

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const { GroupCoachingTopicApi } = useContext(AppActionContext)!

    return (
        <Formik
            enableReinitialize
            initialValues={{
                title: topicToEdit?.title || '',
                subtitle: topicToEdit?.subtitle || '',
                shortDescription: topicToEdit?.shortDescription || '',
                fullDescription: topicToEdit?.fullDescription || '',
                allowedAcctLevelsIds: topicToEdit?.allowedAcctLevelsIds?.map(o => o.toString()) || ([] as string[]),
                guestsAllowed: Boolean(topicToEdit?.guestsAllowed),
                maxSeatCount: topicToEdit?.maxSeatCount?.toString() || '',
                groupCoachingTopicTypeId: topicToEdit?.groupCoachingTopicTypeId || ('' as unknown as number),
                isActive: topicToEdit ? Boolean(topicToEdit?.isActive) : true
            }}
            validationSchema={Yup.object({
                title: Yup.string().required('Required'),
                groupCoachingTopicTypeId: Yup.number().required('Required'),
                isActive: Yup.boolean().required('Required'),
                allowedAcctLevelsIds: Yup.array().nullable().required('Required').min(1, 'Please select at least one allowed account level.')
            })}
            onSubmit={async (values, actions) => {

                const _values = {
                    ...values,
                    subtitle: values.subtitle.length > 0 ? values.subtitle : null,
                    shortDescription: values.shortDescription.length > 0 ? values.shortDescription : null,
                    fullDescription: values.fullDescription.length > 0 ? values.fullDescription : null,
                    maxSeatCount: values.maxSeatCount.length > 0 ? parseInt(values.maxSeatCount.replaceAll(',', '')) : null,
                    allowedAcctLevelsIds: values.allowedAcctLevelsIds.map(o => parseInt(o)),
                    guestsAllowed: Boolean(values.guestsAllowed),
                }

                if (topicToEdit) {
                    await makeHttpRequestWithUi({
                        request: GroupCoachingTopicApi.apiGroupCoachingTopicIdPut(topicToEdit.id, { ...topicToEdit, ..._values }),
                        toastSuccessMessage: 'Updated topic.',
                        toastErrorMessage: 'Encountered an error updating topic.'
                    })
                } else {
                    await makeHttpRequestWithUi({
                        request: GroupCoachingTopicApi.apiGroupCoachingTopicPost({ ..._values }),
                        toastSuccessMessage: 'Created topic.',
                        toastErrorMessage: 'Encountered an error creating topic.'
                    })
                }

                await afterSave()
                actions.resetForm()
            }}
        >
            <Form>
                <SelectField fieldProps={{ name: 'groupCoachingTopicTypeId', label: 'Topic Type' }} options={topicTypes.map(o => ({ value: o.id.toString(), label: o.name }))} />

                <TextField fieldProps={{ name: 'title', label: 'Title' }} />
                <TextField fieldProps={{ name: 'subtitle', label: 'Subtitle' }} />

                <SelectField
                    multiple
                    fieldProps={{ name: 'allowedAcctLevelsIds', label: 'Allowed Account Levels' }}
                    options={Object.values(AccountLevel).filter(o => typeof o === 'number').map(o => ({ label: AccountLevel[(o as number)], value: o.toString() }))}
                />

                <TinyMceField fieldProps={{ name: 'shortDescription', label: 'Short Description' }} />
                <TinyMceField fieldProps={{ name: 'fullDescription', label: 'Full Description' }} />

                <NumberField fieldProps={{ name: 'maxSeatCount', label: 'Max Seat Count' }} />

                <YesNoField fieldProps={{ name: 'guestsAllowed', label: 'Guests Allowed' }} />

                <YesNoField fieldProps={{ name: 'isActive', label: 'Active' }} />


                <button style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button>
                <p></p>
            </Form>
        </Formik>
    )
}