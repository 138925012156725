import { Form, Formik, FormikProps } from 'formik'
import { IFilterByEventsState } from './call-list-summary'
import { FormikEffect } from './formik-effect'
import { ISelectFieldOption } from './forms'
import { FormikSelectField } from './forms/formik-select-field'

export interface ICallListSummaryFilterByEventsProps {
	filterByEventsState: IFilterByEventsState
	closeModal: () => void
	updateFilterByEvents: (filterByEvents: IFilterByEventsState) => void
}

export const CallListSummaryFilterByEvents = (props: ICallListSummaryFilterByEventsProps) => {
    const { filterByEventsState, closeModal, updateFilterByEvents } = props

	const filterByEventsSelectOptions: ISelectFieldOption[] = filterByEventsState.filterByEventsOptions?.events?.map(event => ({ value: `${event.eventContentId}`, label: event.title || '' })) || []

    const resetEventFilter = (formikProps: FormikProps<any>) => {
        filterByEventsState.attendedEventContentId = 0
        filterByEventsState.registeredEventContentId = 0
		filterByEventsState.attendedTitle = ''
		filterByEventsState.registeredTitle = ''
		updateFilterByEvents(filterByEventsState)


        if (formikProps) {
			formikProps.setFieldValue('registeredEventContentId', '')
			formikProps.setFieldValue('attendedEventContentId', '')
		}

		closeModal()
	}

    return (
        <>
            <Formik
				initialValues={{
					attendedEventContentId: filterByEventsState?.attendedEventContentId,
                    registeredEventContentId: filterByEventsState?.registeredEventContentId
				}}
				onSubmit={async (values) => {
					if (filterByEventsState) {
						filterByEventsState.attendedEventContentId = values.attendedEventContentId
						filterByEventsState.registeredEventContentId = values.registeredEventContentId
						filterByEventsState.attendedTitle = values.attendedEventContentId ? (filterByEventsState.filterByEventsOptions?.events?.find(x => x.eventContentId == values.attendedEventContentId)?.title || '') : ''
						filterByEventsState.registeredTitle = values.registeredEventContentId ? (filterByEventsState.filterByEventsOptions?.events?.find(x => x.eventContentId == values.registeredEventContentId)?.title || ''): ''
						updateFilterByEvents(filterByEventsState)
					}

					closeModal()
				}}
			>
				{formikProps => {
					return (
						<Form>
							<FormikEffect formikProps={formikProps} onChange={(prev, next) => {
								console.log(next)
							}} />

							<div className='border p-2 rounded'>
								<h5>Event Attendance</h5>
                                <div>
									You can use this filter to:
									<ul>Show what past event a ministry has attended.</ul>
									<ul>Show what future event a ministry is not currently registered for.</ul>
								</div>
								<br />
                                <FormikSelectField
                                    field={{ name: 'registeredEventContentId', label: 'Registered for upcoming event' }}
									options={filterByEventsSelectOptions}
									multiple={false}
								/>

								<hr className='mb-2' />
								<FormikSelectField
                                    field={{ name: 'attendedEventContentId', label: 'Attended past event' }}
									options={filterByEventsSelectOptions}
									multiple={false}
								/>
							</div>
							<div className='row mt-4 mb-2 px-3 justify-content-center'>
								<button
									className={`btn btn-primary mr-1`}
									type='submit'
								>
									Apply Event Filters
								</button>
								<button type='submit' className='btn btn-danger mr-1' onClick={() => resetEventFilter(formikProps)}>Clear Event Filters</button>
								<button className='btn btn-secondary mr-1' onClick={closeModal}>Cancel</button>
							</div>
						</Form>
					)
				}}
			</Formik>
        </>
    )
}