import { useContext, useEffect, useRef, useState } from "react";
import { IDefaultProps } from "./component-definitions";
import { GuideStarBaseSearch } from "./guide-star-base-search";

interface IFoundationSearch extends IDefaultProps {
 }

export const FoundationSearch = ({ }: IFoundationSearch) => {

    return <GuideStarBaseSearch foundationsOnly={true} />
}