import React, { useContext, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper, useModal, usePossibleMinistryMatchesColumns } from '../services/hooks'
import { IAppState } from '../stores/app-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { IModalProps, Modal } from './modal'
import { ProspectPossibleMatch } from '../models/prospect-possible-match'
import { usePrevious } from 'react-use'
import { uuidv4 } from '../services/helpers'

interface IProspectPossibleMatchesModalProps extends IModalProps {
    ministryContact: {
        id: string
        organizationName?: string
        contactName?: string
        email?: string
    }
    onActionSuccess?: () => Promise<void> // Callback to perform after a merge or delete.
}

export interface IMinistryProspectPossibleMatch {
    contactEmail: string | null
    contactName: string | null
    dateRegistered: string
    eventCount: number
    ministryContactId: number
    ministryId: number
    ministryName: string | null
}

export const ProspectPossibleMatchesModal = (props: IProspectPossibleMatchesModalProps) => {
    const { ministryContact, closeModal, onActionSuccess } = props

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<IAppState> = async () => {
        try {
            const possibleMatchesQuery = await makeHttpRequestWithUi({
                request: appActions.ProspectsApi.apiProspectsIdMinistryMatchesGet(parseInt(ministryContact.id)),
                disableLoading: true,
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching possible matches for this Ministry Prospect.'
            })

            const possibleMatches = possibleMatchesQuery.data as unknown as IMinistryProspectPossibleMatch[]

            return {
                rows: possibleMatches ? possibleMatches.map(ProspectPossibleMatch.toGridListItem) : [],
                count: possibleMatches.length
            }
        } catch (e) {
            return {
                rows: [],
                count: 0
            }
        }
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        dataSource,
        columns: usePossibleMinistryMatchesColumns(),
        rowSelectEnabled: true,
        hideGridFooter: true,
        hideGridHeader: true,
        disableAllRowSelectOption: true,
        // rowActions: {
        //     ministryInfo: {
        //         id: 'ministryInfo',
        //         action: async (options) => options.e.stopPropagation(),
        //         icon: <BuildingIcon />,
        //         tooltipText: 'View Ministry',
        //         url: (row) => `/ministry-info/${row.id}`
        //     },
        // }
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

    const previousMinistryContactId = usePrevious(ministryContact.id)
    useEffect(() => {
        if (previousMinistryContactId && previousMinistryContactId !== ministryContact.id) gridActions.doFetch()

        // eslint-disable-next-line
    }, [ministryContact.id])

    const selectedRows = gridState.rows.filter(r => r.selected)

    const [confirmAddMergeProspectModal, showHideConfirmAddMergeProspectModal] = useModal()
    const addMergeProspect = async () => {
        if (selectedRows.length !== 1) {
            appActions.addAlert({ id: uuidv4(), title: 'Status', body: 'Please choose just one ministry.' })
            return
        }

        showHideConfirmAddMergeProspectModal(true)
    }

    const [confirmDeleteProspectModal, showHideConfirmDeleteProspectModal] = useModal()

    let selectedMinistryContact: IMinistryProspectPossibleMatch | null = null
    let addMergeProspectButtonTitle = 'Add/Merge Prospect'

    if (selectedRows.length === 1) {
        selectedMinistryContact = selectedRows[0].values as unknown as IMinistryProspectPossibleMatch

        if (selectedMinistryContact.ministryContactId > 0) {
            addMergeProspectButtonTitle = 'Merge Prospect with Ministry Contact'
        } else {
            addMergeProspectButtonTitle = 'Add Prospect to Ministry'
        }
    } else {
        addMergeProspectButtonTitle = 'Add/Merge Prospect'
    }

    return (
        <React.Fragment>
            <Modal
                {...props}
                size='xl'
                footer={
                    <React.Fragment>
                        <button className='btn btn-sm btn-primary' onClick={addMergeProspect} >{addMergeProspectButtonTitle}</button>
                        <button className='btn btn-sm btn-danger' onClick={() => showHideConfirmDeleteProspectModal(true)}>Delete Prospect Record</button>
                        <button className='btn btn-sm btn-secondary' onClick={closeModal}>Cancel</button>
                    </React.Fragment>
                }
            >
                <table>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: 700 }}><span style={{ paddingRight: 10 }}>Prospect Organization Name: </span></td>
                            <td>{ministryContact.organizationName ? ministryContact.organizationName : <i>Not Given</i>}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 700 }}>Prospect Contact Name: </td>
                            <td>{ministryContact.contactName}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: 700 }}>Email: </td>
                            <td>{ministryContact.email}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ fontWeight: 700, paddingTop: 10 }}>Existing Ministries Records (Below):</div>
                <Grid actions={gridActions} state={gridState} style={{ height: 'calc(100% - (1rem * 2 + 80px))' }} />
            </Modal>

            <Modal
                {...confirmAddMergeProspectModal}
                modalTitle='Confirm'
                footer={
                    <React.Fragment>
                        <button className='btn btn-sm btn-secondary' onClick={() => showHideConfirmAddMergeProspectModal(false)}>Cancel</button>
                        <button className='btn btn-sm btn-warning' onClick={async () => {
                            if (selectedMinistryContact) {
                                await makeHttpRequestWithUi({
                                    request: appActions.ProspectsApi.apiProspectsIdMergeIntoMinistryPost(
                                        parseInt(ministryContact.id),
                                        {
                                            ministryId: selectedMinistryContact.ministryId,
                                            ministryContactId: selectedMinistryContact.ministryContactId,
                                        }
                                    ),
                                    toastSuccessMessage: `Successfully ${selectedMinistryContact.ministryContactId > 0 ? 'merged' : 'added'} this prospect to ${`${selectedMinistryContact.ministryName}`.trim()}'s ministry account.`,
                                    toastErrorMessage: `There was an error ${selectedMinistryContact.ministryContactId > 0 ? 'merging' : 'adding'} this prospect to ${`${selectedMinistryContact.ministryName}`.trim()}'s ministry account.`,
                                })
                                showHideConfirmAddMergeProspectModal(false)
                                if (onActionSuccess) await onActionSuccess()
                                if (closeModal) closeModal()
                            }

                        }}>{addMergeProspectButtonTitle}</button>
                    </React.Fragment>
                }
            >
                {selectedMinistryContact ?
                    <div>
                        <p>Are you sure you want to {selectedMinistryContact.ministryContactId > 0 ? 'merge' : 'add'} this prospect to {`${selectedMinistryContact.ministryName}`.trim()}'s ministry account?</p>
                        This action cannot be undone.
                    </div>
                    :
                    null
                }
            </Modal>

            <Modal
                {...confirmDeleteProspectModal}
                modalTitle='Confirm'
                footer={
                    <React.Fragment>
                        <button className='btn btn-sm btn-secondary' onClick={() => showHideConfirmAddMergeProspectModal(false)}>Cancel</button>
                        <button className='btn btn-sm btn-danger' onClick={async () => {
                            await makeHttpRequestWithUi({
                                request: appActions.ProspectsApi.apiProspectsIdDelete(parseInt(ministryContact.id)),
                                toastSuccessMessage: `Successfully deleted prospect.`,
                                toastErrorMessage: `There was an error deleting prospect.`,
                            })
                            showHideConfirmDeleteProspectModal(false)
                            if (onActionSuccess) await onActionSuccess()
                            if (closeModal) closeModal()
                        }}>Delete Prospect</button>
                    </React.Fragment>
                }
            >
                Are you sure you want to delete this prospect?
            </Modal>
        </React.Fragment >
    )
}