import React, { useState, useEffect, MouseEvent as ReactMouseEvent, useContext } from 'react'
import { enableAllToolTips, disableAllToolTips, buildTooltipProps, uuidv4 } from '../services/helpers'
import { PopoverContainer, IPopoverState } from './popover-container'
import { GridContext } from './grid'
import { IGridColumn } from '../stores/grid-definitions'
import { GridColumnFilter } from './grid-column-filter'
import { Checkbox } from './forms'
import { AppActionContext } from '../app-store-provider'

export const GridTableHeader = () => {
	const appActions = useContext(AppActionContext)!
	const { state, actions } = useContext(GridContext)!

	const { queryState, columns, gridElement } = state

	// Enable Bootstrap tooltips whenever the columns change
	useEffect(() => {
		enableAllToolTips()
	}, [columns])


	// Reorder Column
	const [dragHoveredColumnProperty, setDragHoveredColumnProperty] = useState<string | null>(null)
	const onColumnDrop = (draggedColProperty: string, targetColProperty: string) => {
		setDragHoveredColumnProperty(null)

		const draggedColumn = columns.find(col => col.property === draggedColProperty)
		const targetColumn = columns.find(col => col.property === targetColProperty)

		if (draggedColumn && targetColumn) {
			const draggedIdx = columns.indexOf(draggedColumn)
			const targetIdx = columns.indexOf(targetColumn)

			const tempColumns = [...columns]
			tempColumns.splice(draggedIdx, 1)
			tempColumns.splice(targetIdx, 0, draggedColumn)

			tempColumns.forEach((c, i) => {
				c.sortOrder = i
			})

			actions.setColumns(tempColumns)
		}
	}


	// Sort Column
	const onColumnHeaderClick = (_col: IGridColumn) => {
		if (_col.disableSort) {
			appActions.addAlert({ id: uuidv4(), title: 'Sort Disabled', body: 'Sorting is not available for this column.' })
			return
		}

		actions.addSort(_col.property)
	}


	// Filter Column
	const [filterColumnState, setFilterColumnState] = useState<{
		popoverState: IPopoverState
		filterColumn?: IGridColumn
	}>({
		popoverState: {
			popoverOptions: {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 5],
						},
					},
				],
				placement: 'bottom-start'
			}
		}
	})
	const onFilterPressed = (e: ReactMouseEvent<HTMLSpanElement>, col: IGridColumn) => {
		e.stopPropagation()
		if (filterColumnState.filterColumn?.property === col.property) {
			onPopoverClosed()
		} else {
			setFilterColumnState({
				...filterColumnState,
				popoverState: {
					...filterColumnState.popoverState,
					trigger: e.currentTarget,
					show: true,
				},
				filterColumn: col
			})
		}
	}
	const onPopoverClosed = () => {
		if (filterColumnState.popoverState.show) {
			setFilterColumnState({
				...filterColumnState,
				popoverState: {
					...filterColumnState.popoverState,
					show: false
				},
				filterColumn: undefined
			})
		}
	}


	// Resize Column
	const [colResizeStart, setColResizeStart] = useState<{
		col: IGridColumn
		start: number
		startingWidth: number
	} | null>(null)

	/* 
		Set up (or tear down) all listeners and handlers whenever colResizeStart changes 
		(e.g. is set by a user starting a drag or is nulled when a user stops/drops a drag)
	*/
	const onColumnResize = (e: MouseEvent) => {
		/* 
			As a user drags the column resize handle we will be constantly updating the column size for this column
			...which will cause a rerender of the page
			...which will reflect the change in column and table width
		*/
		if (colResizeStart) {
			document.getElementById('root')?.classList.add('unselectable')
			const change = e.pageX - colResizeStart.start

			const column = colResizeStart.col
			if (column) {
				if (change > 0) {
					column.width = colResizeStart.startingWidth + change
				} else if (colResizeStart.startingWidth + change > 15) {
					column.width = colResizeStart.startingWidth + change
				} else {
					column.width = 15
				}

				actions.updateColumn(column)
			}
		}
	}
	const onColumnResizeEnd = () => {
		document.getElementById('root')?.classList.remove('unselectable')
		setColResizeStart(null)
	}
	useEffect(() => {
		document.addEventListener('mousemove', onColumnResize)
		document.addEventListener('mouseup', onColumnResizeEnd)

		return function cleanup() {
			document.removeEventListener('mousemove', onColumnResize)
			document.removeEventListener('mouseup', onColumnResizeEnd)
		}

		//eslint-disable-next-line
	}, [colResizeStart])


	return (
		<React.Fragment>
			{/* The popover really can be put just about anywhere. It's hidden until something triggers it, and then when shown is absolutely positioned. */}
			<PopoverContainer
				popoverState={filterColumnState.popoverState}
				onPopoverClosed={onPopoverClosed}
				className={'grid-popover'}
				parentElement={gridElement || null}
				setPopoverContainer={actions.setActiveFilterPopover}
			>
				{filterColumnState.filterColumn ? <GridColumnFilter column={filterColumnState.filterColumn} columnFilters={state.queryState.filters?.filter(f => f.property === filterColumnState.filterColumn?.property) || []} updateColumnFilters={actions.updateColumnFilters} /> : null}
			</PopoverContainer>

			{/* colgroup is used to control column widths */}
			<colgroup>
				{state.rowSelectEnabled ?
					<col style={{ width: '35px' }} />
					:
					null
				}
				{state.enableRowColors ?
					<col style={{ width: '45px' }} />
					:
					null
				}
				{columns.filter(col => !col.hide).map(col => (
					<col key={`${col.property}-colSize`} id={`${col.property}-colSize`} style={{ width: col.width }} />
				))}
			</colgroup>

			{/*
				The <thead> is probably the most featureful part of the Grid from a UI/UX perspective. 
				It houses sorting, column resizing, column reordering, and filter controls. 
			*/}
			<thead>
				<tr>
					{/* Check if we need a header for select all rows checkbox */}
					{state.rowSelectEnabled ?
						<th className='grid-checkbox-header'>
							{state.disableAllRowSelectOption ?
								<div></div>
								:
								<div><Checkbox id={`${state.id}-grid-all-rows-selected`} checked={!!state.allRowsSelected} onChange={() => actions.toggleAllRowsSelect()} /></div>
							}
						</th>
						:
						null
					}
					{/* Check if we need a header for row colors */}
					{state.enableRowColors ?
						<th><div className='column-name'></div></th>
						:
						null
					}
					{/* 
						We start by mapping over the visible columns. 
						This list is guaranteed to also respect column ordering, so no extra order checks are necessary.
					*/}
					{columns.filter(col => !col.hide).map(col => {
						const sorted = queryState.sorts?.find(c => c.property === col.property)
						const filtered = queryState.filters?.filter(f => f.enabled).find(f => f.property === col.property) && !col.disableFilters
						return (
							<th
								id={col.property}
								key={col.property}

								/* 
									Styling for all of the column header is applied at the top level, even if the implications are applied lower down.
									E.g. 'sorted' sets color: $blue, but only on the 'column-name' element, not the whole <th>
								*/
								className={`
									${dragHoveredColumnProperty === col.property ? 'drag-hover' : ''} 
									${sorted ? 'sorted' : ''}
									${sorted ? sorted.direction === 'ASC' ? 'sorted-up' : 'sorted-down' : ''}
									${filtered ? 'filtered' : ''}
								`}

								/* 
									Some of the drag controls are set up here to handle column re-ordering. 
									We want the whole <th> to be a target for "dropping" a column, so the dragover, drop, and dragenter events are handled here.

									(Note: the actual drag starts in the 'column-name' element - that way interacting with the filter, sort, or resize UI elements won't start a drag event for reordering the column.)
								*/
								onDragOver={e => e.preventDefault()}
								onDrop={e => onColumnDrop(e.dataTransfer.getData('colProperty'), col.property)}
								onDragEnter={e => setDragHoveredColumnProperty(e.currentTarget.id)}
							>
								{/* 
									We wrap the contents of the <th> in a container <div> so we can more easily control layout (easier to maniuplate a <div>'s layout than a <th>'s).
								*/}
								<div>
									<div
										className='column-name'

										/* 
											Controls for reordering
											A column drag (reorder) can only be started on this element ('column-name').
										*/
										draggable
										onDragStart={e => {
											disableAllToolTips()
											e.dataTransfer.setData('colProperty', col.property)
										}}
										onDragEnd={enableAllToolTips}

										/* 
											Currently, the only that happens on column header click is sorting.
										*/
										onClick={() => onColumnHeaderClick(col)}
									>
										{/* 
											We set up an inner <span> for the column title so we can easily apply a tooltip to just this element.
											That way you won't annoyingly get the tooltip when interacting with other elements in the <th>
										*/}
										<span {...buildTooltipProps({ tooltipText: col.description || null, placement: 'left', html: true })}>
											{col.title}
										</span>
										<div className='filter-button-container'><span className='filter-button' onClick={(e) => onFilterPressed(e, col)}>filter</span></div>
									</div>

									{/* <div onClick={(e) => onFilterPressed(e, col)}>
										<FilterIcon className='filter-icon' />
									</div> */}

									<div
										className={`resize-handle ${colResizeStart?.col.property === col.property ? 'active' : ''}`}

										onMouseDown={e => {
											e.currentTarget.classList.add('active')
											setColResizeStart({ col, start: e.pageX, startingWidth: col.width })
										}}
									>
										{/* 
											These two <div>s are important - they are the targets for the CSS that styles the lines for the column resize UI.
										*/}
										<div></div>
										<div></div>
									</div>
								</div>
							</th>
						)
					})}
				</tr>
			</thead>
		</React.Fragment>
	)
}