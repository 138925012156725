import { IDefaultProps } from "./component-definitions";
import { GuideStarBaseSearch } from "./guide-star-base-search";

interface IGuideStarSearch extends IDefaultProps {
 }

const exportLimit = 3000

export const GuideStarSearch = ({ }: IGuideStarSearch) => {

    return <GuideStarBaseSearch />
}