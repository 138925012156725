import { IGrantRequestSummaryDocument, IMinistryGrantFundingSummaryDocument, IMinistryGrantPaymentsDocument, IMinistryGrantPaymentSummaryDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useCallback, useContext, useEffect, useState } from "react"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { PencilIcon } from "../assets"
import { gridReducer } from "../stores/grid-reducer"
import { Modal } from "./modal"
import { Grid } from "./grid"
import { SquareDeleteIcon } from "./partials"
import { GrantRequestPaymentForm } from "./grant-request-payment-form"
import { GRANT_REQUEST_STATUS } from "../constants"

interface IGrantRequestPaymentsProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    fundingSources: IMinistryGrantFundingSummaryDocument[]
    afterSave?: () => Promise<void>
}

export const GrantRequestPayments = (props: IGrantRequestPaymentsProps) => {
    const { afterSave, grantRequestToEdit, fundingSources } = props
    const { GrantRequestApi } = useContext(AppActionContext)!

    const [payments, setPayments] = useState<IMinistryGrantPaymentSummaryDocument[]>([])
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [paymentToEdit, setPaymentToEdit] = useState<IMinistryGrantPaymentsDocument>()
    const [paymentFormModal, showHidePaymentFormModal] = useModal()
    const [deletePaymentModal, showHideDeletePaymentModal] = useModal()
    const [paymentId, setPaymentId] = useState<number>()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchPayments = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestGrantIdPaymentsGet(grantRequestToEdit.grantId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching payments.',
        })
        setPayments(data)
    }

    const readonly = grantRequestToEdit.isLegacyGrant ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Complete"] || 
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Denied"] ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Request Tabled"]

    useEffect(() => {
        fetchPayments()
    }, [])

    useEffect(() => {
        if (paymentToEdit) setPaymentId(paymentToEdit.ministryGrantPaymentId)
    }, [paymentToEdit?.ministryGrantPaymentId])

    const dataSource: GridDataFetch<IMinistryGrantPaymentsDocument[]> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.ministryGrantPaymentId!.toString(),
                values: {
                    ...o,
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('GrantRequestPayments > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    const editPayment = useCallback(async (paymentId: string) => {
        const sessionToEditQuery = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestPaymentsPaymentIdGet(parseInt(paymentId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setPaymentToEdit(sessionToEditQuery.data)

        showHidePaymentFormModal(true)
    }, [])

    const addPayment = async () => {
        showHidePaymentFormModal(true)
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.GrantPayments,
        gridActions: [
            {
                id: 'addPayment',
                label: 'Add Payment',
                onClick: () => addPayment(),
                sortOrder: 0,
                disabled: readonly
            },
        ],
        rowActions: {
            editPayment: {
                id: 'editPayment',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    editPayment(row.id)
                },
                tooltipText: 'Edit Payment',
                icon: <PencilIcon />,
                disabled: readonly
            },
            deletePayment: {
                id: 'deletePayment',
                action: async ({row}) => {
                    setSelectedRow(row)
                    showHideDeletePaymentModal(true)
                },
                tooltipText: 'Delete Payment',
                icon: <SquareDeleteIcon />,
                disabled: readonly
            }
        },
        columns: [
            {
                property: 'checkAmount',
                type: 'number',
                width: 150,
                allowFilters: true,
                title: 'Amount',
                render: CurrencyDisplay
            },
            {
                property: 'donorName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Giver',
                render: DefaultGridCellDisplay
            },
            {
                property: 'checkMailedDate',
                type: 'date',
                width: 150,
                allowFilters: true,
                title: 'Check Mail Date',
                render: DefaultGridCellDisplay,
            },            
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: false,
        usingLocalData: true,
        disabledPagination: true,
        disableExport: true,
        respectGlobalCommunityFilter: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            editPayment(row.id)
        },
    }

    //const [gridState, gridActions] = useGrid(gridReducer, initialGridState, [])
    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, payments ?? [])

    useEffect(() => {
        gridActions.doFetch()
    }, [payments])

    return (
        <>
            <p className='mt-3'>
                A list of checks/payments that have been paid out in relationship to this grant request.
            </p>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>
            <Modal
                {...paymentFormModal}
                modalTitle={!!paymentToEdit ? `Edit Payment (${paymentId})` : 'Add Payment'}
                size='lg'

                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setPaymentToEdit(undefined)
                    setPaymentId(undefined)
                }}

                dismissible={false}
            >
            {paymentFormModal.show && 
                <GrantRequestPaymentForm
                    paymentToEdit={paymentToEdit}
                    grantId={grantRequestToEdit.grantId}
                    grantTypeId={grantRequestToEdit.grantTypeId}
                    fundingSources={fundingSources}
                    afterSave={async () => {
                        await fetchPayments()
                        //showHidePaymentFormModal(false)
                        afterSave && afterSave()
                    }}
                /> 
            }
            </Modal>
            <Modal
				{...deletePaymentModal}
				modalTitle='Confirm'
				footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideDeletePaymentModal(false)}>Cancel</button>
						<button className='btn btn-warning' onClick={async () => {
							if (!!selectedRow) {
								await makeHttpRequestWithUi({
									request: GrantRequestApi.apiGrantRequestPaymentsMinistryGrantPaymentIdDelete(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Successfully deleted payment.',
									toastErrorMessage: 'There was an error deleting payment.'
								})
							}
							fetchPayments()
							showHideDeletePaymentModal(false)
                            afterSave && afterSave()
						}}>Delete</button>
					</>
				}
				_onModalHidden={() => {
					setSelectedRow(undefined)
				}}
			>
				Are you sure you want to delete this payment?
            </Modal>
        </>
    )
}