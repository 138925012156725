// TB 20230428 - After I started migrating the GR Summary Report from the legacy system to React, I realized we had already migrated it to ASP.NET on the Givers Portal.
// I stopped the migration in React, but leaving this here, just in case we decide to continue it sometime.
import { truncate } from 'lodash'
import { formatCurrency } from '../services/helpers'
import dayjs from 'dayjs'
import { IGrantRequestSummaryReportProps } from './grant-request-summary-report'

export const GrantRequestSummaryReportPage1 = (props: IGrantRequestSummaryReportProps) => {
    const { grantRequestSummary } = props

    const howFundsRaisedMagicNumber = 1; // Unsure what this magic number means, but pulled over from legacy code.

    return (
        <>
            <table style={{width: '910px', border: 0}} cellPadding={0} cellSpacing={0}>
                <tr>
                    <td align="center" className='hRule' width="100%">
                        <h2 style={{fontSize: '16px'}}>Mission Increase - Grant Request Summary</h2>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
            </table>
            <table style={{width: '910px', border: 0}} cellPadding={0}>
                <tr>
                    <td>
                        <div style={{width: '100%'}}>
                            <div style={{float: 'left'}} className="headerDiv"><strong>{truncate(grantRequestSummary.ministryName || '', {length: 70})}</strong></div>
                            <div style={{float: 'right'}} className="headerDiv"><strong>{grantRequestSummary.branchName}</strong></div>
                        </div>
                    </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td className='boxed'>
                            <table style={{width: '100%', border: 0}}>
                                <tr>
                                    <td className='fieldLabel'>Primary Contact: </td>
                                    <td colSpan={3} className="valueLabel">{grantRequestSummary.firstName} {grantRequestSummary.lastName}</td>
                                    <td>&nbsp;</td>
                                    <td className='fieldLabel' style={{width: '50px !important'}}>City/State: </td>
                                    <td className='valueLabel'>{grantRequestSummary.physicalCity}, {grantRequestSummary.physicalState}</td>
                                </tr>
                                <tr>
                                    <td className='fieldLabel'>Area of Service: </td>
                                    <td colSpan={3} className='valueLabel'>{grantRequestSummary.organizationServe}</td>
                                    <td>&nbsp;</td>
                                    <td className='fieldLabel'>Workshop Att (lifetime): </td>
                                    <td className='valueLabel'>{grantRequestSummary.eventsAttended} of {grantRequestSummary.possibleEvents}</td>
                                </tr>
                                <tr>
                                    <td className='fieldLabel'>IRS Ruling Year: </td>
                                    <td className='valueLabel'>{grantRequestSummary.rulingYear || 0 > 0 ? grantRequestSummary.rulingYear : 'NA'}</td>
                                    <td className='fieldLabel'># of Donors: </td>
                                    <td className='valueLabel'>{grantRequestSummary.numDonors}</td>
                                    <td>&nbsp;</td>
                                    <td className='fieldLabel'>Workshop Att (12 mos.): </td>
                                    <td className='valueLabel'>{grantRequestSummary.eventsAttended12Mo} of {grantRequestSummary.possibleEvents12Mo}</td>
                                </tr>
                                <tr>
                                    <td className='fieldLabel'>Joined MI: </td>
                                    <td className='valueLabel'>{grantRequestSummary.startYear}</td>
                                    <td className='fieldLabel'># Major Donors: </td>
                                    <td className='valueLabel'>{grantRequestSummary.numMajorDonors}</td>
                                    <td>&nbsp;</td>
                                    <td className='fieldLabel'>Total Consulting Hours: </td>
                                    <td className='valueLabel'>{grantRequestSummary.consultingHours > 0 ? grantRequestSummary.consultingHours : '0'}</td>
                                </tr>
                                <tr>
                                    <td className='fieldLabel'>AD Eval Score: </td>
                                    <td className='valueLabel'>{grantRequestSummary.gtoEval ? grantRequestSummary.gtoEval : '0'} of 82</td>
                                    <td className='fieldLabel'># Served: </td>
                                    <td className='valueLabel'>{grantRequestSummary.totalServed}</td>
                                    <td>&nbsp;</td>
                                    <td className='fieldLabel'>Involvement Score (12 mos.): </td>
                                    <td className='valueLabel'>{grantRequestSummary.involvementRolling12Mo} &nbsp;&nbsp;(Rating: {grantRequestSummary.ranking12} of {grantRequestSummary.totalAtLevel12})</td>
                                </tr>
                                <tr>
                                    <td className='fieldLabel'>LY Total Income: </td>
                                    <td className='valueLabel'>{formatCurrency(grantRequestSummary.lastYearIncome)}</td>
                                    <td className='fieldLabel'>LY Total Expense: </td>
                                    <td className='valueLabel'>{formatCurrency(grantRequestSummary.lastYearExpenses)}</td>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <table width="100%" cellSpacing={0} cellPadding="0">
                                            <tr>
                                                <td width="70" className='fieldLabel'>FT Staff: </td>
                                                <td className='valueLabel' style={{paddingLeft: '3px'}}>{grantRequestSummary.fullTimeStaff}</td>
                                                <td width="70" className='fieldLabel'>PT Staff: </td>
                                                <td className='valueLabel' style={{paddingLeft: '3px;'}}>{grantRequestSummary.partTimeStaff}</td>
                                                <td width="105" className='fieldLabel'>Board Giving: </td>
                                                <td className='valueLabel' style={{paddingLeft: '3px;'}}>{grantRequestSummary.boardMembersDonatedParsed ? grantRequestSummary.boardMembersDonatedParsed : 'n/a'}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='fieldLabel'>Prior MI Grants #: </td>
                                    <td className='valueLabel'>{grantRequestSummary.priorGrants}</td>
                                    <td className='fieldLabel'>Total Granted: </td>
                                    <td className='valueLabel'>{grantRequestSummary.totalGranted ? formatCurrency(grantRequestSummary.totalGranted) : '$0'}</td>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <table style={{border: 0}} cellSpacing="0" cellPadding="0">
                                            <tr>
                                                <td className='fieldLabel' style={{width: '125px'}}>Total Matched: </td>
                                                <td className='valueLabel' style={{paddingLeft: '3px'}}>{grantRequestSummary.totalRaised ? formatCurrency(grantRequestSummary.totalRaised) : ''}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
            </table>
            <br />
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td className='hRule'><strong>Ministry Request</strong></td>
                </tr>
                <tr>
                    <td className='boxed'>
                        <table style={{border: 0, width: '100%'}}>
                            <tr>
                                <td className='fieldLabel' style={{width: '150px'}}>Request Date: </td>
                                <td className='valueLabel' style={{width: '175px'}}>{grantRequestSummary.grantReqDate && dayjs(grantRequestSummary.grantReqDate).format('M/DD/YY')}</td>
                                <td className='fieldLabel' style={{width: '175px'}}>Request Amount: </td>
                                <td className='valueLabel'>{formatCurrency(grantRequestSummary.amountRequested)}</td>
                                {grantRequestSummary.bNewStaff ? 
                                    <td colSpan={2}>&nbsp;</td>
                                    : null}
                            </tr>
                            {(grantRequestSummary.howFundsRaisedIdList?.includes(howFundsRaisedMagicNumber) || grantRequestSummary.bNewStaff) ?
                                <tr>
                                {grantRequestSummary.howFundsRaisedIdList?.includes(howFundsRaisedMagicNumber) &&
                                    <>
                                        <td className="fieldLabel">Event Date: </td>
                                        <td className="valueLabel">
                                            {grantRequestSummary.estimatedEventDate}
                                        </td>
                                    </>
                                }
                                {grantRequestSummary.bNewStaff ? 
                                    <>
                                        <td className="fieldLabel">New staff position: </td>
                                        <td colSpan={grantRequestSummary.howFundsRaised?.includes('1') ? 5 : 3} className="valueLabel">
                                            {grantRequestSummary.bNewStaff ?
                                                grantRequestSummary.jobTitleId == 5 && grantRequestSummary.jobTitleOther ? 
                                                    truncate(grantRequestSummary.jobTitleOther, {length: 50})
                                                    :
                                                    grantRequestSummary.jobTitle 
                                                : 'n/a'}
                                        </td>
                                        <td colSpan={2}>&nbsp;</td>
                                    </>
                                    :
                                    <>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </>
                                }
                                </tr>
                                : null}
                            { grantRequestSummary.bNewStaff &&
                                    <tr>
                                        <td className="fieldLabel"># Hours/week: </td>
                                        <td className="valueLabel">
                                            { grantRequestSummary.bNewStaffFullTime ?
                                                    'full-time'
                                                    :
                                                    grantRequestSummary.newStaffHoursPerWeek }
                                        </td>
                                        <td className="fieldLabel">Annual Salary: </td>
                                        <td className="valueLabel">
                                            { grantRequestSummary.newStaffSalary ?
                                                    `${formatCurrency(grantRequestSummary.newStaffSalary)}`
                                                :
                                                    `&nbsp;` }
                                        </td>
                                        <td className="fieldLabel" style={{width: '175px'}}>1<sup>st</sup> yr. Income target: </td>
                                        <td className="valueLabel">
                                            {grantRequestSummary.newStaffAmountRaise && grantRequestSummary.newStaffAmountRaise > 0 ?                                                                   
                                                `${formatCurrency(grantRequestSummary.newStaffAmountRaise)}`
                                                : 
                                                'n/a'}
                                        </td>
                                    </tr>
                            }
                        </table>
                        <table style={{border: 0}} width="100%">
                            <tr>
                                <td>
                                    <table style={{border: 0}}>
                                        <tr>
                                            <td style={{fontWeight: 'bold'}}>Matching Plan: Please describe how you plan to raise matching funds:</td>
                                        </tr>
                                        <tr>
                                            <td>{grantRequestSummary.grantPlan}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 'bold'}}>Fund Use Plan: Please describe how the Mission Increase grant and matching funds will be used:</td>
                                        </tr>
                                        <tr>
                                            <td>{grantRequestSummary.grantUse}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <br />
                    </td>
                </tr>
            </table>
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td className="hRule"><strong>Area Director Recommendations</strong></td>
                </tr>
                <tr>
                    <td>
                    <table style={{border: 0, width: '100%', marginTop: '-3px'}}>
                        <tr>
                            <td valign="top" className="boxed" style={{marginLeft: '-3px'}}>
                                <table>
                                    <tr>
                                        <td className="fieldLabel">Grant Amount: </td>
                                    </tr>
                                    <tr>
                                        <td style={{paddingBottom: '8px'}} className="valueLabel">{formatCurrency(grantRequestSummary.goAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td className="fieldLabel">Grant Type: </td>
                                    </tr>
                                    <tr>
                                        <td style={{paddingBottom: '8px'}} className="valueLabel">{grantRequestSummary.grantType}</td>
                                    </tr>
                                    {grantRequestSummary.grantPeriodBeginning && 
                                    <>
                                        <tr>
                                            <td className="fieldLabel">Matching Period: </td>
                                        </tr>
                                        <tr>
                                            <td className="valueLabel">{dayjs(grantRequestSummary.grantPeriodBeginning).format('M/DD/YY')} {grantRequestSummary.grantPeriodEnding ? `- ${dayjs(grantRequestSummary.grantPeriodEnding).format('M/DD/YY')}` : 'Missing End Date'}</td>
                                        </tr>
                                    </>
                                    }
                                </table>
                            </td>
                            {grantRequestSummary.grantTypeId != 20 && 
                                <td valign="top" className="boxed" style={{paddingRight: '2px', marginRight: '-3px', paddingBottom: '1px'}}>
                                    <table style={{border: 0, width: '100%'}} cellSpacing={0} cellPadding={0}>
                                        <tr>
                                            <td colSpan={2} className="fieldLabel" style={{paddingLeft: '3px !important;'}}>Area Director Ratings: </td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: '3px !important', width: '375px !important;'}}>This ministry is a good fit with our granting criteria: </td>
                                            <td style={{padding: '0px !important', height: '30px !important'}}>
                                                {grantRequestSummary.goRatingCriteria ?
                                                    <>{grantRequestSummary.goRatingCriteria} of 8</>
                                                    : <>&nbsp;</>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: '3px !important'}}>This is a worthy ministry with capable and stable leadership: </td>
                                            <td style={{padding: '0px !important', height: '30px !important'}}>
                                                {grantRequestSummary.goRatingLeadership ?
                                                    <>{grantRequestSummary.goRatingLeadership} of 8</>
                                                    : <>&nbsp;</>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: '3px !important'}}>This is a strategic grant that will have significant impact on this ministry: </td>
                                            <td style={{padding: '0px !important', height: '30px !important'}}>
                                                {grantRequestSummary.goRatingImpact ?
                                                    <>{grantRequestSummary.goRatingImpact} of 8</>
                                                    : <>&nbsp;</>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: '3px !important'}}>This ministry will benefit from the training that will accompany this grant: </td>
                                            <td style={{padding: '0px !important', height: '30px !important'}}>
                                                {grantRequestSummary.goRatingBenefit ?
                                                    <>{grantRequestSummary.goRatingBenefit} of 8</>
                                                    : <>&nbsp;</>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingLeft: '5px', fontWeight: 'bold'}}>Overall Area Director Grant Rating: </td>
                                            <td style={{height: '35px !important', fontWeight: 'bold'}}>
                                                {grantRequestSummary.goRating ?
                                                    <>{grantRequestSummary.goRating} of 8</>
                                                    : <>&nbsp;</>}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            }
                        </tr>
                    </table>
                    {((grantRequestSummary.gtoMatchingPlan && grantRequestSummary.gtoMatchingPlan.length > 0) || (grantRequestSummary.gtoFundUsePlan  && grantRequestSummary.gtoFundUsePlan?.length > 0)) &&
                        <table style={{border: 0, width: '100%', marginTop: '-3px'}}>
                            <tr>
                                <td className="boxed" style={{marginLeft: '-3px', paddingRight: '2px', marginRight: '-3px'}}>
                                    <table>
                                        {(grantRequestSummary.gtoMatchingPlan && grantRequestSummary.gtoMatchingPlan.length > 0) &&
                                            <tr>
                                                <td><strong>Area Director Recommendation for Matching Plan:</strong><br />{grantRequestSummary.gtoMatchingPlan}</td>
                                            </tr>
                                        }
                                        {(grantRequestSummary.gtoFundUsePlan  && grantRequestSummary.gtoFundUsePlan?.length > 0) &&
                                            <tr>
                                                <td><strong>Area Director Recommendation for Fund Use Plan:</strong><br />{grantRequestSummary.gtoFundUsePlan}</td>
                                            </tr>
                                        }
                                    </table>
                                </td>
                            </tr>
                        </table>
                    }
                    </td>
                </tr>
            </table>
        </>
    )
}