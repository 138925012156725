/* 
This form is used for:
    - Add/Update Webinar EventContent (EC)
    - Add/Update Workshop EC
    - Update Group Learning EC (Add a GL EC record using group-coaching-form-summary.tsx)
*/

import { useContext, useEffect, useRef, useState } from "react"
import { Form, Formik } from "formik"
import { IEventContentFormState } from "./event-content-form"
import * as yup from 'yup'
import { EVENT_TOPICS, AccountLevel, EventContentCategory, EventContentType } from "../constants"
import { CheckboxField, CurrencyField, ISelectFieldOption, SelectField, TinyMceField } from "./forms"
import { FormikEffect } from "./formik-effect"
import { IEventContentModelDocument, IGroupCoachingTopicResultDocument, ILmsCourseModelDocument, IPresentersDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { FormikTextField } from "./forms/formik-text-field"
import dayjs from "dayjs"
import { Modal } from "./modal"
import { FormikSelectField } from "./forms/formik-select-field"
import { uuidv4 } from "../services/helpers"

interface IEventContentStep2Props {
    parentState: IEventContentFormState
    setParentState: any
    eventContentToEdit?: IEventContentModelDocument
    setEventContentToEdit: (eventContentToEdit: IEventContentModelDocument) => void
    readonly: boolean
    presenters: IPresentersDocument[]
}

interface ICourseUrlData {
    courseId: number
    courseUrl: string
    courseTitle: string
}

export const getMaxSeatsOptions = () => {
    let options: ISelectFieldOption[] = [{ label: 'Unlimited', value: '' }]

    for (let index = 1; index <= 30; index++) {
        options.push({ label: index.toString(), value: index.toString()})
    }

    return options
}

export const EventContentStep2 = ({ parentState, readonly, eventContentToEdit, setEventContentToEdit, presenters} : IEventContentStep2Props) => {
    const { EventMgmtApi, addAlert } = useContext(AppActionContext)!
    const { currentUser } = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [courseUrlData, setCourseUrlData] = useState<ICourseUrlData[]>([])
    const [showCourses, setShowCourses] = useState<boolean>()
    const [courses, setCourses] = useState<ILmsCourseModelDocument[]>()
    const [confirmActivateModal, showHideConfirmActivateModal] = useModal()

    let selectedTopic:IGroupCoachingTopicResultDocument | undefined
    let availableAccountLevels:(string|AccountLevel)[] = []

    const isWorkshop = parentState.step1Values.eventTypeId.toString() === EventContentType.Workshop.toString()

    if (eventContentToEdit && parentState.step1Values.eventTypeId === EventContentType["Group Learning"].toString())
    {
        selectedTopic = parentState.groupCoachingTopics.find(o => o.id.toString() === eventContentToEdit.groupCoachingTopicId?.toString())
        //@ts-ignore
        availableAccountLevels = Object.values(AccountLevel).filter(o => typeof o === 'number' && selectedTopic?.allowedAcctLevelsIds.includes(o))
    }

    // Keeping this out of form state to avoid potential race conditions with Formik state not updating fast enough to be caught in onSubmit handler.
    const activatePressed = useRef(false)

    const fetchCourses = async () => {
        try {
            if (eventContentToEdit) {
                // This endpoint pulls back all the available courses, plus any that had been previously attached to this EC record.
                const { data } = await makeHttpRequestWithUi({
                    request: EventMgmtApi.apiEventMgmtEventContentEventContentIdCourseListGet(eventContentToEdit.eventContentId),
                    disableSuccessToast: true,
                    toastErrorMessage: 'Encountered an error fetching courses.',
                })

                setCourses(data)
            } else {
                // This endpoint simply returns all of the available courses.
                const { data } = await makeHttpRequestWithUi({
                    request: EventMgmtApi.apiEventMgmtEventContentCourseListGet(),
                    disableSuccessToast: true,
                    toastErrorMessage: 'Encountered an error fetching courses.',
                })

                setCourses(data)
            }
        } catch (e) {
            console.log('EventContentStep2 > fetchCourses() > Exception thrown:', e)
        }
    }

    useEffect(() => {
        if (showCourses && !courses) {
            console.log('useEffect > fetchingCourses')
            fetchCourses()
        }
    }, [showCourses])

    useEffect(() => {
        if (eventContentToEdit?.courseIdList && eventContentToEdit?.courseIdList.length > 0) setShowCourses(true)
    }, [])

    useEffect(() => {
        if (courses && eventContentToEdit?.courseIdList && eventContentToEdit?.courseIdList.length > 0) {
            let urls:ICourseUrlData[] = []
            eventContentToEdit?.courseIdList.forEach(courseId => {
                const course = courses.find(c => c.id === courseId)
                if (course) urls.push({courseId: course.id, courseUrl: course.courseUrl!, courseTitle: course.title! })
            })
            setCourseUrlData(urls)
        }
    }, [courses])

    console.log('parentState', parentState)

    return (
        <Formik
            initialValues={{...parentState.step2Values,
                month: parentState.step2Values.month <= 3 ? 3 : 
                parentState.step2Values.month <=6 ? 6 : 
                parentState.step2Values.month <=9 ? 9 : 
                parentState.step2Values.month <=12 ? 12 : 1 // Default to 1 if no valid value found.
            }}
            validationSchema={
                readonly ?
                    yup.object()
                    :
                    yup.object({
                        title: yup.string().required('Required'),
                        webinarUrl: yup.string()
                            .test(
                                'webinarUrl',
                                'Required',
                                function (value) {
                                    if (parentState.step1Values.eventTypeId.toString() === EventContentType.Webinar.toString() && this.parent.nationalEvent === true) return !!value && value.length > 0
                                    return true
                                }
                            )
                            .url('Must be a valid URL (including https://).'),
                        shortDescription: yup.string().required('Required'),
                        fullDescription: yup.string().required('Required'),
                        month: yup.mixed()
                            .required('Required'),
                        year: yup.number().required('Required'),
                        notifyAreaDirectorIds: yup.array().of(yup.number())
                            .test(
                                'notifyAreaDirectorIds',
                                'Please select at least one Area Director.',
                                function (value) {
                                    if (this.parent.notifyAreaDirectors) return Array.isArray(value) && value.length > 0
                                    return true
                                }
                            ),
                        allowedAccountLevelIds: yup.array().nullable()
                            .test(
                                'allowedAccountLevelIds',
                                'Must select at least one allowed account level.',
                                function (value) {
                                    if (parentState.step1Values.eventTypeId === EventContentType["Group Learning"].toString()) {
                                        if (!value || value.length === 0) return false
                                    }
                                    return true
                                }
                            ),
                    })
            }
            onSubmit={async (values, actions) => {
                const valuesToSave = {
                    ...parentState.step1Values,
                    ...parentState.step2Values,
                    ...values,
                    eventTypeId: parseInt(parentState.step1Values.eventTypeId),
                    eventCategoryId: parseInt(parentState.step1Values.eventCategoryId),
                    eventTopicId: parseInt(parentState.step1Values.eventTopicId),
                    invitationOnly: values.invitationOnly === 'invitation',
                    courseIdList: values.hasCourse ? values.courseIdList.map(cid => parseInt(cid)) : [],
                    allowedAccountLevelIds: values.allowedAccountLevelIds.map(o => parseInt(o))
                }

                let eventContent = eventContentToEdit

                if (eventContentToEdit) {
                    await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventContentEventContentIdPut(eventContentToEdit.eventContentId, valuesToSave),
                        toastSuccessMessage: 'Event Content saved.',
                        toastErrorMessage: 'There was a problem saving the event content.'
                    })
                } else {
                    const { data } = await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventContentPost(valuesToSave),
                        toastSuccessMessage: 'Event Content saved.',
                        toastErrorMessage: 'There was a problem saving the event content.',
                    })
                    eventContent = data
                    setEventContentToEdit(eventContent)
                }

                if (activatePressed.current && eventContent) {
                    await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventContentEventContentIdActivatePut(eventContent.eventContentId),
                        toastSuccessMessage: 'Successfully activated event content.',
                        toastErrorMessage: 'There was an error activating this event content.',
                        onRequestSuccess: async () => eventContent && setEventContentToEdit({ ...eventContent, status: 'activated' })
                    })
                }

                if (values.notifyAreaDirectors && eventContent) {
                    await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventContentEventContentIdSendAreaDirectorNotificationsPost(eventContent.eventContentId, { presenterIds: values.notifyAreaDirectorIds }),
                        toastSuccessMessage: 'Succesfully notified selected Area Directors.',
                        toastErrorMessage: 'There was an error notifying selected Area Directors.',
                    })
                }

                activatePressed.current = false
                actions.setValues({ ...values, notifyAreaDirectors: false, notifyAreaDirectorIds: [] })
            }}
        >
            {formikProps =>
                <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <FormikEffect 
                        formikProps={formikProps} 
                        onChange={async (prev, next) => {
                            if (prev.hasCourse != next.hasCourse) await setShowCourses(next.hasCourse)
                            if (courses) {
                                if (prev.courseIdList != next.courseIdList) {
                                    let urls:ICourseUrlData[] = []
                                    next.courseIdList.forEach(courseId => {
                                        const course = courses.find(c => c.id.toString() === courseId)
                                        if (course) urls.push({courseId: course.id, courseUrl: course.courseUrl!, courseTitle: course.title!})
                                    })
                                    await setCourseUrlData(urls)
                                }
                            }

                            // Currently, we don't support public registration for events that have a seatCost.
                            if (prev.seatCost != next.seatCost) {
                                if (next.seatCost && next.seatCost > 0) {
                                    formikProps.values.bAllowPublicRegistration = false
                                }

                                console.log('prev.seatCost', prev.seatCost)
                                console.log('next.seatCost', next.seatCost)
                                if ((prev.seatCost || 0 < 1) && (next.seatCost || 0 > 0)) 
                                        addAlert({
                                            id: uuidv4(),
                                            title: "Event Payments Notice!",
                                            body: "If this is your first time charging for event seats, please contact Tod Birdsall for an explanation of how it works and some limitations.",
                                        })
                            }
                        }} 
                    />
                    <div>
                        <div style={{ marginBottom: 20 }} className="d-flex">
                            <div style={{ flex: 1 }} className="flex-row d-flex">
                                <div style={{ flex: 1}}>
                                    <div><b>Event Type</b></div>
                                    <div>{EventContentType[parseInt(parentState.step1Values.eventTypeId)]}</div>
                                </div>
                                <div style={{ flex: 1}}>
                                { parentState.step1Values.eventTypeId.toString() === EventContentType["Group Learning"].toString() && 
                                    <>
                                        <div><b>Topic</b></div>
                                        <div>{parentState.groupCoachingTopics.find(o => o.id.toString() === parentState.step1Values.groupCoachingTopicId.toString())?.title}</div>
                                    </>
                                }
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: 20 }}>
                                { isWorkshop && 
                                    <>
                                        <div><b>Category</b></div>
                                        <div>{EventContentCategory[parseInt(parentState.step1Values.eventCategoryId)]}</div>
                                    </>
                                }
                                { parentState.step1Values.eventTypeId.toString() === EventContentType["Group Learning"].toString() && 
                                    <FormikSelectField
                                        multiple
                                        field={{ name: 'allowedAccountLevelIds', label: 'Account Levels' }}
                                        options={availableAccountLevels.map(o => ({ label: AccountLevel[(o as number)], value: o.toString() }))}
                                    />
                                }
                            </div>
                            <div style={{ flex: 1, marginLeft: 20 }}>
                                { isWorkshop && 
                                    <>
                                        <div><b>Topic</b></div>
                                        <div>{EVENT_TOPICS.find(o => o.eventTopicId.toString() === parentState.step1Values.eventTopicId.toString())?.topic}</div>
                                    </>
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: 20 }}>
                                <FormikTextField field={{ name: 'title', label: 'Title', disabled: readonly }} />
                            </div>
                            <div style={{ flex: 1, marginLeft: 20 }}>
                                {parentState.step1Values.eventTypeId.toString() === EventContentType.Webinar.toString() && <FormikTextField field={{ name: 'webinarUrl', label: 'Webinar URL' }} />}
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: 20 }}>
                                <FormikTextField field={{ name: 'subtitle', label: 'Subtitle', disabled: readonly }} />
                            </div>
                            <div style={{ flex: 1, marginLeft: 20, display: 'flex', alignItems: 'center' }}>
                                {(parentState.step1Values.eventTypeId.toString() === EventContentType.Webinar.toString() || parentState.step1Values.eventTypeId.toString() === EventContentType["Group Learning"].toString()) && <CheckboxField disabled={readonly} fieldProps={{ name: 'nationalEvent', label: 'National Event' }} />}
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: 20, display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 20 }}>
                                    {/* 202311202 TB - Converted from "General Month" to "General Quarter". This will make it easier for ADs to make a selection when they don't have to be so specific. */}
                                    <SelectField
                                        disabled={readonly}
                                        fieldProps={{ name: 'month', label: 'General Quarter' }}
                                        options={[
                                            { label: 'Q1', value: '3' },
                                            { label: 'Q2', value: '6' },
                                            { label: 'Q3', value: '9' },
                                            { label: 'Q4', value: '12' },
                                        ]}
                                    />
                                </div>
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                    <SelectField
                                        disabled={readonly}
                                        fieldProps={{ name: 'year', label: 'Year' }}
                                        options={[
                                            { label: dayjs().year().toString(), value: dayjs().year().toString() },
                                            { label: (dayjs().year() + 1).toString(), value: (dayjs().year() + 1).toString() },
                                            { label: (dayjs().year() + 2).toString(), value: (dayjs().year() + 2).toString() },
                                            { label: (dayjs().year() + 3).toString(), value: (dayjs().year() + 3).toString() },
                                            { label: (dayjs().year() + 4).toString(), value: (dayjs().year() + 4).toString() },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div style={{ flex: 1, marginLeft: 20 }}>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: 20 }}>
                                <FormikSelectField
                                        field={{ 
                                            name: 'invitationOnly', 
                                            label: 'Which MI Ministries can register?', 
                                            labelTooltip: "If 'Any MI Ministry', you can control this at the event level. If 'Invitation Only', all events will only be accessible to those whom you send the 'Registration Page URL' to.",
                                            disabled: readonly  }}
                                        options={[
                                            { value: 'any', label: 'Any MI Ministry' },
                                            { value: 'invitation', label: 'By Invitation Only' }
                                        ]}
                                    />
                            </div>
                            <div style={{ flex: 1, marginRight: 20, marginLeft: 20 }}>
                                { formikProps.values.isSeries && <FormikTextField
                                    field={{
                                        name: 'seatCount',
                                        label: 'Seat Count'
                                    }}
                                    labelTooltip="Because this is a series, the seat count is set at the Event Content level."
                                /> }
                            </div>
                            <div style={{ flex: 1, marginRight: 20, marginLeft: 20 }}>
                                { formikProps.values.isSeries && <CurrencyField
                                    fieldProps={{
                                        name: 'seatCost',
                                        label: 'Seat Cost',
                                        labelTooltip: "Because this is a series, the seat cost is set at the Event Content level."
                                    }}
                                /> }
                            </div>
                            <div style={{ flex: 1, marginRight: 20, marginLeft: 20 }}>
                                { formikProps.values.isSeries && <FormikSelectField
                                    field={{
                                        name: 'maxSeatsPerOrg', 
                                        label: 'Max Seats Per Org', 
                                        labelTooltip: 'Would you like to limit the number of seats that a single org can reserve for this event? Because this is a series, the Max Seats Per Org is set at the Event Content level.'}}
                                    options={getMaxSeatsOptions()}
                                 /> }
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <div style={{ flex: 1, marginRight: 20, display: 'flex' }}>
                                {/* {currentUser?.bSuperUser && <CheckboxField disabled={readonly} fieldProps={{ name: 'mifApproved', label: 'MI Approved' }} />} */}

                                {   // Don't allow users to change the 'isSeries' value after the EC record is created. This could lead to bad things happening.
                                    (parentState.step1Values.eventTypeId === EventContentType["Group Learning"].toString()) &&
                                    <CheckboxField 
                                        fieldProps={{ 
                                            name: 'isSeries', 
                                            label: <>This is a <strong>series.</strong></>,
                                            labeltooltip: 'A series is a grouping of multi-part events (e.g., Part 1, Part 2, etc.). Registrants are automatically enrolled for the entire series, not just a single event. Only available to Group Learning events. Cannot be changed.' }}
                                        disabled={!!eventContentToEdit} />
                                }
                            </div>
                            
                        </div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            {currentUser?.bSuperUser &&
                                <div style={{ flex: 1, marginRight: 20 }}>
                                    <CheckboxField disabled={readonly} fieldProps={{ name: 'sendTrainerEvals', label: 'Send Trainer Evals' }} />
                                </div>
                            }

                            <div style={{ flex: 1, marginLeft: 20 }}>
                                <CheckboxField disabled={readonly} 
                                    fieldProps={{ name: 'bAllowPublicRegistration', label: 'Allow Public Registration (Guests)', labeltooltip: 'Allows users with no Ministry Portal account to register for the event.'}} />
                            </div>
                        </div>
                        
                        { // 20230913 TB - Currently, LMS linking is only supported by workshops
                        isWorkshop &&
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 20 }}>
                                    <CheckboxField disabled={readonly} fieldProps={{ name: 'hasCourse', label: 'Has associated LMS course?' }} />
                                </div>
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 20 }}>
                                    { formikProps.values.hasCourse && <SelectField disabled={readonly} 
                                        fieldProps={{ name: 'courseIdList', label: 'Select course(s)' }} 
                                        multiple={true}
                                        options={courses?.map(c => ({ label: c.title || '', value:c.id.toString() }))} /> }
                                </div>
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                    Course URL(s):<br />
                                    { courseUrlData.map(data => <><a href={data.courseUrl} target="blank">{data.courseUrl}</a> ({data.courseTitle})<br /></>) }
                                </div>
                            </div> 
                        </>}

                        <TinyMceField disableContainingPTags disabled={readonly} fieldProps={{ name: 'shortDescription', label: 'Short Description' }} />
                        <TinyMceField disabled={readonly} fieldProps={{ name: 'fullDescription', label: 'Full Description' }} />
                        {isWorkshop ? 
                            <TinyMceField disabled={readonly} fieldProps={{ name: 'privateDetails', label: 'Global Private Details', labeltooltip: "Will display above each event's private details where private details normally are shown. Examples: Registration confirmation email, Reminder email." }} />
                            :
                            null
                        }
                    </div>

                    <div style={{ display: 'flex', flex: 1, margin: 20 }}>
                        {isWorkshop && !currentUser?.bSuperUser ? 
                            <div className="alert alert-warning">Only <strong>Super Users</strong> can save changes to workshop event content records.</div>
                            : 
                            <button type='submit' disabled={readonly} className='btn btn-secondary' style={{ minWidth: 100, marginRight: 10 }}>Save</button>                            
                        }

                        {eventContentToEdit?.status !== 'activated' && currentUser?.bSuperUser &&
                            <button disabled={!formikProps.isValid || readonly} className='btn btn-primary mr-2' style={{ minWidth: 100 }} type='button' onClick={() => showHideConfirmActivateModal(true)}>Activate</button>
                        }
                    </div>

                    <Modal
                        {...confirmActivateModal}
                        modalTitle='Confirm'
                        footer={
                            <>
                                <button
                                    className='btn btn-primary mr-2'
                                    style={{ minWidth: 100 }}
                                    type='button'
                                    onClick={async () => {
                                        activatePressed.current = true

                                        if (formikProps.isValid) {
                                            await formikProps.submitForm()
                                            showHideConfirmActivateModal(false)
                                        }
                                    }}
                                >
                                    Yes, Take it Live
                                </button>
                                <button className='btn btn-secondary' style={{ minWidth: 100, marginRight: 10 }} type='button' onClick={() => showHideConfirmActivateModal(false)} >Cancel</button>
                            </>
                        }
                        onModalHidden={() => {
                            formikProps.setValues({ ...formikProps.values, notifyAreaDirectors: false, notifyAreaDirectorIds: [] })
                        }}
                    >
                        <div style={{ marginBottom: 20 }}>Are you sure you would like to take this event content record live so that Area Directors may begin adding event records to it?</div>
                        <CheckboxField fieldProps={{ name: 'notifyAreaDirectors', label: 'Notify Area Directors via email' }} />
                        {formikProps.values.notifyAreaDirectors && <SelectField multiple fieldProps={{ name: 'notifyAreaDirectorIds', label: 'Which Area Directors would you like to notify?' }} options={presenters.map(o => ({ label: `${o.firstName} ${o.lastName}`, value: o.presenterId.toString() }))} />}
                    </Modal>
                </Form>
            }
        </Formik>
    )
}