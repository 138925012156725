import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalCommunicationsProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalCommunications = (props: IMinistryInfoAreaDirectorEvalCommunicationsProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
        <Formik
            initialValues={{
                bAwareness: areaDirectorEval.ministryEvalState.bAwareness,
                bWeb: areaDirectorEval.ministryEvalState.bWeb,
                bNewsletter: areaDirectorEval.ministryEvalState.bNewsletter,
                bResearch: areaDirectorEval.ministryEvalState.bResearch,
                bCommunication: areaDirectorEval.ministryEvalState.bCommunication,
                communicationsMarketing: areaDirectorEval.ministryEvalState.communicationsMarketing || '',
                tabDirection: null
            }}
            onSubmit={async (values) => {
                const evalRecord: IMinistryEvalsPutDocument = {
                    ...areaDirectorEval.ministryEvalState,
                    bAwareness: values.bAwareness,
                    bWeb: values.bWeb,
                    bNewsletter: values.bNewsletter,
                    bResearch: values.bResearch,
                    bCommunication: values.bCommunication,
                    communicationsMarketing: values.communicationsMarketing,
                    dStep6Updated: moment().format(),
                    step6UpdatedBy: appState.currentUser?.userId || null
                }
                await makeHTTPRequestWithUi({
                    request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
                    toastSuccessMessage: 'Eval record successfully updated.',
                    toastErrorMessage: 'There was an error updating the eval record.',
                })
                
                if (values.tabDirection === 'previous' && afterSavePrevious) {
                    afterSavePrevious()
                } else if (afterSaveNext) {
                    afterSaveNext()
                }
            }}
        >
            {(formikProps) => {
                const form = <React.Fragment>
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bAwareness', 
                            label: 'The marketing materials that this organization uses to raise awareness and/or raise funds for the organization are sufficient to accomplish their purposes' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bWeb', 
                            label: 'The organization has a website that is current and accurately communicates the purpose of the organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bNewsletter', 
                            label: 'This organization has a regular newsletter that creates awareness and a compelling reason to support the program of this organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bResearch', 
                            label: 'The organization has conducted research on the needs of the community it serves' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bCommunication', 
                            label: 'There is an adequate amount of communication and a good working relationship with this organization\'s local community and complementary organizations' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    { !readonly ?
                        <TextareaField
                            fieldProps={{
                                name: 'communicationsMarketing',
                                label: 'Additional Notes on Communications & Marketing'
                            }} />
                        :
                        null
                    }
                    { !readonly ?
                        <div className='row px-3'>
                            <button className={`btn btn-primary`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'previous')
                                formikProps.submitForm()
                            }}>Save &amp; Previous</button>
                            <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'next')
                                formikProps.submitForm()
                            }}>Save &amp; Continue</button>
                        </div>
                        :
                        null
                    }
                </React.Fragment>
                // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                return (
                    readonly ? form :
                    <Form>
                        {form}
                    </Form>
                )
            }}
        </Formik>
    )
}