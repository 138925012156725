import { ICallListDataRecordDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class CallListDataRecordModel {
    public static toGridListItem = (item: ICallListDataRecordDocument): IGridListItem => {
        return {
            id: item.callList2DataId?.toString() || '',
            values: {
                ...item,
                dateOrgRegistered: item.dateOrgRegistered ? new Date(item.dateOrgRegistered) : null,
                lastNoteDate: item.lastNoteDate ? new Date(item.lastNoteDate) : null,
                dateLastEventAttended: item.dateLastEventAttended ? new Date(item.dateLastEventAttended) : null,
            },
        }
    }
} 