import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { isNullOrUndefined } from 'util'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { MalLogModel } from '../models/mail-log'
import { IBodyResultDocument, ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { useHTTPRequestUiWrapper, useMailLogDefaultColumns, useModal } from '../services/hooks'
import { IAppState } from '../stores/app-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { IDefaultProps } from './component-definitions'
import { Grid } from './grid'
import { getOrCreateFilterForColumnPropertyAndOperator } from './grid-column-filter'
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { ReactComponent as AlertTriangleIcon } from '../assets/alert-triangle.svg'
import { ReactComponent as EnvelopeIcon } from '../assets/envelope.svg'
import { Modal } from './modal'
import { Helmet } from 'react-helmet'
import { uuidv4 } from '../services/helpers'
import { BuildingIcon } from '../assets'

interface IMailLog extends IDefaultProps { }

export const MailLog = ({ }: IMailLog) => {
    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
        /* 
            Per https://missionincrease.atlassian.net/browse/MA20-1138 we need to ensure a "startDate" (probably "dSent") filter is applied.
            Otherwise, too many records would be queried and the request will likely time out.

            We short circuit the query here to make sure one is never ran without at least one enabled dSent filter.

            (See useEffect below that listens to filter changes and adds a filter if the user disables/removes values from all dSent filters.)
        */
        const dSentFilter = queryState.filters?.filter(o => o.property === 'dSent' && !!o.value && o.enabled).length
        if (!dSentFilter) return { rows: [], count: 0 }

        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

        try {
            const prospectsQuery = await makeHttpRequestWithUi({
                request: appActions.MailEngineApi.apiMailEngineGet((queryState.page - 1) * queryState.itemsPerPage, queryState.itemsPerPage, sorts, filters),
                disableLoading: true,
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the email log.'
            })

            return {
                rows: prospectsQuery.data.data ? prospectsQuery.data.data.map(MalLogModel.toGridListItem) : [],
                count: prospectsQuery.data.totalCount
            }
        } catch (e) {
            return {
                rows: [],
                count: 0
            }
        }
    }

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [bodyObj, setBodyObj] = useState<IBodyResultDocument>()

    const [viewEmailModal, showHideViewEmailModal] = useModal()
    const [errorLogModal, showHideErrorLogModal] = useModal()
    const [confirmResendModal, showHideConfirmResendModal] = useModal()

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            userSessionStateKey: GridUserInteractionStateKey.MailLog,
            queryState: {
                ...defaultGridState.queryState,
                filters: [
                    {
                        ...getOrCreateFilterForColumnPropertyAndOperator([], 'dSent', 'gt'),
                        isDateFilter: true,
                        enabled: true,
                        value: moment().subtract(1, 'week').format('MM/DD/YYYY')
                    }
                ],
                sorts: [
                    {
                        property: 'dSent',
                        direction: 'DESC'
                    }
                ]
            },
            loading: true,
            columns: useMailLogDefaultColumns(),
            dataSource,
            rowDoubleClicked: async (row) => {
                setSelectedRow(row)
                showHideViewEmailModal(true)
            },
            rowActions: {
                viewEmail: {
                    id: 'viewEmail',
                    action: async (options) => {
                        options.e.stopPropagation()
                        setSelectedRow(options.row)

                        const response = await makeHttpRequestWithUi({
                            request: appActions.MailEngineApi.apiMailEngineMailIdBodyGet(parseInt(options.row.id)),
                            disableLoading: true,
                            disableSuccessToast: true,
                            toastErrorMessage: 'There was a problem fetching the email body.'
                        })

                        setBodyObj(response.data)

                        showHideViewEmailModal(true)
                    },
                    icon: <SearchIcon />,
                    tooltipText: 'View Email',
                    disabled: (row) => !!row.values.hasPassword
                },
                ministryInfo: {
                    id: 'ministryInfo',
                    action: async (options) => options.e.stopPropagation(),
                    icon: <BuildingIcon />,
                    tooltipText: 'View Ministry',
                    hidden: (row) => !row.values.ministryId,
                    url: (row) => `/ministry-info/${row.values.ministryId}`,
                },
                emailError: {
                    id: 'emailError',
                    action: async (options) => {
                        options.e.stopPropagation()
                        setSelectedRow(options.row)
                        showHideErrorLogModal(true)
                    },
                    icon: <AlertTriangleIcon />,
                    tooltipText: 'View Error Log',
                    hidden: (row) => !row.values.errorData
                },
            },
            gridActions: [
                {
                    id: 'resendEmails',
                    label: 'Resend Emails',
                    disabled: (gridState) => gridState.rows.filter(o => o.selected).length <= 0,
                    onClick: () => showHideConfirmResendModal(true),
                },
                {
                    id: 'refreshResults',
                    label: 'Refresh',
                    onClick: (state, actions) => {
                        if (state.lastFetch && moment(state.lastFetch).isAfter(moment().subtract(30, 'seconds'))) {
                            appActions.addAlert({
                                id: uuidv4(),
                                body: <div>
                                    You can only refresh Mail Log results a maximum of once every <b>30 seconds</b>. Please wait <b>{30 - moment().diff(moment(state.lastFetch), 'seconds')} seconds</b> then try again.
                                </div>
                            })
                        } else {
                            actions.doFetch()
                        }
                    },
                }
            ]
        },
        appState
    )

    useEffect(() => {
        // console.log('last fetched', gridState.lastFetch)

        setTimeout(() => {
            // Do something to trigger a render to make sure the "Refresh" button gets enabled
            setSelectedRow(selectedRow)
        }, 30000)

    }, [gridState.lastFetch])

    /* 
        Per https://missionincrease.atlassian.net/browse/MA20-1138 we need to ensure at lesat one "startDate" (probably "dSent") filter is applied.
        Otherwise, too many records would be queried and the request will likely time out.
    */
    // useEffect(() => {
    //     const filters = [...gridState.queryState.filters || []]

    //     const atLeastOneEnabledWithValue = filters.find(o => o.property === 'dSent' && !!o.value && o.enabled)

    //     if (atLeastOneEnabledWithValue) return

    //     const filterWithValue = filters.find(o => o.property === 'dSent' && !!o.value)
    //     if (filterWithValue) {
    //         filterWithValue.enabled = true
    //     } else {
    //         // The user has either disabled or removed values from all filters. Set the default filter.
    //         const ltFilter = filters.find(o => o.property === 'dSent' && o.operator === 'gt')
    //         if (ltFilter) {
    //             ltFilter.enabled = true
    //             ltFilter.value = moment().subtract(1, 'week').format('MM/DD/YYYY')
    //         } else {
    //             // Somehow the default filter was completely removed from the filter list. This shouldn't be possible but we'll cover it just in case.
    //             filters.push({
    //                 ...getOrCreateFilterForColumnPropertyAndOperator([], 'dSent', 'gt'),
    //                 isDateFilter: true,
    //                 enabled: true,
    //                 value: moment().subtract(1, 'week').format('MM/DD/YYYY')
    //             })
    //         }
    //     }

    //     const columns = [...gridState.columns]
    //     const dSentColumn = columns.find(c => c.property === 'dSent')
    //     if (dSentColumn) {
    //         dSentColumn.disableFilters = false
    //         gridActions.setColumns(columns)
    //     }

    //     gridActions.setColumnFilters(filters)

    //     appActions.addAlert({ id: uuidv4(), body: 'A date sent filter is required. Otherwise the mail log query results will be too large.' })
    // }, [gridState])

    return (
        <React.Fragment>
            <Helmet>
                <title>Mail Log</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <EnvelopeIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Mail Log</h3>
                </div>

                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...viewEmailModal}
                modalTitle='Email Body'
                size='xl'
                _onModalHidden={
                    () => {
                        setSelectedRow(undefined)
                        setBodyObj(undefined)
                    }
                }>
                    { 
                        bodyObj ? 
                        <React.Fragment>
                            { 
                                !bodyObj.hasPassword ? 
                                <div dangerouslySetInnerHTML={{ __html: typeof bodyObj.body === 'string' ? bodyObj.body : '' }}></div>
                                :
                                <div>The email body appears to contain a password. Because of this, we cannot display the contents of the body.</div>
                            }
                        </React.Fragment>
                        :
                        null
                    }
            </Modal>

            <Modal
                {...errorLogModal}
                modalTitle='Error Detail'
                size='lg'
                _onModalHidden={() => setSelectedRow(undefined)}>
                <div dangerouslySetInnerHTML={{ __html: typeof selectedRow?.values.errorData === 'string' ? selectedRow?.values.errorData : '' }}></div>
            </Modal>

            <Modal
                {...confirmResendModal}
                modalTitle='Confirm'
                footer={
                    <React.Fragment>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmResendModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (gridState.rows.filter(r => r.selected).length > 0) {
                                    await makeHttpRequestWithUi({
                                        request: appActions.MailEngineApi.apiMailEngineResendPost(gridState.rows.filter(r => r.selected).map(r => parseInt(r.id))),
                                        toastSuccessMessage: 'Successfully resent emails.',
                                        toastErrorMessage: 'Encountered an error resending emails.',
                                    })
                                    showHideConfirmResendModal(false)
                                    gridActions.doFetch()
                                }

                            }}
                        >
                            Resend
                        </button>
                    </React.Fragment>
                }
            >
                Are you sure you want to resend the {gridState.rows.filter(r => r.selected).length} selected email{gridState.rows.filter(r => r.selected).length === 1 ? '' : 's'}?
            </Modal>
        </React.Fragment>
    )
}
