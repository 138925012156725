import { ILeverageSummaryReportDocument } from '../open-api'
import { IGridListItem, IGridColumn } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, CurrencyDisplay, GridActionCell, NteeCodeDisplay } from '../components/grid-cell-displays'

export class LeverageSummary {
	public static toGridListItem = (leverageSummary: ILeverageSummaryReportDocument): IGridListItem => {
		return {
			id: leverageSummary.bmfOrganizationID.toString(),
			values: {
				ministryName: leverageSummary.ministryName?.toLowerCase().replace(/(\b[a-z](?!\s))/g, (word) => word.toUpperCase()) || '',
				nteeCode: leverageSummary.nteeCode,
				baseline: leverageSummary.baseline,
				baseline2: leverageSummary.baseline2,
				baseline2Year: leverageSummary.baseline2Year,
				startYear: leverageSummary.startYear,
				year1: leverageSummary.year1,
				year2: leverageSummary.year2,
				year3: leverageSummary.year3,
				year4: leverageSummary.year4,
				year5: leverageSummary.year5,
				year6: leverageSummary.year6,
				year7: leverageSummary.year7,
				year8: leverageSummary.year8,
				federalTaxNo: leverageSummary.federalTaxNo,
			}
		}
	}
}

export const leverageSummaryColumns: IGridColumn[] = [
	{
		property: 'ministryName',
		type: 'string',
		width: 225,
		allowFilters: true,
		title: 'Ministry Name',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'grid_actions',
		type: 'actions',
		width: 128,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	},
	{
		property: 'nteeCode',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'NTEE Code',
		render: NteeCodeDisplay,
	},
	{
		property: 'baseline2',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'baseline2',
		render: CurrencyDisplay,
	},
	{
		property: 'baseline',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'baseline',
		render: CurrencyDisplay,
	},
	{
		property: 'year1',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year1',
		render: CurrencyDisplay,
	},
	{
		property: 'year2',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year2',
		render: CurrencyDisplay,
	},
	{
		property: 'year3',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year3',
		render: CurrencyDisplay,
	},
	{
		property: 'year4',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year4',
		render: CurrencyDisplay,
	},
	{
		property: 'year5',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year5',
		render: CurrencyDisplay,
	},
	{
		property: 'year6',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year6',
		render: CurrencyDisplay,
	},
	{
		property: 'year7',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year7',
		render: CurrencyDisplay,
	},
	{
		property: 'year8',
		type: 'number',
		width: 110,
		allowFilters: true,
		title: 'year8',
		render: CurrencyDisplay,
	},
]
