import { useEventFormContext } from "./event-form"
import { EventEvals } from "./event-evals"

export const EventFormEvals = () => {

    const { evals } = useEventFormContext()

    return (
        <>
             <EventEvals evals={evals || []} />
        </>
    )
}