import React from 'react'
import { ISeminarEvalSummaryDocument } from '../open-api'
import { IDefaultProps } from './component-definitions'
import { EVENT_EVAL_RATINGS_WORKSHOP } from "../constants"

interface IAttendeeEvalProps extends IDefaultProps { 
    attendeeEval: ISeminarEvalSummaryDocument
    nextEvalHandler?: () => Promise<void>
    previousEvalHandler?: () => Promise<void>
}

export const AttendeeEval = (props: IAttendeeEvalProps) => {
    const { attendeeEval, nextEvalHandler, previousEvalHandler } = props
    return (
        <>
            <div className='d-flex justify-content-between'>
            { previousEvalHandler ?
                <button className='btn btn-secondary' onClick={() => {previousEvalHandler()}}>&lt;&lt;Previous</button>
                :
                null 
            }
            { nextEvalHandler ?
                <button className='btn btn-secondary' onClick={() => {nextEvalHandler()}}>Next &gt;&gt;</button>
                :
                null 
            }
            </div>
            <table style={{width: '100%'}}>
            <tbody>
            <tr>
                <td>
                    <fieldset>
                        <legend style={{fontWeight: 'bold'}}>{attendeeEval.branchAbbr} &gt; {attendeeEval.ministryName} &gt; {attendeeEval.attendeeName}</legend>
                        <h4>General Workshop Feedback</h4>
                        <table style={{width: '100%'}}>
                            <tbody>
                            <tr>
                                <td>
                                <strong>Value of topics discussed</strong>
                                <br />
                                <i>{EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === attendeeEval.value)?.label || ''}</i>
                                </td>
                                <td>
                                    <strong>Scope of content</strong>
                                    <br />
                                    <i>{EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === attendeeEval.content)?.label || ''}</i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Quality of the speakers</strong>
                                    <br />
                                    <i>{EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === attendeeEval.speakers)?.label || ''}</i>
                                </td>
                                <td>
                                    <strong>Facility accommodations:</strong>
                                    <br />
                                    <i>{EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === attendeeEval.facility)?.label || ''}</i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <h4 className='mt-2'>Additional Comments and Feedback</h4>
                        <table>
                            <tbody>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>What part of the content was most useful for you?</td>
                            </tr>
                            <tr>
                                <td><i>{attendeeEval.mostHelpful}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>How could this content have been more helpful?</td>
                            </tr>
                            <tr>
                                <td><i>{attendeeEval.leastHelpful}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>What was your biggest take-away?:</td>
                            </tr>
                            <tr>
                                <td><i>{attendeeEval.changeAfterTraining}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}} colSpan={2}>Additional Comments or Feedback?:</td>
                            </tr>
                            <tr>
                                <td><i>{attendeeEval.comments}</i></td>
                            </tr>
                            <tr><td>&nbsp;</td></tr>
                            </tbody>
                        </table>
                    </fieldset>
                </td>
            </tr>
            </tbody>
        </table>
        </>
    )
}