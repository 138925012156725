import { ISelectFieldOption } from '../components/forms'
import { IEventSiteModelDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'
import { stringIsNumber } from '../services/helpers'

export enum EventType {
	'Consulting' = 6,
	'Webinar' = 5,
	'Small Group Session' = 3,
	'Workshop' = 2,
	'Seminar' = 1,
	'Group Learning' = 7
}

export const eventTypeOptions: ISelectFieldOption[] = Array.from(Object.keys(EventType)).filter(stringIsNumber).map(o => ({ label: `${EventType[parseInt(o)]}`, value: `${o}` }))

export class EventSiteModel {
	public static toGridListItem = (eventSite: IEventSiteModelDocument): IGridListItem => {
		return {
			id: eventSite.siteId.toString(),
			values: {
				...eventSite,
				eventTypes: eventSite.eventTypes?.map(o => ({ display: `${EventType[o]}`, id: o })) || []
			}
		}
	}
}