import React, { useRef, useEffect, useContext } from 'react'
import { GridHeader } from './grid-header'
import { GridTable } from './grid-table'
import { IGridActions } from '../stores/grid-actions'
import { IGridState } from '../stores/grid-definitions'
import { GridFooter } from './grid-footer'
import { AppStateContext } from '../app-store-provider'

/* 
// TODO: sorting
// TODO: resizing columns
TODO: double click on hover icon to change column to width of widest cell in that column
// TODO: filtering (make sure filtering options are powered by an understanding of the data types for each column and range of values they could have)
// TODO: select / select all rows
TODO: accept row onclick handler from parent component
// TODO: single click selects row, double click fires onclick handler
// TODO: review data formatting for each column in original app
// TODO: extend GridListColumn to include data formatting info for the rows
// TODO: switch GridListItem to GridRowItem and extend it to support all the data necessary to support all formatting options from GritListColumn
// TODO: add ability to enable/disable each feature area: page size, show/hide columns, reorder columns, filters, search
TODO: add Column Descriptions button in Show/Hide column view to pop modal with list of all column descriptions
*/

export interface IGridProps {
	state: IGridState
	actions: IGridActions
	style?: React.CSSProperties
	className?: string
}

export const GridContext = React.createContext<{
	actions: IGridActions
	state: IGridState
} | null>(null)
export const Grid = (props: IGridProps) => {
	const appState = useContext(AppStateContext)

	const { state, actions, style, className } = props

	const gridRef = useRef<HTMLDivElement | null>(null)
	useEffect(() => {
		if (gridRef.current && !state.gridElement) actions.setGridElement(gridRef.current)
		//eslint-disable-next-line
	}, [gridRef])

	useEffect(() => {
		actions.doFetch()
		//eslint-disable-next-line
	}, [])

	return (
		<GridContext.Provider value={{ actions, state }}>
			<div className={`grid ${appState.currentUserSessionState?.preferences?.slimTables ? 'slim' : ''} ${className}`} ref={gridRef} style={style}>
				{state.hideGridHeader ? null : <GridHeader />}

				<GridTable />

				{state.hideGridFooter ? null : <GridFooter />}
			</div>
		</GridContext.Provider>
	)
}

