import { IGridListItem } from '../stores/grid-definitions'
import { IProspectSearchResultModelDocument } from '../open-api'

export class Prospect {
	public static toGridListItem = (prospect: IProspectSearchResultModelDocument): IGridListItem => {
		return {
			id: prospect.prospectId.toString(),
			values: {
				organizationName: prospect.organizationName,
				branchAbbr: prospect.branchAbbr,
				trainingAreaAbbr: prospect.trainingAreaAbbr,
				source: prospect.source,
				tags: prospect.tags,
				firstName: prospect.firstName,
				lastName: prospect.lastName,
				monthsSinceLastContact: prospect.monthsSinceLastContact,
				businessPhone: prospect.businessPhone,
				email: prospect.email,
				ageInMonths: prospect.ageInMonths,
				possibleMatchCount: prospect.possibleMatchCount,
				createdByName: prospect.createdByName
			}
		}
	}
}