import React, { useContext, useState, useEffect } from 'react'
import { MinistryInfoContext } from './ministry-info'
import moment from 'moment'
import { accountLevelIdLabel, uuidv4, showModal, hideModal, destroyAllToolTips, buildTooltipProps, enableTooltip, disableTooltip } from '../services/helpers'
import { Modal } from './modal'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { MinistryAccountLevel, MinistryProfileStatus, IMinistryUpdateDocument, MinistryModel } from '../models/ministry'
import { Formik, Form } from 'formik'
import { TextareaField, NumberField, SelectField } from './forms'
import * as Yup from 'yup'
import { TooltipInfo } from './partials'
import { isNullOrUndefined } from 'util'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { MinistryInfoGeneralCharts } from './ministry-info-general-charts'
import { ReactComponent as CardChecklistIcon } from '../assets/card-checklist.svg'
import { ReactComponent as ArrowUpRightIcon } from '../assets/arrow-up-right.svg'
import { UserModel, UserPermissionLevel, App } from '../models/user'
import { MinistryInfoAreaDirectorEval } from './ministry-info-area-director-eval'
import { MinistryInfoGeneralLeverageSummary } from './ministry-info-general-leverage-summary'
import { useModal, useHTTPRequestUiWrapper } from '../services/hooks'
import { IncognitoIcon } from '../assets'
import { MinistryInvolvementScoreCalc } from './ministry-involvement-calc'
import { IS_PRODUCTION, UK_COUNTRY_ID, UNITED_STATES_COUNTRY_ID } from '../constants'
import * as Fathom from 'fathom-client'

export const MinistryInfoGeneral = () => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const ministry = ministryInfoState.ministry!

	const [permissions, setPermissions] = useState<{
		level3: boolean
		level2: boolean
		superUser: boolean
	}>()

	const [modalState, setModalState] = useState<{
		modalId: string
		modalTitle: string
		body: string | JSX.Element
		footer: JSX.Element | null
		onModalHidden?: () => void
		size?: string
	}>({
		modalId: uuidv4(),
		modalTitle: '',
		body: '',
		footer: null
	})

	const [leverageSummaryModalProps, showLeverageSummaryModal] = useModal()
	const [involvementModal, showHideInvolvementModal] = useModal()

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	useEffect(() => {
        if (!appState.activeTrainingAreas) appActions.fetchTrainingAreas()
    }, [])

	const viewAreaDirectorEvalPressed = () => {
		setModalState({
			...modalState,
			modalTitle: `Area Director Eval for ${ministry.ministryName}`,
			size: 'fullscreen',
			body: <MinistryInfoAreaDirectorEval />
		})
		showModal(modalState.modalId)
	}

	const accountLevelSelected = (levelId: MinistryAccountLevel) => {
		if (ministry.levelID !== levelId) {

			let modalTitle = modalState.modalTitle
			let body: string | JSX.Element = <span>Are you sure you want to move this ministry from <b>{accountLevelIdLabel(ministry.levelID || 0)}</b> to <b>{accountLevelIdLabel(levelId)}</b>?{levelId === 3 ? ' This will send the ministry a confirmation email.' : null}</span>
			let disabled = false

			if (levelId === MinistryAccountLevel.Consulting) {
				if (ministry.bAllowChurch) {
					disabled = true
					body = `We're sorry, but this ministry cannot be moved to the Consulting level because they are a church.`
					modalTitle = 'Status'
				} else if (MinistryModel.isInternational(ministry)) {
					body = 'Are you sure you want to move this ministry to Consulting?'
				} else if (ministry.bDeniedForGrants) {
					disabled = true
					modalTitle = 'Status'
					body = `We're sorry, but this ministry cannot be moved to the Consulting level at this time. Please contact tech support for more information.`
				} else if (ministry.profileStatus === MinistryProfileStatus.OK) {
					body = 'Are you sure you want to move this ministry to Consulting? If they are marked as approved at their current level we will mark them as unapproved at the Consulting level.'
				} else if (permissions?.superUser) {
					body = (
						<span>
							WARNING! This ministry does not have a complete/approved profile.
							<br /><br />
							Since you are a Superuser you can still move them to consulting. Are you sure you want to move this ministry to Consulting? If they are marked as approved at their current level we will mark them as unapproved at the Consulting level.
						</span>
					)
				} else {
					disabled = true
					modalTitle = 'Status'
					body = `We're sorry, but this ministry must have a completed and approved account to move to the consulting level.`
				}
			}

			const footer = (
				<React.Fragment>
					<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
					<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
						hideModal(modalState.modalId)
						ministryInfoActions.setLoading(true)
						await appActions.changeAccountLevel(ministry.ministryId, levelId)
						await ministryInfoActions.fetchMinistrySummary()
						ministryInfoActions.setLoading(false)
					}}>Confirm</button>
				</React.Fragment>
			)

			setModalState({
				...modalState,
				modalTitle,
				body,
				footer,
			})
			showModal(modalState.modalId)
		}
	}

	const archiveSelected = () => {
		let body: JSX.Element
		let footer: JSX.Element

		let submitStartYearForm: () => void
		let resetArchiveForm: () => void

		let onModalHidden: (() => void) | undefined = undefined

		if (!ministry.bArchived) {
			body = (
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<Formik
								initialValues={{
									archiveReason: '',
								}}
								validationSchema={Yup.object({
									archiveReason: Yup.string()
										.required('Required'),
								})}
								validateOnMount
								onSubmit={async (values, formikActions) => {
									await makeHttpRequestWithUi({ request: appActions.archiveMinistry(ministry.ministryId, values.archiveReason), toastSuccessMessage: 'Successfully archived the ministry.' })
									ministryInfoActions.fetchMinistrySummary()
									hideModal(modalState.modalId)
								}}
							>
								{formikProps => {
									resetArchiveForm = formikProps.resetForm
									submitStartYearForm = formikProps.submitForm

									return (
										<Form>
											<TextareaField fieldProps={{ name: 'archiveReason', label: 'Why do you want to archive this ministry?' }} />
										</Form>
									)
								}}
							</Formik>
						</div>
					</div>
				</div>
			)

			footer = (
				<React.Fragment>
					<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
					<button type='button' className='btn btn-danger' onClick={() => submitStartYearForm ? submitStartYearForm() : null}>Archive</button>
				</React.Fragment>
			)

			onModalHidden = () => {
				if (resetArchiveForm) resetArchiveForm()
			}

		} else {
			body = <div>Are you sure you want to mark this ministry as active?</div>

			footer = (
				<React.Fragment>
					<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
					<button type='button' className='btn btn-warning text-white' onClick={async () => {
						await makeHttpRequestWithUi({ request: appActions.unarchiveMinistry(ministry.ministryId), toastSuccessMessage: 'Successfully unarchived ministry.' })
						ministryInfoActions.fetchMinistrySummary()
						hideModal(modalState.modalId)
					}}>Unarchive</button>
				</React.Fragment>
			)
		}

		setModalState({
			...modalState,
			modalTitle: 'Confirm',
			body,
			footer,
			onModalHidden,
		})

		showModal(modalState.modalId)
	}

	const startYearSelected = () => {
		let body: JSX.Element
		let footer: JSX.Element

		let submitStartYearForm: () => void
		let resetStartYearForm: () => void

		let onModalHidden: (() => void) | undefined = undefined

		body = (
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-12'>
						<Formik
							enableReinitialize // This must be set or previous startYear value will display after a change is made.
							initialValues={{
								startYear: ministry.startYear,
							}}
							validationSchema={Yup.object({
								startYear: Yup.number()
									.max(3000)
									.required('Required'),
							})}
							validateOnMount
							onSubmit={async (values, formikActions) => {
								if (!isNullOrUndefined(values.startYear)) {
									ministryInfoActions.setLoading(true)
									await appActions.updateMinistry(ministry.ministryId, { startYear: values.startYear })
									await ministryInfoActions.fetchMinistrySummary()
									ministryInfoActions.setLoading(false)
								}

								hideModal(modalState.modalId)
							}}
						>
							{formikProps => {
								resetStartYearForm = formikProps.resetForm
								submitStartYearForm = formikProps.submitForm

								return (
									<Form>
										<NumberField fieldProps={{ name: 'startYear', label: 'Start Year' }} noFormat={true} />
									</Form>
								)
							}}
						</Formik>
					</div>
				</div>
			</div>
		)

		footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' onClick={() => submitStartYearForm ? submitStartYearForm() : null}>Update</button>
			</React.Fragment>
		)

		onModalHidden = () => {
			if (resetStartYearForm) resetStartYearForm()
		}

		setModalState({
			...modalState,
			modalTitle: 'Update Start Year',
			body,
			footer,
			onModalHidden,
		})

		showModal(modalState.modalId)
	}

	const [editTaxYearModalState] = useState({ modalId: uuidv4() })

	let submitEditTaxYearForm: (() => void) | null = null
	let resetEditTaxYearForm: (() => void) | null = null

	const editTaxYearModal = (
		<Modal
			modalId={editTaxYearModalState.modalId}
			modalTitle='Update Fiscal Year'
			footer={
				<React.Fragment>
					<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
					<button type='button' className='btn btn-primary' onClick={() => submitEditTaxYearForm ? submitEditTaxYearForm() : null}>Update</button>
				</React.Fragment>
			}
			onModalHidden={() => { if (resetEditTaxYearForm) resetEditTaxYearForm() }}
		>
			{
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12'>
							<Formik
								initialValues={{
									financialYearEndID: ministry.financialYearEndID,
								}}
								validationSchema={Yup.object({
									financialYearEndID: Yup.number().required('Required'),
								})}
								validateOnMount
								onSubmit={async (values, formikActions) => {
									if (!isNullOrUndefined(values.financialYearEndID)) {
										ministryInfoActions.setLoading(true)
										await appActions.updateMinistry(ministry.ministryId, { financialYearEndID: values.financialYearEndID })
										await ministryInfoActions.fetchMinistrySummary()
										ministryInfoActions.setLoading(false)
										if (resetEditTaxYearForm) resetEditTaxYearForm()
										hideModal(editTaxYearModalState.modalId)
									}

									hideModal(modalState.modalId)
								}}
							>
								{formikProps => {
									resetEditTaxYearForm = formikProps.resetForm
									submitEditTaxYearForm = formikProps.submitForm

									return (
										<Form>
											<SelectField
												fieldProps={{ label: 'Fiscal Year Ends', name: 'financialYearEndID', placeholder: 'Please select a value' }}
												options={[
													{ label: 'January', value: '1' },
													{ label: 'February', value: '2' },
													{ label: 'March', value: '3' },
													{ label: 'April', value: '4' },
													{ label: 'May', value: '5' },
													{ label: 'June', value: '6' },
													{ label: 'July', value: '7' },
													{ label: 'August', value: '8' },
													{ label: 'September', value: '9' },
													{ label: 'October', value: '10' },
													{ label: 'November', value: '11' },
													{ label: 'December', value: '12' },
												]}
											/>
										</Form>
									)
								}}
							</Formik>
						</div>
					</div>
				</div>
			}
		</Modal>
	)

	const doNotShowInReportsSelected = () => {
		let modalTitle = 'Confirm'
		let body: string = ''
		let disabled = false

		if (ministry.bNoReporting && ministry.bAllowChurch) {
			body = 'This ministry is marked as a church and cannot be shown in reports.'
			disabled = true
		} else {
			body = `Are you sure you want to ${ministry.bNoReporting ? 'show' : 'hide'} this ministry in reports?`
		}

		const footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
					ministryInfoActions.setLoading(true)
					await appActions.updateMinistry(ministry.ministryId, { bNoReporting: !ministry.bNoReporting })
					hideModal(modalState.modalId)
					await ministryInfoActions.fetchMinistrySummary()
					ministryInfoActions.setLoading(false)
				}}>Confirm</button>
			</React.Fragment>
		)

		setModalState({
			...modalState,
			modalTitle,
			body,
			footer,
		})
		showModal(modalState.modalId)
	}

	const doNotShowInLeverageSummarySelected = () => {
		let modalTitle = 'Confirm'
		let body: string = ''
		let disabled = false

		if (ministry.bHideLeverageSummary) {
			body = 'Are you sure you want to show this ministry in the Leverage Summary?'
		} else {
			body = `Are you sure you want to hide this ministry from the Leverage Summary?`
		}

		const footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
					ministryInfoActions.setLoading(true)
					await appActions.updateMinistry(ministry.ministryId, { bHideLeverageSummary: !ministry.bHideLeverageSummary })
					hideModal(modalState.modalId)
					await ministryInfoActions.fetchMinistrySummary()
					ministryInfoActions.setLoading(false)
				}}>Confirm</button>
			</React.Fragment>
		)

		setModalState({
			...modalState,
			modalTitle,
			body,
			footer,
		})
		showModal(modalState.modalId)
	}

	const preferGuideStarDataSelected = () => {
		let modalTitle = 'Confirm'
		let body: string = ''
		let disabled = false

		if (ministry.bReportMIIncome) {
			body = 'Are you sure you want to prefer GS data in reports?'
		} else {
			body = `Are you sure you want to prefer ministry-entered data in reports?`
		}

		const footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
					ministryInfoActions.setLoading(true)
					await appActions.toggleMinistryBReportMiIncome(ministry.ministryId)
					hideModal(modalState.modalId)
					await ministryInfoActions.fetchMinistrySummary()
					ministryInfoActions.setLoading(false)
				}}>Confirm</button>
			</React.Fragment>
		)

		setModalState({
			...modalState,
			modalTitle,
			body,
			footer,
		})
		showModal(modalState.modalId)
	}

	const deniedForGrantsSelected = () => {
		let modalTitle = 'Confirm'
		let body: string = ''
		let disabled = false

		if (ministry.bDeniedForGrants) {
			body = 'Are you sure you want to make this ministry grant eligible?'
		} else {
			body = `Are you sure you want to mark this ministry as denied for grants?`
		}

		const footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
					ministryInfoActions.setLoading(true)
					await appActions.updateMinistry(ministry.ministryId, { bDeniedForGrants: !ministry.bDeniedForGrants })
					hideModal(modalState.modalId)
					await ministryInfoActions.fetchMinistrySummary()
					ministryInfoActions.setLoading(false)
				}}>Confirm</button>
			</React.Fragment>
		)

		setModalState({
			...modalState,
			modalTitle,
			body,
			footer,
		})
		showModal(modalState.modalId)
	}

	const sensitiveMinistrySelected = () => {
		let modalTitle = 'Confirm'
		let body: string = ''
		let disabled = false

		if (ministry.bSensitiveMinistry) {
			body = 'Are you sure you want to make this ministry non-sensitive? They will appear on public reports.'
		} else {
			body = `Are you sure you want to mark this ministry as sensitive? They will not appear on public reports.`
		}

		const footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
					ministryInfoActions.setLoading(true)
					await appActions.updateMinistry(ministry.ministryId, { bSensitiveMinistry: !ministry.bSensitiveMinistry })
					hideModal(modalState.modalId)
					await ministryInfoActions.fetchMinistrySummary()
					ministryInfoActions.setLoading(false)
				}}>Confirm</button>
			</React.Fragment>
		)

		setModalState({
			...modalState,
			modalTitle,
			body,
			footer,
		})
		showModal(modalState.modalId)
	}

	const churchSelected = () => {
		let modalTitle = 'Confirm'
		let body: string = ''
		let disabled = false

		if (ministry.levelID === MinistryAccountLevel.Consulting) {
			disabled = true
			body = 'This ministry cannot be flagged as a church because they are at the consulting level.'
		} else if (ministry.bAllowChurch) {
			body = 'Are you sure you want to mark this ministry as not a church?'
		} else {
			body = 'Are you sure you want to mark this ministry as a church and allow them to move to the coaching level?'
		}

		const footer = (
			<React.Fragment>
				<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
				<button type='button' className='btn btn-primary' disabled={disabled} onClick={async () => {
					ministryInfoActions.setLoading(true)
					await appActions.updateMinistry(ministry.ministryId, { bAllowChurch: !ministry.bAllowChurch })
					hideModal(modalState.modalId)
					await ministryInfoActions.fetchMinistrySummary()
					ministryInfoActions.setLoading(false)
				}}>Confirm</button>
			</React.Fragment>
		)

		setModalState({
			...modalState,
			modalTitle,
			body,
			footer,
		})
		showModal(modalState.modalId)
	}

	useEffect(() => {
		if (permissions?.level3) {
			disableTooltip('chapter-dropdown-container')
			disableTooltip('secondary-chapter-dropdown-container')
		} else {
			enableTooltip('chapter-dropdown-container')
			enableTooltip('secondary-chapter-dropdown-container')
		}

		if (permissions?.level2) {
			disableTooltip('training-area-dropdown-container')
		} else {
			enableTooltip('training-area-dropdown-container')
		}

		if (permissions?.superUser) {
			disableTooltip('denied-for-grants-container')
			disableTooltip('hide-from-reports-container')
		} else {
			enableTooltip('denied-for-grants-container')
			enableTooltip('hide-from-reports-container')
		}

		//eslint-disable-next-line
	}, [permissions])

	useEffect(() => {
		setPermissions({
			level2: UserModel.checkPermissionLevelForUser(App.MinistryManager, UserPermissionLevel.Modify, appState.currentUser),
			level3: UserModel.checkPermissionLevelForUser(App.MinistryManager, UserPermissionLevel.Administrator, appState.currentUser),
			superUser: UserModel.userIsSuperUser(appState.currentUser)
		})

		return function cleanup() {
			destroyAllToolTips()
		}
		//eslint-disable-next-line
	}, [])

	// Grant Requests may send someone here to edit the AD Eval.
	// If that is the case, pop open the AD Eval modal.
	useEffect(() => {
		const hash = window.location.hash.replace('#', '')
		if (hash === 'adeval') viewAreaDirectorEvalPressed()
	}, [])

	return (
		<React.Fragment>
			<div className='container-fluid'>
				<div className='row'>

					<div className='col-lg-6 col-md-12 pb-2'>
						<h5 className='sticky-top bg-white py-2'>Basic Info</h5>

						<div className='value-with-label'>
							<div className='label'>Main Contact</div>
							<div className='value'>
								<a href={`mailto:${ministry.primaryContactEmail}`}>{ministry.firstName} {ministry.lastName}</a>
							</div>
						</div>
						<div className='value-with-label'>
							<div className='label'>Contact Email</div>
							<div className='value'><a href={`mailto:${ministry.primaryContactEmail}`}>{ministry.primaryContactEmail}</a></div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Contact Phone</div>
							<div className='value'>{ministry.businessPhone}</div>
						</div>

						{ ministry.physicalCountryId === UNITED_STATES_COUNTRY_ID &&
						<div className='value-with-label'>
							<div className='label'>Legal Name</div>
							<div className='value'>{ministry.legalName}</div>
						</div> }

						{ ministry.physicalCountryId === UK_COUNTRY_ID &&
						<>
						{ ministry.ukCcewId && <div className='value-with-label'>
							<div className='label'>England and Wales ID</div>
							<div className='value'>{ministry.ukCcewId}</div>
						</div> }
						{ ministry.ukCcniId && <div className='value-with-label'>
							<div className='label'>Northern Ireland ID</div>
							<div className='value'>{ministry.ukCcniId}</div>
						</div> }
						{ ministry.ukOscrId && <div className='value-with-label'>
							<div className='label'>Scottish ID</div>
							<div className='value'>{ministry.ukOscrId}</div>
						</div> }
						</>
						}

						<div className='value-with-label'>
							<div className='label'>Physical Address</div>
							<div className='value'>
								{ministry.physicalAddress1}<br />
								{ministry.physicalAddress2 ? <React.Fragment>{ministry.physicalAddress2} <br /></React.Fragment> : null}
								{ministry.physicalCity}, {ministry.physicalState} {ministry.physicalPostalCode}<br />
								{ministry.physicalCountry}
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Mailing Address</div>
							<div className='value'>
								{ministry.mailingAddress1}<br />
								{ministry.mailingAddress2 ? <React.Fragment>{ministry.mailingAddress2} <br /></React.Fragment> : null}
								{ministry.mailingCity}, {ministry.mailingState} {ministry.mailingPostalCode}<br />
								{ministry.mailingCountry}
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Primary Phone</div>
							<div className='value'>{ministry.phone1} {ministry.phone1Ext ? `(${ministry.phone1Ext})` : null}</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Website</div>
							{/* TB 20220912 - Added the replace() calls because some siteUrl values included https and http and that was breaking the hyperlink. */}
							<div className='value'><a href={`//${ministry.siteUrl?.replace('https://', '').replace('http://', '')}`} target='_blank' rel='noopener noreferrer'>{ministry.siteUrl}</a></div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Date Registered</div>
							<div className='value'>{moment(ministry.dRegistered).format('MM/DD/YYYY')}</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Involvement (lifetime)</div>
							<div className='value'><a href='#' onClick={() => showHideInvolvementModal(true)}>{ministry.involvement}</a> (Ranked {ministry.lifetimeInvolvmentRanked} of {ministry.lifetimeInvolvementTotal}) <TooltipInfo tooltipText='Ranking is based on the involvement scores for all ministries at the same level (training, consulting, or granting). If multiple ministries have the same involvment score they also have the same ranking.' /></div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Involvement (12 mos.)</div>
							<div className='value'>{ministry.involvementRolling12Mo} (Ranked {ministry.twelveMosInvolvmentRanked} of {ministry.twelveMosInvolvmentTotal}) <TooltipInfo tooltipText='Ranking is based on the involvement scores for all ministries at the same level (training, consulting, or granting). If multiple ministries have the same involvment score they also have the same ranking.' /></div>
						</div>

						<div>
							<a type='button' className={`btn btn-secondary btn-sm icon-right ${ministry.impersonationUrl ? '' : 'disabled'}`} rel='noopener noreferrer' target='_blank' href={ministry.impersonationUrl || ''}>Impersonate User <IncognitoIcon /></a>
							<button type='button' className='btn btn-secondary btn-sm icon-right ml-1' onClick={viewAreaDirectorEvalPressed}>Area Director Eval <CardChecklistIcon /></button>
							<button type='button' className='btn btn-secondary btn-sm icon-right ml-1' onClick={() => { 
								IS_PRODUCTION && Fathom.trackEvent('showLeverageSummaryModal')
								showLeverageSummaryModal(true)
							}}>Leverage Summary <ArrowUpRightIcon /></button>
						</div>
					</div>

					<div className='col-lg-6 col-md-12 pb-2'>
						<h5 className='sticky-top bg-white py-2'>Settings</h5>

						<div className='value-with-label'>
							<div className='label'>Account Level</div>
							<div className='value'>
								<div className='dropdown'>
									<button className='btn btn-secondary dropdown-toggle btn-sm' type='button' id='account-level-dropdown' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
										{accountLevelIdLabel(ministry.levelID || 0)}
									</button>
									<div className='dropdown-menu' aria-labelledby='account-level-dropdown'>
										<button className='dropdown-item' onClick={() => accountLevelSelected(MinistryAccountLevel.Teaching)} disabled={ministry.levelID === MinistryAccountLevel.Teaching} >Teaching</button>
										<button className='dropdown-item' onClick={() => accountLevelSelected(MinistryAccountLevel.Coaching)} disabled={ministry.levelID === MinistryAccountLevel.Coaching} >Coaching</button>
										<button className='dropdown-item' onClick={() => accountLevelSelected(MinistryAccountLevel.Consulting)} disabled={ministry.levelID === MinistryAccountLevel.Consulting} >Consulting</button>
									</div>
								</div>
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Community&nbsp;
							{!permissions?.superUser && <TooltipInfo tooltipText='If you want to change the community, please contact support@missionincrease.org' /> }
							</div>
							<div className='value'>
								<div id='chapter-dropdown-container' className='dropdown'	{...permissions?.superUser ? {} : buildTooltipProps({ tooltipText: 'You do not have sufficient permissions to perform this action.' })}>
									<button
										className='btn btn-secondary dropdown-toggle btn-sm'
										type='button'
										id='chapter-dropdown'
										data-toggle='dropdown'
										aria-haspopup='true'
										aria-expanded='false'
										disabled={!permissions?.superUser}
									>
										{ministry.branchAbbr} ({appState.activeBranches.find(branch => branch.branchAbbr === ministry.branchAbbr)?.branchName})
							</button>
									<div className='dropdown-menu' aria-labelledby='chapter-dropdown' style={{ maxHeight: '200px', overflow: 'auto' }}>
										{appState.activeBranches.filter(branch => {
											const secondaryCommunity = appState.activeBranches.find(b => b.branchAbbr === ministry.localBranchAbbr)
											if (secondaryCommunity) {
												return secondaryCommunity.isCauseSpecific !== branch.isCauseSpecific
											}

											return true
										}).map(branch => (
											<button
												key={branch.branchId}
												className='dropdown-item'
												disabled={ministry.branchAbbr === branch.branchAbbr}
												onClick={async () => {
													ministryInfoActions.setLoading(true)

													const update: IMinistryUpdateDocument = {
														branchId: branch.branchId
													}

													const trainingArea = appState.trainingAreas?.find(trainingArea => trainingArea.branchId === branch.branchId)
													if (trainingArea) update.trainingAreaId = trainingArea.trainingAreaId

													await appActions.updateMinistry(ministry.ministryId, update)
													await ministryInfoActions.fetchMinistrySummary()
													ministryInfoActions.setLoading(false)
												}}
											>
												{branch.branchAbbr} ({branch.branchName})
											</button>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Secondary Community&nbsp;
							{!permissions?.superUser && <TooltipInfo tooltipText='If you want to change the community, please contact support@missionincrease.org' /> }</div>
							<div className='value'>
								<div id='secondary-chapter-dropdown-container' className='dropdown'	{...!permissions?.superUser ? {} : buildTooltipProps({ tooltipText: 'You do not have sufficient permissions to perform this action.' })}>
									<button
										className='btn btn-secondary dropdown-toggle btn-sm'
										type='button'
										id='chapter-dropdown'
										data-toggle='dropdown'
										aria-haspopup='true'
										aria-expanded='false'
										disabled={!permissions?.superUser}
									>
										{ministry.localBranchAbbr ? `${ministry.localBranchAbbr} (${appState.activeBranches.find(branch => branch.branchAbbr === ministry.localBranchAbbr)?.branchName})` : 'Select a Community'}
									</button>
									<div className='dropdown-menu' aria-labelledby='chapter-dropdown' style={{ maxHeight: '200px', overflow: 'auto' }}>
										<button
											className='dropdown-item'
											onClick={async () => {
												ministryInfoActions.setLoading(true)

												const update: IMinistryUpdateDocument = {
													localBranchId: null
												}

												await appActions.updateMinistry(ministry.ministryId, update)
												await ministryInfoActions.fetchMinistrySummary()
												ministryInfoActions.setLoading(false)
											}}
										>
											None
										</button>
										{appState.activeBranches.map(branch => (
											<button
												key={branch.branchId}
												className='dropdown-item'
												disabled={ministry.branchAbbr === branch.branchAbbr}
												onClick={async () => {
													ministryInfoActions.setLoading(true)

													const update: IMinistryUpdateDocument = {
														localBranchId: branch.branchId
													}

													await appActions.updateMinistry(ministry.ministryId, update)
													await ministryInfoActions.fetchMinistrySummary()
													ministryInfoActions.setLoading(false)
												}}
											>
												{branch.branchAbbr} ({branch.branchName})
											</button>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Training Area</div>
							<div className='value'>
								<div id='training-area-dropdown-container' className='dropdown'	{...permissions?.level2 ? {} : buildTooltipProps({ tooltipText: 'You do not have sufficient permissions to perform this action.' })}>
									<button
										className='btn btn-secondary dropdown-toggle btn-sm'
										type='button'
										id='training-area-dropdown'
										data-toggle='dropdown'
										aria-haspopup='true'
										aria-expanded='false'
										disabled={!permissions?.level2}
									>
										{ministry.trainingAreaAbbr ? `${ministry.trainingAreaAbbr} (${appState.activeTrainingAreas?.find(trainingArea => trainingArea.trainingAreaAbbr === ministry.trainingAreaAbbr)?.trainingArea})` : 'Select Training Area'}
									</button>
									<div className='dropdown-menu' aria-labelledby='training-area-dropdown' style={{ maxHeight: '200px', overflow: 'auto' }}>
										{appState.activeTrainingAreas?.filter(trainingArea => trainingArea.branchId === appState.activeBranches.find(branch => branch.branchAbbr === ministry.branchAbbr)?.branchId).map(trainingArea => (
											<button
												key={trainingArea.trainingAreaId}
												className='dropdown-item'
												disabled={ministry.trainingAreaAbbr === trainingArea.trainingAreaAbbr}
												onClick={async () => {
													ministryInfoActions.setLoading(true)
													await appActions.updateMinistry(ministry.ministryId, { trainingAreaId: trainingArea.trainingAreaId })
													await ministryInfoActions.fetchMinistrySummary()
													ministryInfoActions.setLoading(false)
												}}
											>
												{trainingArea.trainingAreaAbbr} ({trainingArea.trainingArea})
											</button>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Misc. Settings</div>
							<div className='value container'>
								<div className='row mb-1'>
									<button type='button' className={`btn btn-secondary btn-sm`} onClick={archiveSelected}>{ministry.bArchived ? 'Unarchive' : 'Archive'}</button>
								</div>

								<div className='row mb-1'>
									<div id='hide-from-reports-container' className='custom-control custom-checkbox' {...permissions?.superUser ? {} : buildTooltipProps({ tooltipText: 'You do not have sufficient permissions to perform this action.' })}>
										<input disabled={!permissions?.superUser} checked={!!ministry.bNoReporting} onChange={doNotShowInReportsSelected} type='checkbox' className='custom-control-input' id='hide-from-reports' />
										<label className='custom-control-label' htmlFor='hide-from-reports'>Don't show in any reports</label>
									</div>
								</div>

								{/* 20230316 TB - This was only visible to super users. Steve requested we make it visible to ADs in order to allow them to clean up their
								income numbers on the Dashboard Report. */}
								<div className='row mb-1'>
									<div className='custom-control custom-checkbox'>
										<input checked={!!ministry.bHideLeverageSummary} onChange={doNotShowInLeverageSummarySelected} type='checkbox' className='custom-control-input' id='hide-in-leverage-summary' />
										<label className='custom-control-label' htmlFor='hide-in-leverage-summary'>Don't show in aggregate reports <TooltipInfo className='icon-margin-bottom' tooltipText='Removes this ministry from the Leverage Summary Report and from the Outcomes Section of the Quarterly Dashboard Report.' /></label>
									</div>
								</div>

								{/* 20230316 TB - This was only visible to super users. Steve requested we make it visible to ADs in order to allow them to clean up their
								income numbers on the Dashboard Report. */}
								<div className='row mb-1'>
									<div className='custom-control custom-checkbox'>
										<input checked={!ministry.bReportMIIncome} onChange={preferGuideStarDataSelected} type='checkbox' className='custom-control-input' id='prefer-gs-data' />
										<label className='custom-control-label' htmlFor='prefer-gs-data'>Prefer GS Data in Reports <TooltipInfo className='icon-margin-bottom' tooltipText='If checked, and both Guidestar and ministry-reported Income are available (see FY Data tab), the GuideStar data will be used in aggregate reports, such as the Dashboard Ourcomes section, and the annual Leverage Summary. In general, ministry-entered data is preferred, but sometimes Guidestar may be more current or accurate.' /></label>
									</div>
								</div>
								
								<div className='row mb-1'>
									<div id='denied-for-grants-container' className='custom-control custom-checkbox' {...permissions?.superUser ? {} : buildTooltipProps({ tooltipText: 'You do not have sufficient permissions to perform this action.' })}>
										<input disabled={!permissions?.superUser} checked={!!ministry.bDeniedForGrants} onChange={deniedForGrantsSelected} type='checkbox' className='custom-control-input' id='denied-for-grants' />
										<label className='custom-control-label' htmlFor='denied-for-grants'>Denied for Grants</label>
									</div>
								</div>

								<div className='row mb-1'>
									<div className='custom-control custom-checkbox'>
										<input checked={!!ministry.bSensitiveMinistry} onChange={sensitiveMinistrySelected} type='checkbox' className='custom-control-input' id='sensitive-ministry' />
										<label className='custom-control-label' htmlFor='sensitive-ministry'>Sensitive Ministry</label>
									</div>
								</div>

								<div className='row mb-1'>
									<div className='custom-control custom-checkbox'>
										<input checked={!!ministry.bAllowChurch} onChange={churchSelected} type='checkbox' className='custom-control-input' id='church' />
										<label className='custom-control-label' htmlFor='church'>Church <TooltipInfo className='icon-margin-bottom' tooltipText='Check this box if this ministry is a church. This will allow them to move to the coaching level with a temporary taxID.' /></label>
									</div>
								</div>
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Start Year</div>
							<div className='value' style={{ display: 'flex', alignItems: 'center' }}>
								{ministry.startYear}
								<PencilIcon className='icon' style={{ cursor: 'pointer', marginLeft: '5px' }} data-tooltip='edit' onClick={startYearSelected} />
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>IRS Ruling Year&nbsp;<TooltipInfo className='icon-margin-bottom' 
									tooltipText='If a valid U.S. Federal Tax ID is on file, IRS Ruling Year is filled in automatically via GuideStar data pulls that automatically occur at regular intervals. You may force this update by clicking the Refresh GS Data button under the FY Data tab.' /></div>
							<div className='value'>{ministry.rulingYear}</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Current Tax Year</div>
							<div className='value'>{ministry.currentFY}</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Tax Year Ends&nbsp;
								<TooltipInfo className='icon-margin-bottom' 
									tooltipText='The fiscal year always begins the 1st of the month following the chosen month and ends the last day of the chosen month. This affects when FY Data can be entered for the previous year. Example: For the 2021 tax year, if their year end is Nov they will not be able to enter their data until AFTER Nov 30, 2022' /></div>
							<div className='value'>
								{ministry.financialYearEndID !== null ? moment(ministry.financialYearEndID, "M").format('MMMM') : null}
								{UserModel.userIsSuperUser(appState.currentUser) ?
									<PencilIcon className='icon' style={{ cursor: 'pointer', marginLeft: '5px' }} data-tooltip='edit' onClick={() => showModal(editTaxYearModalState.modalId)} />
									:
									null
								}
							</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Profile Status</div>
							<div className='value'>{ministry.profileStatus}</div>
						</div>

						<div className='value-with-label'>
							<div className='label'>Last Login</div>
							<div className='value'>{ministry.lastLogin}</div>
						</div>

						{/* 
						20240328 TB - This data is currently not accurate. We can display it again in the future, when it is fixed.
									No hurry, as I am unsure how useful this is.
						<div className='value-with-label'>
							<div className='label'>Last Impersonation</div>
							<div className='value'>{ministry.lastOverride}</div>
						</div> */}

						<div className='value-with-label'>
							<div className='label'>Last Account Update</div>
							<div className='value'>{ministry.lastUpdate}</div>
						</div>

					</div>

					<div className='col-lg-6 col-md-12 pb-2'>
						<MinistryInfoGeneralCharts />

					</div>
				</div>
			</div>
			<Modal
				modalId={modalState.modalId}
				modalTitle={modalState.modalTitle}
				footer={modalState.footer}
				onModalHidden={modalState.onModalHidden}
				//@ts-ignore
				size={modalState.size}>
				{modalState.body}
			</Modal>
			{editTaxYearModal}

			<Modal
				{...leverageSummaryModalProps}
				modalTitle={`Ministry Analysis - ${ministryInfoState.ministry?.ministryName}`}
				size='fullscreen'
				minHeightPercent={80}
				_onModalShown={() => {
					if (!ministryInfoState.leverageSummary) ministryInfoActions.fetchLeverageSummary(moment().subtract(8, 'y').get('y'))
				}}
			>
				<MinistryInfoGeneralLeverageSummary />
			</Modal>
			<Modal
				{...involvementModal}
				modalTitle={'Involvement Score Calculation'}
				size='md'
			>
				<MinistryInvolvementScoreCalc ministryId={ministry.ministryId} ministryName={ministry.ministryName} />
			</Modal>
		</React.Fragment>
	)
}