import { IDonorModelDocument, IMinistryGrantFundingModelDocument, IMinistryGrantFundingSummaryDocument } from "../open-api"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useContext, useEffect, useState } from "react"
import { CheckboxField, CurrencyField } from "./forms"
import { FormikSelectField } from "./forms/formik-select-field"

interface IGrantFormFundingFormProps {
    fundingSourceToEdit?: IMinistryGrantFundingModelDocument
    fundingSources: IMinistryGrantFundingSummaryDocument[]
    branchId: number
    grantGiftId: number
    afterSave: () => Promise<void>
}

export const GrantFormFundingForm = (props: IGrantFormFundingFormProps) => {
    const { fundingSourceToEdit, afterSave, branchId, grantGiftId, fundingSources } = props
    const { MinistryGrantFundingApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [givers, setGivers] = useState<IDonorModelDocument[]>()

    const fetchGivers = async () => {
        const {data} = await makeHttpRequestWithUi({
            request: MinistryGrantFundingApi.apiMinistryGrantFundingBranchIdPotentialGiversGet(branchId),
            toastErrorMessage: 'There was an error retrieving the givers for this community.',
            disableSuccessToast: true,
            disableLoading: true,
        })

        setGivers(data)
    }

    useEffect(() => {
        fetchGivers()
    }, [])

    const initialValues = {
        fundingId: fundingSourceToEdit?.fundingId || 0,
        amount: fundingSourceToEdit?.amount || 0,
        donorId: fundingSourceToEdit?.donorId || 0,
        bPrimaryFunder: fundingSourceToEdit?.bPrimaryFunder ?? !(fundingSources.length > 0), // If no funding sources already exist, default this to true.
        bAnonymous: fundingSourceToEdit?.bAnonymous || false
    }

    const validationSchema = Yup.object({
        amount: Yup.number().required('Required'),
        donorId: Yup.number().required('Required'),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                if (fundingSourceToEdit) {
                    await makeHttpRequestWithUi({
                        request: MinistryGrantFundingApi.apiMinistryGrantFundingFundingIdPut( fundingSourceToEdit.fundingId, {
                            fundingId: fundingSourceToEdit.fundingId,
                            grantGiftId: grantGiftId,
                            amount: values.amount,
                            donorId: values.donorId,
                            bPrimaryFunder: values.bPrimaryFunder,
                            bAnonymous: values.bAnonymous
                        }),
                        toastSuccessMessage: 'Successfully updated funding source.',
                        toastErrorMessage: 'There was an error updating funding source.'
                    })
                } else {
                    await makeHttpRequestWithUi({
                        request: MinistryGrantFundingApi.apiMinistryGrantFundingPost({
                            grantGiftId: grantGiftId,
                            amount: values.amount,
                            donorId: values.donorId,
                            bPrimaryFunder: values.bPrimaryFunder,
                            bAnonymous: values.bAnonymous
                        }),
                        toastSuccessMessage: 'Successfully created funding source.',
                        toastErrorMessage: 'There was an error creating funding source.'
                    })
                }

                afterSave && afterSave()
            }}
        >
            {formikProps => {
                return (
                    <Form style={{ padding: 10 }}>
                        <FormikSelectField
                            field={{ name: 'donorId', label: 'Giver', disabled: !!fundingSourceToEdit, labelTooltip:'List of givers tied to this communitys.' }}
                            options={givers?.map(giver => ({ label: `${giver.donorName}`, value: `${giver.donorId}` }))}
                        />
                        <CurrencyField fieldProps={{ name: 'amount', label: 'Amount' }} />
                        <CheckboxField fieldProps={{ name: 'bPrimaryFunder', label: 'Primary', labeltooltip: "Is this the primary funding source for this grant?" }} />
                        <CheckboxField fieldProps={{ name: 'bAnonymous', label: 'Anonymous', labeltooltip: "Does this giver wish to remain anonymous?" }} />
                        
                        <button style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button>
                    </Form>
                )
            }}
        </Formik>
    )
}