import { IField } from "./types"
import Select, { MenuPlacement } from 'react-select'
import Styles from 'react-select'
import { useField } from "formik"
import clsx from 'clsx'
import { flatten } from "lodash"
import { portalRoot } from '../../services/helpers'
import { useRef } from "react"
import { useFormikScrollToError } from "../../hooks"
import React from "react"
import { TooltipInfo } from "../partials"

export interface ISelectFieldOption {
    value: string
    label: string,
    isDisabled?: boolean
}
export const isSelectFieldOption = (object: any | null | undefined): object is ISelectFieldOption => !!object && 'value' in object
export interface ISelectFieldOptionGroup {
    id: string
    label: string
    options: ISelectFieldOption[]
}
export interface IFormikSelectFieldProps {
    field: IField
    options?: ISelectFieldOption[]
    optionGroups?: ISelectFieldOptionGroup[]
    multiple?: boolean
    menuPlacement?: MenuPlacement
    helperText?: string
}

export const FormikSelectField = (props: IFormikSelectFieldProps) => {
    const { field: fieldProps, options, optionGroups, multiple, helperText } = props

    const [field, meta, helpers] = useField(fieldProps)
    const { error } = meta

    // Map field state to CSS class strings
    const fieldStateClasses = clsx(error && 'is-invalid')

    let value: ISelectFieldOption[] = []
    if (field.value instanceof Array) {
        if (optionGroups) {
            value = flatten(optionGroups.map(o => o.options)).filter(o => field.value.includes(o.value))
        } else if (options) {
            value = options.filter(o => field.value.includes(o.value))
        }
    } else if (typeof field.value === 'string') {
        if (optionGroups) {
            value = flatten(optionGroups.map(o => o.options)).filter(o => field.value === o.value)
        } else if (options) {
            value = options.filter(o => field.value === o.value)
        }
    } else if (typeof field.value === 'number') {
        if (optionGroups) {
            value = flatten(optionGroups.map(o => o.options)).filter(o => field.value == o.value)
        } else if (options) {
            value = options.filter(o => field.value == o.value)
        }
    }

    const customStyles: Partial<Styles> = {
        multiValueLabel: (styles: any) => ({
            ...styles,
            textOverflow: '',
            overflow: '',
            whiteSpace: 'inherit'
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    }

    const fieldRef = useRef<HTMLLabelElement>(null)
    useFormikScrollToError({ field: fieldRef, name: field.name })

    //console.log('options', options)

    return (
        <div className={`form-group ${fieldStateClasses}`}>
            <label htmlFor={fieldProps.name} ref={fieldRef}>
                <span dangerouslySetInnerHTML={{ __html: fieldProps.label || '' }} /> {fieldProps.labelTooltip && <TooltipInfo style={{ marginBottom: 4 }} tooltipText={fieldProps.labelTooltip} />}
            </label>


            <Select
                options={optionGroups ? optionGroups : options}
                isDisabled={fieldProps.disabled}
                placeholder={fieldProps.placeholder}
                isMulti={multiple}
                {...field}
                {...fieldProps}
                value={value}
                id={fieldProps.name}
                onChange={(selection) => {
                    if (selection instanceof Array) {
                        helpers.setValue(selection.map(o => o.value))
                    } else if (isSelectFieldOption(selection)) {
                        helpers.setValue(selection.value)
                    } else {
                        helpers.setValue(null)
                    }
                }}
                styles={customStyles}
                menuPortalTarget={portalRoot}
                menuPlacement={props.menuPlacement || 'auto'}
            />

            {/* Only show errors if the field has been touched */}
            {meta.error ?
                <div className='invalid-feedback' style={{ display: 'block' }}>{meta.error}</div>
                :
                <div style={{ height: '19.4px', color: '#6D6D6D' }} >{helperText}</div>
            }
        </div>
    )
}