import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import * as appStoreProvider from "../app-store-provider"
import { BriefcaseIcon } from "../assets"
import { IConsultingAreaDirectorDocument, IConsultingApptDocument, IEventContentModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IDefaultProps } from "./component-definitions"
import { ConsultingAppointmentForm } from "./consulting-appointment-form"
import { IConsultingManagementListDataType } from "./consulting-management"

interface IConsultingManagementState {
    meetingTypes: Array<IConsultingManagementListDataType>
    appointmentTypes: Array<IConsultingManagementListDataType>
    consultingTopics: Array<IConsultingManagementListDataType>
    maConsultingTopics: Array<IConsultingManagementListDataType>
    trainingTopics: Array<IConsultingManagementListDataType>
    areaDirectors: IConsultingAreaDirectorDocument[]
}

interface IConsultingManagementDetailProps extends IDefaultProps {
    consultingEventGroupId?: string
}

export const ConsultingManagementDetail = ({ consultingEventGroupId }: IConsultingManagementDetailProps) => {
    const appActions = useContext(appStoreProvider.AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IConsultingManagementState>({
        meetingTypes: [],
        appointmentTypes: [],
        consultingTopics: [],
        maConsultingTopics: [],
        trainingTopics: [],
        areaDirectors: [],
    })

    const [appointmentToEdit, setAppointmentToEdit] = useState<IConsultingApptDocument>()
    const [eventContentRecord, setEventContentRecord] = useState<IEventContentModelDocument>()

    const fetchAppointment = async () => {
        if (!consultingEventGroupId) return
        const sessionToEditQuery = await makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingConsultingEventGroupIdGet(parseInt(consultingEventGroupId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the session to edit.'
        })

        setAppointmentToEdit(sessionToEditQuery.data)

        const { data: eventContentRecord } = await makeHttpRequestWithUi({
            request: appActions.EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(sessionToEditQuery.data.eventContentId),
            disableSuccessToast: true,
            toastErrorMessage: 'Error retrieving associated event content record.'
        })
        setEventContentRecord(eventContentRecord)
    }

    useEffect(() => {
        makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingListDataGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the consulting management list data.'
        })
            .then(results => {
                // @ts-ignore
                setState(_state => ({ ..._state, ...results.data }))
            })

        makeHttpRequestWithUi({
            request: appActions.ConsultingApi.apiConsultingAreaDirectorsGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of area directors.'
        })
            .then(results => {
                setState(_state => ({ ..._state, areaDirectors: results.data }))
            })

        fetchAppointment()
    }, [])

    return (
        <>
            <Helmet>
                <title>Coaching/Consulting: {eventContentRecord?.title || ''}</title>
            </Helmet>
            <div className='d-flex flex-column vh-100'>
                <div className='m-2 d-flex align-items-center'>
                    <BriefcaseIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Coaching/Consulting: {eventContentRecord?.title}</h3>
                </div>

                <div className='m-2' style={{ overflow: 'auto' }}>
                    {appointmentToEdit &&
                        <ConsultingAppointmentForm
                            appointmentToEdit={appointmentToEdit}
                            setAppointmentToEdit={setAppointmentToEdit}
                            meetingTypes={state.meetingTypes}
                            appointmentTypes={state.appointmentTypes}
                            consultingTopics={state.consultingTopics}
                            maConsultingTopics={state.maConsultingTopics}
                            trainingTopics={state.trainingTopics}
                            areaDirectors={state.areaDirectors}
                            afterSave={fetchAppointment}
                        />
                    }
                </div>
            </div>
        </>
    )
}