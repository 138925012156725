// TB 20230428 - After I started migrating the GR Summary Report from the legacy system to React, I realized we had already migrated it to ASP.NET on the Givers Portal.
// I stopped the migration in React, but leaving this here, just in case we decide to continue it sometime.
import React, { useContext, useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext } from "../app-store-provider"
import { IGrantExportModelDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import { GrantRequestSummaryReport } from "./grant-request-summary-report"
import { Loading } from "./loading"

interface IGrantRequestSummaryReportPrintProps extends IDefaultProps {
	grantRequestGuid?: string
}

export const GrantRequestSummaryReportPrint = (props: IGrantRequestSummaryReportPrintProps) => {
    const { grantRequestGuid } = props
    const appActions = useContext(AppActionContext)!
    const [reportData, setReportData] = useState<IGrantExportModelDocument[]>()

    const getReports = async () => {
        if (grantRequestGuid) {
            const results = await appActions.GrantRequestApi.apiGrantRequestGrantRequestGuidSummaryReportDataGet(grantRequestGuid)
            setReportData(results.data)
        }
    }

	useEffect(() => {
        getReports()
    }, [])

    return reportData ?
        <>
            <Helmet>
                <title>Grant Request Report</title>
            </Helmet>
            {/* <GrantRequestSummaryReport grantRequestSummary={reportData[0]} /> */}
            {reportData.map(report => 
                <GrantRequestSummaryReport key={report.grantId} grantRequestSummary={report} />
            )}
        </>
        :
        <Loading />
}