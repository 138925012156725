import { useContext, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { IDefaultProps } from './component-definitions'
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { ICallListDataRecordDocument, ICallListSummaryDocument, IEventFilterFieldsDataModelDocument, IMinistryContactSummaryModelDocument, INotesSummaryDocument } from "../open-api"
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, openUrlInNewTab, sortListBySorts } from "../services/helpers"
import { CallListDataRecordModel } from "../models/call-list-data-record"
import { Grid } from "./grid"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { AccountLevelDisplay, AccountStatusDisplay, DefaultGridCellDisplay, EmailCellDisplay, GridActionCell } from "./grid-cell-displays"
import { Link } from "@reach/router"
import { CallChatIcon, IncognitoIcon, JournalPlusIcon } from "../assets"
import { SquareDeleteIcon } from "./partials"
import { gridReducer } from "../stores/grid-reducer"
import { Modal } from "./modal"
import { Loading } from "./loading"
import { CheckboxField, SimpleSelectField } from "./forms"
import { Form, Formik } from "formik"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { FormikSelectField } from "./forms/formik-select-field"
import dayjs from "dayjs"
import { CallListSummaryFilterByEvents } from './call-list-summary-filter-by-events'
import { IFilter } from "../stores/api-actions"

interface ICallListProps extends IDefaultProps {
    callListId?: string
}

export interface IFilterByEventsState {
    filterByEventsOptions?: IEventFilterFieldsDataModelDocument
    attendedEventContentId: number
    attendedTitle: string
    registeredEventContentId: number
    registeredTitle: string
}

const dataSource: GridDataFetch<ICallListDataRecordDocument[]> = async (_queryState, _callListSummaryItem) => {
    if (!_callListSummaryItem) return { count: 0, rows: [] }

    if (_queryState.sorts) sortListBySorts(_callListSummaryItem, _queryState.sorts)

    let rows = _callListSummaryItem.map(CallListDataRecordModel.toGridListItem)

    if (_queryState.filters) {
        rows = filterGridListItems(rows, _queryState.filters)
    }

    return {
        count: rows.length,
        rows,
    }
}

const filterByEventIdFilter : IFilter = {
    id: 'filter-by-event',
    operator: 'filter-by-event',
    property: '',
    value: 0,
    enabled: false
}

export const CallListSummary = (props: ICallListProps) => {
    const callListId = props.callListId!
    const { activeBranches} = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const { CallListsApi, CallList2DataApi, MinistriesApi, MinistryContactsApi, NotesApi } = useContext(AppActionContext)!
    const [callListSummary, setCallListSummary] = useState<ICallListSummaryDocument>()
    const [otherContacts, setOtherContacts] = useState<IMinistryContactSummaryModelDocument[]>()
    const [selectedOtherContact, setSelectedOtherContact] = useState<IMinistryContactSummaryModelDocument | undefined>()
    const [previousNotes, setPreviousNotes] = useState<INotesSummaryDocument[]>()
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [removeModal, showHideRemoveModal] = useModal()
    const [addNoteModal, showHideAddNoteModal] = useModal()
    const [filterByEventsModal, showHideFilterByEventsModal] = useModal()
    const [filterByEventsState, setFilterByEventsState] = useState<IFilterByEventsState>({
        attendedEventContentId: 0,
        attendedTitle: '',
        registeredEventContentId: 0,
        registeredTitle: ''
    })

    const fetchCallListSummary = async () => {

        const { data } = await makeHttpRequestWithUi({
            request: CallListsApi.apiCallListsIdSummaryGet(callListId, parseInt(callListId),
                filterByEventsState?.registeredEventContentId,
                filterByEventsState?.attendedEventContentId),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch call list.',
            disableLoading: true
        })

        setCallListSummary(data)


        const { data: notes } = await makeHttpRequestWithUi({
            request: NotesApi.apiCalllistsIdNotesGet(parseInt(callListId)),
            disableSuccessToast: true,
            toastErrorMessage: 'Error retrieving previous notes.'
        })
        setPreviousNotes(notes)
    }

    const showOtherContactDropDown = useMemo(() => {
        if (!callListSummary?.callListDataRecords) return false
        if (callListSummary.callListDataRecords.length === 0) return false
        if (callListSummary.callListDataRecords[0].prospectId) return false
        return true
    }, [callListSummary])

    const initialGridState: IGridState = {
        ...defaultGridState,
        overrideInitialStateValues: {
            queryState: {
                ...defaultGridState.queryState,
                filters: [filterByEventIdFilter],
            },
        },
        gridActions: [
            {
                id: 'filterByEvents',
                label: 'Filter by Events',
                onClick: () => showHideFilterByEventsModal(true),
                buttonClass: (gridState) => {
                    const eventFilter = gridState.queryState.filters?.find(f => f.operator === 'filter-by-event')
                    console.log('gridState.queryState.filters', gridState.queryState.filters)
                    if (eventFilter && eventFilter.value == 1) {
                        return 'btn-primary'
                    } else {
                        return 'btn-secondary'
                    }
                },
                // Only allow filtering by events if these are ministries, not prospects.
                disabled: (_gridState) => _gridState.rows.some(x => x.values.prospectId)
            }
        ],
        rowActions: {
            addNote: {
                id: 'addNote',
                action: async (options) => {
                    const { e, row } = options
                    e.stopPropagation()
                    setSelectedRow(row)

                    if (typeof row.values.ministryId === 'number') {
                        const { data } = await makeHttpRequestWithUi({
                            request: MinistryContactsApi.apiMinistriesIdContactsGet(row.values.ministryId),
                            disableSuccessToast: true,
                            toastErrorMessage: 'Error retrieving other ministry contacts',
                        })
                        setOtherContacts(data)


                    }


                    showHideAddNoteModal(true)
                },
                icon: <JournalPlusIcon />,
                tooltipText: 'Add Call Note'
            },
            impersonate: {
                id: 'impersonate',
                action: async (options) => {
                    const { e, row } = options
                    e.stopPropagation()
                    openUrlInNewTab(row.values.impersonationUrl?.toString() ?? '')
                },
                icon: <IncognitoIcon />,
                tooltipText: 'Impersonate User',
                disabled: (row) => !row.values.canImpersonate,
                hidden: (row) => (row.values.ministryId ?? 0) < 1
            },
            remove: {
                id: 'remove',
                action: async (options) => {
                    options.e.stopPropagation()
                    setSelectedRow(options.row)
                    showHideRemoveModal(true)
                },
                icon: <SquareDeleteIcon />,
                tooltipText: 'Remove',
                disabled: (row) => Boolean(row.values.hideDelete)
            }
        },
        columns: [
            {
                property: 'callStatus',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Call Status',
                render: DefaultGridCellDisplay,
                conditionalCellCSSFormatting: (row) => {
                    if (row.values.callStatusId === 1) return 'bg-danger-7'
                    if (row.values.callStatusId === 2) return 'bg-warning-7'
                    if (row.values.callStatusId === 3) return 'bg-success-7'
                    return ''
                },
            },
            {
                property: 'lastNoteDate',
                type: 'date',
                width: 100,
                allowFilters: true,
                title: 'Last Called',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 100,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
            {
                property: 'orgName',
                type: 'string',
                width: 270,
                allowFilters: true,
                title: 'Org Name',
                render: (col, row) => row.values.ministryId ?? row.values.prospectId ?? 0 ? <Link to={row.values.ministryId ? `/ministry-info/${row.values.ministryId}` : `/prospects/${row.values.prospectId}`}>{row.values[col.property]}</Link> : row.values[col.property],
                description: 'The org name'
            },
            {
                property: 'contactName',
                type: 'string',
                width: 155,
                allowFilters: true,
                title: 'Contact',
                render: DefaultGridCellDisplay,
                description: 'The primary contact for the ministry'
            },
            {
                property: 'email',
                type: 'string',
                width: 210,
                allowFilters: true,
                title: 'Email',
                render: EmailCellDisplay,
                description: 'The email of the primary contact for the ministry'
            },
            {
                property: 'phone',
                type: 'string',
                width: 115,
                allowFilters: true,
                title: 'Phone',
                render: DefaultGridCellDisplay,
                description: 'The business phone number for the primary contact',
                align: 'left'
            },
            {
                property: 'lastNoteText',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Call Notes',
                render: DefaultGridCellDisplay,
                tooltip: (row) => typeof row.values.lastNoteText === 'string' ? `${dayjs(row.values.lastNoteDate?.toString()).format('MM/DD/YYYY')} - ${row.values.lastNoteText}` : undefined
            },
            {
                property: 'orgType',
                type: 'string',
                width: 72,
                allowFilters: false,
                title: 'Type',
                render: (_, row) => row.values.ministryId ?? 0 > 0 ? 'Ministry' : 'Prospect',
                hide: true
            },
            {
                property: 'ministryLevelId',
                type: 'string',
                width: 103,
                allowFilters: true,
                filterOptions: [
                    { label: 'Teaching', value: '1' },
                    { label: 'Coaching', value: '2' },
                    { label: 'Consulting', value: '3' },
                ],
                title: 'Acct Level',
                render: AccountLevelDisplay,
                description: 'The level the ministry is assigned.',
            },
            {
                property: 'profileStatus',
                type: 'string',
                width: 135,
                allowFilters: true,
                title: 'Account Status',
                render: AccountStatusDisplay,
                description: 'Displays OK when the account is complete.<br>GRC is displayed when the ministry is within a 60 day grace period after a tax year roll.<br>SUB is displayed when the account has been submitted but not yet approved.<br>INC is displayed when the ministry has an incomplete account.<br>PRE is displayed when the ministry is within the 60 day window after being moved to the granting level.',
                align: 'center',
                filterOptions: [
                    { label: 'OK ', value: 'OK' },
                    { label: 'GRC ', value: 'GRC' },
                    { label: 'PRE ', value: 'PRE ' },
                    { label: 'SUB ', value: 'SUB' },
                    { label: 'INC ', value: 'INC' },
                ],
            },
            {
                property: 'eventsAttendedCount',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Attended',
                description: 'The total number of events has this org attended',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'dateLastEventAttended',
                type: 'date',
                width: 126,
                allowFilters: true,
                title: 'Last Attended',
                description: 'Date this org last attended an event',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'dateOrgRegistered',
                type: 'date',
                width: 115,
                allowFilters: true,
                title: 'Acct Created',
                description: 'The date the ministry created their ministry account',
                render: DefaultGridCellDisplay,
                hide: true
            },
            {
                property: 'attendedPastEvent',
                type: 'boolean',
                width: 98,
                allowFilters: true,
                title: 'Attended',
                description: 'Whether or not they attended the event selected in the toolbar',
                render: (_, row) => row.values.attendedPastEvent == null ? 'N/A' : (row.values.attendedPastEvent ? 'Yes' : 'No'),
            },
            {
                property: 'attendingFutureEvent',
                type: 'boolean',
                width: 99,
                allowFilters: true,
                title: 'Registered',
                description: 'Whether or not they are registered for the event selected in the toolbar',
                render: (_, row) => row.values.attendingFutureEvent == null ? 'N/A' : (row.values.attendingFutureEvent ? 'Yes' : 'No'),
            },
            {
                property: 'branchAbbr',
                type: 'string',
                width: 110,
                allowFilters: true,
                title: 'Community',
                render: DefaultGridCellDisplay,
                description: 'The community that the ministry is attached to',
                filterOptions: activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
            },
            {
                property: 'trainingAreaName',
                type: 'string',
                width: 75,
                allowFilters: true,
                title: 'T. Area',
                render: DefaultGridCellDisplay,
                description: 'The training area that the ministry is attached to'
            },
        ],
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        rowSelectEnabled: false,
        respectGlobalCommunityFilter: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            showHideAddNoteModal(true)
        },
    }

    const [gridState, actions] = useGrid(gridReducer, initialGridState, callListSummary?.callListDataRecords ?? [])

    useEffect(() => {
        actions.doFetch()
    }, [callListSummary])

    useEffect(() => {
        const filters = gridState.queryState.filters;
        const filter = filters?.find(x => x.id == filterByEventIdFilter.id)

        if (filter) {
            filter.value = Boolean(filterByEventsState.attendedEventContentId || filterByEventsState.registeredEventContentId) ? 1 : 0
        }

        fetchCallListSummary()
    }, [filterByEventsState.attendedEventContentId, filterByEventsState.registeredEventContentId])

    if (!callListSummary) return <Loading />

    return (
        <>
            <Helmet>
                <title>Call List: {callListSummary.title}</title>
            </Helmet>
            <div className='d-flex flex-column vh-100'>
                <div className='m-2 d-flex align-items-center'>
                    <CallChatIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Call Lists: {callListSummary.title}</h3>
                </div>
                <div className='ml-4'>
                    Description: {callListSummary.description}
                </div>
                {   (filterByEventsState?.attendedTitle || filterByEventsState?.registeredTitle) ?
                    <div className="ml-4">
                        {filterByEventsState?.attendedTitle ? `Attended: ${filterByEventsState?.attendedTitle}` : null }
                        {filterByEventsState?.attendedTitle && filterByEventsState?.registeredTitle ? <br /> : null }
                        {filterByEventsState?.registeredTitle ? `Registered: ${filterByEventsState?.registeredTitle}` : null }
                    </div>
                    : 
                    null
                }
                <Grid state={gridState} actions={actions} />
            </div>

            <Modal
                {...addNoteModal}
                modalTitle={`Add call notes to ${selectedRow?.values.orgName}`}
                size='xxl'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setOtherContacts([])
                    setSelectedOtherContact(undefined)
                    setPreviousNotes([])
                }}>
                {selectedRow ?
                    <div className='row'>
                        <div className='col'>
                            <h5>Comments to Caller</h5>
                            {callListSummary.notes ?
                                <div dangerouslySetInnerHTML={{ __html: callListSummary.notes }}></div>
                                :
                                <div>
                                    [There are no comments.]
                                </div>
                            }
                        </div>
                        <div className='col'>
                            <h5><Link to={selectedRow.values.ministryId ? `/ministry-info/${selectedRow.values.ministryId}` : `/prospects/${selectedRow.values.prospectId}`}>{selectedRow.values.orgName}</Link></h5>
                            {otherContacts && showOtherContactDropDown &&
                                <div className='mb-2'>
                                    <SimpleSelectField
                                        fieldProps={{ name: '', label: 'Ministry Contacts' }}
                                        options={otherContacts?.map(o => ({ label: `${o.fullName} ${o.title ? '(' + o.title + `)` : ''}`, value: `${o.ministryContactId}` }))}
                                        onChange={(option) => {
                                            if (option && !Array.isArray(option)) setSelectedOtherContact(otherContacts.find(o => o.ministryContactId === parseInt(option.value)))
                                        }}
                                    />
                                </div>
                            }
                            <p>
                                {selectedOtherContact ?
                                    <>
                                        {selectedOtherContact.fullName}<br />
                                        {selectedOtherContact.contactTypeName ? <>{selectedOtherContact.contactTypeName}</> : null}
                                        {selectedOtherContact.contactTypeName && selectedOtherContact.title ? <> - </> : null}
                                        {selectedOtherContact.title ? <>{selectedOtherContact.title}</> : null}
                                        {selectedOtherContact.contactTypeName || selectedOtherContact.title ? <br /> : null}
                                        {selectedOtherContact.businessPhone}<br />
                                        {selectedOtherContact.email ? <a href={`mailto:${selectedOtherContact.email}`}>{selectedOtherContact.email}</a> : null}
                                    </>
                                    :
                                    <>
                                        <b>Primary Contact</b><br />
                                        {selectedRow.values.contactName}<br />
                                        {selectedRow.values.contactType ? <>{selectedRow.values.contactType}</> : null}
                                        {selectedRow.values.contactType && selectedRow.values.contactTitle ? <> - </> : null}
                                        {selectedRow.values.contactTitle ? <>{selectedRow.values.contactTitle}</> : null}
                                        {selectedRow.values.contactType || selectedRow.values.contactTitle ? <br /> : null}
                                        {selectedRow.values.phone}<br />
                                        {selectedRow.values.email ? <a href={`mailto:${selectedRow.values.email}`}>{selectedRow.values.email}</a> : null}
                                    </>
                                }

                            </p>
                            <Formik
                                initialValues={{
                                    callNotes: '',
                                    callStatusId: selectedRow.values.callStatusId?.toString() == '3' ? '3' : '2',
                                    ministryId: selectedRow.values.ministryId?.toString() || '0',
                                    prospectId: selectedRow.values.prospectId?.toString() || '0',
                                    callListCreatorId: selectedRow.values.creatorId?.toString() || '0',
                                    alertAd: false
                                }}
                                onSubmit={async (values) => {


                                    const addNoteQuery = await makeHttpRequestWithUi({
                                        request: CallListsApi.apiCallListsIdLogCallPost(parseInt(selectedRow.id), {
                                            callList2DataId: parseInt(selectedRow.id),
                                            callStatusId: parseInt(values.callStatusId),
                                            ministryId: parseInt(values.ministryId),
                                            noteText: values.callNotes,
                                            prospectId: parseInt(values.prospectId),
                                            alertUserId: values.alertAd ? parseInt(values.callListCreatorId) : null,
                                        }),
                                        toastSuccessMessage: 'Successfully added note.',
                                        toastErrorMessage: 'Error adding note.',
                                        disableLoading: false,
                                    })

                                    showHideAddNoteModal(false)
                                    await fetchCallListSummary()
                                }}
                            >
                                <Form>
                                    <FormikTextAreaField field={{ name: 'callNotes', label: 'Call Notes' }} />
                                    <FormikSelectField
                                        field={{ name: 'callStatusId', label: 'Call Status' }}
                                        options={[{ label: 'Call Again', value: '2' }, { label: 'Call Complete', value: '3' }]}
                                    />
                                    <div className='mb-3'>
                                        <CheckboxField fieldProps={{ name: 'alertAd', label: 'Alert Area Director?', labeltooltip: "Checking this will cause an alert to appear on the appropriate Area Direoctor's Alert Dashboard." }} />
                                    </div>

                                    <div>
                                        <button style={{ width: 100 }} type='reset' className='btn btn-secondary mr-2'>Cancel</button>
                                        <button type='submit' style={{ width: 100 }} className='btn btn-primary'>Add Note</button>
                                    </div>
                                </Form>
                            </Formik>

                            {(() => {
                                const previousMinistryNotes = previousNotes?.filter(o => (o.ministryId !== 0 && o.ministryId === selectedRow.values.ministryId) || (o.prospectId !== 0 && o.prospectId === selectedRow.values.prospectId))

                                return (
                                    <div className='mt-4'>
                                        <h5>Previous Notes</h5>
                                        {previousMinistryNotes?.map(note =>
                                            <div key={note.noteId} className='mb-2'>
                                                <div style={{ color: 'darkgray' }}><b className='mr-2'>{note.createdByFirstName} {note.createdByLastName}</b> {note.dateEntered && dayjs(note.dateEntered).format('MMM DD, YYYY [at] h:mm A')}</div>
                                                <div dangerouslySetInnerHTML={{ __html: note.noteText || '' }} />
                                            </div>
                                        )}
                                    </div>
                                )
                            })()}
                        </div>
                    </div>
                    :
                    <div>Select a row.</div>
                }
            </Modal>

            <Modal
                {...removeModal}
                modalTitle='Remove Organization'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideRemoveModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: CallList2DataApi.apiCallList2DataIdArchivePut(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully removed the organization.',
                                        toastErrorMessage: 'Encountered an error removing the organization.',
                                    })
                                    showHideRemoveModal(false)
                                    fetchCallListSummary()
                                }
                            }}
                        >
                            Dismiss
                        </button>
                    </>
                }
            >
                <p>Are you sure you want to remove this organization from the call list?</p>
            </Modal>
            <Modal
                {...filterByEventsModal}
                modalTitle='Event Filters'
                size='xl'
                _onModalShown={async () => {
                    if (!filterByEventsState.filterByEventsOptions) {
                        const request = await makeHttpRequestWithUi({ request: MinistriesApi.apiMinistriesGetEventFilterFieldsDataGet(), disableSuccessToast: true, toastErrorMessage: `There was an error retrieving 'Filter by Events' data.` })
                        setFilterByEventsState({ ...filterByEventsState, filterByEventsOptions: request.data })
                    }
                }}
            >
                {filterByEventsState.filterByEventsOptions ?
                    <CallListSummaryFilterByEvents
                        filterByEventsState={filterByEventsState}
                        closeModal={() => showHideFilterByEventsModal(false)}
                        updateFilterByEvents={(_filterByEventsSummary: IFilterByEventsState) => setFilterByEventsState(_filterByEventsSummary)}
                    />
                    :
                    null
                }
            </Modal>
        </>
    )
}