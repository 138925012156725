import React, { useCallback, useContext, useState } from "react"
import { Helmet } from "react-helmet"
import { AppActionContext } from "../app-store-provider"
import { PencilIcon, CurrencyDollarIcon, PlusIcon } from "../assets"
import { ISearchRequestFilterDocument, ISearchSortDocument, IGrantGiftModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { IAppState } from "../stores/app-definitions"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from '../stores/grid-reducer'
import { IDefaultProps } from "./component-definitions"
import { GrantForm } from "./grant-form"
import { Grid } from "./grid"
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { Modal } from "./modal"
import { SquareDeleteIcon } from "./partials"

interface IGrantsProps extends IDefaultProps { }

export const GrantManagement = (props: IGrantsProps) => {
    const { GrantGiftApi } = useContext(AppActionContext)!
    
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [grantToEdit, setGrantToEdit] = useState<IGrantGiftModelDocument>()
    const [grantFormModal, showHideGrantFormModal] = useModal()
    const [deleteGrantGiftModal, showHideDeleteGrantGiftModal] = useModal()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    // const fetchRecords = async () => {
    //     const callListsQuery = await makeHttpRequestWithUi({
    //         request: GrantGiftApi.apiGrantGiftGet(Boolean(gridState.queryState.filters?.find(o => o.id === showArchivedFilter.id)?.value)),
    //         disableSuccessToast: true,
    //         toastErrorMessage: 'Failed to fetch call lists.'
    //     })

    //     setCallLists(callListsQuery.data)
    // }

    const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        try {

            const query = await makeHttpRequestWithUi({
                request: GrantGiftApi.apiGrantGiftGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of grants.'
            })

            const rows = (query.data.data || []).map<IGridListItem>(item => ({
                id: item.grantGiftId.toString(),
                values: {
                    ...item
                }
            }))

            return {
                rows,
                count: query.data.totalCount
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    const fetchGrant = useCallback(async (grantId: string) => {
        const {data} = await makeHttpRequestWithUi({
            request: GrantGiftApi.apiGrantGiftGrantGiftIdGet(parseInt(grantId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setGrantToEdit(data)
    }, [])

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.Grants,
        gridActions: [
            {
                id: 'addGrant',
                label: 'Add Grant',
                icon: <PlusIcon className='mr-2' />,
                buttonClass: 'btn-primary',
                onClick: () => showHideGrantFormModal(true),
                sortOrder: 0,
            }
        ],
        rowActions: {
            editGrant: {
                id: 'editGrant',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    await fetchGrant(row.id)
                    showHideGrantFormModal(true)
                },
                tooltipText: 'Edit Grant',
                icon: <PencilIcon />,
            },
            deleteGrant: {
                id: 'deleteGrant',
                action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideDeleteGrantGiftModal(true)
				},
                tooltipText: 'Delete Grant', // 'Cannot delete grant that has been applied for.'
                icon: <SquareDeleteIcon />,
                disabled: (row) => (row.values.ministriesAppliedCount ?? 0) > 0
            },
        },
        columns: [
            {
                property: 'branchAbbr',
                type: 'string',
                width: 50,
                allowFilters: true,
                title: 'Community',
                render: DefaultGridCellDisplay
            },
            {
                property: 'dateCreated',
                type: 'date',
                width: 50,
                allowFilters: true,
                title: 'Created',
                render: DefaultGridCellDisplay
            },
            {
                property: 'description',
                type: 'string',
                width: 125,
                allowFilters: true,
                title: 'Description',
                render: DefaultGridCellDisplay
            },
            {
                property: 'totalFundingAmount',
                type: 'number',
                render: CurrencyDisplay,
                title: 'Total Funding',
                width: 100,
                allowFilters: true,
            },
            {
                property: 'primaryFunderName',
                type: 'string',
                render: DefaultGridCellDisplay,
                title: 'Primary Funder',
                width: 125,
                allowFilters: false,
                disableSort: true
            },
            {
                property: 'createdByName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Created By',
                hide: true,
                render: (_, row) => row.values.createdByFirstName +  ' ' + row.values.createdByLastName
            },
            {
                property: 'isActive',
                type: 'boolean',
                render: DefaultGridCellDisplay,
                title: 'Active',
                width: 75,
                allowFilters: true,
                hide: true
            },
            {
                property: 'ministriesInvitedCount',
                type: 'number',
                width: 75,
                allowFilters: true,
                title: 'Ministries Invited',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'ministriesAppliedCount',
                type: 'number',
                width: 75,
                allowFilters: true,
                title: 'Ministries Applied',
                render: DefaultGridCellDisplay,
            },
            
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: true,
        respectGlobalCommunityFilter: true,
        disableExport: false,
        infinitePaging: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            await fetchGrant(row.id)
            showHideGrantFormModal(true)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, [])

    return (
        <>
            <Helmet>
                <title>Grant Management</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <CurrencyDollarIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Grant Management</h3>
                </div>
                <p className='ml-2'>
                    The first step in the granting process is to create a <i>grant</i>. A <i>grant</i> is a pool of money that one or more ministries can be invited to apply for via a grant request.  
                </p>
                <Grid state={gridState} actions={gridActions} />
            </div>
            <Modal
                {...grantFormModal}
                modalTitle={!!grantToEdit ? `Edit Grant (${grantToEdit.grantGiftId})` : 'Add Grant'}
                size='xl'
                minHeightPercent={100}
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setGrantToEdit(undefined)
                    gridActions.doFetch()
                }}

                dismissible={false}
            >
                {grantFormModal.show &&
                    <GrantForm
                        grantToEdit={grantToEdit} 
                        afterSave={async () => {
                            if (selectedRow)
                                await fetchGrant(selectedRow?.id)
                            else
                                showHideGrantFormModal(false)
                    }} />
                }
            </Modal>
            <Modal
				{...deleteGrantGiftModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteGrantGiftModal(false)}>Cancel</button>
						<button className='btn btn-warning' onClick={async () => {
							if (!!selectedRow) {
								await makeHttpRequestWithUi({
									request: GrantGiftApi.apiGrantGiftGrantGiftIdDelete(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Successfully deleted grant goal.',
									toastErrorMessage: 'There was an error deleting grant goal.'
								})
							}
							await gridActions.doFetch()
							showHideDeleteGrantGiftModal(false)
						}}>Delete</button>
					</React.Fragment>
				}
				_onModalHidden={() => {
					setSelectedRow(undefined)
				}}
			>
				Are you sure you want to delete this grant gift?
            </Modal>
        </>
    )
}