import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import { IMinistryContactSummaryModelDocument, INotePostModelDocument, INotesDocument } from '../open-api'
import * as Yup from 'yup'
import { SelectField, DateStringField, ISelectFieldOption, TinyMceField } from './forms'
import moment from 'moment'
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { NOTE_TYPES } from '../constants'

interface INoteFormProps {
	note?: INotesDocument // note to edit
	ministryId?: number // The ministry the note is for
	grantId?: number // The grant request the note is for
	prospectId?: number // The prospect the note is for
	afterSave?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
	onSaveCancel?: () => void
	ministryContacts?: IMinistryContactSummaryModelDocument[]
}

export const NoteForm = (props: INoteFormProps) => {
	const { ministryId, prospectId, grantId, afterSave, note, ministryContacts } = props

	if (!prospectId && !ministryId && !note && !grantId) throw new Error('NoteForm props must include a prospectId, a ministryId, a grantId, or an existing note.')

	const appActions = useContext(AppActionContext)!

	const [noteTypes, setNoteTypes] = useState<ISelectFieldOption[]>()

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()
	let resetForm: () => void

	// Don't allow event notes to have their note type be altered
	//const disableNoteType: boolean = ((note?.eventId || 0) > 0)

	useEffect(() => {
		// Some of the note types should not be selectable when creating a new note on this page.
		// If the note we are editing is not already of those note types, remove them from the
		// possible list of options

		if (resetForm) resetForm()

		// @ts-ignore
		let filteredNoteTypes: ISelectFieldOption[] = Object.keys(NOTE_TYPES).filter(key => isNaN(key)).map(key => ({ value: `${NOTE_TYPES[key]}`, label: key }))

		if (!note || note.noteTypeId !== NOTE_TYPES["Small Group Session"]) {
			filteredNoteTypes = filteredNoteTypes.filter(x => x.label !== "Small Group Session")
		}
		if (!note || note.noteTypeId !== NOTE_TYPES["Admin Review"]) {
			filteredNoteTypes = filteredNoteTypes.filter(x => x.label !== "Admin Review")
		}
		if (!note || note.noteTypeId !== NOTE_TYPES.Admin) {
			filteredNoteTypes = filteredNoteTypes.filter(x => x.label !== "Admin")
		}

		setNoteTypes(filteredNoteTypes)

	}, [note])

	return (
		<Formik
			initialValues={{
				noteText: note?.noteText || '',
				ministryContactId: note?.ministryContactId?.toString() || '',
				noteTypeId: note?.noteTypeId?.toString() || NOTE_TYPES.Private.toString(),
				dateEntered: moment(note?.dateEntered || undefined).format('MM/DD/YYYY')
			}}
			validationSchema={Yup.object({
				noteText: Yup.string().required('Required'),
				noteTypeId: Yup.mixed().required('Required')
			})}
			onSubmit={async (values, formikActions) => {
				if (!note) {
					const noteRecord: INotePostModelDocument = {
						ministryId: ministryId || null,
						prospectId: prospectId || null,
						grantId: grantId || null,
						dateEntered: values.dateEntered,
						ministryContactId: values.ministryContactId && values.ministryContactId.length ? parseInt(values.ministryContactId) : null,
						noteText: values.noteText,
						noteTypeId: parseInt(values.noteTypeId),
						alertUserId: null,
						callListId: null,
						deleteDate: null,
						deleteUserId: null,
						eventId: null,
						lengthHours: null,
						parentNoteId: null,
						reviewFiscalYear: null,
						reviewStatusId: null,
						seminarId: null
					}

					await makeHTTPRequestWithUi({
						request: appActions.NotesApi.apiNotesPost(noteRecord),
						toastSuccessMessage: 'Note successfully created.',
						toastErrorMessage: 'There was an error creating the note.',
					})
				} else {
					const noteRecord: INotesDocument = {
						...note,
						dateEntered: values.dateEntered,
						ministryContactId: values.ministryContactId && values.ministryContactId.length ? parseInt(values.ministryContactId) : null,
						noteText: values.noteText,
						noteTypeId: parseInt(values.noteTypeId),
					}

					await makeHTTPRequestWithUi({
						request: appActions.NotesApi.apiNotesIdPut(note.noteId, noteRecord),
						toastSuccessMessage: 'Note successfully updated.',
						toastErrorMessage: 'There was an error updating the note.',
					})
				}

				formikActions.resetForm()
				if (afterSave) afterSave()
			}}
		>
			{formikProps => {
				resetForm = formikProps.resetForm

				return (
					<Form>
						<TinyMceField fieldProps={{ name: 'noteText', label: 'Note Text' }} />

						<DateStringField fieldProps={{ name: 'dateEntered', label: 'Date (mm/dd/yyyy)' }} />

						<SelectField
							disabled={true}
							fieldProps={{ name: 'noteTypeId', label: 'Type', placeholder: 'Select note type', labelTooltip: 'Notes are only visible via GrowthTrack. Defaulting all manually created notes to Private, so the user doesn\'t have to guess at if the not should be public or private.' }}
							options={noteTypes}
						/>
						{formikProps.values.noteTypeId === NOTE_TYPES.Admin.toString() ?
							<div className='p-2 bg-light text-muted border border-light rounded animated fadeIn'>Admin notes are only visible to other Admins (users with a default permission of Admin)</div>
							:
							null
						}

						{ministryContacts ?
							<SelectField
								fieldProps={{ name: 'ministryContactId', label: 'Ministry Contact', placeholder: 'Select ministry contact' }}
								options={ministryContacts.map(mc => ({ value: mc.ministryContactId.toString(), label: mc.fullName || '' }))}
							/>
							:
							null
						}

						<div className='row px-3'>
							<button className={`btn btn-primary col`} type='submit'>Save</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}