import { useContext } from "react"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { Modal } from "./modal"
import { AppActionContext } from "../app-store-provider"
import { IS_PRODUCTION } from "../constants"
import * as Fathom from 'fathom-client'

interface IEventAddToMyCalendarProps {
    modalProps: ReturnType<typeof useModal>[0]
    eventId: number
    startTime: string
    timezone: string
    eventContentTitle: string
}

export const EventAddToMyCalendar = ({modalProps, eventId, eventContentTitle, startTime, timezone}: IEventAddToMyCalendarProps) => {
    const { EventMgmtApi } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    return(
        <Modal
            {...modalProps}
            modalTitle='Add To My Outlook Calendar'
            size='lg'
            footer={
                <>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={async () => {
                            IS_PRODUCTION && Fathom.trackEvent('AddToMyOutlookCalendar')
                            await makeHttpRequestWithUi({
                                request: EventMgmtApi.apiEventMgmtEventEventIdAddToMyOutlookCalendarPost(eventId),
                                toastSuccessMessage: 'Successfully added event to your calendar.',
                                toastErrorMessage: 'Encountered an error while attempting to add this event to your calendar.',
                            })
                            modalProps.closeModal()
                        }}
                    >
                        Yes
                    </button>
                    <button type='button' className='btn btn-secondary' onClick={() => modalProps.closeModal()}>Cancel</button>
                </>
            }
        >
            <p>Would you like to add this event your Outlook calendar?</p>
            <p>{startTime} {timezone} - {eventContentTitle}</p>
        </Modal>
    )
}