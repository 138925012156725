import { ChevronUpIcon, ChevronDownIcon } from '../assets'
import { useSpring, animated, config } from 'react-spring'
import { useState, useRef, FC, useEffect } from 'react'

export const ExpandableSection: FC = ({ children }) => {
    const [expanded, setExpanded] = useState(true)
    const animating = useRef(false)
    const expandableRef = useRef<HTMLDivElement | null>(null)
    const [styles, api] = useSpring(() => ({ height: 0 }))

    useEffect(() => {
        // Set the initial height of the spring
        api.set({ height: expandableRef.current?.scrollHeight })
    }, [])

    useEffect(() => {
        if (expanded && !animating.current) api.set({ height: expandableRef.current?.scrollHeight })
    })

    return (
        <div style={{ borderBottom: '1px solid lightgray', position: 'relative', minHeight: 10 }}>
            <animated.div
                ref={expandableRef}
                style={{ ...styles, overflow: 'hidden' }}
            >
                {children}
            </animated.div>

            <div
                className='expand-collapse-button'
                onClick={async () => {
                    setExpanded(!expanded)
                    if (expanded) {
                        animating.current = true
                        await Promise.all(api.start({ height: 0, config: config.stiff }))
                        animating.current = false
                    } else {
                        animating.current = true
                        await Promise.all(api.start({ height: expandableRef.current?.scrollHeight, config: config.stiff }))
                        animating.current = false
                    }

                }}
                style={{ position: 'relative', margin: '0 auto', bottom: -15, borderRadius: '50%', border: '1px solid lightgray', width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
            >
                {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
        </div>
    )
}