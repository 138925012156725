import { IMailEngineSearchResultModelDocument } from "../open-api"
import { IGridListItem } from "../stores/grid-definitions"

export class MalLogModel {
    public static toGridListItem = (mailLogItem: IMailEngineSearchResultModelDocument): IGridListItem => {
		return {
			id: mailLogItem.mailId.toString(),
			values: {
                ...mailLogItem,
                dSent: new Date(mailLogItem.dSent)
			}
		}
	}
}