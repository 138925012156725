import { useContext, useEffect, useState } from "react"
import { IHistoryModelDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import { Loading } from "./loading"
import dayjs from "dayjs"
import { AppActionContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"

interface IHistoryCardProps extends IDefaultProps {
    tableName: string
    tableId: number
    triggerRefresh: number // Allows the parent to indicate that we should refresh the data
}

export const HistoryCard = (props: IHistoryCardProps) => {
    const { tableName, tableId, triggerRefresh } = props;
    const { AuditApi } = useContext(AppActionContext)!

    const [history, setHistory] = useState<IHistoryModelDocument[] | null>(null)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchHistory = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: AuditApi.apiAuditTableNameTableIdGet(tableName, tableId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching event evals.',
        })

        setHistory(data)
    }

    useEffect(() => {
        fetchHistory()
    }, [tableName, tableId, triggerRefresh])
    
    if (!history) return <Loading />

    return (
        <div className="card">
            <div className="card-header">
                History
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                {history.map(x => 
                    <div className="list-group-item" key={x.auditId}>
                        {dayjs(x.createdDate).format('MM/DD/YYYY')} - {x.description}<br />
                        <em>author: {x.createdByName}</em>
                    </div>)}
                </ul>
            </div>
        </div>
    )
}