import { navigate, useLocation } from "@reach/router"
import dayjs from "dayjs"
import { useContext, useEffect, useRef, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { ArrowLeftRightIcon, BullseyeIcon, EnvolpeIcon, EyeIcon, EyeSlashIcon, FileArrowDownIcon, FlagFilledIcon, FlagIcon, Mortorboard, PersonIcon, PersonPlusIcon, XCircleIcon } from "../assets"
import { IEventModelDocument, IGetSimilarEventsResponseDocument, IGroupCoachingModelDocument, IMailBlastRecipientDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { uuidv4, openUrlInNewTab } from "../services/helpers"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { useGrid, defaultGridState } from "../stores/grid-actions"
import { GridDataFetch, GridUserInteractionStateKey, IGridAction, IGridListItem } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { ICustomEmailBlastInitialValues } from "./custom-email-blast-form"
import { EventStatus } from "./event-form"
import { Grid } from "./grid"
import { AccountLevelDisplay, AccountStatusDisplay, DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { Modal } from "./modal"
import { SquareDeleteIcon } from "./partials"
import { AddRegistrantsForm, IAddRegistrantsForm } from './add-registrants-form'
import { useCallback } from "react"
import { IFilter } from "../stores/api-actions"
import { Form, Formik } from "formik"
import * as yup from 'yup'
import { FormikSelectField } from "./forms/formik-select-field"
import { IAppState } from "../stores/app-definitions"
import { AddNonRegistrantsForm, AddNonRegistrantsModalTitle, IAddNonRegistrantsForm } from "./add-non-registrants-form"
import { AddToCourseFormTab } from "./add-to-course-form"
import { LmsSendEmailModal } from "./lms-send-email-modal"

interface IEventFormRegistrantsState {
    flaggedAsAttended: boolean,
    disableFooter: boolean,
    disableFooterSeconds: number
}

interface IEventFormRegistrantsProps {
    eventToEdit?: IEventModelDocument | IGroupCoachingModelDocument
    eventContentTitle: string
    isGroupLearning: boolean
    hasLmsCourses: boolean
}

export const EventFormRegistrants = ({eventToEdit, eventContentTitle, isGroupLearning, hasLmsCourses}: IEventFormRegistrantsProps) => {

    //const { eventToEdit, eventContent } = useEventFormContext()
    const { users, activeBranches } = useContext(AppStateContext)!
    const { EventMgmtApi, LmsApi, addAlert } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const [addNonRegistrantModal, showHideAddNonRegistrantModal] = useModal()
	const [activeTab, setActiveTab] = useState<AddToCourseFormTab>(AddToCourseFormTab.main)

    const [state, setState] = useState<IEventFormRegistrantsState>({
        flaggedAsAttended: false,
        disableFooter: false,
        disableFooterSeconds: 0
    })

    const dataSource: GridDataFetch<IAppState> = async (_queryState, _registrants) => {
        const filters = (_queryState.filters ? JSON.stringify([..._queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (_queryState.sorts ? JSON.stringify(_queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined
        try {
            const queryResult = await makeHttpRequestWithUi({
                request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsGet(
                    eventToEdit?.eventId!,
                    (_queryState.page - 1) * _queryState.itemsPerPage,
                    _queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of group learning events.'
            })

            const rows = (queryResult.data.data || []).map<IGridListItem>(item => ({
                id: item.seminarAttendeeId.toString(),
                values: {
                    ...item,
                    regDate: item.regDate ? new Date(item.regDate) : null,
					// TODO: Add the course access field to the registrants endpoint
                }
            }))

            return {
                rows,
                count: queryResult.data.totalCount
            }
        } catch (e) {
            return {
                rows: [],
                count: 0
            }
        }
    }

    const [confirmRemoveRegistrantModal, showHideConfirmRemoveRegistrantModal] = useModal()
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [confirmSendNotificationModal, showHideConfirmSendNotificationEmail] = useModal()
    const [addRegistrantsModal, showHideAddRegistrantsModal] = useModal()
    const [sendEvalEmailModal, showHideSendEvalEmailModal] = useModal()
    const [sendLmsEmailModal, showHideSendLmsEmailModal] = useModal()
    const [sendDirectEnrollmentLmsEmailModal, showHideSendDirectEnrollmentLmsEmailModal] = useModal()
    const [addToCourseModal, showHideAddToCourseModal] = useModal()
    const [removeFromCourseModal, showHideRemoveFromCourseModal] = useModal()
    const [transferRegistrantModal, showHideTransferRegistrantModal] = useModal()
    const [ courseEnrollmentIds, setCourseEnrollmentIds ] = useState<number[]>([])

    const [similarEvents, setSimilarEvents] = useState<IGetSimilarEventsResponseDocument[]>()

    const unregisteredFilter: IFilter = {
        id: 'bActive',
        value: true,
        operator: '==',
        property: 'bActive',
        enabled: true,
        hidden: true
    }

    const handleAddNonRegistrants: IAddNonRegistrantsForm['onSave'] = useCallback(async values => {
		const ids = await makeHttpRequestWithUi({
			request: LmsApi.apiLmsAddCourseEnrollmentPost({
				...values,
				courseId: 0,
				groupCode: '',
				groupId: 0,
				communityId: eventToEdit?.branchId ?? 0
			}),
			toastSuccessMessage: 'Successfully added non-registrants.',
			toastErrorMessage: 'There was an error adding non-registrants.'
		})
		showHideAddNonRegistrantModal(false)
        setCourseEnrollmentIds(ids.data)
	}, [])

    const handleAddDirectEnrollmentAfterSave = useCallback(() => {
		showHideSendDirectEnrollmentLmsEmailModal(true)
    }, [])

    const location = useLocation()

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            queryState: {
                ...defaultGridState.queryState,
                filters: [unregisteredFilter],
            },
            userSessionStateKey: GridUserInteractionStateKey.EventManagementEditRegistrants,
            gridActions: [
				{
                    id: 'addRegistrants',
                    label: 'Add Registrants',
                    icon: <PersonPlusIcon className='mr-2' />,
                    disabled: eventToEdit?.status === EventStatus["canceled"],
                    onClick: () => showHideAddRegistrantsModal(true)
                } as IGridAction,
                {
                    id: 'emailRegistrants',
                    label: 'Email Registrants',
                    icon: <EnvolpeIcon className='mr-2' />,
                    onClick: (_gridState, _gridActions) => {
                        if (!eventToEdit) return
                        if (!_gridState.rows.some(o => o.selected)) {
                            addAlert({
                                id: uuidv4(),
                                title: 'No Registrants Selected',
                                body: 'Please select at least one registrant.',
                            })
                        } else {
                            const customBlast: ICustomEmailBlastInitialValues = {
                                recipients: _gridState.rows.filter(o => Boolean(o.values.email) && Boolean(o.selected)).map(r => ({
                                    name: `${r.values.fullName}`,
                                    email: `${r.values.email}`,
                                    ...r.values.ministryContactId ? { ministryContactId: r.values.ministryContactId } : {},
                                    ...r.values.prospectId ? { prospectId: r.values.prospectId } : {},
                                })) as IMailBlastRecipientDocument[],
                                eventContentId: eventToEdit.eventContentId.toString(),
                                subject: eventContentTitle,
                            }
                            // navigate(`/custom-blast?initialValues=${JSON.stringify(customBlast)}`)
                            openUrlInNewTab(`${location.origin}/custom-blast?initialValues=${encodeURIComponent(JSON.stringify(customBlast))}`)
                        }
                    }
                },
            ],
			nestedGridActions: [
                ...eventToEdit ? [
                    {
                        id: 'downloadSignInSheet',
                        label: 'Download Sign-In Sheet',
                        icon: <FileArrowDownIcon className='mr-2' />,
                        onClick: () => {
                            openUrlInNewTab(`${location.origin}/event-sign-in-sheet/${eventToEdit?.eventId}`)
                        }
                    }
                ] as IGridAction[] : [],
                ...(eventToEdit?.startDateTime && dayjs(eventToEdit?.startDateTime).startOf('day').isSameOrBefore(dayjs().startOf('day'))) ? [{
                    id: 'flagAsAttended',
                    label: 'Flag Attended',
                    icon: <FlagFilledIcon className='mr-2' />,
                    onClick: async (_gridState) => {
                        if (Object.values(_gridState.selectedRows).length == 0) {
                            addAlert({
                                id: uuidv4(),
                                title: 'No Registrants Selected',
                                body: 'Please select at least one registrant.',
                            })
                        } else {
                            await makeHttpRequestWithUi({
                                request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsFlagAsAttendedPost(eventToEdit.eventId, Object.values(_gridState.selectedRows).map(row => parseInt(row.id))),
                                toastSuccessMessage: 'Successfully marked registrants as attended.',
                                toastErrorMessage: 'Encountered an error marking registrants as attended.'
                            })

                            // Used to fetch here, but now we do it in the "Send Eval Email" modal, so we pickup all of the currently checked registrants.
                            showHideSendEvalEmailModal(true)
                            setState({...state, flaggedAsAttended: true})
                        }
                    }
                },
                {
                    id: 'removeAttendedFlag',
                    label: 'Flag Not Attended',
                    icon: <FlagIcon className='mr-2' />,
                    onClick: async (_gridState) => {
                        if (Object.values(_gridState.selectedRows).length == 0) {
                            addAlert({
                                id: uuidv4(),
                                title: 'No Registrants Selected',
                                body: 'Please select at least one registrant.',
                            })
                        } else {
                            await makeHttpRequestWithUi({
                                request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsFlagAsNotAttendedPost(eventToEdit.eventId, Object.values(_gridState.selectedRows).map(row => parseInt(row.id))),
                                toastSuccessMessage: 'Successfully marked registrants as not attended.',
                                toastErrorMessage: 'Encountered an error marking registrants as not attended.',
                            })
                            gridActions.doFetch()
                        }
                    }
                }] as IGridAction[] : [],
                {
                    id: 'sendEvalRequestEmail',
                    label: 'Request Evaluation',
                    icon: <EnvolpeIcon className='mr-2' />,
                    onClick: (_gridState) => {
                        if (!_gridState.rows.some(o => o.selected)) {
                            addAlert({
                                id: uuidv4(),
                                title: 'No Registrants Selected',
                                body: 'Please select at least one registrant.',
                            })
                        } else {
                            showHideSendEvalEmailModal(true)
                        }
                    }
                },
                ...hasLmsCourses ? [
                    {
                        id: 'addToCourse',
                        label: 'Add to Course',
                        icon: <PersonPlusIcon className='mr-2' />,
                        onClick: (_gridState) => {
                            if (!_gridState.rows.some(o => o.selected)) {
                                addAlert({
                                    id: uuidv4(),
                                    title: 'No Registrants Selected',
                                    body: 'Please select at least one registrant.',
                                })
                            } else {
                                showHideAddToCourseModal(true)
                            }
                        }
                    },
                    {
                        id: 'addDirectEnrollment',
                        label: 'Add Direct Enrollment',
                        icon: <Mortorboard className='mr-2' />,
                        onClick: (_gridState) => {
                            showHideAddNonRegistrantModal(true)
                        }
                    },
                    {
                        id: 'viewDirectEnrollment',
                        label: 'View Direct Enrollment',
                        icon: <Mortorboard className='mr-2' />,
                        onClick: (_gridState) => {
                            navigate(`/manual-course-assignment?eventId=${eventToEdit?.eventId}`)
                        }
                    },
                ] as IGridAction[] : [],
                {
                    id: 'showUnregistered',
                    label: (gridState) => gridState.queryState.filters?.find(o => o.id === unregisteredFilter.id)?.value ? 'Show Unregistered' : 'Hide Unregistered',
                    icon: (gridState) => gridState.queryState.filters?.find(o => o.id === unregisteredFilter.id)?.value ? <EyeIcon className='mr-2' /> : <EyeSlashIcon className='mr-2' />,
                    onClick: (_gridState, _gridActions) => {
                        const _filters = [...gridState.queryState.filters || []]
                        const _unregisteredFilter = _filters.find(o => o.id === unregisteredFilter.id)
                        if (_unregisteredFilter) {
                            if (_unregisteredFilter.value) {
                                _unregisteredFilter.value = false
                                _unregisteredFilter.enabled = false
                            }
                            else {
                                _unregisteredFilter.value = true
                                _unregisteredFilter.enabled = true
                            }

                            // Splice the updated filter in
                            const filterIdx = _filters.findIndex(o => o.id = _unregisteredFilter.id)
                            _filters.splice(filterIdx, 1, _unregisteredFilter)

                            _gridActions.updateColumnFilters(_filters)
                        }
                    }
                }
			],
            columns: [
                {
                    property: 'bActive',
                    title: 'Registered',
                    width: 60,
                    type: 'boolean',
                    render: DefaultGridCellDisplay,
                    allowFilters: false,
                    hide: true
                },
                {
                    property: 'organizationName',
                    title: 'Ministry',
                    width: 135,
                    type: 'string',
                    render: (col, row) => row.values.ministryId || row.values.prospectId ? <a target='_blank' href={row.values.ministryId ? `/ministry-info/${row.values.ministryId}` : `/prospects/${row.values.prospectId}`}>{row.values[col.property]}</a> : row.values[col.property],
                    allowFilters: true
                },
                {
                    property: 'fullName',
                    title: 'Name',
                    width: 65,
                    type: 'string',
                    render: (col, row) => row.values.bActive ? row.values[col.property] : <span style={{textDecoration: 'line-through'}}>{row.values.fullName}</span>,
                    allowFilters: true
                },
                {
                    property: 'email',
                    title: 'Email',
                    width: 150,
                    type: 'string',
                    render: (_, row) => <a href={`mailto:${row.values.email}`}>{row.values.email}</a>,
                    allowFilters: true
                },
                {
                    property: 'title',
                    title: 'Title',
                    width: 100,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                    hide: true
                },
                {
                    property: 'branchAbbr',
                    title: 'Community',
                    width: 45,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                    filterOptions: activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
                },
                {
                    property: 'trainingAreaAbbr',
                    type: 'string',
                    width: 45,
                    allowFilters: true,
                    title: 'Training Area',
                    render: DefaultGridCellDisplay,
                },
                {
                    property: 'businessPhone',
                    title: 'Phone',
                    width: 55,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    allowFilters: true
                },
                {
                    property: 'physicalCity',
                    title: 'City',
                    width: 75,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                    hide: true
                },
                {
                    property: 'physicalStateAbbr',
                    title: 'State',
                    width: 35,
                    type: 'string',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                    hide: true
                },
                {
                    property: 'regDate',
                    title: 'Date Registered',
                    width: 65,
                    type: 'date',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                },
                {
                    property: 'isPaid',
                    title: 'Paid',
                    width: 50,
                    type: 'boolean',
                    render: (_, row) => row.values.isPaid ? <a href={`/order-management#view=${row.values.orderId}`} target="_blank">Yes</a> : <span>No</span>,
                    align: 'center',
                    allowFilters: true,
                    hide: !(eventToEdit?.seatCost && eventToEdit.seatCost > 0)
                },
                {
                    property: 'bAttended',
                    title: 'Attended',
                    width: 50,
                    type: 'boolean',
                    render: DefaultGridCellDisplay,
                    align: 'center',
                    conditionalCellCSSFormatting: (row) => {
                        if (row.values.bAttended) return 'bg-success-7'
                        return ''
                    },
                    allowFilters: true
                },
                {
                    property: 'bSentConfirmation',
                    title: 'Confirmation Sent',
                    width: 75,
                    type: 'boolean',
                    align: 'center',
                    render: DefaultGridCellDisplay,
                    conditionalCellCSSFormatting: (row) => {
                        if (row.values.bSentConfirmation) return 'bg-success-7'
                        return ''
                    },
                    allowFilters: true
                },
                {
                    property: 'bCourseAccess',
                    title: 'Course Access',
                    width: 75,
                    type: 'boolean',
                    align: 'center',
					hide: true,
                    render: DefaultGridCellDisplay,
                    conditionalCellCSSFormatting: (row) => {
                        if (row.values.bCourseAccess) return 'bg-success-7'
                        return ''
                    },
                    allowFilters: true
                },
                {
                    property: 'evalStatus',
                    title: 'Eval Status',
                    width: 55,
                    type: 'string',
                    align: 'center',
                    render: DefaultGridCellDisplay,
                    allowFilters: true,
                    filterOptions: [
                        { label: 'Sent', value: 'Sent' },
                        { label: 'Not sent', value: 'Not sent' },
                    ],
                },
                {
                    property: 'ministryLevelId',
                    type: 'string',
                    width: 70,
                    allowFilters: true,
                    filterOptions: [
                        { label: 'Teaching', value: '1' },
                        { label: 'Coaching', value: '2' },
                        { label: 'Consulting', value: '3' },
                    ],
                    title: 'Acct Level',
                    render: AccountLevelDisplay,
                    description: 'The level the ministry is assigned.',
                },
                {
                    property: 'profileStatus',
                    type: 'string',
                    width: 75,
                    allowFilters: true,
                    title: 'Account Status',
                    render: AccountStatusDisplay,
                    description: 'Displays OK when the account is complete.<br>GRC is displayed when the ministry is within a 60 day grace period after a tax year roll.<br>SUB is displayed when the account has been submitted but not yet approved.<br>INC is displayed when the ministry has an incomplete account.<br>PRE is displayed when the ministry is within the 60 day window after being moved to the granting level.',
                    align: 'center',
                    filterOptions: [
                        { label: 'OK ', value: 'OK' },
                        { label: 'GRC ', value: 'GRC' },
                        { label: 'PRE ', value: 'PRE ' },
                        { label: 'SUB ', value: 'SUB' },
                        { label: 'INC ', value: 'INC' },
                    ],
                },
                {
                    property: 'grid_actions',
                    type: 'actions',
                    width: 50,
                    disableSort: true,
                    title: 'Actions',
                    render: GridActionCell,
                    align: 'center',
                },
            ],
            dataSource,
            rowActions: {
                entityInfo: {
                    id: 'entityInfo',
                    action: async (options) => options.e.stopPropagation(),
                    icon: (row) => 
                        row.values.isProspect ?
                            <BullseyeIcon />
                        :
                            <PersonIcon />,
                    tooltipText: (row) => 'View Contact',
                    disabled: (row) => !row.values.ministryContactId && !row.values.organizationId,
                    url: (row) => 
                    row.values.isProspect ?
                        `/prospects/${row.values.organizationId}`
                    :
                        `/contact-info/${row.values.ministryContactId}`,
                },
                removeRegistrant: {
                    id: 'removeRegistrant',
                    action: async ({ row }) => {
                        setSelectedRow(row)
                        showHideConfirmRemoveRegistrantModal(true)
                    },
                    tooltipText: 'Remove',
                    icon: <SquareDeleteIcon />,
                },
                sendNotification: {
                    id: 'sendNotification',
                    action: async ({ row }) => {
                        setSelectedRow(row)
                        showHideConfirmSendNotificationEmail(true)
                    },
                    tooltipText: 'Send Confirmation Email',
                    icon: <EnvolpeIcon />,
                    disabled: eventToEdit?.endDateTime ? dayjs(eventToEdit?.endDateTime).isBefore(dayjs()) : false
                },
                removeFromCourse: {
                    id: 'removeFromCourse',
                    action: async ({ row }) => {
                        if (!eventToEdit) return
                        setSelectedRow(row)
						showHideRemoveFromCourseModal(true)
                    },
					hidden: (row) => !row.values.bCourseAccess || !row.values.bCourseAccessOverride || !!row.values.bAttended,
                    tooltipText: 'Remove from course',
                    icon: <XCircleIcon />,
                },
                transferRegistrant: {
                    id: 'transferRegistrant',
                    action: async ({ row }) => {
                        if (!eventToEdit) return
                        setSelectedRow(row)
                        const { data } = await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtEventEventIdGetSimilarEventsGet(eventToEdit.eventId, eventToEdit.eventId),
                            disableSuccessToast: true,
                            toastErrorMessage: 'Error retrieving other events.'
                        })
                        if (data.length) {
                            setSimilarEvents(data)
                            showHideTransferRegistrantModal(true)
                        } else {
                            addAlert({
                                id: uuidv4(),
                                title: 'No Other Events',
                                body: 'There are no events this registrant can be transferred to.',
                            })
                        }
                    },
                    tooltipText: 'Transfer to Other Event',
                    icon: <ArrowLeftRightIcon />,
                    //hidden: () => !!Boolean(eventToEdit?.isLegacyEvent) // TB 20230713 - Enough time has passed that I am hoping we no longer need to hide this.
                },
            },
            hideGridHeader: false,
            hideGridFooter: false,
            rowSelectEnabled: true,
        },
        state
    )

    const handleAddRegistrantsSave: IAddRegistrantsForm['onSave'] = useCallback(async values => {
        if (!eventToEdit) return []

        if (values.contactType === 'ministry' && values.ministryContactIds.length) {
            const { data } = await makeHttpRequestWithUi({
                request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsPost(eventToEdit?.eventId, { ministryId: parseInt(values.ministryId), ministryContactIds: values.ministryContactIds.map(o => parseInt(o)) }),
                toastErrorMessage: 'Encountered an error adding registrants.',
                toastSuccessMessage: 'Successfully added registrants.'
            })
            return data
        }

        if (values.contactType === 'outside') {
            const { data } = await makeHttpRequestWithUi({
                request: EventMgmtApi.apiEventMgmtEventEventIdAddOutsideRegistrantPost(eventToEdit.eventId, { ...values, fullName: null }),
                toastErrorMessage: 'Encountered an error adding registrants.',
                toastSuccessMessage: 'Successfully added registrants.'
            })
            return data
        }

        return []
    }, [])

    const handleSendConfirmationEmail: IAddRegistrantsForm['sendConfirmationEmail'] = useCallback(async values => {
        if (!eventToEdit) return
        
        await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventEventIdSendRegistrantNotificationsPost(eventToEdit.eventId, values),
            toastErrorMessage: 'Encountered an error sending notifications.',
            toastSuccessMessage: 'Successfully sent notifications.',
        })
    }, [])

    const [time, setTime] = useState<number>(5);
    const currentTimer = useRef<NodeJS.Timeout>();
    useEffect(() => {
        return () => clearInterval(currentTimer.current!);
    }, []);
    const startTimer = () => {
        setTime(5);
        currentTimer.current = setInterval(() => {
            let localTime = 0
            setTime((prev) => {
                localTime = prev - 1
                console.log('newTime', localTime);
                return localTime
            }
            );
            console.log('hello', localTime);
            if (localTime < 0) {
                clearInterval(currentTimer.current!)

            }
        }, 1000);
    };

    return (
        <>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>

            <Modal
                {...addRegistrantsModal}
                modalTitle='Add Registrants'
            >
                {eventToEdit?.status !== EventStatus.canceled &&
                    <AddRegistrantsForm
                        afterSave={() => gridActions.doFetch()}
                        initialBranchId={users?.find(o => o.presenterId?.toString() === eventToEdit?.presenterIds?.slice(0, 1)?.toString())?.branchId?.toString()}
                        onSave={handleAddRegistrantsSave}
                        sendConfirmationEmail={handleSendConfirmationEmail}
                        //@ts-ignore
                        isSeries={eventToEdit.isSeries}
                    />
                }
            </Modal>

            <Modal
                {...confirmSendNotificationModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmSendNotificationEmail(false)} >Cancel</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                if (!selectedRow || typeof selectedRow.values.email !== 'string' || !selectedRow.values.eventId) return
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdSendRegistrantNotificationsPost(parseInt(selectedRow.values.eventId.toString()), [{ seminarAttendeeId: parseInt(selectedRow.id), attendeeGuid: selectedRow.values.attendeeGuid?.toString() ?? null, email: selectedRow.values.email }]),
                                    toastErrorMessage: 'Encountered an error sending confirmation email.',
                                    toastSuccessMessage: 'Successfully sent confirmation email.',
                                })
                                gridActions.doFetch()
                                showHideConfirmSendNotificationEmail(false)
                            }}
                        >
                            Send
                        </button>
                    </>
                }
            >
                Would you like to send a registration confirmation email to this registrant?
            </Modal>

            <Modal
                {...confirmRemoveRegistrantModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmRemoveRegistrantModal(false)} >Cancel</button>
                        <button
                            className='btn btn-warning'
                            onClick={async () => {
                                if (!eventToEdit) return
                                if (!selectedRow) return
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsSeminarAttendeeIdDelete(eventToEdit?.eventId, parseInt(selectedRow.id)),
                                    toastErrorMessage: 'There was an error removing this registrant.',
                                    toastSuccessMessage: 'Successfully remove registrant.',
                                })
                                gridActions.doFetch()
                                showHideConfirmRemoveRegistrantModal(false)
                            }}
                        >
                            Remove
                        </button>
                    </>
                }
            >
                Are you sure you want to remove this registrant?
                
                {//@ts-ignore
                eventToEdit.isSeries && 
                <div className="alert alert-warning">
                    <b>NOTE:</b> This is a series. Removing a registrant to this event will automatically remove them to all events
                    in the series.
                </div>
                }
            </Modal>

            <Modal
                {...sendEvalEmailModal}
                modalTitle='Send Eval Email?'
                _onModalHidden={() => {
                    gridActions.doFetch() // We don't do it when registrants are flagged as attended, so do it here
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => {
                            showHideSendEvalEmailModal(false)

                            if (state.flaggedAsAttended && hasLmsCourses) {
                                showHideSendLmsEmailModal(true)
                                setState({...state, flaggedAsAttended: false})
                            }
                        }} >No</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                if (!eventToEdit) return
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsSendEvalRequestEmailPost(eventToEdit.eventId, Object.values(gridState.selectedRows).map(row => parseInt(row.id))),
                                    toastErrorMessage: 'There was an error while sending the eval request emails. WARNING: Verify that the emails did not go out before trying again.',
                                    toastSuccessMessage: 'Successfully sent eval request emails.',
                                })
                                //gridActions.doFetch()
                                showHideSendEvalEmailModal(false)

                                if (state.flaggedAsAttended && hasLmsCourses) {
                                    showHideSendLmsEmailModal(true)
                                    setState({...state, flaggedAsAttended: false})
                                }
                            }}
                        >
                            Send Eval Request
                        </button>
                    </>
                }
            >
                Would you like to send these {Object.values(gridState.selectedRows).length} registrants an email inviting them to fill out an <strong>evaluation form</strong> providing you with feedback on their event experience? This is recommended.
            </Modal>

            <Modal
                {...addToCourseModal}
                modalTitle='Add contacts to course?'
                _onModalHidden={() => {
                    gridActions.doFetch()
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => {
                            showHideAddToCourseModal(false)
                        }} >No</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                if (!eventToEdit) return
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsFlagAsOverrideCourseAccessPost(eventToEdit.eventId, Object.values(gridState.selectedRows).map(row => parseInt(row.id))),
									toastErrorMessage: 'There was an error while adding the registrants to the course.',
									toastSuccessMessage: 'Successfully added registrants to the course.',
								})
                                showHideSendLmsEmailModal(true)
                                gridActions.doFetch()
                                showHideAddToCourseModal(false)
                            }}
                        >
                            Add to course
                        </button>
                    </>
                }
            >
                Would you like to add {Object.values(gridState.selectedRows).length < 2 ? 'this' : 'these'} {Object.values(gridState.selectedRows).length} {Object.values(gridState.selectedRows).length < 2 ? 'registrant' : 'registrants'} to the event's course without flagging them as attended?
            </Modal>

            <Modal
                {...removeFromCourseModal}
                modalTitle='Remove contacts from course?'
                _onModalHidden={() => {
                    gridActions.doFetch()
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => {
                            showHideRemoveFromCourseModal(false)
                        }} >No</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                if (!eventToEdit) return
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsUnflagAsOverrideCourseAccessPost(eventToEdit.eventId, [parseInt(selectedRow!.id)]),
									toastErrorMessage: 'There was an error while removing the registrant from the course.',
									toastSuccessMessage: 'Successfully removed registrant from the course.',
								})
                                gridActions.doFetch()
                                showHideRemoveFromCourseModal(false)
                            }}
                        >
                            Remove from course
                        </button>
                    </>
                }
            >
                Would you like to remove this registrant from the event's course?
            </Modal>

            {/* This modal is used to send LMS notification emails after a registrant(s) is flagged as attended.
                There is a separate/different modal (See: LmsSendEmailModal further down on this page) used for 
                sending LMS notifications to ministry leaders when user used "Add to Course" button. */}
            <Modal
                {...sendLmsEmailModal}
                modalTitle='Send LMS Emails?'
                _onModalHidden={() => {
                    gridActions.doFetch() // We don't do it when registrants are flagged as attended, so do it here
                    setSelectedRow(undefined)
                }}
                _onModalShown={() => {
                    setState({...state, disableFooter: true})
                    setTimeout(() => setState({...state, disableFooter: false}), 5000)
                    startTimer()
                    // setState({...state, disableFooterSeconds: 5})
                    // const countdownInterval = setInterval(() => {
                    //     setState({...state, disableFooterSeconds: state.disableFooterSeconds--})
                    //     if (state.disableFooterSeconds < 0) {
                    //         clearInterval(countdownInterval)
                    //     }
                    // }, 1000)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideSendLmsEmailModal(false)} disabled={state.disableFooter} >No</button>
                        <button
                            className='btn btn-primary'
                            disabled={state.disableFooter}
                            onClick={async () => {
                                if (!eventToEdit) return
                                await makeHttpRequestWithUi({
                                    request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsSendCourseAccessNotificationEmailPost(eventToEdit.eventId, Object.values(gridState.selectedRows).map(row => parseInt(row.id))),
                                    toastErrorMessage: 'There was an error while sending the LMS emails. WARNING: Verify that the LMS emails did not go out before trying again.',
                                    toastSuccessMessage: 'Successfully sent LMS emails.',
                                })
                                //gridActions.doFetch()
                                showHideSendLmsEmailModal(false)
                            }}
                        >
                            {state.disableFooter === false ? 'Send LMS Emails' : `Ready in...${time}`}
                        </button>
                    </>
                }
            >
                Would you like to send these {Object.values(gridState.selectedRows).length} registrants an email notifying them they have access to related <strong>LMS content</strong>?
            </Modal>


            <Modal
                {...transferRegistrantModal}
                modalTitle='Transfer to Other Event'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideTransferRegistrantModal(false)} >Cancel</button>
                    </>
                }
            >
                <Formik
                    initialValues={{
                        transferEventId: ''
                    }}
                    validationSchema={yup.object({
                        transferEventId: yup.string().required('Required')
                    })}
                    onSubmit={async (values) => {
                        if (!selectedRow || typeof selectedRow.values.seminarAttendeeId !== 'number') {
                            showHideTransferRegistrantModal(false)
                            return
                        }
                        await makeHttpRequestWithUi({
                            request: EventMgmtApi.apiEventMgmtTransferRegistrantPut({ seminarAttendeeId: selectedRow.values.seminarAttendeeId, newEventId: parseInt(values.transferEventId) }),
                            toastSuccessMessage: 'Successfully transferred registrant.',
                            toastErrorMessage: 'Encountered an error transferring registrant.',
                        })
                        gridActions.doFetch()
                        showHideTransferRegistrantModal(false)
                    }}
                >
                    <Form>
                        <p>What other event would you like to transfer <b>{selectedRow?.values.fullName}</b> to?</p>

                        <FormikSelectField field={{ name: 'transferEventId', label: 'Transfer to event' }} options={similarEvents?.map(o => ({ label: `${o.eventTitle}`, value: `${o.eventId}` }))} />

                        <button className='btn btn-primary' type='submit'>Transfer</button>
                    </Form>
                </Formik>
            </Modal>
            { !isGroupLearning &&
                <Modal
				{...addNonRegistrantModal}
				modalTitle={
					<h2>{ AddNonRegistrantsModalTitle[activeTab] }</h2>
				}
				closeModal={() => {
					if ( activeTab !== AddToCourseFormTab.main ) {
						setActiveTab(AddToCourseFormTab.main)
					}
					else {
						showHideAddNonRegistrantModal(false)
					}
				}}
				size='lg'
			>
				<AddNonRegistrantsForm
                    afterSave={handleAddDirectEnrollmentAfterSave}
					onSave={handleAddNonRegistrants}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					eventToEdit={eventToEdit as IEventModelDocument}
				/>
			</Modal>
            }
            {/* This modal is used to send LMS notification emails to non-registrants after user chooses "Add Direct Enrollment". */}
            { !isGroupLearning &&
                <LmsSendEmailModal modalProps={sendDirectEnrollmentLmsEmailModal} courseEnrollmentIds={courseEnrollmentIds} />
            }
        </>
    )
}