import React, { useContext, useEffect, useState } from 'react'
import { IDefaultProps } from './component-definitions'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { AppActionContext } from '../app-store-provider'
import { IEventContentOptionDocument, ITrainerEvalSummaryDocument, ITrainerEvalSummaryFullDocument } from '../open-api'
import { isSelectFieldOption, SimpleSelectField } from './forms'
import { TrainerEval } from './trainer-eval'
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem } from '../stores/grid-definitions'
import { filterGridListItems, sortListBySorts } from '../services/helpers'
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { DefaultGridCellDisplay, GridActionCell } from './grid-cell-displays'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { Helmet } from 'react-helmet'
import { ClipboardCheckIcon, PrinterIcon, SearchIcon } from '../assets'
import { Modal } from './modal'

interface ITrainerEvalsProps extends IDefaultProps { }

export const TrainerEvals = (props: ITrainerEvalsProps) => {
    const {SeminarTrainerEvalApi} = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const [eventOptions, setEventOptions] = useState<IEventContentOptionDocument[]>()
    const [activeEventContentId, setActiveEventContentId] = useState<number>()
    const [trainerEvals, setTrainerEvals] = useState<ITrainerEvalSummaryDocument[]>([])
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [activeEval, setActiveEval] = useState<ITrainerEvalSummaryFullDocument>()

    const [viewEvalModal, showHideViewEvalModal] = useModal()

    const fetchEventOptions = async () => {
        const eventsQuery = await makeHttpRequestWithUi({
            request: SeminarTrainerEvalApi.apiSeminarTrainerEvalEventContentOptionsGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch event options.'
        })

        setEventOptions(eventsQuery.data)
    }

    const fetchTrainerEvals = async (eventContentId: number) => {
        const evalQuery = await makeHttpRequestWithUi({
            request: SeminarTrainerEvalApi.apiSeminarTrainerEvalByEventContentEventContentIdFullGet(eventContentId),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch trainer evals.'
        })

        setTrainerEvals(evalQuery.data)
    }

    const dataSource: GridDataFetch<ITrainerEvalSummaryDocument[]> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.seminarTrainerEvalId!.toString(),
                values: {
                    ...o
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('TrainerEvals > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    useEffect(() => {
        fetchEventOptions()
    }, [])

    useEffect(() => {
        if (eventOptions && eventOptions[0]) {
            setActiveEventContentId(eventOptions[0].eventContentId)
        }
    }, [eventOptions])

    useEffect(() => {
        if (activeEventContentId) fetchTrainerEvals(activeEventContentId)
    }, [activeEventContentId])

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            dataSource,
            userSessionStateKey: GridUserInteractionStateKey.TrainerEvals,
            columns: [
                {
                    property: 'presenterName',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Area Director',
                    render: DefaultGridCellDisplay,
                },
                {
                    property: 'evalDate',
                    type: 'date',
                    width: 100,
                    allowFilters: true,
                    title: 'Eval Date',
                    render: DefaultGridCellDisplay,
                },
                {
                    property: 'teachabilityOfDeck',
                    type: 'number',
                    width: 80,
                    allowFilters: true,
                    title: 'Teachability Of Deck',
                    render: DefaultGridCellDisplay,
                },
                {
                    property: 'audienceEngagement',
                    type: 'number',
                    width: 80,
                    allowFilters: true,
                    title: 'Audience Engagement',
                    render: DefaultGridCellDisplay,
                },
                {
                    property: 'prepTimeText',
                    type: 'number',
                    width: 80,
                    allowFilters: true,
                    title: 'Prep Time',
                    render: DefaultGridCellDisplay,
                },
                {
                    property: 'audienceConnectedExplain',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Audience Connected Explain',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.audienceConnectedExplain === 'string' ? row.values.audienceConnectedExplain : undefined
                },
                {
                    property: 'audienceStuckExplain',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Audience Stuck Explain',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.audienceStuckExplain === 'string' ? row.values.audienceStuckExplain : undefined
                },
                {
                    property: 'significantAdjustments',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Significant Adjustments',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.significantAdjustments === 'string' ? row.values.significantAdjustments : undefined
                },
                {
                    property: 'changeNextEvents',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Change Next Events',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.changeNextEvents === 'string' ? row.values.changeNextEvents : undefined
                },
                {
                    property: 'otherTools',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Other Tools',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.otherTools === 'string' ? row.values.otherTools : undefined
                },
                {
                    property: 'somethingNew',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Something New',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.somethingNew === 'string' ? row.values.somethingNew : undefined
                },
                {
                    property: 'comments',
                    type: 'string',
                    width: 150,
                    allowFilters: true,
                    title: 'Comments',
                    render: DefaultGridCellDisplay,
                    tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
                },
                {
                    property: 'grid_actions',
                    type: 'actions',
                    width: 50,
                    disableSort: true,
                    title: 'Actions',
                    render: GridActionCell,
                },
            ],
            rowActions: {
                viewEval: {
                    id: 'viewEval',
                    tooltipText: 'View Eval',
                    icon: <SearchIcon />,
                    action: async (options) => {
                        options.e.stopPropagation()
                        setSelectedRow(options.row)
                        showHideViewEvalModal(true)
                    },
                }
            },
            gridActions: [
                {
                    id: 'printAll',
                    label: 'Print All',
                    onClick: (gridState) => window.open(`/trainer-evals-print/${gridState.rows[0].values.eventContentId?.toString()}`),
                    buttonClass: 'btn-secondary',
                    icon: <div className='mr-1'><PrinterIcon /></div>
                }
            ],
            usingLocalData: true,
            disabledPagination: true,
            hideGridHeader: false,
            hideGridFooter: false,
            rowSelectEnabled: false
        },
        trainerEvals
    )

    useEffect(() => {
        gridActions.doFetch()
    }, [trainerEvals])

    useEffect(() => {
        const fetchEvalData = async () => {
                if (selectedRow) {
                const response = await makeHttpRequestWithUi({
                    request: SeminarTrainerEvalApi.apiSeminarTrainerEvalSeminarTrainerEvalIdFullGet(parseInt(selectedRow.id)),
                    disableLoading: true,
                    disableSuccessToast: true,
                    toastErrorMessage: 'There was a problem fetching the eval data.'
                })

                setActiveEval(response.data)
                console
                .log('activeEval', activeEval)
            }
        }

        fetchEvalData()
    }, [selectedRow])

    const nextEvalHandler = async () => {
        const i = gridState.rows.findIndex(x => x.id == selectedRow?.id);
        if (i == -1 || gridState.rows.length <= i)  return
        const nextIndex = i + 1
        const nextRow = gridState.rows[nextIndex]
        setSelectedRow(nextRow);
    }

    const previousEvalHandler = async () => {
        const i = gridState.rows.findIndex(x => x.id == selectedRow?.id);
        if (i == -1 || i == 0)  return
        const previousIndex = i - 1
        const previousRow = gridState.rows[previousIndex]
        setSelectedRow(previousRow);
    }


    return (
        <>
            <Helmet>
                <title>Trainer Evals</title>
            </Helmet>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <ClipboardCheckIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Trainer Evals</h3>
                </div>
                <div className='m-2 w-25'>
                <SimpleSelectField
                    fieldProps={{ name: '', label: 'Workshops' }}
                    options={eventOptions?.map(o => ({ label: `${o.title}`, value: `${o.eventContentId}` }))}
                    onChange={(option) => {
                        if (isSelectFieldOption(option)) {
                            setActiveEventContentId(parseInt(option.value))
                        }
                    }}
                    value={activeEventContentId?.toString()}
                />
                </div>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>

            <Modal
                {...viewEvalModal}
                modalTitle='Trainer Eval'
                size='xl'
                _onModalHidden={
                    () => {
                        setSelectedRow(undefined)
                        setActiveEval(undefined)
                    }
                }>
                    { 
                        activeEval ? 
                        <TrainerEval trainerEval={activeEval} nextEvalHandler={nextEvalHandler} previousEvalHandler={previousEvalHandler} />
                        :
                        null
                    }
            </Modal>
        </>
    )
}