import { useEffect, useContext } from 'react'
import { AppActionContext } from '../app-store-provider'

// This code helps the users session state to remain consistent even if they have multiple
// tabs open. AppOnWindowFocus has been added to App.tsx so that it fires whenever any of
// this apps open browser tabs regain focus.

export const AppOnWindowFocus = () => {
    const appActions = useContext(AppActionContext)!
    

    useEffect(() => {
        const onWindowFocus = () => {
            // Was getting tired of the constant refreshes while working on this in dev.
            // Check that we are in prod before making this call.

            // NOTE: When a user logs out, the user is on the logout page. The below line stops the user from being redirected
            // back to the logout page after re login.
            if (window.location.pathname !== '/logout') appActions.UserSessionActions.refreshUserSessionState()
        }

        window.addEventListener('focus', onWindowFocus)

        return function cleanup() {
            window.removeEventListener('focus', onWindowFocus)
        }
    }, [])

    return null
}