import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AppActionContext } from '../app-store-provider'
import { IEventRegistrantDocument, IGroupCoachingRegistrantDocument } from '../open-api'
import { sortListByProperty } from '../services/helpers'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { IDefaultProps } from './component-definitions'

interface IGroupCoachingRegistrantsSignInSheetProps extends IDefaultProps {
    eventContentId?: string
    eventId?: string
}

export const GroupCoachingRegistrantsSignInSheet = ({ eventContentId, eventId }: IGroupCoachingRegistrantsSignInSheetProps) => {
    const { GroupCoachingApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()


    const [registrants, setRegistrants] = useState<IGroupCoachingRegistrantDocument[]>([])
    const [title, setTitle] = useState<string>()
    const fetchData = async () => {
        if (!eventContentId || !eventId) return
        const registrantsQuery = await makeHttpRequestWithUi({
            request: GroupCoachingApi.apiGroupCoachingEventContentIdRegistrantsGet(parseInt(eventContentId)),
            toastErrorMessage: 'There was an error retrieving registrants.',
            disableSuccessToast: true,
        })
        setRegistrants(sortListByProperty(registrantsQuery.data.filter(x => x.bActive == true), 'organizationName'))

        const sessionToEditQuery = await makeHttpRequestWithUi({
            request: GroupCoachingApi.apiGroupCoachingEventContentIdEventIdGet(parseInt(eventContentId), parseInt(eventId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setTitle(sessionToEditQuery.data.title)
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        // Title is the last thing loaded - use it as a proxy for 'everything is loaded and rendered'
        if (title) window.print()
    }, [title])

    return (
        <>
            <Helmet>
                <style>
                    @media print &#123;
                    @page &#123;
                    margin: 0;
                    size: landscape;
                    &#125;
                    &#125;
                </style>
            </Helmet>
            <div className='m-2'>
                <h2 style={{ textAlign: 'center' }}>{title} </h2>

                <SignInSheet registrants={registrants} />
            </div>
        </>
    )
}

interface IEventRegistrantsSignInSheetProps extends IDefaultProps {
    eventId?: string
}

export const EventRegistrantsSignInSheet = ({ eventId }: IEventRegistrantsSignInSheetProps) => {
    const { EventMgmtApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()


    const [registrants, setRegistrants] = useState<IEventRegistrantDocument[]>([])
    const [title, setTitle] = useState<string>()
    const [checkInUrl, setCheckInUrl] = useState<string>()
    const fetchData = async () => {
        if (!eventId) return
        const { data } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventEventIdRegistrantsSimpleSearchGet(parseInt(eventId)),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching event registrants.',
        })
        setRegistrants(sortListByProperty(data.filter(x => x.bActive == true), 'organizationName'))

        const { data: eventToEdit } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventEventIdGet(parseInt(eventId)),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving event to edit.',
        })

        const { data: selectedEventContent } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(eventToEdit.eventContentId),
            disableSuccessToast: true,
            toastErrorMessage: 'Enountered an error retrieving event content record.',
        })
        setTitle(selectedEventContent.title + ' - ' + eventToEdit.siteName + ' - ' + dayjs(eventToEdit.startDateTime!).format('MM/DD/YYYY'))

        eventToEdit.checkInUrl && setCheckInUrl(eventToEdit.checkInUrl)
    }
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        // Once title is loaded, let's give another seconde for the QR Code to render, then print the page.
        if (title) {
            setTimeout(() => {
                window.print()
            }, 1000)
        }
    }, [title])

    return (
        <>
            <Helmet>
                <style>
                    @media print &#123;
                    @page &#123;
                    margin: 0;
                    size: landscape;
                    &#125;
                    &#125;
                </style>
            </Helmet>
            <div className='m-2'>
                {/* { checkInUrl && 
                    <div className='mt-5 d-flex justify-content-center'>
                        <div className='pr-5'>
                            Scan QR Code with your mobile device to check-in and gain access to any online resources, or go directly to:<br />
                            {checkInUrl}
                        </div>
                        <div><QRCodeCanvas value={checkInUrl} /></div>
                    </div> } */}
                <h2 style={{ textAlign: 'center' }}>{title}</h2>
                <SignInSheet registrants={registrants} />
            </div>
        </>
    )
}


interface ISignInSheetProps {
    registrants: {
        seminarAttendeeId: number
        organizationName: string | null
        fullName: string | null
        title: string | null
        businessPhone: string | null
        email: string | null
    }[]
}

const SignInSheet = ({ registrants }: ISignInSheetProps) => {
    return (
        <table className='table table-striped mt-4'>
            <thead>
                <tr>
                    <th scope='col' style={{ borderRight: '1px solid lightgray', padding: 10 }}></th>
                    <th scope='col' style={{ borderRight: '1px solid lightgray', padding: 10 }}>Organization</th>
                    <th scope='col' style={{ borderRight: '1px solid lightgray', padding: 10 }}>Name</th>
                    <th scope='col' style={{ borderRight: '1px solid lightgray', padding: 10 }}>Title</th>
                    <th scope='col' style={{ borderRight: '1px solid lightgray', padding: 10, width: 130 }}>Phone</th>
                    <th scope='col' style={{ borderRight: '1px solid lightgray', padding: 10 }}>Email</th>
                </tr>
            </thead>
            <tbody>
                {registrants.map(registrant =>
                    <tr key={registrant.seminarAttendeeId}>
                        <td style={{ padding: `10px`, borderRight: '1px solid lightgray' }}>
                            <div
                                style={{
                                    border: '2px solid #008ba2',
                                    height: 25,
                                    width: 25,
                                    backgroundColor: 'white',
                                }}
                            />
                        </td>
                        <td style={{ padding: 10, borderRight: '1px solid lightgray' }}>{registrant.organizationName}</td>
                        <td style={{ padding: 10, borderRight: '1px solid lightgray' }}>{registrant.fullName}</td>
                        <td style={{ padding: 10, borderRight: '1px solid lightgray' }}>{registrant.title}</td>
                        <td style={{ padding: 10, borderRight: '1px solid lightgray' }}>{registrant.businessPhone}</td>
                        <td style={{ padding: 10, borderRight: '1px solid lightgray' }}>{registrant.email}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}