import React, { useContext, useState, useEffect } from 'react'
import { GridDataFetch, IGridState, IGridListItem } from '../stores/grid-definitions'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { filterGridListItems, showModal, uuidv4, sortListBySorts } from '../services/helpers'
import { useGrid, defaultGridState } from '../stores/grid-actions'
import { Grid } from './grid'
import { gridReducer } from '../stores/grid-reducer'
import { MinistryInfoContext } from './ministry-info'
import { EventAttendanceModel, eventAttendanceModelDefaultColumns } from '../models/event-attendance'
import { Loading } from './loading'
import { IMinistryEvalsResponseDocument } from '../open-api'
import { EVENT_TYPES } from '../constants'
import { PencilIcon } from '../assets'

export const MinistryInfoEvents = () => {

	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

	// const appActions = useContext(AppActionContext)!

	// const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const fetchMinistryEventAttendance: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryEventAttendance } = _state

		if (!ministryEventAttendance) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryEventAttendance, queryState.sorts)

		let rows = ministryEventAttendance.map(attendance => EventAttendanceModel.toGridListItem(attendance))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [eventEvalRows, setEventEvalRows] = useState<{
		previousRow: IGridListItem | undefined
		currentRow: IGridListItem
		nextRow: IGridListItem | undefined
	}>()
	const [eventEvalToView, setEventEvalToView] = useState<IMinistryEvalsResponseDocument>()

	const getNextRowWithEval = (row: IGridListItem | undefined, rows: IGridListItem[]) => {
		if (row) {
			const currentRow = rows.find(r => r.id === row.id)
			if (currentRow) {
				const currentRowIdx = rows.indexOf(currentRow)
				return rows.find((r, i) => i > currentRowIdx && r.values.seminarEvalId)
			}
		}
	}
	//const canViewNext = () => !!eventEvalRows?.nextRow
	// const viewNextEval = () => {
	// 	if (eventEvalRows?.nextRow) {
	// 		const nextRow = getNextRowWithEval(eventEvalRows?.nextRow, gridState.rows)
	// 		setEventEvalRows({
	// 			previousRow: eventEvalRows.currentRow,
	// 			currentRow: eventEvalRows.nextRow,
	// 			nextRow
	// 		})
	// 	}
	// }

	const getPreviousRowWithEval = (row: IGridListItem | undefined, rows: IGridListItem[]) => {
		if (row) {
			const currentRow = rows.find(r => r.id === row.id)
			if (currentRow) {
				const currentRowIdx = rows.indexOf(currentRow)
				const previousRows = rows.filter((r, i) => i < currentRowIdx && r.values.seminarEvalId)
				if (previousRows.length > 0) return previousRows[previousRows.length - 1]
			}
		}
	}
	// const canViewPrevious = () => !!eventEvalRows?.previousRow
	// const viewPreviousEval = () => {
	// 	if (eventEvalRows?.previousRow) {
	// 		const previousRow = getPreviousRowWithEval(eventEvalRows?.previousRow, gridState.rows)
	// 		setEventEvalRows({
	// 			previousRow,
	// 			currentRow: eventEvalRows.previousRow,
	// 			nextRow: eventEvalRows.currentRow
	// 		})
	// 	}
	// }

	useEffect(() => {
		if (eventEvalToView) showModal(eventEvalModalId)
		// eslint-disable-next-line
	}, [eventEvalToView])

	const getViewEventLink = (row: IGridListItem) => {
		if (row.values.eventTypeId === EVENT_TYPES.Workshop) 
			return <PencilIcon />

		if (row.values.eventTypeId === EVENT_TYPES.Consulting) 
			return <PencilIcon />

		return <></>
	}

	// Declare unique Id's for modals. Prevents collisions.
	//const [confirmSendNotificationModal, showHideConfirmSendNotificationEmail] = useModal()
	const [eventEvalModalId] = useState(uuidv4())

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: eventAttendanceModelDefaultColumns,
		disabledPagination: true,
		dataSource: fetchMinistryEventAttendance,
		rowActions: {
			viewEvent: {
				id: 'viewEvent',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					//showHideConfirmSendNotificationEmail(true)
				},
				icon: (row) => getViewEventLink(row),
				tooltipText: 'View Event',
				disabled: (row) => row.values.eventTypeId !== EVENT_TYPES.Workshop && row.values.eventTypeId !== EVENT_TYPES.Consulting && row.values.eventTypeId !== EVENT_TYPES.GroupLearning,
				url: (row) => {
					if (row.values.eventTypeId === EVENT_TYPES.Workshop) 
						return `/event-management/${row.values.eventId}`

					if (row.values.eventTypeId === EVENT_TYPES.Consulting) 
						return `/consulting/${row.values.consultingEventGroupId}`

					if (row.values.eventTypeId === EVENT_TYPES.GroupLearning) 
						return `/group-learning/${row.values.eventContentId}/${row.values.eventId}`

					return '#'
				},
			},
			// TB 20220201 - Commenting it out for now to see if anyone notices this feature is missing. :)
			// resend: {
			// 	id: 'resend',
			// 	action: async (options) => {
			// 		options.e.stopPropagation()
			// 		setSelectedRow(options.row)
			// 		showHideConfirmSendNotificationEmail(true)
			// 	},
			// 	icon: <EnvelopeIcon />,
			// 	tooltipText: 'Send Confirmation Email',
			// },
			// TB 20230328 - Dan pointed out this wasn't working. It doesn't appear that it was ever wired up properly. Commenting out for now.
			// viewEventEval: {
			// 	id: 'viewEventEval',
			// 	action: async (options) => {
			// 		options.e.stopPropagation()

			// 		const nextRow = getNextRowWithEval(options.row, options.gridState.rows)
			// 		const previousRow = getPreviousRowWithEval(options.row, options.gridState.rows)

			// 		setEventEvalRows({
			// 			previousRow,
			// 			currentRow: options.row,
			// 			nextRow: nextRow
			// 		})

			// 		showModal(eventEvalModalId)
			// 		// if (typeof options.row.values.seminarEvalId === 'number') {
			// 		// 	const evalQuery = await makeHTTPRequestWithUi({ request: appActions.MinistryEvalsApi.apiMinistryEvalsIdGet(options.row.values.seminarEvalId), disableSuccessToast: true })
			// 		// 	setEventEvalToView(evalQuery.data)
			// 		// }
			// 	},
			// 	icon: <CardChecklistIcon />,
			// 	tooltipText: 'View Event Eval',
			// 	hidden: (row) => !row.values.seminarEvalId,
			// },
		}
	}
	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	useEffect(() => {
		ministryInfoActions.fetchEventAttendance()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryEventAttendance])

	if (!ministryInfoState.ministryEventAttendance || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			{/* TB 20220201 - This is the old way. It has been deprecated and can be deleted.
			<Modal
				modalId={sendRegConfirmationModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' className='btn btn-primary' onClick={async () => {
							if (!selectedRow) return
							const taskId = registerLoadingTask()
							await appActions.SeminarAttendeesApi.apiSeminarAttendeesIdResendEventRegistrationConfirmationEmailPost(parseInt(selectedRow.id))
							deregisterLoadingTask(taskId)
							hideModal(sendRegConfirmationModal)
						}}>Send</button>
					</React.Fragment>
				}
				onModalHidden={() => setSelectedRow(undefined)}
			>
				Are you sure you want to re-send the registration confirmation email to this contact?
			</Modal> */}

			{/* TB 20220201 - This is the new way. It needs to be wired up. Commenting it out for now to see if anyone notices this feature is missing. :)
			<Modal
                {...confirmSendNotificationModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideConfirmSendNotificationEmail(false)} >Cancel</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                if (!selectedRow || typeof selectedRow.values.email !== 'string' || !selectedRow.values.eventId) return
                                await makeHttpRequestWithUi({
                                    request: appActions.EventMgmtApi.apiEventMgmtEventEventIdSendRegistrantNotificationsPost(parseInt(selectedRow.values.eventId.toString()), [{ seminarAttendeeId: parseInt(selectedRow.id), attendeeGuid: selectedRow.values.attendeeGuid?.toString() ?? null, email: selectedRow.values.email }]),
                                    toastErrorMessage: 'Encountered an error sending confirmation email.',
                                    toastSuccessMessage: 'Successfully sent confirmation email.',
                                })
                                showHideConfirmSendNotificationEmail(false)
                            }}
                        >
                            Send
                        </button>
                    </>
                }
            >
                Would you like to send a registration confirmation email to this registrant?
            </Modal> */}

			{/* 20230328 TB - It doesn't appear that this was ever properly wired up, so commenting out for now.
			<Modal
				modalId={eventEvalModalId}

				modalTitle={
					<div className='d-flex flex-column'>
						<b>{eventEvalRows?.currentRow?.values.eventTitle}</b>
						<div><PersonBadgeIcon /> {eventEvalRows?.currentRow?.values.contactFirstName} {eventEvalRows?.currentRow?.values.contactLastName}</div>
						<div><CalendarEvent /> {eventEvalRows?.currentRow?.values.eventDate instanceof Date ? moment(eventEvalRows?.currentRow.values.eventDate).format('MM/DD/YYYY') : null}</div>
						<div><GeoIcon /> {eventEvalRows?.currentRow?.values.locationName}</div>
					</div>
				}

				footer={
					<div className='row flex-fill justify-content-center'>
						<button type='button' className='btn btn-secondary col-5' disabled={!canViewPrevious()} onClick={viewPreviousEval}><ArrowLeftShort /> Previous</button>
						<div className='col-1'></div>
						<button type='button' className='btn btn-secondary col-5' disabled={!canViewNext()} onClick={viewNextEval}>Next <ArrowRightShort /></button>
					</div>
				}
			>
				{eventEvalRows?.currentRow.values.}
			</Modal> */}
		</React.Fragment>
	)
}