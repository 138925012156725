import { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { ICallListRecordDocument } from '../open-api'
import { sortListByProperty } from '../services/helpers'
import { useHTTPRequestUiWrapper } from '../services/hooks'

interface IMinistryManagementCallListProps {
    onSave?: () => void
    ministryIds?: number[]
    prospectIds?: number[]
}


export const MinistryManagementAddToExistingCallList = ({ onSave, ministryIds, prospectIds }: IMinistryManagementCallListProps) => {
    const { CallListsApi } = useContext(AppActionContext)!
    const { currentUser } = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [callLists, setCallLists] = useState<ICallListRecordDocument[]>()
    const fetchRecords = async () => {
        const callListsQuery = await makeHttpRequestWithUi({
            request: CallListsApi.apiCallListsGet(false),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch call lists.'
        })

        sortListByProperty(callListsQuery.data, 'title')

        setCallLists(callListsQuery.data.filter(o => o.bAllowAdd || o.creatorId === currentUser?.userId))
    }
    useEffect(() => {
        fetchRecords()
    }, [])

    return (
        <div>
            <p>Choose a call list to add the selected ministries to:</p>
            <div className='list-group'>
                {callLists?.map(callList =>
                    <button
                        key={callList.callListId}
                        type='button'
                        className='list-group-item list-group-item-action'
                        onClick={async () => {
                            if (ministryIds) {
                                await makeHttpRequestWithUi({
                                    request: CallListsApi.apiCallListsIdAddOrgsPut(callList.callListId,
                                        {
                                            callListId: callList.callListId,
                                            entityIds: ministryIds,
                                            isMinistryId: true,
                                        }
                                    ),
                                    toastSuccessMessage: 'Successfully added selected ministries to call list.',
                                    toastErrorMessage: 'Failed to add ministries to call list.'
                                })
                            } else if (prospectIds) {
                                await makeHttpRequestWithUi({
                                    request: CallListsApi.apiCallListsIdAddOrgsPut(callList.callListId,
                                        {
                                            callListId: callList.callListId,
                                            entityIds: prospectIds,
                                            isMinistryId: false,
                                        }
                                    ),
                                    toastSuccessMessage: 'Successfully added selected prospects to call list.',
                                    toastErrorMessage: 'Failed to add prospects to call list.'
                                })
                            }

                            onSave && onSave()
                        }}
                    >
                        {callList.title}
                    </button>
                )}
            </div>
        </div>
    )
}