import React, { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../../app-store-provider"
import { useHTTPRequestUiWrapper } from "../../services/hooks"
import { Loading, LoadingPropsSizeEnum } from "../loading"
import { FormikSelectField, IFormikSelectFieldProps, ISelectFieldOption } from './formik-select-field'

interface IFormikMinistryContactSelectFieldProps extends IFormikSelectFieldProps {
    ministryId?: number
    //setSites?: Dispatch<SetStateAction<IEventSiteModelDocument[]>> | ((sites: IEventSiteModelDocument[]) => void)
}
export const FormikMinistryContactSelectField = (props: IFormikMinistryContactSelectFieldProps) => {
    const { ministryId } = props
    const { MinistryContactsApi } = useContext(AppActionContext)!
    const [contactsLoading, setContactsLoading] = useState(false)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [contactOptions, setContactOptions] = useState<ISelectFieldOption[]>([])
    const fetchMinistryContactOptions = useCallback(async () => {
        if (ministryId) {
            setContactsLoading(true)
            const contactsQuery = await makeHttpRequestWithUi({
                request: MinistryContactsApi.apiMinistriesIdContactsGet(ministryId),
                disableSuccessToast: true,
                toastErrorMessage: 'There was an error retrieving the contacts for this ministry.',
                disableLoading: true,
            })
            setContactOptions(contactsQuery.data.map(o => ({ label: `${o.firstName} ${o.lastName}`, value: `${o.ministryContactId}` })))
            setContactsLoading(false)
        }
    }, [ministryId])

    useEffect(() => {
        if (ministryId !== undefined && ministryId > 0) {
            fetchMinistryContactOptions()
        } else {
            setContactOptions([])
        }
    }, [ministryId])

    return (
        <>
            {contactsLoading ?
            <Loading size={LoadingPropsSizeEnum.small} />
            :
            <FormikSelectField
                {...props}
                options={contactOptions}
            />
            }
        </>
    )
}