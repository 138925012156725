import { Form, Formik } from "formik"
import { useContext, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IGrantRequestSummaryDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { CheckboxField, CurrencyField, TinyMceField } from "./forms"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { TooltipInfo } from "./partials"
import * as Yup from 'yup'
import { BoxArrowUpRight } from "../assets"
import { Modal } from "./modal"
import { GrantRequestCustomQuestions } from "./grant-request-custom-questions"
import { FY_BUDGET_FILE_DESCRIPTION_ID, GRANT_REQUEST_STATUS, GRANT_TYPE } from "../constants"
import { formatCurrency } from "../services/helpers"

interface IGrantRequestFormAdInfoProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    afterSave?: () => Promise<void>
}

export const GrantRequestFormAdInfo = (props: IGrantRequestFormAdInfoProps) => {
    const { grantRequestToEdit, afterSave } = props
    const { GrantRequestApi, MinistryFilesApi } = useContext(AppActionContext)!
    const ratingCountArray = [1, 2, 3, 4, 5, 6, 7, 8]

    const viewFile = async (fileGuid: string) => {
        const signedUrl = await MinistryFilesApi.apiMinistryFilesMinistryFileGuidGetSignedUrlGet(fileGuid);
    
        if (signedUrl.data) window.open(signedUrl.data)
    }

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [questionsModal, showHideQuestionsModal] = useModal()
    const [questionsGrantId, setQuestionsGrantId] = useState<number>()

    const currentFiscalYearBudget = grantRequestToEdit.grantRequestFiles?.find(x => x.fileDescId === FY_BUDGET_FILE_DESCRIPTION_ID)

    const readonly = grantRequestToEdit.isLegacyGrant ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Complete"] || 
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Denied"] ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Request Tabled"]
    
    const handleRatingClick = (target:HTMLButtonElement, formikProps:any, fieldName:string) => {
        // Remove class from all other buttons in this group
        target.parentElement?.querySelectorAll('button').forEach(x => x.classList.remove('active'))

        target.classList.add('active')
        formikProps.setFieldValue(fieldName, target.value, false)
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    grantId: grantRequestToEdit.grantId,
                    grantTypeId: grantRequestToEdit.grantTypeId,
                    adRecommendedAmount: grantRequestToEdit.adRecommendedAmount || 0,
                    adFundUsePlan: grantRequestToEdit.adFundUsePlan || '',
                    adMatchingPlan: grantRequestToEdit.adMatchingPlan || '',
                    adNotes: grantRequestToEdit.adNotes || '',
                    adRatingBenefit: grantRequestToEdit.adRatingBenefit || 0,
                    adRatingCriteria: grantRequestToEdit.adRatingCriteria || 0,
                    adRatingImpact: grantRequestToEdit.adRatingImpact || 0,
                    adRatingLeadership: grantRequestToEdit.adRatingLeadership || 0,
                    bPublic: grantRequestToEdit.bPublic ?? true
                }}
                validationSchema={Yup.object({
                    adRecommendedAmount: Yup.number().min(1, 'Required').required('Required'),
                    adFundUsePlan: Yup.string().test(
                        'adFundUsePlan',
                        'Required',
                        function (value) {
                            if (this.parent.grantTypeId !== GRANT_TYPE.Managed) return !!value
                            return true
                        }
                    ),
                    adMatchingPlan: Yup.string().test(
                        'adMatchingPlan',
                        'Required',
                        function (value) {
                            if (this.parent.grantTypeId !== GRANT_TYPE.Managed) return !!value
                            return true
                        }
                    ),
                    adNotes: Yup.string().required('Required'),
                    adRatingCriteria: Yup.string().test(
                        'adRatingCriteria',
                        'Required',
                        function (value) {
                            if (this.parent.grantTypeId !== GRANT_TYPE.Managed) return !!value
                            return true
                        }
                    ),
                    adRatingBenefit: Yup.string().test(
                        'adRatingBenefit',
                        'Required',
                        function (value) {
                            if (this.parent.grantTypeId !== GRANT_TYPE.Managed) return !!value
                            return true
                        }
                    ),
                    adRatingImpact: Yup.string().test(
                        'adRatingImpact',
                        'Required',
                        function (value) {
                            if (this.parent.grantTypeId !== GRANT_TYPE.Managed) return !!value
                            return true
                        }
                    ),
                    adRatingLeadership: Yup.string().test(
                        'adRatingLeadership',
                        'Required',
                        function (value) {
                            if (this.parent.grantTypeId !== GRANT_TYPE.Managed) return !!value
                            return true
                        }
                    ),
                })}
                onSubmit={async (values) => {
                    console.log('submitted')
                    const addNoteQuery = await makeHttpRequestWithUi({
                        request: GrantRequestApi.apiGrantRequestIdAdInfoPut(grantRequestToEdit.grantId, {
                            grantId: values.grantId,
                            adRecommendedAmount: Math.trunc(values.adRecommendedAmount), // Decimals are not allowed
                            adFundUsePlan: values.adFundUsePlan,
                            adMatchingPlan: values.adMatchingPlan,
                            adNotes: values.adNotes,
                            adRatingBenefit: values.adRatingBenefit,
                            adRatingCriteria: values.adRatingCriteria,
                            adRatingImpact: values.adRatingImpact,
                            adRatingLeadership: values.adRatingLeadership,
                            bPublic: values.bPublic
                        }),
                        toastSuccessMessage: 'Successfully submitted request.',
                        toastErrorMessage: 'Error submitting request.',
                        disableLoading: false,
                    })

                    afterSave && afterSave()
                }}
                onReset={async (values) => {
                    document.querySelectorAll('#adInfoForm .btn-rating').forEach(element => {
                        element.classList.remove("active") 
                    });
                }}
            >
                {formikProps => {
                    return (
                        <Form id="adInfoForm" className='d-flex flex-column mx-2'>
                            {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                            You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                            <div className="mt-4 mb-4">
                                {!readonly && <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2' disabled={!formikProps.dirty}>Save</button>}
                            </div>
                            <div className="mb-2">
                                <CheckboxField 
                                    fieldProps={{ name: 'bPublic', label: 'If approved, this grant request should show on the Givers Portal.', labeltooltip:'It is unusual to hide grant requests from the Givers Portal.' }} disabled={readonly} />
                            </div>
                            <CurrencyField fieldProps={{ name: 'adRecommendedAmount', label: `AD Recommended Amount (Requested: ${formatCurrency(grantRequestToEdit.amountRequested)})` }} disabled={readonly} />
                            <TinyMceField fieldProps={{ name: 'adNotes', label: 'Notes to the board' }} disabled={readonly} />
                            {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <>
                                <FormikTextAreaField 
                                    field={{ name: 'adMatchingPlan', label: 'Area Director Recommendation for Matching Plan',
                                    labelTooltip: "If the ministry\'s Matching Plan is acceptable \'as is\', please state that here. Otherwise, briefly state any additions or changes you would recommend.", disabled:readonly }} />
                                <FormikTextAreaField 
                                    field={{ name: 'adFundUsePlan', label: 'Area Director Recommendation for Fund Use Plan',
                                        labelTooltip: "If the ministry\'s Matching Plan is acceptable \'as is\', please state that here. Otherwise, briefly state any additions or changes you would recommend.", disabled:readonly }} />
                            </>}
                            {grantRequestToEdit.grantTypeId == GRANT_TYPE.Other ? 
                            <div className="mb-3">
                                <label>Custom Questions <TooltipInfo tooltipText={'Custom Questions show up on the Grant Feedback Request Form. Only applies to "Other" gran types.'} /> :</label>
                                <button
                                    type="button" 
                                    className="btn btn-secondary btn-sm ml-2" 
                                    disabled={readonly}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setQuestionsGrantId(grantRequestToEdit.grantId)
                                        showHideQuestionsModal(true)
                                    }}>Manage Questions</button>
                            </div> : null}
                            {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <div className="mb-3">
                                <label>AD Eval <TooltipInfo tooltipText={'The AD Eval must be completed before this grant can be submitted to the LAB.'} /> :</label>
                                {grantRequestToEdit.adEval ? 
                                    <span style={{color: "green"}}>&nbsp;Complete! <a href={`/ministry-info/${grantRequestToEdit.ministryId}#adeval`} title="View AD Eval" target="blank"><BoxArrowUpRight style={{fontSize: '14px'}} /></a></span>
                                    :
                                    <a target="_blank" className="btn btn-secondary btn-sm ml-2" href={`/ministry-info/${grantRequestToEdit.ministryId}#adeval`}>Complete AD Eval</a>
                                }
                            </div>}
                             
                            {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <>
                                <div className="mb-3">
                                    <label>Current Budget <TooltipInfo tooltipText={'The AD should review the Current Budget prior to accepting this grant request.'} /> :</label>
                                    { currentFiscalYearBudget ?
                                        <button type="button"
                                            className="btn btn-primary btn-sm ml-2"
                                            onClick={async () => {

                                                viewFile(currentFiscalYearBudget.ministryFileGuid)
                                            }}>Download Current Budget</button> 
                                        :
                                        <span>Current Budget file is missing. Request from ministry leader before proceeding.</span>} { /* The CB file is required when the GR is submitted, so this should never happen. */}
                                </div>
                                <h5>Area Director Grant Rating</h5>
                                <p>On a scale of 1-to-8 (1 being negative and 8 being positive) please rate the ministry according to each of the following statements.</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{width:"550px"}}>This ministry is a good fit with our granting criteria:</td>
                                            <td style={{verticalAlign:"center"}}>
                                                No!&nbsp; 
                                                <div className="btn-group" role="group" aria-label="Basic example">                                        
                                                    {ratingCountArray.map((x, index) => (<button type="button"
                                                        key={index} 
                                                        className={`btn btn-outline-primary btn-rating ${formikProps.values.adRatingCriteria === x ? 'active' : ''}`} 
                                                        value={x}
                                                        onClick={(e) => handleRatingClick(e.currentTarget, formikProps, 'adRatingCriteria')}>{x}</button>))}
                                                </div>
                                                &nbsp;Yes!
                                                {formikProps.errors.adRatingCriteria && formikProps.touched.adRatingCriteria ? 
                                                    <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{formikProps.errors.adRatingCriteria}</div>
                                                    :
                                                    null
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>This is a worthy ministry with capable and stable leadership:</td>
                                            <td style={{verticalAlign:"center"}}>
                                                No!&nbsp;
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    {ratingCountArray.map((x, index) => (<button type="button" 
                                                    key={index}
                                                    className={`btn btn-outline-primary btn-rating ${formikProps.values.adRatingLeadership === x ? 'active' : ''}`} 
                                                    value={x}
                                                        onClick={(e) => handleRatingClick(e.currentTarget, formikProps, 'adRatingLeadership')}>{x}</button>))}
                                                </div>
                                                &nbsp;Yes!
                                                {formikProps.errors.adRatingLeadership && formikProps.touched.adRatingLeadership ? 
                                                    <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{formikProps.errors.adRatingLeadership}</div>
                                                    :
                                                    null
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>This is a strategic grant that will have significant impact on this ministry:</td>
                                            <td style={{verticalAlign:"center"}}>
                                                No!&nbsp;
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    {ratingCountArray.map((x, index) => (<button type="button"
                                                    key={index}
                                                    className={`btn btn-outline-primary btn-rating ${formikProps.values.adRatingImpact === x ? 'active' : ''}`}
                                                    value={x}
                                                        onClick={(e) => handleRatingClick(e.currentTarget, formikProps, 'adRatingImpact')}>{x}</button>))}
                                                </div>
                                                &nbsp;Yes!
                                                {formikProps.errors.adRatingImpact && formikProps.touched.adRatingImpact ? 
                                                    <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{formikProps.errors.adRatingImpact}</div>
                                                    :
                                                    null
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>This ministry will benefit from the training that will accompany this grant:</td>
                                            <td style={{verticalAlign:"center"}}>
                                                No!&nbsp;
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    {ratingCountArray.map((x, index) => (<button type="button" 
                                                    key={index}
                                                    className={`btn btn-outline-primary btn-rating ${formikProps.values.adRatingBenefit === x ? 'active' : ''}`} 
                                                    value={x}
                                                        onClick={(e) => handleRatingClick(e.currentTarget, formikProps, 'adRatingBenefit')}>{x}</button>))}
                                                </div>
                                                &nbsp;Yes!
                                                {formikProps.errors.adRatingBenefit && formikProps.touched.adRatingBenefit ? 
                                                    <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{formikProps.errors.adRatingBenefit}</div>
                                                    :
                                                    null
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>}
                            {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                            You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                            <div className="mt-4 mb-4">
                                {!readonly && <>
                                    <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2' disabled={!formikProps.dirty}>Save</button>
                                    <button type='button' onClick={formikProps.handleReset} style={{ width: 100 }} className='btn btn-secondary mr-2' disabled={!formikProps.dirty}>Cancel</button>
                                </>}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            <Modal
				{...questionsModal}
				modalTitle='Custom Grant Feedback Request Form Questions'
                size="xl"
                onModalHide={() => {
                    setQuestionsGrantId(undefined)
                }}
			>
                {questionsGrantId && <GrantRequestCustomQuestions 
                    activeGrantId={questionsGrantId} 
                    afterSave={async () => {
                        setQuestionsGrantId(undefined)
                        showHideQuestionsModal(false)
                    }}
                />}
            </Modal>
        </>
    )
}