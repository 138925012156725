import { useReducer } from "react"
import { useApiActions, IMifApi } from './api-actions'
import { IAppState, IUserSessionState, IToast, IAlert } from './app-definitions'
import { AppAction, AppActionType } from "./app-reducer"
import { IMeAuthModelDocument, IMiBranchModelDocument, IMiUserModelDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { ICachesActions, useCaches } from './caches'
import { IUserSessionStateActions, useUserSessionStateActions } from './user-session-state'
import { allPaths, Path } from "../components/dashboard"
import { useAuth} from "react-oidc-context";
import { useClearLocalStorage } from '../hooks/index'
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'
import { navigate } from "@reach/router"

export interface IMifActions extends IMifApi, ICachesActions {
	bootstrap: () => Promise<void>
	login: () => Promise<void>

	setGlobalCommunityContext: (community: IMiBranchModelDocument | null) => Promise<void>
	enableGlobalCommunityContextDropdown: () => void
	disableGlobalCommunityContextDropdown: () => void

	setGlobalSearchTerm: (searchTerm?: string) => void

	mainNavItemPressed: (path: Path) => void

	// Group all actions for user session (easier to refactor later as the number of user session state actions grow)
	UserSessionActions: IUserSessionStateActions

	addToast: (toast: IToast) => void
	removeToast: (toast: IToast) => void

	addAlert: (alert: IAlert) => void
	removeAlert: (alert: IAlert) => void

	/* 
		Fetches all applications users and saves it in the global state
	*/
	fetchMiUsers: (noLoading?: boolean) => Promise<void>

	/* 
		Fetches all application users' summaries and saves it in the global state
	*/
	fetchMiUsersSummaries: () => Promise<void>

	/* 
		Fetches all application permission types and saves it in the global state
	*/
	fetchAppPermissions: () => Promise<void>

	/* 
		Fetches all community summaries and saves it in the global state
	*/
	fetchCommunitySummaries: () => Promise<void>

	/* 
		Fetches all presenters and saves it in the global state
	*/
	fetchPresenters: () => Promise<void>
}

export const useAppActions = (reducer: React.Reducer<IAppState, AppAction>, initialState: any): [IAppState, IMifActions] => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const apiActions = useApiActions(dispatch, state)
	const userSessionStateActions = useUserSessionStateActions(dispatch, apiActions, state)

	const cacheActions = useCaches(dispatch, apiActions)
	const { refreshAllCaches } = cacheActions

	const clearLocalStorageIfRequired = useClearLocalStorage()

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const auth = useAuth()

	/* 
		A function designed to evolve over time and handle any migrations of the amorphous user session state data.
		When we change the shape of the data in code, we add any necessary migrations to this function. 
		
		When users come online with the new code, if they have any session state data that needs to be
		migrated, this function will handle making sure their session state data stays valid and doesn't
		loose any information.
	*/
	//const migrateUserSessionStateData = (userSessionState: object) => { }

	const fetchLoggedInUser = async (): Promise<{ user: IMiUserModelDocument | undefined, adminStateObj: any, userAuth: IMeAuthModelDocument }> => {
		const userAuth = await makeHTTPRequestWithUi({ request: apiActions.MiUsersApi.apiMiUsersMeAuthGet(), disableSuccessToast: true })

		if(userAuth.data.noAccessMessage)
		{            
			navigate(`/login-error?message=${encodeURIComponent(userAuth.data.noAccessMessage)}`);
            return {
                user: undefined,
                adminStateObj: null,
                userAuth: userAuth.data
            };
		}

		const user = await makeHTTPRequestWithUi({ request: apiActions.MiUsersApi.apiMiUsersMeGet(), disableSuccessToast: true })
		let adminStateObj = undefined
		try {
			adminStateObj = user.data.adminStateObj ? JSON.parse(user.data.adminStateObj) : undefined
		}
		catch (e) {
			adminStateObj = undefined
		}

		return { user: user.data, adminStateObj, userAuth: userAuth.data }
	}

	const actions = {
		bootstrap: async () => {
			if (!state.bootstrapped) {
				console.log('bootstrapping')
				clearLocalStorageIfRequired()

				const pathParts = window.location.pathname.split('/')
				let selectedMainNavItem = Path.home
				allPaths.forEach(path => {
					// @ts-ignore
					if (pathParts.includes(path)) selectedMainNavItem = Path[path]
				})

				// console.log('testing the API url', process.env.REACT_APP_API_URL)
				// const test = await apiActions.testApi()
				// console.log('API test results', test)


				if (!auth.isAuthenticated) {
					// dispatch({ type: AppActionType.navigate, payload: { url: '/login' } })
					dispatch({ type: AppActionType.bootstrap, payload: { selectedMainNavItem } })
				} else {

					refreshAllCaches()

					const { user, adminStateObj, userAuth } = await fetchLoggedInUser()

					const freshworksWidget = (window as any).FreshworksWidget;
					if (freshworksWidget && userAuth?.freshdeskJwtToken) {
						freshworksWidget('authenticate', {
							token: userAuth.freshdeskJwtToken,
							callback: function (response: any) {
								console.log('Freshdesk widget authenticated:', response);
							}
						});
						freshworksWidget('show');
					}

					dispatch({ type: AppActionType.bootstrap, payload: { selectedMainNavItem, user: user, userSessionState: adminStateObj, userAuth } })
				}
			}
		},
		login: async () => {
			await auth.signinSilent()

			refreshAllCaches()

			const { user, adminStateObj, userAuth } = await fetchLoggedInUser()

			const freshworksWidget = (window as any).FreshworksWidget;
			if (freshworksWidget && userAuth?.freshdeskJwtToken) {
				freshworksWidget('authenticate', {
					token: userAuth.freshdeskJwtToken,
					callback: function (response: any) {
						console.log('Freshdesk widget authenticated:', response);
					}
				});
				freshworksWidget('show');
			}

			dispatch({ type: AppActionType.login, payload: { user: user, userSessionState: adminStateObj, userAuth } })
		},

		setGlobalCommunityContext: async (community: IMiBranchModelDocument | null) => {
			dispatch({ type: AppActionType.setGlobalCommunityContext, payload: community })
			const newUserSessionState: IUserSessionState = { ...state.currentUserSessionState }
			newUserSessionState.globalCommunityContextBranchId = community ? community.branchId : null
			userSessionStateActions.saveUserSessionState(newUserSessionState)
		},
		enableGlobalCommunityContextDropdown: () => dispatch({ type: AppActionType.enableGlobalCommunityContextDropdown }),
		disableGlobalCommunityContextDropdown: () => dispatch({ type: AppActionType.disableGlobalCommunityContextDropdown }),

		setGlobalSearchTerm: (searchTerm?: string) => dispatch({ type: AppActionType.setGlobalSearchTerm, payload: { globalSearchTerm: searchTerm } }),

		mainNavItemPressed: (path: Path) => {
			dispatch({ type: AppActionType.setSelectedMainNavItem, payload: path })
			dispatch({ type: AppActionType.navigate, payload: { url: `/${path}` } })
		},

		addToast: (toast: IToast) => {
			dispatch({ type: AppActionType.addToast, payload: toast })
			if (toast.autoDismissTimeOut) {
				setTimeout(() => {
					dispatch({ type: AppActionType.removeToast, payload: toast })
				}, toast.autoDismissTimeOut)
			}
		},
		removeToast: (toast: IToast) => dispatch({ type: AppActionType.removeToast, payload: toast }),

		addAlert: (alert: IAlert) => dispatch({ type: AppActionType.addAlert, payload: alert }),
		removeAlert: (alert: IAlert) => dispatch({ type: AppActionType.removeAlert, payload: alert }),

		UserSessionActions: userSessionStateActions,

		fetchMiUsers: async (noLoading?: boolean) => {
			const usersQuery = await makeHTTPRequestWithUi({ request: apiActions.MiUsersApi.apiMiUsersGet(), disableSuccessToast: true, toastErrorMessage: 'There was a problem fetching the list of MI Admin users.', disableLoading: !!noLoading })
			dispatch({ type: AppActionType.setUsers, payload: usersQuery.data })
		},

		fetchMiUsersSummaries: async () => {
			const usersQuery = await makeHTTPRequestWithUi({ request: apiActions.MiUsersApi.apiMiUsersGetSummaryGet(), disableSuccessToast: true, toastErrorMessage: 'There was a problem fetching the list of MI Admin users.' })
			dispatch({ type: AppActionType.setUsersSummaries, payload: usersQuery.data })
		},

		fetchAppPermissions: async () => {
			const appPermissionsQuery = await makeHTTPRequestWithUi({ request: apiActions.MiAppsApi.apiMiAppsGet(), disableSuccessToast: true, toastErrorMessage: 'There was a problem fetching the list of MI Admin permission types.' })
			dispatch({ type: AppActionType.setAppPermissions, payload: appPermissionsQuery.data })
		},

		fetchCommunitySummaries: async () => {
			const communitiesQuery = await makeHTTPRequestWithUi({ request: apiActions.MiBranchesApi.apiMiBranchesSummaryGet(), disableSuccessToast: true, toastErrorMessage: 'There was a problem fetching the list of communities.' })
			dispatch({ type: AppActionType.setCommunitySummaries, payload: communitiesQuery.data })
		},

		fetchPresenters: async () => {
			const communitiesQuery = await makeHTTPRequestWithUi({ request: apiActions.PresentersApi.apiPresentersGet(), disableSuccessToast: true, toastErrorMessage: 'There was a problem fetching the list of presenters.' })
			dispatch({ type: AppActionType.setPresenters, payload: communitiesQuery.data })
		},

		...apiActions,
		...cacheActions,
	}

	return [state, actions]
}