import React, { useContext, useEffect, useCallback } from "react"
import { MinistryInfoContext } from './ministry-info'
import { IMinistryInfoState } from "../stores/ministry-info-definitions"
import { GridDataFetch, GridListItemValueType, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { Grid } from "./grid"
import { gridReducer } from "../stores/grid-reducer"
import { Loading } from "./loading"
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell } from "./grid-cell-displays"
import { PersonIcon } from "../assets"
import { COURSE_STATUS_OPTIONS } from "../constants"
import { IQueryState } from "../stores/api-actions"
import { AppStateContext } from "../app-store-provider"

export const MinistryInfoCourses = () => {
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const appState = useContext(AppStateContext)

	const dataSource: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryCourses } = _state

		if (!ministryCourses) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryCourses, queryState.sorts)

		let rows: IGridListItem[] = ministryCourses.map(o => ({
			id: o.userId.toString(),
			values: {
				courseId: o.courseId,
				userDisplayName: o.userDisplayName,
				userEmail: o.userEmail,
				userMinistryName: o.userMinistryName,
				userMinistryId: o.userMinistryId,
				userRegistered: o.userRegistered,
				courseStatus: o.courseStatus,
				courseProgressPercentage: o.courseProgressPercentage,
				courseTitle: o.courseTitle,
				groupManagementUrl: o.groupManagementUrl,
				userProfileUrl: o.userProfileUrl
			}
		}))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	let queryState: IQueryState | undefined = appState.currentUserSessionState?.gridUserInteractionStates?.[GridUserInteractionStateKey.ministryInfoCourses]?.queryState
	if (!queryState) queryState = { ...defaultGridState.queryState }
	if (!queryState.filters) queryState.filters = []

	queryState.filters.push({
		id: 'courseStatus-in',
		enabled: true,
		value: ['Completed', 'In Progress'],
		operator: 'in',
		property: 'courseStatus',
	})

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		queryState,
		rowSelectEnabled: false,
		columns: [
			{
				property: 'courseTitle',
				title: 'Course',
				width: 100,
				type: 'string',
				render: DefaultGridCellDisplay,
				allowFilters: true,
			},
			{
				property: 'userDisplayName',
				title: 'Contact Name',
				width: 100,
				type: 'string',
				render: DefaultGridCellDisplay,
				allowFilters: true,
			},
			{
				property: 'userEmail',
				title: 'Contact Email',
				width: 100,
				type: 'string',
				render: EmailCellDisplay,
				allowFilters: true,
			},
			{
				property: 'courseStatus',
				title: 'Status',
				width: 100,
				type: 'string',
				render: (_, row) => {
					if (!row.values.courseStatus) return <></>
					const courseStatus = row.values.courseStatus
					let textColor = 'text-danger'
					if (courseStatus === "In Progress") textColor = 'text-warning'
					if (courseStatus === "Completed") textColor = 'text-success'

					return <span className={textColor}>{row.values.courseStatus}</span>
				},
				allowFilters: true,
				filterOptions: COURSE_STATUS_OPTIONS
			},
			{
				property: 'courseProgressPercentage',
				title: 'Progress',
				width: 100,
				type: 'string',
				render: (_, row) =>
				<div style={{backgroundColor: "#eeeeee", width: "100%"}}>
					<div style={getProgressStyling(row.values.courseProgressPercentage)}>
						{row.values.courseProgressPercentage?.toString()}% Complete
					</div>
				</div>
			},
			{
                property: 'grid_actions',
                type: 'actions',
                width: 110,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
		],
		disabledPagination: true,
		dataSource,
		rowActions: {
            viewProfile: {
                id: 'viewProfile',
                tooltipText: "View contact's LMS profile",
                icon: <PersonIcon />,
                action: async (options) => {
                    options.e.stopPropagation()
					if (options.row.values.userProfileUrl) window.open(options.row.values.userProfileUrl.toString())
                },
				disabled: (row) => !row.values.userProfileUrl,
            },
			// viewGroup: {
            //     id: 'viewGroup',
            //     tooltipText: "View course group info",
            //     icon: <JournalsIcon />,
            //     action: async (options) => {
            //         options.e.stopPropagation()
			// 		if (options.row.values.groupManagementUrl) window.open(options.row.values.groupManagementUrl.toString())
            //     },
			// 	disabled: (row) => !row.values.groupManagementUrl,
            // }
        },
	}

	useEffect(() => {
		ministryInfoActions.fetchLmsUserCourses()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryCourses])


	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	/* 
		Whenever the ministry changes, refresh the data for the grid.
	*/
	// useEffect(() => {
	// 	gridActions.doFetch()
	// 	//eslint-disable-next-line
	// }, [ministryInfoState.ministry])

	const getProgressStyling = useCallback((percentage: GridListItemValueType): React.CSSProperties => {
		if (typeof percentage !== 'number') return {}
	
		let backgroundColor = '#dc354520'
		if (percentage > 33) backgroundColor = '#ffc10720'
		if (percentage > 66) backgroundColor = '#28a74520'
	
		return {
			width: `${percentage}%`,
			backgroundColor,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			textAlign: 'center',
			...percentage === 0 ? { marginLeft: 5 } : { paddingLeft: 5, }
		}
	}, [])

	if (!ministryInfoState.ministryCourses || !ministryInfoState.ministry) return <Loading />

	return (
		<>
		<p className='m-2'>This is a list of courses that this ministry's contacts are currently enrolled in. If a course is newly assigned to a ministry contact, it may not show up here until after the next time they log into the LMS.<br />
		Courses are available at <a href="https://learn.missionincrease.org">https://learn.missionincrease.org</a></p>
		<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
		</>
	)
}
