import { Form, Formik, FormikProps } from "formik"
import { useContext, useEffect } from "react"
import { AppActionContext } from "../app-store-provider"
import { IGrantDenialReasonModelDocument, IGrantRequestSummaryDocument, IMinistryGrantStipulationModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { CurrencyField, DatePickerField, TinyMceField } from "./forms"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { GRANT_DENIAL_REASON, GRANT_REQUEST_STATUS, GRANT_TYPE } from "../constants"
import { FormikSelectField } from "./forms/formik-select-field"
import { GrantRequestStipulations } from "./grant-request-stipulations"

interface IGrantRequestFormBoardInfoProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    stipulations: IMinistryGrantStipulationModelDocument[]
    grantDenialReasons: IGrantDenialReasonModelDocument[]
    afterSave?: () => Promise<void>
    fetchStipulations: () => Promise<void>
}

export const GrantRequestFormBoardInfo = (props: IGrantRequestFormBoardInfoProps) => {
    const { grantRequestToEdit, afterSave, stipulations, grantDenialReasons, fetchStipulations } = props
    const { GrantRequestApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    let _formikProps: FormikProps<any>
	useEffect(() => {
		if (_formikProps) _formikProps.resetForm()
		// eslint-disable-next-line
	}, [grantRequestToEdit])

    const readonly = grantRequestToEdit.isLegacyGrant ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Complete"] || 
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Denied"] ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Request Tabled"]

    return (
        <>
            <Formik
                initialValues={{
                    grantId: grantRequestToEdit.grantId,
                    amountApproved: grantRequestToEdit.grantAmount || 0,
                    boardMeetingDate: grantRequestToEdit.boardMeetingDate,
                    boardNotes: grantRequestToEdit.boardNotes,
                    grantPeriodBeginning: grantRequestToEdit.grantPeriodBeginning,
                    grantPeriodEnding: grantRequestToEdit.grantPeriodEnding,
                    grantTypeId: grantRequestToEdit.grantTypeId,
                    denialReasonIds: grantRequestToEdit.denialReasonIds || [],
                    otherDenialReason: grantRequestToEdit.otherDenialReason
                }}
                enableReinitialize
                onSubmit={async (values) => {
                    const addNoteQuery = await makeHttpRequestWithUi({
                        request: GrantRequestApi.apiGrantRequestIdBoardInfoPut(grantRequestToEdit.grantId, {
                            grantTypeId: grantRequestToEdit.grantTypeId,
                            grantAmount: values.amountApproved,
                            boardMeetingDate: values.boardMeetingDate,
                            boardNotes: values.boardNotes,
                            grantPeriodBeginning: values.grantPeriodBeginning,
                            grantPeriodEnding: values.grantPeriodEnding
                        }),
                        toastSuccessMessage: 'Successfully submitted request.',
                        toastErrorMessage: 'Error submitting request.',
                        disableLoading: false,
                    })

                    afterSave && afterSave()
                }}
            >
                {formikProps => {
                    _formikProps = formikProps
                    return (
                        <Form className='d-flex flex-column mx-2'>
                            {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                            You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                            <div className="mt-4 mb-4">
                                {!readonly && <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2' disabled={!formikProps.dirty}>Save</button>}
                            </div>
                            <DatePickerField fieldProps={{ name: 'boardMeetingDate', label: 'Board Meeting Date', placeholder: 'Select a date...' }} disabled={readonly} />
                            {grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Grant Denied"] && 
                                <CurrencyField fieldProps={{ name: 'amountApproved', label: 'Board Approved Amount' }} disabled={readonly} /> }
                            <TinyMceField fieldProps={{ name: 'boardNotes', label: 'Notes' }} disabled={readonly} />
                            {grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Grant Denied"] && 
                                grantRequestToEdit.feedbackRequired ?
                            <>
                                <DatePickerField fieldProps={{ name: 'grantPeriodBeginning', label: 'Matching Period Begins On', placeholder: 'Select a date...' }} disabled={readonly} />
                                <DatePickerField fieldProps={{ name: 'grantPeriodEnding', label: 'Matching Period Ends On', placeholder: 'Select a date...' }} disabled={readonly} />
                            </> 
                            :
                            <p>Managed grants do not have a matching period. Once approved, they go straight to the "Ready for Funding" step.</p>}
                            {grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Grant Denied"] &&
                            <>
                                <h5>Reasons for Denial</h5>
                                <FormikSelectField 
                                    field={{ name: 'denialReasonIds', label: 'Denial Reasons' }}
                                    multiple={true}
                                    // @ts-ignore
                                    options={grantDenialReasons.map(r => ({ value: r.id, label: r.name, isDisabled: !r.isActive }))} disabled={readonly} />
                                {formikProps.values.denialReasonIds.find(x => x == GRANT_DENIAL_REASON.Other) && 
                                    <FormikTextAreaField field={{ name: 'otherDenialReason', label: 'Other Denial Reason',  disabled: readonly }} />}
                            </>}
                            {grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Grant Denied"] && grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed &&
                            <>
                                <h5>Stipulations</h5>
                                <p className='mt-3'>
                                    Stipulations are...
                                </p>
                                <GrantRequestStipulations
                                    grantRequestToEdit={grantRequestToEdit}
                                    stipulations={stipulations}
                                    afterSave={async () => {
                                        await fetchStipulations()
                                        afterSave && afterSave()
                                    }}
                                />
                            </>
                            }
                            {formikProps.dirty && 
                            <div className="alert alert-warning mt-4" role="alert">
                            You have made changes to this form. You must click the 'Save' button to retain those changes.
                            </div>}
                            <div className="mt-4 mb-4">
                                {!readonly && <>
                                    <button type='submit' style={{ width: 100 }} className='btn btn-primary mr-2' disabled={!formikProps.dirty}>Save</button>
                                    <button type='button' onClick={formikProps.handleReset} style={{ width: 100 }} className='btn btn-secondary mr-2' disabled={!formikProps.dirty}>Cancel</button>
                                </>}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}