import { IMinistryGrantFundingModelDocument, IMinistryGrantFundingSummaryDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useCallback, useContext, useEffect, useState } from "react"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { PencilIcon, PlusIcon } from "../assets"
import { gridReducer } from "../stores/grid-reducer"
import { Modal } from "./modal"
import { GrantFormFundingForm } from "./grant-form-funding-form"
import { Grid } from "./grid"
import { SquareDeleteIcon } from "./partials"

interface IGrantFormFundingProps {
    fundingSources: IMinistryGrantFundingSummaryDocument[]
    branchId: number
    grantGiftId?: number
    afterSave?:  () => void
}

export const GrantFormFunding = (props: IGrantFormFundingProps) => {
    const { afterSave, branchId, grantGiftId, fundingSources } = props
    const { MinistryGrantFundingApi } = useContext(AppActionContext)!

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [fundingSourceToEdit, setFundingSourceToEdit] = useState<IMinistryGrantFundingModelDocument>()
    const [fundingSourceFormModal, showHideFundingSourceFormModal] = useModal()
    const [deleteFundingModal, showHideDeleteFundingModal] = useModal()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<IMinistryGrantFundingSummaryDocument[]> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.fundingId!.toString(),
                values: {
                    ...o,
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('GrantFormFunding > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    const editFundingSource = useCallback(async (fundingId: string) => {
        const sessionToEditQuery = await makeHttpRequestWithUi({
            request: MinistryGrantFundingApi.apiMinistryGrantFundingFundingIdGet(parseInt(fundingId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setFundingSourceToEdit(sessionToEditQuery.data)

        showHideFundingSourceFormModal(true)
    }, [])

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.GrantFundingSources,
        gridActions: [
            {
                id: 'addFunding',
                label: 'Add Funding',
                icon: <PlusIcon className='mr-2' />,
                buttonClass: 'btn-primary',
                onClick: () => showHideFundingSourceFormModal(true),
                sortOrder: 0,
                disabled: !grantGiftId
            }
        ],
        rowActions: {
            editFundingSource: {
                id: 'editFundingSource',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    editFundingSource(row.id)
                },
                tooltipText: 'Edit Funding Source',
                icon: <PencilIcon />,
            },
            deleteFundingSource: {
                id: 'deleteFundingSource',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideDeleteFundingModal(true)
                },
                tooltipText: 'Delete Funding Source',
                icon: <SquareDeleteIcon />,
            },
        },
        columns: [
            {
                property: 'donorName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Giver',
                render: DefaultGridCellDisplay
            },
            {
                property: 'amount',
                type: 'number',
                width: 150,
                allowFilters: true,
                title: 'Amount',
                render: CurrencyDisplay
            },
            {
                property: 'bPrimaryFunder',
                type: 'boolean',
                width: 150,
                allowFilters: true,
                title: 'Primary',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'bAnonymous',
                type: 'boolean',
                width: 150,
                allowFilters: true,
                title: 'Anonymous',
                render: DefaultGridCellDisplay,
            },
            
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: false,
        usingLocalData: true,
        disabledPagination: true,
        rowActionsDisplayType: "icons",
        disableExport: true,
        respectGlobalCommunityFilter: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            editFundingSource(row.id)
        },
    }

    //const [gridState, gridActions] = useGrid(gridReducer, initialGridState, [])
    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, fundingSources ?? [])

    useEffect(() => {
        console.log('GrantFormFunding > doFetch')
        gridActions.doFetch()
    }, [fundingSources])

    return (
        <>
            <p className='mt-3'>
                What groups are providing funding and in what amounts?
            </p>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>
            <Modal
                {...fundingSourceFormModal}
                modalTitle={!!fundingSourceToEdit ? `Edit Funding Source (${fundingSourceToEdit.fundingId})` : 'Add Funding Source'}
                size='lg'

                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setFundingSourceToEdit(undefined)
                }}

                dismissible={false}
            >
                {fundingSourceFormModal.show && grantGiftId &&
                    <GrantFormFundingForm
                        fundingSourceToEdit={fundingSourceToEdit}
                        fundingSources={fundingSources}
                        branchId={branchId}
                        grantGiftId={grantGiftId}
                        afterSave={async () => {
                            showHideFundingSourceFormModal(false)
                            afterSave && afterSave()
                        }} 
                    />
                }
            </Modal>
            <Modal
				{...deleteFundingModal}
				modalTitle='Confirm'
				footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideDeleteFundingModal(false)}>Cancel</button>
						<button className='btn btn-warning' onClick={async () => {
							if (!!selectedRow) {
								await makeHttpRequestWithUi({
									request: MinistryGrantFundingApi.apiMinistryGrantFundingFundingIdDelete(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Successfully deleted payment.',
									toastErrorMessage: 'There was an error deleting payment.'
								})
							}
							showHideDeleteFundingModal(false)
                            afterSave && afterSave()
						}}>Delete</button>
					</>
				}
				_onModalHidden={() => {
					setSelectedRow(undefined)
				}}
			>
				Are you sure you want to delete this funding source?
            </Modal>           
        </>
    )
}