import { IGrantRequestSummaryDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import { GrantRequestFormTabs } from "./grant-request-form-tabs"

interface IGrantRequestFormProps extends IDefaultProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    afterSave?: () => Promise<void>
}

export const GrantRequestForm = (props: IGrantRequestFormProps) => {
    const { grantRequestToEdit, afterSave } = props

    return <GrantRequestFormTabs
                grantRequestToEdit={grantRequestToEdit}
                afterSave={afterSave}     
            />
}