import React, { useContext, useState, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { MinistryInfoContext } from './ministry-info'
import { IApprovalStatusDocument, IProfilePageListModelDocument } from '../open-api'
import { uuidv4, showModal, showTab, hideModal, enableTooltip, destroyTooltip, buildTooltipProps } from '../services/helpers'
import { APPROVE_DENY_EMAIL_TEMPLATE_VARIABLES } from '../constants'
import { MinistryInfoProfilePage } from './ministry-info-profile-page'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { Modal } from './modal'
import { isNullOrUndefined } from 'util'
import { MinistryInfoProfilePageModel } from '../models/ministry-info-profile-page'
import { ReactComponent as PersonPlusIcon } from '../assets/person-check.svg'
import { ReactComponent as PersonDashIcon } from '../assets/person-dash.svg'
import { ReactComponent as CheckCircleIcon } from '../assets/check-circle.svg'
import { ReactComponent as XCircleIcon } from '../assets/x-circle.svg'
import { ReactComponent as ExclamationCircleIcon } from '../assets/exclamation-circle.svg'
import { MinistryModel } from '../models/ministry'
import { Editor } from '@tinymce/tinymce-react'
import moment from 'moment'
import { Checkbox } from './forms'
import { MinistryInfoProfileContactsPage } from './ministry-info-profile-contacts-page'
import { UserModel, UserPermissionLevel, App } from '../models/user'
import { MinistryInfoProfileDocumentsPage } from './ministry-info-profile-documents-page'
import { MinistryInfoProfileLog } from './ministry-info-profile-log'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { usePrevious } from 'react-use'
import { AxiosResponse } from 'axios'

export const MinistryInfoProfile = () => {
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const ministry = ministryInfoState.ministry!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [state, setState] = useState<{
		pageList: IProfilePageListModelDocument[]
		pageApprovalStatus: IApprovalStatusDocument[],
		activeTabId: string | null
		approveDenyEmail: {
			modalId: string
			sendCheckboxId: string
			approveDenyEmailContent?: string
			approveDeny?: 'approve' | 'deny'
			template?: string
		}
	}>({
		pageList: [],
		pageApprovalStatus: [],
		activeTabId: null,
		approveDenyEmail: {
			modalId: uuidv4(),
			sendCheckboxId: uuidv4(),
		}
	})
	const [sendApproveDenyEmail, setSendApproveDenyEmail] = useState(false)
	const [approveDenyEnabled, setApproveDenyEnabled] = useState<{
		enableApproveProfile: boolean
		enableDenyProfile: boolean
	}>({
		enableApproveProfile: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser),
		enableDenyProfile: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser),
	})
	const approvedPillId = uuidv4()

	const [approveDenyModal, showHideApproveDenyModal] = useModal()
	const [approveDenyModalState, setApproveDenyModalState] = useState<{
		approveDeny?: 'approve' | 'deny'
	}>({})

	const fetchData = async () => {
		const promises: [Promise<AxiosResponse<IProfilePageListModelDocument[]>>, Promise<AxiosResponse<IApprovalStatusDocument[]>>] = [
			makeHttpRequestWithUi({
				request: appActions.MinistryProfileApi.apiMinistryProfileIdProfilePageListGet(ministry.ministryId),
				disableSuccessToast: true,
				toastErrorMessage: 'There was an error retrieving the list of profile pages for this ministry.'
			}),
			makeHttpRequestWithUi({
				request: appActions.MinistryProfileApi.apiMinistryProfileIdApprovalStatusGet(ministry.ministryId),
				disableSuccessToast: true,
				toastErrorMessage: 'There was an error retrieing the approval status for the profile pages in this ministry.'
			})
		]

		const results = await Promise.all(promises)

		const profilePageListQuery = results[0]

		const profilePageApprovalStatusQuery = results[1]

		const activeTabId = state.activeTabId || `tab-${profilePageListQuery.data[0].pageId}`
		setState({ ...state, pageList: profilePageListQuery.data, pageApprovalStatus: profilePageApprovalStatusQuery.data, activeTabId })
	}

	const getApproveDenyModalBody = () => {
		if (approveDenyModalState.approveDeny === 'approve') {
			if (!MinistryModel.profileReviewComplete(ministry)) {
				return `We're sorry, but this profile cannot be approved until the ministry submits the profile.`
			}

			if (!MinistryModel.hasValidTaxId(ministry) && !UserModel.userIsSuperUser(appState.currentUser) && !ministry.bAllowChurch) {
				return `We're sorry, but you cannot approve this ministry until they enter a valid TaxID/EIN.`
			}

			return (!MinistryModel.hasValidTaxId(ministry) && UserModel.userIsSuperUser(appState.currentUser) && !ministry.bAllowChurch) ?
				<div>
					This ministry has an invalid TaxID/EIN. Since you are a Superuser you can continue.
					<br /> <br />
					Do you want to approve this profile?
					<br /> <br />
					<Checkbox checked={sendApproveDenyEmail} id={state.approveDenyEmail.sendCheckboxId} label='Would you like to send this ministry an approval email? You will be able to customize the content.' onChange={e => setSendApproveDenyEmail(e.currentTarget.checked)} />
				</div>
				:
				<div>
					Are you sure you want to approve this profile?
					<br /> <br />
					<Checkbox checked={sendApproveDenyEmail} id={state.approveDenyEmail.sendCheckboxId} label='Would you like to send this ministry an approval email? You will be able to customize the content.' onChange={e => setSendApproveDenyEmail(e.currentTarget.checked)} />
				</div>
		}

		if (approveDenyModalState.approveDeny === 'deny') {
			return (
				<div>
					Are you sure you want to deny this profile?
					<br /> <br />
					<Checkbox checked={sendApproveDenyEmail} id={state.approveDenyEmail.sendCheckboxId} label='Would you like to send this ministry a denial email? You will be able to customize the content.' onChange={e => setSendApproveDenyEmail(e.currentTarget.checked)} />
				</div>
			)
		}
	}

	const canApproveProfile = () => {
		if (!MinistryModel.profileReviewComplete(ministry)) {
			return false
		}

		if (!MinistryModel.hasValidTaxId(ministry) && !UserModel.userIsSuperUser(appState.currentUser) && !ministry.bAllowChurch) {
			return false
		}

		return true
	}

	const approveProfile = async () => {
		await ministryInfoActions.approveProfile()
	}

	const denyProfile = async () => {
		await ministryInfoActions.denyProfile()
	}

	const sendProfileApproveDenyEmail = async () => {
		const taskId = registerLoadingTask()
		await appActions.MinistryProfileApi.apiMinistryProfileIdSendApproveDenyProfileEmailPost(ministry.ministryId, { emailBody: state.approveDenyEmail.approveDenyEmailContent || 'No Content' })
		if (state.approveDenyEmail.approveDeny === 'approve') {
			await approveProfile()
		} else {
			await denyProfile()
		}
		deregisterLoadingTask(taskId)
		hideModal(state.approveDenyEmail.modalId)
	}

	const sendProfileApprovalEmailModal = async (approveDeny: 'approve' | 'deny') => {
		const taskId = registerLoadingTask()
		const letterTemplateQuery = await appActions.MinistryProfileApi.apiMinistryProfileGetApproveDenyLetterTemplateGet(isNullOrUndefined(ministry.levelID) ? undefined : ministry.levelID, approveDeny === 'approve')
		setState({ ...state, approveDenyEmail: { ...state.approveDenyEmail, approveDeny, template: letterTemplateQuery.data, approveDenyEmailContent: letterTemplateQuery.data } })
		deregisterLoadingTask(taskId)
		showModal(state.approveDenyEmail.modalId)
		showHideApproveDenyModal(true)
	}

	const approveDenyProfilePressed = (approveDeny: 'approve' | 'deny') => {
		setApproveDenyModalState({ ...approveDenyModalState, approveDeny })
		showHideApproveDenyModal(true)
	}

	useEffect(() => {
		enableTooltip(approvedPillId)

		return function cleanup() {
			destroyTooltip(approvedPillId)
		}

		// eslint-disable-next-line
	}, [])

	const allPagesApproved = state.pageList.length === 0 || state.pageList.filter(p => p.pageCompletionStatusId === 2).length === state.pageList.length - 1
	const allPagesApprovedOrDenied = state.pageList.length === 0 || state.pageList.filter(p => p.pageCompletionStatusId === 2 || p.pageCompletionStatusId === -1).length === state.pageList.length - 1
	const previousBAllPagesApproved = usePrevious(allPagesApproved)
	const refreshApproveDenyEnabledState = () => {
		let enableApproveProfile = approveDenyEnabled.enableApproveProfile
		let enableDenyProfile = approveDenyEnabled.enableDenyProfile

		/* 
			Approve/Deny button state taken from Admin\modules\MinistryInfo\New\profile.js 2155
		*/
		if (!allPagesApproved || ministry.bWorkshopsOnly || ministry.bApproved) {
			enableApproveProfile = false
		} else if (UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Modify, appState.currentUser)) {
			enableApproveProfile = true
			if (previousBAllPagesApproved === false) approveDenyProfilePressed('approve')
		}

		if (!allPagesApprovedOrDenied || ministry.bWorkshopsOnly || ministry.bApproved) {
			enableDenyProfile = false
		} else if (UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Modify, appState.currentUser)) {
			enableDenyProfile = true
		}

		setApproveDenyEnabled({ enableApproveProfile, enableDenyProfile })
	}


	const previousMinistryId = usePrevious(ministry.ministryId)
	useEffect(() => {
		if (previousMinistryId !== ministry.ministryId) {
			fetchData()
			refreshApproveDenyEnabledState()
		}

		//eslint-disable-next-line
	}, [ministry])

	useEffect(() => {
		refreshApproveDenyEnabledState()
		//eslint-disable-next-line
	}, [state.pageList])

	const getPageIcon = (page: IProfilePageListModelDocument) => {
		const iconStyle = { marginTop: '2px', marginRight: '4px' }
		switch (page.pageCompletionStatusId) {
			case 2:
				return <CheckCircleIcon className='text-success' style={iconStyle} />
			case 1:
				return <ExclamationCircleIcon className='text-warning' style={iconStyle} />
			case -1:
				return <XCircleIcon className='text-danger' style={iconStyle} />
			default:
				return null
		}
	}

	const showNextPageInList = (pageId: number) => {
		if (!allPagesApproved) {
			const currentPage = state.pageList.find(o => o.pageId === pageId)
			if (currentPage) {
				const currentPageIdx = state.pageList.indexOf(currentPage)
				if (currentPageIdx < state.pageList.length - 1) {
					const nextPage = state.pageList[currentPageIdx + 1]
					// This is a hack since bootstrap's JS seems to be conflicting with changing tabs programmatically...
					const tabButton = document.getElementById(`id-${nextPage.pageId}`)
					if (tabButton) tabButton.click()
					// showTab(`id-${nextPage.pageId}`)
					// setState({ ...state, activeTabId: `tab-${nextPage.pageId}` })
				}
			}
		}
	}

	const getPageDisplay = (page: IProfilePageListModelDocument, index: number) => {
		let pageLayout = 'px-5 py-3'
		const pageApprovalStatus = state.pageApprovalStatus.find(o => o.pageId === page.pageId)

		const pageApproved = async () => {
			const pageList = [...state.pageList]
			const tempPage = pageList.find(p => p.pageId === page.pageId)
			if (tempPage) tempPage.pageCompletionStatusId = 2
			setState({ ...state, pageList })

			showNextPageInList(page.pageId)
		}

		const pageDenied = async () => {
			const pageList = [...state.pageList]
			const tempPage = pageList.find(p => p.pageId === page.pageId)
			if (tempPage) tempPage.pageCompletionStatusId = -1
			setState({ ...state, pageList })
		}

		let pageDetail =
			<MinistryInfoProfilePage
				pageId={page.pageId}
				pageCompletionStatusId={page.pageCompletionStatusId}
				pageApprovalStatus={pageApprovalStatus}
				ministry={ministry}
				pageApproved={pageApproved}
				pageDenied={pageDenied}
			/>

		if (page.pageId === 6) {
			pageDetail = <MinistryInfoProfileContactsPage
				pageId={page.pageId}
				pageCompletionStatusId={page.pageCompletionStatusId}
				pageApprovalStatus={pageApprovalStatus}
				ministry={ministry}
				pageApproved={pageApproved}
				pageDenied={pageDenied}
			/>
			pageLayout = ''
		}

		if (page.pageId === 7) {
			pageDetail = <MinistryInfoProfileDocumentsPage
				pageId={page.pageId}
				pageCompletionStatusId={page.pageCompletionStatusId}
				pageApprovalStatus={pageApprovalStatus}
				ministry={ministry}
				pageApproved={pageApproved}
				pageDenied={pageDenied}
			/>
			pageLayout = ''
		}

		if (page.pageId === 8) {
			pageDetail = <MinistryInfoProfileLog ministry={ministry} />
			pageLayout = ''
		}

		return (
			<div
				key={page.pageId}
				className={`tab-pane fade ${pageLayout} ${index === 0 ? 'show active' : ''}`}
				id={`content-${page.pageId}`}
				role='tabpanel'
				aria-labelledby={`${page.pageId}-tab`}
				style={{ height: '100%' }}
			>
				{state.activeTabId === `tab-${page.pageId}` ?
					pageDetail
					:
					null
				}
			</div>
		)
	}

	return (
		<React.Fragment>
			<Modal
				{...approveDenyModal}
				modalTitle='Approve Profile'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button
							type='button'
							disabled={approveDenyModalState.approveDeny === 'approve' && !canApproveProfile()}
							className={`btn ${approveDenyModalState.approveDeny === 'approve' ? 'btn-success' : 'btn-danger'}`}
							onClick={() => {
								if (!approveDenyModalState.approveDeny) return
								if (sendApproveDenyEmail) {
									sendProfileApprovalEmailModal(approveDenyModalState.approveDeny)
								} else {
									if (approveDenyModalState.approveDeny === 'approve') approveProfile()
									if (approveDenyModalState.approveDeny === 'deny') denyProfile()
									showHideApproveDenyModal(false)
								}
							}}
						>{approveDenyModalState.approveDeny === 'approve' ? 'Approve' : 'Deny'} Profile</button>
					</React.Fragment>
				}
				onModalHidden={() => setApproveDenyModalState({ ...approveDenyModalState, approveDeny: undefined })}
			>
				{getApproveDenyModalBody()}
			</Modal>

			<Modal
				modalId={state.approveDenyEmail.modalId}
				modalTitle={state.approveDenyEmail.approveDeny === 'approve' ? 'Customize Approval Email' : 'Customize Denial Email'}
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' className='btn btn-success' onClick={() => sendProfileApproveDenyEmail()}>Send</button>
					</React.Fragment>
				}
				onModalHidden={() => {
					setState({ ...state, approveDenyEmail: { modalId: state.approveDenyEmail.modalId, sendCheckboxId: state.approveDenyEmail.sendCheckboxId } })
					showHideApproveDenyModal(false)
				}}
			>
				<p>
					This email is a mail merge email and all Values inserted using the <b>Insert Template Variable</b> button will be automatically filled with primary contact and AD names.
				</p>
				{state.approveDenyEmail.template ?
					<Editor
						initialValue={state.approveDenyEmail.template}
						init={{
							height: 500,
							menu: { insertTemplateVariable: { title: 'Insert Template Variable', items: APPROVE_DENY_EMAIL_TEMPLATE_VARIABLES.join(' ') } },
							menubar: 'insertTemplateVariable',
							plugins: [
								'advlist autolink lists link image charmap print preview anchor',
								'searchreplace visualblocks code fullscreen',
								'insertdatetime media table paste code help wordcount'
							],
							toolbar: 'resetTemplate | fullscreen | undo redo | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | formatselect  | removeformat | help',
							block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6',
							// @ts-ignore
							setup: (editor) => {
								editor.ui.registry.addButton('resetTemplate', {
									icon: 'reload',
									tooltip: 'Reset Template',
									onAction: function () {
										editor.resetContent(state.approveDenyEmail.template)
									}
								})

								APPROVE_DENY_EMAIL_TEMPLATE_VARIABLES.forEach(templateVariable => {
									editor.ui.registry.addMenuItem(templateVariable, {
										text: templateVariable,
										onAction: () => editor.insertContent(`{{${templateVariable}}}`)
									})
								})
							}
						}}
						onEditorChange={(content: string) => setState({ ...state, approveDenyEmail: { ...state.approveDenyEmail, approveDenyEmailContent: content } })}
						value={state.approveDenyEmail.approveDenyEmailContent}
					/>
					:
					null
				}
			</Modal>

			<div style={{ display: 'flex' }}>
				<ul className='nav nav-tabs my-2 ml-2' role='tablist' style={{ flex: 1 }}>
					{state.pageList.map((page, index) => (
						<li key={page.pageId} className='nav-item'>
							<a
								className={`nav-link px-2 ${index === 0 ? 'active' : ''}`}
								id={`id-${page.pageId}`}
								onClick={() => {
									showTab(`id-${page.pageId}`)
									setState({ ...state, activeTabId: `tab-${page.pageId}` })
								}}
								href={`#content-${page.pageId}`}
								role='tab'
								aria-controls={`${page.pageId}`}
								aria-selected={index === 0 ? 'true' : 'false'}
								style={{ display: 'flex', alignItems: 'center' }}
							>
								{getPageIcon(page)}
								{MinistryInfoProfilePageModel.getTitle(page)}
							</a>
						</li>
					))}
				</ul>
				<div className='my-2 mr-2 border-bottom d-flex align-items-center'>
					{ministry.bApproved ? <span id={approvedPillId} {...buildTooltipProps({ tooltipText: ministry.movedToGrantByFirstName ? `Profile approved by ${ministry.movedToGrantByFirstName} ${ministry.movedToGrantByLastName ? `${ministry.movedToGrantByLastName.slice(0, 1)}` : ''} ${ministry.dMovedToGranting ? `on ${moment(ministry.dMovedToGranting).format('M/D/YYYY')}` : ''}` : 'Profile approved.' })} className='badge badge-pill badge-success mr-2'>{`Approved`}</span> : null}
					{ministry.bWorkshopsOnly ? <span className='badge badge-pill badge-warning mr-2'>{`Denied`}</span> : null}
					<button
						className='btn btn-sm btn-secondary mr-2'
						disabled={!approveDenyEnabled.enableApproveProfile}
						onClick={() => {
							approveDenyProfilePressed('approve')
							showHideApproveDenyModal(true)
						}}
					>
						<PersonPlusIcon /> Approve Profile
					</button>
					<button
						className='btn btn-sm btn-secondary'
						disabled={!approveDenyEnabled.enableDenyProfile}
						onClick={() => {
							approveDenyProfilePressed('deny')
							showHideApproveDenyModal(true)
						}}
					>
						<PersonDashIcon /> Deny Profile
					</button>
					{/* <button className='btn btn-sm btn-secondary' disabled> <FileTextIcon /> Print Profile</button> */}
				</div>
			</div>

			<div className='tab-content' style={{ overflow: 'auto' }}>
				{state.pageList.map(getPageDisplay)}
			</div>
		</React.Fragment>
	)
}