import { useContext, useMemo } from 'react'
import { IGridColumn } from '../stores/grid-definitions'
import { IFilter } from '../stores/api-actions'
import { Checkbox } from './forms'
import { GridContext } from './grid'

export const GridColumnFilterSummary = () => {
	const { state: { columns, queryState, loading }, actions } = useContext(GridContext)!

	const filterToggleUi = useMemo(() => {

		const filters = [...queryState.filters || []]

		const _columnFilters: Map<string, { col: IGridColumn, filters: IFilter[] }> = new Map()

		filters.filter(f => f.value !== null && f.value !== undefined && !f.hidden).forEach(filter => {
			const col = columns.find(c => c.property === filter.property)
			if (col) {
				const colFilters = _columnFilters.get(col.property)
				if (colFilters) {
					colFilters.filters.push(filter)
				} else {
					_columnFilters.set(col.property, { col, filters: [filter] })
				}
			}
		})

		const ui = Array.from(_columnFilters.keys())
			.map(colProperty => {
				const { col, filters } = _columnFilters.get(colProperty)!
				return (
					<div key={col.property} className={`_row active-column vertical-center mr-4`} style={{ marginBottom: '10px' }}>
						<Checkbox disabled={col.filterRequired} id={`${col.property}-allFilters`} checked={!Boolean(col.disableFilters) && (Boolean(col.filterRequired) || filters.some(o => o.enabled))} onChange={(e) => actions.toggleColumnFilters(col)} />
						<div>
							<span className='column-name'>{col.title}</span>
							<div
								onClick={() => {
									actions.updateColumnFilters(filters.map(filter => {
										return {
											...filter,
											enabled: undefined,
											value: undefined
										}
									}))
								}}
								className={`clear-button ${loading ? 'disabled' : ''}`}
								style={{ marginTop: '4px' }}
							>
								Clear {filters.length === 1 ? 'filter' : 'filters'}
							</div>
						</div>
					</div>
				)
			})

		// Manually add filter-by-event filter types to the list since they normally do not have a corresponding column
		filters.filter(f => f.operator === 'filter-by-event' && !f.hidden).forEach(filter => {
			const col = columns.find(c => c.property === filter.property)
			// Still check if there is a column for this event filter, because if there is it will already have been displayed by the column-based list of filters created above.
			// Also check to make sure there is some filter data on the event filter and it's not just a placeholder waiting for data
			if (!col && filter.filterByEventOptions && Object.keys(filter.filterByEventOptions).length > 0) {
				// No column! This guy needs to be added on his own.
				ui.push(
					<div key={filter.id} className={`_row active-column vertical-center mr-4`} style={{ marginBottom: '10px' }}>
						<Checkbox id={`${filter.id}-allFilters`} checked={filter.enabled} onChange={(e) => actions.updateColumnFilters([{ ...filter, enabled: !filter.enabled }])} />
						<div>
							<span className='column-name'>Filter by Events</span>
							<div
								onClick={() => {
									actions.updateColumnFilters([{
										id: filter.id,
										operator: 'filter-by-event',
										property: filter.id,
										filterByEventOptions: {},
										value: filter.id,
									}])
								}}
								className={`clear-button ${loading ? 'disabled' : ''}`}
								style={{ marginTop: '4px' }}
							>
								Clear {filters.length === 1 ? 'filter' : 'filters'}
							</div>
						</div>
					</div>
				)
			}
		})

		return ui
	}, [columns, queryState, loading])

	if (filterToggleUi.length) {
		return <div className='d-flex'>{filterToggleUi}</div>
	} else {
		return <div>No active filters. Hover a column name and select "filter" to add a filter.</div>
	}
}