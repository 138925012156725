import React, { useContext, useEffect, useState } from 'react'
import { MinistryInfoContext } from './ministry-info'
import { IGridState, GridDataFetch, IGridListItem, isGridListItemObjectValueTypeArray, GridUserInteractionStateKey } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { filterGridListItems, openUrlInNewTab, uuidv4, showModal, hideModal, onModalHidden, sortListBySorts } from '../services/helpers'
import { SquareDeleteIcon } from './partials'
import { ministryContactDefaultColumns, MinistryContactModel } from '../models/ministry-contact'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { Grid } from './grid'
import { Loading } from './loading'
import { ReactComponent as EnvelopeIcon } from '../assets/envelope.svg'
import { ReactComponent as Tag } from '../assets/tag.svg'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { Modal } from './modal'
import { TagsList } from './tags-list'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { IMinistrySummaryResponseModelDocument, IMinistryContactSummaryModelDocument, IZStatesDocument, IApprovalStatusDocument } from '../open-api'
import { ContactForm } from './contact-form'
import { UserModel, UserPermissionLevel, App } from '../models/user'
import { gridReducer } from '../stores/grid-reducer'
import { IncognitoIcon, PersonIcon } from '../assets'
import { useModal, useHTTPRequestUiWrapper } from '../services/hooks'

interface IMinistryInfoProfileContactsPageProps {
	pageId: number
	pageCompletionStatusId: number | null
	pageApprovalStatus?: IApprovalStatusDocument
	ministry: IMinistrySummaryResponseModelDocument
	pageApproved: () => Promise<void>
	pageDenied: () => Promise<void>
}
export const MinistryInfoProfileContactsPage = (props: IMinistryInfoProfileContactsPageProps) => {
	const { pageCompletionStatusId, pageApprovalStatus, ministry, pageId, pageApproved, pageDenied } = props

	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [state, setState] = useState<{
		enablePageApprove: boolean
		enablePageDeny: boolean
	}>({
		enablePageApprove: false,
		enablePageDeny: false,
	})

	const { enablePageApprove, enablePageDeny } = state

	useEffect(() => {
		if (!ministryInfoState.ministryContacts) ministryInfoActions.fetchMinistryContacts()
	}, [])

	const fetchMinistryContacts: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryContacts } = _state

		if (!ministryContacts) return { count: 0, rows: [] }

		let _ministryContacts = [...ministryContacts]

		if (queryState.sorts) sortListBySorts(_ministryContacts, queryState.sorts)

		let rows = _ministryContacts.map(ministryContact => MinistryContactModel.toGridListItem(ministryContact))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { count: _ministryContacts.length, rows }
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [approvePageModal, showHideApprovePageModal] = useModal()
	const [denyPageModal, showHideDenyPageModal] = useModal()

	/* 
			Adding/Removing Tags from a Contact
	*/
	const [tagsModalState, setTagsModalState] = useState<{
		modalId: string
		modalTitle: string
		startingTagIds: number[]
		endingTagIds: number[]
	}>({
		modalId: uuidv4(),
		modalTitle: 'Tags',
		startingTagIds: [],
		endingTagIds: [],
	})
	const setSelectedTags = (endingTagIds: number[]) => setTagsModalState({ ...tagsModalState, endingTagIds })
	let resetTagList: () => void
	onModalHidden(tagsModalState.modalId, () => { if (resetTagList) resetTagList() })

	const addRemoveTagsFromSelectedContact = async () => {
		if (!selectedRow) return

		const contactId = parseInt(selectedRow.id)

		// Every tag that was in the final list but wasn't in the initial list
		const addedTags = tagsModalState.endingTagIds.filter(endingTagId => !tagsModalState.startingTagIds.find(startingTagId => startingTagId === endingTagId))
		// Every tag that was in the initial list, but isn't in the final list
		const removedTags = tagsModalState.startingTagIds.filter(startingTagId => !tagsModalState.endingTagIds.find(endingTagId => startingTagId === endingTagId))

		const promises: Promise<any>[] = []
		addedTags.forEach(tagId => promises.push(appActions.Tag2DataApi.apiTag2DataPost({ tagId, data: contactId })))
		removedTags.forEach(tagId => promises.push(appActions.Tag2DataApi.apiTag2DataDelete(tagId, contactId)))

		hideModal(tagsModalState.modalId)

		if (promises.length) {
			gridActions.setLoading(true)
			await Promise.all(promises)

			const refreshPromises: Promise<any>[] = [appActions.fetchTagsSummary(), ministryInfoActions.fetchMinistryContacts()]
			await Promise.all(refreshPromises)
		}
	}

	//const [sendLoginInfoModal] = useState(uuidv4())
	const [contactFormModal] = useState(uuidv4())
	const [showContactForm, setShowContactForm] = useState(false)
	const [deleteContactModal] = useState(uuidv4())
	const [confirmResendModal, showHideConfirmResendModal] = useModal()
    const [confirmSendLoginInstructionsModal, showHideConfirmSendLoginInstructionsModal] = useModal()

	const [selectedContact, setSelectedContact] = useState<IMinistryContactSummaryModelDocument>()
	useEffect(() => {
		if (selectedContact) showModal(contactFormModal)
		// eslint-disable-next-line
	}, [selectedContact])

	const initialGridState: IGridState = {
		...defaultGridState,
		tagType: 'MINISTRYCONTACTID',
		columns: ministryContactDefaultColumns,
		userSessionStateKey: GridUserInteractionStateKey.MinistryContacts,
		usingLocalData: true,
		disabledPagination: true,
		rowSelectEnabled: true,
		dataSource: fetchMinistryContacts,
		gridActions: [
			{
				id: 'ministryContactActions',
				label: 'Create Contact',
				onClick: () => {
					showModal(contactFormModal)
				},
				disabled: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser)
			},
			// {
			// 	id: 'mergeContacts',
			// 	label: 'Merge Contacts',
			// 	onClick: () => {
			// 		showModal(contactFormModal)
			// 	},
			// 	disabled: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser)
			// }
		],
		rowActions: {
			entityInfo: {
				id: 'entityInfo',
				action: async (options) => {
					options.e.stopPropagation()
				},
				url: (row) => `/contact-info/${row.id}`,
				icon: <PersonIcon />,
				tooltipText: 'View Contact'
			},
			impersonate: {
				id: 'impersonate',
				action: async (options) => {
					const { e, row } = options

					e.stopPropagation()
					openUrlInNewTab(row.values.impersonationUrl?.toString() ?? '')
				},
				icon: <IncognitoIcon />,
				tooltipText: 'Impersonate User',
				disabled: (row) => !row.values.subjectId || !row.values.doesUserHavePermissionToLogin
			},
			resendEmailInvite: {
				id: 'resendEmailInvite',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideConfirmResendModal(true)
				},
				icon: <EnvelopeIcon />,
				tooltipText: 'Resend Email Invitation',
				disabled: (row) => !row.values.doesUserHavePermissionToLogin,
				hidden: (row) => !!row.values.subjectId
			},
			sendLoginInstructions: {
				id: 'sendLoginInstructions',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideConfirmSendLoginInstructionsModal(true)
				},
				icon: <EnvelopeIcon />,
				tooltipText: 'Send Login Instructions',
				disabled: (row) => !row.values.doesUserHavePermissionToLogin,
            	hidden: (row) => !row.values.subjectId
			},
			deleteContact: {
				id: 'deleteContact',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(deleteContactModal)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete Contact',
				disabled: !UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Read, appState.currentUser)
			},
			manageTags: {
				id: 'manageTags',
				action: async (options) => {
					const { e, row } = options

					e.stopPropagation()

					let existingTagIds: number[] = []
					const _rowTags = row.values.tags
					if (isGridListItemObjectValueTypeArray(_rowTags)) existingTagIds = _rowTags.map(tag => parseInt(tag.id.toString()))

					setTagsModalState({ ...tagsModalState, modalTitle: `Tags for ${row.values['fullName']}`, startingTagIds: existingTagIds, endingTagIds: existingTagIds })
					setSelectedRow(row)
					showModal(tagsModalState.modalId)
				},
				icon: <Tag />,
				tooltipText: 'Manage Tags'
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	useEffect(() => {
		/*
			Admin\modules\MinistryInfo\New\profile.js 2176
			toggle 'Approve Page' / 'Deny Page' disabled states
		*/
		let _enablePageApprove = state.enablePageApprove
		let _enablePageDeny = state.enablePageDeny

		switch (pageCompletionStatusId) {
			case -1:
				_enablePageApprove = true
				_enablePageDeny = false
				break
			case 1:
				_enablePageApprove = true
				_enablePageDeny = true
				break
			case 2:
				_enablePageApprove = false
				_enablePageDeny = true
				break
			default:
				_enablePageApprove = UserModel.userIsSuperUser(appState.currentUser)
				break
		}

		setState({ ...state, enablePageApprove: _enablePageApprove, enablePageDeny: _enablePageDeny })

		//eslint-disable-next-line
	}, [pageCompletionStatusId])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryContacts])

	//const [countries, setCountries] = useState<IZCountrysDocument[]>()
	const [states, setStates] = useState<IZStatesDocument[]>()
	// 20240312 TB - Narrowing blacklistMailTypes down to only two options and hard coding them in constants.ts as MAIL_TYPE_OPTIONS
	//const [blacklistMailTypes, setBlacklistMailTypes] = useState<IMailTypeDocument[]>()
	useEffect(() => {
		appActions.StatesApi.apiStatesGet()
			.then(results => {
				setStates(results.data)
			})

		// appActions.MailTypesApi.apiMailTypesMinistryContactsBlacklistOptionsGet()
		// 	.then(results => {
		// 		setBlacklistMailTypes(results.data)
		// 	})

		// appActions.CountriesApi.apiCountriesGet()
		// 	.then(results => {
		// 		// results.data.sort((a, b) => {
		// 		// 	if (a.country === null) return -1
		// 		// 	if (b.country === null) return 1
		// 		// 	if (a.country < b.country) return -1
		// 		// 	if (a.country > b.country) return 1
		// 		// 	return 0
		// 		// })
		// 		const usa = results.data.find(c => c.countryId === UNITED_STATES_COUNTRY_ID)
		// 		if (usa) {
		// 			const usaIdx = results.data.indexOf(usa)
		// 			const removed = results.data.splice(usaIdx, 1)
		// 			results.data.unshift(...removed)
		// 		}
		// 		setCountries(results.data)
		// 	})

		// eslint-disable-next-line
	}, [])


	if (!ministryInfoState.ministryContacts || !ministryInfoState.ministry || !states) {
		return <Loading />
	} else {
		return (
			<React.Fragment>
				<div className='mb-3 d-flex'>
					{enablePageApprove ?
						<button
							type='button'
							className='btn btn-light ml-1'
							onClick={async () => {
								showHideApprovePageModal(true)
							}}
						>
							Approve Page
						</button>
						:
						null
					}
					{enablePageDeny ? <button type='button' className='btn btn-light ml-1' onClick={() => showHideDenyPageModal(true)}>Deny Page</button> : null}
					{pageApprovalStatus && enablePageDeny ? <i className='ml-1 d-flex align-items-center'>{pageApprovalStatus.pageName} approved {pageApprovalStatus.completeInfo}</i> : null}
				</div>
				<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

				<Modal
					{...approvePageModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button className='btn btn-secondary' onClick={() => showHideApprovePageModal(false)} >Cancel</button>
							<button type="button" className="btn btn-primary" onClick={async () => {

								await makeHttpRequestWithUi({
									request: appActions.MinistryProfileApi.apiMinistryProfileIdApproveProfilePagePost(ministry.ministryId, { pageId: pageId }),
									toastErrorMessage: 'Encountered an error approving page.',
									toastSuccessMessage: 'Successfully approved page.',
								})
								await pageApproved()

								showHideApprovePageModal(false)
							}}>Confirm</button>
						</React.Fragment>
					}
				>
					Are you sure you want to approve this page?
				</Modal>

				<Modal
					{...denyPageModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
							<button type="button" className="btn btn-primary" onClick={async () => {

								await makeHttpRequestWithUi({
									request: appActions.MinistryProfileApi.apiMinistryProfileIdDenyProfilePagePost(ministry.ministryId, { pageId: pageId }),
									toastErrorMessage: 'Encountered an error denying page.',
									toastSuccessMessage: 'Successfully denied page.',
								})
								await pageDenied()

								showHideDenyPageModal(false)
							}}>Confirm</button>
						</React.Fragment>
					}
				>
					Are you sure you want to deny this page?
				</Modal>

				{/* Edit tags for the selected row */}
				<Modal
					modalId={tagsModalState.modalId}
					modalTitle={tagsModalState.modalTitle}
					allowOverflow={true}
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' className='btn btn-primary' onClick={addRemoveTagsFromSelectedContact}>Save changes</button>
						</React.Fragment>
					}
				>
					<TagsList
						tags={appState.tags?.filter(tag => tag.tableKey === 'MINISTRYCONTACTID' && !tag.isArchived) || []}
						selectedTagIds={tagsModalState.endingTagIds}
						setSelectedTagIds={setSelectedTags}
						setResetList={(reset) => resetTagList = reset}
						tagType={'MINISTRYCONTACTID'}
					/>
				</Modal>

				<Modal
					{...confirmResendModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' 
								className='btn btn-primary' 
								onClick={async () => {
									if (!selectedRow) return
									//const taskId = registerLoadingTask()
									await appActions.MinistryContactsApi.apiMinistryContactsMinistryContactIdSendInviteOrLoginInstructionsPost(parseInt(selectedRow.id))
									//deregisterLoadingTask(taskId)
									setSelectedRow(undefined)
									showHideConfirmResendModal(false)
							}}>Send</button>
						</React.Fragment>
					}
					onModalHidden={() => setSelectedRow(undefined)}
				>
					Are you sure you want to re-send the invite email to this contact containing a link for them to register with Mission Increase?
				</Modal>

				<Modal
					{...confirmSendLoginInstructionsModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' 
								className='btn btn-primary' 
								onClick={async () => {
									if (!selectedRow) return
									//const taskId = registerLoadingTask()
									await appActions.MinistryContactsApi.apiMinistryContactsMinistryContactIdSendInviteOrLoginInstructionsPost(parseInt(selectedRow.id))
									//deregisterLoadingTask(taskId)
									setSelectedRow(undefined)
									showHideConfirmSendLoginInstructionsModal(false)
							}}>Send</button>
						</React.Fragment>
					}
					onModalHidden={() => setSelectedRow(undefined)}
				>
					Are you sure you want to send the login instructions email to this giver?
				</Modal>

				<Modal
					modalId={deleteContactModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' className='btn btn-danger' disabled={ministryInfoState.primaryContact?.ministryContactId.toString() === selectedRow?.id} onClick={async () => {
								if (!selectedRow) return
								const taskId = registerLoadingTask()
								await appActions.MinistryContactsApi.apiMinistryContactsIdDelete(parseInt(selectedRow.id))
								await ministryInfoActions.fetchMinistryContacts()
								hideModal(deleteContactModal)
								deregisterLoadingTask(taskId)
							}}>Delete</button>
						</React.Fragment>
					}
				>
					{ministryInfoState.primaryContact?.ministryContactId.toString() === selectedRow?.id ?
						`We're sorry, but you cannot delete the primary contact.`
						:
						`Are you sure you want to delete this contact?`
					}
				</Modal>

				<Modal
					modalId={contactFormModal}
					modalTitle={selectedContact ? 'Edit Contact' : 'Create Contact'}
					size='lg'
					onModalHidden={() => setSelectedContact(undefined)}
					dismissible={false}
					onModalShow={() => {
						setShowContactForm(true)
					}}
					onModalHide={() => {
						setShowContactForm(false)
					}}
				>
					{showContactForm ?
						<ContactForm
							ministryId={ministryInfoState.ministry.ministryId}
							afterSave={async (primaryContactSaved) => {
								const promises: Promise<any>[] = [ministryInfoActions.fetchMinistryContacts()]
								if (primaryContactSaved) promises.push(ministryInfoActions.fetchMinistrySummary())
								await Promise.all(promises)
								hideModal(contactFormModal)
								setSelectedContact(undefined)
								if (!selectedContact)
									appActions.addAlert({
										id: uuidv4(),
										title: 'Success',
										body: 'The contact has been created. If an email address was provided and they have permission to log into the Ministry Portal, an email has been sent to them with instructions on how to setup their account.',
									})
							}}
							ministryContact={selectedContact}
							states={states}
							//blacklistMailTypes={blacklistMailTypes}
						/>
						:
						null
					}
				</Modal>
			</React.Fragment>
		)
	}
}