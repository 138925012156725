import React, { useContext, useEffect, useState } from 'react'
import { MinistryInfoFyDataGeneralData } from './ministry-info-fy-data-general-data'
import { MinistryInfoFyDataIncomeSources } from './ministry-info-fy-data-income-sources'
import { ReactComponent as ArrowUpIcon } from '../assets/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down.svg'
import { ReactComponent as ArrowRepeatIcon } from '../assets/arrow-repeat.svg'
import { MinistryInfoContext } from './ministry-info'
import { uuidv4, showModal, hideModal } from '../services/helpers'
import { Modal } from './modal'
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { InfoCircleIcon } from "../assets"

export const MinistryInfoFyData = () => {
	const appActions = useContext(AppActionContext)!
	const { actions: ministryInfoActions, state: ministryInfoState, } = useContext(MinistryInfoContext)!

	const [helpModal, showHideHelpModal] = useModal()
	const [shiftGsDataUpModalId] = useState(uuidv4())
	const [shiftGsDataDownModalId] = useState(uuidv4())
	const [refreshGsDataModalId] = useState(uuidv4())

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const shiftGsDataUp = async () => {
		if (ministryInfoState.ministry) {
			makeHTTPRequestWithUi({
				request: appActions.MinistryDataApi.apiMinistriesIdIncrementFyDataPut(ministryInfoState.ministry.ministryId),
				toastSuccessMessage: 'GS Data successfully shifted up.',
				toastErrorMessage: 'There was an error shifing GS Data up.',
				onRequestSuccess: async () => {
					await ministryInfoActions.fetchMinistryFyData()
					hideModal(shiftGsDataUpModalId)
				},
				onRequestFailure: async () => hideModal(shiftGsDataUpModalId),
			})
		}
	}

	const shiftGsDataDown = async () => {
		if (ministryInfoState.ministry) {
			await makeHTTPRequestWithUi({
				request: appActions.MinistryDataApi.apiMinistriesIdDecrementFyDataPut(ministryInfoState.ministry.ministryId),
				toastSuccessMessage: 'GS Data successfully shifted down.',
				toastErrorMessage: 'There was an error shifing GS Data down.',
				onRequestSuccess: async () => {
					await ministryInfoActions.fetchMinistryFyData()
					hideModal(shiftGsDataDownModalId)
				},
				onRequestFailure: async () => hideModal(shiftGsDataDownModalId)
			})
		}
	}

	const refreshGsData = async () => {
		if (ministryInfoState.ministry) {
			await makeHTTPRequestWithUi({
				request: fetch(`https://tasks.missionincrease.org/tasks/UpdateMinistryIRSData/${ministryInfoState.ministry.ministryId}`, { method: 'GET' }),
				toastSuccessMessage: 'GS Data successfully refreshed.',
				toastErrorMessage: 'There was an error refreshing GS data.',
				onRequestSuccess: async () => {
					await ministryInfoActions.fetchMinistryFyData()
					hideModal(refreshGsDataModalId)
				},
				onRequestFailure: async () => hideModal(refreshGsDataModalId)
			})
		}
	}

	useEffect(() => {
		ministryInfoActions.fetchMinistryFyData()
		// eslint-disable-next-line
	}, [ministryInfoState.ministry])

	return (
		<React.Fragment>
			<div className='d-flex flex-column'>
				<div className='m-2'>
					<div className='d-flex justify-content-end'>
						<button type='button' className='btn btn-info btn-sm icon-right ml-1' onClick={() => showHideHelpModal(true)}>Help <InfoCircleIcon /></button>
						<button type='button' className='btn btn-secondary btn-sm icon-right ml-1' onClick={() => showModal(shiftGsDataUpModalId)}>Shift GS Data Up <ArrowUpIcon /></button>
						<button type='button' className='btn btn-secondary btn-sm icon-right ml-1' onClick={() => showModal(shiftGsDataDownModalId)}>Shift GS Data Down <ArrowDownIcon /></button>
						<button type='button' className='btn btn-secondary btn-sm icon-right ml-1' onClick={() => showModal(refreshGsDataModalId)}>Refresh GS Data <ArrowRepeatIcon /></button>
					</div>
				</div>

				<h4 className='mb-2 ml-2'>General Data</h4>
				<div className='mb-2 ml-2'>Note: It can take 3+ years after the fiscal year for GuideStar data (including 990 file) to populate. In some cases, it may never get populated.</div>
				<MinistryInfoFyDataGeneralData />

				<h4 className='my-2 ml-2'>Income Sources</h4>
				<MinistryInfoFyDataIncomeSources />
			</div>

			<Modal
				modalId={shiftGsDataUpModalId}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' className='btn btn-primary' onClick={shiftGsDataUp}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to shift GS Data up?
			</Modal>

			<Modal
				modalId={shiftGsDataDownModalId}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' className='btn btn-primary' onClick={shiftGsDataDown}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to shift GS Data down?
			</Modal>

			<Modal
				modalId={refreshGsDataModalId}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' className='btn btn-primary' onClick={refreshGsData}>Confirm</button>
					</React.Fragment>
				}
			>
				<p>Are you sure you want to refresh the GS data?</p>

				It can take 5-10 minutes before any new 990 files appear.
			</Modal>

			<Modal
				{...helpModal}
				modalTitle='FY Data Help'
				size={'lg'}
			>
				<h3>Fiscal vs. Tax Years</h3>
				<br />
				<div className='text-danger'>Mission Increase requires your <b>Tax Year</b> information in order to better align with the financial information you submit to the Tax BureauIRS via your <b>Form 990</b>. It's important to understand the difference between <b>Fiscal Year</b> and <b>Tax Year</b>.</div>
				<br />
				<div>Most organizations think in terms of organization fiscal years. The Tax BureauIRS, in contrast, focuses on the Start/Tax Year in determining the <b>Tax Year</b> and which version of the Form 990 to use.</div>
				<br />
				<div>For a majority of non-profits whose fiscal year runs from January 1 to December 31 there is no difference; the <b>Fiscal Year</b> and the <b>Tax Year</b> represent the same 12 month period. <span className='text-danger'>(see Org "X" example below)</span></div>
				<br />
				<div><b>However</b>, some organizations, like Org "Y" <span className='text-danger'>(below)</span> have fiscal years which run from July 1 to June 30. When Org "Y" completed its 2010 fiscal year on June 30, 2010, it would use the Tax Bureau FormIRS Form 990 with "2009" in the upper right corner for <b><u>Tax Year 2009</u></b>, since this reflects its Start/Tax Year.</div>
				<br />
				<div><b><span className='text-danger'>Examples</span>:</b></div>
				<table className='table'>
					<thead className='borderless'>
						<tr>
							<th></th>
							<th><b>FY Start Date</b></th>
							<th><b>FY End Date</b></th>
							<th><b>Tax Year</b></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><span className='text-danger'>Org X</span></td>
							<td>1/1/2009</td>
							<td>12/31/2009</td>
							<td>2009</td>
						</tr>
						<tr>
							<td><span className='text-danger'>Org Y</span></td>
							<td>7/1/2009</td>
							<td>6/30/2010</td>
							<td>2009</td>
						</tr>
						<tr>
							<td><span className='text-danger'>Org Z</span></td>
							<td>10/1/2008</td>
							<td>9/30/2009</td>
							<td>2008</td>
						</tr>
					</tbody>
				</table>
				<div className='mb-3'></div>

				<h3 className='mb-3'>What does "Shift GS Data Up/Down" do?</h3>
				<h5>Summary</h5>
				<p>Increments various DB records by one fiscal year for the given ministry Id.</p>
				<p>Data affected by this include:</p>
				<ul>
					<li>dbo.MinistryData.GsIncome</li>
					<li>dbo.MinistryData.GsExpenses</li>
					<li>dbo.GsPdf.FiscalYear</li>
					<li>dbo.MinistryFiles.FiscalYear</li>
					<li>dbo.Ministries.GsDataShiftYears</li>
				</ul>
				<p className='mt-2'>A note is added to track this event.</p>
				<h5>Details</h5>
				<p>Shift Up</p>
				<ul>
					<li>MinistryData
						<ul>
							<li>If the most recent record has GuideStar (GS) data, create a new MD record to have somewhere to push data up to.</li>
							<li>Shift the GsIncome and GsExpenses data up, accordingly.</li>
						</ul>
					</li>
					<li>GsPdf
						<ul><li>Increment each record's FiscalYear by +1.</li></ul>
					</li>
					<li>MinistryFiles
						<ul><li>Increment each 990 record FiscalYear by +1.</li></ul>
					</li>
					<li>Ministries
						<ul>
							<li>Increment the GsDataShiftYears by +1</li>
						</ul>
					</li>
				</ul>
				<p>Shift Down</p>
				<ul>
					<li>MinistryData
						<ul>
							<li>If the earliest record has GuideStar (GS) data, create a new MD record to have somewhere to push data down to.</li>
							<li>Shift the GsIncome and GsExpenses data down, accordingly.</li>
						</ul>
					</li>
					<li>GsPdf
						<ul><li>Decrement each record's FiscalYear by -1.</li></ul>
					</li>
					<li>MinistryFiles
						<ul><li>Decrement each 990 record FiscalYear by -1.</li></ul>
					</li>
					<li>Ministries
						<ul>
							<li>Decrement the GsDataShiftYears by -1</li>
						</ul>
					</li>
				</ul>
				<div className='mb-3'></div>
			</Modal>

		</React.Fragment>
	)
}