import { useContext, useEffect, useState } from "react"
import { QuickNoteForm } from "./quick-note-form"
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { INotesDocument, INotesSummaryDocument } from "../open-api"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { App, UserModel, UserPermissionLevel } from "../models/user"
import { NOTE_TYPES } from "../constants"
import { NoteModel } from "../models/ministry-note"
import { useNoteDefaultColumns, useModal } from "../services/hooks"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { Modal } from "./modal"
import { Grid } from "./grid"
import { NoteForm } from "./note-form"

interface IGrantRequestFormSummaryNotesProps {
    notes: INotesSummaryDocument[]
    grantId: number
    afterSave?:  () => void
}

export const GrantRequestFormSummaryNotes = (props: IGrantRequestFormSummaryNotesProps) => {
    const { notes, grantId, afterSave } = props
    const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [selectedNote, setSelectedNote] = useState<INotesDocument>()
    const [noteModal, showHideNoteModal] = useModal()
    const [deleteModal, showHideDeleteModal] = useModal()

	const fetchNotes: GridDataFetch<INotesSummaryDocument[]> = async (queryState, _notes) => {
		if (!_notes) return { count: 0, rows: [] }

		//let cleanedNotes: INotesSummaryDocument[] = [...notes]

		// Make sure the user has permissions to see admin note types. If not, filter admin notes out.
		if (!UserModel.checkPermissionLevelForUser(App.MiAdmin, UserPermissionLevel.Administrator, appState.currentUser)) {
			const adminNoteTypeId = NOTE_TYPES.Admin
			_notes = _notes.filter(item => item.noteTypeId !== adminNoteTypeId)
		}

		if (queryState.sorts) sortListBySorts(_notes, queryState.sorts)

		let rows = _notes.map(note => NoteModel.toGridListItem(note))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length }
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: useNoteDefaultColumns(),
		disabledPagination: true,
		dataSource: fetchNotes,
		// gridActions: [
		// 	{
		// 		id: 'noteActions',
		// 		label: 'Create Note',
		// 		disabled: !UserModel.checkPermissionLevelForUser(App.NoteManager, UserPermissionLevel.Modify, appState.currentUser),
		// 		onClick: () => showHideNoteModal(true)
		// 	}
		// ],
		rowActions: {
			editNote: {
				id: 'editNote',
				action: async (options) => {
					options.e.stopPropagation()
					const noteQuery = await appActions.NotesApi.apiNotesIdGet(parseInt(options.row.id))
					setSelectedNote(noteQuery.data)
                    showHideNoteModal(true)
				},
				tooltipText: 'View Note',
			},
			deleteNote: {
				id: 'deleteNote',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideDeleteModal(true)
				},
				tooltipText: 'Delete Note',
			},
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, notes)

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [notes])

	return (
		<>
            <div className="ml-2 mt-2">
                GrowthTrack users may use this tool to add relevant notes to the Grant Request, like status updates. Use this tool to communicate information that doesn't fit anywhere else.
            </div>
			<div>
				<h4 className="mb-2 ml-2 pt-2">Quick Note</h4>
				<QuickNoteForm
					grantId={grantId}
					noteText=''
					afterSave={async () => { afterSave && afterSave() }} />
			</div>

			<h4 className="mb-2 ml-2">Notes</h4>

			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

            <Modal
                {...noteModal}
				modalTitle='Note'
				onModalHidden={() => {
					setSelectedNote(undefined)
				}}
			>
				{selectedNote && <NoteForm
                    grantId={grantId}
                    note={selectedNote}
                    afterSave={async () => {
                        showHideNoteModal(false)
                        afterSave && afterSave()
                    }}
                />
                }
			</Modal>

			<Modal
				{...deleteModal}
				modalTitle='Confirm'
				onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideDeleteModal(false)}>Cancel</button>
                        <button className='btn btn-warning' onClick={async () => {
							if (selectedRow) {
								await appActions.NotesApi.apiNotesIdDelete(parseInt(selectedRow.id))
								afterSave && afterSave()
							} else {
								alert('There was an error deleting this note. No note ID specified.')
							}
							showHideDeleteModal(false)
                            afterSave && afterSave()
						}}>Delete</button>
					</>
				}
			>
				Are you sure you want to delete this note?

				This cannot be reversed!
			</Modal>
		</>
	)
}