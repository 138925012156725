import React, { } from 'react'
import IMask from 'imask'
import { FormikMaskedInput } from './formik-masked-input'
import { IField } from './types'

const numberMask: IMask.AnyMaskedOptions = {
    mask: Number,
    normalizeZeros: true,
    radix: '.',
    thousandsSeparator: ',',
}

interface IFormikNumberFieldProps {
    field: IField
}

export const FormikNumberField = (props: IFormikNumberFieldProps) => <FormikMaskedInput {...props} mask={numberMask} />