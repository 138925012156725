import { IGridRowAction, IGridListItem, IGridRowGroupAction, IGridState } from '../stores/grid-definitions'

export class GridRowAction {
    public static getDisabledState = (action: IGridRowAction, row: IGridListItem): boolean => {
        let disabled = false

        if (action.disabled) {
            if (typeof action.disabled === 'function') {
                disabled = action.disabled(row)
            } else {
                disabled = true
            }
        }

        return disabled
    }

    public static getRowGroupDisabledState = (action: IGridRowGroupAction, rowGroupId: string, gridState: IGridState): boolean => {
        let disabled = false

        if (action.disabled) {
            if (typeof action.disabled === 'function') {
                disabled = action.disabled(rowGroupId, gridState)
            } else {
                disabled = true
            }
        }

        return disabled
    }
}