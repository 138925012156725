import { Form, Formik } from "formik"
import { IEventContentFormState } from "./event-content-form"
import * as yup from 'yup'
import { SelectField } from "./forms"
import { AccountLevel } from "../constants"
import { sortListByProperty } from "../services/helpers"

interface IEventContentStep1GroupCoachingProps {
    parentState: IEventContentFormState
    setParentState: any
}

export const EventContentStep1GroupCoaching = ({ parentState, setParentState } : IEventContentStep1GroupCoachingProps) => {
    return (
        <div>
            <h5>Step 1 - Choose a Topic</h5>
            <div style={{paddingBottom: '15px'}}>
                Start by choosing the topic for this event. Topics can vary in what accounts are allowed and all topics, except “custom”, include a pre-populated description, as seen below.
            </div>

            <Formik
                initialValues={{
                    topicTypeId: '',
                    topicId: '',
                }}
                validationSchema={yup.object({
                    topicTypeId: yup.string().required('Required'),
                    topicId: yup.string().required('Required'),
                })}
                onSubmit={(step1Values) => {
                    //const selectedTopic = parentState.topics.find(o => o.id.toString() === step1Values.topicId)
                    //if (selectedTopic) setParentState({ selectedTopic, ...values })
                    //const selectedTopicType = parentState.topicTypes.find(o => o.id.toString() === step1Values.topicTypeId)
                    //if (selectedTopicType) setSelectedTopicType(selectedTopicType.name)

                    // @ts-ignore
                    //setParentState(_state => ({ ..._state, step: 'step2', step1Values: {..._state.step1Values, selectedTopic: selectedTopic, selectedTopicType: selectedTopicType} }))
                    setParentState(_state => ({ ..._state, step: 'step2', step1Values: {..._state.step1Values, eventTopicId: step1Values.topicId, topicTypeId: step1Values.topicId} }))
                }}
            >
                {({ values: { topicTypeId, topicId, } }) => {
                    const selectedTopicType = parentState.groupCoachingTopicTypes.find(o => o.id.toString() === topicTypeId)
                    const selectedTopic = parentState.groupCoachingTopics.find(o => o.id.toString() === topicId)

                    return (
                        <Form>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }} className='mr-2'>
                                    <SelectField
                                        fieldProps={{ name: 'topicTypeId', label: 'Type' }}
                                        options={parentState.groupCoachingTopicTypes.map(o => ({ value: o.id.toString(), label: o.name }))}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <SelectField
                                        disabled={!Boolean(selectedTopicType)}
                                        fieldProps={{ name: 'topicId', label: 'Topic' }}
                                        options={sortListByProperty(parentState.groupCoachingTopics.filter(o => o.isActive && o.groupCoachingTopicTypeId === selectedTopicType?.id), 'title').map(o => ({ value: o.id.toString(), label: o.title }))}
                                    />
                                </div>
                            </div>

                            {selectedTopic &&
                                <div>
                                    <div className='mt-2' style={{ display: 'flex' }}>
                                        <div className='mr-4'>
                                            <b>Allowed Account Level(s)</b>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {selectedTopic.allowedAcctLevelsIds?.map(o => <div key={o}>{AccountLevel[o]}</div>)}
                                            </div>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <b>Short Description</b>
                                            <div dangerouslySetInnerHTML={{ __html: selectedTopic.shortDescription || '' }} />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div style={{paddingTop: '15px'}}>
                                Click continue to edit the description and add details for this event.
                            </div>
                            <button className='btn btn-primary mt-4' type='submit'>Continue</button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}