import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from "react-oidc-context";
//import { UserManager, UserManagerSettings, User } from "oidc-client-ts";
import { oidcConfig } from './services/auth-config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss'                // Comment this line to show Maintenance Page
import 'react-day-picker/lib/style.css';    // Comment this line to show Maintenance Page

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Router } from '@reach/router';

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

ReactDOM.render(
    <AuthProvider {...oidcConfig}>
        <App />
    </AuthProvider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
