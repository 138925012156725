import { Form, Formik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IEventRegistrantDocument, IGroupCoachingRegistrantDocument, IMinistrySearchResultModelDocument, ISearchRequestFilterDocument } from "../open-api"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IFilter } from "../stores/api-actions"
import { FormikEffect } from "./formik-effect"
import { CheckboxField, CommunitySelectField, EmailField, ISelectFieldOption, SelectField, TextField } from "./forms"
import { Loading, LoadingOverlay, LoadingPropsSizeEnum } from "./loading"
import * as Yup from 'yup'
import { AccountLevel } from "../constants"

const filter: IFilter = {
    id: 'branchIdFilter',
    enabled: true,
    value: [],
    operator: 'in',
    property: 'branchAbbr'
}

interface IFormValues {
    contactType: 'ministry' | 'outside'
    branchId: string
    ministryId: string
    ministryContactIds: string[]
    firstName: string
    lastName: string
    orgName: string
    title: string
    email: string
    phone: string
    createProspect: boolean
}

export interface IAddRegistrantsForm {
    afterSave: () => void
    initialBranchId?: string
    allowedAccountLevels?: AccountLevel[]
    onSave: (args: IFormValues) => Promise<IGroupCoachingRegistrantDocument[] | IEventRegistrantDocument[]>
    sendConfirmationEmail: (args: { seminarAttendeeId: number, attendeeGuid: string | null, email: string | null, ministryContactId: number | null }[]) => Promise<void>
    disabled?: boolean,
    isSeries?: boolean
}

export const AddRegistrantsForm = ({ afterSave, initialBranchId, allowedAccountLevels, onSave, sendConfirmationEmail, disabled, isSeries }: IAddRegistrantsForm) => {

    const appState = useContext(AppStateContext)!
    const { MinistriesApi, fetchMiUsers, MinistryContactsApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [contactsLoading, setContactsLoading] = useState(false)
    const [contactOptions, setContactOptions] = useState<ISelectFieldOption[]>([])

    const [justAddedRegistrants, setJustAddedRegistrants] = useState<IGroupCoachingRegistrantDocument[] | IEventRegistrantDocument[] | undefined>(undefined)

    const [ministriesLoading, setMinistriesLoading] = useState(false)
    const [ministries, setMinistries] = useState<IMinistrySearchResultModelDocument[] | null>()
    const [ministryOptions, setMinistryOptions] = useState<ISelectFieldOption[]>([])
    const fetchMinistryOptionsForBranchId = async (branchId: string) => {
        const branch = appState.activeBranches.find(o => o.branchId.toString() === branchId)
        if (!branch?.branchAbbr) return

        setMinistriesLoading(true)
        filter.value = [branch.branchAbbr]
        const branchFilter = JSON.stringify([filter]) as unknown as ISearchRequestFilterDocument[]
        const ministryQuery = await makeHttpRequestWithUi({
            request: MinistriesApi.apiMinistriesGet(0, 100000, undefined, branchFilter),
            toastErrorMessage: 'There was an error retrieving the ministries for this community.',
            disableSuccessToast: true,
            disableLoading: true,
        })
        setMinistries(ministryQuery.data.data)
        setMinistryOptions(ministryQuery.data.data?.map(o => ({ label: `${o.ministryName} ${o.levelId ? `(${AccountLevel[o.levelId]})` : ''}`, value: `${o.ministryId}` })) || [])
        setMinistriesLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchMiUsers(true)

        if (initialBranchId) fetchMinistryOptionsForBranchId(initialBranchId)

        return () => {
            setJustAddedRegistrants(undefined)
        }
    }, [])

    const initialValues = {
        contactType: '' as 'ministry' | 'outside',
        branchId: initialBranchId || '',
        ministryId: '',
        ministryContactIds: [] as string[],
        firstName: '',
        lastName: '',
        orgName: '',
        title: '',
        email: '',
        phone: '',
        createProspect: true
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object({
                branchId: Yup.string()
                    .test(
                        'branchId',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'ministry') return !!value
                            return true
                        }
                    ),
                ministryId: Yup.string()
                    .test(
                        'ministryId',
                        `This ministry's account level is not included in the group learning event's allowed account levels (${allowedAccountLevels?.map(o => AccountLevel[o]).join(', ')}).`,
                        function (value) {
                            if (this.parent.contactType === 'ministry' && allowedAccountLevels) {
                                const ministry = ministries?.find(o => o.ministryId.toString() === value)
                                return Boolean(ministry?.levelId && allowedAccountLevels?.includes(ministry.levelId))
                            }
                            return true
                        }
                    ),
                ministryContactIds: Yup.array().of(Yup.string()).nullable()
                    .test(
                        'ministryContactIds',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'ministry') return Boolean(value && Array.isArray(value) && value.length)
                            return true
                        }
                    ),
                firstName: Yup.string()
                    .test(
                        'firstName',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'outside') return !!value
                            return true
                        }
                    ),
                lastName: Yup.string()
                    .test(
                        'lastName',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'outside') return !!value
                            return true
                        }
                    ),
                orgName: Yup.string()
                    .test(
                        'orgName',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'outside') return !!value
                            return true
                        }
                    ),
                phone: Yup.string()
                    .test(
                        'phone',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'outside') return !!value
                            return true
                        }
                    ),
                email: Yup.string()
                    .test(
                        'email',
                        'Required',
                        function (value) {
                            if (this.parent.contactType === 'outside') return !!value
                            return true
                        }
                    )
                    .email('Must be a valid email address.'),
            })}
            onSubmit={async (values, actions) => {
                const results = await onSave(values)
                setJustAddedRegistrants(results)
                actions.resetForm()
                afterSave()
            }}
            children={formikProps => {
                return (
                    <>
                        {!appState.users && <LoadingOverlay position='absolute' />}

                        <FormikEffect
                            formikProps={formikProps}
                            onChange={async (prevValues, nextValues) => {

                                if (nextValues.contactType === 'ministry') {
                                    if (!nextValues.branchId) setMinistryOptions([])
                                    if (nextValues.branchId && nextValues.branchId !== prevValues.branchId || prevValues.contactType !== 'ministry') {
                                        fetchMinistryOptionsForBranchId(nextValues.branchId)
                                    }

                                    if (nextValues.ministryId !== prevValues.ministryId || nextValues.branchId !== prevValues.branchId) setContactOptions([])
                                    if (nextValues.ministryId && nextValues.ministryId !== prevValues.ministryId) {
                                        setContactsLoading(true)
                                        const contactsQuery = await makeHttpRequestWithUi({
                                            request: MinistryContactsApi.apiMinistriesIdContactsGet(parseInt(nextValues.ministryId)),
                                            disableSuccessToast: true,
                                            toastErrorMessage: 'There was an error retrieving the contacts for this ministry.',
                                            disableLoading: true,
                                        })
                                        setContactOptions(contactsQuery.data.map(o => ({ label: `${o.firstName} ${o.lastName}`, value: `${o.ministryContactId}` })))
                                        setContactsLoading(false)
                                    }
                                }
                            }}
                        />
                        {justAddedRegistrants?.length ?
                            <>
                                <p>Would you like to send a registration confirmation email to these registrants?</p>
                                {justAddedRegistrants?.map(o => <p key={o.seminarAttendeeId}>{o.email}</p>)}

                                <p>(You can send individual notifications later.)</p>

                                <div style={{ display: 'flex' }}>
                                    <button className='btn btn-secondary mr-2' onClick={() => setJustAddedRegistrants(undefined)}>Don't Send</button>
                                    <button
                                        className='btn btn-primary'
                                        onClick={async () => {
                                            if (!justAddedRegistrants) return
                                            await sendConfirmationEmail(justAddedRegistrants.map(o => ({ seminarAttendeeId: o.seminarAttendeeId, attendeeGuid: o.attendeeGuid, email: o.email?.length ? o.email : null, ministryContactId: o.ministryContactId })))
                                            setJustAddedRegistrants(undefined)
                                            afterSave()
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </>
                            :
                            <Form>
                                <div>
                                    <p>
                                        Manually register contacts. After you add one or more contacts you will be asked if
                                        you would like to send them a registration confirmation email.
                                    </p>
                                    {isSeries && 
                                    <div className="alert alert-warning">
                                        <b>NOTE:</b> This is a series. Adding a registrant to this event will automatically add them to all events
                                        in the series.
                                    </div>
                                    }
                                </div>

                                {formikProps.values.contactType !== 'ministry' && formikProps.values.contactType !== 'outside' &&
                                    <div style={{ display: 'flex', justifyContent: 'center' }} className='mb-4'>
                                        <button type='button' className='btn btn-secondary mr-1' onClick={() => formikProps.setFieldValue('contactType', 'ministry', false)}>Add Ministry Contacts</button>
                                        <button type='button' className='btn btn-secondary ml-1' onClick={() => formikProps.setFieldValue('contactType', 'outside', false)}>Add Outside Contacts</button>
                                    </div>
                                }

                                {formikProps.values.contactType === 'ministry' && (
                                    <>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <CommunitySelectField
                                                    fieldProps={{ name: 'branchId', label: 'Community' }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                {ministriesLoading ?
                                                    <Loading size={LoadingPropsSizeEnum.small} />
                                                    :
                                                    <SelectField fieldProps={{ name: 'ministryId', label: 'Ministry' }} options={ministryOptions} disabled={ministryOptions.length === 0} />
                                                }
                                            </div>
                                        </div>
                                        <div style={{ minHeight: 85 }}>
                                            {contactsLoading ?
                                                <Loading size={LoadingPropsSizeEnum.small} />
                                                :
                                                <SelectField
                                                    fieldProps={{ name: 'ministryContactIds', label: 'Contacts' }}
                                                    options={contactOptions}
                                                    disabled={contactOptions.length === 0}
                                                    multiple
                                                />
                                            }
                                        </div>
                                    </>
                                )}

                                {formikProps.values.contactType === 'outside' && (
                                    <>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <TextField fieldProps={{ name: 'firstName', label: 'First Name' }} />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <TextField fieldProps={{ name: 'lastName', label: 'Last Name' }} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <TextField fieldProps={{ name: 'title', label: 'Title' }} />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <TextField fieldProps={{ name: 'orgName', label: 'Organization Name' }} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: 1, marginRight: 10 }}>
                                                <EmailField fieldProps={{ name: 'email', label: 'Email' }} />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <TextField fieldProps={{ name: 'phone', label: 'Phone' }} />
                                            </div>
                                        </div>
                                        <CheckboxField fieldProps={{ name: 'createProspect', label: 'Create a prospect?' }} />
                                    </>
                                )}

                                {(formikProps.values.contactType === 'ministry' || formikProps.values.contactType === 'outside') &&
                                    <div style={{ display: 'flex' }}>
                                        <button disabled={disabled} style={{ width: 100, marginRight: 10 }} type='button' className='btn btn-secondary' onClick={() => formikProps.resetForm()}>Clear</button>
                                        <button type='submit' style={{ width: 100 }} className='btn btn-primary' disabled={disabled}>Add</button>
                                    </div>
                                }
                            </Form>
                        }
                    </>
                )
            }}
        />
    )
}