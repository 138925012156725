import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IDefaultProps } from "./component-definitions"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IPremierResponseModelDocument } from "../open-api"
import Helmet from "react-helmet"
import { LoadingOverlay } from "./loading"
import { FileMedicalIcon } from "../assets"

interface IGuideStarPremierProps extends IDefaultProps {
    ein?: string
}

export const GuideStarPremier = ({ein} : IGuideStarPremierProps) => {
    const { GuideStarApi } = useContext(AppActionContext)!
    
    const [premierData, setPremierData] = useState<IPremierResponseModelDocument>()
    
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    const fetchPremier = useCallback(async (_ein: string) => {
        const query = await makeHttpRequestWithUi({
            request: GuideStarApi.apiGuideStarPremierEinGet(_ein),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the GuideStar data.'
        })

        setPremierData(query.data)
    }, [])

    useEffect(() => {
        if (ein) fetchPremier(ein)
    }, [])

    if (!premierData) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>{premierData.data.summary.organization_name} - GuideStar Premier Data</title>
            </Helmet>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <FileMedicalIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>{premierData.data.summary.organization_name} - GuideStar Premier Data</h3>
                </div>
                <div className='d-flex'>
                    <p>Currently, we are only displaying 990 data on this page. The bulk of the ministry's data is displayed in the search results grid. To request more data, reach out to Tod.</p>
                </div>
                <h3>990 Data</h3>
                <div className='d-flex'>
                    { premierData.data.financials.financial_documents ? <div>
                        <ul>
                            {premierData.data.financials.financial_documents.map(fd => fd.form990_url && <li>{`${fd.form990_name} - ${fd.form990_year}`}&nbsp; <a href={fd.form990_url ?? '#'}>Download</a></li>)}
                        </ul>
                    </div>
                    :
                    <div>No 990 data is available.</div>
                    }
                </div>
            </div>
        </>
    )
}