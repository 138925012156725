import React from 'react'

interface IMainNavItemExpandableCell {
    active: boolean
    icon: JSX.Element
    title: string | JSX.Element
    onClick: () => void
}

export const MainNavItemExpandableCell = (props: IMainNavItemExpandableCell) => {
    const {active, icon, title, onClick } = props

    return (
        <div
            className={`nav-row nav-row-link text-body pl-3 py-1 font-size-small ${active ? 'active' : ''}`}
            onClick={onClick}
        >
            <div className='mr-1' style={{ minWidth: '16px' }} data-toggle={'tooltip'} title={typeof title === 'string' ? title : ''}> {icon} </div> {title}
        </div>
    )
}