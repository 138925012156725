import { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { HouseIcon } from "../assets"
import { IDefaultProps } from "./component-definitions"
import { IMiBranchModelDocument } from '../open-api'
import { CardCurrentMinistryCount } from "./card-current-ministry-count"
import { CardAlerts } from './card-alerts'
import { CardCalendar } from './card-calendar'
import { AppStateContext } from "../app-store-provider"
import { CardReportDashboard } from "./card-report-dashboard"

interface IHomeProps extends IDefaultProps { }

export const Home = (props: IHomeProps) => {

    const { } = props
    const { globalCommunityContext } = useContext(AppStateContext)!
    const [activeCommunity, setActiveCommunity] = useState<IMiBranchModelDocument>()

    useEffect(() => {
        if (globalCommunityContext) {
            setActiveCommunity(globalCommunityContext)
        } else {
            setActiveCommunity(undefined)
        }
    }, [globalCommunityContext])

    return (
        <>
            <Helmet>
                <title>GrowthTrack - Dashboard</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ minHeight: 0, overflow: 'hidden' }}>
                <div className='m-2 d-flex align-items-center'>
                    <HouseIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>GrowthTrack Dashboard</h3>
                </div>
                <div className="row pl-2" style={{ overflow: 'hidden' }}>
                    <div className="col-sm-6">
                        <CardReportDashboard activeCommunity={activeCommunity} style={{marginBottom: '15px'}} />
                    </div>
                    <div className="col-sm-6">
                        <div className="pr-3">
                        <CardCurrentMinistryCount activeCommunity={activeCommunity} style={{marginBottom: '15px'}} />
                        <CardAlerts activeCommunity={activeCommunity} style={{marginBottom: '15px'}} />
                        <CardCalendar activeCommunity={activeCommunity} style={{marginBottom: '15px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}