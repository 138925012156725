import { useCallback, useContext, useEffect, useState } from "react"
import { IGrantRequestSummaryDocument, IGrantTypeModelDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { CurrencyField } from "./forms"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { FormikTextField } from "./forms/formik-text-field"
import { GRANT_JOB_TITLE, GRANT_TYPE } from "../constants"
import { FormikSelectField } from "./forms/formik-select-field"
import dayjs from "dayjs"
import { formatCurrency } from "../services/helpers"

interface IGrantRequestFormProps extends IDefaultProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    grantTypes: IGrantTypeModelDocument[]
    afterSave?: () => Promise<void>
}

export const GrantRequestFormSummaryDetails = (props: IGrantRequestFormProps) => {
    const { grantRequestToEdit, afterSave, grantTypes } = props
    const { GrantRequestApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const isLegacyGrant = grantRequestToEdit.grantGiftId < 1

    const initialValues = {
        grantDesc: grantRequestToEdit.grantDesc,
        amountRequested: grantRequestToEdit.amountRequested || '0',
        trainingGoals: grantRequestToEdit.trainingGoals,
        grantPlan: grantRequestToEdit.grantPlan,
        grantUse: grantRequestToEdit.grantUse,
        feedbackRequired: grantRequestToEdit.feedbackRequired ? "Yes" : "No",
        howFundsRaised: grantRequestToEdit.howFundsRaisedParsed,
        bNewStaff: grantRequestToEdit.bNewStaff ? "Yes" : "No",
        jobTitleId: grantRequestToEdit.jobTitleId || '0',
        jobTitleOther: grantRequestToEdit.jobTitleOther,
        bNewStaffFullTime: grantRequestToEdit.bNewStaffFullTime ? "Yes" : "No",
        newStaffHoursPerWeek: grantRequestToEdit.newStaffHoursPerWeek,
        newStaffSalary: grantRequestToEdit.newStaffSalary,
        newStaffAmountRaise: grantRequestToEdit.newStaffAmountRaise,
        majorGiftDefinitionId: grantRequestToEdit.majorGiftDefinitionId || '0',
        majorGiftDefinitionOther: grantRequestToEdit.majorGiftDefinitionOther,
        estimatedEventDate: dayjs(grantRequestToEdit.estimatedEventDate?.toString()).format('MM/DD/YYYY'),
    }

    const validationSchema = Yup.object({
    })

    const [jobTitles, setJobTitles] = useState<{ id: number, name: string }[]>();
    const fetchJobTitles = useCallback(async () => {
        const { data } = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestJobTitlesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching job titles.'
        })

        setJobTitles(data as { id: number, name: string }[])
    }, [])

    useEffect(() => {
        fetchJobTitles()
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                if (afterSave) afterSave()
            }}
        >
            {formikProps => {
                return (
                    <Form className='d-flex flex-column mx-2'>
                        { grantRequestToEdit.bOldRequest ? 
                          <div className="d-flex flex-column mt-2">
                            <p>This is an "Old Request". That means this grant is using a format that had been retired before MI Admin (1.0) was retired.</p>
                            <FormikTextAreaField field={{ name: 'grantDesc', label: 'Please give a short description for this Grant', disabled: true }} />
                            <CurrencyField fieldProps={{ name: 'amountRequested', label: 'Amount Requested' }} disabled={true} />
                            <FormikTextAreaField field={{ name: 'trainingGoals', label: 'Please describe goals you wish to achieve with training', disabled: true }} />
                            <FormikTextAreaField field={{ name: 'grantPlan', label: 'Please describe what you plan to do to raise matching funds. Be as specific as possible.', disabled: true }} />
                            <FormikTextAreaField field={{ name: 'grantUse', label: 'Specifically, how will the Mission Increase funds be used?', disabled: true }} />
                          </div>
                          :
                          <div className="d-flex flex-column mt-2">
                            <div className="mb-2">
                                <label>Grant Type:</label> {grantRequestToEdit.grantTypeName}
                            </div>
                            <div className="mb-2">
                                <label>Request Created:</label> {dayjs(grantRequestToEdit.grantReqDate?.toString()).format('MM/DD/YYYY')}
                            </div>
                            { grantRequestToEdit.dateMinistrySubmitted &&
                                <div className="mb-2">
                                    <label>Request Submitted:</label> {dayjs(grantRequestToEdit.dateMinistrySubmitted?.toString()).format('MM/DD/YYYY')}
                                </div> }
                            { grantRequestToEdit.dateAreaDirectorAccepted && 
                                <div className="mb-2">
                                    <label>Area Director Accepted:</label> {dayjs(grantRequestToEdit.dateAreaDirectorAccepted.toString()).format('MM/DD/YYYY')}
                                </div> }
                            { grantRequestToEdit.dateFlaggedAsBoardApproved && 
                                <div className="mb-2">
                                    <label>Board Approved/Denied:</label> {dayjs(grantRequestToEdit.dateFlaggedAsBoardApproved.toString()).format('MM/DD/YYYY')}
                                </div> }
                            { grantRequestToEdit.grantAmount > 0 && 
                                <div className="mb-2">
                                    <label>Board Approved Amount:</label> {formatCurrency(grantRequestToEdit.grantAmount)}
                                </div> }
                            <div className="mb-2">
                                <label>Contact Person:&nbsp;</label>
                                { grantRequestToEdit.contactEmail ? 
                                    <a href={`mailto:${grantRequestToEdit.contactEmail}`}>{grantRequestToEdit.contactName}</a> : grantRequestToEdit.contactName }
                                &nbsp;
                                { grantRequestToEdit.contactPhone && 
                                `${grantRequestToEdit.contactPhone}` }
                            </div>
                            {grantRequestToEdit.grantTypeId === GRANT_TYPE.Managed &&  <FormikSelectField
                                field={{ name: 'feedbackRequired', label: 'Matching Managed Grant', labelTooltip: 'Whether or not the ministry will raise funds to match this Charity Gift.', disabled: true}}
                                options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' } ]}
                            />}
                            <FormikTextAreaField field={{ name: 'grantDesc', label: 'Please give a short description for this Grant', disabled: true }} />
                            <CurrencyField fieldProps={{ name: 'amountRequested', label: 'Amount Requested' }} disabled={true} />
                            {/* TB - This is here for 1.0 backwards compatability. We used to allow the ministry to select one or more grant types that they were planning to use. Now we select a single grant type when we create the grant gift. */}
                            {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && isLegacyGrant && <FormikSelectField 
                                field={{ name: 'howFundsRaised', label: 'How will you raise funds to match this grant?', disabled: true }}
                                multiple={true}
                                options={grantTypes.map(x => ({ label: x.name ?? '', value: x.grantTypeId.toString() }))} /> }
                            {grantRequestToEdit.grantTypeId === GRANT_TYPE["Major Gifts"] && <FormikSelectField
                                field={{ name: 'majorGiftDefinitionId', label: 'What is your ministry\'s definition of a Major Gift?', disabled: true }}
                                options={[
                                    { label: '$500 or more', value: '1' }, 
                                    { label: '$1,000 or more', value: '2' },
                                    { label: '$2,500 or more', value: '3' },
                                    { label: '$5,000 or more', value: '4' }, 
                                    { label: 'Other', value: '5' } 
                                ]}
                            />}
                            {(formikProps.values.howFundsRaised?.find(x => (x === GRANT_TYPE["Major Gifts"].toString()))) &&
                                (formikProps.values.majorGiftDefinitionId.toString() === '5') && 
                                <FormikTextField field={{ name: 'majorGiftDefinitionOther', label: 'Other Job Title', disabled: true }} /> }
                            {(formikProps.values.howFundsRaised?.find(x => (x === GRANT_TYPE["Large Scale Event"].toString()))) &&
                                <FormikTextField field={{ name: 'estimatedEventDate', label: 'What date will you hold the fundraising event? If you don\'t have an exact date, please give us your best estimate.', disabled: true }} /> }
                            {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <FormikTextAreaField field={{ name: 'grantPlan', label: 'Matching Plan: Please describe how you plan to raise matching funds.', disabled: true }} /> }
                            {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <FormikSelectField
                                field={{ name: 'bNewStaff', label: 'Will this matching grant be used to hire a new staff person?', disabled: true }}
                                options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' } ]}
                            />}

                            {grantRequestToEdit.bNewStaff && 
                            <div>
                                {/* TB - In 1.0, the user could select a job title from a list which included "Other". In 2.0, we default the jobTitleId to "Other" and simply ask the user to type out the job title in the jobTitleOther field. */}
                                {isLegacyGrant && jobTitles && <FormikSelectField
                                    field={{ name: 'jobTitleId', label: 'What is the new staff position?', disabled: true }}
                                    options={jobTitles.map(x => ({ label: x.name, value: x.id.toString() }))}
                                />}
                                {(parseInt(formikProps.values.jobTitleId.toString()) === GRANT_JOB_TITLE.Other) && <FormikTextField field={{ name: 'jobTitleOther', label: 'Other Job Title', disabled: true }} /> }
                                <FormikSelectField
                                    field={{ name: 'bNewStaffFullTime', label: 'Is this a full-time position?', disabled: true }}
                                    options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' } ]}
                                />
                                <FormikTextField field={{ name: 'newStaffHoursPerWeek', label: 'If part-time, how many hours per week?', disabled: true }} />
                                <CurrencyField fieldProps={{ name: 'newStaffSalary', label: 'What is the new position\'s annual salary? (do not include benefits)' }} disabled={true} />
                                <CurrencyField fieldProps={{ name: 'newStaffAmountRaise', label: 'How much is the new position budgeted to raise in the next 12 months?' }} disabled={true} />
                            </div>
                            }
                            <FormikTextAreaField field={{ name: 'grantUse', label: 'Fund Use Plan: Please describe how the Mission Increase grant and matching funds will be used', disabled: true }} />
                          </div>
                        }
                        {/* <CommunitySelectField
                            fieldProps={{ name: 'branchId', label: 'Community' }}
                            disabled={!!grantToEdit}
                        />
                        <FormikTextAreaField field={{ name: 'description', label: 'Description' }} />
                        { !!grantToEdit &&
                            <div className='mb-3'>
                                <CheckboxField fieldProps={{ name: 'isActive', label: 'Active', labeltooltip: "When a grant is inactive, ministries can no longer apply for it." }} />
                            </div>
                        }
                        
                        <button style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button> */}
                    </Form>
                )
            }}
        </Formik>
    )
}