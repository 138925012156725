import { IGrantGiftMinistryContactSummaryDocument, IGrantGiftModelDocument, IGrantTypeModelDocument } from "../open-api"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useContext, useEffect, useState } from "react"
import { CheckboxField, CurrencyField } from "./forms"
import { FormikSelectField } from "./forms/formik-select-field"
import { FormikTextField } from "./forms/formik-text-field"
import { FormikCommunitySelectField } from "./forms/formik-community-select-field"
import { FormikMinistrySelectField } from "./forms/formik-ministry-select-field"
import { FormikEffect } from "./formik-effect"
import { FormikMinistryContactSelectField } from "./forms/formik-ministry-contact-select-field"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { GRANT_TYPE } from "../constants"
// import { GRANT_TYPE } from "../constants"

interface IGrantFormMinistryFormProps {
    ministryToEdit?: IGrantGiftMinistryContactSummaryDocument
    grantTypes: IGrantTypeModelDocument[]
    grantToEdit: IGrantGiftModelDocument
    afterSave: () => Promise<void>
}

export const GrantFormMinistryForm = (props: IGrantFormMinistryFormProps) => {
    const { ministryToEdit, afterSave, grantToEdit, grantTypes } = props
    const { GrantGiftMinistryContactApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const [selectedBranchId, setSelectedBranchId] = useState(0)
    const [selectedMinistryId, setSelectedMinistryId] = useState(0)

    const initialValues = {
        branchId: grantToEdit?.branchId ?? 0,
        grantGiftId: ministryToEdit?.grantGiftId ?? grantToEdit.grantGiftId,
        ministryId: ministryToEdit?.ministryId ?? 0,
        grantTypeId: ministryToEdit?.grantTypeId ?? 0,
        ministryContactId: ministryToEdit?.ministryContactId ?? 0,
        amount: ministryToEdit?.amount ?? 0,
        sendInvite: true,
        grantUse: '',
        grantDesc: '',
        feedbackRequired: null
    }

    const validationSchema = Yup.object({
        grantTypeId: Yup.number().min(1, 'Required field.').required('Required'),
        ministryId: Yup.number().min(1, 'Required field.').required('Required'),
        ministryContactId: Yup.number().min(1, 'Required field.').required('Required'),
        amount: Yup.number().min(1, 'Required field.').required('Required'),
        grantUse: Yup.string().test(
            'grantUse',
            'Required',
            function (value) {
                console.log('this.parent.grantTypeId == GRANT_TYPE.Managed', this.parent.grantTypeId == GRANT_TYPE.Managed)
                if (this.parent.grantTypeId == GRANT_TYPE.Managed) return !!value
                return true
            }
        ),
        grantDesc: Yup.string().test(
            'grantDesc',
            'Required',
            function (value) {
                if (this.parent.grantTypeId == GRANT_TYPE.Managed) return !!value
                return true
            }
        ),
        feedbackRequired: Yup.bool().nullable().test(
            'feedbackRequired',
            'Required',
            function (value) {
                if (this.parent.grantTypeId == GRANT_TYPE.Managed) return value !== null
                return true
            }
        ),
    })

    useEffect(() => {
        console.log('grantToEdit?.branchId', grantToEdit?.branchId)
        if (grantToEdit?.branchId) setSelectedBranchId(grantToEdit?.branchId);
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                if (ministryToEdit) {
                    // await makeHttpRequestWithUi({
                    //     request: MinistryGrantFundingApi.apiMinistryGrantFundingFundingIdPut( fundingSourceToEdit.fundingId, {
                    //         fundingId: fundingSourceToEdit.fundingId,
                    //         grantId: 0,
                    //         grantGiftId: values.grantGiftId,
                    //         amount: values.amount,
                    //         donorId: values.donorId,
                    //         bPrimaryFunder: values.bPrimaryFunder,
                    //         bAnonymous: values.bAnonymous
                    //     }),
                    //     toastSuccessMessage: 'Successfully updated funding source.',
                    //     toastErrorMessage: 'There was an error updating funding source.'
                    // })
                } else {
                    console.log('values', values)
                    const { data } = await makeHttpRequestWithUi({
                        request: GrantGiftMinistryContactApi.apiGrantGiftGrantGiftIdContactsPost(grantToEdit.grantGiftId, {
                            grantGiftId: grantToEdit.grantGiftId,
                            grantTypeId: values.grantTypeId,
                            ministryContactId: values.ministryContactId,
                            ministryId: values.ministryId,
                            amount: values.amount,
                            grantDesc: values.grantDesc,
                            grantUse: values.grantUse,
                            feedbackRequired: values.grantTypeId == GRANT_TYPE.Managed ? values.feedbackRequired === '1' : null
                        }),
                        toastSuccessMessage: 'Successfully created funding source.',
                        toastErrorMessage: 'There was an error creating funding source.'
                    })

                    if (data && values.sendInvite) {
                        await makeHttpRequestWithUi({
                            request: GrantGiftMinistryContactApi.apiGrantGiftMinistryContactGrantGiftMinistryContactIdSendinvitePost(data.grantGiftMinistryContactId),
                            toastSuccessMessage: 'Successfully send email notification.',
                            toastErrorMessage: 'Encountered an error sending email notification.',
                        })
                    }
                }

                if (afterSave) afterSave()
            }}
        >
            {formikProps => {
                return (
                    <>
                        <p>
                            Use this form to give a ministry permission to submit a grant request for this grant. <br />
                            Select one ministry contact to be the primary contact for the ministry's grant request.
                        </p>
                        <FormikEffect
                            formikProps={formikProps}
                            onChange={async (prevValues, nextValues) => {
                                if (prevValues.branchId != nextValues.branchId) setSelectedBranchId(nextValues.branchId)

                                if (prevValues.ministryId != nextValues.ministryId) setSelectedMinistryId(nextValues.ministryId)
                            }}
                        />
                        <Form style={{ padding: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 10 }}>
                                    <FormikCommunitySelectField 
                                        field={{ name: 'branchId', label: 'Community', 
                                            //disabled: Boolean(eventToEdit?.status === GroupCoachingEventStatus.canceled) 
                                    }} />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <FormikMinistrySelectField field={{ name: 'ministryId', label: 'Ministry' }} branchId={selectedBranchId} />
                                </div>
                            </div>
                            <div style={{ minHeight: 85 }}>
                                <FormikMinistryContactSelectField field={{ name: 'ministryContactId', label: 'Primary Grant Contact' }} ministryId={selectedMinistryId} />
                            </div>
                            <div style={{ minHeight: 85 }}>
                                <FormikSelectField 
                                    field={{ name: 'grantTypeId', label: 'Grant Type' }}
                                    options={grantTypes.filter(x => x.isActive).map(x => ({ value: x.grantTypeId.toString(), label: x.name ?? '' }))} />
                            </div>
                            <div style={{ minHeight: 85 }}>
                                <CurrencyField fieldProps={{ name: 'amount', label: 'Amount', labeltooltip: 'The amount the Grant Request will be for.' }} />
                            </div>
                            {formikProps.values.grantTypeId == GRANT_TYPE.Managed && <>
                                <div className="mb-3">For 'Managed' grant types, the grant request is submitted by the AD rather than by the ministry leader. Please provide the additional information below to proceed.</div>
                                <FormikTextField field={{ name: 'grantDesc', label: 'Short Description' }} />
                                <FormikTextAreaField field={{ name: 'grantUse', label: 'Fund Use Plan' }} />
                                <FormikSelectField
                                field={{ name: 'feedbackRequired', label: 'Matching Managed Grant', 
                                    labelTooltip: 'Will the ministry raise funds to match this Managed Gift. They will be required to fill out a Grant Feedback Report.',
                                    placeholder: 'Select one'}}
                                options={[{ label: 'Yes', value: '1' }, { label: 'No', value: '0' } ]}
                            />
                            </> }
                            { formikProps.values.grantTypeId != GRANT_TYPE.Managed && 
                                <CheckboxField fieldProps={{ name: 'sendInvite', label: 'Notify the Primary Grant Contact immediately via automated email that they are invited to apply for this grant.' }} /> }
                            <button type="submit" style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}