import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IMiBranchGrantGoalsDocument, IMiBranchModelDocument } from '../open-api'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { CommunitiesGrantTargetModel } from '../models/community-grant-target'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from './grid-cell-displays'
import { Loading } from './loading'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { SquareDeleteIcon } from './partials'
import { Grid } from './grid'
import { Modal } from './modal'
import { CommunityGrantGoalForm } from './community-grant-goal-form'

interface ICommunityGrantingTargetsProps {
	community: IMiBranchModelDocument
}
interface ICommunityGrantingTargetsState {
	grantGoals?: IMiBranchGrantGoalsDocument[]
}
const defaultCommunityGrantingTargetsState: ICommunityGrantingTargetsState = {
}

export const CommunityGrantingTargets = (props: ICommunityGrantingTargetsProps) => {
	const { community } = props

	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [communityGrantingGoalsState, setCommunityGrantingGoalsState] = useState(defaultCommunityGrantingTargetsState)

	const dataSource: GridDataFetch<ICommunityGrantingTargetsState> = async (queryState, _state) => {
		const { grantGoals } = _state

		if (!grantGoals) return { count: 0, rows: [] }

		let rows = [...grantGoals].filter(o => !!o.bActive).map(CommunitiesGrantTargetModel.toGridListItem)

		return {
			count: rows.length,
			rows,
		}
	}

	const getGrantingGoals = async () => {
		const grantingGoalsQuery = await makeHttpRequestWithUi({
			request: appActions.MiBranchGrantGoalsApi.apiMiBranchesIdMiBranchGrantGoalsGet(community.branchId),
			toastErrorMessage: 'Failed to retrieve grant goals.',
			disableSuccessToast: true,
		})

		setCommunityGrantingGoalsState({
			grantGoals: grantingGoalsQuery.data
		})
	}

	useEffect(() => {
		getGrantingGoals()

		// eslint-disable-next-line
	}, [community])

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [grantGoalFormModal, showHidGrantGoalFormModal] = useModal()
	const [deleteGrantGoalModal, showHideDeleteGrantGoalModal] = useModal()

	const initialGridState: IGridState = {
		...defaultGridState,
		columns: [
			{
				property: 'goalYear',
				type: 'number',
				width: 200,
				allowFilters: false,
				title: 'Fiscal Year',
				render: DefaultGridCellDisplay,
				align: 'right'
			},
			{
				property: 'grid_actions',
				type: 'actions',
				width: 130,
				disableSort: true,
				title: 'Actions',
				render: GridActionCell,
				align: 'center'
			},
			{
				property: 'grantNumGoal',
				type: 'number',
				width: 200,
				allowFilters: false,
				title: 'Number of Grants',
				render: DefaultGridCellDisplay,
				align: 'right'
			},
			{
				property: 'grantTotalGoal',
				type: 'number',
				width: 200,
				allowFilters: false,
				title: 'Total of Grants',
				render: CurrencyDisplay,
				align: 'right'
			},
		],
		rowActions: {
			grantGoalDetail: {
				id: 'grantGoalDetail',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHidGrantGoalFormModal(true)
				},
				icon: <PencilIcon />,
				tooltipText: 'Grant Goal Detail'
			},
			deleteGoal: {
				id: 'deleteGoal',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideDeleteGrantGoalModal(true)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete Grant Goal'
			}
		},
		dataSource,
		usingLocalData: true,
		disabledPagination: true,
		rowSelectEnabled: false,
		hideGridFooter: true,
		hideGridHeader: true,
	}

	const [state, actions] = useGrid(gridReducer, initialGridState, communityGrantingGoalsState)

	useEffect(() => {
		actions.doFetch()
		// eslint-disable-next-line
	}, [communityGrantingGoalsState.grantGoals])

	if (!communityGrantingGoalsState.grantGoals) return <Loading />

	return (
		<React.Fragment>
			<div className='my-2'>
				<button className='btn btn-primary' onClick={() => showHidGrantGoalFormModal(true)}>Add Grant Target</button>
			</div>

			<Grid actions={actions} state={state} style={{ minHeight: 200, height: 'unset' }} />

			<Modal
				{...grantGoalFormModal}
				modalTitle={`${!!selectedRow ? 'Edit' : 'New'} Grant Goal`}
				_onModalHidden={() => {
					setSelectedRow(undefined)
				}}
			>
				{grantGoalFormModal.show ?
					<CommunityGrantGoalForm
						branchId={community.branchId}
						grantGoalToEdit={selectedRow && communityGrantingGoalsState.grantGoals.find(o => o.branchGrantGoalId.toString() === selectedRow.id)}
						onSaveSuccess={() => {
							getGrantingGoals()
							showHidGrantGoalFormModal(false)
						}}
					/>
					:
					null
				}
			</Modal>

			<Modal
				{...deleteGrantGoalModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideDeleteGrantGoalModal(false)}>Cancel</button>
						<button className='btn btn-warning' onClick={async () => {
							if (!!selectedRow) {
								await makeHttpRequestWithUi({
									request: appActions.MiBranchGrantGoalsApi.apiMiBranchGrantGoalsDelete(parseInt(selectedRow.id)),
									toastSuccessMessage: 'Successfully deleted grant goal.',
									toastErrorMessage: 'There was an error deleting grant goal.'
								})
							}
							getGrantingGoals()
							showHideDeleteGrantGoalModal(false)
						}}>Delete</button>
					</React.Fragment>
				}
				_onModalHidden={() => {
					setSelectedRow(undefined)
				}}
			>
				Are you sure you want to delete this grant goal?
            </Modal>
		</React.Fragment>
	)
}