import React, { useContext, useState, useEffect } from "react"
import { AppActionContext } from '../app-store-provider'
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import { MinistryInfoAreaDirectorEvalPurpose } from "./ministry-info-ad-eval-purpose"
import { MinistryInfoAreaDirectorEvalGovernance } from "./ministry-info-ad-eval-governance"
import { MinistryInfoAreaDirectorEvalLeadership } from "./ministry-info-ad-eval-leadership"
import { MinistryInfoAreaDirectorEvalOperations } from "./ministry-info-ad-eval-operations"
import { MinistryInfoAreaDirectorEvalFundraising } from "./ministry-info-ad-eval-fundraising"
import { MinistryInfoAreaDirectorEvalCommunications } from "./ministry-info-ad-eval-communications"
import { MinistryInfoAreaDirectorEvalRiskMgmt } from "./ministry-info-ad-eval-risk-mgmt"
import { MinistryInfoAreaDirectorEvalFinancial } from "./ministry-info-ad-eval-financial"
import { IDefaultProps } from "./component-definitions"
import { IMinistryEvalsSummaryDocument } from "../open-api"
import BaseLayout from "./base-layout"
import { Loading } from "./loading"
import '../styles/ad-summary-print.scss'

export enum GRANT_TYPES {
    "Fundraising Event" = 1,
    "Major Gifts" = 2,
    "Grant Writing" = 4,
    "Other" = 6,
    "Direct Mail" = 7,
    "Micro-Events" = 8,
    "Charity" = 20,
    "TGIA" = 25
}

interface IMinistryInfoAreaDirectorEvalSummaryPrintProps extends IDefaultProps {
	ministryId?: string
}

export const MinistryInfoAreaDirectorEvalSummaryPrint = (props: IMinistryInfoAreaDirectorEvalSummaryPrintProps) => {
    const ministryId = props.ministryId!
    const appActions = useContext(AppActionContext)!
    const [ministryEvalState, setMinistryEvalState] = useState<IMinistryEvalsSummaryDocument>()

    const getMinistryEval = async () => {
		const results = await appActions.MinistryEvalsApi.apiMinistriesIdMinistryEvalGet(parseInt(ministryId))
		setMinistryEvalState(results.data)
    }

	useEffect(() => {
        getMinistryEval()
        setTimeout(() => {
            window.print()
            // var curURL = window.location.href;
            // window.history.replaceState(window.history.state, '', '/');
            // window.print();
            // window.history.replaceState(window.history.state, '', curURL);
        }, 2000)
    }, [])
    
    if (!ministryEvalState) return <Loading />

    return (
        <AreaDirectorEvalContext.Provider value={{ministryEvalState, activeEvalTab: 'purpose-tab'}}>
            <BaseLayout>
                <div className="d-flex justify-content-between pb-1">
                    <h4>{ministryEvalState.ministryName}</h4>
                    <div><strong>Final Score:</strong> {ministryEvalState.finalScore} of 82</div>
                </div>
                <h5>Purpose</h5>
                <MinistryInfoAreaDirectorEvalPurpose
                    readonly={true} />
                <h5>Governance</h5>
                <MinistryInfoAreaDirectorEvalGovernance
                    readonly={true} />
                <h5>Leadership</h5>
                <MinistryInfoAreaDirectorEvalLeadership
                    readonly={true} />
                <h5>Operations</h5>
                <MinistryInfoAreaDirectorEvalOperations
                    readonly={true} />
                <h5>Fundraising</h5>
                <MinistryInfoAreaDirectorEvalFundraising
                    readonly={true} />
                <h5>Communications</h5>
                <MinistryInfoAreaDirectorEvalCommunications
                    readonly={true} />
                <h5>Risk Mngmt</h5>
                <MinistryInfoAreaDirectorEvalRiskMgmt
                    readonly={true} />
                <h5>Financial</h5>
                <MinistryInfoAreaDirectorEvalFinancial
                    readonly={true} />
                <div><strong>Final Score:</strong> {ministryEvalState.finalScore} of 82</div>
            </BaseLayout>
        </AreaDirectorEvalContext.Provider>
    )
}