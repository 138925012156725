import { IGridColumn, IGridListItem } from "../stores/grid-definitions"
import { DefaultGridCellDisplay, GridActionCell } from "../components/grid-cell-displays"
import { IMinistryFileSummaryModelDocument } from "../open-api"
import moment from "moment"

export class MinistryFile {
	public static toGridListItem = (ministryFile: IMinistryFileSummaryModelDocument): IGridListItem => {
		return {
			id: ministryFile.ministryFileGuid.toString(),
			values: {
				uploadDate: moment(ministryFile.uploadDate).toDate(),
				formattedDescription: ministryFile.formattedDescription,
				fileName: ministryFile.clientFile,
				fileDesc: ministryFile.fileDesc,
				uploadedByName: ministryFile.uploadedByName,
			},
			rowGroup: {
				id: ministryFile.formattedFileType || '',
				title: `${ministryFile.formattedFileType}`
			}
		}
	}
}

export const ministryFileDefaultColumns: IGridColumn[] = [
	{
		property: 'uploadDate',
		type: 'date',
		width: 153,
		allowFilters: true,
		title: 'Date',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'formattedDescription',
		type: 'string',
		width: 200,
		allowFilters: true,
		title: 'File Type',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'fileName',
		type: 'string',
		width: 150,
		allowFilters: true,
		title: 'File Name',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'fileDesc',
		type: 'string',
		width: 150,
		allowFilters: true,
		title: 'Description',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'uploadedByName',
		type: 'string',
		width: 150,
		allowFilters: true,
		title: 'Uploaded By',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'grid_actions',
		type: 'actions',
		width: 150,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
		align: 'center'
	},
]