import { Form, Formik } from "formik"
import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IGrantRequestSummaryDocument, IMinistryFileSummaryModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { TinyMceField } from "./forms"
import * as Yup from 'yup'
import { CheckCircleIcon, ExclamationTriangleFillIcon } from "../assets"
import { Modal } from "./modal"
import { uuidv4 } from "../services/helpers"
import { MinistryFiles } from "./ministry-files"
import { GRANT_TYPE } from "../constants"

interface IGrantRequestFormFilesProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    afterSave?: () => Promise<void>
}

export const GrantRequestFormFiles = (props: IGrantRequestFormFilesProps) => {
    const { grantRequestToEdit, afterSave } = props
    const { GrantRequestApi, addAlert, MinistryFilesApi } = useContext(AppActionContext)!

    const [coverLetterText, setCoverLetterText] = useState<string>()
    const [coverLetterModal, showHideCoverLetterModal] = useModal()
    const [approveLettersModal, showHideApproveLettersModal] = useModal()
    const [submitForSignatureModal, showHideSubmitForSignatureModal] = useModal()
    const [uploadedSignedDocsModal, showHideuploadedSignedDocsModal] = useModal()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchCoverLetter = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestGrantIdGrantAgreementCoverLetterGet(grantRequestToEdit.grantId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching cover letter.',
        })
        setCoverLetterText(data)
    }

    const fetchGrantFiles = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: MinistryFilesApi.apiMinistriesIdMinistryFilesGet(grantRequestToEdit.ministryId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching cover letter.',
        })
        if (data) {
        var _grantFiles = data.filter(x => x.grantId == grantRequestToEdit.grantId)
            setGrantFiles(_grantFiles)
        }
    }

    const showCoverLetter = async () => {
        await fetchCoverLetter()
        // Once the user opens the cover letter modal, if a cover letter didn't exists it does now.
        if (!grantRequestToEdit.hasCoverLetter) grantRequestToEdit.hasCoverLetter = true
        showHideCoverLetterModal(true)
    }

    const approveLetters = async () => {
        if (!grantRequestToEdit.hasCoverLetter) {
            addAlert({
                id: uuidv4(),
                title: 'Error',
                body: 'You must review the Cover Letter before proceeding.',
            })
            return false;
        }

        showHideApproveLettersModal(true)
    }

    const submitForSignature = async () => {
        if (!grantRequestToEdit.hasCoverLetter) {
            addAlert({
                id: uuidv4(),
                title: 'Error',
                body: 'You must review and approve the Grant Agreement and LoA before proceeding.',
            })
            return false;
        }

        showHideSubmitForSignatureModal(true)
    }

    const uploadedSignedDocs = async () => {
        if (!grantRequestToEdit.hasCoverLetter) {
            addAlert({
                id: uuidv4(),
                title: 'Error',
                body: 'You must review and approve the Grant Agreement and LoA before proceeding.',
            })
            return false;
        }

        showHideuploadedSignedDocsModal(true)
    }

    const [grantFiles, setGrantFiles] = useState<IMinistryFileSummaryModelDocument[]>([])

    useEffect(() => {
        fetchGrantFiles()
    }, [])

    const getGaLoaTitle = () => {
        // Managed grants only require the LoA.
        return grantRequestToEdit.grantTypeId === GRANT_TYPE.Managed ? 'Letter of Acknowledgement' : 'Grant Agreement and Letter of Acknowledgement'
    }

    return (
        <>
            <div className="d-flex flex-column vh-100 p-2">
                {!grantRequestToEdit.signedGrantAgreementReceived && !grantRequestToEdit.isLegacyGrant && 
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{getGaLoaTitle()}</h5>
                        <p className="card-text">
                            The ministry must sign the {getGaLoaTitle()} (LoA) 
                            in order to move along in the process.
                        </p>
                        <div className="mb-2">{grantRequestToEdit.hasCoverLetter ? <CheckCircleIcon className='text-success' /> : <ExclamationTriangleFillIcon className="text-warning" />} 
                            Step 1. AD <a href="#" onClick={() => showCoverLetter()}>reviews</a> the Cover Letter.</div>
                        <div className="mb-2">{grantRequestToEdit.approvedLetters ? <CheckCircleIcon className='text-success' /> : <ExclamationTriangleFillIcon className="text-warning" />} 
                            Step 2. AD reviews the&nbsp; 
                            {!grantRequestToEdit.hasCoverLetter ? getGaLoaTitle() : <a href={`/grant-request-agreement-print/${grantRequestToEdit.grantId}`} target="_blank">{getGaLoaTitle()}</a>} then {grantRequestToEdit.approvedLetters ? 'approve' : <a href="#" onClick={() => approveLetters()}>approve</a>}.</div>
                        <div className="mb-2">{grantRequestToEdit.dateGrantAgreementSignatureRequested ? <CheckCircleIcon className='text-success' /> : <ExclamationTriangleFillIcon className="text-warning" />} 
                            Step 3. NRC {grantRequestToEdit.dateGrantAgreementSignatureRequested ? 'submits' : <a href="#" onClick={() => submitForSignature()}>submits</a>} a Docusign e-signature request to the ministry.</div>
                        <div className="mb-2">{grantRequestToEdit.signedGrantAgreementReceived ? <CheckCircleIcon className='text-success' /> : <ExclamationTriangleFillIcon className="text-warning" />} 
                            Step 4. NRC <a href="#" onClick={() => uploadedSignedDocs()}>uploads</a> the signed {getGaLoaTitle()}.</div>
                    </div>
                </div>}
                <h5 className="mt-3">Documents</h5>
                <div className="row pl-2">
                    <div className="col-sm-12">
                        {grantRequestToEdit.signedGrantAgreementReceived &&
                        <div className="mb-2">
                            {/* The ministry submits a Grant Report after the Matching Period is complete. */}
                            <label>{getGaLoaTitle()} (Unsigned original):</label> <a href={`/grant-request-agreement-print/${grantRequestToEdit.grantId}`} target="_blank">View</a>
                        </div>}
                        {grantRequestToEdit.summaryReportUrl &&
                        <div className="mb-2">
                            {/* The ministry submits a Grant Report after the Matching Period is complete. */}
                            <label>Grant Summary Report:</label> <a href={grantRequestToEdit.summaryReportUrl} target="_blank">View</a>
                        </div>}
                        {grantRequestToEdit.dateFeedbackSubmitted && grantRequestToEdit.feedbackReportUrl && 
                        <div className="mb-2">
                            {/* The ministry submits a Grant Report after the Matching Period is complete. */}
                            <label>Grant Feedback Report:</label> <a href={grantRequestToEdit.feedbackReportUrl} target="_blank">View</a>
                        </div> }
                    </div>
                </div>
                <MinistryFiles 
                    ministryId={grantRequestToEdit.ministryId}
                    grantId={grantRequestToEdit.grantId}
                    ministryFiles={grantFiles}
                    afterSave={async () => {
                        await fetchGrantFiles()
                    }}
                    hideMissingDocumentsBtn={true} />
            </div>
            <Modal
                    {...coverLetterModal}
                    modalTitle='Cover Letter'
                    size="lg"
                >
                <Formik
                    initialValues={{
                        coverLetter: coverLetterText
                    }}
                    enableReinitialize
                    validationSchema={Yup.object({
                        coverLetter: Yup.string().required('Required')
                    })}
                    onSubmit={async (values) => {
                        await makeHttpRequestWithUi({
                            request: GrantRequestApi.apiGrantRequestGrantIdGrantAgreementCoverLetterPut(grantRequestToEdit.grantId, {
                                content: values.coverLetter || ''
                            }),
                            toastSuccessMessage: 'Successfully updated the cover letter.',
                            toastErrorMessage: 'Error submitting request.',
                            disableLoading: false,
                        })

                        afterSave && afterSave()
                        showHideCoverLetterModal(false)
                    }}
                >
                    {formikProps => {
                        return (
                    <Form>
                        <TinyMceField fieldProps={{name: 'coverLetter', label: 'Cover Letter'}} />
                        <div>
                            <button type='submit' className='btn btn-primary mr-2'>Save</button>
                            <button style={{ width: 100 }} className='btn btn-secondary' type='button' onClick={() => showHideCoverLetterModal(false)}>Cancel</button>
                        </div>
                    </Form>
                    )
                }}
                </Formik>
            </Modal>
            <Modal
                {...approveLettersModal}
                modalTitle='Approve Grant Agreement and LoA'
                footer={
					<>
						<button className='btn btn-secondary' type='button' onClick={() => showHideApproveLettersModal(false)}>Cancel</button>
						<button
							className='btn btn-primary'
                            type='button'
							onClick={async () => {
								await makeHttpRequestWithUi({
                                    request: GrantRequestApi.apiGrantRequestGrantIdApproveLettersPost(grantRequestToEdit.grantId),
                                    toastSuccessMessage: 'Successfully approved Grant Agreement and LoA.',
                                    toastErrorMessage: 'There was an error approving the Grant Agreement and LoA.'
                                })

                                await makeHttpRequestWithUi({
                                    request: GrantRequestApi.apiGrantRequestGrantIdRequestESignaturePost(grantRequestToEdit.grantId),
                                    toastSuccessMessage: 'Successfully submitted request for e-signature.',
                                    toastErrorMessage: 'There was an error requesting the e-signature.'
                                })

                                afterSave && afterSave()
                                showHideApproveLettersModal(false)
							}}
						>
                        Approve
						</button>
					</>
				}
                _onModalHidden={() => {
                    showHideApproveLettersModal(false)
                }}
            >
                <p>You must review the <a href={`/grant-request-agreement-print/${grantRequestToEdit.grantId}`} target="_blank">Grant Agreement and LoA</a> before approving them.</p> 
                <p>By approving the Grant Agreement and LoA for e-signature, <strong>you are acknowledging that you have reviewed
                the Cover Letter, Grant Agreement and LoA</strong>.</p>
                <p>After you have approved these documents, they will be submitted to the primary Grant Request Contact for e-signature within 2 business days.</p>
            </Modal>
            <Modal
                {...submitForSignatureModal}
                modalTitle='Submit for E-Signature'
                footer={
					<>
						<button className='btn btn-secondary' type='button' onClick={() => showHideSubmitForSignatureModal(false)}>Cancel</button>
						<button
							className='btn btn-primary'
                            type='button'
							onClick={async () => {
								await makeHttpRequestWithUi({
                                    request: GrantRequestApi.apiGrantRequestGrantIdRequestedESignaturePost(grantRequestToEdit.grantId),
                                    toastSuccessMessage: 'Successfully updated.',
                                    toastErrorMessage: 'There was an error updating the record.'
                                })

                                afterSave && afterSave()
                                showHideSubmitForSignatureModal(false)
							}}
						>
                        Yes, submitted
						</button>
					</>
				}
                _onModalHidden={() => {
                    showHideSubmitForSignatureModal(false)
                }}
            >
                <p>Has a Docusign e-signature request been submitted to the primary grant request contact?</p> 
                <p>This step is normally taken by the person at the NRC who has generated the Grant Agreement and LoA e-signature request using Docusign.</p>
            </Modal>
            <Modal
                {...uploadedSignedDocsModal}
                modalTitle='Uploaded Signed Documents?'
                footer={
					<>
						<button className='btn btn-secondary' type='button' onClick={() => showHideuploadedSignedDocsModal(false)}>Cancel</button>
						<button
							className='btn btn-primary'
                            type='button'
							onClick={async () => {
								await makeHttpRequestWithUi({
                                    request: GrantRequestApi.apiGrantRequestGrantIdReceivedESignaturePost(grantRequestToEdit.grantId),
                                    toastSuccessMessage: 'Successfully updated.',
                                    toastErrorMessage: 'There was an error updating the record.'
                                })

                                afterSave && afterSave()
                                showHideuploadedSignedDocsModal(false)
							}}
						>
                        Yes, uploaded
						</button>
					</>
				}
                _onModalHidden={() => {
                    showHideuploadedSignedDocsModal(false)
                }}
            >
                <p>Have you uploaded a copy of the signed Grant Agreement and LoA?</p>
            </Modal>
        </>
    )
}