import { IMinistryEventAttendanceSummaryDocument } from "../open-api"
import { IGridListItem, IGridColumn } from "../stores/grid-definitions"
import { DefaultGridCellDisplay, MinistryEventAttendanceEvalDisplay, MinistryEventAttendanceDisplay, MinistryEventAttendedDisplay, GridActionCell } from "../components/grid-cell-displays"

export class EventAttendanceModel {

	public static toGridListItem = (attendanceModel: IMinistryEventAttendanceSummaryDocument): IGridListItem => {

		//const hasHomework = attendanceModel.homeworkFileId || 0 > 0

		return {
			id: attendanceModel.seminarAttendeeId.toString(),
			values: {
				eventDate: attendanceModel.eventDate ? new Date(attendanceModel.eventDate) : null,
				trainingArea: attendanceModel.trainingArea,
				title: attendanceModel.title,
				locationName: attendanceModel.locationName,
				contactFirstName: attendanceModel.contactFirstName,
				contactLastName: attendanceModel.contactLastName,
				contactEmail: attendanceModel.contactEmail,
				attended: attendanceModel.attended,
				seminarEvalId: attendanceModel.seminarEvalId,
				eventTypeId: attendanceModel.eventTypeId,
				isActive: attendanceModel.isActive,
				isNoShow: attendanceModel.isNoShow,
				cancelled: attendanceModel.canceled,
				eventId: attendanceModel.eventId,
				eventContentId: attendanceModel.eventContentId,
				consultingEventGroupId: attendanceModel.consultingEventGroupId
				// 2020071 TB - It is unclear at this time if homework is still being used.
				// postponing implementation for now.
				// hasHomework: hasHomework,
				// homeworkFileId: attendanceModel.homeworkFileId,
				// homeworkFileName: attendanceModel.homeworkFileName
			}
		}
	}
}

export const eventAttendanceModelDefaultColumns: IGridColumn[] = [
	{
		property: 'eventDate',
		type: 'date',
		width: 153,
		allowFilters: true,
		title: 'Event Date',
		render: MinistryEventAttendanceDisplay,
		description: "The date of the event"
	},
	{
		property: 'trainingArea',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'Training Area',
		render: MinistryEventAttendanceDisplay,
		description: 'The training area that the event was held'
	},
	{
		property: 'title',
		type: 'string',
		width: 200,
		allowFilters: true,
		title: 'Event',
		render: MinistryEventAttendanceDisplay,
		description: 'The title of the event'
	},
	{
		property: 'locationName',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'Location',
		render: MinistryEventAttendanceDisplay,
		description: 'The location of the event'
	},
	{
		property: 'contactFirstName',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'First Name',
		render: DefaultGridCellDisplay,
		description: 'The contact that registered for the event'
	},
	{
		property: 'contactLastName',
		type: 'string',
		width: 153,
		allowFilters: true,
		title: 'Last Name',
		render: DefaultGridCellDisplay
	},
	{
		property: 'grid_actions',
		type: 'actions',
		width: 150,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	},
	{
		property: 'attended',
		type: 'boolean',
		width: 153,
		allowFilters: true,
		title: 'Attended',
		render: MinistryEventAttendedDisplay,
		description: 'Whether or not the contact attended the event'
	},
	{
		property: 'seminarEvalId',
		type: 'boolean',
		width: 153,
		allowFilters: true,
		title: 'Eval',
		render: MinistryEventAttendanceEvalDisplay,
		description: 'Whether or not the contact submitted an eval for the event'
	}
]