import React, { useState, useContext } from 'react'
import { IAppState } from '../stores/app-definitions'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { filterGridListItems, showModal, uuidv4, sortListBySorts } from '../services/helpers'
import { EmailLogModel, emailLogModelDefaultColumns } from '../models/email-log'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { Modal } from './modal'
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'

interface IProspectDetailEmailLogProps {
	prospectId: number
}
export const ProspectDetailEmailLog = (props: IProspectDetailEmailLogProps) => {
	const { prospectId } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const fetchMinistryEmailLog: GridDataFetch<IAppState> = async (queryState, _state) => {
		const emailLogQuery = await makeHttpRequestWithUi({
			request: appActions.MailEngineApi.apiProspectsIdEmailsGet(prospectId),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was a problem fetching the email log for this prospect.'
		})

		const emailLog = emailLogQuery.data

		if (queryState.sorts) sortListBySorts(emailLog, queryState.sorts)

		let rows = emailLog.map(EmailLogModel.toGridListItem)

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: emailLogModelDefaultColumns,
		disabledPagination: true,
		dataSource: fetchMinistryEmailLog,
		rowActions: {
			viewEmail: {
				id: 'viewEmail',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(viewEmailModal)
				},
				icon: <SearchIcon />,
				tooltipText: 'View Email',
			}
		}
	}
	const [viewEmailModal] = useState(uuidv4())

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			<Modal
				modalId={viewEmailModal}
				modalTitle='Email Body'
				size='xl'
				onModalHidden={() => setSelectedRow(undefined)}
			>
				<div dangerouslySetInnerHTML={{ __html: typeof selectedRow?.values.body === "string" ? selectedRow?.values.body : '' }}></div>
			</Modal >
		</React.Fragment>
	)
}