// Original Source: Admin\modules\Donors\donorManagement.js

import React, { useCallback, useContext, useState } from "react"
import { IDefaultProps } from "./component-definitions"
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridRowAction, IGridRowGroupActions, IGridState } from "../stores/grid-definitions"
import { IAppState } from "../stores/app-definitions"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IGetGiverOrgResultDocument, IGetGiverResultDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell } from "./grid-cell-displays"
import { gridReducer } from "../stores/grid-reducer"
import { Helmet } from "react-helmet"
import { Grid } from "./grid"
import { ReactComponent as HandshakeRegularIcon } from '../assets/handshake-regular.svg'
import { PencilIcon, IncognitoIcon } from "../assets"
import { UserModel, UserPermissionLevel } from "../models/user"
import { openUrlInNewTab } from "../services/helpers"
import { Modal } from "./modal"
import { SquareDeleteIcon } from "./partials"
import { GiverForm } from './giver-form'
import { GiverOrgForm } from './giver-org-form'
import { ReactComponent as PersonPlusIcon } from '../assets/person-plus.svg'
import { ReactComponent as EnvelopeIcon } from '../assets/envelope.svg'

interface IGiverManagementState {}

interface IGiverManagementProps extends IDefaultProps { }
export const GiverManagement = ({ }: IGiverManagementProps) => {
    const { GiverApi } = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IGiverManagementState>({})

    const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined
        
        try {

            const query = await makeHttpRequestWithUi({
                request: GiverApi.apiGiverGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of giver records.'
            })

            const rows = (query.data.data || []).map<IGridListItem>(item => ({
                id: item.giverId?.toString() ?? '',
                values: {
                    ...item,
                    giverIdDisplay: item.giverId,
                    lastLogin: item.lastLogin ? new Date(item.lastLogin) : null
                },
                rowGroup: {
                    id: item.giverOrgId?.toString(),
                    title: item.giverOrgName || '',
                    showEmptyRowGroup: true
                },
                hidden: !Boolean(item.giverId)
            }))

            return {
                rows,
                count: query.data.totalRows
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    const [selectedGiver, setSelectedGiver] = useState<IGridListItem>()
    // const [selectedGiverOrg, setSelectedGiverOrg] = useState<IGetGiverOrgResultDocument>()

    const [giverOrgId, setGiverOrgId] = useState<number>()
    const [giverToEdit, setGiverToEdit] = useState<IGetGiverResultDocument>()
    const [giverOrgToEdit, setGiverOrgToEdit] = useState<IGetGiverOrgResultDocument>()
	const [giverFormModal, showHideGiverFormModal] = useModal()
    const [giverOrgFormModal, showHideGiverOrgFormModal] = useModal()
    const [confirmResendModal, showHideConfirmResendModal] = useModal()
    const [confirmSendLoginInstructionsModal, showHideConfirmSendLoginInstructionsModal] = useModal()

    const editGiver = async (giverRow: IGridListItem) => {
		setSelectedGiver(giverRow)
		showHideGiverFormModal(true)

		const queryResult = await makeHttpRequestWithUi({
			request: GiverApi.apiGiverGiverIdGet(parseInt(giverRow.id)),
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving the giver to edit.'
		})

        setGiverToEdit(queryResult.data)
	}

    const rowActions: { [id: string]: IGridRowAction } = {}
    const rowGroupActions: IGridRowGroupActions = { actions: [], nestedActions: []}

    const [archiveGiverOrgModal, showHideArchiveGiverOrgModal] = useModal()
	const [giverOrgToArchive, setGiverOrgToArchive] = useState<{ giverOrgId: string | null }>({
		giverOrgId: null
	})

    const [archiveGiverModal, showHideArchiveGiverModal] = useModal()
	const [giverToArchive, setGiverToArchive] = useState<{ giverId: string | null }>({
		giverId: null
	})

    if (appState.currentUser?.bSuperUser) {
        rowActions.editGiver = {
            id: 'editGiver',
            action: async (options) => {
                options.e.stopPropagation()
                editGiver(options.row)
            },
            icon: <PencilIcon />,
            tooltipText: 'Edit Giver'
        }
    }

    // Must have "Read" perms to take these actions
    if (UserModel.checkPermissionLevelForUser(10, UserPermissionLevel.Modify, appState.currentUser)) {
        rowActions.impersonateGiver = {
            id: 'impersonateGiver',
            action: async (options) => {
                const { e, row } = options

                e.stopPropagation()
                openUrlInNewTab(row.values.impersonationUrl?.toString() ?? '')
            },
            icon: <IncognitoIcon />,
            tooltipText: 'Impersonate Giver',
            hidden: (row) => !row.values.impersonationUrl
        }

        rowActions.sendLoginInstructions = {
			id: 'sendLoginInstructions',
			action: async (options) => {
                const { e, row } = options

                e.stopPropagation()
                setSelectedGiver(row)
                showHideConfirmSendLoginInstructionsModal(true)
            },
			icon: <EnvelopeIcon />,
			tooltipText: 'Send Login Instructions',
            hidden: (row) => !row.values.subjectId
		}
    }

    // Must have "Super User" perms to take these actions
    if (appState.currentUser?.bSuperUser) {
        rowGroupActions.actions?.push({
            id: 'editGiverOrg',
            action: async ({ rowGroupId }) => {
                const { data } = await makeHttpRequestWithUi({
                    request: GiverApi.apiGiverGiverOrgGiverOrgIdGet(parseInt(rowGroupId)),
                    disableSuccessToast: true,
                    toastErrorMessage: 'Enountered an error retrieving group org record to edit.',
                })
                setGiverOrgToEdit(data)
                showHideGiverOrgFormModal(true)
            },
            icon: <PencilIcon />,
            tooltipText: 'Edit Giver Org'
        })
        rowGroupActions.actions?.push({
            id: 'addGiver',
            action: async ({ rowGroupId }) => {
                setGiverOrgId(parseInt(rowGroupId))
                showHideGiverFormModal(true)
            },
            icon: <PersonPlusIcon />,
            tooltipText: 'Add Giver'
        })
        rowGroupActions.nestedActions?.push({
            id: 'archiveGiverOrg',
            action: async (options) => {
                options.e.stopPropagation()
                setGiverOrgToArchive({
                    giverOrgId: options.rowGroupId
                })
                showHideArchiveGiverOrgModal(true)
            },
            icon: <SquareDeleteIcon />,
            tooltipText: 'Archive Giver Org'
        })

        rowActions.resendEmailInvite = {
			id: 'resendEmailInvite',
			action: async (options) => {
                const { e, row } = options

                e.stopPropagation()
                setSelectedGiver(row)
                showHideConfirmResendModal(true)
            },
			icon: <EnvelopeIcon />,
			tooltipText: 'Resend Email Invitation',
            hidden: (row) => !!row.values.subjectId
		}
        rowActions.archiveGiver = {
			id: 'archiveGiver',
			action: async (options) => {
				options.e.stopPropagation()
				setGiverToArchive({
					giverId: options.row.id
				})
				showHideArchiveGiverModal(true)
			},
			icon: <SquareDeleteIcon />,
			tooltipText: 'Archive Giver'
		}
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.Givers,
        columns: [
            {
                property: 'giverIdDisplay',
                type: 'number',
                width: 25,
                allowFilters: true,
                title: 'Giver ID',
                render: DefaultGridCellDisplay,
                hide: true
            },
            {
                property: 'giverOrgName',
                type: 'string',
                width: 110,
                allowFilters: true,
                title: 'Giver Org',
                render: DefaultGridCellDisplay,
                hide: true
            },
            {
                property: 'firstName',
                type: 'string',
                width: 85,
                allowFilters: true,
                title: 'First Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'lastName',
                type: 'string',
                width: 85,
                allowFilters: true,
                title: 'Last Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'giverOrgCommunityNames',
                type: 'string',
                width: 50,
                allowFilters: true,
                title: 'Fund(s)',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'email',
                type: 'string',
                width: 75,
                allowFilters: true,
                title: 'Email',
                render: EmailCellDisplay,
            },
            {
                property: 'lastLogin',
                type: 'date',
                width: 50,
                allowFilters: true,
                title: 'Last Login',
                render: DefaultGridCellDisplay
            },
            {
                property: 'lastIpAddress',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'Last Login IP',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },
        ],
        dataSource,
        rowActions,
        rowGroupActions,
        rowSelectEnabled: false,
        gridActions: [
			{
				id: 'newGiverOrg',
				label: 'New Giver Org',
				onClick: () => showHideGiverOrgFormModal(true),
                disabled: () => !UserModel.userIsSuperUser(appState.currentUser) //!UserModel.checkPermissionLevelForUser(10, UserPermissionLevel.Modify, appState.currentUser)
                
			}
		],
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, state)

    return (
        <>
            <Helmet>
                <title>Giver Management</title>
            </Helmet>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <HandshakeRegularIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Giver Management</h3>
                </div>
                {appState.currentUser?.bSuperUser ?
                <p className='ml-2'>
                    You are a super user; therefore you can see all givers, regardless of their community.<br />
                    Non-super users can only see givers of communities they are associated with, cannot add or edit givers, and cannot see Mission Increase givers.
                </p>
                :
                null
                }
                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
				{...giverOrgFormModal}
				modalTitle='New Giver Org'
				size='fullscreen'
				dismissible={false}
				_onModalHidden={() => {
					setGiverOrgToEdit(undefined)
				}}
			>
				{giverOrgFormModal.show ?
					<GiverOrgForm 
                        giverOrgToEdit={giverOrgToEdit}
                        onSaveSucces={async () => {
                            showHideGiverOrgFormModal(false)
                            await gridActions.doFetch()
                        }} />
                    : null
				}
			</Modal>
            
            <Modal
				{...archiveGiverOrgModal}
				modalTitle='Archive Giver Org Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideArchiveGiverOrgModal(false)}>Cancel</button>
						<button
							className='btn btn-danger'
							onClick={async () => {
								if (!giverOrgToArchive.giverOrgId) return
								setGiverOrgToArchive({ ...giverOrgToArchive })
                                await makeHttpRequestWithUi({
                                    request: GiverApi.apiGiverGiverOrgGiverOrgIdDelete(parseInt(giverOrgToArchive.giverOrgId)),
                                    toastSuccessMessage: 'Successfully archived the Giver Org.',
                                    toastErrorMessage: 'There was an error deleting the Giver Org.'
                                })
                                showHideArchiveGiverOrgModal(false)
                                gridActions.doFetch()
							}}
						>
                        ARCHIVE
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setGiverOrgToArchive({ giverOrgId: null })}
			>
				Are you sure you want to archive this giver org?
			</Modal>
            <Modal
				{...archiveGiverModal}
				modalTitle='Archive Giver Confirmation'
				footer={
					<React.Fragment>
						<button className='btn btn-secondary' onClick={() => showHideArchiveGiverModal(false)}>Cancel</button>
						<button
							className='btn btn-danger'
							onClick={async () => {
								if (!giverToArchive.giverId) return
									await makeHttpRequestWithUi({
										request: GiverApi.apiGiverGiverIdDelete(parseInt(giverToArchive.giverId)),
										toastSuccessMessage: 'Successfully archived the Giver.',
										toastErrorMessage: 'There was an error archiving the Giver.'
									})
									showHideArchiveGiverModal(false)
                                    gridActions.doFetch()
							}}
						>
							DELETE
						</button>
					</React.Fragment>
				}
				_onModalHidden={() => setGiverToArchive({ giverId: null})}
			>
				Are you sure you want to archive this giver?
			</Modal>
            <Modal
				{...giverFormModal}
				modalTitle={!!giverToEdit ? 'Edit Giver' : 'New Giver'}
				size='lg'
				_onModalHidden={() => {
					setSelectedGiver(undefined)
					setGiverToEdit(undefined)
                    setGiverOrgId(undefined)
				}}
			>
				{giverFormModal.show && (giverToEdit || giverOrgId) ?
					<GiverForm
                        giverOrgId={giverOrgId}
						giverToEdit={giverToEdit}
						onSaveSucces={() => {
							setSelectedGiver(undefined)
							showHideGiverFormModal(false)
                            gridActions.doFetch()
						}}
					/>
					:
					null
				}
			</Modal>

            <Modal
                {...confirmResendModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' 
                            className='btn btn-primary' 
                            onClick={async () => {
                                if (!selectedGiver) return
                                //const taskId = registerLoadingTask()
                                await GiverApi.apiGiverGiverIdSendInvitePost(parseInt(selectedGiver.id))
                                //deregisterLoadingTask(taskId)
                                setSelectedGiver(undefined)
                                showHideConfirmResendModal(false)
						}}>Send</button>
					</React.Fragment>
				}
				onModalHidden={() => setSelectedGiver(undefined)}
			>
				Are you sure you want to re-send the invite email to this contact containing a link for them to register with Mission Increase?
			</Modal>

            <Modal
                {...confirmSendLoginInstructionsModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' 
                            className='btn btn-primary' 
                            onClick={async () => {
                                if (!selectedGiver) return
                                //const taskId = registerLoadingTask()
                                await GiverApi.apiGiverGiverIdSendLoginInstructionsPost(parseInt(selectedGiver.id))
                                //deregisterLoadingTask(taskId)
                                setSelectedGiver(undefined)
                                showHideConfirmSendLoginInstructionsModal(false)
						}}>Send</button>
					</React.Fragment>
				}
				onModalHidden={() => setSelectedGiver(undefined)}
			>
				Are you sure you want to send the login instructions email to this giver?
			</Modal>
        </>
    )
}