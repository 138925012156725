import { Form, Formik, FormikProps } from 'formik'
import React, { useContext, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IMiTrainingAreasPostModelDocument, IMiTrainingAreasPutModelDocument, IMiTrainingAreaSummaryModelDocument } from '../open-api'
import { CheckboxField, CommunitySelectField, SelectField, TextField } from './forms'
import * as Yup from 'yup'
import { sortListByProperty } from '../services/helpers'
import { Loading, LoadingPropsSizeEnum } from './loading'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { EventSites } from './event-sites'

interface ITrainingAreaFormProps {
	trainingAreaToEdit?: IMiTrainingAreaSummaryModelDocument
	branchId?: number // Create training area for a specific branch
	onSaveSucces?: () => void
}
export const TrainingAreaForm = (props: ITrainingAreaFormProps) => {
	const { trainingAreaToEdit, branchId, onSaveSucces } = props

	console.log('trainingAreaToEdit', trainingAreaToEdit)

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const initialValues = {
		branchId: trainingAreaToEdit?.branchId.toString() || branchId?.toString() || '',
		trainingArea: trainingAreaToEdit?.trainingArea || '',
		trainingAreaAbbr: trainingAreaToEdit?.trainingAreaAbbr || '',
		postalCode: trainingAreaToEdit?.postalCode || '',
		trainingAreaContact: trainingAreaToEdit?.trainingAreaContact?.toString() || '',
		bVirtual: !!trainingAreaToEdit?.bVirtual,
		eventsPerCycle: trainingAreaToEdit ? trainingAreaToEdit.eventsPerCycle.toString() : '',
		defaultPresenters: trainingAreaToEdit?.defaultPresenters?.map(o => o.toString()) || [],
	}

	const validationSchema = Yup.object({
		branchId: Yup.string().required('Required'),
		trainingArea: Yup.string().required('Required'),
		trainingAreaAbbr: Yup.string().required('Required').min(2, 'Must be at least 2 characters.').max(5, 'Must be 5 or fewer characters.'),
		trainingAreaContact: Yup.string().required('Required'),
	})

	useEffect(() => {
		if (!appState.presenters) appActions.fetchPresenters()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (!!trainingAreaToEdit && _formikProps) _formikProps.resetForm()
	}, [trainingAreaToEdit])

	let _formikProps: FormikProps<any>

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={async (values, actions) => {
				const trainingArea: IMiTrainingAreasPostModelDocument = {
					...values,
					branchId: parseInt(values.branchId),
					trainingAreaContact: parseInt(values.trainingAreaContact),
					eventsPerCycle: values.eventsPerCycle ? parseInt(values.eventsPerCycle) : null,
					defaultPresenters: values.defaultPresenters.map(o => typeof o === 'string' ? parseInt(o) : o)
				}

				if (!!trainingAreaToEdit) {
					const trainingAreaEdit: IMiTrainingAreasPutModelDocument = {
						...trainingAreaToEdit,
						...trainingArea,
					}

					await makeHttpRequestWithUi({
						request: appActions.MiTrainingAreasApi.apiMiTrainingAreasIdPut(trainingAreaToEdit.trainingAreaId, trainingAreaEdit),
						toastSuccessMessage: 'Successfully created Training Area.',
						toastErrorMessage: 'Error creating Training Area.'
					})
				} else {
					await makeHttpRequestWithUi({
						request: appActions.MiTrainingAreasApi.apiMiTrainingAreasPost(trainingArea),
						toastSuccessMessage: 'Successfully created Training Area.',
						toastErrorMessage: 'Error creating Training Area.'
					})
				}

				if (onSaveSucces) onSaveSucces()
			}}
		>
			{formikProps => {
				_formikProps = formikProps
				return (
					<div className='d-flex flex-column mx-2' style={{ height: '100%' }}>
						<Form className='d-flex flex-column' style={{ flex: 1 }}>
							{!!branchId ? null : <CommunitySelectField fieldProps={{ name: 'branchId', label: 'Community', placeholder: 'Select a community...' }} />}

							<div className='d-flex'>
								<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'trainingArea', label: 'Training Area Name' }} /></div>
								<div style={{ flex: 1 }}><TextField fieldProps={{ name: 'trainingAreaAbbr', label: 'Training Area Abbreviation' }} /></div>
							</div>

							<div className='d-flex'>
								<div className='mr-2' style={{ flex: 1 }}>
									{appState.users ?
										<SelectField
											fieldProps={{ name: 'trainingAreaContact', label: 'Host Area Director', placeholder: 'Select an area director...' }}
											options={sortListByProperty(appState.users, 'firstName').filter(o => (!o.bDisabled && o.branchId?.toString() === formikProps.values.branchId) || o.userId === 40).map(o => ({ value: `${o.userId}`, label: `${o.firstName || ''} ${o.lastName || ''}` }))}
										/>
										:
										<Loading size={LoadingPropsSizeEnum.small} />
									}
								</div>
								<div style={{ flex: 1 }}>
									{appState.presenters ?
										<SelectField
											multiple={true}
											fieldProps={{ name: 'defaultPresenters', label: 'Default Presenters', placeholder: 'Select a Presenter...' }}
											options={appState.presenters.filter(o => !!o.firstName && !!o.lastName).map(o => ({ value: `${o.presenterId}`, label: `${o.firstName} ${o.lastName}${!!o.title ? `, ${o.title}` : null}` }))}
										/>
										:
										<Loading size={LoadingPropsSizeEnum.small} />
									}
								</div>
							</div>

							<div className='d-flex'>
								<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'postalCode', label: 'Postal Code' }} /></div>
								<div style={{ flex: 1 }}>
									{/*
										20230807 TB - Don't see that we are using this in 2.0, so removing for now. 
									<SelectField
										fieldProps={{ name: 'eventsPerCycle', label: 'Events per Cycle' }}
										options={[
											{ label: '1', value: '1' },
											{ label: '2', value: '2' },
											{ label: '3', value: '3' },
											{ label: '4', value: '4' },
											{ label: '5', value: '5' },
											{ label: '6', value: '6' },
											{ label: '7', value: '7' },
										]}
									/> */}
								</div>
							</div>

							<div className='mr-2'><CheckboxField fieldProps={{ name: 'bVirtual', label: 'Virtual Training Area' }} /></div>

							<div className='mb-4 mt-2'>
								<button type='submit' style={{ minWidth: 300 }} className='btn btn-primary'>{!!trainingAreaToEdit ? 'Update' : 'Create'}</button>
							</div>
						</Form>

						<div style={{ flex: 1 }}>
							{!!trainingAreaToEdit ?
								<React.Fragment>
									<hr />
									<EventSites trainingArea={trainingAreaToEdit} />
								</React.Fragment>
								:
								null
							}
						</div>
					</div>
				)
			}}
		</Formik>
	)
}