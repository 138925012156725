import { ISeminarEvalSummaryDocument } from "../open-api"
import { useEffect } from "react"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, IGridColumn, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { EVENT_EVAL_RATINGS_WEBINAR, EVENT_EVAL_RATINGS_WORKSHOP, EVENT_TYPES } from "../constants"
import { DefaultGridCellDisplay } from "./grid-cell-displays"
import { Grid } from "./grid"

interface IEventEvals {
    evals: ISeminarEvalSummaryDocument[]
}

export const EventEvals = (props: IEventEvals) => {
    const { evals } = props

    const areWebinars = evals.some(x => x.eventTypeId === EVENT_TYPES.Webinar)

    const dataSource: GridDataFetch<typeof evals> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.seminarEvalId!.toString(),
                values: {
                    ...o,
                    // regDate: o.regDate ? new Date(o.regDate) : null,
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('EventFormEvals > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    let gridColumns: IGridColumn[] = []
    let gridColumnsWebinar: IGridColumn[] = []

    gridColumns.push(
        {
            property: 'ministryName',
            type: 'string',
            width: 125,
            allowFilters: true,
            title: 'Ministry',
            render: (col, row) => <a target='_blank' href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
        },
        {
            property: 'attendeeName',
            type: 'string',
            width: 130,
            allowFilters: true,
            title: 'Attendee Name',
            render: DefaultGridCellDisplay,
        },
        {
            property: 'mostHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Most Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.mostHelpful === 'string' ? row.values.mostHelpful : undefined
        },
        {
            property: 'leastHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'How to Improve',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.leastHelpful === 'string' ? row.values.leastHelpful : undefined
        },
        {
            property: 'changeAfterTraining',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Biggest Take-Away',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.changeAfterTraining === 'string' ? row.values.changeAfterTraining : undefined
        },
        {
            property: 'comments',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Comments',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
        },
        {
            property: 'value',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Value',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'content',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Content',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'speakers',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Speakers',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'facility',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Facility',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
    )

    gridColumnsWebinar.push(
        {
            property: 'ministryName',
            type: 'string',
            width: 125,
            allowFilters: true,
            title: 'Ministry',
            render: (col, row) => <a target='_blank' href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
        },
        {
            property: 'attendeeName',
            type: 'string',
            width: 130,
            allowFilters: true,
            title: 'Attendee Name',
            render: DefaultGridCellDisplay,
        },
        {
            property: 'mostHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Most Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.mostHelpful === 'string' ? row.values.mostHelpful : undefined
        },
        {
            property: 'leastHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Least Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.leastHelpful === 'string' ? row.values.leastHelpful : undefined
        },
        {
            property: 'changeAfterTraining',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Change After Training',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.changeAfterTraining === 'string' ? row.values.changeAfterTraining : undefined
        },
        {
            property: 'comments',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Comments',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
        },
        {
            property: 'webinarValue',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Value',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
            
        },
        {
            property: 'webinarContent',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Content',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarSpeakers',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Speakers',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarContentActionable',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Content Actionable',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarEffective',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Effective',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarInvolveStaff',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Involve Staff',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarMoreWebinars',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'More Webinars',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarRegistrationEase',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Registration Ease',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarWellManaged',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Well Managed',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarQuestionAndAnswer',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Q And A',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarAccess',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Access',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarConfirmed',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Confirmed',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarAttendAgain',
            type: 'boolean',
            width: 83,
            allowFilters: true,
            title: 'Attend Again',
            render: DefaultGridCellDisplay
        }
    )

    const initialGridState: IGridState = {
        ...defaultGridState,
        dataSource,
        usingLocalData: true,
        columns: gridColumns,
    }

    const initialGridStateWebinar: IGridState = {
        ...defaultGridState,
        dataSource,
        usingLocalData: true,
        columns: gridColumnsWebinar,
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, evals)
    const [gridStateWebinar, gridActionsWebinar] = useGrid(gridReducer, initialGridStateWebinar, evals)

    useEffect(() => {
        areWebinars ? gridActionsWebinar.doFetch() : gridActions.doFetch()
    }, [evals])

    return (
        <>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                {areWebinars ? 
                <Grid state={gridStateWebinar} actions={gridActionsWebinar} style={{ height: '100%' }} />
                :
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
                }
            </div>
        </>
    )
}