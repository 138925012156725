import { useFormikContext } from "formik"
import { RefObject, useCallback, useEffect } from "react"

interface IUseFormikScrollToErrorArgs {
    field: RefObject<HTMLElement>
    name: string
}

export const useFormikScrollToError = ({ field, name }: IUseFormikScrollToErrorArgs) => {
    const formik = useFormikContext()

    const scrollToField = useCallback(() => {
        if (!field.current) return

        // We go fancy...if it's an input we scroll to it AND focus it so the user can begin typing
        if (field.current instanceof HTMLInputElement) {
            field.current.focus()
        } else {
            field.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [field])

    useEffect(() => {
        /* 
            Scroll to this field if it's the first error
            (otherwise we'd get conflicting scroll attemps if more than one element on the page is using this hook)
        */

        const firstErrorFieldName = Object.keys(formik.errors)[0]
        const firstError = Object.values(formik.errors)[0]

        // Is this an array of errors? If so, we need to do some fancy footwork to extract the field name
        // Formik field names for an array field look like [fieldName][index][subFieldName]
        if (Array.isArray(firstError)) {
            // Is this sub-field in the array the first error of sub-field?
            const firstFieldArrayErrorIdx = firstError.findIndex(o => o)
            const firstSubFieldErrorFieldName = Object.keys(firstError[firstFieldArrayErrorIdx])[0]

            // Now we can assemble the field array field name and scroll to this field if it matches
            if (`${firstErrorFieldName}.${firstFieldArrayErrorIdx}.${firstSubFieldErrorFieldName}` === name) {
                scrollToField()
            }
        }
        // Not an array of errors, we can simply compare the error key with the field name
        // Is the first error for this field? Scroll to the field if so.
        else if (firstErrorFieldName === name) {
            scrollToField()
        }

    }, [formik.submitCount])
}