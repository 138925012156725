import React from 'react'
import { IProspectSearchResultModelDocument } from '../open-api'
import { ProspectForm } from './prospect-form'
import moment from 'moment'

interface IProspectDetailGeneralProps {
	prospect: IProspectSearchResultModelDocument
	onProspectUpdated: () => void
}
export const ProspectDetailGeneral = (props: IProspectDetailGeneralProps) => {
	const { prospect, onProspectUpdated } = props

	return (
		<div className='d-flex'>
			<div className='ml-4 my-4 mr-2' style={{ flex: 1 }}>
				<h5>Basic Information</h5>
				<hr className='pb-2' />
				<ProspectForm prospectToEdit={prospect} onSaveFinish={onProspectUpdated} />
			</div>
			<div className='ml-2 my-4 mr-4' style={{ flex: 1 }}>
				<h5>Prospect Stats</h5>
				<hr className='pb-2' />
				<div style={{ fontWeight: 700 }}>Date Added</div>
				<div className='pb-2'>{prospect.dCreated ? moment(prospect.dCreated).format('MM/DD/YYYY') : null}</div>

				{/* <div style={{ fontWeight: 700 }}>Number of Event Invites</div>
				<div>{prospect.eventInviteCount}</div> */}
			</div>
		</div>
	)
}