import { useCallback, useState } from 'react'
import { IDefaultProps } from './component-definitions'
import { Circle, GoogleMap, Marker, useJsApiLoader, InfoBox, InfoWindow, OverlayView, FLOAT_PANE } from '@react-google-maps/api'
import { GOOGLE_MAPS_API_KEY } from "../constants"

interface INonprofitMap extends IDefaultProps {
  center: google.maps.LatLngLiteral
  zoom: number
  locations?: Poi[]
  circleRadius?: number
}

export type Poi ={ key: string, location: google.maps.LatLngLiteral, content: JSX.Element }

export const MapComponent = ({ center, zoom, locations = [], circleRadius }: INonprofitMap) => {

  console.log('center', center)
  console.log('zoom', zoom)
  console.log('circleRadius', circleRadius)

  const [map, setMap] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState<Poi>()

  const containerStyle = {
  width: '100%',
  height: '100%'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    //mapIds: ['5b3625b9c8712b30']
  })

  const onLoad = useCallback(function callback(map) {

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  // const handleMarkerClick = (poi: Poi) => {
  //   console.log('handleMarkerClick', poi)
  //   if (poi)
  //     setSelectedLocation(poi)

  //   return undefined;
  // }

  const setInfoWindowOpen = (arg0: boolean) => {
    console.log('hello')
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      //onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {locations.map((poi: Poi) => <Marker key={poi.key} position={poi.location} onClick={() => setSelectedLocation(poi)} />)}
      {circleRadius && <Circle center={center} radius={circleRadius} options={{strokeColor: '#008ba2', fillColor: '#008ba2'}} />}
      {selectedLocation && 
            <OverlayView 
              mapPaneName={FLOAT_PANE}
              position={new google.maps.LatLng(selectedLocation.location.lat, selectedLocation.location.lng)}
            //   options={{
            //     //closeBoxURL: "",
            //     //enableEventPropagation: true,
            //     pixelOffset: new google.maps.Size(-110, -75),
            //     // boxStyle: {
            //     //     backgroundColor: "white",
            //     //     width: "220px",
            //     //     padding: "8px",
            //     //     borderRadius: "5px",
            //     // }
            // }}
            >
              <div style={{backgroundColor: 'white', width: '220px', padding: '8px', borderRadius: '5px', top: '25px'}}>
                {selectedLocation.content}
              </div>
            </OverlayView> 
      }
    </GoogleMap>
  ) : (
    <></>
  )
}
