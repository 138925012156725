import React, { useContext, useEffect, useState } from 'react'
import { IDefaultProps } from './component-definitions'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import { Loading } from './loading'
import { showModal, uuidv4, hideModal, filterGridListItems, sortListBySorts } from '../services/helpers'
import { Modal } from './modal'

import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { TagForm } from './tag-form'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { SquareDeleteIcon } from './partials'
import { Grid } from './grid'
import { tagDefaultColumns, TagModel } from '../models/tag'
import { IAppState } from '../stores/app-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Helmet } from 'react-helmet'
import { TagIcon } from '../assets'


export const Tags = (props: IDefaultProps) => {
	const appState = useContext(AppStateContext)
	const appActions = useContext(AppActionContext)!

	let resetTagForm: (() => void) | null
	const onModalHidden = () => {
		if (resetTagForm) resetTagForm()
	}

	const [createTag] = useState<{ modalId: string }>({ modalId: uuidv4() })

	const [deleteTag, setDeleteTag] = useState<{ tag?: TagModel, modalId: string }>({ modalId: uuidv4() })

	const fetchTags: GridDataFetch<IAppState> = async (queryState, _appState) => {
		const { tags } = _appState

		if (!tags) return { count: 0, rows: [] }

		let _tags = [...tags]

		if (queryState.sorts) sortListBySorts(_tags, queryState.sorts)
		let rows = _tags.map(tag => tag.toGridListItem())
		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return {
			count: _tags.length,
			rows,
		}
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		columns: tagDefaultColumns,
		usingLocalData: true,
		disabledPagination: true,
		rowSelectEnabled: false,
		rowActionsDisplayType: 'icons',
		dataSource: fetchTags,
		gridActions: [
			{
				id: 'tagManagementActions',
				label: 'Create Tag',
				onClick: () => {
					showModal(createTag.modalId)
				}
			}
		],
		rowActions: {
			deleteTag: {
				id: 'deleteTag',
				action: async (options) => {
					const tag = options.appState.tags?.find(tag => tag.tagId.toString() === options.row.id)

					if (tag) {
						setDeleteTag({ ...deleteTag, tag })
						showModal(deleteTag.modalId)
					}
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Archive Tag'
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [appState.tags])

	if (!appState.tags) {
		return <Loading />
	} else {
		return (
			<React.Fragment>
				<Helmet>
                	<title>Tags</title>
				</Helmet>
				<div className='d-flex flex-column' style={{ height: '100vh' }}>
					<div className='m-2 d-flex align-items-center'>
						<TagIcon style={{ width: '25px', height: '25px' }} />
						<h3 className='ml-2'>Tags</h3>
					</div>
					<Grid state={gridState} actions={gridActions} />
				</div>

				<Modal
					modalId={deleteTag.modalId}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' className='btn btn-danger' disabled={!deleteTag} onClick={async () => {
								if (deleteTag.tag) {
									setDeleteTag({ ...deleteTag, tag: undefined })
									hideModal(deleteTag.modalId)
									const loadingId = registerLoadingTask()
									await appActions.deleteTag(deleteTag.tag.tagId)
									await appActions.fetchTagsSummary()
									deregisterLoadingTask(loadingId)
								}

							}}>{deleteTag.tag?.taggedCount !== undefined && deleteTag.tag?.taggedCount !== null && deleteTag.tag.taggedCount > 0 ? 'Archive' : 'Delete'}</button>
						</React.Fragment>
					}
				>
					{deleteTag.tag?.taggedCount !== undefined && deleteTag.tag?.taggedCount !== null && deleteTag.tag.taggedCount > 0 ?
						<div>
							<b>{deleteTag.tag.taggedCount}</b> {deleteTag.tag.taggedCount === 1 ? 'item is' : 'items are'} tagged with this tag.
							<br></br>
							This tag cannot be deleted, only archived.
							<br></br>
							<br></br>
							Are you sure you want to archive <b>{deleteTag.tag?.tag}</b>?
						</div>
						:
						<div>Are you sure you want to delete <b>{deleteTag.tag?.tag}</b>?</div>
					}
				</Modal>

				<Modal
					modalTitle='New Tag'
					modalId={createTag.modalId}
					onModalHidden={onModalHidden}
				>
					<TagForm onTagSaved={() => hideModal(createTag.modalId)} setFormikProps={(formikProps) => resetTagForm = formikProps ? formikProps.resetForm : null} />
				</Modal>
			</React.Fragment>
		)
	}
}