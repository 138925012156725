import React, { useRef } from 'react'
import IMask from 'imask'
import { useField } from 'formik'
import clsx from 'clsx'
import { IField } from './types'
import { MaskedInput } from './masked-input'
import { useFormikScrollToError } from '../../hooks/use-formik-scroll-to-error'
import { TooltipInfo } from '../partials'

interface IFormikMaskedInputProps {
    field: IField
    mask: IMask.AnyMaskedOptions
}

export const FormikMaskedInput = ({ field, mask }: IFormikMaskedInputProps) => {
    const [formikField, meta, helpers] = useField<string>(field)
    const { error, touched } = meta

    const showInvalid = error && touched

    const fieldRef = useRef<HTMLInputElement>(null)
    useFormikScrollToError({ field: fieldRef, name: formikField.name })

    return (
        <div className={`form-group ${showInvalid && 'is-invalid'}`} style={{ zIndex: 1 }}>
            <label htmlFor={formikField.name}>
                {field.label}
                {field.labelTooltip ? <TooltipInfo style={{ marginBottom: 4 }} tooltipText={field.labelTooltip} /> : ''}
            </label>

            <div style={{ position: 'relative' }}>
                <fieldset disabled={field.disabled} style={{ border: 'none', margin: 0, padding: 0 }}>
                    <MaskedInput
                        id={formikField.name}
                        onBlur={formikField.onBlur}
                        className={clsx('form-control', showInvalid && 'is-invalid')}
                        mask={mask}
                        setValue={helpers.setValue}
                        value={formikField.value}
                        ref={fieldRef}
                    />
                </fieldset>
            </div>

            <div className='invalid-feedback' style={{ height: '19.4px', display: 'block' }} >{showInvalid && error}</div>
        </div>
    )
}