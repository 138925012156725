import { IGridState, IGridColumn, IGridDataUpdate, GridDataFetch, IGridListItem, IGridRowAction, IGridAction } from "./grid-definitions"
import { IQueryState } from './api-actions'
import { sortListByProperty } from "../services/helpers"

export enum GridActionType {
	loading = 'loading',
	doneLoading = 'doneLoading',
	setGridDataSource = 'setGridDataSource',
	setRowActions = 'setRowActions',
	setFooterActions = 'setFooterActions',
	setColumns = 'setColumns',
	updateRow = 'updateRow',
	toggleAllRowsSelected = 'toggleAllRowsSelected',
	rowPressed = 'rowPressed',
	setGridElement = 'setGridElement',
	setActiveFilterPopover = 'setActiveFilterPopover',
	setQuery = 'setQuery',
	updateData = 'updateData',
	setHighlightedRows = 'setHighlightedRows',
	deselectAllRows = 'deselectAllRows',
	resetFiltersSortsAndRowSelections = 'resetFiltersSortsAndRowSelections',
	setGridAction = 'setGridAction',
	toggleUsingLocalData = 'toggleUsingLocalData',
	toggleRowGroupExpandCollapse = 'toggleRowGroupExpandCollapse',
}

interface IPayloadLessAction {
	type: GridActionType.loading | GridActionType.doneLoading | GridActionType.deselectAllRows
}

interface IToggleAllRowsSelected {
	type: GridActionType.toggleAllRowsSelected
	payload?: boolean
}

interface ISetGridDataSourceAction {
	type: GridActionType.setGridDataSource
	payload: GridDataFetch<any>
}

interface ISetRowActionsAction {
	type: GridActionType.setRowActions
	payload: { [id: string]: IGridRowAction }
}

interface ISetFooterActionsAction {
	type: GridActionType.setFooterActions
	payload: JSX.Element[]
}


interface ISetColumnsAction {
	type: GridActionType.setColumns
	payload: IGridColumn[]
}

interface IUpdateRowAction {
	type: GridActionType.updateRow
	payload: IGridListItem
}

interface IRowPressedAction {
	type: GridActionType.rowPressed
	payload: IGridListItem
}

interface ISetGridElementAction {
	type: GridActionType.setGridElement
	payload: HTMLDivElement
}

interface ISetActiveFilterPopoverAction {
	type: GridActionType.setActiveFilterPopover
	payload: HTMLDivElement | null
}

interface ISetQueryAction {
	type: GridActionType.setQuery
	payload: IQueryState
}

interface IUpdateDataAction {
	type: GridActionType.updateData
	payload: IGridDataUpdate
}

interface ISetHighlightedRowsAction {
	type: GridActionType.setHighlightedRows
	payload: string[]
}

interface IResetGridFiltersSortsAndRowSelectionsAction {
	type: GridActionType.resetFiltersSortsAndRowSelections
	payload: {
		filterIdsToPreserve?: string[]
	}
}

interface ISetGridActionAction {
	type: GridActionType.setGridAction
	payload: IGridAction
}

interface IToggleUsingLocalDataAction {
	type: GridActionType.toggleUsingLocalData,
	payload: boolean
}

interface IToggleRowGroupExpandCollapse {
	type: GridActionType.toggleRowGroupExpandCollapse,
	payload: {
		rowGroupId: string
	}
}

export type GridActions =
	IPayloadLessAction |
	ISetGridDataSourceAction |
	ISetRowActionsAction |
	ISetFooterActionsAction |
	ISetColumnsAction |
	IUpdateRowAction |
	IRowPressedAction |
	ISetGridElementAction |
	ISetActiveFilterPopoverAction |
	ISetQueryAction |
	IUpdateDataAction |
	ISetHighlightedRowsAction |
	IToggleAllRowsSelected |
	IResetGridFiltersSortsAndRowSelectionsAction |
	ISetGridActionAction |
	IToggleUsingLocalDataAction |
	IToggleRowGroupExpandCollapse

export const gridReducer = (state: IGridState, action: GridActions): IGridState => {
	let newState = { ...state }

	newState.version = (newState.version || 0) + 1

	//console.log('grid action', newState.id, newState.version, action.type)

	switch (action.type) {
		case GridActionType.loading:
			newState = { ...newState, loading: true }
			break
		case GridActionType.doneLoading:
			newState = { ...newState, loading: false }
			break
		case GridActionType.setGridDataSource:
			newState = { ...newState, dataSource: action.payload }
			break
		case GridActionType.setRowActions:
			newState = { ...newState, rowActions: action.payload }
			break
		case GridActionType.setFooterActions:
			newState = { ...newState, footerActions: action.payload }
			break
		case GridActionType.setGridElement:
			newState = { ...newState, gridElement: action.payload }
			break
		case GridActionType.setActiveFilterPopover:
			newState = { ...newState, activeFilterPopover: action.payload }
			break
		case GridActionType.setColumns:
			newState = { ...newState, columns: sortListByProperty(action.payload, 'sortOrder') }
			break
		case GridActionType.updateRow:
			const _rows = updateRow([...state.rows], action.payload)
			newState = { ...state, allRowsSelected: _rows.filter(r => r.selected).length === _rows.length, rows: _rows }
			if (action.payload.selected) newState.selectedRows = { ...newState.selectedRows, [action.payload.id]: action.payload }
			if (!action.payload.selected) delete newState.selectedRows[action.payload.id]
			break
		case GridActionType.rowPressed:
			const _rowPressedNewRow = { ...action.payload, selected: !action.payload.selected }
			const _rowPressedRows = updateRow([...state.rows], _rowPressedNewRow)
			newState = { ...state, allRowsSelected: _rowPressedRows.filter(r => r.selected).length === _rowPressedRows.length, rows: _rowPressedRows }
			if (_rowPressedNewRow.selected) newState.selectedRows = { ...newState.selectedRows, [_rowPressedNewRow.id]: _rowPressedNewRow }
			if (!_rowPressedNewRow.selected) delete newState.selectedRows[_rowPressedNewRow.id]
			break
		case GridActionType.toggleAllRowsSelected:
			const _toggleAllRowsSelected = action.payload !== undefined ? action.payload : !newState.allRowsSelected
			newState = {
				...newState,
				allRowsSelected: action.payload !== undefined ? action.payload : !newState.allRowsSelected,
				rows: toggleAllRowsSelected(newState.rows, _toggleAllRowsSelected)
			}

			if (_toggleAllRowsSelected) {
				newState.selectedRows = {
					...newState.selectedRows,
					...newState.rows.reduce((_selectedRows, row) => {
						_selectedRows[row.id] = row
						return _selectedRows
					}, {} as IGridState['selectedRows'])
				}
			} else {
				// Remove all the current page's rows from the selected rows list
				newState.rows.forEach(row => delete newState.selectedRows[row.id])
			}

			break
		case GridActionType.setQuery:
			newState = { ...newState, queryState: action.payload }
			break
		case GridActionType.updateData:
			const _updateDataSelectedRowIds = Object.keys(state.selectedRows)
			action.payload.rows.forEach(row => {
				if (_updateDataSelectedRowIds.includes(row.id)) row.selected = true
			})
			newState = {
				...newState,
				loading: false,
				rows: action.payload.rows,
				pages: Math.ceil((action.payload.count || state.queryState.itemsPerPage) / state.queryState.itemsPerPage),
				totalCount: action.payload.count,
				lastFetch: new Date(),
				allRowsSelected: action.payload.rows.filter(o => o.selected).length === action.payload.rows.length,
			}
			break
		case GridActionType.setHighlightedRows:
			newState = { ...newState, highlightedRows: action.payload }
			break
		case GridActionType.deselectAllRows:
			newState = { ...newState, rows: deselectAllRows([...newState.rows]), selectedRows: {} }
			break
		case GridActionType.resetFiltersSortsAndRowSelections:
			newState = {
				...newState,
				queryState: {
					...newState.queryState,
					filters: action.payload.filterIdsToPreserve ? [...newState.queryState.filters?.filter(f => action.payload.filterIdsToPreserve?.includes(f.id)) || []] : [],
					sorts: undefined
				},
				allRowsSelected: false,
				rows: deselectAllRows([...newState.rows]),
				selectedRows: {},
			}
			break
		case GridActionType.setGridAction:
			newState = {
				...newState,
				gridActions: (() => {
					const i = newState.gridActions?.findIndex(o => o.id === action.payload.id)
					if (i !== undefined && i > -1) {
						newState.gridActions?.splice(i, 1, action.payload) // Splicing retains the order
					}
					return newState.gridActions
				})()
			}
			break
		case GridActionType.toggleUsingLocalData:
			newState = {
				...newState,
				usingLocalData: action.payload,
				disabledPagination: action.payload,
			}
			break
		case GridActionType.toggleRowGroupExpandCollapse:
			// Find all rows with the same row group and toggle their collapsed property
			const toggleRowGroupExpandCollapseRows = newState.rows.filter(o => o.rowGroup?.id === action.payload.rowGroupId)
			const currentlyCollapsed = Boolean(toggleRowGroupExpandCollapseRows[0].rowGroup?.collapsed)
			toggleRowGroupExpandCollapseRows.forEach(o => {
				if (o.rowGroup) o.rowGroup.collapsed = !currentlyCollapsed
			})

			console.log('toggleRowGroupExpandCollapse', toggleRowGroupExpandCollapseRows)

			break
	}

	return newState
}

const updateRow = (_rows: IGridListItem[], _row: IGridListItem): IGridListItem[] => {
	const row = _rows.find(row => row.id === _row.id)
	if (!row) throw new Error('GridStateError: attempted to update a row that does not exist on this Grid.')
	let rowIdx = _rows.indexOf(row)
	_rows.splice(rowIdx, 1, _row)

	return _rows
}

const toggleAllRowsSelected = (rows: IGridListItem[], selected: boolean): IGridListItem[] => {
	const tempRows = [...rows]
	tempRows.forEach(row => {
		updateRow(tempRows, { ...row, selected })
	})
	return tempRows
}

const deselectAllRows = (rows: IGridListItem[]): IGridListItem[] => {
	rows.forEach(r => r.selected = false)
	return rows
}