import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Grid } from './grid'
import { GridDataFetch, IGridState, IGridListItem, GridUserInteractionStateKey, IGridAction } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { IDefaultProps } from './component-definitions'
import { ReactComponent as Tag } from '../assets/tag.svg'
import { uuidv4, showModal, hideModal, openUrlInNewTab, onModalHidden } from '../services/helpers'
import { Modal } from './modal'
import { TagsList } from './tags-list'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { Loading } from './loading'
import { IQueryState, IFilter } from '../stores/api-actions'
import { IAppState } from '../stores/app-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { NoteForm } from './note-form'
import { IMinistryContactSummaryModelDocument, IEventFilterFieldsDataModelDocument, IMergeMinistriesPostModelDocument, ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { useHTTPRequestUiWrapper, useModal, useMinistryManagementDefaultColumns } from '../services/hooks'
import { MinistryManagementFilterByEvents } from './ministry-management-filter-by-events'
import { MinistryManagementmergeMinistries } from './ministry-management-merge-ministries'
import { MinistryManagementCreateCallList } from './ministry-management-create-call-list'
import { navigate } from '@reach/router'
import { isNullOrUndefined } from 'util'
import { MinistryModel } from '../models/ministry'
import { Path } from './dashboard'
import { Helmet } from 'react-helmet'
import { ArchiveIcon, BuildingIcon, JournalPlusIcon, IncognitoIcon, InfoCircleIcon } from '../assets'
import { MinistryManagementAddToExistingCallList } from './ministry-management-add-to-existing-call-list'

interface IMinistryManagementProps extends IDefaultProps { }
export const MinistryManagement = (props: IMinistryManagementProps) => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()
	const [selectedMinistryState, setSelectedMinistryState] = useState<{ row: IGridListItem }>()
	const [showNoteForm, setShowNoteForm] = useState(false)
	const showingArchived = props.path === Path['archived-ministries']
	const [helpModal, showHideHelpModal] = useModal()

	const [tagsModalState, setTagsModalState] = useState<{
		modalId: string
		modalTitle: string
		startingTags: number[]
		endingTags: number[]
	}>({
		modalId: uuidv4(),
		modalTitle: 'Tags',
		startingTags: [],
		endingTags: [],
	})

	const [addNoteModalState, setAddNoteModalState] = useState<{
		modalId: string
		modalTitle: string
		ministryId: number
	}>({
		modalId: uuidv4(),
		modalTitle: 'Add Note',
		ministryId: 0
	})

	const setSelectedTags = (tags: number[]) => setTagsModalState({ ...tagsModalState, endingTags: tags })
	let resetTagList: () => void
	onModalHidden(tagsModalState.modalId, () => { if (resetTagList) resetTagList() })

	const [filterByEventsModalState, showHideFilterByEventsModal] = useModal()
	const [filterByEventsState, setFilterByEventsState] = useState<{
		eventFilterId: string
		filterByEventsOptions?: IEventFilterFieldsDataModelDocument
		eventFilter: IFilter
	}>({
		eventFilterId: 'ministry-management-filter-by-events',
		eventFilter: {
			id: 'ministry-management-filter-by-events',
			operator: 'filter-by-event',
			property: 'ministry-management-filter-by-events',
			filterByEventOptions: {},
			value: 'ministry-management-filter-by-events',
		}
	})
	const fetchMinistries: GridDataFetch<IAppState> = async (queryState, _appState) => {
		/*
			Special case for the ministries query: check to see if there is an events filter.

			This filter gets passed as separate options to the endpoint.

			The endpoint's 'filter' param does not support this filter -
			it's stored in the same interface as other filters on the frontend to facilitate easy of integration with grid queries based on the QueryState interface.

			We need to not include the events filter in the general filters list.
		*/
		const _filters = [...queryState.filters || []]
		const eventFilter = _filters?.find(f => f.operator === 'filter-by-event' && !!f.enabled)

		const _eventFiltersToRemove = _filters?.filter(f => f.operator === 'filter-by-event')
		_eventFiltersToRemove?.forEach(f => {
			const idx = _filters?.indexOf(f)
			if (idx) _filters?.splice(idx, 1)
		})

		// We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing JSON into URL query params
		const filters = (_filters ? JSON.stringify([..._filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
		const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

		const ministriesQuery = await makeHTTPRequestWithUi({
			request: appActions.MinistriesApi.apiMinistriesGet(
				(queryState.page - 1) * queryState.itemsPerPage,
				queryState.itemsPerPage,
				sorts,
				filters,
				eventFilter?.filterByEventOptions?.eventsRegisteredFor?.eventIds,
				eventFilter?.filterByEventOptions?.eventsRegisteredFor?.registeredForEverySelectedEvent,
				eventFilter?.filterByEventOptions?.eventsRegisteredFor?.onlyIncludeMinistriesThatAttended,
				eventFilter?.filterByEventOptions?.eventsNotRegisteredFor?.eventIds,
				eventFilter?.filterByEventOptions?.consultingRegisteredFor?.consultingQuarters,
				eventFilter?.filterByEventOptions?.consultingNotRegisteredFor?.consultingQuarters,
				eventFilter?.filterByEventOptions?.consultingRegisteredFor?.registeredForEverySelectedQuarter,
				eventFilter?.filterByEventOptions?.consultingRegisteredFor?.onlyIncludeMinistriesThatAttended,
			),
			disableLoading: true,
			disableSuccessToast: true,
			toastErrorMessage: 'There was an error retrieving Ministries.'
		})

		return {
			rows: ministriesQuery.data.data?.map(MinistryModel.toListItem) || [],
			count: ministriesQuery.data.totalCount
		}
	}

	const [mergeMinistriesModalState, showHideMergeMinistriesModal] = useModal()
	const [ministriesToMerge, setMinistriesToMerge] = useState<IMergeMinistriesPostModelDocument>()
	const [ministriesToMergeConfirmations, setMinistriesToMergeConfirmations] = useState({
		showConfirmationOne: false,
		showConfirmationTwo: false
	})

	const [addToCallListModalState, showHideAddToCallListModal] = useModal()
	const [createCallListModalState, showHideCreateCallListModal] = useModal()
	const [addToExistingCallListModalState, showHideAddToExistingCallListModal] = useModal()

	let queryState: IQueryState | undefined = appState.currentUserSessionState?.gridUserInteractionStates?.[showingArchived ? GridUserInteractionStateKey.ArchivedMinistries : GridUserInteractionStateKey.MinistryManagement]?.queryState

	/*
		Set the archived ministry filter depending on whether we're handling archived or active ministries
	*/
	if (!queryState) queryState = { ...defaultGridState.queryState }
	if (!queryState.filters) queryState.filters = []
	const archivedFilterIdx = queryState.filters?.findIndex(o => o.id === 'bArchived')
	if (archivedFilterIdx !== -1) {
		queryState.filters[archivedFilterIdx] = { ...queryState.filters[archivedFilterIdx], value: showingArchived ? true : false, enabled: true }
	} else {
		queryState.filters.push({
			id: 'bArchived',
			enabled: true,
			value: showingArchived,
			operator: '==',
			property: 'bArchived',
			hidden: true,
		})
	}

	const [disableTagsFooterBtns, setDisableTagsFooterBtns] = useState<boolean>(false)

	const initialGridState: IGridState = {
		...defaultGridState,
		loading: true,
		respectGlobalCommunityFilter: true,
		queryState,
		columns: useMinistryManagementDefaultColumns(showingArchived), // Per Brock on why he declares columns in a separate hooks.tsx file: "I started declaring them separately but then realized it was an over-separation of concerns. Those column declarations were unlikely to ever be re-used elsewhere and unnecessarily increased file "context switching". So I transitioned to almost entirely inline column definitions."
		dataSource: fetchMinistries,
		tagType: 'MINISTRYID',
		userSessionStateKey: showingArchived ? GridUserInteractionStateKey.ArchivedMinistries : GridUserInteractionStateKey.MinistryManagement,
		overrideInitialStateValues: {
			queryState
		},
		gridActions:
			[
				...showingArchived ? [] :
					[
						{
							id: 'filterByEvents',
							label: 'Filter by Events',
							onClick: () => showHideFilterByEventsModal(true),
							buttonClass: (gridState) => {
								const eventFilter = gridState.queryState.filters?.find(f => f.operator === 'filter-by-event')
								if (eventFilter && eventFilter.enabled) {
									return 'btn-primary'
								} else {
									return 'btn-light'
								}
							}
						},
						{
							id: 'mergeMinistries',
							label: 'Merge Ministries',
							onClick: ({ rows }) => {
								if (rows.filter(row => row.selected).length >= 2) {
									showHideMergeMinistriesModal(true)
								} else {
									appActions.addAlert({ id: uuidv4(), body: 'Please select at least 2 ministries to merge.', title: 'Merge Ministries' })
								}
							},
						},
					] as IGridAction[],
				{
					id: 'createCallList',
					label: 'Add To Call List',
					onClick: ({ rows }) => {
						if (rows.filter(row => row.selected).length >= 1) {
							showHideAddToCallListModal(true)
						} else {
							appActions.addAlert({ id: uuidv4(), body: 'Please select at least 1 ministry.' })
						}
					},
				},
			],

		rowDoubleClicked: async (row) => {
			navigate(`/ministry-info/${row.id}`)
		},
		rowActions: {
			ministryInfo: {
				id: 'ministryInfo',
				action: async (options) => options.e.stopPropagation(),
				icon: <BuildingIcon />,
				tooltipText: 'View Ministry',
				url: (row) => `/ministry-info/${row.id}`,
			},
			addNote: {
				id: 'addNote',
				action: async (options) => {
					const { e, row } = options

					e.stopPropagation()

					setSelectedMinistryState({ ...selectedMinistryState, row })
					setAddNoteModalState({ ...addNoteModalState, modalTitle: 'Add Note to ' + row.values.ministryName, ministryId: selectedMinistryState ? parseInt(selectedMinistryState?.row.id) : 0 })
					showModal(addNoteModalState.modalId)
				},
				icon: <JournalPlusIcon />,
				tooltipText: 'Add Note'
			},
			impersonate: {
				id: 'impersonate',
				action: async (options) => {
					const { e, row, appActions } = options

					e.stopPropagation()
					openUrlInNewTab(row.values.impersonationUrl?.toString() ?? '')					
				},
				icon: <IncognitoIcon />,
				tooltipText: 'Impersonate User',
				disabled: (row) => !row.values.primaryContactSubjectId
			},
			manageTags: {
				id: 'manageTags',
				action: async (options) => {
					const { e, row, appActions: _appActions, appState: _appState } = options

					e.stopPropagation()

					const taskId = registerLoadingTask()

					const ministryTagsQuery = await _appActions.fetchMinistryTagJoinObjects(parseInt(row.id))
					deregisterLoadingTask(taskId)

					if (ministryTagsQuery.error) {

					} else {
						const ministryTags = _appState.tags?.filter(tag => ministryTagsQuery.content.find(tagJoinObject => tagJoinObject.tagId === tag.tagId)) || []
						setSelectedMinistryState({ ...selectedMinistryState, row })
						setTagsModalState({ ...tagsModalState, modalTitle: `Tags for ${row.values['ministryName']}`, startingTags: ministryTags.map(tag => tag.tagId), endingTags: ministryTags.map(tag => tag.tagId) })
						showModal(tagsModalState.modalId)
					}
				},
				icon: <Tag />,
				tooltipText: 'Manage Tags'
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	const addRemoveTagsFromSelectedMinistry = async () => {
		if (!selectedMinistryState) return

		const ministryId = parseInt(selectedMinistryState.row.id)

		// Every tag that was in the final list but wasn't in the initial list
		const addedTags = tagsModalState.endingTags.filter(endingTagId => !tagsModalState.startingTags.find(startingTagId => startingTagId === endingTagId))
		// Every tag that was in the initial list, but isn't in the final list
		const removedTags = tagsModalState.startingTags.filter(startingTagId => !tagsModalState.endingTags.find(endingTagId => startingTagId === endingTagId))

		const promises: Promise<any>[] = []
		addedTags.forEach(tagId => promises.push(appActions.addMinistryToTag(tagId, ministryId)))
		removedTags.forEach(tagId => promises.push(appActions.removeMinistryFromTag(tagId, ministryId)))

		hideModal(tagsModalState.modalId)

		if (promises.length) {
			gridActions.setLoading(true)
			await Promise.all(promises)
			await appActions.fetchTagsSummary()
			gridActions.doFetch()
		}
	}

	const [ministryContacts, setMinistryContacts] = useState<IMinistryContactSummaryModelDocument[]>()
	useEffect(() => {
		if (selectedMinistryState && showNoteForm) {
			makeHTTPRequestWithUi({ request: appActions.MinistryContactsApi.apiMinistriesIdContactsGet(parseInt(selectedMinistryState.row.id)), disableSuccessToast: true })
				.then(results => {
					setMinistryContacts(results.data)
				})
		}

		// eslint-disable-next-line
	}, [showNoteForm])

	// Any time the grid filters change, find the event filter (this way we can retrieve the initial value for the event filter component from the user's session state)
	useEffect(() => {
		//console.log('teh filters', [...gridState.queryState.filters || []])
		const eventFilter = gridState.queryState.filters?.find(filter => filter.id === filterByEventsState.eventFilterId)
		if (eventFilter) setFilterByEventsState({ ...filterByEventsState, eventFilter })
	}, [gridState.queryState.filters])

	const involvementCounts = {
		seminars: 2,
		workshops: 3,
		smallGroupSessions: 1,
		pre2007Grants: 1,
		post2007Grants: 2,
		consultingHours: 10,
		groupLearningHours: 2,
		maConsultingHours: 1,
		webinars: 1
	}

	if (!gridState) {
		return <Loading />
	} else {
		return (
			<React.Fragment>
				<Helmet>
					<title>{showingArchived ? 'Archived' : 'Active'} Ministries</title>
				</Helmet>

				<div className='d-flex flex-column vh-100'>
					<div className='m-2 d-flex align-items-center justify-content-between'>
						<div className='m-2 d-flex align-items-center'>{showingArchived ?
							<ArchiveIcon style={{ width: '25px', height: '25px' }} />
							:
							<BuildingIcon style={{ width: '25px', height: '25px' }} />
						}
						<h3 className='ml-2'>{showingArchived ? 'Archived' : 'Active'} Ministries</h3></div>
						<div className='d-flex'><button type='button' className='btn btn-info btn-sm icon-right ml-1' onClick={() => showHideHelpModal(true)}>Help <InfoCircleIcon /></button></div>
					</div>
					<Grid state={gridState} actions={gridActions} style={{ flex: 1, height: 'unset' }} />
				</div>


				{/* Edit tags for the selected row */}
				<Modal
					modalId={tagsModalState.modalId}
					modalTitle={tagsModalState.modalTitle}
					allowOverflow={true}
					footer={
						<React.Fragment>
							<button type="button" className="btn btn-secondary" disabled={disableTagsFooterBtns} data-dismiss="modal">Cancel</button>
							<button type="button" className="btn btn-primary" disabled={disableTagsFooterBtns} onClick={addRemoveTagsFromSelectedMinistry}>Save changes</button>
						</React.Fragment>
					}
				>
					<TagsList
						tags={appState.tags?.filter(tag => tag.tableKey === 'MINISTRYID' && !tag.isArchived) || []}
						selectedTagIds={tagsModalState.endingTags}
						setSelectedTagIds={setSelectedTags}
						setResetList={(reset) => resetTagList = reset}
						tagType={'MINISTRYID'}
						disableFooterButtons={(disable) => setDisableTagsFooterBtns(disable)}
					/>
				</Modal>

				<Modal
					modalTitle={addNoteModalState.modalTitle}
					modalId={addNoteModalState.modalId}
					onModalShow={() => {
						setShowNoteForm(true)
					}}
					onModalHide={() => {
						setShowNoteForm(false)
					}}
				>
					{showNoteForm && !!ministryContacts ?
						<NoteForm
							ministryId={selectedMinistryState ? parseInt(selectedMinistryState.row.id) : 0}
							//note={selectedNote}
							afterSave={async () => {
								hideModal(addNoteModalState.modalId)
							}}
							ministryContacts={ministryContacts}
						/>
						:
						null
					}
				</Modal>

				<Modal
					{...filterByEventsModalState}
					modalTitle='Ministry Event Filters'
					size='xl'
					_onModalShown={async () => {
						const request = await makeHTTPRequestWithUi({ request: appActions.MinistriesApi.apiMinistriesGetEventFilterFieldsDataGet(((appState.globalCommunityContext && appState.globalCommunityContext.branchId) || undefined)), disableSuccessToast: true, toastErrorMessage: `There was an error retrieving 'Filter by Events' data.` })
							setFilterByEventsState({ ...filterByEventsState, filterByEventsOptions: request.data })
					}}
				>
					{filterByEventsState.filterByEventsOptions ?
						<MinistryManagementFilterByEvents
							filterByEventsOptions={filterByEventsState.filterByEventsOptions}
							eventsFilter={filterByEventsState.eventFilter}
							updateEventsFilter={(eventFilter) => gridActions.updateColumnFilters([eventFilter])}
							closeModal={() => showHideFilterByEventsModal(false)}
						/>
						:
						null
					}
				</Modal>

				<Modal
					{...mergeMinistriesModalState}
					_onModalHidden={() => {
						setMinistriesToMerge(undefined)
						setMinistriesToMergeConfirmations({ showConfirmationOne: false, showConfirmationTwo: false })
					}}
					modalTitle='Ministry Merge'
					size='xxl'
					dismissible={false}
					footer={
						<React.Fragment>
							{!ministriesToMergeConfirmations.showConfirmationOne && !ministriesToMergeConfirmations.showConfirmationTwo ?
								<button className='btn btn-primary' disabled={!ministriesToMerge} onClick={() => setMinistriesToMergeConfirmations({ ...ministriesToMergeConfirmations, showConfirmationOne: true })}>Merge Ministries</button>
								:
								null
							}
							{ministriesToMergeConfirmations.showConfirmationOne && !ministriesToMergeConfirmations.showConfirmationTwo ?
								<button className='btn btn-warning' disabled={!ministriesToMerge} onClick={() => setMinistriesToMergeConfirmations({ showConfirmationOne: true, showConfirmationTwo: true })}>Confirm</button>
								:
								null
							}
							{ministriesToMergeConfirmations.showConfirmationTwo ?
								<button className='btn btn-danger' disabled={!ministriesToMerge} onClick={async () => {
									await makeHTTPRequestWithUi({
										request: appActions.MinistriesApi.apiMinistriesMergePost(ministriesToMerge),
										toastErrorMessage: 'There was an error merging the ministries.',
										toastSuccessMessage: 'Ministries successfully merged.'
									})
									showHideMergeMinistriesModal(false)
									gridActions.doFetch()
								}}>Start Merge</button>
								:
								null
							}
							<button className='btn btn-secondary' onClick={() => { showHideMergeMinistriesModal(false) }}>Cancel</button>
						</React.Fragment>
					}
				>
					{mergeMinistriesModalState.show ?
						<MinistryManagementmergeMinistries
							ministriesIdsToMerge={gridState.rows.filter(row => row.selected).map(row => parseInt(row.id))}
							setWinningMinistryId={(winningMinistryId) => {
								setMinistriesToMerge({
									winningMinistryId: parseInt(winningMinistryId),
									losingMinistryIds: gridState.rows.filter(row => row.selected && row.id !== winningMinistryId).map(row => parseInt(row.id))
								})
							}}
						/>
						:
						null
					}
					{ministriesToMergeConfirmations.showConfirmationOne ?
						<div className='bg-warning border border-warning rounded m-3 p-2'>
							<p>Are you sure you want to merge {gridState.rows.filter(row => ministriesToMerge?.losingMinistryIds?.includes(parseInt(row.id))).length === 1 ? 'this ministry' : 'these ministries'}: <b>{gridState.rows.filter(row => ministriesToMerge?.losingMinistryIds?.includes(parseInt(row.id))).map(row => row.values.ministryName).join(', ')}</b></p>
							<p>Into <b>{`${gridState.rows.find(row => parseInt(row.id) === ministriesToMerge?.winningMinistryId)?.values.ministryName}`.trim()}</b>'s record?</p>
							This is not reversible!
						</div>
						:
						null
					}
					{ministriesToMergeConfirmations.showConfirmationTwo ?
						<div className='bg-danger border border-danger rounded m-3 p-2 text-light'>
							Are you sure you REALLY sure you want to do this? Last chance...
						</div>
						:
						null
					}
				</Modal>


				<Modal
					{...addToCallListModalState}
					modalTitle='Add to Call List'
					dismissible={false}
				>
					<div>
						<p>Would you like to add the selected ministries to an existing call list or create a new one?</p>

						<div>
							<button
								className='btn btn-primary btn-large mr-2'
								onClick={() => {
									showHideAddToCallListModal(false)
									showHideCreateCallListModal(true)
								}}
							>
								Create Call List
							</button>
							<button
								className='btn btn-primary btn-large'
								onClick={() => {
									showHideAddToCallListModal(false)
									showHideAddToExistingCallListModal(true)
								}}
							>
								Add to Existing Call List
							</button>
						</div>
					</div>
				</Modal>

				<Modal
					{...createCallListModalState}
					modalTitle='Create Call List'
					size='xl'
					dismissible={false}
					_onModalHidden={gridActions.deselectAllRows}
				>
					{createCallListModalState.show ?
						<MinistryManagementCreateCallList
							onCancel={() => showHideCreateCallListModal(false)}
							onSave={() => showHideCreateCallListModal(false)}
							ministryIds={Object.values(gridState.selectedRows).map(row => parseInt(row.id))}
						/>
						: null
					}
				</Modal>

				<Modal
					{...addToExistingCallListModalState}
					modalTitle='Add to Existing Call List'
					size='lg'
					dismissible={false}
					_onModalHidden={gridActions.deselectAllRows}
				>
					{addToExistingCallListModalState.show ?
						<MinistryManagementAddToExistingCallList
							onSave={() => showHideAddToExistingCallListModal(false)}
							ministryIds={Object.values(gridState.selectedRows).map(row => parseInt(row.id))}
						/>
						: null
					}
				</Modal>
				<Modal
				{...helpModal}
				modalTitle='Ministries Help'
				size={'lg'}
			>
				<h4>Ministry Status</h4>
				<p><strong>Current ministries</strong> have attended training or coaching/consulting in the last 18 months or have created an account within the last 12 months. This is a narrower number than Active.</p>
				<p><strong>Active ministries</strong> have an account that has not yet been archived and may or may not have attended training or coaching/consulting. They remain active by updating their profile OR by registering for an event (even if they do not attend) within the last 18 months. This is a broader number and includes Current ministries.</p> 
				<p><strong>Archived ministries</strong> have an account older than 12 months, have not updated their profile, and have not attended events or coaching/consulting in 18 months or more. Archived ministries move back to Active if they update their profile or register for an event.</p>
				<h4>Involvement Calculation</h4>
				<p>A ministry's Involvement Score is calculated by adding together the results of the following calculations.</p>
				<ul>
					<li>Total Seminars attended multiplied by {appState.involvementMultipliers?.seminarMultiplier}.
						<ul><li>Note: The Seminar event type has been retired since 2019.</li></ul>
					</li>
					<li>Workshops attended multiplied by {appState.involvementMultipliers?.workshopMultiplier}.</li>
					<li>Webinars attended multiplied by {appState.involvementMultipliers?.webinarMultiplier}.</li>
					<li>Small Group Sessions attended multiplied by {appState.involvementMultipliers?.smallGroupMultiplier}.
						<ul><li>Note: The Small Group Session event type was retired in 2023. It has been replaced by Group Learning events.</li></ul>
					</li>
					<li>Grant Requests Post-2007 multiplied by {appState.involvementMultipliers?.grantPost2007Multiplier}.</li>
					<li>Grant Requests Pre-2007 multiplied by {appState.involvementMultipliers?.grantPre2007Multiplier}.</li>
					<li>Coaching/Consulting Appointment hours multiplied by {appState.involvementMultipliers?.consultingMultiplier}.</li>
					<li>Group Learning hours multiplied by {appState.involvementMultipliers?.groupLearningMultiplier}.</li>
					<li>Mission Accelerate Appointment hours multiplied by {appState.involvementMultipliers?.maConsultingMultiplier}.</li>
				</ul>
				<p className='mt-3'>For example: If a ministry had the following stats:</p>
				<ul>
					<li>Attended {involvementCounts.seminars} seminars</li>
					<li>Attended {involvementCounts.workshops} workshops</li>
					<li>Attended {involvementCounts.webinars} webinars</li>
					<li>Attended {involvementCounts.smallGroupSessions} Small Group Session</li>
					<li>Completed {involvementCounts.post2007Grants} successful Grant Request Post-2007</li>
					<li>Completed {involvementCounts.pre2007Grants} successful Grant Request Pre-2007</li>
					<li>Attended {involvementCounts.consultingHours} hours of Coaching/Consulting Appointments</li>
					<li>Attended {involvementCounts.groupLearningHours} hours of Group Learning Events</li>
					<li>Attended {involvementCounts.maConsultingHours} hours of Mission Accelerate Consulting Appointments</li>
				</ul>
				<p className='mt-3'>The ministry's Involvement Score would be calculated like this:</p>
				<table style={{width: '100%'}}>
					<thead>
						<tr>
							<th>Activity</th>
							<th style={{textAlign: 'right'}}>Count</th>
							<th style={{textAlign: 'right'}}>Multiplier</th>
							<th style={{textAlign: 'right'}}>Score</th>
						</tr>
					</thead>
					<tbody>
					<tr>
						<td>Seminars Attended</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.seminars}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.seminarMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.seminars * appState.involvementMultipliers.seminarMultiplier}</td>
					</tr>
					<tr>
						<td>Workshops Attended</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.workshops}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.workshopMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.workshops * appState.involvementMultipliers.workshopMultiplier}</td>
					</tr>
					<tr>
						<td>Webinars Attended</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.webinars}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.webinarMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.webinars * appState.involvementMultipliers.webinarMultiplier}</td>
					</tr>
					<tr>
						<td>Small Group Sessions Attended</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.smallGroupSessions}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.smallGroupMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.smallGroupSessions * appState.involvementMultipliers.smallGroupMultiplier}</td>
					</tr>
					<tr>
						<td>Grant Count Post 2007</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.post2007Grants}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.grantPost2007Multiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.post2007Grants * appState.involvementMultipliers.grantPost2007Multiplier}</td>
					</tr>
					<tr>
						<td>Grant Count Pre 2007</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.pre2007Grants}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.grantPre2007Multiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.pre2007Grants * appState.involvementMultipliers.grantPre2007Multiplier}</td>
					</tr>
					<tr>
						<td>Coaching/Consulting Hours</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.consultingHours}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.consultingMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.consultingHours * appState.involvementMultipliers.consultingMultiplier}</td>
					</tr>
					<tr>
						<td>Group Learning Hours</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.groupLearningHours}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.groupLearningMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.groupLearningHours * appState.involvementMultipliers.groupLearningMultiplier}</td>
					</tr>
					<tr>
						<td>Mission Accelerate Consulting Hours</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.maConsultingHours}</td>
						<td style={{textAlign: 'right'}}>{appState.involvementMultipliers.maConsultingMultiplier}</td>
						<td style={{textAlign: 'right'}}>{involvementCounts.maConsultingHours * appState.involvementMultipliers.maConsultingMultiplier}</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td style={{textAlign: 'right'}}><strong>Total</strong></td>
						<td style={{textAlign: 'right'}}>{(involvementCounts.seminars * appState.involvementMultipliers.seminarMultiplier) + 
							(involvementCounts.workshops * appState.involvementMultipliers.workshopMultiplier) +
							(involvementCounts.webinars * appState.involvementMultipliers.webinarMultiplier) +
							(involvementCounts.smallGroupSessions * appState.involvementMultipliers.smallGroupMultiplier) +
							(involvementCounts.post2007Grants * appState.involvementMultipliers.grantPost2007Multiplier) +
							(involvementCounts.pre2007Grants * appState.involvementMultipliers.grantPre2007Multiplier) +
							(involvementCounts.consultingHours * appState.involvementMultipliers.consultingMultiplier) +
							(involvementCounts.groupLearningHours * appState.involvementMultipliers.groupLearningMultiplier) +
							(involvementCounts.maConsultingHours * appState.involvementMultipliers.maConsultingMultiplier)}</td>
					</tr>
					</tbody>
				</table>
				{/* <ul>
					<li>Attended 2 seminars multiplied by {appState.involvementMultipliers?.seminarMultiplier} = {(2 * appState.involvementMultipliers?.seminarMultiplier!)}</li>
					<li>Attended 3 workshops multiplied by {appState.involvementMultipliers?.workshopMultiplier} = {(3 * appState.involvementMultipliers?.workshopMultiplier!)}</li>
					<li>Attended 1 Small Group Session multiplied by {appState.involvementMultipliers?.smallGroupMultiplier} = {(1 * appState.involvementMultipliers?.smallGroupMultiplier!)}</li>
					<li>Completed 1 successful Grant Request Pre-2007 multiplied by {appState.involvementMultipliers?.grantPre2007Multiplier} = {(1 * appState.involvementMultipliers?.grantPre2007Multiplier!)}</li>
					<li>Completed 2 successful Grant Request Post-2007 multiplied by {appState.involvementMultipliers?.grantPost2007Multiplier} = {(1 * appState.involvementMultipliers?.grantPost2007Multiplier!)}</li>
					<li>Attended 10 hours of Coaching/Consulting Appointments multiplied by {appState.involvementMultipliers?.consultingMultiplier} = {(10 * appState.involvementMultipliers?.consultingMultiplier!)}</li>
					<li>Attended 2 hours of Group Learning Events multiplied by {appState.involvementMultipliers?.groupLearningMultiplier} = {(2 * appState.involvementMultipliers?.groupLearningMultiplier!)}</li>
					<li>Total Involvement Score = { 
						(2 * appState.involvementMultipliers?.seminarMultiplier!) + 
						(3 * appState.involvementMultipliers?.workshopMultiplier!) +
						(1 * appState.involvementMultipliers?.smallGroupMultiplier!) + 
						(1 * appState.involvementMultipliers?.grantPre2007Multiplier!) + 
						(2 * appState.involvementMultipliers?.grantPost2007Multiplier!) + 
						(10 * appState.involvementMultipliers?.consultingMultiplier!) + 
						(2 * appState.involvementMultipliers?.groupLearningMultiplier!)}
					</li>
				</ul> */}
			</Modal>
			</React.Fragment>
		)
	}
}