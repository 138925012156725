import React, { DetailedHTMLProps, forwardRef, InputHTMLAttributes, MutableRefObject, useCallback, useEffect, useRef } from "react"
import IMask from 'imask'

interface IMaskedInputProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
    mask: IMask.AnyMaskedOptions
    value: string
    setValue: (val: string) => void
}
export const MaskedInput = forwardRef<HTMLInputElement, IMaskedInputProps>(({ mask, setValue, value, ...rest }, forwardedRef) => {
    const iMaskInstance = useRef<IMask.InputMask<typeof mask>>()

    const inputRef = useCallback((_inputRef: HTMLInputElement) => {
        if (!_inputRef) return
        const iMask = IMask(_inputRef, mask)
        iMaskInstance.current = iMask
        iMaskInstance.current.on('accept', () => setValue(iMask.unmaskedValue))
    }, [])

    // To support forwarded refs AND use one locally we have to do some gymnastics to recombine them.
    const refHandler = useCallback((node: HTMLInputElement) => {
        inputRef(node)
        if (typeof forwardedRef === 'function') {
            forwardedRef(node)
        } else if (forwardedRef) {
            (forwardedRef as MutableRefObject<HTMLInputElement>).current = node
        }
    }, [forwardedRef, inputRef])

    useEffect(() => {
        return () => {
            if (iMaskInstance.current) iMaskInstance.current.destroy()
        }
    }, [])

    useEffect(() => {
        if (iMaskInstance.current && iMaskInstance.current.unmaskedValue !== value) iMaskInstance.current.unmaskedValue = value
    }, [value])

    return (
        <input ref={refHandler} {...rest} />
    )
})