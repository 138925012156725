import { useState } from "react"
import { Popup } from "./popup"
import clsx from 'clsx'
import { ChevronDownIcon } from "../assets"
import { FormikProps } from "formik"
import { FormikSelectField } from "./forms/formik-select-field"
import { NTEE_CODES_Map_Tool } from "../constants"

interface IMapNteePopperProps {
    formikProps: FormikProps<any>
}

export const MapNteePopper = (props: IMapNteePopperProps) => {
    const { formikProps } = props
    const [open, setIsOpen] = useState(false)

    return (
      <Popup
        trigger={
          <button
            className={
              'btn btn-outline-secondary' +
              clsx(formikProps.values.nteeCodes.length > 0 ? ' active' : ' ')
            }
            type='button'
          >
            {formikProps.values.nteeCodes.length === 0 ? 'NTEE Codes' : `${formikProps.values.nteeCodes.length} NTEE Codes`} <ChevronDownIcon />
          </button>
        }
        onToggle={setIsOpen}
        popupOptions={{
          placement: 'bottom-start',
          modifiers: [
              {
                  name: 'offset',
                  options: {
                      offset: [0, 5]
                  }
              }
          ]
        }}
      >
        <div
            className="p-3"
          style={{
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '.25rem',
            width: '400px',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          <FormikSelectField
            multiple={true}
            field={{
              name: 'nteeCodes',
              label: 'NTEE Codes',
            }}
            options={NTEE_CODES_Map_Tool.sort((a, b) => {
              if (a.label < b.label) return -1
              if (a.label > b.label) return 1
              return 0
            })}
          />
        </div>
      </Popup>
    )
}