import { useState, useEffect, useMemo } from 'react'
import { TagModel, TagType } from '../models/tag'
import { sortListByProperty, showDropdown, uuidv4 } from '../services/helpers'
import { TagForm } from './tag-form'
import { ITagsDocument } from '../open-api'
import { usePrevious } from 'react-use'
import { ISelectFieldOption, ISelectFieldOptionGroup } from './forms/formik-select-field'
import { CreatableSelectField } from './forms/creatable-select-field'


interface ITagsList {
	tags: TagModel[]
	selectedTagIds: number[]
	setSelectedTagIds: (tags: number[]) => void
	setResetList?: (resetList: () => void) => void
	tagType?: TagType
	disableFooterButtons?: (disable: boolean) => void
}


/* 
	Displays a list of tags as "chips" with an "x" icon to allow users to remove tags from the list.
    
	Displays a tag picker
		Selecting the input element will show a list of tags to select from
		Typing in the input element will filter the list of tags by tag name
		If no tag matches the input value then the input value will be displayed with a (Create tag) label on it
			When clicked, this will switch the display over to the new tag form
			When submitted the form will create the tag and added it to the selected tags list
*/
export const TagsList = (props: ITagsList) => {
	const { tags, selectedTagIds, setSelectedTagIds, setResetList, tagType, disableFooterButtons } = props

	const [tagsListId] = useState(uuidv4())
	const [filteredTags, setFilteredTags] = useState({ filterValue: '', tags })
	const [createTagState, setCreateTagState] = useState<{
		showForm: boolean
		newTag?: string
	}>({
		showForm: false
	})
	let resetTagForm: (() => void) | null

	const resetList = () => {
		if (resetTagForm) resetTagForm()
		setFilteredTags({ filterValue: '', tags: tags })
		setCreateTagState({ showForm: false })
	}
	if (setResetList) setResetList(resetList)

	const filterTags = (filterValue: string) => {
		let _tags = tags
		if (filterValue && filterValue.length) _tags = _tags.filter(tag => tag.tag?.toLowerCase().includes(filterValue.toLowerCase()))

		setFilteredTags({ filterValue, tags: _tags })
	}

	const newTagCreated = (tag: ITagsDocument) => {
		setSelectedTagIds([...selectedTagIds, tag.tagId])
		if (resetList) resetList()
	}

	useEffect(() => {
		filterTags(filteredTags.filterValue)
	}, [tags])

	const previousFilterValue = usePrevious(filteredTags.filterValue)
	useEffect(() => {
		if (previousFilterValue !== filteredTags.filterValue && filteredTags.filterValue.length) showDropdown(tagsListId)
	}, [filteredTags.filterValue])

	const tagOptions = useMemo<ISelectFieldOptionGroup[]>(() => {
		let _tags = tags
		if (filteredTags.filterValue && filteredTags.filterValue.length) _tags = _tags.filter(tag => tag.tag?.toLowerCase().includes(filteredTags.filterValue.toLowerCase()))

		// Group by Branch ID
		const optionGroups = _tags.reduce((optionGroups, tag) => {
			const option = { label: `${tag.tag}`, value: `${tag.tagId}` }
			if (tag.branchId) {
				const branchOptionGroup = optionGroups.find(o => o.id === tag.branchId?.toString())
				if (branchOptionGroup) {
					branchOptionGroup.options.push(option)
				} else {
					optionGroups.push({
						id: `${tag.branchId}`,
						label: `${tag.branchName}`,
						options: [option]
					})
				}
			}
			// No branch ID -> it is a global tag
			else {
				const globalOptionGroup = optionGroups.find(o => o.id === 'global')
				if (globalOptionGroup) {
					globalOptionGroup.options.push(option)
				} else {
					optionGroups.push({
						label: 'Global',
						id: 'global',
						options: [option]
					})
				}
			}

			return optionGroups
		}, [] as ISelectFieldOptionGroup[])

		sortListByProperty(optionGroups, 'label')
		optionGroups.forEach(group => sortListByProperty(group.options, 'label'))

		return optionGroups
	}, [selectedTagIds, tags, filteredTags.filterValue])

	return (
		<div>
			{/* <div className='_row wrap mb-1'>
				{sortListByProperty(tags.filter(tag => selectedTagIds.includes(tag.tagId)), 'tag').map(tag => (
					<button
						key={tag.tagId}
						className='tag list-spacing'
						type='button'
						onClick={() => {
							const tagidx = selectedTagIds.indexOf(tag.tagId)
							const _selectedTags = [...selectedTagIds]
							_selectedTags.splice(tagidx, 1)
							setSelectedTagIds(_selectedTags)
						}}
					>
						<TagIcon className='tag-icon mr-1' />{tag.tag}<CloseIcon className='close-icon' />
					</button>
				))}
			</div> */}

			{createTagState.showForm ?
				<TagForm
					onTagSaved={(tag) => {
						newTagCreated(tag)
						if (disableFooterButtons)
							disableFooterButtons(false)
					}}
					className='animated fadeIn'
					setFormikProps={(formikProps) => resetTagForm = formikProps ? formikProps.resetForm : null}
					tagName={createTagState.newTag}
					onSaveCancel={ () => {
						resetList()
						if (disableFooterButtons)
							disableFooterButtons(false)
					}}
					tagType={tagType}
				/>
				:

				<CreatableSelectField
					multiple
					fieldProps={{ name: 'tags', label: '', placeholder: 'Select a tag' }}
					value={selectedTagIds.map(o => o.toString())}
					optionGroups={tagOptions}
					onChange={(options) => {
						if (options) 
							setSelectedTagIds((options as ISelectFieldOption[]).map(o => parseInt(o.value)))
						else
							setSelectedTagIds([])
					}}
					onCreate={(inputValue) => {
						console.log('setCreateTagState')
						setCreateTagState({ showForm: true, newTag: inputValue })
						if (disableFooterButtons)
							disableFooterButtons(true)
					}}
				/>

				// <SimpleSelectField
				// 	multiple
				// 	fieldProps={{ name: 'tags', label: '', placeholder: 'Select a tag' }}
				// 	value={selectedTagIds.map(o => o.toString())}
				// 	// options={[...sortListByProperty(filteredTags.tags, 'tag').map(o => ({ label: `${o.tag}`, value: `${o.tagId}` }))]}
				// 	optionGroups={tagOptions}
				// 	onChange={(options) => {
				// 		// `options` will always be an array since `multiple` is set to `true`
				// 		if (options) setSelectedTagIds((options as ISelectFieldOption[]).map(o => parseInt(o.value)))
				// 	}}
				// />
				
				// <div className='btn-group animated fadeIn'>
				// 	<input
				// 		value={filteredTags.filterValue}
				// 		onChange={e => filterTags(e.currentTarget.value)}
				// 		placeholder='Select tag'
				// 		id={tagsListId}
				// 		className='dropdown-toggle border rounded p-2'
				// 		data-toggle='dropdown'
				// 		data-flip='false' // Bootstrap's dropdown implementation passes this value on to PopperJS. Setting it to "false" prevents the drop down from automatically dropping up when PopperJS thinks it should (which could cover the <input/> if it did).
				// 	/>
				// 	<div className='dropdown-menu' style={{ maxHeight: '300px', overflowY: 'auto' }}>
				// 		{filteredTags.tags.length ?
				// 			<MinistryManagementTagsDropdownList
				// 				tags={sortListByProperty(filteredTags.tags, 'tag')}
				// 				onTagSelected={(tag) => setSelectedTagIds([...selectedTagIds, tag.tagId])}
				// 				disabledTagIds={selectedTagIds}
				// 			/>
				// 			:
				// 			<button className='dropdown-item' type='button' onClick={() => setCreateTagState({ ...createTagState, showForm: true })}><TagIcon /> {filteredTags.filterValue} (Create tag)</button>
				// 		}
				// 	</div>
				// </div>
			}
		</div>
	)
}