// TB 20230428 - After I started migrating the GR Summary Report from the legacy system to React, I realized we had already migrated it to ASP.NET on the Givers Portal.
// I stopped the migration in React, but leaving this here, just in case we decide to continue it sometime.
import { IGrantExportModelDocument } from '../open-api'
import { IDefaultProps } from './component-definitions'
import '../styles/grant-request-summary-report.scss'
import { GrantRequestSummaryReportPage1 } from './grant-request-summary-report-page1'
import { GrantRequestSummaryReportPage2 } from './grant-request-summary-report-page2'
import { GrantRequestSummaryReportPage3 } from './grant-request-summary-report-page3'
import { GrantRequestSummaryReportPage4 } from './grant-request-summary-report-page4'

{/* Original source: Admin\views\reports\grantSummaries.cfm */}

export interface IGrantRequestSummaryReportProps extends IDefaultProps { 
    grantRequestSummary: IGrantExportModelDocument
}

export const GrantRequestSummaryReport = (props: IGrantRequestSummaryReportProps) => {
    const { grantRequestSummary } = props

    return (
        <>
            <GrantRequestSummaryReportPage1 grantRequestSummary={grantRequestSummary} />
            <div style={{breakAfter:"page"}}></div>
            <GrantRequestSummaryReportPage2 grantRequestSummary={grantRequestSummary} />
            <div style={{breakAfter:"page"}}></div>
            <GrantRequestSummaryReportPage3 grantRequestSummary={grantRequestSummary} />
            <div style={{breakAfter:"page"}}></div>
            <GrantRequestSummaryReportPage4 grantRequestSummary={grantRequestSummary} />
        </>
    )
}