import { Form, Formik } from "formik"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useState } from "react"
import { useContext } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IEventContentModelDocument, IEventModelDocument, IEventSiteModelDocument, ILmsUserCourseModelDocument, IMailBlastTaskResponseDocument, IPresentersDocument, ISeminarEvalSummaryDocument } from "../open-api"
import * as yup from 'yup'
import { CommunitySelectField, SelectField } from "./forms"
import { EventSiteSelectField } from "./event-site-select-field"
import { createContext } from "react"
import { EventFormTabs } from './event-form-tabs'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { useCallback } from "react"
import dayjs from "dayjs"
import { EventContentType } from "../constants"

export enum EventStatus {
    'draft' = 'draft',
    'activated' = 'activated',
    'canceled' = 'canceled',
    'deleted' = 'deleted',
}

interface IEventFormProps {
    eventToEdit?: IEventModelDocument
    setEventToEdit: (eventToEdit: IEventModelDocument) => void
    eventContent: IEventContentModelDocument
    presenters: IPresentersDocument[]
    initialTab?: EventFormTab
}

export enum EventFormTab {
    'general' = 'general',
    'registrants' = 'registrants',
    'evals' = 'evals',
    'lms' = 'lms'
}

const getValidTab = (testName: string) => {
    if (EventFormTab.registrants === testName) {
        return EventFormTab.registrants
    }

    return false
}

interface IEventFormState {
    activeTab: EventFormTab

    step: 'step1' | 'step2'
    step1Values: {
        branchId: string
        trainingAreaId: string
        siteId: string
    }

    sites?: IEventSiteModelDocument[]
    inviteBlasts?: IMailBlastTaskResponseDocument[]
    evals?: ISeminarEvalSummaryDocument[]
    lmsUserCourses?: ILmsUserCourseModelDocument[]
}

interface IEventFormContext extends IEventFormState, IEventFormProps {
    setState: Dispatch<SetStateAction<IEventFormState>>
    fetchInviteBlasts: () => Promise<void>
    fetchEvals: () => Promise<void>
    fetchLmsUserCourses: () => Promise<void>
}
const EventFormContext = createContext<IEventFormContext | null>(null)
export const useEventFormContext = () => {
    const context = useContext(EventFormContext)
    if (!context) throw new Error('Cannot use Event Form Context before it is initialized.')
    return context
}

export const EventForm = (props: IEventFormProps) => {

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const { fetchTrainingAreas, EventSitesApi, EventMgmtApi, MailBlastTaskApi, SeminarEvalApi } = useContext(AppActionContext)!
    const { trainingAreas, activeTrainingAreas, currentUser } = useContext(AppStateContext)!

    useEffect(() => {
        if (!trainingAreas) fetchTrainingAreas()
    }, [trainingAreas])

    const [state, setState] = useState<IEventFormState>({
        ...props,
        activeTab: props.initialTab || EventFormTab.general,
        step: props.eventToEdit ? 'step2' : 'step1',
        step1Values: {
            // If a webinar, set specific defaults: https://missionincrease.atlassian.net/browse/MA20-2195
            branchId: props.eventToEdit?.branchId?.toString() || (props.eventContent.eventTypeId === EventContentType.Webinar && '4') || currentUser?.branchId?.toString() || '',
            trainingAreaId: props.eventToEdit?.trainingAreaId?.toString() || (props.eventContent.eventTypeId === EventContentType.Webinar && '30') || '',
            siteId: props.eventToEdit?.siteId?.toString() || (props.eventContent.eventTypeId === EventContentType.Webinar && '99') || '',
        },
    })

    const fetchInviteBlasts = useCallback(async () => {
        if (props.eventToEdit && props.eventToEdit.branchId) {
            // Fetch invite blasts
            const { data: inviteBlasts } = await makeHttpRequestWithUi({
                request: MailBlastTaskApi.apiMailBlastTaskEventContentEventContentIdGetEventInviteBlastsByCommunityBranchIdGet(props.eventToEdit.eventContentId, props.eventToEdit.branchId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching mail blast tasks for event.'
            })

            inviteBlasts.sort((a, b) => {
                if (a.scheduleDate && b.scheduleDate === null) return -1
                if (a.scheduleDate === null && b.scheduleDate) return 1
                if (a.scheduleDate === null && b.scheduleDate === null) return 0
                if (dayjs(a.scheduleDate!).isBefore(dayjs(b.scheduleDate!))) return -1
                if (dayjs(a.scheduleDate!).isAfter(dayjs(b.scheduleDate!))) return 1
                return 0
            })

            setState(_state => ({ ..._state, inviteBlasts }))
            console.log('inviteBlasts', inviteBlasts)
        }
    }, [props.eventToEdit])

    const fetchEvals = useCallback(async () => {
        if (!props.eventToEdit) {
            setState(_state => ({ ..._state, evals: undefined }))
            return
        }

        const { data } = await makeHttpRequestWithUi({
            request: SeminarEvalApi.apiSeminarEvalEventsEventIdGet(props.eventToEdit.eventId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching event evals.',
        })

        setState(_state => ({ ..._state, evals: data }))
    }, [props.eventToEdit])

    const fetchLmsUserCourses = useCallback(async () => {
        console.log('EventForm > fetchLmsUserCourses')
        if (!props.eventToEdit) {
            setState(_state => ({ ..._state, lmsUserCourses: undefined }))
            return
        }

        const { data } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventEventIdLmsUserCourseInfoGet(props.eventToEdit.eventId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching LMS Course Info.',
        })

        setState(_state => ({ ..._state, lmsUserCourses: data }))
    }, [props.eventToEdit?.eventId])

    const fetchInitialData = useCallback(async () => {
        // Need to fetch the sites if we're skipping step1 (since the EventSiteSelectField won't be rendered for us to piggy back of its Site fetch)
        if (props.eventToEdit?.trainingAreaId && !state.sites) {
            const { data } = await makeHttpRequestWithUi({
                request: EventSitesApi.apiMiTrainingAreasTrainingAreaIdEventSitesGet(props.eventToEdit.trainingAreaId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching sites.'
            })
            setState(_state => ({ ..._state, sites: data }))
        }

        fetchInviteBlasts()
        fetchEvals()
    }, [props.eventToEdit])

    useEffect(() => {
        fetchInitialData()
    }, [props.eventToEdit])

    useEffect(() => {
        // Check if we're loading a URL for a specific tab and show that tab
        const hash = window.location.hash.replace('#', '')
        if (EventFormTab.hasOwnProperty(hash))
            setState(_state => ({ ..._state, activeTab: EventFormTab[hash as keyof typeof EventFormTab] }))
    }, [])

    return (
        <>
            {state.step === 'step1' &&
                <Formik
                    initialValues={state.step1Values}
                    validationSchema={yup.object({
                        branchId: yup.string().required('Required'),
                        trainingAreaId: yup.string().required('Required'),
                        siteId: yup.string().required('Required'),
                    })}
                    onSubmit={(step1Values) => setState(_state => ({ ..._state, step: 'step2', step1Values }))}
                >
                    {formikProps =>
                        <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, marginRight: 20 }}>
                                    <CommunitySelectField fieldProps={{ name: 'branchId', label: 'Community' }} />
                                </div>
                                <div style={{ flex: 1, marginRight: 20, marginLeft: 20 }}>
                                    <SelectField fieldProps={{ name: 'trainingAreaId', label: 'Active Training Area' }} options={activeTrainingAreas?.filter(o => o.branchId.toString() === formikProps.values.branchId).map(o => ({ label: `${o.trainingArea}`, value: o.trainingAreaId.toString() }))} />
                                </div>
                                <div style={{ flex: 1, marginLeft: 20 }}>
                                    <EventSiteSelectField
                                        field={{ name: 'siteId', label: 'Site' }}
                                        trainingAreaId={formikProps.values.trainingAreaId.length ? parseInt(formikProps.values.trainingAreaId) : undefined}
                                        setSites={(sites: IEventSiteModelDocument[]) => setState(_state => ({ ..._state, sites }))}
                                    />
                                </div>
                            </div>

                            <div style={{ flex: 1 }} />

                            <div>
                                <button type='submit' className='btn btn-primary' style={{ minWidth: 100 }}>Continue</button>
                            </div>
                        </Form>
                    }
                </Formik>
            }

            {state.step === 'step2' &&
                <EventFormContext.Provider value={{ ...state, setState, ...props, fetchEvals, fetchInviteBlasts, fetchLmsUserCourses }}>
                    <EventFormTabs />
                </EventFormContext.Provider>
            }
        </>
    )
}