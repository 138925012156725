import { IGcAfterEventDataDocument, IGroupCoachingModelDocument } from "../open-api"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { TinyMceField } from "./forms"
import * as Yup from 'yup'
// import { GridDataFetch, IGridListItem } from "../stores/grid-definitions"
// import { useGrid, defaultGridState } from "../stores/grid-actions"
// import { gridReducer } from "../stores/grid-reducer"
// import { Grid } from "./grid"
// import { LoadingOverlay } from "./loading"
// import { DefaultGridCellDisplay } from "./grid-cell-displays"
import { FormikNumberField } from "./forms/formik-number-field"
// import { filterGridListItems, sortListBySorts } from "../services/helpers"

interface IGroupCoachingFormAfterEventProps {
    //registrants: IGroupCoachingRegistrantDocument[]
    eventToEdit?: IGroupCoachingModelDocument
    afterEventData?: IGcAfterEventDataDocument
    afterSave?: () => Promise<void>
}
export const GroupCoachingFormAfterEvent = ({ eventToEdit, afterEventData, afterSave }: IGroupCoachingFormAfterEventProps) => {
    const { GroupCoachingApi } = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    // const [loading, setLoading] = useState(false)

    // const dataSource: GridDataFetch<IGroupCoachingRegistrantDocument[]> = async (_queryState, _registrants) => {
    //     try {
    //         console.log('_registrants', _registrants)
    //         if (!eventToEdit) return { rows: [], count: 0 }

    //         if (_queryState.sorts) sortListBySorts(_registrants, _queryState.sorts)

    //         let rows: IGridListItem[] = _registrants.filter(o => Boolean(o.seminarAttendeeId) && o.bActive).map(o => ({
    //             id: o.seminarAttendeeId!.toString(),
    //             values: {
    //                 ...o
    //             },
    //             selected: o.bAttended
    //         }))

    //         if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

    //         return {
    //             rows,
    //             count: rows.length
    //         }
    //     } catch (e) {
    //         return {
    //             rows: [],
    //             count: 0
    //         }
    //     }
    // }

    // const [gridState, gridActions] = useGrid(
    //     gridReducer,
    //     {
    //         ...defaultGridState,
    //         columns: [
    //             {
    //                 property: 'fullName',
    //                 title: 'Name',
    //                 width: 100,
    //                 type: 'string',
    //                 render: DefaultGridCellDisplay,
    //             },
    //             {
    //                 property: 'email',
    //                 title: 'Email',
    //                 width: 100,
    //                 type: 'string',
    //                 render: (_, row) => <a href={`mailto:${row.values.email}`}>{row.values.email}</a>
    //             },
    //             {
    //                 property: 'organizationName',
    //                 title: 'Ministry',
    //                 width: 120,
    //                 type: 'string',
    //                 render: (col, row) => row.values.ministryId || row.values.prospectId ? <a target='_blank' href={row.values.ministryId ? `/ministry-info/${row.values.ministryId}` : `/prospects/${row.values.prospectId}`}>{row.values[col.property]}</a> : row.values[col.property],
    //                 allowFilters: true
    //             },
    //         ],
    //         dataSource,
    //         usingLocalData: true,
    //         hideGridHeader: true,
    //         hideGridFooter: true,
    //         rowSelectEnabled: true,
    //     },
    //     registrants
    // )

    // useEffect(() => {
    //     gridActions.doFetch()
    // }, [registrants])

    return (
        <Formik
            enableReinitialize
            initialValues={{
                actualHours: afterEventData?.actualHours?.toString() || '',
                note: afterEventData?.note || '',
            }}

            validationSchema={Yup.object({
                actualHours: Yup.number().required('Required'),
            })}

            onSubmit={async (values) => {
                if (!eventToEdit || !afterEventData) return
                await makeHttpRequestWithUi({
                    request: GroupCoachingApi.apiGroupCoachingEventIdAfterApptDataPut(
                        eventToEdit.eventId,
                        {
                            ...afterEventData,
                            ...values,
                            actualHours: parseFloat(values.actualHours.toString()),
                            //attendedSeminarAttendeesIds: gridState.rows.filter(o => o.selected).map(o => parseInt(o.id)),
                            eventId: eventToEdit.eventId
                        }
                    ),
                    toastErrorMessage: 'Enountered an error updating after event data.',
                    toastSuccessMessage: 'Successfully updated after event data.',
                })

                afterSave && await afterSave()
            }}

            children={formikProps => {
                return (
                    <>
                        <Form style={{ padding: 10, position: 'relative' }}>
                            {/*loading && <LoadingOverlay position='absolute' />*/}

                            <p>
                                After the event is over, use this form to enter hours and notes. Use the Registrants tab to update attendance.
                            </p>

                            {/* {!afterEventData?.actualHours &&
                                <>
                                    <p><b>It looks like the after event data has not been added for this appointment.</b></p>
                                    <p><b>Please complete the after event requirements to avoid being taken straight to this tab in the future.</b></p>
                                </>
                            } */}

                            <FormikNumberField field={{ name: 'actualHours', label: 'Actual Hours' }} />
                            <TinyMceField fieldProps={{ name: 'note', label: 'Notes' }} />

                            {/* <p>Check all registrants that were in attendance.</p>
                            <Grid state={gridState} actions={gridActions} style={{ height: 'unset' }} /> */}

                            <button className='btn btn-primary' style={{ marginTop: 10 }}>Save</button>
                        </Form>
                    </>
                )
            }}
        />
    )
}