import Helmet from "react-helmet"
import { EventContentForm } from "./event-content-form"
import { IDefaultProps } from "./component-definitions"
import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { IEventContentModelDocument, IPresentersDocument } from "../open-api"
import { LoadingOverlay } from "./loading"
import { EventContentStatus } from "./event-management"
import { CalendarEventIcon } from "../assets"
import { EventContentType } from "../constants"

interface IEventContentDetailProps extends IDefaultProps {
    eventContentId?: string
}

export const EventContentDetail = ({eventContentId}: IEventContentDetailProps) => {
    const { PresentersApi, EventMgmtApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [eventContentToEdit, setEventContentToEdit] = useState<IEventContentModelDocument>()
    const [presenters, setPresenters] = useState<IPresentersDocument[]>([])

    const editEventContent = useCallback(async (eventContentId: string) => {
        const { data: eventContentRecord } = await makeHttpRequestWithUi({
            request: EventMgmtApi.apiEventMgmtEventContentEventContentIdGet(parseInt(eventContentId)),
            disableSuccessToast: true,
            toastErrorMessage: 'Enountered an error retrieving event content record.',
        })

        const { data: presentersList } = await makeHttpRequestWithUi({
            request: PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of presenters.'
        })

        setEventContentToEdit(eventContentRecord)
        setPresenters(presentersList)
    }, [])

    useEffect(() => {
        console.log('eventContentId', eventContentId)
        if (eventContentId) editEventContent(eventContentId)
    }, [])

    const getPageTitle = () => {
        if (!eventContentToEdit) return 'Error: No event content was found.'

        let eventType = ''
        let eventTypeIdStr = eventContentToEdit.eventTypeId.toString()
        if (eventContentToEdit.isSeries)
            eventType = 'Series'
        else if (eventTypeIdStr === EventContentType["Group Learning"].toString())
            eventType = 'Group Learning'
        else if (eventTypeIdStr === EventContentType.Workshop.toString())
            eventType = 'Workshop'

        
        let result = eventContentToEdit.title;
        result += ` - ${eventType}`
        result += ` (${eventContentToEdit.eventContentId})`

        return result
    }

    if (!eventContentToEdit) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>{getPageTitle()}</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/event-management">Event Management</a></li>
                        <li className="breadcrumb-item">Event Content</li>
                    </ol>
                </nav>
                <div className='m-2 d-flex align-items-center'>
                    <CalendarEventIcon style={{ width: '25px', height: '25px', }} />
                    <h3 className='ml-2'>
                        {getPageTitle()}
                        {/* 
                            // 20231204 TB - Karla asked us not display "Activated" because it confused the AD into thinking that this was an activated event record, rather than an activated EC record.
                        {eventContentToEdit.status === EventContentStatus.activated ? <span className='badge badge-success ml-2'>Activated</span> : null} */}
                        {eventContentToEdit.status === EventContentStatus.draft ? <span className='badge badge-info ml-2'>Draft</span> : null}
                    </h3>
                </div>

                {presenters && eventContentToEdit &&
                    <div className='m-2'>
                        <EventContentForm
                        eventContentToEdit={eventContentToEdit}
                        setEventContentToEdit={eventContentToEdit => {}}
                        presenters={presenters}
                    />
                    </div>
                }
            </div>
        </>
    )
}