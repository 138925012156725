import { useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IDashboardAlertDocument, IMiBranchModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AlertSnoozeForm } from './alert-snooze-form'
import { Modal } from "./modal"
import { Grid } from "./grid"
import { DashboardAlertModel } from '../models/dashboard-alert'
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { SnoozeIcon, InfoCircleIcon, ExclamationOctagonFillIcon, ExclamationTriangleFillIcon, BuildingIcon, CallChatIcon, CalendarEventIcon, FileMedicalIcon } from "../assets"
import { GridDataFetch, IGridListItem, IGridRowAction, IGridState } from "../stores/grid-definitions"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { gridReducer } from "../stores/grid-reducer"
import { SquareDeleteIcon } from "./partials"
import { IFilter } from "../stores/api-actions"
import { Loading } from "./loading"

interface ICardAlerts {
    activeCommunity: IMiBranchModelDocument | undefined,
    style: object | undefined
}

export const CardAlerts = (props: ICardAlerts) => {
    const { activeCommunity, style } = props

    const { DashboardApi } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [alerts, setAlerts] = useState<IDashboardAlertDocument[]>()
    const [snoozeModal, showHideSnoozeModal] = useModal()
    const [dismissModal, showHideDismissModal] = useModal()
    const [loading, setLoading] = useState(false)

    const fetchAlerts = async () => {
        if (activeCommunity) {
            setLoading(true)
            const alertsQuery = await makeHttpRequestWithUi({
                request: DashboardApi.apiDashboardAlertsGet(activeCommunity.branchId, Boolean(gridState.queryState.filters?.find(o => o.id === showSnoozeFilter.id)?.value)),
                disableSuccessToast: true,
                toastErrorMessage: 'Failed to fetch alerts.',
                disableLoading: true,
                disableFailureToast: true // Don't show error toast if not available. Don't want to distress the user.
            })

            setAlerts(alertsQuery.data)
            setLoading(false)
        }
    }

    const dataSource: GridDataFetch<IDashboardAlertDocument[]> = async (_queryState, _alerts) => {
        if (!_alerts) return { count: 0, rows: [] }

        const _alertsCopy = [..._alerts]

        if (_queryState.sorts) sortListBySorts(_alerts, _queryState.sorts)
        let rows = _alertsCopy.map(DashboardAlertModel.toGridListItem)

        if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters?.filter((item) => item.id != showSnoozeFilter.id))

        return {
            count: rows.length,
            rows,
        }
    }

    const rowActions: { [id: string]: IGridRowAction } = {}

    rowActions.entityInfo = {
        id: 'entityInfo',
        action: async (options) => options.e.stopPropagation(),
        icon: (row) => {
            // SiteId is returned, but never used as the eventId is more relevant.
            if (row.values.callListId)
                return <CallChatIcon />
            else if (row.values.grantId) // TB 20210826 - Grant section doesn't exist yet.
                 return <FileMedicalIcon />
            else if (row.values.eventId)
                return <CalendarEventIcon />
            else if (row.values.consultingEventGroupId)
                // Relates to a coaching/consultin appointment
                return <CalendarEventIcon />
            else if (row.values.ministryId)
                return <BuildingIcon />
            else
                return <span></span>
        },
        tooltipText: (row) => {
            // SiteId is returned, but never used as the eventId is more relevant.
            if (row.values.callListId)
                return 'View Call List'
            // else if (row.values.grantId) // TB 20210826 - Grant section doesn't exist yet.
            //     return <a href={`/communities`} className='d-flex'><ContactCard /></a>
            else if (row.values.eventId)
                return 'View Event'
            else if (row.values.consultingEventGroupId)
                return 'View Appointment'
            else if (row.values.ministryId)
                return 'View Ministry'
            else
                return ''
        }, 
        hidden: (row) => !!(row.values.ministryId && row.values.siteId && row.values.callListId && row.values.eventId && row.values.consultingEventGroupId),
        url: (row) => {
            // SiteId is returned, but never used as the eventId is more relevant.
            if (row.values.callListId)
                return `/call-list-summary/${row.values.callListId}`
            else if (row.values.grantId) // TB 20210826 - Grant section doesn't exist yet.
                 return `/grant-request/${row.values.grantId}`
            else if (row.values.eventId)
                return `/event-management/${row.values.eventId}`
            else if (row.values.consultingEventGroupId)
                // Relates to a coaching/consultin appointment
                return `/consulting/${row.values.consultingEventGroupId}`
            else if (row.values.ministryId)
                return `/ministry-info/${row.values.ministryId}`
            else
                return '#'
        },
        anchorTarget: '_blank'
    }
    rowActions.snooze = {
        id: 'snooze',
        action: async (options) => {
            options.e.stopPropagation()
            setSelectedRow(options.row)
            showHideSnoozeModal(true)
        },
        icon: <SnoozeIcon />,
        tooltipText: 'Snooze Alert',
        disabled: (row) => !row.values.ministryId
    }
    rowActions.dismiss = {
        id: 'dismiss',
        action: async (options) => {
            options.e.stopPropagation()
            setSelectedRow(options.row)
            showHideDismissModal(true)
        },
        icon: <SquareDeleteIcon />,
        tooltipText: 'Dismiss Alert',
        disabled: (row) => !row.values.bDismissable
    }

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const showSnoozeFilter: IFilter = {
        id: 'showSnoozeFilter',
        value: false,
        operator: 'eq',
        property: 'showSnooze',
        enabled: true,
        hidden: true
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        queryState: {
            ...defaultGridState.queryState,
            filters: [showSnoozeFilter]
        },
        gridActions: [
            {
                id: 'toggleShowSnoozed',
                label: (_gridState) => _gridState.queryState.filters?.find(o => o.id === showSnoozeFilter.id)?.value ? 'Hide Snoozed' : 'Show Snoozed',
                onClick: (_gridState, _gridActions) => {
                    const _filters = [..._gridState.queryState.filters || []]
                    const _showSnoozeFilter = _filters.find(o => o.id === showSnoozeFilter.id)

                    if (_showSnoozeFilter) {
                        _showSnoozeFilter.value = !_showSnoozeFilter.value
                        _gridActions.updateColumnFilters(_filters)
                    }
                }
            }
        ],
        columns: [
            {
                property: 'icon',
                type: 'string',
                width: 10,
                allowFilters: false,
                title: '',
                render: (_, row) => {
                    if (row.values.icon == 'alert')
                        return <ExclamationOctagonFillIcon className='text-danger' title='alert' />
                    else if (row.values.icon == 'warning')
                        return <ExclamationTriangleFillIcon className='text-warning' title='warning' />
                    else
                        return <InfoCircleIcon title='info' />
                }
            },
            {
                property: 'ageInDays',
                type: 'string',
                width: 40,
                allowFilters: true,
                title: 'Age',
                render: (_, row) => { return row.values.ageInDays ? `${row.values.ageInDays} days` : '' },
            },
            {
                property: 'recordName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Record',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'alertTypeName',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Alert',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 85,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
        ],
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        disableExport: true,
        rowActions,
        rowSelectEnabled: false,
        hideGridFooter: true,
        respectGlobalCommunityFilter: false
    }

    const [gridState, actions] = useGrid(gridReducer, initialGridState, alerts)

    useEffect(() => {
        console.log('alerts > useEffect')
        actions.doFetch()
    }, [alerts])

    useEffect(() => {
        fetchAlerts()
    }, [activeCommunity, gridState.queryState.filters])

    return (
        <>
            <div className="card" style={{ ...style }}>
                <div className="card-header">
                    {activeCommunity?.branchAbbr} Alerts
                </div>
                {activeCommunity ?
                    loading ? <Loading /> : <Grid state={gridState} actions={actions} style={{ height: 'auto', minHeight: '165px' }} />
                    :
                    <div className="card-body">
                        <p className="card-text">Select a community.</p>
                    </div>
                }
            </div>

            <Modal
                {...snoozeModal}
                modalTitle='Snooze Alert'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    showHideSnoozeModal(false)
                }}
            >
                {selectedRow ?
                    <AlertSnoozeForm
                        alertId={selectedRow.id}
                        onCancel={() => showHideSnoozeModal(false)}
                        afterSave={() => {
                            if (alerts) {
                                showHideSnoozeModal(false)
                                // Update alerts without roundtrip to server.
                                alerts.splice(alerts.findIndex(x => x.id === selectedRow.id), 1)
                                setAlerts([...alerts])
                            }
                        }
                        } />
                    :
                    null}
            </Modal>

            <Modal
                {...dismissModal}
                modalTitle='Dismiss Alert'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    showHideSnoozeModal(false)
                }}
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideDismissModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (selectedRow && alerts) {
                                    await makeHttpRequestWithUi({
                                        request: DashboardApi.apiDashboardAlertsIdDismissPost(selectedRow.id),
                                        toastSuccessMessage: 'Successfully dismissed the alert.',
                                        toastErrorMessage: 'Encountered an error dismissing this alert.',
                                        onRequestSuccess: async () => {
                                            // Update alerts without roundtrip to server.
                                            alerts.splice(alerts.findIndex(x => x.id === selectedRow.id), 1)
                                            setAlerts([...alerts])
                                        }
                                    })
                                    showHideDismissModal(false)
                                }
                            }}
                        >
                            Dismiss
                        </button>
                    </>
                }
            >
                <p>Are you sure you want to dismiss this alert? If you only want to hide it temporarily, you may snooze the alert instead.</p>
            </Modal>
        </>
    )
}