import React, { useContext } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Path } from './dashboard'
import { MainNavItemCell } from './main-nav-item-cell'

interface IMainNavItem {
    path: Path
    icon: JSX.Element
    title: string
    disabled?: boolean
}

export const MainNavItem = (props: IMainNavItem) => {
    const { path } = props

    const appActions = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    return <MainNavItemCell {...props} active={appState.mainNav.selectedItem === path} onClick={() => appActions.mainNavItemPressed(path)} />
}