import React, { useEffect, useContext, useState } from 'react'
import { MinistryInfoContext } from './ministry-info'
import { GridDataFetch, IGridState, IGridListItem } from '../stores/grid-definitions'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { validateFilterState, filterGridListItems, uuidv4, showModal, sortListBySorts } from '../services/helpers'
import { MinistryFyData, ministryFyDataGeneralDataColumns } from '../models/ministry-fy-data'
import { useGrid, defaultGridState } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Loading } from './loading'
import { Grid } from './grid'
import { ReactComponent as ChatTextIcon } from '../assets/chat-text.svg'
import { ReactComponent as FileEarmarkIcon } from '../assets/file-earmark.svg'
import { useModal } from '../services/hooks'
import { Modal } from './modal'
import { MinistryInfoFyDataGeneralDataForm } from './ministry-info-fy-data-general-data-form'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { IMinistryDataFiscalYearSummaryDocument } from '../open-api'
import { AppActionContext } from '../app-store-provider'

export const MinistryInfoFyDataGeneralData = () => {
	const { actions: ministryInfoActions, state: ministryInfoState, } = useContext(MinistryInfoContext)!

	const appActions = useContext(AppActionContext)!

	//const downloadAndViewFile = useDownloadAndViewFile()

	const [userCommentsModalId] = useState(uuidv4())

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [formModal, showHideFormModal] = useModal()

	const fetchFyDataGeneral: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryFyData } = _state

		if (!ministryFyData) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryFyData, queryState.sorts)

		let rows = ministryFyData.map(MinistryFyData.toGridListItem)

		if (queryState.filters) {
			validateFilterState(queryState.filters, gridState.columns)
			rows = filterGridListItems(rows, queryState.filters)
		}

		return { rows, count: ministryFyData.length }
	}

	const viewFile = async (row: IGridListItem) => {
		console.log(row.values)
		if (ministryInfoState.ministry && row.values.fiscalYear) {
			const signedUrl = await appActions.MinistryFilesApi.apiMinistryFilesMinistryIdFiscalYear990GetSignedUrlGet(ministryInfoState.ministry.ministryId, parseInt(row.values.fiscalYear.toString()));

			if (signedUrl.data) window.open(signedUrl.data, '_blank')
		}
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: ministryFyDataGeneralDataColumns,
		disabledPagination: true,
		dataSource: fetchFyDataGeneral,
		hideGridHeader: true,
		hideGridFooter: true,
		rowActions: {
			edit: {
				id: 'edit',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideFormModal(true)
				},
				icon: <PencilIcon />,
				tooltipText: 'Edit FY Data'
			},
			viewPdf: {
				id: 'viewPdf',
				action: async (options) => {
					options.e.stopPropagation()
					viewFile(options.row)
					// if (ministryInfoState.ministry && options.row.values.ministryFileGuid) {
					// 	downloadAndViewFile(ministryInfoState.ministry.ministryId, options.row.values.ministryFileGuid.toString())
					// }
				},
				icon: <FileEarmarkIcon />,
				tooltipText: (row) => row.values.downloadedFromGS === true ? 'View GS 990' : 'View Ministry Uploaded 990',
				hidden: (row) => !(typeof row.values.ministryFileId === 'number' && row.values.ministryFileId > 0)
			},
			viewUserComments: {
				id: 'viewUserComments',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(userCommentsModalId)
				},
				icon: <ChatTextIcon />,
				tooltipText: 'View Ministry Comments',
				hidden: (row) => !row.values.gsDiscrepancyExplanation && !row.values.gs990MissingExplanation
			},
		},
		rowDoubleClicked: async (row) => {
            setSelectedRow(row)
			showHideFormModal(true)
        },
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryFyData])

	/* 
		Highlight any fiscal year rows with a fiscal year that matches the ministry's start year
	*/
	useEffect(() => {
		gridActions.setHighlightedRows(gridState.rows.filter(row => row.values.fiscalYear === ministryInfoState.ministry?.startYear).map(row => row.id))
	}, [gridState.rows, ministryInfoState.ministry?.startYear])

	if (!ministryInfoState.ministryFyData || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: 'unset' }} />
			<Modal
				modalId={userCommentsModalId}
				modalTitle={`Ministry Comments for Discrepancy in ${selectedRow?.values.fiscalYear}`}
				onModalHidden={() => setSelectedRow(undefined)}
				footer={<button type="button" className="btn btn-secondary" data-dismiss="modal">OK</button>}
			>
				{selectedRow?.values.gsDiscrepancyExplanation ?
					<p>
						<b>Income Discrepancy Explanation</b>
						<br />
						{selectedRow.values.gsDiscrepancyExplanation}
					</p>
					:
					null
				}

				{selectedRow?.values.gs990MissingExplanation ?
					<p>
						<b>Missing 990 Explanation</b>
						<br />
						{selectedRow.values.gs990MissingExplanation}
					</p>
					:
					null
				}
			</Modal>

			<Modal
				{...formModal}
				modalTitle={`Edit FY Data: ${selectedRow?.values.fiscalYear}`}
				_onModalHidden={() => setSelectedRow(undefined)}
				size='xl'
			>
				{formModal.show && selectedRow ?
					<MinistryInfoFyDataGeneralDataForm
						afterSave={() => {
							showHideFormModal(false)
							ministryInfoActions.fetchMinistryFyData()
						}}
						ministryInfoFyDataGeneral={selectedRow.values as unknown as IMinistryDataFiscalYearSummaryDocument}
					/>
					:
					null
				}
			</Modal>
		</React.Fragment>
	)
}