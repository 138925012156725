import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { EventType } from "../models/event-site"
import { IEventSiteModelDocument } from "../open-api"
import { FormikSelectField, IFormikSelectFieldProps } from "./forms/formik-select-field"
import { Loading, LoadingPropsSizeEnum } from "./loading"

interface IEventSiteSelectFieldProps extends IFormikSelectFieldProps {
    trainingAreaId?: number
    limitToGroupCoaching?: boolean
    setSites?: Dispatch<SetStateAction<IEventSiteModelDocument[]>> | ((sites: IEventSiteModelDocument[]) => void)
}

export const EventSiteSelectField = (props: IEventSiteSelectFieldProps) => {
    const { trainingAreaId } = props
    const { EventSitesApi } = useContext(AppActionContext)!

    const [state, setState] = useState({
        loading: false,
        sites: [] as IEventSiteModelDocument[]
    })

    const fetchSites = useCallback(async (_trainingAreaId: number) => {
        setState(_state => ({ ..._state, loading: true }))
        const query = await EventSitesApi.apiMiTrainingAreasTrainingAreaIdEventSitesGet(_trainingAreaId)
        const sites = Boolean(props.limitToGroupCoaching) ? query.data.filter(o => o.eventTypes?.includes(EventType["Group Learning"])) : query.data
        setState({
            loading: false,
            sites,
        })
        if (props.setSites) props.setSites(sites)
    }, [])

    const clearSites = useCallback(() => {
        setState({ loading: false, sites: [] })
        if (props.setSites) props.setSites([])
    }, [])

    useEffect(() => {
        if (trainingAreaId !== undefined) {
            fetchSites(trainingAreaId)
        } else if (props.setSites) {
            clearSites()
        }
    }, [trainingAreaId])

    if (state.loading) {
        return <Loading size={LoadingPropsSizeEnum.small} />
    } else {
        return (
            <FormikSelectField
                {...props}
                field={{ ...props.field, disabled: Boolean(props.field.disabled || state.sites.length === 0) }}
                options={state.sites.map(o => ({ value: o.siteId.toString(), label: `${o.locationName}` }))}
                helperText={state.sites.length === 0 && trainingAreaId ? 'No Group Learning sites in the selected training area.' : undefined}
            />
        )
    }
}