import { useContext, useEffect, useRef, useState } from "react";
import { IDefaultProps } from "./component-definitions";
import { AppActionContext } from "../app-store-provider";
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks";
import Helmet from "react-helmet";
import { Grid } from "./grid";
import { SearchIcon } from "../assets";
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions";
import { ISearchResponseModelDocument, IZStatesDocument } from "../open-api";
import { defaultGridState, useGrid } from "../stores/grid-actions";
import { CurrencyDisplay, DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays";
import { gridReducer } from "../stores/grid-reducer";
import { Form, Formik, FormikProps } from 'formik'
import { FormikTextField } from "./forms/formik-text-field";
import { FormikSelectField } from "./forms/formik-select-field";
import { NTEE_CODES_MAJOR, NTEE_CODES_MINOR_Religion_Related } from "../constants";
import { download } from '../services/helpers'
import { unparse } from "papaparse";
import dayjs from "dayjs";
import { Modal } from "./modal";
import { PopperSelectFieldNteeMinor } from "./forms/popper-select-field-ntee-minor";

/* 
We only care about the following foundation types:
02 - Private operating foundation exempt from paying excise taxes or investment income
03 - Private Operating foundation (other)
04 - Private nonoperating foundation

Find the full list here: https://developer.candid.org/docs/interpret-charity-check-api-responses#foundation-code
*/
const defaultFoundationCodes = ['02', '03', '04' ]

interface IGuideStarBaseSearch extends IDefaultProps {
    foundationsOnly?: boolean
 }

const exportLimit = 3000

export const GuideStarBaseSearch = ({ foundationsOnly }: IGuideStarBaseSearch) => {

    const { GuideStarApi, StatesApi } = useContext(AppActionContext)!
    //const appState = useContext(AppStateContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const [searchResponse, setSearchResponse] = useState<ISearchResponseModelDocument>()
    const [exportResponse, setExportResponse] = useState<ISearchResponseModelDocument>()
    const [states, setStates] = useState<IZStatesDocument[]>()
    const [exportModal, showHideExportModal] = useModal()

    const fetchRecords = async (
        searchTerms: string, 
        searchStates: string[] | null, 
        searchZip: string | null, 
        searchRadius: number | null, 
        searchCity: string | null, 
        searchCounty: string | null,
        nteeMinorCodes: string[] | null,
        foundationCodes: string[] | null) => {

        if (!searchTerms && (!nteeMinorCodes || nteeMinorCodes.length == 0) && !searchZip) {
            alert('Please specify at least one field to filter by.')
            return;
        }

        const query = await makeHttpRequestWithUi({
            request: GuideStarApi.apiGuideStarEssentialsPost(
                {
                    searchTerms,
                    from: (gridState.queryState.page - 1) * gridState.queryState.itemsPerPage,
                    size: gridState.queryState.itemsPerPage,
                    filters: {
                        geography: {
                            state: searchStates,
                            zip: searchZip,
                            radius: searchRadius,
                            city: searchCity ? [searchCity] : null,
                            county: searchCounty ? [searchCounty] : null,
                        },
                        organization: {
                            nteeMajorCodes: null,
                            nteeMinorCodes: nteeMinorCodes,
                            foundationCodes: foundationCodes
                        }
                    }
                }
            ),
            disableSuccessToast: true,
            toastErrorMessage: 'There was a problem fetching search results.'
        })

        setSearchResponse(query.data)
    }

    const exportResults = async (
        searchTerms: string, 
        searchStates: string[] | null, 
        searchZip: string | null, 
        searchRadius: number | null, 
        searchCity: string | null, 
        searchCounty: string | null,
        nteeMinorCodes: string[] | null,
        foundationCodes: string[] | null) => {

        const query = await makeHttpRequestWithUi({
            request: GuideStarApi.apiGuideStarEssentialsExportPost(
                {
                    searchTerms,
                    from: 0, // Doesn't matter what we put here
                    size: 25, // Doesn't matter what we put here
                    filters: {
                        geography: {
                            state: searchStates,
                            zip: searchZip,
                            radius: searchRadius,
                            city: searchCity ? [searchCity] : null,
                            county: searchCounty ? [searchCounty] : null,
                        },
                        organization: {
                            nteeMajorCodes: null,
                            nteeMinorCodes: nteeMinorCodes,
                            foundationCodes: foundationCodes
                        }
                    }
                }
            ),
            disableSuccessToast: true,
            toastErrorMessage: 'There was a problem exporting search results.'
        })

        setExportResponse(query.data)
    }

    const downloadData = async () => {
        if (!exportResponse || !exportResponse.hits) return ''
		let totalCount = exportResponse.resultsCount || 0

		// 1) get the data
		// Use the selected rows if the user has selected any rows
		let rows = Object.values(exportResponse.hits)

		// 2) reorganize the columns to match the current column state
		const columns = gridState.columns.filter(o => !o.hide && o.type !== 'actions')
		const header = unparse({ fields: columns.map(o => o.property), data: [] }, { header: true })
		const csv = header + unparse(rows.map(o => {
			//const values: { [key: string]: any } = { ...o }

            const values = {
                organizationName: o.organization.organization_name,
                mission: o.organization.mission,
                ein: o.organization.ein,
                addressLine1: o.geography.address_line_1,
                addressLine2: o.geography.address_line_2,
                city: o.geography.city,
                state: o.geography.state,
                zip: o.geography.zip,
                contactName: o.organization.contact_name,
                contactPhone: o.organization.contact_phone,
                contactEmail: o.organization.contact_email,
                websiteUrl: o.organization.website_url,
                pub78Verified: o.properties.pub78_verified,
                bmfAssets: o.financials.bmf_assets,
                bmfGrossReceipts: o.financials.bmf_gross_receipts,
                totalAssets: o.financials.most_recent_year.total_assets,
                totalRevenue: o.financials.most_recent_year.total_revenue,
            }

			// Flatten any grid item values to a single value, currently we only need worry about arrays
			// Object.keys(o).forEach(property => {
            //     //@ts-ignore
			// 	const val = o[property]
			// 	if (val instanceof Array) {
			// 		values[property] = val.map(_val => _val.display).join(', ')
			// 	} else {
			// 		// The default date format is ISO and Excel doesn't know how to format it.
			// 		// If this is a date value, convert to a format that is consumable by Excel.
			// 		if (val instanceof Date) {
			// 			const dateVal = dayjs(val)
			// 			values[property] = dateVal.format('MM/DD/YYYY hh:mm a')
			// 		}
			// 	}

			// })
			return values
		}), { columns: columns.map(o => o.property), header: false })

        download(`MI Export ${dayjs().format('MM_DD_YYYY')}.csv`, csv)
	}

    const dataSource: GridDataFetch<ISearchResponseModelDocument> = async (_queryState, _searchResponse) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        // const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        // const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        // const query = await makeHttpRequestWithUi({
        //     request: GuideStarApi.apiGuideStarEssentialsGet({
        //         searchTerms: '',
        //         from: (queryState.page - 1) * queryState.itemsPerPage,
        //         size: queryState.itemsPerPage,
        //         // @ts-ignore
        //         filters: undefined
        //         }),
        //     disableSuccessToast: true,
        //     toastErrorMessage: 'There was a problem fetching the list of group learning events.'
        // })

        const rows = (_searchResponse?.hits || []).map<IGridListItem>(item => ({
            id: item.organization.organization_id!,
            values: {
                organizationName: item.organization.organization_name,
                mission: item.organization.mission,
                ein: item.organization.ein,
                addressLine1: item.geography.address_line_1,
                addressLine2: item.geography.address_line_2,
                city: item.geography.city,
                state: item.geography.state,
                zip: item.geography.zip,
                contactName: item.organization.contact_name,
                contactPhone: item.organization.contact_phone,
                contactEmail: item.organization.contact_email,
                websiteUrl: item.organization.website_url,
                pub78Verified: item.properties.pub78_verified,
                bmfAssets: item.financials.bmf_assets,
                bmfGrossReceipts: item.financials.bmf_gross_receipts,
                totalAssets: item.financials.most_recent_year.total_assets,
                totalRevenue: item.financials.most_recent_year.total_revenue,
                nteeCodes: item.taxonomies.ntee_codes?.map(x => x.ntee_code).join(', ') ?? '',
            }
        }))

        return {
            rows,
            count: _searchResponse?.resultsCount ?? 0
        }
    }

    const {...myGridState} = defaultGridState;
    const initialGridState: IGridState = {
        ...myGridState,
        pageSizeOptions: [25],
        //userSessionStateKey: GridUserInteractionStateKey.GroupCoaching,
        rowActions: {
            viewPremier: {
                id: 'viewPremier',
                tooltipText: 'View Premier',
                action: async (options) => options.e.stopPropagation(),
				icon: <SearchIcon />,
                url: (row) => `/guidestar-premier/${row.values.ein}`,
            }
        },
        columns: [
            {
                property: 'organizationName',
                type: 'string',
                width: 150,
                allowFilters: false,
                title: 'Org Name',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'mission',
                type: 'string',
                width: 150,
                allowFilters: false,
                title: 'Mission',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'ein',
                type: 'string',
                width: 100,
                allowFilters: false,
                title: 'EIN',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'addressLine1',
                type: 'string',
                width: 150,
                allowFilters: false,
                title: 'Address Line 1',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'addressLine2',
                type: 'string',
                width: 150,
                allowFilters: false,
                title: 'Address Line 2',
                render: DefaultGridCellDisplay,
                disableSort: true,
                hide: true
            },
            {
                property: 'city',
                type: 'string',
                width: 150,
                allowFilters: false,
                title: 'City',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'state',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'State',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'zip',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'Zip',
                render: DefaultGridCellDisplay,
                disableSort: true,
                hide: true
            },
            {
                property: 'contactName',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'Contact Name',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'contactPhone',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'Contact Phone',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'contactEmail',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'Contact Email',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'websiteUrl',
                type: 'string',
                width: 50,
                allowFilters: false,
                title: 'Website Url',
                render: DefaultGridCellDisplay,
                disableSort: true,
            },
            {
                property: 'pub78Verified',
                type: 'boolean',
                width: 100,
                allowFilters: false,
                title: 'Pub 78 Verified',
                render: DefaultGridCellDisplay,
                disableSort: true,
                hide: true
            },
            {
                property: 'bmfAssets',
                type: 'string',
                width: 120,
                allowFilters: false,
                title: 'Bmf Assets',
                render: CurrencyDisplay,
                disableSort: true,
            },
            {
                property: 'bmfGrossReceipts',
                type: 'string',
                width: 120,
                allowFilters: false,
                title: 'Bmf Gross Receipts',
                render: CurrencyDisplay,
                disableSort: true,
            },
            {
                property: 'totalAssets',
                type: 'string',
                width: 120,
                allowFilters: false,
                title: 'Total Assets',
                render: CurrencyDisplay,
                disableSort: true,
            },
            {
                property: 'totalRevenue',
                type: 'number',
                width: 90,
                allowFilters: false,
                title: 'Total Revenue',
                render: DefaultGridCellDisplay,
                disableSort: true,
                hide: true
            },
            {
                property: 'nteeCodes',
                type: 'string',
                width: 90,
                allowFilters: false,
                title: 'NTEE Codes',
                render: DefaultGridCellDisplay,
                disableSort: true,
                hide: true
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },
        ],
        dataSource,
        rowSelectEnabled: false,
        respectGlobalCommunityFilter: false,
        disableExport: true
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, searchResponse)

    useEffect(() => {
        gridActions.doFetch()
    }, [searchResponse])

    useEffect(() => {
        if (exportResponse && exportResponse.hits && exportResponse.hits.length > 0)
        {
            downloadData()
        }
    }, [exportResponse])

    useEffect(() => {
		StatesApi.apiStatesGet()
			.then(results => {
				setStates(results.data)
			})
    }, []);

    useEffect(() => {
		gridActions.setItemsPerPage(25)
    }, []);

    let _formikProps: FormikProps<any>
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current)
            firstRender.current = false;
        else
        fetchRecords(_formikProps.values.searchTerms, _formikProps.values.searchStates, _formikProps.values.searchZip, _formikProps.values.searchRadius, _formikProps.values.searchCity, _formikProps.values.searchCounty, _formikProps.values.nteeMinorCodes, _formikProps.values.foundationCodes)
    }, [gridState.queryState.page])

    // Can be used to select all minor NTEE codes. Was using it, but Karla said she
    // would prefer to search just the religious minor codes.
    const selectAllNteeMinorCodes = () => {
        console.log('selectAllNteeMinorCodes clicked')
        console.log('_formikProps.values.nteeMinorCodes', _formikProps.values.nteeMinorCodes)
        const allCodes:string[] = []
        NTEE_CODES_MAJOR.forEach(x => x.options.forEach(o => allCodes.push(o.value)));
        console.log('allCodes', allCodes)
        _formikProps.setFieldValue('nteeMinorCodes', allCodes)
    }

        const selectAllNteeMinorReligionCodes = () => {
            console.log('selectAllNteeMinorCodes clicked')
            console.log('_formikProps.values.nteeMinorCodes', _formikProps.values.nteeMinorCodes)
            const allCodes:string[] = []
            NTEE_CODES_MINOR_Religion_Related.forEach(x => allCodes.push(x.value));
            console.log('allCodes', allCodes)
            _formikProps.setFieldValue('nteeMinorCodes', allCodes)
        }

    return (
        <>
            <Helmet>
                <title>{foundationsOnly ? 'Foundation' : 'GuideStar'} Search [BETA]</title>
            </Helmet>

            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item active">Reports</li>
                <li className="breadcrumb-item active" aria-current="page">{foundationsOnly ? 'Foundation' : 'GuideStar'} Search</li>
            </ol>
            </nav>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <SearchIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>{foundationsOnly ? 'Foundation' : 'GuideStar'} Search [BETA]</h3>
                    
                </div>
                <div className="m-2">
                <div>This tool is in beta. It is still very rough around the edges and is a work in progress. If you run into any issues while using it, please contact <a href="mailto:Tod Birdsall">Tod Birdsall</a></div>

                <Formik
                    initialValues={{
                        searchText: '',
                        searchStates: [],
                        searchZip: '',
                        searchRadius: 5,
                        searchCity: '',
                        searchCounty: '',
                        nteeMinorCodes: [],
                        foundationCodes: foundationsOnly ? defaultFoundationCodes : []
                    }}
                    onSubmit={async (values) => {
                        //const newSearch = true
                        gridState.queryState.page = 1
                        if (!values.searchText && values.nteeMinorCodes.length == 0 && !values.searchZip) {
                            alert('Please specify at least one field to filter by.')
                            return;
                        }
                        fetchRecords(values.searchText, values.searchStates, values.searchZip, values.searchRadius, values.searchCity, values.searchCounty, values.nteeMinorCodes, values.foundationCodes)
                    }}
                >
                    {formikProps => {
                        _formikProps = formikProps
                        return (
                          <Form>
                            {/* <FormikEffect formikProps={formikProps} onChange={(prev, next) => {
                                    console.log(next)
                                }} /> */}

<div className='d-flex'>
                                {!foundationsOnly ? <div style={{ flex: 1 }} className='mr-3'>
                                  <FormikTextField
                                    field={{
                                      name: 'searchText',
                                      label: 'Search Text',
                                    }}
                                    labelTooltip="Can be any string you'd like to search on, including EIN, organization name, keywords, etc."
                                  />
                                </div> :
                                null 
                                }
                                <div style={{ flex: 1 }} className='mr-3'>
                                  <FormikTextField
                                    field={{ name: 'searchZip', label: 'Zip' }}
                                  />
                                </div>
                                <div style={{ flex: 1 }} className='mr-3'>
                                  <FormikSelectField
                                    field={{
                                      name: 'searchRadius',
                                      label: 'Radius',
                                    }}
                                    options={[
                                      {
                                        label: '5 miles',
                                        value: '5',
                                      },
                                      {
                                        label: '10 miles',
                                        value: '10',
                                      },
                                      {
                                        label: '25 miles',
                                        value: '25',
                                      },
                                      {
                                        label: '50 miles',
                                        value: '50',
                                      },
                                    ]}
                                  />
                                </div>
                                <div style={{ flex: 1 }} className='mr-3 mt-4'>
                                    <PopperSelectFieldNteeMinor formikProps={formikProps} />
                                  {/* <FormikSelectField
                                    multiple={true}
                                    field={{
                                      name: 'nteeMinorCodes',
                                      label: 'NTEE Minor Codes',
                                    }}
                                    optionGroups={NTEE_CODES_MAJOR.map((x) => ({
                                      id: x.value,
                                      label: x.label,
                                      options: x.options,
                                    }))}
                                  /> */}
                                  {/* <div>
                                    <a
                                      href='#'
                                      onClick={() =>
                                        selectAllNteeMinorReligionCodes()
                                      }
                                      style={{
                                        position: 'relative',
                                        // top: '-20px',
                                      }}
                                    >
                                      Select Religion NTEE Codes
                                    </a>
                                  </div> */}
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    position: 'relative',
                                    top: '24px',
                                  }}
                                  className='mr-3'
                                >
                                  <button
                                    className='btn btn-primary mr-3'
                                    type='submit'
                                  >
                                    Search
                                  </button>
                                  <button
                                    className='btn btn-secondary'
                                    type='button'
                                    disabled={
                                      !searchResponse?.resultsCount ||
                                      searchResponse?.resultsCount < 1
                                    }
                                    onClick={() => showHideExportModal(true)}
                                  >
                                    Export
                                  </button>
                                </div>
                              </div>

                              {/* 20240516 - Narrowing search options down to zip code to reduce user's options. Can add this back in if it is requested.
                                states && <FormikSelectField multiple={true} 
                                    field={{ name: 'searchStates', label: 'State' }}
                                    options={states.filter(state => state.countryId == UNITED_STATES_COUNTRY_ID).map(state => ({ label: `${state.state}`, value: `${state.stateAbbr}` }))}
                            /> */}

                              {/* 20240516 - Narrowing search options down to zip code to reduce user's options. Can add this back in if it is requested.
                                <FormikTextField
                                        field={{ name: 'searchCity', label: 'City' }}
                                    />
                                <FormikTextField
                                        field={{ name: 'searchCounty', label: 'County' }}
                                    /> */}
                          </Form>
                        )
                    }}
                </Formik>
                </div>
                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...exportModal}
                modalTitle='Confirm'
                _onModalHidden={() => {
                    setExportResponse(undefined)
                }}
                footer={
                    <>
                        <button className='btn btn-secondary' onClick={() => showHideExportModal(false)} >Cancel</button>
                        <button
                            className='btn btn-success'
                            onClick={async () => {
                                await exportResults(_formikProps.values.searchTerms, _formikProps.values.searchStates, _formikProps.values.searchZip, _formikProps.values.searchRadius, _formikProps.values.searchCity, _formikProps.values.searchCounty, _formikProps.values.nteeMinorCodes, _formikProps.values.foundationCodes)

                                showHideExportModal(false)
                            }}
                            disabled={!searchResponse?.resultsCount || searchResponse?.resultsCount < 1 || searchResponse?.resultsCount > exportLimit}
                        >
                            Yes, export!
                        </button>
                    </>
                }
            >
                {!searchResponse?.resultsCount || searchResponse?.resultsCount < 1 || searchResponse?.resultsCount > exportLimit ?
                <>You cannot export more than 1,000 records at a time. Please filter your search results further before exporting them.</>
                : 
                <>
                    <strong>NOTE:</strong> Before you export records, please do your best to filter down the search results as much as possible. GuideStar limits the amount of records
                    we can export, so we need to be good stewards of the export feature. Thank you!
                    <br /><br />
                    Are you sure you want to proceed with this export of <strong>{searchResponse?.resultsCount} records</strong>?
                </>
                }
            </Modal>
        </>
    )
}