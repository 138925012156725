import { useReducer, useContext } from "react"
import { AppActionContext } from "../app-store-provider"
import { ContactInfoTab, IContactInfoState } from "./contact-info-definitions"
import { ContactInfoAction, ContactInfoActionType } from "./contact-info-reducer"

export interface IContactInfoActions {
	setLoading: (loading: boolean) => void
	setContactId: (contactId: number) => void
	setActiveTab: (tabId: ContactInfoTab) => void
	fetchContactLmsUserCourses: () => Promise<void>
	fetchLmsUserCourses: () => Promise<void>
	fetchMinistry: () => Promise<void>
	fetchContact: (contactId: number) => Promise<void>
	fetchContactCourses: (contactId: number) => Promise<void>
	fetchContactEditOptions: () => Promise<void>
}

export const useContactInfoActions = (reducer: React.Reducer<IContactInfoState, ContactInfoAction>, initialState: IContactInfoState): [IContactInfoState, IContactInfoActions] => {
	const appActions = useContext(AppActionContext)!

	const [state, dispatch] = useReducer(reducer, initialState)

	const setLoading = (loading: boolean) => dispatch({ type: loading ? ContactInfoActionType.loading : ContactInfoActionType.doneLoading })

	const setContactId = (contactId: number) => dispatch({ type: ContactInfoActionType.setContactId, payload: contactId })

	const setActiveTab = (tabId: ContactInfoTab) => dispatch({ type: ContactInfoActionType.setActiveTab, payload: { tabId } })

	const fetchContactLmsUserCourses = async () => {
		console.log('ContactInfoAction > fetchContactLmsUserCourses')
		if (!state.contact) throw new Error('Cannot load courses for contact - no contact defined.')
		dispatch({ type: ContactInfoActionType.setContactCourses, payload: (await appActions.MinistryContactsApi.apiMinistryContactsIdLmsUserCourseInfoGet(state.contact.ministryContactId)).data })
    }

	const fetchLmsUserCourses = async () => {
		console.log('MinistryInfoAction > fetchLmsUserCourses')
		if (!state.contact?.ministryId) throw new Error('Cannot load courses for ministry - no ministry defined.')
		dispatch({ type: ContactInfoActionType.setContactCourses, payload: (await appActions.MinistriesApi.apiMinistriesIdLmsUserCourseInfoGet(state.contact.ministryId)).data })
    }

	const fetchMinistry = async () => {
		console.log('ContactInfoAction > fetchMinistry')
		if (!state.contact?.ministryId) throw new Error('Cannot load ministry - no ministry defined.')
		dispatch({ type: ContactInfoActionType.fetchMinistry, payload: (await appActions.MinistriesApi.apiMinistriesIdGet(state.contact.ministryId)).data })
	}

	const fetchContact = async (contactId: number) => {
		console.log('ContactInfoAction > fetchContact')
		if (!contactId) throw new Error('Cannot load contact - no contact defined.')
		dispatch({ type: ContactInfoActionType.fetchContact, payload: (await appActions.MinistryContactsApi.apiMinistryContactsIdGet(contactId)).data })
	}

	const fetchContactCourses = async (contactId: number) => {
		console.log('ContactInfoAction > fetchContactCourses')
		if (!contactId) throw new Error('Cannot load contact - no contact defined.')
		dispatch({ type: ContactInfoActionType.fetchContactCourses, payload: (await appActions.MinistryContactsApi.apiMinistryContactsIdLmsUserCourseInfoGet(contactId)).data })
	}

	const fetchContactEditOptions = async () => {
		console.log('ContactInfoAction > fetchContactEditOptions')
		dispatch({ type: ContactInfoActionType.fetchContactEditOptions, payload: (await appActions.MinistryContactsApi.apiMinistryContactsContactEditOptionsGet()).data })
	}

	const actions = {
		setLoading,
		setContactId,
		setActiveTab,
		fetchContactLmsUserCourses,
		fetchLmsUserCourses,
		fetchMinistry,
		fetchContact,
		fetchContactEditOptions,
		fetchContactCourses
	}

	return [state, actions]
}