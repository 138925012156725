import { okHttpStatus, uuidv4 } from './helpers'
import { registerLoadingTask, deregisterLoadingTask } from './loading-service'
import { useContext, useRef, useState, createRef, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { ReactComponent as CheckIcon } from '../assets/check.svg'
import { ReactComponent as ExclamationCirclIcon } from '../assets/exclamation-circle.svg'
import React from 'react'
import { NOTE_TYPES, TOAST_ANIMATION_DURATION } from '../constants'
import { throttle } from 'lodash'
import { IGridColumn } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, MinistryNameDisplay, GrantEligibleDisplay, GridActionCell, GridListItemObjectDisplay, MinistryPhoneWithExt, ConditionalFormatColorDisplay, AccountLevelDisplay, AccountStatusDisplay, HyperlinkCell, EmailCellDisplay, ProspectNameDisplay, PossibleMinistryMatchesDisplay, UserDisabledDisplay, MinistryNameMinistryIdDisplay, SanitizeHtmlContentDisplay, MinistryContactNameDisplay } from '../components/grid-cell-displays'
import { App, UserModel, UserPermissionLevel } from '../models/user'
import moment from 'moment'
import { useAuth} from "react-oidc-context";
import { Link, navigate } from '@reach/router'
import { getMailBlastStatusFromStatusId } from '../models/custom-email-blast'
import dayjs from 'dayjs'


export const useGetAuthToken = () => {
	const auth = useAuth();	

	const getAuthToken = async () => {
		const token = auth.user?.access_token;

		if (token) {
			return token
		} else {
			console.error('No user account signed in. Shouldn\'t be possible...')
			return '';
		}
	}

	return getAuthToken
}

/* 
	Shared interface for HTTP requests that have an HTTP status code.
	Allows us to use shared implementation for both Axios and Fetch HTTP requests since both have a number property named 'status'.
*/
interface IHasHTTPStatusCode {
	status: number
	statusText: string
}

/* 
	Provides a wrapper for HTTP requests that does the followin:
	- Waits for a request 
	- Shows/hides loading spinner while request is in progress
	- Adds a toast on success or error
	- Returns the response from the HTTP request
	- Calls async onSuccess and on onFailure callbacks after a request 
			(to allow for "grouped" operations to follow the initial request, but still happen before the loading spinner is stopped or a toast shown)
*/
interface IWrappedHTTPRequestOptions<ReturnType extends IHasHTTPStatusCode> {
	request: Promise<ReturnType>

	// Loading message is shown by default, but you can disable (e.g. you want a toasts, but don't want to hold up the UI with a loading indicator)
	disableLoading?: boolean

	// Toasts are shown by default for both success and failure, you can disable one or both as desired
	disableFailureToast?: boolean
	disableSuccessToast?: boolean

	// Optional messages for the toast - generic fallbacks will be used if no messages are provided
	toastSuccessMessage?: string
	toastErrorMessage?: string

	/* 
		Optional function that will be called after a SUCCESSFUL request (can be used for refreshing data after an operation is performed)
		This is provided here so that a refresh can be included as part of the UX for a single action (instead of having to separately refresh the data after an action was complete)
	*/
	onRequestSuccess?: () => Promise<void>

	/* 
		Optional function that will be called after a FAILED request (can be used for hiding modals, or showing other error state)
	*/
	onRequestFailure?: () => Promise<void>
}


export const useHTTPRequestUiWrapper = () => {
	const appActions = useContext(AppActionContext)!

	const wrapApiRequest = async <ReturnType extends IHasHTTPStatusCode,>(options: IWrappedHTTPRequestOptions<ReturnType>): Promise<ReturnType> => {
		const { request, toastSuccessMessage, toastErrorMessage, onRequestSuccess, onRequestFailure, disableSuccessToast, disableFailureToast, disableLoading } = options

		const taskId = disableLoading ? '' : registerLoadingTask()

		try {
			const query = await request

			if (okHttpStatus(query.status)) {
				if (onRequestSuccess) await onRequestSuccess()
				deregisterLoadingTask(taskId)

				if (!disableSuccessToast && appActions) {
					appActions.addToast({
						id: uuidv4(),
						timestamp: new Date(),
						title: 'Success',
						body: toastSuccessMessage || 'Operation was successful.',
						icon: <CheckIcon className='text-success' />,
						autoDismissTimeOut: TOAST_ANIMATION_DURATION + 2500,
					})
				}
			} else {
				if (onRequestFailure) onRequestFailure()
				deregisterLoadingTask(taskId)
				if (!disableFailureToast && appActions) {
					appActions.addToast({
						id: uuidv4(),
						timestamp: new Date(),
						title: 'Error',
						body: toastErrorMessage || query.statusText || 'There was an error performing your request.',
						icon: <ExclamationCirclIcon className='text-danger' />,
					})
				}
			}
			return query
		} catch (e) {
			console.log('this was the failure', e)
			if (onRequestFailure) onRequestFailure()
			deregisterLoadingTask(taskId)
			if (!disableFailureToast && appActions) {
				appActions.addToast({
					id: uuidv4(),
					timestamp: new Date(),
					title: 'Error',
					body: (
						<div>
							{toastErrorMessage ? <p>{toastErrorMessage}</p> : null}
							Unexpected error: {`${e.message}`}
						</div>
					),
					icon: <ExclamationCirclIcon className='text-danger' />
				})
			}
			throw (e)
		}
	}

	return wrapApiRequest
}

// export const useDownloadAndViewFile = () => {
// 	const appActions = useContext(AppActionContext)!
// 	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

// 	const downloadAndViewFile = async (ministryId: number, ministryFileId: number) => {
// 		// https://stackoverflow.com/questions/37462263/open-filestreamresult-by-ajax-as-downloaded-file
// 		const fileQuery = await makeHTTPRequestWithUi({
// 			request: appActions.MinistryFilesApi.apiMinistryFilesDownloadMinistryIdMinistryFileIdGet(ministryId, ministryFileId, { responseType: 'blob' }),
// 			toastErrorMessage: 'There was an error attempting to download the file.',
// 			disableSuccessToast: true,
// 		})

// 		if (fileQuery.status === 200) {
// 			const file = fileQuery.data as unknown as string
// 			downloadFileAttachmentFromHttpResponse(fileQuery.headers, file)
// 		}
// 	}

// 	return downloadAndViewFile
// }

export const useAsyncFnThrottleWrapper = <FnReturnType, FnArgumentType extends any[]>(fn: (...args: FnArgumentType) => FnReturnType | Promise<FnReturnType>, ms: number = 500) => {
	const timeout = useRef<ReturnType<typeof setTimeout>>()

	const throttledFn = async (...args: FnArgumentType): Promise<FnReturnType> => {
		if (timeout.current) clearTimeout(timeout.current)

		return new Promise<FnReturnType>((resolve, reject) => {
			timeout.current = setTimeout(() => {
				try {
					resolve(fn(...args))
				} catch (e) {
					reject(e)
				}
			}, ms)
		})
	}

	return throttledFn
}

export const useVisibility = <Element extends HTMLElement>(
	offset = 0,
	throttleMilliseconds = 100
): [Boolean, React.RefObject<Element>] => {
	const [isVisible, setIsVisible] = useState(false)
	const currentElement = createRef<Element>()

	const onScroll = throttle(() => {
		if (!currentElement.current) {
			setIsVisible(false)
			return
		}
		const top = currentElement.current.getBoundingClientRect().top
		setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight)
	}, throttleMilliseconds)

	useEffect(() => {
		window.addEventListener("scroll", onScroll)
		return () => window.removeEventListener("scroll", onScroll)
	})

	return [isVisible, currentElement]
}

export const useModal = (): [
	{ modalId: string, show: boolean, closeModal: () => void },
	((show?: boolean) => void)
] => {
	const [modalState, setModalState] = useState({
		modalId: uuidv4(),
		show: false,
	})

	const showHideModal = (show?: boolean) => {
		if (show === undefined) show = !modalState.show
		setModalState({ ...modalState, show })
	}

	return [
		{
			...modalState,
			closeModal: () => showHideModal(false)
		},
		showHideModal
	]
}

export const useMailLogDefaultColumns = (): IGridColumn[] => {
	const columns: IGridColumn[] = [
		{
			property: 'dSent',
			type: 'date',
			width: 150,
			title: 'Date Sent',
			render: DefaultGridCellDisplay,
			allowFilters: true,
			dateFormatOverride: 'MM/DD/YYYY h:mm a',
			filterRequired: true,
			filterDefaultValue: moment().subtract(1, 'week').format('MM/DD/YYYY'),
			filterValidation: {
				maxRange: 90
			}
		},
		{
			property: 'ministryName',
			type: 'string',
			width: 200,
			title: 'Ministry Name',
			allowFilters: true,
			render: DefaultGridCellDisplay,
		},
		{
			property: 'fromAdx',
			type: 'string',
			width: 150,
			title: 'From',
			allowFilters: true,
			render: DefaultGridCellDisplay,
		},
		{
			property: 'toAdx',
			type: 'string',
			width: 150,
			title: 'To',
			allowFilters: true,
			render: DefaultGridCellDisplay,
		},
		{
			property: 'subject',
			type: 'string',
			width: 150,
			title: 'Subject',
			allowFilters: true,
			render: DefaultGridCellDisplay,
		},
		{
			property: 'status',
			type: 'string',
			width: 100,
			title: 'Status',
			allowFilters: true,
			render: DefaultGridCellDisplay,
			filterOptions: [
				{ label: 'Failed ', value: 'Failed' },
				{ label: 'Delivered ', value: 'Delivered' },
				{ label: 'In Progress ', value: 'In Progress' },
				{ label: 'Blacklisted ', value: 'Blacklisted' },
			]
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 85,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
		},
	]

	return columns
}

export const useMinistryProspectsDefaultColumns = (): IGridColumn[] => {
	const appState = useContext(AppStateContext)!

	const columns: IGridColumn[] = [
		{
			property: 'organizationName',
			type: 'string',
			width: 270,
			allowFilters: true,
			title: 'Ministry Name',
			render: ProspectNameDisplay,
			description: 'The ministry name.'
		},
		{
			property: 'firstName',
			type: 'string',
			width: 140,
			allowFilters: true,
			title: 'First Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'lastName',
			type: 'string',
			width: 140,
			allowFilters: true,
			title: 'Last Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 144,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			description: 'The first icon shows the prospect info window.<br>The second icon allows you to add notes to the prospect.<br>The third icon allows you to delete the prospect.<br>The fourth icon allows you to manage tags associated with the prospect.'
		},
		{
			property: 'branchAbbr',
			type: 'string',
			width: 105,
			allowFilters: true,
			title: 'Community',
			render: DefaultGridCellDisplay,
			description: 'The community that the propsect is assigned.',
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
		},
		{
			property: 'trainingAreaAbbr',
			type: 'string',
			width: 73,
			allowFilters: true,
			title: 'T. Area',
			render: DefaultGridCellDisplay,
			description: 'The training area that the propsect is assigned.'
		},
		{
			property: 'source',
			type: 'string',
			width: 179,
			allowFilters: true,
			title: 'Source',
			render: DefaultGridCellDisplay,
			description: 'How the prospect was entered into our system.'
		},
		{
			property: 'tags',
			type: 'string',
			allowFilters: true,
			disableSort: true,
			filterTypeOverride: 'tags',
			width: 150,
			title: 'Tags',
			render: GridListItemObjectDisplay,
			description: 'Tags that are assigned to the prospect.'
		},
		{
			property: 'businessPhone',
			type: 'string',
			width: 132,
			allowFilters: true,
			title: 'Phone Number',
			render: DefaultGridCellDisplay,
			description: 'The prospect phone number',
		},
		{
			property: 'email',
			type: 'string',
			width: 220,
			allowFilters: true,
			title: 'Email Address',
			render: EmailCellDisplay,
			description: 'The prospect email address',
		},
		{
			property: 'monthsSinceLastContact',
			type: 'number',
			width: 177,
			allowFilters: true,
			title: 'Last Contact (months)',
			render: DefaultGridCellDisplay,
			description: 'The most recent email (excluding event related notifications), or the most recent note left by the Area Director.'
		},
		{
			property: 'ageInMonths',
			type: 'number',
			width: 124,
			allowFilters: true,
			title: 'Age (months)',
			render: DefaultGridCellDisplay,
			description: 'How long (in months) that the prospect has been in the system'
		},
		{
			property: 'possibleMatchCount',
			type: 'number',
			width: 90,
			allowFilters: false,
			title: 'Matches',
			render: PossibleMinistryMatchesDisplay,
		},
	]

	if (appState.currentUser?.bSuperUser) {
		columns.unshift({
			property: 'createdByName',
			type: 'string',
			width: 207,
			allowFilters: true,
			title: 'Created By',
			render: DefaultGridCellDisplay,
			description: 'The user who created the prospect',
		})
	}

	return columns
}


export const useCustomMailBlastColumns = (): IGridColumn[] => {
	const appState = useContext(AppStateContext)!

	const columns: IGridColumn[] = [
		{
			property: 'dCreated',
			type: 'date',
			width: 100,
			allowFilters: true,
			title: 'Created',
			render: DefaultGridCellDisplay,
			description: 'The date that the email blast was created.'
		},
		{
			property: 'dSend',
			type: 'date',
			width: 100,
			allowFilters: true,
			title: 'Sent/Scheduled',
			render: (col, row) => {
				const scheduleDate = row.values.scheduleDate?.toString()
				if (scheduleDate) return dayjs(scheduleDate).format('M/D/YYYY h:mm A')
				const dSend = row.values.dSend?.toString()
				if (dSend) return dayjs(dSend).format('M/D/YYYY h:mm A')
				return ''
			},
			description: 'The date/time that the email blast was sent.',
			dateFormatOverride: 'MM/DD/YYYY hh:mm a'
		},
		{
			property: 'creatorFirstName',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'Created By: First Name',
			render: DefaultGridCellDisplay,
			description: 'Who created the blast.',
		},
		{
			property: 'creatorLastName',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'Created By: Last Name',
			render: DefaultGridCellDisplay,
			description: 'Who created the blast.',
		},
		{
			property: 'layoutName',
			type: 'string',
			width: 140,
			allowFilters: false,
			title: 'Layout',
			hide: true,
			render: DefaultGridCellDisplay,
			description: 'The header and footer that was chosen.',
		},
		{
			property: 'fromEmail',
			type: 'string',
			width: 140,
			allowFilters: true,
			title: 'From',
			render: DefaultGridCellDisplay,
			description: 'The from address that will be used with the blast.'
		},
		{
			property: 'eventTitle',
			type: 'string',
			width: 140,
			allowFilters: true,
			hide: true,
			title: 'Event',
			render: DefaultGridCellDisplay,
			description: 'The event that the blast is tied to.'
		},
		{
			property: 'subject',
			type: 'string',
			width: 140,
			allowFilters: true,
			title: 'Subject',
			render: DefaultGridCellDisplay,
			description: 'The subject of the blast.'
		},
		{
			property: 'mailStatusId',
			type: 'string',
			width: 140,
			allowFilters: true,
			title: 'Status',
			render: (col, row) => {
				const status = row.values[col.property]
				if (typeof status === 'number') return getMailBlastStatusFromStatusId(status)
				return ''
			},
			description: 'The status of the blast.',
			filterOptions: [
				{ label: 'Draft ', value: '1' },
				{ label: 'Awaiting Approval', value: '2' },
				{ label: 'Ready to Send', value: '3' },
				{ label: 'In Progress', value: '4' },
				{ label: 'Complete', value: '5' },
				{ label: 'Archived', value: '6' },
				{ label: 'Scheduled', value: '7' },
			],
			conditionalCellCSSFormatting: (row) => {
				if (row.values.mailStatusId === 'Complete') return 'bg-success-7'
				if (row.values.mailStatusId === 'Scheduled') return 'bg-warning-7'
				return ''
			},
		}
	]

	if (UserModel.checkPermissionLevelForUser(App.MailBlast, UserPermissionLevel.Modify, appState.currentUser)) {
		columns.push({
			property: 'grid_actions',
			type: 'actions',
			width: 85,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			description: 'The first icon allows you to see the details of the blast.<br>The second icon allows you to view the recipients of the blast.<br>The third icon allows you to delete the blast.'
		})
	}

	return columns
}

export const useMailBlastRecipientsDefaultColumns = (): IGridColumn[] => {
	return [
		{
			property: 'ministryName',
			type: 'string',
			width: 90,
			allowFilters: true,
			title: 'Ministry',
			render: MinistryNameMinistryIdDisplay
		},
		{
			property: 'contactName',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'Contact',
			render: MinistryContactNameDisplay,
		},
		{
			property: 'email',
			type: 'string',
			width: 153,
			allowFilters: true,
			title: 'Email',
			render: DefaultGridCellDisplay
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 70,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
		},
		{
			property: 'status',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Success',
			render: DefaultGridCellDisplay
		},
	]
}

export const usePotentialRecipientsDefaultColumns = (): IGridColumn[] => {
	return [
		{
			property: 'branchAbbr',
			type: 'string',
			width: 50,
			allowFilters: true,
			title: 'Community',
			render: DefaultGridCellDisplay
		},
		{
			property: 'secondaryBranchAbbr',
			type: 'string',
			width: 50,
			allowFilters: true,
			title: 'Secondary Community',
			render: DefaultGridCellDisplay
		},
		{
			property: 'ministryName',
			type: 'string',
			width: 90,
			allowFilters: true,
			title: 'Organization',
			render: MinistryNameMinistryIdDisplay
		},
		{
			property: 'firstName',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'First Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'lastName',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'Last Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'email',
			type: 'string',
			width: 153,
			allowFilters: true,
			title: 'Email',
			render: DefaultGridCellDisplay
		},
		{
			property: 'contactType',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Contact Type',
			render: DefaultGridCellDisplay
		},
		{
			property: 'contactSubType',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Title',
			render: DefaultGridCellDisplay
		},
	]
}

export const usePossibleMinistryMatchesColumns = (): IGridColumn[] => {
	return [
		{
			property: 'dateRegistered',
			type: 'date',
			width: 80,
			allowFilters: false,
			title: 'Date Registered',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'ministryName',
			type: 'string',
			width: 200,
			allowFilters: false,
			title: 'Ministry Name',
			render: MinistryNameDisplay
		},
		// {
		// 	property: 'grid_actions',
		// 	type: 'actions',
		// 	width: 50,
		// 	disableSort: true,
		// 	allowFilters: false,
		// 	title: 'Actions',
		// 	render: GridActionCell,
		// },
		{
			property: 'contactName',
			type: 'string',
			width: 125,
			allowFilters: false,
			title: 'Contact Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'contactEmail',
			type: 'string',
			width: 115,
			allowFilters: false,
			title: 'Email',
			render: EmailCellDisplay,
		},
		{
			property: 'eventCount',
			type: 'number',
			width: 60,
			allowFilters: false,
			title: 'Events Att',
			render: DefaultGridCellDisplay,
		},
	]
}

export const useMinistryProspectImportDefaultColumns = (): IGridColumn[] => {

	const columns: IGridColumn[] = [
		{
			property: 'First Name',
			type: 'string',
			width: 100,
			allowFilters: false,
			title: 'First Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Last Name',
			type: 'string',
			width: 100,
			allowFilters: false,
			title: 'Last Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Email',
			type: 'string',
			width: 150,
			allowFilters: false,
			title: 'Email',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Ministry Name',
			type: 'string',
			width: 150,
			allowFilters: false,
			title: 'Ministry Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Title',
			type: 'string',
			width: 130,
			allowFilters: false,
			title: 'Title',
			render: DefaultGridCellDisplay,
		},

		{
			property: 'Business Phone',
			type: 'string',
			width: 120,
			allowFilters: false,
			title: 'Bus. Phone',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Mobile Phone',
			type: 'string',
			width: 120,
			allowFilters: false,
			title: 'Mobile Phone',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Address',
			type: 'string',
			width: 120,
			allowFilters: false,
			title: 'Address',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Address 2',
			type: 'string',
			width: 120,
			allowFilters: false,
			title: 'Address 2',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'City',
			type: 'string',
			width: 100,
			allowFilters: false,
			title: 'City',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'State',
			type: 'string',
			width: 100,
			allowFilters: false,
			title: 'State',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Zip',
			type: 'string',
			width: 60,
			allowFilters: false,
			title: 'Zip',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'Website',
			type: 'string',
			width: 200,
			allowFilters: false,
			title: 'Website',
			render: DefaultGridCellDisplay,
		},
	]

	return columns
}

export const useMinistryManagementDefaultColumns = (showingArchived: boolean): IGridColumn[] => {
	const appState = useContext(AppStateContext)!

	const defaultColumns: IGridColumn[] = [
		{
			property: 'ministryId',
			type: 'number',
			width: 100,
			hide: true,
			allowFilters: true,
			title: 'Ministry ID',
			render: DefaultGridCellDisplay,
			description: 'Our unique Database ID for this ministry record.'
		},
		{
			property: 'ratingText',
			type: 'string',
			width: 70,
			hide: true,
			allowFilters: false,
			title: 'Rating',
			render: DefaultGridCellDisplay,
			description: 'The ministry rating.'
		},
		{
			property: 'dRegistered',
			type: 'date',
			width: 140,
			allowFilters: true,
			title: 'Account Created',
			render: DefaultGridCellDisplay,
			description: 'The date the ministry created their ministry account'
		},		
		{
			property: 'startYear',
			type: 'number',
			width: 100,
			hide: true,
			allowFilters: true,
			title: 'Start Year',
			render: DefaultGridCellDisplay,
			description: 'The year the ministry registered with MI.'
		},
		// {
		// 	property: 'numGrants',
		// 	type: 'number',
		// 	width: 160,
		// 	hide: true,
		// 	allowFilters: true,
		// 	title: 'Num Grants',
		// 	render: DefaultGridCellDisplay,
		// 	description: 'The total number of grants that have been approved or are in progress.',
		// 	align: 'right'
		// },
		{
			property: 'ministryName',
			type: 'string',
			width: 270,
			allowFilters: true,
			title: 'Ministry Name',
			render: MinistryNameDisplay,
			description: 'The ministry name.'
		},
		{
			property: 'isCurrent',
			type: 'boolean',
			width: 120,
			allowFilters: true,
			title: 'Current',
			render: DefaultGridCellDisplay,
			description: 'Current ministries have attended training or coaching/consulting in the last 18 months or have created an account within the last 12 months. This is a narrower number than Active.',
		},
		{
			property: 'grantEligible',
			type: 'string',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Grant Eligible',
			render: GrantEligibleDisplay,
			description: 'Whether this ministry meets the basic eligibility requirements to be considered for a grant. To be eligible a ministry must not be archived, must have an OK status, must have income under $2M, and must be present in the IRS Pub78 Database (or be grouped with an organization that exists in the Pub78 Database).',
			filterOptions: [
				{ label: 'Yes ', value: 'Yes ' },
				{ label: 'No ', value: 'No ' },
			],
		},
		{
			property: 'legalName',
			type: 'string',
			width: 270,
			hide: true,
			allowFilters: true,
			title: 'Legal Name',
			render: DefaultGridCellDisplay,
			description: 'The ministry legal name.'
		},
		{
			property: 'federalTaxNo',
			type: 'string',
			width: 90,
			hide: true,
			allowFilters: true,
			title: 'Tax ID',
			render: DefaultGridCellDisplay,
			description: "The ministry's Tax ID or EIN number.",
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 120,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			description: "The first icon shows the Ministry Info window.<br>The second icon allows you to enter a note.<br>The third icon launches the partner site.<br>The fourth icon lets you manage tags for the ministry."
		},
		{
			property: 'branchAbbr',
			type: 'string',
			width: 110,
			allowFilters: true,
			title: 'Community',
			render: DefaultGridCellDisplay,
			description: 'The community that the ministry is attached to',
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
		},
		{
			property: 'localBranchAbbr',
			type: 'string',
			width: 110,
			allowFilters: true,
			title: 'Secondary Community',
			render: DefaultGridCellDisplay,
			description: 'The secondary community that the ministry is attached to',
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
		},
		{
			property: 'trainingAreaAbbr',
			type: 'string',
			width: 75,
			allowFilters: true,
			title: 'T. Area',
			render: DefaultGridCellDisplay,
			description: 'The training area that the ministry is attached'
		},
		{
			property: 'tags',
			type: 'string',
			allowFilters: true,
			filterTypeOverride: 'tags',
			width: 106,
			title: 'Tags',
			render: GridListItemObjectDisplay,
			description: 'Tags associated with the ministry'
		},
		{
			property: 'serviceArea',
			type: 'string',
			width: 165,
			hide: true,
			allowFilters: true,
			title: 'Service Area',
			render: DefaultGridCellDisplay,
			description: 'The ministry\'s service area'
		},
		{
			property: 'organizationServe',
			type: 'string',
			width: 315,
			hide: true,
			allowFilters: true,
			title: 'Sector',
			render: DefaultGridCellDisplay,
			description: 'The ministry\'s sector'
		},
		{
			property: 'levelId',
			type: 'string',
			width: 130,
			allowFilters: true,
			filterOptions: [
				{ label: 'Teaching', value: '1' },
				{ label: 'Coaching', value: '2' },
				{ label: 'Consulting', value: '3' },
			],
			title: 'Account Level',
			render: AccountLevelDisplay,
			description: 'The level the ministry is assigned.',
		},
		{
			property: 'profileStatus',
			type: 'string',
			width: 130,
			allowFilters: true,
			title: 'Account Status',
			render: AccountStatusDisplay,
			description: 'Displays OK when the account is complete.<br>GRC is displayed when the ministry is within a 60 day grace period after a tax year roll.<br>SUB is displayed when the account has been submitted but not yet approved.<br>INC is displayed when the ministry has an incomplete account.<br>PRE is displayed when the ministry is within the 60 day window after being moved to the granting level.',
			filterOptions: [
				{ label: 'OK ', value: 'OK' },
				{ label: 'GRC ', value: 'GRC' },
				{ label: 'PRE ', value: 'PRE ' },
				{ label: 'SUB ', value: 'SUB' },
				{ label: 'INC ', value: 'INC' },
			],
		},
		{
			property: 'firstName',
			type: 'string',
			width: 155,
			allowFilters: true,
			title: 'First Name',
			render: DefaultGridCellDisplay,
			description: 'The primary contact for the ministry'
		},
		{
			property: 'lastName',
			type: 'string',
			width: 155,
			allowFilters: true,
			title: 'Last Name',
			render: DefaultGridCellDisplay,
			description: 'The primary contact for the ministry'
		},
		{
			property: 'primaryContactEmail',
			type: 'string',
			width: 219,
			hide: true,
			allowFilters: true,
			title: 'Primary Email',
			render: EmailCellDisplay,
			description: 'The primary contact for the ministry'
		},
		{
			property: 'businessPhone',
			type: 'string',
			width: 135,
			hide: true,
			allowFilters: true,
			title: 'Prim Bus Phone',
			render: DefaultGridCellDisplay,
			description: 'The business phone number for the primary contact',
		},
		{
			property: 'cellPhone',
			type: 'string',
			width: 135,
			hide: true,
			allowFilters: true,
			title: 'Prim Cell Phone',
			render: DefaultGridCellDisplay,
			description: 'The cell phone number for the primary contact',
		},
		{
			property: 'phone1',
			type: 'string',
			width: 120,
			allowFilters: true,
			title: 'Bus Phone',
			render: MinistryPhoneWithExt,
			description: 'The phone number for the ministry'
		},
		{
			property: 'profileCompleted',
			type: 'number',
			width: 115,
			hide: true,
			allowFilters: true,
			title: 'Completeness',
			render: DefaultGridCellDisplay,
			description: 'The completeness of the ministry account.',
		},
		{
			property: 'wkAtt',
			type: 'number',
			width: 75,
			hide: true,
			allowFilters: true,
			title: 'Wk. Att',
			render: ConditionalFormatColorDisplay,
			description: 'Of the last 6 workshops that were available, how many did the ministry attend?',
		},
		// {
		// 	property: 'homeworkPercentage',
		// 	type: 'number',
		// 	width: 112,
		// 	hide: true,
		// 	allowFilters: true,
		// 	title: 'Hwk',
		// 	render: ConditionalFormatColorDisplay,
		// 	description: 'Amount of homework uploaded vs the number of events attended',
		// 	align: 'right'
		// },
		// {
		// 	property: 'evalPercentage',
		// 	type: 'number',
		// 	width: 110,
		// 	hide: true,
		// 	allowFilters: true,
		// 	title: 'Evals',
		// 	render: ConditionalFormatColorDisplay,
		// 	description: 'Amount of evals submitted vs the number of events attended',
		// 	align: 'right'
		// },
		{
			property: 'servedLy',
			type: 'number',
			width: 100,
			hide: true,
			allowFilters: true,
			title: '# Served LY',
			render: DefaultGridCellDisplay,
			description: 'The number served last year'
		},
		// {
		// 	property: 'dRegistered',
		// 	type: 'date',
		// 	width: 153,
		// 	allowFilters: true,
		// 	title: 'Registered',
		// 	render: DefaultGridCellDisplay,
		// 	description: 'The date the ministry created their ministry account',
		// 	align: 'center'
		// },
		// {
		// 	property: 'profileDeadline',
		// 	type: 'date',
		// 	width: 210,
		// 	hide: true,
		// 	allowFilters: true,
		// 	title: 'Account Deadline',
		// 	render: DefaultGridCellDisplay,
		// 	description: 'The date that the account must be complete to continue training',
		// 	align: 'right'
		// },
		// {
		// 	property: 'attendInLast12Mos',
		// 	type: 'boolean',
		// 	width: 147,
		// 	hide: true,
		// 	allowFilters: true,
		// 	title: 'Att 12mo',
		// 	render: DefaultGridCellDisplay,
		// 	description: 'Whether or not this ministry has attended a workshop, lab, seminar, or webinar in the last 12 months',
		// 	align: 'center'
		// },
		// {
		// 	property: 'attendConsultInLast12Mos',
		// 	type: 'boolean',
		// 	width: 156,
		// 	hide: true,
		// 	allowFilters: true,
		// 	title: '12mo Cons',
		// 	render: DefaultGridCellDisplay,
		// 	description: 'Whether or not this ministry has attended a consulting appointment in the last 12 months',
		// 	align: 'center'
		// },
		{
			property: 'eventsAttended',
			type: 'number',
			width: 100,
			allowFilters: true,
			title: 'Events Att',
			render: DefaultGridCellDisplay,
			description: 'The number of events attended',
		},
		{
			property: 'physicalAddress1',
			type: 'string',
			width: 225,
			hide: true,
			allowFilters: true,
			title: 'Address',
			render: DefaultGridCellDisplay,
			description: 'The physical address.'
		},
		{
			property: 'physicalAddress2',
			type: 'string',
			width: 128,
			hide: true,
			allowFilters: true,
			title: 'Address Line 2',
			render: DefaultGridCellDisplay,
			description: 'The physical address2.'
		},
		{
			property: 'physicalCity',
			type: 'string',
			width: 100,
			allowFilters: true,
			hide: true,
			title: 'City',
			render: DefaultGridCellDisplay,
			description: 'The physical city.'
		},
		{
			property: 'physicalState',
			type: 'string',
			width: 70,
			allowFilters: true,
			hide: true,
			title: 'State',
			render: DefaultGridCellDisplay,
			description: 'The physical state.'
		},
		{
			property: 'physicalPostalCode',
			type: 'string',
			width: 77,
			allowFilters: true,
			hide: true,
			title: 'Postal Code',
			render: DefaultGridCellDisplay,
			description: 'The physical postal code.'
		},
		{
			property: 'physicalCountry',
			type: 'string',
			width: 100,
			allowFilters: true,
			hide: true,
			title: 'Country',
			render: DefaultGridCellDisplay,
			description: 'The physical country.'
		},
		{
			property: 'fax',
			type: 'string',
			width: 100,
			allowFilters: true,
			hide: true,
			title: 'Fax',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'siteUrl',
			type: 'string',
			width: 200,
			allowFilters: true,
			hide: true,
			title: 'Website',
			render: HyperlinkCell,
		},
		{
			property: 'dec_For_Christ',
			type: 'number',
			width: 105,
			allowFilters: true,
			hide: true,
			title: 'Evangelism',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'involvement',
			type: 'number',
			width: 115,
			allowFilters: true,
			hide: true,
			title: 'Involvement',
			render: DefaultGridCellDisplay,
			description: 'A numeric value describing how involved the ministry has been with MI. Click \'Help\' for more information.',
		},
		{
			property: 'involvementRolling12Mo',
			type: 'number',
			width: 160,
			allowFilters: true,
			title: '12mo Involvement',
			render: DefaultGridCellDisplay,
			description: 'A numeric value describing how involved the ministry has been with MI, based on a rolling 12 month window',
		},
		{
			property: 'nteeCodes',
			type: 'string',
			width: 110,
			allowFilters: true,
			title: 'NTEE Codes',
			render: DefaultGridCellDisplay,
			description: 'NTEE Codes pulled from Guidestar',
			hide: true
		},
		{
			property: 'bNoReporting',
			type: 'boolean',
			width: 140,
			hide: true,
			allowFilters: true,
			title: 'Show in Reports',
			render: DefaultGridCellDisplay,
			description: 'Whether or not to show this ministry in reports',
		},
		{
			property: 'bSensitiveMinistry',
			type: 'boolean',
			width: 155,
			hide: true,
			allowFilters: true,
			title: 'Sensitive Ministry',
			render: DefaultGridCellDisplay,
			description: 'We have determined this ministry is sensitive of their information',
		},
		{
			property: 'bHideLeverageSummary',
			type: 'boolean',
			width: 155,
			hide: true,
			allowFilters: true,
			title: 'Hide Leverage Summary',
			render: DefaultGridCellDisplay,
			description: "Don't show in the Leverage Summary report.",
		},
	]

	if (showingArchived) {
		defaultColumns.splice(2, 0, {
			property: 'dArchived',
			type: 'date',
			width: 123,
			allowFilters: true,
			title: 'Archived Date',
			render: DefaultGridCellDisplay,
			description: "The date the ministry's account was archived."
		})
	}

	return defaultColumns
}


export const useMinistryContactManagementDefaultColumns = (showingArchived: boolean): IGridColumn[] => {
	const appState = useContext(AppStateContext)!

	const defaultColumns: IGridColumn[] = [
		{
			property: 'ministryContactId',
			type: 'number',
			width: 35,
			hide: true,
			allowFilters: true,
			title: 'Ministry Contact ID',
			render: DefaultGridCellDisplay,
			description: 'Our unique Database ID for this ministry contact record.'
		},
		{
			property: 'branchAbbr',
			type: 'string',
			width: 50,
			allowFilters: true,
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
			title: 'Community',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'trainingAreaAbbr',
			type: 'string',
			width: 75,
			allowFilters: true,
			title: 'T. Area',
			render: DefaultGridCellDisplay,
			description: 'The training area that the ministry is attached',
			hide: true
		},
		{
			property: 'fullName',
			type: 'string',
			width: 100,
			disableSort: false,
			allowFilters: true,
			title: 'Full Name',
			render: MinistryContactNameDisplay,
			description: 'The contact\'s first name.',
		},
		{
			property: 'firstName',
			type: 'string',
			width: 50,
			disableSort: false,
			allowFilters: true,
			title: 'First Name',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s first name.',
			hide: true
		},
		{
			property: 'lastName',
			type: 'string',
			width: 50,
			disableSort: false,
			allowFilters: true,
			title: 'Last Name',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s last name.',
			hide: true
		},
		{
			property: 'ministryId',
			type: 'number',
			width: 100,
			hide: true,
			allowFilters: true,
			title: 'Ministry ID',
			render: DefaultGridCellDisplay,
			description: 'Our unique Database ID for this ministry contact record.'
		},
		{
			property: 'ministryName',
			type: 'string',
			width: 130,
			allowFilters: true,
			title: 'Ministry',
			render: MinistryNameDisplay,
			description: 'Our unique Database ID for this ministry contact record.'
		},
		{
			property: 'ministryLevelId',
			type: 'string',
			width: 130,
			hide: true,
			allowFilters: true,
			filterOptions: [
				{ label: 'Teaching', value: '1' },
				{ label: 'Coaching', value: '2' },
				{ label: 'Consulting', value: '3' },
			],
			title: 'Account Level',
			render: AccountLevelDisplay,
			description: 'The level the ministry contact is assigned.',
		},
		{
			property: 'email',
			type: 'string',
			width: 120,
			allowFilters: true,
			disableSort: false,
			title: 'Email',
			render: EmailCellDisplay,
			description: 'The contact\'s email.'
		},
		{
			property: 'businessPhone',
			type: 'string',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Bus. Phone',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s business phone'
		},
		{
			property: 'businessPhoneExt',
			type: 'string',
			width: 60,
			hide: true,
			allowFilters: true,
			title: 'Bus. Ext.',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s business phone extension'
		},
		{
			property: 'cellPhone',
			type: 'string',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Cell Phone',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s cell phone'
		},
		{
			property: 'address1',
			type: 'string',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Address',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s address'
		},
		{
			property: 'address2',
			type: 'string',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Address Line 2',
			render: DefaultGridCellDisplay,
			description: 'Second line of the contact\'s address'
		},
		{
			property: 'city',
			type: 'string',
			width: 100,
			hide: true,
			allowFilters: true,
			title: 'City',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s city'
		},
		{
			property: 'state',
			type: 'string',
			width: 70,
			hide: true,
			allowFilters: true,
			title: 'State',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s state'
		},
		{
			property: 'country',
			type: 'string',
			width: 100,
			hide: true,
			allowFilters: true,
			title: 'Country',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s country'
		},
		{
			property: 'postalCode',
			type: 'string',
			width: 77,
			hide: true,
			allowFilters: true,
			title: 'Postal Code',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s postal code'
		},
		{
			property: 'dLastLogin',
			type: 'date',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Last Login',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s last login date'
		},
		{
			property: 'dCreated',
			type: 'date',
			width: 120,
			hide: true,
			allowFilters: true,
			title: 'Created',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s creation date'
		},
		{
			property: 'isPrimary',
			type: 'boolean',
			width: 50,
			allowFilters: true,
			title: 'Primary',
			render: DefaultGridCellDisplay,
			description: 'Whether or not this contact is the primary contact for the ministry'
		},
		{
			property: 'contactTypeName',
			type: 'string',
			width: 90,
			allowFilters: true,
			title: 'Type',
			render: DefaultGridCellDisplay,
			description: 'The type of contact'
		},
		{
			property: 'contactSubTypeName',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Title',
			render: DefaultGridCellDisplay,
			description: 'The contact\'s title',
		},
		{
			property: 'tags',
			type: 'string',
			allowFilters: true,
			disableSort: true,
			filterTypeOverride: 'tags',
			width: 106,
			title: 'Tags',
			render: GridListItemObjectDisplay,
			description: 'Tags associated with the ministry'
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 60,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			description: 'Available actions for this note.',
			align: 'center',
		}
	]

	return defaultColumns
}

export const useNoteDefaultColumns = (): IGridColumn[] => {
	//const appState = useContext(AppStateContext)!

	const columns: IGridColumn[] = [
		{
			property: 'dateEntered',
			type: 'date',
			width: 50,
			allowFilters: true,
			title: 'Date',
			render: DefaultGridCellDisplay,
			description: "The date the note was entered"
		},
		{
			property: 'createdByFullName',
			type: 'string',
			width: 90,
			allowFilters: true,
			title: 'Author',
			render: DefaultGridCellDisplay,
			description: 'The MI user who entered the note'
		},
		{
			property: 'noteType',
			type: 'string',
			width: 70,
			allowFilters: true,
			title: 'Note Type',
			render: (col, row) => {
				if (row.values.noteTypeId == NOTE_TYPES["Call List"])
					return <Link to={`/call-list-summary/${row.values.callListId}`}>{row.values[col.property]}</Link>
				else if (row.values.noteTypeId == NOTE_TYPES.Consulting)
					return <Link to={`/consulting/${row.values.consultingEventGroup}`}>{row.values[col.property]}</Link>
				else
					return row.values[col.property]
			},
			description: 'The type of note'
		},
		{
			property: 'contactFullName',
			type: 'string',
			width: 90,
			allowFilters: true,
			title: 'Contact',
			render: DefaultGridCellDisplay,
			description: 'The contact that the note references'
		},
		{
			property: 'noteText',
			type: 'string',
			width: 200,
			allowFilters: true,
			title: 'Note Text',
			render: SanitizeHtmlContentDisplay,
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 60,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			description: 'Available actions for this note.',
			align: 'center',
		}
	]

	return columns
}

export const useUsersDefaultColumns = (): IGridColumn[] => {
	const appState = useContext(AppStateContext)!

	const columns: IGridColumn[] = [
		{
			property: 'branchName',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'Community',
			render: DefaultGridCellDisplay,
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchName || '' })),
		},
		{
			property: 'firstName',
			type: 'string',
			width: 130,
			allowFilters: true,
			title: 'First Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'lastName',
			type: 'string',
			width: 130,
			allowFilters: true,
			title: 'Last Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'email',
			type: 'string',
			width: 150,
			allowFilters: true,
			title: 'Email',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'dLastLogin',
			type: 'date',
			width: 105,
			allowFilters: true,
			title: 'Last Login',
			render: DefaultGridCellDisplay,
		},
		// {
		// 	property: 'lastIpaddress',
		// 	type: 'string',
		// 	width: 130,
		// 	allowFilters: true,
		// 	title: 'Last IP',
		// 	render: DefaultGridCellDisplay,
		// 	description: 'The last IP Address that the user logged in from',
		// },
		{
			property: 'bDisabled',
			type: 'string',
			width: 130,
			allowFilters: false,
			filterUnallowedMessage: `Use the 'Show All Users'/'Show Active Users' button above to filter by Allowed or Disallowed users.`,
			title: 'Account Access',
			render: UserDisabledDisplay,
			description: 'Whether or not the user account is disabled',
		},
		{
			property: 'region',
			type: 'string',
			width: 125,
			allowFilters: true,
			title: 'Region',
			render: DefaultGridCellDisplay,
		},
	]

	/* 
		Check the user's permissions before including the action column
		Admin\modules\userAdmin.js line 33
	*/
	if (UserModel.checkPermissionLevelForUser(3, UserPermissionLevel.Modify, appState.currentUser)) {
		columns.splice(3, 0, {
			property: 'grid_actions',
			type: 'actions',
			width: 140,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
			description: 'The first icon shows the user detail<br>The second icon disables the account (does not allow them to log in)<br>The third icon deletes the user account'
		})
	}


	return columns
}

export const useCommunityManagementDefaultColumns = (): IGridColumn[] => {
	const columns: IGridColumn[] = [
		{
			property: 'branchType',
			type: 'string',
			width: 110,
			allowFilters: true,
			title: 'Community Type',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'region',
			type: 'string',
			width: 130,
			allowFilters: true,
			title: 'Region',
			render: DefaultGridCellDisplay,
			hide: true,
		},

		// Removed per https://missionincrease.atlassian.net/browse/MA20-922?focusedCommentId=10522
		// {
		// 	property: 'subRegion',
		// 	type: 'string',
		// 	width: 130,
		// 	allowFilters: true,
		// 	title: 'Sub Region',
		// 	render: DefaultGridCellDisplay,
		// 	hide: true,
		// },
		{
			property: 'trainingArea',
			type: 'string',
			width: 185,
			allowFilters: true,
			title: 'Training Area',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'branchId',
			type: 'number',
			width: 100,
			allowFilters: true,
			title: 'Branch Id',
			render: DefaultGridCellDisplay,
			hide: true
		},
		{
			property: 'areaDirectorFullName',
			type: 'string',
			width: 125,
			allowFilters: true,
			title: 'Area Director',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 70,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
		},
		{
			property: 'branchName',
			type: 'string',
			width: 230,
			allowFilters: true,
			hide: true,
			title: 'Community',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'isCauseSpecific',
			type: 'boolean',
			width: 120,
			allowFilters: true,
			title: 'Cause Specific',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'postalCode',
			type: 'string',
			width: 85,
			allowFilters: true,
			hide: true,
			title: 'Zip Code',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'hosts',
			type: 'string',
			width: 220,
			allowFilters: true,
			title: 'Hosts',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'ministryCount',
			type: 'number',
			width: 92,
			allowFilters: true,
			title: 'Ministries',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'upcomingEventCount',
			type: 'number',
			width: 145,
			allowFilters: true,
			title: 'Upcoming Events',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'previousEventCount',
			type: 'number',
			width: 132,
			allowFilters: true,
			title: 'Previous Events',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'dOpen',
			type: 'date',
			width: 120,
			allowFilters: true,
			title: 'Date Open',
			hide: true,
			render: DefaultGridCellDisplay,
		},
		// Removed per https://missionincrease.atlassian.net/browse/MA20-922?focusedCommentId=10522
		// {
		// 	property: 'pCount',
		// 	type: 'number',
		// 	width: 35,
		// 	allowFilters: true,
		// 	hide: true,
		// 	title: 'P',
		// 	render: DefaultGridCellDisplay,
		// },
		// {
		// 	property: 'eCount',
		// 	type: 'number',
		// 	width: 35,
		// 	allowFilters: true,
		// 	hide: true,
		// 	title: 'E',
		// 	render: DefaultGridCellDisplay,
		// },
		// {
		// 	property: 'oCount',
		// 	type: 'number',
		// 	width: 35,
		// 	allowFilters: true,
		// 	hide: true,
		// 	title: 'O',
		// 	render: DefaultGridCellDisplay,
		// },
		// {
		// 	property: 'naCount',
		// 	type: 'number',
		// 	width: 35,
		// 	allowFilters: true,
		// 	hide: true,
		// 	title: 'N/A',
		// 	render: DefaultGridCellDisplay,
		// },
	]



	return columns
}

