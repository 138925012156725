import { MinistryGeneralChartsDataModel } from '../models/ministry-general-charts-data'
import { IMinistrySummaryResponseModelDocument, IMinistryContactSummaryModelDocument, IMinistryFileSummaryModelDocument, IMinistryGrantsSummaryDocument, IMinistryEventAttendanceSummaryDocument, IMinistryEmailsSummaryDocument, IMinistryDataFiscalYearSummaryDocument, INotesSummaryDocument, IMinistryProfileLoggingSummaryDocument, ILeverageSummaryReportDocument, ISeminarEvalSummaryDocument, ILmsUserCourseModelDocument } from '../open-api'

export type MinistryInfoTab = 'general-tab' | 'profile-tab' | 'grants-tab' | 'ty-data-tab' | 'events-tab' | 'notes-tab' | 'gs-report-tab' | 'email-log-tab' | 'event-evals-tab' | 'ad-evals-tab' | 'courses-tab'

export const isMinistryInfoTab = (obj: unknown): obj is MinistryInfoTab => {
	if (
		obj === 'general-tab' ||
		obj === 'profile-tab' ||
		obj === 'grants-tab' ||
		obj === 'ty-data-tab' ||
		obj === 'events-tab' ||
		obj === 'notes-tab' ||
		obj === 'gs-report-tab' ||
		obj === 'email-log-tab' ||
		obj === 'event-evals-tab' ||
		obj === 'ad-evals-tab' ||
		obj === 'courses-tab'
	) {
		return true
	}

	return false
}

export interface IMinistryInfoState {
	loading: boolean
	ministryId?: number
	ministry?: IMinistrySummaryResponseModelDocument
	ministryContacts?: IMinistryContactSummaryModelDocument[]
	ministryFiles?: IMinistryFileSummaryModelDocument[]
	primaryContact?: IMinistryContactSummaryModelDocument
	ministryGeneralChartsData?: MinistryGeneralChartsDataModel
	activeTab: MinistryInfoTab
	ministryGrants?: IMinistryGrantsSummaryDocument[]
	ministryEventAttendance?: IMinistryEventAttendanceSummaryDocument[]
	ministryEmailLog?: IMinistryEmailsSummaryDocument[]
	ministryEventEvals?: ISeminarEvalSummaryDocument[]
	ministryFyData?: IMinistryDataFiscalYearSummaryDocument[]
	ministryNotes?: INotesSummaryDocument[]
	leverageSummary?: ILeverageSummaryReportDocument[]
	ministryProfileLog?: IMinistryProfileLoggingSummaryDocument[]
	ministryCourses?: ILmsUserCourseModelDocument[]
}

