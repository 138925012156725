import React, { useState, useEffect } from 'react'
import { IS_PRODUCTION } from '../constants'
import { hasDuplicates } from '../services/helpers'

export interface ITab<T> {
	id: T
	title: string | JSX.Element
	content: JSX.Element
	disabled?: boolean
}

interface ITabsProps<T extends string> {
	tabs: ITab<T>[]
	keepTabContentInDomWhenNotActive?: boolean // Use this when you want the contents inside each tab to stay "alive" when a different tab is selected.
	controls?: {
		activeTab: T
		setActiveTab: (tab: T) => void
	}
	tablListClassName?: string
}
export const Tabs = <T extends string,>(props: ITabsProps<T>) => {
	const { tabs, keepTabContentInDomWhenNotActive, tablListClassName } = props

	const [localActiveTab, setLocalActiveTab] = useState<string>()

	const activeTab = props.controls?.activeTab || localActiveTab
	const setActiveTab = props.controls?.setActiveTab || setLocalActiveTab

	useEffect(() => {
		if (!IS_PRODUCTION && hasDuplicates(tabs.map(o => o.id))) {
			throw new Error('Duplicate IDs found in Tabs list. Each Tab must have a unique ID.')
		}

		/* Set default selected tab to the first */
		if (!activeTab && tabs.length) setActiveTab(tabs[0].id)
	}, [tabs])

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
			<ul className={`nav nav-tabs ${tablListClassName}`} role='tablist'>
				{tabs.map(tab => (
					<li key={`${tab.id}-tab`} className='nav-item'>
						<a href='#' className={`nav-link ${activeTab === tab.id ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} id={`${tab.id}-tab`} onClick={() => setActiveTab(tab.id)} role='tab' aria-controls={tab.id} aria-selected='true'>{tab.title}</a>
					</li>
				))}
			</ul>

			<div className='tab-content' style={{ overflow: 'auto', flex: 1, backgroundColor: 'white' }}>
				{tabs.map(tab => (
					<div key={`${tab.id}-content`} style={{ height: '100%' }} className={`tab-pane fade flex-fill ${activeTab === tab.id ? 'show active' : ''}`} id={tab.id} role='tabpanel' aria-labelledby={`${tab.id}-tab`}>
						{activeTab === tab.id || keepTabContentInDomWhenNotActive ? <div className='animated fadeIn' style={{ animationDuration: '300ms', height: '100%' }}>{tab.content}</div> : null}
					</div>
				))}
			</div>
		</div>
	)
}