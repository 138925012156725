import { IMinistryEmailsSummaryDocument } from "../open-api";
import { IGridColumn, IGridListItem } from "../stores/grid-definitions";
import { DefaultGridCellDisplay, GridActionCell } from "../components/grid-cell-displays";

export class EmailLogModel {
    public static toGridListItem = (emailLogModel: IMinistryEmailsSummaryDocument): IGridListItem => {
        return {
			id: emailLogModel.mailId.toString(),
			values: {
				mailType: emailLogModel.mailType,
				subject: emailLogModel.subject,
				toAdx: emailLogModel.toAdx,
                fromAdx: emailLogModel.fromAdx,
                dSent: emailLogModel.dSent ? new Date(emailLogModel.dSent) : null,
                mailEngineId: emailLogModel.meid,
				body: emailLogModel.body,
                progress: emailLogModel.progress,
                bSuccess: emailLogModel.bSuccess,
                hideEmail: emailLogModel.hideEmail,
                errorData: emailLogModel.errorData
			}
		}
    }
}

export const emailLogModelDefaultColumns: IGridColumn[] = [
    {
        property: 'dSent',
        type: 'date',
        width: 90,
        allowFilters: true,
        title: 'Date',
        render: DefaultGridCellDisplay,
        description: "The date the email was sent"
    },
    {
        property: 'progress',
        type: 'string',
        width: 90,
        allowFilters: true,
        title: 'Status',
        render: DefaultGridCellDisplay
    },
    {
        property: 'mailType',
        type: 'string',
        width: 150,
        allowFilters: true,
        title: 'Email Type',
        render: DefaultGridCellDisplay,
    },
    {
        property: 'fromAdx',
        type: 'string',
        width: 153,
        allowFilters: true,
        title: 'From',
        render: DefaultGridCellDisplay
    },
    {
        property: 'toAdx',
        type: 'string',
        width: 153,
        allowFilters: true,
        title: 'To',
        render: DefaultGridCellDisplay
    },
    {
        property: 'subject',
        type: 'string',
        width: 153,
        allowFilters: true,
        title: 'Subject',
        render: DefaultGridCellDisplay
    },
    {
		property: 'grid_actions',
		type: 'actions',
		width: 70,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	}
]