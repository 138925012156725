import React, { useContext, useEffect, useState } from 'react'
import { truncate } from 'lodash'
import dayjs from 'dayjs'
import { IGrantRequestSummaryReportProps } from './grant-request-summary-report'

export const GrantRequestSummaryReportPage3 = (props: IGrantRequestSummaryReportProps) => {
    const { grantRequestSummary } = props

    return (
        <>
            <table style={{width: 910, border: 0}} cellPadding="0" cellSpacing="0">
                <tr>
                    <td align="center" className="hRule" width="100%">
                        <h2 style={{fontSize: '16px'}}>Mission Increase - Grant Request Summary</h2>
                    </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>
                        <div style={{width: '100%'}}>
                            <div style={{float: 'left'}} className="headerDiv"><strong>{grantRequestSummary.ministryName && truncate(grantRequestSummary.ministryName, {length:70})}</strong></div>
                            <div style={{float: 'right'}} className="headerDiv"><strong>{grantRequestSummary.branchName}</strong></div>
                        </div>
                    </td>
                </tr>
            </table>
            <br />
            {grantRequestSummary.showEvalNotes &&
            <>
                <table style={{width: '910px', border: 0}}>
                    <tr>
                        <td className="hRule"><strong>Area Director Evaluation Notes</strong></td>
                    </tr>
                    <tr>
                        <td className="boxed">
                            <table style={{width: '100%', border: 0}}>
                            <tr>
                                <td>
                                    <strong>Purpose ({grantRequestSummary.strategyScore} of {grantRequestSummary.strategyPossibleScore})</strong>
                                    {grantRequestSummary.purposeStrategy &&
                                    
                                        <><strong>:</strong> {grantRequestSummary.purposeStrategy}</>}
                                    {grantRequestSummary.dStep1Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep1Updated).format('M/DD/YY')})</small>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Governance ({grantRequestSummary.governanceScore} of {grantRequestSummary.governancePossibleScore})</strong>
                                    {grantRequestSummary.governance &&
                                        <><strong>:</strong> {grantRequestSummary.governance}</>
                                    }
                                    {grantRequestSummary.dStep2Updated &&
                                    
                                        <small>(updated {dayjs(grantRequestSummary.dStep2Updated).format('M/DD/YY')})</small>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Leadership ({grantRequestSummary.leadershipScore} of {grantRequestSummary.leadershipPossibleScore})</strong>
                                    {grantRequestSummary.leadership &&
                                        <><strong>:</strong> {grantRequestSummary.leadership}</>}
                                    {grantRequestSummary.dStep3Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep3Updated).format('M/DD/YY')})</small> }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Operations ({grantRequestSummary.operationsScore} of {grantRequestSummary.operationsPossibleScore})</strong>
                                    {grantRequestSummary.operationsPrograms && 
                                        <><strong>:</strong> {grantRequestSummary.operationsPrograms}</>}
                                    {grantRequestSummary.dStep4Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep4Updated).format('M/DD/YY')})</small>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Fundraising ({grantRequestSummary.fundraisingScore} of {grantRequestSummary.fundraisingPossibleScore})</strong>
                                    {grantRequestSummary.fundraising &&
                                        <><strong>:</strong> {grantRequestSummary.fundraising}</>}
                                    {grantRequestSummary.dStep5Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep5Updated).format('M/DD/YY')})</small>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Communications ({grantRequestSummary.communicationsScore} of {grantRequestSummary.communicationsPossibleScore})</strong>
                                    {grantRequestSummary.communicationsMarketing &&
                                        <><strong>:</strong> {grantRequestSummary.communicationsMarketing}</>}
                                    {grantRequestSummary.dStep6Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep6Updated).format('M/DD/YY')})</small>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>RiskManagement ({grantRequestSummary.riskManagementScore} of {grantRequestSummary.riskManagementPossibleScore})</strong>
                                    {grantRequestSummary.riskManagement &&
                                        <><strong>:</strong> {grantRequestSummary.riskManagement}</>}
                                    {grantRequestSummary.dStep7Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep7Updated).format('M/DD/YY')})</small>}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Financial ({grantRequestSummary.financialScore} of {grantRequestSummary.financialPossibleScore})</strong>
                                    {grantRequestSummary.financial &&
                                        <><strong>:</strong> {grantRequestSummary.financial}</>}
                                    {grantRequestSummary.dStep8Updated && 
                                        <small>(updated {dayjs(grantRequestSummary.dStep8Updated).format('M/DD/YY')})</small>}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Total Score: </b> {grantRequestSummary.totalScore} of {grantRequestSummary.totalPossibleScore}</td>
                            </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <br />
            </>}
            {(grantRequestSummary.ministryGrantsDonorNotes && grantRequestSummary.ministryGrantsDonorNotes.length > 0) && 
            <>
                <table style={{width: '910px', border: 0}}>
                    <tr>
                        <td className="hRule"><strong>Donor Notes</strong></td>
                    </tr>
                    <tr>
                        <td className="boxed">
                            {grantRequestSummary.ministryGrantsDonorNotes.map((note, index) => 
                                <table key={index} style={{width: '100%', border: 0}}>
                                    <tr>
                                        <td>{truncate(note.notes || '', {length:2000})} - {note.noteAuthorName} && {note.dCreated && dayjs(note.dCreated).format('M/DD/YY')}</td>
                                    </tr>
                                </table>)}
                        </td>
                    </tr>
                </table>
                <br />
            </>}
            <table style={{width: '910px', border: 0}}>
                <tr>
                    <td className="hRule"><strong>Board Members</strong></td>
                </tr>
                <tr>
                    <td className="boxed">
                        <table style={{width: '100%', border: 0}}>
                            <tr>
                                {grantRequestSummary.boardMembers && grantRequestSummary.boardMembers.map((bm, index) => 
                                    <td key={index}><b>{bm.firstName} {bm.lastName}</b><br />{bm.contactSubType}</td>)}
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <br />
        </>
    )
}