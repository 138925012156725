
import moment from "moment"
import { GridListItemValueType, IGridListItem, IGridColumn, IGridState, isGridListItemObjectValueTypeArray } from '../stores/grid-definitions'
import React from 'react'
import { TooltipInfo } from '../components/partials'
import { buildTooltipProps, uuidv4, ensureUrlIncludesHttp, formatCurrency, getGuidestarProfileUrlForTaxId, getIrsOrganizationSearchUrlForTaxId, getProgressStyling } from '../services/helpers'
import { IMifActions } from "../stores/app-actions"
import { IAppState } from "../stores/app-definitions"
import { GridRowAction } from '../models/grid-row-action'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { ReactComponent as ExclamationCircleIcon } from '../assets/exclamation-circle.svg'
import { ReactComponent as DashIcon } from '../assets/dash.svg'
import { ReactComponent as CheckCircleIcon } from '../assets/check-circle.svg'
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { IGridActions } from "../stores/grid-actions"
import { BoxArrowUpRight } from "../assets"

const getDefaultGridCellDisplay = (col: IGridColumn, row: IGridListItem): string | number | null | JSX.Element => {
	const value: GridListItemValueType = row.values[col.property]

	if (col.type === 'date' && value) {
		return moment(value?.toString()).format(col.dateFormatOverride || 'MM/DD/YYYY')
	}

	if (typeof value === 'string') {
		var doc = new DOMParser().parseFromString(value, 'text/html')
		// return <span className='ellipsis-all-the-things' style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: value }}></span>
		return <span className='ellipsis-all-the-things'>{doc.body.textContent || ''}</span>
	}

	if (typeof value === 'boolean') {
		return value ? 'Yes' : 'No'
	}

	if (typeof value === 'number') {
		return value
	}

	return null
}

export type GridCellDisplayFunction = (col: IGridColumn, row: IGridListItem, gridState: IGridState, appActions: IMifActions, appState: IAppState, gridActions: IGridActions) => GridListItemValueType | JSX.Element

export const DefaultGridCellDisplay = (col: IGridColumn, row: IGridListItem): string | number | null | JSX.Element => {
	const value: GridListItemValueType = row.values[col.property]
	return getDefaultGridCellDisplay(col, row)
}

export const MultilineGridCellDisplay = (col: IGridColumn, row: IGridListItem): string | number | null | JSX.Element => {
	const value: GridListItemValueType = row.values[col.property]
	return <span dangerouslySetInnerHTML={{ __html: value as string }} />
}

export const GridActionCell = (col: IGridColumn, row: IGridListItem, gridState: IGridState, appActions: IMifActions, appState: IAppState): JSX.Element => {
	if (gridState.rowActions) {
		if (gridState.rowActionsDisplayType === 'icons') {
			return <React.Fragment>
					{Object.values(gridState.rowActions).map(action => {
						if (typeof action.hidden === 'function' && action.hidden(row)) {
							return null
						} else if (typeof action.hidden === 'boolean' && action.hidden) {
							return null
						}

						let tooltipText: string | null | undefined = null
						if (typeof action.tooltipText === 'string') {
							tooltipText = action.tooltipText
						} else if (typeof action.tooltipText === 'function') {
							tooltipText = action.tooltipText(row)
						}

						let url: string = '#'
						if (typeof action.url === 'string') {
							url = action.url
						} else if (typeof action.url === 'function') {
							url = action.url(row)
						}

						return <div
							{...tooltipText ? { title: tooltipText } : {}}
							key={action.id}
							className={`grid-row-action ${GridRowAction.getDisabledState(action, row) ? 'disabled' : ''}`}
							onClick={(e) => {
								e.stopPropagation()
								if (GridRowAction.getDisabledState(action, row)) return
								action.action({ row, gridState, e, appActions, appState })
							}}
						>
							{action.title ? action.title : null}
							{action.icon ? typeof action.icon === 'function' ? action.icon(row) : action.icon : null}
						</div>
					})}
			</React.Fragment>
		}
		else {
			return (
				<div className="btn-group" role="group">
					<button id={`btnGroupRowAction-${gridState.id}`} type="button" className="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Actions
					</button>
					<div className="dropdown-menu" aria-labelledby={`btnGroupRowAction-${gridState.id}`}>
						{Object.values(gridState.rowActions).map(action => {
							if (typeof action.hidden === 'function' && action.hidden(row)) {
								return null
							} else if (typeof action.hidden === 'boolean' && action.hidden) {
								return null
							}

							let tooltipText: string | null | undefined = null
							if (typeof action.tooltipText === 'string') {
								tooltipText = action.tooltipText
							} else if (typeof action.tooltipText === 'function') {
								tooltipText = action.tooltipText(row)
							}

							let url: string = '#'
							if (typeof action.url === 'string') {
								url = action.url
							} else if (typeof action.url === 'function') {
								url = action.url(row)
							}

							return <a
									className={`dropdown-item ${GridRowAction.getDisabledState(action, row) ? 'disabled' : ''}`}
									key={action.id}
									onClick={(e) => {
										e.stopPropagation()
										if (GridRowAction.getDisabledState(action, row)) return
										action.action({ row, gridState, e, appActions, appState })
									}}
									href={url}
									target={action.anchorTarget ? action.anchorTarget : '_self'}
								>
								{action.icon ? typeof action.icon === 'function' ? action.icon(row) : action.icon : null}
								&nbsp;
								{tooltipText}
							</a>
						})}
					</div>
				</div>
			)
		}
	} else {
		return (
			<div className='ellipses'>
				No actions supported
			</div>
		)
	}
}

export const PercentDisplay = (col: IGridColumn, row: IGridListItem) => {
	const val = row.values[col.property]
	if (typeof val === 'number') return `${val.toLocaleString('en-US', { maximumFractionDigits: 2 })}%`
	return val
}

/* 
	Component to show NTEE codes and include tooltip for each code's details
*/
export const NteeCodeDisplay = (col: IGridColumn, row: IGridListItem, gridState: IGridState, appActions: IMifActions, appState: IAppState) => {
	const val = row.values[col.property]
	if (typeof val === 'string') {
		const codes = val.split(',')
		const codeViews: JSX.Element[] = []
		codes.forEach((code, index) => {
			const codeDetail = appState.nteeCodes.find(c => c.nteeCode === code)
			if (codeDetail) {
				const id = uuidv4()
				codeViews.push(<span key={id} id={id} className='primary-color-hover' {...buildTooltipProps({ tooltipText: codeDetail.definition })}>{codeViews.length > 0 ? ',' : ''} {codeDetail.nteeCode}</span>)
			}
		})
		return <div>{codeViews}</div>
	}
	return val
}

export const CurrencyDisplay = (col: IGridColumn, row: IGridListItem) => {
	const val = row.values[col.property]
	if (typeof val === 'number') return formatCurrency(val)
	return val
}

export const AccountLevelDisplay = (col: IGridColumn, row: IGridListItem) => {
	switch (row.values[col.property]) {
		case 1:
			return 'Teaching'
		case 2:
			return 'Coaching'
		case 3:
			return 'Consulting'
		default:
			return row.values[col.property]?.toString() || null
	}
}

export const PossibleMinistryMatchesDisplay = (col: IGridColumn, row: IGridListItem, gridState: IGridState, appActions: IMifActions, appState: IAppState, gridActions: IGridActions): JSX.Element | null => {
	const possibleMatchCount = typeof row.values['possibleMatchCount'] === 'number' ? row.values['possibleMatchCount'] : null

	if (possibleMatchCount === null || possibleMatchCount === 0) return null

	return (
		<React.Fragment>
			<div>
				<SearchIcon
					className='primary-color-hover'
					style={{ marginBottom: 2, marginRight: 4, cursor: 'pointer' }}
					onClick={(e) => {
						e.stopPropagation()

						/* 
							Bit of a trick here to get around React Hook limitations. We can't add more hooks within a Grid Cell display function because of their conditional rendering.

							So in this case, we stow the rather complex action (that needs hooks) for this cell in the overall row actions in the Grid's initial setup.

							Then, when the icon in this cell is clicked, we grab that action from the Grid's row actions and call it's press action.
						*/
						if (gridState.rowActions?.possibleMatches) gridState.rowActions.possibleMatches.action({ e, row, gridState, appActions, appState })
					}}
				/>
				{possibleMatchCount}
			</div>

		</React.Fragment>
	)
}

export const GrantEligibleDisplay = (col: IGridColumn, row: IGridListItem, gridState: IGridState, appActions: IMifActions): JSX.Element | string => {
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const grantEligible: GridListItemValueType = row.values[col.property]
	const reason: GridListItemValueType = row.values['grantEligibleReason']
	let taxId = row.values['federalTaxNo']
	const ministryName = row.values['ministryName']

	if (typeof taxId === 'number') taxId = taxId.toString()

	if (grantEligible === 'Yes ') {
		return 'Yes'
	} else {
		return (
			<div
				onClick={async () => {
					const ministryStatusData = (await makeHttpRequestWithUi({
						request: appActions.MinistriesApi.apiMinistriesIdNonProfitStatusGet(parseInt(row.id)),
						disableSuccessToast: true,
						toastErrorMessage: 'There was an error retrieving status details for this ministry.'
					})).data

					appActions.addAlert({
						id: uuidv4(),
						title: 'Non-Profit Status',
						size: 'lg',
						body:
							<div>
								{ministryStatusData ?
									<table>
										<tbody>
											<tr>
												<td style={{ fontWeight: 700 }}>Organization:</td>
												<td>{ministryStatusData.ministryName}</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>Tax ID/EIN:</td>
												<td>{ministryStatusData.federalTaxNo}</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}><span style={{ paddingRight: '5px' }}>In MI Database:</span></td>
												<td className='text-success'><CheckCircleIcon /> Yes</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>Account Status:</td>
												<td className={`${ministryStatusData.accountStatus === 'OK' ? 'text-success' : 'text-warning'}`}>{ministryStatusData.accountStatus === 'OK' ? <React.Fragment><CheckCircleIcon /> {ministryStatusData.accountStatus}</React.Fragment> : <React.Fragment><ExclamationCircleIcon /> {ministryStatusData.accountStatus}</React.Fragment>}</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>In Pub78:</td>
												<td className={`${ministryStatusData.inPub78 ? 'text-success' : 'text-danger'}`}>{ministryStatusData.inPub78 ? <React.Fragment><CheckCircleIcon /> Yes</React.Fragment> : <React.Fragment><ExclamationCircleIcon /> No</React.Fragment>}</td>
											</tr>
										</tbody>
									</table>
									:
									<table>
										<tbody>
											<tr>
												<td style={{ fontWeight: 700 }}>Organization:</td>
												<td>{ministryName}</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>Tax ID/EIN:</td>
												<td>{taxId}</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>In MI Database:</td>
												<td className='text-danger'><ExclamationCircleIcon /> No</td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>Account Status:</td>
												<td><DashIcon /></td>
											</tr>
											<tr>
												<td style={{ fontWeight: 700 }}>In Pub78:</td>
												<td><DashIcon /></td>
											</tr>
										</tbody>
									</table>
								}
								{typeof taxId === 'string' ?
									<div style={{ paddingTop: 20 }}>
										<h5>Helpful Links</h5>
										<ul style={{ paddingBottom: 10 }}>
											<li><a href={getGuidestarProfileUrlForTaxId(taxId)} target='_blank'>GuideStar Page*</a></li>
											<li><a href={getIrsOrganizationSearchUrlForTaxId(taxId)} target='_blank'>IRS Search</a></li>
										</ul>
										<i>*If this link shows a 404 page not found error, then the ministry is not listed with GuideStar.</i>
									</div>
									:
									null
								}
							</div>
					})
				}}
			>
				No {typeof reason === 'string' ? <TooltipInfo tooltipText={reason} className='icon icon-margin-bottom' /> : null}
			</div>
		)
	}
}

export const ConditionalFormatColorDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const value: GridListItemValueType = row.values[col.property]

	let color = ''

	if (typeof value === 'number') {
		if (value >= 75)
			color = 'text-success'
		else if (value >= 50)
			color = 'text-warning'
		else
			color = 'text-danger'
	}

	return <span className={color}>{value}%</span>
}

export const AccountStatusDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const value: GridListItemValueType = row.values[col.property]

	let color = ''
	let tooltipText = ''

	if (value === 'OK') {
		tooltipText = 'Account is complete and approved.'
		color = 'text-success'
	}
	else if (value === 'GRC') {
		tooltipText = 'Within 60 day window after FYE.'
		color = 'text-warning'
	}
	else if (value === 'PRE') {
		tooltipText = 'Within 60 day grace period after being moved to the granting level.'
		color = 'text-warning'
	}
	else if (value === 'SUB') {
		tooltipText = 'The account has been submitted but has not been approved.'
		color = 'text-warning'
	}
	else if (value === 'INC') {
		tooltipText = 'The account is incomplete.'
		color = 'text-warning'
	}

	return <span {...buildTooltipProps({ tooltipText })} className={color}>{value}</span>
}

export const GridListItemObjectDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const value: GridListItemValueType = row.values[col.property]

	if (typeof value === 'string') {
		
		const cleanValue = value.replaceAll('<br>', ',')

		const gridListItemObjects = cleanValue.split(',')
		return <div className='column'>{gridListItemObjects.map(gridListItemObject => <span key={uuidv4()} className='ellipses' >{gridListItemObject}</span>)}</div>
	}

	if (isGridListItemObjectValueTypeArray(value)) {
		return <div className='column'>{value.map(gridListItemObject => <span key={uuidv4()} className='ellipses' >{gridListItemObject.display}</span>)}</div>
	}

	return null
}

export const MinistryPrimaryContact = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const firstName: GridListItemValueType = row.values['firstName']
	const lastName: GridListItemValueType = row.values['lastName']
	const email: GridListItemValueType = row.values['primaryContactEmail']

	if (email && firstName && lastName) {
		return <a href={`mailto:${email}`}>{firstName} {lastName}</a>
	} else {
		return `${firstName} ${lastName}`
	}
}

export const EmailCellDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const email: GridListItemValueType = row.values[col.property]

	if (email) {
		return <a href={`mailto:${email}`}>{email}</a>
	} else {
		return ''
	}
}

export const MinistryPhoneWithExt = (col: IGridColumn, row: IGridListItem): GridListItemValueType => {
	const phone1 = row.values[col.property]
	const phone1Ext = row.values['phone1Ext']

	if (phone1 && phone1Ext) {
		return `${phone1} (ext. ${phone1Ext})`
	}

	return phone1
}

export const MinistryCityState = (col: IGridColumn, row: IGridListItem): GridListItemValueType => {
	const city = row.values['physicalCity']
	const state = row.values['physicalState']

	let cityState = ''

	if (city && state) {
		cityState = `${city}, ${state}`
	} else if (typeof city == 'string') {
		cityState = city
	} else if (typeof state == 'string') {
		cityState = state
	}

	return cityState
}

export const HyperlinkCell = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const url = row.values[col.property]

	if (typeof url === 'string') return <a target='blank' href={ensureUrlIncludesHttp(url)}>{url}</a>

	return null
}

export const MinistryNameDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const ministryName = row.values[col.property]
	const ministryId = row.values.ministryId || row.id
	const url = `/ministry-info/${ministryId}`

	if (!ministryName?.toString().trim()) return null

	return <><a href={url}>{ministryName}</a><a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>
}

export const EventDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const eventTitle = row.values[col.property]
	const eventId = row.values.eventId
	if ( ! eventId ) return null
	const url = `/event-management/${eventId}`

	return <><a href={url} title={eventTitle as string}>{eventTitle}</a><a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>
}

export const MinistryContactNameDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const contactName = row.values[col.property]
	const contactId = row.values.ministryContactId
	const url = `/contact-info/${contactId}`

	if (!contactName?.toString().trim()) return null

	return (contactId || 0) as number > 0 ? <><a href={url}>{contactName}</a><a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>
		: <>{contactName}</>
}

export const ProspectNameDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const prospectName = row.values[col.property]
	const prospectId = row.id

	return <a href={`/prospects/${prospectId}`}>{prospectName}</a>
}

export const MinistryEventAttendanceEvalDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const seminarEvalId = row.values[col.property]
	const hasEval = seminarEvalId || 0 > 0
	const eventTypeId = row.values['eventTypeId']
	const isActive = row.values['isActive']
	const isNoShow = row.values['isNoShow']
	const cancelled = row.values['cancelled']


	if (eventTypeId === 6) { // Consulting 
		if (!isActive || isNoShow || cancelled) {
			return <s>N/A</s> // Bootstrap strikethrough tag.
		}
		else {
			return "N/A"
		}
	}
	else if (!isActive || isNoShow || cancelled) {
		if (hasEval) {
			return <s>Yes</s>
		}
		else {
			return <s>No</s>
		}
	} else {
		if (hasEval)
			return "Yes"
		else
			return "No"
	}
}

export const MinistryEventAttendedDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const attended = row.values[col.property]
	const isActive = row.values['isActive']
	const isNoShow = row.values['isNoShow']
	const cancelled = row.values['cancelled']

	if (cancelled) {
		return <s>Event Cancelled</s> // Bootstrap strikethrough tag.
	}

	if (!isActive || isNoShow) {
		if (attended) {
			return <s>Yes</s>
		} else {
			return <s>No</s>
		}
	}
	else {
		if (attended) {
			return 'Yes'
		} else {
			return 'No'
		}
	}
}

export const MinistryEventAttendanceDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const attended = row.values[col.property]
	const isActive = row.values['isActive']
	const isNoShow = row.values['isNoShow']
	const cancelled = row.values['cancelled']
	const defaultVal = getDefaultGridCellDisplay(col, row)

	if (!isActive || isNoShow || cancelled) {
		return <s>{defaultVal}</s> // Bootstrap strikethrough tag.
	}
	else {
		return <span>{defaultVal}</span>
	}
}

export const MinistryNoteCreatorDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const firstName: GridListItemValueType = row.values['createdByFirstName']
	const lastName: GridListItemValueType = row.values['createdByLastName']

	return `${firstName} ${lastName}`
}

export const MinistryNoteContactDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const firstName: GridListItemValueType = row.values['contactFirstName']
	const lastName: GridListItemValueType = row.values['contactLastName']

	if (lastName) return `${firstName} ${lastName}`

	return ''
}

// Will remove HTML tags from string so they don't get displayed in the cell.
export const SanitizeHtmlContentDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | string => {
	const content: GridListItemValueType = row.values[col.property]

	// eslint-disable-next-line
	return typeof content === "string" ? content.replace(/<[a-zA-Z\/][^>]*>/g, "") : ''
}

export const UserDisabledDisplay = (col: IGridColumn, row: IGridListItem): string => {
	const disabled = row.values.bDisabled
	if (disabled === false) return 'Allowed'
	return 'Disabled'
}

export const MinistryNameMinistryIdDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	const ministryName = row.values[col.property]
	const ministryId = row.values.ministryId

	if (!!ministryId) return <a href={`/ministry-info/${ministryId}`}>{ministryName}</a>
	return <span>{ministryName}</span>
}

export const ProgressGridCellDisplay = (col: IGridColumn, row: IGridListItem): JSX.Element | null => {
	if ( ! row.values.courseProgressPercentage === undefined && ! row.values.percentageComplete === undefined ) return null

	return (
		<div style={{backgroundColor: "#eeeeee", width: "100%"}}>
			<div style={getProgressStyling(row.values.courseProgressPercentage ?? row.values.percentageComplete)}>
				{(row.values.courseProgressPercentage ? row.values.courseProgressPercentage : row.values.percentageComplete)?.toString()}% Complete
			</div>
		</div>
	)
}