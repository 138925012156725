import { IModelDocumentBase, ModelBase } from './model-base'
import { IMiUserModelDocument, IMiUserSummaryDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'
import moment from 'moment'
import { ISelectFieldOption } from '../components/forms'

export enum UserPermissionLevel {
	NoAccess = 0,
	Read = 1,
	Modify = 2,
	Administrator = 3
}

export const userPermissionLevelSelectOptions: ISelectFieldOption[] = [
	{
		label: 'No Access',
		value: `${UserPermissionLevel.NoAccess}`
	},
	{
		label: 'Read',
		value: `${UserPermissionLevel.Read}`
	},
	{
		label: 'Modify',
		value: `${UserPermissionLevel.Modify}`
	},
	{
		label: 'Administrator',
		value: `${UserPermissionLevel.Administrator}`
	},
]

export enum UserReportPermissionLevel {
	'Level 1' = 1,
	'Level 2' = 2,
	'Level 3' = 3,
	'Level 4' = 4,
}

export const userReportPermissionLevelSelectOptions: ISelectFieldOption[] = [
	{
		label: 'Level 1',
		value: `${UserReportPermissionLevel["Level 1"]}`
	},
	{
		label: 'Level 2',
		value: `${UserReportPermissionLevel["Level 2"]}`
	},
	{
		label: 'Level 3',
		value: `${UserReportPermissionLevel["Level 3"]}`
	},
	{
		label: 'Level 4',
		value: `${UserReportPermissionLevel["Level 4"]}`
	},
]


export enum App {
	MiAdmin = 1,
	GrantManager = 2,
	UserManager = 3,
	MinistryManager = 4,
	MailBlast = 5,
	NoteManager = 6,
	MediaManager = 7,
	EventManager = 8,
	CallList = 9,
	DonorPortal = 10,
	QuoteBook = 11,
	ManagementReports = 12,
	ScoreCard = 13,
	JobOpenings = 14
}

export interface IUserDocument extends IModelDocumentBase {
	userId: number
	firstName: string | null
	lastName: string | null
	email: string | null
}

export interface IUser extends IUserDocument {

}

export class UserModel extends ModelBase implements IUser {
	userId: number

	// Nullable
	firstName: string | null
	lastName: string | null
	email: string | null

	constructor(doc: IUserDocument) {
		super(doc)

		this.userId = doc.userId

		this.firstName = doc.firstName
		this.lastName = doc.lastName
		this.email = doc.email
	}

	public static userIsSuperUser = (user?: IMiUserModelDocument): boolean => {
		if (!user) return false
		return !!user.bSuperUser
	}

	// Verify that the user may access the given secion of GrowthTrack (aka appId)
	// by checking the user's permission level associated with that section.
	public static checkPermissionLevelForUser = (appId: App, permissionLevel: UserPermissionLevel, user?: IMiUserModelDocument, exact?: boolean): boolean => {
		if (!user) return false
		if (UserModel.userIsSuperUser(user) && !exact) return true
		const appPermission = user.adminAppPermissions?.find(perm => perm.appId === appId)
		if (!appPermission) return false
		if (exact) {
			if (appPermission.permissionId === permissionLevel) return true
		} else {
			if (appPermission.permissionId >= permissionLevel) return true
		}
		return false
	}

	public static toGridListItem = (user: IMiUserSummaryDocument): IGridListItem => {
		return {
			id: user.userId.toString(),
			values: {
				...user,
				dLastLogin: user.dLastLogin ? moment(user.dLastLogin).toDate() : null,
				dLastLogin2: user.dLastLogin2 ? moment(user.dLastLogin2).toDate() : null,
			},
			rowGroup: {
				id: user.branchId?.toString() || '',
				title: `${user.branchName}`
			}
		}
	}

}

export function factory(doc: IUserDocument): IUser {
	return doc
}