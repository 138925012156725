import { IPotentialRecipientDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class PotentialRecipient {
	public static toGridListItem = (potentialRecipient: IPotentialRecipientDocument): IGridListItem => {
		return {
			id: potentialRecipient.fooUniqueId.toString(),
			values: {
				...potentialRecipient,
			}
		}
	}
}