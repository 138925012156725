import $ from 'jquery'
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle'
import { useEffect, RefObject, useState } from 'react'
import { AUTO_QUERY_DELAY_MS } from '../constants'
import { IJsonPatch } from '../stores/app-definitions'
import { isUndefined } from 'util'
import { SortDirections, IFilter, isDateListFilterType, ISort } from '../stores/api-actions'
import { IGridListItem, IGridColumn, isGridListItemObjectValueTypeArray, GridListItemValueType } from '../stores/grid-definitions'
import moment from 'moment'
import { ISelectFieldOption } from '../components/forms'
import { IOperationDocument } from '../open-api'
//import { EnumDeclaration } from 'typescript'
import dayjs from 'dayjs'

// Fixed Typescript removing support for msSaveBlob https://stackoverflow.com/questions/69485778/new-typescript-version-does-not-include-window-navigator-mssaveblob
declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

// It's safe to typecast these two because we know for certain they will exist. And if they don't exist...well, hardcasting here will be the least of the issues.
export const appRoot = document.getElementById('app-root') as HTMLDivElement
export const portalRoot = document.getElementById('modal-root') as HTMLDivElement
export const toastRoot = document.getElementById('toast-root') as HTMLDivElement

export type PopperPlacementPosition = 'auto' | 'top' | 'bottom' | 'left' | 'right'

export const animateCSS = (element: HTMLElement, animationName: string, callback?: () => void) => {
	element.classList.add('animated', animationName)

	function handleAnimationEnd() {
		element.classList.remove('animated', animationName)
		element.removeEventListener('animationend', handleAnimationEnd)

		if (typeof callback === 'function') callback()
	}

	element.addEventListener('animationend', handleAnimationEnd)
}

export const okHttpStatus = (status: number) => status >= 200 && status <= 299

export const countDecimals = (num: number) => {
	if (!num) return 0
	if (Math.floor(num.valueOf()) === num.valueOf()) return 0
	return num.toString().split('.')[1].length || 0
}

export const formatCurrency = (amount: number, includeCents = false, excludeSymbol = false): string => {
	let cents = includeCents
	if (!cents) {
		cents = countDecimals(amount) > 0
	}

	if (excludeSymbol) {
		return amount.toLocaleString('en-US', {
			minimumFractionDigits: cents ? 2 : 0,
		})
	} else {
		return amount.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: cents ? 2 : 0,
		})
	}
}

export const getYearOptions = (numberOfYearsInThePastToInclude: number): ISelectFieldOption[] => {
	let options: ISelectFieldOption[] = []
	let year = moment().year()
	for (let i = 0; i < numberOfYearsInThePastToInclude; i++) {
		let yearOption = (year - i).toString()
		options.push({ value: yearOption, label: yearOption })
	}

	return options
}

export function sortListByProperty<ListType extends { [property: string]: any }>(list: ListType[], property: string, direction?: SortDirections): ListType[] {
	return list.sort((a, b) => {
		let aSortValue = a[property]
		let bSortValue = b[property]

		if (typeof aSortValue === 'string') aSortValue = aSortValue.toLowerCase()
		if (typeof bSortValue === 'string') bSortValue = bSortValue.toLowerCase()

		if (aSortValue === null && bSortValue === null) return -1
		if (bSortValue === null) { return direction === 'DESC' ? -1 : 1 }
		if (aSortValue === null) { return direction === 'DESC' ? 1 : -1 }

		if (aSortValue < bSortValue || bSortValue === null) { return direction === 'DESC' ? 1 : -1 }
		if (aSortValue > bSortValue || aSortValue === null) { return direction === 'DESC' ? -1 : 1 }
		return 0
	})
}

export function sortListBySorts<ListType extends { [property: string]: any }>(list: ListType[], sorts: ISort[]): ListType[] {
	for (const sort of [...sorts].reverse()) sortListByProperty(list, sort.property, sort.direction)
	return list
}

export function sortGidListItemsByProperty(list: IGridListItem[], property: string, direction?: SortDirections): IGridListItem[] {
	return list.sort((a, b) => {
		let aSortValue = a.values[property]
		let bSortValue = b.values[property]

		if (typeof aSortValue === 'string') aSortValue = aSortValue.toLowerCase()
		if (typeof bSortValue === 'string') bSortValue = bSortValue.toLowerCase()

		if (aSortValue === null && bSortValue === null) return -1
		if (bSortValue === null) { return direction === 'DESC' ? -1 : 1 }
		if (aSortValue === null) { return direction === 'DESC' ? 1 : -1 }

		if (aSortValue < bSortValue || bSortValue === null) { return direction === 'DESC' ? 1 : -1 }
		if (aSortValue > bSortValue || aSortValue === null) { return direction === 'DESC' ? -1 : 1 }
		return 0
	})
}

export const sortGridListItemsBySorts = (items: IGridListItem[], sorts: ISort[]): IGridListItem[] => {
	for (const sort of [...sorts].reverse()) sortGidListItemsByProperty(items, sort.property, sort.direction)
	return items
}

export const stringIsNumber = (value: string) => isNaN(Number(value)) === false

export const getTimeOptions = (start: string, end: string): ISelectFieldOption[] => {
	var startTime = moment(start, 'HH:mm')
	var endTime = moment(end, 'HH:mm')

	if (endTime.isBefore(startTime)) endTime.add(1, 'day')

	var timeOptions: ISelectFieldOption[] = []

	while (startTime <= endTime) {
		const value = moment(startTime).format('h:mm A')
		timeOptions.push({ label: value, value: value })
		startTime.add(15, 'minutes')
	}

	return timeOptions
}

// export const convertEnumToSelectOptions = (_enum: EnumDeclaration) => Array.from(Object.keys(_enum)).filter(stringIsNumber).map(o => ({ label: `${_enum[parseInt(o)]}`, value: `${o}` }))

// https://stackoverflow.com/a/23797348/1594335
// export const downloadFileAttachmentFromHttpResponse = (headers: any, file: any) => {
// 	const contentDisposition = headers['content-disposition'] as string | undefined
// 	const contentType = headers['content-type'] as string | undefined

// 	if (contentDisposition && contentDisposition.includes('attachment') && contentType) {
// 		let fileName = ''
// 		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
// 		var matches = filenameRegex.exec(contentDisposition)
// 		if (matches != null && matches[1]) fileName = matches[1].replace(/['"]/g, '')

// 		var blob;
// 		if (typeof File === 'function') {
// 			try {
// 				blob = new File([file], fileName, { type: contentType });
// 			} catch (e) { /* Edge */ }
// 		}
// 		if (typeof blob === 'undefined') {
// 			blob = new Blob([file], { type: contentType });
// 		}

// 		var URL = window.URL || window.webkitURL;
// 		var downloadUrl = URL.createObjectURL(blob);

// 		if (contentType.includes('pdf')) {
// 			window.open(downloadUrl)
// 		} else {
// 			if (typeof window.navigator.msSaveBlob !== 'undefined') {
// 				// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
// 				window.navigator.msSaveBlob(blob, fileName);
// 			} else {
// 				if (fileName) {
// 					// use HTML5 a[download] attribute to specify fileName
// 					var a = document.createElement("a");
// 					// safari doesn't support this yet
// 					if (typeof a.download === 'undefined') {
// 						window.location.href = downloadUrl;
// 					} else {
// 						a.href = downloadUrl;
// 						a.download = fileName;
// 						document.body.appendChild(a);
// 						a.click();
// 					}
// 				} else {
// 					window.location.href = downloadUrl;
// 				}

// 				setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
// 			}


// 		}
// 	}
// }

// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
export const convertFileDownloadToUrl = (headers: any, data: any): string | undefined => {

	var octetStreamMime = 'application/octet-stream'
	var success = false
	let downloadUrl: string | undefined

	// Get the filename from the x-filename header or default to "download.bin"
	var filename = headers['x-filename'] || 'download.bin'
	const contentDisposition = headers['content-disposition'] as string | undefined
	if (contentDisposition && contentDisposition.includes('attachment')) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		var matches = filenameRegex.exec(contentDisposition)
		if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
	}

	// Determine the content type from the header or default to "application/octet-stream"
	var contentType = headers['content-type'] || octetStreamMime

	console.log('contentType', contentType)

	try {
		// Try using msSaveBlob if supported
		var blob = new Blob([data], { type: contentType })
		downloadUrl = URL.createObjectURL(blob)
		success = true
	} catch (ex) {
		console.log("saveBlob method failed with the following exception:")
		console.log(ex)
	}

	if (!success) {
		// Get the blob url creator
		// @ts-ignore
		var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL
		if (urlCreator) {
			// Try to simulate a click
			try {
				// Prepare a blob URL
				// eslint-disable-next-line
				var blob = new Blob([data], { type: contentType })
				var url = urlCreator.createObjectURL(blob)
				downloadUrl = url
				success = true
			} catch (ex) {
				console.log("Download link method with simulated click failed with the following exception:")
				console.log(ex)
			}

			if (!success) {
				// Fallback to window.location method
				try {
					// Prepare a blob URL
					// eslint-disable-next-line
					var blob = new Blob([data], { type: octetStreamMime })
					// eslint-disable-next-line
					var url = urlCreator.createObjectURL(blob)
					downloadUrl = url
					console.log("Download link method with window.location succeeded")
					success = true
				} catch (ex) {
					console.log("Download link method with window.location failed with the following exception:")
					console.log(ex)
				}
			}
		}
	}

	if (!success) {
		alert('Failed to display downloaded file.')
	}

	return downloadUrl
}

// https://stackoverflow.com/questions/24080018/download-file-from-an-asp-net-web-api-method-using-angularjs#24129082
export const downloadFileAttachmentFromHttpResponse = (headers: any, data: any) => {

	var octetStreamMime = 'application/octet-stream'
	var success = false

	// Get the filename from the x-filename header or default to "download.bin"
	var filename = headers['x-filename'] || 'download.bin'
	const contentDisposition = headers['content-disposition'] as string | undefined
	if (contentDisposition && contentDisposition.includes('attachment')) {
		var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		var matches = filenameRegex.exec(contentDisposition)
		if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
	}

	// Determine the content type from the header or default to "application/octet-stream"
	var contentType = headers['content-type'] || octetStreamMime

	try {
		// Try using msSaveBlob if supported
		console.log("Trying saveBlob method ...")
		var blob = new Blob([data], { type: contentType })

		if (contentType.includes('pdf')) {
			var downloadUrl = URL.createObjectURL(blob)
			window.open(downloadUrl)
		} else {
			if (navigator.msSaveBlob)
				navigator.msSaveBlob(blob, filename)
			else {
				// Try using other saveBlob implementations, if available
				// @ts-ignore
				var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob
				// eslint-disable-next-line
				if (saveBlob === undefined) throw "Not supported"
				saveBlob(blob, filename)
			}
			console.log("saveBlob succeeded")
		}


		success = true
	} catch (ex) {
		console.log("saveBlob method failed with the following exception:")
		console.log(ex)
	}

	if (!success) {
		// Get the blob url creator
		// @ts-ignore
		var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL
		if (urlCreator) {
			// Try to use a download link
			var link = document.createElement('a')
			if ('download' in link) {
				// Try to simulate a click
				try {
					// Prepare a blob URL
					console.log("Trying download link method with simulated click ...")
					// eslint-disable-next-line
					var blob = new Blob([data], { type: contentType })
					var url = urlCreator.createObjectURL(blob)
					link.setAttribute('href', url)

					// Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
					link.setAttribute("download", filename)

					// Simulate clicking the download link
					var event = document.createEvent('MouseEvents')
					event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null)
					link.dispatchEvent(event)
					console.log("Download link method with simulated click succeeded")
					success = true

				} catch (ex) {
					console.log("Download link method with simulated click failed with the following exception:")
					console.log(ex)
				}
			}

			if (!success) {
				// Fallback to window.location method
				try {
					// Prepare a blob URL
					// Use application/octet-stream when using window.location to force download
					console.log("Trying download link method with window.location ...")
					// eslint-disable-next-line
					var blob = new Blob([data], { type: octetStreamMime })
					// eslint-disable-next-line
					var url = urlCreator.createObjectURL(blob)
					window.location.href = url
					console.log("Download link method with window.location succeeded")
					success = true
				} catch (ex) {
					console.log("Download link method with window.location failed with the following exception:")
					console.log(ex)
				}
			}

		}
	}

	if (!success) {
		alert('Failed to display downloaded file.')
	}
}

export const download = (filename: string, text: string) => {
	var element = document.createElement('a')
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
	element.setAttribute('download', filename)

	element.style.display = 'none'
	document.body.appendChild(element)

	element.click()

	document.body.removeChild(element)
}

export enum TimeZone {
	'America/Los_Angeles' = 'America/Los_Angeles'
}

export const convertTimezone = (date: Date | string, timeZone: TimeZone) => new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString("en-US", { timeZone }))

export const normalizeTaxId = (taxId: string) => {
	/* 
		If the tax number is only 8 characters long, prepend a 0
		Admin\modules\reports\ministryAnalysis.js line 188
	*/
	if (taxId.length === 8) {
		taxId = `0${taxId}`
	}
	/* 
		If 9 characters long, format the Tax ID/EIN with a "-"
		Admin\modules\reports\ministryAnalysis.js line 192
	*/
	if (taxId.length === 9) {
		taxId = `${taxId.substr(0, 2)}-${taxId.substr(2, 9)}`
	}

	return taxId
}

export const getGuidestarProfileUrlForTaxId = (taxId: string) => `http://www2.guidestar.org/ReportNonProfit.aspx?ein=${normalizeTaxId(taxId)}`

export const getIrsOrganizationSearchUrlForTaxId = (taxId: string) => `https://apps.irs.gov/app/eos/allSearch.do?ein1=${normalizeTaxId(taxId)}&names=&resultsPerPage=25&indexOfFirstRow=0&dispatchMethod=searchAll&city=&state=All+States&country=US&postDateFrom=&postDateTo=&exemptTypeCode=al&deductibility=all&sortColumn=orgName&isDescending=false`

interface IUseOutsideAlerter {
	refs: (RefObject<HTMLDivElement> | Element | undefined)[]
	onOutsideClickHandler: () => void
	useEffectDependencies: any[]
}
export const useOutsideClickHandler = (options: IUseOutsideAlerter) => {
	const { refs, onOutsideClickHandler, useEffectDependencies } = options
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			let outsideWasClicked = true
			refs.forEach(ref => {
				if (ref instanceof Element) {
					if (event.target instanceof Node && ref.contains(event.target)) outsideWasClicked = false

				} else if (ref) {
					if (event.target instanceof Node && ref.current && ref.current.contains(event.target)) outsideWasClicked = false
				}
			})
			if (outsideWasClicked) onOutsideClickHandler()
		}

		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		//eslint-disable-next-line
	}, [...refs, ...useEffectDependencies])
}

/* 
	Basic hook to throttle a function invocation. 
	Useful when a costly function (e.g. api call) may be rapidly invoked by user input (e.g. search field input)
*/
export const useYieldThrottle = (_yield?: () => any): [(_yield: (...args: any) => any) => void, () => void] => {
	const [yields] = useState<{
		pendingAutoYield: NodeJS.Timeout
		_yield: (() => void)
	}[]>([])

	const clearYields = () => {
		if (yields.length > 0) {
			yields.forEach(y => clearTimeout(y.pendingAutoYield))
			yields.length = 0
		}
	}

	const newYield = (_yield: () => Promise<any> | any) => {
		clearYields()

		// Set up a timeout to delay the yield (AUTO_QUERY_DELAY_MS)
		const pendingAutoYield = setTimeout(() => {
			_yield()
		}, AUTO_QUERY_DELAY_MS)

		yields.push({ pendingAutoYield, _yield })
	}

	const yieldNow = () => {
		if (yields.length > 0) {
			const mostRecentYield = yields[yields.length - 1]
			clearYields()
			mostRecentYield._yield()
		}
	}

	return [newYield, yieldNow]
}

/* 
	Check for valid filter state

	- no enabled less than filter with a higher value than an enabled greater than filter
*/
export const validateFilterState = (filters: IFilter[], columns: IGridColumn[]) => {
	const filtersByColumn: Map<string, IFilter[]> | undefined = filters.reduce((_filtersByColumn, filter) => {
		const _columnFilters = _filtersByColumn.get(filter.property)
		if (_columnFilters) {
			_columnFilters.push(filter)
		} else {
			_filtersByColumn.set(filter.property, [filter])
		}

		return _filtersByColumn
	}, new Map<string, IFilter[]>())

	Array.from(filtersByColumn.keys()).forEach(colProperty => {
		const ltFilter = filtersByColumn.get(colProperty)?.find(f => f.operator === 'lt')
		const gtFilter = filtersByColumn.get(colProperty)?.find(f => f.operator === 'gt')


		if (ltFilter && gtFilter && ltFilter.enabled && gtFilter.enabled && ltFilter.value && gtFilter.value) {
			let invalidFilterCombination = false

			const col = columns.find(o => o.property === colProperty)

			if (isDateListFilterType(ltFilter) && isDateListFilterType(gtFilter)) {
				if (moment(ltFilter.value).isBefore(moment(gtFilter.value))) invalidFilterCombination = true
			}
			// @ts-ignore
			else if (ltFilter.value < gtFilter.value) {
				invalidFilterCombination = true
			}

			if (invalidFilterCombination) {
				if (window.confirm(`Your enabled "less than" filter value for "${col?.title}" is lower than your enabled "greater than" filter value. If you continue, your "greater than" filter will be automatically disabled. Continue?`)) {
					gtFilter.enabled = false
				} else {
					ltFilter.enabled = false
				}
			}
		}
	})
}

/* 
	Filter function to perform local filtering on local data using the same syntax as the server.
*/
export const filterGridListItems = (_items: IGridListItem[], filters: IFilter[]): IGridListItem[] => {
	for (const filter of filters) {
		const { enabled, value, operator, property } = filter
		if (enabled && value !== undefined) {
			switch (operator) {
				case 'eq':
				case '==':
					_items = _items.filter(item => {
						const item_value = item.values[property]
						if (item_value === value) return true

						if (item_value instanceof Date) {
							return moment(item_value).format('MM/DD/YYYY') === value
						}

						return false
					})
					break
				case 'in':
					if (value instanceof Array) {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							if (typeof itemPropertyValue === 'string') {
								return value.includes(itemPropertyValue)
							} else if (typeof itemPropertyValue === 'number') {
								return value.includes(itemPropertyValue.toString())
							} else if (isGridListItemObjectValueTypeArray(itemPropertyValue)) {
								return itemPropertyValue.some(item => value.includes(item.id.toString()))
							} else if (Array.isArray(itemPropertyValue)) {
								return value.some(v => itemPropertyValue.some(i => i.toString() === v.toString()))
							}

							return false
						})
					}
					break
				case 'like':
					if (typeof value === 'string') {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							return typeof itemPropertyValue === 'string' && itemPropertyValue.toLowerCase().includes(value.toLowerCase())
						})
					}
					break
				case 'not-like':
					if (typeof value === 'string') {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							return !(typeof itemPropertyValue === 'string' && itemPropertyValue.toLowerCase().includes(value.toLowerCase()))
						})
					}
					break
				case 'lt':
					if (typeof value === 'string') {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							if (itemPropertyValue !== null) {
								if (itemPropertyValue instanceof Date) {
									return dayjs(itemPropertyValue).isSameOrBefore(dayjs(value))
								} else if (typeof itemPropertyValue === 'string') {
									return itemPropertyValue < value
								} else if (typeof itemPropertyValue === 'number') {
									return itemPropertyValue.toString() < value
								}
								throw new Error(`Encountered an unsupported value in row when trying to apply ${operator} filter.`)
							}

							return false
						})
					} else if (typeof value === 'number') {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							if (itemPropertyValue !== null) {
								if (itemPropertyValue instanceof Date) {
									return dayjs(itemPropertyValue).isSameOrBefore(dayjs(value))
								} else if (typeof itemPropertyValue === 'string') {
									return itemPropertyValue < value.toString()
								} else if (typeof itemPropertyValue === 'number') {
									return itemPropertyValue < value
								}
								throw new Error(`Encountered an unsupported value in row when trying to apply ${operator} filter.`)
							}

							return false
						})
					} else {
						throw new Error(`Unsupported value type for ${operator} filter.`)
					}
					break
				case 'gt':
					if (typeof value === 'string') {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							if (itemPropertyValue !== null) {
								if (itemPropertyValue instanceof Date) {
									return dayjs(itemPropertyValue).isSameOrAfter(dayjs(value))
								} else if (typeof itemPropertyValue === 'string') {
									return itemPropertyValue > value
								} else if (typeof itemPropertyValue === 'number') {
									return itemPropertyValue.toString() > value
								}
								throw new Error(`Encountered an unsupported value in row when trying to apply ${operator} filter.`)
							}

							return false
						})
					} else if (typeof value === 'number') {
						_items = _items.filter(item => {
							const itemPropertyValue = item.values[property]
							if (itemPropertyValue !== null) {
								if (itemPropertyValue instanceof Date) {
									return dayjs(itemPropertyValue).isSameOrAfter(dayjs(value))
								} else if (typeof itemPropertyValue === 'string') {
									return itemPropertyValue > value.toString()
								} else if (typeof itemPropertyValue === 'number') {
									return itemPropertyValue > value
								}
								throw new Error(`Encountered an unsupported value in row when trying to apply ${operator} filter.`)
							}

							return false
						})
					} else {
						throw new Error(`Unsupported value type for ${operator} filter.`)
					}
					break
				default:
					throw new Error(`${operator} filter not implemented yet`)
			}
		}
	}

	return _items
}


export interface ITooltipProps {
	'data-toggle': string
	'data-placement': string
	title: string
	'data-html'?: string
}
interface IBuildTooltipOptions {
	tooltipText: string | null
	placement?: PopperPlacementPosition
	html?: boolean
	disable?: boolean
}
export const buildTooltipProps = (options: IBuildTooltipOptions): Partial<ITooltipProps> => {
	const { tooltipText, placement, html, disable } = options

	if (tooltipText && !disable) {
		const tooltipProps: ITooltipProps = {
			'data-toggle': 'tooltip',
			'data-placement': placement || 'auto',
			title: tooltipText,
		}

		if (html) tooltipProps['data-html'] = 'true'

		return tooltipProps
	}

	return {}
}

export const uuidv4 = (): string => {
	// @ts-ignore
	//eslint-disable-next-line
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
}

export const ensureUrlIncludesHttp = (url: string): string => {
	//eslint-disable-next-line
	if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
		url = "https://" + url
	}
	return url
}

export const accountLevelIdLabel = (levelId: number): string => {
	switch (levelId) {
		case 1:
			return 'Teaching'
		case 2:
			return 'Coaching'
		case 3:
			return 'Consulting'
		default:
			return 'No Account Level'
	}
}

export const validateEin = (value: any): boolean => {
	var cleaned = ('' + value).replace(/\D/g, '')
	var match = cleaned.match(/^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/g)
	if (match) {
		return true
	}
	return false
}

export const isOdd = (x: number): boolean => (x & 1) === 1
export const isEven = (x: number): boolean => (x & 1) === 0

export const openUrlInNewTab = (url: string) => {
	Object.assign(document.createElement('a'), { target: '_blank', href: url, rel: 'noopener noreferrer' }).click()
}

export const updateDocToJsonPatch = (doc: any): IJsonPatch[] => {
	const patches: IJsonPatch[] = []
	Object.keys(doc).forEach(property => {
		if (!isUndefined(doc[property])) {
			patches.push({
				op: 'add',
				path: `/${property}`,
				value: doc[property],
			})
		}
	})

	return patches
}

export const objectToOperationDocument = (doc: { [key: string]: any }): IOperationDocument[] => {
	const patches: IOperationDocument[] = []
	Object.keys(doc).forEach(property => {
		if (!isUndefined(doc[property])) {
			patches.push({
				op: 'add',
				path: `/${property}`,
				value: doc[property],
				operationType: 0,
				from: null
			})
		}
	})

	return patches
}

export const getEnumKeyForEnumValue = (_enum: any, value: any): string => {
	const enumEntries = Object.entries(_enum)
	const entry = enumEntries.find(_entry => _entry[1] === value)

	if (entry) {
		return entry[0]
	} else {
		throw new Error('Enum does not contain this value.')
	}
}


export const urlRegex = new RegExp(`(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})`)

export const hasDuplicates = (array: string[]) => new Set(array).size !== array.length

export const locationHashToObject = (hash: string): { [key: string]: string } => {
	if (!hash || !hash.length) return {}
	return hash.substr(1)
		.split('&')
		.map(v => v.split('='))
		.reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
}

// // TODO: this is a placeholder we're calling for all the places we'll need to implement permissions checks
// export enum PermissionCheckType {
// 	SuperAdmin,
// 	Role,
// }
// export const permissionsPlaceholder = (permissionCheckType: PermissionCheckType, pass = true, originalPermissionCheck?: string): boolean => {
// 	switch (permissionCheckType) {
// 		case PermissionCheckType.SuperAdmin:
// 			// alert(`super user permission check - you are ${pass ? '' : 'not'} a super user`)
// 			return pass
// 		case PermissionCheckType.Role:
// 			if (!originalPermissionCheck) throw new Error('Please provide the original permission check as a string to ensure we don\'t miss any.')
// 			// alert(`checking these permissions ${originalPermissionCheck} - you ${pass ? 'pass' : 'shall not pass'}`)
// 			return pass
// 		default:
// 			return pass
// 	}
// }

// ! Evil JQuery added because Bootstrap is still stuck in the JS past. 🙄
// * https://getbootstrap.com/docs/4.4/components/tooltips/#example-enable-tooltips-everywhere
// @ts-ignore
export const enableAllToolTips = () => $('[data-toggle="tooltip"]').tooltip()

// @ts-ignore
export const hideAllToolTips = () => $('[data-toggle="tooltip"]').tooltip('hide')

// @ts-ignore
export const disableAllToolTips = () => $('[data-toggle="tooltip"]').tooltip('hide')

export const destroyAllToolTips = () => {
	console.warn('Destroying all tooltips everywhere.')

	// @ts-ignore
	$('[data-toggle="tooltip"]').tooltip('dispose')
}

// @ts-ignore
export const enableTooltip = (elementId: string) => $(`#${elementId}`).tooltip('enable')

// @ts-ignore
export const disableTooltip = (elementId: string) => $(`#${elementId}`).tooltip('disable')

// @ts-ignore
export const destroyTooltip = (elementId: string) => $(`#${elementId}`).tooltip('dispose')

// @ts-ignore
export const showModal = (elementId: string) => $(`#${elementId}`).modal('show')

// @ts-ignore
export const hideModal = (elementId: string) => $(`#${elementId}`).modal('hide')

// @ts-ignore
export const destroyModal = (elementId: string) => $(`#${elementId}`).modal('dispose')

export const onTabShow = (): Promise<string> => {
	const promise = new Promise((resolve: (tabId: string) => void) => {
		// @ts-ignore
		$('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
			resolve(e.target.id)
		})
	})
	return promise
}

// @ts-ignore
export const showTab = (tabId: string) => $(`#${tabId}`).tab('show')

// @ts-ignore
export const showDropdown = (elementId: string) => $(`#${elementId}`).dropdown()

// These events fire when a modal has finished being hidden or finished being shown
export const onModalHidden = (modalId: string, callback: () => void) => $(`#${modalId}`).on('hidden.bs.modal', callback)
export const onModalShown = (modalId: string, callback: () => void) => $(`#${modalId}`).on('shown.bs.modal', callback)

// These events fire when a modal begins to be hidden or shown
export const onModalHide = (modalId: string, callback: () => void) => $(`#${modalId}`).on('hide.bs.modal', callback)
export const onModalShow = (modalId: string, callback: () => void) => $(`#${modalId}`).on('show.bs.modal', callback)


// When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. We can use this conditional wrapper
// to indicate when we want a wrapper to appear.
// https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
interface IConditionalWrapper {
	condition: boolean
	wrapper: (c: any) => any
	children: React.ReactNode
}
export const ConditionalWrapper = (props: IConditionalWrapper) => props.condition ? props.wrapper(props.children) : props.children

export const getQuarter = (month:number) => {
    if (month < 4) 
        return 1
    else if (month < 7) 
        return 2
    else if (month < 10) 
        return 3
    else if (month <= 12) 
        return 4
}

export const isValidEmail = (email:string) => {
	return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

export const getProgressStyling = (percentage: GridListItemValueType): React.CSSProperties => {
	if (typeof percentage !== 'number') return {}

	let backgroundColor = '#dc354520'
	if (percentage > 33) backgroundColor = '#ffc10720'
	if (percentage > 66) backgroundColor = '#28a74520'

	return {
		width: `${percentage}%`,
		backgroundColor,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		...percentage === 0 ? { marginLeft: 5 } : { paddingLeft: 5, }
	}
}