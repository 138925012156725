import { INotesSummaryDocument } from "../open-api"
import { IGridListItem } from "../stores/grid-definitions"

export class NoteModel {
	public static toGridListItem = (notesModel: INotesSummaryDocument): IGridListItem => {
		return {
			id: notesModel.noteId.toString(),
			values: {
				noteText: notesModel.noteText,
				userId: notesModel.userId,
				ministryId: notesModel.ministryId,
				ministryContactId: notesModel.ministryContactId,
				noteTypeId: notesModel.noteTypeId,
				callListId: notesModel.callListId,
				lengthHours: notesModel.lengthHours,
				eventId: notesModel.eventId,
				consultingEventGroup: notesModel.consultingEventGroup,
				noteType: notesModel.noteType,
				contactFirstName: notesModel.contactFirstName,
				contactLastName: notesModel.contactLastName,
				contactFullName: notesModel.contactLastName ? `${notesModel.contactFirstName} ${notesModel.contactLastName}` : null,
				createdByFirstName: notesModel.createdByFirstName,
				createdByLastName: notesModel.createdByLastName,
				createdByFullName: `${notesModel.createdByFirstName} ${notesModel.createdByLastName}`,
				dateEntered: notesModel.dateEntered ? new Date(notesModel.dateEntered) : null
			}
		}
	}
}