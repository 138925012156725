import { useState, useEffect, useContext } from 'react'
import { SimpleFileField, SelectField } from './forms'
import { uuidv4, download, filterGridListItems, sortListBySorts } from '../services/helpers'
import { parse, unparse } from 'papaparse'
import { IGridListItem, GridDataFetch, IGridState } from '../stores/grid-definitions'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { useMinistryProspectImportDefaultColumns, useHTTPRequestUiWrapper } from '../services/hooks'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

interface IImportState {
	missingHeaderRow?: boolean
	missingColumns?: string[]
	validColumns?: string[]
	invalidColumns?: string[]
	rows?: IGridListItem[]
	csvFile?: File
}
interface IProspectsCsvImportProps {
	onImportFinished?: () => void
}
export const ProspectsCsvImport = (props: IProspectsCsvImportProps) => {
	const { onImportFinished } = props

	const [fileFieldId] = useState(uuidv4())

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	const [importState, setImportState] = useState<IImportState>()

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const requiredColumns = ['First Name', 'Last Name']
	const optionalColumns = ['Ministry Name', 'Title', 'Email', 'Business Phone', 'Mobile Phone', 'Address', 'Address 2', 'City', 'State', 'Zip', 'Website']

	useEffect(() => {
		appActions.fetchTrainingAreas()
	}, [])

	const handleFileChange = (file: File | null) => {
		if (file) {
			parse<string>(file, {
				header: true,
				complete: (parseResults) => {
					// Make sure there's a header row
					if (!parseResults.meta.fields) {
						setImportState({ missingHeaderRow: true })
					} else {
						const fields = parseResults.meta.fields

						// Check to make sure both required columns are included
						if (requiredColumns.filter(c => fields.map(o => o.toLowerCase()).includes(c.toLowerCase())).length !== requiredColumns.length) {
							setImportState({
								missingHeaderRow: false,
								missingColumns: requiredColumns.filter(c => !fields.map(o => o.toLowerCase()).includes(c.toLowerCase()))
							})
						}
						// Valid CSV, need to validate the data itself
						else {
							const validColumns = [...requiredColumns, ...optionalColumns].filter(c => fields.map(o => o.toLowerCase()).includes(c.toLowerCase()))
							setImportState({
								missingHeaderRow: false,
								validColumns,
								invalidColumns: parseResults.meta.fields.filter(c => ![...requiredColumns, ...optionalColumns].map(o => o.toLowerCase()).includes(c.toLowerCase())),
								rows: parseResults.data.map((row, idx) => ({ id: idx.toString(), values: {test: row} })), // TB 20230518 - Altered this to make it pass typescript checks after upgrading papaparse lib, but unsure if it is working.
								csvFile: file,
							})
						}
					}
				}
			})
		} else {
			setImportState(undefined)
		}
	}

	const template = [
		{
			['First Name']: 'Jason',
			['Last Name']: 'Bourne',
			['Ministry Name']: 'CIA',
			['Title']: 'Agent at Large',
			['Email']: 'jbourne@cia.gov',
			['Business Phone']: '1234567890',
			['Mobile Phone']: '1234567890',
			['Address']: '123 Secret Dr.',
			['Address 2']: 'Atn: Special Agents Dept.',
			['City']: 'Langley',
			['State']: 'VA',
			['Zip']: '22101',
			['Website']: 'http://cia.gov/jason-bourne',
		}
	]

	const getProspects: GridDataFetch<IImportState> = async (queryState, _importState) => {
		let { rows } = _importState

		if (!rows) return { count: 0, rows: [] }

		let _rows = [...rows]

		if (queryState.sorts) sortListBySorts(_rows, queryState.sorts)
		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return {
			count: _rows.length,
			rows,
		}
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		columns: useMinistryProspectImportDefaultColumns(),
		usingLocalData: true,
		disabledPagination: true,
		rowSelectEnabled: true,
		hideGridFooter: true,
		hideGridHeader: true,
		dataSource: getProspects,
		gridActions: []
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, importState)

	return (
		<div>
			<div className='d-flex'>
				<div style={{ minWidth: 300 }}>
					<SimpleFileField id={fileFieldId} accept='.csv' onChange={handleFileChange} />
					<button onClick={() => download('mission_increase_prospect_import.csv', unparse(template, { header: true }))} style={{ width: '100%' }} className='btn btn-sm btn-secondary'>Download Template</button>
				</div>
				<div className='ml-4'>
					<div
						style={importState ? { fontWeight: 700 } : {}}
						className={`${importState?.missingHeaderRow === true ? 'text-danger' : ''} ${importState?.missingHeaderRow === false ? 'text-success' : ''}`}
					>
						A header row is required.
						</div>
					<div>Required columns: {requiredColumns.map((o, idx) =>
						<span
							className={`${importState?.validColumns?.map(c => c.toLowerCase()).includes(o.toLowerCase()) ? 'text-success bold' : ''} ${importState?.missingColumns?.map(c => c.toLowerCase()).includes(o.toLowerCase()) ? 'text-danger bold' : ''}`}
							key={o}
						>
							{o}{idx < requiredColumns.length - 1 ? ', ' : null}
						</span>)}
					</div>
					<div>Optional columns: {optionalColumns.map((o, idx) =>
						<span
							className={`${importState?.validColumns?.map(c => c.toLowerCase()).includes(o.toLowerCase()) ? 'text-success bold' : ''}`}
							key={o}
						>
							{o}{idx < optionalColumns.length - 1 ? ', ' : null}
						</span>)}
					</div>
					{importState?.invalidColumns && importState?.invalidColumns.length > 0 ?
						<div>Ignored columns: {importState.invalidColumns.map((o, idx) => <span className='text-warning' key={o}>{o}{idx < importState.invalidColumns!.length - 1 ? ', ' : null}</span>)}</div>
						:
						null
					}
				</div>
			</div>
			{importState?.rows ?
				<div className='mt-4 d-flex flex-column'>
					<div className='mb-2 d-flex'>
						{gridState.rows.filter(r => r.selected).length > 0 ?
							<Formik
								initialValues={{
									branchId: appState.globalCommunityContext?.branchId.toString() || '',
									trainingAreaId: '',
								}}
								validationSchema={Yup.object({
									branchId: Yup.string().required('Please select a community.')
								})}
								onSubmit={async (values) => {
									await makeHttpRequestWithUi({
										request: appActions.ProspectsApi.apiProspectsImportJsonPost({
											branchId: parseInt(values.branchId),
											trainingAreaId: parseInt(values.trainingAreaId),
											prospects: gridState.rows.filter(r => r.selected).map(row => ({
												organizationName: typeof row.values['Ministry Name'] === 'string' ? row.values['Ministry Name'] : null,
												title: typeof row.values['Title'] === 'string' ? row.values['Title'] : null,
												firstName: typeof row.values['First Name'] === 'string' ? row.values['First Name'] : null,
												lastName: typeof row.values['Last Name'] === 'string' ? row.values['Last Name'] : null,
												email: typeof row.values['Email'] === 'string' ? row.values['Email'] : null,
												businessPhone: typeof row.values['Business Phone'] === 'string' ? row.values['Business Phone'] : null,
												mobilePhone: typeof row.values['Mobile Phone'] === 'string' ? row.values['Mobile Phone'] : null,
												address1: typeof row.values['Address'] === 'string' ? row.values['Address'] : null,
												address2: typeof row.values['Address 2'] === 'string' ? row.values['Address 2'] : null,
												city: typeof row.values['City'] === 'string' ? row.values['City'] : null,
												state: typeof row.values['State'] === 'string' ? row.values['State'] : null,
												postalCode: typeof row.values['Zip'] === 'string' ? row.values['Zip'] : null,
												siteUrl: typeof row.values['Website'] === 'string' ? row.values['Website'] : null,
											}))
										}),
										toastErrorMessage: 'There was an error importing prospects.',
										toastSuccessMessage: 'Prospects imported successfuly.'
									})

									if (onImportFinished) onImportFinished()
								}}
							>
								{formikProps => {
									return (
										<Form className='d-flex align-items-center'>
											<div style={{ width: 300 }} className='mr-2'>
												<SelectField
													fieldProps={{ name: 'branchId', label: 'Community', placeholder: 'Select a Community' }}
													options={appState.activeBranches.map(o => ({ label: `${o.branchAbbr} (${o.branchName})`, value: `${o.branchId}` }))}
												/>
											</div>
											<div style={{ width: 300 }} className='mr-2'>
												<SelectField
													disabled={!formikProps.values.branchId}
													fieldProps={{ name: 'trainingAreaId', label: 'Training Area' }}
													options={appState.activeTrainingAreas?.filter(trainingArea => trainingArea.branchId.toString() === formikProps.values.branchId).map(o => ({ label: `${o.trainingAreaAbbr} (${o.trainingArea})`, value: `${o.trainingAreaId}` }))}
												/>
											</div>
											<div>
												<button type='submit' className='btn btn-primary' style={{ width: 300 }}>Import {gridState.rows.filter(r => r.selected).length} Prospect{gridState.rows.filter(r => r.selected).length === 1 ? '' : 's'}</button>
											</div>
										</Form>
									)
								}}
							</Formik>
							:
							<b>Select at least one prospect to import.</b>
						}
					</div>
					<Grid state={gridState} actions={gridActions} style={{ height: 'unset' }} />
				</div>
				:
				null
			}
		</div>
	)
}