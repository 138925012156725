import { IGrantGiftModelDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import { GrantFormTabs } from "./grant-form-tabs"

interface IGrantFormProps extends IDefaultProps {
    grantToEdit?: IGrantGiftModelDocument
    afterSave: () => Promise<void>
}

export const GrantForm = (props: IGrantFormProps) => {
    const { grantToEdit, afterSave } = props

    return <GrantFormTabs
                grantToEdit={grantToEdit}
                afterSave={afterSave}       
            />
}