import { AxiosResponse } from 'axios'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IEventListItemDocument, IMailBlastRecipientDocument, IPotentialRecipientDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { useHTTPRequestUiWrapper, usePotentialRecipientsDefaultColumns } from '../services/hooks'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { FormikEffect } from './formik-effect'
import { CommunitySelectField, SelectField } from './forms'
import { PotentialRecipient } from '../models/potential-recipient'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { ExpandableSection } from './expandable-section'

interface ISelectRecipientsByEvents {
	recipientsSelected: (recipients: IMailBlastRecipientDocument[]) => void,
	mailTypeIds?: number[]
}

export const SelectRecipientsByEvents = (props: ISelectRecipientsByEvents) => {
	const { recipientsSelected, mailTypeIds } = props

	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [eventLists, setEventLists] = useState<{ pastEvents: IEventListItemDocument[], upcomingEvents: IEventListItemDocument[] }>()

	const refreshEventLists = async (communityIds: string[] | null) => {
		if (communityIds) {
			const promises: [Promise<AxiosResponse<IEventListItemDocument[]>>, Promise<AxiosResponse<IEventListItemDocument[]>>] = [
				makeHttpRequestWithUi({
					request: appActions.MailBlastApi.apiMailBlastPotentialRecipientsGetPastEventsListGet(communityIds.map(o => parseInt(o))),
					disableSuccessToast: true,
					toastErrorMessage: 'Encountered an error retrieving past events for these communities.'
				}),
				makeHttpRequestWithUi({
					request: appActions.MailBlastApi.apiMailBlastPotentialRecipientsGetFutureEventsListGet(communityIds.map(o => parseInt(o))),
					disableSuccessToast: true,
					toastErrorMessage: 'Encountered an error retrieving upcoming events for these communities.'
				})
			]

			const results = await Promise.all(promises)

			setEventLists({
				pastEvents: results[0].data,
				upcomingEvents: results[1].data,
			})
		} else {
			setEventLists(undefined)
		}
	}

	const [potentialRecipients, setPotentialRecipients] = useState<IPotentialRecipientDocument[]>()

	const dataSource: GridDataFetch<IPotentialRecipientDocument[]> = async (queryState, _potentialRecipients) => {

		if (!_potentialRecipients) return { rows: [], count: 0 }

		if (queryState.sorts) sortListBySorts(_potentialRecipients, queryState.sorts)

		let rows = _potentialRecipients.map(PotentialRecipient.toGridListItem)

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: true,
		columns: usePotentialRecipientsDefaultColumns(),
		disabledPagination: true,
		hideGridHeader: true,
		dataSource,
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, potentialRecipients)

	useEffect(() => {
		recipientsSelected(gridState.rows.filter(r => r.selected).map(r => ({ name: `${r.values.fullName}`, email: `${r.values.email}`, ministryContactId: r.values.ministryContactID, prospectId: r.values.prospectId })) as IMailBlastRecipientDocument[])
		//eslint-disable-next-line
	}, [gridState.rows])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [potentialRecipients])


	return (
		<Formik
			initialValues={{
				community: [],
				attended: [],
				excluding: [],
				notRegistered: [],
			}}
			onSubmit={async (values) => {
				const contactQuery = await makeHttpRequestWithUi({
					request: appActions.MailBlastApi.apiMailBlastPotentialRecipientsGetByEventsGet(values.community, values.attended, values.notRegistered, values.excluding, mailTypeIds),
					toastSuccessMessage: 'Successfully retrieved contacts.',
					toastErrorMessage: 'Encountered an error retrieving contacts.'
				})

				setPotentialRecipients(contactQuery.data.filter(o => !!o.email))
			}}
		>

			{formikProps => {
				return (
					<Form style={{ height: '100%' }}>
						<FormikEffect
							formikProps={formikProps}
							onChange={(prev, next) => {
								if (prev.community !== next.community) refreshEventLists(next.community)
							}}
						/>

						<ExpandableSection>
							<div>
								<div className='mb-2'><b>Unsubscribed</b> emails are excluded from the results.</div>
								<div style={{ marginBottom: '8px' }}>
									Returns a list of all Ministry Contacts for a given branch based on whether or not they attended specific events, or are registered for upcoming events.
									Does not return Prospects.
								</div>
								<div className='d-flex'>
									<div style={{ width: '25%' }} className='mr-2'>
										<CommunitySelectField multiple={true} fieldProps={{ name: 'community', label: 'Community' }} />
									</div>
									<div style={{ width: '25%' }} className='mr-2'>
										<SelectField multiple={true} disabled={!eventLists?.pastEvents} fieldProps={{ name: 'attended', label: 'Attended' }} options={eventLists?.pastEvents.map(o => ({ label: `${o.title}`, value: `${o.eventId}` }))} />
									</div>
									<div style={{ width: '25%' }} className='mr-2'>
										<SelectField multiple={true} disabled={!eventLists?.pastEvents} fieldProps={{ name: 'excluding', label: 'Excluding' }} options={eventLists?.pastEvents.map(o => ({ label: `${o.title}`, value: `${o.eventId}` }))} />
									</div>
									<div style={{ width: '25%' }}>
										<SelectField multiple={true} disabled={!eventLists?.upcomingEvents} fieldProps={{ name: 'notRegistered', label: 'Not Registered' }} options={eventLists?.upcomingEvents.map(o => ({ label: `${o.title}`, value: `${o.eventId}` }))} />
									</div>
								</div>
							</div>

							<button type='button' className='btn btn-primary mb-4' onClick={formikProps.submitForm}>Load Contacts</button>
						</ExpandableSection>

						{potentialRecipients ?
							<React.Fragment>
								<b>{potentialRecipients.length} contact{potentialRecipients.length === 1 ? '' : 's'}</b>
								<p><i>The results include non-archived and archived primary and secondary contacts.</i></p>
								<Grid state={gridState} actions={gridActions} style={{ height: 'auto', flex: '1' }} />
							</React.Fragment>
							:
							null
						}
					</Form>
				)
			}}
		</Formik>
	)
}