import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SelectField, ISelectFieldOption, TextField, NumberField, EmailField, DateStringField, CheckboxField } from './forms'
import { IZStatesDocument, IMinistryContactPostModelDocument, IMinistryContactSummaryModelDocument, IMinistryContactModelDocument } from '../open-api'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from './loading'
import { CANADA_COUNTRY_ID, MAIL_TYPE_OPTIONS, UNITED_STATES_COUNTRY_ID } from '../constants'
import { Editor } from '@tinymce/tinymce-react'
import { FormikEffect } from './formik-effect'
import { uuidv4, showModal, getYearOptions } from '../services/helpers'
import { Modal } from './modal'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { uniq } from 'lodash'
import { MinistryContactType } from '../constants'
import { FormikSelectField } from './forms/formik-select-field'
import { FormikCountrySelectField } from './forms/formik-country-select-field'
import { FormikStateSelectField } from './forms/formik-state-select-field'

export enum MinistryContactStaffTypeTitle {
	'Founder/President' = 1,
	'CEO/Exec. Director' = 2,
	'CFO/VP Finance' = 3,
	'CTO/IT Manager' = 26,
	'VP Fundraising / Dir. of Development' = 5,
	'Program Vice President/Director/Administrator' = 10,
	'Business Administrator/Dir. of Operations' = 12,
	'Administrative/Support Staff' = 14,
	'Major Gifts / Donor Relations' = 6,
	'Event Coordinator' = 7,
	'Fundraising /Development Staff' = 8,
	'Communications/Marketing/PR Staff' = 9,
	'Accountant/Bookkeeper' = 4,
	'Program Staff' = 11,
	'Volunteer Coordinator' = 13,
	'IT Staff' = 27,
	'Pastor/Staff Pastor' = 28,
	'Ministry Support' = 29
}

export enum MinistryContactVolunteerTypeTitle {
	'Accounting' = 20,
	'CEO/Director' = 19,
	'Fundraising Department' = 21,
	'Fundraising Events' = 22,
	'Marketing/PR' = 23,
	'Ministry Programs' = 24,
	'Other' = 30,
	'Volunteer Coordinator' = 25
}

export enum MinistryContactBoardTypeTitle {
	'Board Chair' = 15,
	'Board Treasurer' = 16,
	'Board Secretary' = 17,
	'Board Member' = 18,
}

export enum MinistryContactSalutation {
	'Mr.' = 1,
	'Ms.' = 2,
	'Mrs.' = 3,
	'Dr.' = 4,
}

export enum MinistryContactAccessLevel {
	'No Access' = -1,
	'Admin' = 0,
	'View' = 1,
	'Modify' = 2,
}

export enum LoginStatus {
	'CreateContact' = 0,
	'CanLogin' = 1,
	'NoEmail' = 2,
	'HasEmail' = 3,
	'NoPermission' = 4,
}

interface IContactFormProps {
	ministryContact?: IMinistryContactSummaryModelDocument // A ministry contact to be edited
	ministryId: number // The ministry the contact is for
	afterSave?: (primaryContactSaved?: boolean) => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
	states: IZStatesDocument[]
	header?: (saveButton: JSX.Element) => JSX.Element
	//blacklistMailTypes: IMailTypeDocument[]
}

export const getContactTitleOptions = (selectedContactTypeIds: number[] | null): ISelectFieldOption[] => {
	let options: ISelectFieldOption[] = []

	if (selectedContactTypeIds) {
		uniq(selectedContactTypeIds).forEach(contactTypeId => {
			switch (contactTypeId) {
				case MinistryContactType['Volunteer/Champion']:
					// @ts-ignore
					options.push(...Object.keys(MinistryContactVolunteerTypeTitle).filter(key => isNaN(key)).map(key => ({ value: MinistryContactVolunteerTypeTitle[key], label: key })))
					break
				case MinistryContactType.Staff:
					// @ts-ignore
					options.push(...Object.keys(MinistryContactStaffTypeTitle).filter(key => isNaN(key)).map(key => ({ value: MinistryContactStaffTypeTitle[key], label: key })))
					break
				case MinistryContactType.Board:
					// @ts-ignore
					options.push(...Object.keys(MinistryContactBoardTypeTitle).filter(key => isNaN(key)).map(key => ({ value: MinistryContactBoardTypeTitle[key], label: key })))
					break
			}
		})
	}

	return options
}

export const ContactForm = (props: IContactFormProps) => {
	const { ministryId, afterSave, ministryContact, states, header } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const [showIsPrimaryField, setShowIsPrimaryField] = useState(true)


	// @ts-ignore
	const getMinistryAccessLevelOptions = (): ISelectFieldOption[] => Object.keys(MinistryContactAccessLevel).filter(key => isNaN(key)).map(key => ({ value: MinistryContactAccessLevel[key].toString(), label: key }))

	/* This validator is user for the hireMonth and hireYear fields */
	const contactSubTypeRequiresHireDateFields = (contactSubTypeId: number) =>
		contactSubTypeId === MinistryContactStaffTypeTitle["CEO/Exec. Director"] ||
		contactSubTypeId === MinistryContactStaffTypeTitle["VP Fundraising / Dir. of Development"] ||
		contactSubTypeId === MinistryContactStaffTypeTitle["Major Gifts / Donor Relations"] ||
		contactSubTypeId === MinistryContactStaffTypeTitle["Fundraising /Development Staff"]

	const hireDateValidator = Yup
		.mixed()
		.test(
			'hireMonth',
			'Hired date is required for the selected Title.',
			function (value) {
				let contactSubTypeId = this.parent.contactSubTypeId
				if (typeof contactSubTypeId === 'string') contactSubTypeId = parseInt(contactSubTypeId)
				if (contactSubTypeRequiresHireDateFields(parseInt(contactSubTypeId))) return !!value
				return true
			}
		)

	/* This validator is used to check each permission type */
	const primaryContactPermissionsLevelValidator = Yup
		.string()
		.test(
			'adminPermissionsCheck',
			'This is the primary contact. It must have full Admin privileges.',
			function (value) {
				//if (typeof value === 'string') value = parseInt(value)
				if (this.parent.isPrimary) {
					return value == MinistryContactAccessLevel.Admin.toString()
				} else {
					return true
				}
			}
		)

	const [primaryContactWarningModalId] = useState(uuidv4())
	const [allPermissionsNoAccessWarningModalId] = useState(uuidv4())

	const [beforeSaveWarningModalId] = useState(uuidv4())
	const [beforeSaveWarningMessages, setBeforeSaveWarningMessages] = useState<string[]>()
	const [beforeSaveWarningMessages_SuperUser, setBeforeSaveWarningMessages_SuperUser] = useState<string[]>()

	const [duplicateEmailModalId] = useState(uuidv4())
	const [duplicateEmail, setDuplicateEmail] = useState<boolean>()
	const [stateOptions, setStateOptions] = useState<ISelectFieldOption[]>()

	/* 
			We need to perform a check for duplicate contacts in two scenarios:
			1) A new contact is being created
			2) An existing contact is being edited AND the user has changed that existing contacts email and/or username.

			We need to check for an existing contact in case the user has changed the contact's username or email.
			If they have, we need to perform a duplicate check to make sure they do not inadvertantly edit an existing
			contact's credentials to match another existing contact's credentials.
	*/
	const [performDuplicateContactCheck, setPerformDuplicateContactCheck] = useState(true)

	const disablePermissions = false

	let resetForm: () => void
	useEffect(() => {
		if (resetForm) resetForm()

		setPerformDuplicateContactCheck(false)

		//setShowIsPrimaryField(!ministryContact || !ministryContact.isPrimary)

		// eslint-disable-next-line
	}, [ministryContact])

	if (!states) {
		return <Loading />
	}

	const initialValues = {
		ministryContactId: ministryContact?.ministryContactId || null,
		contactTypeId: ministryContact?.contactTypeId || '',
		contactSubTypeId: ministryContact?.contactSubTypeId || '',
		salutationId: ministryContact?.salutationId || '',
		firstName: ministryContact?.firstName || '',
		lastName: ministryContact?.lastName || '',
		hireMonth: ministryContact?.hireMonth || '',
		hireYear: ministryContact?.hireYear || '',
		businessPhone: ministryContact?.businessPhone || '',
		businessPhoneExt: ministryContact?.businessPhoneExt || '',
		email: ministryContact?.email || '',
		termStarted: ministryContact?.termStarted || '',
		vocation: ministryContact?.vocation || '',
		permissionId_Profile: ministryContact?.permissionId_Profile !== null && ministryContact?.permissionId_Profile !== undefined ? ministryContact.permissionId_Profile : MinistryContactAccessLevel["No Access"],
		permissionId_Contacts: ministryContact?.permissionId_Contacts !== null && ministryContact?.permissionId_Contacts !== undefined ? ministryContact.permissionId_Contacts : MinistryContactAccessLevel.View,
		permissionId_Training: ministryContact?.permissionId_Training !== null && ministryContact?.permissionId_Training !== undefined ? ministryContact.permissionId_Training : MinistryContactAccessLevel.Modify,
		permissionId_Grants: ministryContact?.permissionId_Grants !== null && ministryContact?.permissionId_Grants !== undefined ? ministryContact.permissionId_Grants : MinistryContactAccessLevel["No Access"],
		permissionId_Resources: ministryContact?.permissionId_Resources !== null && ministryContact?.permissionId_Resources !== undefined ? ministryContact.permissionId_Resources : MinistryContactAccessLevel["No Access"],
		homePhone: ministryContact?.homePhone || '',
		cellPhone: ministryContact?.cellPhone || '',
		countryId: ministryContact?.countryId || null,
		address1: ministryContact?.address1 || '',
		address2: ministryContact?.address2 || '',
		city: ministryContact?.city || '',
		stateId: ministryContact?.stateId || '',
		stateOther: ministryContact?.stateOther || '',
		postalCode: ministryContact?.postalCode || '',
		isPrimary: ministryContact?.isPrimary || false,
		gtoNotes: ministryContact?.gtoNotes || '',
		blacklistMailTypeIds: ministryContact?.blacklistMailTypeIds?.map(x => x.toString()) || [],
	}

	var loginStatus = 0
	if (ministryContact) {
		if (ministryContact.subjectId && ministryContact.doesUserHavePermissionToLogin) {
			loginStatus = LoginStatus.CanLogin
		} else if (!ministryContact.doesUserHavePermissionToLogin) {
			// Has SSO account, but no permissions
			loginStatus = LoginStatus.NoPermission
		} else if (!ministryContact.subjectId) {
			// No SSO Account
			if (!ministryContact.email) {
				loginStatus = LoginStatus.NoEmail
			} else {
				loginStatus = LoginStatus.HasEmail
			}
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object({
				contactTypeId: Yup.string()
					.test(
						'requiredIfNotSuperUser',
						'Required',
						function (value) {
							if (!appState.currentUser?.bSuperUser) {
								return !!value
							} else {
								return true
							}
						}
					),
				contactSubTypeId: Yup.string()
					.test(
						'requiredIfNotSuperUser',
						'Required',
						function (value) {
							if (!appState.currentUser?.bSuperUser) {
								return !!value
							} else {
								return true
							}
						}
					),
				firstName: Yup.string()
					.required('Required')
					.max(250, 'Cannot be more than 250 characters.'),
				lastName: Yup.string()
					.required('Required')
					.max(250, 'Cannot be more than 250 characters.'),
				hireMonth: hireDateValidator,
				hireYear: hireDateValidator,
				cellPhone: Yup.string()
					.test(
						'cellPhone',
						`Cell Phone is required when adding new contact and Contact Type is Staff.`,
						function (value) {
							if (!appState.currentUser?.bSuperUser && (!ministryContact && this.parent.contactTypeId == MinistryContactType.Staff)) {
								return !!value
							} else {
								return true
							}
						}
					),
				businessPhoneExt: Yup.string().max(5, 'Cannot be more than 5 characters.'),
				address1: Yup.string().max(250, 'Cannot be more than 250 characters.'),
				address2: Yup.string().max(250, 'Cannot be more than 250 characters.'),
				city: Yup.string().max(250, 'Cannot be more than 250 characters.'),
				postalCode: Yup.string().max(250, 'Cannot be more than 250 characters.'),
				vocation: Yup.string().max(250, 'Cannot be more than 250 characters.'),
				email: Yup.string()
					.email('Must be a valid email address.'),
				permissionId_Profile: primaryContactPermissionsLevelValidator,
				permissionId_Contacts: primaryContactPermissionsLevelValidator,
				permissionId_Training: primaryContactPermissionsLevelValidator,
				permissionId_Grants: primaryContactPermissionsLevelValidator,
				permissionId_Resources: primaryContactPermissionsLevelValidator,
			})}
			onSubmit={async (values, actions) => {
				const taskId = registerLoadingTask()

				if (performDuplicateContactCheck) {
					const duplicateEmailCheckQuery = await appActions.MinistryContactsApi.apiMinistryContactsDuplicateCheckPost({ ministryContactId: values.ministryContactId || 0, email: values.email })

					if (duplicateEmailCheckQuery.data) {
						deregisterLoadingTask(taskId)
						showModal(duplicateEmailModalId)
						return
					}
				}

				// Save new contact
				if (!ministryContact) {
					const ministryContactRecord: IMinistryContactPostModelDocument = {
						ministryId,
						contactTypeId: values.contactTypeId.toString().length > 0 ? parseInt(values.contactTypeId.toString()) : MinistryContactType.Staff,
						contactSubTypeId: values.contactSubTypeId.toString().length > 0 ? parseInt(values.contactSubTypeId.toString()) : MinistryContactStaffTypeTitle["Program Staff"],
						salutationId: values.salutationId.toString().length > 0 ? parseInt(values.salutationId.toString()) : null,
						firstName: values.firstName,
						lastName: values.lastName,
						hireMonth: values.hireMonth.length > 0 ? values.hireMonth : null,
						hireYear: values.hireYear.length > 0 ? values.hireYear : null,
						businessPhone: values.businessPhone.length > 0 ? values.businessPhone : null,
						businessPhoneExt: String(values.businessPhoneExt).length > 0 ? values.businessPhoneExt : null,
						homePhone: values.homePhone.length > 0 ? values.homePhone : null,
						cellPhone: values.cellPhone.length > 0 ? values.cellPhone : null,
						countryId: values.countryId ? values.countryId : null,
						address1: values.address1.length > 0 ? values.address1 : null,
						address2: values.address2.length > 0 ? values.address2 : null,
						city: values.city.length > 0 ? values.city : null,
						stateId: values.stateId.toString().length > 0 ? parseInt(values.stateId.toString()) : null,
						stateOther: values.stateOther,
						postalCode: values.postalCode.length > 0 ? values.postalCode : null,
						termStarted: values.termStarted.length > 0 ? values.termStarted : null,
						vocation: values.vocation.length > 0 ? values.vocation : null,
						title: null,
						email: values.email.length > 0 ? values.email : null,
						isPrimary: values.isPrimary,
						permissionId_Profile: typeof values.permissionId_Profile === 'string' ? parseInt(values.permissionId_Profile) : values.permissionId_Profile,
						permissionId_Contacts: typeof values.permissionId_Contacts === 'string' ? parseInt(values.permissionId_Contacts) : values.permissionId_Contacts,
						permissionId_Training: typeof values.permissionId_Training === 'string' ? parseInt(values.permissionId_Training) : values.permissionId_Training,
						permissionId_Grants: typeof values.permissionId_Grants === 'string' ? parseInt(values.permissionId_Grants) : values.permissionId_Grants,
						permissionId_Resources: typeof values.permissionId_Resources === 'string' ? parseInt(values.permissionId_Resources) : values.permissionId_Resources,
						gtoNotes: values.gtoNotes.length > 0 ? values.gtoNotes : null,
						blacklistMailTypeIds: values.blacklistMailTypeIds.length > 0 ? values.blacklistMailTypeIds.map(mailId => parseInt(mailId.toString())) : [],
						sendInviteEmail: true
					}

					await appActions.MinistryContactsApi.apiMinistryContactsPost(ministryContactRecord)
				}
				// Update existing contact
				else {
					const ministryContactRecord: IMinistryContactModelDocument = {
						...ministryContact,
						ministryId,
						contactTypeId: values.contactTypeId.toString().length > 0 ? parseInt(values.contactTypeId.toString()) : MinistryContactType.Staff,
						contactSubTypeId: values.contactSubTypeId.toString().length > 0 ? parseInt(values.contactSubTypeId.toString()) : MinistryContactStaffTypeTitle["Program Staff"],
						salutationId: values.salutationId.toString().length > 0 ? parseInt(values.salutationId.toString()) : null,
						firstName: values.firstName,
						lastName: values.lastName,
						hireMonth: values.hireMonth.length > 0 ? values.hireMonth : null,
						hireYear: values.hireYear.length > 0 ? values.hireYear : null,
						businessPhone: values.businessPhone.length > 0 ? values.businessPhone : null,
						businessPhoneExt: String(values.businessPhoneExt).length > 0 ? values.businessPhoneExt : null,
						homePhone: values.homePhone.length > 0 ? values.homePhone : null,
						cellPhone: values.cellPhone.length > 0 ? values.cellPhone : null,
						countryId: values.countryId ? values.countryId : null,
						address1: values.address1.length > 0 ? values.address1 : null,
						address2: values.address2.length > 0 ? values.address2 : null,
						city: values.city.length > 0 ? values.city : null,
						stateId: values.stateId.toString().length > 0 ? parseInt(values.stateId.toString()) : null,
						stateOther: values.stateOther,
						postalCode: values.postalCode.length > 0 ? values.postalCode : null,
						termStarted: values.termStarted.length > 0 ? values.termStarted : null,
						vocation: values.vocation.length > 0 ? values.vocation : null,
						title: null,
						email: values.email.length > 0 ? values.email : null,
						isPrimary: values.isPrimary,
						permissionId_Profile: typeof values.permissionId_Profile === 'string' ? parseInt(values.permissionId_Profile) : values.permissionId_Profile,
						permissionId_Contacts: typeof values.permissionId_Contacts === 'string' ? parseInt(values.permissionId_Contacts) : values.permissionId_Contacts,
						permissionId_Training: typeof values.permissionId_Training === 'string' ? parseInt(values.permissionId_Training) : values.permissionId_Training,
						permissionId_Grants: typeof values.permissionId_Grants === 'string' ? parseInt(values.permissionId_Grants) : values.permissionId_Grants,
						permissionId_Resources: typeof values.permissionId_Resources === 'string' ? parseInt(values.permissionId_Resources) : values.permissionId_Resources,
						gtoNotes: values.gtoNotes.length > 0 ? values.gtoNotes : null,
						blacklistMailTypeIds: values.blacklistMailTypeIds?.length > 0 ? values.blacklistMailTypeIds.map(mailId => parseInt(mailId.toString())) : [],
						sendInviteEmail: false
					}

					await appActions.MinistryContactsApi.apiMinistryContactsIdPut(ministryContact.ministryContactId, ministryContactRecord)
				}

				//actions.resetForm()
				deregisterLoadingTask(taskId)
				if (afterSave) afterSave(values.isPrimary)
			}}
		>
			{(formikProps) => {
				resetForm = formikProps.resetForm
				return (
					<React.Fragment>
						<FormikEffect formikProps={formikProps} onChange={(prevValues, nextValues) => {
							// If permissions are changing from at least one with access to all with 'no access', warn the user of the consequences
							if (
								(
									prevValues.permissionId_Profile.toString() !== MinistryContactAccessLevel["No Access"].toString() ||
									prevValues.permissionId_Contacts.toString() !== MinistryContactAccessLevel["No Access"].toString() ||
									prevValues.permissionId_Training.toString() !== MinistryContactAccessLevel["No Access"].toString() ||
									prevValues.permissionId_Grants.toString() !== MinistryContactAccessLevel["No Access"].toString() ||
									prevValues.permissionId_Resources.toString() !== MinistryContactAccessLevel["No Access"].toString()
								)
								&&
								(
									nextValues.permissionId_Profile.toString() === MinistryContactAccessLevel["No Access"].toString() &&
									nextValues.permissionId_Contacts.toString() === MinistryContactAccessLevel["No Access"].toString() &&
									nextValues.permissionId_Training.toString() === MinistryContactAccessLevel["No Access"].toString() &&
									nextValues.permissionId_Grants.toString() === MinistryContactAccessLevel["No Access"].toString() &&
									nextValues.permissionId_Resources.toString() === MinistryContactAccessLevel["No Access"].toString()
								)
							) {
								showModal(allPermissionsNoAccessWarningModalId)
							}

							// Only perform these effects if the effect came from changes to a form for the same contact. Don't perform these effects if the changes are from loading a new contact into the form.
							if (prevValues.ministryContactId !== nextValues.ministryContactId) return

							// if (prevValues.contactTypeId !== MinistryContactType.Volunteer && nextValues.contactTypeId === MinistryContactType.Volunteer) {
							// 	formikProps.setValues(
							// 		{
							// 			...formikProps.values,
							// 			permissionId_Profile: MinistryContactAccessLevel["No Access"],
							// 			permissionId_Contacts: MinistryContactAccessLevel.View,
							// 			permissionId_Training: MinistryContactAccessLevel["No Access"],
							// 			permissionId_Grants: MinistryContactAccessLevel["No Access"],
							// 			permissionId_Resources: MinistryContactAccessLevel["No Access"],
							// 		}
							// 	)
							// } else if (prevValues.contactTypeId === MinistryContactType.Volunteer && nextValues.contactTypeId !== MinistryContactType.Volunteer) {
							// 	formikProps.setValues(
							// 		{
							// 			...formikProps.values,
							// 			permissionId_Profile: MinistryContactAccessLevel["No Access"],
							// 			permissionId_Contacts: MinistryContactAccessLevel.View,
							// 			permissionId_Training: MinistryContactAccessLevel.Modify,
							// 			permissionId_Grants: MinistryContactAccessLevel["No Access"],
							// 			permissionId_Resources: MinistryContactAccessLevel["No Access"],
							// 		}
							// 	)
							// }

							// Alert if the user changes isPrimary from 'false' to 'true' 
							if (!prevValues.isPrimary && nextValues.isPrimary) showModal(primaryContactWarningModalId)

							// If the user has changed the email address we need to perform a duplicate check
							if (prevValues.email !== nextValues.email) setPerformDuplicateContactCheck(true)
						}} />
						<Form>
							{ header 
								? header(<button type='button' className='btn btn-primary mr-2 min-width-md' onClick={async () => {
										const warnings: string[] = []
										const superUserwarnings: string[] = []

										if (!formikProps.values.email) warnings.push('An email address is required to attend events and to log into the Ministry Portal. You have not entered an email address for this contact.')

										/* 
												There are many fields that are normally required, but for super users just show a warning if there's no value.
										*/
										if (appState.currentUser?.bSuperUser) {
											if (!formikProps.values.contactTypeId) superUserwarnings.push('Please choose a contact type.')
											if (!formikProps.values.contactSubTypeId) superUserwarnings.push('Please choose a title.')
											if (!formikProps.values.businessPhone && formikProps.values.contactTypeId !== MinistryContactType.Board) superUserwarnings.push('Please enter a business phone.')
										} 

										if (
											formikProps.values.permissionId_Profile.toString() === MinistryContactAccessLevel["No Access"].toString() &&
											formikProps.values.permissionId_Contacts.toString() === MinistryContactAccessLevel["No Access"].toString() &&
											formikProps.values.permissionId_Training.toString() === MinistryContactAccessLevel["No Access"].toString() &&
											formikProps.values.permissionId_Grants.toString() === MinistryContactAccessLevel["No Access"].toString() &&
											formikProps.values.permissionId_Resources.toString() === MinistryContactAccessLevel["No Access"].toString()
										) {
											warnings.push("All the security permissions are set to 'no access'. This user will not be able to log in to the partner site.")
										}

										setBeforeSaveWarningMessages(warnings)
										setBeforeSaveWarningMessages_SuperUser(superUserwarnings)

										const errors = await formikProps.validateForm()
										if (Object.values(errors).length > 0) {
											alert('One or more fields are invalid. Error was: ' + JSON.stringify(errors))
										} else if (warnings.length > 0) {
											showModal(beforeSaveWarningModalId)
										} else {
											formikProps.submitForm()
										}
									}}>
										Save
									</button>) 
								: null }
							<div className='container'>
								<div className='row'>
									<div className='col d-flex flex-column mb-3'>
										<SelectField
											fieldProps={{ name: 'contactTypeId', label: 'Contact Type', placeholder: 'Select contact type' }}
											// @ts-ignore
											options={Object.keys(MinistryContactType).filter(key => isNaN(key)).map(key => ({ value: MinistryContactType[key], label: key }))}
										/>

										<SelectField
											disabled={!formikProps.values.contactTypeId}
											fieldProps={{ name: 'contactSubTypeId', label: 'Title', placeholder: 'Select a title' }}
											// Dynamically show different titles based on which Contact Type has been selected
											options={getContactTitleOptions([parseInt(formikProps.values.contactTypeId.toString())])}
										/>

										<div className='d-flex'>
											<div style={{flex: 1}} className='mr-2'><SelectField
												fieldProps={{ name: 'salutationId', label: 'Salutation' }}
												// @ts-ignore
												options={Object.keys(MinistryContactSalutation).filter(key => isNaN(key)).map(key => ({ value: MinistryContactSalutation[key], label: key }))}
												/>
											</div>
											<div style={{flex: 2}} className='mr-2'><TextField fieldProps={{ name: 'firstName', label: 'First Name', placeholder: '' }} /></div>
											<div style={{flex: 2}}><TextField fieldProps={{ name: 'lastName', label: 'Last Name', placeholder: '' }} /></div>
										</div>

										<EmailField fieldProps={{ name: 'email', label: 'Email' }} disabled={Boolean(ministryContact?.subjectId)} />
										{ministryContact?.subjectId ?
											<a type='button' className='btn btn-primary w-50 mb-3' href={`${ministryContact?.requestEmailChangeUrl}`} target='_blank'>Request change of email</a>
											:
											null
										}
										{ loginStatus !== LoginStatus.CanLogin && loginStatus !== LoginStatus.CreateContact ?
										<div className='alert alert-warning'>
											This contact is unable to login. Reason:&nbsp;
											{ loginStatus == LoginStatus.NoEmail ? 'Missing email address.' : null }
											{ loginStatus == LoginStatus.HasEmail ? 'Contact has not accepted their SSO invitation.' : null }
											{ loginStatus == LoginStatus.NoPermission ? 'Contact does not have permission to access the site. All permissions are set to "No Access".' : null }
										</div>
										: null }

										<TextField fieldProps={{ name: 'cellPhone', label: 'Cell Phone' }} />

										<div className='d-flex'>
											<div style={{flex: 2}} className='mr-2'><TextField fieldProps={{ name: 'businessPhone', label: 'Business Phone' }} /></div>
											<div style={{flex: 1}}><NumberField fieldProps={{ name: 'businessPhoneExt', label: 'Extension' }} noFormat={true} /></div>
										</div>

										{/* Hire Month Fields (visible only to Staff or Board contact types) */}
										{/* eslint-disable-next-line */}
										{formikProps.values.contactTypeId == MinistryContactType.Board || formikProps.values.contactTypeId == MinistryContactType.Staff || contactSubTypeRequiresHireDateFields(parseInt(formikProps.values.contactSubTypeId.toString())) ?
											<div className='d-flex animated fadeIn'>
												<div style={{flex: 1}} className='mr-2'><SelectField
													fieldProps={{ name: 'hireMonth', label: 'Month Hired into Current Position', placeholder: 'Select one' }}
													options={[
														{ value: '01', label: 'January' },
														{ value: '02', label: 'February' },
														{ value: '03', label: 'March' },
														{ value: '04', label: 'April' },
														{ value: '05', label: 'May' },
														{ value: '06', label: 'June' },
														{ value: '07', label: 'July' },
														{ value: '08', label: 'August' },
														{ value: '09', label: 'September' },
														{ value: '10', label: 'October' },
														{ value: '11', label: 'November' },
														{ value: '12', label: 'December' },
													]}
												/></div>
												<div style={{flex: 1}}><SelectField fieldProps={{ name: 'hireYear', label: 'Year Hired into Current Position', placeholder: 'Select one' }} options={getYearOptions(100)} /></div>
											</div>
											:
											null
										}

										{/* eslint-disable-next-line */}
										{formikProps.values.contactTypeId == MinistryContactType.Board ?
											<div className='animated fadeIn'>
												<DateStringField fieldProps={{ name: 'termStarted', label: 'Term Started (mm/dd/yyyy)' }} />
												<TextField fieldProps={{ name: 'vocation', label: 'Vocation' }} />
											</div>
											:
											null
										}

										{/* eslint-disable-next-line */}
										{formikProps.values.contactTypeId == MinistryContactType.Board || formikProps.values.contactTypeId == MinistryContactType.Staff || formikProps.values.contactTypeId == MinistryContactType['Volunteer/Champion'] ?
											<div className='animated fadeIn mb-3'>
												<h5 className='text-muted'>Security</h5>
												<div className='border border-light bg-light mb-3 mt-1 p-3'>
													<small>Admin - full ability to add, edit, and delete information on your Ministry Account.</small>
													<br />
													<small>Modify - ability to make changes to most information and add people to training.</small>
													<br />
													<small>View - ability to read information only. No ability to make changes to information or add people to training.</small>
													<br />
													<small>No Access - the section will not be visible to the individual. All permissions are blocked.</small>
												</div>

												{disablePermissions ?
													<div className='border border-light bg-light mb-3 mt-1 p-3'>
														<small>This account was created by an admin using the default permissions. When an admin creates an account and leaves all permissions at the default setting, the permissions cannot be edited except by a super admin.</small>
													</div>
													:
													null
												}

												<SelectField disabled={!!disablePermissions} fieldProps={{ name: 'permissionId_Profile', label: 'Ministry Profile' }} options={getMinistryAccessLevelOptions()} />
												<SelectField disabled={!!disablePermissions} fieldProps={{ name: 'permissionId_Contacts', label: 'Ministry Contacts' }} options={getMinistryAccessLevelOptions()} />
												<SelectField disabled={!!disablePermissions} fieldProps={{ name: 'permissionId_Training', label: 'Training' }} options={getMinistryAccessLevelOptions()} />
												<SelectField disabled={!!disablePermissions} fieldProps={{ name: 'permissionId_Grants', label: 'Ministry Grants' }} options={getMinistryAccessLevelOptions()} />
												<SelectField disabled={!!disablePermissions} fieldProps={{ name: 'permissionId_Resources', label: 'Ministry Resources*' }} options={getMinistryAccessLevelOptions()} />
												<small className='text-muted'>* Includes Reporting, Forums, and Training Videos</small>
											</div>
											:
											null
										}

										<h5 className='text-muted'>Optional Fields</h5>

										<TextField fieldProps={{ name: 'homePhone', label: 'Home Phone' }} />
										{/* <FormikSelectField 
											field={{ name: 'countryId', label: 'Country', placeholder: 'Select a country' }} 
											options={countries.map(country => ({ label: `${country.country}`, value: `${country.countryId}` }))} /> */}
										<FormikCountrySelectField field={{ name: 'countryId', label: 'Country', placeholder: 'Select a country' }} />
										<TextField fieldProps={{ name: 'address1', label: 'Address'}} disabled={!formikProps.values.countryId} />
										<TextField fieldProps={{ name: 'address2', label: 'Address (line 2)' }} disabled={!formikProps.values.countryId} />
										<TextField fieldProps={{ name: 'city', label: 'City' }} disabled={!formikProps.values.countryId} />
										{(formikProps.values.countryId == UNITED_STATES_COUNTRY_ID || formikProps.values.countryId == CANADA_COUNTRY_ID) ? 
											<FormikStateSelectField 
											field={{ name: 'stateId', label: 'State', placeholder: 'Select a state', disabled: !formikProps.values.countryId }}
											countryId={formikProps.values.countryId} />
										:
										<TextField 
												fieldProps={{ name: 'stateOther', label: 'State/Province' }}  
												disabled={!formikProps.values.countryId} />
										// <SelectField 
										// 	fieldProps={{ name: 'stateId', label: 'State', placeholder: 'Select a state' }} 
										// 	options={states.filter(state => state.countryId == formikProps.values.countryId).map(state => ({ label: `${state.state}`, value: `${state.stateId}` }))}
										// 	disabled={!formikProps.values.countryId} />
										}
										<TextField fieldProps={{ name: 'postalCode', label: 'Postal Code' }} disabled={!formikProps.values.countryId} />
										<FormikSelectField 
											multiple={true} 
											field={{ name: 'blacklistMailTypeIds', label: 'Unsubscribe From' }}
											options={MAIL_TYPE_OPTIONS} />

										{!ministryContact || !ministryContact.isPrimary ? <CheckboxField fieldProps={{ name: 'isPrimary', label: 'Primary Contact' }} /> : null}

										<h5 className='text-muted mt-3'>Notes</h5>
										<Editor
											init={{
												height: 250,
												plugins: [
													'advlist autolink lists link image charmap print preview anchor',
													'searchreplace visualblocks code fullscreen',
													'insertdatetime media table paste code help wordcount'
												],
												toolbar: 'fullscreen | undo redo | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | formatselect  | removeformat | help',
												block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6',
											}}
											onEditorChange={(content: string) => formikProps.setFieldValue('gtoNotes', content)}
											value={formikProps.values.gtoNotes}
										/>

										<div className='d-flex mt-3'>
											<button type='button' className='btn btn-primary mr-2 min-width-md' onClick={async () => {
												const warnings: string[] = []
												const superUserwarnings: string[] = []

												if (!formikProps.values.email) warnings.push('An email address is required to attend events and to log into the Ministry Portal. You have not entered an email address for this contact.')

												/* 
														There are many fields that are normally required, but for super users just show a warning if there's no value.
												*/
												if (appState.currentUser?.bSuperUser) {
													if (!formikProps.values.contactTypeId) superUserwarnings.push('Please choose a contact type.')
													if (!formikProps.values.contactSubTypeId) superUserwarnings.push('Please choose a title.')
													if (!formikProps.values.businessPhone && formikProps.values.contactTypeId !== MinistryContactType.Board) superUserwarnings.push('Please enter a business phone.')
												} 

												if (
													formikProps.values.permissionId_Profile.toString() === MinistryContactAccessLevel["No Access"].toString() &&
													formikProps.values.permissionId_Contacts.toString() === MinistryContactAccessLevel["No Access"].toString() &&
													formikProps.values.permissionId_Training.toString() === MinistryContactAccessLevel["No Access"].toString() &&
													formikProps.values.permissionId_Grants.toString() === MinistryContactAccessLevel["No Access"].toString() &&
													formikProps.values.permissionId_Resources.toString() === MinistryContactAccessLevel["No Access"].toString()
												) {
													warnings.push("All the security permissions are set to 'no access'. This user will not be able to log in to the partner site.")
												}

												setBeforeSaveWarningMessages(warnings)
												setBeforeSaveWarningMessages_SuperUser(superUserwarnings)

												const errors = await formikProps.validateForm()
												if (Object.values(errors).length > 0) {
													alert('One or more fields are invalid. Error was: ' + JSON.stringify(errors))
												} else if (warnings.length > 0) {
													showModal(beforeSaveWarningModalId)
												} else {
													formikProps.submitForm()
												}
											}}>Save</button>
										</div>
									</div>
								</div>
							</div>
						</Form>

						<Modal
							modalId={primaryContactWarningModalId}
							modalTitle='Confirm'
							footer={
								<React.Fragment>
									<button type='button' className='btn btn-secondary' data-dismiss='modal' onClick={() => {
										// The user does NOT want to make this contact the primary contact
										formikProps.setFieldValue('isPrimary', false)
									}}>Cancel</button>
									<button type='button' className='btn btn-primary' data-dismiss='modal' onClick={async () => {
										// Since this is being marked as the primary contact, give it full admin permissions for every permission type
										formikProps.setValues(
											{
												...formikProps.values,
												permissionId_Profile: MinistryContactAccessLevel.Admin,
												permissionId_Contacts: MinistryContactAccessLevel.Admin,
												permissionId_Training: MinistryContactAccessLevel.Admin,
												permissionId_Resources: MinistryContactAccessLevel.Admin,
												permissionId_Grants: MinistryContactAccessLevel.Admin,
											}
										)
									}}>OK</button>
								</React.Fragment>
							}
						>
							Only one contact can be your primary. If someone else is currently flagged as your primary contact, this contact will replace them as your primary contact.
                            <br /> <br />
                            Are you sure you want to make this the primary contact? They will be given full Admin privileges.
                        </Modal>

						<Modal
							modalId={allPermissionsNoAccessWarningModalId}
							modalTitle='Permissions'
							footer={<button type='button' className='btn btn-secondary' data-dismiss='modal'>OK</button>}
						>
							By setting all permissions to 'no access', this user will not be able to log in to the partner site.
                        </Modal>

						<Modal
							modalId={duplicateEmailModalId}
							modalTitle='Duplicate Email'
							footer={<button type='button' className='btn btn-secondary' data-dismiss='modal'>OK</button>}
						>
							This email is already in use. Please try again.
                        </Modal>

						<Modal
							modalId={beforeSaveWarningModalId}
							modalTitle='Warning'
							footer={
								<React.Fragment>
									<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
									<button type='button' className='btn btn-warning' data-dismiss='modal' onClick={formikProps.submitForm}>Continue</button>
								</React.Fragment>
							}
						>
							{beforeSaveWarningMessages_SuperUser && beforeSaveWarningMessages_SuperUser?.length ?
								'Since you are a super user you can still continue, but the following should be corrected:'
								:
								null
							}

							<ul className='my-2'>
								{beforeSaveWarningMessages_SuperUser?.map(message => <li key={message}>{message}</li>)}
							</ul>

							{beforeSaveWarningMessages && beforeSaveWarningMessages.length ?
								'The following are warnings, and the contact will still be saved. Do you wish to continue?'
								:
								null
							}

							<ul className='my-2'>
								{beforeSaveWarningMessages?.map(message => <li key={message}>{message}</li>)}
							</ul>

							{/* {beforeSaveWarningMessages?.map((message, index, array) => <div key={message}>{message} {array.length > 1 && index !== array.length - 1 ? <React.Fragment><br /><br /></React.Fragment> : null}</div>)} */}

							{(beforeSaveWarningMessages && beforeSaveWarningMessages?.length) || (beforeSaveWarningMessages_SuperUser && beforeSaveWarningMessages_SuperUser?.length) ?
								'Do you still want to save this contact?'
								:
								null
							}
						</Modal>
					</React.Fragment>
				)
			}}
		</Formik>
	)
}