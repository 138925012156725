import { createRef, useCallback, useContext, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IMiBranchModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import FullCalendar, { EventSourceFunc } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import dayListPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Loading } from "./loading"
import { Modal } from "./modal"
import * as Fathom from 'fathom-client';
import { IS_PRODUCTION } from '../constants'

interface ICardCalendar {
    activeCommunity: IMiBranchModelDocument | undefined,
    style: object | undefined
}

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const CardCalendar = (props: ICardCalendar) => {
    const { activeCommunity, style } = props
    const { DashboardApi } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    //const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([])
    const [loading, setLoading] = useState(false)
    const [calendarModal, showHideCalendarModal] = useModal()

    const calendarRef = createRef<FullCalendar>()

    const fetchEvents = useCallback(async (start: Date, end: Date) => {
        //setLoading(true)
        const {data} = await makeHttpRequestWithUi({
            request: DashboardApi.apiDashboardCalendarGet(start.toDateString(), end.toDateString(), activeCommunity?.branchId || undefined),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch calendar.',
            disableLoading: true,
        })

        const fullCalendarEvents = data?.map(o => ({
                    start: o.isAllDay ? o.endDateTime : o.startDateTime,
                    end: o.isAllDay ? o.endDateTime : o.endDateTime,
                    title: o.subject ?? "Uknown Title",
                    backgroundColor: o.eventId ? '' : '#198754',
                    color: o.eventId ? '' : '#198754',
                    id: o.eventId?.toString() || o.consultingHourId?.toString(),
                    eventId: o.eventId,
                    allDay: o.isAllDay,
                    url: ((o.eventTypeId === 2 || o.eventTypeId === 3 || o.eventTypeId === 5) ? `event-management#view=${o.eventId}` 
                            : o.eventTypeId === 6 ? `consulting#view=${o.consultingEventGroupId}` 
                            : o.eventTypeId === 7 ? `group-learning#view=${o.eventContentId}` 
                            : ''),
                }))

        return fullCalendarEvents

        //setLoading(false)
    }, [activeCommunity])

    const eventFunc: EventSourceFunc = useCallback((arg: {
        start: Date;
        end: Date;
        startStr: string;
        endStr: string;
        timeZone: string;
    }, successCallback) => {
        const activeBranchId = 
        fetchEvents(arg.start, arg.end).then(x =>
            successCallback(x))
    }, [fetchEvents])

    return (
        <>
            <div className="card" style={{ ...style, minHeight: 0 }}>
                <div className="card-header">
                    <span>Calendar</span>
                    <div style={{float: 'right'}}>(Time Zone: {timeZone})</div>
                </div>
                { 
                    loading ? 
                    <Loading />
                    :
                    <div style={{marginTop: '8px'}}>
                        <FullCalendar
                        plugins={[dayGridPlugin, dayListPlugin, bootstrapPlugin, timeGridPlugin]}
                        initialView='listWeek'
                        headerToolbar={{
                            start: 'prev,next today',
                            center: 'title',
                            end: 'showFullCalendar'
                        }}
                        themeSystem='bootstrap'
                        events={eventFunc}
                        allDaySlot={true}
                        customButtons={{
                            "showFullCalendar": {
                                text: 'Full Calendar',
                                click: function() {
                                    IS_PRODUCTION && Fathom.trackEvent('showFullCalendar')
                                    showHideCalendarModal(true)
                                }
                            }
                        }}
                        noEventsText=""
                    />
                    </div>
                }
            </div>

            <Modal
                {...calendarModal}
                modalTitle='Full Dashboard Calendar'
                size='fullscreen'
                dismissible={true}
                onModalHide={() => {
					showHideCalendarModal(false)
				}}
                onModalShown={() => {
                    // Must call update size after shown or calendar doesn't look right.
                    calendarRef.current?.getApi().updateSize()
                }}
            >
                {calendarModal.show && 
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ flex: 1}}>
                            <FullCalendar
                                ref={calendarRef}
                                plugins={[dayGridPlugin, dayListPlugin, bootstrapPlugin, timeGridPlugin]}
                                initialView='timeGridWeek'
                                headerToolbar={{
                                    start: 'prev,next today',
                                    center: 'title',
                                    end: 'listWeek,dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                                themeSystem='bootstrap'
                                events={eventFunc}
                                height='100%'
                                allDaySlot={true}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </>
    )
}