import React, { useMemo } from 'react'
import { IMiBranchModelDocument } from '../open-api'
import { ISelectFieldOption, SimpleSelectField } from './forms'
import { isSelectFieldOption } from './forms/formik-select-field'

interface ICommunitySelectorProps {
	communities: IMiBranchModelDocument[]
	onCommunitySelected: (community: IMiBranchModelDocument | null) => void
	selectedCommunity?: IMiBranchModelDocument | null
	style?: React.CSSProperties
	disabled?: boolean
}
export const CommunitySelector = (props: ICommunitySelectorProps) => {
	const { communities, onCommunitySelected, selectedCommunity, style, disabled } = props

	const communityOptions = useMemo<ISelectFieldOption[]>(() => {
		return [{ value: '', label: 'All Communities' }, ...communities.map(o => ({ label: `${o.branchAbbr} (${o.branchName})`, value: `${o.branchId}` }))]
	}, [communities])

	return (
		<div className='dropdown' style={style}>
			<div style={{ width: '100%' }}>
				<SimpleSelectField
					fieldProps={{ name: 'global-community-context-selector', label: '', placeholder: 'All Communites' }}
					options={communityOptions}
					onChange={option => {
						if (isSelectFieldOption(option)) {
							const community = communities.find(o => o.branchId.toString() === option.value) || null
							onCommunitySelected(community)
						}
					}}
					value={selectedCommunity?.branchId.toString()}
				/>
			</div>


			{/* <button disabled={disabled} data-display="static" className='btn btn-sm btn-secondary dropdown-toggle' type='button' id={id} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
				{selectedCommunity ? `${selectedCommunity.branchAbbr} (${selectedCommunity.branchName})` : 'All Communities'}
			</button>
			<div className='dropdown-menu' aria-labelledby={id} style={{ maxHeight: '200px', overflowY: 'auto' }}>
				<button className='dropdown-item' onClick={() => onCommunitySelected(null)}>All Communities</button>
				{communities.map(community => (
					<button key={community.branchId} className='dropdown-item' onClick={() => onCommunitySelected(community)}>{community.branchAbbr} ({community.branchName})</button>
				))}
			</div> */}
		</div>
	)
}