import React from 'react'

interface IMainNavItemCell { 
    active?: boolean
    icon: JSX.Element
    title: string | JSX.Element
    onClick: () => void
    disabled?: boolean
}

export const MainNavItemCell = (props: IMainNavItemCell) => {
    const {active, icon, title, onClick, disabled } = props

    return (
        <div className={`nav-row ${disabled ? 'nav-row-disabled' : 'nav-row-link'} p-2 ${active ? 'active' : ''}`} onClick={onClick}>
            <div className='nav-icon'>{icon}</div> {title}
        </div>
    )
}

