import { useEffect } from "react";
import { IOrderLineItemSummaryDocument } from "../open-api";
import { filterGridListItems, formatCurrency, sortListBySorts } from "../services/helpers";
import { defaultGridState, useGrid } from "../stores/grid-actions";
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions";
import { gridReducer } from "../stores/grid-reducer";
import { IDefaultProps } from "./component-definitions";
import { Grid } from "./grid";
import { DefaultGridCellDisplay } from "./grid-cell-displays";
import { Loading } from "./loading";

interface IOrderLineItemsProps extends IDefaultProps {
    lineItems: IOrderLineItemSummaryDocument[]
}

export const OrderLineItems = ({lineItems}: IOrderLineItemsProps) => {

    const dataSource: GridDataFetch<typeof lineItems> = async (_queryState, _lineItems) => {
        try {
            if (!_lineItems) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_lineItems, _queryState.sorts)
            let rows: IGridListItem[] = _lineItems.map(o => ({
                id: o.orderId!.toString(),
                values: {
                    ...o
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('OrderLineItems > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        columns: [
            {
                property: 'productTitle',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Product',
                render: DefaultGridCellDisplay
            },
            {
                property: 'qty',
                type: 'number',
                width: 50,
                allowFilters: true,
                title: 'Qty',
                render: DefaultGridCellDisplay
            },
            {
                property: 'unitAmount',
                type: 'number',
                width: 50,
                allowFilters: true,
                title: 'Unit Amount',
                render: (_, row) => row.values.unitAmount && formatCurrency((parseInt(row.values.unitAmount.toString()) / 100))
            },
        ],
        dataSource,
        rowSelectEnabled: false,
        disableExport: true,
        usingLocalData: true,
        disabledPagination: true,
        respectGlobalCommunityFilter: false,
        hideGridFooter: true
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, lineItems)

    useEffect(() => {
        console.log('doFetch', lineItems)
        gridActions.doFetch()
    }, [lineItems])

    if (!lineItems) return <Loading />

    return (
        <Grid state={gridState} actions={gridActions} />
    )
}