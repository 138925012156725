import React, { useEffect } from 'react';
import BaseLayout from './base-layout'
import { IDefaultProps } from './component-definitions'
import { useAuth } from "react-oidc-context"
import { navigate } from '@reach/router'
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'


export const LoginErrorPage = (props: IDefaultProps) => {
    const params = new URLSearchParams(window.location.search);
    const message = params.get('message') || 'An unknown error occurred';
    const auth = useAuth();

    const handleLogout = async () => {        
        auth.signoutRedirect({
            post_logout_redirect_uri:IDP_REDIRECT_URI
        });  
    }

    return (
        <BaseLayout className='center'>
        <div className='small column bottom-gutters'>
            <h1>Login Error</h1>
            <p>You are unable to login to Growtrack with this user.
                <br />"{message}"
                <br />Please contact <a href="mailto:support@missionincrease.org">support</a> or 
                login with a different user.
            </p>
            <button className='btn btn-primary' onClick={() => handleLogout()}>Log in as different user</button>
        </div>

        </BaseLayout>
        
    );
};

export default LoginErrorPage;