import React from 'react'
import { EventNewsRecipientModel } from '../models/event-news-recipient'
import { IRecipientSearchResultDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { DefaultGridCellDisplay, MinistryNameMinistryIdDisplay } from './grid-cell-displays'

interface IEventNewsRecipientsProps {
    recipients: IRecipientSearchResultDocument[]
}
export const EventNewsRecipients = (props: IEventNewsRecipientsProps) => {
    const { recipients } = props

    const dataSource: GridDataFetch<IRecipientSearchResultDocument[]> = async (queryState, _recipients) => {
        const _recipientsCopy = [..._recipients]

        if (queryState.sorts) sortListBySorts(_recipientsCopy, queryState.sorts)
        let rows = _recipientsCopy.map(EventNewsRecipientModel.toGridListItem)
        if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

        return {
            count: _recipientsCopy.length,
            rows,
        }
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        columns: [
            {
                property: 'ministryName',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Ministry Name',
                render: MinistryNameMinistryIdDisplay,
            },
            {
                property: 'firstName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'First Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'lastName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Last Name',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'email',
                type: 'string',
                width: 120,
                allowFilters: true,
                title: 'Email',
                render: DefaultGridCellDisplay,
            },
            // 2024702 TB - The values in this column are not always accurate, so commenting out for now.
            // Example: MTN would send out 1k+ emails, but the status would show as "Failed - May be unsubscribed".
            // In reality, the emails were sent successfully, but the related webhook would get overwhelmed and fail.
            // {
            //     property: 'status',
            //     type: 'string',
            //     width: 75,
            //     allowFilters: true,
            //     title: 'Status',
            //     render: DefaultGridCellDisplay,
            //     align: 'center'
            // },
        ],
        dataSource,
        disabledPagination: true,
        rowSelectEnabled: false,
        usingLocalData: true,
    }

    const [state, actions] = useGrid(gridReducer, initialGridState, recipients)

    return <Grid actions={actions} state={state} style={{ flex: 1, height: 'unset' }} />
}