import { useContext, useEffect, useState } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { IInvolvementCalcDetailsDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'

interface IMinistryInvolvementScoreCalcProps {
    ministryId: number,
    ministryName: string | null
}

export const MinistryInvolvementScoreCalc = (props: IMinistryInvolvementScoreCalcProps) => {
    const { ministryId, ministryName } = props
    const { involvementMultipliers } = useContext(AppStateContext)

    const {InvolvementMultipliersApi} = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const [calcDetails, setCalcDetails] = useState<IInvolvementCalcDetailsDocument>()
    const [calcTotal, setCalcTotal] = useState<number>(0)

    const fetchCalcDetails = async () => {
        const callListsQuery = await makeHttpRequestWithUi({
            request: InvolvementMultipliersApi.ministriesMinistryIdInvolvementCalcDetailsGet(ministryId),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch details.'
        })

        setCalcDetails(callListsQuery.data)
    }

    useEffect(() => {
        fetchCalcDetails()
    }, [ministryId])

    useEffect(() => {
        console.log('calcDetails', calcDetails)
        if (calcDetails) {
            setCalcTotal(calcDetails.consultingScore + 
                calcDetails.grantsPost2007Score +
                calcDetails.grantsPre2007Score +
                calcDetails.groupLearningScore +
                calcDetails.seminarScore + 
                calcDetails.smallGroupScore + 
                calcDetails.workshopScore + 
                calcDetails.maConsultingScore +
                calcDetails.webinarScore)
        }
    }, [calcDetails])

    return (
        <>
            <p>Here is Involvement Score breakdown for {ministryName}.</p>
            <p>Note: The <strong>Multiplier</strong> values are the same for all ministries.</p>
            <table style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th>Activity</th>
                        <th style={{textAlign: 'right'}}>Count</th>
                        <th style={{textAlign: 'right'}}>Multiplier</th>
                        <th style={{textAlign: 'right'}}>Score</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Seminars Attended</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.seminarCount}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.seminarMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.seminarScore}</td>
                </tr>
                <tr>
                    <td>Workshops Attended</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.workshopCount}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.workshopMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.workshopScore}</td>
                </tr>
                <tr>
                    <td>Webinars Attended</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.webinarCount}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.webinarMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.webinarScore}</td>
                </tr>
                <tr>
                    <td>Small Group Sessions Attended</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.smallGroupCount}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.smallGroupMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.smallGroupScore}</td>
                </tr>
                <tr>
                    <td>Grant Count Post 2007</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.grantPost2007Count}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.grantPost2007Multiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.grantsPost2007Score}</td>
                </tr>
                <tr>
                    <td>Grant Count Pre 2007</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.grantPre2007Count}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.grantPre2007Multiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.grantsPre2007Score}</td>
                </tr>
                <tr>
                    <td>Coaching/Consulting Hours</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.consultingHours}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.consultingMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.consultingScore}</td>
                </tr>
                <tr>
                    <td>Group Learning Hours</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.groupLearningHours}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.groupLearningMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.groupLearningScore}</td>
                </tr>
                <tr>
                    <td>Mission Accelerate Consulting Hours</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.maConsultingHours}</td>
                    <td style={{textAlign: 'right'}}>{involvementMultipliers.maConsultingMultiplier}</td>
                    <td style={{textAlign: 'right'}}>{calcDetails?.maConsultingScore}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td style={{textAlign: 'right'}}><strong>Total</strong></td>
                    <td style={{textAlign: 'right'}}>{calcTotal}</td>
                </tr>
                </tbody>
            </table>
        </>
    )
}


