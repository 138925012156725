import { Formik, Form } from 'formik'
import React, { useContext } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IMinistryDataFiscalYearSummaryDocument } from '../open-api'
import { objectToOperationDocument } from '../services/helpers'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { CurrencyField } from './forms'

interface IMinistryInfoFyDataIncomeSourcesForm {
    ministryInfoFyDataGeneral: IMinistryDataFiscalYearSummaryDocument
    afterSave?: () => void
}

export const MinistryInfoFyDataIncomeSourcesForm = (props: IMinistryInfoFyDataIncomeSourcesForm) => {
    const { ministryInfoFyDataGeneral, afterSave } = props

    const appActions = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    return (
        <Formik
            initialValues={ministryInfoFyDataGeneral}
            onSubmit={async (values) => {

                const patches = {
                    amtChurchSponsor: values.amtChurchSponsor !== null ? values.amtChurchSponsor.toString().match(/\d/g)?.join('') : null,
                    amtFeeService: values.amtFeeService !== null ? values.amtFeeService.toString().match(/\d/g)?.join('') : null,
                    amtGrantWrite: values.amtGrantWrite !== null ? values.amtGrantWrite.toString().match(/\d/g)?.join('') : null,
                    amtMajorDonor: values.amtMajorDonor !== null ? values.amtMajorDonor.toString().match(/\d/g)?.join('') : null,
                    amtOnlineGiving: values.amtOnlineGiving !== null ? values.amtOnlineGiving.toString().match(/\d/g)?.join('') : null,
                    amtDirectMail: values.amtDirectMail !== null ? values.amtDirectMail.toString().match(/\d/g)?.join('') : null,
                    amtSpecialEvent: values.amtSpecialEvent !== null ? values.amtSpecialEvent.toString().match(/\d/g)?.join('') : null,
                    amtGik: values.amtGik !== null ? values.amtGik.toString().match(/\d/g)?.join('') : null,
                    amtOther: values.amtOther !== null ? values.amtOther.toString().match(/\d/g)?.join('') : null,
                }

                await makeHttpRequestWithUi({
                    request: appActions.MinistryDataApi.apiMinistryDataIdPatch(ministryInfoFyDataGeneral.ministryDataId, 'testReason', objectToOperationDocument(patches)),
                    toastSuccessMessage: `Successfully updated income source data for ${ministryInfoFyDataGeneral.fiscalYear}.`,
                    toastErrorMessage: `Encountered an error updating income source data for ${ministryInfoFyDataGeneral.fiscalYear}.`,
                })
                if (afterSave) afterSave()
            }}
        >
            {formikProps => (
                <Form>
                    <div className='d-flex'>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtChurchSponsor', label: 'Church Sponsor' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtFeeService', label: 'Fee Service' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtGrantWrite', label: 'Grant Write' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtMajorDonor', label: 'Major Donor' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtOnlineGiving', label: 'Online Giving' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtDirectMail', label: 'Direct Mail' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtSpecialEvent', label: 'Special Event' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtGik', label: 'Gifts in Kind' }} /></div>
                        <div className='mr-2'><CurrencyField fieldProps={{ name: 'amtOther', label: 'Other' }} /></div>
                    </div>

                    <button type='submit' className='btn btn-primary'>Submit</button>
                </Form>
            )}
        </Formik>
    )
}

