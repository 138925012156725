import { Form, Formik, FormikProps } from 'formik'
import moment from 'moment'
import { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { EventType, eventTypeOptions } from '../models/event-site'
import { IEventSiteModelDocument, IEventSitePostModelDocument, IMiTrainingAreaSummaryModelDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { CheckboxField, CommunitySelectField, NumberField, SelectField, TextField } from './forms'
import { FormikTextAreaField } from './forms/formik-text-area-field'
// import { GoogleMap } from './google-map'
import { FormikStateSelectField } from './forms/formik-state-select-field'
import { CANADA_COUNTRY_ID, UNITED_STATES_COUNTRY_ID } from '../constants'
import { FormikCountrySelectField } from './forms/formik-country-select-field'

interface IEventSiteFormProps {
	trainingArea: IMiTrainingAreaSummaryModelDocument
	eventSiteToEdit?: IEventSiteModelDocument
	onSaveSucess?: () => void
}
export const EventSiteForm = (props: IEventSiteFormProps) => {
	const { trainingArea, eventSiteToEdit, onSaveSucess } = props
	const { trainingAreaId, branchId, bVirtual } = trainingArea

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	// TB 20230713 - Unsure if this was having any positive/negative effect on the page, so commenting it out for now.
	// Let's delete this section after sufficient time goes by.
	// useEffect(() => {
	// 	if (_formikProps) _formikProps.resetForm()
	// 	// eslint-disable-next-line
	// }, [eventSiteToEdit])

	useEffect(() => {
		if (!appState.activeTrainingAreas) appActions.fetchTrainingAreas()
	}, [])

	let _formikProps: FormikProps<any>

	const initialValues = {
		trainingAreaId,
		branchId,
		locationName: eventSiteToEdit?.locationName || '',
		locationContact: eventSiteToEdit?.locationContact || '',
		locationPhone: eventSiteToEdit?.locationPhone || '',
		locationCountryId: eventSiteToEdit?.locationCountryId?.toString() || appState.activeBranches.find(o => o.branchId === branchId)?.countryId?.toString() || UNITED_STATES_COUNTRY_ID.toString(),
		locationAddress: eventSiteToEdit?.locationAddress || '',
		locationAddress2: eventSiteToEdit?.locationAddress2 || '',
		locationCity: eventSiteToEdit?.locationCity || '',
		locationStateId: eventSiteToEdit?.locationStateId?.toString() || appState.activeBranches.find(o => o.branchId === branchId)?.stateId?.toString() || '',
		locationStateOther: eventSiteToEdit?.locationStateOther || '',
		locationZip: eventSiteToEdit?.locationZip || '',
		locationDetails: eventSiteToEdit?.locationDetails || '',
		maxSeats: eventSiteToEdit?.maxSeats?.toString() || '',
		startTime: eventSiteToEdit?.startTime ? moment(eventSiteToEdit.startTime).format('h:mm A') : '',
		endTime: eventSiteToEdit?.endTime ? moment(eventSiteToEdit.endTime).format('h:mm A') : '',
		bDefaultSite: eventSiteToEdit ? eventSiteToEdit.bDefaultSite : '',
		eventTypes: eventSiteToEdit?.eventTypes?.map(o => o.toString()) || eventTypeOptions.map(o => o.value),
	}

	const validationSchema = Yup.object({
		locationName: Yup.string().required('Required'),
		locationCountryId: Yup.string().required('Required'),
		locationAddress: bVirtual ? Yup.string() : Yup.string().required('Required'),
		locationCity: bVirtual ? Yup.string() : Yup.string().required('Required'),
		locationZip: bVirtual ? Yup.string() : Yup.string().required('Required'),
		maxSeats: Yup.string().required('Required'),
		// startTime: Yup.string()
		// 	.test(
		// 		'startTime',
		// 		'Start time must be before end time.',
		// 		function (value) {
		// 			if (typeof this.parent.endTime === 'string' && this.parent.endTime.length) {
		// 				return moment(value, 'h:mm A').isBefore(moment(this.parent.endTime, 'h:mm A'))
		// 			} else {
		// 				return true
		// 			}
		// 		}
		// 	),
		// endTime: Yup.string()
		// 	.test(
		// 		'endTime',
		// 		'End time must be after start time.',
		// 		function (value) {
		// 			if (typeof this.parent.startTime === 'string' && this.parent.startTime.length) {
		// 				return moment(value, 'h:mm A').isAfter(moment(this.parent.startTime, 'h:mm A'))
		// 			} else {
		// 				return true
		// 			}
		// 		}
		// 	)
	})

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {

				let eventSite: IEventSitePostModelDocument = {
					...values,
					trainingAreaId,
					locationCountryId: parseInt(values.locationCountryId),
					locationStateId: parseInt(values.locationStateId),
					locationStateOther: values.locationStateOther,
					maxSeats: parseInt(values.maxSeats),
					bDefaultSite: typeof values.bDefaultSite === 'boolean' ? values.bDefaultSite : null,
					eventTypes: values.eventTypes ? values.eventTypes.map(o => parseInt(o)) : null,
					bActive: true,
					mapServiceId: null,
					customDirections: null,
				}

				if (!!eventSiteToEdit) {
					await makeHttpRequestWithUi({
						request: appActions.EventSitesApi.apiEventSitesIdPut(eventSiteToEdit.siteId, { ...eventSiteToEdit, ...eventSite }),
						toastSuccessMessage: 'Successfully updated event site.',
						toastErrorMessage: 'Error updating event site.',
					})
				} else {
					await makeHttpRequestWithUi({
						request: appActions.EventSitesApi.apiEventSitesPost(eventSite),
						toastSuccessMessage: 'Successfully created event site.',
						toastErrorMessage: 'Error creating event site.'
					})
				}

				if (!!onSaveSucess) onSaveSucess()
			}}
		>
			{formikProps => {
				_formikProps = formikProps
				return (
					<Form>
						<div className='d-flex'>
							<div style={{ flex: 1 }}>
								<div className='d-flex'>
									<div className='mr-2' style={{ flex: 1 }}>
										<CommunitySelectField disabled={true} fieldProps={{ name: 'branchId', label: 'Community' }} />
									</div>
									<div style={{ flex: 1 }}>
										<SelectField disabled={true} fieldProps={{ name: 'trainingAreaId', label: 'Training Area' }} options={appState.activeTrainingAreas?.map(o => ({ label: `${o.trainingAreaAbbr}`, value: `${o.trainingAreaId}` }))} />
									</div>
								</div>

								<TextField fieldProps={{ name: 'locationName', label: 'Site Name' }} />

								<div className='d-flex'>
									<div className='mr-2' style={{ flex: 1 }}>
										<TextField fieldProps={{ name: 'locationContact', label: 'Contact' }} />
									</div>
									<div style={{ flex: 1 }}>
										<TextField fieldProps={{ name: 'locationPhone', label: 'Phone' }} />
									</div>
								</div>

								<div className='d-flex'>
									<div className='mr-2' style={{ flex: 1 }}>
										<FormikCountrySelectField field={{ name: 'locationCountryId', label: 'Country', placeholder: 'Select a country' }} />
									</div>
								</div>
								
								{bVirtual ?
									null
									:
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}>
											<TextField fieldProps={{ name: 'locationAddress', label: 'Address Line 1' }} />
										</div>
										<div style={{ flex: 1 }}>
											<TextField fieldProps={{ name: 'locationAddress2', label: 'Address Line 2' }} />
										</div>
									</div>
								}

								<div className='d-flex'>
									{bVirtual ? null :
										<div className='mr-2' style={{ flex: 1 }}>
											<TextField fieldProps={{ name: 'locationCity', label: 'City' }} />
										</div>
									}
									<div className='mr-2' style={{ flex: 1 }}>
										{/* <StateSelectField fieldProps={{ name: 'locationStateId', label: 'State' }} /> */}
										{(_formikProps.values.locationCountryId == UNITED_STATES_COUNTRY_ID || _formikProps.values.locationCountryId == CANADA_COUNTRY_ID) ? 
										<FormikStateSelectField 
											field={{ name: 'locationStateId', label: 'State', placeholder: 'Select a state', disabled: !_formikProps.values.locationCountryId }}
											countryId={_formikProps.values.locationCountryId} />
										: 
										<TextField 
											fieldProps={{ name: 'locationStateOther', label: 'State/Province' }}  
											disabled={!_formikProps.values.locationCountryId} /> }
									</div>
									{bVirtual ? null :
										<div style={{ flex: 1 }}>
											<TextField fieldProps={{ name: 'locationZip', label: 'Postal Code' }} />
										</div>
									}
								</div>

								{/* <div className='d-flex'>
									<div className='mr-2' style={{ flex: 1 }}>
										<SelectField fieldProps={{ name: 'startTime', label: 'Start Time' }} options={getTimeOptions('08:00', '22:00')} />
									</div>
									<div style={{ flex: 1 }}>
										<SelectField fieldProps={{ name: 'endTime', label: 'End Time' }} options={getTimeOptions('08:00', '22:00')} />
									</div>
								</div> */}

								<div className='d-flex'>
									<div className='mr-2' style={{ flex: 1 }}>
										<NumberField fieldProps={{ name: 'maxSeats', label: 'Max # of Seats' }} />
									</div>
									<div className='mr-2' style={{ flex: 1 }}>
										<SelectField 
											multiple={true} 
											fieldProps={{ name: 'eventTypes', label: 'Event Types', labelTooltip: 'What event types does this site support. If you are not sure, select all of them. Otherwise this site may not be available to choose from when you go to create an event.' }} 
											options={eventTypeOptions.filter(o => o.value !== EventType.Webinar.toString())} />
									</div>
									<div style={{ flex: 1 }} className='d-flex align-items-center'>
										<CheckboxField fieldProps={{ name: 'bDefaultSite', label: 'Default Site' }} />
									</div>
								</div>
								<div>
									<FormikTextAreaField field={{ name: 'locationDetails', label: 'Site Details', labelTooltip: 'Provide additional information about the site, like parking instructions.' } } />
								</div>
							</div>
							<div style={{ flex: 1 }} className='m-4'>
								{/* {formikProps.values.locationAddress && formikProps.values.locationCity && formikProps.values.locationStateId ?
									<div className='mb-4' style={{ height: 400 }}>
										<GoogleMap address1={formikProps.values.locationAddress} city={formikProps.values.locationCity} stateId={formikProps.values.locationStateId} />
									</div>
									:
									null
								} */}
							</div>
						</div>


						<button className='btn btn-primary' type='submit' style={{ minWidth: 200 }}>Save</button>
					</Form>
				)
			}}
		</Formik>
	)
}