import { useContext, useEffect, useState } from "react"
import { IGrantDenialReasonModelDocument, IGrantRequestSummaryDocument, IGrantTypeModelDocument, IMinistryGrantStipulationModelDocument } from "../open-api"
import { Tabs } from "./tabs"
import { GrantRequestFormSummary } from "./grant-request-form-summary"
import { GrantRequestFormAdInfo } from "./grant-request-form-ad-info"
import { ReactComponent as ExclamationCircleIcon } from '../assets/exclamation-circle.svg'
import { GrantRequestFormBoardInfo } from "./grant-request-form-board-info"
import { GrantRequestFormFunding } from "./grant-request-form-funding"
import { GrantRequestFormFiles } from "./grant-request-form-files"
import { BoxArrowUpRight, CheckCircleIcon, ExclamationTriangleFillIcon } from "../assets"
import { GRANT_DENIAL_REASON, GRANT_REQUEST_STATUS, GRANT_TYPE } from "../constants"
import { GrantRequestFormVerify } from "./grant-request-form-verify"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { Modal } from "./modal"
import { Form, Formik } from "formik"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import * as Yup from 'yup'
import { CheckboxField, CurrencyField, DatePickerField } from "./forms"
import { FormikSelectField } from "./forms/formik-select-field"
import { GrantRequestStipulations } from "./grant-request-stipulations"

interface IGrantRequestFormTabsProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    afterSave?: () => Promise<void>
    initialTab?: 'summary'
}

export const GrantRequestFormTabs = (props: IGrantRequestFormTabsProps) => {
    const { grantRequestToEdit, afterSave, initialTab } = props
    const { GrantGiftApi, GrantRequestApi } = useContext(AppActionContext)!
    //if (!grantToEdit) throw new Error('Must provide a grant to edit.')

    const [activeTab, setActiveTab] = useState<string>(initialTab || 'summary')
    const [grantTypes, setGrantTypes] = useState<IGrantTypeModelDocument[]>([])
    const [stipulations, setStipulations] = useState<IMinistryGrantStipulationModelDocument[]>([])
    const [denialReasons, setDenialReasons] = useState<IGrantDenialReasonModelDocument[]>([])
    const [submitForFundingModal, showHideSubmitForFundingModal] = useModal()
    const [rejectModal, showHideRejectModal] = useModal()
    const [acceptModal, showHideAcceptModal] = useModal()
    const [deniedModal, showHideDeniedModal] = useModal()
    const [tabledModal, showHideTabledModal] = useModal()
    const [approvedModal, showHideApprovedModal] = useModal()
    const [submitToBoardModal, showHideSubmitToBoardModal] = useModal()
    const [reactivateModal, showHideReactivateModal] = useModal()
    const [completeModal, showHideCompleteModal] = useModal()

    const documentsDisabled = grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Request In Progress"]
    const boardInfoDisabled = grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Request In Progress"]|| grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Request Submitted"] || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Ready For Review"] || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Submitted to Board"] || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Request Tabled"]
    const verifyDisabled = boardInfoDisabled || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["In Matching Period"] || !grantRequestToEdit.signedGrantAgreementReceived
    const fundingDisabled = verifyDisabled || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Queued For Funding"]
    
    const documentsShowIcon = !documentsDisabled && grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Submitted to Board"] && grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Request Submitted"]

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchGrantTypes = async () => {
        console.log('GrantFormSummary > fetchGrantTypes')
        const {data} = await makeHttpRequestWithUi({
            request: GrantGiftApi.apiGrantGiftGrantTypesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch grant types.'
        })

        setGrantTypes(data)
    }

    const fetchStipulations = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestGrantIdStipulationsGet(grantRequestToEdit.grantId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching stipulations.',
        })
        setStipulations(data)
    }

    const fetchDenialReasons = async () => {
        const { data } = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestGrantDenialReasonsGet(grantRequestToEdit.grantId),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error fetching stipulations.',
        })
        setDenialReasons(data)
    }

    useEffect(() => {
        fetchGrantTypes()
        fetchStipulations()
        fetchDenialReasons()
    }, [])

    const getTabIcon = (tabIsComplete: boolean) => {
        if (grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Request In Progress"] || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Grant Complete"] || grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Grant Denied"]) return null
		const iconStyle = { marginTop: '2px', marginRight: '4px' }
        return tabIsComplete ? <CheckCircleIcon className='text-success' style={iconStyle} /> :  <ExclamationCircleIcon className='text-warning' style={iconStyle} />
		// switch (page.pageCompletionStatusId) {
		// 	case 2:
		// 		return 
		// 	case 1:
		// 		return <ExclamationCircleIcon className='text-warning' style={iconStyle} />
		// 	case -1:
		// 		return 
		// 	default:
		// 		return null
		// }
	}

    const getNextStepsButtons = () => {
        switch (grantRequestToEdit.grantRequestStatusId) {
            case GRANT_REQUEST_STATUS["Request Submitted"]:
                return (
                    <div className="d-flex">
                        { grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <button type='button' className='btn btn-primary' 
                            style={{marginRight:'30px'}}
                            onClick={() => showHideRejectModal(true)}>&lt; Send Request Back to Ministry</button> }
                        {grantRequestToEdit.isAdInfoComplete ? 
                            <button type='button' className='btn btn-primary' onClick={() => showHideAcceptModal(true)}>Accept Request &gt;</button>
                            :
                            <button type='button' className='btn btn-primary' onClick={() => setActiveTab('adInfo')}>Complete AD Info &gt;</button>}
                    </div>)
            case GRANT_REQUEST_STATUS["Ready For Review"]:
                return (
                    <div className="d-flex">
                    <button type='button' className='btn btn-primary' 
                        style={{marginRight:'30px'}}
                        onClick={() => showHideSubmitToBoardModal(true)}>Submit to Board &gt;</button>
                    </div>)
            case GRANT_REQUEST_STATUS["Submitted to Board"]:
                return (
                    <div className="d-flex">
                        <button type='button' className='btn btn-primary' 
                            style={{marginRight:'30px'}}
                            onClick={() => showHideSubmitToBoardModal(true)}>Re-submit to Board</button>
                        <button type='button' className='btn btn-danger' 
                            style={{marginRight:'30px'}}
                            onClick={() => showHideDeniedModal(true)}>Denied</button>
                        <button type='button' className='btn btn-warning' 
                            style={{marginRight:'30px'}}
                            onClick={() => showHideTabledModal(true)}>Tabled</button>
                        <button type='button' className='btn btn-success' 
                            onClick={() => showHideApprovedModal(true)}>Approved &gt;</button>
                    </div>)
            case GRANT_REQUEST_STATUS["Grant Denied"]:
                return (
                    <div className="d-flex">
                        <button type='button' className='btn btn-primary' 
                            style={{marginRight:'30px'}}
                            onClick={() => showHideReactivateModal(true)}>Reactivate</button>
                    </div>)
            case GRANT_REQUEST_STATUS["Request Tabled"]:
                return (
                    <div className="d-flex">
                        <button type='button' className='btn btn-primary' 
                            style={{marginRight:'30px'}}
                            onClick={() => showHideReactivateModal(true)}>Reactivate</button>
                    </div>)
            case GRANT_REQUEST_STATUS["In Matching Period"]:
                if (!grantRequestToEdit.signedGrantAgreementReceived) {
                    return (
                        <div className="d-flex">
                            <button type='button' className='btn btn-primary' onClick={() => setActiveTab('documents')}>Continue &gt;</button>
                        </div>)
                } else {
                    return (<div></div>)
                }
            case GRANT_REQUEST_STATUS["Queued For Funding"]:
                if (!grantRequestToEdit.signedGrantAgreementReceived && grantRequestToEdit.grantTypeId == GRANT_TYPE.Managed) {
                    return (
                        <div className="d-flex">
                            <button type='button' className='btn btn-primary' onClick={() => setActiveTab('documents')}>Continue &gt;</button>
                        </div>)
                }
                if (!grantRequestToEdit.isVerificationComplete) {
                    return (
                        <div className="d-flex">
                            <button type='button' className='btn btn-primary' onClick={() => setActiveTab('verify')}>Continue &gt;</button>
                        </div>
                    )
                } else {
                    return (
                        <div className="d-flex">
                            <button type='button' className='btn btn-primary' 
                                style={{marginRight:'30px'}}
                                onClick={() => showHideSubmitForFundingModal(true)}>Submit for Funding</button>
                        </div>
                    )
                }
            case GRANT_REQUEST_STATUS["Ready For Funding"]:
                if (!grantRequestToEdit.isFundingComplete) {
                    return (
                        <div className="d-flex">
                            <button type='button' className='btn btn-primary' onClick={() => setActiveTab('funding')}>Continue &gt;</button>
                        </div>
                    )
                } else {
                    return (
                        <div className="d-flex">
                            <button type='button' className='btn btn-primary' 
                                style={{marginRight:'30px'}}
                                onClick={() => showHideCompleteModal(true)}>Complete!</button>
                        </div>
                    )
                }
            default:
                return null
        }
    }

    const getStatusNextStepsText = () => {
        switch (grantRequestToEdit.grantRequestStatusId) {
            case GRANT_REQUEST_STATUS["Request In Progress"]:
                return 'Waiting for the grant contact to submit this grant request.'
            case GRANT_REQUEST_STATUS["Request Submitted"]:
                if (grantRequestToEdit.isAdInfoComplete) 
                    return 'The AD Info tab is complete! You may now accept this grant request.'
                else if (grantRequestToEdit.grantTypeId == GRANT_TYPE.Managed)
                    return 'Complete the AD Info tab.'
                else 
                    return 'Review this grant request then decide if you would like to accept the request as-is, or if you would like to send this back to the ministry to make changes and resubmit. <br /><br /> If you are happy with state of the grant request, complete the AD Info tab.'
            case GRANT_REQUEST_STATUS["Ready For Review"]:
                return 'This grant request is ready to be submitted to the Local Advisory Board for review.'
            case GRANT_REQUEST_STATUS["Submitted to Board"]:
                return 'This grant request has been submitted to the Local Advisory Board for review. What decision did the board make?'
            case GRANT_REQUEST_STATUS["Grant Denied"]:
                return 'This grant request has been denied. You may reactivate the grant, which will change its status back to "Submitted to Board".'
            case GRANT_REQUEST_STATUS["Request Tabled"]:
                return 'This grant request has been tabled. You may reactivate the grant, which will change its status back to "Submitted to Board".'
            case GRANT_REQUEST_STATUS["In Matching Period"]:
                if (!grantRequestToEdit.signedGrantAgreementReceived) {
                    if (grantRequestToEdit.grantTypeId == GRANT_TYPE.Managed)
                        return 'Prepare the Letter of Acknowledgement, and obtain a signed copy.'
                    else
                        return 'Prepare the Grant Agreement and Letter of Acknowledgement, and obtain a signed copy.'
                }

                //if (grantRequestToEdit.feedbackRequired && !grantRequestToEdit.dateFeedbackSubmitted) {
                    return 'Waiting for the ministry to submit their Grant Feedback Report.' // Once they submit this, the grant is moved into "Queued For Funding" status.
                //}

                //return `Waiting for the end of matching period end date (${grantRequestToEdit.grantPeriodEnding && dayjs(grantRequestToEdit.grantPeriodEnding).format('MM/DD/YYYY')}) to be reached`
            case GRANT_REQUEST_STATUS["Queued For Funding"]:
                if (!grantRequestToEdit.signedGrantAgreementReceived && grantRequestToEdit.grantTypeId == GRANT_TYPE.Managed) {
                    return 'Prepare the Letter of Acknowledgement, and obtain a signed copy.'
                }
                if (!grantRequestToEdit.isVerificationComplete) {
                    return 'Review the Verify tab and complete the form.'
                } else {
                    return 'This grant request is ready to be submitted for funding.'
                }
            case GRANT_REQUEST_STATUS["Ready For Funding"]:
                if (!grantRequestToEdit.isFundingComplete) {
                    return 'Waiting for the NRC staff to issue payment to the ministry and then flag this grant request as "Complete".'
                } else {
                    return 'A payment has been made to the ministry. You may now flag this grant as "Complete".'
                }
            default:
                return 'This is a placeholder.'
        }
    }
    
    return (
        <>
            { grantRequestToEdit.isLegacyGrant && 
                <div className="card mt-2 mb-3 alert alert-warning">
                    <div className="card-body">
                        <h5 className="card-title">Legacy Grant Request</h5>
                        <p className="card-text">
                            This grant request was created in our legacy MI Admin system. Therefore, you will not be able to change any of the data. All fields will be read-only.
                        </p>
                    </div> 
                </div> }
            { !grantRequestToEdit.isLegacyGrant && grantRequestToEdit.grantRequestStatusId !== GRANT_REQUEST_STATUS["Grant Complete"] && 
                <div className="card mt-2 mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Next Steps</h5>
                        <p className="card-text" dangerouslySetInnerHTML={{__html: getStatusNextStepsText()}}></p>
                        {getNextStepsButtons()}
                    </div> 
                </div> }
            <Tabs
                keepTabContentInDomWhenNotActive
                controls={{
                    activeTab,
                    setActiveTab
                }}
                tabs={[
                    {
                        id: 'summary',
                        title: 'Summary',
                        content: <GrantRequestFormSummary
                            grantRequestToEdit={grantRequestToEdit}
                            stipulations={stipulations}
                            grantDenialReasons={denialReasons}
                            grantTypes={grantTypes}
                            afterSave={afterSave}
                            setActiveParentTab={setActiveTab}
                            fetchStipulations={fetchStipulations}
                        />
                    },
                    {
                        id: 'adInfo',
                        title: <>{getTabIcon(grantRequestToEdit.isAdInfoComplete)} AD Info</>,
                        disabled: grantRequestToEdit.grantRequestStatusId === GRANT_REQUEST_STATUS["Request In Progress"],
                        content: <GrantRequestFormAdInfo
                            grantRequestToEdit={grantRequestToEdit}
                            afterSave={afterSave}
                        />
                    },
                    // ...grantRequestToEdit && grantRequestToEdit.dateAreaDirectorAccepted ?
                    // [
                        {
                            id: 'boardInfo',
                            title: <>{!boardInfoDisabled && getTabIcon(grantRequestToEdit.isBoardInfoComplete)} Board Info</>,
                            disabled: boardInfoDisabled,
                            content: !boardInfoDisabled ? <GrantRequestFormBoardInfo
                                    grantRequestToEdit={grantRequestToEdit}
                                    stipulations={stipulations}
                                    grantDenialReasons={denialReasons}
                                    afterSave={afterSave}
                                    fetchStipulations={fetchStipulations}
                                />
                                : 
                                <></>
                        },
                    // ] : [],
                    {
                        id: 'documents',
                        title: <>{documentsShowIcon && getTabIcon(grantRequestToEdit.signedGrantAgreementReceived)} Documents</>,
                        disabled: documentsDisabled,
                        content: !documentsDisabled ? <GrantRequestFormFiles
                                grantRequestToEdit={grantRequestToEdit}
                                afterSave={afterSave}
                            />
                            :
                            <></>
                    },
                    {
                        id: 'verify',
                        title: <>{!verifyDisabled && getTabIcon(grantRequestToEdit.isVerificationComplete)} Verify</>,
                        disabled: verifyDisabled,
                        content: !verifyDisabled ? <GrantRequestFormVerify
                                grantRequestToEdit={grantRequestToEdit}
                                stipulations={stipulations}
                                setActiveParentTab={setActiveTab}
                                afterSave={afterSave}
                            />
                            :
                            <></>
                    },
                    {
                        id: 'funding',
                        title: <>{!fundingDisabled && getTabIcon(grantRequestToEdit.isFundingComplete)} Funding</>,
                        disabled: fundingDisabled,
                        content: !fundingDisabled ? <GrantRequestFormFunding
                                grantRequestToEdit={grantRequestToEdit}
                                afterSave={afterSave}
                            />
                            :
                            <></>
                    },
                ]}
            />

            <Modal
				{...submitForFundingModal}
				modalTitle='Submit for Funding'
                size="lg"
				footer={
					<>
                        <button className='btn btn-primary mr-2' 
                        onClick={async () => {
                            await makeHttpRequestWithUi({
                                request: GrantRequestApi.apiGrantRequestGrantIdReadyForFundingPost(grantRequestToEdit.grantId),
                                toastSuccessMessage: 'Successfully submitted this grant request for funding.',
                                toastErrorMessage: 'Encountered an error submitting this grant request for funding.',
                            })
                            afterSave && afterSave()
                            showHideSubmitForFundingModal(false)
                            //fetchHistory()
                        }}>Submit for Funding</button>
						<button className='btn btn-secondary' onClick={() => showHideSubmitForFundingModal(false)}>Cancel</button>
					</>
				}
			>
                <>
                    Are you sure you want to submit this grant request for funding. Doing so will send an email to our accounting department
                    requesting they begin the funding process.
                </>
            </Modal>
            <Modal
				{...acceptModal}
				modalTitle='Accept Grant Request'
                size="lg"
				footer={
					<>
                        <button className='btn btn-primary mr-2' 
                        onClick={async () => {
                            await makeHttpRequestWithUi({
                                request: GrantRequestApi.apiGrantRequestIdAdApprovePost(grantRequestToEdit.grantId),
                                toastSuccessMessage: 'Successfully accepted this grant request.',
                                toastErrorMessage: 'Encountered an error accepting this grant request.',
                            })
                            afterSave && afterSave()
                            showHideAcceptModal(false)
                        }}>Yes, Accept</button>
						<button className='btn btn-secondary' onClick={() => showHideAcceptModal(false)}>Cancel</button>
					</>
				}
			>
                <>
                    Have you thoroughly reviewed this grant request?
                </>
            </Modal>
            <Modal
				{...rejectModal}
				modalTitle='Send Request Back to Ministry'
                size="lg"
			>
                <div className="d-flow">
                    <p>Sending the grant request back to the grant contact for changes will do the following:</p>
                    <ul className="mb-2">
                        <li>Change the status of this grant request back to "In Progress".</li>
                        <li>Send an email notification to the grant request contact, along with any instructions you enter below.</li>
                    </ul>
                    <p>After the contact updates their grant request and re-submits it for review you will receive an email notification.</p>
                </div>
                <Formik
                    initialValues={{
                        comments: '',
                    }}
                    onSubmit={async (values) => {


                        const addNoteQuery = await makeHttpRequestWithUi({
                            request: GrantRequestApi.apiGrantRequestIdRequestChangesPost(grantRequestToEdit.grantId, {
                                comments: values.comments,
                            }),
                            toastSuccessMessage: 'Successfully submitted request.',
                            toastErrorMessage: 'Error submitting request.',
                            disableLoading: false,
                        })


                        afterSave && afterSave()

                        showHideRejectModal(false)
                    }}
                >
                    <Form>
                        <FormikTextAreaField field={{ name: 'comments', label: 'Instructions for the grant contact:' }} />
                        <div>
                            <button type='submit' className='btn btn-primary mr-2'>Send it back</button>
                            <button style={{ width: 100 }} className='btn btn-secondary' type='button' onClick={() => showHideRejectModal(false)}>Cancel</button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
            <Modal
				{...submitToBoardModal}
				modalTitle='Submit to Board'
                size="lg"
			>
                <div className="d-flow">
                    <p>Enter the board member email addresses below (<b>separated by commas</b>) and we will send them an email notification
                    along with a link to the <a href={grantRequestToEdit.summaryReportUrl!} target="_blank">Grant Request Summary Report&nbsp;<BoxArrowUpRight style={{fontSize: '10px'}} /></a>.</p>
                </div>
                <Formik
                    initialValues={{
                        emails: '',
                    }}
                    validationSchema={Yup.object({
                        emails: Yup.string().required('Required')
                    })}
                    onSubmit={async (values) => {
                        await makeHttpRequestWithUi({
                            request: GrantRequestApi.apiGrantRequestGrantIdsSendSummaryToLabPost(grantRequestToEdit.grantId.toString(), {
                                labEmailAddresses: values.emails.split(',').map(item=>item.trim()),
                            }),
                            toastSuccessMessage: 'Successfully submitted request.',
                            toastErrorMessage: 'Error submitting request.',
                            disableLoading: false,
                        })

                        afterSave && afterSave()

                        showHideSubmitToBoardModal(false)
                    }}
                >
                    {formikProps => {
                        return (
                    <Form>
                        <FormikTextAreaField field={{ name: 'emails', label: 'Board member email addresses (comma separated):' }} />
                        {formikProps.values.emails && <div className="mb-3">
                            For confirmation, here is a list of the emails you entered above. Please review before submitting:
                            <ol>
                                {formikProps.values.emails.split(',').map(item=><li>{item.trim()}</li>)}
                            </ol>
                        </div> }
                        <div>
                            <button type='submit' className='btn btn-primary mr-2'>Submit for review</button>
                            <button style={{ width: 100 }} className='btn btn-secondary' onClick={() => showHideSubmitToBoardModal(false)}>Cancel</button>
                        </div>
                    </Form>
                    )
                }}
                </Formik>
            </Modal>
            <Modal
				{...deniedModal}
				modalTitle='Deny Grant Request'
                size="lg"
			>
                <Formik
                    initialValues={{
                        boardMeetingDate: '',
                        boardNotes: '',
                        denialReasonIds: [] as number[],
                        otherDenialReason: '',
                        sendEmailAlertToMinistry: true
                    }}
                    validationSchema={Yup.object({
                        boardMeetingDate: Yup.date().required('Required'),
                        otherDenialReason: Yup.string().test(
                            'otherDenialReason',
                            'Required',
                            function (value) {
                                const reasonIds = this.parent.denialReasonIds;
                                if (reasonIds instanceof Array && reasonIds.find(x => x == GRANT_DENIAL_REASON.Other)) {
                                    return !!value
                                }
                                return true
                            }
                        ),
                        denialReasonIds: Yup.array().required('Required').min(1, 'Must select at least one denial reason.')
                    })}
                    onSubmit={async (values) => {
                        await makeHttpRequestWithUi({
                            request: GrantRequestApi.apiGrantRequestGrantIdDenyRequestPost(grantRequestToEdit.grantId, {
                                boardMeetingDate: values.boardMeetingDate,
                                boardNotes: values.boardNotes,
                                denialReasonIds: values.denialReasonIds,
                                otherDenialReason: values.otherDenialReason,
                                sendEmailAlertToMinistry: values.sendEmailAlertToMinistry
                            }),
                            toastSuccessMessage: 'Successfully submitted request.',
                            toastErrorMessage: 'Error submitting request.',
                            disableLoading: false,
                        })

                        afterSave && afterSave()
                        showHideDeniedModal(false)
                        //fetchHistory()
                    }}
                >
                    {formikProps => {
                        return (
                    <Form>
                        <DatePickerField fieldProps={{ name: 'boardMeetingDate', label: 'Board Meeting Date', placeholder: 'Select a date...' }} />
                        <FormikTextAreaField field={{ name: 'boardNotes', label: 'Board Notes (optional)' }} />
                        <FormikSelectField 
                            field={{ name: 'denialReasonIds', label: 'Denial Reasons' }}
                            multiple={true}
                            // @ts-ignore
                            options={denialReasons.map(r => ({ value: r.id, label: r.name, isDisabled: !r.isActive }))} />
                        {formikProps.values.denialReasonIds.find(x => x == GRANT_DENIAL_REASON.Other) && 
                            <FormikTextAreaField field={{ name: 'otherDenialReason', label: 'Other Denial Reason' }} />}
                        <CheckboxField fieldProps={{ name: 'sendEmailAlertToMinistry', label: 'Notify grant request contact via automated email that will go out 72 hours from now?' }} />
                        <div>
                            <button type='submit' className='btn btn-danger mr-2'>Deny</button>
                            <button style={{ width: 100 }} className='btn btn-secondary' onClick={() => showHideDeniedModal(false)}>Cancel</button>
                        </div>
                    </Form>
                    
                    )
                }}
                </Formik>
            </Modal>
            <Modal
				{...tabledModal}
				modalTitle='Table Grant Request'
                size="lg"
			>
                <Formik
                    initialValues={{
                        boardMeetingDate: '',
                        boardNotes: '',
                        sendEmailAlertToMinistry: true
                    }}
                    validationSchema={Yup.object({
                        boardMeetingDate: Yup.date().required('Required')
                    })}
                    onSubmit={async (values) => {
                        await makeHttpRequestWithUi({
                            request: GrantRequestApi.apiGrantRequestGrantIdTableRequestPost(grantRequestToEdit.grantId, {
                                boardMeetingDate: values.boardMeetingDate,
                                boardNotes: values.boardNotes,
                                sendEmailAlertToMinistry: values.sendEmailAlertToMinistry
                            }),
                            toastSuccessMessage: 'Successfully submitted request.',
                            toastErrorMessage: 'Error submitting request.',
                            disableLoading: false,
                        })

                        afterSave && afterSave()
                        showHideTabledModal(false)
                        //fetchHistory()
                    }}
                >
                    {formikProps => {
                        return (
                    <Form>
                        <DatePickerField fieldProps={{ name: 'boardMeetingDate', label: 'Board Meeting Date', placeholder: 'Select a date...' }} />
                        <FormikTextAreaField field={{ name: 'boardNotes', label: 'Board Notes (optional)' }} />
                        <CheckboxField fieldProps={{ name: 'sendEmailAlertToMinistry', label: 'Notify grant request contact via automated email that will go out 72 hours from now?' }} />
                        <div>
                            <button type='submit' className='btn btn-warning mr-2'>Table</button>
                            <button style={{ width: 100 }} type='button' className='btn btn-secondary' onClick={() => showHideTabledModal(false)}>Cancel</button>
                        </div>
                    </Form>
                    )
                }}
                </Formik>
            </Modal>
            <Modal
				{...approvedModal}
				modalTitle='Approve Grant Request'
                size="lg"
			>
                <Formik
                    initialValues={{
                        boardMeetingDate: '',
                        boardNotes: '',
                        amountApproved: 0,
                        grantPeriodBeginning: '',
                        grantPeriodEnding: '',
                        sendEmailAlertToMinistry: true
                    }}
                    validationSchema={Yup.object({
                        boardMeetingDate: Yup.date().required('Required'),
                        amountApproved: Yup.number().required('Required').min(1, 'Required'),
                        grantPeriodBeginning: Yup.date().test(
                            'grantPeriodBeginning',
                            'Required',
                            function (value) {
                                if (grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed) {
                                    return !!value
                                }
                                return true
                            }
                        ),
                        grantPeriodEnding: Yup.date().test(
                            'grantPeriodEnding',
                            'Required',
                            function (value) {
                                if (grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed) {
                                    return !!value
                                }
                                return true
                            }
                        ),
                    })}
                    onSubmit={async (values) => {
                        await makeHttpRequestWithUi({
                            request: GrantRequestApi.apiGrantRequestGrantIdApproveRequestPost(grantRequestToEdit.grantId, {
                                boardMeetingDate: values.boardMeetingDate,
                                boardNotes: values.boardNotes,
                                grantPeriodBeginning: values.grantPeriodBeginning,
                                grantPeriodEnding: values.grantPeriodEnding,
                                sendEmailAlertToMinistry: values.sendEmailAlertToMinistry,
                                amountApproved: values.amountApproved
                            }),
                            toastSuccessMessage: 'Successfully submitted request.',
                            toastErrorMessage: 'Error submitting request.',
                            disableLoading: false,
                        })

                        afterSave && afterSave()
                        showHideApprovedModal(false)
                        //fetchHistory()
                    }}
                >
                    {formikProps => {
                        return (
                    <Form>
                        <CurrencyField fieldProps={{ name: 'amountApproved', label: 'Board Approved Amount' }} />
                        <DatePickerField fieldProps={{ name: 'boardMeetingDate', label: 'Board Meeting Date', placeholder: 'Select a date...' }} />
                        <FormikTextAreaField field={{ name: 'boardNotes', label: 'Board Notes (optional)' }} />
                        {/* Charity grants skip the InMatchingPeriod step and go straight to funding. */}
                        {grantRequestToEdit.feedbackRequired ?
                            <>
                                <DatePickerField fieldProps={{ name: 'grantPeriodBeginning', label: 'Matching Period Begins On', placeholder: 'Select a date...' }} />
                                <DatePickerField fieldProps={{ name: 'grantPeriodEnding', label: 'Matching Period Ends On', placeholder: 'Select a date...' }} />
                            </> 
                        :
                            <p>This grant does not have a matching period. Once approved, it will go straight to the "Ready for Funding" step.</p>}
                        <CheckboxField fieldProps={{ name: 'sendEmailAlertToMinistry', label: 'Notify grant request contact via automated email that will go out 72 hours from now?' }} />
                        {grantRequestToEdit.grantTypeId !== GRANT_TYPE.Managed && <>
                            <h5 className="mt-3">Stipulations</h5>
                            <p className='mt-3'>
                                Would you like to add stipulations?
                            </p>
                            <GrantRequestStipulations
                                grantRequestToEdit={grantRequestToEdit}
                                stipulations={stipulations}
                                afterSave={async () => {
                                    await fetchStipulations()
                                    afterSave && afterSave()
                                }}
                            />
                        </>}
                        <div>
                            <button type='submit' className='btn btn-success mr-2'>Approve</button>
                            <button style={{ width: 100 }} className='btn btn-secondary' onClick={() => showHideApprovedModal(false)}>Cancel</button>
                        </div>
                    </Form>
                    
                    )
                }}
                </Formik>
            </Modal>
            <Modal
				{...reactivateModal}
				modalTitle='Reactivate Grant Request'
                size="lg"
				footer={
					<>
                        <button className='btn btn-primary mr-2' 
                        onClick={async () => {
                            await makeHttpRequestWithUi({
                                request: GrantRequestApi.apiGrantRequestGrantIdReactivateRequestPost(grantRequestToEdit.grantId),
                                toastSuccessMessage: 'Successfully reactivated this grant request.',
                                toastErrorMessage: 'Encountered an error reactivating this grant request.',
                            })
                            afterSave && afterSave()
                            showHideReactivateModal(false)
                            //fetchHistory()
                        }}>Reactivate</button>
						<button className='btn btn-secondary' onClick={() => showHideReactivateModal(false)}>Cancel</button>
					</>
				}
			>
                <>
                    Are you sure you want to reactivate this grant request. Doing so will change its state back to "Submitted to Board". 
                    You will <strong>lose</strong> any denial/tabled reason information that may have been previously entered.
                </>
            </Modal>

            <Modal
				{...completeModal}
				modalTitle='Complete?'
                size="lg"
				footer={
					<>
                        <button className='btn btn-primary mr-2' 
                        onClick={async () => {
                            await makeHttpRequestWithUi({
                                request: GrantRequestApi.apiGrantRequestGrantIdCompletePost(grantRequestToEdit.grantId),
                                toastSuccessMessage: 'Successfully flagged this grant request as "Complete".',
                                toastErrorMessage: 'Encountered an error flagging this grant request as "Complete".',
                            })
                            afterSave && afterSave()
                            showHideCompleteModal(false)
                            //fetchHistory()
                        }}>Complete!</button>
						<button className='btn btn-secondary' onClick={() => showHideCompleteModal(false)}>Cancel</button>
					</>
				}
			>
                <>
                    Are you sure you want to flag this grant request as 'Complete'? Doing so will send an email alert to the corresponding Area Director.
                </>
            </Modal>
        </>
    )
}