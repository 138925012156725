import { IEventContentOptionDocument, ISeminarEvalSummaryDocument } from "../open-api"
import { useContext, useEffect, useState } from "react"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, IGridColumn, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { EVENT_EVAL_RATINGS_WEBINAR, EVENT_EVAL_RATINGS_WORKSHOP, EVENT_TYPES } from "../constants"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { Grid } from "./grid"
import { IDefaultProps } from "./component-definitions"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { Helmet } from "react-helmet"
import { ClipboardCheckIcon, SearchIcon } from "../assets"
import { isSelectFieldOption, SimpleSelectField } from "./forms"
import { Modal } from "./modal"
import { AttendeeEval } from './attendee-eval'

interface IAttendeeEvals extends IDefaultProps { }

export const AttendeeEvals = (props: IAttendeeEvals) => {
    const appState = useContext(AppStateContext)!    
    const {SeminarTrainerEvalApi, SeminarEvalApi} = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    const [eventOptions, setEventOptions] = useState<IEventContentOptionDocument[]>()
    const [attendeeEvals, setAttendeeEvals] = useState<ISeminarEvalSummaryDocument[]>([])
    const [activeEventContentId, setActiveEventContentId] = useState<number>()
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [viewEvalModal, showHideViewEvalModal] = useModal()
    const [activeEval, setActiveEval] = useState<ISeminarEvalSummaryDocument>()

    const areWebinars = attendeeEvals.some(x => x.eventTypeId === EVENT_TYPES.Webinar)

    const dataSource: GridDataFetch<typeof attendeeEvals> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.seminarEvalId!.toString(),
                values: {
                    ...o
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('AttendeeEvals > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    useEffect(() => {
        // Declaring function inside useEffect to avoid linting warning
        const fetchEventOptions = async () => {
            const eventsQuery = await makeHttpRequestWithUi({
                request: SeminarTrainerEvalApi.apiSeminarTrainerEvalEventContentOptionsGet(),
                disableSuccessToast: true,
                toastErrorMessage: 'Failed to fetch event options.'
            })
    
            setEventOptions(eventsQuery.data)
        }

        fetchEventOptions()
    }, [])

    useEffect(() => {
        if (eventOptions && eventOptions[0]) {
            setActiveEventContentId(eventOptions[0].eventContentId)
        }
    }, [eventOptions])

    useEffect(() => {
        // Declaring function inside useEffect to avoid linting warning
        const fetchAttendeeEvals = async (eventContentId: number) => {
            const { data } = await makeHttpRequestWithUi({
                request: SeminarEvalApi.apiSeminarEvalEventcontentEventContentIdGet(eventContentId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching event evals.',
            })
    
            setAttendeeEvals(data)
        }

        if (activeEventContentId) fetchAttendeeEvals(activeEventContentId)
    }, [activeEventContentId])


    let gridColumns: IGridColumn[] = []
    let gridColumnsWebinar: IGridColumn[] = []

    gridColumns.push(
        {
			property: 'branchAbbr',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Community',
			render: DefaultGridCellDisplay,
			description: 'The community that the ministry is attached to',
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
		},
        {
			property: 'trainingAreaAbbr',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Training Area',
			render: DefaultGridCellDisplay,
		},
        {
			property: 'siteName',
			type: 'string',
			width: 100,
			allowFilters: true,
			title: 'Site',
			render: DefaultGridCellDisplay,
            hide: true
		},
        {
            property: 'ministryName',
            type: 'string',
            width: 125,
            allowFilters: true,
            title: 'Ministry',
            render: (col, row) => <a target='_blank' rel="noreferrer" href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
        },
        {
            property: 'attendeeName',
            type: 'string',
            width: 130,
            allowFilters: true,
            title: 'Attendee Name',
            render: DefaultGridCellDisplay
        },
        {
            property: 'mostHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Most Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.mostHelpful === 'string' ? row.values.mostHelpful : undefined
        },
        {
            property: 'leastHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'How to Improve',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.leastHelpful === 'string' ? row.values.leastHelpful : undefined
        },
        {
            property: 'changeAfterTraining',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Biggest Take-Away',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.changeAfterTraining === 'string' ? row.values.changeAfterTraining : undefined
        },
        {
            property: 'comments',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Comments',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
        },
        {
            property: 'value',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Value',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'content',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Content',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'speakers',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Speakers',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'facility',
            type: 'string',
            width: 72,
            allowFilters: true,
            title: 'Facility',
            render: (col, row) => EVENT_EVAL_RATINGS_WORKSHOP.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'grid_actions',
            type: 'actions',
            width: 80,
            disableSort: true,
            title: 'Actions',
            render: GridActionCell,
        },
    )

    gridColumnsWebinar.push(
        {
			property: 'branchAbbr',
			type: 'string',
			width: 80,
			allowFilters: true,
			title: 'Community',
			render: DefaultGridCellDisplay,
			description: 'The community that the ministry is attached to',
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
		},
        {
            property: 'ministryName',
            type: 'string',
            width: 125,
            allowFilters: true,
            title: 'Ministry',
            render: (col, row) => <a target='_blank' rel="noreferrer" href={`/ministry-info/${row.values.ministryId}`}>{row.values[col.property]}</a>
        },
        {
            property: 'attendeeName',
            type: 'string',
            width: 130,
            allowFilters: true,
            title: 'Attendee Name',
            render: DefaultGridCellDisplay
        },
        {
            property: 'mostHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Most Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.mostHelpful === 'string' ? row.values.mostHelpful : undefined
        },
        {
            property: 'leastHelpful',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Least Helpful',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.leastHelpful === 'string' ? row.values.leastHelpful : undefined
        },
        {
            property: 'changeAfterTraining',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Change After Training',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.changeAfterTraining === 'string' ? row.values.changeAfterTraining : undefined
        },
        {
            property: 'comments',
            type: 'string',
            width: 150,
            allowFilters: true,
            title: 'Comments',
            render: DefaultGridCellDisplay,
            tooltip: (row) => typeof row.values.comments === 'string' ? row.values.comments : undefined
        },
        {
            property: 'webinarValue',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Value',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
            
        },
        {
            property: 'webinarContent',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Content',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarSpeakers',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Speakers',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarContentActionable',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Content Actionable',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarEffective',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Effective',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarInvolveStaff',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Involve Staff',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarMoreWebinars',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'More Webinars',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarRegistrationEase',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Registration Ease',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarWellManaged',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Well Managed',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarQuestionAndAnswer',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Q And A',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarAccess',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Access',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarConfirmed',
            type: 'string',
            width: 83,
            allowFilters: true,
            title: 'Confirmed',
            render: (col, row) => EVENT_EVAL_RATINGS_WEBINAR.find(x => x.value === row.values[col.property])?.label || '',
        },
        {
            property: 'webinarAttendAgain',
            type: 'boolean',
            width: 83,
            allowFilters: true,
            title: 'Attend Again',
            render: DefaultGridCellDisplay
        },
        {
            property: 'grid_actions',
            type: 'actions',
            width: 50,
            disableSort: true,
            title: 'Actions',
            render: GridActionCell,
        },
    )

    const initialGridState: IGridState = {
        ...defaultGridState,
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        columns: gridColumns,
        rowActions: {
            viewEval: {
                id: 'viewEval',
                tooltipText: 'View Eval',
                icon: <SearchIcon />,
                action: async (options) => {
                    options.e.stopPropagation()
                    setSelectedRow(options.row)
                    showHideViewEvalModal(true)
                },
            }
        },
        respectGlobalCommunityFilter: true
    }

    const initialGridStateWebinar: IGridState = {
        ...defaultGridState,
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        columns: gridColumnsWebinar,
        rowActions: {
            viewEval: {
                id: 'viewEval',
                tooltipText: 'View Eval',
                icon: <SearchIcon />,
                action: async (options) => {
                    options.e.stopPropagation()
                    setSelectedRow(options.row)
                    showHideViewEvalModal(true)
                },
            }
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, attendeeEvals)
    const [gridStateWebinar, gridActionsWebinar] = useGrid(gridReducer, initialGridStateWebinar, attendeeEvals)

    useEffect(() => {
        areWebinars ? gridActionsWebinar.doFetch() : gridActions.doFetch()
    }, [attendeeEvals])

    useEffect(() => {
        const fetchEvalData = async () => {
                if (selectedRow) {
                const response = await makeHttpRequestWithUi({
                    request: SeminarEvalApi.apiSeminarEvalSeminarEvalIdGet(parseInt(selectedRow.id)),
                    disableLoading: true,
                    disableSuccessToast: true,
                    toastErrorMessage: 'There was a problem fetching the eval data.'
                })

                setActiveEval(response.data)
            }
        }

        fetchEvalData()
    }, [selectedRow])

    const nextEvalHandler = async () => {
        const i = gridState.rows.findIndex(x => x.id == selectedRow?.id);
        if (i == -1 || gridState.rows.length <= i)  return
        const nextIndex = i + 1
        const nextRow = gridState.rows[nextIndex]
        setSelectedRow(nextRow);
    }

    const previousEvalHandler = async () => {
        const i = gridState.rows.findIndex(x => x.id == selectedRow?.id);
        if (i == -1 || i == 0)  return
        const previousIndex = i - 1
        const previousRow = gridState.rows[previousIndex]
        setSelectedRow(previousRow);
    }

    return (
        <>
            <Helmet>
                <title>Attendee Evals</title>
            </Helmet>
            <div className='d-flex flex-column vh-100'>
                <div className='m-2 d-flex align-items-center'>
                    <ClipboardCheckIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Attendee Evals</h3>
                </div>
                <div className='m-2 w-25'>
                <SimpleSelectField
                    fieldProps={{ name: '', label: 'Workshops' }}
                    options={eventOptions?.map(o => ({ label: `${o.title}`, value: `${o.eventContentId}` }))}
                    onChange={(option) => {
                        if (isSelectFieldOption(option)) {
                            setActiveEventContentId(parseInt(option.value))
                        }
                    }}
                    value={activeEventContentId?.toString()}
                />
                </div>
                {areWebinars ? 
                <Grid state={gridStateWebinar} actions={gridActionsWebinar} style={{ height: '100%' }} />
                :
                <Grid state={gridState} actions={gridActions} style={{ flex: 1, height: '100%' }} />
                }
            </div>

            <Modal
                {...viewEvalModal}
                modalTitle='Trainer Eval'
                size='xl'
                _onModalHidden={
                    () => {
                        setSelectedRow(undefined)
                        setActiveEval(undefined)
                    }
                }>
                    { 
                        activeEval ? 
                        <AttendeeEval attendeeEval={activeEval} nextEvalHandler={nextEvalHandler} previousEvalHandler={previousEvalHandler} />
                        :
                        null
                    }
            </Modal>
        </>
    )
}