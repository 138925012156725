import { IDefaultProps } from './component-definitions'
import { MinistryManagement } from './ministry-management'
import { Router } from '@reach/router'
import { MinistryInfo } from './ministry-info'
import { MainNav } from './main-nav'
import { Tags } from './tags'
import { Settings } from './settings'
import { Prospects } from './prospects'
import { ProspectDetail } from './prospect-detail'
import { Users } from './users'
import { Communities } from './communities'
import { CommunityDetail } from './community-detail'
import { EventNews } from './event-news'
import { Presenters } from './presenters'
import { CustomEmailBlast } from './custom-email-blasts'
import { MailLog } from './mail-log'
import { MailNotifications } from './mail-notifications'
import { UnsubscribeList } from './unsubscribe-list'
import { ConsultingManagement } from './consulting-management'
import { GlobalSearchResults } from './global-search-results'
import { GroupCoachingTopics } from './group-coaching-topics'
import { EventManagement } from './event-management'
import { GiverManagement } from './giver-management'
import { Home } from './home'
import { CallLists } from './call-lists'
import { CallListSummary } from './call-list-summary'
import { EventDetail } from './event-detail'
import { TrainingAreaDetail } from './training-area-detail'
import { ConsultingManagementDetail } from './consulting-management-detail'
import { TrainerEvals } from './trainer-evals'
import { AttendeeEvals } from './attendee-evals'
import { DashboardReport } from './report-dashboard'
import { GrantManagement } from './grant-management'
import { GrantRequests } from './grant-requests'
import { GrantRequestDetail } from './grant-request-detail'
import { EventNewsSettings } from './event-news-settings'
import { GuideStarSearch } from './guide-star-search'
import { GuideStarPremier } from './guidestar-premier'
import { GroupCoachingDetail } from './group-coaching-detail'
import { GroupCoachingRegistrantsSignInSheet } from './download-sign-in-sheet'
import { MinistryInfoFyDataDetail } from './ministry-info-fy-data-detail'
import { LeverageSummaryReport } from './report-leverage-summary'
import { OrderManagement } from './order-management'
import { EventContentDetail } from './event-content-detail'
import { GroupCoachingDuplicate } from './group-coaching-duplicate'
import { MinistryContactManagement } from './ministry-contact-management'
import { ContactInfo } from './contact-info'
import { CourseEnrollment } from './course-enrollment'
import { ManualCourseAssignment } from './manual-course-assignment'
import { FoundationSearch } from './foundation-search'
import { AnnualKpisReport } from './report-annual-kpis'
import { NonprofitMap } from './nonprofit-map'

export enum Path {
	'home' = 'home',
	'ministries' = 'ministries',
	'archived-ministries' = 'archived-ministries',
	'current-ministries' = 'current-ministries',
	'contacts' = 'contacts',
	'archived-contacts' = 'archived-contacts',
	'contact-info' = 'contact-info',
	'course-enrollment' = 'course-enrollment',
	'manual-course-assignment' = 'manual-course-assignment', 
	'ministry-info' = 'ministry-info',
	'prospects' = 'prospects',
	'tags' = 'tags',
	'communities' = 'communities',
	'users' = 'users',
	'settings' = 'settings',
	'eventnews' = 'eventnews',
	'presenters' = 'presenters',
	'custom-blast' = 'custom-blast',
	'mail-log' = 'mail-log',
	'consulting' = 'consulting',
	'givers' = 'givers',
	'mail-notifications' = 'mail-notifications',
	'unsubscribe-list' = 'unsubscribe-list',
	'search' = 'search',
	'group-learning-topics' = 'group-learning-topics',
	'group-learning' = 'group-learning',
	'group-coaching-v2' = 'group-coaching-v2',
	'event-management' = 'event-management',
	'call-lists' = 'call-lists',
	'call-list-summary' = 'call-list-summary',
	'trainer-evals' = 'trainer-evals',
	'attendee-evals' = 'attendee-evals',
	'dashboard-report' = 'dashboard-report',
	'grants' = 'grants',
	'grant-requests' = 'grant-requests',
	'grant-request' = 'grant-request',
	'event-news-settings' = 'event-news-settings',
	'guide-star-search' = 'guide-star-search',
	'foundation-search' = 'foundation-search',
	'guidestar-premier' = 'guidestar-premier',
	'fy-data-detail' = 'fy-data-detail',
	'leverage-summary-report' = 'leverage-summary-report',
	'order-management' = 'order-management',
	'event-content' = 'event-content',
	'group-coaching-duplicate' = 'group-coaching-duplicate',
	'annual-kpis' = 'annual-kpis',
	'nonprofit-map' = 'nonprofit-map'
}

export const allPaths = Object.keys(Path).filter(key => typeof key === 'string')

export const Dashboard = ({ }: IDefaultProps) => {
	return (
		<div id='dashboard' className='base'>
			<MainNav />
			<Router>
				<Home path={Path.home} default />
				<MinistryManagement path={Path.ministries} />
				<MinistryManagement path={Path['archived-ministries']} />
				<MinistryContactManagement path={Path.contacts} />
				<MinistryContactManagement path={Path['archived-contacts']} />
				<ContactInfo path={`/${Path['contact-info']}/:contactId`} />
				<CourseEnrollment path={`/${Path['course-enrollment']}`} />
				<ManualCourseAssignment path={`/${Path['manual-course-assignment']}`} />
				<MinistryManagement path={Path['current-ministries']} />
				<MinistryInfo path={`/${Path["ministry-info"]}/:ministryId`} />
				<Prospects path={Path.prospects} />
				<ProspectDetail path={`${Path.prospects}/:prospectId`} />
				<Tags path={Path.tags} />
				<Communities path={Path.communities} />
				<CommunityDetail path={`${Path.communities}/:communityId`} />
				<TrainingAreaDetail path={`${Path.communities}/training-area/:trainingAreaId`} />
				<Users path={Path.users} />
				<Settings path={Path.settings} />
				<EventNews path={Path.eventnews} />
				<EventNews path={`${Path.eventnews}/:eventNewsId`} />
				<Presenters path={Path.presenters} />
				<TrainerEvals path={Path['trainer-evals']} />
				<AttendeeEvals path={Path['attendee-evals']} />
				<CustomEmailBlast path={Path["custom-blast"]} />
				<MailLog path={Path["mail-log"]} />
				<MailNotifications path={Path['mail-notifications']} />
				<UnsubscribeList path={Path['unsubscribe-list']} />
				<ConsultingManagement path={Path.consulting} />
				<ConsultingManagementDetail path={`${Path.consulting}/:consultingEventGroupId`} />
				<GlobalSearchResults path={Path.search} />
				<GroupCoachingTopics path={Path['group-learning-topics']} />
				<GroupCoachingDetail path={`${Path['group-learning']}/:eventContentId/:eventId`} />
				<GroupCoachingRegistrantsSignInSheet path='group-learning-sign-in-sheet/:eventContentId/:eventId' />
				<EventManagement path={Path['event-management']} />
				<EventDetail path={`${Path['event-management']}/:eventId`} />
				<EventContentDetail path={`${Path['event-content']}/:eventContentId`} />
				<GiverManagement path={Path.givers} />
				<CallLists path={Path['call-lists']} />
				<CallListSummary path={`/${Path["call-list-summary"]}/:callListId`} />
				<DashboardReport path={Path["dashboard-report"]} />
				<LeverageSummaryReport path={Path["leverage-summary-report"]} />
				<GrantManagement path={Path.grants} />
				<GrantRequests path={Path['grant-requests']} />
				<GrantRequestDetail path={`/${Path["grant-request"]}/:grantRequestId`} />
				<MinistryInfoFyDataDetail path={`/${Path["fy-data-detail"]}/:ministryDataId`} />
				<GuideStarPremier path={`/${Path["guidestar-premier"]}/:ein`} />
				<GuideStarSearch path={Path["guide-star-search"]} />
				<FoundationSearch path={Path["foundation-search"]} />
				<EventNewsSettings path={Path["event-news-settings"]} />
				<OrderManagement path={Path["order-management"]} />
				<GroupCoachingDuplicate path={`/${Path["group-coaching-duplicate"]}/:eventContentId`} />
				<AnnualKpisReport path={Path["annual-kpis"]} />
				<NonprofitMap path={Path["nonprofit-map"]} />
			</Router>
		</div>
	)
}