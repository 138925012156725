import React, { useEffect, useContext, useState, useRef } from 'react'
import { MinistryInfoContext } from './ministry-info'
import { usePrevious } from 'react-use'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { Loading } from './loading'
import { GridDataFetch, IGridState, IGridListItem } from '../stores/grid-definitions'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { validateFilterState, filterGridListItems, isEven, formatCurrency, uuidv4, openUrlInNewTab, getGuidestarProfileUrlForTaxId, sortListBySorts } from '../services/helpers'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { LeverageSummary, leverageSummaryColumns } from '../models/leverage-summary'
import { ReactComponent as BinocularsIcon } from '../assets/binoculars.svg'
import { ReactComponent as SearchIcon } from '../assets/search.svg'
import { ReactComponent as ArrowLeftShortIcon } from '../assets/arrow-left-short.svg'
import { ReactComponent as ArrowRightShortIcon } from '../assets/arrow-right-short.svg'
import { ReactComponent as ArrowRepeatIcon } from '../assets/arrow-repeat.svg'
import { Grid } from './grid'
import Chart from 'chart.js'
import { Modal } from './modal'
import { AppActionContext } from '../app-store-provider'
import moment from 'moment'
import { IFilter } from '../stores/api-actions'
import { TooltipInfo } from './partials'

export const MinistryInfoGeneralLeverageSummary = () => {
	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!
	const appActions = useContext(AppActionContext)!

	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const maxBaselineYear = moment().subtract(8, 'y').get('y')
	const [baselineYear, setBaselineYear] = useState(maxBaselineYear)

	const previousMinistry = usePrevious(ministryInfoState.ministry)
	const previousBaselineYear = usePrevious(baselineYear)

	const [showX20Orgs, setShowx20Orgs] = useState(false)

	/* 
		Refresh leverage summary data if:
		- Ministry changes
		- Baseline year changes
	*/
	useEffect(() => {
		if (previousMinistry && previousMinistry.ministryId !== ministryInfoState.ministry?.ministryId || (previousBaselineYear && previousBaselineYear !== baselineYear)) {
			makeHTTPRequestWithUi({
				request: ministryInfoActions.fetchLeverageSummary(baselineYear),
				disableSuccessToast: true,
				toastErrorMessage: 'Failed to retrieve Ministry Leverage Summary.'
			})
		}

	}, [ministryInfoState.ministry, baselineYear])

	const [quickStats, setQuickStats] = useState({
		comparisonOrgs: 0,
		revokedOrgs: 0
	})

	const fetchFyDataGeneral: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { leverageSummary } = _state

		if (!leverageSummary) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(leverageSummary, queryState.sorts)

		let rows = leverageSummary.map(LeverageSummary.toGridListItem)

		const comparisonMinistryRow = rows.find(r => r.id === _state.ministry?.ministryId.toString())

		if (queryState.filters) {
			validateFilterState(queryState.filters, gridState.columns)
			rows = filterGridListItems(rows, queryState.filters)
		}

		if (!rows.find(r => r.id === _state.ministry?.ministryId.toString()) && comparisonMinistryRow) rows.unshift(comparisonMinistryRow)

		const ministryRow = rows.find(row => row.id === ministryInfoState.ministry?.ministryId.toString())
		if (ministryRow) ministryRow.selected = true

		return { rows, count: leverageSummary.length }
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [excludeOrphanConfirmModalId] = useState(uuidv4())

	const queryState = { ...defaultGridState.queryState }
	const x20OrgFilter: IFilter = {
		id: 'x20-filter',
		property: 'nteeCode',
		operator: 'not-like',
		enabled: !showX20Orgs,
		value: 'x20'
	}
	queryState.filters = [x20OrgFilter]

	const initialGridState: IGridState = {
		...defaultGridState,
		queryState,
		usingLocalData: true,
		rowSelectEnabled: true,

		// Turn on colors for each row (turns the Grid into a legend for charts)
		enableRowColors: true,

		// Prevent users from disabling the ministry we're doing the leverage summary comparison for (this makes sure it always shows in the chart)
		disableSelect: (row) => row.id === ministryInfoState.ministry?.ministryId.toString(),
		columns: leverageSummaryColumns,
		disabledPagination: true,
		dataSource: fetchFyDataGeneral,
		hideGridHeader: true,
		hideGridFooter: true,
		rowActionsDisplayType: 'icons',
		rowActions: {
			viewGsInfo: {
				id: 'viewGsInfo',
				action: async (options) => {
					options.e.stopPropagation()

					let taxId = options.row.values.federalTaxNo as string | null

					if (taxId) {
						openUrlInNewTab(getGuidestarProfileUrlForTaxId(taxId))
					} else {
						appActions.addAlert({ id: uuidv4(), body: 'This ministry does not have a valid tax ID/EIN. Cannot view Guidestar report without one.', title: 'No Valid Tax ID/EIN' })
					}
				},
				icon: <div><BinocularsIcon /></div>,
				tooltipText: 'View GS Info',
			},
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	/* 
		Set up the chart once the chartRef is available
	*/
	const [chart, setChart] = useState<Chart>()
	const [dataSetRowIdIndex] = useState<Map<string, number>>(new Map())
	const chartRef = useRef<HTMLCanvasElement>(null)
	useEffect(() => {
		const ctx = chartRef.current
		if (ctx) {
			setChart(new Chart(ctx, {
				type: 'line',
				data: {
					labels: [],
					datasets: []
				},
				options: {
					title: {
						display: true,
						text: 'Income Performance'
					},

					// This is required to keep the chart from overflowing the container
					maintainAspectRatio: false,

					legend: {
						display: false,
					},

					// Make the lines straight so solid and dashed datasets for each leverage summary row perfectly overlap
					elements: {
						line: {
							tension: 0
						}
					},

					// Filter out tooltips for dashed lines (since we have to double up the lines)
					tooltips: {
						filter: (tooltipItem) => tooltipItem.datasetIndex !== undefined && (tooltipItem.datasetIndex === 0 || isEven(tooltipItem.datasetIndex)),
						callbacks: {
							label: (tooltipItem, data) => {
								return `${data.datasets && tooltipItem.datasetIndex !== undefined ? data.datasets[tooltipItem.datasetIndex].label : ''}: ${!!tooltipItem.yLabel ? (typeof tooltipItem.yLabel === 'number' ? formatCurrency(tooltipItem.yLabel) : formatCurrency(parseInt(tooltipItem.yLabel))) : ''}`
							},
						}
					},

					// Format y axis labels to be currency
					scales: {
						yAxes: [{
							ticks: {
								// Include a dollar sign in the ticks
								callback: function (value, index, values) {
									return formatCurrency(parseInt(value.toString()), false)
								}
							}
						}]
					},
				}
			}))
		}
	}, [chartRef.current])

	/* 
		Refresh the chart data whenever the selected rows change

		We keep track of the previous set of rows in order to diff selected/deselected rows and add/remove data from the chart.
		This lets us not rerender the whole chart with all datasets, instead just render the newly added/removed datasets.
	*/
	const previousLeverageSummary = usePrevious(ministryInfoState.leverageSummary)
	useEffect(() => {
		const previousSelectedRowIds = Array.from(dataSetRowIdIndex.keys())
		const currentlySelectedRowIds = gridState.rows.filter(row => row.selected).map(row => row.id)

		let selected = currentlySelectedRowIds.filter(x => !previousSelectedRowIds.includes(x))
		let deselected = previousSelectedRowIds.filter(x => !currentlySelectedRowIds.includes(x))

		// In what circumstances do we need to update the chart?
		const leverageSummaryDataChanged = previousLeverageSummary !== ministryInfoState.leverageSummary // Leverage summary data has been updated
		const selectedRowsChanged = selected.length > 0 || deselected.length > 0 // Rows have been selected or deselected

		if (
			chart &&
			ministryInfoState.leverageSummary &&
			ministryInfoState.leverageSummary.length > 0 && // Only need to update chart data if the LeverageSummary data is loaded
			(leverageSummaryDataChanged || selectedRowsChanged)
		) {
			// For some reason the "baseline" year for summary data is the "baseline2Year" property - setting it to a new name here for increased legibility
			const baselineYear = ministryInfoState.leverageSummary[0].baseline2Year

			// Create the year labels that will be used by each dataset
			chart.data.labels = [
				`${baselineYear}`,
				`${baselineYear + 1}`,
				`${baselineYear + 2}`,
				`${baselineYear + 3}`,
				`${baselineYear + 4}`,
				`${baselineYear + 5}`,
				`${baselineYear + 6}`,
				`${baselineYear + 7}`,
				`${baselineYear + 8}`,
				`${baselineYear + 9}`,
			]

			// Add selected rows to chart datasets
			const selectedRows = gridState.rows.filter(row => selected.includes(row.id))
			selectedRows.forEach(row => {
				const summary = ministryInfoState.leverageSummary?.find(o => o.bmfOrganizationID.toString() === row.id)
				if (summary) {
					// Gather the actual array of values for this summary record
					const data = [
						summary.baseline2,
						summary.baseline,
						summary.year1,
						summary.year2,
						summary.year3,
						summary.year4,
						summary.year5,
						summary.year6,
						summary.year7,
						summary.year8,
					]

					// This will always be defined but to make Typescipt happy we'll make absolutely certain there's an array waiting
					if (!chart.data.datasets) chart.data.datasets = []

					// Add the main dataset for this summary record
					chart.data.datasets.push({
						fill: false,
						label: summary.ministryName || '',
						data,
						backgroundColor: `#${row?.color}`,
						borderColor: `#${row?.color}`,
					})

					// Keep track of which dataset indexes go with which rows (ministries)
					dataSetRowIdIndex.set(row.id, chart.data.datasets.length - 1)

					// Add the "dashed" data set to show gaps (necessary because we can't style the "spanGaps" lines to be dashes)
					chart.data.datasets.push({
						fill: false,
						label: summary.ministryName || '',
						data,
						backgroundColor: `#${row?.color}`,
						borderColor: `#${row?.color}`,
						borderDash: [5, 5],
						spanGaps: true,
					})
				}
			})

			// Remove deselected rows
			deselected.forEach(deselectedRowId => {
				const deselectedRowDatasetIndex = dataSetRowIdIndex.get(deselectedRowId)
				if (deselectedRowDatasetIndex !== undefined) {
					chart.data.datasets?.splice(deselectedRowDatasetIndex, 2)
					dataSetRowIdIndex.delete(deselectedRowId)

					/* 
						Update remaining datastore indexes we're tracking
						  For all the indexes AFTER this one, we need to take them down by 2 slots
					*/
					Array.from(dataSetRowIdIndex.entries())
						.filter(([rowId, dataSetIndex]) => dataSetIndex > deselectedRowDatasetIndex)
						.forEach(([rowId, dataSetIndex]) => {
							dataSetRowIdIndex.set(rowId, dataSetIndex - 2)
						})
				}
			})

			/* 
				If the underlying leverage summary data changed we can:
				- Update the chart data for currently selected rows
				- Remove any datasets for rows that don't exist in the new leverage summary data (in case different baseline years return different list of comparison orgs)
			*/
			if (leverageSummaryDataChanged) {
				// Update the chart data for currently selected rows
				Array.from(dataSetRowIdIndex.entries())
					.forEach(([rowId, dataSetIndex]) => {
						const summary = ministryInfoState.leverageSummary?.find(o => o.bmfOrganizationID.toString() === rowId)

						if (summary) {
							const data = [
								summary.baseline2,
								summary.baseline,
								summary.year1,
								summary.year2,
								summary.year3,
								summary.year4,
								summary.year5,
								summary.year6,
								summary.year7,
								summary.year8,
							]

							// (Safe to "!" these since we know this data set will be here - if we encounter errors then we've done something wrong somewhere else)
							chart.data.datasets![dataSetIndex].data = data
							chart.data.datasets![dataSetIndex + 1].data = data
						}
					})
			}

			chart.update()
		}
	}, [gridState.rows, ministryInfoState.leverageSummary])

	useEffect(() => {
		if (ministryInfoState.leverageSummary) {
			setQuickStats({
				comparisonOrgs: ministryInfoState.leverageSummary.filter(o => o.bmfOrganizationID !== ministryInfoState.ministry?.ministryId).length,
				revokedOrgs: ministryInfoState.leverageSummary.filter(o => o.bRevoked).length
			})

			// Any time the leverage summary data changes, we need to refresh the column titles for the years
			if (ministryInfoState.leverageSummary.length > 0) {
				const baselineYear = ministryInfoState.leverageSummary[0].baseline2Year

				const _columns = [...gridState.columns]

				// Pretty much just an ugly switch case since the data is actually stored as year1, year2, year3, etc.
				_columns.forEach(col => {
					switch (col.property) {
						case 'baseline2':
							col.title = `${baselineYear}`
							break
						case 'baseline':
							col.title = `${baselineYear + 1}`
							break
						case 'year1':
							col.title = `${baselineYear + 2}`
							break
						case 'year2':
							col.title = `${baselineYear + 3}`
							break
						case 'year3':
							col.title = `${baselineYear + 4}`
							break
						case 'year4':
							col.title = `${baselineYear + 5}`
							break
						case 'year5':
							col.title = `${baselineYear + 6}`
							break
						case 'year6':
							col.title = `${baselineYear + 7}`
							break
						case 'year7':
							col.title = `${baselineYear + 8}`
							break
						case 'year8':
							col.title = `${baselineYear + 9}`
							break
					}
				})

				gridActions.updateColumns(_columns)
			}

			gridActions.doFetch()
		}
	}, [ministryInfoState.leverageSummary])

	// Listen to row changes and make sure the main ministry's row always stays selected
	useEffect(() => {
		const row = gridState.rows.find(row => row.id === ministryInfoState.ministry?.ministryId.toString())
		if (row && !row.selected) gridActions.toggleRowSelect(row, true)
	}, [gridState.rows])

	/* 
		Listen for when the user changes the "show x20 orgs" setting
		When they do, toggle the filter that shows/hides orgs whose NTEE code property includes "x20", then refresh the Grid.
	*/
	useEffect(() => {
		x20OrgFilter.enabled = !showX20Orgs
		gridActions.updateColumnFilters([x20OrgFilter])
	}, [showX20Orgs])

	if (!ministryInfoState.leverageSummary || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<div style={{ height: '100%' }}>
				<div className='d-flex' style={{ height: '300px' }}>
					<div style={{ flex: 9 }}>
						<canvas height='200' ref={chartRef}></canvas>
					</div>
					<div style={{ flex: 3 }} className='p-2'>
						<p>This tool compares MI ministries against other local ministries meeting specific criteria.</p>
						<p>Comparison ministries have income between 50% and 150% of the MI ministry's income. They must also be within 150 miles of the MI ministry, and have matching NTEE Codes as defined by the IRS Business Master File.</p>

						<h5>Quick Stats</h5>
						<div className='row'>
							<div className='col-6'>
								<div>Comparison Orgs</div>
							</div>
							<div className='col-6'>{quickStats.comparisonOrgs}</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<div>Revoked Orgs <TooltipInfo placement={'bottom'} style={{ marginBottom: '1px' }} tooltipText='The number of comparison organizations that have had their exemption status revoked. This data is collected from the IRS website.' /> </div>
							</div>
							<div className='col-6'>{quickStats.revokedOrgs}</div>
						</div>
					</div>
				</div>

				<div className='d-flex py-1' style={{ height: '40px' }}>
					<button className='btn btn-primary btn-sm icon-left mr-1' style={{ width: '95px' }} onClick={() => setBaselineYear(baselineYear - 1)}><ArrowLeftShortIcon /> Earlier</button>
					<button className='btn btn-primary btn-sm icon-right mr-1' style={{ width: '95px' }} disabled={baselineYear === maxBaselineYear} onClick={() => setBaselineYear(baselineYear + 1)}>Later <ArrowRightShortIcon /></button>
					<button className='btn btn-primary btn-sm icon-left mr-1' onClick={() => setShowx20Orgs(!showX20Orgs)}><SearchIcon /> {showX20Orgs ? 'Hide' : 'Show'} X20 Orgs ({ministryInfoState.leverageSummary.filter(r => r.bmfOrganizationID !== ministryInfoState.ministry?.ministryId && typeof r.nteeCode === 'string' && r.nteeCode.toLowerCase().includes('x20')).length})</button>
					<button className='btn btn-primary btn-sm icon-left mr-1' onClick={() => {
						const rows = [...gridState.rows]
						rows.forEach(row => row.selected = false)
						gridActions.resetFiltersSortsAndRowSelections(['x20-filter'])
						setShowx20Orgs(false)
						setBaselineYear(maxBaselineYear)
					}}><ArrowRepeatIcon /> Reset</button>
				</div>

				<Grid state={gridState} actions={gridActions} style={{ height: 'calc(100% - 340px)' }} />
			</div>

			<Modal
				modalId={excludeOrphanConfirmModalId}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button
							type='button'
							className='btn btn-danger'
							onClick={async () => {
								appActions.addAlert({ id: uuidv4(), title: 'NOT YET IMPLEMENTED', body: 'TODO - IMPLEMENT THIS ACTION ONCE API ENDPOINT READY' })
							}}
						>
							Exclude
						</button>
					</React.Fragment>
				}
			>
				Are you sure you want to exclude this orphan?

				<p>{selectedRow?.values.ministryName}</p>
			</Modal>
		</React.Fragment>
	)
}