import { Formik, Form } from 'formik'
import { useContext } from 'react'
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper, useModal } from '../services/hooks'
import { EventStatus } from './event-form'
import { CheckboxField, TinyMceField } from './forms'
import { Modal } from './modal'
import { IEventModelDocument, IGroupCoachingModelDocument } from '../open-api'

interface IEventFormCancelProps {
    modalProps: ReturnType<typeof useModal>[0]
    setEventToEdit: any
    eventToEdit?: IEventModelDocument | IGroupCoachingModelDocument
}

export const EventFormCancel = ({ modalProps, setEventToEdit, eventToEdit }: IEventFormCancelProps) => {

    const { EventMgmtApi } = useContext(AppActionContext)!
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    //const { setEventToEdit, eventToEdit } = useEventFormContext()

    return (
        <Formik
            initialValues={{
                sendCancellationEmail: false,
                emailContent: '',
            }}
            onSubmit={async (values) => {
                if (!eventToEdit) return
                await makeHttpRequestWithUi({
                    request: EventMgmtApi.apiEventMgmtEventEventIdCancelPut(eventToEdit.eventId),
                    toastSuccessMessage: 'Successfully canceled event.',
                    toastErrorMessage: 'Encountered an error canceling event.'
                })

                if (values.sendCancellationEmail)
                    await makeHttpRequestWithUi({
                        request: EventMgmtApi.apiEventMgmtEventEventIdSendCancellationNotificationsPost(eventToEdit.eventId, { reasonMessage: values.emailContent })
                    })

                modalProps.closeModal()
                setEventToEdit({ ...eventToEdit, status: EventStatus["canceled"] })
            }}
        >
            {formikProps => (
                <Modal
                    {...modalProps}
                    modalTitle='Cancel Event'
                    footer={<button className='btn btn-danger' onClick={formikProps.submitForm}>Cancel Event</button>}
                >
                     <>
                        <div>Are you sure you want to cancel the event? This action cannot be undone.</div>
                        <Form>
                            <CheckboxField fieldProps={{ name: 'sendCancellationEmail', label: 'Send a cancellation email to all registrants?' }} />
                            {formikProps.values.sendCancellationEmail && <TinyMceField fieldProps={{ name: 'emailContent', label: 'Reason Message (optional)' }} />}
                        </Form>
                     </>
                </Modal>
            )}
        </Formik >
    )
}