import React, { useEffect } from 'react'
import { IMailBlastRecipientDocument } from '../open-api'
import { sortListBySorts, filterGridListItems } from '../services/helpers'
import { useModal } from '../services/hooks'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { DefaultGridCellDisplay } from './grid-cell-displays'
import { Modal } from './modal'


interface ICustomEmailBlastFormRecipients {
	recipients: IMailBlastRecipientDocument[]
	setRecipients: (recipients: IMailBlastRecipientDocument[]) => void
}

export const CustomEmailBlastFormRecipients = (props: ICustomEmailBlastFormRecipients) => {
	const { recipients, setRecipients } = props

	const dataSource: GridDataFetch<IMailBlastRecipientDocument[]> = async (queryState, _recipients) => {
		if (!_recipients) return { rows: [], count: 0 }

		if (queryState.sorts) sortListBySorts(_recipients, queryState.sorts)

		let rows: IGridListItem[] = _recipients.map(o => ({ id: o.email, values: { email: o.email } }))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const [confirmRemoveRecipientsModal, showHideConfirmRemoveRecipientsModal] = useModal()

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: true,
		gridActions: [
			{
				id: 'removeRecipients',
				label: 'Remove Recipient(s)',
				onClick: () => showHideConfirmRemoveRecipientsModal(true),
				disabled: (gridState) => gridState.rows.filter(r => r.selected).length === 0,
			}
		],
		columns: [
			{
				property: 'email',
				type: 'string',
				width: 850,
				allowFilters: true,
				title: 'Email',
				render: DefaultGridCellDisplay
			},
		],
		disabledPagination: true,
		hideGridFooter: false,
		dataSource,
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, recipients)

	useEffect(() => {
		gridActions.doFetch()
		//esline-disable-next-line
	}, [recipients])

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%', flex: '1' }} />

			<Modal
				{...confirmRemoveRecipientsModal}
				modalTitle='Confirm'
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' onClick={() => showHideConfirmRemoveRecipientsModal(false)}>Cancel</button>
						<button
							type='button'
							className='btn btn-warning'
							onClick={() => {
								showHideConfirmRemoveRecipientsModal(false)
								const removedRecipients = gridState.rows.filter(o => o.selected).map(o => o.values.email) as string[]
								setRecipients(recipients.filter(o => !removedRecipients.includes(o.email)))
							}}
						>
							Remove
						</button>
					</React.Fragment>
				}
			>
				Are you sure you want to remove the selected recipients?
			</Modal>
		</React.Fragment>
	)
}