import React, { useContext, useEffect, useRef } from 'react'
import { usePrevious } from 'react-use'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Path } from './dashboard'
import { MainNavItemCell } from './main-nav-item-cell'
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg'
import { MainNavItemExpandableCell } from './main-nav-item-expandable-cell'
import { ManNavExpandable } from './main-nav'

interface IMainNavItemExpandable {
    icon: JSX.Element
    title: string
    mainNavKey: ManNavExpandable

    // You can pass in either straight children or a list of items
    content: {
        items?: {
            path: Path
            icon: JSX.Element
            title: string
        }[]
        children?: JSX.Element[]
    }
}

export const MainNavItemExpandable = (props: IMainNavItemExpandable) => {
    const { mainNavKey, content, title } = props
    const { items, children } = content

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!

    const ref = useRef<HTMLDivElement>(null)

    const previousCurrentUserSessionState = usePrevious(appState.currentUserSessionState)
    const previouslyExpandedSubMenus: { [key: string]: boolean } = { ...previousCurrentUserSessionState?.expandedSubMenus }
    const currentlyExpandedSubMenus: { [key: string]: boolean } = { ...appState.currentUserSessionState?.expandedSubMenus }
    useEffect(() => {
        if (currentlyExpandedSubMenus[mainNavKey] && ref.current) ref.current.style.height = `${ref.current.scrollHeight}px`
        if (!previouslyExpandedSubMenus[mainNavKey] && currentlyExpandedSubMenus[mainNavKey] && ref.current) {
            // Expand
            ref.current.style.height = `${ref.current.scrollHeight}px`
        } else if (previouslyExpandedSubMenus[mainNavKey] && !currentlyExpandedSubMenus[mainNavKey] && ref.current) {
            // Collapse
            ref.current.style.height = '0'
        }
    }, [appState.currentUserSessionState, content])

    return (
        <React.Fragment>
            <MainNavItemCell
                {...props}
                onClick={() => appActions.UserSessionActions.toggleMainNavMenuExpandCollapse(mainNavKey)}
                title={
                    <React.Fragment>
                        <span className='title' style={{ width: '100%' }}>{title}</span>
                        <ChevronRight style={{ transform: `rotate(${currentlyExpandedSubMenus[mainNavKey] ? '90' : '180'}deg)` }} />
                    </React.Fragment>
                }
            />

            <div className='expandable' ref={ref}>
                {children ? children : null}

                {items ?
                    items.map(item => <MainNavItemExpandableCell key={item.path} active={appState.mainNav.selectedItem === item.path} title={item.title} icon={item.icon} onClick={() => appActions.mainNavItemPressed(item.path)} />)
                    :
                    null
                }
            </div>
        </React.Fragment>
    )
}