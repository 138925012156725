// 20231002 TB - This has been deprecated.

import React, { useCallback, useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { IDefaultProps } from "./component-definitions"
import { PencilIcon, PeopleIcon, Signpost2Icon, LayersIcon } from '../assets/index'
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { IGroupCoachingModelDocument, IGroupCoachingTopicResultDocument, IPresentersDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, GridUserInteractionStateKey, IGridAction, IGridListItem, IGridState } from "../stores/grid-definitions"
import { gridReducer } from "../stores/grid-reducer"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { SquareDeleteIcon } from "./partials"
import { Grid } from "./grid"
import { Modal } from "./modal"
import { IAppState } from "../stores/app-definitions"
import { GroupCoachingForm } from './group-coaching-form'
import { Path } from "./dashboard"
import { navigate, useLocation } from "@reach/router"
import dayjs from "dayjs"
import { IFilter } from "../stores/api-actions"
import { ISelectFieldOption } from "./forms"
import { locationHashToObject } from "../services/helpers"

export enum IGroupCoachingEventStatus {
    'canceled' = 'canceled',
    'activated' = 'activated',
    'draft' = 'draft',
    'deleted' = 'deleted',
}

interface IGroupCoachingState {
    topicTypes: { id: number, name: string }[]
    topics: IGroupCoachingTopicResultDocument[]
    presenters: IPresentersDocument[]
    selectedTopicType?: string
}

interface IGroupCoachingProps extends IDefaultProps { }
export const GroupCoaching = ({ }: IGroupCoachingProps) => {

    const { GroupCoachingApi, GroupCoachingTopicApi, PresentersApi } = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [state, setState] = useState<IGroupCoachingState>({
        topicTypes: [],
        topics: [],
        presenters: [],
    })

    const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        try {

            const query = await makeHttpRequestWithUi({
                request: GroupCoachingApi.apiGroupCoachingGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of group learning events.'
            })

            const rows = (query.data.data || []).map<IGridListItem>(item => ({
                id: item.eventContentId.toString(),
                values: {
                    ...item,
                    startTime: new Date(item.startTime),
                    groupCoachingTopicTypeId: item.groupCoachingTopicType,
                }
            }))

            return {
                rows,
                count: query.data.totalCount
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const [eventFormModal, showHideEventFormModal] = useModal()
    const [confirmDeleteModal, showHideConfirmDeleteModal] = useModal()
    const [confirmDuplicateModal, showHideConfirmDuplicateModal] = useModal()

    const [eventToEdit, setEventToEdit] = useState<IGroupCoachingModelDocument>()
    const [initialTab, setInitialTab] = useState<'summary' | 'registrants' | undefined>()

    const editEvent = useCallback(async (eventId: string) => {
        // const sessionToEditQuery = await makeHttpRequestWithUi({
        //     request: GroupCoachingApi.apiGroupCoachingEventContentIdGet(parseInt(eventId)),
        //     disableSuccessToast: true,
        //     toastErrorMessage: 'There was an error retrieving the event to edit.'
        // })

        // setEventToEdit(sessionToEditQuery.data)

        showHideEventFormModal(true)
    }, [])

    // Support linking to a particular event by appending "#view=EVENT_ID" to /group-learning URL
    const location = useLocation()
    useEffect(() => {
        const parsedHash = locationHashToObject(location.hash)
        if (parsedHash.view) editEvent(parsedHash.view)
    }, [location.hash])

    const historyFilter: IFilter = {
        id: 'history-filter',
        isDateFilter: true,
        value: dayjs().add(-1, 'day').format('MM/DD/YYYY'),
        operator: 'gt',
        property: 'startTime',
        enabled: true,
    }

    const showHistoryUpcomingToggle: IGridAction = {
        id: 'showHistoryUpcomingToggle',
        label: '',
        dropdown: (state, actions) => {
            const value = state.queryState.filters?.find(o => o.id === historyFilter.id)?.operator === 'gt' ? 'upcoming' : 'history'
            return {
                options: [
                    { label: 'Show History', value: 'history' },
                    { label: 'Show Upcoming', value: 'upcoming' },
                ],
                onChange: (option: ISelectFieldOption | ISelectFieldOption[] | null) => {
                    const _filters = [...state.queryState.filters || []]
                    const _historyFilter = _filters.find(o => o.id === historyFilter.id)
                    if (_historyFilter) {
                        // Go ahead and set the value to today on every click, just in case someone has left their browser window open or we decide to start persisting this grid state for users
                        _historyFilter.value = dayjs().format('MM/DD/YYYY')

                        // Toggle between before and after today
                        if (_historyFilter.operator === 'lt') {
                            _historyFilter.operator = 'gt'
                            _historyFilter.value = dayjs().add(-1, 'day').format('MM/DD/YYYY')
                        } else {
                            _historyFilter.operator = 'lt'
                        }

                        // Splice the updated filter in
                        const historyFilterIdx = _filters.findIndex(o => o.id = _historyFilter.id)
                        _filters.splice(historyFilterIdx, 1, _historyFilter)

                        actions.updateColumnFilters(_filters)
                    }
                },
                value,
            }
        },
        sortOrder: 2,
    }



    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.GroupCoaching,
        overrideInitialStateValues: {
            queryState: {
                ...defaultGridState.queryState,
                filters: [historyFilter],
            },
        },
        gridActions: [
            {
                id: 'addEvent',
                label: 'Add Event',
                onClick: () => showHideEventFormModal(true),
                sortOrder: 0,
            },
            showHistoryUpcomingToggle,
            ...appState.currentUser?.bSuperUser ? [{
                id: 'topics',
                label: 'Group Learning Topics',
                onClick: () => navigate(`/${Path["group-learning-topics"]}`),
                sortOrder: 1,
            }] as IGridAction[] : []
        ],
        rowActions: {
            editEvent: {
                id: 'editEvent',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    setInitialTab('summary')
                    editEvent(row.id)
                },
                tooltipText: 'View Event',
                icon: <PencilIcon />,
            },
            viewRecipients: {
                id: 'viewRecipients',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    setInitialTab('registrants')
                    editEvent(row.id)
                },
                icon: <PeopleIcon />,
                tooltipText: 'Registrants',
            },
            deleteEvent: {
                id: 'deleteEvent',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideConfirmDeleteModal(true)
                },
                tooltipText: 'Delete Event',
                icon: <SquareDeleteIcon />,
                disabled: (row) => row.values.status !== IGroupCoachingEventStatus.draft
            },
            duplicateEvent: {
                id: 'duplicateEvent',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHideConfirmDuplicateModal(true)
                },
                tooltipText: 'Duplicate Event',
                icon: <div style={{ paddingBottom: 5 }}><LayersIcon /></div>
            }
        },
        columns: [
            {
                property: 'eventContentTitle',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Title',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'groupCoachingTopicTypeId',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Topic Type',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'startTime',
                type: 'date',
                width: 150,
                allowFilters: true,
                title: 'Event Date',
                render: DefaultGridCellDisplay,
                dateFormatOverride: 'MM/DD/YYYY hh:mm a',
            },
            {
                property: 'branchAbbr',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Community',
                render: DefaultGridCellDisplay,
                filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: `${b.branchAbbr}` })),
            },
            {
                property: 'siteName',
                type: 'string',
                width: 120,
                allowFilters: true,
                title: 'Site',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'status',
                type: 'string',
                width: 120,
                allowFilters: true,
                disableSort: true,
                title: 'Status',
                align: 'center',
                render: DefaultGridCellDisplay,
                conditionalCellCSSFormatting: (row) => {
                    if (row.values.status === IGroupCoachingEventStatus.activated) return 'bg-success-7'
                    if (row.values.status === IGroupCoachingEventStatus.canceled) return 'bg-warning-7'
                    if (row.values.status === IGroupCoachingEventStatus.deleted) return 'bg-danger-7'
                    if (row.values.status === IGroupCoachingEventStatus.draft) return 'bg-secondary-7'
                    return ''
                },
                filterOptions: Object.keys(IGroupCoachingEventStatus).map(o => ({ value: o, label: o }))
            },
            {
                property: 'orgsRegisteredCount',
                type: 'number',
                width: 90,
                allowFilters: false,
                title: 'Orgs Registered',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'orgsAttendedCount',
                type: 'number',
                width: 90,
                allowFilters: false,
                title: 'Orgs Attended',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'durationHours',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Hours',
                description: 'Hours logged for this appointment.',
                render: DefaultGridCellDisplay,
                rowGroupTotal: true
            },
            {
                property: 'registrantCount',
                type: 'number',
                width: 90,
                allowFilters: false,
                title: 'Registered',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'attendeeCount',
                type: 'number',
                width: 90,
                allowFilters: false,
                title: 'Attended',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: true,
        respectGlobalCommunityFilter: true,
        disableExport: false,
        infinitePaging: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            setInitialTab('summary')
            editEvent(row.id)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, state)

    useEffect(() => {
        // Refresh the selected event if data changes
        if (eventToEdit) editEvent(eventToEdit.eventContentId.toString())
    }, [gridState.rows])

    useEffect(() => {
        GroupCoachingTopicApi.apiGroupCoachingTopicGet()
            .then(results => {
                setState(_state => ({ ..._state, topics: results.data }))
            })

        makeHttpRequestWithUi({
            request: GroupCoachingTopicApi.groupCoachingTopicTypesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Encountered an error retrieving group learning topic types.'
        })
            .then(results => {
                setState(_state => ({ ..._state, topicTypes: results.data as { id: number, name: string }[] }))
            })

        makeHttpRequestWithUi({
            request: PresentersApi.apiPresentersGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the list of presenters.'
        })
            .then(results => {
                setState(_state => ({ ..._state, presenters: results.data }))
            })
    }, [])

    useEffect(() => {
        const topicTypeColumn = gridState.columns.find(o => o.property === 'groupCoachingTopicTypeId')
        if (topicTypeColumn && state.topicTypes.length !== topicTypeColumn?.filterOptions?.length) {
            gridActions.updateColumn({
                ...topicTypeColumn,
                filterOptions: state.topicTypes.map(o => ({ value: o.id.toString(), label: o.name }))
            })
        }
    }, [state.topicTypes, gridState, gridActions])


    if (!state.topicTypes.length || !state.topics.length) return null

    return (
        <>
            <Helmet>
                <title>Group Learning</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <div className='m-2 d-flex align-items-center'>
                    <Signpost2Icon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Group Learning</h3>
                </div>

                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...eventFormModal}
                modalTitle={!!eventToEdit ?
                    <div>
                        {/* @ts-ignore */}
                        Edit {state.selectedTopicType || eventToEdit.groupCoachingTopic.groupCoachingTopicType.name || ''} Event 
                        {eventToEdit.startDateTime && <span>&nbsp;-&nbsp;{dayjs(eventToEdit.startDateTime?.toString()).format('M/D/YYYY')}</span>}
                        &nbsp;({eventToEdit.eventId})&nbsp;
                        {eventToEdit.status === IGroupCoachingEventStatus.canceled ? <span className='badge badge-warning'>Canceled</span> : null}
                        {eventToEdit.status === IGroupCoachingEventStatus.activated ? <span className='badge badge-success'>Activated</span> : null}
                        {eventToEdit.status === IGroupCoachingEventStatus.draft ? <span className='badge badge-info'>Draft</span> : null}
                    </div>
                    :
                    `New ${state.selectedTopicType || ''} Event`
                }
                size='xxl'
                minHeightPercent={100}

                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setEventToEdit(undefined)
                    setState(_state => ({ ..._state, selectedTopicType: undefined }))
                }}

                dismissible={false}
            >
                {eventFormModal.show && state &&
                    <GroupCoachingForm
                        topicTypes={state.topicTypes}
                        topics={state.topics}
                        eventToEdit={eventToEdit}
                        presenters={state.presenters}
                        setEventToEdit={setEventToEdit}
                        afterSave={async () => await gridActions.doFetch()}
                        closeModal={() => showHideEventFormModal(false)}
                        //initialTab={initialTab}
                        setSelectedTopicType={selectedTopicType => setState(_state => ({ ..._state, selectedTopicType }))}
                    />
                }
            </Modal>

            <Modal
                {...confirmDeleteModal}
                modalTitle='Confirm Delete'
                footer={
                    <React.Fragment>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmDeleteModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-danger'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: GroupCoachingApi.apiGroupCoachingEventContentIdDelete(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully deleted group learning event.',
                                        toastErrorMessage: 'Encountered an error deleting group learning event.',
                                    })
                                    showHideConfirmDeleteModal(false)
                                    gridActions.doFetch()
                                }
                            }}
                        >
                            Delete
                        </button>
                    </React.Fragment>
                }
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
            >
                <p>Are you sure you want to delete this group learning event?</p>
            </Modal>

            <Modal
                {...confirmDuplicateModal}
                modalTitle='Confirm Duplicate'
                footer={
                    <React.Fragment>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmDuplicateModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (selectedRow) {
                                    await makeHttpRequestWithUi({
                                        request: GroupCoachingApi.apiGroupCoachingEventContentIdDuplicatePost(parseInt(selectedRow.id)),
                                        toastSuccessMessage: 'Successfully duplicated group learning event. Look for new event with tite that begins with "COPY..."',
                                        toastErrorMessage: 'Encountered an error duplicating group learning event.',
                                    })
                                    showHideConfirmDuplicateModal(false)
                                    gridActions.doFetch()
                                }
                            }}
                        >
                            Duplicate
                        </button>
                    </React.Fragment>
                }
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
            >
                <p>Are you sure you want to duplicate this group learning event? It will create a new event that is identical in almost every way.</p>
            </Modal>
        </>
    )
}