import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalLeadershipProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalLeadership = (props: IMinistryInfoAreaDirectorEvalLeadershipProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
        <Formik
            initialValues={{
                bExperience: areaDirectorEval.ministryEvalState.bExperience,
                bRelationship: areaDirectorEval.ministryEvalState.bRelationship,
                bEvaluates: areaDirectorEval.ministryEvalState.bEvaluates,
                bMeets: areaDirectorEval.ministryEvalState.bMeets,
                bCompetent: areaDirectorEval.ministryEvalState.bCompetent,
                bCompensation: areaDirectorEval.ministryEvalState.bCompensation,
                bConfident: areaDirectorEval.ministryEvalState.bConfident,
                bTurnover: areaDirectorEval.ministryEvalState.bTurnover,
                leadership: areaDirectorEval.ministryEvalState.leadership || '',
                tabDirection: null
            }}
            onSubmit={async (values) => {
                const evalRecord: IMinistryEvalsPutDocument = {
                    ...areaDirectorEval.ministryEvalState,
                    bExperience: values.bExperience,
                    bRelationship: values.bRelationship,
                    bEvaluates: values.bEvaluates,
                    bMeets: values.bMeets,
                    bCompetent: values.bCompetent,
                    bCompensation: values.bCompensation,
                    bConfident: values.bConfident,
                    bTurnover: values.bTurnover,
                    leadership: values.leadership,
                    dStep3Updated: moment().format(),
                    step3UpdatedBy: appState.currentUser?.userId || null
                }
                await makeHTTPRequestWithUi({
                    request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
                    toastSuccessMessage: 'Eval record successfully updated.',
                    toastErrorMessage: 'There was an error updating the eval record.',
                })
                
                if (values.tabDirection === 'previous' && afterSavePrevious) {
                    afterSavePrevious()
                } else if (afterSaveNext) {
                    afterSaveNext()
                }
            }}
        >
            {(formikProps) => {
                const form = <React.Fragment>
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bExperience', 
                            label: 'The executive director has the necessary experience to lead the organization toward its purpose' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bRelationship', 
                            label: 'The relationship between the board and the executive director is considered professional and defined' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bEvaluates', 
                            label: 'The board annually evaluates the performance of the executive director' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bMeets', 
                            label: 'The executive director regularly meets with his/her staff or leadership' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bCompetent', 
                            label: 'The executive director has a competent staff that can implement the organization\'s plan' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bCompensation', 
                            label: 'Compensation for the executive director and staff are considered appropriate' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bConfident', 
                            label: 'The executive director is confident that he/she was called to serve in this capacity in this organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bTurnover', 
                            label: 'There has not been significant employee turnover in the past year' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    { !readonly ?
                        <TextareaField
                            fieldProps={{
                                name: 'leadership',
                                label: 'Additional Notes on Leadership'
                            }} />
                        :
                        null
                    }
                    { !readonly ?
                        <div className='row px-3'>
                            <button className={`btn btn-primary`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'previous')
                                formikProps.submitForm()
                            }}>Save &amp; Previous</button>
                            <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'next')
                                formikProps.submitForm()
                            }}>Save &amp; Continue</button>
                        </div>
                        :
                        null
                    }
                </React.Fragment>
                // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                return (
                    readonly ? form :
                    <Form>
                        {form}
                    </Form>
                )
            }}
        </Formik>
    )
}