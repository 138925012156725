import { IModelDocumentBase, ModelBase } from './model-base'
import { IGridListItem, IGridColumn } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, EmailCellDisplay, EventDisplay, GridActionCell, MinistryContactNameDisplay, MinistryNameDisplay } from '../components/grid-cell-displays'
import { ILmsEnrolledCourseSearchResultModelDocument } from '../open-api'
import { AppStateContext } from '../app-store-provider'
import { useContext } from 'react'

export interface ILmsCourseEnrollmentDocument extends IModelDocumentBase {
	lmsCourseEnrollmentId: number
	ministryContactId: number
	email: string
	ministryId: number
	ministryName: string
	firstName: string
	lastName: string
	groupTitle: string
	groupSlug: string
	communityId: number
	branchAbbr: string
	courseId: number
	eventId: number
	eventTitle: string
	inviteSentDate: Date | null
	activatedDate: Date | null
	createdDate: Date | null
	updatedDate: Date | null
	deletedDate: Date | null
	createdBy: number
	updatedBy: number
	deletedBy: number
}

export interface ILmsCourseEnrollment extends ILmsCourseEnrollmentDocument {

}

export class LmsCourseEnrollmentModel extends ModelBase implements ILmsCourseEnrollment {
	lmsCourseEnrollmentId: number
	ministryContactId: number
	email: string
	ministryId: number
	ministryName: string
	firstName: string
	lastName: string
	groupTitle: string
	groupSlug: string
	communityId: number
	branchAbbr: string
	courseId: number
	eventId: number
	eventTitle: string
	inviteSentDate: Date | null
	activatedDate: Date | null
	createdDate: Date | null
	updatedDate: Date | null
	deletedDate: Date | null
	createdBy: number
	updatedBy: number
	deletedBy: number

	constructor(doc: ILmsCourseEnrollmentDocument) {
		super(doc)

		this.lmsCourseEnrollmentId = doc.lmsCourseEnrollmentId
		this.ministryContactId = doc.ministryContactId
		this.email = doc.email
		this.ministryId = doc.ministryId
		this.ministryName = doc.ministryName
		this.firstName = doc.firstName
		this.lastName = doc.lastName
		this.groupTitle = doc.groupTitle
		this.groupSlug = doc.groupSlug
		this.communityId = doc.communityId
		this.branchAbbr = doc.branchAbbr
		this.courseId = doc.courseId
		this.eventId = doc.eventId
		this.eventTitle = doc.eventTitle

		this.inviteSentDate = doc.inviteSentDate ? new Date(doc.inviteSentDate) : null
		this.activatedDate = doc.activatedDate ? new Date(doc.activatedDate) : null
		this.createdDate = doc.createdDate ? new Date(doc.createdDate) : null
		this.updatedDate = doc.updatedDate ? new Date(doc.updatedDate) : null
		this.deletedDate = doc.deletedDate ? new Date(doc.deletedDate) : null

		this.createdBy = doc.createdBy
		this.updatedBy = doc.updatedBy
		this.deletedBy = doc.deletedBy
	}

	public static toGridListItem = (course: ILmsEnrolledCourseSearchResultModelDocument): IGridListItem => {
		return {
			id: course.lmsCourseEnrollmentId.toString(),
			values: {
				lmsCourseEnrollmentId: course.lmsCourseEnrollmentId,
				ministryContactId: course.ministryContactId,
				email: course.email,
				ministryId: course.ministryId,
				ministryName: course.ministryName,
				firstName: course.firstName,
				lastName: course.lastName,
				groupTitle: course.groupTitle,
				branchAbbr: course.branchAbbr,
				courseId: course.courseId,
				eventId: course.eventId,
				eventTitle: course.eventTitle,
				inviteSentDate: course.inviteSentDate,
				activatedDate: course.activatedDate,
				createdDate: course.createdDate,
				updatedDate: course.updatedDate,
				deletedDate: course.deletedDate,
				createdBy: course.createdBy,
				updatedBy: course.updatedBy,
				deletedBy: course.deletedBy,
				fullName: course.fullName,
				isInviteSent: course.inviteSentDate ? 'Yes' : 'No',
			}
		}
	}
}

export const factory = (doc: ILmsCourseEnrollmentDocument): LmsCourseEnrollmentModel => {
	return new LmsCourseEnrollmentModel(doc)
}

export const useLmsCourseEnrollmentDefaultColumns = (): IGridColumn[] => {
	const appState = useContext(AppStateContext)!
	
	return [
		{
			property: 'lmsCourseEnrollmentId',
			type: 'string',
			width: 147,
			hide: true,
			allowFilters: true,
			title: 'Course Enrollment ID',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'fullName',
			type: 'string',
			width: 184,
			allowFilters: true,
			title: 'Name',
			render: MinistryContactNameDisplay,
		},
		{
			property: 'ministryName',
			type: 'string',
			width: 184,
			allowFilters: true,
			title: 'Ministry',
			render: MinistryNameDisplay,
		},
		{
			property: 'email',
			type: 'string',
			width: 184,
			allowFilters: true,
			title: 'Email',
			render: EmailCellDisplay,
		},
		{
			property: 'eventId',
			type: 'string',
			width: 100,
			hide: true,
			allowFilters: true,
			title: 'Event Id',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'eventTitle',
			type: 'string',
			width: 120,
			allowFilters: true,
			title: 'Event',
			render: EventDisplay,
		},
		{
			property: 'groupTitle',
			type: 'string',
			width: 219,
			allowFilters: true,
			title: 'Group Name',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'branchAbbr',
			type: 'string',
			width: 120,
			allowFilters: true,
			filterOptions: appState.activeBranches.map(b => ({ label: `${b.branchAbbr && b.branchAbbr.trim()} (${b.branchName})`, value: b.branchAbbr || '' })),
			title: 'Community',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'isInviteSent',
			type: 'string',
			width: 120,
			allowFilters: true,
			filterOptions: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }],
			disableSort: true,
			title: 'Invite Sent',
			render: DefaultGridCellDisplay,
			hide: true
		},
		{
			property: 'inviteSentDate',
			type: 'date',
			width: 140,
			allowFilters: true,
			title: 'Invite Sent Date',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'createdDate',
			type: 'date',
			width: 140,
			allowFilters: true,
			title: 'Created',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'activatedDate',
			type: 'date',
			width: 140,
			allowFilters: true,
			title: 'Activated',
			render: DefaultGridCellDisplay,
		},
		{
			property: 'grid_actions',
			type: 'actions',
			width: 150,
			disableSort: true,
			title: 'Actions',
			render: GridActionCell,
		}
	] 
}
