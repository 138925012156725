import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider"
import { IGrantRequestSummaryDocument, IGrantStipulationModelDocument, IMinistryGrantStipulationModelDocument } from "../open-api"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { SquareDeleteIcon } from "./partials"
import { PencilIcon } from "../assets"
import { Modal } from "./modal"
import { Grid } from "./grid"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { GridDataFetch, IGridListItem, IGridState } from "../stores/grid-definitions"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { gridReducer } from "../stores/grid-reducer"
import { GrantRequestStipulationForm } from "./grant-request-stipulation-form"
import { GRANT_REQUEST_STATUS } from "../constants"

interface IGrantRequestStipulationsProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
    stipulations: IMinistryGrantStipulationModelDocument[]
    afterSave?: () => Promise<void>
}

export const GrantRequestStipulations = (props: IGrantRequestStipulationsProps) => {
    const { afterSave, grantRequestToEdit, stipulations } = props
    const { GrantRequestApi } = useContext(AppActionContext)!

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [stipulationToEdit, setStipulationToEdit] = useState<IMinistryGrantStipulationModelDocument>()
    const [templates, setTemplates] = useState<IGrantStipulationModelDocument[]>([])
    const [stipulationFormModal, showHideStipulationFormModal] = useModal()
    const [deleteStipulationModal, showHidDeleteStipulationModal] = useModal()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchTemplates = async () => {
        const {data} = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestStipulationTemplatesGet(),
            toastErrorMessage: 'There was an error retrieving the stipulation templates.',
            disableSuccessToast: true,
            disableLoading: true,
        })

        setTemplates(data)
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    const readonly = grantRequestToEdit.isLegacyGrant ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Complete"] || 
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Grant Denied"] ||
                     grantRequestToEdit.grantRequestStatusId == GRANT_REQUEST_STATUS["Request Tabled"]

    const dataSource: GridDataFetch<typeof stipulations> = async (_queryState, _evals) => {
        try {
            if (!_evals) return { rows: [], count: 0 }

            if (_queryState.sorts) sortListBySorts(_evals, _queryState.sorts)
            let rows: IGridListItem[] = _evals.map(o => ({
                id: o.grantStipulationId!.toString(),
                values: {
                    ...o,
                }
            }))

            if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters)

            return {
                rows,
                count: rows.length
            }
        } catch (e) {
            console.log('GrantRequestStipulations > Exception thrown:', e)
            return {
                rows: [],
                count: 0
            }
        }
    }

    const editStipulation = useCallback(async (grantStipulationId: string) => {
        const editQuery = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestStipulationsGrantStipulationIdGet(parseInt(grantStipulationId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setStipulationToEdit(editQuery.data)

        showHideStipulationFormModal(true)
    }, [])

    const initialGridState: IGridState = {
        ...defaultGridState,
        gridActions: [
            {
                id: 'addStipulations',
                label: 'Add Stipulations',
                onClick: () => showHideStipulationFormModal(true),
                sortOrder: 0,
                disabled: readonly
            }
        ],
        rowActions: {
            editStipulation: {
                id: 'editStipulation',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    editStipulation(row.id)
                },
                tooltipText: 'Edit Stipulation',
                icon: <PencilIcon />,
                disabled: readonly
            },
            deleteStipulation: {
                id: 'deleteStipulation',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    showHidDeleteStipulationModal()
                },
                tooltipText: 'Delete Stipulation',
                icon: <SquareDeleteIcon />,
                disabled: readonly
            },
        },
        columns: [
            {
                property: 'stipulation',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Stipulation',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            }

        ],
        dataSource,
        rowSelectEnabled: false,
        usingLocalData: true,
        disabledPagination: true,
        disableExport: true,
        respectGlobalCommunityFilter: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            editStipulation(row.id)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, stipulations ?? [])

    useEffect(() => {
        gridActions.doFetch()
    }, [stipulations])

    return (
        <>
            <div style={{ paddingTop: 10, paddingRight: 10, position: 'relative' }}>
                <Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
            </div>
            <Modal
                {...stipulationFormModal}
                modalTitle={!!stipulationToEdit ? `Edit Stipulation (${stipulationToEdit.grantStipulationId})` : 'Add Stipulation'}
                size='md'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    //setStipulationToEdit(undefined)
                }}
                dismissible={false}
                style={{"zIndex": 1051}}
            >
               <GrantRequestStipulationForm 
                    stipulationToEdit={stipulationToEdit} 
                    templates={templates}
                    grantId={grantRequestToEdit.grantId}
                    afterSave={async () => {
                        showHideStipulationFormModal(false)
                        afterSave && afterSave()
                    }}  />
            </Modal>
            <Modal
				{...deleteStipulationModal}
				modalTitle='Delete Stipulation'
				_onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<>
						<button type='button' className='btn btn-secondary'>No</button>
						<button
							type='button'
							className='btn btn-warning'
							onClick={async () => {
								if (selectedRow) {
									await makeHttpRequestWithUi({
										request: GrantRequestApi.apiGrantRequestStipulationsGrantStipulationIdDelete(parseInt(selectedRow.id)),
										toastSuccessMessage: 'Successfully deleted stipulation.',
										toastErrorMessage: 'Encountered an error deleting stipulation',
									})
									showHidDeleteStipulationModal(false)
									gridActions.doFetch()
								}
							}}
						>
							Yes
						</button>
					</>
				}
			>
				<p>Are you sure you would like to delete this stipulation?</p>
			</Modal>
        </>
    )
}