import React, { useContext, useEffect } from 'react'
import { Formik, Form, FormikProps } from 'formik'
import * as Yup from 'yup'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { TagType } from '../models/tag'
import { TextField, SelectField } from './forms'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { ITagsDocument } from '../open-api'
import { isNullOrUndefined } from 'util'

interface ITagFormProps {
	setFormikProps?: (formikProps: FormikProps<any> | null) => void
	onTagSaved?: (tag: ITagsDocument) => void
	onSaveCancel?: () => void
	tagName?: string
	className?: string
	tagType?: TagType
}
export const TagForm = (props: ITagFormProps) => {
	const { setFormikProps, onTagSaved, tagName, className, onSaveCancel, tagType } = props

	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	let _formikProps: FormikProps<any>

	useEffect(() => {
		if (tagName && _formikProps) _formikProps.setFieldValue('tagName', tagName)
		// eslint-disable-next-line
	}, [tagName])

	useEffect(() => {
		if (tagType && _formikProps) _formikProps.setFieldValue('tagType', tagType)
		// eslint-disable-next-line
	}, [tagType])

	useEffect(() => {
		if (appState.globalCommunityContext && _formikProps) _formikProps.setFieldValue('branchId', appState.globalCommunityContext.branchId)
		// eslint-disable-next-line
	}, [appState.globalCommunityContext?.branchId])

	useEffect(() => {
		return function cleanup() {
			if (setFormikProps) setFormikProps(null)
		}
		// eslint-disable-next-line
	}, [])


	return (
		<Formik
			initialValues={{
				tagName: '',
				tagType: tagType || 'MINISTRYID',
				branchId: (appState.globalCommunityContext && appState.globalCommunityContext.branchId) || ''
			}}
			validationSchema={Yup.object({
				tagName: Yup.string().required('Required'),
				tagType: Yup.string().required('Required'),
			})}
			onSubmit={async (values, formikActions) => {
				const loadingId = registerLoadingTask()

				let tagType: TagType = 'MINISTRYID'
				switch (values.tagType) {
					case 'MINISTRYID':
						tagType = 'MINISTRYID'
						break
					case 'MINISTRYCONTACTID':
						tagType = 'MINISTRYCONTACTID'
						break
					case 'PROSPECTID':
						tagType = 'PROSPECTID'
						break
				}

				// eslint-disable-next-line
				const createTagQuery = await appActions.TagsApi.apiTagsPost({ tag: values.tagName, tableKey: tagType, branchId: Number(values.branchId) })
				await appActions.fetchTagsSummary()
				deregisterLoadingTask(loadingId)
				if (onTagSaved) onTagSaved(createTagQuery.data)
			}}
		>
			{formikProps => {
				_formikProps = formikProps
				if (setFormikProps) setFormikProps(formikProps)

				return (
					<Form className={className}>
						<TextField
							fieldProps={{ name: 'tagName', label: 'Tag Name' }}
						/>

						<SelectField
							disabled={!isNullOrUndefined(tagType)}
							fieldProps={{ name: 'tagType', label: 'Tag Type', placeholder: 'Select tag type' }}
							options={[
								{ value: 'MINISTRYID', label: 'Ministry' },
								{ value: 'MINISTRYCONTACTID', label: 'Ministry Contact' },
								{ value: 'PROSPECTID', label: 'Prospect' },
							]}
						/>

						<SelectField
							fieldProps={{ name: 'branchId', label: 'Community', placeholder: 'Select community' }}
							options={appState.activeBranches.sort((a, b) => {
								if (a.branchName === null) return -1
								if (b.branchName === null) return 1
								if (a.branchName < b.branchName) return -1
								if (a.branchName > b.branchName) return 1
								return 0
							}).map(b => ({ value: b.branchId.toString(), label: b.branchName || '' }))}
						/>

						<div className='row px-3'>
							{onSaveCancel ? <button className='btn btn-secondary col mr-1' onClick={onSaveCancel}>Cancel</button> : null}
							<button className={`btn btn-primary col ${onSaveCancel ? 'ml-1' : ''}`} type='submit'>Create Tag</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}
