import { useState } from "react"
import { useCallback } from "react"
import { useCopyToClipboard } from "react-use"
import { ClipboardCheckIcon, ClipboardIcon } from "../assets"

interface ICopyToClipboardIconProps {
    value: string
}

export const CopyToClipboardIcon = ({ value }: ICopyToClipboardIconProps) => {

    const [_, copy] = useCopyToClipboard()

    const [copied, setCopied] = useState(false)

    const handleCopyPress = useCallback(() => {
        copy(value)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }, [value])

    return (
        <>
            {copied ?
                <ClipboardCheckIcon className='primary-color-hover cursor-pointer' onClick={handleCopyPress} />
                :
                <ClipboardIcon className='primary-color-hover cursor-pointer' onClick={handleCopyPress} />
            }
        </>
    )
}