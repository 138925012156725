import React, { useContext } from "react"
import { AppStateContext } from "../../app-store-provider"
import { FormikSelectField, IFormikSelectFieldProps } from './formik-select-field'

interface IFormikCommunitySelectFieldProps extends IFormikSelectFieldProps { }
export const FormikCommunitySelectField = (props: IFormikCommunitySelectFieldProps) => {
    const appState = useContext(AppStateContext)!
    return <FormikSelectField
        {...props}
        options={appState.activeBranches.sort((a, b) => {
            if (a.branchName === null) return -1
            if (b.branchName === null) return 1
            if (a.branchName < b.branchName) return -1
            if (a.branchName > b.branchName) return 1
            return 0
        }).map(b => ({ value: b.branchId.toString(), label: `${b.branchAbbr?.trim() || ''} (${b.branchName})` }))}
    />
}