import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalGovernanceProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalGovernance = (props: IMinistryInfoAreaDirectorEvalGovernanceProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
        <Formik
            initialValues={{
                bComplies: areaDirectorEval.ministryEvalState.bComplies,
                bDiversity: areaDirectorEval.ministryEvalState.bDiversity,
                bAdequate: areaDirectorEval.ministryEvalState.bAdequate,
                bConflict: areaDirectorEval.ministryEvalState.bConflict,
                bOversight: areaDirectorEval.ministryEvalState.bOversight,
                governance: areaDirectorEval.ministryEvalState.governance || '',
                tabDirection: null
            }}
            onSubmit={async (values) => {
                const evalRecord: IMinistryEvalsPutDocument = {
                    ...areaDirectorEval.ministryEvalState,
                    bComplies: values.bComplies,
                    bDiversity: values.bDiversity,
                    bAdequate: values.bAdequate,
                    bConflict: values.bConflict,
                    bOversight: values.bOversight,
                    governance: values.governance,
                    dStep2Updated: moment().format(),
                    step2UpdatedBy: appState.currentUser?.userId || null
                }
                await makeHTTPRequestWithUi({
                    request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
                    toastSuccessMessage: 'Eval record successfully updated.',
                    toastErrorMessage: 'There was an error updating the eval record.',
                })
                
                if (values.tabDirection === 'previous' && afterSavePrevious) {
                    afterSavePrevious()
                } else if (afterSaveNext) {
                    afterSaveNext()
                }
            }}
        >
            {(formikProps) => {
                const form = <React.Fragment>
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bComplies', 
                            label: 'This organization has a board that complies with external industry or government standards' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bDiversity', 
                            label: 'The board of this organization is organized, of sufficient size and expertise and diversity to provide leadership to the executive director and accountability to the organization\'s vision' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bAdequate', 
                            label: 'Regular board meetings are scheduled, documented and attendance of board members is considered adequate' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bConflict', 
                            label: 'There does not exist any relevant conflict of interest in the board of this organization (e.g. the chairman of the board is not a staff member, related-party transactions are closely evaluated, etc)' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bOversight', 
                            label: 'The board provides scheduled financial oversight and financial accountability to the organization' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    { !readonly ?
                        <TextareaField
                            fieldProps={{
                                name: 'governance',
                                label: 'Additional Notes on Governance'
                            }} />
                        :
                        null
                    }
                    { !readonly ?
                        <div className='row px-3'>
                            <button className={`btn btn-primary`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'previous')
                                formikProps.submitForm()
                            }}>Save &amp; Previous</button>
                            <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'next')
                                formikProps.submitForm()
                            }}>Save &amp; Continue</button>
                        </div>
                        :
                        null
                    }
                </React.Fragment>
                // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                return (
                    readonly ? form :
                    <Form>
                        {form}
                    </Form>
                )
            }}
        </Formik>
    )
}