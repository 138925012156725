import React, { useEffect } from 'react'
import { IEventFilterFieldsDataModelDocument } from '../open-api'
import { IFilter, IEventFilter } from '../stores/api-actions'
import { Formik, Form, FormikProps } from 'formik'
import { SelectField, ISelectFieldOption, ToggleField, CheckboxField } from './forms'
import { TooltipInfo } from './partials'

export interface IMinistryManagementFilterByEventsProps {
	filterByEventsOptions: IEventFilterFieldsDataModelDocument
	eventsFilter: IFilter
	updateEventsFilter: (filter: IFilter) => void
	closeModal: () => void
}

/* 
	This component is only used by the Ministry Management component (currently).
	It is an advanced filter UI for filtering ministries using event based criteria (e.g. "Attended Ministry Luncheon on 6/4/2020").

	Users can configure two types of event-based filters:
	- Event Attendance
	- Consulting Attendance

	For both types the user can select (multi-select) events registered for and not registered for.

	For events registered for, the user can additionally configure whether the ministry registered for EVERY or ANY selected event, 
	as well as toggle showing only ministries that also attended the selected events. 
	(These settings are only meaningful if at least one item is selected as registered for - if a user is only filtering by not registered for then they should be ignored.)

	The option data (list of events and the 'consulting quarters' that the user can filter by) is provided by the backend API.

	The user's selections are distilled down into a standard grid filter, but using special formatting for event filtering:
    
		registeredFor is an array of EventContentId's.
		{"registeredFor":[1529,419], "onlyIfRegisteredForAll": true, "onlyIfAttended": true}

		registeredForConsulting is an array of string that signify quarter start and end dates for a given year.
		{"registeredForConsulting":["1/1/2018|3/31/2018", "4/1/2015|6/30/2015"], "onlyIfRegisteredForAllConsulting": true, "onlyIfAttendedConsulting": true}

	(We store the user's selection on the frontend in a standard IFilter, however, the endpoint breaks out event filtering into separate params for the endpoint, 
	so the event filter is broken down and passed as params to the API request.)


	General actions the user can perform when filtering by events:
	- Apply Filters/Disable Filters (keeps the filter data in the UI but "un-applies" it to the query)
	- Clear Filters

	TODO: apply validation to make sure that the same event/consulting period isn't selected for both inclusion and exclusion
*/
export const MinistryManagementFilterByEvents = (props: IMinistryManagementFilterByEventsProps) => {
	const { filterByEventsOptions, eventsFilter, updateEventsFilter, closeModal } = props

	// ERROR: You must select at least one event or consulting quarter to filter by.

	const { eventsRegisteredFor, eventsNotRegisteredFor, consultingRegisteredFor, consultingNotRegisteredFor } = eventsFilter.filterByEventOptions!

	const filterByEventsSelectOptions: ISelectFieldOption[] = filterByEventsOptions.events?.map(event => ({ value: `${event.eventContentId}`, label: event.title || '' })) || []
	const filterByConsultingSelectOptions: ISelectFieldOption[] = filterByEventsOptions.consultingQuarters?.map(event => ({ value: `${event.dateRange}`, label: `${event.title}` })) || []

	const resetEventFilter = () => {
		updateEventsFilter({
			id: eventsFilter.id,
			operator: 'filter-by-event',
			property: eventsFilter.id,
			filterByEventOptions: {},
			enabled: false,
			value: eventsFilter.id,
		})

		closeModal()
	}

	let _formikProps: FormikProps<any>

	useEffect(() => {
		if (_formikProps) _formikProps.resetForm()
	}, [eventsFilter])

	return (
		<div>
			<Formik
				initialValues={{
					enabled: true,
					eventsRegisteredFor: eventsRegisteredFor?.eventIds.map(o => `${o}`) || [],
					registeredForEverySelectedEvent: eventsRegisteredFor?.registeredForEverySelectedEvent === undefined ? true : eventsRegisteredFor.registeredForEverySelectedEvent,
					eventsOnlyIncludeMinistriesThatAttended: !!eventsRegisteredFor?.onlyIncludeMinistriesThatAttended,
					eventsNotRegisteredFor: eventsNotRegisteredFor?.eventIds.map(o => `${o}`) || [],
					consultingRegisteredFor: consultingRegisteredFor?.consultingQuarters || [],
					registeredForEverySelectedConsulting: consultingRegisteredFor?.registeredForEverySelectedQuarter === undefined ? true : consultingRegisteredFor.registeredForEverySelectedQuarter,
					consultingNotRegisteredFor: consultingNotRegisteredFor?.consultingQuarters || [],
					consultingOnlyIncludeMinistriesThatAttended: !!consultingRegisteredFor?.onlyIncludeMinistriesThatAttended,
				}}
				onSubmit={(values, formikActions) => {
					const filterByEventOptions: IEventFilter = {
						...eventsFilter.filterByEventOptions
					}

					if (values.eventsRegisteredFor.length > 0) {
						filterByEventOptions.eventsRegisteredFor = {
							eventIds: values.eventsRegisteredFor.map(o => parseInt(o)),
							registeredForEverySelectedEvent: values.registeredForEverySelectedEvent,
							onlyIncludeMinistriesThatAttended: values.eventsOnlyIncludeMinistriesThatAttended
						}
					}

					if (values.eventsNotRegisteredFor.length > 0) {
						filterByEventOptions.eventsNotRegisteredFor = {
							eventIds: values.eventsNotRegisteredFor.map(o => parseInt(o))
						}
					}

					if (values.consultingRegisteredFor) {
						filterByEventOptions.consultingRegisteredFor = {
							consultingQuarters: values.consultingRegisteredFor,
							registeredForEverySelectedQuarter: values.registeredForEverySelectedConsulting,
							onlyIncludeMinistriesThatAttended: values.consultingOnlyIncludeMinistriesThatAttended
						}
					}

					if (values.consultingNotRegisteredFor) {
						filterByEventOptions.consultingNotRegisteredFor = {
							consultingQuarters: values.consultingNotRegisteredFor
						}
					}

					updateEventsFilter({ ...eventsFilter, filterByEventOptions, enabled: values.enabled })

					closeModal()
				}}
			>
				{formikProps => {
					_formikProps = formikProps


					return (
						<Form>

							<div className='border p-2 rounded'>
								<h5>Event Attendance</h5>
								<hr className='mb-2' />
								<SelectField
									fieldProps={{ name: 'eventsRegisteredFor', label: 'Registered for' }}
									options={filterByEventsSelectOptions}
									multiple={true}
								/>

								<ToggleField
									fieldProps={{ name: 'registeredForEverySelectedEvent', label: 'Registered for' }}
									trueText={<span><b>Every</b> selected event</span>}
									falseText={<span><b>Any</b> selected event</span>}
								/>

								<CheckboxField
									fieldProps={{ name: 'eventsOnlyIncludeMinistriesThatAttended', label: <span>Only show attendees <TooltipInfo style={{ paddingBottom: '2px' }} placement='top' tooltipText='Only show ministries that actually attended the event(s).' /></span> }}
								/>
								<div className='mt-3'></div>

								<SelectField
									fieldProps={{ name: 'eventsNotRegisteredFor', label: 'Not registered for' }}
									options={filterByEventsSelectOptions}
									multiple={true}
								/>
							</div>

							<div className='border p-2 rounded mt-2'>
								<h5>Consulting Attendance</h5>
								<hr className='mb-2' />

								<div className='d-flex'>
									<div style={{ width: '50%' }} className='mr-2'>
										<SelectField
											fieldProps={{ name: 'consultingRegisteredFor', label: 'Registered for' }}
											options={filterByConsultingSelectOptions}
											multiple={true}
										/>

										<ToggleField
											fieldProps={{ name: 'registeredForEverySelectedConsulting', label: 'Registered for' }}
											trueText={<span><b>Every</b> selected quarter</span>}
											falseText={<span><b>Any</b> selected quarter</span>}
										/>
									</div>
									<div style={{ width: '50%' }} className='ml-2'>
										<SelectField
											fieldProps={{ name: 'consultingNotRegisteredFor', label: 'Not registered for' }}
											options={filterByConsultingSelectOptions}
											multiple={true}
										/>
									</div>
								</div>

								<CheckboxField
									fieldProps={{ name: 'consultingOnlyIncludeMinistriesThatAttended', label: <span>Only show attendees <TooltipInfo style={{ paddingBottom: '2px' }} placement='top' tooltipText='Only show ministries that actually attended appointments in the selected quarter(s).' /></span> }}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
			<div className='row mt-4 mb-2 px-3 justify-content-center'>
				<button
					onClick={() => {
						if (_formikProps) {
							_formikProps.setFieldValue('enabled', true)
							_formikProps.submitForm()
						}
					}}
					className={`btn btn-primary mr-1`}
					type='submit'
				>
					Apply Event Filters
				</button>
				{eventsFilter.enabled ?
					<button
						onClick={() => {
							if (_formikProps) {
								_formikProps.setFieldValue('enabled', false)
								_formikProps.submitForm()
							}
						}}
						className={`btn btn-warning mr-1`}
						type='submit'
					>
						Disable Event Filters
					</button>
					:
					null
				}
				<button type='submit' className='btn btn-danger mr-1' onClick={resetEventFilter}>Clear Event Filters</button>
				<button className='btn btn-secondary mr-1' onClick={closeModal}>Cancel</button>
			</div>
		</div>
	)
}