
import React, { useEffect, useContext, useState } from 'react'
import { MinistryInfoContext } from './ministry-info'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { validateFilterState, filterGridListItems, sortListBySorts } from '../services/helpers'
import { MinistryFyData, ministryFyDataIncomeSourcesColumns } from '../models/ministry-fy-data'
import { useGrid, defaultGridState } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { Loading } from './loading'
import { Grid } from './grid'
import { MinistryInfoFyDataIncomeSourcesForm } from './ministry-info-fy-data-income-sources-form'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { useModal } from '../services/hooks'
import { Modal } from './modal'
import { IMinistryDataFiscalYearSummaryDocument } from '../open-api'


export const MinistryInfoFyDataIncomeSources = () => {
	const { actions: ministryInfoActions, state: ministryInfoState, } = useContext(MinistryInfoContext)!

	const [formModal, showHideFormModal] = useModal()
	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const fetchFyDataIncomeSources: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryFyData } = _state

		if (!ministryFyData) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryFyData, queryState.sorts)

		let rows = ministryFyData.map(MinistryFyData.toGridListItem)

		if (queryState.filters) {
			validateFilterState(queryState.filters, gridState.columns)
			rows = filterGridListItems(rows, queryState.filters)
		}

		return { rows, count: ministryFyData.length }
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: ministryFyDataIncomeSourcesColumns,
		disabledPagination: true,
		dataSource: fetchFyDataIncomeSources,
		hideGridHeader: true,
		hideGridFooter: true,
		rowActions: {
			edit: {
				id: 'edit',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideFormModal(true)
				},
				icon: <PencilIcon className='primary-color-hover mt-1' />,
				tooltipText: 'Edit FY Data'
			},
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryInfoState.ministryFyData])

	/* 
	Highlight any fiscal year rows with a fiscal year that matches the ministry's start year
*/
	useEffect(() => {
		gridActions.setHighlightedRows(gridState.rows.filter(row => row.values.fiscalYear === ministryInfoState.ministry?.startYear).map(row => row.id))
	}, [gridState.rows, ministryInfoState.ministry?.startYear])

	if (!ministryInfoState.ministryFyData || !ministryInfoState.ministry) return <Loading />

	return <React.Fragment>
		<Grid state={gridState} actions={gridActions} style={{ height: 'unset' }} />

		<Modal
			{...formModal}
			modalTitle={`Edit Income Source Data: ${selectedRow?.values.fiscalYear}`}
			_onModalHidden={() => setSelectedRow(undefined)}
			size='xl'
		>
			{formModal.show && selectedRow ?
				<MinistryInfoFyDataIncomeSourcesForm
					afterSave={() => {
						showHideFormModal(false)
						ministryInfoActions.fetchMinistryFyData()
					}}
					ministryInfoFyDataGeneral={selectedRow.values as unknown as IMinistryDataFiscalYearSummaryDocument}
				/>
				:
				null
			}
		</Modal>
	</React.Fragment>
}