import React, { useContext, useState, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { TextField, SelectField, CheckboxField, TinyMceField, ISelectFieldOption } from './forms'
import { sortListByProperty, uuidv4 } from '../services/helpers'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'
import { ICallListModelDocument } from '../open-api'


interface IMinistryManagementCreateCallList {
	onCancel?: () => void
	onSave?: () => void
	ministryIds?: number[]
	prospectIds?: number[]
	callListToEdit?: ICallListModelDocument
}
export const MinistryManagementCreateCallList = (props: IMinistryManagementCreateCallList) => {
	const { onCancel, onSave, ministryIds, prospectIds, callListToEdit } = props

	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!
	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	// const expirationDaysOptions: ISelectFieldOption[] = []
	// for (var i = 5; i < 365; i += 5) {
	// 	expirationDaysOptions.push({
	// 		value: `${i}`,
	// 		label: `${i} Days`
	// 	})
	// }

	const userIdsToShareWith: string[] = []
	const [shareWithUserOptions, setShareWithUserOptions] = useState<ISelectFieldOption[]>()

	useEffect(() => {
		if (!appState.users) appActions.fetchMiUsers()
		if (!callListToEdit && !ministryIds && !prospectIds) throw new Error('ministryIds and prospectIds both undefined. You must include either some prospects or some ministries to create a Call List for.')
	}, [])

	useEffect(() => {
		if (appState.users) {
			setShareWithUserOptions(sortListByProperty(appState.users, 'firstName').map(user => ({ label: `${user.fullName}`, value: `${user.userId}` })))
		}
	}, [appState.users])

	if (!shareWithUserOptions) return null

	return (
		<Formik
			initialValues={{
				name: callListToEdit?.title || '',
				description: callListToEdit?.description || '',
				userIdsToShareWith: callListToEdit?.userIdsToShareWith?.map(x => x.toString()) || userIdsToShareWith,
				usersCanAdd: callListToEdit?.bAllowAdd || false,
				usersCanDelete: callListToEdit?.bAllowDelete || false,
				notesToCaller: callListToEdit?.notes || '',
			}}
			validationSchema={Yup.object({
				name: Yup.string().required('Required').max(100, 'Title must be at most ${max} characters'),
				description: Yup.string().required('Required').max(3000, 'Description must be at most ${max} characters'),
				notesToCaller: Yup.string().max(2048, 'Comments must be at most ${max} characters')
			})}
			onSubmit={async (values, formikActions) => {
				console.log('values', values)
				if (callListToEdit) {
					const callList: ICallListModelDocument = {
						...callListToEdit,
						title: values.name,
						description: values.description,
						userIdsToShareWith: values.userIdsToShareWith?.map(x => parseInt(x)) || [],
						bAllowAdd: values.usersCanAdd,
						bAllowDelete: values.usersCanDelete,
						notes: values.notesToCaller
					}


					await makeHttpRequestWithUi({
						request: appActions.CallListsApi.apiCallListsIdPut(callListToEdit.callListId, callList),
						toastSuccessMessage: 'Successfully created Training Area.',
						toastErrorMessage: 'Error creating Training Area.'
					})
				} else {
					const ids = ministryIds || prospectIds
					const createCallListQuery = await makeHttpRequestWithUi({
						request: appActions.CallListsApi.apiCallListsPost({
							creatorId: appState.currentUser ? appState.currentUser.userId : null,
							title: values.name,
							description: values.description,
							notes: values.notesToCaller,
							bAllowAdd: values.usersCanAdd,
							bAllowDelete: values.usersCanDelete,
							isMinistryId: !!ministryIds,
							userIdsToShareWith: values.userIdsToShareWith.length > 0 ?
								values.userIdsToShareWith.map(o => parseInt(o))
								:
								null,
							entityIds: ids ? ids : null
						}),
						toastSuccessMessage: 'Successfully created Call List.',
						toastErrorMessage: 'Error creating Call List.',
					})

					const taskId = registerLoadingTask()

					const promises: Promise<any>[] = []

					appActions.addToast({
						id: uuidv4(),
						title: 'Creating Call List Associations',
						timestamp: new Date(),
						body: `Linking new Call List to ${ministryIds ? `${ministryIds.length > 1 ? 'ministries' : 'ministry'}` : null}${prospectIds ? `${prospectIds.length > 1 ? 'prospects' : 'prospect'}` : null}${values.userIdsToShareWith.length > 0 ? ` and user${values.userIdsToShareWith.length > 1 ? 's' : ''} to share with.` : ''}.`,
						autoDismissTimeOut: 2000,
					})

					await Promise.all(promises)

					deregisterLoadingTask(taskId)
				}

				if (onSave) onSave()
			}}
		>
			{formikProps => {
				return (
					<Form>
						<TextField fieldProps={{ name: 'name', label: 'Name' }} />
						<TextField fieldProps={{ name: 'description', label: 'Description' }} />
						<SelectField fieldProps={{ name: 'userIdsToShareWith', label: 'Share With' }} options={shareWithUserOptions} multiple={true} />
						<CheckboxField fieldProps={{ name: 'usersCanAdd', label: 'Users Can Add (Check this box to allow shared users to add to the list)' }} />
						<CheckboxField fieldProps={{ name: 'usersCanDelete', label: 'Users Can Delete (Check this box to allow shared users to delete from the list)' }} />
						<br />
						<TinyMceField fieldProps={{ name: 'notesToCaller', label: 'Comments to Caller' }} />

						<div className='row px-3 pb-3'>
							<button className='btn btn-secondary col mr-1' onClick={() => { if (onCancel) onCancel() }}>Cancel</button>
							<button className={`btn btn-primary col ml-1`} type='submit'>Save</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}