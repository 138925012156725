import { useContext, useEffect, useState } from "react"
import { IGrantGiftMinistryContactSummaryDocument, IGrantGiftModelDocument, IGrantTypeModelDocument, IMinistryGrantFundingSummaryDocument } from "../open-api"
import { Tabs } from "./tabs"
import { GrantFormSummary } from "./grant-form-summary"
import { GrantFormFunding } from "./grant-form-funding"
import { GrantFormMinistries } from "./grant-form-ministries"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { Loading } from "./loading"

interface IGrantFormTabsProps {
    grantToEdit?: IGrantGiftModelDocument
    afterSave: () => Promise<void>
    initialTab?: 'summary'
}

export const GrantFormTabs = (props: IGrantFormTabsProps) => {
    const { grantToEdit, afterSave, initialTab } = props
    const { GrantGiftApi, MinistryGrantFundingApi, GrantGiftMinistryContactApi } = useContext(AppActionContext)!
    //if (!grantToEdit) throw new Error('Must provide a grant to edit.')

    const [activeTab, setActiveTab] = useState<string>(initialTab || 'summary')
    const [grantTypes, setGrantTypes] = useState<IGrantTypeModelDocument[]>([])
    const [fundingSources, setFundingSources] = useState<IMinistryGrantFundingSummaryDocument[]>([])
    const [ministries, setMinistries] = useState<IGrantGiftMinistryContactSummaryDocument[]>([])
    const [fundingLoaded, setFundingLoaded] = useState(false)
    const [ministriesLoaded, setMinistriesLoaded] = useState(false)
    const [ministryFormModal, showHideMinistryFormModal] = useModal()
    
    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchGrantTypes = async () => {
        console.log('GrantFormSummary > fetchGrantTypes')
        const {data} = await makeHttpRequestWithUi({
            request: GrantGiftApi.apiGrantGiftGrantTypesGet(),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch grant types.'
        })

        setGrantTypes(data)
    }

    const fetchFunding = async () => {
        if (grantToEdit?.grantGiftId && grantToEdit.grantGiftId > 0) {
            // In 2.0 funding is attached to the Grant Gift record.
            const { data } = await makeHttpRequestWithUi({
                request: MinistryGrantFundingApi.apiGrantGiftGrantGiftIdFundingSourcesGet(grantToEdit.grantGiftId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching funding sources.',
            })
            setFundingSources(data)

            setFundingLoaded(true)
        }
    }

    const fetchMinistries = async () => {
        if (grantToEdit?.grantGiftId && grantToEdit.grantGiftId > 0) {
            const { data } = await makeHttpRequestWithUi({
                request: GrantGiftMinistryContactApi.apiGrantGiftGrantGiftIdContactsGet(grantToEdit.grantGiftId),
                disableSuccessToast: true,
                toastErrorMessage: 'Encountered an error fetching ministries.',
            })
            setMinistries(data)

            setMinistriesLoaded(true)
        }
    }

    useEffect(() => {
        fetchFunding()
    }, [])

    useEffect(() => {
        fetchGrantTypes()
    }, [])

    
    useEffect(() => {
        fetchMinistries()
    }, [])

    const getStatusNextStepsText = () => {
        if (fundingSources.length < 1) return 'Now that you have created a grant, the next step is to add at least one funding source. Finally, you will need to invite one or more ministries to apply for this grant.'

        if (ministries.length < 1) return 'Finally, you will need to invite one or more ministries to apply for this grant.'

        return 'For <b>NON-managed grants</b>, you will receive an automated email notification once the primary grant contact submits a grant request via the Ministry Portal. If this is a <b>managed grant</b> please view the grant request details for the ministry to determine the next steps.'
    }

    const getNextStepsButtons = () => {
        if (fundingSources.length < 1) return (
            <div className="d-flex">
                <button type='button' className='btn btn-primary' onClick={() => setActiveTab('fundingSources')}>Add funding source</button>
            </div>
        )
        if (ministries.length < 1) return (
            <div className="d-flex">
                <button type='button' className='btn btn-primary'
                    onClick={async () => { 
                        await setActiveTab('ministries')
                        await showHideMinistryFormModal(true)
                    }}>Add ministry</button>
            </div>
        )

        return ''
    }

    if (grantToEdit && !fundingLoaded && !ministriesLoaded) {
        console.log('loading')
        return <Loading />
    }
    
    return (
        <>
            { grantToEdit &&
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Next Steps</h5>
                        <p className="card-text" dangerouslySetInnerHTML={{__html: getStatusNextStepsText()}}></p>
                        {getNextStepsButtons()}
                    </div> 
                </div> }
            { !grantToEdit &&
                <div className="alert alert-info" role="alert">
                    <strong>NOTE!</strong> Before you start this Grant process, reach out to <a href="mailto:tcombs@mif.org">Trent Combs</a> first to best set yourself up for success.
                </div> }
            <Tabs
                keepTabContentInDomWhenNotActive
                controls={{
                    activeTab,
                    setActiveTab
                }}
                tabs={[
                    {
                        id: 'summary',
                        title: grantToEdit ? 'Summary' : 'Add Grant',
                        content: <GrantFormSummary
                        grantToEdit={grantToEdit}
                        afterSave={afterSave}
                        />
                    },
                    ...grantToEdit ?
                        [{
                            id: 'fundingSources',
                            title: 'Funding Sources',
                            content:
                                <GrantFormFunding
                                    fundingSources={fundingSources}
                                    branchId={grantToEdit.branchId}
                                    grantGiftId={grantToEdit.grantGiftId}
                                    afterSave={() => {
                                        fetchFunding()
                                        afterSave()
                                    }}
                                />
                        }] : [],
                    ...grantToEdit ?
                        [
                            {
                                id: 'ministries',
                                title: 'Ministries',
                                content:
                                    <GrantFormMinistries
                                        ministries={ministries}
                                        grantToEdit={grantToEdit}
                                        grantTypes={grantTypes}
                                        ministryFormModal={ministryFormModal}
                                        showHideMinistryFormModal={showHideMinistryFormModal}
                                        afterSave={async () => {
                                            await fetchMinistries()
                                            await afterSave()
                                        }}
                                        
                                    />
                            },
                        ] : []
                    ,
                ]}
            />
        </>
    )
}