import React, { useState, useEffect, useContext } from 'react'
import { IMinistryFileModelDocument, IMinistryFileSummaryModelDocument } from '../open-api'
import { AppActionContext } from '../app-store-provider'
import { GridDataFetch, IGridListItem, IGridState } from '../stores/grid-definitions'
import { filterGridListItems, validateFilterState, uuidv4, showModal, hideModal, sortListBySorts } from '../services/helpers'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { SquareDeleteIcon } from './partials'
import { MinistryFile, ministryFileDefaultColumns } from '../models/ministry-file'
import { ReactComponent as FileEarmarkTextIcon } from '../assets/file-earmark-text.svg'
import { ReactComponent as PencilIcon } from '../assets/pencil.svg'
import { gridReducer } from '../stores/grid-reducer'
import { Grid } from './grid'
import { MinistryInfoContext } from './ministry-info'
import { Modal } from './modal'
import { DocumentForm } from './document-form'
import { Loading } from './loading'
import { registerLoadingTask, deregisterLoadingTask } from '../services/loading-service'

interface IMinistryFilesProps {
	ministryId: number
	grantId?: number
	ministryFiles?: IMinistryFileSummaryModelDocument[]
	afterSave?: () => void
	hideMissingDocumentsBtn?: boolean
}
export const MinistryFiles = (props: IMinistryFilesProps) => {
	const { ministryId, grantId, ministryFiles, afterSave, hideMissingDocumentsBtn } = props

	const appActions = useContext(AppActionContext)!
	const ministryInfoContext = useContext(MinistryInfoContext)
	const state = ministryInfoContext?.state;

	const fetchMinistryFiles: GridDataFetch<IMinistryFileSummaryModelDocument[]> = async (queryState, ministryFiles) => {
		if (!ministryFiles) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryFiles, queryState.sorts)

		let rows = ministryFiles.map(file => MinistryFile.toGridListItem(file))

		if (queryState.filters) {
			validateFilterState(queryState.filters, gridState.columns)
			rows = filterGridListItems(rows, queryState.filters)
		}

		return {
			rows,
			count: ministryFiles.length
		}
	}

	const [documentFormModalId] = useState(uuidv4())
	const [deleteDocumentModalId] = useState(uuidv4())
	const [missingDocumentsModalId] = useState(uuidv4())

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()
	const [missingDocumentsLabel, setMissingDocumentsLabel] = useState('No missing documents.')

	const viewFile = async (row: IGridListItem) => {
		const signedUrl = await appActions.MinistryFilesApi.apiMinistryFilesMinistryFileGuidGetSignedUrlGet(row.id);

		if (signedUrl.data) window.open(signedUrl.data)
	}

	const [selectedMinistryFile, setSelectedMinistryFile] = useState<IMinistryFileModelDocument>()
	useEffect(() => {
		if (selectedMinistryFile) showModal(documentFormModalId)
		// eslint-disable-next-line
	}, [selectedMinistryFile])

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: ministryFileDefaultColumns,
		disabledPagination: true,
		dataSource: fetchMinistryFiles,
		gridActions: [
			{
				id: 'addDocument',
				label: 'Add Document',
				onClick: () => showModal(documentFormModalId)
			},
			...!hideMissingDocumentsBtn ? [{
				id: 'missingDocuments',
				label: 'Missing Documents',
				buttonClass: (gridState: IGridState) => {
					var missingDocs = gridState.rows.length == 0 || !gridState.rows.find(o => o.values.formattedDescription === 'Current IRS Nonprofit Letter on file')
					return missingDocs ? 'btn-danger' : 'btn-light'
				},
				onClick: () => {
					showModal(missingDocumentsModalId)
				},
			}] : []
		],
		rowDoubleClicked: async (row) => {
			viewFile(row)
		},
		rowActions: {
			viewFile: {
				id: 'viewFile',
				action: async (options) => {
					options.e.stopPropagation()
					viewFile(options.row)
				},
				icon: <FileEarmarkTextIcon />,
				tooltipText: 'View File',
			},
			editFile: {
				id: 'editFile',
				action: async (options) => {
					options.e.stopPropagation()

					const taskId = registerLoadingTask()
					const ministryFileQuery = await appActions.MinistryFilesApi.apiMinistryFilesIdGet(options.row.id)
					deregisterLoadingTask(taskId)
					setSelectedMinistryFile(ministryFileQuery.data)

				},
				icon: <PencilIcon />,
				tooltipText: 'Edit file',
			},
			deleteFile: {
				id: 'deleteFile',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showModal(deleteDocumentModalId)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete File',
			},
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryFiles)

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [ministryFiles])

	useEffect(() => {

		if (!state?.ministryFiles?.length) {
			setMissingDocumentsLabel('No documents.')
		} else if (!state?.ministryFiles?.find(file => file.fileDescId === 1)) {
			setMissingDocumentsLabel('Current IRS Nonprofit Letter is missing.')
		} else {
			setMissingDocumentsLabel('No missing documents.')
		}

		//eslint-disable-next-line
	}, [ministryFiles])

	if (!ministryFiles) return <Loading />

	return (
		<>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />

			<Modal
				modalId={documentFormModalId}
				modalTitle='Document'
				onModalHidden={() => {
					setSelectedRow(undefined)
					setSelectedMinistryFile(undefined)
				}}
				dismissible={false}
			>
				<p className='text-secondary'>This section of the ministry account allows you to attach any other documents that will help us better understand your organization.</p>
				<DocumentForm
					ministryId={ministryId}
					ministryFiles={ministryFiles}
					grantId={grantId}
					afterSave={async () => {
						hideModal(documentFormModalId)
						const taskId = registerLoadingTask()
						deregisterLoadingTask(taskId)
						if (afterSave) afterSave()
					}}
					ministryFile={selectedMinistryFile}
				/>
			</Modal>

			<Modal
				modalId={deleteDocumentModalId}
				modalTitle='Confirm'
				onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-danger" onClick={async () => {
							if (selectedRow) {
								const taskId = registerLoadingTask()
								await appActions.MinistryFilesApi.apiMinistryFilesIdDelete(selectedRow.id)
								//if (actions) await actions.fetchMinistryFiles()
								if (afterSave) afterSave()
								deregisterLoadingTask(taskId)
								hideModal(deleteDocumentModalId)
							} else {
								alert('There was an error deleting this file. No file ID specified.')
							}
						}}>Delete</button>
					</>
				}
			>
				Are you sure you want to delete this document?

				This cannot be reversed!
			</Modal>
			<Modal
				modalId={missingDocumentsModalId}
				modalTitle='Missing Documents'
				footer={<button type="button" className="btn btn-secondary" data-dismiss="modal">OK</button>}
			>
				{missingDocumentsLabel}
			</Modal>
		</>
	)
}