import { IMiTrainingAreasModelDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'
import { IModelDocumentBase, ModelBase } from './model-base'


export interface ITrainingAreaDocument extends IModelDocumentBase {
	trainingAreaId: number
	branchId: number
	bVirtual: boolean
	trainingArea: string | null
	trainingAreaAbbr: string | null
	bDefault: boolean | null
	bActive: boolean | null
	defaultSiteId: number | null
	latitude: number | null
	longitude: number | null
	postalCode: string | null
	trainingAreaContact: number | null
}

export interface ITrainingArea extends ITrainingAreaDocument {

}

export class TrainingAreaModel extends ModelBase implements ITrainingArea {
	trainingAreaId: number
	branchId: number
	bVirtual: boolean

	// Nullable
	trainingArea: string | null
	trainingAreaAbbr: string | null
	bDefault: boolean | null
	bActive: boolean | null
	defaultSiteId: number | null
	latitude: number | null
	longitude: number | null
	postalCode: string | null
	trainingAreaContact: number | null

	constructor(doc: ITrainingAreaDocument) {
		super(doc)

		this.trainingAreaId = doc.trainingAreaId
		this.branchId = doc.branchId
		this.bVirtual = doc.bVirtual

		// Nullable
		this.trainingArea = doc.trainingArea
		this.trainingAreaAbbr = doc.trainingAreaAbbr
		this.bDefault = doc.bDefault
		this.bActive = doc.bActive
		this.defaultSiteId = doc.defaultSiteId
		this.latitude = doc.latitude
		this.longitude = doc.longitude
		this.postalCode = doc.postalCode
		this.trainingAreaContact = doc.trainingAreaContact
	}

	public static toGridListItem = (trainingArea: IMiTrainingAreasModelDocument): IGridListItem => {
		return {
			id: trainingArea.trainingAreaId.toString(),
			values: {
				...trainingArea
			}
		}
	}
}

export const factory = (doc: ITrainingAreaDocument): TrainingAreaModel => {
	return new TrainingAreaModel(doc)
}