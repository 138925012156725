import React, { useContext, useState, useEffect } from 'react'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { ContactInfoContext } from './contact-info'
import { ContactForm } from './contact-form'
import { Loading } from './loading'
import { IZStatesDocument } from '../open-api'
import { IncognitoIcon, XSquareIcon } from '../assets'
import { ContextMenu } from './context-menu'
import { hideModal, openUrlInNewTab, showModal, uuidv4 } from '../services/helpers'
import { Modal } from './modal'
import { deregisterLoadingTask, registerLoadingTask } from '../services/loading-service'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { navigate } from '@reach/router'

export const ContactInfoGeneral = () => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!
	const { state: contactInfoState, actions: contactInfoActions } = useContext(ContactInfoContext)!
	const contact = contactInfoState.contact!
	const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

	const [states, setStates] = useState<IZStatesDocument[]>()

	const [deleteContactModal] = useState(uuidv4())

	// 20240312 TB - Narrowing blacklistMailTypes down to only two options and hard coding them in constants.ts as MAIL_TYPE_OPTIONS
	//const [blacklistMailTypes, setBlacklistMailTypes] = useState<IMailTypeDocument[]>()

	useEffect(() => {
		contactInfoActions.fetchContactEditOptions();
		appActions.StatesApi.apiStatesGet()
			.then(results => {
				setStates(results.data)
			})

		// appActions.MailTypesApi.apiMailTypesMinistryContactsBlacklistOptionsGet()
		// 	.then(results => {
		// 		setBlacklistMailTypes(results.data)
		// 	})
	}, [])

	const renderContactEditForm = () => {
		if ( !contactInfoState.ministry?.ministryId || !contactInfoState.contact || !states ) {
			return <Loading/>
		} else {
			return (
				<ContactForm 
					ministryId={contactInfoState.ministry.ministryId} 
					ministryContact={contact} 
					states={states} 
					header={(saveButton) => (<>
						<div className='d-flex justify-content-end align-items-center container'>
							{ saveButton }

							<button title={contact.impersonationUrl ? '' : 'This contact is not associated with an SSO record. They must create an SSO account for you to impersonate them.'} type='button' className='btn btn-secondary mr-3' disabled={contact.impersonationUrl ? false : true} onClick={() => openUrlInNewTab(contact.impersonationUrl?.toString() ?? '')	}>
								<IncognitoIcon className="mr-2" />

								Impersonate User
							</button>

							<ContextMenu>
								<button className='dropdown-item d-flex align-items-center' onClick={() => showModal(deleteContactModal)}>
									<XSquareIcon className="mr-2" />

									Delete Contact
								</button>
							</ContextMenu>
						</div>
					</>)}
					//blacklistMailTypes={blacklistMailTypes}
				/>
			)
		}
	}

	return (
		<React.Fragment>
			<div className='pt-4 pb-4'>

				{renderContactEditForm()}
			</div>

			<Modal
					modalId={deleteContactModal}
					modalTitle='Confirm'
					footer={
						<React.Fragment>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
							<button type='button' className='btn btn-danger' disabled={contact?.isPrimary} onClick={async () => {
								const taskId = registerLoadingTask()
								await makeHTTPRequestWithUi({
									request: appActions.MinistryContactsApi.apiMinistryContactsIdDelete(contact.ministryContactId),
									disableLoading: true,
									disableSuccessToast: false,
									toastErrorMessage: 'There was an error deleting contact.'
								})
								deregisterLoadingTask(taskId)
								hideModal(deleteContactModal)
								navigate('/contacts')
							}}>Delete</button>
						</React.Fragment>
					}
				>
					{contact.isPrimary ?
						`We're sorry, but you cannot delete the primary contact.`
						:
						`Are you sure you want to delete this contact?`
					}
				</Modal>
		</React.Fragment>
	)
}