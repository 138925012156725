import React, { useState } from 'react'
import { useEffect, useContext } from 'react'
import { useAuth } from "react-oidc-context"
import { AppActionContext } from '../app-store-provider'
import BaseLayout from './base-layout'
import { IDefaultProps } from './component-definitions'
import { Loading } from './loading'

export const SignOut = (props: IDefaultProps) => {
	const actions = useContext(AppActionContext)
	
	const auth = useAuth();
	const authenticated = auth.isAuthenticated

	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		if (actions && authenticated) actions.logout().then(() => {
			setIsLoading(false)
		})
	}, [actions, authenticated])


	if (isLoading) return <Loading />

	return (
		<BaseLayout className='center'>
			<div className='single-center-element small column bottom-gutters'>
				<div className='_row center'>
					<h1 className="display-5">Mission Increase Admin</h1>
				</div>
				<div className='_row center'>
					<h2>You have been signed out.</h2>
				</div>

				<a
					type="button"
					className="btn btn-primary btn-lg btn-block"
					href='/'
				>
					Continue
				</a>
			</div>
		</BaseLayout>
	)
}
