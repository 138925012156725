import { IModelDocumentBase, ModelBase } from './model-base'
import { IGridListItem, IGridColumn } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, EmailCellDisplay, GridActionCell, GridListItemObjectDisplay, MinistryContactNameDisplay } from '../components/grid-cell-displays'
import { IMinistryContactSearchResultModelDocument, IMinistryContactSummaryModelDocument } from '../open-api'

export interface IMinistryContactDocument extends IModelDocumentBase {
	ministryContactId: number
	ministryId: number | null
	bAllowLogin: boolean | null
	contactTypeId: number | null
	salutationId: number | null
	firstName: string | null
	lastName: string | null
	title: string | null
	email: string | null
	businessPhone: string | null
	businessPhoneExt: string | null
	homePhone: string | null
	cellPhone: string | null
	address1: string | null
	address2: string | null
	city: string | null
	stateId: number | null
	countryId: number | null
	postalCode: string | null
	termStarted: string | null
	vocation: string | null
	dLastLogin: string | Date | null
	bEmailVerified: boolean | null
	guid: string | null
	bNotApproved: boolean | null
	bDeleted: boolean | null
	deleteDate: string | Date | null
	deleteByUserId: number | null
	dCreated: string | Date | null
	dLastOveride: string | Date | null
	lastOverrideBy: number | null
	bVerified: boolean | null
	contactSubTypeId: number | null
	dVerified: string | Date | null
	verifiedBy: number | null
	bNotifiedBadEmail: boolean | null
	gtoNotes: string | null
	bAdminCreated: boolean | null
	dAdminCreated: string | Date | null
	adminCreatedBy: number | null
	hireMonth: string | null
	hireYear: string | null
	fullName: string | null
	isPrimary: boolean
	unsubscribedFromAll: boolean
	blacklistMailTypeIds: number[]
	contactTypeName: string | null
	contactSubTypeName: string | null
	permissionId_Profile: number | null
	permissionId_Contacts: number | null
	permissionId_Training: number | null
	permissionId_Grants: number | null
	permissionId_Resources: number | null
	subjectId: string | null
	impersonationUrl: string | null
	doesUserHavePermissionToLogin: string | null
	tagNames: string | null
	trainingAreaAbbr: string | null
}

export interface IMinistryContact extends IMinistryContactDocument {

}

export class MinistryContactModel extends ModelBase implements IMinistryContact {
	ministryContactId: number

	dLastLogin: Date | null
	deleteDate: Date | null
	dCreated: Date | null
	dLastOveride: Date | null
	dVerified: Date | null
	dAdminCreated: Date | null


	ministryId: number | null
	bAllowLogin: boolean | null
	contactTypeId: number | null
	salutationId: number | null
	firstName: string | null
	lastName: string | null
	title: string | null
	email: string | null
	businessPhone: string | null
	businessPhoneExt: string | null
	homePhone: string | null
	cellPhone: string | null
	address1: string | null
	address2: string | null
	city: string | null
	stateId: number | null
	countryId: number | null
	postalCode: string | null
	termStarted: string | null
	vocation: string | null
	bEmailVerified: boolean | null
	guid: string | null
	bNotApproved: boolean | null
	bDeleted: boolean | null
	deleteByUserId: number | null
	lastOverrideBy: number | null
	bVerified: boolean | null
	contactSubTypeId: number | null
	verifiedBy: number | null
	bNotifiedBadEmail: boolean | null
	gtoNotes: string | null
	bAdminCreated: boolean | null
	adminCreatedBy: number | null
	hireMonth: string | null
	hireYear: string | null
	fullName: string | null
	isPrimary: boolean
	unsubscribedFromAll: boolean
	blacklistMailTypeIds: number[]
	contactTypeName: string | null
	contactSubTypeName: string | null
	permissionId_Profile: number | null
	permissionId_Contacts: number | null
	permissionId_Training: number | null
	permissionId_Grants: number | null
	permissionId_Resources: number | null
	subjectId: string | null
	impersonationUrl: string | null
	doesUserHavePermissionToLogin: string | null
	tagNames: string | null
	trainingAreaAbbr: string | null

	constructor(doc: IMinistryContactDocument) {
		super(doc)

		this.ministryContactId = doc.ministryContactId

		this.dLastLogin = doc.dLastLogin ? new Date(doc.dLastLogin) : null
		this.deleteDate = doc.deleteDate ? new Date(doc.deleteDate) : null
		this.dCreated = doc.dCreated ? new Date(doc.dCreated) : null
		this.dLastOveride = doc.dLastOveride ? new Date(doc.dLastOveride) : null
		this.dVerified = doc.dVerified ? new Date(doc.dVerified) : null
		this.dAdminCreated = doc.dAdminCreated ? new Date(doc.dAdminCreated) : null

		this.ministryId = doc.ministryId
		this.bAllowLogin = doc.bAllowLogin
		this.contactTypeId = doc.contactTypeId
		this.salutationId = doc.salutationId
		this.firstName = doc.firstName
		this.lastName = doc.lastName
		this.title = doc.title
		this.email = doc.email
		this.businessPhone = doc.businessPhone
		this.businessPhoneExt = doc.businessPhoneExt
		this.homePhone = doc.homePhone
		this.cellPhone = doc.cellPhone
		this.address1 = doc.address1
		this.address2 = doc.address2
		this.city = doc.city
		this.stateId = doc.stateId
		this.countryId = doc.countryId
		this.postalCode = doc.postalCode
		this.termStarted = doc.termStarted
		this.vocation = doc.vocation
		this.bEmailVerified = doc.bEmailVerified
		this.guid = doc.guid
		this.bNotApproved = doc.bNotApproved
		this.bDeleted = doc.bDeleted
		this.deleteByUserId = doc.deleteByUserId
		this.lastOverrideBy = doc.lastOverrideBy
		this.bVerified = doc.bVerified
		this.contactSubTypeId = doc.contactSubTypeId
		this.verifiedBy = doc.verifiedBy
		this.bNotifiedBadEmail = doc.bNotifiedBadEmail
		this.gtoNotes = doc.gtoNotes
		this.bAdminCreated = doc.bAdminCreated
		this.adminCreatedBy = doc.adminCreatedBy
		this.hireMonth = doc.hireMonth
		this.hireYear = doc.hireYear
		this.fullName = doc.fullName
		this.isPrimary = doc.isPrimary
		this.unsubscribedFromAll = doc.unsubscribedFromAll
		this.blacklistMailTypeIds = doc.blacklistMailTypeIds
		this.contactTypeName = doc.contactTypeName
		this.contactSubTypeName = doc.contactSubTypeName
		this.permissionId_Profile = doc.permissionId_Profile
		this.permissionId_Contacts = doc.permissionId_Contacts
		this.permissionId_Training = doc.permissionId_Training
		this.permissionId_Grants = doc.permissionId_Grants
		this.permissionId_Resources = doc.permissionId_Resources
		this.subjectId = doc.subjectId
		this.impersonationUrl = doc.impersonationUrl
		this.doesUserHavePermissionToLogin = doc.doesUserHavePermissionToLogin
		this.tagNames = doc.tagNames
		this.trainingAreaAbbr = doc.trainingAreaAbbr
	}

	public static toGridListItem = (contact: IMinistryContactSummaryModelDocument): IGridListItem => {
		return {
			id: contact.ministryContactId.toString(),
			values: {
				isPrimary: contact.isPrimary,
				contactTypeName: contact.contactTypeName,
				contactSubTypeName: contact.contactSubTypeName,
				fullName: contact.fullName,
				firstName: contact.firstName,
				lastName: contact.lastName,
				email: contact.email,
				businessPhone: contact.businessPhone,
				cellPhone: contact.cellPhone,
				unsubscribedFromAll: contact.unsubscribedFromAll,
				tags: contact.tags?.map(tag => ({ display: tag.tagName, id: tag.tagId })) || [],
				subjectId: contact.subjectId,
				impersonationUrl: contact.impersonationUrl,
				doesUserHavePermissionToLogin: contact.doesUserHavePermissionToLogin
			}
		}
	}

	public static toListItem = (contact: IMinistryContactSearchResultModelDocument): IGridListItem => {
		return {
			id: contact.ministryContactId.toString(),
			values: {
				ministryContactId: contact.ministryContactId,
				ministryId: contact.ministryId,
				ministryName: contact.ministryName,
				branchAbbr: contact.branchAbbr,
				firstName: contact.firstName,
				lastName: contact.lastName,
				fullName: contact.fullName,
				title: contact.title,
				email: contact.email,
				businessPhone: contact.businessPhone,
				businessPhoneExt: contact.businessPhoneExt,
				cellPhone: contact.cellPhone,
				address1: contact.address1,
				address2: contact.address2,
				city: contact.city,
				state: contact.state,
				country: contact.country,
				postalCode: contact.postalCode,
				dLastLogin: contact.dLastLogin,
				dCreated: contact.dCreated,
				isPrimary: contact.isPrimary,
				contactTypeName: contact.contactTypeName,
				contactSubTypeName: contact.contactSubTypeName,
				ministryLevelId: contact.ministryLevelId,
				impersonationUrl: contact.impersonationUrl,
				tagNames: contact.tagNames,
				tags: contact.tags?.map(tag => ({ display: tag.tagName, id: tag.tagId })) || [],
				trainingAreaAbbr: contact.trainingAreaAbbr,
			}
		}
	}

	//public static getImpersonationUrl = (contactGuid: string | null | undefined, currentUserId: number | null | undefined): string => `${MINISTRY_PORTAL_ROOT_URL}/home/impersonate/${contactGuid}?userId=${currentUserId}`
}

export const factory = (doc: IMinistryContactDocument): MinistryContactModel => {
	return new MinistryContactModel(doc)
}

export const ministryContactDefaultColumns: IGridColumn[] = [
	{
		property: 'ministryContactId',
		type: 'number',
		width: 50,
		allowFilters: true,
		title: 'Ministry ContactId',
		render: DefaultGridCellDisplay,
		hide: true
	},
	{
		property: 'isPrimary',
		type: 'boolean',
		width: 50,
		allowFilters: true,
		title: 'Primary',
		render: DefaultGridCellDisplay,
		description: 'Whether or not this contact is the primary contact for the ministry'
	},
	{
		property: 'contactTypeName',
		type: 'string',
		width: 90,
		allowFilters: true,
		title: 'Contact Type',
		render: DefaultGridCellDisplay,
		filterOptions: [
			{ label: 'Staff ', value: 'Staff' },
			{ label: 'Board ', value: 'Board' },
		],
	},
	{
		property: 'contactSubTypeName',
		type: 'string',
		width: 90,
		allowFilters: true,
		title: 'Title',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'fullName',
		type: 'string',
		width: 100,
		disableSort: false,
		allowFilters: true,
		title: 'Full Name',
		render: MinistryContactNameDisplay,
		description: 'The contact\'s first name.',
	},
	{
		property: 'email',
		type: 'string',
		width: 120,
		allowFilters: true,
		title: 'Email',
		render: EmailCellDisplay,
	},
	{
		property: 'cellPhone',
		type: 'string',
		width: 100,
		allowFilters: true,
		title: 'Cell Phone',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'businessPhone',
		type: 'string',
		width: 100,
		allowFilters: true,
		title: 'Business Phone',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'unsubscribedFromAll',
		type: 'boolean',
		width: 100,
		allowFilters: true,
		title: 'Unsubscribed From All',
		description: 'Is contact unsubscribed from "All MI Email"?',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'tags',
		type: 'string',
		allowFilters: true,
		filterTypeOverride: 'tags',
		width: 140,
		title: 'Tags',
		hide: true,
		render: GridListItemObjectDisplay,
	},
	{
		property: 'grid_actions',
		type: 'actions',
		width: 150,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	},
]
