import React, { useContext } from 'react'
import { toastRoot } from '../services/helpers'
import { createPortal } from 'react-dom'
import { AppStateContext, AppActionContext } from '../app-store-provider'
import ReactTimeago from 'react-timeago'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { TOAST_ANIMATION_DURATION } from '../constants'

/* 
	This is a 'container' component for displaying toasts that have been added to the global state. 
	
	It shouldn't need to be used more than once at the top level of the whole app (App.tsx).

	If you're trying to display a toast, use the addToast action. 
	This will add a toast to the global state, and this component will handle its display and dimissal.
*/
export const Toasts = () => {
	const appState = useContext(AppStateContext)!
	const appActions = useContext(AppActionContext)!

	const toasts = appState.toasts?.map(toast =>
		<CSSTransition
			key={toast.id}
			timeout={TOAST_ANIMATION_DURATION}
			classNames={{
				enter: 'animated fadeInRight',
				enterDone: 'show',
				exit: 'animated fadeOutRight',
				exitDone: ''
			}}
		>
			<div className='toast' role='alert' aria-live='assertive' aria-atomic='true'>
				<div className='toast-header'>
					{toast.icon ?
						<div className='mr-1'>{toast.icon}</div>
						:
						null
					}

					<strong className='mr-auto'>{toast.title}</strong>

					<small className='text-muted ml-2'><ReactTimeago date={toast.timestamp} /></small>

					{toast.notDimissible ?
						null
						:
						<button type='button' className='ml-2 mb-1 close' data-dismiss='toast' aria-label='Close' onClick={() => appActions.removeToast(toast)}>
							<span aria-hidden='true'>&times;</span>
						</button>
					}
				</div>

				<div className='toast-body'>
					{toast.body}
				</div>
			</div>
		</CSSTransition>
	)

	return createPortal(
		<div style={{ position: 'absolute', top: 10, right: 10, overflow: 'hidden', zIndex: 2000 }}>
			<TransitionGroup
				component={null}
			>
				{toasts}
			</TransitionGroup>
		</div>,
		toastRoot
	)
}