import moment from 'moment'
import { IGetResultDocument } from '../open-api'
import { IGridListItem } from '../stores/grid-definitions'

export class UnsubscribeListModel {
    public static toGridListItem = (unsubscribeListItem: IGetResultDocument): IGridListItem => {
        return {
            id: unsubscribeListItem.emailAddress || '',
            values: {
                ...unsubscribeListItem,
                dateEntered: unsubscribeListItem.dateEntered && moment(unsubscribeListItem.dateEntered).toDate(),
                mailTypeIds: unsubscribeListItem.mailTypeIds?.filter(o => o !== null).join(',') || null,
                branchId: unsubscribeListItem.branch,
            },
        }
    }
} 