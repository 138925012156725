import { IPartnerQuestionsModelDocument } from '../open-api'
import { FormikProps } from 'formik'
import { MINISTRY_INFO_PROFILE_QUESTIONS } from '../constants'

export class QuestionModel {
	public static isCountriesServed = (question: IPartnerQuestionsModelDocument): boolean => question.questionId === 44
	public static isStatesServed = (question: IPartnerQuestionsModelDocument): boolean => question.questionId === 45 || question.questionId === MINISTRY_INFO_PROFILE_QUESTIONS.PhysicalStateId || question.questionId === MINISTRY_INFO_PROFILE_QUESTIONS.MailingStateId
	public static isDirectorActivities = (question: IPartnerQuestionsModelDocument): boolean => question.questionId === 29
	public static isIncomeAmount = (question: IPartnerQuestionsModelDocument): boolean => question.questionId === 12
	public static isMailingAddress = (question: IPartnerQuestionsModelDocument): boolean => question.questionId === 73
	public static isReportedIncome = (question: IPartnerQuestionsModelDocument): boolean => question.questionId === 10

	public static getFormFieldNameForPastThreeYearField = (fieldName: string | null, fiscalYear: number) => `${fieldName}-${fiscalYear}`
	public static getFormFieldNameForDirectorActivitiesField = (fieldName: string | null, defaultValue: string | null) => `${fieldName}${defaultValue}`

	public static getCashIncomeSubTotalForFiscalYearOfReportedIncomeQuestion = (question: IPartnerQuestionsModelDocument, fiscalYear: number, formikProps: FormikProps<{}>): number => {
		let total = 0

		const { fields } = question

		if (!fields) return total

		// For each fiscal year, get the total of the first 8 fields of this question.
		fields.forEach((_field, _index) => {
			if (_index < fields.length - 1) {
				const name = QuestionModel.getFormFieldNameForPastThreeYearField(_field.fieldName, fiscalYear)
				// @ts-ignore
				const value = formikProps.values[name]
				const cleaned = `${value}`.replace(/\D/g, '')
				if (cleaned.length > 0) total += parseInt(cleaned)
			}
		})

		return total
	}

	public static getTotalIncomeSubTotalForFiscalYearOfReportedIncomeQuestion = (question: IPartnerQuestionsModelDocument, fiscalYear: number, formikProps: FormikProps<{}>): number => {
		let total = 0

		const { fields } = question

		if (!fields) return total

		// For each fiscal year, get the total of the first 8 fields of this question.
		fields.forEach((_field, _index) => {
			const name = QuestionModel.getFormFieldNameForPastThreeYearField(_field.fieldName, fiscalYear)
			// @ts-ignore
			const value = formikProps.values[name]
			const cleaned = `${value}`.replace(/\D/g, '')
			if (cleaned.length > 0) total += parseInt(cleaned)
		})

		return total
	}
}