import { IMinistryProfileLoggingSummaryDocument } from "../open-api"
import { IGridListItem, IGridColumn } from "../stores/grid-definitions"
import { uuidv4 } from "../services/helpers"
import { DefaultGridCellDisplay } from "../components/grid-cell-displays"


export class MinistryProfileLogModel {
    public static toGridListItem = (ministryProfileLogModel: IMinistryProfileLoggingSummaryDocument): IGridListItem => {
        return {
            id: uuidv4(),
            values: {
                bApproved: ministryProfileLogModel.bApproved,
                createdByName: ministryProfileLogModel.createdByName,
                dCreated: ministryProfileLogModel.dCreated ? new Date(ministryProfileLogModel.dCreated) : null,
                pageTitle: ministryProfileLogModel.pageTitle
            }
        }
    }
}

export const ministryProfileLogModelDefaultColumns: IGridColumn[] = [
    {
        property: 'dCreated',
        type: 'date',
        width: 50,
        allowFilters: true,
        title: 'Action Date',
        render: DefaultGridCellDisplay
    },
    {
        property: 'createdByName',
        type: 'string',
        width: 50,
        allowFilters: true,
        title: 'User',
        render: DefaultGridCellDisplay
    },
    {
        property: 'pageTitle',
        type: 'string',
        width: 70,
        allowFilters: true,
        title: 'Page',
        render: DefaultGridCellDisplay
    },
    {
        property: 'bApproved',
        type: 'boolean',
        width: 28,
        allowFilters: true,
        title: 'Approved',
        render: DefaultGridCellDisplay,
        conditionalCellCSSFormatting: (row) => {
			// (see description of the column above for explanation of this conditional formatting)
			if (row.values.bApproved) {
			    return 'bg-success-7'
			} else {
                return 'bg-danger-7'
            }
		},
    }
]