import { useContext } from 'react'
import { Form, Formik, FormikProps } from 'formik'
import { IGetGiverOrgResultDocument, IPostGiverOrgRequestDocument, IPutGiverOrgRequestDocument } from "../open-api"
import { AppActionContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import * as Yup from 'yup'
import { CheckboxField, CommunitySelectField, StateSelectField, TextField } from './forms'

interface IGiverOrgFormProps {
	giverOrgToEdit?: IGetGiverOrgResultDocument
	onSaveSucces?: () => Promise<void>
}

export const GiverOrgForm = (props: IGiverOrgFormProps) => {
	const { giverOrgToEdit, onSaveSucces } = props

    const { GiverApi } = useContext(AppActionContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const initialValues = {
		orgId: giverOrgToEdit?.giverOrgId ?? 0,
		name: giverOrgToEdit?.giverOrgName || '',
		abbr: giverOrgToEdit?.giverOrgAbbr || '',
		onlyShowFundedGrants: giverOrgToEdit?.giverOrgOnlyShowFundedGrants || false,
		showUnfundedGrants: giverOrgToEdit?.giverOrgShowUnfundedGrants || false,
		// isArchived: giverOrgToEdit?.giverOrgIsArchived || false,
		funderRepresentative: giverOrgToEdit?.giverOrgFunderRepresentative || '',
		funderRepresentativeTitle: giverOrgToEdit?.giverOrgFunderRepresentativeTitle || '',
		address1: giverOrgToEdit?.giverOrgAddress1 || '',
		address2: giverOrgToEdit?.giverOrgAddress2 || '',
		city: giverOrgToEdit?.giverOrgCity || '',
		stateId: giverOrgToEdit?.giverOrgStateId || 0,
		zipCode: giverOrgToEdit?.giverOrgZipCode || '',
		communityIds: giverOrgToEdit?.giverOrgCommunityIds?.map(x => x.toString()) ?? [],
		communityPortalIds: giverOrgToEdit?.giverOrgPortalCommunityIds?.map(x => x.toString()) ?? []
	}

	const validationSchema = Yup.object({
		name: Yup.string().required('Required'),
		abbr: Yup.string().required('Required'),
		communityIds: Yup.array().of(Yup.string()).min(1, 'Must select at least one presenter.'),
	})

    let _formikProps: FormikProps<any>

    return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, actions) => {
				const giverOrg: IPostGiverOrgRequestDocument = {
					...values,
					communityIds: values.communityIds.map(x => parseInt(x)),
					communityPortalIds: values.communityPortalIds.map(x => parseInt(x))
				}

				if (!!giverOrgToEdit && giverOrgToEdit.giverOrgId) {
					const giverOrgEdit: IPutGiverOrgRequestDocument = {
						giverOrgId: giverOrgToEdit.giverOrgId,
						...giverOrg
					}

					await makeHttpRequestWithUi({
						request: GiverApi.apiGiverGiverOrgGiverOrgIdPut(giverOrgToEdit.giverOrgId, giverOrgEdit),
						toastSuccessMessage: 'Successfully edited Giver.',
						toastErrorMessage: 'Error creating Giver.'
					})
				} else {
					await makeHttpRequestWithUi({
						request: GiverApi.apiGiverGiverOrgPost(giverOrg),
						toastSuccessMessage: 'Successfully created Giver.',
						toastErrorMessage: 'Error creating Giver.'
					})
				}

				if (onSaveSucces) onSaveSucces()
			}}
		>
			{formikProps => {
				_formikProps = formikProps
				return (
					<div className='d-flex flex-column mx-2' style={{ height: '100%' }}>
						<Form className='d-flex flex-column' style={{ flex: 1 }}>
							<div className='d-flex p-1' style={{ flex: 1 }}>
								{/* Left Column */}
								<div className='d-flex flex-column pr-3 border-right pt-3' style={{ flex: 1 }}>
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'name', label: 'Org Name' }} /></div>
										<div><TextField fieldProps={{ name: 'abbr', label: 'Org Abbreviation' }} /></div>
									</div>
									<CommunitySelectField
                                        fieldProps={{ name: 'communityIds', label: 'Provides Funding To' }}
										multiple={true}
                                    />
									<CommunitySelectField
                                        fieldProps={{ name: 'communityPortalIds', label: 'Givers Portal Access' }}
										multiple={true}
                                    />
								</div>
								{/* Right Column */}
								<div className='d-flex flex-column pl-3 pt-3' style={{ flex: 1 }}>
									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }}><TextField fieldProps={{ name: 'address1', label: 'Address Line 1' }} /></div>
										<div style={{ flex: 1 }}><TextField fieldProps={{ name: 'address2', label: 'Address Line 2' }} /></div>
									</div>

									<div className='d-flex'>
										<div className='mr-2' style={{ flex: 1 }} ><TextField fieldProps={{ name: 'city', label: 'City' }} /></div>
										<div style={{ flex: 1 }} ><TextField fieldProps={{ name: 'zipCode', label: 'Zip' }} /></div>
									</div>

									<div className='d-flex'>
										<div style={{ flex: 1 }}><StateSelectField fieldProps={{ name: 'stateId', label: 'State', placeholder: 'Select a state...' }} /></div>
									</div>
									<div className='d-flex flex-column'>
										<div className='mr-3'><CheckboxField fieldProps={{ name: 'onlyShowFundedGrants', label: 'Impact Report', labeltooltip: 'Check this box to show grant data from this donor separately from the chapter.' }} /></div>
										<div className='mr-3'><CheckboxField fieldProps={{ name: 'showUnfundedGrants', label: 'Allow Grid Export', labeltooltip: 'Check this box to show grants that do not have a funding source selected.' }} /></div>
										{/* <div className='mr-3'><CheckboxField fieldProps={{ name: 'isArchived', label: 'Archived', labeltooltip: 'Archived Donors will not show on the list of funders for grants. Donors will auto-archive after 12 months of not funding any grants.' }} /></div> */}
									</div>
								</div>
							</div>
							<div className='mb-4 mt-2'>
								<button type='submit' style={{ minWidth: 300 }} className='btn btn-primary'>{!!giverOrgToEdit ? 'Update' : 'Create'}</button>
							</div>
						</Form>
					</div>
				)
			}}
		</Formik>
	)
}