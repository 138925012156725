import { useContext, useEffect, useState } from "react"
import { IDefaultProps } from "./component-definitions"
import { AppActionContext } from "../app-store-provider"
import { Helmet } from "react-helmet"
import { Modal } from "./modal"
import { Grid } from "./grid"
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks"
import { GridDataFetch, IGridListItem, IGridRowAction, IGridState } from "../stores/grid-definitions"
import { IFilter } from "../stores/api-actions"
import { filterGridListItems, sortListBySorts } from "../services/helpers"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { ICallListModelDocument, ICallListRecordDocument } from "../open-api"
import { DefaultGridCellDisplay, GridActionCell } from "./grid-cell-displays"
import { ArchiveIcon, PencilIcon, CallChatIcon, ArrowBarUpIcon } from "../assets"
import { CallListModel } from "../models/call-list"
import { MinistryManagementCreateCallList } from "./ministry-management-create-call-list"

interface ICallListsProps extends IDefaultProps {}

export const CallLists = (props: ICallListsProps) => {
    const { CallListsApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const [callLists, setCallLists] = useState<ICallListRecordDocument[]>()

    const [callListToEdit, setCallListToEdit] = useState<ICallListModelDocument>()
    const [callListFormModal, showHideCallListFormModal] = useModal()

    
    const [createCallListModal, showHideCreateCallListModal] = useModal()
    const [archiveModal, showHideArchiveModal] = useModal()
    const [listToArchive, setListToArchive] = useState<{ callListId: string | null }>({
		callListId: null
	})

    const [unArchiveModal, showHideUnArchiveModal] = useModal()

    const fetchRecords = async () => {
        const callListsQuery = await makeHttpRequestWithUi({
            request: CallListsApi.apiCallListsGet(Boolean(gridState.queryState.filters?.find(o => o.id === showArchivedFilter.id)?.value)),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch call lists.'
        })

        setCallLists(callListsQuery.data)
    }

    const dataSource: GridDataFetch<ICallListRecordDocument[]> = async (_queryState, _callLists) => {
        if (!_callLists) return { count: 0, rows: [] }

        if (_queryState.sorts) sortListBySorts(_callLists, _queryState.sorts)

        let rows = _callLists.map(CallListModel.toGridListItem)

        console.log('queryState.sorts', _queryState.sorts)
        
        if (_queryState.filters) rows = filterGridListItems(rows, _queryState.filters.filter(x => x.id != showArchivedFilter.id))

        return {
            count: rows.length,
            rows,
        }
    }

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()

    const rowActions: { [id: string]: IGridRowAction } = {}

    rowActions.viewCallList = {
        id: 'viewCallList',
        action: async (options) => options.e.stopPropagation(),
        icon: <CallChatIcon />,
        tooltipText: 'View Call List',
        url: (row) => `/call-list-summary/${row.id}`
    }

    rowActions.editCallList = {
        id: 'editCallList',
        action: async (options) => {
            setSelectedRow(options.row)
		    showHideCallListFormModal(true)

            const queryResult = await makeHttpRequestWithUi({
                request: CallListsApi.apiCallListsIdGet(parseInt(options.row.id)),
                disableSuccessToast: true,
                toastErrorMessage: 'There was an error retrieving the giver to edit.'
            })

            setCallListToEdit(queryResult.data)
        },
        icon: <PencilIcon />,
        tooltipText: 'Edit Call List'
    }

    rowActions.archiveCallList = {
        id: 'archiveCallList',
        action: async (options) => {
            options.e.stopPropagation()
            setListToArchive({
                callListId: options.row.id
            })
            showHideArchiveModal(true);
        },
        icon: <ArchiveIcon />,
        tooltipText: 'Archive Call List',
        hidden: (row) => !!row.values.bArchived
    }

    rowActions.unArchiveCallList = {
        id: 'unArchiveCallList',
        action: async (options) => {
            options.e.stopPropagation()
            setListToArchive({
                callListId: options.row.id
            })
            showHideUnArchiveModal(true);
        },
        icon: <ArrowBarUpIcon />,
        tooltipText: 'Un-Archive Call List',
        hidden: (row) => !row.values.bArchived
    }

    const showArchivedFilter: IFilter = {
        id: 'showArchivedFilter',
        value: false,
        operator: 'eq',
        property: 'showArchived',
        enabled: true,
        hidden: true
    }

    const initialGridState: IGridState = {
        ...defaultGridState,
        queryState: {
            ...defaultGridState.queryState,
            filters: [showArchivedFilter]
        },
        gridActions: [
			{
				id: 'createCallList',
				label: 'Create Call List',
				onClick: (_gridState, _gridActions) => {
                    showHideCreateCallListModal(true)
                }
			},
            {
				id: 'toggleShowArchived',
				label: (_gridState) => _gridState.queryState.filters?.find(o => o.id === showArchivedFilter.id)?.value ? 'Hide Archived' : 'Show Archived',
				onClick: (_gridState, _gridActions) => {
                    const _filters = [..._gridState.queryState.filters || []]
                    const _showArchivedFilter = _filters.find(o => o.id === showArchivedFilter.id)
                    
                    if (_showArchivedFilter) {
                        _showArchivedFilter.value = !_showArchivedFilter.value
                        _gridActions.updateColumnFilters(_filters)
                    }
                }
			}
		],
        columns: [
            {
                property: 'dateCreated',
                type: 'date',
                width: 91,
                allowFilters: true,
                title: 'Created',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'title',
                type: 'string',
                width: 290,
                allowFilters: true,
                title: 'Title',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'grid_actions',
                type: 'actions',
                width: 110,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center',
            },
            {
                property: 'description',
                type: 'string',
                width: 345,
                allowFilters: true,
                title: 'Description',
                render: DefaultGridCellDisplay
            },
            {
                property: 'creatorName',
                type: 'string',
                width: 120,
                allowFilters: true,
                title: 'Owner',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'calledCount',
                type: 'number',
                width: 65,
                allowFilters: true,
                title: 'Called',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'notCalledCount',
                type: 'number',
                width: 100,
                allowFilters: true,
                title: 'Not Called',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'shareCount',
                type: 'number',
                width: 115,
                allowFilters: true,
                title: 'Shared With',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'ministryCount',
                type: 'number',
                width: 98,
                allowFilters: true,
                title: 'Ministries',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'prospectCount',
                type: 'number',
                width: 99,
                allowFilters: true,
                title: 'Prospects',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'noteCount',
                type: 'number',
                width: 69,
                allowFilters: true,
                title: 'Notes',
                render: DefaultGridCellDisplay,
            },
            {
                property: 'lastCallDate',
                type: 'date',
                width: 100,
                allowFilters: true,
                title: 'Last Call',
                render: DefaultGridCellDisplay,
            },
        ],
        dataSource,
        usingLocalData: true,
        disabledPagination: true,
        rowActions,
        rowSelectEnabled: false,
        respectGlobalCommunityFilter: false
    }

    const [gridState, actions] = useGrid(gridReducer, initialGridState, callLists)

    useEffect(() => {
        actions.doFetch()
    }, [callLists])

    useEffect(() => {
        fetchRecords()
    }, [gridState.queryState.filters])
    
    return (
        <>
            <Helmet>
                <title>{ gridState.queryState.filters?.find(x => x.id === showArchivedFilter.id)?.value ? 'Archived' : '' } Call Lists</title>
            </Helmet>
            <div className='d-flex flex-column'>
                <div className='m-2 d-flex align-items-center'>
                    <CallChatIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>{ gridState.queryState.filters?.find(x => x.id === showArchivedFilter.id)?.value ? 'Archived' : '' } Call Lists</h3>
                </div>
                <Grid state={gridState} actions={actions} style={{height: 'auto'}} />
            </div>
            <Modal
                {...callListFormModal}
                modalTitle='Edit Call List'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    setCallListToEdit(undefined)
                    showHideCallListFormModal(false)
                }}
            >
                {callListToEdit ? 
                    <MinistryManagementCreateCallList
                        callListToEdit={callListToEdit}
                        onCancel={() => {
                            setSelectedRow(undefined)
                            showHideCallListFormModal(false)
                            setCallListToEdit(undefined)
                        }}
                        onSave={() => { 
                            showHideCallListFormModal(false)
                            setSelectedRow(undefined)
                            setCallListToEdit(undefined)
                            fetchRecords()
                        }
                    } /> 
                : 
                null }
            </Modal>
            <Modal
                {...archiveModal}
                modalTitle='Archive Call List'
                footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideArchiveModal(false)}>Cancel</button>
						<button
							className='btn btn-danger'
							onClick={async () => {
								if (!listToArchive.callListId) return
                                await makeHttpRequestWithUi({
                                    request: CallListsApi.apiCallListsIdArchivePut(parseInt(listToArchive.callListId)),
                                    toastSuccessMessage: 'Successfully archived the Call List.',
                                    toastErrorMessage: 'There was an error archiving the Call List.'
                                })
                                showHideArchiveModal(false)
                                fetchRecords()
							}}
						>
                        ARCHIVE
						</button>
					</>
				}
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    showHideArchiveModal(false)
                }}
            >
                Are you sure you want to archive this call list?
            </Modal>
            <Modal
                {...unArchiveModal}
                modalTitle='Un-Archive Call List'
                footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideUnArchiveModal(false)}>Cancel</button>
						<button
							className='btn btn-primary'
							onClick={async () => {
								if (!listToArchive.callListId) return
                                await makeHttpRequestWithUi({
                                    request: CallListsApi.apiCallListsIdUnarchivePut(parseInt(listToArchive.callListId)),
                                    toastSuccessMessage: 'Successfully un-archived the Call List.',
                                    toastErrorMessage: 'There was an error un-archiving the Call List.'
                                })
                                showHideUnArchiveModal(false)
                                fetchRecords()
							}}
						>
                        Un-Archive
						</button>
					</>
				}
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                    showHideUnArchiveModal(false)
                }}
            >
                Are you sure you want to un-archive this call list and make it active again?
            </Modal>
            <Modal
                {...createCallListModal}
                modalTitle='Create Call List'
                footer={
					<>
						<button className='btn btn-secondary' onClick={() => showHideCreateCallListModal(false)}>Cancel</button>
						<button
							className='btn btn-primary'
							onClick={async () => {
								window.location.href = '/ministries'
							}}
						>
                        Continue
						</button>
					</>
				}
                _onModalHidden={() => {
                    showHideCreateCallListModal(false)
                }}
            >
                Use the 'Create Call List' button at the top of the Ministry Management section to create
                a new call list.
            </Modal>
        </>
    )
}