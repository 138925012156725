import { IMinistryDataFiscalYearSummaryDocument } from '../open-api'
import { IGridListItem, IGridColumn } from '../stores/grid-definitions'
import { DefaultGridCellDisplay, CurrencyDisplay, PercentDisplay, GridActionCell } from '../components/grid-cell-displays'

export class MinistryFyData {
	public static toGridListItem = (fyData: IMinistryDataFiscalYearSummaryDocument): IGridListItem => {
		return {
			id: fyData.ministryDataId.toString(),
			values: {
				...fyData,
				fiscalYear: fyData.fiscalYear,
				income: fyData.income,
				gsIncome: fyData.gsIncome,
				incomeDiff: fyData.incomeDiff,
				expenses: fyData.expenses,
				gsExpenses: fyData.gsExpenses,
				numEvents: fyData.numEvents,
				eventAttendance: fyData.eventAttendance,
				donorsAcquired: fyData.donorsAcquired,
				majorDonorsAcquired: fyData.majorDonorsAcquired,
				totalDonors: fyData.totalDonors,
				totalMajorDonors: fyData.totalMajorDonors,
				totalServed: fyData.totalServed,
				fulltimeStaff: fyData.fulltimeStaff,
				parttimeStaff: fyData.parttimeStaff,
				amtChurchSponsor: fyData.amtChurchSponsor,
				amtFeeService: fyData.amtFeeService,
				amtGrantWrite: fyData.amtGrantWrite,
				amtMajorDonor: fyData.amtMajorDonor,
				amtOnlineGiving: fyData.amtOnlineGiving,
				amtDirectMail: fyData.amtDirectMail,
				amtSpecialEvent: fyData.amtSpecialEvent,
				amtGik: fyData.amtGik,
				amtOther: fyData.amtOther,
				ministryFileId: fyData.ministryFileId,
				gsDiscrepancyExplanation: fyData.gsDiscrepancyExplanation,
				gs990MissingExplanation: fyData.gs990MissingExplanation,
				bIgnoreDiscrepancy: fyData.bIgnoreDiscrepancy,
				downloadedFromGS: fyData.downloadedFromGS,
			}
		}
	}
}

export const ministryFyDataGeneralDataColumns: IGridColumn[] = [
	{
		property: 'grid_actions',
		type: 'actions',
		width: 100,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	},
	{
		property: 'fiscalYear',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'Fiscal Year',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'income',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'Income',
		render: CurrencyDisplay,
		description: "Income data entered by the ministry leader."
	},
	{
		property: 'gsIncome',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'GS Income',
		render: CurrencyDisplay,
		description: "Income data received from GuideStar."
	},
	{
		property: 'incomeDiff',
		type: 'number',
		width: 165,
		allowFilters: true,
		title: 'Income Diff',
		render: PercentDisplay,
		description: 'The difference between income value the ministry leader entered and the income value GuideStar provided. Highlighted red when it is 5% low or 3% high.',
		conditionalCellCSSFormatting: (row) => {
			// (see description of the column above for explanation of this conditional formatting)
			const incomeDiff = row.values.incomeDiff
			if (typeof incomeDiff === 'number' && incomeDiff !== 0) {
				if (incomeDiff <= 5 || incomeDiff >= 3) return 'bg-danger-7'
			}

			return ''
		},
		tooltip: (row) => {
			// The === does deep type comparison. This only evaluates as true if bIgnoreDiscrepancy is actually a boolean set to true.
			if (row.values.bIgnoreDiscrepancy === true) return 'This discrepancy has been grandfathered and can be ignored.'
			return undefined
		}
	},
	{
		property: 'expenses',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'Expenses',
		render: CurrencyDisplay,
		description: "Expense data entered by the ministry leader."
	},
	{
		property: 'gsExpenses',
		type: 'number',
		width: 165,
		allowFilters: true,
		title: 'GS Expenses',
		render: CurrencyDisplay,
		description: "Expense data received from GuideStar."
	},
	{
		property: 'numEvents',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: '# Events',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'eventAttendance',
		type: 'number',
		width: 160,
		allowFilters: true,
		title: 'Attendance',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'donorsAcquired',
		type: 'number',
		width: 170,
		allowFilters: true,
		title: 'New Donors',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'majorDonorsAcquired',
		type: 'number',
		width: 225,
		allowFilters: true,
		title: 'New Major Donors',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'totalDonors',
		type: 'number',
		width: 180,
		allowFilters: true,
		title: 'Total Donors',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'totalMajorDonors',
		type: 'number',
		width: 225,
		allowFilters: true,
		title: 'Total Major Donors',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'totalServed',
		type: 'number',
		width: 170,
		allowFilters: true,
		title: '# Served',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'fulltimeStaff',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'FT Staff',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'parttimeStaff',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'PT Staff',
		render: DefaultGridCellDisplay,
	},
]

export const ministryFyDataIncomeSourcesColumns: IGridColumn[] = [
	{
		property: 'grid_actions',
		type: 'actions',
		width: 100,
		disableSort: true,
		title: 'Actions',
		render: GridActionCell,
	},
	{
		property: 'fiscalYear',
		type: 'number',
		width: 153,
		allowFilters: true,
		title: 'Fiscal Year',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'amtChurchSponsor',
		type: 'number',
		width: 200,
		allowFilters: true,
		title: 'Church Sponsor',
		render: CurrencyDisplay,
	},
	{
		property: 'amtFeeService',
		type: 'number',
		width: 175,
		allowFilters: true,
		title: 'Fee Service',
		render: CurrencyDisplay,
	},
	{
		property: 'amtGrantWrite',
		type: 'number',
		width: 160,
		allowFilters: true,
		title: 'Grant Write',
		render: CurrencyDisplay,
	},
	{
		property: 'amtMajorDonor',
		type: 'number',
		width: 200,
		allowFilters: true,
		title: 'Major Donor',
		render: CurrencyDisplay,
	},
	{
		property: 'amtOnlineGiving',
		type: 'number',
		width: 200,
		allowFilters: true,
		title: 'Online Giving (Archived)',
		render: CurrencyDisplay,
		description: 'This has been archived. Ministries should not be using this anymore.',
		hide: true
	},
	{
		property: 'amtDirectMail',
		type: 'number',
		width: 200,
		allowFilters: true,
		title: 'Direct Mail',
		render: CurrencyDisplay,
	},
	{
		property: 'amtSpecialEvent',
		type: 'number',
		width: 200,
		allowFilters: true,
		title: 'Special Event',
		render: CurrencyDisplay,
	},
	{
		property: 'amtGik',
		type: 'number',
		width: 200,
		allowFilters: true,
		title: 'Gifts in Kind',
		render: CurrencyDisplay,
	},
	{
		property: 'amtOther',
		type: 'number',
		width: 160,
		allowFilters: true,
		title: 'Other',
		render: CurrencyDisplay,
	},

]