import { IMinistryContactSummaryModelDocument, ILmsUserCourseModelDocument, IMinistriesResponseModelDocument, IContactEditOptionsModelDocument } from '../open-api'
import { ContactInfoTab, IContactInfoState } from "./contact-info-definitions"

export enum ContactInfoActionType {
	loading = 'loading',
	doneLoading = 'doneLoading',
	setContactId = 'setContactId',
	refreshData = 'refreshData',
	setActiveTab = 'setActiveTab',
	setContactCourses = 'setContactCourses',
	fetchMinistry = 'fetchMinistry',
	fetchContact = 'fetchContact',
	fetchContactCourses = 'fetchContactCourses',
	fetchContactEditOptions = 'fetchContactEditOptions'
}

interface IPayloadLessAction {
	type:
	ContactInfoActionType.loading |
	ContactInfoActionType.doneLoading
}

interface ISetContactIdAction {
	type: ContactInfoActionType.setContactId
	payload: number
}

interface ISetContactAction {
	type: ContactInfoActionType.refreshData
	payload: {
		contact: IMinistryContactSummaryModelDocument
		contactCourses: ILmsUserCourseModelDocument[]
	}
}

interface ISetActiveTabAction {
	type: ContactInfoActionType.setActiveTab
	payload: {
		tabId: ContactInfoTab
	}
}

interface ISetCoursesAction {
	type: ContactInfoActionType.setContactCourses
	payload: ILmsUserCourseModelDocument[]
}

interface IFetchMinistryAction {
	type: ContactInfoActionType.fetchMinistry
	payload: IMinistriesResponseModelDocument
}

interface IFetchContactAction {
	type: ContactInfoActionType.fetchContact
	payload: IMinistryContactSummaryModelDocument
}

interface IFetchContactEditOptionsAction {
	type: ContactInfoActionType.fetchContactEditOptions
	payload: IContactEditOptionsModelDocument
}

interface IFetchContactCoursesAction {
	type: ContactInfoActionType.fetchContactCourses
	payload: ILmsUserCourseModelDocument[]
}


export type ContactInfoAction =
	IPayloadLessAction |
	ISetContactIdAction |
	ISetContactAction |
	ISetActiveTabAction |
	ISetCoursesAction |
	IFetchMinistryAction |
	IFetchContactAction |
	IFetchContactEditOptionsAction |
	IFetchContactCoursesAction
	//ISetGsReportAction


export const contactInfoReducer = (state: IContactInfoState, action: ContactInfoAction): IContactInfoState => {
	let newState = state

	switch (action.type) {
		case ContactInfoActionType.loading:
			newState = { ...newState, loading: true }
			break
		case ContactInfoActionType.doneLoading:
			newState = { ...newState, loading: false }
			break
		case ContactInfoActionType.setContactId:
			newState = { ...newState, contactId: action.payload }
			break
		case ContactInfoActionType.setContactCourses:
			newState = { ...newState, contactCourses: action.payload }
			break
		case ContactInfoActionType.fetchMinistry:
			newState = { ...newState, ministry: action.payload }
			break
		case ContactInfoActionType.fetchContact:
			newState = { ...newState, contact: action.payload }
			break
		case ContactInfoActionType.refreshData:
			newState = {
				...newState,
				loading: false,
				...action.payload,
			}
			break
		case ContactInfoActionType.setActiveTab:
			newState = { ...newState, activeTab: action.payload.tabId }
			break
		case ContactInfoActionType.fetchContactEditOptions:
			newState = { ...newState, contactEditOptions: action.payload }
			break
		case ContactInfoActionType.fetchContactCourses:
			newState = { ...newState, contactCourses: action.payload }
			break
	}

	return newState
}