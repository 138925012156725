import React, { useContext, useEffect } from 'react'
import { GridDataFetch, IGridState } from '../stores/grid-definitions'
import { IMinistryInfoState } from '../stores/ministry-info-definitions'
import { filterGridListItems, sortListBySorts } from '../services/helpers'
import { GrantModel, grantModelDefaultColumns } from '../models/grant'
import { useGrid, defaultGridState } from '../stores/grid-actions'
import { Grid } from './grid'
import { gridReducer } from '../stores/grid-reducer'
import { MinistryInfoContext } from './ministry-info'
import { Loading } from './loading'
import { EyeIcon } from '../assets'

export const MinistryInfoGrants = () => {

	const { state: ministryInfoState, actions: ministryInfoActions } = useContext(MinistryInfoContext)!

	const fetchMinistryGrants: GridDataFetch<IMinistryInfoState> = async (queryState, _state) => {
		const { ministryGrants } = _state

		if (!ministryGrants) return { count: 0, rows: [] }

		if (queryState.sorts) sortListBySorts(ministryGrants, queryState.sorts)

		let rows = ministryGrants.map(ministryGrant => GrantModel.toGridListItem(ministryGrant))

		if (queryState.filters) rows = filterGridListItems(rows, queryState.filters)

		return { rows, count: rows.length, }
	}

	const initialGridState: IGridState = {
		...defaultGridState,
		usingLocalData: true,
		rowSelectEnabled: false,
		columns: grantModelDefaultColumns,
		rowActions: {
			viewCallList: {
				id: 'viewCallList',
				action: async (options) => options.e.stopPropagation(),
				icon: <EyeIcon />,
				tooltipText: 'View Grant',
				url: (row) => `/grant-request/${row.id}`,
			}
		},
		disabledPagination: true,
		dataSource: fetchMinistryGrants
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, ministryInfoState)

	useEffect(() => {
		console.log('fetchingGrants')
		ministryInfoActions.fetchGrants()
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		gridActions.doFetch()
		//eslint-disable-next-line
	}, [
		ministryInfoState.ministryGrants,
		ministryInfoState.ministry // Whenever the ministry changes, refresh the data for the grid.
	])

	if (!ministryInfoState.ministryGrants || !ministryInfoState.ministry) return <Loading />

	return (
		<React.Fragment>
			<Grid state={gridState} actions={gridActions} style={{ height: '100%' }} />
		</React.Fragment>
	)
}