import React, { useEffect, useContext, useState } from 'react'
import { AppActionContext } from '../app-store-provider'
import { IProspectSearchResultModelDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { Tabs } from './tabs'
import { ProspectDetailGeneral } from './prospect-detail-general'
import { ProspectDetailNotes } from './prospect-detail-notes'
import { ProspectDetailEmailLog } from './prospect-detail-email-log'
import { uuidv4 } from '../services/helpers'
import { IDefaultProps } from './component-definitions'
// import { ReactComponent as StarIcon } from '../assets/star.svg'
// import { ReactComponent as StarFillIcon } from '../assets/star-fill.svg'
import { ReactComponent as ProspectsIcon } from '../assets/bullseye.svg'
import { Helmet } from 'react-helmet'
import { Loading } from './loading'

interface IProspectDetailProps extends IDefaultProps {
	prospectId?: string
}
export const ProspectDetail = (props: IProspectDetailProps) => {
	const prospectId = parseInt(props.prospectId!)

	const appActions = useContext(AppActionContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const [prospect, setProspect] = useState<IProspectSearchResultModelDocument>()

	const fetchProspect = () => makeHttpRequestWithUi({
		request: appActions.ProspectsApi.apiProspectsIdGet(prospectId),
		disableSuccessToast: true,
		toastErrorMessage: 'Error retrieving prospect details.'
	}).then(results => {
		/* Going to presume that, even though the return type of this endpoint is an array, it will only ever return one result since it is a getById endpoing. */
		if (results.data.data) {
			if (results.data.data.length > 1) {
				appActions.addAlert({
					id: uuidv4(),
					title: 'Error',
					body: `Unexpected number of Prospects returned: ${results.data.totalCount}`
				})
				throw new Error(`Unexpected number of Prospects returned: ${results.data.totalCount}`)
			} else {
				setProspect(results.data.data[0])
			}
		} else {
			appActions.addAlert({
				id: uuidv4(),
				title: 'Error',
				body: `We were not able to find any Prospect records for this prospect ID: ${props.prospectId}`
			})
		}
	})

	useEffect(() => {
		fetchProspect()
	}, [prospectId])

	if (!prospect) return <Loading />

	const displayName = `${prospect.firstName} ${prospect.lastName} ${prospect.organizationName ? `(${prospect.organizationName})` : null}`

	return (
		<div className='bg-light' style={{ height: '100vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>

			<Helmet>
				<title>{displayName}</title>
			</Helmet>

			<div className='p-2 d-flex align-items-center'>
				<ProspectsIcon style={{ width: '25px', height: '25px' }} />
				<h3 className='ml-2'>{displayName}</h3>
				{/* <div className='ml-2'>
					{appState.currentUserSessionState?.favorites?.prospects?.find(p => p.prospectId === prospect.prospectId) ?
						<StarFillIcon
							style={{ cursor: 'pointer' }}
							className='text-warning' // Bootstrap's 'warning' color makes a good gold star color...
							onClick={async () => {
								const taskId = registerLoadingTask()
								await appActions.UserSessionActions.removeProspectFromCurrentUserFavorites(prospect)
								deregisterLoadingTask(taskId)
							}}
						/>
						:
						<StarIcon
							style={{ cursor: 'pointer' }}
							onClick={async () => {
								const taskId = registerLoadingTask()
								await appActions.UserSessionActions.addProspectToCurrentUserFavorites(prospect)
								deregisterLoadingTask(taskId)
							}}
						/>
					}
				</div> */}
			</div>
			<Tabs tabs={[
				{
					id: 'general',
					title: 'General',
					content: <ProspectDetailGeneral prospect={prospect} onProspectUpdated={fetchProspect} />
				},
				{
					id: 'notes',
					title: 'Notes',
					content: <ProspectDetailNotes prospectId={prospect.prospectId} />
				},
				{
					id: 'email',
					title: 'Email Log',
					content: <ProspectDetailEmailLog prospectId={prospect.prospectId} />
				}
			]} />
		</div>
	)
}