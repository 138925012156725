import { IBodyResultDocument, IGrantRequestSummaryDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api"
import { IDefaultProps } from "./component-definitions"
import { useHTTPRequestUiWrapper, useMailLogDefaultColumns, useModal } from "../services/hooks"
import { AppActionContext, AppStateContext } from "../app-store-provider"
import { useContext, useState } from "react"
import { MAIL_TYPE_GROUP } from "../constants"
import { GridDataFetch, IGridListItem } from "../stores/grid-definitions"
import { IAppState } from "../stores/app-definitions"
import { isNullOrUndefined } from "util"
import { MalLogModel } from "../models/mail-log"
import { Grid } from "./grid"
import { Modal } from "./modal"
import { defaultGridState, useGrid } from "../stores/grid-actions"
import { gridReducer } from "../stores/grid-reducer"
import { getOrCreateFilterForColumnPropertyAndOperator } from "./grid-column-filter"
import { AlertTriangleIcon, BuildingIcon, SearchIcon } from "../assets"

interface IGrantRequestFormProps extends IDefaultProps {
    grantRequestToEdit: IGrantRequestSummaryDocument
}

export const GrantRequestFormSummaryEmail = (props: IGrantRequestFormProps) => {
    const { grantRequestToEdit } = props
    const { MailEngineApi } = useContext(AppActionContext)!
    const appState = useContext(AppStateContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const dataSource: GridDataFetch<IAppState> = async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

        try {
            const prospectsQuery = await makeHttpRequestWithUi({
                request: MailEngineApi.apiMailEngineGet((queryState.page - 1) * queryState.itemsPerPage, queryState.itemsPerPage, sorts, filters),
                disableLoading: true,
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the email log.'
            })

            return {
                rows: prospectsQuery.data.data ? prospectsQuery.data.data.map(MalLogModel.toGridListItem) : [],
                count: prospectsQuery.data.totalCount
            }
        } catch (e) {
            return {
                rows: [],
                count: 0
            }
        }
    }
    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [bodyObj, setBodyObj] = useState<IBodyResultDocument>()

    const [viewEmailModal, showHideViewEmailModal] = useModal()
    const [errorLogModal, showHideErrorLogModal] = useModal()
    const [confirmResendModal, showHideConfirmResendModal] = useModal()

    const [gridState, gridActions] = useGrid(
        gridReducer,
        {
            ...defaultGridState,
            //userSessionStateKey: GridUserInteractionStateKey.MailLog,
            queryState: {
                ...defaultGridState.queryState,
                filters: [ // Only return emails related to this specific grant request.
                    {
                        ...getOrCreateFilterForColumnPropertyAndOperator([], 'foreignKey2', 'eq'),
                        enabled: true,
                        value: grantRequestToEdit.grantId
                    },
                    {
                        ...getOrCreateFilterForColumnPropertyAndOperator([], 'mailTypeGroupId', 'eq'),
                        enabled: true,
                        value: MAIL_TYPE_GROUP.Grants 
                    }
                ],
                sorts: [
                    {
                        property: 'dSent',
                        direction: 'DESC'
                    }
                ]
            },
            loading: true,
            columns: useMailLogDefaultColumns(),
            dataSource,
            rowDoubleClicked: async (row) => {
                setSelectedRow(row)
                showHideViewEmailModal(true)
            },
            rowActions: {
                viewEmail: {
                    id: 'viewEmail',
                    action: async (options) => {
                        options.e.stopPropagation()
                        setSelectedRow(options.row)

                        const response = await makeHttpRequestWithUi({
                            request: MailEngineApi.apiMailEngineMailIdBodyGet(parseInt(options.row.id)),
                            disableLoading: true,
                            disableSuccessToast: true,
                            toastErrorMessage: 'There was a problem fetching the email body.'
                        })

                        setBodyObj(response.data)

                        showHideViewEmailModal(true)
                    },
                    icon: <div style={{ margin: '0 2px' }}><SearchIcon /></div>,
                    // tooltipText: (row) => !row.values.hasPassword ? 'View Email' : 'Cannot show email body that appears to contain a password.',
                    // disabled: (row) => !!row.values.hasPassword
                },
                ministryInfo: {
                    id: 'ministryInfo',
                    action: async (options) => options.e.stopPropagation(),
                    icon: <BuildingIcon />,
                    tooltipText: 'View Ministry',
                    hidden: (row) => !row.values.ministryId,
                    url: (row) => `/ministry-info/${row.values.ministryId}`
                },
                emailError: {
                    id: 'emailError',
                    action: async (options) => {
                        options.e.stopPropagation()
                        setSelectedRow(options.row)
                        showHideErrorLogModal(true)
                    },
                    icon: <AlertTriangleIcon />,
                    tooltipText: 'View Error Log',
                    hidden: (row) => !row.values.errorData
                },
            },
            gridActions: [
                {
                    id: 'resendEmails',
                    label: 'Resend Emails',
                    disabled: (gridState) => gridState.rows.filter(o => o.selected).length <= 0,
                    onClick: () => showHideConfirmResendModal(true),
                },
                {
                    id: 'refreshResults',
                    label: 'Refresh',
                    onClick: (state, actions) => {
                            actions.doFetch()
                    },
                }
            ],
            rowSelectEnabled: false
        },
        appState
    )

    return (
        <>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <Grid state={gridState} actions={gridActions} />
            </div>

            <Modal
                {...viewEmailModal}
                modalTitle='Email Body'
                size='xl'
                _onModalHidden={
                    () => {
                        setSelectedRow(undefined)
                        setBodyObj(undefined)
                    }
                }>
                    { 
                        bodyObj ? 
                        <>
                            { 
                                !bodyObj.hasPassword ? 
                                <div dangerouslySetInnerHTML={{ __html: typeof bodyObj.body === 'string' ? bodyObj.body : '' }}></div>
                                :
                                <div>The email body appears to contain a password. Because of this, we cannot display the contents of the body.</div>
                            }
                        </>
                        :
                        null
                    }
            </Modal>

            <Modal
                {...errorLogModal}
                modalTitle='Error Detail'
                size='lg'
                _onModalHidden={() => setSelectedRow(undefined)}>
                <div dangerouslySetInnerHTML={{ __html: typeof selectedRow?.values.errorData === 'string' ? selectedRow?.values.errorData : '' }}></div>
            </Modal>

            <Modal
                {...confirmResendModal}
                modalTitle='Confirm'
                footer={
                    <>
                        <button type='button' className='btn btn-secondary' onClick={() => showHideConfirmResendModal(false)}>Cancel</button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={async () => {
                                if (gridState.rows.filter(r => r.selected).length > 0) {
                                    await makeHttpRequestWithUi({
                                        request: MailEngineApi.apiMailEngineResendPost(gridState.rows.filter(r => r.selected).map(r => parseInt(r.id))),
                                        toastSuccessMessage: 'Successfully resent emails.',
                                        toastErrorMessage: 'Encountered an error resending emails.',
                                    })
                                    showHideConfirmResendModal(false)
                                    gridActions.doFetch()
                                }

                            }}
                        >
                            Resend
                        </button>
                    </>
                }
            >
                Are you sure you want to resend the {gridState.rows.filter(r => r.selected).length} selected email{gridState.rows.filter(r => r.selected).length === 1 ? '' : 's'}?
            </Modal>
        </>
    )
}