import React, { useContext } from "react"
import { IMinistryEvalsPutDocument } from "../open-api"
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { Loading } from "./loading"
import { Formik, Form } from "formik"
import { YesNoField, TextareaField } from "./forms"
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AreaDirectorEvalContext } from "./ministry-info-area-director-eval"
import moment from "moment"

interface IMinistryInfoAreaDirectorEvalOperationsProps {
    afterSaveNext?: () => void // Pass in a function to be called after the form saves (e.g. to close a modal or refresh data)
    afterSavePrevious?: () => void
    readonly?: boolean
}

export const MinistryInfoAreaDirectorEvalOperations = (props: IMinistryInfoAreaDirectorEvalOperationsProps) => {
    const { afterSaveNext, afterSavePrevious, readonly = false } = props

    const appState = useContext(AppStateContext)!
    const appActions = useContext(AppActionContext)!
    const areaDirectorEval = useContext(AreaDirectorEvalContext)!

    const makeHTTPRequestWithUi = useHTTPRequestUiWrapper()

    if (!areaDirectorEval.ministryEvalState) return <Loading />

    return (
        <Formik
            initialValues={{
                bAudience: areaDirectorEval.ministryEvalState.bAudience,
                bEffectiveness: areaDirectorEval.ministryEvalState.bEffectiveness,
                bOutcomes: areaDirectorEval.ministryEvalState.bOutcomes,
                bReviews: areaDirectorEval.ministryEvalState.bReviews,
                bSufficient: areaDirectorEval.ministryEvalState.bSufficient,
                bResources: areaDirectorEval.ministryEvalState.bResources,
                bTrains: areaDirectorEval.ministryEvalState.bTrains,
                bEvangelism: areaDirectorEval.ministryEvalState.bEvangelism,
                operationsPrograms: areaDirectorEval.ministryEvalState.operationsPrograms || '',
                tabDirection: null
            }}
            onSubmit={async (values) => {
                const evalRecord: IMinistryEvalsPutDocument = {
                    ...areaDirectorEval.ministryEvalState,
                    bAudience: values.bAudience,
                    bEffectiveness: values.bEffectiveness,
                    bOutcomes: values.bOutcomes,
                    bReviews: values.bReviews,
                    bSufficient: values.bSufficient,
                    bResources: values.bResources,
                    bTrains: values.bTrains,
                    bEvangelism: values.bEvangelism,
                    operationsPrograms: values.operationsPrograms,
                    dStep4Updated: moment().format(),
                    step4UpdatedBy: appState.currentUser?.userId || null
                }
                await makeHTTPRequestWithUi({
                    request: appActions.MinistryEvalsApi.apiMinistryEvalsIdPut(evalRecord.ministryEvalId, evalRecord),
                    toastSuccessMessage: 'Eval record successfully updated.',
                    toastErrorMessage: 'There was an error updating the eval record.',
                })
                
                if (values.tabDirection === 'previous' && afterSavePrevious) {
                    afterSavePrevious()
                } else if (afterSaveNext) {
                    afterSaveNext()
                }
            }}
        >
            {(formikProps) => {
                const form = <React.Fragment>
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bAudience', 
                            label: 'The organization has a discernible purpose and a defined audience for its programs' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bEffectiveness', 
                            label: 'General goals have been created by the organization to measure its programs\' effectiveness' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bOutcomes', 
                            label: 'The organization has specific outcomes by which it measures success' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bReviews', 
                            label: 'This organization regularly reviews these goals and outcomes and plans their reviews' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bSufficient', 
                            label: 'The organization has sufficient equipment, infrastructure and office space to accomplish it\'s goals' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bResources', 
                            label: 'This organization\'s staff has the resources and expertise to execute each program that the organization offers.' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bTrains', 
                            label: 'This organization effectively trains and organizes volunteers (if relevant)' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    <YesNoField 
                        fieldProps={{ 
                            name: 'bEvangelism', 
                            label: 'Christian evangelism is considered an integral part of the organization\'s program(s)' }}
                            setFieldValue={formikProps.setFieldValue}
                            disabled={readonly} />
                    { !readonly ?
                        <TextareaField
                            fieldProps={{
                                name: 'operationsPrograms',
                                label: 'Additional Notes on Operations & Programs'
                            }} />
                        :
                        null
                    }
                    { !readonly ?
                        <div className='row px-3'>
                            <button className={`btn btn-primary`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'previous')
                                formikProps.submitForm()
                            }}>Save &amp; Previous</button>
                            <button className={`btn btn-primary ml-1`} type='button' onClick={() => {
                                formikProps.setFieldValue('tabDirection', 'next')
                                formikProps.submitForm()
                            }}>Save &amp; Continue</button>
                        </div>
                        :
                        null
                    }
                </React.Fragment>
                // When printing a page, <form> tags (as well as other tags) will cause unintended page breaks. 
                return (
                    readonly ? form :
                    <Form>
                        {form}
                    </Form>
                )
            }}
        </Formik>
    )
}