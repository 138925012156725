import React, { useEffect } from "react"
import { IAppState, initialAppState } from './stores/app-definitions'
import { IMifActions, useAppActions } from './stores/app-actions'
import { appReducer } from './stores/app-reducer'
import { Loading } from './components/loading'
import { useAuth } from "react-oidc-context";

export const AppActionContext = React.createContext<IMifActions | null>(null)
export const AppStateContext = React.createContext<IAppState>(initialAppState)

export const AppStoreProvider = (props: any) => {
	const [appState, actions] = useAppActions(appReducer, initialAppState)

	const auth = useAuth();
	const isAuthenticated = auth.isAuthenticated;

	useEffect(() => {
		if (!appState.bootstrapped && isAuthenticated) actions.bootstrap()
		// eslint-disable-next-line
	}, [isAuthenticated])

	useEffect(() => {
		if (appState.bootstrapped) actions.fetchTagsSummary()

		// eslint-disable-next-line
	}, [appState.globalCommunityContext])

	return appState.bootstrapped ?
		<AppActionContext.Provider value={actions}>
			<AppStateContext.Provider value={appState}>
				{props.children}
			</AppStateContext.Provider>
		</AppActionContext.Provider>
		:
		<Loading />
}