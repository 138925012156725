import { IGrantStipulationModelDocument, IMinistryGrantStipulationModelDocument } from "../open-api"
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHTTPRequestUiWrapper } from "../services/hooks"
import { AppActionContext } from "../app-store-provider"
import { useContext } from "react"
import { FormikSelectField } from "./forms/formik-select-field"
import { FormikTextAreaField } from "./forms/formik-text-area-field"
import { FormikEffect } from "./formik-effect"

interface IGrantRequestStipulationFormProps {
    stipulationToEdit?: IMinistryGrantStipulationModelDocument
    templates: IGrantStipulationModelDocument[]
    grantId: number
    afterSave: () => Promise<void>
}

export const GrantRequestStipulationForm = (props: IGrantRequestStipulationFormProps) => {
    const { stipulationToEdit, afterSave, grantId, templates } = props
    const { GrantRequestApi } = useContext(AppActionContext)!

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const initialValues = {
        template: '',
        stipulation: stipulationToEdit?.stipulation
    }

    const validationSchema = Yup.object({
        stipulation: Yup.string().required('Required')
    })

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                if (stipulationToEdit) {
                    await makeHttpRequestWithUi({
                        request: GrantRequestApi.apiGrantRequestStipulationsGrantStipulationIdPut( stipulationToEdit.grantStipulationId, {
                            grantStipulationId: stipulationToEdit.grantStipulationId,
                            grantId: grantId,
                            stipulation: values.stipulation || ''
                        }),
                        toastSuccessMessage: 'Successfully updated stipulation.',
                        toastErrorMessage: 'There was an error updating stipulation.'
                    })
                } else {
                    await makeHttpRequestWithUi({
                        request: GrantRequestApi.apiGrantRequestGrantIdStipulationsPost(grantId, {
                            grantId: grantId,
                            stipulation: values.stipulation || ''
                        }),
                        toastSuccessMessage: 'Successfully created stipulation.',
                        toastErrorMessage: 'There was an error creating stipulation.'
                    })
                }

                afterSave && afterSave()
            }}
        >
            {formikProps => {
                return (
                    <Form style={{ padding: 10 }}>
                        <FormikEffect
                            formikProps={formikProps}
                            onChange={(prevValues, nextValues) => {
                                if (!stipulationToEdit && nextValues.template !== prevValues.template) {
                                    formikProps.setFieldValue('stipulation', nextValues.template)
                                }
                            }}
                        />
                        {!stipulationToEdit && 
                            <>
                                <p>Choose from a list of stipulation templates or write your own stipulation from scratch.</p>
                                <FormikSelectField
                                field={{ name: 'template', label: 'Template' }}
                                options={templates?.map(t => ({ label: `${t.stipulation}`, value: `${t.stipulation}` }))}
                                
                            />
                            </>
                        }
                        <FormikTextAreaField
                            field={{name: 'stipulation', label: 'Stipulation'}}
                         />        
                        <button style={{ width: 200 }} className='btn btn-primary mt-4'>Save</button>
                    </Form>
                )
            }}
        </Formik>
    )
}