import { useContext, useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { AppActionContext } from '../app-store-provider'
import { FileMedicalIcon } from '../assets'
import { IGrantRequestSummaryDocument } from '../open-api'
import { useHTTPRequestUiWrapper } from '../services/hooks'
import { IDefaultProps } from './component-definitions'
import { GrantRequestForm } from './grant-request-form'
import { LoadingOverlay } from './loading'
import { HistoryCard } from "./history-card"

interface IGrantRequestDetailProps extends IDefaultProps {
    grantRequestId?: string
}

export const GrantRequestDetail = ({grantRequestId} : IGrantRequestDetailProps) => {
    const { GrantRequestApi } = useContext(AppActionContext)!
    
    const [grantRequestToEdit, setGrantRequestToEdit] = useState<IGrantRequestSummaryDocument>()
    const [triggerRefresh, setTriggerRefresh] = useState<number>(0)

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()
    
    const editGrantRequest = useCallback(async (grantId: string) => {
        const query = await makeHttpRequestWithUi({
            request: GrantRequestApi.apiGrantRequestIdGet(parseInt(grantId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the event to edit.'
        })

        setGrantRequestToEdit(query.data)
    }, [])

    useEffect(() => {
        if (grantRequestId) editGrantRequest(grantRequestId)
    }, [])

    if (!grantRequestToEdit) return <LoadingOverlay />

    return (
        <>
            <Helmet>
                <title>{grantRequestToEdit?.ministryName || ''} - Grant Request {`(${grantRequestToEdit.grantId})`}</title>
            </Helmet>
            <div className='d-flex flex-column' style={{ height: '100vh' }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/grant-requests">Grant Requests</a></li>
                        <li className="breadcrumb-item">Grant Request</li>
                    </ol>
                </nav>
                <div className='m-2 d-flex align-items-center'>
                    <FileMedicalIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>{grantRequestToEdit?.ministryName} - {grantRequestToEdit.isLegacyGrant && <>LEGACY</>} Grant Request ({grantRequestToEdit.grantId})</h3>
                </div>
                <div className='d-flex'>
                    <div className="col-9">
                        <GrantRequestForm
                            grantRequestToEdit={grantRequestToEdit}
                            afterSave={async () => {
                                if (grantRequestId) editGrantRequest(grantRequestId)
                                setTriggerRefresh(triggerRefresh + 1)
                            }}
                        />
                    </div>
                    <div className="col-3">
                        {grantRequestId && !grantRequestToEdit.isLegacyGrant &&
                            <HistoryCard tableName='MinistryGrants' tableId={parseInt(grantRequestId)} triggerRefresh={triggerRefresh} /> }
                    </div>
                </div>
            </div>
        </>
    )
} 