import { IMinistryInfoState, MinistryInfoTab } from "./ministry-info-definitions"
import { MinistryInfoAction, MinistryInfoActionType } from "./ministry-info-reducer"
import { useReducer, useContext } from "react"
import { AppActionContext } from "../app-store-provider"
import { ILeverageSummaryReportDocument } from '../open-api'
import { AxiosResponse } from "axios"

export interface IMinistryInfoActions {
	setLoading: (loading: boolean) => void
	setMinistryId: (ministryId: number) => void
	setActiveTab: (tabId: MinistryInfoTab) => void
	fetchMinistrySummary: () => Promise<void>
	fetchMinistryContacts: () => Promise<void>
	fetchMinistryGeneralChartsData: () => Promise<void>
	fetchMinistryFiles: () => Promise<void>
	fetchMinistryFyData: () => Promise<void>
	fetchEmailLog: () => Promise<void>
	fetchEventEvals: () => Promise<void>
	fetchEventAttendance: () => Promise<void>
	fetchGrants: () => Promise<void>
	fetchNotes: () => Promise<void>
	fetchLeverageSummary: (baselineYear: number) => Promise<AxiosResponse<ILeverageSummaryReportDocument[]>>
	fetchProfileLog: () => Promise<void>
	//fetchGsReport: () => Promise<AxiosResponse<IGsPrintableReportDocument | void> | undefined>
	approveProfile: () => Promise<void>
	denyProfile: () => Promise<void>
	fetchLmsUserCourses: () => Promise<void>
}

export const useMinistryInfoActions = (reducer: React.Reducer<IMinistryInfoState, MinistryInfoAction>, initialState: IMinistryInfoState): [IMinistryInfoState, IMinistryInfoActions] => {
	const appActions = useContext(AppActionContext)!

	const [state, dispatch] = useReducer(reducer, initialState)

	const setLoading = (loading: boolean) => dispatch({ type: loading ? MinistryInfoActionType.loading : MinistryInfoActionType.doneLoading })

	const setMinistryId = (ministryId: number) => dispatch({ type: MinistryInfoActionType.setMinistryId, payload: ministryId })

	const setActiveTab = (tabId: MinistryInfoTab) => dispatch({ type: MinistryInfoActionType.setActiveTab, payload: { tabId } })

	const fetchMinistrySummary = async () => {
		if (state.ministryId) dispatch({ type: MinistryInfoActionType.setMinistrySummary, payload: (await appActions.MinistriesApi.apiMinistriesIdSummaryGet(state.ministryId)).data })
	}

	const fetchMinistryContacts = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setMinistryContacts, payload: (await appActions.MinistryContactsApi.apiMinistriesIdContactsGet(state.ministry.ministryId)).data })
	}

	const fetchMinistryGeneralChartsData = async () => {
		if (state.ministry?.ministryId) dispatch({ type: MinistryInfoActionType.setMinistryGeneralChartData, payload: (await appActions.MinistriesApi.apiMinistriesIdGeneralChartsDataGet(state.ministry.ministryId)).data })
	}

	const fetchMinistryFiles = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setMinistryFiles, payload: (await appActions.MinistryFilesApi.apiMinistriesIdMinistryFilesGet(state.ministry.ministryId)).data })
	}

	const fetchMinistryFyData = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setMinistryFyData, payload: (await appActions.MinistryDataApi.apiMinistriesIdFyDataGet(state.ministry.ministryId)).data })
	}

	const fetchEmailLog = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setEmailLog, payload: (await appActions.MailEngineApi.apiMinistriesIdEmailsGet(state.ministry.ministryId)).data })
	}

	const fetchEventEvals = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setEventEvals, payload: (await appActions.SeminarEvalApi.apiSeminarEvalMinistriesMinistryIdGet(state.ministry.ministryId)).data })
	}

	const fetchEventAttendance = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setEventAttendance, payload: (await appActions.SeminarAttendeesApi.apiMinistriesIdEventAttendanceGet(state.ministry.ministryId)).data })
	}

	const fetchGrants = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setGrants, payload: (await appActions.MinistryGrantsApi.apiMinistriesIdGrantsGet(state.ministry.ministryId)).data })
	}

	const fetchNotes = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setNotes, payload: (await appActions.NotesApi.apiMinistriesIdNotesGet(state.ministry.ministryId)).data })
	}

	const fetchLeverageSummary = async (baselineYear: number) => {
		if (state.ministry) {
			const request = await appActions.ReportsApi.apiReportsLeverageSummaryGet(state.ministry.ministryId, baselineYear)
			dispatch({ type: MinistryInfoActionType.setLeverageSummary, payload: request.data })
			return request
		} else {
			throw new Error('Cannot retrieve leverage summary without ministry ID.')
		}
	}

	// const fetchGsReport = async () => {
	// 	if (state.ministry) {
	// 		try {
	// 			try {
	// 				const request = await appActions.MinistryFilesApi.apiMinistryFilesMinistryIdGsReportGetSignedUrlGet(state.ministry.ministryId, { responseType: 'blob' })
	// 				if (request.status === 200) {
	// 					const data = convertFileDownloadToUrl(request.headers, request.data)
	// 					if (data) {
	// 						dispatch({
	// 							type: MinistryInfoActionType.setGsReport,
	// 							payload: {
	// 								data,
	// 								inline: false,
	// 								failed: false
	// 							}
	// 						})
	// 					}

	// 					return request
	// 				} else {
	// 					const otherRequest = await makeHTTPRequestWithUi({ request: appActions.GsPrintableReportApi.apiMinistriesIdGsPrintableReportGet(state.ministry.ministryId), disableLoading: true, toastErrorMessage: 'Error retrieving GS Report data.', disableSuccessToast: true })
	// 					if (otherRequest.data.gsData) dispatch({
	// 						type: MinistryInfoActionType.setGsReport,
	// 						payload: {
	// 							data: otherRequest.data.gsData,
	// 							inline: true,
	// 							failed: false
	// 						}
	// 					})
	// 					return otherRequest
	// 				}
	// 			} catch (e) {
	// 				const otherRequest = await makeHTTPRequestWithUi({ request: appActions.GsPrintableReportApi.apiMinistriesIdGsPrintableReportGet(state.ministry.ministryId), disableLoading: true, toastErrorMessage: 'Error retrieving GS Report data.', disableSuccessToast: true, disableFailureToast: true })
	// 				if (otherRequest.data.gsData) dispatch({
	// 					type: MinistryInfoActionType.setGsReport,
	// 					payload: {
	// 						data: otherRequest.data.gsData,
	// 						inline: true,
	// 						failed: false
	// 					}
	// 				})
	// 				return otherRequest
	// 			}
	// 		} catch (e) {
	// 			dispatch({ type: MinistryInfoActionType.setGsReport, payload: {data: undefined, inline: true, failed: true} })
	// 			//throw e
	// 		}
	// 	} else {
	// 		throw new Error('Cannot retrieve leverage summary without ministry ID.')
	// 	}
	// }

	const fetchProfileLog = async () => {
		if (state.ministry) dispatch({ type: MinistryInfoActionType.setProfileLog, payload: (await appActions.MinistriesApi.apiMinistriesIdProfileLoggingSummaryGet(state.ministry.ministryId)).data })
	}

	const approveProfile = async () => {
		if (!state.ministry) throw new Error('Cannot approve profile for ministry - no ministry defined.')
		await appActions.MinistryProfileApi.apiMinistryProfileIdApproveProfilePost(state.ministry.ministryId)
		dispatch({ type: MinistryInfoActionType.approveProfile })
	}

	const denyProfile = async () => {
		if (!state.ministry) throw new Error('Cannot deny profile for ministry - no ministry defined.')
		await appActions.MinistryProfileApi.apiMinistryProfileIdDenyProfilePost(state.ministry.ministryId)
		dispatch({ type: MinistryInfoActionType.approveProfile })
	}

	const fetchLmsUserCourses = async () => {
		console.log('MinistryInfoAction > fetchLmsUserCourses')
		if (!state.ministry) throw new Error('Cannot load courses for ministry - no ministry defined.')
		dispatch({ type: MinistryInfoActionType.setMinistryCourses, payload: (await appActions.MinistriesApi.apiMinistriesIdLmsUserCourseInfoGet(state.ministry.ministryId)).data })
    }

	const actions = {
		setLoading,
		setMinistryId,
		setActiveTab,
		fetchMinistryFiles,
		fetchMinistryFyData,
		fetchEmailLog,
		fetchEventEvals,
		fetchEventAttendance,
		fetchGrants,
		fetchNotes,
		fetchLeverageSummary,
		fetchProfileLog,
		//fetchGsReport,
		fetchMinistrySummary,
		fetchMinistryContacts,
		fetchMinistryGeneralChartsData,
		approveProfile,
		denyProfile,
		fetchLmsUserCourses
	}

	return [state, actions]
}