import { IMergeInfoDocument } from '../open-api'
import { IGridListItem, IGridColumn } from '../stores/grid-definitions'
import { DefaultGridCellDisplay } from '../components/grid-cell-displays'
import moment from 'moment'

export class MinistryMergeInfo {
	public static toGridListItem = (item: IMergeInfoDocument): IGridListItem => {
		return {
			id: item.ministryId.toString(),
			values: {
				ministryName: item.ministryName,
				contactName: item.contactName,
				dateRegistered: item.dateRegistered ? moment(item.dateRegistered).toDate() : null,
				contactsCount: item.contactsCount,
				grantsCount: item.grantsCount,
				eventsAttendedCount: item.eventsAttendedCount,
				upcomingEventsCount: item.upcomingEventsCount,
			}
		}
	}
}

export const ministryMergeInfoDefaultColumns: IGridColumn[] = [
	{
		property: 'ministryName',
		type: 'string',
		width: 90,
		allowFilters: false,
		title: 'Ministry',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'dateRegistered',
		type: 'date',
		width: 55,
		allowFilters: false,
		title: 'Reg Date',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'contactName',
		type: 'string',
		width: 65,
		allowFilters: false,
		title: 'Main contact',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'contactsCount',
		type: 'number',
		width: 55,
		allowFilters: false,
		title: 'Contacts',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'grantsCount',
		type: 'number',
		width: 50,
		allowFilters: false,
		title: 'Grants',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'eventsAttendedCount',
		type: 'number',
		width: 65,
		allowFilters: false,
		title: 'Events Att.',
		render: DefaultGridCellDisplay,
	},
	{
		property: 'upcomingEventsCount',
		type: 'number',
		width: 85,
		allowFilters: false,
		title: 'Upcoming Events',
		render: DefaultGridCellDisplay,
	},
]