import React, { useContext, useState } from 'react'
import { IDefaultProps } from './component-definitions'
import { GridDataFetch, IGridState, GridUserInteractionStateKey, IGridListItem, isGridListItemObjectValueTypeArray } from '../stores/grid-definitions'
import { IAppState } from '../stores/app-definitions'
import { AppActionContext, AppStateContext } from '../app-store-provider'
import { useHTTPRequestUiWrapper, useMinistryProspectsDefaultColumns, useModal } from '../services/hooks'
import { Prospect } from '../models/prospect'
import { Grid } from './grid'
import { defaultGridState, useGrid } from '../stores/grid-actions'
import { gridReducer } from '../stores/grid-reducer'
import { ReactComponent as TagIcon } from '../assets/tag.svg'
import { ReactComponent as ProspectsIcon } from '../assets/bullseye.svg'
import { SquareDeleteIcon } from './partials'
import { isNullOrUndefined } from 'util'
import { ISearchRequestFilterDocument, ISearchSortDocument } from '../open-api'
import { Modal } from './modal'
import { uuidv4 } from '../services/helpers'
import { NoteForm } from './note-form'
import { TagsList } from './tags-list'
import { UserModel, App, UserPermissionLevel } from '../models/user'
import { ProspectForm } from './prospect-form'
import { MinistryManagementCreateCallList } from './ministry-management-create-call-list'
import { ProspectsCsvImport } from './prospects-csv-import'
import { navigate } from '@reach/router'
import { ProspectPossibleMatchesModal } from './prospect-possible-matches-modal'
import { Helmet } from 'react-helmet'
import { BullseyeIcon, JournalPlusIcon, SearchIcon } from '../assets'
import { MinistryManagementAddToExistingCallList } from './ministry-management-add-to-existing-call-list'

interface IProspectsProps extends IDefaultProps { }
export const Prospects = (props: IProspectsProps) => {
	const appActions = useContext(AppActionContext)!
	const appState = useContext(AppStateContext)!

	const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

	const fetchProspects: GridDataFetch<IAppState> = async (queryState, _appState) => {
		// We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
		const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && !isNullOrUndefined(f.value))]) : undefined) as ISearchRequestFilterDocument[] | undefined
		const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as ISearchSortDocument[] | undefined

		try {
			const prospectsQuery = await makeHttpRequestWithUi({
				request: appActions.ProspectsApi.apiProspectsGet(0, (queryState.page - 1) * queryState.itemsPerPage, queryState.itemsPerPage, sorts, filters),
				disableLoading: true,
				disableSuccessToast: true,
				toastErrorMessage: 'There was a problem fetching prospects.'
			})

			return {
				rows: prospectsQuery.data.data ? prospectsQuery.data.data.map(Prospect.toGridListItem) : [],
				count: prospectsQuery.data.totalCount
			}
		} catch (e) {
			return {
				rows: [],
				count: 0
			}
		}
	}

	const [selectedRow, setSelectedRow] = useState<IGridListItem>()

	const [deleteProspectModal, showHideDeleteProspectModal] = useModal()
	const deleteSelectedProspect = async () => {
		if (!selectedRow) {
			appActions.addAlert({ title: 'Error', body: 'No prospect is selected...', id: uuidv4() })
			return
		}
		await makeHttpRequestWithUi({
			request: appActions.ProspectsApi.apiProspectsIdDelete(parseInt(selectedRow.id)),
			toastErrorMessage: 'There was a problem deleting this prospect.',
			toastSuccessMessage: 'Contact successfully deleted.'
		})
		showHideDeleteProspectModal(false)
		gridActions.doFetch()
	}

	const [addNoteModal, showHideAddNoteModal] = useModal()

	const [manageTagsModal, showHideManageTagsModal] = useModal()
	const [manageTagsState, setManageTagsState] = useState<{
		startingTags: number[]
		endingTags: number[]
	}>({
		startingTags: [],
		endingTags: [],
	})
	const setSelectedTags = (tags: number[]) => setManageTagsState({ ...manageTagsState, endingTags: tags })
	let resetTagList: () => void
	const addRemoveTagsFromSelectedProspect = async () => {
		if (!selectedRow) {
			appActions.addAlert({ title: 'Error', body: 'No prospect is selected...', id: uuidv4() })
			return
		}

		const prospectId = parseInt(selectedRow.id)

		// Every tag that was in the final list but wasn't in the initial list
		const addedTags = manageTagsState.endingTags.filter(endingTagId => !manageTagsState.startingTags.find(startingTagId => startingTagId === endingTagId))
		// Every tag that was in the initial list, but isn't in the final list
		const removedTags = manageTagsState.startingTags.filter(startingTagId => !manageTagsState.endingTags.find(endingTagId => startingTagId === endingTagId))

		const promises: Promise<any>[] = []
		addedTags.forEach(tagId => promises.push(makeHttpRequestWithUi({ request: appActions.Tag2DataApi.apiTag2DataPost({ tagId, data: prospectId }), disableLoading: true, disableSuccessToast: true, toastErrorMessage: `Error adding tag (${tagId}) to prospect.` })))
		removedTags.forEach(tagId => promises.push(makeHttpRequestWithUi({ request: appActions.Tag2DataApi.apiTag2DataDelete(tagId, prospectId), disableLoading: true, disableSuccessToast: true, toastErrorMessage: `Error removing tag (${tagId}) from prospect.` })))

		if (promises.length) {
			gridActions.setLoading(true)
			await Promise.all(promises)
			await appActions.fetchTagsSummary()
			gridActions.doFetch()
		}

		showHideManageTagsModal(false)
	}

	const [posssibleMatchesModal, showHidePossibleMatchesModal] = useModal()

	const [newProspectModal, showHideNewProspectModal] = useModal()
	let resetProspectForm: () => void

	const [createCallListModalState, showHideCreateCallListModal] = useModal()

	const [csvImportModal, showHideCsvImportModal] = useModal()

	const [addToCallListModalState, showHideAddToCallListModal] = useModal()
	const [addToExistingCallListModalState, showHideAddToExistingCallListModal] = useModal()

	const initialGridState: IGridState = {
		...defaultGridState,
		respectGlobalCommunityFilter: true,
		loading: true,
		columns: useMinistryProspectsDefaultColumns(),
		dataSource: fetchProspects,
		tagType: 'PROSPECTID',
		userSessionStateKey: GridUserInteractionStateKey.Prospects,
		gridActions: [
			{
				id: 'newProspect',
				label: 'New Prospect',
				onClick: () => showHideNewProspectModal(true),
			},
			{
				id: 'createCallList',
				label: 'Add to Call List',
				onClick: ({ rows }) => {
					if (rows.filter(row => row.selected).length >= 1) {
						showHideAddToCallListModal(true)
					} else {
						appActions.addAlert({ id: uuidv4(), body: 'Please select at least 1 prospect.' })
					}
				},
			},
			{
				id: 'import',
				label: 'Import',
				onClick: () => showHideCsvImportModal(true),
			},
		],
		rowDoubleClicked: async (row) => {
			navigate(`/prospects/${row.id}`)
		},
		rowActions: {
			viewDetails: {
				id: 'viewDetails',
				action: async (options) => {
					options.e.stopPropagation()
				},
				icon: <BullseyeIcon />,
				tooltipText: 'View Details',
				url: (row) => `/prospects/${row.id}`
			},
			addNote: {
				id: 'addNote',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideAddNoteModal(true)
				},
				icon: <JournalPlusIcon />,
				tooltipText: 'Add Note',
				disabled: () => !UserModel.checkPermissionLevelForUser(App.NoteManager, UserPermissionLevel.Modify, appState.currentUser)
			},
			deleteProspect: {
				id: 'deleteProspect',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHideDeleteProspectModal(true)
				},
				icon: <SquareDeleteIcon />,
				tooltipText: 'Delete',
			},
			manageTags: {
				id: 'manageTags',
				action: async (options) => {
					options.e.stopPropagation()

					setSelectedRow(options.row)

					const tags = options.row.values['tags']
					if (isGridListItemObjectValueTypeArray(tags)) {
						const ministryTags = options.appState.tags?.filter(tag => tags.find(t => t.id == tag.tagId)) || []
						setManageTagsState({ ...manageTagsState, startingTags: ministryTags.map(tag => tag.tagId), endingTags: ministryTags.map(tag => tag.tagId) })
					}

					showHideManageTagsModal(true)
				},
				icon: <TagIcon />,
				tooltipText: 'Manage Tags',
			},
			/* 
				The possibleMatches action is a bit unique. See the comments in <PossibleMinistryMatchesDisplay />
			*/
			possibleMatches: {
				id: 'possibleMatches',
				action: async (options) => {
					options.e.stopPropagation()
					setSelectedRow(options.row)
					showHidePossibleMatchesModal(true)
				},
				icon: <SearchIcon />,
				tooltipText: 'Possible matches',
				hidden: (row) => {
					const possibleMatchCount = typeof row.values['possibleMatchCount'] === 'number' ? row.values['possibleMatchCount'] : null

					return (possibleMatchCount === null || possibleMatchCount === 0)
				}
			}
		}
	}

	const [gridState, gridActions] = useGrid(gridReducer, initialGridState, appState)

	return (
		<React.Fragment>
			<Helmet>
				<title>Prospects</title>
			</Helmet>

			<div className='d-flex flex-column' style={{ height: '100vh' }}>
				<div className='m-2 d-flex align-items-center'>
					<ProspectsIcon style={{ width: '25px', height: '25px' }} />
					<h3 className='ml-2'>Prospects</h3>
				</div>

				<Grid state={gridState} actions={gridActions} style={{ flex: 1, height: 'unset' }} />
			</div>

			{/* Prospect delete confirmation modal */}
			<Modal
				{...deleteProspectModal}
				modalTitle='Confirm'
				_onModalHidden={() => setSelectedRow(undefined)}
				footer={
					<React.Fragment>
						<button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
						<button type='button' className='btn btn-danger' onClick={deleteSelectedProspect}>Confirm</button>
					</React.Fragment>
				}
			>
				Are you sure you want to delete this prospect?
			</Modal>

			{/* Add note to prospect modal */}
			<Modal
				{...addNoteModal}
				modalTitle={`New Note - ${selectedRow?.values['organizationName']}`}
				_onModalHidden={() => setSelectedRow(undefined)}
			>
				<div className='mb-2'>
					{addNoteModal.show && selectedRow ?
						<NoteForm
							prospectId={parseInt(selectedRow.id)}
							afterSave={async () => showHideAddNoteModal(false)}
						/>
						:
						null
					}
				</div>
			</Modal>

			{/* Edit tags for prospect modal */}
			<Modal
				{...manageTagsModal}
				modalTitle={`Manage Tags - ${selectedRow?.values['organizationName']}`}
				allowOverflow={true}
				_onModalHidden={() => {
					setSelectedRow(undefined)
					if (resetTagList) resetTagList()
				}}
				footer={
					<React.Fragment>
						<button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-primary" onClick={addRemoveTagsFromSelectedProspect}>Save changes</button>
					</React.Fragment>
				}
			>
				<TagsList
					tags={appState.tags?.filter(tag => tag.tableKey === 'PROSPECTID') || []}
					selectedTagIds={manageTagsState.endingTags}
					setSelectedTagIds={setSelectedTags}
					setResetList={(reset) => resetTagList = reset}
					tagType={'PROSPECTID'}
				/>
			</Modal>

			{/* View and manage possible matches for a ministry prospect */}
			{selectedRow ?
				<ProspectPossibleMatchesModal
					{...posssibleMatchesModal}
					modalTitle='Possible Ministry Matches'
					ministryContact={{
						id: selectedRow.id,
						organizationName: `${selectedRow.values.organizationName}`,
						contactName: `${selectedRow.values.firstName} ${selectedRow.values.lastName}`,
						email: `${selectedRow.values.email}`,
					}}
					_onModalHidden={() => setSelectedRow(undefined)}
					onActionSuccess={gridActions.doFetch}
				/>
				:
				null
			}

			{/* New prospect modal */}
			<Modal
				{...newProspectModal}
				modalTitle='New Prospect'
				dismissible={false}
				_onModalHidden={() => {
					if (resetProspectForm) resetProspectForm()
				}}
			>
				<ProspectForm
					setResetForm={(resetForm) => resetProspectForm = resetForm}
					onSaveFinish={() => {
						showHideNewProspectModal(false)
						gridActions.doFetch()
					}}
				/>
			</Modal>

			<Modal
				{...addToCallListModalState}
				modalTitle='Add to Call List'
				dismissible={false}
			>
				<div>
					<p>Would you like to add the selected ministries to an existing call list or create a new one?</p>

					<div>
						<button
							className='btn btn-primary btn-large mr-2'
							onClick={() => {
								showHideAddToCallListModal(false)
								showHideCreateCallListModal(true)
							}}
						>
							Create Call List
						</button>
						<button
							className='btn btn-primary btn-large'
							onClick={() => {
								showHideAddToCallListModal(false)
								showHideAddToExistingCallListModal(true)
							}}
						>
							Add to Existing Call List
						</button>
					</div>
				</div>
			</Modal>

			{/* Create call list modal */}
			<Modal
				{...createCallListModalState}
				modalTitle='Create Call List'
				size='xl'
				dismissible={false}
				_onModalHidden={gridActions.deselectAllRows}
			>
				{createCallListModalState.show ?
					<MinistryManagementCreateCallList
						onCancel={() => showHideCreateCallListModal(false)}
						onSave={() => showHideCreateCallListModal(false)}
						prospectIds={Object.values(gridState.selectedRows).map(row => parseInt(row.id))}
					/>
					: null
				}
			</Modal>

			<Modal
				{...addToExistingCallListModalState}
				modalTitle='Add to Existing Call List'
				size='lg'
				dismissible={false}
				_onModalHidden={gridActions.deselectAllRows}
			>
				{addToExistingCallListModalState.show ?
					<MinistryManagementAddToExistingCallList
						onSave={() => showHideAddToExistingCallListModal(false)}
						prospectIds={Object.values(gridState.selectedRows).map(row => parseInt(row.id))}
					/>
					: null
				}
			</Modal>

			{/* CSV import modal */}
			<Modal
				{...csvImportModal}
				modalTitle='Import Prospects from CSV File'
				size='xxl'
			>
				<ProspectsCsvImport onImportFinished={() => {
					showHideCsvImportModal(false)
					gridActions.doFetch()
				}} />
			</Modal>

		</React.Fragment>
	)
}