import { User, WebStorageStateStore } from "oidc-client-ts";  
import { AuthProviderProps } from "react-oidc-context";
import { navigate } from '@reach/router'
import { IDP_CLIENT_ID, IDP_AUTHORITY, IDP_REDIRECT_URI } from '../constants'


// Config object to be passed to OIDC on creation
export const oidcConfig: AuthProviderProps = {
    
    authority: IDP_AUTHORITY,
    client_id: IDP_CLIENT_ID,
    redirect_uri: IDP_REDIRECT_URI,
    
    // This method is used to clear the URL after a successful login
    // See: https://github.com/authts/react-oidc-context?tab=readme-ov-file Getting Started
    // This sends the user to the correct URL after a successful login
    onSigninCallback: (_user: User | void): void => {
        
        var originalUrl = window.localStorage.getItem('originalUrl') || window.location.pathname;

        if(originalUrl.startsWith('/logout')){ originalUrl = '/'; }

        window.localStorage.removeItem('originalUrl');
        navigate(originalUrl);
    },
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    // ...
  };

// Function to initiate login and store the original URL
export const initiateLogin = () => {
    window.localStorage.setItem('originalUrl', window.location.pathname + window.location.search);
    // Call the login function from your OIDC client
    // Example: userManager.signinRedirect();
};