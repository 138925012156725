import { useCallback, useContext, useEffect, useState } from "react"
import { AppActionContext } from "../app-store-provider";
import { IDefaultProps } from "./component-definitions";
import { IOrderLineItemSummaryDocument, IOrderSearchResultDocument, ISearchRequestFilterDocument, ISearchSortDocument } from "../open-api";
import { useHTTPRequestUiWrapper, useModal } from "../services/hooks";
import { GridDataFetch, GridUserInteractionStateKey, IGridListItem, IGridState } from "../stores/grid-definitions";
import { IAppState } from "../stores/app-definitions";
import { defaultGridState, useGrid } from "../stores/grid-actions";
import { BoxArrowUpRight, CurrencyDollarIcon, PencilIcon } from "../assets"
import { DefaultGridCellDisplay, GridActionCell, MinistryContactNameDisplay  } from "./grid-cell-displays";
import { gridReducer } from "../stores/grid-reducer";
import Helmet from "react-helmet";
import { Grid } from "./grid";
import { Modal } from "./modal";
import { FormikTextAreaField } from "./forms/formik-text-area-field";
import { Form, Formik } from "formik";
import dayjs from "dayjs";
import { formatCurrency, locationHashToObject } from "../services/helpers";
import { useLocation } from "@reach/router";
import { OrderLineItems } from "./order-line-items";
import { getEventUrl } from "./event-management";

interface IOrdersProps extends IDefaultProps { }

export const OrderManagement = (props: IOrdersProps) => {
    const { PayOrderApi, PayOrderLineItemApi } = useContext(AppActionContext)!

    const [selectedRow, setSelectedRow] = useState<IGridListItem>()
    const [lineItems, setLineItems] = useState<IOrderLineItemSummaryDocument[]>([])
    const [orderFormModal, showHideOrderFormModal] = useModal()
    const [orderToEdit, setOrderToEdit] = useState<IOrderSearchResultDocument>()

    const makeHttpRequestWithUi = useHTTPRequestUiWrapper()

    const fetchLineItems = async () => {
        if (!orderToEdit) return
        
        const {data} = await makeHttpRequestWithUi({
            request: PayOrderLineItemApi.apiPayOrderLineItemOrderIdGet(orderToEdit.orderId),
            disableSuccessToast: true,
            toastErrorMessage: 'Failed to fetch call lists.'
        })

        setLineItems(data)
    }

    const editOrder = async (orderId: string) => {
        const {data} = await makeHttpRequestWithUi({
            request: PayOrderApi.apiPayOrderOrderIdGet(parseInt(orderId)),
            disableSuccessToast: true,
            toastErrorMessage: 'There was an error retrieving the session to edit.'
        })

        setOrderToEdit(data)

        showHideOrderFormModal(true)
    }

    const dataSource: GridDataFetch<IAppState> = useCallback(async (queryState, _appState) => {
        // We have to type-coerce the filters and sorts since the OpenApi template generator doesn't support serializing complex URL query params
        const filters = (queryState.filters ? JSON.stringify([...queryState.filters.filter(f => f.enabled && f.value !== null && f.value !== undefined)]) : undefined) as unknown as ISearchRequestFilterDocument[] | undefined
        const sorts = (queryState.sorts ? JSON.stringify(queryState.sorts) : undefined) as unknown as ISearchSortDocument[] | undefined

        try {

            const query = await makeHttpRequestWithUi({
                request: PayOrderApi.apiPayOrderGet(
                    (queryState.page - 1) * queryState.itemsPerPage,
                    queryState.itemsPerPage,
                    sorts,
                    filters
                ),
                disableSuccessToast: true,
                toastErrorMessage: 'There was a problem fetching the list of grants.'
            })

            const rows = (query.data.data || []).map<IGridListItem>(item => ({
                id: item.orderId.toString(),
                values: {
                    ...item
                }
            }))

            return {
                rows,
                count: query.data.totalCount
            }
        } catch (e) {
            return {
                count: 0,
                rows: []
            }
        }
    }, [])

    const initialGridState: IGridState = {
        ...defaultGridState,
        userSessionStateKey: GridUserInteractionStateKey.Orders,
        rowActions: {
            editOrder: {
                id: 'editOrder',
                action: async ({ row }) => {
                    setSelectedRow(row)
                    editOrder(row.id)
                },
                tooltipText: 'View Order',
                icon: <PencilIcon />,
            }
        },
        columns: [
            {
                property: 'dateCreated',
                type: 'date',
                width: 75,
                allowFilters: true,
                title: 'Created',
                render: DefaultGridCellDisplay
            },
            {
                property: 'orderId',
                type: 'number',
                width: 50,
                allowFilters: true,
                title: 'Order ID',
                render: DefaultGridCellDisplay,
                hide: true
            },
            {
                property: 'processorId',
                type: 'number',
                width: 150,
                allowFilters: true,
                title: 'Processor ID',
                render: (col, row) => <a href={`https://dashboard.stripe.com/payments/${row.values.processorId}`} target="_blank">{row.values.processorId}</a>
            },
            {
                property: 'eventContentTitle',
                type: 'string',
                width: 150,
                allowFilters: true,
                title: 'Event',
                render: (col, row) => {
                    if (row.values.entityType === 'Events') {
                        //@ts-ignore
                        const url = getEventUrl(row.values.eventTypeId, row.values.eventContentId, row.values.eventId)
                        return <><a href={url}>{row.values.eventContentTitle}</a> <a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>
                    } else {
                        // Assume this is a 'zEventContent'
                        const url = `/event-content/${row.values.eventContentId}`
                        return <><a href={url}>{row.values.eventContentTitle}</a> <a href={url} target='_blank' style={{marginLeft: "5px"}}><BoxArrowUpRight /></a></>
                    }
                },
            },
            {
                property: 'ministryName',
                type: 'string',
                width: 125,
                allowFilters: true,
                title: 'Ministry',
                render: MinistryContactNameDisplay
            },
            {
                property: 'ministryContactFullName',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Contact',
                render: (_, row) => row.values.ministryContactFirstName +  ' ' + row.values.ministryContactLastName
            },
            {
                property: 'customerType',
                type: 'string',
                width: 100,
                allowFilters: true,
                title: 'Customer Type',
                render: DefaultGridCellDisplay,
                filterOptions: [
                    { label: 'Guest ', value: 'Guest' },
                    { label: 'MinistryContact ', value: 'MinistryContact' },
                ],
            },
            {
                property: 'amount',
                type: 'number',
                width: 50,
                allowFilters: true,
                title: 'Amount',
                render: (_, row) => row.values.amount && formatCurrency((parseInt(row.values.amount.toString()) / 100))
            },
            {
                property: 'status',
                type: 'string',
                width: 50,
                allowFilters: true,
                title: 'Status',
                render: DefaultGridCellDisplay,
            },            
            {
                property: 'grid_actions',
                type: 'actions',
                width: 75,
                disableSort: true,
                title: 'Actions',
                render: GridActionCell,
                align: 'center'
            },

        ],
        dataSource,
        rowSelectEnabled: false,
        disableExport: false,
        infinitePaging: false,
        rowDoubleClicked: async (row) => {
            setSelectedRow(row)
            editOrder(row.id)
        },
    }

    const [gridState, gridActions] = useGrid(gridReducer, initialGridState, [])

    useEffect(() => {
        fetchLineItems()
    }, [orderToEdit])

    // Support linking to a particular order by appending "#view=EVENT_ID" to /event-management URL
    const location = useLocation()
    useEffect(() => {
        const parsedHash = locationHashToObject(location.hash)
        if (parsedHash.view) editOrder(parsedHash.view)
    }, [location.hash])

    return (
        <>
            <Helmet>
                <title>Order Management</title>
            </Helmet>

            <div className='d-flex flex-column' style={{ height: '100vh' }}>
            <div className='m-2 d-flex align-items-center'>
                    <CurrencyDollarIcon style={{ width: '25px', height: '25px' }} />
                    <h3 className='ml-2'>Order Management</h3>
                </div>
                <p className='ml-2'>
                    Here is a list of orders relating to event ticket purchases.
                </p>
                <Grid state={gridState} actions={gridActions} />
            </div>
            <Modal
                {...orderFormModal}
                modalTitle={`Order Details (${orderToEdit?.orderId})` }
                size='lg'
                _onModalHidden={() => {
                    setSelectedRow(undefined)
                }}
            >
                {orderFormModal.show && orderToEdit &&
                    <Formik
                    initialValues={{
                        metadata: orderToEdit.metadata,
                    }}
                    onSubmit={async (values) => {
                        return
                    }}
                >
                    {formikProps => {
                        return (
                        <Form style={{ padding: 10 }}>
                            <div className="d-flex justify-content-between">
                                <div style={{flex: 1}} className="mr-4">
                                    <div className="mb-2">Created: {dayjs(orderToEdit.dateCreated?.toString()).format('MM/DD/YYYY')}</div>
                                    <div className="mb-2">Total Amount: {orderToEdit.amount ? formatCurrency((parseInt(orderToEdit.amount.toString()) / 100)) : ''}</div>
                                    <div className="mb-2">Status <b style={{color: 'green'}}>{orderToEdit.status}</b></div>
                                    <div className="mb-2">Stripe ID: <a href={`https://dashboard.stripe.com/payments/${orderToEdit.processorId}`} target="_blank">{orderToEdit.processorId}</a></div>
                                </div>
                                <div style={{flex: 1}}>
                                <div className="mb-2">Ministry: {orderToEdit.ministryId > 0 ? <a href={`/ministry-info/${orderToEdit.ministryId}`} target="_blank">{orderToEdit.ministryName}</a> : orderToEdit.ministryName} </div>
                                    <div className="mb-2">Contact: {orderToEdit.ministryContactId || 0 > 0 ? <a href={`/contact-info/${orderToEdit.ministryContactId}`} target="_blank">{orderToEdit.ministryContactFullName}</a> : <>{orderToEdit.ministryContactFirstName}</> }</div>
                                    <div className="mb-2">Customer Type: {orderToEdit.customerType}</div>
                                    <div className="mb-2">Event: {orderToEdit.eventContentTitle}</div>
                                </div>
                            </div>
                            
                            <FormikTextAreaField field={{label: 'Metadata', name: 'metadata', disabled: true }} />
                            <h4>Line Items</h4>
                            <OrderLineItems lineItems={lineItems} />
                        </Form>
                        )
                    }}
                </Formik>
                
                }
            </Modal>
        </>
    )
}