import React, { useRef, useEffect, useContext } from 'react'
import { Chart } from 'chart.js'
import { MinistryInfoContext } from './ministry-info'
import moment from 'moment'
import { formatCurrency } from '../services/helpers'
import { usePrevious } from 'react-use'

export const MinistryInfoGeneralCharts = () => {
	const { state, actions } = useContext(MinistryInfoContext)!
	const { ministryGeneralChartsData } = state

	const twelveMonthEventsChart = useRef<HTMLCanvasElement>(null)
	const incomeAmountsChart = useRef<HTMLCanvasElement>(null)

	const previousMinistryId = usePrevious(state.ministryId)
	useEffect(() => {
		if (!ministryGeneralChartsData || state.ministryId !== previousMinistryId) actions.fetchMinistryGeneralChartsData()
	}, [state.ministryId])

	useEffect(() => {
		const ctx = twelveMonthEventsChart.current
		if (ctx && ministryGeneralChartsData) {
			new Chart(ctx, {
				type: 'pie',
				data: {
					labels: ['Attended', 'Not attended'],
					datasets: [{
						data: [ministryGeneralChartsData.eventsAttended12Mo, ministryGeneralChartsData.possibleEvents12Mo - ministryGeneralChartsData.eventsAttended12Mo],
						// data: [15, 62],
						backgroundColor: ['rgba(40, 167, 69, 0.75)', 'rgba(220, 53, 69, 0.75)']
					}]
				},
				options: {
					title: {
						display: true,
						text: `12 Month Event Attendance (Total events offered: ${ministryGeneralChartsData.possibleEvents12Mo})`
					}
				}
			})
		}

	}, [ministryGeneralChartsData])

	useEffect(() => {
		const ctx = incomeAmountsChart.current
		if (ctx && ministryGeneralChartsData) {
			new Chart(ctx, {
				type: 'bar',
				data: {
					labels: [moment().subtract(4, 'year').format('YYYY'), moment().subtract(3, 'year').format('YYYY'), moment().subtract(2, 'year').format('YYYY'), moment().subtract(1, 'year').format('YYYY'),],
					datasets: [{
						label: 'Income',
						data: [ministryGeneralChartsData.income4, ministryGeneralChartsData.income3, ministryGeneralChartsData.income2, ministryGeneralChartsData.income1],
						// data: [1549, 1241, 1313, 1334],
						backgroundColor: ['#17a2b8', '#17a2b8', '#17a2b8', '#17a2b8']
					}]
				},
				options: {
					title: {
						display: true,
						text: 'Income Amounts'
					},
					scales: {
						yAxes: [{
							ticks: {
								callback: (value, index, values) => {
									return formatCurrency(parseInt(value.toString()))
								}
							}
						}]
					},
					tooltips: {
						callbacks: {
							label: (tooltipItem, data) => {
								return `${tooltipItem.yLabel ? formatCurrency(parseInt(tooltipItem.yLabel.toString())) : ''}`
							}
						}
					}
				}
			})
		}

	}, [ministryGeneralChartsData])

	if (!ministryGeneralChartsData) {
		return null
	} else {
		return (
			<div>
				<h5 className='sticky-top bg-white py-2'>Charts</h5>
				<div className='row'>
					<div className='col-md-6'>
						{/* <h5>12 Month Events</h5> */}
						<canvas ref={twelveMonthEventsChart} width='200' height='200'></canvas>
					</div>
					<div className='col-md-6'>
						{/* <h5>Income Amounts</h5> */}
						<canvas ref={incomeAmountsChart} width='200' height='200'></canvas>
					</div>
				</div>
			</div>
		)
	}
}